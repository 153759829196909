import { ObjectType } from '@worldfavor/constants/enums'

export const questionAnswerTypeTranslateMap = {
  1: 'modules.questionAnswerTypes.processing',
  2: 'modules.questionAnswerTypes.notRelevant',
  3: 'modules.questionAnswerTypes.no',
  4: 'modules.questionAnswerTypes.yes',
  5: 'modules.questionAnswerTypes.accredited',
  6: 'modules.questionAnswerTypes.compliant',
  7: 'modules.questionAnswerTypes.notCompliant',
  8: 'modules.questionAnswerTypes.registered',
  9: 'modules.questionAnswerTypes.notRegistered',
  10: 'modules.questionAnswerTypes.agree',
  11: 'modules.questionAnswerTypes.doNotAgree',
  13: 'modules.questionAnswerTypes.partlyCompliant',
  14: 'modules.questionAnswerTypes.approved',
  15: 'modules.questionAnswerTypes.notApproved',
  16: 'modules.questionAnswerTypes.noTime',
  17: 'modules.questionAnswerTypes.notApplicable',
  18: 'modules.questionAnswerTypes.checked',
  19: 'modules.questionAnswerTypes.notChecked',
  20: 'modules.questionAnswerTypes.partly',
  21: 'modules.questionAnswerTypes.noInfoAvailable',
  22: 'modules.questionAnswerTypes.dontKnow',
  23: 'modules.questionAnswerTypes.certified',
  24: 'modules.questionAnswerTypes.lowRisk',
  25: 'modules.questionAnswerTypes.mediumRisk',
  26: 'modules.questionAnswerTypes.highRisk',
  27: 'modules.questionAnswerTypes.inProgress',
  unanswered: 'modules.questionAnswerTypes.unanswered',
}

export const fulfillmentStateTranslateMap = {
  0: 'modules.fulfillmentStates.unfulfilled',
  1: 'modules.fulfillmentStates.fulfilled',
  2: 'modules.fulfillmentStates.assessmentNeeded',
  3: 'modules.fulfillmentStates.reportingNeeded',
  4: 'modules.fulfillmentStates.expired',
  5: 'modules.fulfillmentStates.certificateExpired',
}

export const iconClasses = {
  [ObjectType.statement]: { iconClass: 'fas', icon: 'fa-quote-right' },
  [ObjectType.question]: { iconClass: 'fas', icon: 'fa-comment' },
  [ObjectType.measure]: { iconClass: 'fas', icon: 'fa-line-chart' },
  [ObjectType.relativeMeasure]: { iconClass: 'fas', icon: 'fa-line-chart' },
  [ObjectType.measureAnswer]: { iconClass: 'fas', icon: 'fa-line-chart' },
  [ObjectType.location]: { iconClass: 'fas', icon: 'fa-map-marker-alt' },
  [ObjectType.link]: { iconClass: 'fas', icon: 'fa-link' },
  [ObjectType.embed]: { iconClass: 'fas', icon: 'fa-play-circle' },
  [ObjectType.orgDocument]: { iconClass: 'fas', icon: 'fa-file' },
  [ObjectType.questionAnswer]: { iconClass: 'far', icon: 'fa-comments' },
  [ObjectType.orgActivity]: { iconClass: 'far', icon: 'fa-calendar-check' },
  [ObjectType.productionSite]: { iconClass: 'fas', icon: 'fa-industry' },
  [ObjectType.productService]: { iconClass: 'fas', icon: 'fa-cube' },
  [ObjectType.certificate]: { iconClass: 'fas', icon: 'fa-certificate' },
  [ObjectType.finding]: { iconClass: 'fas', icon: 'fa-comments' },
  [ObjectType.organization]: { iconClass: 'fas', icon: 'fa-building' },
  [ObjectType.person]: { iconClass: 'fas', icon: 'fa-user' },
  [ObjectType.individual]: { iconClass: 'fas', icon: 'fa-user' },
  [ObjectType.dateItem]: { iconClass: 'far', icon: 'fa-calendar-alt' },
  [ObjectType.country]: { iconClass: 'far', icon: 'fa-flag' },
  0: { iconClass: 'fas', icon: 'fa-globe-americas' },
}

export const getItemIcon = type => iconClasses[type] || iconClasses[0]
