import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import MaxWidthGrid from '@worldfavor/components/MaxWidthGrid'
import Paper from '@material-ui/core/Paper'
import { getSubNodesFromNodeId } from '../selectors/dataSelector'
import { loadItem, loadSubItemsPage, updateItem, deleteItem, deleteThirdPartyInfluence } from '../actions/dataThunk'
import Loading from './Loading'
import Table from '@worldfavor/components/Table'
import TableHeaderCell from '@worldfavor/components/Table/TableHeaderCell'
import InfluenceTableRowCell from '../components/Influence/InfluenceTableRowCell'
import InfluenceEditorDialog from '../components/Influence/InfluenceEditorDialog'
import DeleteDialog from '@worldfavor/components/Dialog/DeleteDialog'
import { useDialogState } from '@worldfavor/hooks'
import { StaticIds } from '@worldfavor/constants'
import withPage from '../hoc/withPage'
import StyledPageHeader from '@worldfavor/components/Header/StyledPageHeader'
import Dialog from '@worldfavor/components/Dialog'
import { createThirdPartyInfluence } from '../actions/dataThunk'
import { resetState as resetPaginationState } from '@worldfavor/portal/actions/paginationActions'
import { exchangePath, influencePath } from '@worldfavor/constants/paths'
import { apiCallError, beginDataApiCall, dataApiCallSuccess } from '../actions/apiCallStatusActions'
import useDataFetcher from '@worldfavor/hooks/dataFetcher'

const WrappedTable = withPage(({ items, totalElements, ...other }) => (
  items ? <Table data={items} count={totalElements} {...other} /> : null
))

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  row: {
    cursor: 'pointer',
  },
  preTableInfo: {
    paddingTop: 15,
    paddingBottom: 15,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  resultCount: {
    fontWeight: theme.typography.fontWeights.medium,
    fontSize: theme.typography.fontSizes.medium,
  },
}))

const rowKeyExtractor = ({ node }) => `row-${node.wfid}`
const rowCellKeyExtractor = ({ row, columnIndex }) => `rowCell-${row.node.wfid}-${columnIndex}`

const InfluencesScene = (props) => {
  const { match, history } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const { networkId } = match.params
  const [shouldReloadData, setShouldReloadData] = useState(false)
  const [saving, setSaving] = useState(false)
  const [pageHeader, setPageHeader] = useState({})
  const dataLoading = useSelector(state => state.apiCallsInProgress > 0)
  const [editingInfluence, setEditingInfluence] = useState(null)
  const [influenceToDelete, setInfluenceToDelete] = useState(null)
  const [deletingInfluence, setDeletingInfluence] = useState(false)
  const [reporterOrganizationName, setReporterOrganizationName] = useState()
  const users = useSelector(state => getSubNodesFromNodeId(state, StaticIds.Colleagues)).map(user => ({
    value: user.node.id,
    label: `${user.node.name} (${user.node.email})`,
  }))
  const [influenceToStopReportingOnBehalf, setInfluenceToStopReportingOnBehalf] = useState(null)
  const [influenceToStartReportingOnBehalf, setInfluenceToStartReportingOnBehalf] = useState(null)
  const [influenceEditorDialogOpen, openInfluenceEditorDialog, closeInfluenceEditorDialog] = useDialogState(false)
  const [influenceDeleteDialogOpen, openInfluenceDeleteDialog, closeInfluenceDeleteDialog] = useDialogState(false)
  const [reportingOnBehalfDialogOpen, openReportingOnBehalfDialog, closeReportingOnBehalfDialog] = useDialogState(false)

  const dialogActions = [
    {
      label: <FormattedMessage id={'general.close'} />,
      onClick: _closeReportingOnBehalfDialog,
      variant: 'text',
    },
    {
      label: <FormattedMessage id={'supplyChain.thirdPartyReporting.startReportingDialog.button'} />,
      onClick: startReportingOnBehalf,
      variant: 'text',
      hide: Boolean(!influenceToStartReportingOnBehalf),
      saving,
    },
    {
      label: <FormattedMessage id={'supplyChain.thirdPartyReporting.stopReportingDialog.button'} />,
      onClick: stopReportingOnBehalf,
      variant: 'text',
      hide: Boolean(!influenceToStopReportingOnBehalf),
      saving,
    },
  ]

  useEffect(() => {

    async function fetchUsers() {
      const [type, id] = StaticIds.Colleagues.split('-')
      try {
        await dispatch(loadItem(id, type))
      } catch (e) {
        console.log('Could not fetch users: ' + e)
      }
    }

    async function fetchData() {
      const [type, id] = StaticIds.SentInfluences.split('-')
      let pageHeader
      try {
        pageHeader = await dispatch(loadItem(id, type, { childrenLoadDepth: -1 }))
      } catch (e) {
        console.log('Could not fetch data: ' + e)
      } finally {
        setPageHeader(pageHeader)
      }
    }

    fetchData()
    fetchUsers()
  }, [])

  const fetchData = (source, pageNumber, pageSize) => dispatch(
    loadSubItemsPage(
      StaticIds.SentInfluences, pageNumber, pageSize, StaticIds.SentInfluences,
      source, { getterConditions: { networkId } },
    ),
  )

  const {
    resultCount,
    pageNumber,
    pageSize,
    pageId,
    requestId,
    setPageNumber,
    setPageSize,
  } = useDataFetcher(fetchData, [shouldReloadData], { onBeforeSend: () => setShouldReloadData(false) })

  function openInfluenceEditor(influence) {
    setEditingInfluence(influence)
    openInfluenceEditorDialog()
  }

  function closeInfluenceEditor() {
    setEditingInfluence(null)
    closeInfluenceEditorDialog()
  }

  function openInfluenceDeletion(influence) {
    setInfluenceToDelete(influence)
    openInfluenceDeleteDialog()
  }

  function closeInfluenceDeletion() {
    setInfluenceToDelete(null)
    closeInfluenceDeleteDialog()
  }

  function _closeReportingOnBehalfDialog() {
    closeReportingOnBehalfDialog()
    setInfluenceToStartReportingOnBehalf(null)
    setInfluenceToStopReportingOnBehalf(null)
  }

  function openStartReportingOnBehalfDialog(influence) {
    openReportingOnBehalfDialog()
    setInfluenceToStartReportingOnBehalf(influence)
  }

  function openStopReportingOnBehalfDialog(influence) {
    openReportingOnBehalfDialog()
    setInfluenceToStopReportingOnBehalf(influence)
  }

  function getDialogTitle() {
    if (influenceToStopReportingOnBehalf) {
      return (
        <FormattedMessage
          id={'supplyChain.thirdPartyReporting.stopReportingDialog.title'}
          values={{ organizationName: getReporterOrganizationName() }}
        />
      )
    }

    if (influenceToStartReportingOnBehalf) {
      return (
        <FormattedMessage
          id={'supplyChain.thirdPartyReporting.startReportingDialog.title'}
          values={{ organizationName: getReporterOrganizationName() }}
        />
      )
    }
  }

  function getDialogDescription() {
    if (influenceToStopReportingOnBehalf) {
      return (
        <FormattedMessage
          id={'supplyChain.thirdPartyReporting.stopReportingDialog.description'}
          values={{ organizationName: getReporterOrganizationName() }}
        />
      )
    }

    if (influenceToStartReportingOnBehalf) {
      return (
        <FormattedMessage
          id={'supplyChain.thirdPartyReporting.startReportingDialog.description'}
          values={{ organizationName: getReporterOrganizationName() }}
        />
      )
    }
  }

  function getReporterOrganizationName() {
    if (reporterOrganizationName) {
      return reporterOrganizationName
    }
    return <FormattedMessage id={'supplyChain.thirdPartyReporting.reporter'} />
  }

  function renderColumnCell({ column }) {
    const columns = {
      title: <TableHeaderCell label={<FormattedMessage id="general.request" values={{ count: 1 }} />} />,
      organization: <TableHeaderCell label={<FormattedMessage id="general.organization" values={{ count: 1 }} />} />,
      activatedAt: <TableHeaderCell label={<FormattedMessage id={'supplyChain.influences.activatedAt'} />} />,
      deadline: <TableHeaderCell label={<FormattedMessage id={'general.deadline'} />} />,
      fulfillmentStatus: <TableHeaderCell label={<FormattedMessage id={'general.fulfillmentStatus'} />} />,
      actions: null,
    }

    return typeof columns[column] === undefined ? <TableHeaderCell label={<FormattedMessage id={`general.${column}`} />} /> : columns[column]
  }

  function renderRowCell(attributes) {
    return (
      <InfluenceTableRowCell
        {...attributes}
        onEditInfluence={openInfluenceEditor}
        onDeleteInfluence={openInfluenceDeletion}
        onStartReportingOnBehalfDialog={openStartReportingOnBehalfDialog}
        onRemovePermissionDialog={openStopReportingOnBehalfDialog}
        setReporterOrganizationName={setReporterOrganizationName}
      />
    )
  }

  function onRowClick(event, row) {
    history.push(row.node.useNewRoute ? exchangePath(row.node.wfid) : influencePath(row.node.id))
  }

  function onPageChange(event, pageNumber) {
    setPageNumber(pageNumber)
  }

  function onChangeRowsPerPage(event) {
    setPageSize(event.target.value)
  }

  async function startReportingOnBehalf() {
    try {
      setSaving(true)
      if (influenceToStartReportingOnBehalf) {
        await dispatch(createThirdPartyInfluence(influenceToStartReportingOnBehalf))
        closeReportingOnBehalfDialog()
        setInfluenceToStartReportingOnBehalf(null)
      }
      else {
        console.error('influenceToStartReportingOnBehalf not defined')
      }
    } catch (error) {
      console.error('Could not create third party influence', error)
    } finally {
      setSaving(false)
    }
  }

  async function stopReportingOnBehalf() {
    try {
      setSaving(true)
      if (influenceToStopReportingOnBehalf) {
        dispatch(beginDataApiCall())
        await dispatch(deleteThirdPartyInfluence(influenceToStopReportingOnBehalf))
        await dispatch(resetPaginationState())
        setShouldReloadData(true)
        setInfluenceToStopReportingOnBehalf(null)
        closeReportingOnBehalfDialog()
      }
      else {
        dispatch(apiCallError())
        console.error('influenceToStartReportingOnBehalf not defined')
      }
    } catch (error) {
      dispatch(apiCallError())
      console.error('Could not remove third party influence: ', error)
    } finally {
      dispatch(dataApiCallSuccess())
      setSaving(false)
    }
  }

  async function onDeleteInfluence() {
    setDeletingInfluence(true)
    try {
      dispatch(beginDataApiCall())
      await dispatch(deleteItem(influenceToDelete))
      await dispatch(resetPaginationState())
      setShouldReloadData(true)
      setInfluenceToDelete(null)
      closeInfluenceDeletion()
    } catch (e) {
      dispatch(apiCallError())
      console.log('Could not delete influence: ', e)
    } finally {
      dispatch(dataApiCallSuccess())
      setDeletingInfluence(false)
    }
  }

  async function onInfluenceEditorDialogSave(event, model) {
    const influenceWithNewValues = { ...editingInfluence, ...model }
    try {
      await dispatch(updateItem(influenceWithNewValues))
      closeInfluenceEditor()
    } catch (e) {
      console.log('Could not update item: ' + e)
    }
  }

  return (
    <Grid container justify={'center'}>
      <MaxWidthGrid item xs={12} sm={12}>
        { pageHeader && (
          <div data-cy="influences-title">
            <StyledPageHeader title={pageHeader.title} description={pageHeader.description} />
          </div>
        )}
        {resultCount ?
          (
            <div className={classes.preTableInfo}>
              <span className={classes.resultCount}>
                <FormattedMessage id="search.resultsCount" values={{ count: `${resultCount}` }} />
              </span>
            </div>
          ) : null
        }
        <Paper className={classes.root}>
          { (dataLoading || !requestId || !pageId) && <Loading /> }
          {
            (!dataLoading && requestId && pageId) && (
              <WrappedTable
                requestId={requestId}
                pageId={pageId}

                classes={{ row: classes.row }}
                className={classes.table}
                columns={['title', 'organization', 'activatedAt', 'deadline', 'fulfillmentStatus', 'actions']}
                columnWidths={['30%', '20%', '15%', '10%', '20%', '5%']} // Part of columns prop
                renderRowCell={renderRowCell}
                renderColumnCell={renderColumnCell}
                rowKeyExtractor={rowKeyExtractor}
                rowCellKeyExtractor={rowCellKeyExtractor}
                rowsPerPage={pageSize}
                page={pageNumber}
                onPageChange={onPageChange}
                onChangeRowsPerPage={onChangeRowsPerPage}
                controlledPagination
                enableSparsePage
                enablePagination
                rowHover
                rowsPerPageOptions={[5, 10, 20]}
                onRowClick={onRowClick}
              />
            )
          }
        </Paper>
      </MaxWidthGrid>

      {
        influenceEditorDialogOpen && (
          <InfluenceEditorDialog
            open={influenceEditorDialogOpen}
            influence={editingInfluence}
            onClose={closeInfluenceEditor}
            onSave={onInfluenceEditorDialogSave}
            editable={Boolean(editingInfluence)}
            users={users}
          />
        )
      }

      <Dialog
        open={reportingOnBehalfDialogOpen}
        title={getDialogTitle()}
        description={getDialogDescription()}
        actions={dialogActions}
        info
      />

      <DeleteDialog
        open={influenceDeleteDialogOpen}
        title={<FormattedMessage id={'supplyChain.influences.deleteDialog.title'} />}
        subtitle={<FormattedMessage id={'supplyChain.influences.deleteDialog.subtitle'} />}
        onCancel={closeInfluenceDeletion}
        onDelete={onDeleteInfluence}
        deleting={deletingInfluence}
      />
    </Grid>
  )
}

export default withRouter(InfluencesScene)
