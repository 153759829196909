export const getGoogleMapsLink = (lat, lng) => `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`

export const isIE = () => {
  const { userAgent } = navigator
  /* MSIE used to detect old browsers and Trident used to newer ones*/
  return userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1
}
export default {
  getGoogleMapsLink,
  isIE,
}
