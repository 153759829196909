import * as React from 'react'
import CurvedLine from './CurvedLine'

const Arc = React.memo(({ from, to, ...rest }) => {
  if (from[0] > to[0]) {
    const tmp = from
    from = to
    to = tmp
  }

  const offsetX = to[1] - from[1]
  const offsetY = to[0] - from[0]
  const r = Math.sqrt(Math.pow(offsetX, 2) + Math.pow(offsetY, 2))
  const theta = Math.atan2(offsetY, offsetX)
  const thetaOffset = (3.14 / 10)
  const r2 = (r / 2) / (Math.cos(thetaOffset))
  const theta2 = theta + thetaOffset
  const midpointX = (r2 * Math.cos(theta2)) + from[1]
  const midpointY = (r2 * Math.sin(theta2)) + from[0]
  const midpointLatLng = [midpointY, midpointX]

  const path = [
    'M', from,
    'Q', midpointLatLng,
    to,
  ]

  return (
    <CurvedLine
      positions={path}
      {...rest}
    />
  )
})

export default Arc
