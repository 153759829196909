//@flow
import React, { useMemo, useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MuiTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import MuiTableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import { FormattedMessage } from 'react-intl'
import { useTable, usePagination, useResizeColumns, useBlockLayout } from 'react-table'
import classNames from 'classnames'
import Colors from '@worldfavor/constants/colors'
import Divider from '@worldfavor/components/Divider'
import EmptyState from '@worldfavor/components/EmptyState'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  table: {
    tableLayout: 'fixed',
    width: '100%',
  },
  header: {
    top: 50,
    background: theme.palette.common.white,
    zIndex: 1,
  },
  stickyHeader: {
    position: 'fixed',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
  },
  headerTableCell: {
    whiteSpace: 'normal !important',
  },
  rowTableCell: {},
  tableCell: {},
  tableBody: {
    visibility: 'hidden',
  },
  wordWrap: {
    whiteSpace: 'normal !important',
    wordBreak: 'break-word',
  },
  columnResizerWrapper: {
    position: 'absolute',
    right: 0,
    top: 4,
    bottom: 4,
    width: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    transition: 'all .3s ease-in',
    '&:hover': {
      top: 0,
      bottom: 0,
    },
  },
  tableWrapper: {},
  loaderPlaceholder: {
    position: 'relative',
    height: '50vh',
  },
  headerRow: {},
  row: {},
  oddRow: {},
  tableCellWrapper: {},
  loader: {
    color: theme.palette.secondary.main,
    height: 150,
    width: 150,
    position: 'absolute',
    left: '50%',
    marginLeft: -50,
    top: '50%',
  },
  emptyStateTitle: {
    textTransform: 'lowercase',
  },
}))

const TableRow = (props) => {
  const { classes, onRowClick, row, index, _renderRowCell, renderRowCell, rowHover, rowActions } = props

  function onClick(event) {
    onRowClick && onRowClick(event, row.original)
  }

  return (
    <MuiTableRow
      {...row.getRowProps()}
      onClick={onClick}
      hover={rowHover}
      className={classNames(classes.row, !(index % 2) ? classes.oddRow : null)}
      data-cy="table-row"
    >
      {
        row.cells.map(cell => cell.render(_renderRowCell(renderRowCell, cell, rowActions)))
      }
    </MuiTableRow>
  )
}

type Props = {
  // --- mandatory props ---
  data: Array<Object>,
  columns: {
    Header: string,
    accessor: string,
    width: number,
  },

  // --- controlledPagination props ---
  count?: number,
  rowsPerPage?: number,
  controlledPageIndex?: number,
  controlledPagination: boolean,
  onPageChange?: (event: SyntheticEvent<HTMLButtonElement>) => void,

  // --- optional props ---
  wordWrap?: boolean,
  dense?: boolean,
  columnResizing?: boolean,
  enablePagination?: boolean,
  rowsPerPageOptions: Array<number>,
  renderRowCell?: (value: any, row: Object, column: Object, cell: Object) => void,
  renderColumnCell?: (Header: string, accessor: string, column: Object) => void,
  rowHover?: (event: SyntheticEvent<HTMLButtonElement>) => void,
  onRowClick?: (event: SyntheticEvent<HTMLButtonElement>) => void,
  onChangeRowsPerPage?: (event: SyntheticEvent<HTMLButtonElement>) => void,
  rowActions?: Object,
}

const ReactTable = (props: Props) => {
  const {
    // --- mandatory props ---
    data,
    columns = [],

    // --- controlledPagination props ---
    count,
    rowsPerPage,
    controlledPageIndex,
    controlledPagination, //controlled by the parent
    onPageChange,

    // --- optional props ---
    dense,
    columnResizing,
    enablePagination,
    rowsPerPageOptions,
    stickyHeader,
    renderRowCell,
    renderColumnCell,
    rowHover,
    onRowClick,
    onChangeRowsPerPage,
    resultCount,
    title,
    wordWrap,
    rowActions,
    // enableSparsePage, //not implemented yet
  } = props
  const classes = useStyles(props)
  const memoizedData = useMemo(() => data, [data])
  const memoizedColumns = useMemo(() => columns, [columns])
  const defaultColumn = useMemo(() => ({ minWidth: 100 }), [])
  const {
    getTableProps,
    headerGroups,
    page, // rows for the active page
    prepareRow,

    // --- pagination options ---
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      defaultColumn,
      manualPagination: controlledPagination,
      disableResizing: !columnResizing,
    },
    useBlockLayout,
    usePagination,
    useResizeColumns,
  )
  const filteredRowsPerPage = rowsPerPageOptions.filter(option => (resultCount >= option))
  const table = useRef(null)
  const tableWrapper = useRef(null)
  const [sticky, setSticky] = useState(false)
  const [width, setWidth] = useState(0)
  const dataLoading = useSelector(state => state.apiCallsInProgress > 0)

  const scrollListener = () => {
    if (!table || !table.current) return

    const { top } = table.current.getBoundingClientRect()
    if (sticky && top > 50) {
      setSticky(false)
    } else if (!sticky && top <= 50) {
      setSticky(true)
    }
  }

  const resizeListener = () => {
    if (stickyHeader && tableWrapper && tableWrapper.current) {
      setWidth(tableWrapper.current.offsetWidth)
    }
  }

  useEffect(() => {
    if (stickyHeader) {
      if (tableWrapper && tableWrapper.current) {
        setWidth(tableWrapper.current.offsetWidth)
      }
      window.addEventListener('scroll', scrollListener)
      window.addEventListener('resize', resizeListener)
    }
    return () => {
      if (stickyHeader) {
        window.removeEventListener('scroll', scrollListener)
        window.addEventListener('resize', resizeListener)
      }
    }
  }, [scrollListener, tableWrapper, stickyHeader])

  useEffect(() => {
    // disabling event listeners if the user disables 'sticky header'
    if (!stickyHeader) {
      setSticky(false)
      window.removeEventListener('scroll', scrollListener)
      window.addEventListener('resize', resizeListener)
    }
  }, [stickyHeader])

  function _renderColumnCell(renderColumnCell, column) {
    const { Header, id } = column
    return (
      <TableCell
        {...column.getHeaderProps()}
        className={classNames(classes.headerTableCell,
          classes.tableCell,
          wordWrap && classes.wordWrap)}
      >
        <div className={classes.tableCellWrapper}>
          {renderColumnCell ? renderColumnCell(Header, id, column) : Header}
        </div>
        {columnResizing && (
          <div {...column.getResizerProps()} className={classes.columnResizerWrapper} data-cy="table-resizer">
            <Divider style={{ borderRadius: 5 }} width={2} margin={'0'} height={'100%'} color={Colors.graySemiLight} />
          </div>
        )}
      </TableCell>
    )
  }

  function _renderRowCell(renderRowCell, cell, rowActions) {
    const { value, row, column } = cell

    return (
      <TableCell
        {...cell.getCellProps()}
        data-cy="table-cell"
        className={classNames(classes.rowTableCell, classes.tableCell, wordWrap && classes.wordWrap)}
      >
        {renderRowCell ? renderRowCell(value, row, column, cell, rowActions) : value}
      </TableCell>
    )
  }

  function _onPageChange(event, pageNumber) {
    if (!controlledPagination && pageNumber && Number.isInteger(pageNumber)) {
      gotoPage(pageNumber)
    }
    onPageChange && onPageChange(event, pageNumber)
  }

  function _onChangeRowsPerPage(event) {
    const pageSize = event.target.value
    if (!controlledPagination && Number.isInteger(pageSize)) {
      setPageSize(pageSize)
    }
    onChangeRowsPerPage && onChangeRowsPerPage(event)
  }

  return (
    <>
      <ScrollSync>
        <ScrollSyncPane group="horizontal">
          <div
            ref={tableWrapper}
            className={classNames(classes.tableWrapper, {
              [classes.loaderPlaceholder]: dataLoading,
            })}
          >
            { dataLoading && <CircularProgress size={68} className={classes.loader} /> }
            <MuiTable ref={table} size={dense ? 'small' : 'medium'} {...getTableProps()}>
              <ScrollSyncPane group="horizontal">
                <TableHead data-cy="table-head" className={classNames(classes.header, sticky && classes.stickyHeader)} style={stickyHeader ? { width } : null}>
                  {
                    headerGroups.map(headerGroup => (
                      <MuiTableRow
                        {...headerGroup.getHeaderGroupProps()}
                        className={classNames(classes.headerRow)}
                      >
                        {headerGroup.headers.map(column => (
                          column.render(_renderColumnCell(renderColumnCell, column))
                        ))}
                      </MuiTableRow>
                    ))
                  }
                </TableHead>
              </ScrollSyncPane>
              { (data && data.length > 0) && (
                <TableBody id="table-body" data-cy="table-body" classes={{ root: dataLoading && classes.tableBody }}>
                  {
                    page.map((row, index) => prepareRow(row) || (
                      <TableRow
                        key={`table-row-${index}`}
                        onRowClick={onRowClick}
                        row={row}
                        _renderRowCell={_renderRowCell}
                        renderRowCell={renderRowCell}
                        rowActions={rowActions}
                        rowHover={rowHover}
                        classes={classes}
                        index={index}
                      />
                    ))
                  }
                </TableBody>
              )}
            </MuiTable>
            { ((!data || !data.length) && !dataLoading) && (
              <div data-cy="data-manager-empty-state">
                <EmptyState
                  style={{ padding: 150 }}
                  size={'small'}
                  iconClass={'fas fa-database'}
                  title={(
                    <FormattedMessage
                      id={'dataManager.emptyStateTitle'}
                      values={{ itemType: title && <span className={classes.emptyStateTitle}>{title}</span> }}
                    />
                  )}
                />
              </div>
            )}
          </div>
        </ScrollSyncPane>
      </ScrollSync>
      {
        (Boolean(enablePagination) && (data && data.length > 0)) && (
          <TablePagination
            rowsPerPageOptions={filteredRowsPerPage}
            count={controlledPagination ? count : data.length}
            rowsPerPage={controlledPagination ? rowsPerPage : pageSize}
            page={controlledPagination ? controlledPageIndex : pageIndex}
            labelRowsPerPage={<FormattedMessage id={'table.labelRowPerPage'} />}

            component="div"
            onChangePage={_onPageChange}
            onChangeRowsPerPage={_onChangeRowsPerPage}
          />
        )
      }
    </>
  )
}

ReactTable.defaultProps = {
  columnResizing: false,
  controlledPagination: false,
  rowsPerPageOptions: [5, 10, 25],
}

export default ReactTable
