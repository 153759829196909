import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Icon from '@material-ui/core/Icon'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import FilterIcon from '@material-ui/icons/FilterList'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  label: {
    padding: 10,
    fontSize: 12,
    marginRight: 5,
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
  },
  chipLabel: {
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    marginRight: 10,
  },
  avatar: {
    marginTop: 4,
    marginBottom: 4,
    marginLeft: 4,
    height: 24,
    width: 24,
    backgroundColor: theme.palette.common.white + '!important',
  },
  filterIcon: {
    color: theme.palette.primary.main,
  },
}))

const TableHeaderCell = (props) => {
  const { label, onClick, hasFilter, hasActiveFilter } = props
  const classes = useStyles(props)

  function _onClick(e) {
    onClick && onClick(e, label)
  }

  if (onClick && hasFilter) {
    return (
      <Chip
        avatar={hasActiveFilter ? (
          <Avatar>
            <FilterIcon className={classes.filterIcon} />
          </Avatar>
        ) : null}
        label={label}
        classes={{
          root: classes.chip,
          avatar: classes.avatar,
          label: hasActiveFilter ? undefined : classes.chipLabel,
          deleteIcon: classes.icon,
        }}
        color={hasActiveFilter ? 'primary' : undefined}
        variant={hasActiveFilter ? 'default' : 'outlined'}
        onClick={onClick}
        clickable
        onDelete={onClick}
        deleteIcon={<ArrowDropDown fontSize="small" />}
      />
    )
  }

  return (
    <div className={classNames(classes.root, onClick && classes.clickable)} onClick={_onClick}>
      <span className={classes.label}>{ label }</span>
      {
        onClick && (
          <Icon classes={{ root: classes.icon }} aria-label="filter">
            <ArrowDropDown fontSize="small" />
          </Icon>
        )
      }
    </div>
  )
}

export default TableHeaderCell
