import app from './appReducer'
import data from './dataReducer'
import graph from './graphReducer'
import pagination from './paginationReducer'
import apiCallsInProgress from './apiCallStatusReducer'

export default {
  app,
  data,
  graph,
  pagination,
  apiCallsInProgress,
}
