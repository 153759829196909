// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { hexToRgbA } from '@worldfavor/constants/colors'

import NetworkCard from '@worldfavor/components/NetworkCard'
import Button from '@material-ui/core/Button'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.common.white,
    borderColor: hexToRgbA(theme.palette.common.white, 0.8),
    marginTop: 35,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
}))

type Props = {
  network: {
    imageUrl?: string,
    description?: string,
  },
  creatorOrganization: {
    name: string,
    registrationNumber?: string,
    imageUrl?: string,
  },
  url?: string,
}

const LinkedNetworkCard = (props: Props) => {
  const { url, network, creatorOrganization, ...rest } = props
  const { imageUrl: networkImageUrl, description } = network
  const classes = useStyles(props)

  const { name, registrationNumber, imageUrl: organizationImageUrl } = creatorOrganization

  return (
    <NetworkCard
      title={name}
      subtitle={registrationNumber}
      description={description}
      thumbnailImageUrl={organizationImageUrl}
      backgroundImageUrl={networkImageUrl}
      {...rest}
    >
      <Button className={classes.button} variant="outlined">
        <FormattedMessage id="general.open" />
      </Button>
    </NetworkCard>
  )
}

export default LinkedNetworkCard
