(function () {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfInit', wfInit)
		.directive('wfInitThen', wfInitThen)
		.directive('wfInitLoader', wfInitLoader)
		.directive('wfDsBind', wfDsBind)
		.directive('wfDsCompile', wfDsCompile)
	;

	wfInit.$inject = ['$parse'];

	function wfInit($parse) {
		var directive = {
			restrict: 'A',
			priority: 10,
			controller: ['$scope','$element','$attrs','$timeout', wfInitController]
		};
		return directive;

		function wfInitController($scope, $element, $attrs, $timeout) {
			var
				vm = this,
				fn = $parse($attrs["wfInit"]),
				ifCondition,
				promise, df,
				resolveThenCallblack,
				resolveCount = 0, len
			;
			
			vm.callbacks_wfInitThen = [];
			vm.callbacks_wfInitLoader = [];
			
			// console.log($scope.$eval($attrs.wfInitIf), $scope);
			if (typeof $attrs.wfInitIf !== 'undefined' && (typeof (ifCondition = $scope.$eval($attrs.wfInitIf)) !== 'undefined' && ifCondition !== true)) {
				// console.log(ifCondition);
				// ifCondition = $scope.$eval($attrs.wfInitIf);
				// if (ifCondition)
				promise = $timeout();
				// console.log("wfInitIf");
			}
			else
			{
				vm.fnOutput = fn($scope);
				if (angular.isArray(vm.fnOutput))
				{
					df = $.Deferred();
					len = vm.fnOutput.length;
					resolveThenCallblack = function () {
						resolveCount++;
						if (resolveCount === len)
						{
							df.resolve();
						}
					};
					
					for (var i = 0; i < len; i++) {
						vm.fnOutput[i].then(resolveThenCallblack);
					}
					
					promise = df.promise();
				}
				else if (vm.fnOutput && vm.fnOutput.then)
				{
					promise = vm.fnOutput;
				}
				
			}
			
			vm.promise = promise;
			
			if (promise && promise.then) {
				promise.then(function () {
					$timeout(function () {
						// console.log(vm.callbacks_wfInitThen.length, vm.callbacks_wfInitLoader.length);
						if (vm.callbacks_wfInitThen.length)
							_.invokeMap(vm.callbacks_wfInitThen, _.call, null);
						if (vm.callbacks_wfInitLoader.length)
							_.invokeMap(vm.callbacks_wfInitLoader, _.call, null);
					}, 100); // Needed to guarantee that wf-init-loader and wf-init-then further down in the html have added callbacks before they are invoked.
				});
			}
			// console.log("wfInit", this.fnOutput);
		}
	}
	
	
	wfInitThen.$inject = ['$parse'];

	function wfInitThen($parse) {
		var directive = {
			restrict: 'A',
			require: '^wfInit',
			priority: 9,
			link: link
		};
		return directive;

		function link(scope, element, attrs, wfInitCtrl) {
			if (wfInitCtrl.promise && wfInitCtrl.promise.then)
			{
				wfInitCtrl.callbacks_wfInitThen.push(function () {
					// console.log("THEN wf-init");
					scope.$eval(attrs["wfInitThen"]);
				});
				// wfInitCtrl.fnOutput.then(function () {
				// 	// console.log("then");
				// })
			}
		}
	}
	
	
	wfInitLoader.$inject = ['$timeout'];

	// Trancludes the innerHtml of the element that wf-init-loader is added to
	// and delays the compiling of the innerHtml until the promise from wf-init has resolved.
	function wfInitLoader($timeout) {
		var directive = {
			restrict: 'A',
			require: '^wfInit',
			transclude: true,
			priority: 8,
			link: link
		};
		return directive;

		function link(scope, element, attrs, wfInitCtrl, transclude) {
			// console.log(!!wfInitCtrl.promise, attrs.wfInit)
			if (wfInitCtrl.promise && wfInitCtrl.promise.then)
			{
				// console.log("class added")
				element.addClass("loading");
				wfInitCtrl.callbacks_wfInitLoader.push(function () {
					// console.log("THEN wf-init-loader");
					
					// console.log("Transcluding", scope.vm.item.wfid);
					
					element.removeClass("loading");
					// console.log("loader > promise");
					if (attrs["wfInitLoader"].length > 0)
					{
						// console.log("loader > promise > then");
						scope.$eval(attrs["wfInitLoader"]);
						// console.log("loader then");
					}
					scope.$eval(attrs["wfInitThen"]);
					
					transclude(scope, function(clone) {
						// console.log("transclude");
						element.append(clone);
					});
				});
				// wfInitCtrl.fnOutput.then(function () {
				// });
			}
			else
			{
				element.removeClass("loading");
				// console.log("loader then (no promise)");
			}
		}
	}
	
	
	wfDsBind.$inject = ['$parse'];

	function wfDsBind($parse) {
		var directive = {
			restrict: 'A',
			link: link 
		};
		return directive;

		function link(scope, element, attrs) {
			var
				fn = $parse(attrs.wfDsBind)
			;
			element = element[0];
			element.textContent = fn(scope);
			
			scope.$on("wfObjectModified", function () {
				element.textContent = fn(scope);
			});
		}
	}
	
	
	wfDsCompile.$inject = [];

	// Trancludes the innerHtml of the element that wf-ds-compile is added to
	// and recompiles the innerHtml each time the JSData wfObject cache is modified.
	function wfDsCompile() {
		var directive = {
			restrict: 'A',
			transclude: true,
			link: link
		};
		return directive;

		function link(scope, element, attrs, ctrl, transclude) {
			var render = function () {
				transclude(scope, function(clone) {
					element.html(clone);
				});
			};
			
			render();
			// console.log("inited")
			
			scope.$on("wfObjectModified", function () {
				// console.log("wfObjectModified", attrs.wfDsCompile)
				render();
			});
		}
	}

	// angular.module('xeditable').directive('editableMeasure', ['editableDirectiveFactory',
	// 	function(editableDirectiveFactory) {
	// 		return editableDirectiveFactory({
	// 		directiveName: 'editableMeasure',
	// 		inputTpl: '<input type="text" /><select></select>'
	// 		// autosubmit: function() {
	// 		// 	var self = this;
	// 		// 	self.inputEl.bind('change', function() {
	// 		// 	self.scope.$apply(function() {
	// 		// 		self.scope.$form.$submit();
	// 		// 	});
	// 		// 	});
	// 		// }
	// 	});
	// }]);

})();