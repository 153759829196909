import React from 'react'
import Image from '@worldfavor/components/Image'
import TableElementsStyles from './TableElementsStyles'
import NewFilterDropdown from '@worldfavor/components/Filters/NewFilterDropdown'
import { ConstVars } from '@worldfavor/constants'
import { FormattedMessage } from 'react-intl'
import TooltipWithCopyToClipboard from '@worldfavor/components/Text/TooltipWithCopyToClipboard'

export function getTableColumns(options) {
  const { filters, onFilterChange, clearFilter } = options
  return [
    {
      Header: <FormattedMessage id="dataManager.product" />,
      accessor: ConstVars.ProductsAccessors.Product,
      width: 320,
    },
    {
      Header: <FormattedMessage id="dataManager.registrationNumber" />,
      accessor: ConstVars.ProductsAccessors.Organization,
      width: 160,
    },
    {
      Header: <FormattedMessage id="dataManager.vatNumber" />,
      accessor: ConstVars.ProductsAccessors.CoMapper,
      width: 150,
    },
    {
      Header: <FormattedMessage id="dataManager.glnNumber" />,
      accessor: ConstVars.ProductsAccessors.Gtin,
      width: 150,
    },
    {
      Header: <FormattedMessage id="dataManager.transparencyLevel" />,
      accessor: ConstVars.ProductsAccessors.TransparencyLevel,
      options: {
        filter: filters && filters.find(filter => filter.id === ConstVars.ProductsAccessors.TransparencyLevel),
        onFilterChange,
        clearFilter,
      },
      width: 120,
    },
    {
      Header: <FormattedMessage id="dataManager.activationStatus" />,
      accessor: ConstVars.ProductsAccessors.Activation,
      options: {
        filter: filters && filters.find(filter => filter.id === ConstVars.ProductsAccessors.Activation),
        onFilterChange,
        clearFilter,
      },
      width: 120,
    },
  ]
}

export function renderRowCell(value, row, column, cell) {
  const classes = TableElementsStyles()
  const imageUrl = _.get(row, 'original.imageUrl')

  if (column && column.id === ConstVars.OrganizationAccessors.Name) {
    return (
      <div className={classes.cell} style={{ width: column.width }}>
        <Image
          className={classes.image}
          size={'cover'}
          src={imageUrl}
        />
        <TooltipWithCopyToClipboard classes={{ root: classes.labelCell }} text={cell.value} />
      </div>
    )
  }
  if (column && column.id === ConstVars.OrganizationAccessors.Country) {
    return (
      <div className={classes.cell} style={{ width: column.width }}>
        <div
          className={`flag-icon-${cell.value.iso3166_alpha2}`}
          style={{ minWidth: 25, minHeight: 25 / (4 / 3), borderRadius: 2, marginRight: 15 }}
        />
        <TooltipWithCopyToClipboard text={cell.value.name} />
      </div>
    )
  }
  if (column && column.id === ConstVars.OrganizationAccessors.PrimaryAddress) {
    return (
      <div className={classes.cell} style={{ width: column.width }}>
        <a
          href={`https://maps.google.com/?q=${cell.value}`}
          rel="noopener noreferrer"
          target="_blank"
          style={{  overflow: 'hidden ', textOverflow: 'ellipsis' }}
        >
          <TooltipWithCopyToClipboard text={cell.value} />
        </a>
      </div>
    )
  }
  return <div className={classes.cell} style={{ width: column.width }}><TooltipWithCopyToClipboard text={value} /></div>
}

export function renderColumnCell(Header, id, column) {
  if (column.options) {
    const { filter, onFilterChange, clearFilter } = column.options

    if (filter && onFilterChange) {
      return (
        <NewFilterDropdown
          filter={filter}
          style={{ width: column.width }}
          onChange={onFilterChange}
          onClear={clearFilter}
        />
      )
    }
  }

  return <div style={{ width: column.width }}> {column.Header} </div>
}
