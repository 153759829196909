(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('ngAnimateDisabled', ngAnimateDisabled);

	ngAnimateDisabled.$inject = [ '$animate' ];
	
	function ngAnimateDisabled($animate) {
		var directive = {
			restrict: "A",
			scope: false,
			link: function (scope, element) {
				$animate.enabled(false, element);
			}
		};
		return directive;
	}
})();