import * as enums from '@worldfavor/constants/enums'

(function() {
'use strict';

	angular
		.module('wf.common')
		.controller('SupplierEducationStudentsController', SupplierEducationStudentsController);

	SupplierEducationStudentsController.$inject = ['$uibModal', '$ngBootbox', '$scope', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', '$timeout', '$q', 'apiProxy', 'wfAuth'];
	function SupplierEducationStudentsController($uibModal, $ngBootbox, $scope, dataOps, modal, wfObject, dataQuery, $translate, $timeout, $q, apiProxy, wfAuth) {
		var
			vm,
			mainStructure,
			ancestor = $scope.$parent,
			requirementWatcher,
			uiMode = enums.uiMode.view,
			organizationId,
			fulfillmentCalcXhrRequest
		;

		// console.log($scope);
		// while (!ancestor.vm) {
		// 	ancestor = ancestor.$parent;
		// 	console.log(ancestor.vm, ancestor);
		// }
		_.assign(this, $scope.$parent.vm || $scope.vm);
		vm = this;
		vm.createColleague = createColleague;
		vm.listControl = {};

		vm.isWorkMode = false;
		vm.isPrintMode = vm.context.isPrintMode;
		vm.isAggregateMode = !!vm.context.aggregateObjectType;
		vm.askToSendInviteMail = askToSendInviteMail;
		vm.askToDelete = askToDelete;
		vm.calculateFulfillment = calculateFulfillment;
		vm.calculatingFulfillment = false;
		activate();

		////////////////

		function activate() {
			// vm.users = wfObject.filter({ where: { type: 81, childType: 100, wffid: vm.supplierOrg.wfid } });

			if (vm.context.influence)
			{
				vm.influence = vm.context.influence;
				vm.organizationId = organizationId = vm.context.influence.organizationId;
				vm.mainStructure = mainStructure = wfObject.get(vm.influence.objectType + '-' + vm.influence.objectId);
				// vm.mainRequirement = vm.mainStructure.getRequirement(organizationId);

				vm.isFulfilled = vm.context.influence.fulfilled;
				// console.log(vm.mainStructure)

				// if (!vm.mainStructure.requirements.length) {
				// 	// For some strange reason the requirement is not instantly available sometimes, so we watch for it until it becomes available.
				// 	requirementWatcher = $scope.$watch(function () {
				// 		return vm.mainStructure.requirements.length;
				// 	}, function () {
				// 		if (vm.mainStructure.requirements.length)
				// 			requirementWatcher();

				// 		vm.mainRequirement = vm.mainStructure.getRequirement(organizationId);
				// 		// console.log(vm.mainStructure.requirement);
				// 		console.log(vm.mainStructure)
				// 		console.log(vm.mainStructure.requirements);
				// 		console.log(vm.mainRequirement);
				// 	});

				// 	$scope.$on('$destroy', function() {
				// 		if (requirementWatcher)
				// 			requirementWatcher();
				// 	});

				// }
				// else {
				// 	console.log(vm.mainStructure)
				// 	console.log(vm.mainStructure.requirements);
				// 	console.log(vm.mainRequirement);
				// }

				if (wfAuth.getOrganizationId() == vm.influence.organizationId) {
					vm.isWorkMode = true;
					uiMode = enums.uiMode.work;
				}
				if (wfAuth.getOrganizationId() == vm.influence.creatorOrganizationId) {
					vm.isAdminMode = true;
					uiMode = enums.uiMode.admin;
				}

				vm.mainStructureRequirementSettings = {
					uiMode: uiMode,
					forOrganizationId: vm.influence.organizationId,
					requirementOptions: [
						{
							name: "Inget",
							rule: 0,
							value: null
						},
						{
							name: "Minst 1 har genomfört utbildningen inkl. kontaktperson. Alla inbjudna personer har genomfört utbildningen.",
							rule: 9,
							value: "1"
						},
						{
							name: "Minst 2 har genomfört utbildningen inkl. kontaktperson. Alla inbjudna personer har genomfört utbildningen.",
							rule: 9,
							value: "2"
						},
						{
							name: "Minst 1 har bjudits in och genomfört utbildningen.",
							rule: 4,
							value: "1"
						},
						{
							name: "Minst 2 har bjudits in och genomfört utbildningen.",
							rule: 4,
							value: "2"
						}
					],
					onUpdated: function (updatedRequirement) {
						$timeout();

						calculateFulfillment();
					}
				};
			}
			else {
				vm.mainStructure = mainStructure = vm.item.childContent;
				vm.mainRequirement = mainStructure ? mainStructure.getRequirement() : null;
			}

			syncUsersArray();
		}

		function syncUsersArray() {
			vm.users = vm.context.aggregateQuestionAnswers
				? vm.mainStructure.filterChildren({ 'organizationId': null })
				: vm.mainStructure.filterChildren({ 'childContent.organizationId': vm.context.influence.organizationId });
		}

		function createColleague() {
			var
				modalPromise,
				jqDf = $.Deferred()
			;

			modalPromise = modal.createWithPromise({ type: 303 }, {
				title: "Bjud in kollega",
				submitCaption: "Skicka inbjudan",
				action: function (res) {
					var jqDf2 = $.Deferred();
					var userGroupCrossReference = {
						7: 1, // Bygg
						8: 2 // Motor
					}

					// Setting userGroup (according to learning.wf db) according to network (channelId)
					res.userGroup = userGroupCrossReference[vm.influence.channelId] || 1;
					res.educationId = mainStructure.conditions.objectId;
					res.influenceId = vm.influence.id;

					// Create the student
					apiProxy("suppliers.createSupplierEducationStudent", dataOps.prepareWfObject(res)).then(function (eduStudent) {
						wfObject.inject(eduStudent);

						// Create virtual relation (not saved backend)
						dataOps.createVirtualSubItemRelation(mainStructure, eduStudent, 7).then(function () { // 7 here means that subItemsKind is childrenByUser
							eduStudent.educationTitle = mainStructure.title;

							// Send the invite mail
							apiProxy("suppliers.sendEducationstudentInviteMail", dataOps.prepareWfObject(eduStudent)).then(function (mailResult) {
								// Because all invited colleagues need to have finished the education,
								// inviting a new colleague sets the fulfillment to false instantly.
								vm.influence.fulfilled = vm.isFulfilled = false;
								calculateFulfillment(); // Also calculate it for real backend.

								jqDf2.resolve(true); // Resolve and close the modal
								syncUsersArray();
								vm.listControl.compile();
							});
						});

					});

					return jqDf2.promise();
				}
			});

			modalPromise.then(function (eduStudent) {
				jqDf.resolve(eduStudent);
			})
			modalPromise.modal.closed.then(function () {
				jqDf.resolve(false);
			});

			return jqDf.promise();
		}

		function askToSendInviteMail(eduStudent) {
			var jqDf = $.Deferred()

			$ngBootbox.customDialog({
				title: "Skicka inbjudan",
				message: "Bekräfta att du vill skicka inbjudan till:<span style='font-size:14px;'><br /><br /><b>" + eduStudent.user.displayName + "</b><br />" + eduStudent.user.email + "</span>",
				onEscape: true,
				buttons: {
					cancel: {
						label: $translate.instant('Cancel'),
						className: "btn-default",
						callback: function() {
							jqDf.resolve();
						}
					},
					primary: {
						label: "Skicka nu",
						className: "btn-primary",
						callback: function() {
							eduStudent.educationTitle = mainStructure.title;
							return apiProxy("suppliers.sendEducationstudentInviteMail", dataOps.prepareWfObject(eduStudent)).then(function (res) {
								jqDf.resolve();
							});
						}
					}
				}
			});

			return jqDf.promise();
		}

		function askToDelete(eduStudent) {
			var jqDf = $.Deferred()

			$ngBootbox.customDialog({
				title: "Ta bort",
				message: "Bekräfta att du vill ta bort:<span style='font-size:14px;'><br /><br /><b>" + eduStudent.user.displayName + "</b><br />" + eduStudent.user.email + "</span>",
				onEscape: true,
				buttons: {
					cancel: {
						label: $translate.instant('Cancel'),
						className: "btn-default",
						callback: function() {
							jqDf.resolve();
						}
					},
					primary: {
						label: "OK",
						className: "btn-primary",
						callback: function() {
							return apiProxy("suppliers.deleteSupplierEducationStudent", dataOps.prepareWfObject(eduStudent)).then(function (res) {
								wfObject.eject(`81-|${vm.mainStructure.wfid}|${eduStudent.wfid}`);
								wfObject.eject(eduStudent.wfid);
								syncUsersArray();
								vm.listControl.compile();

								calculateFulfillment();
							});
						}
					}
				}
			});

			return jqDf.promise();
		}

		function calculateFulfillment() {
			var id = _.uniqueId();
			vm.calculatingFulfillment = true;
			$timeout();

			if (fulfillmentCalcXhrRequest) {
				fulfillmentCalcXhrRequest.abort();
				fulfillmentCalcXhrRequest = undefined;
			}

			fulfillmentCalcXhrRequest = apiProxy.raw("fulfillment.calculate", {
				item: dataOps.prepareWfObject(vm.influence),
				hasSigned: false
			});

			fulfillmentCalcXhrRequest.then(function (fulfillmentResult) {
				fulfillmentCalcXhrRequest = undefined;
				vm.influence.fulfilled = vm.isFulfilled = fulfillmentResult.fulfillment.fulfills;
				vm.influence.fulfillmentProgress = fulfillmentResult.fulfillment.fulfillmentProgress;
				vm.influence.fulfillmentProgressTotal = fulfillmentResult.fulfillment.fulfillmentProgressTotal;
				vm.influence.fulfillmentProgressPercentage = fulfillmentResult.fulfillment.fulfillmentProgressPercentage;
				vm.influence.containsSpecialRequirements = fulfillmentResult.fulfillment.containsSpecialRequirements;
				vm.influence.fulfillmentState = fulfillmentResult.fulfillment.fulfillmentState;
				vm.influence.isAssessmentNeeded = fulfillmentResult.fulfillment.isAssessmentNeeded;
				vm.influence.isReportingNeeded = fulfillmentResult.fulfillment.isReportingNeeded;
				vm.influence.fulfillmentStatistics = fulfillmentResult.influence.fulfillmentStatistics;

				$timeout(function () {
					if (!fulfillmentCalcXhrRequest)
						vm.calculatingFulfillment = false;
				}, 700);
			});

			return fulfillmentCalcXhrRequest;
		}
	}
})();
