// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import classNames from 'classnames'
import EmptyState from '@worldfavor/components/EmptyState'

type Props = {
  onEditButtonClick?: (event: SyntheticEvent<HTMLButtonElement>) => void,
  editable?: boolean,
}

const useStyles = makeStyles({
  root: {
    paddingTop: 26,
    paddingBottom: 36,
    paddingLeft: 40,
    paddingRight: 40,
    maxWidth: 320,
  },
  buttonIcon: {
    marginRight: 8,
  },
})

const EmptyViewerPlaceholder = (props: Props) => {
  const { onEditButtonClick, editable, ...rest } = props
  const classes = useStyles(props)
  return (
    <EmptyState
      iconClass={'fas fa-building'}
      title={
        <FormattedMessage id={`supplyChain.viewSwitcher.noOrganization.${editable ? 'edit' : 'noEdit'}.title`} />
      }
      description={
        <FormattedMessage id={`supplyChain.viewSwitcher.noOrganization.${editable ? 'edit' : 'noEdit'}.subtitle`} />
      }
      className={classes.root}
      {...rest}
    >
      {
        editable && (
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={onEditButtonClick}
            style={{ marginTop: 30 }}
          >
            <i className={classNames('fas fa-project-diagram', classes.buttonIcon)} />
            <FormattedMessage id={'supplyChain.viewSwitcher.edit'} />
          </Button>
        )
      }
    </EmptyState>
  )
}

export default EmptyViewerPlaceholder
