// @flow
import type { Dispatch } from 'redux'
import { addItems, addItem } from './dataActions'
import { addItems as addGraphItems, removeItems } from './graphActions'
import { getFlatProductSupplierNode, getFlatProductSupplierEdge } from '../selectors/productSelector'
import Api from '../api'

export const loadProductSupplierTree = (productWfid: string, networkWfid: string) => {
  return async (dispatch: Dispatch<*>) => {
    try {
      const result = await Api.get(Api.endpoints.productSupplierTree(productWfid, networkWfid))
      const { graph, content } = result.data
      dispatch(addItems(content))
      dispatch(addGraphItems(graph))
    } catch (e) {
      console.error('Could not fetch product graph data')
      throw new Error('Could not fetch product graph data')
    }
  }
}

export const loadProductSupplierList = (productWfid: string, networkWfid: string) => {
  return async (dispatch: Dispatch<*>) => {
    try {
      const result = await Api.get(Api.endpoints.productSupplierList(productWfid, networkWfid))
      const { graph, content } = result.data
      dispatch(addItems(content))
      dispatch(addGraphItems(graph))
    } catch (e) {
      console.error('Could not fetch product supplier list')
      throw new Error('Could not fetch product supplier list')
    }
  }
}

export const saveProductActivation = (productWfid: string, isActive: bool, networkWfid: string) => {
  return async (dispatch: Dispatch<*>) => {
    const result = await Api.post(Api.endpoints.productActivation, {
      productWfid,
      networkWfid,
      isActive,
    })
    dispatch(addItem(result.data))
  }
}

export const saveMappingTransparencyLevel = (mappingEntity: Object, transparencyLevel: number) => {
  return async (dispatch: Dispatch<*>) => {
    const result = await Api.post(Api.endpoints.updateExtended, {
      item: {
        ...mappingEntity,
        transparencyLevel,
      },
    })
    dispatch(addItem({ ...mappingEntity, transparencyLevel: result.data.transparencyLevel }))
  }
}

export const saveProductSupplierTree = (productWfid: string, networkWfid: string,
  addedNodes: Array<{id: string, properties: { organizationWfid: string }}>,
  addedEdges: Array<{fromId: string, toId: string}>,
  dropNodeIds: Array<string>,
  dropEdgeIds: Array<string>) => {
  return async (dispatch: Dispatch<*>) => {
    const result = await Api.post(Api.endpoints.saveProductSupplierTree, {
      productWfid,
      addNodes: addedNodes.map(({ id: temporaryId, properties: { organizationWfid  } = {} }) => ({ temporaryId, organizationWfid })),
      addEdges: addedEdges.map(({ fromId, toId }) => ({ fromId, toId })),
      dropNodeIds,
      networkWfid,
    })

    // cleanup tree
    await dispatch(removeItems([...dropNodeIds, ...dropEdgeIds]))

    // populate the tree with the new content
    const { graph, content } = result.data
    dispatch(addItems(content))
    dispatch(addGraphItems(graph))
  }
}

type SupplierFormData = {
  useExisting: boolean,
  isCoMapper: boolean,
  content: {
    organization: {
      name: string,
      registrationNumber: string,
      vatNumber: string,
      gln: string,
      image: {
        files: FileList,
      },
      location: {
        latitude: number,
        longitude: number,
        formattedAddress: string,
        address: {
          CountryCode: string,
          LongLabel: string,
          Match_addr: string,
        }
      },
      website: string,
    },
    contact: {
      firstName: string,
      lastName: string,
      title: string,
      email: string,
      phoneNumber: string,
      language: string,
    },
    // actorType: string,
  } & { wfid: string }
}

export const addCrowdSourcedOrganization = (productWfid: string, networkWfid: string, influenceWfid: string, formData: SupplierFormData) => {
  return async (dispatch: Dispatch<*>) => {
    let payload = {
      productWfid,
      influenceWfid,
      isCoMapper: formData.isCoMapper,
    }

    if (formData.useExisting) {
      payload = {
        ...payload,
        organizationWfid: formData.content.wfid,
      }
    }
    else {
      const { image, location, name, registrationNumber, vatNumber, gln, website } = formData.content.organization
      const { latitude, longitude, formattedAddress } = location
      const {
        email,
        firstName: given_name,
        lastName: family_name,
        phoneNumber: phone_number,
        title: position,
        language: culture,
      } = formData.content.contact

      let imageUploadResult
      if (image) {
        const uploadImageFormData = new FormData()
        uploadImageFormData.append('image', image.files[0])
        imageUploadResult = await Api.post(Api.endpoints.uploadImage, uploadImageFormData, {
          'Content-Type': 'multipart/form-data',
        }, null, { overrideData: true })
      }

      payload = {
        ...payload,
        user: {
          given_name,
          family_name,
          email,
          phone_number,
          position,
          culture,
        },
        organization: {
          name,
          registrationNumber,
          vatNumber,
          gln,
          image: imageUploadResult ? imageUploadResult.data : undefined,
          primaryWebsite: website,
        },
        location: {
          countryCode: location.address.CountryCode,
          latitude,
          longitude,
          formattedAddress,
        },
        // actorTypes: [formData.content.actorType],
      }
      
    }

    const result = await Api.post(Api.endpoints.crowdSourcedOrganization, payload)
    const { organization, product } = result.data
    const organizationWfid = result.data.organization.wfid
    dispatch(addItem(organization))
    dispatch(addItem(product))

    if (!payload.isCoMapper) {
      const graphResult = await Api.post(Api.endpoints.addFlatMappedProductSupplier, { productWfid, organizationWfid, networkWfid })
      const { graph, content } = graphResult.data
      dispatch(addItems(content))
      dispatch(addGraphItems(graph))
    }
  }
}

export const deleteFlatProductSupplier = (productWfid: string, organizationWfid: string, networkWfid: string) => {
  return async (dispatch: Dispatch<*>, getState: () => Object) => {
    const state = getState()
    await Api.delete(Api.endpoints.dropFlatMappedProductSupplier, { productWfid, organizationWfid, networkWfid })

    const node = getFlatProductSupplierNode(state, productWfid, organizationWfid)
    const edge = getFlatProductSupplierEdge(state, productWfid, organizationWfid)

    dispatch(removeItems([node.wfid, edge.wfid]))
  }
}

export const setActorType = (productWfid: string, nodeId: string, actorType: string, influenceWfid: string) => {
  return async (dispatch: Dispatch<*>) => {
    try {
      const result = await Api.post(Api.endpoints.organizationActorTypes, { productWfid, nodeId, actorTypeWfids: [actorType], influenceWfid })
      const { content, graph } = result.data
      
      dispatch(addItems(content))
      dispatch(addGraphItems(graph))
    } catch (e) {
      console.error('Could not set actor types', e)
      throw new Error('Could not set actor types')
    }
  }
}
