(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfExpand', wfExpand);

	wfExpand.$inject = [];
	function wfExpand() {
		var directive = {
			bindToController: true,
			controller: wfExpandController,
			controllerAs: 'expandVm',
			restrict: 'A'
		};

		return directive;
	}
	
	wfExpandController.$inject = [ "$scope", "$element", "$parse", '$attrs', '$timeout' ];
	function wfExpandController($scope, $element, $parse, $attrs, $timeout) {
		var vm = this;
		var elementToExpand = undefined;
		var contentCompiled = false;
		
		_.assign(vm, {
			//Variables
			busy: false,
			expanded: false,
			element: $element,

			//Functions
			setExpandableElement: setExpandableElement,
			toggleExpand: toggleExpand,
			setClassOnWfExpand: setClassOnWfExpand
		});

		var defaultOptions = {
			allowExpand: true,
			keepTranscludedContent: false
		}

		vm.options = _.assign(defaultOptions, $parse($attrs.wfExpand)($scope));

		if (!vm.options.allowExpand)
			$element.removeAttr("wf-expand");

		function setExpandableElement(element) {
			elementToExpand = element;
		}

		function setClassOnWfExpand(expandClass) {
			$element.addClass(expandClass);
		}

		function toggleExpand() {
			if (elementToExpand) {
				vm.expanded = !vm.expanded;

				$element.toggleClass("expanded", vm.expanded);

				if (vm.expanded) {
					if (!vm.options.keepTranscludedContent || !contentCompiled) {
						contentCompiled = true;
						vm.compileElements();
						$timeout(function() {
							elementToExpand.collapse('toggle');
						}, 100);
					}
					else
						elementToExpand.collapse('toggle');
				}
				else {
					elementToExpand.collapse('toggle');
					if (!vm.options.keepTranscludedContent) {
						$timeout(function() {
							vm.destroyElements();
						}, 200);
					}
				}
			}
			else
				console.error("wfExpand.directive.js - elementToExpand is not defined");
		}
	}
})();