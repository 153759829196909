import bootstrapReact from '../index'

const hasBugsnag = typeof window.Bugsnag !== 'undefined' && Bugsnag.leaveBreadcrumb

let apiProxyAttemptTimer
let apiProxyTotalTimer

// This method is called recursively until the api proxies have been loaded.
function waitForApiProxies(delay, callback) {
  apiProxyAttemptTimer && clearTimeout(apiProxyAttemptTimer)

  apiProxyAttemptTimer = setTimeout(() => {
    if (window.$ && $.proxies) {
      clearTimeout(apiProxyTotalTimer)

      if (hasBugsnag) {
        Bugsnag.leaveBreadcrumb('Api proxies loaded: ' + Object.keys($.proxies).join(', '))
      }

      callback && callback()

      if (hasBugsnag) {
        Bugsnag.leaveBreadcrumb('Angular bootstrapped')
      }
    }
    else {
      waitForApiProxies(100, callback)
    }
  }, delay || 0)
}

function setTimers() {
  apiProxyTotalTimer = setTimeout(() => {
    console.log('Api proxies did not load within 5 seconds.')
    apiProxyTotalTimer = setTimeout(() => {
      console.log('Api proxies did not load within 10 seconds.')
      apiProxyTotalTimer = setTimeout(() => {
      }, 50000)
    }, 5000)
  }, 5000)
}

export function bootstrapPortal() {
  if (window.wf__isOldBrowser || window.wf__embeddedWidgetMode) {
    return window.ga && window.ga('send', 'pageview', '/old-browser')
  }

  // Checks if the queryString part of the url precedes the hash part.
  // When $locationProvider.html5Mode is used, Angular doesn't properly rewrite urls when queryString part comes before the hash part.
  // This is the case when Auth0 redirects to our app from an email verification ticket url if that url was generated
  // before we switched to html5mode (early october 2016).
  // Solution: Replace the current location with a url in the correct format (swapping queryString and hash part).
  const { origin, pathname, hash, search, href } = location
  if (hash.length
    && search.length
    && href.indexOf(search) < href.indexOf(hash)) {
    location.replace(origin + pathname + hash + search)
    return
  }

  $(document).ready(() => {
    waitForApiProxies(null, bootstrapReact)
    setTimers()
  })
}
