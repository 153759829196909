import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';

	angular
		.module('wf.common')
		.component('wfAnsweringFeedItem', {
			templateUrl: 'scripts/wf/workItem/wfAnsweringFeedItem.component.html',
			controller: wfAnsweringFeedItemController,
			controllerAs: 'vm',
			require: {
				wfWorkItem: '?^wfWorkItem'
			},
			bindings: {
				parentItemComposite: '<',
				itemComposite: '<', // REQUIRED
				ticket: '<', // REQUIRED,
				index: "<", // index from vm.subItems array
				// dropdownActionsFromAttr: "<dropdownActions"
			},
		});

	wfAnsweringFeedItemController.$inject = [ '$scope', 'wfAuth', 'wfAnsweringService', '$timeout', 'wfMeasureService', '$q' ];
	function wfAnsweringFeedItemController($scope, wfAuth, wfAnsweringService, $timeout, wfMeasureService, $q) {
		const authOrgId = wfAuth.getOrganizationId();
		var
			vm = this,
			dropdownAttachments = [], // can be any value that is in attachmentsLookupDropdownActions
			otherDropdownActions = [], // can have any value that is defined in the wfDropdown.directive.js like: [ 'userData', 'edit', 'visibility', etc... ]
			customDropdownActionSpecs = {},
			customDropdownActionSpecsByType,
			attachmentsLookupDropdownActions = [ "attachActivity", "attachDocument", "attachStatement", "attachLink", "attachMedia", "attachCertificate", "attachPerson", "attachOrganization", "attachLocation", "attachCountry" ],
			onFulfillmentSyncedListener
			// attachmentsLookupDropdownActions MUST match the specifications array in the wfAnswering.service.js - getDropdownActionSpefication()
		;

		_.assign(vm, {
			user:  wfAuth.getWorldfavorUser(),
			isFirstFeedItem: vm.index === 0,
			hideItemImage: false,
			highlightItem: false,
			dropdownActions: undefined,
			creatorTimeConfig: {
				display: {}
			},

			inlineAnsweringModuleCompiler: {},
			showInlineAnsweringModule: false,
			pickerSettings: {},
			objectTypesToAttach: [], //objectTypes to attach
			customDropdownActionSpecs: customDropdownActionSpecs,

			// Functions
			closeInlineAnsweringModule: closeInlineAnsweringModule
		});

		vm.$onInit = function() {
			if (vm.itemComposite) {
				let shouldAddDropdownActions = true

				setCreatorTimeDisplaySettings();

				if (vm.ticket && vm.ticket.thirdParty
					&& vm.itemComposite.dataRelation.reportingOrganizationId !== authOrgId
				)
				{
					shouldAddDropdownActions = false
				}

				if (vm.wfWorkItem.uiMode === enums.uiMode.work || vm.wfWorkItem.uiMode === enums.uiMode.admin) {
					checkRequirement();

					if (shouldAddDropdownActions) {
						defineDropdownAttachments();
						setDropdownActions();
					}
				}
			}
			else
				console.error("itemComposite not defined")

			$scope.$on("closeAttachers", function () {
				if (!vm.isInlineAnsweringFormEmpty || vm.isInlineAnsweringFormEmpty()) {
					if (!vm.attachmentsNeeded) {
						vm.closeInlineAnsweringModule();
					}
				}
			});

			$scope.$on("checkRequirement", function () {
				checkRequirement();
			});

			onFulfillmentSyncedListener = vm.itemComposite.onFulfillmentSynced(function () {
				checkRequirement();
			})
		};

		vm.$onDestroy = function() {
			onFulfillmentSyncedListener(); // Removes listener
		}

		function checkRequirement() {
			var
				fulfillmentResult = _.get(vm, "parentItemComposite.fulfillmentResult"),
				areAttachmentsNeeded = undefined,
				latestAnswerContent = undefined
			;

			vm.attachmentsNeeded = false;

			if (fulfillmentResult) {
				// areAttachmentsNeeded = true; // <----------------- MOCKED <----------------------
				areAttachmentsNeeded = fulfillmentResult.attachmentsNeeded;
				latestAnswerContent = fulfillmentResult.latestAnswerContent;

				if (fulfillmentResult.attachmentsNeeded && latestAnswerContent) {
					if ((latestAnswerContent instanceof Array && latestAnswerContent.find(x => x.wfid === vm.itemComposite.wfid)) || latestAnswerContent.wfid === vm.itemComposite.wfid) {
						// show the form to add attachments on the answer
						if (vm.wfWorkItem.secondaryAnswerModules && vm.wfWorkItem.secondaryAnswerModules.length) {
							vm.attachmentsNeeded = true;
							showInlineAnsweringModule();
						}
					}
				}
			}
		}

		function defineDropdownAttachments() {
			vm.dropdownActions = vm.itemComposite.content.isUserDataType() ? [ "userData" ] : [ "deleteRelation" ]

			if (vm.itemComposite.type === enums.objectType.measureAnswer) {
				if (!_.get(vm.wfWorkItem, "itemComposite.loader.categoryStructure.conditions.requirementPackageSettings.disableMeasureAnswersDeletion")) {
					vm.dropdownActions = [ "deleteContent" ]
				}
				else {
					vm.dropdownActions = [ ]
				}
				// vm.getCustomFormSpecificationForEditing = () => {
				// 	return $q(resolve => {
				// 		let form = {}
				// 		wfMeasureService.getFullMeasureAnswerFormSpecification(vm.wfWorkItem.itemComposite.content, vm.wfWorkItem.itemComposite.dataRelation, form).then(formSpec => {
				// 			_.remove(formSpec.form, x => x.key === "periodSelection");
				// 			form.model.unitId = vm.itemComposite.content.unitId;
				// 			delete form.model.showNotAvailableCheckbox;
				// 			var output = {...formSpec, ...form}
				//
				// 			resolve(output)
				// 		})
				// 	})
				// };
			}

			var secondaryAnswerModules = vm.wfWorkItem.secondaryAnswerModules;

			if (secondaryAnswerModules && secondaryAnswerModules.length)
				vm.objectTypesToAttach = _.map(secondaryAnswerModules, "type");

			_.each(vm.dropdownActions, function(action) {
				if (_.includes(attachmentsLookupDropdownActions, action))
					dropdownAttachments.push(action);
				else
					otherDropdownActions.push(action);
			});

			if (vm.objectTypesToAttach && vm.objectTypesToAttach.length) { // options coming from the secondaryAnswerModules
				_.assign(customDropdownActionSpecs, wfAnsweringService.getDropdownActionSpecifications());
				customDropdownActionSpecsByType = _.mapKeys(customDropdownActionSpecs, function(spec) { return spec.type });

				_.each(vm.objectTypesToAttach, function(objectType) {
					if (customDropdownActionSpecsByType[objectType]) // if (dropdownActionToAttach)
						dropdownAttachments.push(customDropdownActionSpecsByType[objectType].actionName);
					else
						console.log("Dropdown action name not defined for objecType: ", objectType);
				});
			}

			_.each(dropdownAttachments, function(dropdownAttachmentActionName) {
				vm.objectTypesToAttach.push(customDropdownActionSpecs[dropdownAttachmentActionName].type);
			});

			vm.objectTypesToAttach = _.uniq(vm.objectTypesToAttach); // remove duplicates
			vm.pickerSettings.objectTypes = vm.objectTypesToAttach;

			if (!dropdownAttachments.length) {
				dropdownAttachments = null;
			}
			return dropdownAttachments;
		}

		function setDropdownActions() {
			if (dropdownAttachments && dropdownAttachments.length) {
				var numberOfAttachments = _.intersection(dropdownAttachments, attachmentsLookupDropdownActions).length;
				var inlineAttachmentAction = undefined;

				if (!vm.pickerSettings.objectTypes)
					vm.pickerSettings.objectTypes = [];

				if (numberOfAttachments > 1) {
					customDropdownActionSpecs["inlineAttachMultiPicker"].action = showInlineAnsweringModule;
					dropdownAttachments = [ "inlineAttachMultiPicker" ];
				}
				else if (numberOfAttachments === 1) {
					inlineAttachmentAction = getAttachmentActionByActionName(dropdownAttachments[0]);
					if (inlineAttachmentAction)
						customDropdownActionSpecs[dropdownAttachments[0]].action = inlineAttachmentAction;
				}

			}

			if (dropdownAttachments) {
				if (otherDropdownActions && otherDropdownActions.length)
					vm.dropdownActions = dropdownAttachments.concat(otherDropdownActions);
				else
					vm.dropdownActions = dropdownAttachments;
			}

			function getAttachmentActionByActionName(action) {
				var actionName = typeof action === "object" ? action.actionName : action;
				switch (actionName) {
					case "attachActivity":
					case "attachDocument":
					case "attachStatement":
					case "attachLink":
					case "attachMedia":
					case "attachCertificate":
					case "attachPerson":
					case "attachLocation":
					case "attachOrganization":
					case "attachCountry":
						return showInlineAnsweringModule
					default:
						return false;
				}
			}
		}

		function setCreatorTimeDisplaySettings() {
			switch (vm.itemComposite.type) {
				case enums.objectType.questionAnswer:
				case enums.objectType.measureAnswer:
					vm.hideItemImage = true;
					break;
				default:
					vm.hideItemImage = false;
					break;
			}

			vm.creatorTimeConfig.display.creator = false;
			vm.creatorTimeConfig.display.dateAndTime = false;
		}

		function definePickerSettings() {
			vm.pickerSettings = _.defaultsDeep({
				objectTypes: vm.objectTypesToAttach ? vm.objectTypesToAttach : [],
				relationTarget: {
					item: vm.itemComposite,
					kind: enums.subItemsKind.relatedContentByUser
				},
				ticket: vm.ticket
			}, vm.pickerSettings);
		}

		function showInlineAnsweringModule() {
			if (vm.showInlineAnsweringModule) {
				closeInlineAnsweringModule();
				// recompileInlineAnsweringModule();
			}

			if (vm.ticket && vm.ticket.thirdParty
				&& vm.itemComposite.dataRelation.reportingOrganizationId !== authOrgId
			) {
				return;
			}

			definePickerSettings();
			vm.showInlineAnsweringModule = true;
			if (!vm.highlightItem)
				vm.highlightItem = true;

			$timeout();
		}

		function closeInlineAnsweringModule() {
			if (vm.showInlineAnsweringModule) {
				vm.showInlineAnsweringModule = false;
				vm.highlightItem = false;
			}

			$timeout();
		}

		function recompileInlineAnsweringModule() {
			if (vm.inlineAnsweringModuleCompiler && typeof vm.inlineAnsweringModuleCompiler.compile === "function")
				vm.inlineAnsweringModuleCompiler.compile();

			$timeout();
		}
	}
})();
