//@flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import NewFilterMenu from '@worldfavor/components/Filters/NewFilterMenu'
import { useMenuState } from '@worldfavor/hooks/mui-hooks'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(theme => ({
  filterChip: {
    marginRight: 8,
  },
  label: {
    color: theme.palette.text.secondary,
  },
  icon: {
    color: theme.palette.text.secondary,
  },
}))

type Props = {
  id?: number,
  active?: boolean,
  label?: string,
  options?: Array<{ label: string, value: string }>,

  onChange: () => void,
  onClear?: () => void,

  filter?: {
    id: number,
    active: boolean,
    labelKey: string,
    filterOptions: Array<{ label: string, value: string }>,
  },
}

const NewFilterDropdown = (props: Props) => {
  const { label, options, onChange, active, id, filter } = props
  const [anchorEl, openFilterMenu, closeFilterMenu] = useMenuState(null)
  const classes = useStyles(props)

  const filterId = filter ? filter.id : id
  const isActiveFilter = filter ? filter.active : active
  const filterOptions = filter ? filter.filterOptions : options

  function _onChange(options) {
    onChange && onChange(options, filterId)
  }

  function renderChipLabel() {
    const { labelKey } = filter
    let labelToRender = ''

    if (labelKey) {
      labelToRender = <FormattedMessage id={`filtering.labels.${labelKey}`} />
    }

    if (label) {
      labelToRender = label
    }

    return labelToRender
  }

  return (
    <>
      <Chip
        data-cy="filter-button"
        label={renderChipLabel()}
        classes={{ root: classes.filterChip,
          deleteIcon: !isActiveFilter && classes.icon,
          label: !isActiveFilter && classes.label,
        }}
        color={isActiveFilter ? 'secondary' : 'default'}
        variant={isActiveFilter ? 'default' : 'outlined'}
        onClick={openFilterMenu}
        onDelete={openFilterMenu}
        deleteIcon={<ArrowDropDownIcon />}
        clickable
      />
      {
        filterOptions && filterOptions.length > 0 && anchorEl && (
          <NewFilterMenu
            options={filterOptions}
            onChange={_onChange}
            menuProps={{
              anchorEl,
              open: Boolean(anchorEl),
              onClose: closeFilterMenu,
            }}
            selected={isActiveFilter}
          />
        )
      }
    </>
  )
}

export default NewFilterDropdown
