import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';

	angular
		.module('wf.common')
		.service('importExportService', importExportService);

	importExportService.$inject = [ '$rootScope', '$compile', '$timeout', '$q', 'dataOperationsService', 'csvService' ];
	function importExportService($rootScope, $compile, $timeout, $q, dataOps, csvService) {
		var service = {
			exportToCsv: exportToCsv,
			stringifyJSON: stringifyJSON,
			parseJSONToString: parseJSONToString,
			extractJSONFromFile: extractJSONFromFile,
			extractXmlFromFile: extractXmlFromFile,
			downloadFile: downloadFile,
			importSingleItem: importSingleItem
		};

		return service;

		function exportToCsv(items, mapper, csvOptions) {
			return prepareForCsvExport(items, mapper).then(function(itemsToExport) {
				var data = itemsToExport.data,
					defaultCsvOptions = { 
						header: itemsToExport.header,
						filename: 'wf-csv-export.csv',

						//Properties that can be overridden when defining csvOptions in controller
						//Default values are set to:
						fieldSep: ',',
						decimalSep: '.',
						txtDelim: '"',
						quoteStrings: true,
						dateTimeFormat: "YYYY-MM-DD HH:mm",
						charset: 'utf-8',
						addByteOrderMarker: true,
					};
				
					csvOptions = _.defaults(csvOptions, defaultCsvOptions);

				csvService.stringify(data, csvOptions).then(function (stringifiedText) {
					downloadFile(stringifiedText, csvOptions.filename);
				});
			});
		}

		function prepareForCsvExport(items, mappingProperties) {
			var headerTitles = new Array(mappingProperties.length);;
			var preparedItems = new Array(items.length);
			var promises = [];

			return $q(function(resolve) {
				var queue = _.clone(mappingProperties);

				mapPropertySpec(queue.shift(), 0);

				function mapPropertySpec(propertySpec, mapPropIndex) {
					var lookUpItems;
					var promise;

					if (!propertySpec)
						return;

					if (propertySpec.byObjectType) {
						if (propertySpec.byObjectType === enums.objectType.country) {
							promise = dataOps.getSubItems("71-13804", enums.subItemsKind.children).then(function(countries) {
								lookUpItems = _.chain(countries).keyBy('childId').mapValues('childContent.name').value();
							});
							promises.push(promise);
						}
					}
					else if (typeof propertySpec.resolve === "function") {
						promises.push(propertySpec.resolve());
					}

					if (promises.length) {
						$q.all(promises).then(function() {
							handleItems();
						})
					}
					else {
						handleItems();
					}

					function handleItems() {
						var preparedObject;
						_.each(items, function(item, index) {
							var key, value;

							preparedObject = !preparedItems[index] ? preparedItems[index] = {} : preparedItems[index];

							if (typeof propertySpec === 'object') {
								if (index == 0) {
									if (propertySpec.header)
										headerTitles[mapPropIndex] = propertySpec.header;
									else
										console.error("Header is undefined");
								}

								if (propertySpec.source) {
									if (typeof propertySpec.source === 'function') {
										key = propertySpec.header;
										value = propertySpec.source(item);
									}
									else {
										key = propertySpec.source;
										value = _.get(item, propertySpec.source);

										if (lookUpItems)
											value = lookUpItems[value];
									}
								}
								else console.error("You are missing the source propertySpec");
							}
							else if (typeof propertySpec === 'string') {
								if (index == 0)
									headerTitles[mapPropIndex] = propertySpec.header;

								key = propertySpec;
								value = _.get(item, propertySpec);
							}

							if (key) {
								preparedObject[key] = typeof value === "string" ? _.trim(value) : value;
							}
							else {
								console.error("Key/value could not be extracted");
							}
						});

						if (queue.length === 0)
							resolve({ data: preparedItems, header: headerTitles });
						else
							mapPropertySpec(queue.shift(), mapPropIndex + 1);
					}
				}
			});
		}

		function stringifyJSON(data) {
			if (!data) {
				console.error('No data to stringify');
				return;
			}

			if (typeof data === "object")
				data = JSON.stringify(data, undefined, 4);
			else 
				console.error("Cannot stringify data. Data is not an object");

			return data;
		}

		function parseJSONToString(string) {
			if (string !== null && isJSONString(string))
				return JSON.parse(string);
			else
				return false;


			function isJSONString(string) {
				try {
					JSON.parse(string);
				} 
				catch (e) {
					return false;
				}
				return true;
			}
		}

		function extractJSONFromFile(file) {
			var deferred = $q.defer();
			var reader = new FileReader();
			var data = undefined;
			reader.onload = function() {
				data = parseJSONToString(reader.result);
				if (typeof data === "object") {
					data.fileName = file.name;
					deferred.resolve(data);
				}
				else {
					deferred.reject(data);
				}
			};	
			reader.readAsText(file);
			
			return deferred.promise;
		}

		function extractXmlFromFile(file) {
			var deferred = $q.defer();
			var reader = new FileReader();
			var data = undefined;

			if (file) {
				reader.readAsText(file);
				reader.onload = function() {
					data = reader.result;
					if (typeof data === "string") {
						deferred.resolve(data);
					}
					else {
						deferred.reject(data);
					}
				};	
			}
			
			return deferred.promise;
		}

		function downloadFile(data, filename, customBlobType) {
			var blobType = "application/json";
			if (customBlobType)
				blobType = customBlobType;
			var blob = new Blob([ data ], { type: blobType });
			if (window.navigator.msSaveOrOpenBlob)  // IE hack
				window.navigator.msSaveBlob(blob, filename);
			else {
				var dummyElement = window.document.createElement("a");
				dummyElement.href = window.URL.createObjectURL(blob);
				dummyElement.download = filename;
				document.body.appendChild(dummyElement);
				dummyElement.click();  // IE: "Access is denied"
				document.body.removeChild(dummyElement);
			}
		}

		function importSingleItem(item) {
			var deferred = $q.defer();
			
			dataOps.create(item).then(function(createdItem) {
				deferred.resolve(createdItem);
			}, function(rejectedItem) {
				deferred.reject(rejectedItem);
				console.error("Could not import an item - ", rejectedItem)
			});

			return deferred.promise;
		}
	}
})();
