(function() {
'use strict';

	angular
		.module('wf.common')
		.controller('SupplierCategoryController2', SupplierCategoryController2);

	SupplierCategoryController2.$inject = ['$scope', '$element', '$attrs', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', '$timeout', '$q'];
	
	function SupplierCategoryController2($scope, $element, $attrs, dataOps, modal, wfObject, dataQuery, $translate, $timeout, $q) {
		var
			vm = this,
			mainCategory
		;
		_.assign(this, $scope.$parent.vm);
		
		$scope.modal = modal;
		$scope.wfObject = wfObject;
		$scope.dataQuery = dataQuery;

		_.assign(vm, {
			loaded: false,
			filterBarList: {},
			filterByCategory: filterByCategory,
			selectedCategory: null,
			openFilteredUsersModal: openFilteredUsersModal,
			collectionQuery: { type: null },
			allSuppliers: [],
			pickedCategorySuppliers: [],
			filteredSuppliers: [],
			createSupplier: function () {
				var df = $q.defer();
				modal.createWithRelation({ objectType: 101, dataRelationOptions: { kind: 7, item1: mainCategory } }).then(function () {
					setTimeout(function () {
						if (vm.selectedParentId == mainCategory.id)
							filterByCategory(vm.selectedCategory);
							
						df.resolve();
					}, 1000);
				});
				return df.promise;
			}
		});
		
		mainCategory = vm.item.childContent;

		activate();

		////////////////

		function activate() {
			$scope.$watch("vm.search", function () {
				applySearchFilter();
				
				if (vm.listControl)
					vm.listControl.compile();
			});
			
			loadStructureDependencies().then(function () {
				vm.allSuppliers = vm.filteredSuppliers = vm.pickedCategorySuppliers = _.orderBy(mainCategory.childs, [ "createdAt" ], [ "desc" ]);
				// console.log(vm.filteredSuppliers);
				
				vm.selectedCategory =  mainCategory;
				vm.selectedParentId = mainCategory.id;
				vm.collectionQuery = {
					where:
					{
						type: 73,
						parentType: 71,
						parentId: mainCategory.id,
						childType: 101,
						childId: { in: dataQuery.mapProperty('childId',
							{
								where:
								{
									type: 73,
									childType: 101,
									parentType: 71,
									parentId: vm.selectedParentId
								}
							})
						}
					}
				};
				vm.organizationIds = dataQuery.mapProperty('childId', vm.collectionQuery);
				vm.chartSource = wfObject.filter({
					where:
					{
						type: 71,
						id:
						{
							'in': dataQuery.mapProperty('objectId',
							{
								where:
								{
									type: 13,
									objectType: 71,
									organizationId:
									{
										'in': vm.organizationIds
									}
								}
							})
						}
					}
				});
				
				vm.categoryFilterButtons = wfObject.filter({
					where:
					{
						type: 71,
						id:
						{
							// Get all categories on the organizations, except the current category
							'!==': mainCategory.id,
							'in': dataQuery.mapProperty('parentId',
							{
								where:
								{
									type: 73,
									parentType: 71,
									childType: 101,
									childId:
									{
										// Get all organizations in the current category
										'in': dataQuery.mapProperty('childId',
										{
											where:
											{
												type: 73,
												childType: 101,
												parentType: 71,
												parentId: mainCategory.id
											}
										})
									}
								}
							})
						}
					}
				});
				
				vm.categoryFilterButtons.unshift({ title: $translate.instant('All'), id: mainCategory.id });
				
				$timeout(function () {
					vm.loaded = true;
					vm.listControl.compile();
				}, 100);
			});
		}
		
		function loadStructureDependencies() {
			var jqDf = $.Deferred();
			
			// Load parent/parent/parent (Grupp > Kategorier > Kategori)
			//                                 |
			//             	Gets the dataRelation object of the group
			dataOps.getObjectByPath(mainCategory, "parent/parent").then(function (supplierGroupDataRelation) {
				vm.networkId = supplierGroupDataRelation.parentContent.conditions.networkId; // parentContent is a group
				// TODO: Make sure that the below code only loads what is necessary
				
				// Load children of the group with depth 2 (will load Kategorier and Krav structures)
				dataOps.getSubItems(supplierGroupDataRelation.parentContent, 7, { childrenLoadDepth: 2 }).then(function (res) {
					jqDf.resolve();
					// dataOps.getObjectByPath(supplierGroupDataRelation.parentContent, "parent/childByUser:1/childByUser").then(function () {
					// });
				});
			});
			
			return jqDf.promise();
		}

		var
			escapeCharacters = /[-\/\\^$*+?.()|[\]{}]/g,
			whiteSpaces = /\s+/g
		;
		
		function applySearchFilter() {
			var
				regexp, res, words,
				searchString = vm.search
			;
			
			if (searchString && searchString !== "")
			{
				words = _.uniq(searchString.trim().replace(whiteSpaces, ' ').replace(escapeCharacters, '').split(' '));
				regexp = new RegExp("(" + words.join(")|(") + ")","gi");
				vm.filteredSuppliers = _.filter(vm.pickedCategorySuppliers, function (x) {
					if (x.childContent && x.childContent.title)
					{
						res = x.childContent.title.match(regexp);
						return res && _.uniq(res).length === words.length;
					}
					else if (x.childContent && x.childContent.name)
					{
						res = x.childContent.name.match(regexp);
						return res && _.uniq(res).length === words.length;
					}
					else if (x.childContent && x.childContent.text)
					{
						res = x.childContent.text.match(regexp);
						return res && _.uniq(res).length === words.length;
					}
					else if (x.childContent && x.childContent.description)
					{
						res = x.childContent.description.match(regexp);
						return res && _.uniq(res).length === words.length;
					}
					else
						return false;
				});
			}
			else
				vm.filteredSuppliers = vm.pickedCategorySuppliers;
		}
		
		function filterByCategory(category) {
			// return;
			vm.selectedCategory = category;
			vm.selectedParentId = (vm.selectedParentId == category.id ? mainCategory.id : category.id);
			vm.collectionQuery = {
				where:
				{
					type: 73,
					parentType: 71,
					parentId: mainCategory.id,
					childType: 101,
					childId: { in: dataQuery.mapProperty('childId',
						{
							where:
							{
								type: 73,
								childType: 101,
								parentType: 71,
								parentId: vm.selectedParentId
							}
						})
					}
				}
			};
			vm.organizationIds = dataQuery.mapProperty('childId', vm.collectionQuery);
			vm.chartSource = wfObject.filter({
				where:
				{
					type: 71,
					id:
					{
						'in': dataQuery.mapProperty('objectId',
						{
							where:
							{
								type: 13,
								objectType: 71,
								organizationId:
								{
									'in': vm.organizationIds
								}
							}
						})
					}
				}
			});
			
			vm.filteredSuppliers = vm.pickedCategorySuppliers = _.orderBy(wfObject.filter(vm.collectionQuery), [ "createdAt" ], [ "desc" ]);

			
			applySearchFilter();
			console.log("compiling", vm.filteredSuppliers)
			vm.listControl.compile();
		}
		
		function openFilteredUsersModal(structureItem) {
			modal.openItem({
				meta: {
					title: structureItem.title,
					settings: {
						templateId: 21,
						childrenTemplateId: 7
					}
				},
				viewMode: true,
				highlightInfluence: true,
				onlyFulfilled: true,
				structureId: structureItem.id,
				query:
				{
					where:
					{
						type: 73,
						childType: 101,
						parentType: 71,
						parentId: vm.selectedParentId,
						childId:
						{
							'in': dataQuery.mapProperty('organizationId', {
								where:
								{
									type: 13,
									objectType: 71,
									objectId: structureItem.id,
									organizationId:
									{
										'in': vm.organizationIds
									}
								}
							})
						}
					}
				}
			})
		}
	}
})();