import { useState } from 'react'
import weburlRegex from '@worldfavor/utils/regex/weburl'
import emailRegex from '@worldfavor/utils/regex/email'

export function useStateValidation<T>(initialState: T, validator: ((value: T) => boolean) = (() => true)) {
  const [value, setValue] = useState(initialState)
  const [isValid, setIsValid] = useState(true)

  function setValidatedValue(newValue) {
    setValue(newValue)

    let finalValue = value
    if (typeof newValue === 'function') {
      finalValue = newValue(value)
    }
    setIsValid(validator(finalValue))
  }

  return [value, setValidatedValue, isValid]
}

function validateByField(value, type) {
  switch (type) {
    case 'url':
      return typeof value === 'string' && new RegExp(weburlRegex, 'i').test(value)
    case 'email':
      return typeof value === 'string' && new RegExp(emailRegex).test(value)
    default:
      return true
  }
}

export function useFormValidation(
  initialState = {},
  descriptors: {
    [string]: {
      validator?: (value: any, formData?: { [string]: any }) => boolean,
      required?: boolean,
      type?: string,
    }
  } = {},
) {
  const [formData, setFormData] = useState(initialState)
  const [validation, setValidation] = useState({})

  function validateField(fieldKey, value) {
    const { validator, required, type } = descriptors[fieldKey] || {}

    let isValid = true
    if (validator) {
      isValid = validator(value, formData)
    }
    else if (type && Boolean(value)) {
      isValid = validateByField(value, type)
    }

    if (required) {
      isValid = isValid && Boolean(value)

      // ignore whitespaces if the value is a string
      if (typeof value === 'string') {
        isValid = isValid && Boolean(value.trim())
      }
    }

    return isValid
  }

  function validateForm() {
    const validationKeys = Object.keys(descriptors)
    let isFormValid = true
    let i = 0
    let newValidation = {}
    while (i < validationKeys.length) {
      const fieldKey = validationKeys[i]
      const value = formData[fieldKey]

      const isFieldValid = validateField(fieldKey, value)

      newValidation = {
        ...newValidation,
        [fieldKey]: !isFieldValid,
      }

      isFormValid = isFormValid && isFieldValid

      i++
    }

    setValidation(newValidation)
    return isFormValid
  }

  function setField(fieldKey) {
    return function _setField(newValue) {
      let finalValue = newValue
      if (typeof newValue === 'function') {
        finalValue = newValue(formData[fieldKey])
      }

      setFormData({ ...formData, [fieldKey]: finalValue })

      setValidation({
        ...validation,
        [fieldKey]: !validateField(fieldKey, finalValue),
      })
    }
  }

  return [formData, setField, validation, validateForm, setFormData]
}
