(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfStickyBreadcrumbs', StickyBreadcrumbs);

	StickyBreadcrumbs.$inject = [];

	function StickyBreadcrumbs() {

		var directive = {
			//bindToController: true,
			//controller: ControllerController,
			//controllerAs: 'vm',
			link: link,
			restrict: 'A',
			scope: {
			}
		};
		return directive;
		
		function link(scope, element, attrs) {
			return;
			var breadcrumbsContainer = $("<div class='stickyBreadcrumbs-container' />").appendTo($("body"));

			setTimeout(function() {
				headingBreadcrumbs({});
			}, 2000);

			function headingBreadcrumbs(options) {
				var ARTICLE = element;
				var CONTAINER = breadcrumbsContainer;
				var END_OF_ARTICLE = ARTICLE.offset().top + ARTICLE[0].offsetHeight;
				// var FADING_DISTANCE = options.fadingDistance || 100;
				// var OFFSET_END_OF_SCOPE = options.offsetEndOfScope || 100;
				var DEPTH = 6;

				init();

				function init() {
					var headingsPerLevel = getHeadingsPerLevel();
					makeBreadcrumbs(headingsPerLevel);

					window.addEventListener('scroll', function () {
					makeBreadcrumbs(headingsPerLevel);
					});
				}

				function makeBreadcrumbs(headingsPerLevel) {
					
					CONTAINER.html(getBreadcrumbs(headingsPerLevel, scrollTop()));
				}

				function scrollTop() {
					return window.scrollY;
				}

				function getHeadingsPerLevel() {
					var headingsPerLevel = [];

					for (var level = 1; level <= DEPTH; level++) {
						var headings = ARTICLE.find('.level' + level);
						//Sorting headings by their offset from the top of the deocument in a decending order. 
						 headings = headings.sort(function (a, b) {
							 return $(b).offset().top - $(a).offset().top });
						headingsPerLevel.push(headings);
					}


					return headingsPerLevel;
				}

				function getBreadcrumbs(headingsPerLevel, scrollTop) {
					var breadcrumbs = [];
					var headingsInScope = findHeadingsInScope(headingsPerLevel, scrollTop);

					headingsInScope.forEach(function (heading) {
						//var opacity = calculateOpacity(heading.beginningOfScope, heading.endOfScope, scrollTop);

						var html = '<a href="#' + heading.id
							+ '" class="' + heading.tag
							//+ '" style="opacity:' + opacity
							//+ '; pointer-events: ' + (opacity > 0.5 ? 'auto' : 'none')
							+ '">' + heading.text
							+ '</a>';

						breadcrumbs.push(html);
					});

					return breadcrumbs.join('');
				}

				function findHeadingsInScope(headingsPerLevel, scrollTop) {
					var headingsInScope = [];
					var previousHeadingOffset = 0;

					headingsPerLevel.forEach(function (headings, level) {
					var heading = _.find(headings, function (node) {
						node = $(node);			 
						return (node.offset().top < scrollTop) && (node.offset().top > previousHeadingOffset);
					});

				


					if (heading) {
						heading = $(heading);
						var nextHeadingOfSameLevel = headingsPerLevel[level][headingsPerLevel[level].index(heading[0]) - 1];
						var currentHeadingOfHigherLevel = headingsInScope[headingsInScope.length - 1];
						var endOfScope = calculateEndOfScope(nextHeadingOfSameLevel, currentHeadingOfHigherLevel);

						headingsInScope.push({
						id: heading[0].id,
						tag: heading[0].tagName.toLowerCase(),
						text: heading[0].textContent,
						beginningOfScope: heading.offset().top + heading[0].offsetHeight,
						endOfScope: endOfScope
						});

						previousHeadingOffset = heading.offset().top;
					}
					else {
						previousHeadingOffset = END_OF_ARTICLE;
					}
					});
					return headingsInScope;
				}

				function calculateEndOfScope(nextHeadingOfSameLevel, currentHeadingOfHigherLevel) {
					var endOfScope;

					if (currentHeadingOfHigherLevel) {
					if (nextHeadingOfSameLevel) {
						endOfScope = Math.min($(nextHeadingOfSameLevel).offset().top, currentHeadingOfHigherLevel.endOfScope);
					}
					else {
						endOfScope = currentHeadingOfHigherLevel.endOfScope;
					}
					}
					else {
					if (nextHeadingOfSameLevel) {
						endOfScope = $(nextHeadingOfSameLevel).offset().top
					}
					else {
						endOfScope = END_OF_ARTICLE;
					}
					}

					return endOfScope;
				}

				// function calculateOpacity(top, bottom, scrollTop) {
				// 	var diffTop = scrollTop - top;
				// 	var opacityTop = diffTop > FADING_DISTANCE ? 1 : diffTop / FADING_DISTANCE;

				// 	var diffBottom = bottom - scrollTop - OFFSET_END_OF_SCOPE;
				// 	var opacityBottom = diffBottom > FADING_DISTANCE ? 1 : diffBottom / FADING_DISTANCE;
				// 	return Math.min(opacityTop, opacityBottom);
				// }
			}

			// //Another JS File

			// var anchorForId = function (id) {
			// var anchor = document.createElement('a');
			// anchor.className = 'header-link';
			// anchor.href = '#' + id;
			// anchor.innerHTML = '<i class=\'fa fa-link\'></i>';
			// return anchor;
			// };

			// var linkifyAnchors = function (level, containingElement) {
			// var headers = containingElement.getElementsByTagName('h' + level);
			// for (var h = 0; h < headers.length; h++) {
			// 	var header = headers[h];

			// 	if (typeof header.id !== 'undefined' && header.id !== '') {
			// 	header.appendChild(anchorForId(header.id));
			// 	}
			// }
			// };

			// document.addEventListener('DOMContentLoaded', function () {
			// var post = document.getElementsByClassName('post')[0];

			// if (post) {
			// 	for (var level = 1; level <= 6; level++) {
			// 	linkifyAnchors(level, post);
			// 	}
			// }
			// });
		}
	}
})();