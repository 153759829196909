// @flow
import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import MaxWidthGrid from '@worldfavor/components/MaxWidthGrid'

import { getNodeFromNodeId, getSubNodesFromNodeId } from '../selectors/dataSelector'
import { loadItem } from '../actions/dataThunk'
import { ObjectType } from '@worldfavor/constants/enums'
import InfluenceHeader from '../components/Influence/InfluenceHeader'
import InfluenceInfoBox from '../components/Influence/InfluenceInfoBox'
import InfluencesTable from '../components/Influence/InfluencesTable'
import Loading from './Loading'
import ReportingProductSearchTable from '../components/Product/ReportingProductSearchTable'
import { get } from "lodash"

const styles = {
  root: {
  },
  header: {
    display: 'flex',
    marginTop: 30,
  },
  influenceHeader: {
    flex: 4,
    marginRight: 20,
  },
  influenceInfoBox: {
    flex: 1,
    marginTop: 35,
  },
  content: {
    marginTop: 50,
  },
}

const Influence = ({
  classes,
  influenceWfid,
  loadItem,
  influence,
  influenceContent,
  influenceContentSubItems,
  influenceCreatorOrganization,
}) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function fetchData() {
      const [type, id] = influenceWfid.split('-')
      setLoading(true)
      try {
        await loadItem(id, type)
      } catch (e) {
        // TODO handle error
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [influenceWfid])

  if (loading || !influence) {
    return <Loading />
  }

  const { description, imageUrl, guidance, relatedContent, conditions } = influenceContent
  const isNotFulfillable = get(influenceContent, 'conditions.requirementPackageSettings.isTool') || get(influenceContent, 'conditions.clientSettings.componentType') || get(influenceContent, 'conditions.templateId') === 80
  const { objectTypes, clientSettings = {} } = conditions
  const { componentType } = clientSettings
  const objectType = objectTypes && objectTypes[0]
  const tableComponents = {
    [ObjectType.productService]: (
      <ReportingProductSearchTable
        influenceWfid={influenceWfid}
        data={influenceContentSubItems.filter(x => x.edge.organizationId === influence.organizationId)}
      />
    ),
    [ObjectType.influence]: <InfluencesTable influenceWfid={influenceWfid} data={influenceContentSubItems} />,
  }
  const { imageUrl: creatorOrgImageUrl, name: creatorOrgName } = influenceCreatorOrganization || {}
  const { comment, createdAt, title, creatorUser = {} } = influence

  let content = null
  if (componentType === 'table') {
    content = tableComponents[objectType] || null
  }

  return (
    <Grid container justify={'center'}>
      <MaxWidthGrid item xs={12} sm={12}>
        <div className={classes.root}>
          <div className={classes.header}>
            <div className={classes.influenceHeader}>
              <InfluenceHeader
                senderImageUrl={creatorOrgImageUrl}
                senderName={creatorOrgName}
                senderDate={createdAt}
                title={title}
                description={description}
                imageUrl={imageUrl}
              />
            </div>
            <div className={classes.influenceInfoBox}>
              <InfluenceInfoBox
                className={classes.influenceInfoBox}
                guidance={guidance}
                comment={comment && {
                  text: comment,
                  authorName: creatorUser.name,
                  authorImageUrl: creatorUser.imageUrl,
                }}
                attachments={relatedContent ? relatedContent.map(relation => ({
                  wfid: relation.wfcid,
                  title: relation.childContent.title,
                  fileUrl: relation.childContent.url,
                })) : undefined}
                deadline={!isNotFulfillable && influence.fulfillmentDueAt}
              />
            </div>
          </div>

          <div className={classes.content}>
            {content}
          </div>
        </div>
      </MaxWidthGrid>
    </Grid>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { influenceWfid } = ownProps.match.params
  const influence = getNodeFromNodeId(state, influenceWfid)
  const influenceContent = influence ? getNodeFromNodeId(state, influence.wfcid) : undefined
  const influenceContentSubItems = influenceContent ? getSubNodesFromNodeId(state, influenceContent.wfid) : undefined
  const influenceCreatorOrganization = influence ? getNodeFromNodeId(state, influence.creatorOrganizationWfid) : undefined

  return {
    influenceWfid,
    influence,
    influenceContent,
    influenceContentSubItems,
    influenceCreatorOrganization,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loadItem,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Influence))
