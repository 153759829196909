import * as enums from '@worldfavor/constants/enums'

/**
 * @ngdoc component
 * @name wfObjectViewerBox
 * 
 * @description 
 * This component has a purpose to display all the boxes that are inside boxesDefinition (objectViewer.controller.js).
 * It takes care of displaying the appropriate title, dropdown button (+ button) and of course the entire box (depending on the configuration and setting in boxesDefinition)
 * 
 * @param {Object=} box 
 * @param {Object=} obj-viewer-vm 
 * @param {WfObject} item
 * 
 * @example 
 * 
 * <wf-object-viewer-box box="vm.box" obj-viewer-vm="vm"></wf-object-viewer-box>
 * 
 */

(function () {
	'use strict';

	angular
		.module ('wf.common')
		.component ('wfObjectViewerBox', wfObjectViewerBox());

	function wfObjectViewerBox() {

		var component = {
			bindings: {
				box: '=',
				item: '=',
				objViewerVm: '='
			},
			controller: wfObjectViewerBoxController,
			controllerAs: 'boxVm',
			templateUrl: "scripts/wf/objectViewer/objectViewerBox.component.html"
		};
		
		return component;
	}

	wfObjectViewerBoxController.$inject = [ "$scope", "$attrs", 'modalService', '$translate', 'dataOperationsService', '$timeout', 'wfObject' ];
	function wfObjectViewerBoxController($scope, $attrs, modal, $translate, dataOps, $timeout, wfObject) {
		var 
			$ctrl = this,
			box,
			kind
		;

		_.assign($ctrl, {
			//Variables
			hasDropdown: true,
			hasAddAction: false,
			isExpandable: false,
			everythingLoaded: false,
			applyRightSideStyle: false,
			isUserData: $ctrl.objViewerVm.item.isUserDataType && $ctrl.objViewerVm.item.isUserDataType(),
			isUserInbox: $ctrl.objViewerVm.isUserInbox,
			emptyStateSearchText: $translate.instant("modules.objectViewer.emptyStateSearch"),

			//Functions
			openCreatorAndPicker: openCreatorAndPicker,
			getChildrenOfItems: getChildrenOfItems,
			onAddButtonClick: onAddButtonClick,
			onSearch: onSearch

		});
	
		box = $ctrl.box;
		kind = box.kind;

		activate();

		function activate() {
			if (box != undefined) {
				box.currentItemTypeSettings = box.currentItemTypeSettings || {};
				$ctrl.hasDropdown = !box.addButtonAction && !box.currentItemTypeSettings.addButtonAction && box.showAttachInfoDropdown;
				$ctrl.hasAddAction = !$ctrl.hasDropdown && (box.pickerSettings || box.currentItemTypeSettings.pickerSettings || box.addButtonAction || box.currentItemTypeSettings.addButtonAction);
				
				if (box.sideBar) {
					$ctrl.applyRightSideStyle = true;
						if (box.kind && box.kind == enums.subItemsKind.parentsByUser)
							$ctrl.hasDropdown = false;
				}
				if (box.items.length > 0 && box.allowExpanding && !box.sideBar && !(box.currentItemTypeSettings.allowExpanding === false))
					$ctrl.isExpandable = true;

				if ($ctrl.isExpandable) {
					box.itemsOptions = _.chain(box.items).keyBy("wfid").mapValues(function(item) {
						return {
							expanded: false,
							isChildrenLoading: false,
							isChildrenLoaded: false,
							expandItem: _.noop,
							collapseItem: _.noop
						};
					}).value();
				}
			}

			$scope.$on("dropdownActionExecuted", function ($event, operation, item, dataRelation) {
				if (operation == 'delete') {
					$ctrl.objViewerVm.populateBoxItems(box)

					if (box.id === "parentsByUser_forItemWithinNetwork") {
						item.type = enums.objectType.historicDataRelation;
						item.deletedAt = moment().format('YYYY-MM-DD, HH:mm:ss')
						item.wfid = item.type + "-" + item.id;
						wfObject.inject(item);

						var historicCategoriesBox = _.find($ctrl.objViewerVm.boxes, { id: "historicParentsByUser_forItemWithinNetwork" });
						if (historicCategoriesBox)
							$ctrl.objViewerVm.populateBoxItems(historicCategoriesBox);
					}

					$timeout();
				}
				if (operation == 'attach' && item === $ctrl.objViewerVm.item) {
					$ctrl.objViewerVm.populateBoxItems(box);
					$timeout();
				}
			});
		}

		function openCreatorAndPicker(type) {
			modal.openCreatorAndPicker(type ? { objectTypes: [ type ], relationTarget: { item: $ctrl.objViewerVm.item, kind: kind } } : box.pickerSettings).closed(function() {
				$ctrl.objViewerVm.populateBoxItems(box)
				$timeout();
			});
		}

		function onAddButtonClick() {
			if (typeof box.currentItemTypeSettings.addButtonAction === "function")
				box.currentItemTypeSettings.addButtonAction(box, $ctrl.objViewerVm.item);
			else if (typeof box.addButtonAction === "function")
				box.addButtonAction($ctrl.objViewerVm.item);
			else
				openCreatorAndPicker();
		}
		
		function getChildrenOfItems() {
			if (box.toggleExpanded) {
				_.invokeMap(box.itemsOptions, "expandItem");
			}
			else {
				_.invokeMap(box.itemsOptions, "collapseItem");
			}
		}

		function onSearch(searchString, items, searchActive) {
			$ctrl.searchActive = searchActive;
		}
	}

} ());
