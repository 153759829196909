import * as React from 'react'
import { isIE } from '@worldfavor/utils'
import { getCurrentSourceType } from './sourceTypeRegistry'

class Droppable extends React.Component {

  childEnterCount = 0

  state = {
    canDrop: false,
    isOver: false,
  }

  getTypes = (ev) => {
    if (isIE()) {
      const type = getCurrentSourceType()
      return type ? [type.toLowerCase()] : []
    }
    else {
      return ev.dataTransfer.types
    }
  }

  onDragOver = (ev) => {
    ev.preventDefault()
  }

  onDragEnter = (ev) => {
    ev.preventDefault()
    const { targets } = this.props
    const types = this.getTypes(ev)

    let canDrop = false
    if (typeof targets === 'string') {
      canDrop = types.includes(targets.toLowerCase())
    }
    else if (Array.isArray(targets)) {
      canDrop = targets.map(target => target.toLowerCase)
        .some(target => types.includes(target))
    }

    if (this.childEnterCount === 0) {
      this.setState({ isOver: true, canDrop })
    }
    this.childEnterCount++
  }

  onDragLeave = (ev) => {
    ev.preventDefault()
    this.childEnterCount--
    if (this.childEnterCount === 0) {
      this.setState({ isOver: false, canDrop: false })
    }
  }

  onDrop = (ev) => {
    ev.preventDefault()
    const { onDrop, targets } = this.props
    const types = this.getTypes(ev)

    this.childEnterCount = 0

    let dataKey = null
    if (typeof targets === 'string') {
      dataKey = types.find(type => type === targets.toLowerCase())
    }
    else if (Array.isArray(targets)) {
      dataKey = targets.map(target => target.toLowerCase)
        .find(target => types.includes(target))
    }

    if (dataKey && isIE()) {
      dataKey = 'Text'
    }

    if (dataKey) {
      const data = ev.dataTransfer.getData(dataKey)
      onDrop && onDrop(data)
    }
    this.setState({ isOver: false, canDrop: false })
  }

  render() {
    const { children } = this.props
    const { canDrop, isOver } = this.state
    return (
      <div
        onDragOver={this.onDragOver}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
      >
        {children(canDrop, isOver)}
      </div>
    )
  }
}

export default Droppable
