import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import Button from '@material-ui/core/Button'
import { PanZoom } from 'react-easy-panzoom'
import TreeView from '../Tree/TreeView'
import OrganizationMap from '../Map/OrganizationMap'
import MappingStatus from '../MappingStatus'
import { useDialogState } from '@worldfavor/hooks'
import DeleteOrganizationNodeDialog from './DeleteOrganizationNodeDialog'
import { addTransparency } from '@worldfavor/constants/colors'
import CoMapperStatus from '../CoMapperStatus'
import { useSelector } from 'react-redux'
import { getAvailableActorTypes } from '../../../selectors/productSelector'
import ActorTypesDialog from '@worldfavor/portal/components/SupplyChain/ActorTypesDialog'

const miniMapSize = {
  width: 220,
  height: 220,
}

const useStyles = makeStyles(theme => ({
  rightPanel: {
    flex: 1,
    minWidth: 800,

    display: 'flex',
    flexDirection: 'column',
  },
  panelContainer: {
    flex: 1,
    position: 'relative',
  },

  toolbar: {
    backgroundColor: theme.palette.primary.main,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    boxShadow: theme.shadows[1],
    marginBottom: 2,
  },

  toolbarTitle: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),

    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
  },
  title: {
    ...theme.typography.h6,
    color: theme.palette.primary.contrastText,
  },
  saveButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
  },
  treeContainer: {
    overflow: 'hidden',
    height: '100%',
  },
  minimapContainer: {
    position: 'relative',
    top: -miniMapSize.height - theme.spacing(2),
    right: theme.spacing(2),
    float: 'right',
    boxShadow: theme.shadows[1],
    borderRadius: 6,
  },
  toolbarRightContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  mapperOptionsBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    minHeight: 55,
    backgroundColor: addTransparency(theme.palette.primary.main, 0.05),
    paddingLeft: 24,
    paddingRight: 24,
  },
  coMapperStatus: {
    width: 'auto',
  },
  mappingStatus: {
    justifyContent: 'flex-end',
    width: 'auto',
  },
}))

const MapperRightPanel = (props) => {
  const {
    treeKey,
    rootNodeId,
    organizations,
    uniqueOrganizations,
    mappingEntity,
    networkWfid,
    influenceWfid,
    availableActorTypesWfid,
    nodes,
    edges,
    search,
    onDone,
    createEdge,
    onRemoveNode,
    onSetActorType,
    onMappingStatusEdited,
  } = props
  const { coMapperOrganization = {}, canSetCoMapper } = mappingEntity
  const [hoveredNodeWfid, setHoveredNodeWfid] = useState(null)

  const [deleteNodeOrganization, setDeleteNodeOrganization] = useState(null)
  const [deleteNodeDialogOpen, openDeleteNodeDialog, closeDeleteNodeDialog] = useDialogState(false)
  const [deleteNodeWfid, setDeleteNodeWfid] = useState(null)
  const [open, openDialog, closeDialog] = useDialogState(false)
  const [actorTypesSaving, setActorTypesSaving] = useState(false)

  const treeCentered = useRef(false)
  const panZoom = useRef()
  const miniMap = useRef()
  const selectedNode = useRef(null)
  const addedOrganization = useRef(null)

  const availableActorTypes = useSelector(state => getAvailableActorTypes(state, availableActorTypesWfid))

  function onOrganizationNodeHoverChange(node, isHovering) {
    setHoveredNodeWfid(isHovering ? node.wfid : null)
  }

  function onOrganizationNodeClick(e, node, organization = {}) {
    const { location } = organization
    if (location && location.latitude && location.longitude) {
      miniMap.current.leafletElement.flyTo([location.latitude, location.longitude], 6, { duration: 2.5 })
    }
  }

  function onTreeMounted() {
    if (!treeCentered.current && panZoom.current) {
      treeCentered.current = true
      panZoom.current.autoCenter()
    }
  }

  function onDeleteNodeDialogOpen(e, node, organization) {
    setDeleteNodeOrganization(organization)
    setDeleteNodeWfid(node.wfid)
    openDeleteNodeDialog()
  }

  function onDeleteNodeDialogClose() {
    setDeleteNodeOrganization(null)
    setDeleteNodeWfid(null)
    closeDeleteNodeDialog()
  }

  function _onRemoveNode() {
    onRemoveNode(deleteNodeWfid)
    onDeleteNodeDialogClose()
  }

  function openActorTypeDialog(event, node, organization) {
    selectedNode.current = node
    addedOrganization.current = organization
    openDialog()
  }

  function _closeDialog() {
    setActorTypesSaving(false)
    closeDialog()
    selectedNode.current = null
    addedOrganization.current = null
  }

  async function _onSetActorType(event, actorType) {
    setActorTypesSaving(true)
    await onSetActorType(event, actorType, selectedNode.current)
    _closeDialog()
  }

  const classes = useStyles(props)
  return (
    <React.Fragment>
      <div className={classes.rightPanel}>
        <div className={classes.toolbar}>
          <div className={classes.toolbarTitle}>
            <div className={classes.title}>
              <FormattedMessage id={'supplyChain.mapper.title'} values={{ name: mappingEntity.name }} />
            </div>
            <div className={classes.toolbarRightContainer}>
              <Button
                variant="contained"
                className={classes.saveButton}
                onClick={onDone}
              >
                <FormattedMessage id={'general.done'} />
              </Button>
            </div>
          </div>
        </div>

        <div className={classes.mapperOptionsBar}>
          {
            (coMapperOrganization || canSetCoMapper) && (
              <CoMapperStatus
                classes={{ root: classes.coMapperStatus }}
                uniqueOrganizations={uniqueOrganizations}
                mappingEntityWfid={mappingEntity.wfid}
                influenceWfid={influenceWfid}
                networkWfid={networkWfid}
                allowAdding
                organizationImage={coMapperOrganization.imageUrl}
                organizationName={coMapperOrganization.name}
                showLabel
              />
            )
          }
          <MappingStatus
            mappingEntity={mappingEntity}
            level={mappingEntity.transparencyLevel}
            classes={{ root: classes.mappingStatus }}
            showLabel
            compactButton
            enableEditing
            onEdited={onMappingStatusEdited}
          />
        </div>

        <div className={classNames(classes.panelContainer, classes.treeContainer)}>
          <PanZoom
            ref={panZoom}
            maxZoom={1}
            minZoom={0.05}
            style={{ height: '100%' }}
          >
            <TreeView
              key={treeKey}
              id={rootNodeId}
              organizations={organizations}
              nodes={nodes}
              edges={edges}
              search={search}
              onNodeHoverChange={onOrganizationNodeHoverChange}
              onNodeClick={onOrganizationNodeClick}
              onAddNode={createEdge}
              onRemoveNode={onDeleteNodeDialogOpen}
              onTreeMounted={onTreeMounted}
              showOrganizationNodeDropdown
              onSetActorTypeClick={openActorTypeDialog}
              allowEditingActorTypes
              edit
            />
          </PanZoom>
          <div className={classes.minimapContainer}>
            <OrganizationMap
              ref={miniMap}
              {...miniMapSize}
              style={{ borderRadius: 6, zIndex: 0 }}
              edges={edges}
              nodes={nodes}
              organizations={organizations}
              highlightedNodeId={hoveredNodeWfid}
              compact
            />
          </div>
        </div>
      </div>

      {
        deleteNodeDialogOpen && (
          <DeleteOrganizationNodeDialog
            open={deleteNodeDialogOpen}
            organization={deleteNodeOrganization}
            organizations={organizations}
            nodeId={deleteNodeWfid}
            nodes={nodes}
            edges={edges}
            onClose={onDeleteNodeDialogClose}
            onCancel={onDeleteNodeDialogClose}
            onRemove={_onRemoveNode}
          />
        )
      }

      {
        <ActorTypesDialog
          open={open}
          saving={actorTypesSaving}
          onClose={_closeDialog}
          onCancel={_closeDialog}
          onSubmit={_onSetActorType}
          disableBackdropClick
          types={availableActorTypes}
          selectedActorTypeWfids={selectedNode.current ? selectedNode.current.properties.actorTypeWfids : null}
          organization={addedOrganization.current}
        />
      }
    </React.Fragment>
  )
}

export default MapperRightPanel
