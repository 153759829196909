import React, { forwardRef } from 'react'
import Image from '@worldfavor/components/Image'
import TableElementsStyles from './TableElementsStyles'
import { ConstVars } from '@worldfavor/constants'
import DropdownMenu from '@worldfavor/components/DropdownMenu'
import { MenuItem as MuiMenuItem } from '@material-ui/core'
import { FormattedMessage, FormattedDate } from 'react-intl'
import NewFilterDropdown from '@worldfavor/components/Filters/NewFilterDropdown'
import TooltipWithCopyToClipboard from '@worldfavor/components/Text/TooltipWithCopyToClipboard'
import _ from 'lodash'
import DialogTitle from '@material-ui/core/DialogTitle'
import AnalyzeResultsHeader from '../AnalyzeResults/AnalyzeResultsHeader'
import AnalyzeResultsTable from '../AnalyzeResults/AnalyzeResultsTable'
import { makeStyles } from '@material-ui/styles'
import Colors from '@worldfavor/constants/colors'

const useStyles = makeStyles(theme => ({
  analysisJobNameContainer: {
    marginTop: -35,
    marginBottom: -5,
  },
  analysisJobName: {
    color: theme.palette.common.white,
  },
  analysisJobOrganization: {
    color: theme.palette.common.white,
  },

  dialogTitle: {
    color: theme.palette.common.white,
  },
  analysisLabel: {
    border: `1px solid ${theme.palette.common.white}`,
    color: theme.palette.common.white,
  },
  analysisTitleDivider: {
    backgroundColor: Colors.blackLight,
  },
  analysisScore: {
    color: theme.palette.common.white,
  },
  dialogTitleContainer: {
    backgroundColor: theme.palette.primary.main,
  },
}))

const MenuItem = forwardRef((props, ref) => {
  const { item, option } = props

  function _onClick(event) {
    event.stopPropagation()
    option.onClick && option.onClick(event, item)
  }

  return (
    <MuiMenuItem ref={ref} onClick={_onClick}>
      <FormattedMessage id={option.translateLabelId} />
    </MuiMenuItem>
  )
})

export function getTableColumns(options) {
  const { filters, onFilterChange, clearFilter, expandCells } = options

  return [
    {
      Header: <FormattedMessage id="dataManager.analysisPackageTitle" />,
      accessor: ConstVars.AnalyzeJobsAccessors.Title,
      width: !expandCells ? 300 : 500,
      options: {
        filter: filters && filters.find(filter => filter.id === ConstVars.AnalyzeJobsAccessors.PackageId),
        onFilterChange,
        clearFilter,
      },
    },
    {
      Header: <FormattedMessage id="dataManager.organization" />,
      accessor: ConstVars.AnalyzeJobsAccessors.Organization,
      width: !expandCells ? 240 : 350,
      options: {
        filter: filters && filters.find(filter => filter.id === ConstVars.AnalyzeJobsAccessors.TargetOrganizationId),
        onFilterChange,
        clearFilter,
      },
    },
    {
      Header: <FormattedMessage id="dataManager.score" />,
      accessor: ConstVars.AnalyzeJobsAccessors.Score,
      width: 75,
    },
    {
      Header: <FormattedMessage id="dataManager.scoreCalculatedAt" />,
      accessor: ConstVars.AnalyzeJobsAccessors.ScoreCalculatedAt,
      width: 175,
    },
    {
      Header: <FormattedMessage id="dataManager.dateAdded" />,
      accessor: ConstVars.AnalyzeJobsAccessors.CreatedAt,
      width: 175,
    },
    {
      Header: <FormattedMessage id="dataManager.scoreLabel" />,
      accessor: ConstVars.AnalyzeJobsAccessors.ScoreLabel,
      width: 120,
    },
    {
      id: ConstVars.action,
      Header: ' ',
      accessor: () => {},
      width: 80,
    },
  ]
}

export function renderRowCell(value, row, column, cell, rowActions) {
  const { openDialog, customRowAction } = rowActions
  const dropdownOptions = [
    {
      id: 'open-dialog-action',
      translateLabelId: 'dataManager.AnalyzeJobsTable.dropdownActions.openAnalysisResults',
      onClick: openDialog,
    },
    {
      id: 'open-new-tab-action',
      translateLabelId: 'dataManager.AnalyzeJobsTable.dropdownActions.openAnalysisResultsInNewTab',
      onClick: customRowAction,
    },
  ]
  const classes = TableElementsStyles()
  const imageUrl = _.get(row, 'original.imageUrl')
  if (column && column.id === ConstVars.AnalyzeJobsAccessors.Title) {
    return (
      <div className={classes.cell}>
        <Image
          className={classes.image}
          size={'cover'}
          src={imageUrl}
        />
        <TooltipWithCopyToClipboard classes={{ root: classes.labelCell }} text={cell.value} />
      </div>
    )
  }

  if ((column && column.id === ConstVars.AnalyzeJobsAccessors.ScoreLabel) && (cell.value)) {
    return (
      <div className={classes.cell}>
        <div className={classes.scoreLabel}>{cell.value}</div>
      </div>
    )
  }

  if (column && column.id === ConstVars.action && dropdownOptions) {
    return (
      <div className={classes.actionButtonWrapper}>
        <DropdownMenu>
          { dropdownOptions.map(option => <MenuItem item={row.original} key={option.id} option={option} />) }
        </DropdownMenu>
      </div>
    )
  }

  if (column && (
    column.id === ConstVars.AnalyzeJobsAccessors.ScoreCalculatedAt ||
    column.id === ConstVars.AnalyzeJobsAccessors.CreatedAt
  )) {
    return (
      <div className={classes.cell}>
        <TooltipWithCopyToClipboard
          text={
            (
              <FormattedDate
                year={'2-digit'}
                day={'2-digit'}
                month={'2-digit'}
                hour={'numeric'}
                minute={'numeric'}
                hour12={false}
                value={cell.value}
              />
            )
          }
        />
      </div>
    )
  }

  return <div className={classes.cell}><TooltipWithCopyToClipboard text={value} /></div>
  
}

export function renderColumnCell(Header, id, column) {
  if (column.options) {
    const { filter, onFilterChange, clearFilter } = column.options

    if (filter && onFilterChange) {
      return (
        <NewFilterDropdown
          filter={filter}

          onChange={onFilterChange}
          onClear={clearFilter}
        />
      )
    }
  }
  return column.Header
}

export const AnalyzeJobsDialogContent = (props) => {
  const { selectedItem, closeDialog, solutionId, networkId } = props
  const classes = useStyles(props)

  return (
    <>
      <DialogTitle className={classes.dialogTitleContainer}>
        <AnalyzeResultsHeader
          closeDialog={closeDialog}
          targetOrganization={selectedItem.targetOrganization.name}
          selectedAnalyzeJob={selectedItem}
          solutionId={solutionId}
          networkId={networkId}
          classes={{
            analysisJobNameContainer: classes.analysisJobNameContainer,
            analysisJobName: classes.analysisJobName,
            title: classes.dialogTitle,
            analysisLabel: classes.analysisLabel,
            analysisJobOrganization: classes.analysisJobOrganization,
            analysisTitleDivider: classes.analysisTitleDivider,
            analysisScore: classes.analysisScore,
          }}
          openInNewTab
        />
      </DialogTitle>
      <AnalyzeResultsTable
        title={<FormattedMessage id="dataManager.contentTypes.analyzeJobs" />}
        networkId={networkId}
        analyzeJobIds={selectedItem.id}
      />
    </>
  )
}
