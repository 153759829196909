// @flow
import * as React from 'react'
import { FormattedDate } from 'react-intl'
import type { Item } from '@worldfavor/types'
import classNames from 'classnames'
import { ObjectType } from '@worldfavor/constants/enums'
import { GOOGLE_MAPS_API_KEY } from '@worldfavor/constants' // eslint-disable-line no-unused-vars
import EmbeddedPlayer from './EmbeddedPlayer'
import CreationDateHeader from '../CreationDateHeader'
import CreatorHeader from '../CreatorHeader'
import Divider from '../Divider'
import LocationLink from '../GMaps/LocationLink'
import sanitizeHtml from 'sanitize-html'
import re_weburl from '@worldfavor/utils/regex/weburl'
import { getItemIcon } from '@worldfavor/utils/propertyExtractors'

import '@worldfavor/less/components/item-summary.less'

type Props = {
	item: Item,
  creator?: Item,
  extendedView?: Boolean,
  showPlaceholder?: Boolean,
  noPlayer?: Boolean,
	style?: { [string]: any },
  extendedView?: boolean,
  noPlayer?: boolean,
}
const imageFileTypes = ['jpg', 'png', 'jpeg', 'jfif', 'tif', 'tiff', 'gif', 'bmp', 'svg', 'exif', 'ppm', 'pgm', 'pbm', 'pnm']
const matchUrl = new RegExp(`(${re_weburl})`, 'gi')

const supportedTypes = [
  ObjectType.link,
  ObjectType.orgActivity,
  ObjectType.statement,
  ObjectType.orgDocument,
  ObjectType.embed,
  ObjectType.certificate,
  ObjectType.dateItem,
  ObjectType.person,
  ObjectType.individual,
  ObjectType.organization,
  ObjectType.country,
  ObjectType.question,
  ObjectType.location,
  ObjectType.productionSite,
  ObjectType.productService,
  ObjectType.parameterValue,
  ObjectType.structure,
  ObjectType.network,
  ObjectType.measure,
]

class ItemSummary extends React.PureComponent<Props> {

  renderImage() {
    const {
      item: {
        hasImage,
        imageUrl,
        type,
        fileType,
        iso3166_alpha2,
        latitude, // eslint-disable-line no-unused-vars
        longitude, // eslint-disable-line no-unused-vars
      } } = this.props
    if (hasImage) {
      return <div styleName="image" style={{ backgroundImage: `url(${imageUrl})` }} />
    }

    if (type === ObjectType.country && iso3166_alpha2) {
      return (
        <div
          styleName="image flag-4-3"
          className={classNames('flag-icon-background', `flag-icon-${iso3166_alpha2}`)}
        />
      )
    }

    // Only enable if Google Maps snapshot is really needed in the item image
    /*if (type === ObjectType.location) {
      const googleStaticMapURL = `https://maps.googleapis.com/maps/api/staticmap?zoom=17&size=100x100&maptype=roadmap&markers=color:red%7Alabel:C%7C${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY}`
      return <div styleName="image" style={ { backgroundImage: `url(${googleStaticMapURL})` } }/>
    }*/

    let { iconClass, icon } = getItemIcon(type)

    if (type === ObjectType.orgDocument) {
      iconClass = 'far'
      if (imageFileTypes.includes(fileType)) {
        icon = 'fa-file-image'
      }
      else if (fileType === 'txt') {
        icon = 'fa-file-alt'
      }
      else if (fileType === 'pdf') {
        icon = 'fa-file-pdf'
      }
    }

    return (
      <div
        styleName="image icon"
        className={classNames(iconClass, icon)}
      />
    )
  }

  renderTitle() {
    const {
      item: {
        type,
        url,
        title,
        fileType,
        providerName,
        name,
        date,
        dateEnd,
      },
    } = this.props

    if (type === ObjectType.link
      || type === ObjectType.orgDocument
      || type === ObjectType.embed
      || type === ObjectType.certificate) {
      return (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          styleName="header-text link"
        >
          { title }
          <span styleName="external-link">
            <i className={'fas fa-external-link-alt'} />
            {
              (type === ObjectType.orgDocument || type === ObjectType.embed || type === ObjectType.certificate) && (
                <span styleName="external-link-text">
                  { fileType || providerName }
                </span>
              )
            }
          </span>
        </a>
      )
    }

    let text = title
    switch (type) {
      case ObjectType.dateItem:
        text = (
          <React.Fragment>
            <FormattedDate
              value={date}
              year={'numeric'}
              day={'numeric'}
              month={'long'}
            />
            {
              dateEnd && (
                <React.Fragment>
                  { ' - ' }
                  <FormattedDate
                    value={dateEnd}
                    year={'numeric'}
                    day={'numeric'}
                    month={'long'}
                  />
                </React.Fragment>
              )
            }
          </React.Fragment>
        )
        break
      case ObjectType.person:
      case ObjectType.individual:
      case ObjectType.organization:
      case ObjectType.country:
      case ObjectType.location:
      case ObjectType.productionSite:
      case ObjectType.productService:
        text = name
        break
      default:
        break
    }

    if (text) {
      return (
        <div styleName="header-text">
          { text }
        </div>
      )
    }
    return null
  }

  renderSubHeader() {
    const { item } = this.props
    switch (item.type) {
      case ObjectType.orgActivity:
        return (
          <div styleName="sub-header">
            <i styleName="icon" className={'far fa-calendar-check'} />
            {
              (item.whenDate || item.validFromDate) && (
                <FormattedDate
                  year={'numeric'}
                  day={'numeric'}
                  month={'short'}
                  value={item.whenDate || item.validFromDate}
                />
              )
            }
            {
              (item.untilDate || item.validUntilDate) && (
                <React.Fragment>
                  { ' - ' }
                  <FormattedDate
                    year={'numeric'}
                    day={'numeric'}
                    month={'short'}
                    value={item.untilDate || item.validUntilDate}
                  />
                </React.Fragment>
              )
            }
          </div>
        )
      case ObjectType.location:
        return (
          <LocationLink
            lat={item.latitude}
            lng={item.longitude}
          />
        )
      case ObjectType.productionSite:
        if (item.gln) {
          return (
            <div styleName="sub-header">
              { `GLN: ${item.gln}` }
            </div>
          )
        }
        return null
      default:
        return null
    }
  }

  renderCreationDate() {
    const { item, creator, extendedView } = this.props
    if (item.createdAt && item.type !== ObjectType.question) {
      return (
        <div styleName="creator-box">
          {
            creator && extendedView && (
              <React.Fragment>
                <CreatorHeader
                  creator={creator}
                  avatarStyle={{ opacity: 0.7, transition: 'opacity .1s' }}
                />
                <Divider style={{ top: 2 }} margin={'0 6px'} width={1} height={11} />
                <Divider style={{ top: 2 }} margin={'0 6px'} width={1} height={11} />
              </React.Fragment>
            )
          }
          <CreationDateHeader
            value={item.createdAt}
            iconStyle={{ fontSize: 10 }}
          />
        </div>
      )
    }
    return null
  }

  renderDescription() {
    const {
      item: {
        type,
        description,
        text,
        email,
        phone_number,
        position,
        registrationNumber,
        vatNumber,
        formattedAddress,
      },
      item,
    } = this.props

    let content = ''

    switch (type) {
      case ObjectType.person:
      case ObjectType.individual:
        content = [email, phone_number, position].filter(Boolean).join(' • ')
        break
      case ObjectType.organization:
        content = [registrationNumber, vatNumber].filter(Boolean).join(' • ')
        break
      case ObjectType.productService:
        content = [
          item.identifierCode && `ID: ${item.identifierCode}`,
          item.ean && `EAN: ${item.ean}`,
          item.upc && `UPC: ${item.upc}`,
          item.isbn && `ISBN: ${item.isbn}`,
        ].filter(Boolean).join(' • ')
        break
      case ObjectType.location:
      case ObjectType.productionSite:
        content = formattedAddress
        break
      case ObjectType.parameterValue:
        content = item.value
        break
      default:
        content = description || text
        content = content && sanitizeHtml(content)
          .replace(matchUrl, '<a target="_blank" href="$1" rel="noopener noreferrer">$1</a>')
        break
    }

    if (!content) {
      return null
    }

    return (
      <div
        styleName="description"
        // ignore dangerouslySetInnerHTML as this content is created by us
        dangerouslySetInnerHTML={{ __html: content }} // eslint-disable-line
      />
    )
  }

  renderPlaceholder() {
    const { style } = this.props
    return (
      <div styleName="container extended-view placeholder" style={style}>
        <div styleName="animated-background">
          <div styleName="mask header-top" />
          <div styleName="mask header-left" />
          <div styleName="mask header-right" />
          <div styleName="mask header-bottom" />
          <div styleName="mask subheader-left" />
          <div styleName="mask subheader-right" />
          <div styleName="mask subheader-bottom" />
          <div styleName="mask content-top" />
          <div styleName="mask content-first-end" />
          <div styleName="mask content-second-line" />
          <div styleName="mask content-second-end" />
          <div styleName="mask content-third-line" />
          <div styleName="mask content-third-end" />
        </div>
      </div>
    )
  }

  renderExtendedView() {
    const { item, style, showPlaceholder, noPlayer } = this.props

    if (showPlaceholder) {
      return this.renderPlaceholder()
    }

    if (!item) {
      return null
    }

    return (
      <div styleName="container extended-view" style={style}>
        <div styleName="header">
          { this.renderImage() }
          <div styleName="header-text-block">
            { this.renderTitle() }
            { this.renderSubHeader() }
            { this.renderCreationDate() }
          </div>
        </div>
        { this.renderDescription() }
        {
          item.type === ObjectType.embed && !noPlayer && (item.html && item.aspectRatio && item.width && item.height) &&
          <EmbeddedPlayer item={item} style={{ margin: '10px 0', maxWidth: 400 }} />
        }
      </div>
    )
  }

  render() {
    const { item, style, extendedView } = this.props

    if (item && !supportedTypes.includes(item.type)) {
      return null
    }

    if (extendedView) {
      return this.renderExtendedView()
    }

    return (
      <div styleName="container" style={style}>
        { this.renderImage() }
        <div styleName="header">
          <div styleName="header-text-block">
            { this.renderTitle() }
            { this.renderSubHeader() }
            { this.renderCreationDate() }
            { this.renderDescription() }
          </div>
          {
            (item.type === ObjectType.embed
              && (item.html && item.aspectRatio && item.width && item.height)) && (
              <EmbeddedPlayer
                item={item}
                style={{ margin: '10px 0', maxWidth: 400 }}
              />
            )
          }
        </div>
      </div>
    )
  }
}

export default ItemSummary
