// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import PickerList from '../PickerList'
import Image from '../Image'
import Images from '@worldfavor/assets/Images'
import classNames from 'classnames'
import { addTransparency } from '@worldfavor/constants/colors'

const useStyles = makeStyles({
  overlay: {
    borderRadius: 4,
  },
})

const useRowStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
  separator: {
    marginTop: 26,
  },
  imageWrapper: {
    padding: 16,
  },
  image: {
    height: 90,
    width: 90,
    borderRadius: '100%',
  },
  textContainer: {
    flex: 1,
    padding: 16,
    paddingRight: 36,
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    color: theme.palette.common.black,
    ...theme.typography.h6,
  },
  description: {
    ...theme.typography.body2,
    marginTop: 8,
  },
}))

const useImageOverlayStyles = makeStyles(theme => ({
  overlay: {
    backgroundColor: addTransparency('#002763', 0.3),
    width: '100%',
    height: '100%',
    borderRadius: '100%',
  },
  letter: {
    color: theme.palette.common.white,
    fontSize: 55,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    width: '100%',
    height: '100%',
    lineHeight: 'intital',
  },
}))

const ImageOverlay = (props) => {
  const { letter } = props
  const classes = useImageOverlayStyles(props)
  return (
    <div className={classes.overlay}>
      <span className={classes.letter}>{letter}</span>
    </div>
  )
}

const ActorTypeRow = (props) => {
  const { imageUrl, title, description, index } = props
  const classes = useRowStyles(props)

  return (
    <Paper
      elevation={1}
      className={classNames(classes.root, index > 0 && classes.separator)}
    >
      <div className={classes.imageWrapper}>
        <Image
          size={'cover'}
          className={classes.image}
          src={imageUrl || Images.actorTypesDefaultImage}
          overlay={!imageUrl && <ImageOverlay letter={title && title.charAt(0)} />}
        />
      </div>
      <div className={classes.textContainer}>
        <div className={classes.title}>{title}</div>
        <div className={classes.description}>{description}</div>
      </div>
    </Paper>
  )
}

const keyExtractor = ({ wfid }) => `actor-type-${wfid}`
const renderItem = ({ item, index }) => <ActorTypeRow {...item} index={index} />

const ActorTypePicker = (props) => {
  const { types, onSelect, selectedType } = props
  const classes = useStyles(props)
  
  return (
    <PickerList
      items={types.map(actorType => ({ id: actorType.wfid, value: actorType }))}
      selectedIds={[selectedType]}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      onSelect={onSelect}
      classes={{ overlay: classes.overlay }}
    />
  )
}

ActorTypePicker.defaultProps = {
  types: [],
}

export default ActorTypePicker
