import * as enums from '@worldfavor/constants/enums'

(function() {
'use strict';

	angular
		.module('wf.common')
		.controller('MailPreviewController', MailPreviewController);

	MailPreviewController.$inject = [ '$scope', 'modalService', 'modalContext', 'apiProxy', '$timeout', 'formSchemaService', '$translate' ];
	function MailPreviewController($scope, modal, modalContext, apiProxy, $timeout, formSchemaService, $translate) {
		var
			vm = this
		;

		_.assign(vm, {
			//Variables
			context: modalContext,
			showSendButton: modalContext.showSendButton,
			onSent: modalContext.onSent,
			sending: false,
			restrictSend: false,
			showForm: false,
			previewLoaded: false,
			disableSendButton: false,
			editButtonLabel: $translate.instant("modules.mailPreview.editMail"),
			formLoaded: false,
			formChanged: false,
			spinReload: false,
			previewResult: {},
			toLabel: $translate.instant("To") + ": ",
			fromLabel: $translate.instant("From") + ": ",
			subjectLabel: $translate.instant("Subject") + ": ",
			formSpec: {
				schema: {},
				form: []
			},
			formControl: {},

			//Functions
			generatePreview: generatePreview,
			saveMail: saveMail,
			toggleEditButton: toggleEditButton,
			send: send,
			close: close
		});

		var 
			iframe,
			iframeHtml,
			iframeWrap,
			iframeDoc,
			mailParameters = {
				userIds: vm.context.userIds, 
				networkId: vm.context.networkId, 
				mailPurpose: vm.context.mailPurpose, 
				organizationId: vm.context.organizationId
			},
			previewEndpoint = vm.context.previewEndpoint || "mail.getMailPreview",
			sendEndpoint = vm.context.sendEndpoint || "mail.sendMail",
			canCloseWindow = true
		;

		activate();

		function activate() {
			$timeout(function () {
				generatePreview().then(function() {
					vm.previewLoaded = true;
				});
			}, 100);
		}

		function generatePreview() {
			return apiProxy(previewEndpoint, mailParameters).then(function (res) {
				var mailHtml, outboundMailPreview;

				if (res.length) {
					outboundMailPreview = res[0];
					
					mailHtml = outboundMailPreview.renderedHtml;
					vm.mailPreview = {
						toName: outboundMailPreview.toName,
						toEmail: outboundMailPreview.toEmail,
						fromName: outboundMailPreview.fromName,
						fromEmail: outboundMailPreview.fromEmail,
						subject: outboundMailPreview.subject,
						bodyText: outboundMailPreview.mailSetting.bodyText
					};

					if (!iframe) {
						iframe = $("#mail-preview-frame");
						iframeWrap = iframe.parent(".preview");
						iframeDoc = iframe[0].contentWindow.document;
						
					}
					
					// https://stackoverflow.com/a/42023690/4871222
					iframeDoc.open();
					iframeDoc.write(mailHtml);
					iframeDoc.close();
					
					setTimeout(function () {
						iframe.contents().find("a").attr("onclick", "return false;")
						setIframeHeight();
					}, 100)
					
				} 
				else {
					showPreviewErrorMessageModal();
				}
			}, function(reason) {
				console.error(reason);
				showPreviewErrorMessageModal();
			});

			function showPreviewErrorMessageModal() {
				modal.alert({
					title: $translate.instant("modules.mailPreview.serverErrorModal.title"),
					message: $translate.instant("modules.mailPreview.serverErrorModal.message"),
					onEscape: false,
					type: "info",
					buttons: {
						primary: {
							label: $translate.instant("Close"),
							className: "btn-hollow action",
							callback: function() {
								$scope.$close();
							}
						}
					}
				});
			}
		}

		function toggleEditButton() {
			vm.showForm = !vm.showForm;
			vm.restrictSend = false;
			if (vm.showForm) {
				vm.disableSendButton = true;
				vm.editButtonLabel = $translate.instant("modules.mailPreview.cancelEditing");
				getFormData();
			}
			else {
				vm.disableSendButton = false;
				vm.editButtonLabel = $translate.instant("modules.mailPreview.editMail");
				iframeWrap.css("opacity", "1");
			}
		}

		function send() {
			if (!vm.showForm) {
				_.assign(mailParameters, {
					customValues: {
						subject: vm.mailPreview.subject,
						bodyText: vm.mailPreview.bodyText
					}
				});
				vm.sending = true;

				return apiProxy(sendEndpoint, mailParameters).then(function () {
					vm.sending = false;
					window.onbeforeunload = null;
					if (vm.context.onSent)
						vm.context.onSent();

					modal.alert({
						title: $translate.instant("Sent"),
						message: $translate.instant("modules.mailPreview.confirmationMessage") + ': ' + vm.mailPreview.toName,
						onEscape: false,
						type: "success",
						buttons: {
							primary: {
								label: $translate.instant("Close"),
								className: "btn-hollow action",
								callback: function() {
									$scope.$close();
								}
							}
						}
					});
				});
			}
			else {
				vm.restrictSend = true;
			}
			$timeout();
		}

		function setIframeHeight() {
			var iframeHeight = iframe.contents().innerHeight();
			iframe.height(iframeHeight);
		}

		function getFormData() {
			formSchemaService.getFromType(enums.objectType.mailSettings).then(function (res) {
				vm.formModal = { 
					organizationId: mailParameters.organizationId, 
					networkId: mailParameters.networkId,
					subject: vm.mailPreview.subject, 
					bodyText: vm.mailPreview.bodyText, 
				};

				vm.formSpec.schema = {
					"type": "object",
					"properties": (function () {
						return _.cloneDeep(res.schema.properties);
					})(),
					"required": [ "subject", "bodyText" ]
				}

				vm.formSpec.form = [
					{
						title: $translate.instant("Subject"),
						key: "subject"
					},
					{
						title: $translate.instant("Message"),
						key: "bodyText",
						type: "textarea"
					}
				];

				_.each(vm.formSpec.form, function (field) {
					field.onChange = _.throttle(onChangeCallback, 500);
				});

				_.each(_.filter(vm.formSpec.form, function (field) {
					return field.readonly
				}), function (field) {
					field.disableErrorState = true;
					field.disableSuccessState = true;
					field.htmlClass = "readonly-simplified";
				});

				vm.formLoaded = true;

				$timeout();

			});
		}

		function onChangeCallback() {
			vm.formChanged = true;
			if (canCloseWindow)
				disableWindowClose();
			iframeWrap.css("opacity", "0.3");
			iframeWrap.css("transition", "opacity .3s ease-in");
		}

		function saveMail() {
			if (vm.formControl.isValid()) {
				vm.spinReload = true;
				_.assign(mailParameters, { customValues: vm.formControl.getModel() });
				// _.assign(mailParameters, vm.formControl.getModel());
				generatePreview().then(function() {
					vm.spinReload = false;
					vm.formChanged = false;
					iframeWrap.css("opacity", "1");
					vm.toggleEditButton();
				});
			}
		}

		function disableWindowClose() {
			window.onbeforeunload = function(e) {
				var dialogText = 'Are you sure you want to close?';
				var dialogText = 'Är du säker på att du vill avsluta?';
				e.returnValue = dialogText;
				return dialogText;
			};

			$scope.$on('$destroy', function() {
				window.onbeforeunload = null;
			});

			canCloseWindow = false;
		}

		function close() {
			if (!canCloseWindow) {
				modal.alert({
					title: $translate.instant("modules.mailPreview.exitModal.title"),
					message: $translate.instant("modules.mailPreview.exitModal.message"),
					onEscape: false,
					type: "warning",
					buttons: {
						back:{
							label: $translate.instant("modules.mailPreview.exitModal.backButton"),
							className: "btn-hollow"
						},
						primary: {
							label: $translate.instant("modules.mailPreview.exitModal.primaryButton"),
							className: "btn-hollow action",
							callback: function() {
								$scope.$close();
							}
						}
					}
				});
			}
			else $scope.$close();
		}
	}
})();
