import React from 'react'
import { react2angular } from 'react2angular'
import ItemList from '@worldfavor/components/Items/ItemList'
import { WrapProvider } from './providers'

const props = [
  'items',
]

class ItemListWrapper extends React.Component {

  prepareProps = (props) => {
    const items = props.items ?
      props.items.map(item => item.isPlaceholder ? null : { ...item.content, creator: item.creatorUser }) :
      []
    return {
      ...props,
      items,
      getScrollParent: () => document.getElementsByClassName('modal wf-modal')[0],
    }
  }

  render() {
    const { wfAuth, ...rest } = this.props
    return (
      <WrapProvider wfAuth={wfAuth}>
        <ItemList {...this.prepareProps(rest)} />
      </WrapProvider>
    )
  }
}

export default react2angular(ItemListWrapper, props, ['wfAuth'])
