(function () {
	'use strict';

	angular
	.module('schemaForm')
	.config(['schemaFormProvider', 'schemaFormDecoratorsProvider', 'sfPathProvider',
		function (schemaFormProvider, schemaFormDecoratorsProvider, sfPathProvider) {
			var picker_single = function (name, schema, options) {
				if (schema.format === 'picker_single') {
					var f  = schemaFormProvider.stdFormObj(name, schema, options);

					f.key  = options.path;
					f.type = 'picker_single';
					// f.validationMessage = {
					// 	customRule: ""
					// };
					// f.$validators = {
					// 	customRule: function (value) {
					// 		return !!value;
					// 	}
					// }
					options.lookup[sfPathProvider.stringify(options.path)] = f;
					return f;
				}
			};

			schemaFormProvider.defaults.integer.unshift(picker_single);

			schemaFormDecoratorsProvider.addMapping(
				'bootstrapDecorator',
				'picker_single',
				'scripts/wf/form/schema-form-picker-single.html'
			);
		}
	]);

	angular
	.module('wf.common')
	.directive('wfSchemaPickerSingle', ['$timeout', '$q', '$translate','modalService','wfObject','apiProxy','dataOperationsService',
		function ($timeout, $q, $translate, modal, wfObject, apiProxy, dataOps) {
		return {
			restrict: 'A',
			scope:    true,
			require:  'ngModel',
			link: function (scope, element, attrs, ngModel) {
				var
					sourceList,
					rootObject,
					orgRootObject,
					userRootObject
				;
				scope.openPicker = openPicker;
				scope.resetRoot = resetRoot;
				scope.rootObject = null;
				scope.rootStatusText = ""

				activate();

				function activate() {
					var auth0UserId = scope.$parent.$parent.$parent.model.auth0Id; // Must be a better way!

					return apiProxy("authentication.getUserRootObjectInfo", {
						user_id: auth0UserId
					}).then(function(res) {
						orgRootObject = wfObject.inject(res.organizationRoot);
						if (res.userRoot)
							userRootObject = wfObject.inject(res.userRoot);

						if (res.inherited) {
							scope.rootStatusText = $translate.instant("modules.users.Full");
							scope.rootObject = orgRootObject;
						}
						else {
							scope.rootStatusText = $translate.instant("modules.users.Restricted");
							scope.rootObject = userRootObject;
							ngModel.$setViewValue(userRootObject.id);
							scope.isCustomUserRoot = true;
						}

						scope.itemPreviewCompiler.compile();
						scope.loaded = true;
						$timeout();
					});
				}

				function resetRoot() {
					scope.isCustomUserRoot = false;
					scope.rootObject = orgRootObject;
					scope.rootStatusText = $translate.instant("modules.users.Full");
					ngModel.$setViewValue(undefined);
					scope.itemPreviewCompiler.compile();
					$timeout();
				}

				function openPicker() {
					return dataOps.getObject({
						objectId: orgRootObject.childId,
						objectType: orgRootObject.childType,
						childrenLoadDepth: 3
					}).then(function (sourceItem) {
						var
							preSelected = userRootObject ? [ userRootObject ] : []
						;
						var
							maxDepth = 3,
							childrenQuery = { type: 73, wffid: null }
						;

						if (!sourceList) {
							// console.log(sourceList)

							sourceList = [];
							// console.log(sourceItem)
							handleChildren(sourceItem, 0);

							// _.forEach(sourceList, function (item) {
							// 	console.log(new Array(item.depth + 1).join("  "), item.data.childContent.title || item.data.childContent.text || item.data.childContent.name);
							// });
							
						}
						
						function handleChildren(parent, depth) {
							if (depth > maxDepth || !parent || !parent.conditions || !(parent.conditions.dataRelation || parent.conditions.objectType)) return;

							childrenQuery.wffid = parent.wfid;

							_.forEach(wfObject.filter({ where: childrenQuery }), function (dataRelation) {
								sourceList.push({
									depth: depth,
									data: dataRelation,
									wfid: dataRelation.wfid
								});
								handleChildren(dataRelation.childContent, depth + 1);
							})
						}

						modal.openCreatorAndPicker({
							sourceList: sourceList,
							relationBucket: { preSelected: preSelected, allSelected: preSelected, singlePick: true },
						}).closed(function (relationBucketResult) {
							// console.log(relationBucketResult.allSelected);
							if (relationBucketResult.allSelected.length) {
								scope.isCustomUserRoot = true;
								scope.rootStatusText = $translate.instant("modules.users.Restricted");
								scope.rootObject = relationBucketResult.allSelected[0];
								ngModel.$setViewValue(scope.rootObject.id);
							}
							else {
								resetRoot();
							}

							scope.itemPreviewCompiler.compile();
							$timeout();
								
							// jqDf.resolve();

							// relationBucketResult = _relationBucketResult;

							// vm.wrappedRelations.length = 0;
							// _.forEach(relationBucketResult.allSelected, function(item) {
							// 	vm.wrappedRelations.push({
							// 		childContent: item,
							// 		childType: item.type
							// 	});
							// });

							// $timeout(function () {});
						});
					})
				}

				scope.validateField = function () {

				};
				// scope.submit = function () {
				// 	if (scope.uploadForm.file && scope.uploadForm.file.$valid && scope.picFile && !scope.picFile.$error) {
				// 		scope.uploadFile(scope.picFile);
				// 	} else if (scope.uploadForm.files && scope.uploadForm.files.$valid && scope.picFiles && !scope.picFiles.$error) {
				// 		scope.uploadFiles(scope.picFiles);
				// 	}
				// };
				scope.$on('schemaFormReset', reset);
				scope.$on('schemaFormValidate', scope.validateField);
				scope.$on('schemaFormBeforeSubmit', schemaFormBeforeSubmit);
				// scope.$on('schemaFormFileUploadSubmit', scope.submit);
				
				function reset() {
					ngModel.$setViewValue(undefined);
				}

				function schemaFormBeforeSubmit(event, model) {
				}
			}
		};
	}]);
})();
