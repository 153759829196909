import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';

	angular
		.module('wf.common')
		.component('wfMeasurePeriodSettingsEditor', {
			templateUrl: "scripts/wf/objectSettings/wfMeasurePeriodSettingsEditor.component.html",
			controller: wfMeasurePeriodSettingsEditorController,
			controllerAs: "vm",
			bindings: {
				value: "<",
				form: "<"
			}
		});

	wfMeasurePeriodSettingsEditorController.$inject = [ "$scope", "$timeout", "$element", "$rootScope", "$state", "requirements", "$transclude", "$compile", "$translate", "$q", "dataOperationsService", "wfObject", "statisticsService", "analyzeService", "wfPropertyExtractor", "wfMeasureService" ];
	function wfMeasurePeriodSettingsEditorController($scope, $timeout, $element, $rootScope, $state, requirementService, $transclude, $compile, $translate, $q, dataOps, wfObject, statisticsService, analyzeService, wfPropertyExtractor, wfMeasureService) {
		var
			vm = this,
			debug = false
		;

		var
			itemPrototype = {
				startMonth: null,
				startDay: null,
				endMonth: null,
				endDay: null,
				name: null,
				onChange: function () { onItemChange(this); },
				remove: removeItem,
			}
		;

		this.$onInit = $onInit;

		_.assign(vm, {
			items: _.map(_.get(vm, "value.ranges"), function (range) { return _.assign(_.clone(itemPrototype), range); }) || [],
			availableMonths: _.chain(moment.months()).map(function (monthName, index) {
				return {
					id: index + 1,
					name: monthName
				}
			}).value(),
			availableDaysByMonth: _.chain(_.times(12, function (i) {
				return {
					month: ++i,
					days: _.times(wfMeasureService.getDaysInMonth(i, 2001), function (j) { // 2001 is not a leap year. Prevents the user from selecting 29 Feb.
						return {
							id: ++j,
							name: j.toString()
						}
					})
				};
			})).keyBy("month").mapValues("days").value(),

			addItem: addItem,
			onChange: onItemChange
		});

		function $onInit() {
			updateRangesPreview();
			if (!vm.value)
				vm.value = { };

			vm.value.frequency = enums.calendarFrequency.custom;
			vm.form.onChanged(vm.value);
		}

		function addItem() {
			vm.items.push(_.clone(itemPrototype));
			updateRangesPreview();
		}

		function removeItem() {
			_.remove(vm.items, this);

			updateRangesPreview();
		}

		function updateRangesPreview() {
			if (_.every(vm.items, function (item) {
				return item.startMonth && item.startDay && item.endMonth && item.endDay;
			})) {
				vm.generatedPeriods = wfMeasureService.generatePeriods({
					frequency: enums.calendarFrequency.custom,
					ranges: vm.items
				})
				vm.value.ranges = null
				$timeout(() => vm.value.ranges = vm.items, 300);
			}
			else {
				vm.generatedPeriods = null;
				vm.value.ranges = null;
			}

			vm.form.onChanged(vm.value);
		}

		function onItemChange(item) {
			var
				lastDayInMonth
			;

			if (item.startMonth && item.startDay) {
				lastDayInMonth = _.last(vm.availableDaysByMonth[item.startMonth]).id;
				if (item.startDay > lastDayInMonth)
				item.startDay = lastDayInMonth
			}

			if (item.endMonth && item.endDay) {
				lastDayInMonth = _.last(vm.availableDaysByMonth[item.endMonth]).id;
				if (item.endDay > lastDayInMonth)
					item.endDay = lastDayInMonth
			}

			updateRangesPreview();
		}
	}
})();
