export default function filteringHook(_incomingFilters, useInvertedFiltering, setFilters, setActiveFilters) {
  if (_incomingFilters && _incomingFilters.length > 0) {
    _incomingFilters.forEach((filter) => {
      filter.active = false
      filter.filterOptions.map(option => option.checked = useInvertedFiltering)
    })
    setFilters(_incomingFilters)
    setActiveFilters({})
  }
}
