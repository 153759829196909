import _ from 'lodash'
import { formatValue, getValueWithUnit } from '../helper'

// TODO use real sanitize function instead of mocked
const $sanitize = value => value

export const parseStringToJSON = (string) => {

  const isJSONString = (string) => {
    try {
      JSON.parse(string)
    }
    catch (e) {
      //console.warn('Could not parse String into JSON')
      return false
    }
    return true
  }

  //Define a function to fix special characters in the String
  if (!String.prototype.escapeSpecialChars) {
    String.prototype.escapeSpecialChars = function() {
      return this.replace(/\\n/g, '\\n')
        .replace(/\\'/g, '\\\'')
        .replace(/\\"/g, '\\"')
        .replace(/\\&/g, '\\&')
        .replace(/\\r/g, '\\r')
        .replace(/\\t/g, '\\t')
        .replace(/\\b/g, '\\b')
        .replace(/\\f/g, '\\f')
    }
  }

  if (string.indexOf('&quot;') > -1) {
    string = string.replace(/&quot;/g, '"')
  }
  string = string.escapeSpecialChars()

  if (string !== null && isJSONString(string)) {
    return JSON.parse(string)
  }
  else {
    return false
  }
}

function ctTooltip(options) {

  const Chartist = require('chartist')

  const defaultOptions = {
    currency: undefined,
    currencyFormatCallback: undefined,
    tooltipOffset: {
      x: 0,
      y: -20,
    },
    anchorToPoint: false,
    appendToBody: false,
    class: undefined,
    pointClass: 'ct-point',
    tooltipFnc(meta, value, allowRoundNumber) {

      const useNumeralJs = false
      const abbreviateLargeNumbers = false

      let legendLabel
      let datasetName
      let xAxisLabel
      let xAxisLabelDetails
      let extraHtml = ''

      meta = parseStringToJSON(meta)
      value = formatValue(value, useNumeralJs, allowRoundNumber, abbreviateLargeNumbers)
      value = getValueWithUnit(value, meta.unit)
      delete meta['unit']

      if (!_.isEmpty(meta)) {
        legendLabel = _.get(meta, 'legendLabel.value')
        datasetName = _.get(meta, 'datasetName.value')
        xAxisLabel = _.get(meta, 'xAxisLabel.value')
        xAxisLabelDetails = _.get(meta, 'xAxisLabelDetails.value')

        if (options.ticket && options.ticket.organizationIds
          && meta.organizationCount && meta.organizationCount.value
          && parseInt(meta.organizationCount.value) >= 0) {
          if (options.aggregateMode === 'separate') {
            extraHtml = `<div class='meta'><div class='org-name'>${$sanitize(datasetName)}</div></div>`
          }
          else {
            extraHtml =  `<div class='meta'><span>${$sanitize(meta.organizationCount.label)}:</span> <span class='text-bold'>${$sanitize(meta.organizationCount.value)}</span></div>`
          }
        }
      }

      return '<div class=\'custom-tooltip-template pull-left\'>' +
        (xAxisLabel ? (
          '<div class=\'tooltip-header\'>' +
          `<span class='value'>${$sanitize(xAxisLabel)}</span>` +
          (xAxisLabelDetails && xAxisLabelDetails !== xAxisLabel ? `<div class='value-detailed'>${$sanitize(xAxisLabelDetails)}</div>` : '') +
          '</div>'
        ) : '') +
        '<div class=\'tooltip-body\'>' +
        (legendLabel && datasetName !== legendLabel ? legendLabel + ': ' : '') + `<span class='text-bold data-value'>${$sanitize(value)}</span>` +
        extraHtml +
        '</div>' +
        '</div>'
    },
  }

  options = Chartist.extend({}, defaultOptions, options)

  const show = (element) => {
    if (!hasClass(element, 'tooltip-show')) {
      element.className = element.className + ' tooltip-show'
    }
  }

  const hide = (element) => {
    const regex = new RegExp('tooltip-show\\s*', 'gi')
    element.className = element.className.replace(regex, '').trim()
  }

  const hasClass = (element, className) => {
    return (` ${element.getAttribute('class')} `).indexOf(` ${className} `) > -1
  }

  const next = (element, className) => {
    do {
      element = element.nextSibling
    } while (element && !hasClass(element, className))
    return element
  }

  const text = (element) => {
    return element.innerText || element.textContent
  }

  return function ctTooltip(chart) {
    let tooltipSelector = options.pointClass
    if (chart instanceof Chartist.Bar) {
      tooltipSelector = 'ct-bar'
    }
    else if (chart instanceof Chartist.Pie) {
      // Added support for donut graph
      if (chart.options.donut) {
        tooltipSelector = 'ct-slice-donut-solid'
      }
      else {
        tooltipSelector = 'ct-slice-pie'
      }
    }

    const chartContainer = chart.container
    if (chartContainer) {
      let $toolTip = chartContainer.querySelector('.chartist-tooltip')
      if (!$toolTip) {
        $toolTip = document.createElement('div')
        $toolTip.className = (!options.class) ? 'chartist-tooltip' : 'chartist-tooltip ' + options.class
        if (!options.appendToBody) {
          chartContainer.appendChild($toolTip)
        }
        else {
          document.body.appendChild($toolTip)
        }
      }
      let height = $toolTip.offsetHeight
      let width = $toolTip.offsetWidth

      hide($toolTip)

      on('mouseover', tooltipSelector, (event) => {
        const $point = event.target
        let tooltipText = ''

        const isPieChart = (chart instanceof Chartist.Pie) ? $point : $point.parentNode
        const seriesName = (isPieChart) ? $point.parentNode.getAttribute('ct:meta') || $point.parentNode.getAttribute('ct:series-name') : ''
        let meta = $point.getAttribute('ct:meta') || seriesName || ''
        const hasMeta = !!meta
        let value = $point.getAttribute('ct:value')

        if (options.transformTooltipTextFnc && typeof options.transformTooltipTextFnc === 'function') {
          value = options.transformTooltipTextFnc(value)
        }

        if (options.tooltipFnc && typeof options.tooltipFnc === 'function') {
          tooltipText = options.tooltipFnc(meta, value, options.allowRoundNumber)
        } else {
          if (options.metaIsHTML) {
            const txt = document.createElement('textarea')
            txt.innerHTML = meta
            meta = txt.value
          }

          meta = `<span class="chartist-tooltip-meta">${meta}</span>`

          if (hasMeta) {
            tooltipText += meta + '<br>'
          } else {
            // For Pie Charts also take the labels into account
            // Could add support for more charts here as well!
            if (chart instanceof Chartist.Pie) {
              const label = next($point, 'ct-label')
              if (label) {
                tooltipText += text(label) + '<br>'
              }
            }
          }

          if (value) {
            if (options.currency) {
              if (!options.currencyFormatCallback) {
                value = options.currencyFormatCallback(value, options)
              } else {
                value = options.currency + value.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
              }
            }
            value = `<span class="chartist-tooltip-value">${value}</span>`
            tooltipText += value
          }
        }

        if (tooltipText) {
          $toolTip.innerHTML = tooltipText
          setPosition(event, { height, width, $toolTip })
          show($toolTip)

          // Remember height and width to avoid wrong position in IE
          height = $toolTip.offsetHeight
          width = $toolTip.offsetWidth
        }
      })

      on('mouseout', tooltipSelector, () => {
        hide($toolTip)
      })

      on('mousemove', null, (event) => {
        if (false === options.anchorToPoint) {
          setPosition(event, { height, width, $toolTip })
        }
      })
    }

    function setPosition(event, opt) {
      const toolTip = opt.$toolTip
      const height = opt.height || toolTip.offsetHeight
      const width = opt.width || toolTip.offsetWidth
      const offsetX = - width / 2 + options.tooltipOffset.x
      const offsetY = - height + options.tooltipOffset.y
      let anchorX
      let anchorY

      if (!options.appendToBody) {
        const box = chartContainer.getBoundingClientRect()
        const left = event.pageX - box.left - window.pageXOffset
        const top = event.pageY - box.top - window.pageYOffset

        if (true === options.anchorToPoint && event.target.x2 && event.target.y2) {
          anchorX = parseInt(event.target.x2.baseVal.value)
          anchorY = parseInt(event.target.y2.baseVal.value)
        }

        toolTip.style.top = `${(anchorY || top) + offsetY}px`
        toolTip.style.left = `${(anchorX || left) + offsetX}px`
      } else {
        toolTip.style.top = `${event.pageY + offsetY}px`
        toolTip.style.left = `${event.pageX + offsetX}px`
      }
    }

    function on(event, selector, callback) {
      chartContainer.addEventListener(event, (e) => {
        if (!selector || hasClass(e.target, selector)) {
          callback(e)
        }
      })
    }
  }
}

export default ctTooltip
