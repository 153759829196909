(function() {
	'use strict';

	angular
		.module('wf.common')
		.factory('RelationMapper', relationMapperService);

	relationMapperService.$inject = [ 'dataOperationsService', '$q' ];
	function relationMapperService(dataOps, $q) {

		return RelationMapper;

		function RelationMapper() {
			var self = this;

			function Map(type, oldId, newId, kind) {
				this.type = type;
				this.oldId = oldId;
				this.newId = newId
				this.kind = kind;

				this.getValues = getValues;
				this.getOldId = getOldId;
				this.getNewId = getNewId;
				this.getType = getType;
				this.getKind = getKind;

				return this;

				function getValues() {
					return { type: this.type, oldId: this.oldId, newId: this.newId, kind: this.kind };
				}
				function getOldId() {
					return this.oldId;
				} 
				function getNewId() {
					return this.newId;
				} 
				function getType() {
					return this.type;
				} 
				function getKind() {
					return this.kind;
				} 
			}

			_.assign(self, {
				//Variables
				mapper: {
					informationRepository: [],
					relations: []
				},

				//Functions
				saveOldAndNewId: saveOldAndNewId,
				getRelationAndUpdateChildId: getRelationAndUpdateChildId,
				getParentAndChildId: getParentAndChildId
			});



			function saveOldAndNewId(oldItem, newItem) {
				var type;
				var map;

				if (oldItem.type === newItem.type)
					type = oldItem.type;
				else
					console.error("The old and new item are not of same type");

				if (!self.mapper.informationRepository[type] && !_.isArray(self.mapper.informationRepository[type]))
					self.mapper.informationRepository[type] = [];

				map = new Map(type, oldItem.id, newItem.id);
				self.mapper.informationRepository[type].push(map.getValues());
			} 

			function getRelationAndUpdateChildId(relation) {
				var item = _.find(self.mapper.informationRepository[relation.childType], { "type" : relation.childType, "oldId" : relation.childId });
				if (item) {
					relation.childId = item.newId;
					return item;
				}
				else
					return undefined;
			}

			function getActualObjectId(currentType, currentId) {
				var idMap = _.find(self.mapper.informationRepository[currentType], { oldId: currentId });
			
				if (idMap)
					return idMap.newId;
				else
					return currentId;
			}

			function getParentAndChildId(relation) {
				var deferred = $q.defer();
				var promises = [];
				var itemsToCheck = [
					{ id: getActualObjectId(relation.parentType, relation.parentId), type: relation.parentType },
					{ id: getActualObjectId(relation.childType, relation.childId), type: relation.childType }
				];

				_.each(itemsToCheck, function(item) {
					promises.push(dataOps.getObject({ objectId: item.id, objectType: item.type }));
				});

				$q.all(promises).then(function(result) {
					if (!_.some(result, [ 'wfid', -1 ])) {
						console.log("Both parent and child exist", result);
						deferred.resolve({ parentId: itemsToCheck[0].id, childId: itemsToCheck[1].id });
					}
					else {
						console.log("Parent or child doesn't exist in the database - cannot create relation", result);
						deferred.reject({ parentId: undefined, childId: undefined });
					}
				}, function() {
					console.error("Something went wrong with dataOps.getObject()");
				});

				return deferred.promise;
			}
		}
	}
})();
