// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import PickerList from '../PickerList'
import classNames from 'classnames'
import { capitalize } from '@worldfavor/utils/helpers'
import Colors from '@worldfavor/constants/colors'
import { FormattedMessage } from 'react-intl'

const styles = {
  overlay: {
    borderRadius: 4,
  },
}

const rowStyles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    minWidth: 700,
  },
  separator: {
    marginTop: 16,
  },
  flagContainer: {
    height: 120,
    width: 120,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 52,
    backgroundColor: '#F1F1F1',
    color: '#A8A8A8',
  },
  flagPartiallyMapped: {
    color: Colors.orange,
  },
  flagFullyMapped: {
    color: Colors.green,
  },

  textContainer: {
    flex: 1,
    padding: 16,
    paddingRight: 36,
  },
  title: {
    ...theme.typography.h5,
  },
  description: {
    ...theme.typography.body2,
    marginTop: 8,
  },
})

const TransparencyStatusList = ['notMapped', 'partiallyMapped', 'fullyMapped']

const TransparencyLevelRow = withStyles(rowStyles)(({ classes, status, index }) => {
  return (
    <Paper
      elevation={2}
      className={classNames(classes.root, index >= 0 && classes.separator)}
    >
      <div className={classes.flagContainer}>
        <i
          className={
            classNames('fas fa-flag', {
              [classes[`flag${capitalize(status)}`]]: status !== 'notMapped',
            })
          }
        />
      </div>

      <div className={classes.textContainer}>
        <div className={classes.title}>
          <FormattedMessage id={`supplyChain.mappingStatus.${status}.title`} />
        </div>
        <div className={classes.description}>
          <FormattedMessage id={`supplyChain.mappingStatus.${status}.description`} />
        </div>
      </div>
    </Paper>
  )
})

const keyExtractor = level => `transparency-level-${level}`
const renderItem = ({ item, index }) => <TransparencyLevelRow status={item} index={index} />

const TransparencyLevelPicker = ({ classes, onSelect, selectedLevel }) => (
  <PickerList
    items={TransparencyStatusList.map(status => ({ id: status, value: status }))}
    selectedIds={[selectedLevel]}
    keyExtractor={keyExtractor}
    renderItem={renderItem}
    onSelect={onSelect}
    classes={{ overlay: classes.overlay }}
  />
)

export default withStyles(styles)(TransparencyLevelPicker)
