(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfTextareaAutoHeight', wfTextareaAutoHeight);

	wfTextareaAutoHeight.$inject = [];
	
	function wfTextareaAutoHeight() {
		var directive = {
			restrict: "A",
			link: function ($scope, $element) {
				// Auto-height will be activated if the textarea have max-height set from css.
				var
					element,
					maxHeight
				;

				// console.log(element);
				
				setTimeout(function () {
					element = $element[0],
					maxHeight = parseInt($element.css("maxHeight"))

					if (maxHeight) {
						element.style.resize = "none";
						resize();
					}
					else {
						return;
					}

					$element.on("keypress keydown change keyup", function () {
						setTimeout(function () {
							resize();
						}, 0);
					})
				}, 0); // Needed, probably because it is used with wf-compile-clone directive which causes the maxHeight css to be missing without this delay


				function resize() {
					element.style.height = "5px";
					element.style.height = (element.scrollHeight) + "px";
					if (element.scrollHeight > maxHeight) {
						element.style.overflow = "auto"
					}
					else {
						element.style.overflow = "hidden"
					}
				}
			}
		};
		return directive;
	}
})();