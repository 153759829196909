import endpoints from './endpoints'
import { post } from './requestWrappers'

export const fetchOrganizations = async (options = {}, headers = {}, source) => {
  const payload = { ...options }
  return await post(endpoints.readOrganizations, payload, headers, source)
}

export const fetchContributors = async (options = {}, headers = {}, source) => {
  const payload = { ...options }
  return await post(endpoints.readContributors, payload, headers, source)
}

export const fetchCategorizations = async (options = {}, headers = {}, source) => {
  const payload = { ...options }
  return await post(endpoints.readCategorizations, payload, headers, source)
}

export const fetchAnalyzeJobs = async (options = {}, headers = {}, source) => {
  const payload = { ...options }
  return await post(endpoints.readAnalyzeJobs, payload, headers, source)
}

export const fetchAnalyzeResults = async (options = {}, headers = {}, source) => {
  const payload = { ...options }
  return await post(endpoints.readAnalyzeResults, payload, headers, source)
}
