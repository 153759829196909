import React, { useEffect, useState, useRef } from 'react'
import Colors from '@worldfavor/constants/colors'
import { makeStyles } from '@material-ui/core/styles'
import Popper from '@material-ui/core/Popper'
import MenuList from '@material-ui/core/MenuList'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Api from '../../angular/api'
import MenuItem from '@material-ui/core/MenuItem'
import Link from '@material-ui/core/Link'
import Avatar from '@material-ui/core/Avatar'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

const useStyles = makeStyles(() => ({
  navbar: {
    background: Colors.black,
    display: 'grid',
    padding: '0 15px',
    gridTemplateColumns: '[logo] 40px [nav] auto [user] 200px',
  },
  logo: {
    font: '0/0 a !important',
    color: 'transparent !important',
    textShadow: 'none !important',
    backgroundColor: 'transparent !important',
    border: '0 !important',
    background: 'url(/static/media/wf_logo_white_filled.00b9432d.svg) no-repeat center',
    backgroundSize: 'contain',
    borderRadius: 2,
    height: 36,
    width: 36,
    marginRight: '32px !important',
    margin: '7px 0',
    gridColumnStart: 'logo',
  },
  navbarItem: {
    padding: 15,
  },
  nav: {
    gridColumnStart: 'nav',
    gridColumnEnd: 'user',
    display: 'flex',
  },
  button: {
    color: Colors.grayOffWhite,
  },
  user: {
    cursor: 'pointer',
    gridColumnStart: 'user',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  userDetail: {
    fontWeight: 'bold',
    marginRight: 15,
  },
  organization: {
    fontWeight: 'lighter',
  },
}))

const NavbarItem = (props) => {
  const { name, subItems, target } = props
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const classes = useStyles(props)

  function handleToggle() {
    setOpen(prevOpen => !prevOpen)
  }

  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }
  return (
    <div className={classes.navbarItem} data-cy={'navbar-item'}>
      <div
        onMouseEnter={handleToggle}
        onMouseLeave={handleToggle}
      >
        <Link
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={classes.button}
          href={target}
          data-cy={'navbar-item-link'}
        >
          {name}
        </Link>
        {subItems && (
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {subItems.map(item => (
                      <MenuItem key={item.name} onClick={handleClose} data-cy={'menu-item'}>{item.name}</MenuItem>
                    ))}
                  </MenuList>
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
      </div>
    </div>
  )
}

const User = (props) => {
  const { user } = props
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const classes = useStyles(props)

  function handleToggle() {
    setOpen(prevOpen => !prevOpen)
  }

  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }
  return (
    <div data-cy={'user'}>
      <div
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={classes.user}
      >
        <div className={classes.userDetail} data-cy={'user-detail'}>
          {user.name} <br />
          <span className={classes.organization}>{user.organization}</span>
        </div>
        <Avatar alt={user.name} src={user.avatarUrl} />
      </div>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem>Log out</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

const Navbar = () => {
  const classes = useStyles()
  const [items, setItems] = useState([])
  const [user, setUser] = useState(null)

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await Api.get('/navbar', {}, null, { mockedRequest: true })
        setItems(res.data.items)
        setUser(res.data.user)
      } catch (e) {
        console.error(e)
      }
    }
    fetchData()
  }, [setItems])

  return (
    <div className={classes.navbar} data-cy={'navbar'}>
      <Link className={classes.logo} href={'/'}>WorldFavor</Link>
      <nav className={classes.nav}>
        {items && items.map(item => (
          <NavbarItem key={item.name} name={item.name} subItems={item.subItems} target={item.target} />
        ))}
      </nav>
      <div className={classes.user}>
        {user && (
          <User user={user} />
        )}
      </div>
    </div>
  )
}

export default Navbar
