(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfItemsCollection', wfItemsCollection)
		.directive('wfActionCompiler', wfActionCompiler)
		.directive('wfActionCompilerUpdate', wfActionCompilerUpdate)
	;

	wfItemsCollection.$inject = [];
	function wfItemsCollection() {
		var directive = {
			bindToController: true,
			controller: wfItemsCollectionController,
			controllerAs: 'colVm',
			restrict: 'A',
		};

		return directive;
	}
	
	wfItemsCollectionController.$inject = ['$parse', '$attrs', '$scope'];
	function wfItemsCollectionController($parse, $attrs, $scope) {
		var vm = this;
		var wfItemsCollection = $parse($attrs.wfItemsCollection)($scope);

		_.assign(vm, {
			// wfItemsCollection: wfItemsCollection,
			items: wfItemsCollection.items,
			onDeleted: wfItemsCollection.onDeleted,
			onUpdated: wfItemsCollection.onUpdated,
			onAttached: wfItemsCollection.onAttached,
			remove: remove,
			update: update,
			callback_attach: callback_attach
		});
		
		function remove(item) {
			_.remove(wfItemsCollection.items, item);

			if (typeof wfItemsCollection.onDeleted === "function") {
				wfItemsCollection.onDeleted(item);
			}
		}

		function update(item) {
			if (typeof wfItemsCollection.onUpdated === "function") {
				wfItemsCollection.onUpdated(item);
			}
		}

		function callback_attach(item) {
			if (typeof wfItemsCollection.onAttached === "function") {
				wfItemsCollection.onAttached(item);
			}
		}
	}
	
	wfActionCompiler.$inject = [];
	function wfActionCompiler() {
		var directive = {
			controller: wfActionCompilerController,
			restrict: 'A',
		};
		return directive;
	}

	wfActionCompilerController.$inject = ['$scope', '$element', '$attrs', '$parse', '$compile'];
	function wfActionCompilerController($scope, $element, $attrs, $parse, $compile) {
		var control;
		this.element = $element;

		if ($attrs.wfActionCompiler) {
			control = $parse($attrs.wfActionCompiler)($scope);
			
			control.compile = function () {
				if ($element) {
					$element.height($element.height());

					$element.css({ opacity: 0 });
					
					$compile($element.contents())($scope);
					
					setTimeout(function () {
						$element.css("height", "");
							$element.animate({ opacity: 1 }, 300);
					}, 300)
				}
			};
			console.info(control); 
		}
	}
	
	wfActionCompilerUpdate.$inject = [];
	function wfActionCompilerUpdate() {
		var directive = {
			controller: wfActionCompilerUpdateController,
			restrict: 'A',
			terminal: true
			// compile: function(element, attrs) {
			// 	return {
			// 		post: function(scope, element, attrs, ctrl) {
			// 			console.log("compile", element.html());
			// 		}
			// 	};
			// }
		};
		return directive;
	}

	wfActionCompilerUpdateController.$inject = ['$scope', '$element', '$attrs', '$parse', '$compile', '$timeout'];
	function wfActionCompilerUpdateController($scope, $element, $attrs, $parse, $compile, $timeout) {
		var
			control,
			vm = this,
			elementClone = $element[0].cloneNode(true);
		;
		// console.log($element.html());
		this.element = $element;

		// if ($attrs.wfActionCompilerUpdate) {
			// control = $parse($attrs.wfActionCompilerUpdate)($scope) || {};
			
			vm.compile = function () {
				var newElementClone, compiledElement;
				var oldHeight, newHeight;

				if ($element) {
					oldHeight = $element.height();
					$element.height($element.height());

					$element.animate({ opacity: 0 }, 50, function () {
						$element.html("");
						newElementClone = elementClone.cloneNode(true);
						$element.append(newElementClone.childNodes);
						
						$compile($element.contents())($scope);
						$timeout();
						
						setTimeout(function () {
							$element.css("height", "");
							newHeight = $element.height();
							$element.height(oldHeight);
							$element.animate({ opacity: 1, height: newHeight }, 250, function () {
								$element.css("height", "");
							});
						}, 50)
					});
				}
			};
			$compile($element.contents())($scope);
			// console.info(control); 
		// }
	}
})();