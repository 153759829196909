import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfListInterface', function () {
			return {
				controller: wfListInterfaceController,
				// templateUrl is loaded dynamically because this directive uses special transclusion logic
				controllerAs: "vm",
				bindToController: true,
				scope: {
					fromItem: '=',
					fromArray: "=",
					ticket: "<",
					transclusionOuterVm: "<",
					configFromAttribute: "<config",
					depth: "<",
					listVm: "<",
					negotiatorFromAttr: "=negotiator",
				},
				require: {
					wfDataNegotiator: "?^^wfDataNegotiator"
				},

				// Terminal is needed to prevent Angular from touching any html that might be inside <wf-list-interface></wf-list-interface>
				// that will be used for transclusion. The html is manually cloned and passed to every instance of wfListItem in ngRepeat.
				terminal: true,

				// Transclusion slots:
				// transcludeItemRightSide (handled manually)

				link: function (scope, element, attrs, controllers, transclude) {
					scope.vm.wfDataNegotiator = controllers.wfDataNegotiator;
					scope.vm.activate();
				}
			};
		});

	var templateUrl = "scripts/wf/list/wfListInterface.directive.html";
	var templateRequest;
	var templateLoaded = false;

	wfListInterfaceController.$inject = [ "$scope", "$attrs", "$timeout", "$element", "$rootScope", "$state", "requirements", "$compile", "$translate", "apiProxy", "dataQuery", "wfPropertyExtractor", "DataNegotiator", "$http", "$templateCache", "modalService", "$parse", "$q" ];
	function wfListInterfaceController($scope, $attrs, $timeout, $element, $rootScope, $state, requirements, $compile, $translate, apiProxy, dataQuery, wfPropertyExtractor, DataNegotiator, $http, $templateCache, modal, $parse, $q) {
		var
			vm = this,
			xhrRequest
		;

		_.assign(vm, {
			$rootScope: $rootScope,
			scopeId: $scope.$id,
			items: [],
			loaded: false,
			negotiator: undefined,
			showColumns: false,
			itemStyle: "list_indented",
			creationDropdownActions: undefined,
			showConfigBar: true,
			showItemIndentation: true,
			itemWidth: "100%",
			config: {
				// The wfChartNew component needs a width to render correctly.
				// If the listInterface or any of its parents are hidden when everything has loaded and the html is rendered the chart will have zero width.
				// By default the width of the wfListInterface component element is used but if a wrapping component controls the visibility the width
				// must be specified by that wrapping component like this: <wf-list-interface config="{ width: 600 }"></wf-list-interface>.
				// The width value will be passed along to the wfChartNew component.
				width: $element.width(),

				showLoadedItem: false,
				showCreationButton: false,
				showFiltering: false,
				showVisualization: true,
				showActions: false,
				showModificationActions: false,
				showTotals: false,
				showLayoutOptions: true,
				showConsolidationStatistics: true,
				creationAction: undefined,
				includeActions: {
					embed: true,
					export: true,
					view: true,
					create: true,
					sort: true,
					mark: true,
					listView: true,
					gridView: true,
					tableView: true
				},
				filteringConfig: {
					filters: [
						[
							{ bySearch: true }
						],
						[
							{
								headerTranslate: "Categories",
								sourceIdPath: 'wfid', bySubItemsKind: enums.subItemsKind.parentsByUser
							}
						]
					]
				},
				itemSettings: {
					showDropdown: false,
					dropdownActions: undefined
				},
				chartConfig: { showLegend: true, chartWidth: $element.width() },
        useReactList: vm.configFromAttribute && vm.configFromAttribute.useReactList,
      },
			creationButtonIconClass: "fa fa-plus-circle",
			dropdownCreateActions: undefined,
			creationButtonAction: undefined,
			activate: activate,
			noop: _.noop,
			uiCompiler: {}, // Used to compile the head and the list after changes have been made to the config
			listCompiler: {}, // Used to compile the list
			tableData: {
				initExportPanel
			},

			onUiSettingChanged: onUiSettingChanged,
			setListLayout: setListLayout
		});

		_.each(vm, function (value, key) {
			if (key in $attrs && typeof value === "boolean") {
				if ($attrs[key] && $attrs[key].length)
					vm[key] = $parse($attrs[key])($scope);
				else
					vm[key] = true;
			}
		});

		if ($element[0].childNodes.length) {
			vm.transcludeItemRightSide = {
				elementClone: $element.children("transclude-item-right-side")[0],
				outerVm: vm.transclusionOuterVm
			};
			$element.html("");
		}

		loadTemplate();


		function loadTemplate() {
			if (templateLoaded) {
				applyTemplate();
				return;
			}
			else if ($templateCache.get(templateUrl)) {
				templateLoaded = true;
				applyTemplate();
				return;
			}
			
			if (!templateRequest) {
				templateRequest = $http.get(templateUrl)
			}
			

			templateRequest.then(function (res) {
				templateLoaded = true;
				$templateCache.put(templateUrl, res.data);
				applyTemplate();
			});

		}

		function applyTemplate() {
			var templateContent = $templateCache.get(templateUrl);

			$element.html(templateContent);
			$compile($element.contents())($scope);
		}


		function activate() {
			vm.negotiator = DataNegotiator.instantiate(vm.negotiatorFromAttr || vm.wfDataNegotiator, { fromItem: vm.fromItem, ticket: vm.ticket, loadDepth: vm.depth });

			vm.negotiator.onRequest.then(function (res) {
				vm.ticket = vm.negotiator.ticket;
				vm.isConsolidation = vm.negotiator.ticket && vm.negotiator.ticket.organizationIds;
				vm.config = _.defaultsDeep(vm.negotiator.listInterfaceConfig, vm.config);

				setupCreationActions();
			});

			_.assign(vm.config, vm.configFromAttribute);

			if (vm.listVm) {
				vm.nestedListDepth = (vm.listVm.nestedListDepth || 0) + 1;
			}
			
			vm.sortingActions = [
				{
					text: "Title",
					action: function () {}
				},
				{
					text: "Created",
					action: function () {}
				}
			];
			// console.log($element.html());

			// var $html = $('<div />',{ html:htmlContent }); // for much easier manipulation (so you can use DOM functions - you can also manipulate directly on htmlContent string)


			// $transclude(function(clone, scope) {
			// 	console.log(clone);
			// 	// vm.transcludeItemRightSide = {
			// 	// 	elementClone: clone[0],
			// 	// 	outerVm: vm.transclusionOuterVm
			// 	// }
			// });//, null, "transcludeItemRightSide");
		}

		function setupCreationActions() {
			var
				mainItem = _.get(vm, "negotiator.item"),
				objectType = _.get(vm, "negotiator.item.conditions.objectTypes[0]"),
				dataRelationCondition = _.get(vm, "negotiator.item.conditions.dataRelation"),
				options = {
					dataRelationOptions: { kind: enums.subItemsKind.childrenByUser, item1: mainItem }
				}
			;
			
			if (!mainItem)
				return;

			if (typeof vm.config.creationAction === "function") {
				vm.creationButtonAction = function () {
					vm.config.creationAction(vm.negotiator)
				}
			}

			vm.creationButtonIcon = "fa fa-plus";
			vm.creationButtonCaption = $translate.instant("Create");

			vm.creationButtonAction = function () {
				if (mainItem.type === enums.objectType.structure) {
					if (!dataRelationCondition) {
						options.dataRelationOptions.virtual = true;
					}
					
					if (objectType) {
						options.objectType = objectType;
					}
				}
				else if (mainItem.type === enums.objectType.measure) {
					options.objectType = enums.objectType.measureAnswer;
				}
				else if (mainItem.type === enums.objectType.question) {
					options.objectType = enums.objectType.questionAnswer;
				}

				var promise = modal.createWithRelation(options);

				promise.then(function (res) {
					if (res) {
						vm.negotiator.addItem(res);
					}
				});
			}
		}

		function onUiSettingChanged() {
			vm.uiCompiler.compile();
		}

		function setListLayout(view) {
			var oldView = vm.listLayout;
			var listLayoutWatcher = $scope.$watch("vm.listLayout", function () {
				$scope.$broadcast("updateChartist");
			});

			vm.listLayout = view;
			vm.showItemIndentation = false;
			
			if (view === "grid") {
				vm.itemWidth = '33.333%'
			}
			else if (view === "list" || view === "list_indented") {
				vm.itemWidth = '100%'

				if (view === "list_indented") {
					vm.showItemIndentation = true;
				}
			}
			else if (view === "table") {
				vm.itemWidth = '100%'
				initTableData();
			}
			
			if (oldView === "table" || view === "table") {
				vm.listCompiler.compile();
			}

			$scope.$on("$destroy", function () {
				listLayoutWatcher();
			});
		}

		function initTableData() {
			const promises = [];

			if (vm.tableData.inited) {
				return;
			}

			vm.tableData.inited = true;

			if (vm.negotiator.fromItem.type === 11 || vm.negotiator.fromItem.type === 21) {
				const promise = vm.negotiator.getTableData_onlyLatestAnswers();
				promise.then(res => {
					vm.tableData.items = res;
				});
				promises.push(promise);

			}
			else {
				const promise = vm.negotiator.getTableData();
				promise.then(res => {
					vm.tableData.items = res;
				});
				promises.push(promise);
	
			}

			vm.tableData.exportMapping = vm.negotiator.getTableExportMapping();

			return $q((resolve, reject) => {
				$q.all(promises).then(() => {
					vm.tableData.loaded = true
					resolve();
				});
			})
		}

		function initExportPanel() {
			return initTableData();
			const promises = [];

			if (vm.export.inited) {
				return;
			}

			vm.export.inited = true;

			if (vm.negotiator.fromItem.type === 11 || vm.negotiator.fromItem.type === 21) {
				const promise = vm.negotiator.getTableData_onlyLatestAnswers();
				promise.then(res => {
					vm.export.items = res;
				});
				promises.push(promise);

			}
			else {
				const promise = vm.negotiator.getTableData();
				promise.then(res => {
					vm.export.items = res;
				});
				promises.push(promise);
	
			}

			vm.export.mapping = vm.negotiator.getTableExportMapping();

			return $q((resolve, reject) => {
				$q.all(promises).then(() => {
					vm.tableData.loaded = true
					resolve();
				});
			})
		}
		// [
		// 	{
		// 		colWidth: 6,
		// 		header: "",
		// 		source: "content.creatorOrganization.name"
		// 	},
		// 	{
		// 		colWidth: 2,
		// 		header: "",
		// 		source: "content.mainTextual"
		// 	},
		// 	{
		// 		colWidth: 4,
		// 		header: "",
		// 		source: "item.content.createdAt",
		// 		format: function (value) {
		// 			return moment(value).format("D MMM YYYY, HH:mm");
		// 		}
		// 	}
		// ]
	}

})();
