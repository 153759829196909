import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { FormattedDate } from 'react-intl'
import get from 'lodash/get'
import { exchangePath, influencePath } from '@worldfavor/constants/paths'
import { getNodeFromNodeId } from '../../selectors/dataSelector'
import DropdownMenu from '@worldfavor/components/DropdownMenu'
import FulfillmentStatusIndicator from '../SupplyChain/FulfillmentStatusIndicator'
import MenuItem from '@material-ui/core/MenuItem'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import Colors from '@worldfavor/constants/colors'

const withStyles = makeStyles(theme => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: theme.typography.fontSizes.medium,
    fontWeight: theme.typography.fontWeights.medium,
    flex: 1,
  },
  image: {
    width: 30,
    height: 30,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 3,
    marginRight: 24,
    flex: 'none'
  },
  thirdPartyReporter: {
    fontSize: theme.typography.fontSizes.default,
  },
  thirdPartyTarget: {
    fontSize: theme.typography.fontSizes.default,
  },
  date: {
    fontSize: theme.typography.fontSizes.default,
    whiteSpace: 'nowrap',
  },
  fulfillmentStatus: {
    fontSize: theme.typography.fontSizes.default,
    maxWidth: 170,
  },
  actions: {
    float: 'right',
  },
  linkMenuItem: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    outline: 'none',
    '&:focus': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      outline: 'none',
    },
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  cellLink: {
    display: 'block',
    '&, &:hover': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
  },
  actionWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  handIcon: {
    marginRight: 8,
    color: Colors.orange,
  },
}))

const InfluenceTableRowCell = (props) => {
  const {
    row,
    column,
    onEditInfluence,
    onDeleteInfluence,
    excludeImage,
    onRemovePermissionDialog,
    setTargetOrganizationName,
    setReporterOrganizationName,
    onStartReportingOnBehalfDialog,
  } = props
  const classes = withStyles(props)
  const rowPath = row.node.useNewRoute ? exchangePath(row.node.wfid) : influencePath(row.node.id)
  const influenceContent = useSelector(state => getNodeFromNodeId(state, row.node.wfcid))
  const thirdPartyReporter = useSelector(state => getNodeFromNodeId(state, row.node.organizationWfid))
  const tableRowDropdownOptions = [
    {
      id: 1,
      label: <FormattedMessage id={'general.open'} />,
      classes: { root: classes.linkMenuItem },
      component: Link,
      to: rowPath,
      show: true,
    },
    {
      id: 2,
      label: <FormattedMessage id={'general.edit'} />,
      onClick: _onEditInfluence,
      show: Boolean(onEditInfluence),
    },
    {
      id: 3,
      label: <FormattedMessage id={'supplyChain.thirdPartyReporting.startReportingButtonText'} />,
      onClick: _onReportOnBehalfDialogOpen,
      show: false, //!row.node.hasThirdPartyReporter && !row.node.hasThirdPartyTarget,
    },
    {
      id: 4,
      label: getRemoveInfluenceButtonText(),
      onClick: _onRemovePermissionsDialogOpen,
      show: row.node.hasThirdPartyReporter || row.node.hasThirdPartyTarget,
    },
    {
      id: 5,
      label: <FormattedMessage id={'general.delete'} />,
      onClick: _onDeleteInfluence,
      show: Boolean(onDeleteInfluence),
    },
  ]

  function _onDeleteInfluence() {
    onDeleteInfluence(row.node)
  }

  function _onEditInfluence() {
    onEditInfluence(row.node)
  }

  function getRemoveInfluenceButtonText() {
    if (row.node.hasThirdPartyReporter) {
      return <FormattedMessage id={'supplyChain.thirdPartyReporting.stopReportingButtonText'} />
    }
    if (row.node.hasThirdPartyTarget) {
      return <FormattedMessage id={'supplyChain.thirdPartyReporting.removePermissionsButtonText'} />
    }
    return null
  }

  function _onReportOnBehalfDialogOpen() {
    if (thirdPartyReporter && setReporterOrganizationName) {
      setReporterOrganizationName(thirdPartyReporter.name)
    }
    if (row.node.thirdPartyTargetOrganization && setTargetOrganizationName) {
      setTargetOrganizationName(row.node.thirdPartyTargetOrganization.name)
    }
    onStartReportingOnBehalfDialog && onStartReportingOnBehalfDialog(row.node)
  }

  function _onRemovePermissionsDialogOpen() {
    if (thirdPartyReporter && setReporterOrganizationName) {
      setReporterOrganizationName(thirdPartyReporter.name)
    }
    if (row.node.thirdPartyTargetOrganization && setTargetOrganizationName) {
      setTargetOrganizationName(row.node.thirdPartyTargetOrganization.name)
    }
    onRemovePermissionDialog && onRemovePermissionDialog(row.node)
  }

  const imageUrl = column === 'title' ? get(influenceContent, 'imageUrl') : undefined
  const columns = {
    title: (
      <div className={classes.titleWrapper}>
        {imageUrl && !excludeImage && <div style={{ backgroundImage: `url('${imageUrl}')` }} className={classes.image} />}
        <span className={classes.title}>{row.node.title}</span>
      </div>
    ),
    organization: thirdPartyReporter && (
      <>
        {
          row.node.hasThirdPartyReporter && (
            <Tooltip placement="top" title={<FormattedMessage id={'supplyChain.thirdPartyReporting.reportingOnBehalfTooltip'} />}>
              <span className={classes.handIcon}><i className="fas fa-hands-helping" /></span>
            </Tooltip>
          )
        }
        <span className={classes.thirdPartyReporter}>
          {thirdPartyReporter.name}
        </span>
      </>
    ),
    activatedAt: row.node.activatedAt && (
      <span className={classes.date}>
        <FormattedDate
          year={'numeric'}
          day={'2-digit'}
          month={'2-digit'}
          value={row.node.activatedAt}
        />
      </span>
    ),
    onBehalfOf: row.node.thirdPartyTargetOrganization && (
      <span className={classes.thirdPartyTarget}>
        {row.node.thirdPartyTargetOrganization.name}
      </span>
    ),
    deadline: row.node.fulfillmentDueAt && (
      <span className={classes.date}>
        <FormattedDate
          year={'numeric'}
          day={'2-digit'}
          month={'2-digit'}
          value={row.node.fulfillmentDueAt}
        />
      </span>
    ),
    fulfillmentStatus: row.node && (
      <div className={classes.fulfillmentStatus}>
        <FulfillmentStatusIndicator influence={row.node} hideProgressBar hideProgress />
      </div>
    ),
    actions: (
      <div className={classes.actionWrapper}>
        <DropdownMenu
          className={classes.actions}
        >
          {
            tableRowDropdownOptions.filter(option => option.show).map(({ id, label, show, ...rest }) => (
              <MenuItem key={`influence-dropdown-option-${id}`} {...rest}>
                { label }
              </MenuItem>
            ))
          }
        </DropdownMenu>
      </div>
    ),
  }

  const cellContent = columns[column] || ''

  if (column === 'actions') {
    return cellContent
  }

  return (
    <a
      href={rowPath}
      className={classes.cellLink}
    >
      {cellContent}
    </a>
  )
}

export default InfluenceTableRowCell
