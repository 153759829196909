(function () {
	'use strict';

	angular
		.module('wf.common')
		.service('listInterfaceService', listInterfaceService);

		listInterfaceService.$inject = [ "dataOperationsService", "apiProxy", "$q", "wfObject", "$sanitize", "$filter" ];
	function listInterfaceService(dataOps, apiProxy, $q, wfObject, $sanitize, $filter) {
		var service = {
			getActualConfig: function (itemComposite) {
				var configObjects = [ 
					_.get(itemComposite, "dataRelation.settings_user.listInterfaceConfig"),
					_.get(itemComposite, "dataRelation.settings_organization.listInterfaceConfig"),
					_.get(itemComposite, "dataRelation.settings.listInterfaceConfig"),
					_.get(itemComposite, "content.conditions_user.listInterfaceConfig"),
					_.get(itemComposite, "content.conditions_organization.listInterfaceConfig"),
					_.get(itemComposite, "content.conditions.listInterfaceConfig")
				];

				var actualConfig = _.defaultsDeep.apply(null, configObjects);

				return actualConfig;
			}
		};

		return service;
	}
})();
