// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import Colors from '@worldfavor/constants/colors'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 26,
    paddingBottom: 26,
    paddingLeft: 18,
    paddingRight: 18,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    border: `dashed 2px ${Colors.graySemiLight}`,
    borderRadius: 4,
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    color: Colors.black,
    fontSize: 18,
    marginBottom: 6,
  },
  subtitle: {
    color: Colors.grayText,
    fontSize: 14,
  },
}))

const NoOrganizationNode = (props) => {
  const classes = useStyles(props)
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <FormattedMessage id={'supplyChain.mapper.placeholder.noOrganization.title'} />
      </div>
      <div className={classes.subtitle}>
        <FormattedMessage id={'supplyChain.mapper.placeholder.noOrganization.subtitle'} />
      </div>
    </div>
  )
}

export default NoOrganizationNode
