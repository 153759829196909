// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import PickerList from '../PickerList'
import Divider from '../Divider'
import HighlightedText from '@worldfavor/components/Text/HighlightedText'

const styles = {
  overlay: {
    borderRadius: 4,
  },
}

const rowStyles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    padding: 8,
    cursor: 'pointer',
  },
  image: {
    marginRight: 16,
  },
  textContainer: {
    flex: 1,
    overflow: 'hidden',
  },
  name: {
    ...theme.typography.h6,
    paddingRight: 36,
    lineHeight: 1.2,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  description: {
    ...theme.typography.body2,
    marginTop: 2,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
})

const OrganizationSeparator = () => (
  <div style={{ paddingLeft: 64 }}>
    <Divider
      height={1}
      color={'rgba(221, 221, 221, 0.5)'}
      style={{ marginBottom: 2, marginTop: 2 }}
    />
  </div>
)

const OrganizationRow = withStyles(rowStyles)(({ classes, imageUrl, name, description, search }) => {
  return (
    <div className={classes.root}>
      <Avatar src={imageUrl} className={classes.image}>{name && name[0]}</Avatar>
      <div className={classes.textContainer}>
        <div title={name} className={classes.name}>
          <HighlightedText text={name} match={search} />
        </div>
        <div title={description} className={classes.description}>{description}</div>
      </div>
    </div>
  )
})

const keyExtractor = ({ wfid }) => `organization-${wfid}`

const OrganizationPicker = ({
  classes,
  organizations,
  selectedOrganization,
  search,
  onOrganizationSelect,
  ...rest
}) => {

  function renderItem({ item }) {
    return <OrganizationRow {...item} search={search} />
  }

  function onSelect(event, selectedId) {
    onOrganizationSelect(event, organizations.find(({ wfid }) => wfid === selectedId))
  }

  return (
    <PickerList
      items={organizations.map(organization => ({ id: organization.wfid, value: organization }))}
      selectedIds={[(selectedOrganization || {}).wfid]}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ItemSeparatorComponent={OrganizationSeparator}
      classes={{ overlay: classes.overlay }}
      onSelect={onSelect}
      {...rest}
    />
  )
}

OrganizationPicker.defaultProps = {
  onOrganizationSelect: () => {},
}

export default withStyles(styles)(OrganizationPicker)
