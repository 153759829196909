// @flow
import type { Dispatch } from 'redux'
import { addItems, addItem, removeItem, resetState } from './dataActions'
import { addPage } from './paginationActions'
import { getPageFromRequestIdAndPageId } from '../selectors/paginationSelector'
import Api from '../api'
import { FilterTypes } from '@worldfavor/constants'
import get from 'lodash/get'
import { getEdgesFromToNodeId } from '../selectors/dataSelector'
import { apiCallError, beginDataApiCall, dataApiCallSuccess } from './apiCallStatusActions'

const objectKeys = ['childContent', 'organization', 'creatorOrganization', 'creatorUser', 'ancestor', 'originalRelation', 'positionRelation', 'fulfillment']
const arrayKeys = ['childs', 'relatedContent', 'relatedContentByUser', 'verifications', 'users', 'requirements']
const keyTypes = {
  ...objectKeys.reduce((acc, key) => ({ ...acc, [key]: 'object' }), {}),
  ...arrayKeys.reduce((acc, key) => ({ ...acc, [key]: 'array' }), {}),
}

// Extract nested objects/arrays from the item to their own objects
export const extractRelationalContent = (item: Object) => {
  // The item arg can contain any of the props defined above in objectKeys and arrayKeys.
  // Below we reduce on the keys in keyTypes and for each iteration we attempt to extract
  // the prop matching the key from keyTypes and put whats left of the item in the 'other' variable using the spread operator.
  // This continues for all keys in keyTypes until all relational props have been extracted from the item.

  // If the key comes from arrayKeys the prop value will be an array.
  // The extractRelationalContent func is then invoked for item in the array.

  const result = Object.keys(keyTypes)
    .reduce((acc, key) => {
      const { [key]: attribute, ...other } = acc.item
      const keyType = keyTypes[key]
      const content = (attribute ? (keyType === 'object' ? extractRelationalContent : reduceList)(attribute) : [])
      return {
        item: other,
        array: [
          ...(acc.array),
          ...content,
        ],
      }
    }, { item, array: [] })
  return [result.item, ...result.array]
}

const reduceList = list => list.reduce((acc, item) => [...acc, ...extractRelationalContent(item)], [])

export const loadItem = (id: number, type: number, options: Object) => {
  return async (dispatch: Dispatch<*>) => {
    const result = await Api.fetchItem(id, type, options)
    const item = result.data
    const items = extractRelationalContent(item)
    dispatch(addItems(items))
    return item
  }
}

export const loadSubItemsOfAll = (wfids: Array<number>, kind: number, options: Object) => {
  return async (dispatch: Dispatch<*>) => {
    const result = await Api.fetchSubItemsOfAll(wfids, kind, options)
    const items = result.data.reduce(
      (acc, x) => [...acc, ...extractRelationalContent(x)],
      [],
    )
    dispatch(addItems(items))
    return items
  }
}

export const loadExistingOrganizations = (registrationNumber: string, vatNumber: string, gln: string) => {
  return async (dispatch: Dispatch<*>) => {
    const result = await Api.post(Api.endpoints.getOrganizationByCondition, { registrationNumber, vatNumber, gln })
    const organizations = result.data.reduce((acc, organization) => [...acc, ...extractRelationalContent(organization)], [])
    dispatch(addItems(organizations))
    return organizations
  }
}

export const loadSubItemsPage = (
  wfid: string,
  pageNumber: number,
  pageSize: number,
  requestId: string,
  source: Object,
  options: Object = {},
  filterConditions: Array<Object> = [],
) => {
  return async (dispatch: Dispatch<*>, getState: () => Object) => {
    dispatch(beginDataApiCall())

    const requestParams = {
      ...options,
      filterConditions: [
        {
          filterType: FilterTypes.PageSize,
          numValues: [pageSize],
        },
        {
          filterType: FilterTypes.PageNumber,
          numValues: [pageNumber],
        },

        ...filterConditions,
      ],
    }
    if (requestParams != null) {
      const pageId = JSON.stringify(requestParams)

      // If the page already exist return the already existing page
      // TODO implementing caching and disable caching strategy
      let page = getPageFromRequestIdAndPageId(getState(), requestId, pageId)
      if (page) {
        dispatch(dataApiCallSuccess())
        return { requestId, pageId, ...page }
      }

      const [type, id] = wfid.split('-')
      const result = await Api.fetchSubItemsPage(id, type, source, requestParams)

      if (result.data != null) {
        page = result.data

        const items = page.items.reduce((acc, item) => [...acc, ...extractRelationalContent(item)], [])

        dispatch(addItems(items))
        dispatch(addPage(
          requestId,
          pageId,
          page.items.map(item => item.wfid),
          page.totalElements,
          page.pageNumber,
          page.pageSize,
        ))
        dispatch(dataApiCallSuccess())
        return { requestId, pageId, ...page }
      } else {
        dispatch(apiCallError())
        console.log('Error: No data available')
      }
    } else {
      dispatch(apiCallError())
      console.log('Error: Missing requestParams')
    }
  }
}

export const loadSubItemsPageForDataCollector = (
  api: () => {},
  pageNumber: number,
  pageSize: number,
  requestId: string,
  source: Object,
  options: Object = {},
  filterConditions: Array<Object> = [],
) => {
  return async (dispatch: Dispatch<*>, getState: () => Object) => {
    dispatch(beginDataApiCall())
    const requestParams = {
      ...options,
      filterConditions: [
        {
          filterType: FilterTypes.PageSize,
          numValues: [pageSize],
        },
        {
          filterType: FilterTypes.PageNumber,
          numValues: [pageNumber],
        },

        ...filterConditions,
      ],
    }
    if (requestParams != null) {
      const pageId = JSON.stringify(requestParams)

      // If the page already exist return the already existing page
      // TODO implementing caching and disable caching strategy
      let page = getPageFromRequestIdAndPageId(getState(), requestId, pageId)
      if (page) {
        dispatch(dataApiCallSuccess())
        return { requestId, pageId, ...page }
      }

      const result = await api(requestParams, null, source)
      if (result.data != null) {
        page = result.data
        const items = page.items.reduce((acc, item) => [...acc, ...extractRelationalContent(item)], [])

        dispatch(addItems(items))
        dispatch(addPage(
          requestId,
          pageId,
          page.items.map(item => item.wfid),
          page.totalElements,
          page.pageNumber,
          page.pageSize,
        ))
        dispatch(dataApiCallSuccess())
        return { requestId, pageId, ...page }
      } else {
        dispatch(apiCallError())
        console.log('Error: No data available')
      }
    } else {
      dispatch(apiCallError())
      console.log('Error: Missing requestParams')
    }
  }
}

export const createItem = (item: Object) => {
  return async (dispatch: Dispatch<*>) => {
    const result = await Api.post(Api.endpoints.createExtended, { item })
    dispatch(addItem(result.data))
  }
}

/**
 *
 * @param {string} item1Wfid is the wfid of the item to which you are creating the relation FROM
 * @param {string} item2Wfid is the wfid of the item to which you are creating the relation TO
 * @param {number} kind is a type of relation to create. It is a number from enums.subItemsKind (usually it is subItemsKind.children, subItemsKind.relatedContent etc.)
 * @param {Object} options can include optional values like the ones below
 * @param {string} options.contextParentWfid
 * @param {{ networkId: number, organizationId: number }} options.ticket
 * @param {number} options.networkId
 * @param {number} options.order
 * @param {boolean} options.virtual if virtual is true, it will create a fake relation between item1Wfid & item2Wfid. It also means that the kind will NOT be take into consideration and will always be enums.subItemsKind.childrenByUser
 * @param {Object} options.virtualItem if we pass virtualItem, the server will create a virtal relation (it is used when we are creating a user and making a relation to an organization) - this option is not widely used and might be depricated and handled in the backend
 *
 * @returns {async}
 *
 * @example
 * // createSubItemRelation('52-184', '100-12468', enums.SubItemsKind.children)
 * //   item1Wfid represents the networkWfid
 * //   item2Wfid represents the userWfid
 * //   kind indicates that we are creating a relation between these items where item2Wfid is added as a child to the item1Wfid
 *
 * @example
 * // createSubItemRelation('100-12468', '52-184', enums.SubItemsKind.parent)
 * //   gives the same result as the Example 1, it just defines the type of relation between the items in a different way
 * //   note that item1Wfid & item2Wfid are swapped and that the kind is changed from children to parent
 *
 */
export const createSubItemRelation = (item1Wfid: string, item2Wfid: string, kind: number, options: Object) => {
  return async (dispatch: Dispatch<*>) => {
    const { contextParentWfid, ticket, networkId, order, virtual, virtualItem } = options
    const [item1Type, item1Id] = item1Wfid.split('-')
    const [item2Type, item2Id] = item2Wfid.split('-')
    const item1 = { type: parseInt(item1Type), id: parseInt(item1Id), wfid: item1Wfid }
    const item2 = { type: parseInt(item2Type), id: parseInt(item2Id), wfid: item2Wfid }

    const result = await Api.post(Api.endpoints.createSubItemRelation, {
      item1,
      item2,
      kind,
      contextParentWfid,
      ticket,
      networkId,
      order,
      virtual,
      virtualItem1: virtualItem,
    })
    dispatch(addItem(result.data))
  }
}

export const updateItem = (item: Object) => {
  return async (dispatch: Dispatch<*>) => {
    dispatch(beginDataApiCall())
    const result = await Api.post(Api.endpoints.updateExtended, { item })
    if (result != null) {
      dispatch(addItem(result.data))
      dispatch(dataApiCallSuccess())
    } else {
      dispatch(apiCallError())
      console.log('Error: Could not update item')
    }
  }
}

export const deleteItem = (item: Object) => {
  return async (dispatch: Dispatch<*>, getState: () => Object) => {
    dispatch(beginDataApiCall())
    const state = getState()
    await Api.post(Api.endpoints.delete, { ...item })
    dispatch(removeItem(item.wfid))
    const edge = getEdgesFromToNodeId(state, item.wfid)[0]
    edge && dispatch(removeItem(edge.wfid))
    dispatch(dataApiCallSuccess())
  }
}

export const resetDataState = () => {
  return async (dispatch) => {
    dispatch(resetState())
  }
}

export const createThirdPartyInfluence = (item: Object) => {
  return async (dispatch: Dispatch<*>) => {
    dispatch(beginDataApiCall())
    const result = await Api.post(Api.endpoints.createThirdPartyInfluence, { influenceWfid: item.wfid })
    if (result != null) {
      const influenceUpdates = get(result, 'data.influenceUpdates')
      dispatch(addItem({ ...item, ...influenceUpdates }))
      dispatch(dataApiCallSuccess())
    } else {
      dispatch(apiCallError())
      console.log('Error: Could not create third party influence')
    }
  }
}

export const deleteThirdPartyInfluence = (item: Object) => {
  return async (dispatch: Dispatch<*>, getState: () => Object) => {
    const state = getState()
    const result = await Api.post(Api.endpoints.deleteThirdPartyInfluence, { influenceWfid: item.wfid })
    if (result != null) {
      const influenceUpdates = get(result, 'data.influenceUpdates')
      dispatch(addItem({ ...item, ...influenceUpdates }))
      dispatch(removeItem(result.data.deletedInfluenceWfid))
      const edge = getEdgesFromToNodeId(state, result.data.deletedInfluenceWfid)[0]
      edge && dispatch(removeItem(edge.wfid))
    } else {
      console.log('Error: Could not delete third party influence')
    }
  }
}
