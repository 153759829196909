(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfFilteringTool', wfFilteringTool);

	wfFilteringTool.$inject = [];
	
	function wfFilteringTool() {
		var directive = {
			restrict: "E",
			templateUrl: "scripts/wf/filtering/wfFilteringTool.directive.html"
		}

		return directive;
	}
})();