import * as React from 'react' // eslint-disable-line no-unused-vars
import { Path, withLeaflet } from 'react-leaflet'
import isEqual from 'fast-deep-equal'

class CurveLine extends Path {
  createLeafletElement(props) {
    return L.curve(props.positions, { ...props.options, className: props.className }) // eslint-disable-line no-undef
  }

  updateLeafletElement(fromProps, toProps) {
    if (toProps.positions !== fromProps.positions) {
      this.leafletElement.setPath(toProps.positions)
    }
    if (!isEqual(toProps.options, fromProps.options)) {
      this.leafletElement.setOptions(toProps.options)
    }
    // TODO try fixing options by using that
    this.setStyleIfChanged(fromProps, toProps)
  }
}

export default withLeaflet(CurveLine)
