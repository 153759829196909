import React from 'react'
import { react2angular } from 'react2angular'
import NextEmailSendoutInfoDialog from '../components/SupplyChain/NextEmailSendoutInfoDialog'
import { WrapProvider } from './providers'

const props = [
  'open',
  'mailPurpose',
  'networkId',
  'onClosed',
]

class NextEmailSendoutInfoDialogWrapper extends React.Component {
  render() {
    const { wfAuth, ...rest } = this.props
    return (
      <WrapProvider wfAuth={wfAuth}>
        <NextEmailSendoutInfoDialog {...rest} />
      </WrapProvider>
    )
  }
}

export default react2angular(NextEmailSendoutInfoDialogWrapper, props, ['wfAuth'])
