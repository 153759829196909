import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Loading from '@worldfavor/portal/scenes/Loading'

import { getNodeFromNodeId } from '../../selectors/dataSelector'
import { regExpSearchMapper, sortAlphabetically } from '@worldfavor/utils/helpers'
import { exchangePath, influencePath } from '@worldfavor/constants/paths'
import EmptyState from '@worldfavor/components/EmptyState'
import FulfillmentStatusIndicator from '../SupplyChain/FulfillmentStatusIndicator'
import HighlightedText from '@worldfavor/components/Text/HighlightedText'
import SearchBar from '@worldfavor/components/SearchBar'
import SearchCount from '@worldfavor/components/SearchBar/SearchCount'
import Table from '@worldfavor/components/Table'
import TableHeaderCell from '@worldfavor/components/Table/TableHeaderCell'
import DropdownMenu from '@worldfavor/components/DropdownMenu'
import MenuItem from '@material-ui/core/MenuItem'
import Dialog from '@worldfavor/components/Dialog'
import { useDialogState } from '@worldfavor/hooks'
import Colors from '@worldfavor/constants/colors'
import Tooltip from '@material-ui/core/Tooltip'
import { createThirdPartyInfluence, deleteThirdPartyInfluence } from '../../actions/dataThunk'
import get from 'lodash/get'

const withStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  searchBarContainer: {
    paddingBottom: 30,
  },
  searchBar: {
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    marginBottom: 50,
  },
  onFocusSearchBarBorder: {},
  roundedSearchBar: {
    borderRadius: 50 + '!important',
  },
  row: {
    cursor: 'pointer',
    '&:hover $titleCell': {
      textDecoration: 'underline',
    },
  },
  image: {
    width: 30,
    height: 30,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 3,
    marginRight: 24,
  },
  cellLink: {
    display: 'block',
    '&, &:hover': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
  },
  linkMenuItem: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    outline: 'none',
    '&:focus': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      outline: 'none',
    },
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  titleCell: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
  },
  fulfillmentStatus: {
    maxWidth: 170,
  },
  emptyState: {
    padding: '50px 0',
  },
  handIcon: {
    marginRight: 15,
    color: Colors.orange,
  },
  resultCount: {
    fontWeight: theme.typography.fontWeights.medium,
    fontSize: theme.typography.fontSizes.medium,
  },
  preTableInfo: {
    paddingTop: 15,
    paddingBottom: 15,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

const rowKeyExtractor = ({ item: { node: { wfid } } = {} } = {}) => `row-${wfid}`

const InfluencesTable = (props) => {
  const classes = withStyles(props)
  const dispatch = useDispatch()
  const { data, targetOrganizationsByWfid, influenceContentsByWfid, intl, history } = props
  const [searchText, setSearchText] = useState('')
  const [resultCount, setResultCount] = useState(data.length)
  const searchMapper = regExpSearchMapper(['node.title', x => get(targetOrganizationsByWfid[x.node.organizationWfid], 'name')], searchText)
  const [filteredData, setFilteredData] = useState([])
  const [open, openDialog, closeDialog] = useDialogState(false)
  const [saving, setSaving] = useState(false)
  const [influenceToStartReportingOnBehalf, setInfluenceToStartReportingOnBehalf] = useState(null)
  const [influenceToStopReportingOnBehalf, setInfluenceToStopReportingOnBehalf] = useState(null)
  const [loading, setLoading] = useState(false)
  const reportOnBehalfDialogActions = [
    {
      label: <FormattedMessage id={'general.close'} />,
      onClick: _closeDialog,
      variant: 'text',
    },
    {
      label: <FormattedMessage id={'supplyChain.thirdPartyReporting.startReportingDialog.button'} />,
      onClick: startReportingOnBehalf,
      variant: 'text',
      hide: Boolean(!influenceToStartReportingOnBehalf),
      saving,
    },
    {
      label: <FormattedMessage id={'supplyChain.thirdPartyReporting.stopReportingDialog.button'} />,
      onClick: stopReportingOnBehalf,
      variant: 'text',
      hide: Boolean(!influenceToStopReportingOnBehalf),
      saving,
    },
  ]

  useEffect(() => {
    setLoading(true)
    const sortedFilterResult = sortAlphabetically(data, 'node.title').map(searchMapper).filter(x => Boolean(x))
    onDataFiltered(sortedFilterResult)
    setLoading(false)
  }, [data, searchText])

  function onDataFiltered(data) {
    setFilteredData(data)
    if (resultCount !== data.length) {
      setResultCount(data.length)
    }
  }

  function onSearchChange(event) {
    setSearchText(event.target.value)
  }

  function onClear() {
    setSearchText('')
  }

  function renderCount() {
    if (data.length && !filteredData) {
      return null
    }

    return <SearchCount count={resultCount} total={data.length} />
  }

  function getInfluenceLink(newRoute, wfid, id) {
    if (newRoute) {
      return exchangePath(wfid)
    }
    return influencePath(id)
  }

  function renderColumnCell({ column }) {
    const columns = {
      title: <TableHeaderCell label={<FormattedMessage id="general.request" values={{ count: 1 }} />} />,
      organization: <TableHeaderCell label={<FormattedMessage id="general.organization" values={{ count: 1 }} />} />,
      deadline: <TableHeaderCell label={<FormattedMessage id={'general.deadline'} />} />,
      fulfillmentStatus: <TableHeaderCell label={<FormattedMessage id={'general.fulfillmentStatus'} />} />,
      actions: <span />,
    }

    return columns[column] || <TableHeaderCell label={<FormattedMessage id={`general.${column}`} />} />
  }

  function renderRowCell({ row, column }) {
    const { search } = row.match
    const { wfid, wfcid, id, useNewRoute, title, organizationWfid, fulfillmentDueAt } = row.item.node
    const imageUrl = column === 'title' ? get(influenceContentsByWfid[wfcid], 'imageUrl') : undefined
    const influenceUrl = getInfluenceLink(useNewRoute, wfid, id)

    const dropdownMenuActions = [
      {
        id: 1,
        label: <FormattedMessage id={'general.open'} />,
        classes: { root: classes.linkMenuItem },
        component: Link,
        to: influenceUrl,
        show: true,
      },
      {
        id: 2,
        label: <FormattedMessage id={'supplyChain.thirdPartyReporting.startReportingButtonText'} />,
        onClick: onStartReportingOnBehalfMenuItemClick,
        show: !row.item.node.hasThirdPartyReporter,
      },
      {
        id: 3,
        label: <FormattedMessage id={'supplyChain.thirdPartyReporting.stopReportingButtonText'} />,
        onClick: onStopReportingOnBehalfMenuItemClick,
        show: row.item.node.hasThirdPartyReporter,
      },
    ]
    const columns = {
      title: (
        <div className={classes.titleWrapper}>
          {imageUrl && <div style={{ backgroundImage: `url('${imageUrl}')` }} className={classes.image} />}
          <span className={classes.titleCell}><HighlightedText text={title} match={search} /></span>
        </div>
      ),
      organization: (
        <>
          {
            row.item.node.hasThirdPartyReporter && (
              <Tooltip placement="top" title={<FormattedMessage id={'supplyChain.thirdPartyReporting.reportingOnBehalfTooltip'} />}>
                <span className={classes.handIcon}><i className="fas fa-hands-helping" /></span>
              </Tooltip>
            )}
          <HighlightedText text={get(targetOrganizationsByWfid[organizationWfid], 'name')} match={search} />
        </>
      ),
      deadline: fulfillmentDueAt && (
        <FormattedDate
          year={'numeric'}
          day={'2-digit'}
          month={'2-digit'}
          value={fulfillmentDueAt}
        />
      ),
      fulfillmentStatus: row.item.node && (
        <div className={classes.fulfillmentStatus}>
          <FulfillmentStatusIndicator
            influence={row.item.node}
          />
        </div>
      ),
      actions: (
        <DropdownMenu item={row}>
          {
            dropdownMenuActions.filter(actions => actions.show).map(({ id, label, show, ...rest }) => (
              <MenuItem key={`menu-option-${id}`} {...rest}>{ label }</MenuItem>
            ))
          }
        </DropdownMenu>
      ),
    }

    if (column === 'actions') {
      return columns[column] || ''
    }
    else {
      return (
        <Link className={classes.cellLink} to={influenceUrl}>
          {columns[column] || ''}
        </Link>
      )
    }
  }

  function _closeDialog() {
    setInfluenceToStartReportingOnBehalf(null)
    setInfluenceToStopReportingOnBehalf(null)
    closeDialog()
  }

  function onStartReportingOnBehalfMenuItemClick(event, item) {
    openDialog()
    setInfluenceToStartReportingOnBehalf(item.item.node)
  }

  function onStopReportingOnBehalfMenuItemClick(event, item) {
    openDialog()
    setInfluenceToStopReportingOnBehalf(item.item.node)
  }

  async function startReportingOnBehalf() {
    try {
      setLoading(true)
      setSaving(true)
      await dispatch(createThirdPartyInfluence(influenceToStartReportingOnBehalf))
      _closeDialog()
      setLoading(false)
    } catch (error) {
      console.error('Could not create third party influence', error)
    } finally {
      setSaving(false)
    }
  }

  async function stopReportingOnBehalf() {
    try {
      setLoading(true)
      setSaving(true)
      await dispatch(deleteThirdPartyInfluence(influenceToStopReportingOnBehalf))
      _closeDialog()
      setLoading(false)
    } catch (error) {
      console.error('Could not delete third party influence', error)
    } finally {
      setSaving(false)
    }
  }

  function onRowClick(event, row) {
    const { wfid, id, useNewRoute } = row.item.node
    const influenceUrl = getInfluenceLink(useNewRoute, wfid, id)
    history.push(influenceUrl)
  }

  function getTargetOrganizationName() {
    if (influenceToStopReportingOnBehalf) {
      return targetOrganizationsByWfid[influenceToStopReportingOnBehalf.organizationWfid].name
    }

    if (influenceToStartReportingOnBehalf) {
      return targetOrganizationsByWfid[influenceToStartReportingOnBehalf.organizationWfid].name
    }
  }

  function getDialogTitle() {
    if (influenceToStopReportingOnBehalf) {
      return (
        <FormattedMessage
          id={'supplyChain.thirdPartyReporting.stopReportingDialog.title'}
          values={{ organizationName: getTargetOrganizationName() }}
        />
      )
    }

    if (influenceToStartReportingOnBehalf) {
      return (
        <FormattedMessage
          id={'supplyChain.thirdPartyReporting.startReportingDialog.title'}
          values={{ organizationName: getTargetOrganizationName() }}
        />
      )
    }
  }

  function getDialogDescription() {
    if (influenceToStopReportingOnBehalf) {
      return (
        <FormattedMessage
          id={'supplyChain.thirdPartyReporting.stopReportingDialog.description'}
          values={{ organizationName: getTargetOrganizationName() }}
        />
      )
    }

    if (influenceToStartReportingOnBehalf) {
      return (
        <FormattedMessage
          id={'supplyChain.thirdPartyReporting.startReportingDialog.description'}
          values={{ organizationName: getTargetOrganizationName() }}
        />
      )
    }
  }

  return (
    <>
      <div className={classes.searchBarContainer}>
        <SearchBar
          onClear={onClear}
          renderCount={renderCount}
          rounded
          classes={{
            root: classes.searchBar,
            rounded: classes.roundedSearchBar,
            onFocusBorder: classes.onFocusSearchBarBorder,
          }}
          inputProps={{
            placeholder: intl.formatMessage({ id: 'search.keywordPlaceholder' }),
            value: searchText,
            onChange: onSearchChange,
          }}
        />
      </div>
      {resultCount ?
        (
          <div className={classes.preTableInfo}>
            <span className={classes.resultCount}>
              <FormattedMessage id="search.resultsCount" values={{ count: `${resultCount}` }} />
            </span>
          </div>
        ) : null
      }
      <Paper className={classes.root}>
        {
          (data.length && !filteredData.length) ? (
            <EmptyState
              className={classes.emptyState}
              title={<FormattedMessage id={'search.noInfluencesMatch'} />}
              iconClass={'fas fa-search'}
            />
          ) : (
            loading ? <Loading /> : (
              <Table
                classes={{ row: classes.row }}
                className={classes.table}
                data={filteredData}
                columns={['title', 'organization', 'deadline', 'fulfillmentStatus', 'actions']}
                columnWidths={['32%', '25%', '15%', '20%', '8%']}
                renderRowCell={renderRowCell}
                renderColumnCell={renderColumnCell}
                rowKeyExtractor={rowKeyExtractor}
                rowsPerPage={10}
                enablePagination={data.length && data.length > 10}
                onRowClick={onRowClick}
                rowHover
              />
            )
          )
        }
      </Paper>
      {
        open && (
          <Dialog
            open={open}
            title={getDialogTitle()}
            description={getDialogDescription()}
            actions={reportOnBehalfDialogActions}
            info
          />
        )
      }
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  const influenceContentsByWfid = ownProps.data
    .map(item => getNodeFromNodeId(state, item.node.wfcid))
    .reduce((acc, item) => ({
      ...acc,
      [item.wfid]: item,
    }), {})

  const targetOrganizationsByWfid = [...new Set(ownProps.data.map(item => item.node.organizationWfid))]
    .map(wfid => getNodeFromNodeId(state, wfid))
    .reduce((acc, item) => ({
      ...acc,
      [item.wfid]: item,
    }), {})

  return {
    targetOrganizationsByWfid,
    influenceContentsByWfid,
  }
}

export default withRouter(injectIntl(connect(mapStateToProps)(InfluencesTable)))
