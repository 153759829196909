import React from 'react'
import Image from '@worldfavor/components/Image'
import TableElementsStyles from './TableElementsStyles'
import { ConstVars } from '@worldfavor/constants'
import { FormattedMessage } from 'react-intl'
import NewFilterDropdown from '@worldfavor/components/Filters/NewFilterDropdown'
import TooltipWithCopyToClipboard from '@worldfavor/components/Text/TooltipWithCopyToClipboard'
import _ from 'lodash'

export function getTableColumns(options) {
  const { filters, onFilterChange, clearFilter, expandCells } = options
  return [
    {
      Header: <FormattedMessage id="dataManager.analysisPackageTitle" />,
      accessor: ConstVars.AnalyzeResultsAccessors.Name,
      width: !expandCells ? 200 : 500,
      options: {
        filter: filters && filters.find(filter => filter.id === ConstVars.AnalyzeResultsAccessors.Name),
        onFilterChange,
        clearFilter,
      },
    },
    // {
    //   Header: <FormattedMessage id="dataManager.organization" />,
    //   accessor: ConstVars.AnalyzeResultsAccessors.Organization,
    //   width: !expandCells ? 100 : 300,
    // },
    {
      Header: <FormattedMessage id="dataManager.categoryNumber" values={{ categoryNumber: '1' }} />,
      accessor: ConstVars.AnalyzeResultsAccessors.CategoryOne,
      width: !expandCells ? 150 : 300,
    },
    // {
    //   Header: <FormattedMessage id="dataManager.categoryNumberDescription" values={{ categoryNumber: '1' }} />,
    //   accessor: ConstVars.AnalyzeResultsAccessors.CategoryOneDescription,
    //   width: !expandCells ? 300 : 500,
    // },
    // {
    //   Header: <FormattedMessage id="dataManager.categoryNumber" values={{ categoryNumber: '2' }} />,
    //   accessor: ConstVars.AnalyzeResultsAccessors.CategoryTwo,
    //   width: !expandCells ? 150 : 300,
    // },
    // {
    //   Header: <FormattedMessage id="dataManager.categoryNumberDescription" values={{ categoryNumber: '2' }} />,
    //   accessor: ConstVars.AnalyzeResultsAccessors.CategoryTwoDescription,
    //   width: !expandCells ? 300 : 500,
    // },
    // {
    //   Header: <FormattedMessage id="dataManager.categoryNumber" values={{ categoryNumber: '3' }} />,
    //   accessor: ConstVars.AnalyzeResultsAccessors.CategoryThree,
    //   width: !expandCells ? 150 : 300,
    // },
    // {
    //   Header: <FormattedMessage id="dataManager.categoryNumberDescription" values={{ categoryNumber: '3' }} />,
    //   accessor: ConstVars.AnalyzeResultsAccessors.CategoryThreeDescription,
    //   width: !expandCells ? 300 : 500,
    // },
    {
      Header: <FormattedMessage id="dataManager.order" />,
      accessor: ConstVars.AnalyzeResultsAccessors.Order,
      width: 150,
    },
    {
      Header: <FormattedMessage id="dataManager.calcStepOrder" />,
      accessor: ConstVars.AnalyzeResultsAccessors.CalcStepOrder,
      width: 150,
    },
    {
      Header: <FormattedMessage id="dataManager.requirement" />,
      accessor: ConstVars.AnalyzeResultsAccessors.Requirement,
      width: !expandCells ? 150 : 500,
    },
    {
      Header: <FormattedMessage id="dataManager.matched" />,
      accessor: ConstVars.AnalyzeResultsAccessors.Matched,
      width: 150,
      options: {
        filter: filters && filters.find(filter => filter.id === ConstVars.AnalyzeResultsAccessors.Matched),
        onFilterChange,
        clearFilter,
      },
    },
    {
      Header: <FormattedMessage id="dataManager.operation" />,
      accessor: ConstVars.AnalyzeResultsAccessors.Operation,
      width: 150,
    },
    {
      Header: <FormattedMessage id="dataManager.stepScore" />,
      accessor: ConstVars.AnalyzeResultsAccessors.StepScore,
      width: 150,
    },
    {
      Header: <FormattedMessage id="dataManager.opposite" />,
      accessor: ConstVars.AnalyzeResultsAccessors.RequiresOppositeAnswer,
      width: 150,
    },
    {
      Header: <FormattedMessage id="dataManager.break" />,
      accessor: ConstVars.AnalyzeResultsAccessors.Break,
      width: 150,
    },
    {
      Header: <FormattedMessage id="dataManager.additionalTasks" />,
      accessor: ConstVars.AnalyzeResultsAccessors.AdditionalTasks,
      width: !expandCells ? 180 : 300,
    },
    {
      Header: <FormattedMessage id="dataManager.categorizationsAssigned" />,
      accessor: ConstVars.AnalyzeResultsAccessors.CategorizationsAssigned,
      width: !expandCells ? 150 : 250,
    },
    {
      Header: <FormattedMessage id="dataManager.totalDataPointScore" />,
      accessor: ConstVars.AnalyzeResultsAccessors.TotalDataPointScore,
      width: 150,
    },
    {
      Header: <FormattedMessage id="dataManager.totalAnalysisScore" />,
      accessor: ConstVars.AnalyzeResultsAccessors.TotalAnalyzeJobScore,
      width: 150,
    },
  ]
}

export function renderRowCell(value, row, column, cell) {
  const classes = TableElementsStyles()
  const imageUrl = _.get(row, 'original.imageUrl')
  if (column && column.id === ConstVars.AnalyzeResultsAccessors.Name) {
    return (
      <div className={classes.cell}>
        <Image
          className={classes.image}
          size={'cover'}
          src={imageUrl}
        />
        <TooltipWithCopyToClipboard classes={{ root: classes.labelCell }} text={cell.value} />
      </div>
    )
  }
  if (cell && (cell.value === true)) {
    return <FormattedMessage id={'modules.questionAnswerTypes.yes'} />
  }
  if (cell && (cell.value === false)) {
    return <FormattedMessage id={'modules.questionAnswerTypes.no'} />
  }
  if (column && column.id === ConstVars.AnalyzeResultsAccessors.Operation) {
    switch (cell.value) {
      case 0: {
        return <FormattedMessage id="dataManager.operationOptions.addition" />
      }
      case 1: {
        return <FormattedMessage id="dataManager.operationOptions.subtraction" />
      }
      case 2: {
        return <FormattedMessage id="dataManager.operationOptions.multiplication" />
      }
      case 3: {
        return <FormattedMessage id="dataManager.operationOptions.division" />
      }
      case 4: {
        return <FormattedMessage id="dataManager.operationOptions.replace" />
      }
      default:
        return null
    }
  }
  return <div className={classes.cell}><TooltipWithCopyToClipboard text={value} /></div>
  
}

export function renderColumnCell(Header, id, column) {
  if (column.options) {
    const { filter, onFilterChange, clearFilter } = column.options

    if (filter && onFilterChange) {
      return (
        <NewFilterDropdown
          filter={filter}

          onChange={onFilterChange}
          onClear={clearFilter}
        />
      )
    }
  }
  return column.Header
}
