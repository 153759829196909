// @flow
import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import AddCircle from '@material-ui/icons/AddCircle'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'

import classNames from 'classnames'

import CoMapperInfoDialog from './CoMapperInfoDialog'
import OrganizationFormDialog from './OrganizationForm/OrganizationFormDialog'
import OrganizationPickerDialog from './OrganizationPickerDialog'
import { getAvailableActorTypes } from '../../selectors/productSelector'
import { addCrowdSourcedOrganization } from '../../actions/productThunk'

import { useDialogState } from '@worldfavor/hooks'
import ErrorDialog from '@worldfavor/components/Dialog/ErrorDialog'
import Image from '@worldfavor/components/Image'
import IconButton from "@material-ui/core/IconButton"
import EditIcon from '@material-ui/icons/Edit'

type Props = {
  organizationImage: string,
  organizationName: string,
  allowAdding?: boolean,
  showLabel?: boolean,
  compact?: boolean,
  compactButton?: boolean,

  uniqueOrganizations: Array<any>,
  mappingEntityWfid: string,
  networkWfid: string,
  influenceWfid: string,
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  imageWrapper: {
    width: 30,
    height: 30,
    display: 'flex',
    marginRight: 16,
  },
  image: {
    width: 30,
    height: 30,
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelText: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    whiteSpace: 'pre',
  },
  orgName: {
    color: theme.palette.common.black,
    fontWeight: 500,
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
    maxWidth: 500,
  },
  buttonWrapper: {
    marginLeft: 24,
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
  },
  editIcon: {
    color: theme.palette.primary.main,
  },
  addIcon: {
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tooltip: {
    margin: 0,
    fontSize: 12,
    backgroundColor: theme.palette.primary.dark,
    '& .tooltip-arrow:before': {
      borderColor: theme.palette.primary.dark + ' transparent transparent transparent !important',
    },
  },
  compact: {
    '& $imageWrapper': {
      width: 25,
      height: 25,
      marginRight: 10,
    },
    '& $image': {
      width: 25,
      height: 25,
    },
    '& $textWrapper': {
      flexDirection: 'row',
      alignItems: 'center',
    },
    '& $labelText': {
      marginRight: 6,
    },
    '& $orgName': {
      fontSize: 12,
    },
    '& $buttonWrapper': {
      marginLeft: 10,
    },
    '& $editIcon': {
      width: 20,
      height: 20,
    },
  },
  clickable: {
    cursor: 'pointer',
  },
}))

const CoMapperStatus = (props: Props) => {
  const {
    organizationImage,
    organizationName,
    allowAdding,
    showLabel,
    compact,
    compactButton,

    uniqueOrganizations,
    mappingEntityWfid,
    networkWfid,
    influenceWfid,
  } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()

  const [open, openDialog, closeDialog] = useDialogState(false)
  const [isErrorDialogOpen, openErrorDialog, closeErrorDialog] = useDialogState(false)
  const [coMapperFormOpen, openCoMapperForm, closeCoMapperForm] = useDialogState(false)
  const [coMapperPickerOpen, openCoMapperPicker, closeCoMapperPicker] = useDialogState(false)

  async function onAddCoMapper(organization) {
    try {
      await dispatch(addCrowdSourcedOrganization(mappingEntityWfid, networkWfid, influenceWfid, {
        useExisting: true,
        content: organization,
        isCoMapper: true,
      }))
      closeCoMapperPicker()
      return true
    } catch (e) {
      openErrorDialog()
      console.error(`Couldn't add co-mapper organization to the mappingEntity`, e)
      return false
    }
  }

  async function onCreateCoMapper(formData) {
    try {
      await dispatch(addCrowdSourcedOrganization(mappingEntityWfid, networkWfid, influenceWfid, { ...formData, isCoMapper: true }))
      closeCoMapperForm()
      return true
    } catch (e) {
      openErrorDialog()
      console.error(`Couldn't create organization`, e)
      return false
    }
  }

  function onCreateCoMapperClick() {
    closeCoMapperPicker()
    setTimeout(() => {
      openCoMapperForm()
    }, 100)
  }

  function renderAddCoMapperButton() {
    if (compactButton) {
      return (
        <div className={classes.buttonWrapper}>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={<FormattedMessage id={'supplyChain.mapper.coMapperOrganization.setCoMapper'} />}
            placement="top"
          >
            <AddCircle classes={{ root: classes.addIcon }} />
          </Tooltip>
        </div>
      )
    }

    return (
      <div className={classes.buttonWrapper}>
        <Button variant="outlined">
          <FormattedMessage id={'supplyChain.mapper.coMapperOrganization.setCoMapper'} />
        </Button>
      </div>
    )
  }

  function renderEditButton() {
    return (
      <div className={classes.buttonWrapper}>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={<FormattedMessage id={'supplyChain.mapper.coMapperOrganization.changeCoMapper'} />}
          placement="top"
        >
          <IconButton
            className={classes.iconButton}
            size={'small'}
          >
            <EditIcon classes={{ root: classes.editIcon }} />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div
        onClick={allowAdding ? openDialog : undefined}
        className={
          classNames(classes.root, {
            [classes.compact]: compact,
            [classes.clickable]: allowAdding,
          })
        }
      >
        <div className={classes.container}>
          {
            organizationImage && (
              <div className={classes.imageWrapper}>
                <Image src={organizationImage} className={classes.image} size={'cover'} />
              </div>
            )
          }
          <div className={classes.textWrapper}>
            {
              showLabel && (
                <span className={classes.labelText}>
                  <FormattedMessage id={'supplyChain.mapper.coMapperOrganization.label'} />{':'}
                </span>
              )
            }
            {
              organizationName ? (
                <span className={classes.orgName}>{organizationName}</span>
              ) : (
                <FormattedMessage id={'supplyChain.mapper.coMapperOrganization.notSet'} />
              )
            }
          </div>
          { allowAdding && (organizationName ? renderEditButton() : renderAddCoMapperButton()) }
        </div>
      </div>

      <CoMapperInfoDialog
        open={open}
        onCancel={closeDialog}
        onContinue={openCoMapperPicker}
      />

      <OrganizationFormDialog
        open={coMapperFormOpen}
        onClose={closeCoMapperForm}
        onCancel={closeCoMapperForm}
        onFinish={onCreateCoMapper}
        disableBackdropClick
        disableEscapeKeyDown
      />

      <OrganizationPickerDialog
        open={coMapperPickerOpen}
        onCancel={closeCoMapperPicker}
        onAdd={onAddCoMapper}
        onCreate={onCreateCoMapperClick}
        organizations={uniqueOrganizations}
      />

      <ErrorDialog
        open={isErrorDialogOpen}
        closeDialog={closeErrorDialog}
        showReloadButton
        showContactSupport
      />
    </React.Fragment>
  )
}

export default CoMapperStatus
