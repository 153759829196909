(function() {
	'use strict';
	angular
		.module('wf.common')
		.directive('wfLocation', wfLocationDirective);


	wfLocationDirective.$inject = [ 'NgMap', '$timeout' ];
	function wfLocationDirective(NgMap, $timeout) {
		var directive = {
			controllerAs: 'locationVm',
			controller: wfLocationController,
			templateUrl: 'scripts/wf/location/wfLocation.directive.html',
			restrict: 'EA',
		
		};
		return directive;

		function wfLocationController() {
			var vm = this;
			
			//Final object - vm.location - to be sent to the backend
			vm.location = {
				name: "",
				formattedAddress: "",
				latitude: null,
				longitude: null
			};

			vm.addMarker = function(event) {
				var ll = event.latLng;
				vm.location.latitude = ll.lat();
				vm.location.longitude = ll.lng();
				vm.coordinatesToAddress(ll.lat(), ll.lng());
			}
			vm.deleteMarker = function() {
				vm.location.latitude = null;
				vm.location.longitude = null;
				vm.location.formattedAddress = "";
			};

  			vm.types = "['establishment']";
  			vm.placeChanged = function() {
    			vm.place = this.getPlace();
				if (vm.place.geometry) {
					if (vm.place.geometry.viewport) {
						vm.map.fitBounds(vm.place.geometry.viewport);
					}else {
						vm.map.setCenter(vm.place.geometry.location);
						vm.map.setZoom(17);  // Why 17? Because it looks good.
					}
					vm.location.latitude = vm.place.geometry.location.lat();
					vm.location.longitude = vm.place.geometry.location.lng();
					vm.location.formattedAddress = vm.place.formatted_address;
				}
				else {
					console.log("The address does not exist");
				}
  			}
  			NgMap.getMap().then(function(map) {
    			vm.map = map;
  			});

			var geocoder = new google.maps.Geocoder;
			//Get Address from coordinates
			vm.coordinatesToAddress = function(lat, lng) {
				var latlng = new google.maps.LatLng(lat, lng);

				geocoder.geocode({ 'latLng': latlng }, function(results, status) {
					if (status == google.maps.GeocoderStatus.OK) {
						if (results[0]) {
							vm.location.formattedAddress = results[0].formatted_address;
							$timeout();
						}else {
							console.log("No Location Found (coordinatesToAddress - geocode)");
						}
					}
				});
			}
		}
	}
	
})();