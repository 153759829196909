angular
.module("wf.common")
.directive("wfCountUp", function()
{
	return {
		restrict: 'E',
		template: "<span></span>",
		scope: {
			target: "<",
			speed: "<",
			start: "<",
			format: "<"
		},
		link: function ($scope, $element) {
			$scope.valueElement = $element.children("span")[0];
		},
		controller: [ "$scope", "$element", "$attrs", "$timeout", "$filter", function ($scope, $element, $attrs, $timeout, $filter)
		{
			var
				target = $scope.target || 0,
				speed = $scope.speed || 3,
				value,
				output
			;

			value = $scope.start || 0;

			$scope.$watch("target", function (newTarget) {
				target = newTarget;
				$scope.tickNumber();
			});

			$scope.$watch("start", function (newStart) {
				value = newStart;
				$scope.tickNumber();
			});

			$scope.$watch("speed", function (newSpeed) {
				speed = newSpeed;
			});

			$scope.interpolate = function(current, target, delta, speed)
			{
				if ( speed <= 0.0 ) {
					return target;
				}

				var distance = target - current;

				if (distance * distance < 0.000001) {
					return target;
				}
				
				var move = distance * Math.min(Math.max(delta * speed, 0.0), 1.0);

				return current + move;
			}


			$scope.updateNumber = function () {
				if ($scope.valueElement) {
					value = $scope.interpolate(value, target, 0.03, speed);
					if ($scope.format)
						output = numeral(value).format($scope.format);
					else
						output = numeral(value).format(target >= 100000000 ? '0,0.000a' : '0,0'); // Abbreviate if target => 100 million

					$scope.valueElement.innerText = output;
				}
			};

			$scope.tickNumber = function () {
				if (Math.abs(value - target) > 0)
				{
					$scope.updateNumber();
					$timeout($scope.tickNumber, 10);
				}
			};

		} ]
	};

});