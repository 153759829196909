import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfItemTools', wfItemTools)
		.directive('wfItemToolsCompile', wfItemToolsCompile)
		.directive('wfItemToolsAction', wfItemToolsAction)
		.directive('wfItemToolsExecuted', wfItemToolsExecuted)
		.directive('wfItemToolsListener', wfItemToolsListener)
		.service('wfItemToolsService', wfItemToolsService)
	;

	wfItemTools.$inject = ['$translate', 'wfObject','$compile', 'screenLoaderService'];
	
	function wfItemTools($translate, wfObject, $compile, screenLoader) {
		var
			userDataObjectTypes = [
				enums.objectType.orgActivity,
				enums.objectType.orgDocument,
				enums.objectType.link,
				enums.objectType.statement,
				enums.objectType.internalComment,
				enums.objectType.questionAnswer,
				enums.objectType.measureAnswer
			]
		;
		 
		var directive = {
			restrict: 'E',
			scope: {
				'collection': '=',
				'item': '=',
				'itemComposite': '<',
				'parent': '=',
				'discreet': '=',
				'simple': '=',
				'recompile': '=',
				'actions': '=',
				'relationsCompiler': '=',
				'itemContent': '=',
				'itemRelation': '=',
				'belongsTo': '=',
				'influence': '=',
				'customFormSpecification': '<'
			},
			require: '?^^wfItemToolsCompile',
			transclude: true,
			templateUrl: 'scripts/wf/item/item-tools.directive.html',
			controllerAs: 'vm',
			controller: ['$scope','modalService','$element','dataOperationsService','wfAuth','$attrs','$transclude','$parse', wfItemToolsController],
			link: function (scope, element, attributes, wfItemToolsCompileCtrl) {
				scope.wfItemToolsCompileCtrl = wfItemToolsCompileCtrl; 
			}
		};
		return directive;

		function wfItemToolsController($scope, modal, $element, dataOps, wfAuth, $attrs, $transclude, $parse) {
			var
				vm = this,
				innerContent,
				actionsSpec,
				actions = {
					create: true,
					update: true,
					del: true,
					deleteTag: true,
					conditions: true,
					belonging: true,
					requirements: true,
					add: false
				},
				isDataRelationByUser = true,
				isInnerContentByUser = true,
				innerContent,
				i,
				simplifyForm = $attrs.hasOwnProperty("simplifyForm") ? ($attrs.simplifyForm.length ? $parse($attrs.simplifyForm)($scope.$parent) : true) : false
			;

			if (!wfAuth.canUserWrite()) {
				$element.remove();
				return;
			}

			if ($scope.itemContent && $scope.itemRelation) {
				vm.innerContent = innerContent = $scope.itemContent;
				vm.item = vm.itemRelation = $scope.itemRelation;
			}
			else {
				if ($scope.itemComposite || ($scope.item && $scope.item.isComposite) || ($scope.item && $scope.item.composite)) {
					vm.itemComposite = $scope.itemComposite || $scope.item;
					vm.innerContent = innerContent = vm.itemComposite.content;
					vm.itemRelation = vm.itemComposite.dataRelation;
					vm.item = vm.itemComposite;
				}
				else if ($scope.item && $scope.item.wfcid && (vm.innerContent = innerContent = $scope.item.childContent)) {
					vm.item = vm.itemRelation = $scope.item;
				}
				else {
					if ($scope.item && $scope.item.type === 71)
						vm.innerContent = innerContent = vm.item = $scope.item;
					else {
						vm.innerContent = innerContent = vm.item = $scope.item;
					}
				}
			}

			if (!innerContent || (!$scope.item && !$scope.itemComposite) || (!isDataRelationByUser && !isInnerContentByUser)) {
				$scope.$destroy();
				$element.remove();
				return;
			}

			vm.edit = edit;
			vm['delete'] = deleteItem;
			vm.editSettings = editSettings;
			vm.editConditions = editConditions;
			vm.relationsCompiler = $scope.relationsCompiler;
			vm.consoleLog = consoleLog;
			vm.editBelonging = editBelonging;
			vm.editRequirement = editRequirement;
			vm.editUserOrgAccessSettings = editUserOrgAccessSettings;
			vm.isUserData = false;
			vm.copyToClipboard = copyToClipboard;
			$scope.modal = modal;
			$scope._ = _;

			vm.isUserData = _.includes(userDataObjectTypes, innerContent.type);
			
			if ($scope.actions) {
				// "create,update,delete"
				actionsSpec = $scope.actions.split(',');
				i = actionsSpec.length;
				
				_.forEach(actions, function (value, key) {
					actions[key] = false;
				});
				
				while (i--) {
					switch (actionsSpec[i]) {
						case 'create': actions.create = true; continue;
						case 'update': actions.update = true; continue;
						case 'delete': actions.del = true; continue;
						case 'conditions': actions.conditions = true; continue;
						case 'deleteTag': actions.deleteTag = true; continue;
						case 'belonging': actions.belonging = true; continue;
						case 'requirements': actions.requirements = true; continue;
						case 'add': actions.add = true; continue;
					}
				}
			}
			vm.actions = actions;

			$element[0].addEventListener("click", function (event) {
				event.dropdown = true;
			}, false);

			// console.log($element.contents())
			$transclude($scope, function(clone, outerScope) {
				var ul;
				// console.log(clone)

				if (clone.length) {
					ul = $element.find("ul.dropdown-menu").first();

					if ("prependTransclude" in $attrs) {
						ul.prepend(clone);
					}
					else {
						ul.append(clone);
					}
				// $compile(clone)($scope);
				}
			});
			// if (element.contents().length) {
			// }
			
			
			// if ($scope.discreet)
			// {
			// 	$element.children().first().dropdown();
			// }

			function copyToClipboard(value) {
				let copiedMessage;

				if (value instanceof Array) {
					value = value.filter(x => x).join(" / ");
					copiedMessage = "Copied \"" + value + "\""
				}
				else if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
					copiedMessage = "Copied \"" + value + "\""
				}
				else if (typeof value === "object") {
					const obj = dataOps.prepareWfObject(value)
					if (value.childContent) {
						obj.childContent = dataOps.prepareWfObject(value.childContent)
						delete obj.childContent.metadata
					}
					if (value.parentContent) {
						obj.parentContent = dataOps.prepareWfObject(value.parentContent)
						delete obj.parentContent.metadata
					}
					delete obj.metadata
					value = JSON.stringify(obj)
					copiedMessage = "Copied object details";
				}


				screenLoader.show(copiedMessage);
				// Create a dummy input to copy the string array inside it
				var dummy = document.createElement("input");

				// Add it to the document
				document.body.appendChild(dummy);

				// Set its ID
				dummy.setAttribute("id", "dummy_id");

				// Output the array into it
				dummy.value = value;

				// Select it
				dummy.select();

				// Copy its contents
				document.execCommand("copy");

				// Remove it as its not needed anymore
				document.body.removeChild(dummy);

				setTimeout(function () {
					screenLoader.hide(700);
				}, 800);
			}

			function editSettings(item) {
				modal.editSettings(item);
				//var model = dataModeller.objectSettings.getCollectionModelFromItem(item);
				//dataOpService.edit(model, {
				//	endpointPath: "multi.savesettings"
				//}).then(function (updatedSettings) {
				//	item.settings = updatedSettings;
				//	if ($scope.collection)
				//	{
				//		$scope.collection.redrawGrid();
				//	}
				//});
			}

			function editConditions(item) {
				modal.editConditions(item);



				//var model = dataModeller.objectConditions.getCollectionModelFromItem(item);

				//dataOpService.edit(model, {
				//	endpointPath: "multi.savesettings"
				//}).then(function (updatedConditions) {
				//	item.condition = updatedConditions;
				//	if ($scope.collection)
				//	{
				//		$scope.collection.reloadItemChildren(item);
				//	}
				//});
			}

			function editRequirement(item) {
				modal.editRequirement(item);
			}

			function editUserOrgAccessSettings(item) {
				modal.edit({
					type: 89,
					wfid: "89-" + item.user_id + "|" + wfAuth.getOrganizationId(), 
					organizationId: wfAuth.getOrganizationId(),
					auth0UserId: item.user_id
				});
			}

			function edit(item) {
				modal.edit(item, { simplifyForm: simplifyForm, customFormSpecification: $scope.customFormSpecification, influence: $scope.influence }).then(function (res) {
					// console.log("edit done")
					if ($scope.wfItemToolsCompileCtrl && $scope.wfItemToolsCompileCtrl.recompileItemElement)
					{
						$scope.wfItemToolsCompileCtrl.recompileItemElement();
					}
				});
			}

			function deleteItem(item, dataRelation) {
				modal.confirmDelete(item).then(function () {
					if ($scope.wfItemToolsCompileCtrl && $scope.wfItemToolsCompileCtrl.removeItemElement)
					{
						$scope.wfItemToolsCompileCtrl.removeItemElement();
					}
					if (dataRelation) {
						// Not needed beacuse relations are removed from server (this if statement never worked because the condition is wrong...)
						// if (dataRelation === 73) {
						// 	dataOps.destroy(dataRelation).then(function () {
						// 		$scope.$emit("itemToolsActionExecuted", "delete", item, dataRelation);
						// 	});
						// 	return;
						// }
						// else
							wfObject.eject(dataRelation.wfid);
					}

					$scope.$emit("itemToolsActionExecuted", "delete", item, dataRelation);

					if ($scope.item && $scope.belongsTo && _.isArray($scope.belongsTo)) {
						_.remove($scope.belongsTo, $scope.item)
					}
				});
				// .then(function () {
				// 	//if ($scope.parent && $scope.parent.childContent === item)
				// 	//{
				// 	//	$scope.parent.childContent = null;
				// 	//	if ($scope.collection)
				// 	//		$scope.collection.reloadItemChildContent($scope.parent);
				// 	//}
				// 	//else if ($scope.collection)
				// 	//{
				// 	//	$scope.collection.remove(item);
				// 	//}
				// });
			}

			function editBelonging(item) {
				// return dataOps.getObject({
				// 	objectType: enums.objectType.dataRelation,
				// 	objectId: 346808
				// }).then(function (res) {

					modal.openCreatorAndPicker({
						title: $translate.instant("SetVisibility"),
						showTopItemAboveTitle: true,
						relationTarget: {
							item: item,
							kind: enums.subItemsKind.parentsByUser,

							settingsByItemType: {
								52: {
									kind: enums.subItemsKind.visible,
									prependItem: function () {
										var publicNetwork = wfObject.get("52-1");
										if (!publicNetwork) {
											publicNetwork = wfObject.inject({
												id: 1,
												type: 52,
												wfid: "52-1",
												title: $translate.instant("PublicInformation")
											})
										}
										return {
											type: 81,
											wfid: '81-|' + "71-11008" + '|' + publicNetwork.wfid,
											parentType: 71,
											parentData1: null,
											childId: item.childId,
											childType: 52,
											wffid: "71-11009",
											wfcid: publicNetwork.wfid,
											childContent: publicNetwork,
											order: 0
										}
									}
								}
								// { // Network
								// 	//kind: enums.subItemsKind.visible
								// 	// queryGetter: function (relationTarget, item) {
								// 	// 	return {
								// 	// 		where:
								// 	// 		{
								// 	// 			type: 61, // VisibilityTag
								// 	// 			objectId: relationTarget.item.id,
								// 	// 			objectType: relationTarget.item.type,
								// 	// 			wffid: item.wfid
								// 	// 		}
								// 	// 	}
								// 	// },
								// 	// add: function () {
								// 	// 	return dataOps.createSubItemRelation(relationTarget.item, $scope.item, relationTarget.kind);
								// 	// },
								// 	// remove: function () {
										
								// 	// }
								// }
							}
						},
						objectTypes: [ enums.objectType.network ],//, enums.objectType.individual ],
						create: false,
						// sourceItem: res,
						// templateId: 65
					}).closed(function () {
						// vm.childrenCompiler.compile();
						if ($scope.wfItemToolsCompileCtrl && $scope.wfItemToolsCompileCtrl.recompileItemElement)
						{
							$scope.wfItemToolsCompileCtrl.recompileItemElement();
						}
					});
				// });
			}

			function toggleSelected(item)
			{
				// Add/remove DataRelation of kind
			}

			function consoleLog()
			{
				if (vm.itemComposite)
					console.log(vm.itemComposite);
				else
					console.log(vm.item);
			}
		}
		
	}
	
	
	
	
	
	
	
	wfItemToolsAction.$inject = ['$translate','$parse'];
	
	function wfItemToolsAction($translate, $parse) {
		// Usage:
		//     <wf-item-tools></wf-item-tools>
		// Creates:
		// 
		var directive = {
			restrict: 'A',
			require: '?^^wfItemToolsCompile',
			// controllerAs: 'vm',
			controller: ['$scope','modalService','$element','$attrs', 'wfAuth', wfItemToolsActionController],
			link: function (scope, element, attributes, wfItemToolsCompileCtrl) {
				scope.wfItemToolsCompileCtrl = wfItemToolsCompileCtrl; 
			}
		};
		return directive;

		function wfItemToolsActionController($scope, modal, $element, $attrs, wfAuth) {
			var
				vm = this,
				action,
				item
			;
			$element.click(function () {
				if (!action)
					action = $attrs.wfItemToolsAction;

				if (!item)
					item = $scope.item;
					
				switch (action) {
					case 'update':
						edit(item.childContent);
						break;
					case 'delete':
						deleteItem(item.childContent, item);
						break;
					case 'conditions':
						editConditions(item.childContent);
						break;
					case 'settings':
						editSettings(item);
						break;
					case 'updateTag':
						edit(item);
						break;
					case 'deleteTag':
						deleteItem(item);
						break;
					case 'editUserOrgAccessSettings':
						editUserOrgAccessSettings(item);
				}
			});

			function editSettings(item) {
				modal.editSettings(item);
			}

			function editConditions(item) {
				modal.editConditions(item);
			}

			function editUserOrgAccessSettings(item) {
				modal.edit({
					type: 89,
					wfid: "89-" + item.user_id + "|" + wfAuth.getOrganizationId(), 
					organizationId: wfAuth.getOrganizationId(),
					auth0UserId: item.user_id
				}).then;
			}

			function edit(item) {
				modal.edit(item, {
					simplifyForm: "simplifyForm" in $attrs
				}).then(function (res) {
					if ($scope.wfItemToolsCompileCtrl && $scope.wfItemToolsCompileCtrl.recompileItemElement)
					{
						$scope.wfItemToolsCompileCtrl.recompileItemElement();
						$scope.$emit("itemToolsActionExecuted", "update", res);
					}
					else if ($scope.wfItemToolsCompile && $scope.wfItemToolsCompile.recompileItemElement)
					{
						$scope.wfItemToolsCompile.recompileItemElement();
						$scope.$emit("itemToolsActionExecuted", "update", res);
					}
				});
			}

			function deleteItem(item, dataRelation) {
				modal.confirmDelete(item).then(function () {
					if ($scope.wfItemToolsCompileCtrl && $scope.wfItemToolsCompileCtrl.removeItemElement)
					{
						$scope.wfItemToolsCompileCtrl.removeItemElement();
						// console.log("delete", item);
					}
					else if ($scope.wfItemToolsCompile && $scope.wfItemToolsCompile.recompileItemElement)
					{
						$scope.wfItemToolsCompile.removeItemElement();
					}
					$scope.$emit("itemToolsActionExecuted", "delete", item, dataRelation);
				});
			}

			function toggleSelected(item)
			{
			}
		}
	}
	
	
	
	
	
	
	
	
	
	
	
	wfItemToolsCompile.$inject = ['$parse'];

	// Recompiles the innerHtml each time an editor modal that was triggerd from wf-item-tools is saved.
	// The wf-item-tools element just needs to be inside the element with the wf-item-tools-compile attribute
	function wfItemToolsCompile($parse) {
		var directive = {
			restrict: 'A',
			transclude: true,
			controller: function () {},
			link: function (scope, element, attrs, ctrl, transclude) {
				var control;
				ctrl.recompileItemElement = function () {
						element.height(element.height());
					// element.animate({ opacity: 0 }, 0, function () {
						element.css({ opacity: 0 });
						
						transclude(scope, function(clone) {
							element.html(clone);
						});
						setTimeout(function () {
							element.css("height", "");
							 element.animate({ opacity: 1 }, 300);
						}, 300)
					// }); 
				};
				
				ctrl.removeItemElement = function () {
					if (control)
						ctrl.recompileItemElement();
					else
					{
						element.animate({ opacity: 0 }, 200, function () {
							element.animate({ height: 0, marginTop: 0, marginBottom: 0, paddingTop: 0, paddingBottom: 0 }, 200, function () {
								element.remove();
							});
						});
					}
				};
				
				if (attrs.wfItemToolsCompile) {
					control = $parse(attrs.wfItemToolsCompile)(scope);
					
					control.compile = ctrl.recompileItemElement;
					// console.info(control); 
				}
				
				transclude(scope, function(clone) {
					element.html(clone);
				});

				scope.wfItemToolsCompile = ctrl;
			}
		};
		return directive;
	}

	
	
	
	wfItemToolsExecuted.$inject = ['$parse'];

	// Recompiles the innerHtml each time an editor modal that was triggerd from wf-item-tools is saved.
	// The wf-item-tools element just needs to be inside the element with the wf-item-tools-compile attribute
	function wfItemToolsExecuted($parse) {
		var directive = {
			restrict: 'A',
			link: function (scope, element, attrs, ctrl, transclude) {
				var
					control,
					onActionExecuted
				;


				if (attrs.wfItemToolsExecuted) {
					// control = $parse(attrs.wfItemToolsExecuted)(scope);
					
					if (attrs.wfItemToolsExecuted != null && attrs.wfItemToolsExecuted.length) {
						scope.$on("itemToolsActionExecuted", function (event, action, item) {
							if (!onActionExecuted)
								onActionExecuted = $parse(attrs.wfItemToolsExecuted)(scope);

							if (typeof onActionExecuted === "function")
								onActionExecuted(action, item);
						});
					}
					// control.onActionExecuted = ctrl.recompileItemElement;
					// console.info(control); 
				}
			}
		};
		return directive;
	}


	
	wfItemToolsListener.$inject = ['$parse'];

	// Recompiles the innerHtml each time an editor modal that was triggerd from wf-item-tools is saved.
	// The wf-item-tools element just needs to be inside the element with the wf-item-tools-listener attribute
	function wfItemToolsListener($parse) {
		var directive = {
			restrict: 'A',
			link: function (scope, element, attrs, ctrl, transclude) {
				var
					dataArray
				;

				if (attrs.wfItemToolsListener) {
					dataArray = $parse(attrs.wfItemToolsListener)(scope);
					
					if (dataArray) {
						scope.$on("itemToolsActionExecuted", function (event, action, item, dataRelation) {
							if (dataRelation)
								item = dataRelation;

							if (action === "delete" && dataArray.length && !!~dataArray.indexOf(item)) {
								event.stopPropagation();
								element.animate({ opacity: 0 }, 200, function () {
									element.animate({ height: 0, marginTop: 0, marginBottom: 0, paddingTop: 0, paddingBottom: 0 }, 200, function () {
										_.remove(dataArray, item).length
									});
								});
							}
						});
					}
					// control.onActionExecuted = ctrl.recompileItemElement;
					// console.info(control); 
				}
			}
		};
		return directive;
	}



	wfItemToolsService.$inject = ['modalService','$translate', 'wfAuth'];
	
	function wfItemToolsService(modal, $translate, wfAuth) {
		_.assign(this, {
			edit: edit,
			askDelete: deleteItem,
			editConditions: editConditions,
			editSettings: editSettings,
			editUserOrgAccessSettings: editUserOrgAccessSettings
		});

		// Everything in here just executes stuff in modalService, except editUserOrgAccessSettings.
		// It is unnecessary.

		function editSettings(item) {
			return modal.editSettings(item);
		}

		function editConditions(item) {
			return modal.editConditions(item);
		}

		function editUserOrgAccessSettings(item) {
			return modal.edit({
				type: 89,
				wfid: "89-" + item.user_id + "|" + wfAuth.getOrganizationId(),
				organizationId: wfAuth.getOrganizationId(),
				auth0UserId: item.user_id
			});
		}

		function edit(item) {
			return modal.edit(item);
		}

		function deleteItem(item, dataRelation) {
			return modal.confirmDelete(item);
		}
	}
})();
