// @flow
import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'

type Props = {
  children: string | React.Node,
}

const useStyles = makeStyles(theme => ({
  preHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  preHeaderText: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightLight,
    fontSize: 14,
  },
}))

const PreHeader = (props: Props) => {
  const { children } = props
  const classes = useStyles(props)

  if (!React.isValidElement(children)) {
    return (
      <div className={classes.preHeader}>
        <span className={classes.preHeaderText}>
          { children }
        </span>
      </div>
    )
  }
  
  return children
}

export default PreHeader
