(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfHierarchy', wfHierarchy);

	wfHierarchy.$inject = ["$timeout", "screenLoaderService", "$translate"];
	function wfHierarchy($timeout, screenLoader, $translate) {
		var directive = {
			require: "?^^wfItem",
			link: link,
			restrict: 'E',
			templateUrl: 'scripts/wf/hierarchical/wfHierarchy.directive.html'
		};
		return directive;
		
		function link(scope, element, attrs, wfItemCtrl) {
			var vm;
			// if (!scope.vm && wfItemCtrl)
			// {
			// }
			scope.vm.context.itemCompilers = {};
			scope.$translate = $translate;

			if (wfItemCtrl)
			{
				scope.vm = wfItemCtrl
				vm = scope.vm;
				
				vm.itemsPerPage = 10
				vm.itemsPerPage_level2 = 100
				if (scope.vm.item) {
					if (scope.vm.item.childContent && scope.vm.item.childContent.conditions && scope.vm.item.childContent.conditions.itemsPerPage)
						vm.itemsPerPage = scope.vm.item.childContent.conditions.itemsPerPage;
					else if (scope.vm.item.settings && scope.vm.item.settings.itemsPerPage)
						vm.itemsPerPage = scope.vm.item.settings.itemsPerPage;
						
					if (scope.vm.item.childContent && scope.vm.item.childContent.conditions && scope.vm.item.childContent.conditions.itemsPerPage_level2)
						vm.itemsPerPage_level2 = scope.vm.item.childContent.conditions.itemsPerPage_level2;
					else if (scope.vm.item.settings && scope.vm.item.settings.itemsPerPage_level2)
						vm.itemsPerPage_level2 = scope.vm.item.settings.itemsPerPage_level2;
				}
				
				if (scope.vm.context && scope.vm.context.settings && scope.vm.context.settings.itemsPerPage)
					vm.itemsPerPage = scope.vm.context.settings.itemsPerPage;
				
				if (scope.vm.context && scope.vm.context.settings && scope.vm.context.settings.itemsPerPage_level2)
					vm.itemsPerPage_level2 = scope.vm.context.settings.itemsPerPage_level2;
				
			}
			setTimeout(function () {
				if (vm.context.sunburstControl && vm.context.sunburstControl.onNodeClick) {
					vm.context.sunburstControl.onNodeClick(function (d) {
						if (vm.selectedId === d.id) return;

						if (!d.root && !_.find(vm.childs, { childId: d.id }))
							return false;
						
						screenLoader.showAndHide();
						$timeout(function () {
							vm.selectedId = d.root ? null : d.id;
						});
					});
				}
			}, 1000)
			
			// console.log("wfItemCtrl", wfItemCtrl);
			// console.log("scope", scope);
		}
	}

})();