import * as enums from '@worldfavor/constants/enums'

(function () {
	'use strict';

	angular
		.module('wf.common')
		.controller('invoiceController', invoiceController);

	invoiceController.$inject = [ '$scope', '$element', 'moment', '$translate', 'apiProxy', 'currencyFormatService', '$q', '$ngBootbox', '$compile', '$timeout', '$http', 'wfAuth' ];
	function invoiceController($scope, $element, moment, $translate, apiProxy, currencyFormatService, $q, $ngBootbox, $compile, $timeout, $http, wfAuth) {
		var vm = this,
			stages = getActionStages(),
			deliveryMethods = _.invert(getDeliveryMethods())
		;
		_.assign(this, $scope.$parent.vm);
		$scope.moment = moment;

		_.assign(vm, {
			loaded: false,
			invoices: undefined,
			showInvoice: showInvoice,
			stageByInvoiceId: {},
			downloadInvoice: downloadInvoice
		});

		activate();

		function activate() {
			$scope.currencyFormat = currencyFormatService.getLanguages(),
			$scope.currencyFormat.se_SE = { decimal: ",", thousands: " " };

			apiProxy("multi.getObjects", { objectType: enums.objectType.invoice }).then(function (invoices) {
				vm.invoices = _.chain(invoices)
				.map(function(invoice) {
					vm.stageByInvoiceId[invoice.childContent.id] = setStages(invoice.childContent)
					return invoice.childContent;
				})
				.filter('invoiceNumber')
				.orderBy([ 'invoiceDate', 'invoiceNumber' ], [ 'desc', 'desc' ])
				.value();
				vm.loaded = true;
			});
		}

		function setStages(invoice) {
			if (invoice.stage == stages.completed) {
				return { 
					enums: invoice.stage, 
					name: getMultilingual("Paid"), 
					class: "paid", 
					deliveryMethod: getMultilingual(deliveryMethods[invoice.deliveryMethod]) 
				}
			}else if (invoice.stage == stages.paymentSent) {
				return { 
					enums: invoice.stage, 
					name: getMultilingual("Payment Sent"), 
					class: "paymentSent", 
					deliveryMethod: getMultilingual(deliveryMethods[invoice.deliveryMethod]) 
				} 
			}else {
				if (moment().diff(moment(invoice.dueDate), 'days') > 0)
					return { 
						enums: invoice.stage, 
						name: getMultilingual("Overdue"), 
						class: "overdue", 
						deliveryMethod: getMultilingual(deliveryMethods[invoice.deliveryMethod]) 
					}
				else {
					return { 
						enums: invoice.stage, 
						name: getMultilingual("Unpaid"), 
						class: "unpaid", 
						deliveryMethod: getMultilingual(deliveryMethods[invoice.deliveryMethod]) 
					}
				}
			}
		}

		function showInvoice(invoiceId) {
			var invoicePdfUrl = $.proxies.baseUrl + "api/order/invoicePdf?id=" + invoiceId;
			$ngBootbox.customDialog({
				title: $translate.instant("modules.invoice.invoiceTableHeader.invoice") + " #" + invoiceId,
				message: $compile('<wf-pdf-viewer url="' + invoicePdfUrl + '"></wf-pdf-viewer>')($scope),
				onEscape: true,
				className: "terms-and-conditions-modal",
				buttons: {
					primary: {
						label: $translate.instant('Close'),
						className: "btn wf-btn-link"
					}
				}
			});
			$timeout();
		}

		function getMultilingual(invoiceState) {
			var stateText;
			switch (invoiceState) {
				case "Unpaid":
					stateText = $translate.instant('modules.invoice.invoiceStages.unpaid');
					break;
				case "Paid":
					stateText = $translate.instant('modules.invoice.invoiceStages.paid');
					break;
				case "Overdue":
					stateText = $translate.instant('modules.invoice.invoiceStages.overdue');
					break;
				case "Payment Sent":
					stateText = $translate.instant('modules.invoice.invoiceStages.paymentSent');
					break;
				case "E-mail":
					stateText = $translate.instant('modules.invoice.invoiceTableHeader.eMail');
					break;
				case "Post":
					stateText = $translate.instant('modules.invoice.invoiceTableHeader.post');
					break;
				default:
					break;
			}
			return stateText;
		}

		function getActionStages() {
			return {
				unknown: -1,
				none: 0,
				created: 1,
				reminderInvoiceSent: 2,
				debtCollectionInvoiceSent: 3,
				sentToBailiff: 4,
				manual: 5,
				completed: 6,
				cancelled: 7,
				foreignManual: 8,
				awaitingFeePayment: 9,
				feeInvoiceSent: 10,
				installmentPlanInvoiceSent: 11,
				invoiceSent: 12,
				attested: 13,
				debtCollectionClaimDue: 14,
				returnToSourceRequested: 15,
				sentToDebtCollection: 16,
				invoiceCreated: 17,
				paymentSent: 18,
				paymentCancelled: 19,
				salesRequested: 20,
				sold: 21,
				salesRequestedCancelled: 22,
				salesRequestedDenied: 23,
				latePaymentInvoiceSent: 24,
				debtCollectionClaimResponseRequested: 25,
				readyForBailiff: 26,
				longTermSurveilance: 27
			}
		}

		function getDeliveryMethods() {
			return {
				unknown: -1,
				"E-mail": 0,
				"Post": 1,
				manually: 2,
				sms: 3,
				eInvoice: 4
			}
		}

		function downloadInvoice(invoice) {
			$http({
				method: "GET",
				url: $.proxies.baseUrl + "api/order/invoicePdf?id=" + invoice.id,
				responseType: 'arraybuffer',
				headers: { 
					'Authorization': wfAuth.getAuthenticationHeaderValue()
				}
			})
			.then(function (response) {
				processArrayBufferToBlob(response.data, response.headers, invoice);
			}, function() {
				console.log("There was an error downloading the PDF file.");
			});
		}

		function processArrayBufferToBlob(data, headers, invoice) {
			var pdfStreamMime = 'application/pdf';
			var success = false;

			// Get the headers
			headers = headers();

			// Get the filename from the x-filename header or default to "download.bin"
			var filename = headers['x-filename'] || 'Worldfavor ' + $translate.instant('modules.invoice.invoiceTableHeader.invoice') + ' - ' + invoice.invoiceNumber + '.pdf';

			// Determine the content type from the header or default to "application/octet-stream"
			var contentType = pdfStreamMime;

			try {
				// Try using msSaveBlob if supported
				var blob = new Blob([ data ], { type: contentType });
				if (navigator.msSaveBlob)
					navigator.msSaveBlob(blob, filename);
				else {
					// Try using other saveBlob implementations, if available
					var saveBlob = navigator.webkitSaveBlob || navigator.mozSaveBlob || navigator.saveBlob;
					if (saveBlob === undefined) throw "Not supported";
					saveBlob(blob, filename);
				}
				success = true;
			} 
			catch (ex) {
				console.log("msSaveBlob not supported");
			}

			if (!success) {
				// Get the blob url creator
				var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
				if (urlCreator) {
					// Try to use a download link
					var link = document.createElement('a');
					if ('download' in link) {
						// Try to simulate a click
						try {
							// Prepare a blob URL
							var blob = new Blob([ data ], { type: contentType });
							var url = urlCreator.createObjectURL(blob);
							link.setAttribute('href', url);

							// Set the download attribute (Supported in Chrome 14+ / Firefox 20+)
							link.setAttribute("download", filename);

							// Simulate clicking the download link
							var event = document.createEvent('MouseEvents');
							event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
							link.dispatchEvent(event);
							success = true;
						} 
						catch (ex) {
							console.log("Download link method with simulated click failed with the following exception:");
							console.log(ex);
						}
					}

					if (!success) {
						// Fallback to window.location method
						try {
							// Prepare a blob URL
							// Use application/octet-stream when using window.location to force download
							var blob = new Blob([ data ], { type: pdfStreamMime });
							var url = urlCreator.createObjectURL(blob);
							window.location = url;
							success = true;
						} 
						catch (ex) {
							console.log("Download link method with window.location failed with the following exception:");
							console.log(ex);
						}
					}
				}
			}

			if (!success) {
				// Fallback to window.open method
				console.log("No methods worked for saving the arraybuffer, using last resort window.open");
				// window.open( httpPath, '_blank', '');
			}
			return filename;
		};
	}
})();
