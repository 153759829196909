import * as enums from '@worldfavor/constants/enums'

(function () {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfMeasureAnsweringManager', wfMeasureAnsweringManager)
	;
	wfMeasureAnsweringManager.$inject = [ '$parse', 'dataQuery', 'dataOperationsService', '$compile' ];

	function wfMeasureAnsweringManager($parse, dataQuery, dataOps, $compile) {
		var directive = {
			restrict: 'A',
			controllerAs: 'measureAnsweringManager',
			controller: [ '$scope', '$attrs', '$element', '$templateCache', '$timeout', 'wfObject', wfMeasureAnsweringManagerController ],
			
		};
		
		return directive;

		function wfMeasureAnsweringManagerController($scope, $attrs, $element, $templateCache, $timeout, wfObject) {
			var
				vm = this,
				itemsByWfid = {},
				items = [],
				/* {
					requirement: <object>,
					fulfillsLocally: <bool>,
					latestAnswerId: <number>,
					measureDataRelation: <object>,
					measure: <object>
				}
					*/
				fulfillsAllLocally = false,
				initialCalcTimer,
				allAnswers,
				allAnswerInNetwork,
				allAnswersByContextParents,
				allAnswersContextParentsArrays = [],
				contextParentsIntersectionArguments,
				networkId
			;
			vm.insert = setMeasureAnswerInfo;
			vm.update = updateMeasureAnswerInfo;
			vm.fulfillsAllLocally = checkLocalFulfillmentOnAll;
			vm.getLocalFulfillmentData = getLocalFulfillmentData;
			vm.getLatestMeasureAnswer = getLatestMeasureAnswer;
			vm.addCompositeItems = addCompositeItems;

			if ($attrs.wfMeasureAnsweringManager !== "") {
				var x = $parse($attrs.wfMeasureAnsweringManager)($scope);
				_.assign(x, vm);
				if (typeof x.onLoaded === "function") {
					x.onLoaded(vm);
				}
			}

			function updateMeasureAnswerInfo(wfid, data, initial) {
				// console.log(wfid, data);
				setMeasureAnswerInfo(wfid, data);
				if (allAnswers)
					allAnswers.push(data.latestAnswerDataRelation);
				// console.log(itemsByWfid[wfid]);

				if (!initial) {
					$scope.$emit("measureAnswerChanged", itemsByWfid[wfid]);
					$scope.$broadcast("measureAnswerChanged", itemsByWfid[wfid]);
				}
			}
			
			function setMeasureAnswerInfo(wfid, data) {
				$timeout.cancel(initialCalcTimer);
				
				if (!itemsByWfid[wfid]) {
					itemsByWfid[wfid] = {};
					data.wfid = wfid;
					items.push(data)
				}
				
				_.assign(itemsByWfid[wfid], data);
				// When measures are being set up and setMeasureAnswerInfo are executed several times in a row,
				// by using a $timeout that cancels itself the checkLocalFulfillmentOnAll method is only executed when
				// all measures have been set up.
				initialCalcTimer = $timeout(function () {
					$scope.fulfillsAllAnswersLocally = checkLocalFulfillmentOnAll();
				}, 0);
			}
			
			function checkLocalFulfillmentOnAll() {
				return getLocalFulfillmentData().unfulfilled.length === 0;

			}

			function getLocalFulfillmentData() {
				var
					unfulfilled = [],
					fulfilled = [],
					unfulfilledCompositeItems = []
				;

				for (var key in itemsByWfid) {
					if (itemsByWfid.hasOwnProperty(key)) {
						var item = itemsByWfid[key];
						if (item.fulfillsLocally === true) {
							// If requirement rule is manual it is still required that there must be an answer before signing
							if (item.requirement && item.requirement.rule === enums.requirementRule.manual) {
								if (!!item.latestAnswerId)
									fulfilled.push(item.measureDataRelation)
								else {
									unfulfilled.push(item.measureDataRelation)
									unfulfilledCompositeItems.push(item.itemComposite)
								}
							}
							else
								fulfilled.push(item.measureDataRelation)

						}
						else if (item.fulfillsLocally === false) {
							unfulfilled.push(item.measureDataRelation)
							unfulfilledCompositeItems.push(item.itemComposite)
						}
					}
				}

				return {
					fulfilled: fulfilled,
					unfulfilled: unfulfilled,
					unfulfilledCompositeItems: unfulfilledCompositeItems
				}
			}

			function getLatestMeasureAnswer(measure, organizationId, verification, networkId, contextParentWfids) {
				var
					allAnswersOnMeasure,
					latestAnswerDataRelation
				;

				// TODO: Implement network visibility

				if (!allAnswers) {
					allAnswers = wfObject.filter({ where: {
						type: 73,
						parentType: enums.objectType.measure,
						childType: enums.objectType.measureAnswer,
						organizationId: organizationId
					} });
				}

				allAnswersOnMeasure = _.chain(allAnswers).filter({ wffid: measure.wfid }).orderBy([ 'createdAt' ], [ 'desc' ]).value();
				latestAnswerDataRelation = allAnswersOnMeasure[0];

				return {
					allAnswersOnMeasure: allAnswersOnMeasure,
					latestAnswerDataRelation: latestAnswerDataRelation
				}
			}
			
			function addCompositeItems(items, organizationId) { // Shouldn't this require intersectionSettings to be passed?
				var
					measureWfids = _.map(items, "wfid"),
					latestMeasureAnswerDataRelationsByMeasureWfid = _.chain(wfObject.filter({ where: {
						type: 73,
						parentData1: null,
						wffid: { "in": measureWfids },
						parentType: enums.objectType.measure,
						childType: enums.objectType.measureAnswer,
						organizationId: organizationId
					} })).sortBy([ "wffid", "createdAt" ]).groupBy("wffid").mapValues(function (dataRelations) {
						return _.last(dataRelations);
					}).map().keyBy("wffid").value()
					// latestAnswers = _.keyBy(wfObject.filter({ where: {
					// 	type: enums.objectType.measureAnswer,
					// 	wfid: { "in": _.map(latestMeasureAnswerDataRelations, "wfcid") } }
					// });
				;

				_.each(items, function (itemComposite) {
					var
						requirement = itemComposite.content.getRequirement(organizationId) || itemComposite.dataRelation.getRequirement(organizationId)
					;

					setMeasureAnswerInfo(itemComposite.wfid, {
						requirement: requirement,
						fulfillsLocally: requirement ? answersMatchesRequirement(latestMeasureAnswerDataRelationsByMeasureWfid[itemComposite.wfid], requirement) : undefined,
						latestAnswerId: (latestMeasureAnswerDataRelationsByMeasureWfid[itemComposite.wfid] || {}).id,
						measureDataRelation: itemComposite.dataRelation,
						measure: itemComposite.content,
						itemComposite: itemComposite
					})
				});
			}

			function answersMatchesRequirement(latestAnswerDataRelation, requirement)
			{
				var fulfills = !!latestAnswerDataRelation;

				return fulfills;
			}
		}
	}
})();
