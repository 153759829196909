import * as enums from '@worldfavor/constants/enums'

(function() {
'use strict';

	angular
		.module('wf.common')
		.controller('WfUserSettingsController', WfUserSettingsController);

	WfUserSettingsController.$inject = ['$scope', '$element', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', '$timeout', 'formSchemaService', '$q', '$rootScope', 'DS', 'wfAuth', 'responsiveUi'];
	function WfUserSettingsController($scope, $element, dataOps, modal, wfObject, dataQuery, $translate, $timeout, formSchemaService, $q, $rootScope, DS, wfAuth, responsiveUi) {
		var
			vm = this,
			userProperties = [ 'email', 'given_name', 'family_name', 'picture', 'phone_number', 'culture' ],
			auth0UserId = wfAuth.getAuth0UserId(),
			userObjectType = 100
		;

		vm.pageTitle = $translate.instant('AccountSettings'); 
		vm.formControl = {};
		vm.submitButtonClick = submitButtonClick;
		vm.objectType = enums.objectType.individual;

		activate();

		function activate() {
			responsiveUi.enableResponsiveness();
			responsiveUi.disableResponsivenessOnScopeDestroy($scope);
			dataOps.getObject({
				bypassCache: true,
				objectType: 100,
				auth0UserId: wfAuth.getAuth0UserId()
			}).then(function (user) {
				vm.model = user;
				
				vm.loaded = true;
			});
		}

		function submit(form) {
			var jqDf = $.Deferred();
			
			vm.formControl.submit(function (model, deferred) {
				model.user_id = auth0UserId;
				model.userId = auth0UserId;
				model.id = 0;
				model.wfid = userObjectType + "-" + auth0UserId;
				model.type = userObjectType;
				wfObject.inject(model);

				dataOps.update(model).then(function (res) {
					wfAuth.assignAuthUserValues({
						name: res.name,
						culture: model.culture
					});

					if (model.imageUpload) {
						wfAuth.assignAuthUserValues({
							imageUrl: res.imageUrl
						});
						delete model.imageUpload; 
					}

					if (model.culture !== wfAuth.getCulture()) {
						// When culture is changed in account settings it does not change the token that has the culture claim
						// so the new culture value is stored in localStorage and then that value is used until the user gets reauthenticated.
						wfAuth.forceCulture(model.culture);

						setTimeout(function () {
							location.reload();
						}, 100)
					}
					else
						deferred.resolve(res);
				});
			}).then(function (updatedUser) {
				jqDf.resolve();
				if (updatedUser)
					vm.formControl.resetUploaders(updatedUser.imageUrl);
				else
					vm.formControl.resetUploaders();
				
			})

			return jqDf.promise();
		}
		
		function submitButtonClick(e) {
			return submit();
		}
	}
})();
