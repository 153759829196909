import * as enums from '@worldfavor/constants/enums'

(function() {
	"use strict";

	angular
		.module("wf.common")
		.component("wfManageScopeDashboard", {
			templateUrl: "scripts/wf/manage/wfManageScopeDashboard.component.html",
			controller: wfManageScopeDashboardController,
			controllerAs: "vm",
			bindings: {
				hierarchyStructureId: "<",
				categoryStructureId: "<",
				permissionsStructureId: "<",
				sourceNetworkId: "<", // Which networkId to load data from. Must be included in the children of sourcesStructureId
				viewsStructureId: "<", // Structure that contain a list of available views
				sourcesStructureId: "<", // Structure that contain a list of available data source
				configFromAttribute: "<config",
				influenceId: "<",
				influence: "<"
			}
		});

	wfManageScopeDashboardController.$inject = [ "$scope", "$timeout", "$element", "$rootScope", "$state", "requirements", "$transclude", "$compile", "$translate", "$q", "dataOperationsService", "wfObject", "DataNegotiator", "$uibModal", "dataQuery", "modalService", "dropdownUtility", "wfAuth", "$sanitize", "TheNewLoaderFactory", "valueChainService", "wfTranslate", "apiProxy" ];
	function wfManageScopeDashboardController($scope, $timeout, $element, $rootScope, $state, requirementService, $transclude, $compile, $translate, $q, dataOps, wfObject, DataNegotiator, $uibModal, dataQuery, modal, dropdownUtility, wfAuth, $sanitize, TheNewLoaderFactory, valueChainService, wfTranslate, apiProxy) {
		var
			vm = this,
			authOrgId = wfAuth.getOrganizationId(),
			availableViewsStructureId = vm.viewsStructureId,
			availableDataSourcesStructureId = vm.sourcesStructureId || valueChainService.ids.networksStructure //19645
		;

		// Functions
		initMainLoader, openViewCreator, syncAvailableViews, syncAvailableDataSources;

		_.assign(vm, {
			showScopeSelectionAndFilters: true,
			preselectedSource: undefined, // If preselectedSource = "ownData", then the sourceNetworkId will not be used on initial loading but must still be in the list of available sources
			ticket: { organizationId: authOrgId },
			openViewCreator: openViewCreator,
			allDataSourceActions: [],
			showDataSourceSelector: true,
			showViewSelector: true,
			showViewAdder: true,
			showScopeAdder: true,
			receivingOrganizationsAndLimitedDataAccessFromInfluenceId: null,
			isDataCollectionDashboardViewAllowed: authOrgId === 102389 || $rootScope.adminMode
		});

		vm.$onInit = function() {
			var promise, promises = [];
			// wfAuth.forceCulture("en-US", false);

			// $scope.$on("$destroy", function () {
			// 	wfAuth.resetForcedCulture();
			// });

			if (vm.configFromAttribute) {
				_.assign(vm, vm.configFromAttribute);
			}

			// if (vm.hierarchyStructureId > 0)
			// 	initMainLoader();

			// vm.dropdownSwitchActions = [
			// 	{ text: 'GRI Standards', icon: 'fa fa-circle-o-notch', action: function () {
			// 	  $state.go("manageScopeDashboard", { hierarchyStructureId: 10517 })
			// 	} },
			// 	{ text: 'UN Sustainable Development Goals', icon: 'fa fa-cube', action: function () {
			// 	  $state.go("manageScopeDashboard", { hierarchyStructureId: 387 })
			// 	} }
			//   ]

			// var customFramework = wfObject.get("71-16113");
			// if (customFramework) {
			// 	vm.dropdownSwitchActions.push({ text: customFramework.title, icon: 'fa fa-circle', action: function () {
			// 		$state.go("manageScopeDashboard", { hierarchyStructureId: 16113 })
			// 	} })
			// }

			if (vm.influenceId || vm.influence) {
				vm.showViewSelector = false;
				let onInfluenceLoaded = function (influence) {
					vm.influence = influence;
					vm.primaryItemDepth = 0;
					vm.primaryItemDeterminator = function (itemComposite) {
						if (itemComposite.type === enums.objectType.structure && !dataQuery.hasItemAnySettingForAttachingData(itemComposite)) {
							return true;
						}
					};
					$rootScope.setPageTitle(influence.title);
					vm.categoryStructureId = undefined;
					vm.hierarchyStructureId = influence.objectId;
					vm.ticket = {
						networkId: influence.channelId,
						organizationId: influence.thirdPartyTargetOrganizationId || influence.organizationId,
						thirdPartyOrganizationId: influence.thirdPartyTargetOrganizationId ? influence.organizationId : undefined,
						contextParentWfid: influence.contextParentWfids,
						contextParents: influence.contextParentWfids && [ influence.contextParentWfids ],
						contextParentWfids: influence.contextParentWfids && [ influence.contextParentWfids ],
					};
					if (influence.thirdPartyTargetOrganizationId) {
						vm.ticket.thirdParty = {
							influenceId: influence.id,
						}
					}
					vm.showScopeAdder = false;
					vm.showDataSourceSelector = false;
					vm.loadedFromInfluence = true;
					vm.allContentPreloaded = true;
				}

				if (vm.influence) {
					onInfluenceLoaded(vm.influence);
				}
				else {
					promise = dataOps.getObject({
						objectType: enums.objectType.influence,
						objectId: vm.influenceId,
						getterConditions: {
							skipContentLoad: true
						}
					}).then(onInfluenceLoaded);

					promises.push(promise);
				}
			}

			if (vm.showViewSelector) {
				promises.push(syncAvailableViews());
			}

			$q.all(promises).then(function () {
				initMainLoader();
				$timeout();
			})
		};

		vm.$onChanges = function(changesObj) { };
		vm.$onDestroy = function() {
			dataOps.clearPersistentNegotiators()
		};

		function initMainLoader() {
			var promises = [];

			if (vm.selectedView && vm.selectedView.isDataCollectionDashboardView) {
				$rootScope.setPageTitle("Overview");
				apiProxy.raw("valuechain.getDataCollectorStatistics", {
					networkId: vm.sourceNetworkId,
				}).then(function (res) {
					vm.loaded = true
					vm.dataCollectorDashboardData = res
					$timeout()
				});
				return;
			}

			let canModify = true;

			if (vm.permissionsStructureId) {
				const permissionsStructure = wfObject.get(`71-${vm.permissionsStructureId}`);
				const permissions = _.get(permissionsStructure, "permissions");
				if (permissions) {
					if (!permissions.canUpdate || !permissions.canCreate || !permissions.canDelete) {
						vm.showScopeAdder = false;
						vm.showViewAdder = false;
					}
				}
			}
			if (!vm.showScopeAdder && !vm.showViewAdder) {
				canModify = false;
			}

			vm.loaderConfig = {
				uiMode: vm.uiMode,
				// primaryItemDepth: vm.primaryItemDepth,
				primaryItemDeterminator: vm.primaryItemDeterminator,
				categoryStructureId: vm.categoryStructureId,
				hierarchyStructureId: vm.hierarchyStructureId,
				enableSideActions: canModify,
				ticket: vm.ticket,
				loadUserDataOnInit: true, //vm.sourceNetworkId ? false : true
				allContentPreloaded: vm.allContentPreloaded,
				// showItemNumbering: !!vm.influence,
				includePrimaryItemsInFiltering: !!vm.influence || true,
				treatNestedPrimaryItemsAsSubItems: !vm.influence,
				influence: vm.influence
			};

			vm.loader = TheNewLoaderFactory.instantiate(vm.loaderConfig);
			vm.loader.onLoaded(function () {
				var
					settingsToCheck = [
						"showDataSourceSelector",
						"showInternalNetworkDataInSourceSelector",
						"preselectedDataSourceFilter"
					]
				;

				vm["showInternalNetworkDataInSourceSelector"] = true;

				if (!vm.loadedFromInfluence) {
					_.each(settingsToCheck, function (settingKey) {
						var settingValue =  _.get(vm.loader.hierarchyStructure, "conditions.uiSettings.scopeDashboardConfig." + settingKey);
						if (typeof settingValue !== "undefined") {
							vm[settingKey] = settingValue;
						}
					});

					$rootScope.setPageTitle(vm.loader.hierarchyStructure.title);
				}

				if (vm.loadedFromInfluence) {
					vm.loader.loadedFromInfluence = true;
				}

				if (vm.showDataSourceSelector || vm.sourceNetworkId)
					promises.push(syncAvailableDataSources());

				$q.all(promises).then(function () {
					vm.loaded = true;
				})
			});
		}

		function openViewCreator() {
			// modal.createWithRelation({
			// 	simplifyForm: true,
			// 	objectType: enums.objectType.structure,
			// 	dataRelationOptions: { item1: enums.objectType.structure + "-" + availableViewsStructureId, kind: enums.subItemsKind.childrenByUser },
			// 	additionalDataRelations: [{ item1: enums.objectType.structure + "-" + enums.ids.common.customFrameworks, kind: enums.subItemsKind.childrenByUser }],
			// }).then(function (dataRelation, additionalDataRelations) {
			// 	// When modal is closed and not submitted, the dataRelation argument is will be false
			// });
			var
				promise,
				sourceLists = []
			;

			var sourceListIds = []

			if (wfAuth.isAdmin()) {
				const solutionDashboardCategories = {
					"Sustainability Management": [
						enums.ids.common.standardDashboardsPicker_Environmental,
						enums.ids.common.standardDashboardsPicker_Social,
						enums.ids.common.standardDashboardsPicker_Governance,
						enums.ids.common.standardDashboardsPicker_Investments,
					],
					"Sustainable Investments": [
						enums.ids.common.standardDashboardsPicker_Environmental,
						enums.ids.common.standardDashboardsPicker_Social,
						enums.ids.common.standardDashboardsPicker_Governance,
						enums.ids.common.standardDashboardsPicker_Investments,
					],
					"Sustainable Sourcing": [
						enums.ids.common.standardDashboardsPicker_Sourcing,
					],
				}[wfAuth.getSolution()]

				if (solutionDashboardCategories) {
					Array.prototype.push.apply(sourceListIds, solutionDashboardCategories)
				}
			}

			Array.prototype.push.apply(sourceListIds,[
				enums.ids.common.customFrameworks,
				enums.ids.common.isoStandards,
				enums.ids.common.guidelines
			]);

			_.each(sourceListIds, function (structureId) {
				sourceLists.push({
					items: function () {
						var self = this;
						return $q(function (resolve, reject) {
							dataOps.getObject({
								objectType: enums.objectType.structure,
								objectId: structureId
							}).then(function (res) {
								if (res && res.childs.length) {
									self.title = res.title;
									resolve(dataQuery.makeItemComposites(res.childs));
								}
								else {
									resolve([]);
								}
							}, function () {
								resolve([]);
							});
						})
					}
				})
			})


			promise = modal.openCreatorAndPicker({
				title: $translate.instant('modules.manageScopeDashboard.views.adderModal.header'),
				descriptionHtml: $translate.instant('modules.manageScopeDashboard.views.adderModal.description'),
				relationTarget: { item: enums.objectType.structure + "-" + availableViewsStructureId, kind: enums.subItemsKind.childrenByUser },
				sourceLists: sourceLists
			});

			promise.modal.closed.then(function () {
				syncAvailableViews();
			});
		}

		function syncAvailableDataSources() {
			return $q(function (resolve, reject) {
				var promise;

				promise = dataOps.getSubItems(
					{ type: enums.objectType.structure, id: availableDataSourcesStructureId },
					enums.subItemsKind.children,
					{ skipExtras: true, getterConditions: { dataRelationOrganizationMatchMode: 2 } }
				);

				promise.then(function (res) {
					var preselectedNetwork, preselectedDataSourceAction;

					vm.dataSourceDropdownActions = _.map(res, function (relation) {
						var output, targetContextParentTypes, subAction;

						output = {
							text: relation.childContent.title,
							networkId: relation.childId,
							objectId: relation.childId,
							objectType: relation.childType,
							isNetwork: relation.childType === enums.objectType.network,
							image: relation.childContent.imageUrl,
							action: onDropdownItemClickAction,
							content: relation.childContent
						}
						vm.allDataSourceActions.push(output);

						if (output.isNetwork) {
							targetContextParentTypes = _.get(relation.childContent, "settings.valueChainSettings.targetContextParentTypes")

							if (targetContextParentTypes && targetContextParentTypes.length) {
								output.subActions = _.map(targetContextParentTypes, function (objectType) {
									subAction = {
										contextParentType: objectType,
										parent: output,
										networkId: relation.childId,
										isNetworkSubSource: true,
										text: wfTranslate.instant('MAP_ObjectType', { type: objectType, plural: true }),
										subText: objectType === enums.objectType.organization && [ wfTranslate.instant("SubSuppliers"), wfTranslate.instant("Or").toLowerCase(), wfTranslate.instant("PortfolioCompanies") ].join(" "),
										icon: "fa fa-sitemap",
										action: onDropdownItemClickAction
									};
									vm.allDataSourceActions.push(subAction);

									return subAction
								})
							}

							if (vm.showInternalNetworkDataInSourceSelector) {
								if (!output.subActions) output.subActions = [];

								subAction = {
									contextParentType: enums.objectType.organization,
									parent: output,
									networkId: relation.childId,
									isInternalNetworkData: true,
									text: $translate.instant("InternalData"),
									icon: "fa fa-database",
									action: onDropdownItemClickAction
								};

								vm.allDataSourceActions.push(subAction);
								output.subActions.push(subAction);
							}
						}

						return output;
					});
					vm.dataSourceDropdownActions.unshift(vm.selectedDataSource = {
						text: vm.selectedDataSourceText = $translate.instant("modules.manageScopeDashboard.yourOwnData"),
						icon: "",
						action: onDropdownItemClickAction
					});

					if (vm.loader.hierarchyStructure.creatorOrganizationId === wfAuth.getOrganizationId()) {
						vm.dataSourceDropdownActions.push("-");
						vm.dataSourceDropdownActions.push({
							setCurrentAsDefault: true,
							text: $translate.instant("modules.manageScopeDashboard.setAsDefaultDataSource"),
							icon: "",
							action: onDropdownItemClickAction
						});
					}


					if (vm.preselectedDataSourceFilter) {
						preselectedDataSourceAction = _.find(vm.allDataSourceActions, vm.preselectedDataSourceFilter);
						if (preselectedDataSourceAction) {
							onDropdownItemClickAction.call(preselectedDataSourceAction);
							if (!(preselectedDataSourceAction.isNetwork || preselectedDataSourceAction.isNetworkSubSource)) {
								resolve();
							}
							return;
						}
					}

					if (vm.sourceNetworkId) {
						preselectedNetwork = _.find(vm.dataSourceDropdownActions, { networkId: vm.sourceNetworkId, isNetwork: true });

						if (!preselectedNetwork)
							$state.go("root");
						else if (vm.preselectedSource != "ownData") {// If preselectedSource = "ownData", then the sourceNetworkId will not be used on initial loading but must still be in the list of available sources
							onDropdownItemClickAction.call(preselectedNetwork);
						}
						else
							resolve();
					}
					else
						resolve();

					function onDropdownItemClickAction() {
						var self = this;

						if (self.setCurrentAsDefault) {
							if (_.get(vm.loader.hierarchyStructure, "conditions.uiSettings.scopeDashboardConfig")) {
								delete vm.loader.hierarchyStructure.conditions.uiSettings.scopeDashboardConfig.preselectedDataSourceFilter;
							}

							let preselectedDataSourceFilter = undefined;

							if (vm.selectedDataSource.isInternalNetworkData) {
								preselectedDataSourceFilter = {
									isInternalNetworkData: true,
									networkId: vm.selectedDataSource.networkId
								};
							}
							else if (vm.selectedDataSource.contextParentType) {
								preselectedDataSourceFilter = {
									isNetworkSubSource: true,
									contextParentType: vm.selectedDataSource.contextParentType,
									networkId: vm.selectedDataSource.networkId
								};
							}
							else if (vm.selectedDataSource.networkId) {
								preselectedDataSourceFilter = {
									isNetwork: true,
									networkId: vm.selectedDataSource.networkId
								};
							}

							let newSettings = preselectedDataSourceFilter ? _.defaultsDeep({
								uiSettings: {
									scopeDashboardConfig: {
										preselectedDataSourceFilter: preselectedDataSourceFilter
									}
								}
							}, vm.loader.hierarchyStructure.conditions) : vm.loader.hierarchyStructure.conditions

							let settingsWithSerializedProps = {};
							_.each(newSettings, (value, key) => {
								if (key == "dataRelation") {
									value = "";
								}
								else if (key === "childrenSettings") {
									return;
								}
								else if (value instanceof Array) {
									// These settings are stored like joined strings in db but are split into arrays when loaded.
									// They must be joined here before being saved.
									if (_.includes([ "objectTypes", "limitQuestionAnswerTypes", "contextParentWfids", "attachObjectTypes" ], key))
										value = value.join(",");
								}

								if (typeof value === "object") {
									settingsWithSerializedProps[key] = JSON.stringify(value);
								}
								else {
									settingsWithSerializedProps[key] = value;
								}
							});

							dataOps.saveSettings({
								item: vm.loader.hierarchyStructure,
								settings: settingsWithSerializedProps
							}).then(function () {
								vm.loader.hierarchyStructure.conditions = newSettings;
							});
							return;
						}

						vm.selectedDataSource = this;

						vm.selectedDataSourceText = self.text;
						if (self.isNetworkSubSource || self.isInternalNetworkData) {
							vm.selectedDataSourceText = self.parent.text + " / " + vm.selectedDataSourceText;
						}

						vm.isDataSourceFilteringPopoverOpen = false;

						if (self.isNetwork || self.isNetworkSubSource) {// || self.isInternalNetworkData) {
							vm.currentNetworkSource = self.isNetworkSubSource ? self.parent : self;
							loadNetworkAndSetTicket.call(self);
						}
						else if (self.isInternalNetworkData) {
							vm.currentNetworkSource = null;
							vm.loader.setTicket({
								contextParentType: self.contextParentType,
								// networkId: self.networkId,
								organizationIds: [ wfAuth.getOrganizationId() ],
							}, true);
						}
						else {
							vm.currentNetworkSource = null;
							vm.loader.setTicket({
								organizationId: wfAuth.getOrganizationId(),
							}, true);
						}

						$timeout();
					}

					function loadNetworkAndSetTicket() {
						var self = this, subSource;

						if ((self.isNetworkSubSource || self.isInternalNetworkData) && self.contextParentType) {
							subSource = self;
							self = self.parent;
						}

						if (!self.inited) {
							self.loading = true;
							self.inited = true;

							if (vm.loader) {
								vm.loader.pagingFunctionEnabled = false;
								vm.loader.clearContentAndContainerReferencesOnAllItems();
							}

							valueChainService.loadOrganizationsInNetwork(this.networkId, true).then(function(res) {
								self.loading = self.loaded = true;
								// If there are duplicate relations to any organization then those duplicate will have undefined childContent
								// (since the result is not injected into JSData) so remove them here.

								self.organizationIds = _.chain(res).map("childId").compact().value();
								self.organizations = _.chain(res).map("childContent").compact().value();
								self.ticket = createTicketFromSelectedSource(self, subSource, self.organizationIds);

								vm.ticket = self.ticket;

								if (vm.loader) {
									setTicketFromSelectedSource(self, subSource, self.organizationIds);
									resolve();
								}
								else {
									resolve();
								}
								loadNetworkFilters();
							});
						}
						else if (self.loaded) {
							setTicketFromSelectedSource(self, subSource);
							loadNetworkFilters();
						}

						function loadNetworkFilters(networkDataSourceDropdownItem) {
							if (!networkDataSourceDropdownItem)
							networkDataSourceDropdownItem = vm.currentNetworkSource;

							valueChainService.loadCategoriesInNetwork(networkDataSourceDropdownItem.networkId, { formatWithParents: true }).then(function (res) {
								var allCategoryWFIDsInNetwork = _.chain(res).map("wfid").uniq().value();

								valueChainService.loadCategorizationsInNetwork(networkDataSourceDropdownItem.networkId).then(function () {
									var
										categoryOrganizationRelations = wfObject.filter({ where: {
											type: enums.objectType.dataRelation,
											parentType: enums.objectType.structure,
											childType: enums.objectType.organization,
											childId: { "in": networkDataSourceDropdownItem.organizationIds },
											wffid: { "in": allCategoryWFIDsInNetwork }
										} }),
										orgIdsByCategoryWfid = _.chain(categoryOrganizationRelations)
											.groupBy("wffid")
											.mapValues(function (relations) {
												return _.map(relations, "childId");
											})
											.value(),
										filterGroups
										;
									
										filterGroups = _.chain(res)
										.groupBy("parentWfid")
										.mapValues(function (categoryComposites, parentWfid) {
											var
												parentContent = categoryComposites[0].parentContent
											;

											return {
												header: parentContent ? parentContent.title : $translate.instant("Categories"),
												content: parentContent,
												filterOptionsSource: (function () {
													var output;

													output = _.chain(categoryComposites)
														.map(function (categoryComposite) {
															var orgIds = orgIdsByCategoryWfid[categoryComposite.wfid] || []

															return {
																content: categoryComposite.content,
																title: categoryComposite.title,
																id: categoryComposite.wfid,
																count: orgIds.length,
																items: orgIds
															};
														})
														.value();

													return output;
												})()
											};
										})
										.map()
										.value()
									;

									filterGroups.push({
										header: $translate.instant("Organization"),
										// content: parentContent,
										displayAs: "dropdown",
										hideCount: true,
										filterOptionsSource: (function () {
											var output;

											output = _.chain(networkDataSourceDropdownItem.organizations)
												.map(function (org) {
													return {
														title: org.name.toString(),
														id: org.id,
														items: [ org.id ],
													};
												})
												.value();

											return output;
										})()
									})
											
									networkDataSourceDropdownItem.filteringConfig = {
										enableInvertedFiltering: true,
										initialSelectedFilterOptions: _.get(networkDataSourceDropdownItem, "filteringConfig.initialSelectedFilterOptions"),
										onFiltered: _.debounce(function (filteredItems, selectedOptions) {
											networkDataSourceDropdownItem.filteringConfig.initialSelectedFilterOptions = selectedOptions;
											networkDataSourceDropdownItem.filteredOrganizationIds = filteredItems;

											setTicketFromSelectedSource(self, subSource, filteredItems, true);

											networkDataSourceDropdownItem.selectedFiltersCount = selectedOptions.length;

											// if (selectedOptions.length)
											// 	vm.filteredOrganizationIds = _.map(filteredItems, "id");
											// else
											// 	vm.filteredOrganizationIds = organizationIds;

											// syncAggregatedDataView();
											// $timeout();
										}, 500, { leading: true }),
										filters: filterGroups
									};

									networkDataSourceDropdownItem.filtersLoaded = true;
									$timeout();
								});
							});

						}
					}
				});
			});
		}

		function createTicketFromSelectedSource(source, subSource) {
			if (subSource && subSource.isInternalNetworkData) {
				return {
					//contextParents: _.map(source.filteredOrganizationIds || source.organizationIds, function (orgId) { return "101-" + orgId; }),
					contextParentType: subSource.contextParentType,
					organizationIds: [ authOrgId ]
				};
			}
			else {
				return {
					organizationIds: source.filteredOrganizationIds || source.organizationIds,
					networkId: source.networkId,
					contextParentType: subSource && subSource.contextParentType
				};
			}
		}

		function setTicketFromSelectedSource(source, subSource, organizationIds, overrideChartsLoading) {
			vm.loader.setTicket(createTicketFromSelectedSource(source, subSource, organizationIds), overrideChartsLoading);
		}

		function syncAvailableViews() {
			if (!vm.viewsDropdownActions) {
				if (availableViewsStructureId) {

					var promise = dataOps.getSubItems(
						{ type: enums.objectType.structure, id: availableViewsStructureId },
						enums.subItemsKind.children,
						{ skipExtras: true, getterConditions: { dataRelationOrganizationMatchMode: 2 } }
					);

					promise.then(function (res) {
						populate();
					});

					return promise;
				}
				else {
					vm.loader.negotiator.onRequest.then(function () {
						populate();
					});
				}
			}
			else {
				populate();
			}
			function populate() {
				var relations, actionMatchingHierarchyStructure, reloadWithDefaultView;

				if (availableViewsStructureId) {
					relations = dataQuery.getRelations({
						organizationIds: [ null, authOrgId ],
						kind: enums.subItemsKind.childrenByUser,
						parentWfid: enums.objectType.structure + "-" + availableViewsStructureId
					});
				}
				else
				{
					if (vm.loader.hierarchyStructure) {
						relations = [ { createdAt: null, organizationId: 0, childType: 71, childId: vm.hierarchyStructureId, childContent: vm.loader.hierarchyStructure } ];
					}
				}

				vm.viewsDropdownActions = _.chain(relations).map(function (relation) {
					var viewItem;

					viewItem = {
						wfid: relation.wfcid,
						organizationId: relation.organizationId ? relation.organizationId : null,
						createdAt: relation.createdAt,
						text: relation.childContent.title,
						objectId: relation.childId,
						objectType: relation.childType,
						content: relation.childContent,
						image: relation.childContent.imageUrl,
						url: $state.href($state.current.name, _.assign({}, $state.current.params, { view: relation.childId })),
						action: function () {
							// $state.go("manageScopeDashboard", { hierarchyStructureId: 16113 })
						}
					}
					return viewItem;
				}).sortBy("organizationId", "createdAt").value();

				vm.viewsDropdownActions.unshift({
					text: "Overview",
					isDataCollectionDashboardView: true,
					url: $state.href($state.current.name, _.assign({}, $state.current.params, { view: "overview" }))
				});

				actionMatchingHierarchyStructure = _.find(vm.viewsDropdownActions, { objectId: vm.hierarchyStructureId });

				// If the currrently active view was removed then reload the UI with the default view
				if (vm.selectedView && !_.find(vm.viewsDropdownActions, { objectId: vm.selectedView.objectId })) {
					// if (vm.viewsDropdownActions.length === 0)
						reloadWithDefaultView = true;
						// If the removed view was also the
					// if (vm.selectedView.objectId === vm.hierarchyStructurId)

					// vm.selectedView = vm.viewsDropdownActions[0];
				}
				// If loaded with no preselected view but there are views available then auto select the first view
				else if (!(vm.hierarchyStructureId > 0) && !actionMatchingHierarchyStructure) {
					if (vm.viewsDropdownActions.length) {
						vm.selectedView = vm.viewsDropdownActions[vm.isDataCollectionDashboardViewAllowed ? 0 : 1]
						vm.hierarchyStructureId = vm.selectedView.objectId;
					}

					if (vm.loader) {
						reloadWithDefaultView = true;
					}
				}
				// If loaded with a preselected view that is in the list of available views then auto select it
				else if (vm.hierarchyStructureId > 0 && actionMatchingHierarchyStructure) {
					if (vm.selectedView) {
						if (vm.selectedView.objectId === actionMatchingHierarchyStructure.objectId) {

						}
						else {
							if (vm.viewsDropdownActions.length === 1)
								reloadWithDefaultView = true;
						}
					}

					vm.selectedView = actionMatchingHierarchyStructure;
				}
				else {
					vm.selectedView = vm.viewsDropdownActions[vm.isDataCollectionDashboardViewAllowed ? 0 : 1];
				}

				if (reloadWithDefaultView) {
					if (_.get($state, "params.view")) {
						$state.go($state.current.name, { view: null });
					}
					else {
						location.reload();
					}
				}
			}
		}
	}
})();
