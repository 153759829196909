export const API_CALL_ERROR = 'API_CALL_ERROR'
export const BEGIN_DATA_API_CALL = 'BEGIN_DATA_API_CALL'
export const DATA_API_CALL_SUCCESS = 'DATA_API_CALL_SUCCESS'
export const SET_ENFORCED_CULTURE = 'SET_ENFORCED_CULTURE'
export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_AUTH_USER = 'SET_AUTH_USER'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const CHANGE_ORGANIZATION = 'CHANGE_ORGANIZATION'
export const LOGOUT = 'LOGOUT'
export const RESET_APP_STATE = 'RESET_APP_STATE'
export const ADD_ITEM = 'ADD_ITEM'
export const ADD_ITEMS = 'ADD_ITEMS'
export const REMOVE_ITEM = 'REMOVE_ITEM'
export const RESET_DATA_STATE = 'RESET_DATA_STATE'
export const ADD_GRAPH_ITEM = 'ADD_GRAPH_ITEM'
export const ADD_GRAPH_ITEMS = 'ADD_GRAPH_ITEMS'
export const REMOVE_GRAPH_ITEM = 'REMOVE_GRAPH_ITEM'
export const REMOVE_GRAPH_ITEMS = 'REMOVE_GRAPH_ITEMS'
export const RESET_GRAPH_STATE = 'RESET_GRAPH_STATE'
export const ADD_PAGE = 'ADD_PAGE'
export const RESET_PAGINATION_STATE = 'RESET_PAGINATION_STATE'
