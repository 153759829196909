import * as enums from '@worldfavor/constants/enums'
import { StaticIds } from '@worldfavor/constants'

(function() {
'use strict';

  angular
    .module('wf.common')
    .controller('WfThirdPartyPermissionsController', WfThirdPartyPermissionsController);

  WfThirdPartyPermissionsController.$inject = ['$scope', 'dataOperationsService'];
  function WfThirdPartyPermissionsController($scope, dataOps) {
    var vm = this;

    activate();

    function activate() {
      vm.loaded = true;

      const [type, id] = StaticIds.ThirdPartyPermissions.split('-')
      dataOps.getObject({
        objectType: type,
        objectId: id,
      }).then(function (result) {
        vm.thirdPartyRequestsStructure = result;
        vm.loaded = true;
      });
    }
  }
})();
