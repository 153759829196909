import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/styles'
import { MAX_PAGE_CONTENT_WIDTH } from '@worldfavor/constants'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'

const styles =  () => ({
  [`@media (min-width: ${MAX_PAGE_CONTENT_WIDTH}px)`]: {
    maxWidthGrid: props => ({
      maxWidth: props.disableMaxWidthGrid ? null : MAX_PAGE_CONTENT_WIDTH,
    }),
  },
  title: {
    fontSize: 32,
  },
  oops: {
    position: 'absolute',
    fontSize: '370px',
    fontWeight: 'bold',
    left: 0,
    right: 0,
    color: 'rgba(91,149,179,0.20)',
    top: -50,
  },
  container: {
    position: 'relative',
    paddingTop: 450,
    textAlign: 'center',
  },
})

class ErrorBoundary extends Component {

  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ hasError: true })
    console.error('%cOoops, seems like there was a serious issue: ', 'color: red; font-size: 18px')
    console.error(error)
    console.error(errorInfo)
  }

  render() {
    const { hasError } = this.state
    const { classes } = this.props

    if (hasError) {
      return (
        <Grid container justify={'center'}>
          <Grid classes={{ item: classes.maxWidthGrid }} item xs={12}>
            <div className={classes.container} data-cy="error-boundary">
              <div className={classes.oops}><FormattedMessage id="general.oops" /></div>
              <h1 className={classes.title}>
                <FormattedMessage id="general.somethingWentWrong" />
              </h1>
              <p>
                <Button variant="outlined" color="primary" href="/">
                  <FormattedMessage id="general.letsGoBack" />
                </Button>
              </p>
            </div>
          </Grid>
        </Grid>
      )
    }
    return this.props.children
  }

}

export default withStyles(styles)(ErrorBoundary)
