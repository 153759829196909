import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StaticIds } from '@worldfavor/constants'
import { loadSubItemsPage } from '@worldfavor/portal/actions/dataThunk'
import Paper from '@material-ui/core/Paper'
import Loading from '@worldfavor/portal/scenes/Loading'
import ThirdPartyPermissionsTable from './ThirdPartyPermissionsTable'
import useDataFetcher from '@worldfavor/hooks/dataFetcher'

const ThirdPartyPermissions = () => {
  const dispatch = useDispatch()

  const dataLoading = useSelector(state => state.apiCallsInProgress > 0)
  const [shouldReloadData, setShouldReloadData] = useState(false)

  const fetchData = (source, pageNumber, pageSize) => dispatch(
    loadSubItemsPage(
      StaticIds.ThirdPartyPermissions, pageNumber, pageSize, StaticIds.ThirdPartyPermissions, source, {},
    ),
  )

  const {
    pageNumber,
    pageSize,
    pageId,
    requestId,
    setPageNumber,
    setPageSize,
  } = useDataFetcher(fetchData, [shouldReloadData])

  function onPageChange(event, pageNumber) {
    setPageNumber(pageNumber)
  }

  function onChangeRowsPerPage(event) {
    setPageSize(event.target.value)
  }

  return (
    <Paper>
      { (dataLoading || !requestId || !pageId) ?
        <Loading /> :
        (
          <ThirdPartyPermissionsTable
            requestId={requestId}
            pageId={pageId}
            page={pageNumber}
            onPageChange={onPageChange}
            rowsPerPage={pageSize}
            onChangeRowsPerPage={onChangeRowsPerPage}
            shouldReloadData={shouldReloadData}
            setShouldReloadData={setShouldReloadData}
          />
        )
      }
    </Paper>
  )
}

export default ThirdPartyPermissions
