(function () {
	'use strict';

	angular
		.module('wf.common')
		.service('d3Utility', d3Utility);

	d3Utility.$inject = [];

	function d3Utility() {
		var colorCategory20;
		var tooltipElement;
		var service = {
			color20: color20,
			d3: d3,
			tooltip: {
				show: showTooltip,
				hide: hideTooltip
			}
		};

		activate();

		return service;

		function activate () {
		}

		function color20(value) {
			if (!colorCategory20)
				colorCategory20 = d3.scale.category20();

			return colorCategory20(value);
		}
		
		function showTooltip (value, x, y) {
			if (!tooltipElement)
				tooltipElement = $("<div />").addClass("globalTooltip").appendTo("body");
				
			tooltipElement.html(value).css({
				top: y,
				left: x
			}).show();
		}
		
		function hideTooltip () {
			if (tooltipElement)
				tooltipElement.hide();
		}
	}
})();