import * as enums from '@worldfavor/constants/enums'
import { sortAlphabetically } from '@worldfavor/utils/helpers'

(function () {
	'use strict';
	angular
		.module('wf.common')
		.controller('ValueChainOrganizationsController', ValueChainOrganizationsController);

	ValueChainOrganizationsController.$inject = ['$scope', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', 'formSchemaService', '$q',
		'wfTranslate', '$timeout', '$stateParams', '$rootScope', 'apiProxy', 'wfAuth', '$state', '$sanitize', '$ngBootbox', '$window', 'valueChainService', '$interpolate', 'NgMap', '$templateCache', 'importExportService', 'analyzeService', 'DataNegotiator'];
	function ValueChainOrganizationsController($scope, dataOps, modal, wfObject, dataQuery, $translate, formSchemaService, $q,
		wfTranslate, $timeout, $stateParams, $rootScope, apiProxy, wfAuth, $state, $sanitize, $ngBootbox, $window, valueChainService, $interpolate, NgMap, $templateCache, importExportService, analyzeService, DataNegotiator) {
		var
			vm = this,
			authOrgId = wfAuth.getOrganizationId(),
			authOrgWfid = "101-" + authOrgId,
			itemsToEject = [],
			relationBucketEmpty = true,
			ignoreJSDataCache = true,
			loadedInfluences,
			loadedInternalInfluences,
			pageSize = 15,
			notificationsRequest,
			alreadyLoadedUsersOnOrgIds = [],
			usersRequest // Used to hold an ongoing XHR request instance for loading users
			;

		var
			filterGroupPrototype = {
				wfid: null,
				header: null,
				order: null,
				active: false,
				options: null,
				type: "buttons",
				clearFilter: function (skipUpdatingFilterResult) {
					var groupWfid = this.wfid;
					this.active = false;

					_.each(this.options, function (item) {
						if (item.groupWfid === groupWfid) {
							item.clear();
						}
					})

					if (!skipUpdatingFilterResult)
						filterOnAnything();
				}
			},
			filterOptionPrototype = {
				clear: function () {
					delete vm.filters.selectedInvertedFiltersById[this.id];
					delete vm.filters.selectedFilters[this.id];
					_.remove(vm.filters.selectedParents, this);
				}
			}

		$scope.moment = moment;

		_.assign(vm, {
			window: $window,
			loaded: false,
			selectedParentId: undefined,
			organizationCompilers: {},
			mailSettings: {},
			openedWithParent: undefined,
			network: undefined,
			isWorldfavorAdmin: authOrgId === 4536,
			networkImplementsProductionSites: false,
			networkImplementsProductServices: false,
			networkImplementsSubOrganizations: false,
			networkImplementsAnalyze: false,
			hideFilters: false,
			mappingOptionsForCsvExport: undefined,
			onHoverTextForAggregatedData: $translate.instant("modules.valueChain.onHoverTextForAggregatedData"),
			showOrganizations: true,
			filterWithSearchQuery: false,
			aggregatedData: {},
			filtersElement: undefined,
			showAllActiveFilters: false,
			isProductOrganizationExplorerOpen: false,
			isDataCollectorImporterOpen: false,
			authOrganization: wfAuth.getOrganization(),
			authSolutionType: wfAuth.getSolution(),
			isNetworkOrganizationsExporterOpen: false,
			nextEmailInfoDialogIsOpen: false,
			filters: {
				clearButtonTooltipText: $translate.instant("ClearFilter"),
				allFiltersById: {},
				selectedInvertedFiltersById: {},
				parents: [],
				statuses: [],
				packages: [],
				influenceYears: [],
				selectedFilters: {},
				selectedParents: [],
				analyze: {},
				analyzeSliderCompiler: {}
			},
			mailPurposeTitle: {
				1: $translate.instant("modules.mailSettings.Notification"),
				2: $translate.instant("modules.mailSettings.ValueChainInvitation"),
				3: $translate.instant("modules.mailSettings.ValueChainReminder")
			},

			// IDs
			filteredOrganizationIds: [],
			organizationIds: [],
			organizationWFIDs: [],
			allOrganizationIdsInValueChain: [],

			// Arrays
			allOrganizationsInValueChain: [],
			allOrgRegistrationNumbers: [],
			allInfluences: [],
			filteredOrganizations: [],
			organizations: [],
			organizationsWithFailedMail: [],
			allCategoriesInNetwork: [],
			allCategoryWFIDsInNetwork: [],
			pagedFilteredItems: [],
			allPackagesInNetwork: [],
			unsearchedFilteredOrganizations: [],
			packagesForNetworkOrganizationsExport: [],

			// Distinct arrays
			distinctInfluenceStructures: [],
			distinctParents: [],

			// Lookup objects
			failedMailsByOrg: {},
			influencesByOrg: {},
			influenceStructuresById: {},
			influenceStructuresForContextParentById: {}, // Structures that has the condition requirementPackageSettings.targetContextParentType defined
			organizationsById: {},
			organizationsByParent: {},
			organizationsByAnything: {},
			parentsByOrg: {},
			usersByOrg: {},
			publicContactsByOrg: {},
			removingOrganizations: {},
			analyzeJobsByOrgId: {},
			analyzeJobs: [],
			influnceGrouperCounts: {},

			// Export
			export: {
				items: undefined,
				mappingOptions: undefined,
				includeCategorizations: { value: false },
				hideExportUI: false,
				initExportPanel: export_initExportPanel,
				includeCategorizations_onChange: export_includeCategorizations_onChange
			},

			// Functions
			filterOnAnything: filterOnAnything,
			openInfluence: openInfluence,
			openInternalInfluence: openInternalInfluence,
			editInternalInfluence: editInternalInfluence,
			openAnalyzeJob: openAnalyzeJob,
			openMailHistory: openMailHistory,
			changeContactPerson: changeContactPerson,
			changeCategories: changeCategories,
			createInfluence: createInfluence,
			createInternalInfluence: createInternalInfluence,
			createAnalyzeJob: createAnalyzeJob,
			sendMailToOrganization: sendMailToOrganization,
			addOrganization: addOrganization,
			editOrganization: editOrganization,
			removeOrganization: removeOrganization,
			deleteInfluence: deleteInfluence,
			deleteAnalyzeJob: deleteAnalyzeJob,
			admin_setRootObject: admin_setRootObject,
			admin_createValueChain: admin_createValueChain,
			admin_openStandardCategorizer: valueChainService.admin_openStandardCategorizer,
			admin_moveValueChainToSolution: admin_moveValueChainToSolution,
			openInfluenceGrouper: openInfluenceGrouper,
			setCustomId: setCustomId,
			setYearlySpend: setYearlySpend,
			clearAllFilters: clearAllFilters,
			onSearch: onSearch,
			pagingFunction: pagingFunction,
			pageChanged: pageChanged,
			openConsolidatedPackageView: openConsolidatedPackageView,
			goToAnchor: goToAnchor,
			toggleActiveFilters: toggleActiveFilters,
			setProductOrganization: setProductOrganization,
			onProductOrganizationExploreClosed: onProductOrganizationExploreClosed,
			openDataCollectorImporter: openDataCollectorImporter,
			onDataCollectorImporterClosed: onDataCollectorImporterClosed,
			openNetworkOrganizationsExportDialog: openNetworkOrganizationsExportDialog,
			onNetworkOrganizationsExporterClosed: onNetworkOrganizationsExporterClosed,
			onNextEmailInfoClose: onNextEmailInfoClose,
			openEmailInfoDialog: openEmailInfoDialog,
			getWeekday: getWeekday,
		})

		// console.time("Load data collector");
		activate();

		////////////////

		function activate() {
			// $scope.$on("objectUpdated", function ($event, obj) {
			// 	if (_.includes(allWfids, obj.wfid)) {
			// 		resyncEverything();
			// 	}
			// })

			$scope.$on('$destroy', function () {
				// var leftToEject, counter = 0;
				var orgWfids = _.map(vm.allOrganizationsInValueChain, "wfid");
				if (vm.network) {
					// dataOps.eject(vm.network.wfid);

					// leftToEject = vm.allInfluences.length;
					// while (leftToEject--) {
					// 	if (wfObject.eject(vm.allInfluences[leftToEject].wfid))
					// 		counter++;
					// }
					// console.log("[Eject from cache] Influences", counter); // Influences

					// counter = 0;
					// leftToEject = orgWfids.length;
					// while (leftToEject--) {
					// 	if (wfObject.eject(orgWfids[leftToEject]))
					// 		counter++;
					// }
					// console.log("[Eject from cache] Organizations", counter); // Organizations


					// // console.log("[Eject from cache] Influences", wfObject.ejectAll({ where: { type: 13, wfid: { "in": _.map(vm.allInfluences, "wfid") }}}).length); // Influences
					// // console.log("[Eject from cache] Organizations", wfObject.ejectAll({ where: { type: 101, wfid: { "in": orgWfids }}}).length); // Organizations

					// // console.log("[Eject from cache] Network relations", wfObject.ejectAll({ where: { type: 73, wffid: vm.network.wfid }}).length); // Network relations
					// // console.log("[Eject from cache] Relations where orgs are children", wfObject.ejectAll({ where: { type: 73, wfcid: { "in": orgWfids }}}).length); // Relations where orgs are children
					// // console.log("[Eject from cache] Network notifications", wfObject.ejectAll({ where: { type: enums.objectType.notification, channelId: vm.network.id }}).length); // Network notifications
				}
			});

			vm.pageHeaderItem = wfObject.get(valueChainService.ids.networksStructure);
			if (vm.pageHeaderItem) {
				$rootScope.setPageTitle(vm.pageHeaderItem.title);
			}

			valueChainService.loadNetworks().then(function (result) {
				var
					network = result.networksById[$stateParams.networkId],
					mainStructure = result.structure
					;
				vm.pageHeaderItem = mainStructure;

				if (network) {
					vm.network = network;

					vm.showCountries = _.get(vm.network, "settings.valueChainSettings.showCountries") !== false;

					dataOps.getObject({
						objectType: network.type,
						objectId: network.id,
						childrenLoadDepth: 1,
						skipExtras: true,
						getterConditions: {
							objectTypes: [enums.objectType.organization]
						}
					}).then(function (obj) {
						if ($stateParams.parentId) {
							dataOps.getObject({
								objectType: enums.objectType.structure,
								objectId: $stateParams.parentId,
								childrenLoadDepth: 1,
								onlyLoadRelations: true,
								loadParents: false
							}).then(function (res) {
								vm.pageHeaderItem = res;
								vm.openedWithParent = vm.pageHeaderItem;
								$rootScope.setPageTitle(vm.pageHeaderItem.title);
								handleInitialResponse(obj);
							})
						}
						else {
							$rootScope.setPageTitle(mainStructure.title);
							handleInitialResponse(obj);
						}
					});
				}
				else {
					// Data Collector needs a network
					$state.go("valueChain-root");
				}
			});

			initializeListeners();
		}

		function initializeListeners() {
			$scope.$on("wfObject.created", function ($event, wfid, obj) {
				if (obj && obj.type === enums.objectType.dataRelation && (obj.childType === enums.objectType.organization && obj.wffid === vm.network.wfid)) {
					if (!vm.organizationsById[obj.childId]) {
						dataOps.getObject({
							objectType: enums.objectType.organization,
							objectId: obj.childId,
							getterConditions: {
								includeOrganizationsUsers: true
							}
							// bypassCache: true
						}).then(function (res) {
							var organization = res;
							if (vm.openedWithParent)
								vm.allOrganizationsInValueChain.unshift(organization);
							else
								vm.organizations.unshift(organization);

							vm.allOrganizationIdsInValueChain.unshift(organization.id);

							if (vm.currentOrgCreationDeferred) { // The promise used for spinner on creation button
								vm.currentOrgCreationDeferred.resolve();
								vm.currentOrgCreationDeferred = undefined;
							}

							resyncEverything();
							$timeout();
						});
					}
				}
			});
		}

		function handleInitialResponse(obj) {
			const organizationDataRelations = _.chain(obj.childs).filter({ childType: enums.objectType.organization }).uniqBy("childId").orderBy(["createdAt"], ["desc"]).value();
			vm.organizationWFIDs = organizationDataRelations.map(x => x.wfcid);
			vm.organizationRelationByWfid = _.keyBy(organizationDataRelations, "wfcid");
			vm.allOrganizationsInValueChain = wfObject.filter({ where: { type: enums.objectType.organization, wfid: { "in": vm.organizationWFIDs } } })
			vm.allOrganizationIdsInValueChain = _.map(vm.allOrganizationsInValueChain, "id");

			//  _.chain(obj.childs).uniqBy("childId").orderBy([ "createdAt" ], [ "desc" ]).map("childContent").value();

			if (vm.openedWithParent) {
				vm.organizationWFIDs = _.chain(vm.openedWithParent.childs).uniqBy("childId").orderBy(["createdAt"], ["desc"]).map("wfcid").value();
				vm.organizations = wfObject.filter({ where: { type: enums.objectType.organization, wfid: { "in": vm.organizationWFIDs } } });
				// vm.organizations = _.chain(vm.openedWithParent.childs).uniqBy("childId").orderBy([ "createdAt" ], [ "desc" ]).map("childContent").value();
			}
			else { // Overview mode, all organizations i Data Collector
				vm.organizations = vm.allOrganizationsInValueChain;
			}

			// console.log(vm.organizationWFIDs);

			vm.organizationIds = _.map(vm.organizations, "id");
			vm.organizationsById = _.keyBy(vm.organizations, "id");

			resyncUsers();

			loadDataDependenciesAsync(function () {
				vm.loaded = true;

				syncDistinctInfluenceStructures();
				syncFilterBars();


				filterOnAnything();
				$timeout();
				// console.timeEnd("Load data collector");
				// console.log(vm.organizationCompilers)


				// loadNotificationsAsync();
			});
		}

		function loadDataDependenciesAsync(resolve) {
			var params, promise;

			if (vm.organizationIds.length) {
				// Get influences made to the organizations by the auth org

				loadInfluences().then(function () {
					resyncInfluences();

					// Get structures used to influence with
					dataOps.getObjects(_.chain(vm.allInfluences).uniqBy("wfcid").map(function (influence) {
						return influence.objectType + "-" + influence.objectId;
					}).value()).then(function () {

						// Get failed emails.
						// The result is an array with outboundMail items - one per organization that has at least one failed mail.
						// If an organization have several failed mails then only the first one is included.
						apiProxy("multi.getObjects", {
							objectType: enums.objectType.mailOutbound,
							wrapInRelations: false,
							organizationIds: vm.organizationIds,
							getterConditions: {
								networkId: vm.network.id
							}
						}).then(function (mailOutboundResult) {

							_.forEach(mailOutboundResult, function (mailOutbound) {
								// console.log(_.chain(vm.usersByOrg[mailOutbound.toOrganizationId]).map("email").value(), mailOutbound.toEmail);
								// if (_.chain(vm.usersByOrg[mailOutbound.toOrganizationId]).map("email").includes(mailOutbound.toEmail).value()) {
								vm.failedMailsByOrg[mailOutbound.toOrganizationId] = vm.failedMailsByOrg[mailOutbound.toOrganizationId] || {};
								vm.failedMailsByOrg[mailOutbound.toOrganizationId][mailOutbound.toEmail] = mailOutbound;

								vm.organizationsWithFailedMail.push(_.find(vm.organizations, { id: mailOutbound.toOrganizationId }));
								// }
							});
							vm.organizationsWithFailedMail = _.uniqBy(vm.organizationsWithFailedMail, "id");

							// Get all MailSettings for the network
							dataOps.getObjects({
								requestSignature_noResultNeeded: ["valueChain", vm.network.id, "mailSettings"].join("_"),
								objectType: enums.objectType.mailSettings,
								wrapInRelations: false,
								getterConditions: {
									networkId: vm.network.id
								}
							}).then(function () {
								var innerPromises = [], promise;

								vm.mailSettings = _.keyBy(wfObject.filter({ where: { type: enums.objectType.mailSettings, networkId: vm.network.id } }), "mailPurpose");


								// Load packages in network
								valueChainService.loadPackagesInNetwork(vm.network).then(function (packages) {
									var
										allPackagesInNetwork = _.map(packages, "childContent")
										;

									vm.allPackagesInNetwork = allPackagesInNetwork;

									vm.networkImplementsAnalyze = !!wfObject.get("71-" + valueChainService.ids.analyzePackagesStructure);

									vm.productionSitesStructure = _.find(allPackagesInNetwork, function (requirementPackage) {
										return _.get(requirementPackage, "conditions.templateId") === 80
											&& _.get(requirementPackage, "conditions.objectTypes[0]") === 107;
									});

									vm.productServicesStructure = _.find(allPackagesInNetwork, function (requirementPackage) {
										return _.get(requirementPackage, "conditions.templateId") === 80
											&& _.get(requirementPackage, "conditions.objectTypes[0]") === 120;
									});

									vm.subOrganizationsStructure = _.find(allPackagesInNetwork, function (requirementPackage) {
										return _.get(requirementPackage, "conditions.templateId") === 80
											&& _.get(requirementPackage, "conditions.objectTypes[0]") === 101;
									});

									vm.networkImplementsProductMapping = !!_.find(allPackagesInNetwork, function (requirementPackage) {
										return _.get(requirementPackage, "conditions.objectTypes[0]") === 120
											&& _.get(requirementPackage, "conditions.requirementPackageSettings.crowdSourcedDataCollector.enabled") === true;
									})


									if (vm.subOrganizationsStructure) {
										vm.networkImplementsSubOrganizations = true;

										// The structure where a reporting org adds organizations can have pickerSettings that specifies another objectType.
										// This objectType can be either 113 (enums.objectType.supplier) or 114 (enums.objectType.holding) and they DO NOT
										// represent a real type of objects. They are only used for controlling the wording. The items that are being picked
										// by reporting org is always of type 101 (enums.objectType.organization).
										vm.subOrganizationsReporting_wordingType = _.get(vm.subOrganizationsStructure, "conditions.pickerSettings.objectTypes[0]");
										if (vm.subOrganizationsReporting_wordingType) {
											vm.subOrganizationsReporting_typeWord = wfTranslate.instant('MAP_ObjectType', { type: vm.subOrganizationsReporting_wordingType, plural: true });
										}
										else
											vm.subOrganizationsReporting_typeWord = wfTranslate.instant('MAP_ObjectType', { type: enums.objectType.organization, plural: true });

										// Same api request as in influenceGrouper js
										promise = apiProxy("suppliers.getAggregatedSubItems", { // The items returned will not be injected in the JSData cache
											structureId: vm.subOrganizationsStructure.id,
											networkId: vm.network.id,
											kind: enums.subItemsKind.childrenByUser,
											wrapInVirtualRelations: true, // Instead of returning the real dataRelations we get virtualDataRelations
											distinct: true, // Get unique items (if multiple organizations have added the same items)
											loadParents: false,
											loadMetadata: false,
											loadVisibilityTags: false,
											getterConditions: {
												loadCreators: false,
												includeOrganizations: false
											}
										});

										promise.then(function (virtualDataRelations) {
											vm.influnceGrouperCounts.subOrganizations = virtualDataRelations.length;
										});
										innerPromises.push(promise);
									}

									if (vm.productionSitesStructure) {
										vm.networkImplementsProductionSites = true;

										// Same api request as in influenceGrouper js
										promise = apiProxy("suppliers.getAggregatedSubItems", { // The items returned will not be injected in the JSData cache
											structureId: vm.productionSitesStructure.id,
											networkId: vm.network.id,
											kind: enums.subItemsKind.childrenByUser,
											wrapInVirtualRelations: true, // Instead of returning the real dataRelations we get virtualDataRelations
											distinct: true // Get unique items (if multiple organizations have added the same items)
										});

										promise.then(function (virtualDataRelations) {
											vm.influnceGrouperCounts.productionSites = virtualDataRelations.length;
										});
										innerPromises.push(promise);
									}

									if (vm.productServicesStructure) {
										vm.networkImplementsProductServices = true;

										// Same api request as in influenceGrouper js
										promise = apiProxy("suppliers.getAggregatedSubItems", { // The items returned will not be injected in the JSData cache
											structureId: vm.productServicesStructure.id,
											networkId: vm.network.id,
											kind: enums.subItemsKind.childrenByUser,
											wrapInVirtualRelations: true, // Instead of returning the real dataRelations we get virtualDataRelations
											distinct: true // Get unique items (if multiple organizations have added the same items)
										});

										promise.then(function (virtualDataRelations) {
											vm.influnceGrouperCounts.productServices = virtualDataRelations.length;
										});
										innerPromises.push(promise);
									}

									if (innerPromises.length) {
										$q.all(innerPromises).then(function () {
											loadCategoriesInNetwork();
										});
									}
									else
										loadCategoriesInNetwork();

									function loadCategoriesInNetwork() {
										valueChainService.loadCategoriesInNetwork(vm.network).then(function (categories) {
											vm.allCategoriesInNetwork = _.map(categories, "childContent");
											vm.allCategoryWFIDsInNetwork = _.map(vm.allCategoriesInNetwork, "wfid");

											valueChainService.loadCategorizationsInNetwork(vm.network).then(function (categorizations) {
												dataOps.getSubItemsOfAll(vm.allCategoryWFIDsInNetwork, enums.subItemsKind.parentsByUser).then(function () {
													initializeLocations();
													initializeActorTypes().then(function () {
														initializeIndustries().then(function () {
															initializeCountries().then(function () {
																loadOrganizationParentsOnOrganizations().then(function () {
																	loadParameterValuesOnOrganizations().then(function () {
																		if (vm.networkImplementsAnalyze) {
																			loadAnalyzeJobs().then(function () {
																				resolve();
																			});
																		}
																		else {
																			resolve();
																		}
																	});
																});
															});
														});
													});
												});
											});
										});
									}
								});

							});

						});
					});
				});
			}
			else {
				resolve();
			}

			function loadInfluences() {
				vm.networkImplementsInternalReqPackages = !!wfObject.get("71-" + valueChainService.ids.internalPackagesStructure);

				return $q(function (resolve, reject) {
					var promise
					var params = {
						requestSignature_noResultNeeded: ["valueChain", vm.network.id, "influences"].join("_"),
						objectType: enums.objectType.influence,
						wrapInRelations: false,
						loadMetadata: false,
						getterConditions: {
							organizationIds: vm.allOrganizationIdsInValueChain,
							includeOrganizations: false,
							includeTargetUsers: false,
							loadRequirements: false,
							networkId: vm.network.id,
							loadCreators: false
						},
						culture: wfAuth.getCulture()
						// bypassCache: true
					}

					if (ignoreJSDataCache) {
						promise = apiProxy("multi.getObjects", params);
					}
					else {
						promise = dataOps.getObjects(params);
					}

					promise.then(function (influences) {
						loadedInfluences = influences;

						if (vm.networkImplementsInternalReqPackages) {
							apiProxy("multi.getObjects", {
								requestSignature_noResultNeeded: ["valueChain", vm.network.id, "internal-influences"].join("_"),
								objectType: enums.objectType.influence,
								wrapInRelations: false,
								loadMetadata: false,
								getterConditions: {
									contextParentWfids: _.map(vm.allOrganizationsInValueChain, "wfid"),
									influenceOrganizationCondition: 5,
									includeOrganizations: false,
									includeTargetUsers: false,
									loadRequirements: false,
									loadCreators: false
								},
								culture: wfAuth.getCulture()
							}).then(function (internalInfluences) {
								loadedInternalInfluences = internalInfluences;

								resyncInfluences();
								resolve();
							});
						}
						else {
							resyncInfluences();
							resolve();
						}
					});
				});
			}
		}

		function syncParentOrganizations() {
			var relations = wfObject.filter({
				where: {
					type: enums.objectType.dataRelation,
					parentType: enums.objectType.organization,
					childType: enums.objectType.organization,
					wfcid: { "in": vm.organizationWFIDs },
				}
			})
			// var organizationsByParentOrg = _.groupBy(relations, "parentId");

			vm.parentOrganizationsByOrg = _.chain(relations).groupBy("childId").mapValues(function (items) { return _.map(items, "parentContent"); }).value();
			// console.log(vm.parentOrganizationsByOrg);

			// vm.filters.parentOrganizations = [];

			// _.each(organizationsByParentOrg, function (relations) {
			// 	var
			// 		organizations = [],
			// 		orgIds = _.map(relations, "childId"),
			// 		filterButton,
			// 		parentOrg = relations[0].parentContent
			// 	;

			// 	filterButton = {
			// 		type: "parentOrg",
			// 		id: parentOrg.wfid,
			// 		wfid: parentOrg.wfid,
			// 		title: parentOrg.title,
			// 		hasImage: parentOrg.hasImage,
			// 		imageUrl: parentOrg.imageUrl,
			// 		count: orgIds.length
			// 	};
			// 	vm.filters.parentOrganizations.push(filterButton);

			// 	for (var i = 0, len = orgIds.length; i < len; i++) {
			// 		organizations.push(vm.organizationsById[orgIds[i]]);
			// 	}

			// 	vm.organizationsByAnything[parentOrg.wfid] = organizations;
			// });
		}

		// Loads parentsByUser of type organization on the organizations.
		// When reported sub-suppliers are added to a Data Collector, a dataRelation is created between the reporting organization and the reported sub-supplier
		// so that it is easy to see in the Data Collector UI if an organization comes from being reported by another organization.
		function loadOrganizationParentsOnOrganizations() {
			return $q(function (resolve) {
				dataOps.getSubItemsOfAll(vm.organizationWFIDs, enums.subItemsKind.parentsByUser, {
					loadMetadata: false,
					getterConditions: {
						loadCreators: false,
						objectTypes: [enums.objectType.organization]
					}
				}).then(function () {
					syncParentOrganizations();
					resolve();
				});
			});
		}

		// Loads Custom ID's for the organizations in the network.
		// Custom ID is defined as a Parameter with id 33.
		// Each organization with a Custom ID corresponds to a ParameterValue instance
		// with ParameterID = 33, ObjectType = 101 (Organization), ObjectID = each org id.
		// The Value property of the ParameterValue is the Custom ID.
		function loadParameterValuesOnOrganizations() {
			return $q(function (resolve) {
				var i = 0;
				var parameterSpecs = [
					{
						id: 33, cacheKey: "customIdsByOrgId"
					},
					{
						id: 35, cacheKey: "yearlySpendByOrgId"
					}
				];
				var promises = [];

				_.each(parameterSpecs, function (parameterSpec) {
					var promise = dataOps.getObjects({
						requestSignature_noResultNeeded: ["valueChain", parameterSpec.id, "parameters"].join("_"),
						objectType: enums.objectType.parameterValue,
						wrapInRelations: false,
						getterConditions: {
							parameterId: parameterSpec.id,
							objectType: enums.objectType.organization,
							objectIds: vm.organizationIds
						}
					});

					promise.then(function () {
						var parameterValues = wfObject.filter({
							where: {
								type: enums.objectType.parameterValue,
								organizationId: authOrgId,
								parameterId: parameterSpec.id,
								objectType: enums.objectType.organization
							}
						});
						vm[parameterSpec.cacheKey] = _.keyBy(parameterValues, "objectId");

						i++;
						if (i === parameterSpecs.length) // Can't get $q.all to work with dataOps.getObjects so doing it with a counter instead
							resolve();
					});

					// promises.push(promise);
				});

				// dataOps.getObjects({
				// 	objectType: enums.objectType.parameterValue,
				// 	wrapInRelations: false,
				// 	getterConditions: {
				// 		parameterId: 33,
				// 		objectType: enums.objectType.organization,
				// 		objectIds: vm.organizationIds
				// 	}
				// }).then(function (parameterValues) {
				// 	vm.customIdsByOrgId = _.keyBy(parameterValues, "objectId");
				// 	resolve();
				// });
			});
		}

		function loadAnalyzeJobs() {
			var promise = apiProxy("multi.getObjects", {
				objectType: enums.objectType.analyzeJob,
				wrapInRelations: false,
				loadMetadata: false,
				getterConditions: {
					networkId: vm.network.id,
					organizationIds: vm.organizationIds,
				},
				culture: wfAuth.getCulture()
			});

			promise.then(function (res) {
				vm.analyzeJobs = _.orderBy(res, "createdAt");
				vm.analyzeJobsByOrgId = _.groupBy(vm.analyzeJobs, "targetOrganizationId");
			});

			return promise;
		}

		function initializeLocations() {
			var infoWindow;
			dataOps.getObjects({
				requestSignature_noResultNeeded: ["valueChain", vm.network.id, "locations"].join("_"),
				objectType: enums.objectType.location,
				organizationIds: vm.organizationIds,
				wrapInRelations: false,
				getterConditions: {
					loadParents: false,
					loadCreators: false,
					includeOrganizations: false
				}
			}).then(function (locations) {
				vm.locations = locations;
				vm.filteredLocations = _.clone(vm.locations);
				$timeout();

				NgMap.getMap({ id: "valueChainLocations" }).then(function (map) {
					var clearListeners = google.maps.event.clearListeners;
					var startBounds = new google.maps.LatLngBounds();
					var currentOpenMarker;
					var currentOpenLocation;
					var infoWindowOpen;
					var markers = [];
					infoWindow = new google.maps.InfoWindow({});

					vm.onMarkerClick = function (event, location) {
						// console.log("attachMarkersInfoWindow", map.markers.length);
						// clearListeners(marker, 'click');
						// console.log("addListener", marker.infocontent)
						// marker.addListener('click', function() {
						// console.log("click", infoWindowOpen, currentOpenMarker === marker)
						if (infoWindow) {
							infoWindow.close();
							infoWindowOpen = false;
						}

						if (infoWindowOpen && currentOpenLocation === location) {
						}
						else {
							infoWindow = new google.maps.InfoWindow({
								content: '<div class=\'map-infoWindow\'><b>' + $sanitize(location.name) + '</b><div class=\'subHeaderText\'>' + $sanitize(location.organizationName) + '</div>' + $sanitize(location.formattedAddress) + '</div>',
								position: new google.maps.LatLng(location.latitude, location.longitude),
								pixelOffset: new google.maps.Size(-1, -40),
							})
							// infoWindow.setContent();
							// infoWindow.setPosition(new google.maps.LatLng(location.latitude, location.longitude));
							infoWindow.open(map);
							infoWindowOpen = true;
						}
						currentOpenLocation = location;
						// });
					}

					google.maps.event.addListener(infoWindow, 'closeclick', function () {
						infoWindowOpen = false;
						currentOpenLocation = undefined;
					});

					_.forEach(map.markers, function (marker) {
						startBounds.extend(marker.position)
						markers.push(marker);
						// attachMarkerInfoWindow(marker);
						map.fitBounds(startBounds);
					});

					$scope.$watchCollection("vm.searchResultItems", function (influences) {

						// var
						// 	influenceIds = _.map(influences, "id"),
						// 	influences = _.filter(vm.influenceObjects, function (influence) {
						// 		return _.includes(influenceIds, influence.id);
						// 	}),
						var
							organizationIds = _.map(vm.searchResultItems, "id"),
							bounds = new google.maps.LatLngBounds()
							;
						// console.log(markers);
						infoWindow.close();

						vm.filteredLocations.length = 0;
						_.assign(vm.filteredLocations, _.filter(vm.locations, function (location) {
							var includes = _.includes(organizationIds, location.organizationId);

							if (includes) {
								location.organizationName = (vm.organizationsById[location.organizationId] || {})["name"] || "";
								bounds.extend(new google.maps.LatLng(location.latitude, location.longitude));
							}

							return includes;
						}));

						setTimeout(function () {
							map.fitBounds(vm.filteredLocations.length ? bounds : startBounds);

							if (map.zoom > 16) {
								map.setZoom(16)
							}
						}, 0);

						// setTimeout(function () {
						// 	_.forEach(map.markers, function (marker) {
						// 		attachMarkerInfoWindow(marker);
						// 	});
						// }, 1000);
						// if (vm.filteredLocations.length === 1) {
						// }
						// console.log(map)
					});
				});
			});
		}

		function initializeIndustries() {
			var promise;

			if (_.get(vm.network, "settings.valueChainSettings.showIndustries") === false) {
				return $q(function (resolve, reject) {
					resolve();
				});
			}
			else {
				promise = apiProxy.raw("multi.getSubItems", {
					item: { id: 14409, type: enums.objectType.structure },
					ticket: {
						networkId: vm.network.id,
						organizationIds: vm.organizationIds
					},
					loadParents: false,
					loadMetadata: false,
					loadVisibilityTags: false,
					getterConditions: {
						loadCreators: false,
						includeOrganizations: false
					},
					kind: enums.subItemsKind.childrenByUser,
					culture: wfAuth.getCulture()
				}).then(function (industryRelations) {
					var industryItemInterpolate = $interpolate("<div class='btn btn-hollow btn-sm industry-item' title='{{tooltipText}}'>{{title}}</div>");
					var contentByWfid = _.chain(industryRelations).map("childContent").compact().keyBy("wfid").value();

					vm.industryRelations = industryRelations;
					_.each(industryRelations, function (relation) {
						relation.childContent = contentByWfid[relation.wfcid]
					});

					syncIndustries();
					$timeout();
				});

				return promise;
			}

		}

		function initializeCountries() {
			return $q(function (resolve, reject) {
				dataOps.getObjects({
					requestSignature_noResultNeeded: ["valueChain", vm.network.id, "countries"].join("_"),
					objectType: enums.objectType.country,
					wrapInRelations: false,
					skipextras: true
				}).then(countries => {
					vm.countryById = _.chain(countries).keyBy("id").value();
					resolve()
				})
			});
		}

		function syncIndustries(org) {
			// if (!vm.isWorldfavorAdmin)
			// 	return syncIndustries = _.noop;

			var industryItemInterpolate = $interpolate("<div class='btn btn-hollow btn-sm industry-item' title='{{tooltipText}}'>{{title}}</div>");
			var orgIndustryRelations;

			if (org) {
				orgIndustryRelations = wfObject.filter({
					where: {
						type: enums.objectType.dataRelation,
						organizationId: org.id,
						wffid: "71-14409",
						parentData1: null,
					}
				});

				_.remove(vm.industryRelations, { organizationId: org.id });

				if (orgIndustryRelations.length) {
					Array.prototype.push.apply(vm.industryRelations, orgIndustryRelations);
					vm.industriesByOrgId[org.id] = orgIndustryRelations;

					vm.industriesHtmlByOrgId[org.id] = _.map(orgIndustryRelations, function (relation) {
						var title = $sanitize(relation.childContent.title);
						return industryItemInterpolate({
							title: title,
							tooltipText: $translate.instant("Industry") + ": " + title
						});
					}).join("");
				}
				else {
					delete vm.industriesByOrgId[org.id];
					delete vm.industriesHtmlByOrgId[org.id];
				}
			}
			else {
				vm.industriesByOrgId = _.groupBy(vm.industryRelations, "organizationId");
				vm.industriesHtmlByOrgId = _.mapValues(vm.industriesByOrgId, function (relations) {
					return _.map(relations, function (relation) {
						var title = $sanitize(relation.childContent.title);
						return industryItemInterpolate({
							title: title,
							tooltipText: $translate.instant("Industry") + ": " + title
						});
					}).join("");
				});
			}
		}



		function initializeActorTypes() {
			var promise;

			if (_.get(vm.network, "settings.valueChainSettings.showActorTypes") === false) {
				return $q(function (resolve, reject) {
					resolve();
				});
			}
			else {
				promise = apiProxy.raw("multi.getSubItems", {
					item: { id: 30069, type: enums.objectType.structure },
					ticket: {
						networkId: vm.network.id,
						organizationIds: vm.organizationIds
					},
					loadParents: false,
					loadMetadata: false,
					loadVisibilityTags: false,
					getterConditions: {
						loadCreators: false,
						includeOrganizations: false
					},
					kind: enums.subItemsKind.relatedContentByUser,
					culture: wfAuth.getCulture()
				}).then(function (actorTypeRelations) {
					var actorTypeItemInterpolate = $interpolate("<div class='btn btn-hollow btn-sm actorType-item' title='{{tooltipText}}'>{{title}}</div>");
					var contentByWfid = _.chain(actorTypeRelations).map("childContent").compact().keyBy("wfid").value();

					vm.actorTypeRelations = actorTypeRelations;
					_.each(actorTypeRelations, function (relation) {
						relation.childContent = contentByWfid[relation.wfcid]
					});

					syncActorTypes();
					$timeout();
				});

				return promise;
			}

		}

		function syncActorTypes(org) {
			// if (!vm.isWorldfavorAdmin)
			// 	return syncActorTypes = _.noop;

			var actorTypeItemInterpolate = $interpolate("<div class='btn btn-hollow btn-sm actorType-item' title='{{tooltipText}}'>{{title}}</div>");
			var orgActorTypeRelations;

			if (org) {
				orgActorTypeRelations = wfObject.filter({
					where: {
						type: enums.objectType.dataRelation,
						organizationId: org.id,
						wffid: "71-30069",
						parentData1: 1,
					}
				});

				_.remove(vm.actorTypeRelations, { organizationId: org.id });

				if (orgActorTypeRelations.length) {
					Array.prototype.push.apply(vm.actorTypeRelations, orgActorTypeRelations);
					vm.actorTypesByOrgId[org.id] = orgActorTypeRelations;

					vm.actorTypesHtmlByOrgId[org.id] = _.map(orgActorTypeRelations, function (relation) {
						var title = $sanitize(relation.childContent.title);
						return actorTypeItemInterpolate({
							title: title,
							tooltipText: $translate.instant("ActorType") + ": " + title
						});
					}).join("");
				}
				else {
					delete vm.actorTypesByOrgId[org.id];
					delete vm.actorTypesHtmlByOrgId[org.id];
				}
			}
			else {
				vm.actorTypesByOrgId = _.groupBy(vm.actorTypeRelations, "organizationId");
				vm.actorTypesHtmlByOrgId = _.mapValues(vm.actorTypesByOrgId, function (relations) {
					return _.map(relations, function (relation) {
						var title = $sanitize(relation.childContent.title);
						return actorTypeItemInterpolate({
							title: title,
							tooltipText: $translate.instant("ActorType") + ": " + title
						});
					}).join("");
				});
			}
		}

		function syncFilterBars() {
			// console.time("syncFilterBars");
			var
				allParentRelations = wfObject.filter({
					where: {
						type: enums.objectType.dataRelation,
						parentType: enums.objectType.structure,
						childType: enums.objectType.organization,
						wfcid: { "in": vm.organizationWFIDs },
						wffid: { "in": vm.allCategoryWFIDsInNetwork }
					}
				}),
				allParentWFIDs = _.map(allParentRelations, "wffid"),
				distinctParentWFIDs = _.uniq(allParentWFIDs),
				distinctParents = _.chain(wfObject.filter({
					where: {
						type: enums.objectType.structure,
						wfid: { "in": distinctParentWFIDs }
					}
				})).sortBy("title").map(function (item) {
					var
						parentDataRelation,
						parentContent,
						output
						;

					parentDataRelation = _.find(item.parents, function (parentDataRelation) {
						var parentContent = parentDataRelation.parentContent;
						return parentContent.type !== enums.objectType.network && parentContent.wfid !== vm.network.wfid && parentContent.wfid !== "71-12224"
					});
					parentDataRelation = parentDataRelation || { wffid: 0, order: -1, parentContent: { title: $translate.instant("modules.valueChain.categories.header") } };

					output = {
						type: "parent",
						content: item,
						parent: parentDataRelation,
						parentGroupWfid: parentDataRelation.wffid,
						title: item.title,
						wfid: item.wfid,
						id: item.wfid,
						objectId: item.id,
						order: parentDataRelation.order
					};

					return output;
				}).value(),
				distinctParentsByWfid = _.keyBy(distinctParents, "wfid");
			;

			vm.filters.parentGroups = _.chain(distinctParents).groupBy("parent.wffid").mapValues(function (categories, key) {
				const parentDataRelation = categories[0].parent;
				const groupCategoryParentRelation = parentDataRelation && parentDataRelation.parentContent.parents ? parentDataRelation.parentContent.parents.filter(x => x.parentId === valueChainService.ids.categoryGroupsStructure)[0] : {}
				const order = _.get(groupCategoryParentRelation, "order")

				return {
					wfid: parentDataRelation.wffid,
					header: parentDataRelation.parentContent.title,
					order: parentDataRelation.order || order,
					parents: categories,
					active: false,
					clearFilter: function (skipUpdatingFilterResult) {
						var parentGroupWfid = this.wfid;
						this.active = false;

						_.each(this.parents, function (item) {
							if (item.parentGroupWfid === parentGroupWfid) {
								delete vm.filters.selectedInvertedFiltersById[item.id];
								delete vm.filters.selectedFilters[item.id];
								_.remove(vm.filters.selectedParents, item);
							}
						})

						if (!skipUpdatingFilterResult)
							filterOnAnything();
					}
				}
			}).map().sortBy("order").value();

			// distinctParents = _.chain(allParents)
			// 	.uniqBy("parentId")
			// 	.map("parentContent")
			// 	.sortBy("title")
			// 	.value();

			if (vm.openedWithParent) {
				// The opened category should not appear as a filter button. So it gets excluded here.
				distinctParents = _.filter(distinctParents, function (item) {
					return item.id !== vm.openedWithParent.id;
				});
			}

			vm.distinctParents = distinctParents;
			vm.distinctParents.unshift({
				title: $translate.instant("All"),
				type: "parent",
				count: vm.organizations.length,
				id: 0,
				wfid: 0
			});

			vm.filters.parents = vm.distinctParents;

			vm.organizationsByAnything = _.chain(allParentRelations)
				.groupBy("wffid")
				.mapValues(function (items, wfid) {
					var output = [], parent;
					for (var i = 0, len = items.length; i < len; i++) {
						output.push(vm.organizationsById[items[i].childId])
					}

					parent = _.find(distinctParents, { wfid: wfid });
					if (parent) {
						parent.count = output.length;
						// console.log(parent.title, parent.count);
					}

					return output;
				}).value();

			vm.parentsByOrg = _.chain(allParentRelations)
				.groupBy("childId")
				.mapValues(function (items) {
					var output = [];
					for (var i = 0, len = items.length; i < len; i++) {
						output.push(distinctParentsByWfid[items[i].wffid])
					}
					return _.uniq(output);
				}).value();

			// vm.organizationsByAnything[0] = _.clone(vm.organizations);

			_.each(vm.filters.parentGroups, function (parentGroup) {
				var organizationsInGroup = [];

				// for (var i = 0, len = parentGroup.categories.length; i < len; i++) {
				// 	Array.prototype.push.apply(organizationsInGroup, vm.organizationsByAnything[parentGroup.categories[i].wfid]);
				// }

				// organizationsInGroup = _.uniq(organizationsInGroup);
				// vm.organizationsByAnything[parentGroup.wfid] = organizationsInGroup;
				vm.organizationsByAnything[parentGroup.wfid] = _.clone(vm.organizations);
			});


			vm.filters.packages = [];

			vm.influencesByPackageWfid = _.groupBy(vm.allInfluences, function (influence) {
				return influence.wfcid;
			});

			_.each(vm.distinctInfluenceStructures, function (structure) {
				var
					organizations = [],
					orgIds = _.chain(vm.allInfluences).filter({ objectId: structure.id }).map("organizationId").uniq().value(),
					filterButton
					;

				filterButton = {
					type: "package",
					id: structure.wfid,
					// type: structure.type,
					wfid: structure.wfid,
					title: structure.title,
					hasImage: structure.hasImage,
					imageUrl: structure.imageUrl,
					count: orgIds.length
				};
				vm.filters.packages.push(filterButton);

				for (var i = 0, len = orgIds.length; i < len; i++) {
					organizations.push(vm.organizationsById[orgIds[i]]);
				}

				vm.organizationsByAnything[structure.wfid] = organizations;
			});


			// vm.filters.influenceYears = [];

			// vm.influencesByYear = _.chain(vm.allInfluences).groupBy(function (influence) {
			// 	return moment(influence.createdAt).format("YYYY");
			// }).value();
			// vm.organizationsByInfluenceYear = _.mapValues(vm.influencesByYear, function (influences) {
			// 	var output = [];
			// 	for (var i = 0, len = influences.length; i < len; i++) {
			// 		output.push(vm.organizationsById[influences[i].organizationId])
			// 	}
			// 	return _.uniq(output);
			// });

			// _.each(vm.organizationsByInfluenceYear, function (organizations, year) {
			// 	var filterButton;

			// 	filterButton = {
			// 		type: "influenceYear",
			// 		id: "year" + year,
			// 		// type: structure.type,
			// 		wfid: "year" + year,
			// 		title: year,
			// 		hasImage: false,
			// 		count: organizations.length
			// 	};
			// 	vm.filters.influenceYears.push(filterButton);
			// 	vm.organizationsByAnything["year" + year] = organizations;

			// });

			var statusTitlesByStatus = {
				// "influences_withDueDate": $translate.instant("modules.valueChain.dueDate"),
				"influences_fulfilled": $translate.instant("Fulfills"),
				"influences_progress": $translate.instant("modules.valueChain.partiallyFulfilled"),
				"influences_overdue": $translate.instant("modules.valueChain.requireAction"),
				"influences_withException": $translate.instant("Exception"),
				"influences_assessmentNeeded": $translate.instant("fulfillmentStates.assessmentNeeded"),
				"influences_dataExpired": $translate.instant("fulfillmentStates.expired"),
				"influences_certificateExpired": $translate.instant("fulfillmentStates.certificateExpired"),
				"influences_signed": $translate.instant("modules.notifications.eventSentences.influenceSigned"),
			};
			var statusIconsByStatus = {
				// "influences_withDueDate": $translate.instant("modules.valueChain.dueDate"),
				"influences_fulfilled": "fas fa-check fulfilled",
				"influences_progress": "fa fa-minus-circle hasProgress",
				"influences_overdue": "fa fa-warning overdue",
				"influences_withException": "fa fa-exclamation specialRequirements",
				"influences_assessmentNeeded": "fa fa-question-circle assessmentNeeded",
				"influences_dataExpired": "",
				"influences_certificateExpired": "",
				"influences_signed": "fa fa-pencil allSigned",
			};
			var influencesByStatus = _.chain(statusTitlesByStatus).clone().mapValues(function () {
				return [];
			}).value();
			vm.filters.statuses = [];

			_.each(vm.allInfluences, function (influence) {
				var
					// withDueDate = !influence.fulfilled && influence.fulfillmentDueAt && !influence.isOverdue,
					progress,
					overdue
					;

				if (influence.isAssessmentNeeded) {
					influencesByStatus["influences_assessmentNeeded"].push(influence);
					return;
				}

				if (influence.isReportedDataExpired) {
					influencesByStatus["influences_dataExpired"].push(influence);
				}

				if (influence.isReportedCertificateExpired) {
					influencesByStatus["influences_certificateExpired"].push(influence);
				}

				if (influence.isSigned) {
					influencesByStatus["influences_signed"].push(influence);
				}

				if (influence.containsSpecialRequirements)
					influencesByStatus["influences_withException"].push(influence);

				if (influence.fulfilled)
					influencesByStatus["influences_fulfilled"].push(influence);
				else {
					// if (withDueDate)
					// 	influencesByStatus["influences_withDueDate"].push(influence);
					progress = !influence.fulfilled && influence.fulfillmentProgress < influence.fulfillmentProgressTotal;
					overdue = !influence.fulfilled && influence.isOverdue;

					if (progress)
						influencesByStatus["influences_progress"].push(influence);
					if (overdue)
						influencesByStatus["influences_overdue"].push(influence);
				}
			});

			_.each(influencesByStatus, function (influences, key) {
				var
					organizations = [],
					orgIds = _.chain(influences).map("organizationId").uniq().value()
					;

				for (var i = 0, len = orgIds.length; i < len; i++) {
					organizations.push(vm.organizationsById[orgIds[i]]);
				}

				vm.organizationsByAnything[key] = organizations;
				vm.filters.statuses.push({
					type: "status",
					id: key,
					wfid: key,
					title: statusTitlesByStatus[key],
					count: organizations.length,
					iconCssClass: statusIconsByStatus[key]
				});
			})
			vm.influencesByAnything = _.assign({}, influencesByStatus, vm.influencesByPackageWfid, vm.influencesByYear);

			if (vm.organizationsWithFailedMail.length) {
				vm.filters.statuses.unshift(vm.filters.failedMailsButton = {
					type: "status",
					id: "failedMails",
					wfid: "failedMails",
					title: $translate.instant("modules.valueChain.failedMails"),
					count: vm.organizationsWithFailedMail.length,
					iconCssClass: "fa fa-times-circle failedMails"
				});
			}

			if (!vm.filters.influenceDatePickers) {
				vm.filters.influenceDatePickers = _.map([
					_.find(vm.filters.influenceDatePickers, { id: "activatedAt" }) || { id: "activatedAt", title: $translate.instant("modules.valueChain.influence.activationDate") },
					_.find(vm.filters.influenceDatePickers, { id: "dueAt" }) || { id: "dueAt", title: $translate.instant("modules.valueChain.influence.dueDateLabel") }
				], function (filter) {
					var today = moment().format();
					var yesterday = moment().subtract(1, 'days').format();

					return _.assign(filter, {
						type: "influenceDate",
						init: function () {
							if (!filter.dataRangePickerHandle) {
								filter.inputElement = $(document).find('#daterangepicker-' + filter.id);
							}
						},
						dateSpan: {
							startDate: undefined,
							endDate: undefined
						},
						dateSpanResult: {},
						dateSpanResult_epoch: {},
						clearFilter: function (skipUpdatingFilterResult) {
							filter.active = false;
							filter.dateSpanResult.startDate = undefined;
							filter.dateSpanResult.endDate = undefined;
							filter.dateSpanResult_epoch.startDate = undefined;
							filter.dateSpanResult_epoch.endDate = undefined;
							delete vm.filters.selectedFilters[filter.id];
							_.remove(vm.filters.selectedParents, filter);

							if (!filter.dataRangePickerHandle) {
								filter.inputElement = $(document).find('#daterangepicker-' + filter.id);
							}

							if (filter.inputElement)
								filter.inputElement.val("");

							filter.subTitle = undefined;

							if (!skipUpdatingFilterResult) {
								$timeout();
								setTimeout(function () {
									filterOnAnything();
								}, 100);
							}
						},
						datePickerOptions: {
							applyClass: 'btn-primary',
							cancelClass: 'btn-hollow',
							// maxDate: today,
							alwaysShowCalendars: true,
							// autoUpdateInput: true, //overridden by angular-daterangepicker.js (it is always false)
							linkedCalendars: false,
							locale: {
								firstDay: 1, // setting Monday to be first day of the week
								opens: 'right',
								format: "YYYY-MM-DD",
								applyLabel: $translate.instant("modules.dateRangePicker.applyLabel"),
								cancelLabel: $translate.instant("modules.dateRangePicker.cancelLabel"),
								monthNames: moment.monthsShort(),
								daysOfWeek: moment.weekdaysShort(),
								customRangeLabel: $translate.instant('modules.dateRangePicker.ranges.customRange')
							},
							// showDropdowns: true,
							ranges: (function () {
								var output = {};
								output[$translate.instant('modules.dateRangePicker.ranges.today')] = [today, yesterday];
								output[$translate.instant('modules.dateRangePicker.ranges.lastWeek')] = [moment().subtract(1, 'weeks').format(), yesterday];
								output[$translate.instant('modules.dateRangePicker.ranges.lastMonth')] = [moment().subtract(1, 'months').format(), yesterday];
								output[$translate.instant('modules.dateRangePicker.ranges.last3Months')] = [moment().subtract(3, 'months').format(), yesterday];
								output[$translate.instant('modules.dateRangePicker.ranges.last6Months')] = [moment().subtract(6, 'months').format(), yesterday];
								output[$translate.instant('modules.dateRangePicker.ranges.lastYear')] = [moment().subtract(1, 'years').format(), yesterday];
								output[$translate.instant('modules.dateRangePicker.ranges.last2Years')] = [moment().subtract(2, 'years').format(), yesterday];
								output[$translate.instant('modules.dateRangePicker.ranges.last3Years')] = [moment().subtract(3, 'years').format(), yesterday];
								return output;
							})(),
							eventHandlers: {
								'apply.daterangepicker': function (event) {
									filter.active = true;
									if (!vm.filters.selectedFilters[filter.id]) {
										vm.filters.selectedFilters[filter.id] = filter;
										vm.filters.selectedParents.push(filter);
									}

									filter.dateSpanResult = {
										startDate: filter.dateSpan.startDate,
										endDate: filter.dateSpan.endDate
									};
									filter.dateSpanResult_epoch = {
										startDate: Math.floor(filter.dateSpan.startDate.toDate().getTime() / 1000),
										endDate: Math.floor(filter.dateSpan.endDate.clone().add(86399, "seconds").toDate().getTime() / 1000)
									};

									filter.subTitle = filter.dateSpanResult.startDate.format("YYYY-MM-DD") + " - " + filter.dateSpanResult.endDate.format("YYYY-MM-DD");
									filter.inputElement.val(filter.subTitle);
									$timeout();
									setTimeout(function () {
										filterOnAnything();
									}, 100)
								},
								'cancel.daterangepicker': function (event) {
									filter.clearFilter();
								}
							}
						}
					})
				});

			}

			syncIndustriesFilterBar();
			syncCountriesFilterBar();
			syncActorTypesFilterBar();
			syncAnalyze();

			_.assign(vm.organizationsByAnything, {
				"failedMails": vm.organizationsWithFailedMail
			})

			vm.filters.allFiltersById = _.keyBy(Array.prototype.concat.call(vm.filters.parents, vm.filters.statuses, vm.filters.packages, vm.filters.influenceYears, vm.filters.industries, vm.filters.actorTypes), "id"); // vm.filters.industries

			if (vm.networkImplementsAnalyze) {
				_.assign(vm.filters.allFiltersById, _.keyBy(Array.prototype.concat.call(vm.filters.analyzeLabels, vm.filters.analyzePackages), "id"));

				if (vm.filters.analyzeScore) {
					vm.filters.allFiltersById[vm.filters.analyzeScore.id] = vm.filters.analyzeScore;
				}
			}

			_.each(vm.filters.selectedParents, function (filter, index) {
				vm.filters.selectedParents[index] = vm.filters.allFiltersById[filter.id];
			});
			vm.filters.selectedParents = _.compact(vm.filters.selectedParents);

			_.each(vm.filters.selectedFilters, function (filter, key) {
				vm.filters.selectedFilters[key] = vm.filters.allFiltersById[key];

				if (!vm.filters.allFiltersById[key])
					filterOnAnything(filter);
			});
			// var
			// 	allParents = _.filter(wfObject.filter({ where: {
			// 		type: enums.objectType.dataRelation,
			// 		parentType: enums.objectType.structure,
			// 		childType: enums.objectType.organization,
			// 		childId: { "in": vm.organizationIds }
			// 	}}), function (item) {
			// 		return item.parentContent;
			// 	}),
			// 	distinctParents
			// ;

			// distinctParents = _.chain(allParents)
			// 	.uniqBy("parentId")
			// 	.map("parentContent")
			// 	.sortBy("title")
			// 	.value();

			// if (vm.openedWithParent) {
			// 	distinctParents = _.filter(distinctParents, function (item) {
			// 		return item.id !== vm.openedWithParent.id;
			// 	});
			// }

			// vm.distinctParents = distinctParents;
			// vm.distinctParents.unshift({
			// 	title: $translate.instant("All"),
			// 	wfid: 0
			// });


			// vm.organizationsByAnything = _.chain(allParents)
			// 	.groupBy("wffid")
			// 	.mapValues(function (items) {
			// 		return _.map(items, "childContent");
			// 	}).value();

			// vm.parentsByOrg = _.chain(allParents)
			// 	.groupBy("childId")
			// 	.mapValues(function (items) {
			// 		return _.map(items, "parentContent");
			// 	}).value();

			// vm.organizationsByAnything[0] = _.clone(vm.organizations);
			// console.timeEnd("syncFilterBars");
		}

		function syncIndustriesFilterBar() {
			var relationsByIndustryWfid = _.groupBy(vm.industryRelations, "wfcid");

			vm.filters.industries = [];

			_.each(relationsByIndustryWfid, function (relations) {
				var
					organizations = [],
					orgIds = _.map(relations, "organizationId"),
					filterButton,
					structure = relations[0].childContent
					;

				filterButton = {
					type: "industry",
					id: structure.wfid,
					wfid: structure.wfid,
					title: structure.title,
					hasImage: structure.hasImage,
					imageUrl: structure.imageUrl,
					count: orgIds.length
				};
				vm.filters.industries.push(filterButton);

				for (var i = 0, len = orgIds.length; i < len; i++) {
					organizations.push(vm.organizationsById[orgIds[i]]);
				}

				vm.organizationsByAnything[structure.wfid] = organizations;
			});
		}

		function syncCountriesFilterBar() {
			var organizationsByCountryId = _.groupBy(vm.organizations.map(x => ({ ...x, countryId: x.countryId || 0 })), "countryId");

			vm.filters.countries = [];

			_.each(organizationsByCountryId, function (organizations, countryId) {
				var
					orgIds = _.map(organizations, "id"),
					filterButton,
					country = vm.countryById[countryId] || { wfid: "75-0", name: $translate.instant("NoCountry"), }
					;

				filterButton = {
					type: "country",
					id: country.wfid,
					wfid: country.wfid,
					title: country.name,
					hasImage: country.hasImage,
					imageUrl: country.imageUrl,
					count: orgIds.length,
					iso3166_alpha2: country.iso3166_alpha2
				};
				vm.filters.countries.push(filterButton);

				vm.organizationsByAnything[country.wfid] = organizations;
			});
		}

		function syncActorTypesFilterBar() {
			var relationsByActorTypeWfid = _.groupBy(vm.actorTypeRelations, "wfcid");

			vm.filters.actorTypes = [];

			_.each(relationsByActorTypeWfid, function (relations) {
				var
					organizations = [],
					orgIds = _.map(relations, "organizationId"),
					filterButton,
					structure = relations[0].childContent
					;

				filterButton = {
					type: "actorType",
					id: structure.wfid,
					wfid: structure.wfid,
					title: structure.title,
					hasImage: structure.hasImage,
					imageUrl: structure.imageUrl,
					count: orgIds.length
				};
				vm.filters.actorTypes.push(filterButton);

				for (var i = 0, len = orgIds.length; i < len; i++) {
					organizations.push(vm.organizationsById[orgIds[i]]);
				}

				vm.organizationsByAnything[structure.wfid] = organizations;
			});
		}

		// Sync filter bars for Analyze
		function syncAnalyze() {
			var
				analyzeJobsWithLabel,
				analyzeJobsByLabel,
				analyzeJobsByObjectWfid,
				analyzeJobScores,
				filterGroups = [],
				scoreFilterGroup,
				filterButtons,
				scoreMinValue,
				scoreMaxValue,
				scoreFilterOptionDummy
				;

			if (!vm.networkImplementsAnalyze)
				return;

			analyzeJobsWithLabel = _.filter(vm.analyzeJobs, function (analyzeJob) {
				return analyzeJob.scoreLabel && analyzeJob.scoreLabel.length;
			});

			analyzeJobsByLabel = _.groupBy(analyzeJobsWithLabel, "scoreLabel");
			analyzeJobsByObjectWfid = _.groupBy(vm.analyzeJobs, "wfcid");

			// Analyze Labels --------------------------

			filterButtons = _.chain(analyzeJobsByLabel).map(function (analyzeJobs, label) {
				var
					orgIds = _.chain(analyzeJobs).map("targetOrganizationId").uniq().value(),
					analyzeJobIds = _.chain(analyzeJobs).map("id").uniq().value(),
					filterButton,
					filterButtonId = "analyze|" + label
					;

				filterButton = {
					type: "analyze-label",
					id: filterButtonId,
					wfid: filterButtonId,
					title: label,
					count: orgIds.length,
					organizations: [],
					analyzeJobIds: analyzeJobIds
				};

				for (var i = 0, len = orgIds.length; i < len; i++) {
					filterButton.organizations.push(vm.organizationsById[orgIds[i]]);
				}

				vm.organizationsByAnything[filterButtonId] = filterButton.organizations;

				return filterButton;

			}).sortBy("title").value();

			if (filterButtons.length) {
				filterGroups.push(_.defaults({
					options: filterButtons,
					wfid: "analyze-labelsGroup",
					header: $translate.instant("modules.valueChain.analyze.labelsFilterBarHeader"),
				}, filterGroupPrototype));
			}

			vm.filters.analyzeLabels = filterButtons;

			// Analyze Package titles --------------------------

			filterButtons = _.chain(analyzeJobsByObjectWfid).map(function (analyzeJobs, objectWfid) {
				var
					title = analyzeJobs[0].title,
					orgIds = _.chain(analyzeJobs).map("targetOrganizationId").uniq().value(),
					analyzeJobIds = _.chain(analyzeJobs).map("id").uniq().value(),
					filterButton,
					filterButtonId = "analyze|" + objectWfid
					;

				filterButton = {
					type: "analyze-title",
					id: filterButtonId,
					wfid: filterButtonId,
					title: title,
					count: orgIds.length,
					organizations: [],
					analyzeJobIds: analyzeJobIds
				};

				for (var i = 0, len = orgIds.length; i < len; i++) {
					filterButton.organizations.push(vm.organizationsById[orgIds[i]]);
				}

				vm.organizationsByAnything[filterButtonId] = filterButton.organizations;

				return filterButton;

			}).sortBy("title").value();

			if (filterButtons.length) {
				filterGroups.push(_.defaults({
					options: filterButtons,
					wfid: "analyze-titlesGroup",
					header: $translate.instant("modules.valueChain.analyze.titlesFilterBarHeader"),
				}, filterGroupPrototype));
			}

			vm.filters.analyzePackages = filterButtons;

			// Analyze scores --------------------------

			analyzeJobScores = _.chain(vm.analyzeJobs)
				.filter(function (analyzeJob) {
					return analyzeJob.score !== null && !isNaN(analyzeJob.score);
				})
				.map("score").value();

			if (analyzeJobScores.length) {
				scoreMinValue = _.floor(_.min(analyzeJobScores));
				scoreMaxValue = _.ceil(_.max(analyzeJobScores));

				if (!vm.filters.analyze.scoreFilterGroup) {
					vm.filters.analyze.scoreFilterGroup = scoreFilterGroup = _.defaults({
						wfid: "analyze-scoreGroup",
						header: $translate.instant("modules.valueChain.analyze.scoreFilterBarHeader"),
						type: "slider",
						scoreMinValue: scoreMinValue,
						scoreMaxValue: scoreMaxValue,
						currentlySelectedMinValue: scoreMinValue,
						currentlySelectedMaxValue: scoreMaxValue,
						clearFilter: function (skipUpdatingFilterResult) {
							// if (vm.filters.selectedFilters["analyze|score"])
							// 	vm.filters.selectedFilters["analyze|score"]
							scoreFilterGroup.active = false;

							scoreFilterGroup.slider.minValue = scoreMinValue;
							scoreFilterGroup.slider.maxValue = scoreMaxValue;
							$timeout();

							if (!skipUpdatingFilterResult)
								scoreFilterGroup.slider.options.onEnd(scoreFilterGroup.slider.options.id, scoreMinValue, scoreMaxValue);
							// filterGroupPrototype.clearFilter.call(this);
						},
						slider: {
							minValue: scoreMinValue, // Two way binded with slider
							maxValue: scoreMaxValue, // Two way binded with slider
							options: {
								id: _.uniqueId(),
								floor: 0,
								showTicks: false,
								showSelectionBar: true,
								draggableRange: true,
								onEnd: function (sliderId, selectedMinValue, selectedMaxValue) {
									var
										matchingAnalyzeJobs,
										orgIds,
										analyzeJobIds,
										filterId = "analyze|score"
										;

									if (scoreFilterGroup.currentlySelectedMinValue === selectedMinValue && scoreFilterGroup.currentlySelectedMaxValue === selectedMaxValue)
										return;

									if (scoreFilterGroup.scoreMinValue === selectedMinValue && scoreFilterGroup.scoreMaxValue === selectedMaxValue) {
										if (vm.filters.selectedFilters[filterId]) {
											_.remove(vm.filters.selectedParents, scoreFilterOptionDummy);
											delete vm.filters.selectedFilters[filterId];
											scoreFilterGroup.active = false;
										}
									}
									else {
										scoreFilterGroup.active = true;

										matchingAnalyzeJobs = _.filter(vm.analyzeJobs, function (analyzeJob) {
											return analyzeJob.score >= selectedMinValue && analyzeJob.score <= selectedMaxValue;
										});
										orgIds = _.chain(matchingAnalyzeJobs).map("targetOrganizationId").uniq().value();
										analyzeJobIds = _.chain(matchingAnalyzeJobs).map("id").uniq().value();

										if (!vm.filters.selectedFilters["analyze|score"]) {
											scoreFilterOptionDummy = _.defaults({
												title: scoreFilterGroup.header,
												subTitle: selectedMinValue + " - " + selectedMaxValue,
												type: "analyze-score",
												id: filterId,
												wfid: filterId,
												clearFilter: function (skipUpdatingFilterResult) {
													scoreFilterGroup.active = false;
													scoreFilterGroup.slider.minValue = scoreMinValue;
													scoreFilterGroup.slider.maxValue = scoreMaxValue;
													$timeout();

													if (!skipUpdatingFilterResult)
														filterOnAnything()
												}
											}, filterOptionPrototype);
											vm.filters.selectedFilters[filterId] = scoreFilterOptionDummy;
											vm.filters.selectedParents.push(scoreFilterOptionDummy);
										}
										else {
											scoreFilterOptionDummy = vm.filters.selectedFilters[filterId]
										}

										scoreFilterGroup.scoreFilterOptionDummy = scoreFilterOptionDummy;
										scoreFilterOptionDummy.organizations = [];
										scoreFilterOptionDummy.analyzeJobIds = analyzeJobIds;
										scoreFilterOptionDummy.subTitle = selectedMinValue + " - " + selectedMaxValue;

										for (var i = 0, len = orgIds.length; i < len; i++) {
											scoreFilterOptionDummy.organizations.push(vm.organizationsById[orgIds[i]]);
										}
									}

									scoreFilterGroup.currentlySelectedMinValue = selectedMinValue;
									scoreFilterGroup.currentlySelectedMaxValue = selectedMaxValue;

									filterOnAnything()
								}
							}
						}
					}, filterGroupPrototype);
				}
				else {
					scoreFilterGroup = vm.filters.analyze.scoreFilterGroup;
					scoreFilterGroup.scoreMinValue = scoreMinValue;
					scoreFilterGroup.scoreMaxValue = scoreMaxValue;
					scoreFilterGroup.currentlySelectedMinValue = scoreFilterGroup.currentlySelectedMinValue;
					scoreFilterGroup.currentlySelectedMaxValue = scoreFilterGroup.currentlySelectedMaxValue;

					scoreFilterGroup.slider.minValue = scoreMinValue;
					scoreFilterGroup.slider.maxValue = scoreMaxValue;

					// vm.filters.analyzeSliderCompiler.compile();
				}

				filterGroups.push(scoreFilterGroup);

				if (vm.filters.analyze.scoreFilterGroup.scoreFilterOptionDummy)
					vm.filters.analyzeScore = vm.filters.analyze.scoreFilterGroup.scoreFilterOptionDummy;
			}


			vm.filters.analyze.groups = filterGroups;

		}

		function syncDistinctInfluenceStructures() {
			var
				distinctStructures
				;

			distinctStructures = _.chain(vm.allInfluences)
				// .filter({ objectType: enums.objectType.structure })
				.uniqBy("wfcid")
				.map(function (influence) {
					var structure = influence.childContent;

					// Newly created influences does not contain childContent so if a structure
					// was used for the first time on a new influence then we attempt to get it from vm.allPackagesInNetwork array.
					if (!structure)
						structure = _.find(vm.allPackagesInNetwork, { wfid: influence.wfcid });

					return structure;
				})
				.compact() // Remove potentially undefined items
				.filter(function (item) {
					return item;
				})
				.sortBy("title")
				.value();

			vm.distinctInfluenceStructures = distinctStructures;
			vm.influenceStructuresById = _.keyBy(distinctStructures, "id");
			vm.influenceStructuresForContextParentById = _.chain(distinctStructures).filter(function (structure) {
				return !structure.conditions || !structure.conditions.requirementPackageSettings || !structure.conditions.requirementPackageSettings.targetContextParentType;
			}).keyBy("id").value();
		}

		function filterOnAnything(filter, onlyFilteringPreview, useInvertedResult, $event) {
			var
				selectedFilters,
				selectedFiltersById,
				filteringPreviewRemoverFunc,

				intersectedWithSearch = [],
				intersectedWithParents = [],
				intersectedWithStatus = [],
				intersectedWithPackages = [],
				intersectedWithIndustries = [],
				intersectedWithCountries = [],
				intersectedWithActorTypes = [],
				intersectedWithInfluenceYears = [],

				intersectedWithAnalyzeLabels = [],
				intersectedWithAnalyzeScores = [],

				influencesWithStatus = [],
				influencesWithPackages = [],
				influencesWithInfluenceYears = [],

				arraysToIntersect = [],
				intersectionItems = [],
				id,
				influenceArraysToIntersect = [],
				intersectedInfluences,
				organizationsFromIntersectedInfluences = [],
				distinctAnalyzeJobIds,

				organizationsByParentsGroupWfid = {},
				orgArraysToIntersect = [],
				activeInfluenceDateFilters,

				addFilterOption = null,
				removeFilterOption = null
				;

			if ($event) {
				$event.stopPropagation();
			}

			// if (_.isEmpty(vm.filters.selectedFilters)) {
			// 	filter = _.find(vm.filters.parents, { id: 0 });
			// }

			if (onlyFilteringPreview) {
				selectedFilters = _.clone(vm.filters.selectedParents);
				selectedFiltersById = _.clone(vm.filters.selectedFilters);

				filteringPreviewRemoverFunc = function (filterOption) {
					if (filter.type == "parent")
						return filterOption.parentGroupWfid === filter.parentGroupWfid;
					else
						return filterOption.type === filter.type || filterOption === filter;
				}

				_.remove(selectedFilters, function (filterOption) {
					return filteringPreviewRemoverFunc(filterOption);
				});

				_.each(selectedFiltersById, function (filterOption, key) {
					if (filteringPreviewRemoverFunc(filterOption))
						delete selectedFiltersById[key];
				});
			}
			else {
				selectedFilters = vm.filters.selectedParents;
				selectedFiltersById = vm.filters.selectedFilters;
			}

			if (filter && typeof filter === "string") {
				filter = vm.filters.allFiltersById[filter];
			}

			if (filter && typeof filter !== "undefined") {
				id = filter.id;

				if (!onlyFilteringPreview && useInvertedResult) {
					if (vm.filters.selectedInvertedFiltersById[id]) {
						delete vm.filters.selectedInvertedFiltersById[id];
					}
					else
						vm.filters.selectedInvertedFiltersById[id] = true;

					if (!selectedFiltersById[id])
						addFilterOption = true;
				}
				else {
					addFilterOption = !selectedFiltersById[id];
					removeFilterOption = !addFilterOption;
				}

				if (removeFilterOption) {
					if (filter.type == "parent" && filter.id == 0 && selectedFiltersById[filter.id]) {
						return;
					}
					delete selectedFiltersById[id];
					_.remove(selectedFilters, filter);

					if (typeof filter.clearFilter === "function")
						filter.clearFilter(true);

					if (selectedFilters.length == 0) {
						// var allButton = _.find(vm.filters.parents, { id: 0 });
						// vm.filters.selectedFilters[allButton.id] = allButton;
						// vm.filters.selectedParents.push(allButton);
					}

					if (!onlyFilteringPreview)
						delete vm.filters.selectedInvertedFiltersById[id];

					filter = undefined;
				}
				else if (addFilterOption) {
					if (filter.type == "parent" && filter.id != 0) {
						// var allButton = _.find(vm.filters.parents, { id: 0 });
						// delete vm.filters.selectedFilters[allButton.id];
						// _.remove(vm.filters.selectedParents, allButton);
					}
					if (filter.type == "parent" && filter.id == 0) {
						_.each(vm.filters.parents, function (item) {
							if (item.id != 0) {
								delete selectedFiltersById[item.id];
								_.remove(selectedFilters, item);
							}
						})

					}

					selectedFiltersById[id] = filter;
					selectedFilters.push(filter);
				}
			}

			_.each(selectedFiltersById, function (value, key) {
				var array;

				if (key in vm.influencesByAnything) {
					// Some filters are based on influences (status (except failedMails), package and influenceYear).
					// Matching influences are intersected first to get a list of organizations and then those organizations are intersected based on other selected filters

					switch (value.type) {
						case "status":
							array = influencesWithStatus;
							break;
						case "package":
							array = influencesWithPackages;
							break;
						case "influenceYear":
							array = influencesWithInfluenceYears;
							break;
					}
					if (array) {
						if (vm.filters.selectedInvertedFiltersById[key]) {
							Array.prototype.push.apply(array, _.difference(vm.allInfluences, vm.influencesByAnything[key]));
						}
						else
							Array.prototype.push.apply(array, vm.influencesByAnything[key]);

						if (!_.includes(influenceArraysToIntersect, array))
							influenceArraysToIntersect.push(array);
					}
				}
				else if (key.indexOf("analyze|") === 0) {
					if (!_.includes(orgArraysToIntersect, value.organizations))
						orgArraysToIntersect.push(value.organizations);

					if (!distinctAnalyzeJobIds)
						distinctAnalyzeJobIds = [];

					Array.prototype.push.apply(distinctAnalyzeJobIds, value.analyzeJobIds);
				}
				else {
					switch (value.type) {
						case "searchFilter":
							array = intersectedWithSearch;
							break;
						case "parent":
							// array = intersectedWithParents;
							array = organizationsByParentsGroupWfid[value.parentGroupWfid];
							if (!array)
								array = organizationsByParentsGroupWfid[value.parentGroupWfid] = [];

							// Array.prototype.push.apply(array, vm.organizationsByAnything[key]);

							break;
						case "status":
							array = intersectedWithStatus;
							break;
						case "package":
							array = intersectedWithPackages;
							break;
						case "industry":
							array = intersectedWithIndustries;
							break;
						case "country":
							array = intersectedWithCountries;
							break;
						case "actorType":
							array = intersectedWithActorTypes;
							break;
						case "influenceDate":
							if (!activeInfluenceDateFilters)
								activeInfluenceDateFilters = {};
							activeInfluenceDateFilters[value.id] = value; // Filtering of dates is done later to increase performance
							break;
					}
					// console.info(_.find(vm.aggregatedParents.itemsByParentWfid[key], { wfid: "71-116" }));
					// console.info(value.type, key);
					if (array) {
						if (vm.filters.selectedInvertedFiltersById[key]) {
							Array.prototype.push.apply(array, _.difference(vm.organizations, vm.organizationsByAnything[key]));
						}
						else
							Array.prototype.push.apply(array, vm.organizationsByAnything[key]);

						if (!_.includes(orgArraysToIntersect, array))
							orgArraysToIntersect.push(array);
					}
				}
			});

			if (influenceArraysToIntersect.length || activeInfluenceDateFilters) {
				if (influenceArraysToIntersect.length === 0) // If only influence date filters are active
					intersectedInfluences = vm.allInfluences;
				else
					intersectedInfluences = _.intersection.apply(null, influenceArraysToIntersect);

				if (activeInfluenceDateFilters)
					intersectedInfluences = filterInfluenceDates(intersectedInfluences);

				_.each(_.uniqBy(intersectedInfluences, "organizationId"), function (influence) {
					var org = vm.organizationsById[influence.organizationId];
					if (org)
						organizationsFromIntersectedInfluences.push(org);
				});

				orgArraysToIntersect.push(organizationsFromIntersectedInfluences);
			}

			if (orgArraysToIntersect.length) {
				intersectionItems = _.intersection.apply(null, orgArraysToIntersect);

				intersectionItems = _.chain(intersectionItems).uniq().filter(function (item) {
					return !!item;
				}).value();
			}
			else {
				intersectionItems = onlyFilteringPreview ? intersectionItems : _.clone(vm.organizations)
			}

			if (onlyFilteringPreview)
				return intersectionItems;


			_.each(vm.filters.parentGroups, function (parentGroup) {
				parentGroup.active = parentGroup.wfid in organizationsByParentsGroupWfid;
			});

			vm.unsearchedFilteredOrganizations.length = 0;
			Array.prototype.push.apply(vm.unsearchedFilteredOrganizations, intersectionItems);

			// Filter including search query
			if (vm.searchActive && vm.filterWithSearchQuery) {
				intersectionItems = _.intersection(vm.searchResultItems, intersectionItems);
				vm.filterWithSearchQuery = false;
			}

			vm.filteredOrganizations.length = 0;
			vm.filteredOrganizationIds.length = 0;

			Array.prototype.push.apply(vm.filteredOrganizations, intersectionItems);
			Array.prototype.push.apply(vm.filteredOrganizationIds, _.map(vm.filteredOrganizations, "id"));

			vm.requiresActionCount = _.chain(vm.allInfluences).filter(function (influence) {
				return !influence.isAssessmentNeeded && !influence.fulfilled && influence.isOverdue && !!~vm.filteredOrganizationIds.indexOf(influence.organizationId);
			}).map("organizationId").uniq().value().length;

			var influencesOnFilteredOrganizations = [];
			_.each(vm.filteredOrganizationIds, function (orgId) {
				var influences = vm.influencesByOrg[orgId];
				if (influences && influences.length)
					Array.prototype.push.apply(influencesOnFilteredOrganizations, vm.influencesByOrg[orgId]);
			})

			vm.influencesOnFilteredOrganizations = influencesOnFilteredOrganizations;

			vm.filteredDistinctInfluenceStructures = _.chain(influencesOnFilteredOrganizations)
				.uniqBy("wfcid")
				.filter({ objectType: enums.objectType.structure })
				.map(function (influence) {
					// console.log(influence.objectId, vm.influenceStructuresById[influence.objectId]);
					return vm.influenceStructuresById[influence.objectId]
				})
				.sortBy("title")
				.value();

			vm.filters.intersectedInfluencesById = _.keyBy(intersectedInfluences, "id");
			vm.filters.hasIntersectedInfluences = intersectedInfluences && intersectedInfluences.length !== 0;

			vm.filteredDistinctInternalInfluenceStructures = _.chain(vm.internalInfluencesByOrg).filter(function (value, key) {
				return !!~vm.filteredOrganizationIds.indexOf(parseInt(key.split("-")[1]));
			}).flatten().map(x => vm.internalInfluenceStructuresById[x.objectId]).compact().uniqBy("id").value();

			_.each(vm.filteredOrganizationIds, function (orgId) {
				var influences = vm.influencesByOrg[orgId];
				if (influences && influences.length)
					Array.prototype.push.apply(influencesOnFilteredOrganizations, vm.influencesByOrg[orgId]);
			})


			if (distinctAnalyzeJobIds && distinctAnalyzeJobIds.length) {
				vm.filters.intersectedAnalyzeJobsById = _.chain(distinctAnalyzeJobIds).uniq().keyBy().value();
				vm.filters.hasIntersectedAnalyzeJobs = true;
			}
			else {
				vm.filters.intersectedAnalyzeJobsById = null
				vm.filters.hasIntersectedAnalyzeJobs = false
			}

			// vm.pagedFilteredItems.length = 0;
			// pagingFunction();
			// if (vm.chartsCompiler)
			// 	vm.chartsCompiler.compile();

			// var selectedFilterCount = vm.filters.selectedParents.length;
			// vm.filters.activeFilterText = selectedFilterCount == 1 ? $translate.instant("modules.filters.activeFilterMessage", { count: selectedFilterCount }) : $translate.instant("modules.filters.activeFilterMessagePlural", { count: selectedFilterCount });

			setFiltersIntersectedPreviewState(filter);

			vm.usersLoading = true;
			vm.pageNumber = 1;
			loadNotificationsAsync();
			loadUsersOnFilteredOrganizations();

			// syncAggregatedDataView();

			$timeout(function () {
				if (vm.showAllActiveFilters)
					toggleActiveFilters($event, true);
			}, 100);

			if (vm.listCompiler)
				vm.listCompiler.compile();


			function filterInfluenceDates(influences) {
				var
					checkActivatedAt,
					activatedAt_startDate,
					activatedAt_endDate,
					checkDueAt,
					dueAt_startDate,
					dueAt_endDate,
					dateEpoch,
					isWithinDateSpan
					// wrappedInfluences = _.intersectionBy(vm.influencesLookupArray, influences, "id");
					;


				if (activeInfluenceDateFilters.activatedAt) {
					checkActivatedAt = true;
					activatedAt_startDate = activeInfluenceDateFilters.activatedAt.dateSpanResult_epoch.startDate;
					activatedAt_endDate = activeInfluenceDateFilters.activatedAt.dateSpanResult_epoch.endDate;
				}

				if (activeInfluenceDateFilters.dueAt) {
					checkDueAt = true;
					dueAt_startDate = activeInfluenceDateFilters.dueAt.dateSpanResult_epoch.startDate;
					dueAt_endDate = activeInfluenceDateFilters.dueAt.dateSpanResult_epoch.endDate;
				}

				influences = _.filter(influences, function (influence) {
					isWithinDateSpan = true;

					if (checkActivatedAt) { // Start with checking activatedAt
						dateEpoch = influence.activatedAt_epoch;
						isWithinDateSpan = dateEpoch >= activatedAt_startDate && dateEpoch <= activatedAt_endDate;
						// console.log(dateEpoch + " >= " + activatedAt_startDate + " && " + dateEpoch + " <= " +  activatedAt_endDate + " == " + isWithinDateSpan)
					}

					if (checkDueAt && isWithinDateSpan) {// If dueAt should be checked, it only needs to be checked if isWithinDateSpan is still true
						dateEpoch = influence.fulfillmentDueAt_epoch;
						isWithinDateSpan = dateEpoch >= dueAt_startDate && dateEpoch <= dueAt_endDate;
					}

					return isWithinDateSpan;
				});

				// influences = _.map(wrappedInfluences, "actualInfluence");

				return influences;
			}
		}

		function setFiltersIntersectedPreviewState(triggeringFilterOption) {
			var filterOptionsToCheck;

			if (vm.filters.selectedParents.length) {
				filterOptionsToCheck = _.chain(vm.filters.allFiltersById)
					.map()
					.reject({ id: 0 });

				// if (triggeringFilterOption)
				// 	filterOptionsToCheck = filterOptionsToCheck
				// 							.reject({ id: triggeringFilterOption.id })
				// 							// .reject({ disabled: true });

				filterOptionsToCheck = filterOptionsToCheck.value();

				if (vm.filters.selectedParents.length == 1 && (!triggeringFilterOption || !vm.filters.selectedInvertedFiltersById[triggeringFilterOption.id])) {
					_.remove(filterOptionsToCheck, vm.filters.selectedParents[0]);
					_.assign(vm.filters.selectedParents[0], {
						actualCount: undefined,
						disabled: false
					})
				}

				_.each(filterOptionsToCheck, function (filterOption) {
					var intersectionResult = filterOnAnything(filterOption, true);

					filterOption.actualCount = intersectionResult.length;

					if (filterOption.actualCount > filterOption.count && !vm.filters.selectedInvertedFiltersById[filterOption.id])
						filterOption.actualCount = undefined;

					filterOption.disabled = intersectionResult.length === 0;
				});
			}
			else {
				filterOptionsToCheck = _.map(vm.filters.allFiltersById);
				_.each(filterOptionsToCheck, function (filterOption) {
					filterOption.actualCount = undefined;
					filterOption.disabled = false;
				});
			}
		}

		function clearAllFilters(event) {
			if (event)
				event.stopPropagation();

			var groups = vm.filters.parentGroups;

			if (groups.length != 0) {
				_.each(groups, function (group) {
					group.clearFilter(true);
				});
			}

			vm.filters.selectedInvertedFiltersById = {};

			if (vm.filters.analyze && vm.filters.analyze.groups) {
				groups = vm.filters.analyze.groups;
				if (groups && groups.length != 0) {
					_.each(groups, function (group) {
						group.clearFilter(true);
					});
				}
			}

			if (vm.filters.selectedParents.length) {
				vm.filters.selectedFilters = {};
				vm.filters.selectedParents.length = 0;
			}

			_.invokeMap(vm.filters.influenceDatePickers, "clearFilter", true);

			filterOnAnything();
		}

		function openInfluence(influence) {
			if (influence.useNewRoute) {
				$window.open(`/exchange/${influence.wfid}`, '_blank')
			}
			else {
				valueChainService.openInfluence(influence, vm.organizationCompilers[influence.organizationId], {
					onClosed: function () {
						var actualInfluence = wfObject.get(influence.wfid)
						_.assign(influence, actualInfluence);
						syncFilterBars();
					}
				});
			}
		}

		function editInternalInfluence(influence) {
			valueChainService.openInfluence(influence, vm.organizationCompilers[influence.organizationId], {
				uiMode: enums.uiMode.admin,
				onClosed: function () {
					var actualInfluence = wfObject.get(influence.wfid)
					_.assign(influence, actualInfluence);
					syncFilterBars();
				}
			});
		}

		function openInternalInfluence(influence) {
			valueChainService.openInfluence(influence, vm.organizationCompilers[influence.organizationId], {
				uiMode: enums.uiMode.work,
				showInfluenceHeader: false,
				uiComponents: {
					showLevelsPanel: true,

					// Filter Bars
					showMaterialityFilterBar: true,
					showDeviationFilterBar: true,
					showCustomFrameworkFilterBar: true,
					showCustomCategoryFilterBar: true,
					showCustomReportFilterBar: true,
					showUserFilterBar: false,
					showAnswerTypeFilterBar: false,
					showRelatedContentFilterBar: true,
					showSelectedFilters: false,

					// Side panels
					showFullfilmentProgress: false,
					showContextStructureProgress: false,
					showMaterialityProgress: false,
					showDeviationProgress: false,
					showCustomFrameworkProgress: false,
					showAnswerTypeProgress: true,
					showSunburstChart: true,
				},
				onClosed: function () {
					var actualInfluence = wfObject.get(influence.wfid)
					_.assign(influence, actualInfluence);
					syncFilterBars();
				}
			});
		}

		function openAnalyzeJob(analyzeJob) {
			analyzeService.openAnalyzeJob(analyzeJob, {
				onClosed: function () {
					var actualAnalyzeJob = wfObject.get(analyzeJob.wfid)
					_.assign(analyzeJob, actualAnalyzeJob);
					vm.organizationCompilers[analyzeJob.targetOrganizationId].compile();
					syncFilterBars();
				}
			});
		}

		function loadNotificationsAsync() {
			loadNotificationsAsync = _.throttle(function () {
				if (notificationsRequest) {
					notificationsRequest.abort();
					notificationsRequest = undefined;
				}

				vm.notificationsLoaded = false;

				if (vm.filteredOrganizationIds.length) {
					notificationsRequest = apiProxy.raw("multi.getObjects", {
						requestSignature_noResultNeeded: ["valueChain", vm.network.id, "notifications"].join("_"),
						objectType: enums.objectType.notification,
						organizationIds: vm.filteredOrganizationIds,
						loadMetadata: false,
						getterConditions: {
							networkId: vm.network.id,
						},
						wrapInRelations: false,
						limit: 10
					});

					notificationsRequest.then(function (notificationsResult) {
						$timeout(function () {
							var filteredNotifications = _.chain(notificationsResult).filter(function (notification) {
								return !!notification;
							}).value();

							vm.notificationsLoaded = true;
							vm.notifications = filteredNotifications;
						});
					});
				}
				else {
					vm.notificationsLoaded = true;
					vm.notifications = [];
				}
			}, 1000);

			loadNotificationsAsync();
		}

		function resyncEverything(skipRefiltering) {
			resyncInfluences();

			vm.organizationWFIDs = _.map(vm.organizations, "wfid");
			vm.organizationIds = _.map(vm.organizations, "id");
			vm.organizationsById = _.keyBy(vm.organizations, "id");

			resyncUsers();

			syncDistinctInfluenceStructures();
			syncFilterBars();

			if (!skipRefiltering)
				filterOnAnything();

			// vm.usersByOrg = _.chain(wfObject.filter({ where: {
			// 	childType: enums.objectType.individual,
			// 	parentType: enums.objectType.organization,
			// 	parentId: { "in": vm.organizationIds }
			// }}))
			// 	.groupBy("parentId")
			// 	.mapValues(function (userDataRelations) {
			// 		return _.map(userDataRelations, "childContent")
			// 	})
			// 	.value();
		}

		function resyncInfluences() {
			var networkId = vm.network.id;

			if (ignoreJSDataCache) {
				vm.allInfluences = _.chain(loadedInfluences)
					.filter(function (influence) {
						var condition = influence.creatorOrganizationId === authOrgId
							&& !influence.isInternal
							&& influence.channelId === networkId
							&& (!influence.contextParentWfids || influence.contextParentWfids === authOrgWfid || influence.contextParentWfids.indexOf("71-") === 0) // Do not show influences with contextParentWfids in the overview unless it's the same as authenticated org or is of structure type
							&& !!~vm.organizationIds.indexOf(influence.organizationId) // If array contains item

						return condition
					})
					.value();

				vm.allInfluences = sortAlphabetically(vm.allInfluences, "title");

				if (vm.networkImplementsInternalReqPackages) {
					vm.allInternalInfluences = _.chain(loadedInternalInfluences)
						.filter(function (influence) {
							var condition = influence.creatorOrganizationId === authOrgId
								&& influence.isInternal
								&& influence.channelId === networkId
								&& influence.organizationId === authOrgId
								&& influence.contextParentWfids && !!~vm.organizationWFIDs.indexOf(influence.contextParentWfids) // If array contains item

							return condition
						})
						.value();

					vm.allInternalInfluences = sortAlphabetically(vm.allInternalInfluences, "title");
					vm.internalInfluenceStructuresById = _.chain(vm.allInternalInfluences).map("childContent").uniq().keyBy("id").value();
				}
			}
			else {
				vm.allInfluences = _.filter(wfObject.filter({
					where: { type: 13, creatorOrganizationId: authOrgId, organizationId: { "in": vm.organizationIds }, channelId: vm.network.id, contextParentWfids: undefined }
				}));
			}
			// vm.influencesLookupArray = _.map(vm.allInfluences, function (influence) {
			// 	return {
			// 		id: influence.id,
			// 		actualInfluence: influence,
			// 		activatedAtMoment: moment(influence.activatedAt),
			// 		dueAtMoment: moment(influence.fulfillmentDueAt),
			// 	}
			// });
			// Alternative way of excluding influences with contextParent:
			// }), function (influence) {
			// 	return !(influence.wfcid in vm.influenceStructuresForContextParentById); // Exclude influences with structure that are ment for specific contextParent
			// });

			vm.influencesByOrg = _.groupBy(vm.allInfluences, "organizationId");
			vm.internalInfluencesByOrg = _.groupBy(vm.allInternalInfluences, "contextParentWfids");
			// console.log(vm.influencesByOrg[109841].length)
		}

		function resyncUsers() {
			var userRelations = wfObject.filter({
				where: {
					childType: enums.objectType.individual,
					parentType: enums.objectType.organization,
					wffid: { "in": vm.organizationWFIDs }
				}
			});

			var usersById = _.keyBy(wfObject.filter({ where: { type: enums.objectType.individual } }), "id");

			var userWfidsInNetwork =
				_.chain(wfObject.filter({
					where: {
						type: enums.objectType.dataRelation,
						childType: enums.objectType.individual,
						wffid: { "in": [vm.network.wfid, "52-1"] },
						contextParentType: enums.objectType.organization,
						contextParentId: { "in": vm.organizationIds }
					}
				}))
					.map(function (dataRelation) {
						if (dataRelation.parentType === enums.objectType.network && dataRelation.parentId === 1) {
							if (!vm.publicContactsByOrg[dataRelation.contextParentId])
								vm.publicContactsByOrg[dataRelation.contextParentId] = {};

							vm.publicContactsByOrg[dataRelation.contextParentId][dataRelation.childId] = true;
						}
						return dataRelation.childId + "|" + dataRelation.contextParentId;
					})
					.uniq()
					.keyBy()
					.value();

			vm.usersByOrg = _.chain(userRelations).sortBy(function (dataRelation) {
				return vm.publicContactsByOrg[dataRelation.parentId] && vm.publicContactsByOrg[dataRelation.parentId][dataRelation.childId];
			}).filter(function (userDataRelation) {
				return userDataRelation.childId + "|" + userDataRelation.parentId in userWfidsInNetwork;
			}).groupBy("parentId").mapValues(function (userDataRelations, orgId) {
				var output = [], failedEmails, userEmails, failedEmailsThatAreNotUsersAnymore;

				for (var i = 0, len = userDataRelations.length; i < len; i++) {
					output.push(usersById[userDataRelations[i].childId]);

				}

				// if org has failed mails and some failed mails are not a user in the organiztaion
				// then remove the email from failed mails array
				if (vm.failedMailsByOrg[orgId]) {
					failedEmails = _.keys(vm.failedMailsByOrg[orgId]);
					userEmails = _.map(output, "email");
					failedEmailsThatAreNotUsersAnymore = _.difference(failedEmails, userEmails);
					if (failedEmailsThatAreNotUsersAnymore.length) {
						for (i = 0, len = failedEmailsThatAreNotUsersAnymore.length; i < len; i++) {
							delete vm.failedMailsByOrg[orgId][failedEmailsThatAreNotUsersAnymore[i]];
						}
						if (_.isEmpty(vm.failedMailsByOrg[orgId])) {
							delete vm.failedMailsByOrg[orgId];
						}
					}
				}

				return output;
			}).value();

			if (_.flatten(vm.failedMailsByOrg).length != vm.organizationsWithFailedMail.length) {
				vm.organizationsWithFailedMail.length = 0;
				Array.prototype.push.apply(vm.organizationsWithFailedMail, _.chain(vm.failedMailsByOrg)
					.map(function (array, orgId) {
						return vm.organizationsById[orgId];
					})
					.value()
				);
				vm.filters.failedMailsButton.count = vm.organizationsWithFailedMail.length;
			}
		}

		function openMailHistory(org) {
			modal.openMailHistory({
				toOrganizationId: org.id,
				includeFilters: true,
				name: org.name
			});
		}

		function changeContactPerson(org) {
			if (vm.publicContactsByOrg && vm.publicContactsByOrg[org.id]) //If an org has a public contact persion do not open the OrgUsersManager
				return;

			valueChainService.openOrganizationUsersManager(org, vm.network, vm.organizationCompilers[org.id]).modal.closed.then(function () {
				resyncUsers();
				vm.organizationCompilers[org.id].compile();
			});
		}

		function changeCategories(org) {
			valueChainService.openOrganizationCategoriesPicker(org, vm.network.id).closed(function () {

				syncFilterBars();
				setFiltersIntersectedPreviewState();
				vm.organizationCompilers[org.id].compile();
			});
		}

		function createInfluence(org) {
			if (ignoreJSDataCache && vm.influencesByOrg[org.id] && vm.influencesByOrg[org.id].length) {
				wfObject.inject(vm.influencesByOrg[org.id]);
			}

			valueChainService.openInfluenceCreator({
				organization: org,
				networkId: vm.network.id,
				compilerControl: vm.organizationCompilers[org.id]
			}).then(function (influences) {
				loadedInfluences = loadedInfluences.concat(influences);
				resyncEverything(true);

				vm.organizationCompilers[org.id].compile();
			});
		}

		function createInternalInfluence(org) {
			if (ignoreJSDataCache && vm.internalInfluencesByOrg[org.wfid] && vm.internalInfluencesByOrg[org.wfid].length) {
				wfObject.inject(vm.internalInfluencesByOrg[org.wfid]);
			}

			valueChainService.openInfluenceCreator({
				useInternalPackages: true,
				organization: wfAuth.getOrganization(),
				networkId: vm.network.id,
				contextParentWfid: org.wfid,
				compilerControl: vm.organizationCompilers[org.id],
			}).then(function (influences) {
				loadedInternalInfluences = loadedInternalInfluences.concat(influences);
				resyncEverything(true);

				vm.organizationCompilers[org.id].compile();
			});
		}

		function createAnalyzeJob(org) {
			var orgAnalyzeJobs = vm.analyzeJobsByOrgId[org.id];

			if (orgAnalyzeJobs && orgAnalyzeJobs.length)
				wfObject.inject(orgAnalyzeJobs);

			analyzeService.openAnalyzeJobCreator({
				organization: org,
				networkId: vm.network.id,
				existingAnalyzeJobs: vm.analyzeJobsByOrgId[org.id]
			}).then(function (newAnalyzeJobs) {
				if (!orgAnalyzeJobs)
					orgAnalyzeJobs = vm.analyzeJobsByOrgId[org.id] = [];

				Array.prototype.push.apply(orgAnalyzeJobs, newAnalyzeJobs)
				Array.prototype.push.apply(vm.analyzeJobs, newAnalyzeJobs)
				resyncEverything(true);

				vm.organizationCompilers[org.id].compile();
			});
		}

		function sendMailToOrganization(organization, mailPurpose) {
			if (!vm.usersByOrg[organization.id] || vm.usersByOrg[organization.id].length === 0 || !vm.influencesByOrg[organization.id] || vm.influencesByOrg[organization.id].length === 0) {
				modal.alert({
					headerText: $translate.instant('modules.valueChain.sendMail.noRequirementUserModal.title'),
					title: $translate.instant('modules.valueChain.sendMail.noRequirementUserModal.message'),
					message: $translate.instant('modules.valueChain.sendMail.noRequirementUserModal.description')
				});
				return;
			}

			if (!hasActiveInfluence(vm.influencesByOrg[organization.id])) {
				modal.alert({
					headerText: $translate.instant('modules.valueChain.sendMail.noActiveInfluenceModal.headerText'),
					title: $translate.instant('modules.valueChain.sendMail.noActiveInfluenceModal.title'),
					message: $translate.instant('modules.valueChain.sendMail.noActiveInfluenceModal.message')
				});
				return;
			}

			if (_.every(vm.influencesByOrg[organization.id], { "isAssessmentNeeded": true })) {
				modal.alert({
					title: $translate.instant("modules.valueChain.sendMail.allInfluencesNeedAssessment.title"),
					message: $translate.instant("modules.valueChain.sendMail.allInfluencesNeedAssessment.message")
				});
				return;
			}

			if (vm.usersByOrg[organization.id].length == 1) {
				modal.previewMail({
					userIds: [vm.usersByOrg[organization.id][0].id],
					networkId: vm.network.id,
					mailPurpose: mailPurpose,
					organizationId: organization.id,
					showSendButton: true,
					showForm: true,
					onSent: function () {
						console.log("Sent!")
					}
				});

				return;
			}

			modal.openCreatorAndPicker({
				create: false,
				pick: true,
				title: $translate.instant('modules.mailPreview.chooseContact'),
				buttons: {
					primary: {
						label: $translate.instant('Next'),
						className: "btn btn-primary",
						callback: function (scope, bucket) {
							if (bucket.allSelected.length == 0) {
								relationBucketEmpty = true;
								modal.alert({
									title: "",
									message: $translate.instant('modules.valueChain.sendMail.noUserSelectedModal.message'),
									onEscape: false,
									type: "info",
									buttons: {
										back: {
											label: "OK",
											className: "btn btn-hollow"
										}
									}
								});
							}
							else {
								scope.$close();
							}
						}
					}
				},
				singlePick: true,
				sourceList: _.map(vm.usersByOrg[organization.id], function (item) {
					return { data: item, wfid: item.wfid }
				}),
				relationBucket: { singlePick: true, allSelected: {} }
			}).closed(function (relationBucketResult) {
				if (relationBucketResult.allSelected.length !== 0) {
					modal.previewMail({
						userIds: [relationBucketResult.allSelected[0].id],
						networkId: vm.network.id,
						mailPurpose: mailPurpose,
						organizationId: organization.id,
						showSendButton: true,
						showForm: true,
						onSent: function () {
							console.log("Sent!")
						}
					});
				}
			});
		}

		function hasActiveInfluence(influences) {
			var now = moment();

			// Is any influences active?
			return _.some(influences, function (influence) {
				var activatedMoment;

				if (influence.activatedAt) {
					activatedMoment = moment(influence.activatedAt);
					return activatedMoment.isSame(now, "day") || activatedMoment.isBefore(now, "day");
				}
				else
					return false;
			});
		}

		function editOrganization(org) {
			var formSpec = {
				schema: {},
				form: ["*"]
			};

			var isVatOrRegNumberValid = false;
			var instantVatOrRegNumberValidation = false;
			var regNumberValid = false;
			var vatNumberValid = false;

			formSpec.schema = {
				"type": "object",
				"properties": {
					"name": {
						"title": $translate.instant("Name"),
						"type": "string",
						"x-schema-form": {
						}
					},
					"registrationNumber": {
						"title": $translate.instant("RegistrationNumber"),
						"type": "string",
						"x-schema-form": {
							validationMessage: {
								vatOrRegNumber: $translate.instant("validationMessages.vatRegOrGlnNumber")
							},
							$validators: {
								vatOrRegNumber: function (value) {
									if (!instantVatOrRegNumberValidation) return true;

									var model = modalPromise.formControl.getModel();
									var result = !!(vatNumberValid || value)
									regNumberValid = !!value;

									if (isVatOrRegNumberValid !== result) {
										isVatOrRegNumberValid = result;
										modalPromise.formControl.$scope.$broadcast('schemaForm.error.vatNumber', 'vatOrRegNumber', result);
									}

									return result;
								}
							}
						}
					},
					"vatNumber": {
						"title": $translate.instant("VATNumber"),
						"type": "string",
						"x-schema-form": {
							validationMessage: {
								vatOrRegNumber: $translate.instant("validationMessages.vatRegOrGlnNumber")
							},
							$validators: {
								vatOrRegNumber: function (value) {
									if (!instantVatOrRegNumberValidation) return true;

									var model = modalPromise.formControl.getModel();
									var result = !!(regNumberValid || value)
									vatNumberValid = !!value;

									if (isVatOrRegNumberValid !== result) {
										isVatOrRegNumberValid = result;
										modalPromise.formControl.$scope.$broadcast('schemaForm.error.registrationNumber', 'vatOrRegNumber', result);
									}

									return result;
								}
							}
						}
					},
					"gln": {
						"title": $translate.instant("GLNNumber"),
						"type": "string"
					},
				},
				"required": ["name"]
			}

			if (vm.isWorldfavorAdmin) {
				formSpec.schema.properties.industry = {
					title: $translate.instant("modules.organization.introModal.form.industries.label"),
					type: "integer",
					"x-schema-form": {
						type: "picker_multiple",
						typeOptions: {
							required: false,
							ticket: { organizationId: org.id, networkId: vm.network.id },
							addButtonCaption: $translate.instant("Select") + " " + $translate.instant("Industry").toLowerCase(),
							targetWfid: "71-14409", // Currently selected industries
							picker: {
								sourceItem: "71-13886", // List of available industries
								title: $translate.instant("modules.organization.introModal.form.industries.pickerTitle"),
								description: $translate.instant("modules.organization.introModal.form.industries.pickerDescription")
							}
						}
					}
				};
			}

			var modalPromise = modal.edit(org, {
				customFormSpecification: formSpec,
				onBeforeSubmitTriggered: function (event) {
					var
						orgModel = event.getModel(),
						formControl = modalPromise.formControl
						;

					orgModel.type == 101;

					event.setModel(orgModel);

					if (!orgModel.registrationNumber && !orgModel.vatNumber) {
						instantVatOrRegNumberValidation = true;
						formControl.$scope.$broadcast('schemaForm.error.registrationNumber', 'vatOrRegNumber', false);
						formControl.$scope.$broadcast('schemaForm.error.vatNumber', 'vatOrRegNumber', false);
						event.cancelSubmit();
						return;
					}
					else {
						event.continueSubmit();
					}
				}
			});

			modalPromise.then(function () {
				syncIndustries(org);
				syncActorTypes(org);
				vm.organizationCompilers[org.id].compile();
			})
		}

		function removeOrganization(org) {
			modal.alert({
				headerText: $translate.instant("modules.valueChain.organizations.remove.modalHeaderText", { orgname: org.name }),
				title: $translate.instant("modules.valueChain.organizations.remove.modalTitle"),
				message: $translate.instant("modules.valueChain.organizations.remove.modalMessage"),
				type: "danger",
				vertical: true,
				buttons: {
					cancel: {
						label: $translate.instant('No'),
						className: "btn-default",
						callback: function () {
						}
					},
					primary: {
						label: $translate.instant('Remove'),
						className: "btn-hollow action",
						callback: function () {
							var
								deleteCounter = 0,
								influenceToDestroy,
								influencesQuery,
								total = 0,
								orgDataRelation = wfObject.filter({
									where: {
										type: enums.objectType.dataRelation,
										parentType: enums.objectType.network,
										wffid: vm.network.wfid,
										wfcid: org.wfid
									}
								})[0],
								checkIfAllDeleted = function () {
									deleteCounter++;
									if (total === deleteCounter) {
										_.remove(vm.allOrganizationsInValueChain, org);
										_.remove(vm.allOrganizationIdsInValueChain, org.id);
										_.remove(vm.organizations, org);
										_.remove(vm.filteredOrganizations, org);
										_.remove(vm.searchResultItems, org);

										delete vm.removingOrganizations[org.id];

										resyncEverything();
										console.log(_.find(vm.searchResultItems, { id: org.id }));

									}
								}
								;

							vm.removingOrganizations[org.id] = true;

							if (orgDataRelation) {
								// Remove from network
								// console.log(52, orgDataRelation);
								total++;
								dataOps.destroy(orgDataRelation).then(function () {
									checkIfAllDeleted();
								});
							}

							// Remove from all categories (if any)
							_.each(wfObject.filter({
								where: {
									type: enums.objectType.dataRelation,
									organizationId: vm.network.organizationId,
									parentType: enums.objectType.structure,
									wfcid: org.wfid
								}
							}), function (item) {
								// console.log(71, item);
								total++;
								dataOps.destroy(item).then(function () {
									checkIfAllDeleted();
								});
							});

							influencesQuery = {
								type: enums.objectType.influence,
								creatorOrganizationId: vm.network.organizationId,
								organizationId: org.id,
								channelId: vm.network.id
							};

							if (ignoreJSDataCache) {
								influenceToDestroy = _.remove(loadedInfluences, influencesQuery);
							}
							else {
								influenceToDestroy = wfObject.filter({ where: influencesQuery });
							}

							// Remove all influences to organization (if any)
							_.each(influenceToDestroy, function (item) {
								// console.log(13, item);
								total++;
								dataOps.destroy(item).then(function () {
									checkIfAllDeleted();
								});
							});

							$timeout();
						}
					}
				}
			});
		}

		function addOrganization() {
			var
				swedishOrgNumberRegExp = /^(\d{1})(\d{5})\-(\d{4})$/,
				jqDf = $.Deferred(),
				mapOrgsByRegNumber = function (organizations) {
					return _.chain(organizations).filter(function (org) {
						return org.registrationNumber && org.registrationNumber.length > 0 // Ignore empty reg numbers
					}).map(function (org) {
						var orgNumber = org.registrationNumber;

						if (org.countryId === 190) { // Sweden
							if (swedishOrgNumberRegExp.test(orgNumber))
								orgNumber = orgNumber; // If valid swedish reg number, use that
							else if (orgNumber.length === 10 && !isNaN(orgNumber)) // If correct length and only numbers (like 1234567890)
								orgNumber = orgNumber.substr(0, 6) + "-" + orgNumber.substr(6); // Add a dash (like 123456-7890)
						}

						return [orgNumber, org];
					}).fromPairs().value()
				},
				valueChainOrganizationsByRegNumber = mapOrgsByRegNumber(vm.allOrganizationsInValueChain),
				categoryOrganizationsByRegNumber = vm.openedWithParent ? mapOrgsByRegNumber(vm.organizations) : null,
				addRelationToCategoryOnly = false
				;

			var formSpec = {
				schema: {},
				form: ["*"]
				// onBeforeSubmit: function (model) {
				// },

			};

			var isVatOrRegNumberValid = false;
			var instantVatOrRegNumberValidation = false;
			var regNumberValid = false;
			var vatNumberValid = false;

			formSpec.schema = {
				"type": "object",
				"properties": {
					"name": {
						"title": $translate.instant("Name"),
						"type": "string",
						"x-schema-form": {
						}
					},
					"registrationNumber": {
						"title": $translate.instant("RegistrationNumber"),
						"type": "string",
						"x-schema-form": {
							validationMessage: {
								vatOrRegNumber: $translate.instant("validationMessages.vatRegOrGlnNumber")
							},
							$validators: {
								vatOrRegNumber: function (value) {
									if (!instantVatOrRegNumberValidation) return true;

									var model = modalPromise.formControl.getModel();
									var result = !!(vatNumberValid || value)
									regNumberValid = !!value;

									if (isVatOrRegNumberValid !== result) {
										isVatOrRegNumberValid = result;
										modalPromise.formControl.$scope.$broadcast('schemaForm.error.vatNumber', 'vatOrRegNumber', result);
									}

									return result;
								}
							}
						}
					},
					"vatNumber": {
						"title": $translate.instant("VATNumber"),
						"type": "string",
						"x-schema-form": {
							validationMessage: {
								vatOrRegNumber: $translate.instant("validationMessages.vatRegOrGlnNumber")
							},
							$validators: {
								vatOrRegNumber: function (value) {
									if (!instantVatOrRegNumberValidation) return true;

									var model = modalPromise.formControl.getModel();
									var result = !!(regNumberValid || value)
									vatNumberValid = !!value;

									if (isVatOrRegNumberValid !== result) {
										isVatOrRegNumberValid = result;
										modalPromise.formControl.$scope.$broadcast('schemaForm.error.registrationNumber', 'vatOrRegNumber', result);
									}

									return result;
								}
							}
						}
					},
					"gln": {
						"title": $translate.instant("GLNNumber"),
						"type": "string"
					},
				},
				"required": ["name"]
			}

			var modalPromise = modal.createWithPromise({
				// countryId: 190,
				type: 101
			},
				{
					title: $translate.instant("modules.valueChain.organizations.createNew"),
					submitCaption: $translate.instant("Add"),
					customFormSpecification: formSpec,
					bypassAdapter: true,
					onBeforeSubmitTriggered: function (event) {
						var
							orgModel = event.getModel(),
							formControl = modalPromise.formControl,
							organizationAlreadyInValueChain = valueChainOrganizationsByRegNumber[orgModel.registrationNumber],
							organizationAlreadyInCategory = vm.openedWithParent ? categoryOrganizationsByRegNumber[orgModel.registrationNumber] : null
							;

						orgModel.type == 101;

						event.setModel(orgModel);

						if (!orgModel.registrationNumber && !orgModel.vatNumber) {
							instantVatOrRegNumberValidation = true;
							formControl.$scope.$broadcast('schemaForm.error.registrationNumber', 'vatOrRegNumber', false);
							formControl.$scope.$broadcast('schemaForm.error.vatNumber', 'vatOrRegNumber', false);
							event.cancelSubmit();
							return;
						}


						// console.log("OPEN WITH PARENT", !!vm.openedWithParent);
						// console.log("IN CATEGORY", organizationAlreadyInValueChain);
						// console.log("IN VALUE CHAIN", organizationAlreadyInCategory);

						if (vm.openedWithParent && organizationAlreadyInValueChain && !organizationAlreadyInCategory) {
							// If the organization is already in the Data Collector but not in the currently opened category
							addRelationToCategoryOnly = true;
							// console.log("addRelationToCategoryOnly");
							event.setResultAndCloseModal(organizationAlreadyInValueChain);
						}
						else if ((vm.openedWithParent && organizationAlreadyInCategory) || organizationAlreadyInValueChain) {
							// If the organization is already in the Data Collector and/or in the opened category
							$ngBootbox.customDialog({
								title: $translate.instant('modules.valueChain.organizations.alreadyAdded.modalTitle'),
								message: $translate.instant('modules.valueChain.organizations.alreadyAdded.modalMessage', {
									orgname: organizationAlreadyInValueChain.name,
									orgnumber: organizationAlreadyInValueChain.registrationNumber
								}),
								onEscape: true,
								className: "valueChain-modal-orgAlreadyExists",
								buttons: {
									cancel: {
										label: $translate.instant('OK'),
										className: "btn-primary",
										callback: function () {
											event.cancelSubmit();
										}
									}
								}
							});
						}
						else {
							// If the organization is not already in the Data Collector then
							// check with server if it already exists in the database (using registration number)
							apiProxy("utility.getOrganizationByCondition", {
								registrationNumber: orgModel.registrationNumber,
								vatNumber: orgModel.vatNumber,
								gln: orgModel.gln
							}).then(function (orgs) {
								var org;

								if (!orgs.length)
									event.continueSubmit();
								else {
									if (orgs.length === 0) {
										org = orgs[0];
										$ngBootbox.customDialog({
											title: $translate.instant('modules.valueChain.organizations.alreadyExists.modalTitle'),
											message: $translate.instant('modules.valueChain.organizations.alreadyExists.modalMessage', {
												orgname: org.name,
												orgnumber: org.registrationNumber
											}),
											onEscape: true,
											className: "valueChain-modal-orgAlreadyExists",
											buttons: {
												cancel: {
													label: $translate.instant('No'),
													className: "btn-default",
													callback: function () {
														event.cancelSubmit();
													}
												},
												primary: {
													label: $translate.instant('Yes'),
													className: "btn-primary",
													callback: function () {
														wfObject.inject(org);
														org = wfObject.get(org.wfid);
														event.setResultAndCloseModal(org);
													}
												}
											}
										});
									}
									else {
										var sourceList = [];

										sourceList = _.map(orgs, function (org) {
											return {
												data: org,
												wfid: org.wfid
											};
										});

										modal.openCreatorAndPicker({
											title: $translate.instant('modules.valueChain.organizations.multipleAlreadyExists.modalTitle'),
											description: $translate.instant('modules.valueChain.organizations.multipleAlreadyExists.modalMessage'),
											singlePick: true,
											relationBucket: { preSelected: [], allSelected: [] },
											sourceList: sourceList,
											buttons: [
												{
													label: "OK",
													callback: function ($scope, relationBucketResult) {
														org = relationBucketResult.allSelected[0];
														if (org) {
															wfObject.inject(org);
															org = wfObject.get(org.wfid);
															event.setResultAndCloseModal(org);
														}
														$scope.$close();
													}
												}
											]
										}).closed(function (relationBucketResult) {
											event.cancelSubmit();
										});
									}
								}
							});
							// event.closeModal();
						}
					}
				}
			);

			modalPromise.then(function (organization) {
				var
					addToCategory = function () {
						return dataOps.createSubItemRelation(vm.openedWithParent, organization, enums.subItemsKind.childrenByUser);
					},
					finish = function () {
						dataOps.getObject({
							objectType: enums.objectType.organization,
							objectId: organization.id,
							getterConditions: {
								includeOrganizationsUsers: true
							}
							// bypassCache: true
						}).then(function () {
							jqDf.resolve();
							resyncEverything();
							$timeout();
						});
					}
					;

				organization = wfObject.inject(organization);

				if (addRelationToCategoryOnly) {
					addToCategory().then(function () {
						if (!_.find(vm.organizations, { id: organization.id })) {
							vm.organizations.unshift(organization);
						}
						finish();
					});
				}
				else {
					if (vm.openedWithParent) {
						addToCategory().then(function (res) {
							vm.currentOrgCreationDeferred = jqDf;
							dataOps.createSubItemRelation(vm.network, organization, enums.subItemsKind.childrenByUser);
							// Logic happens in listener $scope.$on("wfObject.created" ...
						});
					}
					else {
						vm.currentOrgCreationDeferred = jqDf;
						dataOps.createSubItemRelation(vm.network, organization, enums.subItemsKind.childrenByUser);
						// Logic happens in listener $scope.$on("wfObject.created" ...

					}

					// dataOps.createSubItemRelation(vm.network, organization, enums.subItemsKind.childrenByUser).then(function (res) {
					// 	if (vm.openedWithParent) {
					// 		addToCategory().then(function (res) {
					// 			finish();
					// 		});
					// 	}
					// 	else {
					// 		// Logic happens in listener $scope.$on("wfObject.created" ...
					// 	}
					// });
				}
			});

			jqDf.promise();
		}

		function deleteInfluence(influence, organization) {
			valueChainService.deleteInfluence(influence, organization).then(function () {
				if (influence.isInternal) {
					_.remove(loadedInternalInfluences, { id: influence.id });
					_.remove(vm.internalInfluencesByOrg[organization.wfid], influence);
				}
				else {
					_.remove(loadedInfluences, { id: influence.id });
					_.remove(vm.influencesByOrg[organization.id], influence);
				}
				resyncEverything(true);

				vm.organizationCompilers[organization.id].compile();
			});
		}

		function deleteAnalyzeJob(analyzeJob, organization) {
			analyzeService.deleteAnalyzeJob(analyzeJob, organization).then(function () {
				_.remove(vm.analyzeJobsByOrgId[organization.id], analyzeJob);
				_.remove(vm.analyzeJobs, analyzeJob);
				resyncEverything(true);

				vm.organizationCompilers[organization.id].compile();
			});
		}

		function openInfluenceGrouper(objectType) {
			var scope = {};

			scope.structure = vm.productionSitesStructure;
			scope.network = vm.network;

			// Because vm.allInfluences only contains influences without contextParentWfids then they need to be fetched here with a query
			scope.influences = wfObject.filter({ where: { type: enums.objectType.influence, creatorOrganizationId: authOrgId, organizationId: { "in": vm.organizationIds }, channelId: vm.network.id } });;

			modal.open({
				title: $translate.instant("ProductionSites"),
				template: '<wf-influence-grouper network="network" item="structure" influences="influences"></wf-influence-grouper>',
				scope: scope,
				className: "modal-width-1100"
			});
		}

		function admin_setRootObject(org) {
			// dataOps.getObjects({}).then(function () {});
			var availableRootObjects = [
				{ value: -1, name: 'Report and ISO-standards (Default for all orgs)' },
				{ value: 12756, name: 'Report and ISO-standards' },
				{ value: 13544, name: 'Everything (Manage, Visualize, VC, Report)' },
				{ value: enums.ids.root.solutionPackagingRoot, name: "Solution packaging" }
			];
			var formSpec = {
				schema: {
					"type": "object",
					"properties": {
						"rootObjectId": {
							"title": 'Root',
							"type": "integer"
						}
					}
				},
				form: [
					{
						key: "rootObjectId",
						type: "select",
						titleMap: availableRootObjects
					}
				]
			};

			getOrganizationRootObject(org.id).then(function (rootObjectSimplified) {
				if (!_.some(availableRootObjects, { value: rootObjectSimplified.id })) {
					availableRootObjects.push({ value: rootObjectSimplified.id, name: "(OLD) " + rootObjectSimplified.title });
				}

				modal.edit({
					rootObjectId: rootObjectSimplified.id
				},
					{
						title: "Set root for " + org.name,
						action: function (model) {
							return $q(function (resolve, reject) {
								apiProxy("authentication.setOrganizationRoot", { organizationId: org.id, rootObjectId: model.rootObjectId }).then(function () {
									resolve();
								});
							});
						},
						customFormSpecification: formSpec
					}
				).then(function () {
				});
			});
		}

		function admin_moveValueChainToSolution(org) {
			var
				availableSolutionNetworkStructures = [
					{ id: valueChainService.ids.networksStructureBySolution.sustManagement, name: "Sustainability Management" },
					{ id: valueChainService.ids.networksStructureBySolution.sustSourcing, name: "Sustainable Sourcing" },
					{ id: valueChainService.ids.networksStructureBySolution.sustSourcing2, name: "Sustainable Sourcing2" },
					{ id: valueChainService.ids.networksStructureBySolution.sustSourcing3, name: "Sustainable Sourcing3" },
					{ id: valueChainService.ids.networksStructureBySolution.sustSourcing4, name: "Sustainable Sourcing4" },
					{ id: valueChainService.ids.networksStructureBySolution.sustSourcing5, name: "Sustainable Sourcing5" },
					{ id: valueChainService.ids.networksStructureBySolution.sustLending, name: "Sustainable Lending" },
					{ id: valueChainService.ids.networksStructureBySolution.sustInvestments, name: "Sustainable Investments" },
				]
				;

			if (org.loading) return;

			// get networks for org and where they belong (parents)
			// list the networks and where they belong
			//   - 12226: Old solution Data Collector
			//   - Other structure: New solution (hide 12226 in this case)
			// for each network show dropdown to change belonging to new solution
			// show save button next to dropdown

			org.loading = true;
			apiProxy("multi.getObjects", {
				objectType: enums.objectType.network,
				loadParents: true,
				wrapInRelations: false,
				getterConditions: {
					organizationId: org.id
				}
			}).then(function (networks) {
				var networkItems = [];
				org.loading = false;

				networkItems = _.map(networks, function (network) {
					var
						output,
						solutionNetworkStructureRelation = _.chain(network.parents).filter({ parentType: 71 }).reject({ parentId: 12226 }).first().value(),
						solutionNetworkStructureId = _.get(solutionNetworkStructureRelation, "parentId")
						;

					output = {
						network: network,
						solutionNetworkStructureRelation: solutionNetworkStructureRelation,
						solutionNetworkStructureId: solutionNetworkStructureId,
						isMovedToSolution: !solutionNetworkStructureId,
						saveNetworkPlace: saveNetworkPlace,
						model: {
							solutionNetworkStructureId: solutionNetworkStructureId
						},
						onChange: onChange
					};

					return output;
				});

				modal.open({
					title: $translate.instant("Manage networks for") + " " + org.name,
					// template: "Test",
					template: '<div wf-include src="scripts/wf/valueChain/valueChainNetworkAdmin.template.html"></div>',
					scope: {
						networkItems: networkItems,
						availableSolutionNetworkStructures: availableSolutionNetworkStructures
					},
					size: "width-700"
				});
			});

			function saveNetworkPlace() {
				var item = this;
				if (item.saving) return;
				item.saving = true;
				return $q(function (resolve, reject) {
					item.saving = true;

					if (item.solutionNetworkStructureRelation) {
						item.solutionNetworkStructureRelation.parentId = item.model.solutionNetworkStructureId;

						dataOps.update(item.solutionNetworkStructureRelation).then(function (relation) {
							item.solutionNetworkStructureId = item.model.solutionNetworkStructureId;
							item.hasChanges = false;
							item.saving = false;
							resolve();
						});
					}
					else {
						dataOps.create({
							type: 73,
							organizationId: org.id,
							parentType: 71,
							parentId: item.model.solutionNetworkStructureId,
							parentData1: null,
							childType: 52,
							childId: item.network.id
						}).then(function (relation) {
							item.solutionNetworkStructureRelation = relation;
							item.solutionNetworkStructureId = item.model.solutionNetworkStructureId;
							item.hasChanges = false;
							item.saving = false;
							resolve();
						});
					}
				})
			}

			function onChange() {
				if (this.saving) return;
				this.hasChanges = this.solutionNetworkStructureId !== this.model.solutionNetworkStructureId;
			}
		}

		function admin_createValueChain(org) {
			var networkParentId;
			var formSpec = {
				schema: {
					"type": "object",
					"properties": {
						"networkTitle": {
							"title": $translate.instant("Title"),
							"type": "string"
						}
					},
					"required": ["networkTitle"]
				},
				form: [
					"networkTitle"
				]
			};

			getOrganizationRootObject(org.id).then(function (rootObjectSimplified) {
				if (rootObjectSimplified.id === enums.ids.root.solutionPackagingRoot) {
					networkParentId = valueChainService.ids.networksStructureBySolution.sustManagement;
					formSpec.schema.properties.networkParentId = {
						"title": "Solution",
						"type": "integer"
					};
					formSpec.schema.required.push("networkParentId")
					formSpec.form.unshift({
						key: "networkParentId",
						type: "select",
						titleMap: [
							{ value: valueChainService.ids.networksStructureBySolution.sustManagement, name: "Sustainability Management" },
							{ value: valueChainService.ids.networksStructureBySolution.sustSourcing, name: "Sustainable Sourcing" },
							{ value: valueChainService.ids.networksStructureBySolution.sustSourcing2, name: "Sustainable Sourcing2" },
							{ value: valueChainService.ids.networksStructureBySolution.sustSourcing3, name: "Sustainable Sourcing3" },
							{ value: valueChainService.ids.networksStructureBySolution.sustSourcing4, name: "Sustainable Sourcing4" },
							{ value: valueChainService.ids.networksStructureBySolution.sustSourcing5, name: "Sustainable Sourcing5" },
							{ value: valueChainService.ids.networksStructureBySolution.sustLending, name: "Sustainable Lending" },
							{ value: valueChainService.ids.networksStructureBySolution.sustInvestments, name: "Sustainable Investments" },
						]
					});

					openEditor();
				}
				else {
					openEditor();
				}
			});

			function openEditor() {
				modal.edit({
					networkTitle: org.name,
					networkParentId: networkParentId
				},
					{
						title: "Create a Data Collector network for " + org.name,
						action: function (model) {
							return $q(function (resolve, reject) {
								apiProxy("admin.createValuechain", { organizationId: org.id, networkTitle: model.networkTitle, networkParentId: model.networkParentId }).then(function (res) {
									modal.alert({
										title: "Data Collector created",
										message: "For organization: " + $sanitize(org.name) + "<br />Network name: " + $sanitize(res.network.title) + "<br /><br />Mail settings for invitation and reminder were created and set to inactive."
									})
									resolve();
								});
								resolve();

							});
						},
						customFormSpecification: formSpec
					}).then(function () {
					});
			}
		}

		function getOrganizationRootObject(orgId) {
			return apiProxy("authentication.getOrganizationRoot", { organizationId: orgId, culture: wfAuth.getCulture() });
		}

		function setCustomId(org) {
			var existingParameterValue = vm.customIdsByOrgId[org.id];

			modal[existingParameterValue ? "edit" : "createWithPromise"](existingParameterValue || {
				type: enums.objectType.parameterValue,
				parameterId: 33,
				objectType: enums.objectType.organization,
				objectId: org.id
			}, {
				title: $translate.instant("SetCustomID") + " " + $translate.instant("for") + " " + org.name,
				customFormSpecification: {
					form: [
						{
							key: "value",
							title: " ", // Non-break space (Alt+0160)
							type: "text"
						}
					]
				}
			}).then(function (parameterValue) {
				vm.customIdsByOrgId[org.id] = parameterValue;
				vm.organizationCompilers[org.id].compile();
			});
		}

		function setYearlySpend(org) {
			var existingParameterValue = vm.yearlySpendByOrgId[org.id];

			modal[existingParameterValue ? "edit" : "createWithPromise"](existingParameterValue || {
				type: enums.objectType.parameterValue,
				parameterId: 35,
				objectType: enums.objectType.organization,
				objectId: org.id
			}, {
				title: $translate.instant("SetYearlySpend") + " " + $translate.instant("for") + " " + org.name,
				customFormSpecification: {
					form: [
						{
							key: "value",
							title: " ", // Non-break space (Alt+0160)
							type: "text"
						}
					]
				}
			}).then(function (parameterValue) {
				vm.yearlySpendByOrgId[org.id] = parameterValue;
				vm.organizationCompilers[org.id].compile();
			});
		}

		function loadUsersOnFilteredOrganizations() {
			loadUsersOnFilteredOrganizations = _.throttle(function () {
				var currentOrgs = _.orderBy(vm.searchActive ? vm.searchResultItems : vm.filteredOrganizations, ["createdAt"], ["desc"]);
				var orgIds;

				if (currentOrgs.length <= pageSize)
					vm.pageNumber = 1;

				var pageNumber = vm.pageNumber || 1;
				orgIds = _.chain(currentOrgs).slice(pageSize * (pageNumber - 1)).take(pageSize).map("id").value();
				orgIds = _.difference(orgIds, alreadyLoadedUsersOnOrgIds);

				if (orgIds.length) {
					loadUsersOnOrganizations(orgIds).then(function () {
						$timeout();
					});
				}
				else {
					vm.usersLoading = false;
				}
			}, 1000);

			loadUsersOnFilteredOrganizations();
		}

		function loadUsersOnOrganizations(organizationIds) {
			if (usersRequest) {
				usersRequest.abort();
				usersRequest = undefined;
			}

			return $q(function (resolve, reject) {
				if (organizationIds.length) {
					usersRequest = apiProxy.raw("multi.getObjects", {
						objectType: 100,
						getterConditions: {
							networkId: vm.network.id,
							organizationIds: organizationIds
						}
					});

					usersRequest.then(function (res) {
						Array.prototype.push.apply(alreadyLoadedUsersOnOrgIds, organizationIds);
						wfObject.inject(res);
						vm.usersLoading = false;
						resyncUsers();
						resolve();
					});
				}
				else
					resolve();
			})
		}

		function pageChanged(pageNumber) {
			vm.usersLoading = true;
			$timeout();
			vm.pageNumber = pageNumber;
			loadUsersOnFilteredOrganizations();
		}

		function onSearch(searchString, items, searchActive) {
			vm.pagedFilteredItems.length = 0;
			vm.searchActive = searchActive;

			vm.filterWithSearchQuery = true;
			debouncedFilterOnAnything();
		}

		function debouncedFilterOnAnything() {
			debouncedFilterOnAnything = _.debounce(function () {
				filterOnAnything();
			}, 1000);

			debouncedFilterOnAnything();
		}

		function pagingFunction() {
			var newChunk;
			newChunk = _.chain(vm.searchResultItems).slice(vm.pagedFilteredItems.length).take(pageSize).value();

			if (newChunk.length) {
				Array.prototype.push.apply(vm.pagedFilteredItems, newChunk)
			}
		}

		function openConsolidatedPackageView(structure, useInternalMode) {
			var categories = [];
			var categoryGroups = [];
			var statusesEtc = [];
			/*
			[
				{
					title: "Category title 1",
					orgIds: [ 1,2,3,4 ]
				},
				{
					title: "Influence status 1",
					orgIds: [ 1,2,3,4 ]
				},
				{
					title: "Package title 1",
					orgIds: [ 1,2,3,4 ]
				}
			]
			*/

			_.each(vm.filters.parentGroups, function (categoryGroup) {
				const categoryGroupInfo = {
					id: "group" + categoryGroup.wfid,
					title: categoryGroup.header,
					categories: []
				}
				categoryGroups.push(categoryGroupInfo);
				_.each(categoryGroup.parents, function (item) {
					categoryGroupInfo.categories.push({
						id: item.wfid,
						title: item.title,
						orgIds: _.map(vm.organizationsByAnything[item.wfid], "id")
					});

					categories.push({
						id: item.wfid,
						title: categoryGroup.header + " - " + item.title,
						orgIds: _.map(vm.organizationsByAnything[item.wfid], "id")
					});
				});
			});

			_.each(vm.filters.statuses, function (item) {
				statusesEtc.push({
					id: item.id,
					title: "Status - " + item.title,
					orgIds: _.map(vm.organizationsByAnything[item.id], "id")
				});
			});

			_.each(vm.filters.packages, function (item) {
				statusesEtc.push({
					id: item.id,
					title: "Package - " + item.title,
					orgIds: _.map(vm.organizationsByAnything[item.id], "id")
				});
			});

			_.each(vm.filters.analyzeLabels, function (item) {
				statusesEtc.push({
					id: item.id,
					title: "Analyze Label - " + item.title,
					orgIds: _.map(vm.organizationsByAnything[item.id], "id")
				});
			});

			_.each(vm.filters.analyzePackages, function (item) {
				if (!item.title) return;

				statusesEtc.push({
					id: item.id,
					title: "Analyze Package - " + item.title,
					orgIds: _.map(vm.organizationsByAnything[item.id], "id")
				});
			});

			var orgIds, contextParentWfids;

			if (useInternalMode) {
				orgIds = [authOrgId];
				contextParentWfids = _.map(vm.filteredOrganizationIds, function (id) {
					return "101-" + id;
				});
			}
			else {
				orgIds = vm.filteredOrganizationIds;

				if (_.get(structure.conditions, "requirementPackageSettings.targetContextParentWfid")) {
					contextParentWfids = [structure.conditions.requirementPackageSettings.targetContextParentWfid];
				}
				else {
					contextParentWfids = null
				}
			}

			valueChainService.openConsolidatedPackageView({
				structure: structure,
				networkId: vm.network.id,
				organizationIds: orgIds,
				selectedFilterOptions: _.map(vm.filters.selectedParents, function (filter) {
					return filter.title + (filter.subTitle ? "(" + filter.subTitle + ")" : "");
				}),
				influencesByOrgId: _.mapValues(vm.influencesByOrg, function (influences) {
					return _.filter(influences, { objectId: structure.id })
				}),
				organizationsById: vm.organizationsById,
				categories: categories,
				categoryGroups: categoryGroups,
				statusesEtc: statusesEtc,
				contextParentWfids: contextParentWfids,
				isInternalPackage: useInternalMode
			});
		}

		function goToAnchor(id) {
			var gap = 60;
			if (!vm.filtersElement)
				vm.filtersElement = $("#filters.filters.collapse");

			if (id) {
				$('html, body').animate({
					scrollTop: $("#" + id).offset().top - gap
				}, 500, null, function () {
					if (vm.hideFilters) {
						vm.hideFilters = false;
						vm.filtersElement.collapse("show");
						$timeout();
					}
				});
			}
		}

		function toggleActiveFilters(event, checkHeight) {
			if (event && !vm.btnGroupElement)
				vm.btnGroupElement = $($(event.currentTarget).closest("div.filter-options")).children("div.btn-group");

			if (vm.showAllActiveFilters && vm.btnGroupElement && checkHeight) {
				if (vm.btnGroupElement.outerHeight() > 80)
					return;
			}

			vm.showAllActiveFilters = !vm.showAllActiveFilters;
		}



		function export_includeCategorizations_onChange() {
			vm.export.hideExportUI = true;
			vm.export.mappingOptions = vm.export.getMappingOptionsForCsvExport();
			vm.export.items = vm.export.getRowsForCsvExport();
			$timeout();

			$timeout(function () {
				vm.export.hideExportUI = false;
			}, 100);
		}

		function export_initExportPanel() {
			vm.export.mappingOptions = getMappingOptionsForCsvExport();
			vm.export.items = getRowsForCsvExport();

			vm.export.getMappingOptionsForCsvExport = getMappingOptionsForCsvExport;
			vm.export.getRowsForCsvExport = getRowsForCsvExport;

			function getMappingOptionsForCsvExport() {
				var
					output = {},
					itemWfidsThatHaveAttachedInfo = [],
					orgIdsThatAttachedInfo = [],
					orgIdByItemWfid = {}
					;

				output["id"] = {
					include: true,
					header: $translate.instant("ID"),
					source: "id"
				};
				output["organization"] = {
					include: true,
					header: $translate.instant("Organization"),
					source: "organization"
				};
				output["orgNumber"] = {
					include: true,
					header: $translate.instant("RegistrationNumber"),
					source: "orgNumber"
				};
				output["vatNumber"] = {
					include: true,
					header: $translate.instant("VATNumber"),
					source: "vatNumber"
				};
				output["gln"] = {
					include: true,
					header: "GLN",
					source: "gln"
				};
				output["customId"] = {
					include: true,
					header: $translate.instant("CustomID"),
					source: "customId"
				};
				output["yearlySpend"] = {
					include: true,
					header: $translate.instant("YearlySpend"),
					source: "yearlySpend"
				};
				output["contactPersons"] = {
					include: true,
					header: $translate.instant("ContactPersons"),
					resolve: function () {
						var orgIdsThatNeedsUsers = _.difference(vm.filteredOrganizationIds, alreadyLoadedUsersOnOrgIds);

						return loadUsersOnOrganizations(orgIdsThatNeedsUsers);
					},
					source: function (item) {
						var list = [];
						_.each(vm.usersByOrg[parseInt(item.id.split("-")[1])], function (item) {
							const { name, email, phone_number } = item
							let contact = name;
							if (email) {
								contact += ` <${email}>`;
							}
							if (phone_number) {
								contact += ` (${phone_number})`;
							}
							list.push(contact)
						});
						return list.join(", \n");
					}
				};
				output["country"] = {
					include: true,
					header: $translate.instant("Country"),
					byObjectType: enums.objectType.country,
					source: "countryId"
				};
				output["dateAdded"] = {
					include: false,
					header: $translate.instant("DateAdded"),
					source: "dateAdded"
				};

				if (vm.export.includeCategorizations.value) {
					_.each(vm.filters.parentGroups, function (categoryGroup) {
						output["categoryGroup_" + categoryGroup.wfid] = {
							include: true,
							header: categoryGroup.header,
							source: "categoryGroup_" + categoryGroup.wfid
						};
					});
				}

				return output;
			}

			function getRowsForCsvExport() {
				var
					output = []
					;

				var customIdParamsByOrgId = _.chain(wfObject.filter({
					where: {
						type: enums.objectType.parameterValue,
						organizationId: wfAuth.getOrganizationId(),
						parameterId: 33, // customId
						objectType: enums.objectType.organization
					}
				})).keyBy("objectId").mapValues(function (paramValue) {
					return paramValue.value
				}).value();
				var yearlySpendParamsByOrgId = _.chain(wfObject.filter({
					where: {
						type: enums.objectType.parameterValue,
						organizationId: wfAuth.getOrganizationId(),
						parameterId: 35, // yearlySpend
						objectType: enums.objectType.organization
					}
				})).keyBy("objectId").mapValues(function (paramValue) {
					return paramValue.value
				}).value();

				_.each(vm.searchResultItems, function (org) {
					var row = {};
					// var influences = influencesByOrgId[orgId];
					// var influence = influences ? influences[0] : null;

					row.organization = _.get(org, "name");
					row.orgNumber = _.get(org, "registrationNumber");
					row.vatNumber = _.get(org, "vatNumber");
					row.gln = _.get(org, "gln");
					row.customId = customIdParamsByOrgId[org.id];
					row.yearlySpend = yearlySpendParamsByOrgId[org.id];
					row.countryId = org.countryId;
					row.id = _.get(org, "wfid");
					const relationCreatedAt = _.get(vm.organizationRelationByWfid[row.id], "createdAt");
					row.dateAdded = relationCreatedAt ? moment(relationCreatedAt).format('YYYY-MM-DD HH:mm:ss') : null;

					if (vm.export.includeCategorizations.value) {
						_.each(vm.filters.parentGroups, function (categoryGroup) {
							var columnValue = [];

							_.each(categoryGroup.parents, function (item) {
								if (_.some(vm.organizationsByAnything[item.wfid], { id: org.id }))
									columnValue.push(item.title)
							});
							columnValue = columnValue.sort().join(", \n");

							row["categoryGroup_" + categoryGroup.wfid] = columnValue;
						});
					}

					output.push(row);
				});

				return output;
			}
		}

		function setProductOrganization(org) {
			vm.selectedProductOrganization = org;
			vm.isProductOrganizationExplorerOpen = true;
		}

		function onProductOrganizationExploreClosed() {
			vm.selectedProductOrganization = null;
			vm.isProductOrganizationExplorerOpen = false;
			$timeout();
		}

		function openDataCollectorImporter() {
			vm.isDataCollectorImporterOpen = true;
			$timeout();
		}

		function onDataCollectorImporterClosed() {
			vm.isDataCollectorImporterOpen = false;
			$timeout();
		}

		function openNetworkOrganizationsExportDialog() {
			if (vm.loaded) {
				vm.packagesForNetworkOrganizationsExport = vm.allPackagesInNetwork
					.filter(p => p.conditions.templateId === 75)
					.map(p => ({ id: p.id, title: p.title }))
				vm.isNetworkOrganizationsExporterOpen = true
				$timeout();
			}
		}

		function onNetworkOrganizationsExporterClosed() {
			vm.isNetworkOrganizationsExporterOpen = false
			$timeout();
		}

		function onNextEmailInfoClose() {
			vm.nextEmailInfoDialogIsOpen = false;
			$timeout();
		}

		function openEmailInfoDialog(mailPurpose) {
			vm.mailPurpose = mailPurpose;
			vm.nextEmailInfoDialogIsOpen = true;

			$timeout();
		}

		function getWeekday(dayOfWeek) {
			return moment.weekdays(dayOfWeek);
		}
	}
})();
