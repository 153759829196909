// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  '@global': {
    iframe: {
      '&.intercom-launcher-frame, &.intercom-launcher-discovery-frame': {
        bottom: '80px !important',
      },
    },
  },
})

function IntercomCssBaseline(props) {
  const { children = null } = props
  useStyles()
  return <React.Fragment>{children}</React.Fragment>
}

export default IntercomCssBaseline
