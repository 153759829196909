export default {
  map: {
    cluster: require('./img/map-cluster-512.png'),
  },
  logo: require('./img/worldfavor_logo.svg'),
  logoFilled: require('./img/wf_logo_white_filled.svg'),
  logoLoader: require('./img/loader_green.gif'),
  defaultCoverImage: require('./img/coverPhoto.png'),
  systembolagetLogo: require('./img/systembolaget_logo.jpg'),
  actorTypesDefaultImage: require('./img/actorTypesDefaultImage.jpg'),
}
