(function () {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfMeasureAnsweringHistory', wfMeasureAnsweringHistory)
	;
	wfMeasureAnsweringHistory.$inject = ['$parse','dataQuery','dataOperationsService','$compile'];

	function wfMeasureAnsweringHistory($parse, dataQuery, dataOps, $compile) {
		var
			templateUrl = 'scripts/wf/answering/wfMeasureAnsweringHistory.directive.html',
			templateContent
		;
		var directive = {
			restrict: 'EA',
			// require: '?^^wfMeasureAnsweringManager',
			templateUrl: templateUrl,
			link: link,
			controllerAs: 'measureAnsweringHistory',
			controller: ['$scope', '$element', '$templateCache','$timeout', '$attrs', wfMeasureAnsweringHistoryController],
			
		};
	
		return directive;

		function wfMeasureAnsweringHistoryController($scope, $element, $templateCache, $timeout, $attrs) {
				var
					vm = this,
					isChartLoaded,
					chart, // Instance of c3.js chart
					html = templateContent || (templateContent = $templateCache.get(templateUrl)),
					minAnswersView = 0,
					historyForPeriod,
					periodHistory,
					historyParent
				;

				if ($scope.measureAnsweringVm && $scope.measureAnsweringVm.allAnswers)
					activate();
				else {
					$scope.activateMeasureAnsweringHistory = function () {
						// console.log("Activated later", activate);
						activate();
						$element.html(html);
						$compile($element.contents())($scope);
						// $timeout(function () {
						// }, 1000)
					};
					// console.log("$scope.activateMeasureAnsweringHistory.activate set")
				}

				// console.log("activating measure history");

				vm.updateHistory = updateHistory;

				function activate() {
					vm.onAnswerRemoved = onAnswerRemoved; 
					getValues();
					
					// if (!vm.context)
					// 	vm.context = {};
						
					// vm.context.isByAutheticatedOrg = $scope.isByAutheticatedOrg;
					// console.log(vm.isByAutheticatedOrg);
					
					function getValues() {
						if ($attrs.historyPeriod)
							historyForPeriod = $parse($attrs.historyPeriod)($scope);

						if ($attrs.historyParent) // Handle for potential parent wfMeasureAnsweringHistory
							historyParent = $parse($attrs.historyParent)($scope);
							
						periodHistory = !!historyForPeriod;

						_.assign(vm, {
							groupedSortedAnswers: [],
							allAnswers: $scope.measureAnsweringVm.allAnswers, // From wfMeausreAnswering directive
							answersCount: $scope.measureAnsweringVm.allAnswers.length,
							isByAutheticatedOrg: $scope.isByAutheticatedOrg,
							toggleAnswers: toggleAnswers,
							filteredAnswers: [],
							toggled: false,
							periodHistory: periodHistory
						});

						groupAndSortAnswers();

						vm.answerWfidMap = _.keyBy(vm.groupedSortedAnswers, "wfcid")
					}

					function onAnswerRemoved(action, answer) {
						var updateAnswer, dataRelation;
						if (action === "delete" && vm.answerWfidMap[answer.wfid]) {
							// if (!vm.groupedSortedAnswers.length || vm.groupedSortedAnswers[0].wfcid === answer.wfid) {
							// 	// Needs to update answer
							// 	updateAnswer = true;
							// }
							dataOps.destroy(_.find(vm.allAnswers, { wfcid: answer.wfid }));
							
							dataRelation = _.remove(vm.allAnswers, { wfcid: answer.wfid })[0];
							// _.remove(vm.allAnswers, { wfcid: answer.wfid });
							groupAndSortAnswers();
							vm.answerWfidMap = _.keyBy(vm.groupedSortedAnswers, "wfcid")

							vm.answersCount = vm.groupedSortedAnswers.length;

							// if (updateAnswer) {
							// }
							
							$scope.measureAnsweringVm.setSelectedAnswer(vm.groupedSortedAnswers[0]);

							$scope.$emit("updateMeasureAnsweringHistoryItem", dataRelation, vm.groupedSortedAnswers[0]);
							// if (historyParent && historyParent !== vm) {
							// 	historyParent.replaceAnswer(item, vm.groupedSortedAnswers[0]);
							

							updateChart();
						}
					}

					$scope.$on("updateMeasureAnsweringHistoryItem", function (event, oldItem, newItem) {
						var oldIndex = vm.groupedSortedAnswers.indexOf(oldItem);

						updateHistory();
						if (!oldItem || !vm.answerWfidMap[oldItem.wfcid])
							return;

						event.stopPropagation();

						if (!newItem)
							vm.onAnswerRemoved("delete", { wfid: oldItem.wfcid });
						else {
							if (~oldIndex) {
								vm.groupedSortedAnswers[oldIndex] = newItem;
							}
						}

					});
				}
				
				function updateHistory() {
					minAnswersView = 1;
					groupAndSortAnswers();

					vm.answersCount = vm.groupedSortedAnswers.length;

					vm.answerWfidMap = _.keyBy(vm.groupedSortedAnswers, "wfcid");

					if (!vm.toggled) {
						vm.filteredAnswers.length = 0;
						vm.filteredAnswers = _.take(vm.groupedSortedAnswers, minAnswersView);
					}
					else {
						vm.filteredAnswers.length = 0;
						vm.filteredAnswers = vm.groupedSortedAnswers;
					}
					
					$element.html(html);
					$compile($element.contents())($scope);

					isChartLoaded = false;
					initChart();
					// console.log(html)
				}
				
				function toggleAnswers() {
					if (vm.toggled) {
						vm.filteredAnswers = _.take(vm.groupedSortedAnswers, minAnswersView);
					}
					else {
						vm.filteredAnswers = vm.groupedSortedAnswers;
					}
					vm.toggled = !vm.toggled;

					if (vm.toggled && !isChartLoaded) {
						initChart();
					}
				}

				function initChart() {
					isChartLoaded = true;
					chart = c3.generate({
						bindto: $element.find("div.measure-chart")[0],
						size: {
							height: 200
						},
						data: {
							colors: {
								Data: '#297D7D'
							},
							x: "x",
							types: {
								Data: "area"
							},
							columns: [
								[ "x" ].concat(_.map(vm.groupedSortedAnswers, function (o) {
									return parseInt(o.childContent.year);
								})),
								[ "Data" ].concat(_.map(vm.groupedSortedAnswers, function (o) {
									return parseInt(o.childContent.value);
								}))
							]
						},
						grid: {
							x: {
								show: true
							},
							y: {
								show: true
							}
						},
						point: {
							r: 6
						},
						transition: {
							duration: 0
						},
						legend: {
							show: false
						},
						axis: {
							y: {
								max: null,
								min: null
							},
							x: {
								tick: {
									rotate: 0
								},
								padding: 1 - (vm.groupedSortedAnswers.length / 50.0)
							}
						}
					});
				}

				function updateChart() {
					if (isChartLoaded) {
						chart.load({
							axes: {
								x: {
									padding: vm.groupedSortedAnswers.length / 35.0 * 2
								}
							},
							columns: [
								[ "x" ].concat(_.map(vm.groupedSortedAnswers, function (o) {
									return parseInt(o.childContent.year);
								})),
								[ "Data" ].concat(_.map(vm.groupedSortedAnswers, function (o) {
									return parseInt(o.childContent.value);
								}))
							]
						});
					}
				}
				
				function groupAndSortAnswers() {
					var result;

					if (periodHistory)
					{
						result = vm.groupedSortedAnswers = _
							.chain(vm.allAnswers)
							.filter(function (item) {
								return item.childContent.year === historyForPeriod;
							})
							.orderBy([ function (a) {
								return a.createdAt;
							} ], [ "desc" ]).value()
					}
					else
					{
						result = vm.groupedSortedAnswers = _
							.chain(vm.allAnswers)
							.groupBy('childContent.year')
							.map(function(group) {
								return _.orderBy(group, [ "createdAt" ], [ "desc" ])[0];
							})
							.orderBy([ function (a) {
								return a.childContent.year;
							} ], [ "desc" ]).value()
					}

					vm.answersCount = result.length;
					return result;
				}
			}

		function link(scope, element, attrs) {
				// console.log(element.html())
				element.addClass('wfMeasureAnsweringHistory');
			}
	}
})();