import { useEffect, useState } from 'react'
import axios from 'axios'
import Api from '@worldfavor/portal/api'
import { get } from 'lodash'
import { DefaultTablePageSize } from '@worldfavor/constants'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'

export const useFiltersFetcher = (endpoint, useEffectDependencies = []) => {
  const [filtersLoading, setFiltersLoading] = useState(false)
  const [fetchedFilters, setFetchedFilters] = useState(null)

  useEffect(() => {
    const source = axios.CancelToken.source()

    async function fetchFilters() {
      setFiltersLoading(true)
      try {
        if (endpoint) {
          const result = await Api.get(endpoint, null, source)
          const filtersFromServer = get(result, 'data.filters')
          setFetchedFilters(filtersFromServer)
        }
      }
      catch (e) {
        console.error('Could not fetch filters: ', e)
      }
      finally {
        setFiltersLoading(false)
      }
    }

    fetchFilters()

    return () => {
      source.cancel('Request canceled, component unmounted')
    }
  }, useEffectDependencies)

  return { filtersLoading, fetchedFilters }
}

/**
 * Custom hooks to fetch data and deal with loading state, page number, result count, etc..
 * @param fetchData function(source, pageNumber, pageSize)
 * @param [dependencies] array of dependencies for the useEffect callback
 * @param [callbacks] { onBeforeSend, onSuccess, onError}
 * @returns [resultCount, pageNumber, pageSize, pageId, requestId, setPageNumber, setPageSize]
 */

export const useDataFetcher = (fetchData, dependencies = [], callbacks = {}) => {
  const [resultCount, setResultCount] = useState()
  const [pageNumber, setPageNumber] = useState(0)
  const [pageSize, setPageSize] = useState(DefaultTablePageSize)
  const [pageId, setPageId] = useState('')
  const [requestId, setRequestId] = useState('')
  const [error, setError] = useState(null)

  const { onBeforeSend, onSuccess, onError } = callbacks

  /*
    Using deep compare here to make this hook more flexible to use, the default react useEffect only compare dependencies by reference,
    this one does a deep comparison of objects. read more here : https://github.com/kentcdodds/use-deep-compare-effect
   */
  useDeepCompareEffectNoCheck(() => {
    const source = axios.CancelToken.source()

    if (typeof onBeforeSend === 'function') {
      onBeforeSend()
    }

    async function fetchPage() {
      try {
        const { requestId, pageId, numberOfElements, totalElements } = await fetchData(source, pageNumber, pageSize)

        setResultCount(totalElements)

        if (numberOfElements === 0 && totalElements !== 0) {
          setPageNumber(0)
        }

        setRequestId(requestId)
        setPageId(pageId)

      } catch (e) {
        // TODO handle error
        if (e.cancelled) {
          console.warn('The request was cancelled')
          return
        } else {
          console.error('Error while fetching data', e)
          setError(e)
          if (typeof onError === 'function') {
            onError()
          }
          return
        }
      }
      if (typeof onSuccess === 'function') {
        onSuccess()
      }
    }

    fetchPage()
    return () => {
      source.cancel('Request canceled, component unmounted')
    }
  }, [pageSize, pageNumber, ...dependencies])

  return { resultCount, pageNumber, pageSize, pageId, requestId, error, setPageNumber, setPageSize }
}

export default useDataFetcher
