(function() {
'use strict';

	angular
		.module('wf.common')
		.controller('InternalReportController', InternalReportController);

	InternalReportController.$inject = ['$scope', '$element', '$attrs', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', '$timeout', '$state'];
	function InternalReportController($scope, $element, $attrs, dataOps, modal, wfObject, dataQuery, $translate, $timeout, $state) {
		var vm = this;
		_.assign(this, $scope.$parent.vm);
		vm.$state = $state;

		activate();

		function activate() {
			dataOps.getObject({
				objectId: 13597, // Structure containing internal reporting influences.
				objectType: 71
			}).then(function (internalReportingStructure) {
				if (internalReportingStructure.childs.length > 0) {
					vm.showInternalReportingInfluences = true
					vm.internalReportingStructure = internalReportingStructure;

					vm.internalReportingInfluences = _.chain(internalReportingStructure.childs).orderBy("childContent.title").value();
				}

				vm.loaded = true;
				$timeout();
			});
		}
	}
})();