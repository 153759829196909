(function () {
	'use strict';

	angular
		.module ('wf.common')
		.directive ('wfDropdownRightclick', wfDropdownRightclick);

	wfDropdownRightclick.$inject = [ '$parse', '$timeout' ];
	function wfDropdownRightclick($parse, $timeout) {

		var directive = {
			link: link,
			restrict: 'A',
		}
		return directive;
		

		function link(scope, element, attrs) {
			var dropdownDirective = element.find('wf-dropdown');

			if (dropdownDirective.length) {
				element.bind('contextmenu', function(event) {
					clickWfDropdown(event);
					$timeout();
				});
			}

			function clickWfDropdown(event) {
				var fn = $parse(attrs.ngRightClick);
				var cursorPositionX = event.clientX;
				var cursorPositionY = event.clientY;

				event.preventDefault();
				event.stopPropagation();
				fn(scope, { $event: event });
				dropdownDirective.click();
				dropdownDirective.children('.dropdown').children('button').click();

				dropdownDirective.children('.dropdown').children('ul.dropdown-menu').css('top', cursorPositionY);
				dropdownDirective.children('.dropdown').children('ul.dropdown-menu').css('left', cursorPositionX);
			}
		}
	}

} ());