// @flow
import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import SearchBar from '@worldfavor/components/SearchBar'
import { FormattedMessage, injectIntl } from 'react-intl'
import OrganizationPicker from '@worldfavor/components/Picker/OrganizationPicker'
import AddIcon from '@material-ui/icons/Add'
import EmptyState from '@worldfavor/components/EmptyState'
import CircularProgressWrapper from '@worldfavor/components/CircularProgressWrapper'

const MAX_ITEM = 5
const ITEM_HEIGHT = 63
const DIVIDER_HEIGHT = 5

const styles = theme => ({
  root: {},
  picker: {
    //marginTop: 36,
    maxHeight: MAX_ITEM * ITEM_HEIGHT + (MAX_ITEM - 1) * DIVIDER_HEIGHT,
    minHeight: MAX_ITEM * ITEM_HEIGHT + (MAX_ITEM - 1) * DIVIDER_HEIGHT,
    minWidth: 420,
    maxWidth: 420,
    overflowY: 'auto',
  },
  searchBar: {
    marginBottom: theme.spacing(3),
  },
  createContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    marginTop: 28,
    borderRadius: 4,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
    },
  },
  addButton: {
    marginRight: 16,
  },
  createOrganization: {
    flex: 1,
    fontSize: 18,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
  },
  noOrgPlaceholder: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(12),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
})

const OrganizationPickerDialog = ({ classes, open, saving, intl, organizations, onCancel, onAdd, onCreate, ...rest }) => {
  const [search, setSearch] = useState('')
  const [selectedOrganization, setSelectedOrganization] = useState(null)
  const [filteredOrganizations, setFilteredOrganizations] = useState(organizations)

  function onSearchChange(event) {
    setSearch(event.target.value)
    const searchRegex = new RegExp(event.target.value, 'i')
    setFilteredOrganizations(organizations.filter(({ name }) => searchRegex.test(name)))
  }

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setSearch('')
        setSelectedOrganization(null)
      }, 100)
    }
    else {
      onSearchClear()
    }
  }, [open])

  function onSearchClear() {
    setSearch('')
    setFilteredOrganizations(organizations)
  }

  function _onAdd() {
    onAdd(selectedOrganization)
  }

  function onOrganizationSelect(event, selectedOrganization) {
    setSelectedOrganization(selectedOrganization)
  }

  return (
    <Dialog
      onClose={onCancel}
      className={classes.root}
      open={open}
      {...rest}
    >
      <DialogTitle>
        <FormattedMessage id={'supplyChain.organizationPickerDialog.title'} />
      </DialogTitle>

      <DialogContent>
        <SearchBar
          classes={{ root: classes.searchBar }}
          onClear={onSearchClear}
          rounded
          compact
          inputProps={{
            value: search,
            placeholder: intl.formatMessage({ id: 'general.search' }),
            onChange: onSearchChange,
          }}
        />

        <div className={classes.picker}>
          {
            filteredOrganizations && filteredOrganizations.length > 0 ? (
              <OrganizationPicker
                organizations={filteredOrganizations}
                selectedOrganization={selectedOrganization}
                onOrganizationSelect={onOrganizationSelect}
                search={search}
              />
            ) : (
              <EmptyState
                iconClass={'fas fa-building'}
                title={(
                  <FormattedMessage
                    id={
                      organizations && organizations.length === 0 ?
                        'supplyChain.organizationPickerDialog.noOrganization.title'
                        : 'search.noOrganizationMatch'
                    }
                  />
                )}
                description={
                  organizations && organizations.length === 0 && (
                    <FormattedMessage id={'supplyChain.organizationPickerDialog.noOrganization.description'} />
                  )
                }
                classes={{ root: classes.noOrgPlaceholder }}
              />
            )
          }
        </div>

        <div className={classes.createContainer} onClick={onCreate}>
          <Fab color="primary" size={'small'} className={classes.addButton} onClick={onCreate}>
            <AddIcon />
          </Fab>

          <div className={classes.createOrganization}>
            <FormattedMessage id={'supplyChain.organizationPickerDialog.createOrganization'} />
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel} color="primary" disabled={saving}>
          <FormattedMessage id={'general.cancel'} />
        </Button>
        <CircularProgressWrapper loading={saving}>
          <Button
            disabled={saving || !selectedOrganization}
            onClick={_onAdd}
            color="primary"
            autoFocus
          >
            <FormattedMessage id={'general.add'} />
          </Button>
        </CircularProgressWrapper>
      </DialogActions>
    </Dialog>
  )
}

OrganizationPickerDialog.defaultProps = {
  onAdd: () => {},
  onCancel: () => {},
}

export default injectIntl(withStyles(styles)(OrganizationPickerDialog))
