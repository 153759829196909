// @flow
import * as React from 'react'
import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import HighlightedText from '@worldfavor/components/Text/HighlightedText'
import IconButton from '@material-ui/core/IconButton'
import MoreVert from '@material-ui/icons/MoreVert'

type Props = {
  organization: {
    imageUrl: string,
    name: string,
    // actorTypes: Array<{ title: string }>,
  },
  onClick?: (event: SyntheticEvent<HTMLButtonElement>) => void,
  onClickMore: (event: SyntheticEvent<HTMLButtonElement>, organization: Object) => void,
  showMore?: boolean,
  match: { search: string },
  style?: { [string]: any },
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 18,
    paddingBottom: 18,
    alignItems: 'center',
  },
  hover: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  container: {
    flex: 1,
    overflow: 'hidden',
  },
  title: {
    fontSize: 16,
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  subtitle: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  imageContainer: {
    width: 40,
    height: 40,
    marginRight: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    borderRadius: 4,
  },
  icon: {
    fontSize: 20,
  },
  iconButton: {
    padding: 4,
    margin: 0,
  },
  moreIcon: {
    margin: 6,
  },
}))

const OrganizationRow = (props: Props) => {
  const { organization, showMore, onClickMore, match, onClick, style } = props
  const classes = useStyles(props)
  const [hover, setHover] = useState(false)
  // const actorType = (organization.actorTypes && organization.actorTypes.length) && organization.actorTypes[0]

  function onMouseOver() {
    !hover && setHover(true)
  }

  function onMouseLeave() {
    hover && setHover(false)
  }

  function _onClickMore(event) {
    onClickMore(event, organization)
  }

  return (
    <div
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      className={classNames(classes.root, hover && classes.hover)}
      style={style}
    >

      <div className={classes.imageContainer}>
        {
          organization.imageUrl ? (
            <div
              className={classes.image}
              style={{ backgroundImage: `url('${organization.imageUrl}')` }}
            />
          ) : <i className={classNames('fas fa-box-open', classes.icon)} />
        }
      </div>

      <div className={classes.container}>
        <div title={organization.name} className={classes.title}>
          <HighlightedText text={organization.name} match={match.search} />
        </div>
        {/* {
          actorType && <div className={classes.subtitle}>{actorType.title}</div>
        } */}
      </div>

      {
        showMore && (
          <IconButton
            className={classes.iconButton}
            onClick={_onClickMore}
            disabled={!onClickMore}
          >
            <MoreVert className={classNames(classes.moreIcon)} size={'small'} />
          </IconButton>
        )
      }
    </div>
  )
}

OrganizationRow.defaultProps = {
  match: {},
}

export default OrganizationRow
