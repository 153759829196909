(function() {
	'use strict';
	angular
		.module('wf.common')
		.directive('wfMapImage', wfMapImageDirective)
		.directive('wfMap', wfMapDirective);

	wfMapImageDirective.$inject = [];
	function wfMapImageDirective() {
		var directive = {
			template: '<div class="wfMapImage"></div>',
			link: link,
			restrict: 'E',
			scope: {
				longitude: "@",
				latitude: "@",
				zoom: "@",
				width: "@",
				height: "@",
			}
		};
		return directive;

		function link(scope, element, attrs) {
			var
				googleUrl,
				width = scope.width || "100",
				height = scope.height || "100",
				longitude = scope.longitude,
				latitude = scope.latitude,
				zoom = scope.zoom,
				googleStaticMapURL,
				googleKey = "AIzaSyChfzQUypvsAJdjIdCtQIzAUP_P0GHIbiM"
			;

			if ("fullScreenWidth" in attrs) {
				width = window.innerWidth;
			}
			googleUrl = "https://maps.googleapis.com/maps/api/staticmap?zoom=" + zoom + "&size=" + width + "x" + height + "&maptype=roadmap&markers=color:red%7Alabel:C%7C";
			googleStaticMapURL = googleUrl + longitude + "," + latitude + "&key=" + googleKey;

			element.css('backgroundImage', 'url(' + googleStaticMapURL + ')');
		}
	}

	wfMapDirective.$inject = [ 'NgMap', '$timeout' ];
	function wfMapDirective(NgMap, $timeout) {
		var directive = {
			controllerAs: 'mapVm',
			controller: [ '$scope', '$timeout', '$attrs', 'dataOperationsService', '$element', wfMapController ],
			templateUrl: 'scripts/wf/location/wfMap.directive.html',
			restrict: 'E',
			scope: {
				locations: "=",
				zoom: "@",
				options: "<",
				enableResponsiveness: "<",
				showInfoWindows: "<",
				height: "@"
			}

		};
		return directive;

		function wfMapController($scope, $timeout, $attrs, dataOps, $element) {
			var vm = this;

			_.assign(vm, {
				//Variables
				mapId: $scope.$id,
				height: $scope.height || $element.height(),
				types: "['establishment']",
				locations: [],
				selectedLocation: undefined,
				center: undefined,
				zoom: parseInt($scope.zoom) || 10,
				showInfoWindows: false,
				options: {
					panControl: false,
					fullscreenControl: false,
					draggable: true,
					clickableIcons: true,
					gestureHandling: "auto",
					keyboardShortucts: true,
					scrollWheel: true,
					disableDoubleClickZoom: false,
					disableDefaultUI: false,
					zoomToIncludeMarkers: false
				},

				//Functions
				showInfo: showInfo,
				hideInfo: hideInfo
			});

			// dataOps.getSubItems("71-11970", 7).then(function (res) {
			// 	$scope.locations = _.map(res, "childContent");
			// });

			initLocations();

			// var ngMap = $element.find("ng-map").css("opacity", 0);

			NgMap.getMap( { id: $scope.$id } ).then(function(map) {
				vm.map = map;
				if (vm.center)
					vm.map.setCenter(vm.center);
				if ("enableResponsiveness" in $attrs)
					setBoundsToFitMarkers();
				if ("showInfoWindows" in $attrs)
					vm.showInfoWindows = true;

				// setTimeout(function () {
				// 	ngMap.animate({ opacity: 1 }, 1000);
				// }, 500)
			});

			function initLocations() {
				if (typeof $scope.locations == "object") {
					if ($scope.locations.constructor === Array) {
						vm.locations = $scope.locations;
						if (vm.locations.length > 1)
							vm.options.zoomToIncludeMarkers = true;
					}
					else {
						vm.locations.push($scope.locations);
						vm.center = new google.maps.LatLng($scope.locations.latitude, $scope.locations.longitude);
					}
					if ($scope.options && $scope.options.constructor === Object)
						_.assign(vm.options, $scope.options);
				}
				else console.error("locations attribute is accepting either a single object {latitude: 1111, longitude: 2222} or an array of objects [{latitude: 1111, longitude: 2222}, {latitude: 3333, longitude: 4444}]");
			}

			function setBoundsToFitMarkers() {
				google.maps.event.addDomListener(window, "resize", function() {
					var bound = new google.maps.LatLngBounds();

					_.each(vm.map.markers, function(marker) {
						bound.extend(marker.position);
					});
					$timeout(function() {
						vm.map.fitBounds(bound);
						if (vm.locations.length == 1) {
							vm.map.setCenter(vm.center);
							vm.map.setZoom(vm.zoom);
						}
					}, 100)
				});
			}

			function showInfo(e, location) {
				if (vm.showInfoWindows) {
					vm.selectedLocation = location;
					vm.map.showInfoWindow('info-window', location.id.toString());
				}
			};

			function hideInfo() {
				vm.map.hideInfoWindow('info-window');
			};
		}
	}

})();
