import * as enums from '@worldfavor/constants/enums'

(function () {
	'use strict';

	angular
		.module('wf.common')
		.controller('MailHistoryController', MailHistoryController);

	MailHistoryController.$inject = [ '$rootScope', '$scope', '$translate', '$timeout', 'dataOperationsService', 'apiProxy', 'modalService', '$injector', 'wfAuth', 'valueChainService', '$stateParams', '$state' ];

	function MailHistoryController($rootScope, $scope, $translate, $timeout, dataOps, apiProxy, modal, $injector, wfAuth, valueChainService, $stateParams, $state) {
		/* jshint validthis:true */
		var 
			vm = this,
			modalContext,
			networkId = $stateParams.networkId,
			toOrganizationId,
			mailStatuses = [
				{ title: $translate.instant("modules.filters.mailStatus.sent"), id: enums.mailStatus.sent, icon: "fa fa-check" },
				{ title: $translate.instant("modules.filters.mailStatus.failedToSend"), id: enums.mailStatus.sendFailed, icon: "fa fa-exclamation-triangle" },
			],
			mailPurposes = [
				
				{ title: $translate.instant("modules.filters.mailPurpose.valueChainInvitation"), id: enums.mailPurpose.valueChainInvitation, icon: "fa fa-link" },
				{ title: $translate.instant("modules.filters.mailPurpose.valueChainReminder"), id: enums.mailPurpose.valueChainReminder, icon: "fa fa-bell" }
				
			]
		;

		_.assign(vm, {
			//Variables
			openInModal: false,
			includeFilters: true,
			mailHistoryConfig: undefined,
			pageLoaded: false,
			scrollableConfig: {
				callbacks: {
					onTotalScroll: function() {
						$scope.$emit('scrolledOnBottom');
					}
				}
			},
			
			//Functions
			previewHistoricMail: previewHistoricMail,
			onButtonClick: onButtonClick
		});

		valueChainService.loadOrganizationsInNetwork(networkId, true).then(function(res) {
			vm.organizations = _.map(res, "childContent");
			activate();
		});
		
		function activate() {
			if ($scope.$resolve && $scope.$resolve.modalContext) {
				modalContext = $scope.$resolve.modalContext;
				vm.context = modalContext;
				toOrganizationId = modalContext.toOrganizationId;
				vm.includeFilters = modalContext.includeFilters;
				vm.loading = true;
				vm.openInModal = true;
				vm.pageLoaded = true;
			}
			else if ($injector.has("$stateParams")) {
				$injector.invoke([ "$stateParams", function ($stateParams) {
					networkId = $stateParams.networkId;
					if (networkId) {
						valueChainService.loadNetworks().then(function (result) {
							var network = result.networksById[networkId];
							vm.pageHeaderItem = {
								title: $translate.instant("modules.mailHistory.pageHeader"),
								description: ""
							};
							
							$rootScope.setPageTitle(vm.pageHeaderItem.title);
							
							if (network)
								vm.network = network;
							else {
								$state.go("valueChain-root");
								return;
							}

							vm.pageLoaded = true;
						});
					} 
					else
						console.error("NetworkId not found in the $stateParams")
				} ]);
			}

			if (vm.includeFilters) {
				loadFilters();
			}
			else if (toOrganizationId || networkId) {
				var parameters = {
					objectType: enums.objectType.mailOutbound,
					limit: 30
				};

				if (networkId)
					parameters.getterConditions = { networkId: networkId };
				
				if (toOrganizationId)
					parameters.organizationId = toOrganizationId;

				dataOps.getObjects(parameters).then(function (res) {
					$timeout(function () {
						vm.loading = false;
						vm.filteredItems = _.orderBy(res, [ function (item) {
							vm.filteredItemsLoaded = true;
							return item.childContent.createdAt;
						} ], [ "desc" ]);
					})
				});
			}
		}

		function loadFilters() {
			vm.mailHistoryConfig = {
				controllerAs: 'mhVm',
				infiniteScroll: true, 
				useServer: { 
					method: 'getObjects',
					baseParams: {
						objectType: enums.objectType.mailOutbound,
						organizationId: toOrganizationId,
						getterConditions: {
							networkId: networkId
						}
					},
					handleResponse: vm.handleFilterServerResponse
				}, 
				filters: [
					
						// {
						// 	placeholder: 'Search',
						// 	label: 'Filter by users',
						// 	filterOptionsSource: users,
						// 	getterParamName: 'userIds',
						// 	displayAs: 'dropdown'
						// },
						[{
							placeholder: $translate.instant("Search"),
							label: $translate.instant("modules.filters.filterByOrganization"),
							filterOptionsSource: vm.organizations,
							getterParamName: 'organizationIds',
							displayAs: 'dropdown'
						},
						{
							placeholder: $translate.instant("Search"),
							label: $translate.instant("modules.filters.filterByMailPurpose"),
							filterOptionsSource: mailPurposes,
							getterParamName: 'mailPurpose',
							displayAs: 'dropdown'
						},
						{
							placeholder: $translate.instant("Search"),
							label: $translate.instant("modules.filters.filterByMailStatus"),
							filterOptionsSource: mailStatuses,
							getterParamName: 'mailStatus',
							displayAs: 'dropdown'
						},
						],
						{
							label: $translate.instant("modules.filters.filterByDate"),
							displayAs: 'dateRangePicker',
							includeSlider: true,
							minValue: vm.firstLogEventDate
						},
						
					
				]
			};
		}

		function onButtonClick($event, button) {
			var
				fnOutput = button.onClick(button.clickEventParam),
				element = $($event.currentTarget)
			;

			if (button.busy) return;

			if (fnOutput && fnOutput.then)
			{
				button.busy = true;
				element.addClass("loading");
				fnOutput.then(function () {
					// uibModalInstance.close();
					// element.removeClass("loading");
				});
			}
		}

		function previewHistoricMail(mailOutbound) {
			var
				iframe,
				iframeHtml,
				iframeWrap
			;

			return apiProxy("mail.getMailPreview", {
				mailOutboundId: mailOutbound.id
			}).then(function (res) {
				var modalElement, mailOutbound = res;

				if (res) {
					var mailHtml = res.renderedHtml;
					var template = '<ng-include src="\'scripts/wf/mail/mailHeader.template.html\'"></ng-include>'
								+ '<ng-include src="\'scripts/wf/mail/mailPreviewFrame.template.html\'" onload="vm.onIframeReady()"></ng-include>';
					var htmlPreviewAvailable = res.renderedHtml ? true : false;
					var scope = {
						vm: {
							previewLoaded: true,
							historic: true,
							htmlPreviewUnavailable: !htmlPreviewAvailable,
							modalTitle: mailOutbound.status === 0 ? $translate.instant("modules.mailPreview.previewOfSentMail") : $translate.instant("modules.mailPreview.previewOfFailedMail"),
							mailPreview: {
								toName: res.toName,
								toEmail: res.toEmail,
								fromName: res.fromName,
								fromEmail: res.fromEmail,
								subject: res.subject,
								sentAt: moment(res.createdAt).format('ddd YYYY-MM-DD HH:mm')
							},
							mailOutbound: res,
							onIframeReady: function () {
								if (htmlPreviewAvailable) {
									iframe = modalElement.find("#mail-preview-frame");

									// https://stackoverflow.com/a/42023690/4871222
									var iframeDoc = iframe[0].contentWindow.document;
									iframeDoc.open();
									iframeDoc.write(mailHtml);
									iframeDoc.close();

									setTimeout(function () {
										iframe.contents().find("a").attr("onclick", "return false;")
										setIframeHeight();
									}, 100);
								}
							}
						}
					};
					
					modal.open({
						template: template,
						scope: scope,
						windowClass: "modal-width-800",
						onLoaded: function ($scope, $element) {
							modalElement = $element;
						}
					});
				}
			}, function () {
				modal.alert({
					message: $translate.instant("modules.mailPreview.historicMailPreviewUnavailable")
				});
			})

			function setIframeHeight() {
				var iframeHeight = iframe.contents().innerHeight();
				iframe.height(iframeHeight);
			}
		}

	}
})();
