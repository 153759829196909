(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfDataNegotiator', wfDataNegotiator)
	;

	wfDataNegotiator.$inject = [];
	function wfDataNegotiator() {
		var directive = {
			bindToController: true,
			restrict: 'A',
			controllerAs: 'negotiatorVm',
			controller: wfDataNegotiatorController,
			scope: {
				config: "<wfDataNegotiator"
			}
		};

		return directive;
	}
	
	wfDataNegotiatorController.$inject = [ '$scope', 'DataNegotiator' ];
	
	function wfDataNegotiatorController($scope, DataNegotiator) {
		var
			vm = this
		;

		activate();

		function activate() {
			var negotiatorInstance, negotiatorOptions;

			if (vm.config instanceof Array) {
				negotiatorInstance = vm.config[0];
				negotiatorOptions = vm.config[1];
				// vm.config = negotiatorOptions;
			}
			else {
				negotiatorOptions = vm.config;
			}

			vm.instance = DataNegotiator.instantiate(negotiatorInstance, _.assign({ $scope: $scope }, negotiatorOptions));
		}
	}
})();