import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';

	angular
		.module('wf.common')
		.controller('orderingController', orderingController)

	orderingController.$inject = [ "$rootScope", "$scope", 'modalContext', '$timeout', 'modalService', '$ngBootbox', '$translate', 'formSchemaService', 'currencyFormatService', 'dataOperationsService', '$q', 'wfAuth', '$compile', 'apiProxy'];
	function orderingController($rootScope, $scope, modalContext, $timeout, modal, $ngBootbox, $translate, formSchemaService, currencyFormatService, dataOps, $q, wfAuth, $compile, apiProxy) {
		//vm for the template set to orderVm in modal.service.js (startOrdering() function)
		var 
			vm = this,
			productCatalogues = {
				"IsoStandards": "71-13589",
				// "reserved1": "71-13590",
				// "reserved2": "71-13591"
			},
			catalogueWfid,
			existingBillingInformation,
			termsAndConditionsPdfUrl = "https://portal.worldfavor.com/assets/docs/Worldfavor_ServiceAgreement.pdf",
			modalElement
		;

		_.assign(vm, {
			//Variables
			steps:{
				productPicker: {
					show: false,
					showBackButton: false,
					visited: false,
					stepTitle: $translate.instant("modules.eCommerce.productPicker.stepTitle"),
					stepNumber: 1,
					headerTitle: $translate.instant("modules.eCommerce.productPicker.headerTitle"),
					headerDescription: undefined,
					icon: "fa fa-shopping-cart",
					buttonLabel: $translate.instant("modules.eCommerce.productPicker.buttonLabel"),
					actionButton: function() {
						showCheckout();
					}
				},
				checkout: {
					show: false,
					showBackButton: true,
					visited: false,
					stepTitle: $translate.instant("modules.eCommerce.checkout.stepTitle"),
					stepNumber: 2,
					headerTitle: $translate.instant("modules.eCommerce.checkout.headerTitle"),
					headerDescription: $translate.instant("modules.eCommerce.checkout.headerDescription"),
					icon: "fa fa-shopping-cart",
					buttonLabel: $translate.instant("modules.eCommerce.checkout.buttonLabel"),
					actionButton: function() {
						return createInvoice();
					}
				},
				invoicePreview: {
					show: false,
					showBackButton: true,
					visited: false,
					stepTitle: $translate.instant("modules.eCommerce.invoicePreview.stepTitle"),
					stepNumber: 3,
					headerTitle: $translate.instant("modules.eCommerce.invoicePreview.headerTitle"),
					headerDescription: $translate.instant("modules.eCommerce.invoicePreview.headerDescription"),
					icon: "fa fa-shopping-cart",
					buttonLabel: $translate.instant("modules.eCommerce.invoicePreview.buttonLabel"),
					actionButton: function() {
						finalStep();
					}
				},
				finalStep:{
					show: false,
					showBackButton: false,
					visited: false,
					lastStep: true,
					stepTitle: $translate.instant("modules.eCommerce.finalStep.stepTitle"),
					stepNumber: 4,
					headerTitle: $translate.instant("modules.eCommerce.finalStep.headerTitle"),
					headerDescription: $translate.instant("modules.eCommerce.finalStep.headerDescription"),
					icon: "fa fa-check-circle",
					buttonLabel: $translate.instant("modules.eCommerce.finalStep.buttonLabel"),
					actionButton: function() {
						close();
						location.reload();

					}
				}
			},
			quantityPerProduct: {},
			formControl: {},
			initialStep: 'productPicker',
			invoicePreview: false,
			context: modalContext,
			billingInfoModel: {},
			termsChecked: false,
			termsNotChecked: false,
			isoCode: 'SEK',
			invoicePdfUrl: undefined,
			
			selectedProducts: [], // { product: {}, quantity: 2 },
			toggledProducts: [],
			relationBucket: { singlePick: false, preSelected: vm.selectedProducts, allSelected: vm.selectedProducts },
			productsLoaded: false,
			formLoaded: false,
			finalStepLoaded: false,
			disableAllActions: false,
			showErrorMessages: false,
			noServiceSelected: false,

			//Functions
			createInvoice: createInvoice,
			addQuantity: addQuantity,
			reduceQuantity: reduceQuantity,
			getQuantityOfItem: getQuantityOfItem,
			showTermsAndConditions: showTermsAndConditions,
			showMoreInformation: showMoreInformation,
			goBack: goBack,
			showCheckout: showCheckout,
			finalStep: finalStep,
			onToggled: onToggled,
			close: close
		});
		
		activate();

		function activate() {
			$scope.currentStep = '';
			catalogueWfid = productCatalogues[vm.context.catalogueName];
			vm.relationBucket.preSelected = vm.relationBucket.allSelected = vm.selectedProducts;

			getProductCatalogue().then(function() {
				$timeout(function() {
					vm.productsLoaded = true;
					vm.steps.productPicker.headerTitle =  vm.productCatalogue.title;
					vm.steps.productPicker.headerDescription = vm.productCatalogue.description;
				}, 1000);
			});
			
			gotoStep(vm.initialStep);

			$scope.currencyFormat = currencyFormatService.getLanguages(),
			$scope.currencyFormat.se_SE = { decimal: ",", thousands: " " };

			$scope.$on('$destroy', function() {
				window.onbeforeunload = null;
			});
		}
		
		function showCheckout() {
			if (vm.relationBucket.allSelected.length != 0) {
				vm.showErrorMessages = false;
				vm.noServiceSelected = false;
				fitModalToWindow(false);
				window.onbeforeunload = function(e) {
					var dialogText = 'Are you sure that you want to close this window?';
					e.returnValue = dialogText;
					return dialogText;
				};
				var totalPriceOfAllRecords = 0;
				vm.steps[vm.initialStep].visited = !vm.steps[vm.initialStep].visited;
				gotoStep('checkout');


				getFormSpecification().then(function() {
					dataOps.getObject({ objectType: enums.objectType.billingInformation, objectId: -1 }).then(function(billingInformation) {
						if (typeof billingInformation.wfid !== "string") {
							existingBillingInformation = undefined;
						}
						else {
							_.assign(vm.billingInfoModel, billingInformation);
							existingBillingInformation = billingInformation;
						}
						vm.formLoaded = true;
					});
				});

				vm.records = _.map(vm.selectedProducts, function (prod) {
					var totalPrice = vm.quantityPerProduct[prod.id] * prod.yearlyPrice; 
					totalPriceOfAllRecords += totalPrice;
					return { 
						product: prod,
						productId: prod.id,
						totalPrice: totalPrice, 
						billingPeriod: {
							from: moment().format("YYYY-MM-DD"),
							to: moment().add(vm.quantityPerProduct[prod.id], 'year').format("YYYY-MM-DD")
						},
						quantity: vm.quantityPerProduct[prod.id]
					};
				})

				vm.totalPriceOfAllRecords = totalPriceOfAllRecords;

			}
			else {
				vm.showErrorMessages = true;
				vm.noServiceSelected = true;
				$timeout(function() {
					vm.showErrorMessages = false;
				}, 5000 );
			}
			$timeout();
		}

		function createInvoice() {
			var model = {};
			if (vm.formControl.isValid() && vm.termsChecked) {
				vm.steps['checkout'].visited = !vm.steps['checkout'].visited;

				model.type = enums.objectType.invoice;
				vm.disableAllActions = true;

				model.billingInformation = vm.formControl.getModel();
				model.billingInformation.type = enums.objectType.billingInformation;

				if (existingBillingInformation)
					model.billingInformation.id = existingBillingInformation.id;

				return $q(function (resolve, reject) {
					dataOps[existingBillingInformation ? "update" : "create"](model.billingInformation).then(function(billingInformation) {
						existingBillingInformation = billingInformation;
						model.billingInformation = dataOps.prepareWfObject(billingInformation);
						model.records = _.map(vm.records, function (record) {
							return {
								productId: record.productId,
								quantity: record.quantity * 12
							}
						});
						
						dataOps.create(model).then(function (invoicePreview) {
							vm.invoicePreviewItem = invoicePreview;
							vm.invoicePdfUrl = $.proxies.baseUrl + "api/order/previewPdf?id=" + invoicePreview.id;
							gotoStep('invoicePreview');
							vm.disableAllActions = false;
							resolve();
						}).catch(function(e) {
							console.error("Invoice PDF not retrieved: ", e);
							modal.alert({
								title: $translate.instant("modules.eCommerce.invoicePreview.alertModal.title"),
								message: $translate.instant("modules.eCommerce.invoicePreview.alertModal.message"),
								type: "warning",
								buttons: {
									primary:{
										label: "OK",
										className: "btn-hollow action",
										callback: function() {
											$scope.$close();
										}
									}
								}
							});
						});
					});
				});
			}
			else {
				vm.showErrorMessages = true;
				vm.termsNotChecked = true;
			}
			$timeout();
		}

		function finalStep() {
			apiProxy("order.attestandSendInvoice", { id: vm.invoicePreviewItem.id }).then(function() {
				vm.finalStepLoaded = true;
				fitModalToWindow(true);
				vm.steps['invoicePreview'].visited = !vm.steps['invoicePreview'].visited;
				vm.steps['finalStep'].visited = true;
				gotoStep('finalStep');
				vm.steps['finalStep'].show = false;
				window.onbeforeunload = null;
			}).catch(function() {
				console.error("Invoice not send and attested");
				modal.alert({
					title: $translate.instant("modules.eCommerce.finalStep.alertModal.title"),
					message: $translate.instant("modules.eCommerce.finalStep.alertModal.message"),
					type: "warning",
					buttons: {
						primary:{
							label: "OK",
							className: "btn-hollow action",
							callback: function() {
								$scope.$close();
							}
						}
					}
				});
			});
			
			$timeout();
		}

		function showTermsAndConditions() {
			$ngBootbox.customDialog({
				title: $translate.instant("modules.eCommerce.termsAndConditions.title"),
				message: $compile('<wf-pdf-viewer url="' + termsAndConditionsPdfUrl + '" allow-navigation="true" show-info="true"></wf-pdf-viewer>')($scope),
				onEscape: true,
				className: "terms-and-conditions-modal",
				buttons: {
					primary: {
						label: $translate.instant('Close'),
						className: "btn wf-btn-link"
					}
				}
			});
			$timeout();
		}

		function showMoreInformation(text) {
			$ngBootbox.customDialog({
				title: $translate.instant("modules.eCommerce.moreInfo.title"),
				message: text,
				onEscape: true,
				className: "text-prewrap",
				buttons: {
					primary: {
						label: $translate.instant('Close'),
						className: "btn wf-btn-link"
					}
				}
			});
			$timeout();
		}

		function gotoStep(stepName) {
			$scope.currentStep = stepName;
			vm.steps.productPicker.show = false;
			vm.steps.checkout.show = false;
			vm.steps.invoicePreview.show = false;
			vm.steps[stepName].show = true;

			$timeout();
		}

		function goBack() {
			if (vm.steps.checkout.show && !vm.disableAllActions) {
				vm.steps['checkout'].visited = false;
				vm.steps['productPicker'].visited = false;
				vm.showErrorMessages = false;
				vm.noServiceSelected = false;
				fitModalToWindow(true);
				vm.billingInfoModel = vm.formControl.getModel();
				gotoStep("productPicker");
			}
			else if (vm.steps.invoicePreview.show && !vm.disableAllActions) {
				vm.steps['invoicePreview'].visited = false;
				vm.steps['checkout'].visited = false;
				vm.billingInfoModel = vm.formControl.getModel();
				gotoStep("checkout");
			}
		}

		function close() {
			if (!vm.disableAllActions) {
				if (vm.steps[$scope.currentStep].lastStep) {
					$scope.$close();
				}
				else if (vm.relationBucket.allSelected.length != 0) {
					modal.alert({
						// headerText:  $translate.instant('modules.eCommerce.exitModal.warning'),
						title: $translate.instant('modules.eCommerce.exitModal.title'),
						message: $translate.instant("modules.eCommerce.exitModal.message"),
						onEscape: false,
						type: "warning",
						buttons: {
							back:{
								label: $translate.instant("modules.eCommerce.exitModal.backButton"),
								className: "btn btn-hollow"
							},
							primary: {
								label:  $translate.instant('modules.eCommerce.exitModal.primaryButton'),
								className: "btn-hollow action",
								callback: function() {
									$scope.$close();
								}
							}
						}
					});
				}
				else {
					$scope.$close();
				}
			}

			$timeout();
		}

		function getFormSpecification() {
			return formSchemaService.getFromType(enums.objectType.billingInformation).then(function (res) {
				var
					properties = _.keys(res.schema.properties),
					propertiesChunks = _.chunk(properties, _.ceil(properties.length / 2))
				;
				
				vm.formSpec = {
					schema: res.schema,
					form: [ {
						type: "section",
						htmlClass: "row clearfix",
						items: [
							{
								type: "section",
								htmlClass: "col-sm-6",
								items: propertiesChunks[0]
							},
							{
								type: "section",
								htmlClass: "col-sm-6",
								items: propertiesChunks[1]
							}
						]
					} ]
				};
			});
		}

		function getProductCatalogue() {
			return dataOps.getObject(catalogueWfid).then(function (catalogue) {
				vm.productCatalogue = catalogue;
				vm.products = _.map(catalogue.childs, "childContent");

				vm.products = _.map(vm.products, function (prod) {
					vm.quantityPerProduct[prod.id] = 1;
					return _.assign(prod, {
						moreInformation: prod.moreInformation,
						monthlyPrice: prod.monthlyPrice,
						yearlyPrice: prod.yearlyPrice
					});
				});
			}).catch(function(e) {
				console.error("Product catalogue not loaded: ", e);
				modal.alert({
					title: $translate.instant("modules.eCommerce.productPicker.alertModal.title"),
					message: $translate.instant("modules.eCommerce.productPicker.alertModal.message"),
					type: "warning",
					buttons: {
						primary:{
							label: "OK",
							className: "btn-hollow action",
							callback: function() {
								$scope.$close();
							}
						}
					}
				});
			});
		}

		function onToggled(item, checked) {
			if (checked) {
				if (!(_.includes(vm.selectedProducts, item)))
					vm.selectedProducts.push(item);
			}
			else {
				_.remove(vm.selectedProducts, item);
			}
			vm.toggledProducts = _.keyBy(vm.selectedProducts, 'id');
		}

		function addQuantity(itemId) {
			vm.quantityPerProduct[itemId]++;
		}

		function reduceQuantity(itemId) {
			if (vm.quantityPerProduct[itemId] != 1) {
				vm.quantityPerProduct[itemId]--;
			}
		}

		function getQuantityOfItem(itemId) {
			var multilingualYear = $translate.instant("Year");
			if (vm.quantityPerProduct[itemId] > 1) {
				multilingualYear  = $translate.instant("Years");
			}
			return vm.quantityPerProduct[itemId] + " " + multilingualYear;
		}

		function fitModalToWindow(fit) {
			if (!modalElement) {
				modalElement = $("div.modal-fit-window");
			}
			if (modalElement && fit)
				modalElement.addClass("modal-fit-window");
			else if (modalElement && !fit)
				modalElement.removeClass("modal-fit-window");
		}

	}

}());
