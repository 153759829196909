import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';
	var objSettingsRowRenderer;

	angular
		.module('wf.common')
		.directive('wfObjectSettings', wfObjectSettings);

	wfObjectSettings.$inject = ["$interpolate", "$sanitize"];
	function wfObjectSettings($interpolate, $sanitize) {
		objSettingsRowRenderer = initObjSettingsRowRenderer($interpolate, $sanitize);

		var directive = {
			controller: wfObjectSettingsController,
			controllerAs: 'objSettingsVm',
			templateUrl: 'scripts/wf/objectSettings/wfObjectSettings.directive.html',
			restrict: 'E'
		};

		return directive;
	}

	function initObjSettingsRowRenderer($interpolate, $sanitize) {
		var html = '<li><strong>{{label}}:</strong><span>{{optionName}}</span><li>';
		var interpolationFunc = $interpolate(html);

		return {
			render: function (settingDef, currentItemSettings) {
				var
					currentSettingDefOption,
					params,
					paramsAdditionalItem,
					output,
					hasCurrentSettingValue = currentItemSettings.hasOwnProperty(settingDef.settingKind),
					currentSettingValue = currentItemSettings[settingDef.settingKind]
				;

				if (settingDef.condition) {
					if (!_.isMatch(currentItemSettings[settingDef.condition.key], settingDef.condition.value)) {
						return;
					}
				}

				if (settingDef.customControl) {
					return;
				}

				if (hasCurrentSettingValue) {
					if (_.isArrayLike(currentSettingValue)) {
						currentSettingValue = currentSettingValue.join(",")
					}

					currentSettingDefOption = _.find(settingDef.options, function (option) {
						return typeof option.checkValue === "function"
							? option.checkValue(currentSettingValue)
							: option.value === currentSettingValue
					});

					if (!currentSettingDefOption || currentSettingDefOption.hideInPreview) {
						return;
					}

					params = {
						label: $sanitize(settingDef.label),
						optionName: $sanitize(currentSettingDefOption.name)
					};

					if (currentSettingDefOption.additionalWhenSelected && currentSettingDefOption.additionalWhenSelected.display) {
						const displayValue = currentSettingDefOption.additionalWhenSelected.display.value(currentSettingValue);
						if (displayValue) {
							paramsAdditionalItem = {
								label: $sanitize(currentSettingDefOption.additionalWhenSelected.display.label()),
								optionName: $sanitize(displayValue)
							}
						}
					}

				}
				else {
					currentSettingDefOption = _.find(settingDef.options, { whenNotSet: true });
					if (!currentSettingDefOption || currentSettingDefOption.hideInPreview) {
						return;
					}
					params = {
						label: $sanitize(settingDef.label),
						optionName: $sanitize(currentSettingDefOption.name)
					};
				}

				output = interpolationFunc(params);

				if (paramsAdditionalItem) {
					output += interpolationFunc(paramsAdditionalItem);
				}

				// console.log(params, output);

				return output;
			}
		}
	}

	wfObjectSettingsController.$inject = ['$scope', '$element', 'dataOperationsService', '$timeout', 'wfAuth', 'modalService', '$parse', '$attrs', '$translate']
	function wfObjectSettingsController($scope, $element, dataOps, $timeout, wfAuth, modal, $parse, attrs, $translate) {
		var
			definition = $parse(attrs.settings)($scope),
			item,
			onChange,
			objectSettings,
			forOrganization,
			uiMode,
			vm = this,
			requirementSpec = {},
			defaultRequirement,
			selectorOptions,
			currentItemSettings
		;

		if (definition) {
			item = $parse(attrs.item)($scope) || definition.item;
			onChange = $parse(attrs.onChange)($scope);

			if (item.type == enums.objectType.virtualDataRelation && typeof item.originalRelationWfid === "string") {
				item = item.originalRelation;
			}

			objectSettings = definition.settings;
			forOrganization = definition.forOrganization;
			uiMode = definition.uiMode || enums.uiMode.view;
			currentItemSettings = (item.type === enums.objectType.dataRelation ? item.settings : item.conditions) || {};
			vm.editButtonCaption = definition.editButtonCaption || $translate.instant("Edit");

			if ($parse(attrs.compactLayout)($scope)) {
				$element.addClass("compact-layout");
			}
		}
		else {
			$element.remove();
			return;
		}

		activate();

		function activate() {
			_.assign(vm, {
				compiler: {},
				uiMode: uiMode,
				isAdminMode: uiMode === enums.uiMode.admin,
				getDisplayContent: getDisplayContent,
				editObjectSettings: editObjectSettings,
				hasObjSettings: !!objectSettings.length
			})
		}

		function getDisplayContent() {
			var
				output = []
			;

			for (var i = 0, len = objectSettings.length, objectSetting; i < len; i++) {
				objectSetting = objectSettings[i];
				output.push(objSettingsRowRenderer.render(objectSetting, currentItemSettings))
			}

			// for (var key in item.settings) {
			// 	if (item.settings.hasOwnProperty(key)) {
			// 		var value = item.settings[key];

			// 		setting = _.find(objectSettingsDefinition, { settingKind: key })

			// 		if (setting) {
			// 		}

			// 	}
			// }



			return output.join("");
		}

		function editObjectSettings() {
			modal.editFormattedObjectSettings({
				item: item,
				objectSettings: objectSettings,
				onBeforeSubmit: definition.onBeforeSubmit,
				forOrganization: forOrganization
			}).then(function () {
				currentItemSettings = (item.type === enums.objectType.dataRelation ? item.settings : item.conditions);
				vm.compiler.compile();
				$timeout();

				if (definition.onUpdated)
					definition.onUpdated();

				if (typeof onChange === "function")
					onChange();
			});
		}

		function getText(setting) {
			// console.log(requirement);
			if (!settingRow)
				return;

			var settingRow = _.find(requirementOptions, { rule: requirement.rule, value: requirement.value })
			if (requirementOption) {
				return requirementOption.name;
			}
		}

		function getDefaultRequirementText() {
			var requirement = requirementSpec.standard;

			if (!requirement && vm.isAdminMode) {
				return "Inget krav";
			}

			return getText(requirement);
		}

		function getSpecificRequirementText() {
			var requirement = requirementSpec.specific;

			return getText(requirement);
		}

		function editSpecificRequirement() {
			if (!selectorOptions) {
				selectorOptions = _.filter(settings.requirementOptions, function (item) {
					return item.selectable !== false;
				});
			}
			modal.editRequirement({
				item: item,
				forOrganizationId: settings.forOrganizationId,
				selectorOptions: selectorOptions
			}).then(function (updatedRequirementSpec) {
				requirementSpec.specific = updatedRequirementSpec.specific;

				if (requirementSpec.specific === null) {
					requirementSpec.actual = requirementSpec.standard;
				}
				else {
					requirementSpec.actual = requirementSpec.specific;
				}

				vm.compiler.compile();
				$timeout();

				if (settings.onUpdated)
					settings.onUpdated();
			});
		}

		function editDefaultRequirement() {
			if (!selectorOptions) {
				selectorOptions = _.filter(settings.requirementOptions, function (item) {
					return item.selectable !== false;
				});
			}
			modal.editRequirement({
				item: item,
				selectorOptions: selectorOptions
			}).then(function (updatedRequirementSpec) {
				requirementSpec.standard = updatedRequirementSpec.standard;

				if (requirementSpec.specific === null) {
					requirementSpec.actual = requirementSpec.standard;
				}
				else {
					requirementSpec.actual = requirementSpec.specific;
				}

				vm.compiler.compile();
				$timeout();

				if (settings.onUpdated)
					settings.onUpdated();
			});
		}
	}
})();
