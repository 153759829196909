import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Colors from '@worldfavor/constants/colors'
import { FormattedMessage } from 'react-intl'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles(theme => ({
  analysisJobNameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  analysisJobName: {
    fontSize: theme.typography.fontSizes.xxLarge,
    marginLeft: 15,
  },
  analysisJobOrganization: {
    fontWeight: theme.typography.fontWeights.medium,
    fontSize: theme.typography.fontSizes.xxLarge,
    marginRight: 15,
  },
  openAnalysisResultSceneButtonContainer: {
    marginLeft: 10,
  },
  openAnalysisResultSceneButton: {
    height: 40,
    width: 40,
    fontSize: theme.typography.fontSizes.medium,
    color: theme.palette.common.white,
    textDecoration: 'none',
    '&:visited': {
      color: theme.palette.common.white,
    },
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.common.white,
    },
    '&:focus': {
      textDecoration: 'none',
      outline: 'none',
    },
  },
  title: {
    textTransform: 'uppercase',
    fontSize: theme.typography.fontSizes.default,
  },
  analysisLabel: {
    marginRight: 10,
    borderRadius: 4,
    padding: '1px 8px 3px 8px',
    fontSize: theme.typography.fontSizes.default,
    fontWeight: theme.typography.fontWeights.medium,
  },
  headerTopSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeDialogButton: {
    width: 50,
    height: 50,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: Colors.blackDark,
    },
  },
  analysisTitleDivider: {
    height: 40,
    borderRadius: 5,
    width: 2,
  },
  subTitleContainer: {
    display: 'flex',
  },
  analysisScore: {
    fontSize: theme.typography.fontSizes.default,
    fontWeight: theme.typography.fontWeights.semiBold,
  },
}))

const AnalyzeResultsHeader = (props) => {
  const classes = useStyles(props)
  const { closeDialog, openInNewTab, selectedAnalyzeJob, solutionId, networkId, targetOrganization } = props
  return (
    <div data-cy="analyze-results-header">
      <div className={classes.headerTopSection}>
        <div className={classes.title}><FormattedMessage id="dataManager.contentTypes.analyzeResults" /></div>
        { closeDialog && (
          <div>
            <IconButton
              onClick={closeDialog}
              className={classes.closeDialogButton}
            >
              <i className="fas fa-times" />
            </IconButton>
          </div>
        )}
      </div>
      <div className={classes.analysisJobNameContainer}>
        {
          targetOrganization && (
            <div className={classes.analysisJobOrganization}>{targetOrganization}</div>
          )
        }
        <div className={classes.analysisTitleDivider} />
        {
          selectedAnalyzeJob.title && (
            <div className={classes.analysisJobName}>{selectedAnalyzeJob.title}</div>
          )
        }
        {
          openInNewTab && (
            <div className={classes.openAnalysisResultSceneButtonContainer}>
              <IconButton
                href={`/${solutionId}/valuechain/${networkId}/analyzeResults/${selectedAnalyzeJob.id}`}
                target="_blank"
                className={classes.openAnalysisResultSceneButton}
                size="small"
              >
                <i className="fas fa-external-link-alt" />
              </IconButton>
            </div>
          )
        }
      </div>
      <div className={classes.subTitleContainer}>
        {
          selectedAnalyzeJob.scoreLabel && (
            <div><span className={classes.analysisLabel}>{selectedAnalyzeJob.scoreLabel}</span></div>
          )
        }
        {
          (selectedAnalyzeJob.score && selectedAnalyzeJob.score > 0) ? (
            <div>
              <span className={classes.analysisScore}>
                <FormattedMessage id="dataManager.scoreDisplay" values={{ analysisScore: selectedAnalyzeJob.score }} />
              </span>
            </div>
          ) : null
        }
      </div>
    </div>
  )
}

export default AnalyzeResultsHeader
