import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { react2angular } from 'react2angular'
import DataExplorer from '@worldfavor/components/DataExplorer/DataExplorer'
import { WrapProvider, StoreProvider } from './providers'
import Colors from '@worldfavor/constants/colors'
import { hexToRgbA } from '@worldfavor/constants/colors'

const useStyles = makeStyles({
  searchBar: {
    border: `solid 1px ${hexToRgbA(Colors.black, 0.2)}`,
  },
  onFocusSearchBarBorder: {
    border: 'none',
  },
  menuButton: {
    backgroundColor: hexToRgbA(Colors.black, 0.2),
    '&:hover': {
      background: hexToRgbA(Colors.black, 0.4),
    },
  },
  menuButtonText: {
    color: Colors.black,
  },
  menuIcon: {
    color: Colors.black,
  },
  filterBarWrapper: {
    marginTop: 20,
    paddingLeft: 28,
    top: 0,
  },
  filterBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
})

const DataExplorerWrapper = (props) => {
  const { wfAuth } = props
  const classes = useStyles(props)
  return (
    <WrapProvider wfAuth={wfAuth}>
      <StoreProvider>
        <DataExplorer
          disableMaxWidthGrid
          classes={{
            searchBar: classes.searchBar,
            menuButton: classes.menuButton,
            menuButtonText: classes.menuButtonText,
            menuIcon: classes.menuIcon,
            onFocusSearchBarBorder: classes.onFocusSearchBarBorder,
            filterBarWrapper: classes.filterBarWrapper,
            filterBar: classes.filterBar,
          }}
        />
      </StoreProvider>
    </WrapProvider>
  )
}

export default react2angular(DataExplorerWrapper, [], ['wfAuth'])
