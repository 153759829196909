import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import Colors from '@worldfavor/constants/colors'
import { FormattedMessage } from 'react-intl'
import EmptyState from '@worldfavor/components/EmptyState'
import ReactTable from '@worldfavor/components/Table/ReactTable'
import HighlightedText from '@worldfavor/components/Text/HighlightedText'
import withPage from '@worldfavor/portal/hoc/withPage'
import { addSpaceBetweenWords } from '@worldfavor/utils/helpers'
import { hexToRgbA } from '@worldfavor/constants/colors'
import DataManagerTableSettings from '@worldfavor/components/DataManager/DataManagerTableSettings'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(theme => ({
  preTableInfo: {
    padding: 15,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  resultCount: {
    fontWeight: theme.typography.fontWeights.medium,
    fontSize: theme.typography.fontSizes.medium,
  },
  headerTableCell: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  rowTableCell: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  tableCell: {
    paddingRight: 8,
    whiteSpace: 'pre',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tableWrapper: {
    overflowX: 'scroll',
  },
  headerRow: {

    textTransform: 'uppercase',
  },
  row: {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    '&:hover': {
      backgroundColor: hexToRgbA(theme.palette.primary.main, 0.10),
    },
  },
  oddRow: {
    backgroundColor: Colors.grayOffWhite,
    '&:hover': {
      backgroundColor: hexToRgbA(theme.palette.primary.main, 0.10),
    },
  },
  rowHighlight: {
    backgroundColor: hexToRgbA(theme.palette.secondary.main, 0.10),
  },
  tableCellWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: theme.typography.fontSizes.default,
    fontWeight: theme.typography.fontWeights.medium,
    color: Colors.grayText,
  },
  emptyState: {
    padding: 150,
  },
}))

export const DataExplorerTable = (props) => {
  const { data, columns, searchText, page, resultCount, dataSource, ...otherProps } = props
  const tableClasses = useStyles(props)
  const [tableColumns, setTableColumns] = useState([])
  const [enteringSearchInput, setEnteringSearchInput] = useState(false)
  const [settings, setSettings] = useState({
    expandCells: false,
    wordWrap: false,
    stickyHeader: false,
    columnResizing: true,
    enableDenseRows: true,
  })
  const { expandCells, wordWrap, stickyHeader, columnResizing, enableDenseRows } = settings

  useEffect(() => {
    const handleKeyDown = () => {
      setEnteringSearchInput(true)
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  useEffect(() => {
    setTableColumns([])
    if (data && data.length) {
      const keys = getUniqueKeys(data)
      columns ? setTableColumns(columns) : setTableColumnsFromKeys(keys)
    }
  }, [data, expandCells])

  function getUniqueKeys(array) {
    let allKeys = []

    //accumulates all keys
    array.map(item => allKeys = [...Object.keys(item), ...allKeys])

    //returns unique array elements
    return [...new Set(allKeys)]
  }

  function setTableColumnsFromKeys(keys) {
    setTableColumns(keys.map((key) => {
      return { Header: addSpaceBetweenWords(key), accessor: `${key}`, width: !expandCells ? 200 : 300 }
    }))
  }

  function renderColumnCell(Header, accessor, column) {
    return column.Header
  }

  function onRowClick(event) {
    const cell = event.target
    cell.parentElement.classList.toggle(tableClasses.rowHighlight)
  }

  function renderRowCell(value, row, column) {
    const rowObject = row.original
    const cellValue = rowObject[column.id]

    if (cellValue === undefined || typeof cellValue === 'object') {
      return ''
    }
    else if (!enteringSearchInput) {
      return <HighlightedText text={`${cellValue}`} match={searchText} />
    } else {
      return cellValue
    }
  }

  if (tableColumns && tableColumns.length) {
    return (
      <>
        <div className={tableClasses.preTableInfo}>
          <span className={tableClasses.resultCount} data-cy={'result-count'}>
            <FormattedMessage id="search.resultsCount" values={{ count: `${resultCount}` }} />
          </span>
          <DataManagerTableSettings
            settings={settings}
            setSettings={setSettings}
          />
        </div>
        <Paper data-cy={'data-explorer-table'}>
          <ReactTable
            data={data}
            columns={tableColumns}
            enablePagination
            dense={enableDenseRows}
            renderRowCell={renderRowCell}
            renderColumnCell={renderColumnCell}
            controlledPagination
            controlledPageIndex={page}
            columnResizing={columnResizing}
            expandCell={expandCells}
            stickyHeader={stickyHeader}
            resultCount={resultCount}
            onRowClick={onRowClick}
            wordWrap={wordWrap}
            {...otherProps}
            classes={{
              headerTableCell: tableClasses.headerTableCell,
              rowTableCell: tableClasses.rowTableCell,
              tableCell: tableClasses.tableCell,
              tableWrapper: tableClasses.tableWrapper,
              headerRow: tableClasses.headerRow,
              row: tableClasses.row,
              oddRow: tableClasses.oddRow,
              tableCellWrapper: tableClasses.tableCellWrapper,
            }}
          />
        </Paper>
      </>
    )
  }

  return (
    <Paper data-cy="data-explorer-empty-state">
      <EmptyState
        style={{ padding: 150 }}
        size={'small'}
        iconClass={dataSource.icon}
        title={(
          <FormattedMessage
            id={'dataExplorer.emptyStateTitle'}
            values={{ itemType: dataSource && dataSource.label.toLowerCase() }}
          />
        )}
      />
    </Paper>
  )
}

export const DataExplorerTableWithPage = withPage(({ items, totalElements, onSearchChange, dataSource, ...other }) => {
  const data = items.map(item => item.node)
  return (
    <DataExplorerTable
      data={data}
      count={totalElements}
      dataSource={dataSource}
      {...other}
    />
  )
})
