import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';

	angular
		.module('wf.common')
		.component('wfMeasurePeriodLockSetting', {
			templateUrl: "scripts/wf/objectSettings/wfMeasurePeriodLockSetting.component.html",
			controller: wfMeasurePeriodLockSettingController,
			controllerAs: "vm",
			bindings: {
				value: "<",
				outerForm: "<form",
				frequency: "<",
				customRanges: "<"
			}
		});

	wfMeasurePeriodLockSettingController.$inject = [ "$scope", "$timeout", "$element", "$rootScope", "$state", "requirements", "$transclude", "$compile", "$translate", "$q", "dataOperationsService", "wfObject", "statisticsService", "analyzeService", "wfPropertyExtractor", "wfMeasureService" ];
	function wfMeasurePeriodLockSettingController($scope, $timeout, $element, $rootScope, $state, requirementService, $transclude, $compile, $translate, $q, dataOps, wfObject, statisticsService, analyzeService, wfPropertyExtractor, wfMeasureService) {
		var
			vm = this,
			debug = false
		;

		this.$onInit = $onInit;

		_.assign(vm, {
			formModel: {
				lockPeriod: ""
			},
			formControl: {}
		});

		function $onInit() {
			if (!vm.value)
				vm.value = { };

			vm.value.frequency = vm.frequency;

			vm.outerForm.onChanged(vm.value);

			vm.generatedPeriods = wfMeasureService.generatePeriods({
				frequency: vm.frequency,
				ranges: vm.customRanges
			}).map( x => { return { value: x.startDate + "|" + x.endDate, name: x.name }})
			vm.generatedPeriods.unshift({ value: "", name: $translate.instant("modules.valueChain.objectSettings.measureSettings.lockPeriod.none") })

			const currentLockPeriod = _.get(vm.value, "lockPeriod") || null;
			if (vm.generatedPeriods.some(x => x.value === currentLockPeriod)) {
				vm.formModel.lockPeriod = currentLockPeriod;
			}
			else {
				vm.value.lockPeriod = null;
				vm.outerForm.onChanged(vm.value);
			}

			vm.formSpec = {
				schema: {
					"type": "object",
					"properties": {
						"lockPeriod": {
							"title": $translate.instant("modules.valueChain.objectSettings.measureSettings.lockPeriod.label"),
							"type": "string"
						}
					}
				},
				form: [
					{
						key: "lockPeriod",
						type: "select",
						titleMap: vm.generatedPeriods,
						onChange: (modelValue, form) => {
							if (!modelValue) {
								vm.value.lockPeriod = null;
							}
							else {
								vm.value.lockPeriod = modelValue;
							}
							vm.outerForm.onChanged(vm.value);
						}
					}
				]
			};
		}
	}
})();
