(function() {
	'use strict';

	angular
		.module('wf.common')
		.component('wfValueChainImporter', {
			templateUrl:'scripts/wf/importExport/wfValueChainImporter.component.html',
			controller: wfValueChainImporterController,
			controllerAs: 'vm',
			bindings: {},
		});

	wfValueChainImporterController.$inject = [ "$q", "valueChainService", "$timeout" ];
	function wfValueChainImporterController($q, valueChainService, $timeout) {
		var vm = this;
		var requirejs, requireConfig;
		var head = document.getElementsByTagName("head")[0];
		var do_file;

		var
			columns = {
				organization: {
					name: "Supplier Group Name",
					vatNumber: "Local Unit Supplier VAT",
					registrationNumber: "Swedish Org Number",
					yearlySpend: "Sum of Totals"
				},
				user: {
					given_name: "SuppContact{{number}} Firstname",
					family_name: "SuppContact{{number}} Lastname",
					email: "SuppContact{{number}} Email"
				}
			},
			maxUserCount = 4,
			sheetName = "Cleaned Data"
		;

		var
			itemPrototype = {
				id: null,
				imported: false, // Indicates if all parts of the supplier is imported
				orgImported: false,
				usersImported: false,
				categoriesImported: false,
				organization: {
					content: {
						imported: false,
						validVatNumber: false,
						validRegistrationNumber: null,
						countryCouldBeDetermined: false,
						foundMatchInDb: false,
						matchInDbFoundWith: null, // "VAT Number", "Registration Number", "Name"
						matches: [],
						id: -1,
						model: {
							name: null,
							vatNumber: null,
							registrationNumber: null,
							countryId: null
						},
						actual: null
					},
					relation: {
						imported: false,
						id: -1,
						model: {},
						actual: null
					}
				},
				users: [],
				categories: [],
				yearlySpend: {
					content: {
						model: {
							value: null
						},
						actual: null
					}
				}
			},
			userPrototype = {
				content: {
					imported: false,
					id: -1,
					model: {
						given_name: null,
						family_name: null,
						email: null
					}
				},
				relation: {
					imported: false,
					id: -1,
					model: {}
				}
			}
		;

		_.assign(vm, {
			readXlsx: readXlsx,
			network: {
				type: 52,
				id: 0
			},
			items: null,
			columns: columns,

			createOrganization: createOrganization,
			createUsers: createUsers
		});

		loadRequireJs().then(function(requireLoaded) {
			if (requireLoaded)
				require([ "xlsx" ], function(xlsx) {
					activate(xlsx);
				});
		});

		function readXlsx(file, invalidFile) {
			//Not gonna work like this
			//Need to use the SheetJS library to get the file...
			vm.file = file;
			do_file([ file ]);
		}


		function loadRequireJs() {
			var defered = $q.defer();
			requirejs = document.createElement("script");
			requirejs.type = "text/javascript";
			requirejs.src = "node_modules/requirejs/require.js";
			requirejs.onload = function() {
				requireConfig = document.createElement("script");
				requireConfig.type = "text/javascript";
				requireConfig.src = "assets/plugins/requirejs/requireConfig.js";
				requireConfig.onload = function() {
					defered.resolve(true);
				}
				head.appendChild(requireConfig);
			}
			head.appendChild(requirejs);

			return defered.promise;
		}

		function createOrganization(item) {
			var
				model = item.organization.content.model,
				promise
			;

			return $q(function (resolve, reject) {
				promise = valueChainService.createOrganization(vm.network, model);

				promise.then(function (res) {
					if (res) {
						if (typeof item.yearlySpend.content.model.value === "number" || typeof item.yearlySpend.content.model.value === "string") {
							valueChainService.setOrganizationYearlySpend(res, item.yearlySpend.content.model.value).then(function (yearlySpendParamValue) {
								item.yearlySpend.imported = true;
								item.yearlySpend.content.actual = yearlySpendParamValue;

								finish()
							});
						}
						else
							finish();
					}
					else
						resolve();

					function finish() {
						item.organization.imported = true;
						item.organization.id = res.id;
						item.organization.content.actual = res;
						item.orgImported = true;

						resolve();
					}
				});
			});
		}

		function createUsers(item) {
			var
				modalClosedCount = 0,
				importedCount = 0
			;

			return $q(function (resolve, reject) {
				_.each(item.users, function (user) {
					var
						model = user.content.model,
						promise
					;
					
					promise = valueChainService.createUser(vm.network, item.organization.content.actual, model);

					promise.then(function (res) {
						if (res) {
							user.imported = true;
							user.id = res.id;
							user.content.actual = res;

							importedCount++;
							if (importedCount === item.users.length) {
								item.usersImported = true;
								$timeout();
							}
						}
							
						modalClosedCount++;
						if (modalClosedCount === item.users.length) {
							resolve();
						}
					});
				})
			})
		}

		function handleResult(rows) {
			var
				organizationRelations
			;			
			
			vm.items = _.map(rows, function (row, index) {
				var
					item,
					userModel,
					searchSource = ""
				;

				item = _.defaultsDeep({
					id: index + 1,
					organization: {
						content: {
							model: {
								name: trim(row[columns.organization.name]),
								vatNumber: trim(row[columns.organization.vatNumber]),
								registrationNumber: trim(row[columns.organization.registrationNumber])
							}
						}
					},
					yearlySpend: {
						content: {
							model: {
								value: trim(row[columns.organization.yearlySpend])
							}
						}
					},
					users: [],
				}, itemPrototype);

				searchSource = _.compact([ item.organization.content.model.name, item.organization.content.model.vatNumber, item.organization.content.model.registrationNumber, item.organization.content.model.gln ]).join(" ");

				for (var i = 1; i <= maxUserCount; i++) {
					userModel = {
						given_name: trim(row[columns.user.given_name.replace("{{number}}", i)]),
						family_name: trim(row[columns.user.family_name.replace("{{number}}", i)]),
						email: trim(row[columns.user.email.replace("{{number}}", i)])
					};

					if (userModel.given_name || userModel.family_name || userModel.email) {
						userModel.name = _.compact([ userModel.given_name, userModel.family_name ]).join(" ").trim();
						item.users.push(_.defaultsDeep({
							content: {
								model: userModel
							}
						}, userPrototype));

						searchSource += " " + userModel.name + " " + userModel.email;
					}

					userModel = undefined;
				}

				item.searchSource = searchSource;

				return item;
			});

			console.log(vm.items);

			$timeout();
			
			// console.log(wfObject.inject(organizationRelations));

			function trim(value) {
				if (typeof value === "string")
					return value.trim();
				else
					return value;
			}
		}

		function activate(XLSX) {
			var X = XLSX;
			var global_wb;

			var process_wb = (function() {
				var OUT = document.getElementById('out');
				var HTMLOUT = document.getElementById('htmlout');

				var get_format = (function() {
					var radios = document.getElementsByName( "format" );
					return function() {
						for(var i = 0; i < radios.length; ++i) if(radios[i].checked || radios.length === 1) return radios[i].value;
					};
				})();

				var to_json = function to_json(workbook) {
					var result = {};
					workbook.SheetNames.forEach(function(sheetName) {
						var roa = X.utils.sheet_to_json(workbook.Sheets[sheetName], { header: undefined, raw: true });
						if(roa.length) result[sheetName] = roa;
					});

					handleResult(result[sheetName])
					return JSON.stringify(result, 2, 2);
				};

				var to_csv = function to_csv(workbook) {
					var result = [];
					workbook.SheetNames.forEach(function(sheetName) {
						var csv = X.utils.sheet_to_csv(workbook.Sheets[sheetName]);
						if (csv.length) {
							result.push("SHEET: " + sheetName);
							result.push("");
							result.push(csv);
						}
					});
					return result.join("\n");
				};

				var to_fmla = function to_fmla(workbook) {
					var result = [];
					workbook.SheetNames.forEach(function(sheetName) {
						var formulae = X.utils.get_formulae(workbook.Sheets[sheetName]);
						if (formulae.length) {
							result.push("SHEET: " + sheetName);
							result.push("");
							result.push(formulae.join("\n"));
						}
					});
					return result.join("\n");
				};

				var to_html = function to_html(workbook) {
					HTMLOUT.innerHTML = "";
					workbook.SheetNames.forEach(function(sheetName) {
						var htmlstr = X.write(workbook, {sheet:sheetName, type:'binary', bookType:'html'});
						HTMLOUT.innerHTML += htmlstr;
					});
					return "";
				};

				return function process_wb(wb) {
					global_wb = wb;
					var output = "";
					switch(get_format()) {
						case "form": output = to_fmla(wb); break;
						case "html": output = to_html(wb); break;
						default:
						case "json": output = to_json(wb); break;
						// default: output = to_csv(wb);
					}
					// if(OUT.innerText === undefined) OUT.textContent = output;
					// else OUT.innerText = output;
					// if(typeof console !== 'undefined') console.log("output", new Date());
				};
			})();

			var setfmt = window.setfmt = function setfmt() { if (global_wb) process_wb(global_wb); };

			var b64it = window.b64it = (function() {
				var tarea = document.getElementById('b64data');
				return function b64it() {
					if(typeof console !== 'undefined') console.log("onload", new Date());
					var wb = X.read(tarea.value, {type:'base64', WTF:false});
					process_wb(wb);
				};
			})();

			do_file = (function() {
				var rABS = typeof FileReader !== "undefined" && (FileReader.prototype ||{}).readAsBinaryString;
				var domrabs = document.getElementsByName("userabs")[0];
				if(!rABS) domrabs.disabled = !(domrabs.checked = false);

				var use_worker = typeof Worker !== 'undefined';
				var domwork = document.getElementsByName("useworker")[0];
				if(!use_worker) domwork.disabled = !(domwork.checked = false);

				// domrabs.disabled = false;
				// domwork.disabled = true;

				var xw = function xw(data, cb) {
					var worker = new Worker(XW.worker);
					worker.onmessage = function(e) {
						switch (e.data.t) {
							case 'ready': break;
							case 'e': console.error(e.data.d); break;
							case XW.msg: cb(JSON.parse(e.data.d)); break;
						}
					};
					worker.postMessage({ d: data, b: rABS ? 'binary' : 'array'});
				};

				return function do_file(files) {
					rABS = domrabs.checked;
					use_worker = domwork.checked;
					var f = files[0];
					var reader = new FileReader();
					reader.onload = function(e) {
						// if (typeof console !== 'undefined') console.log("onload", new Date(), rABS, use_worker);
						var data = e.target.result;
						if (!rABS) data = new Uint8Array(data);
						if (use_worker) xw(data, process_wb);
						else process_wb(X.read(data, { type: rABS ? 'binary' : 'array' }));
					};
					if(rABS && reader.readAsBinaryString) reader.readAsBinaryString(f);
					else if (reader.readAsArrayBuffer) reader.readAsArrayBuffer(f);
				};
			})();
		}
	}
})();



