import * as enums from '@worldfavor/constants/enums'

(function () {
	'use strict';

	angular
		.module('wf.common')
		.factory('WfFile', wfFileService);

	wfFileService.$inject = [ 'dataOperationsService', 'wfAuth', 'moment', 'importExportService' ];
	function wfFileService(dataOps, wfAuth, moment, importExportService) {
		return WfFile;

		function WfFile(data) {
			var self = this;
			_.assign(self, {
				//Variables
				data: {
					fileName: data ? data.fileName : null,
					organization: data ? data.organization : wfAuth.getOrganization(),
					createdBy: data ? data.createdBy : wfAuth.getWorldfavorUser(),
					createdAt: data ? data.createdAt : moment().format(),
					informationRepository: data ? data.informationRepository : {},
					relationsCount: data ? data.relationsCount : 0,
					relations: data ? data.relations : []
				},
				verified: false,

				//Function
				saveToInformationRepository: saveToInformationRepository,
				saveRelations: saveRelations,
				download: download,
				verify: verify
			});

			function verify() {
				if (self.data) {
					if (!data.fileName)
						self.data.fileName = "Could not read file name";
					if (data.createdAt == undefined)
						self.data.createdAt = "Could not read the created date of the file.";
					if (typeof self.data.createdBy !== "object" || (typeof self.data.createdBy === "object" && _.isEmpty(self.data.createdBy)) || !data.createdBy || self.data.createdBy.type !== enums.objectType.individual)
						self.data.createdBy = "Could not find the creator of the file."
					if (typeof self.data.organization !== "object" || (typeof self.data.organization === "object" && _.isEmpty(self.data.organization)) || !data.organization || self.data.organization.type !== enums.objectType.organization)
						return "Cannot find the organization in the file.";
					// if (typeof self.data.informationRepository !== "object" || _.isEmpty(self.data.informationRepository))
					// 	return "Information repository property is empty or it is missing from the file.";

					//We should add some more checks to verify the file

					self.verified = true;

					return self.verified;
				}
			}

			function saveToInformationRepository(key, items, relations) {
				self.data.informationRepository[key] = {
					items: items,
					relations: relations
				};
			}

			function saveRelations(items) {
				self.data.relations = items;
			}

			function download() {
				var data = self.data;
				data.fileName = "Worldfavor_data_" + moment(data.createdAt).format("YYYY-MM-DD") + ".json";
				
				var stringifiedJSON = importExportService.stringifyJSON(data);
				importExportService.downloadFile(stringifiedJSON, data.fileName, "application/json");
			}
		}
	}
})();
