(function() {
	'use strict';

	angular
		.module('wf.common')
		.service('csvService', csvService);

	csvService.$inject = [ '$q' ];

	function csvService($q) {
		var EOL = '\r\n';
		var BOM = "\ufeff";

		var specialChars = {
			'\\t': '\t',
			'\\b': '\b',
			'\\v': '\v',
			'\\f': '\f',
			'\\r': '\r'
		};

		var service = {
			"stringify": stringify
		};

		return service;

		/**
		 * Creates a csv from a data array
		 * @param data
		 * @param options
		 *  * header - Provide the first row (optional)
		 *  * fieldSep - Field separator, default: ','
		 *  * decimalSep - Decimal separator, default: '.'
		 *  * txtDelim - If provided, will use this characters to "escape" fields, otherwise will use double quotes (") as deafult
		 *  * quoteStrings - If provided, will force escaping of every string field.
		 *  * addByteOrderMarker - Add Byte order mark, default(false)
		 * @param callback
		 */
		function stringify(data, options) {
			var def = $q.defer();
			
			var csv = "";
			var csvContent = "";

			if (isSpecialChar(options.fieldSep))
				options.fieldSep = getSpecialChar(options.fieldSep);

			var dataPromise = $q.when(data).then(function (responseData) {
				//responseData = angular.copy(responseData);//moved to row creation
				// Check if there's a provided header array
				if (angular.isDefined(options.header) && options.header) {
					var encodingArray, headerString;

					encodingArray = [];
					angular.forEach(options.header, function (title, key) {
						this.push(stringifyField(title, options));
					}, encodingArray);

					headerString = encodingArray.join(options.fieldSep ? options.fieldSep : ",");
					csvContent += headerString + EOL;
				}

				var arrData = [];

				if (angular.isArray(responseData)) {
					arrData = responseData;
				}
				else if (angular.isFunction(responseData)) {
					arrData = responseData();
				}

				// Check if using keys as labels
				if (angular.isDefined(options.label) && options.label && typeof options.label === 'boolean') {
						var labelArray, labelString;

						labelArray = [];
						angular.forEach(arrData[0], function(value, label) {
								this.push(stringifyField(label, options));
						}, labelArray);
						labelString = labelArray.join(options.fieldSep ? options.fieldSep : ",");
						csvContent += labelString + EOL;
				}

				angular.forEach(arrData, function (oldRow, index) {
					var row = angular.copy(arrData[index]);
					var dataString, infoArray;

					infoArray = [];

					var iterator = !!options.columnOrder ? options.columnOrder : row;
					angular.forEach(iterator, function (field, key) {
						var val = !!options.columnOrder ? row[field] : field;
						this.push(stringifyField(val, options));
					}, infoArray);

					dataString = infoArray.join(options.fieldSep ? options.fieldSep : ",");
					csvContent += index < arrData.length ? dataString + EOL : dataString;
				});

				// Add BOM if needed
				if (options.addByteOrderMarker) {
					csv += BOM;
				}

				// Append the content and resolve.
				csv += csvContent;
				def.resolve(csv);
			});

			if (typeof dataPromise['catch'] === 'function') {
				dataPromise['catch'](function (err) {
					def.reject(err);
				});
			}

			return def.promise;
		}

		/**
		 * Stringify one field
		 * @param data
		 * @param options
		 * @returns {*}
		 */
		function stringifyField(data, options) {
			if (data && data._isAMomentObject) {
				data = data.format(options.dateTimeFormat);
			}

			if (options.decimalSep === 'locale' && isFloat(data)) {
				return data.toLocaleString();
			}

			if (options.decimalSep !== '.' && isFloat(data)) {
				return data.toString().replace('.', options.decimalSep);
			}

			if (typeof data === 'string') {
				data = data.replace(/"/g, '""'); // Escape double qoutes

				if (options.quoteStrings || data.indexOf(',') > -1 || data.indexOf('\n') > -1 || data.indexOf('\r') > -1) {
						data = options.txtDelim + data + options.txtDelim;
				}

				return data;
			}

			if (typeof data === 'boolean') {
				return data ? 'TRUE' : 'FALSE';
			}

			return data;
		}

		/**
		 * Helper function to check if input is float
		 * @param input
		 * @returns {boolean}
		 */
		function isFloat(input) {
			return +input === input && (!isFinite(input) || Boolean(input % 1));
		}

		/**
		 * Helper function to check if input is really a special character
		 * @param input
		 * @returns {boolean}
		 */
		function isSpecialChar(input) {
			return specialChars[input] !== undefined;
		}

		/**
		 * Helper function to get what the special character was supposed to be
		 * since Angular escapes the first backslash
		 * @param input
		 * @returns {special character string}
		 */
		function getSpecialChar(input) {
			return specialChars[input];
		}
	}

})();
