import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { hexToRgbA } from '@worldfavor/constants/colors'
import MultiLineTextEllipsis from './Text/MultiLineTextEllipsis'
import GradientOverlay from '@worldfavor/components/GradientOverlay'

import Paper from '@material-ui/core/Paper'

import Image from '@worldfavor/components/Image'
import Images from '@worldfavor/assets/Images'

type Props = {
  title: string,
  subtitle?: string,
  description?: string,
  imageUrl?: string,
  backgroundImageUrl?: string,
  thumbnailImageUrl?: string,
  buttonLabel?: string | React.Node,
}

const styles = theme => ({
  paper: {
    height: '100%',
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '25px 35px',
    minHeight: 350,
    height: '100%',
  },
  content: {
    width: '100%',
  },
  thumbnailImage: {
    width: 85,
    height: 85,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 15,
    borderRadius: 3,
  },
  headerWrapper: {
    textAlign: 'center',
    marginBottom: 15,
  },
  title: {
    fontSize: 22,
    fontWeight: 500,
    color: theme.palette.common.white,
  },
  subtitle: {
    color: hexToRgbA(theme.palette.common.white, 0.54),
    marginTop: 5,
  },
  description: {
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  gradientOverlay: {
    borderRadius: 3,
  },
})

class NetworkCard extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      title,
      subtitle,
      description,
      thumbnailImageUrl,
      backgroundImageUrl,
      children,
      dispatch,
      ...rest
    } = this.props

    return (
      <Paper classes={{ root: classes.paper }} {...rest}>
        <Image
          src={backgroundImageUrl}
          size={'cover'}
          style={{ borderRadius: 3, height: '100%' }}
          overlay={<GradientOverlay className={classes.gradientOverlay} />}
        >
          <div className={classes.cardWrapper}>
            <div className={classes.content}>
              {
                thumbnailImageUrl && (
                  <div className={classes.thumbnailImage}>
                    <Image src={thumbnailImageUrl} className={classes.thumbnailImage} size={'cover'} />
                  </div>
                )
              }
              <div className={classes.headerWrapper}>
                <div className={classes.title}>{title}</div>
                { subtitle && <div className={classes.subtitle}>{subtitle}</div> }
              </div>
              {
                description && (
                  <div className={classes.description}>
                    <MultiLineTextEllipsis lines={4} className={classes.description} hideReadMore>
                      {description}
                    </MultiLineTextEllipsis>
                  </div>
                )
              }
            </div>
            { children }
          </div>
        </Image>
      </Paper>
    )
  }
}

NetworkCard.defaultProps = {
  backgroundImageUrl: Images.defaultCoverImage,
}

export default withStyles(styles)(NetworkCard)
