import { getSsoSpecificationFromEmail } from '@worldfavor/portal/scripts/wf/authentication/enterpriseLogin'

(function () {
	'use strict';

	angular
		.module('wf.data')
		.controller('WfMainController', WfMainController);

	WfMainController.$inject = ['dataOperationsService','wfObject','$scope','$rootScope','store','$state','$location','$timeout','dataModeller','communicationHub','DS','screenLoaderService','$translate', 'wfAuth']; // ,'signalR'

	function WfMainController(dataOps, wfObject, $scope, $rootScope, store, $state, $location, $timeout, dataModeller, comHub, DS, screenLoader, $translate, wfAuth) { // ,signalR
		/* jshint validthis:true */
		var
			pageTitleSuffix = " - Worldfavor"
		;

		// console.log($state.get()) // Uncomment to log all registered states

		window.wf = window.wf || {};
		window.wf.showWatchers = function () {
			var digestCount = 0;
			$rootScope.countDigests = function() {
				wf.$$watchersCount = $rootScope.$$watchersCount;
				wf.$$watchers = $rootScope.$$watchers.length;
				wf.jsDataCount = wfObject.getAll().length;
				console.log('Digests: ' + (++digestCount), ' $$watchersCount: ', $rootScope.$$watchersCount, ' $watchers: ', $rootScope.$$watchers.length, ' jsData count: ', wfObject.getAll().length);
			};

			$rootScope.$watch('countDigests()');
		};
		window.wf.showWatchersAndQueries = function () {
			window.wf.showWatchers();
			window.wf.showQueries();
		};
		// window.wf.showWatchers();
		// wf.showWatchersAndQueries();
		
		// $translate.use('sv');

		$rootScope._ = _;
		$rootScope.dataModeller = dataModeller;
		$rootScope.dataOps = dataOps;
		$rootScope.comHub = comHub;
		$rootScope.root = $rootScope;
		$rootScope.store = store;
		$(document.body).removeClass('adminMode')
		$rootScope.adminMode = false;
		$rootScope.screenLoader = screenLoader;

    document.title = "Worldfavor";
		$rootScope.setPageTitle = function (title) {
      document.title = title ? title + pageTitleSuffix : "Worldfavor";
		}

		$rootScope.$watch(function () {
			var lastModified;
			// return false;
			// return wfObject.hasChanges() === true // jsdata v3;
				
			//console.time($scope.$id + " | Check modified");
			lastModified = wfObject.lastModified();
			//console.timeEnd($scope.$id + " | Check modified");
			return lastModified;
		}, function () {
			// console.log("Changed");
			//console.log(wfObject.getAll());
			$scope.$broadcast("wfObjectModified");
		});


		window.wfObject = wfObject;
		
		$rootScope.signInAsOrganization = function (data, gotoStateAfterLogin) {
			wfAuth.signIn({ organizationId: data.organization.id });

			// TODO:
			// Integrate following code in wfAuth (only used for dev)
			/*
					if ($rootScope.gotoStateAfterLogin) {
						gotoStateAfterLogin = $rootScope.gotoStateAfterLogin;
						$rootScope.gotoStateAfterLogin = undefined;
					}

					if (profile.wf_orgId > 0) {
						if (gotoStateAfterLogin) {
							$state.go(gotoStateAfterLogin.stateName, gotoStateAfterLogin.stateParams);
						}
						else
							$state.go('root');
					}
					else
						$state.go("accountOrganizations");
			*/
		}

		$rootScope.logOut = function(attemptedToState, attemptedToParams) {
			if (attemptedToState && attemptedToParams) {
				$rootScope.gotoStateAfterLogin = {
					stateName: attemptedToState.name,
					stateParams: attemptedToParams
				};
			}
			else {
				$rootScope.gotoStateAfterLogin = undefined;
			}

			var userEmail = wfAuth.getUserEmail();
			var gotoState;

			const ssoInfo = getSsoSpecificationFromEmail(userEmail);

			if (ssoInfo) {
				gotoState = ssoInfo.routeStateName;
			}
			else {
				gotoState = localStorage.getItem("login_state") || "login"; // Go to normal login page
			}

			document.location = $state.href(gotoState);
		}
	}
})();
