// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popper from '@material-ui/core/Popper'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles({
  popper: {
    zIndex: 1301,
    marginBottom: 8,
  },
  tooltip: {
    padding: 8,
    fontSize: 14,
  },
})

const MinimumFilterTooltip = (props) => {
  const { anchorEl } = props
  const classes = useStyles(props)
  return (
    <Popper
      className={classes.popper}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement={'top'}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper className={classes.tooltip} elevation={4}>
            <FormattedMessage id={'tooltip.minimumFilterRequired'} />
          </Paper>
        </Fade>
      )}
    </Popper>
  )
}

export default MinimumFilterTooltip
