(function () {
	'use strict';

	angular
		.module('wf.data')
		.service('dataModeller', dataModeller);

	dataModeller.$inject = ['$translate','$q','apiProxy','wfObject'];

	function dataModeller($translate, $q, apiProxy, wfObject) {
		var valuesByIdentifier = {
		}

		var modellersByType = {}
		var service = {
			fromObjectToArray: fromObjectToArray,
			fromArrayToObject: fromArrayToObject,
			objectSettings: {
				getCollectionModelFromItem: function (item) {
					return makeObjectSettingsCollectionModel(item, "settings")
				}
			},
			objectConditions: {
				getCollectionModelFromItem: function (item) {
					return makeObjectSettingsCollectionModel(item, "conditions")
				}
			},
			getArrayFromFilter: function (property, filter) {
				var output = _.map(wfObject.filter(filter), property)
				//console.log(output);
				return output;
			},
			setFilterValue: function (identifier, value) {
				valuesByIdentifier[identifier] = value
			},
			getFilterValue: function (identifier, key) {
				var x = valuesByIdentifier[identifier];
				if (x && key)
					return x[key];
				else
					return x;
			}
		};

		activate();

		return service;

		function fromObjectToArray(type, data) {
			if (modellersByType[type] && modellersByType[type].fromObjectToArray)
				return modellersByType[type].fromObjectToArray(data);
		}

		function fromArrayToObject(type, data) {
			if (modellersByType[type] && modellersByType[type].fromArrayToObject)
				return modellersByType[type].fromArrayToObject(data);
		}

		function makeObjectSettingsCollectionModel(item, propertyName) {
			return {
				id: null,
				type: 79,
				objectId: item.id,
				objectType: item.type,
				items: modellersByType[79].fromObjectToArray(item[propertyName])
			}
		}

		function activate()
		{
			modellersByType[79] = {
				fromObjectToArray: function (data) {
					var output = [];

					if (!data) return output;

					if (data.settingsBundle)
					{
						return [ { settingKind: "settingsBundle", value: data.settingsBundle } ];
					}

					angular.forEach(data, function (value, key) {
						var formattedValue;

						if (key == "dataRelation")
							value = "";
							
						if (key == "childrenSettings")
							return;

						if (typeof value === "object")
							formattedValue = JSON.stringify(value);
						else if (typeof value === "undefined" || value === null)
							formattedValue = "";
						else
							formattedValue = value.toString()

						output.push({ settingKind: key, value: formattedValue })
					});

					return output;
				},
				fromArrayToObject: function (data) {
					var output = {};

					for (var i = 0, len = data.length; i < data; i++) {
						output[data[i].settingKind] = data[i].value
					}

					return output;
				}
			}
		}
	}
})();