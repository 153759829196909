// @flow
import * as React from 'react'
import ItemSummary from './ItemSummary'
import compareDesc from 'date-fns/compare_desc'
import type { Item } from '@worldfavor/types'

type Props ={
  subItems: Array<Item>,
  dataRelation: Item,
  style?: { [string]: any },
}

class ItemSummaryList extends React.PureComponent<Props> {

  static defaultProps = {
    style: {},
  }

  render() {
    const { subItems, dataRelation: { wfcid: id }, style } = this.props
    return (
      <div style={{ maxHeight: 230, overflowY: 'auto', ...style }}>
        {
          subItems.sort((a, b) => compareDesc(a.createdAt, b.createdAt)).map(item => (
            <ItemSummary
              key={`subItems-${id}-${item.wfid}`}
              item={item}
              style={{ paddingTop: 5, paddingBottom: 10 }}
            />
          ))
        }
      </div>
    )
  }
}

export default ItemSummaryList
