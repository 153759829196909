import * as enums from '@worldfavor/constants/enums'

(function() {
'use strict';

	angular
		.module('wf.common')
		.controller('ValueChainMailSettingsController', ValueChainMailSettingsController);

	ValueChainMailSettingsController.$inject = [ '$scope', 'dataOperationsService', 'modalService', 'wfObject', '$translate', '$timeout', '$stateParams', '$rootScope', '$window', 'valueChainService', '$state', '$q', 'apiProxy' ];
	function ValueChainMailSettingsController($scope, dataOps, modal, wfObject, $translate, $timeout, $stateParams, $rootScope, $window, valueChainService, $state, $q, apiProxy) {
		var
			vm = this,
			permissions,
			structureId, // The e-mail settings structure. Different depending on External/Internal Data Collector. It doesn't contain the actual MailSettings, they are fetched in a seperate request
			getterConditions, // Different depending on External/Internal Data Collector. Used when requesting the actual MailSettings to define networkId (if External VC)
			preTitle
		;

		_.assign(vm, {
			window: $window,
			showDropdown: true,
			pageHeaderPreTitleItem: undefined,
			itemCompilers: {},
			mailPurposeTitle: {
				1: $translate.instant("modules.mailSettings.Notification"),
				2: $translate.instant("modules.mailSettings.ValueChainInvitation"),
				3: $translate.instant("modules.mailSettings.ValueChainReminder")
			},

			//Functions
			getWeekday: getWeekday,
			editMailSetting: editMailSetting,
			editTaskInformationTexts: editTaskInformationTexts,
		});

		activate();

		function activate() {
			if ($stateParams.isInternalValueChain) {
				structureId = valueChainService.ids.internalVCMailSettingsStructure;
				getterConditions = undefined;

				loadMailSettings();
			}
			else {
				preTitle = "Data Collector";
				vm.pageHeaderPreTitleItem = { title: preTitle };
				valueChainService.loadNetworks().then(function (result) {
					var network = result.networksById[$stateParams.networkId];
					vm.pageHeaderItem = {
						title: $translate.instant("modules.mailSettings.pageHeader"),
						description: ""
					};
					
					$rootScope.setPageTitle(vm.pageHeaderItem.title);

					if (!network) {
						$state.go("valueChain-root");
						return
					}

					vm.network = network;
					structureId = valueChainService.ids.mailSettingsStructure;
					getterConditions = { networkId: network.id }

					loadMailSettings();
				});
			}
		}

		function loadMailSettings() {
			dataOps.getObject({
				objectType: enums.objectType.structure,
				objectId: structureId // The e-mail settings structure. It doesn't contain the actual MailSettings, they are fetched below
			}).then(function (res) {
				vm.mainStructure = res;

				if ($stateParams.isInternalValueChain) {
					vm.pageHeaderPreTitleItem = wfObject.get("71-" + valueChainService.ids.internalValueChainRootStructure);
				}

				// Get the MailSettings for the network or Internal Data Collector
				dataOps.getObjects({
					objectType: enums.objectType.mailSettings,
					wrapInRelations: false,
					getterConditions: getterConditions
				}).then(function (mailSettings) {
					vm.items = mailSettings;
					vm.loaded = true;
					vm.itemCompilers = _.chain(vm.items).keyBy("wfid").mapValues(function () { return {} }).value();

					// Check permissions
					permissions = vm.mainStructure.permissions
					if (permissions) {
						vm.showDropdown = permissions.canUpdate;
					}

					$timeout();
				});
			});
		}

		function getWeekday(dayOfWeek) {
			return moment.weekdays(dayOfWeek);
		}

		function editMailSetting(mailSetting) {
			modal.edit(mailSetting, {
				customFormSpecification: {
					form: [
						{
							type: "template",
							templateUrl: "scripts/wf/valueChain/mailSettingsFormPlaceholders.template.html",
							hideTimeParams: true,
							vm: vm
						},
						"fromEmail",
						"fromName",
						"replyEmail",
						"subject",
						mailSetting.mailPurpose === enums.mailPurpose.valueChainReminder && {
							key: "dayOfWeek",
							type: 'select',
							titleMap: (function () {
								// From Moment.js docs:
								// Absent the locale specific parameter (like moment.weekdays(true)), weekdays always have Sunday as index 0, regardless of the local first day of the week.
								var weekdays = moment.weekdays(), output;

								output = _.map(weekdays, function (weekday, index) {
									return {
										value: index,
										name: weekday
									}
								});

								// Move Sunday to the end of the array
								output.push(output.shift());

								return output;
							})()
						},
						{
							key: "hourOfDay",
							type: 'select',
							titleMap: [
								{ value: 0, name: "00:00 UTC" },
								{ value: 1, name: "01:00 UTC" },
								{ value: 2, name: "02:00 UTC" },
								{ value: 3, name: "03:00 UTC" },
								{ value: 4, name: "04:00 UTC" },
								{ value: 5, name: "05:00 UTC" },
								{ value: 6, name: "06:00 UTC" },
								{ value: 7, name: "07:00 UTC" },
								{ value: 8, name: "08:00 UTC" },
								{ value: 9, name: "09:00 UTC" },
								{ value: 10, name: "10:00 UTC" },
								{ value: 11, name: "11:00 UTC" },
								{ value: 12, name: "12:00 UTC" },
								{ value: 13, name: "13:00 UTC" },
								{ value: 14, name: "14:00 UTC" },
								{ value: 15, name: "15:00 UTC" },
								{ value: 16, name: "16:00 UTC" },
								{ value: 17, name: "17:00 UTC" },
								{ value: 18, name: "18:00 UTC" },
								{ value: 19, name: "19:00 UTC" },
								{ value: 20, name: "20:00 UTC" },
								{ value: 21, name: "21:00 UTC" },
								{ value: 22, name: "22:00 UTC" },
								{ value: 23, name: "23:00 UTC" },
							]
						},
						"helloText",
						"bodyText",
						"callToActionText",
						"actionButtonText",
						"endingText",
						"regardsText",
						"senderLineOne",
						"senderLineTwo",
						"active"
					]
				}
			}).then(function (res) {
				vm.itemCompilers[mailSetting.wfid].compile();
			});
		}

		function editTaskInformationTexts(mailSetting) {
			var
				valueChainMailTaskStuctures,
				schema = {
					"type": "object",
					"format": "multilingualObject",
					"properties": {
						"timeAgoInformation": {
							"title": $translate.instant("modules.mailSettings.valueChainReminderMessages.messagePreset.timeAgoInfo"),
							"validationMessage": $translate.instant("modules.mailSettings.valueChainReminderMessages.validationMessage", { message: $translate.instant("modules.mailSettings.valueChainReminderMessages.timeAgoInfo").toLowerCase() }),
							"format": "multilingual",
							"type": "string",
							"x-schema-form": { type: 'textarea' }
						},
						"timeLeftWarning": {
							"title": $translate.instant("modules.mailSettings.valueChainReminderMessages.messagePreset.timeLeftWarning"),
							"format": "multilingual",
							"validationMessage": $translate.instant("modules.mailSettings.valueChainReminderMessages.validationMessage", { message: $translate.instant("modules.mailSettings.valueChainReminderMessages.timeLeftWarning").toLowerCase() }),
							"type": "string",
							"x-schema-form": { type: 'textarea' }
						},
						"sameDayWarning": {
							"title": $translate.instant("modules.mailSettings.valueChainReminderMessages.messagePreset.sameDayWarning"),
							"format": "multilingual",
							"validationMessage": $translate.instant("modules.mailSettings.valueChainReminderMessages.validationMessage", { message: $translate.instant("modules.mailSettings.valueChainReminderMessages.sameDayWarning").toLowerCase() }),
							"type": "string",
							"x-schema-form": { type: 'textarea' }
						},
						"overdueWarning": {
							"title": $translate.instant("modules.mailSettings.valueChainReminderMessages.messagePreset.overdueWarning"),
							"format": "multilingual",
							"validationMessage": $translate.instant("modules.mailSettings.valueChainReminderMessages.validationMessage", { message: $translate.instant("modules.mailSettings.valueChainReminderMessages.overdueWarning").toLowerCase() }),
							"type": "string",
							"x-schema-form": { type: 'textarea' }
						}
					},
					// "required": [ "timeAgoInformation", "timeLeftWarning", "sameDayWarning", "overdueWarning" ]
				},
				model = {
					type: 71,
					"timeAgoInformation": { enum: enums.valueChainMailTaskStatusMessagePreset.timeAgoInformation },
					"timeLeftWarning": { enum: enums.valueChainMailTaskStatusMessagePreset.timeLeftWarning },
					"sameDayWarning": { enum: enums.valueChainMailTaskStatusMessagePreset.sameDayWarning },
					"overdueWarning": { enum: enums.valueChainMailTaskStatusMessagePreset.overdueWarning }
				}
			;

			if (vm.loadingReminderTextsForm) return;

			vm.loadingReminderTextsForm = true;
			apiProxy("mail.getCustomReminderMailTaskTexts", dataOps.prepareWfObject(mailSetting)).then(function(res) {
				if (res) {
					var fieldKey = undefined;
					valueChainMailTaskStuctures = _.map(res, "childContent");

					if (valueChainMailTaskStuctures && valueChainMailTaskStuctures.length) {
						_.each(valueChainMailTaskStuctures, function(task) {
							fieldKey = _.findKey(model, { "enum": task.conditions.valueChainMailTaskStatusMessageSettings.messagePreset });

							if (fieldKey)
								model[fieldKey] = _.assign({ wfid: task.wfid }, task.title);
						});

						vm.loadingReminderTextsForm = false;
						modal.edit(model, {
							// limitLanguages: [ "en", "sv" ],
							replaceFormSpecification: true,
							requireAllLanguages: false,
							windowClass: "modal-width-800",
							titleTranslate: "modules.mailSettings.valueChainReminderMessages.editFormtitle",
							customFormSpecification: {
								isMultilingual: true,
								schema: schema,
								multilingualSchema: schema,
								multilingualProperties: [
									"timeAgoInformation",
									"timeLeftWarning",
									"sameDayWarning",
									"overdueWarning"
								],
								form: [
									{
										type: "template",
										templateUrl: "scripts/wf/valueChain/mailSettingsFormPlaceholders.template.html",
										hideRecipientPersonParams: true,
										vm: vm
									},
									{
										key: "timeAgoInformation",
										htmlClass: "custom-email-text-reminder-form"
									},
									{
										key: "timeLeftWarning",
										htmlClass: "custom-email-text-reminder-form"
									},
									{
										key: "sameDayWarning",
										htmlClass: "custom-email-text-reminder-form"
									},
									{
										key: "overdueWarning",
										htmlClass: "custom-email-text-reminder-form"
									}
								],
							},
							action: function(model) {
								var promises = [];
								_.each(model, function(property, key) {
									if (key === "timeAgoInformation" || key === "timeLeftWarning" || key === "sameDayWarning" || key === "overdueWarning")
										promises.push(saveValueChainReminderMessage(_.find(valueChainMailTaskStuctures, { "wfid": property.wfid }), property));
								});

								return $q.all(promises);
							}
						}).then(function (res) {

						});
					}
					else
						console.error("ValueChain Mail Tasks not defined");
				}
				else
					console.error("Could not load subItems of children for mailSettings");
			});

			function saveValueChainReminderMessage(itemToUpdate, modelValues) {
				var deferred = $q.defer();
				var newTitleObject = _.clone(modelValues);
				delete newTitleObject.wfid;

				if (itemToUpdate) {
					if (typeof itemToUpdate.title === "object")
						_.assign(itemToUpdate.title, newTitleObject);
					else
						itemToUpdate.title = newTitleObject;

					dataOps.update(itemToUpdate).then(function(res) {
						// console.log("Saving item - ", itemToUpdate);
						deferred.resolve(res);
					});
				}

				return deferred.promise;
			}
		}
	}
})();
