function ctPoint(options) {
  const Chartist = require('chartist')

  const getCircleShape = (data, options) => {
    return Chartist.Svg('circle', {
      cx: data.x,
      cy: data.y,
      r: options.circleShapeOptions.radius,
      stroke: data.series.color,
      'ct:value': data.value.y,
      'ct:meta': data.meta,
    }, options.circleShapeOptions.className)
  }

  // eslint-disable-next-line
  const getSquareShape = (data, options) => {

  }

  return function ctPoint(chart) {
    const defaultOptions = {
      shape: 'circle',
      circleShapeOptions: {
        radius: 6,
        className: 'ct-custom-point',
        enableAnimations: false,
        fillColor: 'white',
      },
      squareShapeOptions: {

      },
    }

    options = Chartist.extend({}, defaultOptions, options)

    let numberOfDataPoints = undefined
    if (chart instanceof Chartist.Line) {
      numberOfDataPoints = chart.data.labels.length
      chart.on('draw', (data) => {
        if (data.type === 'point') {
          if (options.shape === 'circle') {
            if ((data.axisX.stepLength / numberOfDataPoints) < (options.circleShapeOptions.radius * 2)) {
              options.circleShapeOptions.radius = 4
            }

            //animate
            if (options.circleShapeOptions.enableAnimations) {
              data.element.animate({
                cy: {
                  begin: 0,
                  dur: 500,
                  from: data.axisY.chartRect.y1,
                  to: data.y,
                  easing: Chartist.Svg.Easing.easeInOutSine,
                },
              })
            }
            data.element.replace(getCircleShape(data, options))
            data.group.attr({ style: 'fill: ' + options.circleShapeOptions.fillColor + ' !important;' })
          }
          data.x = data.x + (data.axisX.stepLength / 2.0)
        }
      })
    }
  }
}

export default ctPoint
