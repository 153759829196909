import * as enums from '@worldfavor/constants/enums'

(function () {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfMeasureAnswering', wfMeasureAnswering)
		.service('wfMeasureService', wfMeasureService)
	;
	wfMeasureAnswering.$inject = [ '$parse', 'dataQuery', 'dataOperationsService', 'moment', 'modalService', 'wfAuth', 'requirements', 'wfMeasureService', '$translate', '$ngBootbox', '$sanitize'];

	function wfMeasureAnswering($parse, dataQuery, dataOps, moment, modal, wfAuth, requirementService, wfMeasureService, $translate, $ngBootbox, $sanitize) {
		var directive = {
			restrict: 'EA',
			require: '?^^wfMeasureAnsweringManager',
			templateUrl: 'scripts/wf/answering/wfMeasureAnswering.directive.html',
			link: link,
			controllerAs: 'measureAnsweringVm',
			controller: [ '$scope', '$element', '$attrs', wfMeasureAnsweringController ]
		};

		return directive;

		function wfMeasureAnsweringController(scope, element, attrs) {
			var
				measureAnsweringVm = this,
				measureDataRelation,
				measure
			;

			if ("itemRelation" in attrs && "itemContent" in attrs) {
				measure = measureAnsweringVm.measure = $parse(attrs.itemContent)(scope);
				measureAnsweringVm.measureDataRelation = $parse(attrs.itemRelation)(scope);
				if (!measure || (measure.type !== enums.objectType.measure && measure.type !== enums.objectType.relativeMeasure))
					return;
			}
			else {
				if (attrs.item)
					measureDataRelation = $parse(attrs.item)(scope);
				else
					measureDataRelation = scope.vm.item;

				if (measureDataRelation.childType !== enums.objectType.measure && measureDataRelation.childType !== enums.objectType.relativeMeasure) return;


				measureAnsweringVm.item = measureAnsweringVm.measureDataRelation = measureDataRelation;
				measure = measureAnsweringVm.measure = measureDataRelation.childContent;
			}

			if (scope.vm && scope.vm.context && scope.vm.context.aggregateMeasureAnswers) {
				measureAnsweringVm.stats = measure.filterChildren({ type: 86, networkId: scope.vm.context.networkId, aggregate: 1, wffid: measure.wfid })[0];
			}

			//Show/Hide Add data button
			measureAnsweringVm.showAddButton = measure.type === enums.objectType.relativeMeasure ? false : true;
		}

		function link(scope, element, attrs, wfMeasureAnsweringManagerCtrl) {
			var
				measureAnsweringVm = scope.measureAnsweringVm,
				measure,
				measureDataRelation,
				itemComposite,
				requirement,
				localFulfillmentElement,
				fulfillsLocally,
				validValues,
				settings,
				latestAnswerId,
				busy,
				organizationId,
				networkId,
				influence,
				manager = wfMeasureAnsweringManagerCtrl,
				item,
				displayMode = null,
				isViewMode = false,
				verification,
				measureAnswerElement = element.children("div.measure-answer"),
				displayedAnswerElement = measureAnswerElement.children("div.value"),
				answerTrendElement = measureAnswerElement.children("div.trend"),
				context,
				contextParentWfids,
				latestAnswerContent,
				intersectionSettings,
				ticket,
				fulfillmentSpec = {}
			;

			if (!measureAnsweringVm || !measureAnsweringVm.measureDataRelation || !measureAnsweringVm.measure || (measureAnsweringVm.measure.type !== enums.objectType.measure && measureAnsweringVm.measure.type !== enums.objectType.relativeMeasure)) return;

			item = measureDataRelation = measureAnsweringVm.measureDataRelation;
			measureAnsweringVm.setSelectedAnswer = setSelectedAnswer;

			measure = measureAnsweringVm.measure;
			settings = item.settings;

			// If intersected in back-end then the originalRelation is on the questionDataRelation (that is a virtualDataRelation)
			if (item.type == enums.objectType.virtualDataRelation && typeof item.originalRelationWfid === "string") {
				settings = item.originalRelation.settings;
			}

			if (attrs.mode)
				displayMode = attrs.mode;

			if (attrs.uiMode && $parse(attrs.uiMode)(scope) === enums.uiMode.view)
				displayMode = "view";

			if (attrs.verification)
				verification = $parse(attrs.verification)(scope);

			if ("itemComposite" in attrs)
				itemComposite = $parse(attrs.itemComposite)(scope);

			// If intersected in front-end then the originalRelation is on the itemComposite
			if (itemComposite && itemComposite.originalRelation && itemComposite.originalRelation.settings && itemComposite.originalRelation.settings.contextParentWfids) {
				contextParentWfids = itemComposite.originalRelation.settings.contextParentWfids;
			}
			else if (settings) {
				// Else, use the settings object that we already have
				contextParentWfids = settings.contextParentWfids;
			}

			measureAnsweringVm.context = context = (scope.vm ? scope.vm.context : {}) || {};
			measureAnsweringVm.addRelatedContentStatementOnAnswer = addRelatedContentStatementOnAnswer;
			measureAnsweringVm.addRelatedContentOnAnswer = addRelatedContentOnAnswer;
			measureAnsweringVm.onRelatedContentRemoved = onRelatedContentRemoved;
			measureAnsweringVm.updateLatestAnswer = updateLatestAnswer;

			if (!context.aggregateMeasureAnswers) {
				if (!wfAuth.canUserWrite())
					isViewMode = true;

				if (context && context.isViewMode)
				{
					isViewMode = context.isViewMode;
				}

				if (context && context.influence)
				{
					influence = measureAnsweringVm.influence = context.influence;
					organizationId = influence.organizationId;
					networkId = influence.channelId;
					getRequirement();

					// scope.allAnswers = dataQuery.getAll.intersectedChildrenOf(
					// 	[ meausre, { 'childContent.organizationId': organizationId } ],
					// 	// wfObject.get('52-' + influence.channelId)
					// );
				}
				else if ("influence" in attrs && (influence = $parse(attrs.influence)(scope))) {
					measureAnsweringVm.influence = influence;
					organizationId = influence.organizationId;
					networkId = influence.channelId;
					getRequirement();
				}
				else if ("ticket" in attrs) {
					ticket =  $parse(attrs.ticket)(scope);
					organizationId = ticket.organizationId;
					networkId = ticket.networkId;

					if (ticket.contextParentWfid)
						contextParentWfids = [ ticket.contextParentWfid ];
				}
				else if (context && context.verification)
				{
					organizationId = context.verification.organizationId;
				}
				else
				{
					organizationId = wfAuth.getOrganizationId() || 0;
				}

				if (!requirement && itemComposite) {
					getRequirement();
				}

				if (itemComposite) {
					scope.$on("requirementChanged", function (event, updatedRequirement) {
						if (updatedRequirement && measureDataRelation && updatedRequirement.wffid_requirement !== measureDataRelation.wfid)
							return;

						getRequirement();

						if (requirement)
							fulfillsLocally = answersMatchesRequirement(latestAnswerContent, validValues, localFulfillmentElement, requirement)
						else
							fulfillsLocally = null;

						// console.log(requirement, fulfillsLocally);
						scope.$broadcast("checkLocalFulfillment", measure, { latestAnswerContent: latestAnswerContent, intersectionSettings: intersectionSettings, onChecked: function (fulfillmentResult) {
							fulfillsLocally = fulfillmentResult.fulfillmentState === enums.fulfillmentState.assessmentNeeded ? true : fulfillmentResult.actuallyFulfills;
						} });

						if (manager)
						{
							manager.update(measure.wfid, {
								fulfillsLocally: fulfillsLocally,
								requirement: requirement
							});
						}
					});
				}

				if (influence) {
					answerTrendElement.remove();
				}

				if (measureDataRelation && measureDataRelation.settings && measureDataRelation.settings.contextParentWfids) {
					contextParentWfids = measureDataRelation.settings.contextParentWfids;
				}

				if (influence && !contextParentWfids && influence.contextParentWfids)
					contextParentWfids = influence.contextParentWfids.split(",");

				intersectionSettings = {
					organizationId: organizationId,
					contextParents: contextParentWfids,
					networkId: networkId
				};

				// intersectSource.push([ measure, { 'childContent.organizationId': organizationId } ]);

				// measureAnsweringVm.allAnswers = dataQuery.getAll.intersectedChildrenOf.apply(this, intersectSource);
				scope.isMeasure = true;

				updateLatestAnswer(true);

				// if (measureAnsweringVm.allAnswers.length) {
				// 	if (context && context.verification)
				// 	{
				// 		measureAnsweringVm.allAnswers = dataQuery.getAll.intersectedVerifiedBy(measureAnsweringVm.allAnswers, context.verification);
				// 	}

				// 	// measureAnsweringVm.allAnswers = _.orderBy(measureAnsweringVm.allAnswers, [ 'createdAt' ], [ 'desc' ]);
				// 	measureAnsweringVm.allAnswers = _.orderBy(measureAnsweringVm.allAnswers, [ function (a) {
				// 		return a.childContent.year;
				// 	}, 'createdAt' ], [ 'desc', 'desc' ]);

				// 	latestAnswerId = measureAnsweringVm.allAnswers[0];

				// 	displayLatestAnswer({
				// 		scope: scope,
				// 		measure: measure,
				// 		organizationId: organizationId,
				// 		displayedAnswerElement: displayedAnswerElement,
				// 		answerTrendElement: answerTrendElement,
				// 		updateChart: false,

				// 		networkId: networkId,
				// 		contextParents: contextParentWfids,
				// 		dataQuery: dataQuery
				// 	});
				// 	// displayLatestAnswer(scope, measure, organizationId, displayedAnswerElement, answerTrendElement, false, dataQuery);
				// 	var x = dataQuery.get.latestAnswerOnMeasure(measure, {
				// 		networkId: networkId,
				// 		organizationId: organizationId,
				// 		getTop: 2,
				// 		contextParents: measureDataRelation && measureDataRelation.settings ? measureDataRelation.settings.contextParentWfids : undefined
				// 	});
				// 	console.log((x ? (x.year + ": " + x.value + " " + x.childContent.symbol, x) : null), x);
				// }

				if (wfAuth.getOrganizationId() == organizationId && displayMode !== 'view' && !isViewMode)
				{
					scope.isByAutheticatedOrg = true;
					element.on('click', 'button.btn', function() {
						var
							btn = $(this),
							initialValues = {}
						;

						if (busy) return;
						busy = true;

						btn.addClass("loading");
						setTimeout(function (params) {
							btn.removeClass("loading");
							busy = false;
						}, 1000);

						if (requirement) {
							if (requirement.value)
								initialValues.year = requirement.value;
						}

						const fulfillmentResult = requirementService.checkLocalFulfillment(measure, measureDataRelation, requirement, intersectionSettings, { useDetailedResult: true });

						var measureCreatorOptions = {
							additionalPropertiesForInitialValues: { year: initialValues.year },
							influence: influence,
							networkId: networkId,
							contextParents: contextParentWfids,
							intersectionSettings: intersectionSettings,
							showAttachInformation: requirement && (requirement.rule === enums.requirementRule.anyValueWithRelatedContent || requirement.rule === enums.requirementRule.inPeriodWithRelatedContent || requirement.rule === enums.requirementRule.inPeriodWithRelatedContentExceptIfNA || requirement.rule === enums.requirementRule.anyValueWithRelatedContentExceptIfNA || requirement.rule === enums.requirementRule.inPeriodWithRelatedContentIfNA ),
							fulfillmentResult: fulfillmentResult,
						};

						modal.openMeasureAnswerCreator(measureDataRelation, measure, measureCreatorOptions).then(function (res) {
							if (typeof res === "object") {
								latestAnswerId = res.childId;

								if (requirement) {
									fulfillsLocally = answersMatchesRequirement(res.childContent, validValues, localFulfillmentElement, requirement);
									scope.$emit("checkLocalFulfillment", measure, { latestAnswerContent: latestAnswerContent, intersectionSettings: intersectionSettings, onChecked: function (fulfillmentResult) {
										fulfillsLocally = fulfillmentResult.fulfillmentState === enums.fulfillmentState.assessmentNeeded ? true : fulfillmentResult.actuallyFulfills
										// manager.update(measure.wfid, {
										// 	fulfillsLocally: fulfillsLocally,
										// 	latestAnswerId: latestAnswerId
										// });
									} });
								}

								if (manager) {
									manager.update(measure.wfid, {
										fulfillsLocally: fulfillsLocally,
										latestAnswerId: latestAnswerId
									});
								}
								else
									scope.$emit("measureAnswerChanged", measure);

								scope.$emit("newAnswerCreated", measure);
								updateLatestAnswer();
							}
						});
					});
				}
				else
				{
					element.find("button.btn").remove();
				}

				measureAnsweringVm.isViewMode = isViewMode;
				// else
				// {
				// 	scope.isByAutheticatedOrg = false;
				// 	element.addClass('view');
				// 	if (latestAnswerContent)
				// 	{
				// 		latestAnswerBtn.removeClass('btn btn-primary btn-white btn-sm').addClass('text-bold').siblings().remove();
				// 		latestAnswerBtn.width('100%');
				// 	}
				// 	else
				// 		element.html('').append($('<div />').text('?').addClass('text-bold'));
				// }
				if (scope.activateMeasureAnsweringHistory) {
					scope.activateMeasureAnsweringHistory();
				}
			}

			if (requirement) {
				// localFulfillmentElement = element.find('div.localFulfillment');
				// validValues = requirement.value.toString().split(',');

				// fulfillsLocally = answersMatchesRequirement(latestAnswerContent, validValues, localFulfillmentElement, requirement)
				// scope.$emit("checkLocalFulfillment", measure, { latestAnswerContent: latestAnswerContent });
			}

			if (manager) {
				manager.insert(measure.wfid, {
					requirement: requirement,
					fulfillsLocally: fulfillsLocally,
					latestAnswerId: latestAnswerId,
					measureDataRelation: measureDataRelation,
					measure: measure,
					itemComposite: itemComposite
				});
			}

			function getRequirement() {
				return requirement = measureAnsweringVm.actualRequirement = requirementService.getActualRequirement({
					itemContent: measure,
					itemRelation: measureDataRelation,
					itemComposite: itemComposite,
					organizationId: organizationId
				});
			}

			function setSelectedAnswer(answerDataRelation, answerContent, orderedLatestAnswers, initial) {
				displayLatestAnswer({
					orderedLatestAnswers: orderedLatestAnswers,
					scope: scope,
					measure: measure,
					organizationId: organizationId,
					displayedAnswerElement: displayedAnswerElement,
					answerTrendElement: answerTrendElement,
					updateChart: true,

					networkId: networkId,
					contextParents: contextParentWfids,
					dataQuery: dataQuery
				});

				if (answerContent)
					latestAnswerId = answerContent.id;
				else if (answerDataRelation)
					latestAnswerId = answerDataRelation.childId;
				else
					latestAnswerId = undefined;

				if (requirement) {
					fulfillsLocally = answersMatchesRequirement(latestAnswerContent, validValues, localFulfillmentElement, requirement);
					scope.$emit("checkLocalFulfillment", measure, { latestAnswerContent: latestAnswerContent, intersectionSettings: intersectionSettings, initial: false, onChecked: function (fulfillmentResult) {
						fulfillsLocally = fulfillmentResult.fulfillmentState === enums.fulfillmentState.assessmentNeeded ? true : fulfillmentResult.actuallyFulfills
					} });
				}

				if (manager)
				{
					// When initial is true, "measureAnswerChanged" will not be emitted/broadcasted
					manager.update(measure.wfid, {
						fulfillsLocally: fulfillsLocally,
						latestAnswerId: latestAnswerId
					}, initial);
				}
			}

			function updateLatestAnswer(initial) {
				var orderedLatestAnswers;
				// var latestAnswerDataRelation;
				// intersectSource.push([ measure, { 'childContent.organizationId': organizationId } ]);

				// measureAnsweringVm.allAnswers = dataQuery.getAll.intersectedChildrenOf.apply(this, intersectSource);
				scope.isMeasure = true;

				// if (measureAnsweringVm.allAnswers.length) {
				// 	if (context && context.verification)
				// 	{
				// 		measureAnsweringVm.allAnswers = dataQuery.getAll.intersectedVerifiedBy(measureAnsweringVm.allAnswers, context.verification);
				// 	}

				// 	measureAnsweringVm.allAnswers = _.orderBy(measureAnsweringVm.allAnswers, [ function (a) {
				// 		return a.childContent.year;
				// 	}, 'createdAt' ], [ 'desc', 'desc' ]);

				// 	latestAnswerDataRelation = measureAnsweringVm.allAnswers[0];

					orderedLatestAnswers = dataQuery.get.latestAnswerOnMeasure(measure, {
						networkId: networkId,
						organizationId: organizationId,
						take: 2,
						contextParents: contextParentWfids
					});

					latestAnswerContent = measureAnsweringVm.latestAnswerContent = orderedLatestAnswers[orderedLatestAnswers.length - 1];

					setSelectedAnswer(null, latestAnswerContent, orderedLatestAnswers, initial);
				// }
			}

			function updateLatestAnswerRelatedContent() {
				if (latestAnswerContent)
					measureAnsweringVm.latestAnswerRelatedContent = latestAnswerContent.filterRelatedContentByUser({ organizationId: organizationId, wfxpid: _.get(contextParentWfids, "[0]") });
				else
					measureAnsweringVm.latestAnswerRelatedContent = [];
			}

			function addRelatedContentStatementOnAnswer(options) {
				if (!latestAnswerContent)
					return;

				modal.openCreatorAndPicker({
					influence: influence,
					pick: false,
					objectTypes: [ 44 ],
					// compilerControl: vm.listControls[vm.mainStructure.wfid],
					relationTarget: { item: latestAnswerContent, kind: 5 },
					title: $translate.instant("modules.valueChain.influence.addComment"),
					noFormHeader: true,
					submitCaption: $translate.instant("Add"),
					intersection: {
						contextParents: contextParentWfids,
						organizationId: organizationId,
						networkId: networkId
					}
				}).modal.closed.then(function () {
					updateLatestAnswerRelatedContent();

					fulfillsLocally = answersMatchesRequirement(latestAnswerContent, validValues, localFulfillmentElement, requirement, fulfillmentSpec, measureDataRelation)
					// scope.$emit("localFulfillmentChanged", measure, fulfillmentSpec.state);
					scope.$emit("checkLocalFulfillment", measure, { latestAnswerContent: latestAnswerContent, intersectionSettings: intersectionSettings, onChecked: function (fulfillmentResult) {
						fulfillsLocally = fulfillmentResult.fulfillmentState === enums.fulfillmentState.assessmentNeeded ? true : fulfillmentResult.actuallyFulfills
					} });

					if (manager)
					{
						manager.update(measure.wfid, {
							fulfillsLocally: fulfillsLocally,
							latestAnswerId: latestAnswerId
						});
					}

					if (options && options.compiler)
						options.compiler.compile();
				});
			}

			function addRelatedContentOnAnswer(options) {
				var
					message,
					hasRequirement = requirement && (requirement.rule == enums.requirementRule.preferredValueWithRelatedContent
						|| requirement.rule == enums.requirementRule.anyValueWithRelatedContent
						|| requirement.rule == enums.requirementRule.specificValueWithRelatedContent
						|| requirement.rule == enums.requirementRule.inPeriodWithRelatedContent
						|| requirement.rule == enums.requirementRule.inPeriodWithRelatedContentExceptIfNA
						|| requirement.rule == enums.requirementRule.anyValueWithRelatedContentExceptIfNA
						|| requirement.rule == enums.requirementRule.inPeriodWithRelatedContentIfNA
				);

				if (!latestAnswerContent) {
					message = $translate.instant("modules.valueChain.influence.haveToAnswerQuestionBeforeAddingInfo");

					$ngBootbox.customDialog({
						message: '<i class="fa fa-exclamation-circle bootbox-icon"></i><div class="bootbox-text">' + $sanitize(message) + "</div>",
						// onEscape: true,
						closeButton: false,
						className: "centerWithIcon",
						buttons: {
							primary: {
								label: $translate.instant('OK'),
								className: "btn-primary"
							}
						}
					});
					return;
				}

				modal.openCreatorAndPicker({
					influence: influence,
					pick: options.objectType === enums.objectType.certificate,
					singlePick: true,
					objectTypes: [ options.objectType ],
					// compilerControl: vm.listControls[vm.mainStructure.wfid],
					relationTarget: { item: latestAnswerContent, kind: 5 },
					title: options.modalTitle,
					noFormHeader: true,
					submitCaption: $translate.instant("Add"),
					intersection: {
						contextParents: contextParentWfids,
						organizationId: organizationId,
						networkId: networkId
					}
				}).modal.closed.then(function () {
					if (hasRequirement) {
						fulfillsLocally = answersMatchesRequirement(latestAnswerContent, validValues, localFulfillmentElement, requirement, fulfillmentSpec, measureDataRelation)
						// scope.$emit("localFulfillmentChanged", measure, fulfillmentSpec.state);
						scope.$emit("checkLocalFulfillment", measure, { latestAnswerContent: latestAnswerContent, intersectionSettings: intersectionSettings, onChecked: function (fulfillmentResult) {
							fulfillsLocally = fulfillmentResult.fulfillmentState === enums.fulfillmentState.assessmentNeeded ? true : fulfillmentResult.actuallyFulfills
						} });

						if (manager)
						{
							manager.update(measure.wfid, {
								fulfillsLocally: fulfillsLocally,
								latestAnswerId: latestAnswerId
							});
						}
					}

					if (options && options.compiler)
						options.compiler.compile();
				});
			}

			function onRelatedContentRemoved() {
				if (!latestAnswerContent.metadata)
					latestAnswerContent.metadata = { countByRelationKind: {} };

					measureAnsweringVm.latestAnswerRelatedContent = latestAnswerContent.filterRelatedContentByUser({ organizationId: organizationId, wfxpid: _.get(contextParentWfids, "[0]") });

				latestAnswerContent.metadata.countByRelationKind[enums.subItemsKind.relatedContentByUser] = measureAnsweringVm.latestAnswerRelatedContent.length;

				if (requirement) {
					fulfillsLocally = answersMatchesRequirement(latestAnswerContent, validValues, localFulfillmentElement, requirement, fulfillmentSpec, measureDataRelation);
					// scope.$emit("localFulfillmentChanged", measure, fulfillmentSpec.state);
					scope.$emit("checkLocalFulfillment", measure, { latestAnswerContent: latestAnswerContent, intersectionSettings: intersectionSettings, onChecked: function (fulfillmentResult) {
						fulfillsLocally = fulfillmentResult.fulfillmentState === enums.fulfillmentState.assessmentNeeded ? true : fulfillmentResult.actuallyFulfills
					} });
				}

				if (manager)
				{
					manager.update(measure.wfid, {
						fulfillsLocally: fulfillsLocally,
						latestAnswerId: latestAnswerId
					});
				}
			}
		}

		function answersMatchesRequirement(latestAnswerContent, validValues, element, requirement, fulfillmentSpec, measureDataRelation) {
			var fulfills = !!latestAnswerContent;// && validValues.indexOf(answer.toString()) !== -1;
			var fulfillsException = false;

			if (requirement && requirement.rule == enums.requirementRule.manual)
				fulfills = true;

			if (element) {
				// element.toggleClass("fulfillsException", fulfillsException === true);// : '<i class="fa fa-times"></i>')
				// element.toggleClass("fulfills", fulfills === true);// : '<i class="fa fa-times"></i>')
				// element.toggleClass("unfulfills", !fulfills);// : '<i class="fa fa-times"></i>')
			}

			if (fulfillmentSpec) {
				fulfillmentSpec.state = fulfillsException ? "exception" : fulfills === true;
			}

			return fulfills
		}

		function displayLatestAnswer(options) {
			var
				orderedLatestAnswers = options.orderedLatestAnswers,
				intersectSource = [],
				latestAnswerContent = orderedLatestAnswers[orderedLatestAnswers.length - 1],
				// latestAnswerContent,
				lastPeriod,
				previousPeriodAnswerContent = orderedLatestAnswers[orderedLatestAnswers.length - 2],
				lastValue,
				previousValue,
				sortedAnswers,
				jqDf = $.Deferred()
			;

			var
				scope = options.scope,
				measure = options.measure,
				organizationId,
				displayedAnswerElement = options.displayedAnswerElement,
				answerTrendElement = options.answerTrendElement,
				updateChart = options.updateChart,
				dataQuery,
				networkId,
				contextParents
			;
			// if (!scope.allAnswers)
			// {
			// 	console.log("NO", scope);
			// }
			// sortedAnswers = _.orderBy(scope.measureAnsweringVm.allAnswers, [ function (a) {
			// 	return a.childContent.year;
			// }, 'createdAt' ], [ 'desc', 'desc' ]);

			// latestAnswerContent = sortedAnswers[0];

			answerTrendElement.removeClass("up down same");
			if (latestAnswerContent)
			{
				// latestAnswerChildContent = latestAnswerContent.childContent;

				// if (orderedLatestAnswers.length > 1) {
					// lastPeriod = latestAnswerChildContent.year;
					// previousPeriodAnswer = _.find(sortedAnswers, function (o) {
					// 	return o.childContent.year < lastPeriod;
					// });

					if (previousPeriodAnswerContent) {
						lastValue = parseInt(latestAnswerContent.value);
						previousValue = parseInt(previousPeriodAnswerContent.childContent.value);
						// console.log(lastPeriod, previousPeriodAnswer, lastValue, previousValue, lastValue > previousValue)
						if (lastValue > previousValue)
							answerTrendElement.addClass("up");
						else if (lastValue < previousValue)
							answerTrendElement.addClass("down");
						else if (lastValue === previousValue)
							answerTrendElement.addClass("same");
					}
				// }

				if (!latestAnswerContent.childContent)
				{
					dataOps.getObject({
						objectType: latestAnswerContent.wfcid.split("-")[0],
						objectId: latestAnswerContent.wfcid.split("-")[1]
					}).then(function (res) {
						// displayedAnswerElement.text(latestAnswerChildContent.value + " " + res["symbol"])
						// displayedAnswerElement.prepend("<span>" + latestAnswerChildContent.year + ":</span> ")
						displayedAnswerElement.html(latestAnswerContent.getHeaderText());
						jqDf.resolve(latestAnswerContent);
					});
				}
				else
				{
					// displayedAnswerElement.text(latestAnswerChildContent.value + " " + latestAnswerChildContent.childContent["symbol"])
					displayedAnswerElement.html(latestAnswerContent.getHeaderText());//("<span>" + latestAnswerChildContent.year + ":</span> ")
					jqDf.resolve(latestAnswerContent);
				}
			}
			else
			{
				displayedAnswerElement.text("");
				jqDf.resolve();
			}

			if (updateChart && scope.vm && scope.vm.context && scope.vm.context.sunburstControl && scope.vm.context.sunburstControl.setMeasureAnswerState) {
				scope.vm.context.sunburstControl.setMeasureAnswerState(measure.wfid, latestAnswerContent)
			}

			return jqDf.promise();
		}
	}

	wfMeasureService.$inject = [ "$translate", "$q", "dataOperationsService", "$rootScope" ];
	function wfMeasureService($translate, $q, dataOps, $rootScope) {
		var
			monthNames = moment.months(),
			service = {
				getMeasureAnswerFormSpecification: getMeasureAnswerFormSpecification,
				getFullMeasureAnswerFormSpecification: getFullMeasureAnswerFormSpecification,
				generatePeriods: generatePeriods,
				getDaysInMonth: getDaysInMonth,
				formatPeriodNameFromAnswer: formatPeriodNameFromAnswer,
				groupAndFormatAnswersByPeriod: groupAndFormatAnswersByPeriod,
				getMeasureAnswerPeriodName: getMeasureAnswerPeriodName,
				formatPeriodNameFromPeriodItem: formatPeriodNameFromPeriodItem,
				formatPeriodNameFromRequirement: formatPeriodNameFromRequirement,
				getMeasurePeriodSettings: getMeasurePeriodSettings
			}
		;

		return service;

		function getMeasurePeriodSettings(item) {
			var
				dataRelation,
				measurePeriodSettings,
				periodSettingsObjectWfid
			;

			if (item.isComposite) {
				dataRelation = item.dataRelation;
			}
			else if (item.type === enums.objectType.dataRelation || item.type === enums.objectType.virtualDataRelation) {
				dataRelation = item;
			}

			if (dataRelation) {
				measurePeriodSettings = _.get(dataRelation.originalDataRelation, "settings.measurePeriodSettings")
				if (measurePeriodSettings)
					periodSettingsObjectWfid = dataRelation.originalDataRelation.wfid;
				else {
					measurePeriodSettings = _.get(dataRelation, "settings.measurePeriodSettings");
					if (measurePeriodSettings)
						periodSettingsObjectWfid = dataRelation.wfid;
				}
			}

			if (!measurePeriodSettings) {
				measurePeriodSettings = {
					frequency: enums.calendarFrequency.yearly
				};
			}

			return {
				measurePeriodSettings,
				periodSettingsObjectWfid
			}
		}

		function getFullMeasureAnswerFormSpecification(itemContent, itemRelation, form, options) {
			var
				periodRangesByIndex = {},
				periodSettingsResult = getMeasurePeriodSettings(itemRelation),
				{ measurePeriodSettings, periodSettingsObjectWfid } = periodSettingsResult,
				measureAnswerFormId = "measureAnswerForm_" + _.uniqueId()
			;

			var initialValues = {
				unitId: itemContent.unitId,
				frequency: measurePeriodSettings.frequency,
				periodSettingsObjectWfid: periodSettingsObjectWfid || null,
				measureId: itemContent.id,
				measureAnswerFormId: measureAnswerFormId,
				showNotAvailableCheckbox: measurePeriodSettings.showNotAvailableCheckbox,
				// getLatestValueInPeriod
			};

			// if (options && options.additionalPropertiesForInitialValues)
			// 	_.assign(initialValues, options.additionalPropertiesForInitialValues);

			if (form) {
				form.model = initialValues;
				form.periodRangesByIndex = periodRangesByIndex;
			}

			return getMeasureAnswerFormSpecification({
				measure: itemContent,
				measureAnswerFormId: measureAnswerFormId,
				periodSettings: measurePeriodSettings,
				periodRangesByIndex: periodRangesByIndex,
				getLatestValueInPeriod: options && options.getLatestValueInPeriod,
			});
		}

		function getMeasureAnswerFormSpecification(options) {
			var
				deferred = $q.defer(),
				output,
				periodRangesByIndex = options.periodRangesByIndex || null,
				generatedPeriods,
				periodDropdownItems,
				measure = options.measure
			;

			generatedPeriods = generatePeriods(options.periodSettings);

			periodDropdownItems = _.map(generatedPeriods, function (period, index) {
				period.value = index.toString();

				return period;
			})

			if (periodRangesByIndex) {
				_.assign(periodRangesByIndex, _.chain(periodDropdownItems).keyBy("value").mapValues(function (period) {
					return {
						period: period.startDate,
						periodEnd: period.endDate,
						nameSpecification: period.nameSpecification
					};
				}).value());
			}

			if (options.periodSettings.showUnitSelector) {
				getAllUnits().then(function(dataRelationUnits) {
					var
						units = _.chain(dataRelationUnits).map("childContent").orderBy([ "quantityType", "name" ]).value(),
						titleMap = [],
						unitObjectFromMeasure = _.find(units, { "id": measure.unit.id }),
						quantityType = undefined
					;

					if (unitObjectFromMeasure) {
						quantityType = unitObjectFromMeasure.quantityType;
						units = _.filter(units, { 'quantityType': quantityType });

						if (units && units.length) {
							_.forEach(units, function (unit) {
								titleMap.push({ value: unit.id, name: unit.name + (unit["symbol"] && unit["symbol"].length ? " (" + unit["symbol"] + ")" : "") });
							});
						}
					}

					output = {
						form: [
							{
								key: "value"
							},
							{
								key: "unitId",
								type: "select",
								name: "Units",
								titleMap: titleMap,
								onChange: function(modelValue, form) {
									var unit = _.find(units, { id: modelValue });
									if (unit)
										$rootScope.$emit('measureUnitChanged_' + options.measureAnswerFormId, unit);
								}
							},
							{
								key: "periodSelection",
								type: "select",
								readonly: options.periodSettings && options.periodSettings.lockPeriod,
								titleMap: periodDropdownItems,
								onChangeExtended: function(value, form, model) {
									getLatestValueInPeriod(value, form, model)
								},
							}
						]
					};

					deferred.resolve(output);
				});
			}
			else {
				output = {
					form: [
						{
							key: "value"
						},
						{
							key: "periodSelection",
							type: "select",
							readonly: options.periodSettings && options.periodSettings.lockPeriod,
							titleMap: periodDropdownItems,
							onChangeExtended: function(value, form, model) {
								getLatestValueInPeriod(value, form, model)
							},
						}
					]
				};

				deferred.resolve(output);
			}

			function getLatestValueInPeriod(periodIndexString, form, model) {
				if (options.getLatestValueInPeriod) {
					options.getLatestValueInPeriod(periodIndexString).then(({ value, baseUnitFactor, unit }) => {
						$rootScope.$emit('periodChanged_' + options.measureAnswerFormId, value, baseUnitFactor, unit);
					});
				}
			}

			return deferred.promise;
		}

		function getAllUnits() {
			return dataOps.getSubItems({ id: 12102, type: enums.objectType.structure }, enums.subItemsKind.children);
		}

		function generatePeriods(measurePeriodSettings, options) {
			var
				output,
				periodSettings,
				periodDropdownItems = [],
				minYear = _.get(options, "minYear") || 1980,
				maxYear = new Date().getFullYear() + 1,
				monthNames,
				halfYearWord = $translate.instant("calendarFrequency.halfYear")
			;

			periodSettings = measurePeriodSettings;

			if (periodSettings.frequency === enums.calendarFrequency.yearly) {
				// Yearly
				maxYear = maxYear + 2;
				for (var year = minYear; year <= maxYear; year++) {
					periodDropdownItems.push({
						frequency: periodSettings.frequency,
						name: year,
						year: year,
						startDate: concatDateString(year, 1),
						endDate: concatDateString(year, 12, getDaysInMonth(12, year))
					});
				}
			}
			else if (periodSettings.frequency === enums.calendarFrequency.halfYearly) {
				// Half yearly
				for (var year = minYear; year <= maxYear; year++) {
					for (var j = 1; j <= 2; j++) {
						periodDropdownItems.push({
							frequency: periodSettings.frequency,
							name: halfYearWord + " " + j + " " + year,
							startDate: concatDateString(year, (6 * j) - 5),
							endDate: concatDateString(year, (6 * j), getDaysInMonth((6 * j), year))
						});
					}
				}
			}
			else if (periodSettings.frequency === enums.calendarFrequency.quarterly) {
				// Quarterly
				for (var year = minYear; year <= maxYear; year++) {
					for (var quarter = 1; quarter <= 4; quarter++) {
						periodDropdownItems.push({
							frequency: periodSettings.frequency,
							name: "Q" + quarter + " " + year,
							startDate: concatDateString(year, (3 * quarter) - 2),
							endDate: concatDateString(year, (3 * quarter), getDaysInMonth((3 * quarter), year))
						});
					}
				}
			}
			else if (periodSettings.frequency === enums.calendarFrequency.monthly) {
				// Monthly
				monthNames = moment.months();

				for (var year = minYear; year <= maxYear; year++) {
					for (var month = 1; month <= 12; month++) {
						periodDropdownItems.push({
							frequency: periodSettings.frequency,
							name: monthNames[month - 1] + " " + year,
							startDate: concatDateString(year, month),
							endDate: concatDateString(year, month, getDaysInMonth(month, year))
						});
					}
				}
			}
			else if (periodSettings.frequency === enums.calendarFrequency.custom) {
				// Custom ranges
				monthNames = moment.months();

				(function () {
					var
						currentYear = minYear,
						yearBeforeRangeIterations,
						maxYear = new Date().getFullYear() + 1,
						itemOutput;
					;

					while (currentYear < maxYear) {
						yearBeforeRangeIterations = currentYear;

						for (var i = 0, startYear, startMonth, startDay, endYear, endMonth, endDay, name, len = periodSettings.ranges.length; i < len; i++) {
							startYear = currentYear;
							startMonth = periodSettings.ranges[i].startMonth;
							startDay = periodSettings.ranges[i].startDay;

							endMonth = periodSettings.ranges[i].endMonth;
							endDay = periodSettings.ranges[i].endDay;
							name = periodSettings.ranges[i].name;

							if (endMonth < startMonth || (endMonth === startMonth && endDay <= startDay))
								endYear = startYear + 1;
							else
								endYear = startYear;

							currentYear = endYear;

							itemOutput = {
								frequency: periodSettings.frequency,
								name: null,
								startYear: startYear,
								startMonth: startMonth,
								startDay: startDay,
								endYear: endYear,
								endMonth: endMonth,
								endDay: endDay,
								startDate: concatDateString(startYear, startMonth, startDay),
								endDate: concatDateString(endYear, endMonth, endDay),
								nameSpecification: name
							};

							if (name && name.length)
								name = formatPeriodName(itemOutput, name);
							else
								name = moment({ year: startYear, month: startMonth - 1, day: startDay }).format("YYYY-MM-DD") + " - " + moment({ year: endYear, month: endMonth - 1, day: endDay }).format("YYYY-MM-DD");

							itemOutput.name = name;

							periodDropdownItems.push(itemOutput);
						}

						if (yearBeforeRangeIterations === currentYear)
							currentYear++;
					}
				})();
			}

			periodDropdownItems = _.reverse(periodDropdownItems);

			return periodDropdownItems;

			function concatDateString(year, month, day) {
				return (year < 10 ? '0' : '') + year + "-" + (month < 10 ? '0' : '') + month + "-" + ((day || 1) < 10 ? '0' : '') + (day || 1)
			}
		}

		// Gets the no. of days in a month. Assumes m starts with 1 for January
		// Credit: https://stackoverflow.com/a/27947860 / https://stackoverflow.com/a/27946635
		function getDaysInMonth(m, y) {
			return m === 2 ? y & 3 || !(y % 25) && y & 15 ? 28 : 29 : 30 + (m + (m >> 3) & 1);
		}

		function getMeasureAnswerPeriodName(measureAnswer, useShortDates, options) {
			var
				startMoment,
				endMoment
			;

			if (measureAnswer.periodName)
				return measureAnswer.periodName;

			if (!measureAnswer.frequency)
				return measureAnswer.year;

			if (measureAnswer.frequency !== enums.calendarFrequency.custom) {
				startMoment = moment(measureAnswer.period);
			}

			switch (measureAnswer.frequency) {
				case enums.calendarFrequency.yearly:
					return startMoment.year();
				case enums.calendarFrequency.halfYearly:
					return "H" + measureAnswer.halfYear + " " +  startMoment.year();
				case enums.calendarFrequency.quarterly:
					return "Q" + measureAnswer.quarter + " " +  startMoment.year();
				case enums.calendarFrequency.monthly:
					if (options && options.useNumericMonthlyFormat) {
						return startMoment.format("YYYY-MM");
					}
					else {
						return startMoment.format("MMM YYYY");
					}
				case enums.calendarFrequency.custom:
					return formatPeriodNameFromAnswer(measureAnswer, measureAnswer.intervalNameSpecification, useShortDates);
			}
		}

		function formatPeriodNameFromPeriodItem(spec, useShortDates) {
			var
				startMoment,
				endMoment
			;

			if (spec.periodName)
				return spec.periodName;

			if (!spec.frequency)
				return spec.year;

			if (spec.frequency !== enums.calendarFrequency.custom) {
				startMoment = moment(spec.startDate);
			}

			switch (spec.frequency) {
				case enums.calendarFrequency.yearly:
					return startMoment.year();
				case enums.calendarFrequency.halfYearly:
					return "H" + getHalfYearNumber(spec.startDate) + " " +  startMoment.year();
				case enums.calendarFrequency.quarterly:
					return "Q" + getQuarterNumber(spec.startDate) + " " +  startMoment.year();
				case enums.calendarFrequency.monthly:
					return startMoment.format("MMM YYYY");
				case enums.calendarFrequency.custom:
					return formatPeriodNameFromAnswer(spec, spec.intervalNameSpecification, useShortDates);
			}

			function getHalfYearNumber(startDateString) {
				if (_.endsWith(startDateString, "-01-01"))
					return 1;
				else if (_.endsWith(startDateString, "-07-01"))
					return 2;
			}

			function getQuarterNumber(startDateString) {
				if (_.endsWith(startDateString, "-01-01"))
					return 1;
				else if (_.endsWith(startDateString, "-04-01"))
					return 2;
				else if (_.endsWith(startDateString, "-07-01"))
					return 3;
				else if (_.endsWith(startDateString, "-10-01"))
					return 4;
			}
		}

		function formatPeriodNameFromAnswer(measureAnswerOrPeriodItem, nameSpec, useShortDates) {
			var periodItem, dateString = useShortDates ? "YYMMDD" : "YYYY‑MM‑DD";
			var
				startDate = measureAnswerOrPeriodItem.startDate || measureAnswerOrPeriodItem.period,
				endDate = measureAnswerOrPeriodItem.endDate || measureAnswerOrPeriodItem.periodEnd
			;

			if (!nameSpec || !nameSpec.length) {
				// The hyphens in the dates are the unicode Character 'NON-BREAKING HYPHEN' (U+2011)
				return moment(startDate).format(dateString) + " - " + moment(endDate).format(dateString);
			}
			else if (nameSpec && nameSpec.indexOf("{{") !== -1 && nameSpec.indexOf("}}") !== -1) {
				periodItem = {
					startMoment: moment(startDate),
					endMoment: moment(endDate),
					replaceParams: true
				}
				periodItem.startYear = periodItem.startMoment.year();
				periodItem.startMonth = periodItem.startMoment.month() + 1;
				periodItem.startDay = periodItem.startMoment.day();
				periodItem.endYear = periodItem.endMoment.year();
				periodItem.endMonth = periodItem.endMoment.month() + 1;
				periodItem.endDay = periodItem.endMoment.day();

				return formatPeriodName(periodItem, nameSpec, useShortDates);
			}
			else
				return nameSpec
		}

		function formatPeriodNameFromRequirement(requirement, measurePeriodSettings) {
			if (measurePeriodSettings.frequency === enums.calendarFrequency.yearly) {
				return requirement.value
			}
			else {
				let
					dates = requirement.value.split("|"),
					startDate = dates[0],
					endDate = dates[1]
				;

				return formatPeriodNameFromPeriodItem({
					frequency: measurePeriodSettings.frequency,
					intervalNameSpecification: measurePeriodSettings.name,
					startDate, endDate
				});
			}

		}

		function formatPeriodName(periodItem, nameSpec, useShortDates) {
			var
				output = nameSpec,
				startDate,
				endDate,
				dateString = useShortDates ? "YYMMDD" : "YYYY‑MM‑DD";
			;

			if ((output && periodItem.replaceParams) || (output && output.indexOf("{{") !== -1 && output.indexOf("}}") !== -1)) {
				startDate = periodItem.startMoment || moment({ year: periodItem.startYear, month: periodItem.startMonth - 1, day: periodItem.startDay });
				endDate = periodItem.endMoment || moment({ year: periodItem.endYear, month: periodItem.endMonth - 1, day: periodItem.endDay });

				output = output.replace(/{{startDate}}/g, startDate.format(dateString)); // The hyphens are the unicode Character 'NON-BREAKING HYPHEN' (U+2011)
				output = output.replace(/{{endDate}}/g, endDate.format(dateString)); // The hyphens are the unicode Character 'NON-BREAKING HYPHEN' (U+2011)

				output = output.replace(/{{startYear}}/g, periodItem.startYear);
				output = output.replace(/{{startMonth}}/g, periodItem.startMonth);
				output = output.replace(/{{startMonthName}}/g, monthNames[periodItem.startMonth - 1]);
				output = output.replace(/{{startDay}}/g, periodItem.startDay);

				output = output.replace(/{{endYear}}/g, periodItem.endYear);
				output = output.replace(/{{endMonth}}/g, periodItem.endMonth);
				output = output.replace(/{{endMonthName}}/g, monthNames[periodItem.endMonth - 1]);
				output = output.replace(/{{endDay}}/g, periodItem.endDay);
			}

			return output;
		}

		function groupAndFormatAnswersByPeriod(answerRelations, options) {
			var
				output,
				preparedContents
				// unitContent
			;

			preparedContents = _.map(answerRelations, function (relation) {
				var
					measureAnswer = relation.childContent,
					clonedContent = _.assign({}, measureAnswer)
				;

				clonedContent.relationCreatedAt = relation.createdAt;

				// if (!unitContent)
				// 	unitContent = measureAnswer.childContent;

				// Concatenate periods to get for example "2016-04-01|2016-06-31"
				// If no periodEnd is specified the answer uses the old format which was always a full year so just use year + "-12-31" then
				clonedContent.periodConcat = clonedContent.period + "|" + (clonedContent.periodEnd || (clonedContent.year + "-12-31"))
				clonedContent.frequency = clonedContent.frequency || enums.calendarFrequency.yearly; // If no frequency specified it is yearly

				clonedContent.realContent = measureAnswer;

				return clonedContent;
			});

			output = _.chain(preparedContents)
				.groupBy("periodConcat")
				.map(function(group) {
					if (group.length === 1)
						return group[0]
					else
						return _.orderBy(group, "relationCreatedAt")[group.length - 1];
				})
				.orderBy("periodConcat")
				.value()
			;

			if (options && options.take) {
				output = _.takeRight(output, options.take);
			}

			_.each(output, function (clonedContent) {
				clonedContent.periodName = getMeasureAnswerPeriodName(clonedContent, options && options.useShortDates)
				clonedContent.childContent = clonedContent.realContent && clonedContent.realContent.childContent;
			});

			if (options && options.useRealContent) {
				output = _.map(output, "realContent");
			}

			return output;
		}
	}

})();
