import * as enums from '@worldfavor/constants/enums'

(function () {
	'use strict';

	var newDataRelationsOrder = -1;


	angular.module('wf.data')
	.config([ function () {
	// .config(['DSProvider','DSHttpAdapterProvider', function (DSProvider, DSHttpAdapterProvider) {
		// angular.extend(DSProvider.defaults, {});
		// angular.extend(DSHttpAdapterProvider.defaults, {});
	} ])
	.service('DS', [ function () {
		return new (JSData.DS || JSData.DataStore)(); // 'DataStore' in preperation for JSData v3
	} ])
	.service('wfObject', [ 'apiProxy', '$q', 'DS', '$timeout', 'wfAuth', 'wfPropertyExtractor', '$uibTooltip', '$rootScope',
		function (apiProxy, $q, DS, $timeout, wfAuth, wfPropertyExtractor, $uibTooltip, $rootScope) {
			var
				userDataObjectTypes = _.keyBy([
					enums.objectType.orgActivity,
					enums.objectType.questionAnswer,
					enums.objectType.measureAnswer,
					enums.objectType.parameterValue,
					enums.objectType.orgDocument,
					enums.objectType.statement,
					enums.objectType.link,
					enums.objectType.location,
					enums.objectType.invoice,
					enums.objectType.embed,
					enums.objectType.certificate,
					enums.objectType.finding,
					enums.objectType.dateItem,
					enums.objectType.person,
				]),
				answereableType = _.keyBy([
					enums.objectType.question,
					enums.objectType.measure,
					enums.objectType.relativeMeasure,
					enums.objectType.parameter
				]),
				answerType = _.keyBy([
					enums.objectType.questionAnswer,
					enums.objectType.measureAnswer,
					enums.objectType.parameterValue
				]),
				linkableObjectTypes = _.keyBy([
					enums.objectType.orgDocument,
					enums.objectType.link,
					enums.objectType.embed,
					enums.objectType.certificate
				]),
				typeHasHeader = _.keyBy([
					enums.objectType.structure,
					enums.objectType.orgActivity,
					enums.objectType.embed
				]),
				relationalTypes = _.keyBy([
					enums.objectType.dataRelation,
					enums.objectType.historicDataRelation,
					enums.objectType.virtualDataRelation,
					enums.objectType.visibilityTag
				]),
				itemsByType = {},
				// itemsByTypeAndWfid = {},
				dataRelationsByChildType = {},
				dataRelationsByParentType = {},
				logQueryTimeToConsole_throttled = _.throttle(logQueryTimeToConsole, 2000),
				useCustomGetters = true,
				useCustomFilters = true,
				logTime = false
			;

			window.wfLogQueryTimes = function (state) {
				logTime = state;
			};
			window.wfTotalQueryTime = 0;
			window.wfResetQueryTime = function () {
				window.wfTotalQueryTime = 0;
			}
			window.wfIndexes = {
				itemsByType: itemsByType,
				dataRelationsByChildType: dataRelationsByChildType,
				dataRelationsByParentType: dataRelationsByParentType
			};
		// DS is the result of `new JSData.DS()`

		// We don't register the "User" resource
		// as a service, so it can only be used
		// via DS.<method>('user', ...)
		// The advantage here is that this code
		// is guaranteed to be executed, and you
		// only ever have to inject "DS"


		//var dataRelation = DS.defineResource({
		//	name: 'dataRelation',
		//	idAttribute: 'wfid',
		//	relations: {
		//		hasOne: {
		//			wfObject: {
		//				localField: 'childContent',
		//				localKey: 'wfcid'
		//			}
		//		}
		//	}
		//});
		// var
		// 	relationGetterQueries = {
		// 		children:
		// 	}
		// ;

		var
			baseRelationQueries = {
				parents: function (wfid) {
					return { parentData1: null, wfcid: wfid };
				}
			}

		var
			cachedChildrenResult = {

			},
			isRelationCacheAllowed = function (item) {
				return false;
				return item.type == 71
					&& item.conditions
					&& item.conditions.dataRelation
					&& !item.conditions.dataRelation.byUser
			}
		;

		function hasOne_getter(instance, localKey, type) {
			var query;

			if (type)
				query = { type: type, wfid: instance[localKey] };
			else
				query = { wfid: instance[localKey] };

			return wfObject.filter(query)[0];
		}

		function hasMany_getter(instance, localKey, type) {
			var query = { parentType: instance.type, wffid: instance.wfid, parentData1: null };

			query = { where: query, orderBy: 'order' };

			return wfObject.filter(query);
		}

		var wfObjectDefinition = _.assign({
			// debug: true,
			name: 'wfObject',
			idAttribute: 'wfid',
			onConflict: 'replace',
			relations: defineWfObject_relations(),
			computed: defineWfObject_computed(),
			methods: defineWfObject_methods()
		}, defineWfObject_lifecycleHooks());

		var wfObject = DS.defineResource(wfObjectDefinition);
		var defaultFilterThisObject = {
			store: DS,
			definitions: {
				wfObject: wfObject
			}
		}
		var
			debug_showQueries = false,
			logQuery = function	(label, query) {
				console.log('    JSData: ' + (i++) + ', ' + label, query.where || query);
				console.groupCollapsed('        Stack trace');
				console.log(getStackTrace());
				console.groupEnd('        Stack trace');
			}
		;
		var originalFilterMethod = wfObject.filter;
		var i = 0;
		var getStackTrace = function() {
			var obj = {};
			Error.captureStackTrace(obj, getStackTrace);
			// console.log(obj.stack);
			return obj.stack
		};

		_.assign(wfObject, defineWfObject_lifecycleHooks);

		window.wf = window.wf || {};

		window.wf.showQueries = function () {
			debug_showQueries = true;
			wfObject.filter = function() {
				getStackTrace();
				console.log('    JSData: ' + (i++), arguments[0].where || arguments[0]);
				console.groupCollapsed('        Stack trace');
				console.log(getStackTrace());
				console.groupEnd('        Stack trace');

				return originalFilterMethod.apply(wfObject, arguments);
			}
		};

		window.wfObject = wfObject;
		DS.registerAdapter('apiProxy', new MyCustomAdapter(apiProxy, $q, wfObject, $timeout, wfAuth, $rootScope), { default: true });
		window.DS = DS;

		if (useCustomFilters)
			extendWfObject();

		return wfObject;


		function extendWfObject() {
			var originalFilterFunc = wfObject.originalFilter = wfObject.filter;

			wfObject.filter = function (options) {
				var
					query, orderBy, type, output, label,
					startTime, resultTime
				;

				if (!options)
					return [];

				if (options.where) {
					orderBy = options.orderBy;
					query = options.where;
				}
				else
					query = options;

				if (logTime)
					startTime = window.performance.now();

				if ("childType" in query) {// && typeof query.type === "number") {
					type = query.childType;
					delete query.childType;
					// console.info("Use type index", type, options, query, orderBy);
					if (logTime) label = "Smart childType query";
					output = wfObject.defaultFilter.call(defaultFilterThisObject, dataRelationsByChildType[type] || [], "wfObject", { where: query, orderBy: orderBy }, { allowSimpleWhere: true });

					query.childType = type; // Put it back so that debugging doesn't get confusing when inspecting the query object after wfObject.defaultFilter have executed.
				}
				else if ("parentType" in query) {// && typeof query.type === "number") {
					type = query.parentType;
					delete query.parentType;
					// console.info("Use type index", type, options, query, orderBy);
					if (logTime) label = "Smart parentType query";
					output = wfObject.defaultFilter.call(defaultFilterThisObject, dataRelationsByParentType[type] || [], "wfObject", { where: query, orderBy: orderBy }, { allowSimpleWhere: true });

					query.parentType = type; // Put it back so that debugging doesn't get confusing when inspecting the query object after wfObject.defaultFilter have executed.
				}
				else if ("type" in query && typeof query.type === "number") {
					type = query.type;
					// console.info("Use type index", type, options, query, orderBy);

					if (!itemsByType[type]) {
						// console.log("index missing", type, itemsByType);
						if (logTime) label = "Type missing in index";
						output = [];
					}
					else {
						if ("wfid" in query && typeof query.wfid === "string") {
							// console.log("access single item", type, query.wfid)
							if (logTime) label = "Smart query, wfid";
							output = [ itemsByType[type].wfidIndex[query.wfid] ];
						}
						else {
							if (logTime) label = "Smart query, type";
							delete query.type;
							output = wfObject.defaultFilter.call(defaultFilterThisObject, itemsByType[type].items, "wfObject", { where: query, orderBy: orderBy }, { allowSimpleWhere: true });

							query.type = type; // Put it back so that debugging doesn't get confusing when inspecting the query object after wfObject.defaultFilter have executed.
						}
					}
				}
				else if ("wfid" in query && (typeof query.wfid === "string" || typeof query.wfid === "undefined")) {
					if (typeof query.wfid === "undefined") {
						if (logTime) label = "Query with undefined wfid";
						output = [];
					}
					else {
						type = parseInt(query.wfid.split("-")[0]);
						if (logTime) label = "Smart query, wfid (extracted type)";

						if (!itemsByType[type]) {
							return [];
						}
						output = [ itemsByType[type].wfidIndex[query.wfid] ];
					}
				}
				else
				{
					if (logTime) label = "Default query";
					output = originalFilterFunc(options);
					// console.log("--", options, output)
					//console.log("Use default query", options);
				}

				if (logTime) {
					resultTime = window.performance.now() - startTime;
					wfTotalQueryTime += resultTime;
					console.log((Math.round(resultTime * 10000) / 10000), label, JSON.stringify(options))
					logQueryTimeToConsole_throttled()
				}

				return output;
			};

			wfObject.isKindChild = (function () {
				var map = {};

				map[enums.subItemsKind.children]
				= map[enums.subItemsKind.childrenByUser]
				= map[enums.subItemsKind.usersOnOrg]
				= map[enums.subItemsKind.contextChildren]
				= map[enums.subItemsKind.verifications]
				= map[enums.subItemsKind.relatedContent]
				= map[enums.subItemsKind.relatedContentByUser]
				= map[enums.subItemsKind.linkageTo]
				= map[enums.subItemsKind.linkageChildren]
				= true;

				map[enums.subItemsKind.parents]
				= map[enums.subItemsKind.parentsByUser]
				= map[enums.subItemsKind.relatedParents]
				= map[enums.subItemsKind.relatedParentsByUser]
				= map[enums.subItemsKind.verifies]
				= map[enums.subItemsKind.contextParents]
				= map[enums.subItemsKind.linkageFrom]
				= map[enums.subItemsKind.linkageParents]
				= false;

				return function (kind) {
					return map[kind];
				};
			})();

			wfObject.isKindParent = function (kind) {
				return !wfObject.isKindChild(kind);
			}

			wfObject.getRelationKeyOfKind = function (kind) {
				switch (kind)
				{
					case enums.subItemsKind.children:
					case enums.subItemsKind.childrenByUser:
					case enums.subItemsKind.usersOnOrg:
					case enums.subItemsKind.contextChildren:
						return "wfcid";
					case enums.subItemsKind.parents:
					case enums.subItemsKind.parentsByUser:
					case enums.subItemsKind.relatedParents:
					case enums.subItemsKind.relatedParentsByUser:
					case enums.subItemsKind.verifies:
					case enums.subItemsKind.contextParents:
						return "wffid";
					case enums.subItemsKind.verifications:
						return "wfcid";
					case enums.subItemsKind.relatedContent:
					case enums.subItemsKind.relatedContentByUser:
						return "wfcid";
					default:
						return null;
				}
			};

			wfObject.getForeignKeyOfKind = function (kind) {
				switch (kind)
				{
					case enums.subItemsKind.children:
					case enums.subItemsKind.childrenByUser:
					case enums.subItemsKind.usersOnOrg:
					case enums.subItemsKind.contextChildren:
						return "wffid";
					case enums.subItemsKind.parents:
					case enums.subItemsKind.parentsByUser:
					case enums.subItemsKind.relatedParents:
					case enums.subItemsKind.relatedParentsByUser:
					case enums.subItemsKind.verifies:
					case enums.subItemsKind.contextParents:
						return "wfcid";
					case enums.subItemsKind.verifications:
						return "wffid";
					case enums.subItemsKind.relatedContent:
					case enums.subItemsKind.relatedContentByUser:
						return "wffid";
					default:
						return null;
				}
			};

			wfObject.getSubContentTypeKeyOfKind = function (kind) {
				switch (kind)
				{
					case enums.subItemsKind.children:
					case enums.subItemsKind.childrenByUser:
					case enums.subItemsKind.usersOnOrg:
					case enums.subItemsKind.contextChildren:
						return "childType";
					case enums.subItemsKind.parents:
					case enums.subItemsKind.parentsByUser:
					case enums.subItemsKind.relatedParents:
					case enums.subItemsKind.relatedParentsByUser:
					case enums.subItemsKind.verifies:
					case enums.subItemsKind.contextParents:
						return "parentType";
					case enums.subItemsKind.verifications:
						return "childType";
					case enums.subItemsKind.relatedContent:
					case enums.subItemsKind.relatedContentByUser:
						return "childType";
					default:
						return null;
				}
			};

			wfObject.getSupContentTypeKeyOfKind = function (kind) {
				switch (kind)
				{
					case enums.subItemsKind.children:
					case enums.subItemsKind.childrenByUser:
					case enums.subItemsKind.usersOnOrg:
					case enums.subItemsKind.contextChildren:
						return "parentType";
					case enums.subItemsKind.parents:
					case enums.subItemsKind.parentsByUser:
					case enums.subItemsKind.relatedParents:
					case enums.subItemsKind.relatedParentsByUser:
					case enums.subItemsKind.verifies:
					case enums.subItemsKind.contextParents:
						return "childType";
					case enums.subItemsKind.verifications:
						return "parentType";
					case enums.subItemsKind.relatedContent:
					case enums.subItemsKind.relatedContentByUser:
						return "parentType";
					default:
						return null;
				}
			};

			wfObject.getRelationParentDataOfKind = function (kind) {
				switch (kind)
				{
					case enums.subItemsKind.children:
					case enums.subItemsKind.parents:
					case enums.subItemsKind.childrenByUser:
					case enums.subItemsKind.parentsByUser:
						return null;
					case enums.subItemsKind.relatedContent:
					case enums.subItemsKind.relatedContentByUser:
					case enums.subItemsKind.relatedParents:
					case enums.subItemsKind.relatedParentsByUser:
						return 1;
					case enums.subItemsKind.verifications:
					case enums.subItemsKind.verifies:
						return 2;
					case enums.subItemsKind.contextChildren:
					case enums.subItemsKind.contextParents:
						return 3;
					case enums.subItemsKind.linkageTo:
					case enums.subItemsKind.linkageFrom:
						return 4;
					case enums.subItemsKind.linkageChildren:
					case enums.subItemsKind.linkageParents:
						return 5;
				}
			};

			wfObject.getOppositeRelationKind = function (kind) {
				switch (kind)
				{
					case enums.subItemsKind.children:
						return enums.subItemsKind.parents;
					case enums.subItemsKind.parents:
						return enums.subItemsKind.children;
					case enums.subItemsKind.childrenByUser:
						return enums.subItemsKind.parentsByUser;
					case enums.subItemsKind.parentsByUser:
						return enums.subItemsKind.childrenByUser;
					case enums.subItemsKind.relatedContent:
						return enums.subItemsKind.relatedParents;
					case enums.subItemsKind.relatedContentByUser:
						return enums.subItemsKind.relatedParentsByUser;
					case enums.subItemsKind.relatedParents:
						return enums.subItemsKind.relatedContent;
					case enums.subItemsKind.relatedParentsByUser:
						return enums.subItemsKind.relatedContentByUser;
					case enums.subItemsKind.verifications:
						return enums.subItemsKind.verifies;
					case enums.subItemsKind.verifies:
						return enums.subItemsKind.verifications;
					case enums.subItemsKind.contextChildren:
						return enums.subItemsKind.contextParents;
					case enums.subItemsKind.contextParents:
						return enums.subItemsKind.contextChildren;
				}
			};



			wfObject.isRelationKindByUser = function (kind) {
				switch (kind)
				{
					case enums.subItemsKind.childrenByUser:
					case enums.subItemsKind.parentsByUser:
					case enums.subItemsKind.relatedContentByUser:
					case enums.subItemsKind.relatedParentsByUser:
					case enums.subItemsKind.verifications:
					case enums.subItemsKind.verifies:
						return true;
					case enums.subItemsKind.children:
					case enums.subItemsKind.parents:
					case enums.subItemsKind.relatedContent:
					case enums.subItemsKind.relatedParents:
					case enums.subItemsKind.contextChildren:
					case enums.subItemsKind.contextParents:
					case enums.subItemsKind.linkageTo:
					case enums.subItemsKind.linkageFrom:
					case enums.subItemsKind.linkageChildren:
					case enums.subItemsKind.linkageParents:
						return false;
				}
			};

			wfObject.updateMetadataFromRelation = function (dataRelation, delta) {
				var
					subContent,
					kind,
					currentCount,
					kinds = enums.subItemsKind,
					countByRelationKind,
					ensureMetadataExists = function (subContent) {
						if (!subContent.metadata || !subContent.metadata.countByRelationKind) {
							subContent.metadata = subContent.metadata || {};
							subContent.metadata.countByRelationKind = {};
						}
						countByRelationKind = subContent.metadata.countByRelationKind;

						for (var key in enums.subItemsKind) {
							if (!countByRelationKind.hasOwnProperty(enums.subItemsKind[key]))
								countByRelationKind[enums.subItemsKind[key]] = 0;
						}
						return countByRelationKind;
					},
					getKindForChildContent = function () {
						if (!dataRelation.organizationId && dataRelation.parentData1 === null)
							return kinds.parents;
						if (dataRelation.organizationId && dataRelation.parentData1 === 2)
							return kinds.verifies;
						if (dataRelation.organizationId && dataRelation.parentData1 === null)
							return kinds.parentsByUser;
						if (!dataRelation.organizationId && dataRelation.parentData1 === 1)
							return kinds.relatedParents;
						if (dataRelation.organizationId && dataRelation.parentData1 === 1)
							return kinds.relatedParentsByUser;
						if (dataRelation.organizationId && dataRelation.parentData1 === 3)
							return kinds.contextParents;
					},
					getKindForParentContent = function () {
						if (!dataRelation.organizationId && dataRelation.parentData1 === null)
							return kinds.children;
						if (dataRelation.organizationId && dataRelation.parentData1 === null)
							return kinds.childrenByUser;
						if (dataRelation.organizationId && dataRelation.parentData1 === 2)
							return kinds.verifications;
						if (!dataRelation.organizationId && dataRelation.parentData1 === 1)
							return kinds.relatedContent;
						if (dataRelation.organizationId && dataRelation.parentData1 === 1)
							return kinds.relatedContentByUser;
						if (dataRelation.organizationId && dataRelation.parentData1 === 3)
							return kinds.contextChildren;
					}
				;

				if ("wfcid" in dataRelation) {
					subContent = wfObject.get(dataRelation.wfcid); // childContent
					kind = getKindForChildContent();
					// console.log("Kind on childContent", enums.subItemsKindName[kind]);
					if (kind && subContent)
					{
						countByRelationKind = ensureMetadataExists(subContent);
						currentCount = countByRelationKind[kind];
						if (!(currentCount + delta < 0)) {
							countByRelationKind[kind] += delta;
						}
						// console.log(countByRelationKind);
					}
				}
				else console.log("Property wfcid missing on", dataRelation);

				if ("wffid" in dataRelation) {
					subContent = wfObject.get(dataRelation.wffid); // parentContent
					kind = getKindForParentContent();
					// console.log("Kind on parentContent", enums.subItemsKindName[kind]);
					if (kind && subContent)
					{
						countByRelationKind = ensureMetadataExists(subContent);
						currentCount = countByRelationKind[kind];
						if (!(currentCount + delta < 0)) {
							countByRelationKind[kind] += delta;
						}
						// console.log(countByRelationKind);
					}
				}
				else console.log("Property wffid missing on", dataRelation);

			};
		}

		function logQueryTimeToConsole() {
			console.info("Total query duration", (Math.round(wfTotalQueryTime * 10000) / 10000) + "ms");
		}

		function injectInCustomIndex(item, options) {
			var type = item.type, array, typeIndex, wfidIndex;
			typeIndex = itemsByType[type];

			if (!typeIndex) {
				itemsByType[type] = {
					items: array = [],
					wfidIndex: wfidIndex = {}
				};
				// console.log("add new index", type)
			}
			else {
				array = typeIndex.items;
				wfidIndex = typeIndex.wfidIndex;
			}

			// if (!array) {
			// 	array = itemsByType[type] = [];
			// }

			// if (!_.find(itemsByType[type], item))
			if (!!!~array.indexOf(item)) // If array doesn't contain item
				array.push(item);

			if (!(item.wfid in wfidIndex))
				wfidIndex[item.wfid] = item;

			// if (item.hasOwnProperty("childType")) {
			if ("childType" in item) {
				type = item.childType;
				array = dataRelationsByChildType[type];

				if (!array) {
					array = dataRelationsByChildType[type] = [];
				}

				if (!!!~array.indexOf(item)) // If array doesn't contain item
					array.push(item);
			}

			// if (item.hasOwnProperty("parentType")) {
			if ("parentType" in item) {
				type = item.parentType;
				array = dataRelationsByParentType[type];

				if (!array) {
					array = dataRelationsByParentType[type] = [];
				}

				// if (!_.find(dataRelationsByParentType[type], item))
				if (!!!~array.indexOf(item)) // If array doesn't contain item
					array.push(item);
			}
			// console.log("inject", type, item, itemsByType[type].length);
		}

		function ejectFromCustomIndex(item) {
			var typeIndex = itemsByType[item.type];

			// if (!itemsByType[type]) {
			// 	itemsByType[type] = [];
			// }

			_.remove(typeIndex.items, item);
			delete typeIndex.wfidIndex[item.wfid]

			if ("childType" in item) {
				_.remove(dataRelationsByChildType[item.childType], item);
			}
			if ("parentType" in item) {
				_.remove(dataRelationsByParentType[item.parentType], item);
			}
			// console.log("eject", type, item, itemsByType[type].length);
		}

		function defineWfObject_computed() {
			return {
				_typeName: {
					get: function () {
						return enums.objectTypeName[this.type];
					}
				},
				headerText: {
					get: function () {
						return wfPropertyExtractor.getHeaderText(this);
					}
				}
			};
		}

		function defineWfObject_methods() {
			return {
				getSubContentOfKind: function (kind) {
					switch (kind)
					{
						case enums.subItemsKind.children:
						case enums.subItemsKind.childrenByUser:
						case enums.subItemsKind.usersOnOrg:
						case enums.subItemsKind.contextChildren:
							return this.childContent;
						case enums.subItemsKind.parents:
						case enums.subItemsKind.parentsByUser:
						case enums.subItemsKind.relatedParents:
						case enums.subItemsKind.relatedParentsByUser:
						case enums.subItemsKind.verifies:
						case enums.subItemsKind.visible:
						case enums.subItemsKind.contextParents:
							return this.parentContent;
						case enums.subItemsKind.verifications:
							return this.childContent;
						case enums.subItemsKind.relatedContent:
						case enums.subItemsKind.relatedContentByUser:
							return this.childContent;
						default:
							return {};
					}
				},
				getSubContentWfidOfKind: function (kind) {
					switch (kind)
					{
						case enums.subItemsKind.children:
						case enums.subItemsKind.childrenByUser:
						case enums.subItemsKind.usersOnOrg:
						case enums.subItemsKind.contextChildren:
							return this["wfcid"];
						case enums.subItemsKind.parents:
						case enums.subItemsKind.parentsByUser:
						case enums.subItemsKind.relatedParents:
						case enums.subItemsKind.relatedParentsByUser:
						case enums.subItemsKind.verifies:
						case enums.subItemsKind.contextParents:
							return this["wffid"];
						case enums.subItemsKind.verifications:
							return this["wfcid"];
						case enums.subItemsKind.relatedContent:
						case enums.subItemsKind.relatedContentByUser:
							return this["wfcid"];
						default:
							return null;
					}
				},
				getSubContentTypeOfKind: function (kind) {
					switch (kind)
					{
						case enums.subItemsKind.children:
						case enums.subItemsKind.childrenByUser:
						case enums.subItemsKind.usersOnOrg:
						case enums.subItemsKind.contextChildren:
							return this["childType"];
						case enums.subItemsKind.parents:
						case enums.subItemsKind.parentsByUser:
						case enums.subItemsKind.relatedParents:
						case enums.subItemsKind.relatedParentsByUser:
						case enums.subItemsKind.verifies:
						case enums.subItemsKind.visible:
						case enums.subItemsKind.contextParents:
							return this["parentType"];
						case enums.subItemsKind.verifications:
							return this["childType"];
						case enums.subItemsKind.relatedContent:
						case enums.subItemsKind.relatedContentByUser:
							return this["childType"];
						default:
							return null;
					}
				},
				getSubListOfKind: function (kind, organizationId) {
					if (!organizationId)
						organizationId = wfAuth.getOrganizationId();

					organizationId = organizationId || 0;

					switch (kind)
					{
						case enums.subItemsKind.children: return this.filterChildren({ organizationId: null });
						case enums.subItemsKind.childrenByUser: return this.filterChildren({ organizationId: organizationId instanceof Array ? { "in": organizationId } : organizationId });
						case enums.subItemsKind.parents: return this.filterParents({ organizationId: null });
						case enums.subItemsKind.parentsByUser: return this.filterParents({ organizationId: organizationId });
						case enums.subItemsKind.verifications: return this.verifications;
						case enums.subItemsKind.verifies: return this.verifies;
						case enums.subItemsKind.relatedContent: return this.filterRelatedContent({ organizationId: null });
						case enums.subItemsKind.relatedContentByUser: return this.filterRelatedContent({ organizationId: organizationId });
						case enums.subItemsKind.relatedParents: return this.filterRelatedParents({ organizationId: null });
						case enums.subItemsKind.relatedParentsByUser: return this.filterRelatedParents({ organizationId: organizationId });
						case enums.subItemsKind.usersOnOrg: return this.users;
						case enums.subItemsKind.visible: return this.visibility;
						case enums.subItemsKind.contextChildren: return this.contextChildren;
						case enums.subItemsKind.contextParents: return this.contextParents;
						default:
							return [];
					}
				},
				filterChildren: function (query) {
					// var finalQuery;
					// if (cachedChildrenResult[this.wfid])
					// {

					// }
					// else
					// {

					// }
					var finalQuery = { where: _.assign({ wffid: this.wfid, parentData1: null, parentType: this.type, type: { "!=": enums.objectType.historicDataRelation } }, query), orderBy: 'order' }
					if (debug_showQueries) logQuery("fiterChildren on " + this.wfid, finalQuery)

					return wfObject.filter(finalQuery);
				},
				filterParents: function (query) {
					var finalQuery = { where: _.assign({ wfcid: this.wfid, parentData1: null, childType: this.type, type: { "!=": enums.objectType.historicDataRelation } }, query), orderBy: 'order' }
					if (debug_showQueries) logQuery("filterParents on " + this.wfid, finalQuery)

					return wfObject.filter(finalQuery);
				},
				filterRelatedParents: function (query) {
					var finalQuery = { where: _.assign({ wfcid: this.wfid, parentData1: 1, childType: this.type, type: { "!=": enums.objectType.historicDataRelation } }, query), orderBy: 'order' }
					if (debug_showQueries) logQuery("filterRelatedParents on " + this.wfid, finalQuery)

					return wfObject.filter(finalQuery);
				},
				// parentsByAuthOrg: function (query) {
				// 	return baseRelationQueries.parents(this.wfid)
				// 	var finalQuery = { where: _.assign({ wfcid: this.wfid, parentData1: null }, query) }
				// 	if (debug_showQueries) logQuery("filterParents on " + this.wfid, finalQuery)

				// 	return wfObject.filter(finalQuery);
				// },
				filterRelatedContent: function (query) {
					var finalQuery = { where: _.assign({ wffid: this.wfid, parentData1: 1, parentType: this.type, type: { "!=": enums.objectType.historicDataRelation } }, query), orderBy: 'order'  }
					if (debug_showQueries) logQuery("filterRelatedContent on " + this.wfid, finalQuery)

					return wfObject.filter(finalQuery);
				},
				filterRelatedContentByUser: function (query) {
					var finalQuery = { where: _.assign({ wffid: this.wfid, parentData1: 1, parentType: this.type, userId: { '!=': null }, type: { "!=": enums.objectType.historicDataRelation } }, query), orderBy: 'order'  }
					if (debug_showQueries) logQuery("filterRelatedContentByUser on " + this.wfid, finalQuery)

					return wfObject.filter(finalQuery);
				},
				filterVerifications: function (query) {
					var finalQuery = { where: _.assign({ wffid: this.wfid, parentData1: 2, parentType: this.type, type: { "!=": enums.objectType.historicDataRelation } }, query), orderBy: 'order'  }
					if (debug_showQueries) logQuery("filterVerifications on " + this.wfid, finalQuery)

					return wfObject.filter(finalQuery);
				},
				getAllTextual: function () {
					var output;
					switch (this.type) {
						case enums.objectType.statement:
						case enums.objectType.question:
						case enums.objectType.measure:
						case enums.objectType.relativeMeasure:
							return wfPropertyExtractor.getBodyText(this);
						default:
							output = wfPropertyExtractor.getHeaderText(this);

							if (!output || !output.length)
								output = wfPropertyExtractor.getBodyText(this) || "";
							else
								output += wfPropertyExtractor.getBodyText(this) || "";

							return output;
					}
				},
				getMainTextual: function (options) {
					var output;
					switch (this.type) {
						case enums.objectType.statement:
						case enums.objectType.question:
						case enums.objectType.measure:
						case enums.objectType.relativeMeasure:
							return wfPropertyExtractor.getBodyText(this, options);
						default:
							output = wfPropertyExtractor.getHeaderText(this, options);

							if (!output || !output.length)
								output = wfPropertyExtractor.getBodyText(this, options);

							return output;
					}
				},
				getHeaderText: function () {
					return wfPropertyExtractor.getHeaderText(this);
				},
				getBodyText: function () {
					return wfPropertyExtractor.getBodyText(this);
				},
				getImageUrl: function () {
					return wfPropertyExtractor.getImageUrl(this);
				},
				getRequirement: function (forOrganizationId) {
					var
						requirements,
						defaultReq,
						orgReq,
						wfid = this.wfid
					;

					if (this.type == enums.objectType.virtualDataRelation && typeof this.originalRelationWfid === "string") {
						wfid = this.originalRelationWfid;
					}

					requirements = wfObject.filter({ where: { type: 60, wffid_requirement: wfid, organizationId: forOrganizationId ? { "in": [ null, forOrganizationId  ] } : null } });

					if (requirements.length == 1) {
						return requirements[0];
					}
					else if (requirements.length > 1) {
						orgReq = _.find(requirements, { organizationId: forOrganizationId })
						return orgReq;
					}
					else
						return null;
				},
				getRequirementSpecification: function (forOrganizationId) {
					var
						requirements,
						defaultReq,
						organizationReq,
						actualReq,
						wfid = this.wfid
					;

					if (this.type == enums.objectType.virtualDataRelation && typeof this.originalRelationWfid === "string") {
						wfid = this.originalRelationWfid;
					}

					requirements = wfObject.filter({ where: { type: 60, wffid_requirement: wfid, organizationId: forOrganizationId ? { "in": [ null, forOrganizationId  ] } : null } });

					if (requirements.length == 1) {
						actualReq = requirements[0];
						if (actualReq.organizationId)
							organizationReq = actualReq;
						else
							defaultReq = actualReq;
					}
					else if (requirements.length > 1) {
						organizationReq = _.find(requirements, { organizationId: forOrganizationId })
						defaultReq = _.find(requirements, { organizationId: null })
						actualReq = organizationReq || defaultReq;
					}

					return {
						actual: actualReq,
						standard: defaultReq,
						specific: organizationReq
					};
				},
				isOfAnswerableType: function () {
					return !!answereableType[this.type];
				},
				isAnswerType: function () {
					return !!answerType[this.type];
				},
				isUserDataType: function () {
					return !!userDataObjectTypes[this.type];
				},
				isLinkableType: function () {
					return !!linkableObjectTypes[this.type];
				},
				isRelationalType: function () {
					return !!relationalTypes[this.type];
				},
				typeHasHeader: function () {
					return !!typeHasHeader[this.type];
				},
				getEncodedWfid: function (propertyName) {
					return btoa(btoa(this[propertyName || "wfid"]));
				},
				getEncodedIdentifiers: function (relation) {
					var output = { o: this.wfid }; // object wfid

					if (relation) {
						output.r = relation.wfid; // relation wfid

						if (relation.originalRelationWfid)
							output.or = relation.originalRelationWfid; // original relation wfid
						if (relation.positionRelationWfid)
							output.pr = relation.positionRelationWfid; // position relation wfid
					}

					return btoa(JSON.stringify(output));
				},
				getKindForChildContent: function () {
					if (!this.isRelationalType())
						return;
					if (!this.organizationId && this.parentData1 === null)
						return enums.subItemsKind.parents;
					if (this.organizationId && this.parentData1 === 2)
						return enums.subItemsKind.verifies;
					if (this.organizationId && this.parentData1 === null)
						return enums.subItemsKind.parentsByUser;
					if (!this.organizationId && this.parentData1 === 1)
						return enums.subItemsKind.relatedParents;
					if (this.organizationId && this.parentData1 === 1)
						return enums.subItemsKind.relatedParentsByUser;
					if (this.organizationId && this.parentData1 === 3)
						return enums.subItemsKind.contextParents;
				},
				getKindForParentContent: function () {
					if (!this.isRelationalType())
						return;
					if (!this.organizationId && this.parentData1 === null)
						return enums.subItemsKind.children;
					if (this.organizationId && this.parentData1 === null)
						return enums.subItemsKind.childrenByUser;
					if (this.organizationId && this.parentData1 === 2)
						return enums.subItemsKind.verifications;
					if (!this.organizationId && this.parentData1 === 1)
						return enums.subItemsKind.relatedContent;
					if (this.organizationId && this.parentData1 === 1)
						return enums.subItemsKind.relatedContentByUser;
					if (this.organizationId && this.parentData1 === 3)
						return enums.subItemsKind.contextChildren;
				}
			};
		}

		function defineWfObject_relations() {
			return {
				hasOne: {
					wfObject: [
						{
							localField: 'childContent',
							localKey: 'wfcid',
							get: !useCustomGetters ? undefined : function (Resource, relationDef, instance, origGetter) {
								if (instance._cachedChildContent) {
									if (logTime) console.log(instance.wfid, "cached childContent");
									return instance._cachedChildContent;
								}
								else {
									// console.info(instance.wfid, "direct childContent");
									return instance._cachedChildContent = hasOne_getter(instance, relationDef.localKey, instance.childType);
								}
							}
						},
						{
							localField: 'parentContent',
							localKey: 'wffid',
							get: !useCustomGetters ? undefined : function (Resource, relationDef, instance, origGetter) {
								if (instance._cachedParentContent) {
									if (logTime) console.log(instance.wfid, "cached parentContent");
									return instance._cachedParentContent;
								}
								else {
									// console.info(instance.wfid, "direct parentContent");
									return instance._cachedParentContent = hasOne_getter(instance, relationDef.localKey, instance.parentType);
								}
							}
						},
						{
							localField: 'contextParentContent',
							localKey: 'wfxpid',
							get: !useCustomGetters ? undefined : function (Resource, relationDef, instance, origGetter) {
								if (instance._cachedContextParentContent) {
									if (logTime) console.log(instance.wfid, "cached contextParentContent");
									return instance._cachedContextParentContent;
								}
								else {
									// console.info(instance.wfid, "direct parentContent");
									return instance._cachedContextParentContent = hasOne_getter(instance, relationDef.localKey, instance.contextParentType);
								}
							}
						},
						{
							localField: 'creatorOrganization',
							localKey: 'creatorOrganizationWfid',
							get: !useCustomGetters ? undefined : function (Resource, relationDef, instance, origGetter) {
								return hasOne_getter(instance, relationDef.localKey, 101);
							}
						},
						{
							localField: 'creatorUser',
							localKey: 'creatorUserWfid',
							get: !useCustomGetters ? undefined : function (Resource, relationDef, instance, origGetter) {
								return hasOne_getter(instance, relationDef.localKey, 100);
							}
						},
						{
							localField: 'organization',
							localKey: 'organizationWfid',
							get: !useCustomGetters ? undefined : function (Resource, relationDef, instance, origGetter) {
								return hasOne_getter(instance, relationDef.localKey, 101);
							}
						},
						{
							localField: 'ancestor',
							localKey: 'ancestorWfid',
							get: !useCustomGetters ? undefined : function (Resource, relationDef, instance, origGetter) {
								return hasOne_getter(instance, relationDef.localKey, 71);
							}
						},
						{
							localField: 'originalRelation',
							localKey: 'originalRelationWfid',
							get: !useCustomGetters ? undefined : function (Resource, relationDef, instance, origGetter) {
								return hasOne_getter(instance, relationDef.localKey, 73);
							}
						},
						{
							localField: 'positionRelation',
							localKey: 'positionlRelationWfid',
							get: !useCustomGetters ? undefined : function (Resource, relationDef, instance, origGetter) {
								return hasOne_getter(instance, relationDef.localKey, 73);
							}
						},
						{
							localField: 'fulfillment',
							localKey: 'fulfillmentWfid',
							get: !useCustomGetters ? undefined : function (Resource, relationDef, instance, origGetter) {
								return hasOne_getter(instance, relationDef.localKey, 53);
							}
						}
					]
				},
				hasMany: {
					wfObject: !useCustomGetters ? [
						{
							localField: 'childs',
							foreignKey: 'wffid',
							get: function (Resource, relationDef, instance, origGetter) {
								var finalQuery;
								if (isRelationCacheAllowed(instance) && cachedChildrenResult[instance.wfid])
								{
									if (debug_showQueries) logQuery("cached children on " + instance.wfid, {})
									return cachedChildrenResult[instance.wfid];
								}
								else
								{
									finalQuery = { wffid: instance.wfid, parentData1: null, type: { "!=": enums.objectType.historicDataRelation } };
									if (debug_showQueries) logQuery("children on " + instance.wfid, finalQuery)

									finalQuery = { where: finalQuery, orderBy: 'order' };

									return cachedChildrenResult[instance.wfid] = Resource.defaultFilter.call(defaultFilterThisObject, DS.store[relationDef.name].collection, relationDef.relation, finalQuery, { allowSimpleWhere: true });
								}
							}
						},
						{
							localField: 'parents',
							foreignKey: 'wfcid',
							get: function (Resource, relationDef, instance, origGetter) {
								var finalQuery = { wfcid: instance.wfid, parentData1: null, type: { "!=": enums.objectType.historicDataRelation } };
								if (debug_showQueries) logQuery("parents on " + instance.wfid, finalQuery)

								finalQuery = { where: finalQuery, orderBy: 'order' };

								return Resource.defaultFilter.call(defaultFilterThisObject, DS.store[relationDef.name].collection, relationDef.relation, finalQuery, { allowSimpleWhere: true });
							}
						},
						{
							localField: 'parentsByUser',
							foreignKey: 'wfcid',
							get: function (Resource, relationDef, instance, origGetter) {
								var finalQuery = { wfcid: instance.wfid, parentData1: null, userId: { '!=': null }, type: { "!=": enums.objectType.historicDataRelation } };
								if (debug_showQueries) logQuery("parents by user on " + instance.wfid, finalQuery)

								finalQuery = { where: finalQuery, orderBy: 'order' };

								return Resource.defaultFilter.call(defaultFilterThisObject, DS.store[relationDef.name].collection, relationDef.relation, finalQuery, { allowSimpleWhere: true });
							}
						},
						{
							localField: 'relatedContent',
							foreignKey: 'wffid',
							get: function (Resource, relationDef, instance, origGetter) {
								var finalQuery = { wffid: instance.wfid, parentData1: 1, userId: null, type: { "!=": enums.objectType.historicDataRelation } };
								if (debug_showQueries) logQuery("relatedContent on " + instance.wfid, finalQuery)

								finalQuery = { where: finalQuery, orderBy: 'order' };

								return Resource.defaultFilter.call(defaultFilterThisObject, DS.store[relationDef.name].collection, relationDef.relation, finalQuery, { allowSimpleWhere: true });
							}
						},
						{
							localField: 'relatedContentByUser',
							foreignKey: 'wffid',
							get: function (Resource, relationDef, instance, origGetter) {
								var finalQuery = { wffid: instance.wfid, parentData1: 1, userId: { '!=': null }, type: { "!=": enums.objectType.historicDataRelation } };
								if (debug_showQueries) logQuery("relatedContentByUser on " + instance.wfid, finalQuery)

								finalQuery = { where: finalQuery, orderBy: 'order' };

								return Resource.defaultFilter.call(defaultFilterThisObject, DS.store[relationDef.name].collection, relationDef.relation, finalQuery, { allowSimpleWhere: true });
							}
						},
						{
							localField: 'verifications',
							foreignKey: 'wffid',
							get: function (Resource, relationDef, instance, origGetter) {
								var finalQuery = { wffid: instance.wfid, parentData1: 2, type: { "!=": enums.objectType.historicDataRelation } };//, userId: null };
								if (debug_showQueries) logQuery("verifications on " + instance.wfid, finalQuery)

								finalQuery = { where: finalQuery, orderBy: 'order' };

								return Resource.defaultFilter.call(defaultFilterThisObject, DS.store[relationDef.name].collection, relationDef.relation, finalQuery, { allowSimpleWhere: true });
							}
						},
						{
							localField: 'verifies',
							foreignKey: 'wfcid',
							get: function (Resource, relationDef, instance, origGetter) {
								var finalQuery = { wfcid: instance.wfid, parentData1: 2, type: { "!=": enums.objectType.historicDataRelation } };//, userId: null };
								if (debug_showQueries) logQuery("verifies on " + instance.wfid, finalQuery)

								finalQuery = { where: finalQuery, orderBy: 'order' };

								return Resource.defaultFilter.call(defaultFilterThisObject, DS.store[relationDef.name].collection, relationDef.relation, finalQuery, { allowSimpleWhere: true });
							}
						},
						{
							localField: 'users', // Used on organizations
							foreignKey: 'wffid',
							get: function (Resource, relationDef, instance, origGetter) {
								var finalQuery = { type: 85, wffid: instance.wfid };
								if (debug_showQueries) logQuery("users on " + instance.wfid, finalQuery)

								finalQuery = { where: finalQuery, orderBy: 'order' };

								return Resource.defaultFilter.call(defaultFilterThisObject, DS.store[relationDef.name].collection, relationDef.relation, finalQuery, { allowSimpleWhere: true });
							}
						},
						{
							localField: 'requirements',
							foreignKey: 'wffid_requirement',
							get: function (Resource, relationDef, instance, origGetter) {
								var finalQuery = { type: 60, wffid_requirement: instance.wfid };
								if (debug_showQueries) logQuery("requirements on " + instance.wfid, finalQuery)

								finalQuery = { where: finalQuery };

								return Resource.defaultFilter.call(defaultFilterThisObject, DS.store[relationDef.name].collection, relationDef.relation, finalQuery, { allowSimpleWhere: true });
							}
						}
					] : [
						{
							localField: 'childs',
							foreignKey: 'wffid',
							get: function (Resource, relationDef, instance, origGetter) {
								var query = { parentType: instance.type, wffid: instance.wfid, parentData1: null, type: { "!=": enums.objectType.historicDataRelation } };
								query = { where: query, orderBy: 'order' };

								return wfObject.filter(query);
							}
						},
						{
							localField: 'parents',
							foreignKey: 'wfcid',
							get: function (Resource, relationDef, instance, origGetter) {
								var query = { childType: instance.type, wfcid: instance.wfid, parentData1: null, type: { "!=": enums.objectType.historicDataRelation } };
								query = { where: query, orderBy: 'order' };

								return wfObject.filter(query);
							}
						},
						{
							localField: 'parentsByUser',
							foreignKey: 'wfcid',
							get: function (Resource, relationDef, instance, origGetter) {
								var query = { childType: instance.type, wfcid: instance.wfid, parentData1: null, userId: { '!=': null }, type: { "!=": enums.objectType.historicDataRelation } };
								query = { where: query, orderBy: 'order' };

								return wfObject.filter(query);
							}
						},
						{
							localField: 'visibility',
							foreignKey: 'wfcid',
							get: function (Resource, relationDef, instance, origGetter) {
								var query = { objectType: instance.type, wfcid: instance.wfid, type: enums.objectType.visibilityTag };
								query = { where: query };

								return wfObject.filter(query);
							}
						},
						{
							localField: 'accessTags',
							foreignKey: 'wfcid',
							get: function (Resource, relationDef, instance, origGetter) {
								var query = { objectType: instance.type, wfcid: instance.wfid, type: enums.objectType.accessTag };
								query = { where: query };

								return wfObject.filter(query);
							}
						},
						{
							localField: 'relatedParents',
							foreignKey: 'wfcid',
							get: function (Resource, relationDef, instance, origGetter) {
								var query = { childType: instance.type, wfcid: instance.wfid, parentData1: 1, type: { "!=": enums.objectType.historicDataRelation }};
								query = { where: query, orderBy: 'order' };

								return wfObject.filter(query);
							}
						},
						{
							localField: 'relatedParentsByUser',
							foreignKey: 'wfcid',
							get: function (Resource, relationDef, instance, origGetter) {
								var query = { childType: instance.type, wfcid: instance.wfid, parentData1: 1, userId: { '!=': null }, type: { "!=": enums.objectType.historicDataRelation } };
								query = { where: query, orderBy: 'order' };

								return wfObject.filter(query);
							}
						},
						{
							localField: 'relatedContent',
							foreignKey: 'wffid',
							get: function (Resource, relationDef, instance, origGetter) {
								var query = { parentType: instance.type, wffid: instance.wfid, parentData1: 1, userId: null, type: { "!=": enums.objectType.historicDataRelation } };
								query = { where: query, orderBy: 'order' };

								return wfObject.filter(query);
								// var finalQuery = { wffid: instance.wfid, parentData1: 1, userId: null };
								// if (debug_showQueries) logQuery("relatedContent on " + instance.wfid, finalQuery)

								// finalquery = { where: query, orderBy: 'order' };

								// return Resource.defaultFilter.call(defaultFilterThisObject, DS.store[relationDef.name].collection, relationDef.relation, finalQuery, { allowSimpleWhere: true });
							}
						},
						{
							localField: 'relatedContentByUser',
							foreignKey: 'wffid',
							get: function (Resource, relationDef, instance, origGetter) {
								var query = { parentType: instance.type, wffid: instance.wfid, parentData1: 1, userId: { '!=': null }, type: { "!=": enums.objectType.historicDataRelation } };
								query = { where: query, orderBy: 'order' };

								return wfObject.filter(query);
								// var finalQuery = { wffid: instance.wfid, parentData1: 1, userId: { '!=': null } };
								// if (debug_showQueries) logQuery("relatedContentByUser on " + instance.wfid, finalQuery)

								// finalquery = { where: query, orderBy: 'order' };

								// return Resource.defaultFilter.call(defaultFilterThisObject, DS.store[relationDef.name].collection, relationDef.relation, finalQuery, { allowSimpleWhere: true });
							}
						},
						{
							localField: 'verifications',
							foreignKey: 'wffid',
							get: function (Resource, relationDef, instance, origGetter) {
								var query = { parentType: instance.type, wffid: instance.wfid, parentData1: 2, type: { "!=": enums.objectType.historicDataRelation } };
								query = { where: query, orderBy: 'order' };

								return wfObject.filter(query);
								// var finalQuery = { wffid: instance.wfid, parentData1: 2 };//, userId: null };
								// if (debug_showQueries) logQuery("verifications on " + instance.wfid, finalQuery)

								// finalquery = { where: query, orderBy: 'order' };

								// return Resource.defaultFilter.call(defaultFilterThisObject, DS.store[relationDef.name].collection, relationDef.relation, finalQuery, { allowSimpleWhere: true });
							}
						},
						{
							localField: 'verifies',
							foreignKey: 'wfcid',
							get: function (Resource, relationDef, instance, origGetter) {
								var query = { childType: instance.type, wfcid: instance.wfid, parentData1: 2, type: { "!=": enums.objectType.historicDataRelation } };
								query = { where: query, orderBy: 'order' };

								return wfObject.filter(query);
								// var finalQuery = { wfcid: instance.wfid, parentData1: 2 };//, userId: null };
								// if (debug_showQueries) logQuery("verifies on " + instance.wfid, finalQuery)

								// finalquery = { where: query, orderBy: 'order' };

								// return Resource.defaultFilter.call(defaultFilterThisObject, DS.store[relationDef.name].collection, relationDef.relation, finalQuery, { allowSimpleWhere: true });
							}
						},
						{
							localField: 'users', // Used on organizations
							foreignKey: 'wffid',
							get: function (Resource, relationDef, instance, origGetter) {
								var query = { type: 85, wffid: instance.wfid };
								query = { where: query, orderBy: 'order' };

								return wfObject.filter(query);
								// var finalQuery = { type: 85, wffid: instance.wfid };
								// if (debug_showQueries) logQuery("users on " + instance.wfid, finalQuery)

								// finalquery = { where: query, orderBy: 'order' };

								// return Resource.defaultFilter.call(defaultFilterThisObject, DS.store[relationDef.name].collection, relationDef.relation, finalQuery, { allowSimpleWhere: true });
							}
						},
						{
							localField: 'requirements',
							foreignKey: 'wffid_requirement',
							get: function (Resource, relationDef, instance, origGetter) {
								var query = { type: 60, wffid_requirement: instance.wfid }; // 60 = enums.objectType.requirement
								query = { where: query, orderBy: 'order' };

								return wfObject.filter(query);
							}
						},
						{
							localField: 'analyzeRequirements',
							foreignKey: 'wffid_analyzeRequirement',
							get: function (Resource, relationDef, instance, origGetter) {
								var query = { type: 109, wffid_analyzeRequirement: instance.wfid }; // 109 = enums.objectType.analyzeRequirement
								query = { where: query, orderBy: 'order' };

								return wfObject.filter(query);
							}
						},
						{
							localField: 'analyzeResults',
							foreignKey: 'wffid_analyzeResult',
							get: function (Resource, relationDef, instance, origGetter) {
								var query = { type: 111, wffid_analyzeResult: instance.wfid }; // 111 = enums.objectType.analyzeResult

								return wfObject.filter(query);
							}
						},
						{
							localField: 'contextChildren',
							foreignKey: 'wffid',
							get: function (Resource, relationDef, instance, origGetter) {
								var query = { parentType: instance.type, wffid: instance.wfid, parentData1: 3, type: { "!=": enums.objectType.historicDataRelation } };
								query = { where: query, orderBy: 'order' };

								return wfObject.filter(query);
							}
						},
						{
							localField: 'contextParents',
							foreignKey: 'wfcid',
							get: function (Resource, relationDef, instance, origGetter) {
								var query = { childType: instance.type, wfcid: instance.wfid, parentData1: 3, type: { "!=": enums.objectType.historicDataRelation } };
								query = { where: query, orderBy: 'order' };

								return wfObject.filter(query);
							}
						},
					]
				}
			};
		}

		function defineWfObject_lifecycleHooks() {
			var
				itemsBeforeUpdateByWfid = {}
			;

			return {
				// beforeInject: function (options, items) {
				// 	if (_.isArray(items)) {
				// 		for (var i = 0, len = items.length; i < len; i++) {
				// 			injectInCustomIndex(items[i]);
				// 		}
				// 	}
				// 	else {
				// 		injectInCustomIndex(items);
				// 	}
				// 	console.log("beforeInject", items);
				// },
				afterInject: function (options, items) {
					// console.log(items);
					if (_.isArray(items)) {
						for (var i = 0, len = items.length; i < len; i++) {
							injectInCustomIndex(items[i], options);
						}
					}
					else {
						injectInCustomIndex(items, options);
					}
				},
				afterEject: function (options, items) {
					if (_.isArray(items)) {
						for (var i = 0, len = items.length; i < len; i++) {
							ejectFromCustomIndex(items[i]);
						}
					}
					else {
						ejectFromCustomIndex(items);
					}
				},
				beforeUpdate: function (options, attrs, cb) {
					if (attrs.item && attrs.item.wfid) {
						itemsBeforeUpdateByWfid[attrs.item.wfid] = _.cloneDeep(attrs.item);
					}
					cb(null, attrs);
				},
				afterCreate: function (options, attrs, cb) {
					cb(null, attrs);
					setTimeout(function () {
						$rootScope.$broadcast("wfObject.created", attrs.wfid, wfObject.get(attrs.wfid));
					}, 0);
				},
				afterUpdate: function (options, attrs, cb) {
					if (itemsBeforeUpdateByWfid[attrs.wfid]) {
						attrs.metadata = itemsBeforeUpdateByWfid[attrs.wfid].metadata;

						if (itemsBeforeUpdateByWfid[attrs.wfid].creatorUserWfid)
							attrs.creatorUserWfid = itemsBeforeUpdateByWfid[attrs.wfid].creatorUserWfid;
					}
					cb(null, attrs);
				},
				afterDestroy: function (options, attrs, cb) {

					cb(null, attrs);
					$rootScope.$broadcast("wfObject.destroyed", attrs.wfid, attrs);
				}
			};
		}

	} ]).run([ 'DS', 'wfObject', function (DS, wfObject) {} ]);
	//.factory('Comment', function (DS) {
	//	// This code won't execute unless you actually
	//	// inject "Comment" somewhere in your code.
	//	// Thanks Angular...
	//	// Some like injecting actual Resource
	//	// definitions, instead of just "DS"
	//	return DS.defineResource('comment');
	//});


	function MyCustomAdapter(apiProxy, $q, wfObject, $timeout, wfAuth, $rootScope) {
		this.prepareObject = prepareObject;
		this.apiProxy = apiProxy;
		this.$q = $q;
		this.wfObject = wfObject;
		this.timeout = $timeout;
		this.wfAuth = wfAuth;
		this.$rootScope = $rootScope

		function prepareObject(attrs) {
			var ownAttrs = {};

			for (var key in attrs) {
				// The api proxy uses jQuery's AJAX logic internally which, when parameters are serialized, erroneously tries to invoke prototype functions
				// that are defined by JSData on the WfObject instance. To prevent this behaviour the properties are looped through and only the properties
				// that exists directly on the object will be sent in the request.

				if (attrs.hasOwnProperty(key) && typeof attrs[key] !== "function" && key !== "_cachedChildContent" && key !== "_cachedParentContent" && key !== "_cachedContextParentContent")
					ownAttrs[key] = attrs[key];
			}

			return ownAttrs;
		}
	}

	function wrappedWorldfavorUserWfid(profile) {
		var
			wfid = "100-" + profile.wf_userId
		;

		if (!wfObject.get(wfid)) {
			wfObject.inject({
				id: 0,
				wfid: wfid,
				user_id: profile.user_id,
				type: 100,
				username: profile.username,
				worldfavorUserId: profile.wf_userId,
				name: getMetadataOrDefaultValueOnUser(profile, "name"),
				email: getMetadataOrDefaultValueOnUser(profile, "email"),
				picture: getMetadataOrDefaultValueOnUser(profile, "picture"),
				given_name: getMetadataOrDefaultValueOnUser(profile, "given_name"),
				family_name: getMetadataOrDefaultValueOnUser(profile, "family_name"),
				phone_number: getMetadataOrDefaultValueOnUser(profile, "phone_number")
			});
		}

		return wfid;
	}

	function getMetadataOrDefaultValueOnUser(profile, key) {
		return profile.user_metadata && profile.user_metadata[key] ? profile.user_metadata[key] : profile[key];
	}

	// All of the methods shown here must return a promise

	// "definition" is a resource defintion that would
	// be returned by DS#defineResource

	// "options" would be the options argument that
	// was passed into the DS method that is calling
	// the adapter method

	MyCustomAdapter.prototype.create = function (definition, attrs, options) {
		var
			df = this.$q.defer(),
			$rootScope = this.$rootScope,
			prepareObject = this.prepareObject,
			wfObject = this.wfObject,
			order = this.order,
			wfAuth = this.wfAuth,
			action = options.action || "multi.createExtended",
			attrs = prepareObject(attrs)
		;

		this.apiProxy(action, attrs).then(function (res) {
			if (res.__hasErrors__) {
				displayErrorMessage(res, action, options, attrs);
				df.reject(res);
			}
			else {
				if (res.type == 73 || res.type == 81)
					res.order = newDataRelationsOrder--;

				if (res.userId) {
					res.creatorUserWfid = wfAuth.getWorldfavorUserWfid();
				}

				// Set from server
				if (res.type == 73) {
					wfObject.updateMetadataFromRelation(res, 1);
				}

				df.resolve(res);

				// console.log(wfObject.get(res.wfid))
			}
		}).catch(function(e) {
			if (e && e.statusText !== "abort") {
				console.error("Could not create() wfObject", attrs);
				df.reject.apply(df, arguments);
			}
		});

		return df.promise;
	};

	MyCustomAdapter.prototype.find = function (definition, wfid, options) {
		var df = this.$q.defer();
		var prepareObject = this.prepareObject;

		if (options.apiParams.ticket && options.apiParams.ticket.influence) {
			options.apiParams.ticket.influence = prepareObject(options.apiParams.ticket.influence);
		}
		//var
		//	objectSplit = wfid.split("-"),
		//	objectId = parseInt(objectSplit[1]),
		//	objectType = parseInt(objectSplit[0])
		//;
		//console.log(this.dataOps.prepareWfObejct(options));
		// console.info("FIND", wfid, options);
		this.apiProxy("multi.getObject", options.apiParams).then(function (res) {
			// if (res)
				df.resolve(res);
			// else
			// 	df.reject(); // Doesn't work
		}).catch(function(e) {
			if (e && e.statusText !== "abort") {
				console.error("Could not find() wfObject");
				df.reject.apply(df, arguments);
			}

		});

		return df.promise;
	};

	MyCustomAdapter.prototype.findAll = function (definition, params, options) {
		var
			df = this.$q.defer(),
			prepareObject = this.prepareObject
			// wfObject = this.wfObject,
			// timeout = this.timeout
		;

		// var jqDf = $.Deferred();

		if (options.apiParams.ticket && options.apiParams.ticket.influence) {
			options.apiParams.ticket.influence = prepareObject(options.apiParams.ticket.influence);
		}


		//console.log("findAll");
		this.apiProxy("multi." + (options.apiParams.action || "getObjects"), options.apiParams
		// {
		// 	item: options.apiParams.item,
		// 	kind: options.apiParams.kind,
		// 	searchString: options.apiParams.searchString,
		// 	childrenLoadDepth: options.apiParams.childrenLoadDepth,
		// 	skipChildContentOnDepth: options.apiParams.skipChildContentOnDepth,
		// 	aggregate: options.apiParams.aggregate,
		// 	aggregateObjectType: options.apiParams.aggregateObjectType,
		// 	networkId: options.apiParams.networkId,
		// 	culture: options.apiParams.culture,
		// 	wfids: options.apiParams.wfids
		// }
		).then(function (res) {
			// console.info("Before resolve");
			// wfObject.inject(res);
			// jqDf.resolve();
			// if (res)

				df.resolve(res);
			// else
			// 	df.reject(); // Doesn't work

			// console.info("After resolve");
		});

		// df.resolve([]);

		return df.promise;
	};

	MyCustomAdapter.prototype.update = function (definition, id, attrs, options) {
		var
			df = this.$q.defer(),
			action = options.action || "multi.updateExtended"
		;

		this.apiProxy(action, attrs).then(function (res) {
			if (res.__hasErrors__) {
				displayErrorMessage(res, action, options, attrs);
				df.reject(res);
			}
			else {
				if (options.prepareResult)
					df.resolve(options.prepareResult(res));
				else
					df.resolve(res);
			}
		})
		// .catch(function () {
		// });

		return df.promise;
	};

	MyCustomAdapter.prototype.updateAll = function (definition, attrs, params, options) {
	  // Must return a promise that resolves with the updated items
	};

	MyCustomAdapter.prototype.destroy = function (definition, wfid, options) {
		var
			df = this.$q.defer(),
			$rootScope = this.$rootScope,
			prepareObject = this.prepareObject,
			objectSplit = wfid.split("-"),
			objectId = parseInt(objectSplit[1]),
			objectType = parseInt(objectSplit[0])
		;

		let payload = options.item;
		if (options && options.thirdParty) {
			payload.thirdParty = options.thirdParty;
		}

		this.apiProxy("multi.delete", prepareObject(options.item)).then(function (res) {
			var relationItems;

			if (options.item.type == 73) {
				wfObject.updateMetadataFromRelation(options.item, -1);
			}
			else {
				relationItems = wfObject.filter({ where: { wfcid: options.item.wfid } });
				Array.prototype.push.apply(relationItems, wfObject.filter({ where: { wffid: options.item.wfid } }));

				_.each(relationItems, function (relationItem) {
					wfObject.updateMetadataFromRelation(relationItem, -1);
				});
			}

			if (options.item.type !== 73 && options.item.type !== 81 && options.item.type !== 61) {
				wfObject.ejectAll({ where: { type: 73, wfcid: wfid } }); // DataRelation
				wfObject.ejectAll({ where: { type: 73, wffid: wfid } }); // DataRelation
				wfObject.ejectAll({ where: { type: 81, wfcid: wfid } }); // VirtualDataRelation
				wfObject.ejectAll({ where: { type: 81, wffid: wfid } }); // VirtualDataRelation
				wfObject.ejectAll({ where: { type: 61, wfcid: wfid } }); // VisibilityTag
			}

			if (options.prepareResult)
				df.resolve(options.prepareResult(res));
			else {
				df.resolve(res);
			}
		});

		return df.promise
	};

	MyCustomAdapter.prototype.destroyAll = function (definition, params, options) {
	  // Must return a promise
	};

	function displayErrorMessage(result, action, options, attrs) {
		// try {
			if (result.__hasErrors__) {

				if (result.errorCode === 10) { // Unauthorized
					console.error("Error: Unauthorized API operation (" + action + ")", prettifyData(attrs))

					if (typeof window.Bugsnag !== "undefined")
						Bugsnag.notify("Unauthorized API operation", action, {
							"XHR": {
								"data": prettifyData(attrs)
							}
						});
				}
			}
		// }
		// catch (e) {

		// }

		function prettifyData(value) {
			try {
				return JSON.parse('{"' + decodeURI(value).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
			}
			catch (e) {
				return value;
			}
		}
	}
})();
