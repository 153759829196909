import * as enums from '@worldfavor/constants/enums'

(function() {
'use strict';

	angular
		.module('wf.common')
		.controller('DashboardController', DashboardController);

	DashboardController.$inject = [ '$scope', '$q', '$timeout', 'apiProxy', 'wfObject', '$rootScope', '$stateParams', '$state', 'screenLoaderService', 'dataOperationsService', '$translate', 'wfTranslate', 'wfAuth', 'statisticsService' ];
	function DashboardController($scope, $q, $timeout, apiProxy, wfObject, $rootScope, $stateParams, $state, screenLoader, dataOps, $translate, wfTranslate, wfAuth, statisticsService) {
		var
			authOrgId = wfAuth.getOrganizationId(),
			vm = this,
			mainStructure,
			conditions,
			isReportDashboard,
			featureAccessByContextVariable,
			featureAccessContextVariables,
			isoStandardWfids = [
				"71-21", // ISO 26000
				"71-903", // ISO 14001
				"71-10433", // ISO 20121
				"71-10394", // ISO 27001
				"71-990" // ISO 9001
			],
			currentIsoStandards,
			hidePromotionForOrgIds = [ 54195, 46712, 23607, 18796, 25247, 20330, 35317, 109264, 109084, 22777, 17018, 111857, 109123, 42570, 120088 ],
			sidePromises = []
		;

		vm.useCardItems = true;

		// loadStatistics();
		// return;
		activate();

		////////////////

		function activate() {
			dataOps.getObject({
				objectId: 11746,
				objectType: 71
			}).then(function (headerStructure) {
				vm.headerStructure = headerStructure;
				$("div.firstLoader").remove();

				$rootScope.setPageTitle("Dashboard");

				vm.organization = wfAuth.getOrganization();

				$timeout();

				// Root structure
				dataOps.getObject({
					objectId: 10010,
					objectType: 71,
					childrenLoadDepth: 3
				}).then(function (res) {
					mainStructure = res.childContent;
					vm.item = res;
					vm.itemDataRelations = _.filter(res.childContent.childs, function (relation) {
						return relation.childId != 18568; // Hide 'Sustainability Insights' in Dashboard
					});

					_.remove(vm.itemDataRelations, function (item) {
						var contextVariable1 = _.get(item, "childContent.conditions.contextVariable1")
						return contextVariable1 === "InternalReport";
					})

					var accountSideMenu = _.remove(vm.itemDataRelations, function (item) {
						var contextVariable1 = _.get(item, "childContent.conditions.contextVariable1")
						return contextVariable1 === "ACCOUNT_MENU";
					})[0];

					vm.canManageUsers = accountSideMenu && _.some(accountSideMenu.childContent.childs, function (item) {
						return _.get(item, "childContent.conditions.contextVariable1") === "ACCOUNT_OrgUsers"
					});

					vm.canChangeOrganizationSettings = accountSideMenu && _.some(accountSideMenu.childContent.childs, function (item) {
						return _.get(item, "childContent.conditions.contextVariable1") === "ACCOUNT_OrgSettings"
					});
					
					vm.items = _.map(vm.itemDataRelations, "childContent");

					transformItemsAndSetupStatistics();

					vm.showPromotions = true;

					if (_.includes(hidePromotionForOrgIds, authOrgId))
						vm.showPromotions = false;

					// console.log(vm, hidePromotionForOrgIds, authOrgId, _.includes(hidePromotionForOrgIds, authOrgId))
					// The old valueChain use contextVariable1 "ValueChain-OLD" so handle that here
					featureAccessByContextVariable = _.chain(vm.items).keyBy("conditions.contextVariable1").mapKeys(function (value, key) {
						return key === "ValueChain-OLD" ? "ValueChain" : key;
					}).value();

					featureAccessContextVariables = _.keys(featureAccessByContextVariable);

					// Only show items as a list if numbers of items is less then 3 and contains ISO Standards
					// or if there is only one item and that item is Report.
					if (vm.items.length < 3) {
						if (featureAccessByContextVariable["ISO_STANDARDS"])
							vm.useCardItems = false;
						else if (vm.items.length === 1 && featureAccessByContextVariable["Report"])
							vm.useCardItems = false;
					}

					// If one of the items is ISO Standards then put that on top
					if (featureAccessByContextVariable["ISO_STANDARDS"]) {
						vm.items = _.sortBy(vm.items, function (item) {
							return item.conditions.contextVariable1 != "ISO_STANDARDS";
						})
					}

						// vm.items = [];
					// Internal reporting
					dataOps.getObject({
						objectId: 13597, // Structure containing internal reporting influences.
						objectType: 71
					}).then(function (internalReportingStructure) {
						var allInternalInfluences = internalReportingStructure.childs;
						vm.unfinishedInternalInfluences = _.chain(allInternalInfluences).filter(function (dr) {
							return !dr.childContent.fulfilled;
						}).orderBy("childContent.title").value();

						// allInternalInfluences = [];
						// vm.unfinishedInternalInfluences = [];

						// If root is empty and no tasks assigned
						if (vm.items.length === 0 && allInternalInfluences.length === 0) {
							vm.showMainEmptyState = true;
						}
						// If root is empty and no assigned tasks left to finish
						else if (vm.items.length === 0 && allInternalInfluences.length > 0 && vm.unfinishedInternalInfluences.length === 0) {
							vm.showInterlReportEmptyState = true;
							vm.showInternalReportingInfluences = true;
						}
						else if (vm.unfinishedInternalInfluences.length > 0) {
							vm.showInternalReportingInfluences = true;
						}

						vm.mainItemsLoaded = true;
						$timeout();
					});

					loadUsers().then(function () {
						// Event log
						sidePromises.push($q(function (resolve, reject) {
							apiProxy.raw("multi.getObject", {
							// dataOps.getObject({
								objectId: 11723,
								objectType: 71,
								bypassCache: true,
								suppressErrorMessage: true
							}).then(function (eventLogResult) {
								wfObject.inject(eventLogResult);
								eventLogResult = wfObject.get(eventLogResult.wfid);

								var items = _.take(eventLogResult.childs, 10);
								var
									wfids = _.map(items, 'childContent.wfcid') // eventLogResult > childs(dataRelations) > childContent(logEvent) > childContent(items to get)
								;

								wfids = _.uniq(wfids);
								
								dataOps.getObjects(wfids).then(function () {
									$timeout(function () {
										var filteredLogEvents = [];

										vm.eventLog = eventLogResult;
										vm.logEvents = _.map(items, 'childContent');

										_.forEach(vm.logEvents, function (item) {
											if (item.childContent || item.objectData) {
												if (item.objectType === enums.objectType.dataRelation || item.objectType === enums.objectType.historicDataRelation) {
													if ((item.objectData && (!item.objectData.childContent || !item.objectData.parentContent)) || (item.childContent && (!item.childContent.childContent || !item.childContent.parentContent))) {
														return;
													}
												}

												filteredLogEvents.push(item);
											}
										});

										vm.logEvents = _.filter(filteredLogEvents, function (item) { 
											// return true;
											return typeof item !== "undefined" 
										});

										resolve();
									});
								});
							}, function () {
								vm.eventLog = null;
								vm.logEvents = []
								resolve();
							})
						}));

						if (vm.showPromotions) {
							// Each of the main features/pages on Worldfavor: Manage, Visualize, Data Collector and ISO Standards
							// that we promote have a contextVariable1 condition on the structure that indicates which one it is.
							// These are cross referenced with the promotion items to know which ones to promote

							currentIsoStandards = wfObject.filter({ where: { wfid: { type: enums.objectType.structure, "in": isoStandardWfids } } })

							// Structure that contains the header for the promo list
							sidePromises.push(dataOps.getObject({
								objectId: 13461,
								objectType: 71
							}).then(function (promoHeaderStructureResult) {
								vm.promoHeaderStructure = promoHeaderStructureResult;
							}));

							// Promotional items
							sidePromises.push(dataOps.getObject({
								objectId: 13423,
								objectType: 71
							}).then(function (promotionalResult) {
								// Compares promotional items with the items in the current user's root structure
								vm.promotionalItems = _.filter(promotionalResult.childs, function (item) {
									var promotionContextVariable;

									try {
										promotionContextVariable = item.childContent.conditions.contextVariable1;
									}
									catch (e) {
										return false; // If no value exists then don't show promo item
									}

									if (promotionContextVariable === "IsoStandards") {
										return currentIsoStandards.length === 0;
									}

									return !featureAccessByContextVariable.hasOwnProperty(promotionContextVariable);
								});
							}));
						}

						$q.all(sidePromises).then(function () {
							vm.sideContentLoaded = true
						});
					});
				});
			});
		}

		function loadUsers() {
			return $q(function (resolve, reject) {
				// Users on org
				dataOps.getObject({
					objectId: 10051,
					objectType: 71,
					suppressErrorMessage: true
				}).then(function (users) {
					$timeout(function () {
						vm.users = _.map(users.childs, 'childContent');
					});
					resolve();
				}, function () {
					resolve();
				});
			});
		}

		function transformItemsAndSetupStatistics() {
			/*

			A statistics object from the server is returned when onlyStatistics is true in the request parameters.
			The statistics object is in the metadata object on the structure and contains the following:
			{
				count: 1,
				datasets: [ // Historic data grouped by a time unit (default is month and year), always includes count apart from any additional datasets, like fulfilledCount
					{
						id: "count",
						data: [
							{ year: 2015, month: 2, value: 1 },
							...
						]
					},
					...
				],
				totals: [ // Contains additional totals apart from the main count value (count is always a property of the statistics object is never included in the totals array)
					{ id: "fulfilledCount", value: 1 }
				]
			}

			Depending on the options in the statisticsSettings object different structures will be loaded and prepared in different ways.

			 */
			var
				forceEmptyState = false,
				statisticsSettings =  {
					manage: {
						asEmphasizedTotals: true, // The count value from every structure will be displayed with each structure's title as the label
						structureIds: [
							13546, // ISO Standards
							13547, // Standards & guidelines
							203, // Custom frameworks
							14497 // Internal Influences sent by organization
						],
						accessStructures: {
							14497: "71-13593" // For structureId 14497, structure 13593 is used to check if the organization has access (internal influences / collaboration tool)
							// 14497 is used to return statistics and 13593 is the structure that represents the menu item (Manage > Collaboration tool)
						},
						bgColor: "#4799DB",
						contextVariable1: "MANAGE",
					},
					visualize: {
						combinedData: true, // All statistics from the structures will be combined into a single set of statistics
						structureIds: [
							11971, // Widgets
							11953 // Sustainability Rooms
						],
						bgColor: "#34485E",
						contextVariable1: "Visualize",
						emptyStateHeader: $translate.instant("modules.dashboard.visualize.emptyStateHeader"),
						chartType: "line",
						additionalRequestParams: {
							includeReadStatistics: true
						},
						labels: {
							reads: $translate.instant("modules.dashboard.visualize.totalViews")
						},
						datasetPrototype: {
							backgroundColor: function () {
								var offscreen = document.createElement('canvas'); // detached from DOM
								var ctx = offscreen.getContext('2d');
								var bgGradient = ctx.createLinearGradient(0, 0, 0, 110);
								bgGradient.addColorStop(0, 'rgba(0, 0, 0, 0.15)');
								bgGradient.addColorStop(1, 'rgba(0, 0, 0, 0)');
								return bgGradient;
							}
						},
						onBeforeSetup: function (structureStats) { // Called for each structure specified in structureIds when they have finished loading but before any other logic has executed
							// Modify the statistics object of each structure so that only "reads" data is included
							var modifiedStats = _.cloneDeep(structureStats);
							modifiedStats.datasets = _.filter(modifiedStats.datasets, { id: "reads" });
							modifiedStats.totals = _.filter(modifiedStats.totals, { id: "reads" });

							return modifiedStats;
						}
					},
					valueChain: {
						cssClass: "valueChain",
						structureId: 14496,
						bgColor: "#ED9D0D",
						contextVariable1: "ValueChain",
						emptyStateHeader: $translate.instant("modules.dashboard.valueChain.emptyStateHeader"),
						chartType: "line",
						labels: {
							count: $translate.instant("modules.dashboard.valueChain.sentRequest"),
							fulfilledCount: $translate.instant("modules.dashboard.valueChain.fulfilledRequests")
						}
					},
					report: {
						cssClass: "report",
						structureId: 10489,
						bgColor: "#48CE76",
						contextVariable1: "Report",
						emptyStateHeader: $translate.instant("modules.dashboard.report.emptyStateHeader"),
						chartType: "donut", // Will show a donut chart with the totals in fractional style (ex: 4/10) in the middle
						label: $translate.instant("modules.dashboard.report.completed")
					},
					eventLog: {
						structureId: 11723,
						contextVariable1: "EventLog",
						chartType: "line",
						datasetPrototype: {
							height: 80,
							borderColor: "#4799DB",
							borderWidth: 4,
							backgroundColor: function () {
								var offscreen = document.createElement('canvas'); // detached from DOM
								var ctx = offscreen.getContext('2d');
								var bgGradient = ctx.createLinearGradient(0, 0, 0, 60);
								bgGradient.addColorStop(0, 'rgba(71, 153, 219, 0.4)');
								bgGradient.addColorStop(1, 'rgba(71, 153, 219, 0)');
								return bgGradient;
							}
						},
						onAfterSetup: function (stats) {
							stats.chartJsOptions.scales.xAxes[0].display = false;
						}
					}
				},
				rootItems = _.keyBy(vm.items, "conditions.contextVariable1")
			;

			vm.items = _.map(vm.itemDataRelations, function (dataRelation) {
				var itemContent = dataRelation.childContent;
				return {
					content: itemContent,
					dataRelation: dataRelation,
					conditions: itemContent.conditions,
					key: _.findKey(statisticsSettings, { "contextVariable1": itemContent.conditions.contextVariable1 }),
					stats: _.find(statisticsSettings, { "contextVariable1": itemContent.conditions.contextVariable1 })
				}
				//  === "ValueChain" ? 'VALUE_CHAIN' : item.conditions.contextVariable1
			});

			vm.eventLogStats = statisticsSettings.eventLog;

			statisticsService.loadStatistics(vm.eventLogStats);
		}
	}
})();
