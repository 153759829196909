import {
  SET_AUTH_USER,
  SET_TOKEN,
  SET_ENFORCED_CULTURE,
  SET_USER_INFO,
  SET_CURRENT_USER,
  CHANGE_ORGANIZATION,
  LOGOUT,
  RESET_APP_STATE,
} from './'

export const setAuthUser = (authUser) => {
  return {
    type: SET_AUTH_USER,
    authUser,
  }
}

export const setToken = (token) => {
  return {
    type: SET_TOKEN,
    token,
  }
}

export const setEnforcedCulture = (enforcedCulture) => {
  return {
    type: SET_ENFORCED_CULTURE,
    enforcedCulture,
  }
}

export const setUserInfo = (userInfo) => {
  return {
    type: SET_USER_INFO,
    userInfo,
  }
}

export const setCurrentUser = (currentUser) => {
  return {
    type: SET_CURRENT_USER,
    currentUser,
  }
}

export const changeOrganization = () => {
  return {
    type: CHANGE_ORGANIZATION,
  }
}

export const logout = () => {
  return {
    type: LOGOUT,
  }
}

export const resetState = () => {
  return {
    type: RESET_APP_STATE,
  }
}
