import axios from 'axios'

// Refer to https://developers.arcgis.com/rest/geocode/api-reference/geocoding-category-filtering.htm
// for complete documentation about category filtering
export const Categories = {
  StreetInt: {
    name: 'Intersection',
    featureType: 'StreetInt',
    zoom: 17,
  },
  DistanceMarker: {
    name: 'Distance Marker',
    featureType: 'DistanceMarker',
    zoom: 17,
  },
  StreetAddress: {
    name: 'Street Address',
    featureType: 'StreetAddress',
    zoom: 17,
  },
  StreetName: {
    name: 'Street Name',
    featureType: 'StreetName',
    zoom: 17,
  },
  POI: {
    name: 'POI',
    featureType: 'POI',
    zoom: 16,
  },
  PointAddress: {
    name: 'Point Address',
    featureType: 'PointAddress',
    zoom: 16,
  },
  Postal: {
    name: 'Postal',
    featureType: 'Postal',
    zoom: 12,
  },
  Locality: {
    name: 'Populated Place',
    featureType: 'Locality',
    zoom: 12,
  },
}

const BASE_URL = 'https://geocode.arcgis.com/arcgis/rest/services/World'

const request = (method, url, payload, headers) => {
  return axios(
    {
      method,
      url: `${BASE_URL}${url}`,
      data: payload,
      headers,
    },
  )
}

export const suggest = (query, location, categories = [], maxSuggestions = 5) => {
  let url = `/GeocodeServer/suggest?f=json&category=${categories.join(',')}&text=${query}&maxSuggestions=${maxSuggestions}`
  if (location) {
    url += `&location=${location.lng},${location.lat}`
  }
  return request('GET', url)
}

export const geocode = (query, location, magicKey, categories = []) => {
  let url = `/GeocodeServer/findAddressCandidates?f=json&singleLine=${query}&category=${categories.join(',')}&outFields=*`
  if (location) {
    url += `&location=${location.lng},${location.lat}`
  }
  if (magicKey) {
    url += `&magicKey=${magicKey}`
  }
  return request('POST', url)
}

export const reverseGeocode = (latitude, longitude, featureTypes = []) => {
  return request('GET', `/GeocodeServer/reverseGeocode?f=json&featureTypes=${featureTypes.join(',')}&location=${longitude},${latitude}&outFields=*`)
}
