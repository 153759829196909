import React from 'react'
import { FormattedMessage } from 'react-intl'
import Colors from '@worldfavor/constants/colors'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

const RemoveOrganizationWarningDialog = (props) => {
  const { open, onClose, organization, ...rest } = props
  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      <DialogTitle>
        <FormattedMessage
          id={'supplyChain.mapper.removeOrganizationFromTree.title'}
          values={{ OrganizationName: <span style={{ color: Colors.red }}>{organization.name}</span> }}
        />
      </DialogTitle>
      <DialogContent>
        <span>
          <FormattedMessage
            id={'supplyChain.mapper.removeOrganizationFromTree.description'}
            values={{ OrganizationName: <strong>{organization.name}</strong> }}
          />
        </span>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          <FormattedMessage id={'general.close'} />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RemoveOrganizationWarningDialog
