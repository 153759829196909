// @flow
import * as React from 'react'
import CreationDateHeader from '../CreationDateHeader'
import CreatorHeader from '../CreatorHeader'
import Divider from '../Divider'
import classNames from 'classnames'
import Colors from '@worldfavor/constants/colors'
import { FormattedNumber, FormattedMessage } from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import TooltipArrow from '../TooltipArrow'

type Props = {
  label: string,
  detailedLabel: string | React.Node,
  average?: number,
  organizationCount?: number,
  value: number,
  unit: string,
  createdAt?: string,
  creator?: {
    imageUrl: string,
    name: string,
  },
  valueColor?: string,
  isInconsistent?: boolean,

  classes: { +[string]: string },
  style?: { [string]: any },
}

const styles = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  valueContainer: {
    // flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  label: {
    color: Colors.blackDark,
    fontWeight: 'bold',
    fontSize: 16,
  },
  value: {
    fontSize: 55,
    fontWeight: 'bold',
    marginRight: 6,
  },
  unit: {
    fontSize: 16,
    color: Colors.grayDark,
  },
  metaContainer: {
    display: 'flex',
    alignItems: 'center',
    justifySelf: 'flex-end',
    // marginTop: 25,
  },
  tooltip: {
    margin: 0,
    fontSize: 12,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  tooltipBody: {
    paddingTop: 3,
  },
  dataValue: {
    fontSize: 15,
  },
  bold: {
    fontWeight: 'bold',
  },
  inconsistent: {
    margin: "auto",
    padding: "10px",
    backgroundColor: "#bcbcbce6",
    borderRadius: "5px",
    position: "absolute",
    left: 50,
    right: 50,
    top: "36%",
    textAlign: "center",
    fontSize: "12px",
    textShadow: "0 0 15px #00000038",
    boxShadow: "0 0 0 1px #ffffff38",
    color: "#fff",
    fontWeight: 500,
  }
}

class SinglePointChart extends React.PureComponent<Props> {

  _getTooltipBody = () => {
    const { classes, average, organizationCount, unit } = this.props
    if ((average || average === 0) || organizationCount) {
      return (
        <div className={classes.tooltipBody}>
          {
            (average || average === 0) && (
              <div>
                <FormattedMessage
                  id="charts.label.average"
                  values={{
                    value: (
                      <span className={classNames(classes.dataValue, classes.bold)}>
                        { `${average}${unit && ` ${unit}`}` }
                      </span>
                    ),
                  }}
                />
              </div>
            )
          }
          {
            !!organizationCount && (
              <div>
                <FormattedMessage
                  id={`charts.label.organization`}
                  values={{
                    count: organizationCount,
                    countSpan: <span className={classes.bold}>{ organizationCount }</span>,
                  }}
                />
              </div>
            )
          }
        </div>
      )
    }
    return null
  }

  _getTooltipContent = () => {
    const { detailedLabel } = this.props
    return (
      <div>
        { detailedLabel }
        { this._getTooltipBody() }
      </div>
    )
  }

  renderCreationInfo() {
    const { classes, createdAt, creator } = this.props
    return (
      <div className={classes.metaContainer}>
        {
          creator && (
            <CreatorHeader
              creator={creator}
              avatarSize={20}
              avatarStyle={{ marginRight: 10 }}
              nameStyle={{ color: Colors.grayText, fontWeight: 500 }}
            />
          )
        }
        {
          creator && createdAt && (
            <Divider width={2} height={12} margin={'0 10px'} color={Colors.gray} />
          )
        }
        {
          createdAt && (
            <CreationDateHeader
              value={createdAt}
              iconStyle={{ paddingTop: 2, color: Colors.grayDark }}
              textStyle={{ color: Colors.grayDark }}
            />
          )
        }
      </div>
    )
  }

  render() {
    const { classes, label, value, unit, valueColor, style, isInconsistent } = this.props
    const decimals = value < 1 ? 8 : 2
    return (
      <div className={classes.container} style={style}>
        <div className={classes.valueContainer}>
          {isInconsistent && (<div className={classes.inconsistent}><i className="fa fa-info-circle" style={{ "marginRight": 10 }} /><span><FormattedMessage id={'itemStatistics.inconsistentData'} /></span></div>)}
          <span className={classes.label}>{ label }</span>
          <TooltipArrow
            tooltipClasses={classes.tooltip}
            title={this._getTooltipContent()}
            placement="top"
          >
            <div style={{ lineHeight: 'initial', paddingLeft: 8, paddingRight: 8 }}>
              <span className={classes.value} style={{ color: valueColor || Colors.defaultColor }}>
                <FormattedNumber value={value} maximumFractionDigits={decimals} />
              </span>
              {
                unit && <span className={classes.unit}>{ unit }</span>
              }
            </div>
          </TooltipArrow>
        </div>
        { this.renderCreationInfo() }
      </div>
    )
  }
}

export default withStyles(styles)(SinglePointChart)
