// @flow
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage, injectIntl } from 'react-intl'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Api from '../../api'
import Colors from '@worldfavor/constants/colors'
import dateFns from 'date-fns'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import LanguageField from '@worldfavor/components/LanguageField'
import classNames from 'classnames'
import CircularProgressWrapper from '../../../components/CircularProgressWrapper'

const useStyles = makeStyles(theme => ({
  dialogDescription: {
    fontSize: 14,
  },
  titleContainer: {
    paddingRight: 40,
  },
  closeButton: {
    color: Colors.blue,
  },
  textFieldSize: {
    fontSize: theme.typography.fontSizes.medium,
  },
  alertDialogContent: {
    display: 'flex',
    alignItems: 'center',
  },
  alertDialogIcon: {
    fontSize: 56,
    marginRight: 20,

    '&.info': {
      color: Colors.blue,
    },
    '&.success': {
      color: Colors.green,
    },
    '&.error': {
      color: Colors.red,
    },
  },
}))

type Props = {
  open: boolean,
  packages: any[],
  networkId: number,
  onClosed: () => void,
  intl: any
}

const RequirementPackageExporter = (props: Props) => {
  const { open, packages, networkId, onClosed, intl } = props
  const classes = useStyles(props)
  const [loading, setLoading] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState('en-US')
  const [selectedRequirementPackages, setSelectedRequirementPackages] = useState(packages || [])

  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false)
  const [alertDialogTitle, setAlertDialogTitle] = useState()
  const [alertDialogContent, setAlertDialogContent] = useState()
  const [alertDialogType, setAlertDialogType] = useState()
  const [AlertDialogIconComponent, setAlertDialogIconComponent] = useState()
  const [fileUploadSuccess, setFileUploadSuccess] = useState(false)

  useEffect(() => {
    setSelectedRequirementPackages(packages || [])
  }, [packages, networkId])

  function onClose() {
    if (loading) {
      showBusyDialog()
      return
    }

    onClosed && onClosed()
    setIsAlertDialogOpen(false)

    setTimeout(() => {
      setFileUploadSuccess(false)
      setLoading(null)
      setAlertDialogTitle(null)
      setAlertDialogContent(null)
      setAlertDialogType(null)
      setAlertDialogIconComponent(null)
    }, 250)
  }

  async function onExportClick() {
    setLoading(true)
    onExport()
  }

  async function onExport() {
    try {
      const packageIds = selectedRequirementPackages.map(rp => rp.id)

      const exportResult = await Api.post(Api.endpoints.exportRequirementPackages,
        { networkId, packageIds, language: selectedLanguage }, {
        }, null, { overrideData: true, responseType: 'blob' })

      const now = dateFns.format(new Date(), 'YYYYMMDD_HHmmss')
      const downloadedFileName = `WF Export - Network ${networkId} Requirement packages ${now}.xlsx`

      setTimeout(() => {
        setFileUploadSuccess(true)
        showSuccessDialog(downloadedFileName)
      }, 250)

      const url = URL.createObjectURL(exportResult.data)
      const link = document.createElement('a')
      link.href = url
      link.download = downloadedFileName
      link.style = { display: 'none' }
      document.body.append(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)

    } catch {
      setTimeout(() => showErrorDialog(), 250)
    } finally {
      setIsAlertDialogOpen(false)
      setTimeout(() => setLoading(false), 400)
    }
  }

  function showSuccessDialog(downloadedFileName) {
    setAlertDialogTitle(intl.formatMessage({ id: 'requirementPackageExporter.infoDialog.successExportTitle' }))
    setAlertDialogContent(intl.formatMessage({ id: 'requirementPackageExporter.infoDialog.successExportDescription' }, {
      downloadedFileName: <strong>{downloadedFileName}</strong>,
      br: <br />,
    }))
    setAlertDialogType('success')
    setAlertDialogIconComponent(CheckCircleIcon)
    setIsAlertDialogOpen(true)
  }

  function showBusyDialog() {
    setAlertDialogTitle(intl.formatMessage({ id: 'requirementPackageExporter.infoDialog.busyExportingTitle' }))
    setAlertDialogContent(intl.formatMessage({ id: 'requirementPackageExporter.infoDialog.busyExportingDescription' }))
    setAlertDialogType('info')
    setAlertDialogIconComponent(InfoIcon)
    setIsAlertDialogOpen(true)
  }

  function showErrorDialog() {
    setAlertDialogTitle(intl.formatMessage({ id: 'requirementPackageExporter.infoDialog.errorTitle' }))
    setAlertDialogContent(intl.formatMessage({ id: 'requirementPackageExporter.infoDialog.errorDescription' }))
    setAlertDialogType('error')
    setAlertDialogIconComponent(ErrorIcon)
    setIsAlertDialogOpen(true)
  }

  function closeAlertDialog() {
    setTimeout(() => {
      setAlertDialogTitle(null)
      setAlertDialogContent(null)
      setAlertDialogType(null)
      setAlertDialogIconComponent(null)
    }, 500)

    setIsAlertDialogOpen(false)
  }

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value)
    setFileUploadSuccess(false)
  }

  const handleRequirementPackagesChange = (event) => {
    setSelectedRequirementPackages(event.target.value)
    setFileUploadSuccess(false)
  }

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>
          <div className={classes.titleContainer}>
            <FormattedMessage
              id={'requirementPackageExporter.dialogTitle'}
            />
          </div>
        </DialogTitle>
        <DialogContent style={{ minWidth: 600 }}>
          <div className={classes.dialogDescription}>
            <FormattedMessage
              id={'requirementPackageExporter.dialogDescription'}
            />
          </div>
          <Box mt={2} mb={2}>
            <LanguageField
              disabled={loading}
              value={selectedLanguage}
              onChange={handleLanguageChange}
              style={{ width: 300 }}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                classes: {
                  select: classes.textFieldSize,
                },
              }}
              margin="dense"
              variant="outlined"
            />
          </Box>
          <Box mt={2} mb={2} style={{ display: 'flex' }}>
            <TextField
              disabled={loading}
              select
              label={intl.formatMessage({ id: 'requirementPackageExporter.requirementPackages' })}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 600 }}
              margin="dense"
              variant="outlined"
              SelectProps={{
                onChange: handleRequirementPackagesChange,
                value: selectedRequirementPackages,
                multiple: true,
                renderValue: selected => selected.map(x => x.title).join(', '),
                classes: {
                  select: classes.textFieldSize,
                },
              }}
            >
              {
                packages && (
                  packages.map(p => (
                    <MenuItem key={`package-${p.id}`} value={p}>
                      <Checkbox
                        checked={selectedRequirementPackages && selectedRequirementPackages.indexOf(p) > -1}
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                      />
                      {p.title}
                    </MenuItem>
                  ))
                )
              }
            </TextField>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            <FormattedMessage id={'exportDialog.close'} />
          </Button>
          <CircularProgressWrapper
            loading={loading}
          >
            <Button
              disabled={loading || fileUploadSuccess || selectedRequirementPackages.length === 0}
              onClick={onExportClick}
              variant="contained"
              style={fileUploadSuccess ? { backgroundColor: Colors.green } : loading ? null : { backgroundColor: Colors.blue }}
              color="primary"
            >
              {fileUploadSuccess ? <CheckCircleIcon /> : <FormattedMessage id={'exportDialog.export'} />}
            </Button>
          </CircularProgressWrapper>
        </DialogActions>
      </Dialog>

      <Dialog open={isAlertDialogOpen}>
        <DialogTitle>
          {alertDialogTitle}
        </DialogTitle>
        <DialogContent>
          <div className={classes.alertDialogContent}>
            {AlertDialogIconComponent && <AlertDialogIconComponent className={classNames(classes.alertDialogIcon, alertDialogType)} />}
            <div>
              {alertDialogContent}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAlertDialog} color="secondary">
            <FormattedMessage id={'general.ok'} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default injectIntl(RequirementPackageExporter)
