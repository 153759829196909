import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfNotification', wfNotification);

		
		wfNotification.$inject = [ '$q', '$timeout', 'apiProxy', 'wfObject', '$rootScope', '$stateParams', '$state', 'screenLoaderService', 'dataOperationsService', '$translate', 'wfTranslate', 'wfPropertyExtractor', '$parse', 'wfAuth' ];
		function wfNotification($q, $timeout, apiProxy, wfObject, $rootScope, $stateParams, $state, screenLoader, dataOps, $translate, wfTranslate, wfPropertyExtractor, $parse, wfAuth) {
			var
			objType = enums.objectType,
			eventType = enums.eventType,
			clickableTypes = [ enums.objectType.influence, enums.objectType.network ],
			quoteCharRegexp = new RegExp(/\"/g),
			onlyLettersRegExp = new RegExp(/[a-z]/i),
			lastWhitespaceIndexRegExp = new RegExp(/\s\S*$/),
			userDataObjectTypes = _.keyBy([
				enums.objectType.orgActivity,
				enums.objectType.questionAnswer,
				enums.objectType.measureAnswer,
				enums.objectType.parameterValue,
				enums.objectType.orgDocument,
				enums.objectType.statement,
				enums.objectType.link,
				enums.objectType.location,
				enums.objectType.invoice,
				enums.objectType.embed,
				enums.objectType.certificate
			])
		;

		var directive = {
			// bindToController: true,
			// controller: ControllerController,
			// controllerAs: 'notificationVm',
			link: link,
			templateUrl: "scripts/wf/notifications/wfNotification.directive.html",
			restrict: 'E',
		};
		return directive;
		
		function link(scope, element, attrs) {
			var
				item,
				objectData,
				ownAttrs,
				authOrgId = wfAuth.getOrganizationId(),
				author,
				vm = scope.notificationVm = {},
				disableNavigation = 'disableNavigation' in attrs,
				eventInfoItem,
				contentItem
			;

			vm.excludeTimeAgo = 'excludeTimeAgo' in attrs;

			if (scope.signalREvent) {
				eventInfoItem = scope.signalREvent
				contentItem = scope.signalREvent.objectData;

				// This code was haunting the app for almost a year...
				// All items that were not a dataRelation had wfcid set to its own wfid value
				// beacuse childContent is a getter/setter property and both childContent properties pointed to the same object.
				// The original purpose of the code is unclear.
				// if (scope.signalRItem.objectType !== enums.objectType.dataRelation)
				// 	scope.item.childContent = scope.signalRItem.childContent;
			}
			else {
				if ("item" in attrs)
					item = $parse(attrs.item)(scope);
				else
					item = scope.item;

				if (item.type == enums.objectType.dataRelation || item.type == enums.objectType.virtualDataRelation)
					item = item.objectData || item.childContent;
				else
					item = item;

				if (!item.childContent && item.objectData) {
					ownAttrs = {};

					for (var key in item) {
						if (item.hasOwnProperty(key))
							ownAttrs[key] = item[key];
					}
					ownAttrs.creatorUser = item.creatorUser;
					
					item = ownAttrs;
					item.childContent = item.objectData;

					eventInfoItem = item;
					contentItem = item.objectData
				}
				else if (item.objectData) {
					eventInfoItem = item
					contentItem = item.objectData;
				}
				else if (item.childContent) {
					eventInfoItem = item
					contentItem = item.childContent;
				}
				else if (item.type === enums.objectType.notification) {
					eventInfoItem = item;
				}
				
				if (eventInfoItem.type === enums.objectType.notification) {
					vm.isNotification = true;
				}
			}
			// console.log(item.id, "----------------------------------------------------");
			vm.noContent = true;

			// try {
				author = getAuthor(eventInfoItem, contentItem);
				vm.item = item;
				vm.eventSentence = getEventSentence(eventInfoItem, contentItem, author, authOrgId, vm);
				// console.log(vm.eventSentence);
				_.assign(vm, {
					isDetailsCollapsed: true,
					showApiIntegrationMessage: eventInfoItem.organizationId === 42570 && eventInfoItem.httpStatusCode === 400 && eventInfoItem.requestParameters,
					eventInfoItem: eventInfoItem,
					isAttemptedEvent: eventInfoItem.httpStatusCode === 400,
					author: getAuthorName(author, eventInfoItem, contentItem),
					imageUrl: getImageUrl(eventInfoItem, contentItem),
					createdAt: getCreatedAt(eventInfoItem, contentItem),
					clickable: contentItem && _.includes(clickableTypes, contentItem.type),
					urlPath: wfPropertyExtractor.getUrlPath(contentItem)
			//noContent: !item.childContent || item.objectType === enums.objectType.loginSession || item.objectType === enums.objectType.individual 
				});

			if (vm.noContent) {
				setTimeout(function() {
					scope.$emit("itemLinked", item);
				}, 0);
			}

			vm.navigateToItem = function () {
				// console.log(item)
				if (!disableNavigation) {
				switch (contentItem.type)
				{
					// case 73: // DataRelation
					// 	$state.go('dataRelation', { idPath: item.childContent.id });
					// 	break;
					// case 71: // Structure
					// 	$state.go('structure', { idPath: item.childContent.id });
					// 	break;
					case 52: // Network
						$state.go('network', { idPath: contentItem.id });
						break;
					case 13: // Influence
						$state.go('influence', { idPath: contentItem.id });
						break;
					}
					return false;
				}
			}
		}

		function getCreatedAt(eventInfoItem, contentItem) {
			switch (eventInfoItem.type) {
				case enums.objectType.logEvent:
				case enums.objectType.notification:
				case enums.objectType.dataRelation:
				case enums.objectType.loginSession:
					return eventInfoItem.createdAt
				default:
					return eventInfoItem.createdAt
			}
		}

		function getImageUrl(eventInfoItem, contentItem) {
			var 
				objType = eventInfoItem.objectType
			;

			try {
				switch (objType) {
					// case enums.objectType.logEvent:
					// case enums.objectType.notification:
					// 	return item.childContent.creatorUser.name;
					case enums.objectType.influence:
						if (eventInfoItem.eventType === enums.eventType.update || eventInfoItem.eventType === enums.eventType.create || eventInfoItem.eventType === enums.eventType["delete"])
							return eventInfoItem.creatorUser.imageUrl;
						else
							return wfPropertyExtractor.getImageUrl(contentItem.childContent);
						// return item.childContent.organization.name;
					case enums.objectType.loginSession:
						return eventInfoItem.creatorUser.imageUrl;
					default:
						return eventInfoItem.creatorUser.imageUrl;
				}
			}
			catch (e) {}

			// try {
			// 	if (objType === enums.objectType.influence) {
			// 		return item.childContent.organization.imageUrl;
			// 	}
			// 	else if (objType === enums.objectType.loginSession) {
			// 		return item.creatorUser.picture;
			// 	}
			// 	else
			// 		return item.childContent.creatorUser.picture;
			// }
			// catch (e) {}
		}

		function getAuthor(eventInfoItem, contentItem) {
			var 
				objType = eventInfoItem.objectType
			;

			try {
				switch (objType) {
					// case enums.objectType.logEvent:
					// case enums.objectType.notification:
					// 	return item.childContent.creatorUser.name;
					case enums.objectType.influence:
						if (eventInfoItem.type === enums.objectType.logEvent) {
							return eventInfoItem.creatorUser;
						}
						else if (eventInfoItem.eventType == enums.eventType.fulfill || eventInfoItem.eventType == enums.eventType.unfulfill || eventInfoItem.eventType == enums.eventType.sign)
							return contentItem.organization;
						else
							return contentItem.creatorOrganization;

					case enums.objectType.loginSession:
						return eventInfoItem.creatorUser;
					default:
						return eventInfoItem.creatorUser;
				}
			}
			catch (e) {}

		}

		function getAuthorName(author, eventInfoItem, contentItem) {
			if (author && author.name)
				return author.name;
			else
				return $translate.instant("Somebody");
		}

		function getEventSentenceNew() {
			var array = [
				{
					eventType: enums.eventType.update,
					objectType: enums.eventType.update
				}
			]
		}

		function getEventSentence(eventInfoItem, contentItem, author, authOrgId, vm) {
			var
				output,
				itemEventType = eventInfoItem.eventType,
				translateParams,
				includeItemText = true,
				itemText
			;

			// console.log(item2, item)
			// if (!item.childContent.organization) return;
			// console.log(item);
			switch (contentItem.type) {
				case objType.influence:
					// console.log(eventType)
					// if (itemEventType === enums.eventType.fulfill) {
					// 	output = [ $translate.instant("modules.notifications.eventSentences.influenceFulfilled") ].join(" ");
					// }
					// else if (itemEventType === enums.eventType.unfulfill) {
					// 	output = [ $translate.instant("modules.notifications.eventSentences.influenceUnfulfilled") ].join(" ");
					// }
					// else
					if (itemEventType === enums.eventType.sign) {
						output = [
							wfTranslate.instant('MAP_EventType', { type: itemEventType, pastTense: true }),
							wfTranslate.instant('MAP_ObjectType', { type: contentItem.type, DA: true, IA: false }),
							getBoldItemTextHtml(contentItem),
							eventInfoItem.objectData && eventInfoItem.objectData.hasOwnProperty("fulfilled") ? "(" + ($translate.instant(eventInfoItem.objectData.fulfilled ? "Fulfilled" : "NotFulfilled")) + ")" : ""
						];
					}
					break;
				case objType.loginSession:
					output = $translate.instant('modules.notifications.eventSentences.signedIn');
					break;
				case objType.dataRelation:
				case objType.historicDataRelation:
					translateParams = {
						sourceTypeWord: wfTranslate.instant('MAP_ObjectType', { type: contentItem.childType, DA: true }),
						sourceText: contentItem.childContent ? wfPropertyExtractor.getMainTextual(contentItem.childContent, { html: false, useFriendlyTitle: true }) : undefined,
						targetText: contentItem.parentContent ? wfPropertyExtractor.getMainTextual(contentItem.parentContent, { html: false, useFriendlyTitle: true }) : undefined
					}
					
					if (translateParams.targetText)
						translateParams.targetText_cut = cutString(translateParams.targetText, 100);
					
					if (translateParams.sourceText)
						translateParams.sourceText_cut = cutString(translateParams.sourceText, 100);
						
					if (translateParams.targetText && translateParams.sourceText) {
						translateParams.targetText = translateParams.targetText.replace(quoteCharRegexp, "&quot;")
						translateParams.sourceText = translateParams.sourceText.replace(quoteCharRegexp, "&quot;")
					}
					
					if (itemEventType == eventType["delete"]) {
						if (translateParams.sourceText && translateParams.targetText) {
							if (contentItem.parentType === enums.objectType.structure
								&& _.includes([ enums.objectType.structure, enums.objectType.question, enums.objectType.measure, enums.objectType.organization ], contentItem.childType)
							) {
								if (eventInfoItem.httpStatusCode === 400)
									output = [ $translate.instant('AttemptedTo'), $translate.instant('Uncategorize').toLowerCase(), $translate.instant('modules.notifications.eventSentences.textPart_sourceFromTarget', translateParams) ]
								else
									output = [ $translate.instant('modules.notifications.eventSentences.uncategorized', translateParams) ]
							}
							else if (userDataObjectTypes[contentItem.childType])
								output = [ $translate.instant('modules.notifications.eventSentences.deletedDataRelation_specific_withObjectTypeWord', translateParams) ]
							else
								output = [ $translate.instant('modules.notifications.eventSentences.deletedDataRelation_specific', translateParams) ]
						}
						else {
							output = [ $translate.instant('modules.notifications.eventSentences.deletedDataRelation') ]
						}
					}
					else if (contentItem && contentItem.parentType == objType.question && contentItem.childType == objType.questionAnswer) {
						if (translateParams.sourceText && translateParams.targetText) {
							output = [ $translate.instant('modules.notifications.eventSentences.questionAnswered_specific', translateParams) ]
						}
						else {
							output = [ $translate.instant('modules.notifications.eventSentences.questionAnswered') ]
						}
					}
					else if (contentItem && contentItem.parentType == objType.measure && contentItem.childType == objType.measureAnswer) {
						if (translateParams.sourceText && translateParams.targetText) {
							output = [ $translate.instant('modules.notifications.eventSentences.measureAnswered_specific', translateParams) ]
						}
						else {
							output = [ $translate.instant('modules.notifications.eventSentences.measureAnswered') ]
						}
					}
					// else if (contentItem && contentItem.parentType == objType.structure) {
					// 	if (translateParams.sourceText && translateParams.targetText) {
					// 		output = [ $translate.instant('modules.notifications.eventSentences.createdDataRelation_specific', translateParams) ]
					// 	}
					// 	else {
					// 		output = [ $translate.instant('modules.notifications.eventSentences.createdDataRelation') ]
					// 	}
					// }
					else if (itemEventType == eventType.create) {
						if (translateParams.sourceText && translateParams.targetText) {
							if (contentItem.parentType === enums.objectType.structure
								&& _.includes([ enums.objectType.structure, enums.objectType.question, enums.objectType.measure, enums.objectType.organization ], contentItem.childType)
							) {
								if (eventInfoItem.httpStatusCode === 400)
									output = [ $translate.instant('AttemptedTo'), $translate.instant('Categorize').toLowerCase(), $translate.instant('modules.notifications.eventSentences.textPart_sourceToTarget', translateParams) ]
								else
									output = [ $translate.instant('modules.notifications.eventSentences.categorized', translateParams) ]
							}
							else if (contentItem.parentType === enums.objectType.individual
								&& _.includes([ enums.objectType.structure, enums.objectType.question, enums.objectType.measure ], contentItem.childType)
							) {
								output = [ $translate.instant('modules.notifications.eventSentences.sentTo', translateParams) ]
							}
							else if (userDataObjectTypes[contentItem.childType])
								output = [ $translate.instant('modules.notifications.eventSentences.createdDataRelation_specific_withObjectTypeWord', translateParams) ]
							else
								output = [ $translate.instant('modules.notifications.eventSentences.createdDataRelation_specific', translateParams) ]
						}
						else {
							output = [ $translate.instant('modules.notifications.eventSentences.createdDataRelation') ]
						}
					}
					else if (itemEventType == eventType.update) {
						if (translateParams.sourceText && translateParams.targetText) {
							if (userDataObjectTypes[contentItem.childType])
								output = [ $translate.instant('modules.notifications.eventSentences.updatedDataRelation_specific_withObjectTypeWord', translateParams) ]
							else
								output = [ $translate.instant('modules.notifications.eventSentences.updatedDataRelation_specific', translateParams) ]
						}
						else {
							output = [ $translate.instant('modules.notifications.eventSentences.updatedDataRelation') ]
						}

						// if (contentItem) {
						// 	output = [
						// 		$translate.instant('modules.notifications.eventSentences.moved'),
						// 		wfTranslate.instant('MAP_ObjectType', { type: contentItem.childType, IA: true })
						// 	]
						// }
						// else {
						// 	output = [
						// 		$translate.instant('modules.notifications.eventSentences.updatedDataRelation', translateParams)
						// 	]
						// }
					}
					break;
				case objType.visibilityTag:
					translateParams = {
						sourceTypeWord: wfTranslate.instant('MAP_ObjectType', { type: contentItem.objectType, DA: true }),
						sourceText: contentItem.childContent ? wfPropertyExtractor.getHeaderText(contentItem.childContent, { useFriendlyTitle: true }) : "source",
						targetText: contentItem.parentContent ? wfPropertyExtractor.getHeaderText(contentItem.parentContent, { useFriendlyTitle: true }) : "target"
					}
					// if (itemEventType == eventType["delete"]) {
					// 	output = [
					// 		$translate.instant('modules.notifications.eventSentences.deletedDataRelation', translateParams)
					// 		// wfTranslate.instant('MAP_ObjectType', { type: item.childContent.childType, IA: true })
					// 	]
					// }
					// else if (item && item.parentType == objType.question && item.childType == objType.questionAnswer) {
					// 	output = [
					// 		$translate.instant('modules.notifications.eventSentences.questionAnswered')
					// 	]
					// }
					if (itemEventType == eventType.create) {
						output = [
							$translate.instant('modules.notifications.eventSentences.createdVisibilityTag', translateParams)
							// wfTranslate.instant('MAP_ObjectType', { type: item.childContent.childType, IA: true })
						]
					}
					// else if (itemEventType == eventType.update) {
					// 	if (item) {
					// 		output = [
					// 			$translate.instant('modules.notifications.eventSentences.moved'),
					// 			wfTranslate.instant('MAP_ObjectType', { type: item.childType, IA: true })
					// 		]
					// 	}
					// 	else {
					// 		output = [
					// 			$translate.instant('modules.notifications.eventSentences.updatedDataRelation', translateParams)
					// 		]
					// 	}
					// }
					break;
				case objType.invoice:
					if (itemEventType == eventType.send)
						output = $translate.instant('modules.notifications.eventSentences.sendAndAttestInvoice', { orgname: $rootScope.authUser.organizationName })
					break;
				case objType.billingInformation:
					output = $translate.instant('modules.notifications.eventSentences.updatedBillingInformation', { orgname: $rootScope.authUser.organizationName })
					break;
				case objType.individual:
					if (itemEventType == eventType.update && author && author.type === enums.objectType.individual && author.wfid === contentItem.wfid)
						output = $translate.instant('modules.notifications.eventSentences.individualUserUpdatedOneself')
					break;
				case objType.requirement:
					if (itemEventType == eventType["delete"])
						output = $translate.instant('modules.notifications.eventSentences.deletedRequirement')
					else if (itemEventType == eventType["create"])
						output = $translate.instant('modules.notifications.eventSentences.createdRequirement')
					else
						output = $translate.instant('modules.notifications.eventSentences.updatedRequirement')
					break;
				case objType.questionAnswer:
					output = $translate.instant('modules.notifications.eventSentences.questionAnswered');
					break;
				case objType.measureAnswer:
					output = $translate.instant('modules.notifications.eventSentences.measureAnswered');
					break;
				case objType.parameterValue:
					if (contentItem && contentItem.parameterId === 33 && contentItem.objectType === enums.objectType.organization && contentItem.organizationId !== contentItem.objectId) {
						output = $translate.instant('modules.notifications.eventSentences.updatedOrganizationCustomId', { value: contentItem.value });
					}
					else
						output = $translate.instant('modules.notifications.eventSentences.parameterAnswered');
					break;
				case objType.organization:
					if (itemEventType == eventType.update && contentItem.objectId === authOrgId)
						output = $translate.instant('modules.notifications.eventSentences.updatedOrganization', { orgname: $rootScope.authUser.organizationName });
					break;
				case objType.mailOutbound:
					if (itemEventType == eventType.create) {
						var userFullName = _.get(eventInfoItem, "objectData.toName");
						var orgName = _.get(eventInfoItem, "objectData.toOrganizationName");
						var emailAddress = _.get(eventInfoItem, "objectData.toEmail");
						var emailNoun = "";
						var multilingualKey;
						var endingText = ""

						vm.noTextTransform = true;

						switch (_.get(contentItem, "mailPurpose") || _.get(eventInfoItem, "requestParameters.mailPurpose")) {
							case enums.mailPurpose.valueChainInvitation:
								emailNoun = $translate.instant("modules.notifications.emailTypes.valueChainInvitation");
								endingText = $translate.instant("modules.notifications.eventSentences.sentencePart_inValueChain");
								break;
							case enums.mailPurpose.valueChainReminder:
								emailNoun = $translate.instant("modules.notifications.emailTypes.valueChainReminder");
								endingText = $translate.instant("modules.notifications.eventSentences.sentencePart_inValueChain");
								break;
							case enums.mailPurpose.colleagueInvitation:
								emailNoun = $translate.instant("modules.notifications.emailTypes.colleagueInvitation");
								break;
							default:
								emailNoun = $translate.instant("modules.notifications.emailTypes.email");
								break;
						}

						if (userFullName && orgName)
							multilingualKey = "modules.notifications.eventSentences.sentEmailToUserOnOrg";
						else if (userFullName && !orgName)
							multilingualKey = "modules.notifications.eventSentences.sentEmailToUser";
						else if (emailAddress)
							multilingualKey = "modules.notifications.eventSentences.sentEmailToAddress";
						else
							multilingualKey = "modules.notifications.eventSentences.sentEmail";

						output = $translate.instant(multilingualKey, {
							username: userFullName,
							orgname: orgName,
							emailAddress: emailAddress,
							emailNoun: emailNoun,
							endingText: endingText
						});
					}
					break;
				default:
					break;
			}

			if (!output) {
				if (includeItemText) {
					itemText = getBoldItemTextHtml(contentItem)
					includeItemText = itemText && itemText.length;
				}
					
				output = [
					wfTranslate.instant('MAP_EventType', { type: itemEventType, pastTense: true }),
					wfTranslate.instant('MAP_ObjectType', { type: contentItem.type, DA: includeItemText, IA: !includeItemText }),
					itemText
				];

			}

			if (typeof output !== "string")
				output = output.join(" ");

			return output;

			// return wfTranslate.instant('MAP_EventType', { type: item.eventType }) + ' ' + wfTranslate.instant('MAP_ObjectType', { type: item.objectType });
		}

		function getBoldItemTextHtml(item) {
			var itemText = wfPropertyExtractor.getMainTextual(item, { html: false, useFriendlyTitle: true });
			var element = $("<span class='itemText' />").html(cutString(itemText, 150));
			element.attr("title", itemText);

			return element[0].outerHTML || "";
		}

		function cutString(value, length) {
			var
				maxRecursion = 10,
				i = 0,
				output = value,
				lastChar,
				lastWhitespaceIndex,
				trimEnd = function () {
					i++;
					if (i === maxRecursion)
						return;
					
					lastChar = output[output.length - 1];
					if (!lastChar.match(onlyLettersRegExp) && output.length > 2) {
						output = output.substr(0, output.length - 1);
						trimEnd();
					}
				}
			;

			if (length < 20)
				length = 20;

			if (value && value.length > length + 10) {
				output = output.substr(0, length);
				lastWhitespaceIndex = output.search(lastWhitespaceIndexRegExp);
				if (lastWhitespaceIndex > 10) {
					output = output.substr(0, lastWhitespaceIndex);
				}
				trimEnd();

				return output + "..."
			}
			else
				return value;
		}
	}

})();
