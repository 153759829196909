// @flow
import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Colors from '@worldfavor/constants/colors'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

type Props = {
  children?: React.Node,
  compact?: boolean,
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(12),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  compact: {
    '& $circle': {
      height: 86,
      width: 86,
    },

    '& $icon': {
      fontSize: theme.typography.fontSizes.xLarge,
    },

    '& $title': {
      fontSize: theme.typography.fontSizes.large,
    },
  },
  circle: {
    height: 158,
    width: 158,
    borderRadius: '100%',
    backgroundColor: Colors.grayLighter,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    fontSize: theme.typography.fontSizes.xxxLarge,
    color: Colors.gray,
  },
  title: {
    ...theme.typography.h6,
    fontSize: theme.typography.fontSizes.larger,
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
}))

const DefaultTitle = () => (
  <>
    <div><FormattedMessage id={'filtering.emptyState.title'} /></div>
    <div><FormattedMessage id={'filtering.emptyState.description'} /></div>
  </>
)

const FilterOptionsEmptyState = (props: Props) => {
  const { compact, children } = props
  const classes = useStyles(props)
  return (
    <div className={classNames(classes.root, compact && classes.compact)}>
      <div className={classes.circle}>
        <i className={classNames(classes.icon, 'fas fa-filter')} />
      </div>
      <div className={classes.title}>
        {
          children || <DefaultTitle />
        }
      </div>
    </div>
  )
}

FilterOptionsEmptyState.defaultProps = {
  compact: true,
}

export default FilterOptionsEmptyState
