// @flow
import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import classnames from 'classnames'

type State = {
  arrowRef: HTMLElement | null,
}

const styles = theme => ({
  arrowPopper: {
    '&[x-placement*="bottom"] $arrowArrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.grey[700]} transparent`,
      },
    },
    '&[x-placement*="top"] $arrowArrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.grey[700]} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrowArrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.grey[700]} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrowArrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.grey[700]}`,
      },
    },
    '&[x-placement*="start"] $arrowArrow': {
      left: '45% !important',
    },
  },
  arrowArrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
})

class TooltipArrow extends React.PureComponent<*, State> {

  state = {
    arrowRef: null,
  }

  handleArrowRef = (node) => {
    this.setState({
      arrowRef: node,
    })
  }

  render() {
    const { tooltipClasses, classes, title, children, ...rest } = this.props
    return (
      <Tooltip
        title={(
          <React.Fragment>
            { title }
            <span className={classnames(classes.arrowArrow, 'tooltip-arrow')} ref={this.handleArrowRef} />
          </React.Fragment>
        )}
        classes={{ popper: classes.arrowPopper, tooltip: tooltipClasses }}
        PopperProps={{
          popperOptions: {
            modifiers: {
              arrow: {
                enabled: Boolean(this.state.arrowRef),
                element: this.state.arrowRef,
              },
            },
          },
        }}
        {...rest}
      >
        { children }
      </Tooltip>
    )
  }
}

export default withStyles(styles)(TooltipArrow)
