import * as enums from '@worldfavor/constants/enums'

(function() {
'use strict';

	angular
		.module('wf.common')
		.controller('SustainabilityRoomController', SustainabilityRoomController);

	SustainabilityRoomController.$inject = ['$scope', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', 'formSchemaService', '$q', 'wfTranslate', '$timeout','$stateParams','$rootScope','apiProxy','wfAuth','$state','$sanitize','$location'];
	function SustainabilityRoomController($scope, dataOps, modal, wfObject, dataQuery, $translate, formSchemaService, $q, wfTranslate, $timeout, $stateParams, $rootScope, apiProxy, wfAuth, $state, $sanitize, $location) {
		var
			vm = this,
			bodyElement = $("body") 
		;

		$scope.modal = modal;
		$scope.$state = $state;

		_.assign(vm, {
			isAdminMode: $stateParams.isAdminMode,
			editRoom: editRoom,
			editChildren: editChildren,
			roomCompiler: {},
			childrenCompiler: {},
			isLoggedIn: $rootScope.isLoggedIn,
			roomUrlRoot: null,
			$sanitize: $sanitize
		})

		activate();

		////////////////

		function activate() {
			$scope.$on("$destroy", function() {
        $(document.body).removeClass('white')
        $rootScope.useWhiteModePublicLayout = false;
        $rootScope.setPublicLayoutClass();
				bodyElement.css({
					background: "",
					backgroundSize: ""
				});
				wfAuth.resetAuthenticationHeaderValue();
			});

			if (vm.isAdminMode) {
				loadRoom($stateParams.roomId);
				vm.roomUrlRoot = "/room-admin/" + $stateParams.roomId;
				vm.roomStateRoot = "room-admin";
			}
			else {
				// wfAuth.signOut();
				$translate.use("sv-SE");
				moment.locale("sv")
				numeral.locale('sv');
        $(document.body).addClass('white')
        $rootScope.useWhiteModePublicLayout = true;
        $rootScope.setPublicLayoutClass();
				vm.roomUrlRoot = "/room/" + $stateParams.roomRouteName;
				vm.roomStateRoot = "room";

				apiProxy("utility.getSustRoomByRouteName", $stateParams.roomRouteName).then(function (res) {
					if (!res) { // A sustainability room with that urlRouteName was not found
						$state.go("root");
					}
					else {
						wfAuth.setAuthenticationHeaderValue("sust_room|" + res.id)
						loadRoom(res.id)
					}
				});
			}
		}

		function loadRoom(id) {
			dataOps.getObject({
				objectType: enums.objectType.sustainabilityRoom,
				objectId: id
			}).then(function (room) {
				$rootScope.setPageTitle(room.title);
				vm.room = room;
				vm.roomChildren = _.orderBy(room.childs, "id");

				if (room.organizationId) {
					dataOps.getObject({
						objectType: enums.objectType.organization,
						objectId: room.organizationId
					}).then(function (roomCreatorOrganization) {
						vm.roomLoaded = true;
						vm.roomCreatorOrganization = roomCreatorOrganization;
						vm.allLoaded = true;
						
						if (room.hasImage) {
							bodyElement.css({
								background: "url('" + room.imageUrlLarge + "') no-repeat center top",
								backgroundSize: "cover"
							});
						}

						if (vm.roomChildren.length && !$state.includes(vm.roomStateRoot + ".child")) {
							// console.log($location.path())
							$location.path($location.path() + "/item/" + vm.roomChildren[0].id).replace();
						}
							// $state.go(vm.roomStateRoot + ".child", {
							// 	objectId: vm.roomChildren[0].id,
							// 	networkId: 1,
							// 	isViewMode: true,
							// 	ticket: {
							// 		networkId: 1
							// 	}
							// }, { location: true }); // Bug with "replace". The networkId ticket parameters gets excluded when "replace" is used. Fix!
							// }, { location: "replace" });

						$timeout();
					})
				}
			});

		}

		function editRoom() {
			// var currentRouteName = vm.room.urlRouteName;
			// var currentLocation = document.location.href;

			modal.edit(vm.room).then(function () {
				// if (vm.room.urlRouteName === currentRouteName)
					vm.roomCompiler.compile();
				// else
				 	// document.location.replace(currentLocation.replace("/room/" + currentRouteName, "/room/" + vm.room.urlRouteName));

			});
		}

		function editChildren() {
			return $q(function (resolve) {
				dataOps.getObject({
					objectType: enums.objectType.dataRelation,
					objectId: 346808
				}).then(function (res) {
					var sustRoomAvailableChildrenStructure = res.childContent;

					dataOps.getObject({
						objectType: enums.objectType.structure,
						objectId: 203
					}).then(function (ownStructures) {
						var sourceLists = [{
							title: sustRoomAvailableChildrenStructure.title,
							items: dataQuery.makeItemComposites(sustRoomAvailableChildrenStructure.childs)
						}];

						if (ownStructures && ownStructures.childs.length) {
							sourceLists.push({
								title: ownStructures.title,
								items: dataQuery.makeItemComposites(ownStructures.childs)
							})
						}
						resolve();
						modal.openCreatorAndPicker({
							relationTarget: { item: vm.room, kind: 7 },
							create: false,
							templateId: 65,
							description: $translate.instant("modules.sustainabilityRoom.pickerDescription"),
							sourceLists: sourceLists
						}).closed(function () {
							vm.roomChildren = _.orderBy(vm.room.childs, "id");
							vm.childrenCompiler.compile();
						});
					});
				});
			})
		}
	}
})();
