import React, { forwardRef, useState } from 'react'
import ReactTable from '@worldfavor/components/Table/ReactTable'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage, injectIntl } from 'react-intl'
import Colors from '@worldfavor/constants/colors'
import EmptyState from '@worldfavor/components/EmptyState'
import withPage from '@worldfavor/portal/hoc/withPage'
import DropdownMenu from '@worldfavor/components/DropdownMenu'
import Image from '@worldfavor/components/Image'
import { MenuItem as MuiMenuItem } from '@material-ui/core'
import { resetState as resetPaginationState } from '@worldfavor/portal/actions/paginationActions'
import { FormattedDate } from 'react-intl'
import { deleteItem } from '@worldfavor/portal/actions/dataThunk'
import { useDispatch } from 'react-redux'
import { useDialogState } from '@worldfavor/hooks'
import Dialog from '@worldfavor/components/Dialog/'
import { apiCallError, beginDataApiCall, dataApiCallSuccess } from '@worldfavor/portal/actions/apiCallStatusActions'

const useStyles = makeStyles(theme => ({
  headerTableCell: {
    paddingTop: 20,
    paddingBottom: 20,
    textTransform: 'uppercase',
    fontSize: theme.typography.fontSizes.default,
    fontWeight: theme.typography.fontWeights.medium,
    color: Colors.grayText,
  },
  rowTableCell: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  tableWrapper: {
    minWidth: '100%',
    minHeight: '60vh',
  },
  oddRow: {
    '&:hover': {
      background: Colors.grayOffWhite,
    },
  },
  row: {
    '&:hover': {
      background: Colors.grayOffWhite,
    },
  },
  dateAndAction: {
    paddingTop: 10,
    fontSize: theme.typography.fontSizes.medium,
  },
  orgInfo: {
    paddingTop: 5,
    display: 'flex',
    alignItems: 'center',
  },
  orgName: {
    fontWeight: theme.typography.fontWeights.medium,
    fontSize: theme.typography.fontSizes.large,
  },
  actionButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

const ThirdPartyPermissionsTable = injectIntl((props) => {
  const { data, page, shouldReloadData, setShouldReloadData, intl, ...other } = props
  const classes = useStyles(props)
  const tableColumns = getTableColumns()
  const dispatch = useDispatch()
  const [open, openDialog, closeDialog] = useDialogState(false)
  const [saving, setSaving] = useState(false)
  const [permissionToDelete, setPermissionToDelete] = useState()
  const dropdownActions = [
    {
      id: 'remove-action',
      translateLabelId: 'supplyChain.thirdPartyReporting.removePermissionsButtonText',
      onClick: _openDialog,
    },
  ]
  const dialogActions = [
    {
      label: intl.formatMessage({ id: 'general.close' }),
      onClick: closeDialog,
      variant: 'text',
    },
    {
      label: intl.formatMessage({ id: 'supplyChain.thirdPartyReporting.removePermissionsButtonText' }),
      onClick: removePermission,
      variant: 'text',
      saving,
    },
  ]

  const MenuItem = forwardRef((props, ref) => {
    const { item, action } = props

    function _onClick(event) {
      action.onClick(event, item)
    }

    return (
      <MuiMenuItem ref={ref} onClick={_onClick}>
        <FormattedMessage id={action.translateLabelId} />
      </MuiMenuItem>
    )
  })

  function _openDialog(event, item) {
    openDialog()
    setPermissionToDelete(item)
  }

  function removePermission() {
    setSaving(true)
    if (permissionToDelete) {
      removeThirdPartyPermission(permissionToDelete)
    } else {
      dispatch(apiCallError())
      console.error('influenceToStartReportingOnBehalf not defined')
    }
    setSaving(false)
  }

  function getPermissionToDeleteName() {
    if (permissionToDelete) {
      return permissionToDelete.thirdPartyOrganization.name
    }
    return <FormattedMessage id={'supplyChain.thirdPartyReporting.reporter'} />
  }

  async function removeThirdPartyPermission() {
    try {
      dispatch(beginDataApiCall())
      await dispatch(deleteItem(permissionToDelete))
      await dispatch(resetPaginationState())
      setShouldReloadData(!shouldReloadData)
    } catch (error) {
      dispatch(apiCallError())
      console.error('Could not remove third party influence: ', error)
    } finally {
      dispatch(dataApiCallSuccess())
    }
  }

  function getTableColumns() {
    return [
      {
        cellType: 'org',
        Header: intl.formatMessage({ id: 'supplyChain.thirdPartyReporting.reporter' }),
        accessor: row => row.thirdPartyOrganization.name,
        width: 440,
      },
      {
        cellType: 'date',
        Header: intl.formatMessage({ id: 'supplyChain.thirdPartyReporting.reportingOnOurBehalfSince' }),
        accessor: 'createdAt',
        width: 350,
      },
      {
        cellType: 'action',
        Header: ' ',
        accessor: () => {},
        width: 50,
      },
    ]
  }

  function renderRowCell(value, row, column, cell) {
    switch (column.cellType) {
      case 'org': {
        const imageUrl = _.get(row, 'original.thirdPartyOrganization.imageUrl')
        return (
          <div className={classes.orgInfo}>
            <Image
              style={{ minWidth: 30, minHeight: 30, borderRadius: 2, marginRight: 20 }}
              size={'cover'}
              src={imageUrl}
            />
            <span className={classes.orgName}>{cell.value}</span>
          </div>
        )
      }
      case 'date':
        return (
          <div className={classes.dateAndAction}>
            <FormattedDate
              year={'numeric'}
              day={'2-digit'}
              month={'2-digit'}
              value={cell.value}
            />
          </div>
        )
      case 'action':
        return (
          <div className={classes.actionButtonWrapper}>
            <DropdownMenu>
              { dropdownActions.map(action => <MenuItem item={row.original} key={action.id} action={action} />) }
            </DropdownMenu>
          </div>
        )
      default:
        return null
    }
  }

  function renderColumnCell(Header, id, column) {
    return column.Header
  }

  return (
    <>
      <div data-cy={'third-party-permissions-table'}>
        {
          (data && data.length > 0) ? (
            <ReactTable
              data={data}
              columns={tableColumns}
              classes={{
                tableWrapper: classes.tableWrapper,
                headerTableCell: classes.headerTableCell,
                rowTableCell: classes.rowTableCell,
                oddRow: classes.oddRow,
                row: classes.row,
              }}
              renderRowCell={renderRowCell}
              renderColumnCell={renderColumnCell}
              controlledPagination
              controlledPageIndex={page}
              enablePagination
              {...other}
            />
          ) : (
            <EmptyState
              style={{ padding: 150 }}
              size={'small'}
              iconClass={'fas fa-hands-helping'}
              title={(
                <FormattedMessage id={'supplyChain.thirdPartyReporting.permissionsTableEmptyState'} />
              )}
            />
          )}
      </div>
      <Dialog
        open={open}
        title={(
          <FormattedMessage
            id={'supplyChain.thirdPartyReporting.removePrivilegesDialog.title'}
            values={{ organizationName: getPermissionToDeleteName() }}
          />
        )
        }
        description={(
          <FormattedMessage
            id={'supplyChain.thirdPartyReporting.removePrivilegesDialog.description'}
            values={{ organizationName: getPermissionToDeleteName() }}
          />
        )
        }
        actions={dialogActions}
        info
      />
    </>
  )
})

export default withPage(({ items, totalElements, ...other }) => {
  const data = items.map(item => item.node)

  return (
    <ThirdPartyPermissionsTable
      data={data}
      count={totalElements}
      {...other}
    />
  )
})
