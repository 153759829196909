//  @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import classNames from 'classnames'
import Tooltip from '@material-ui/core/Tooltip'
import Colors from '@worldfavor/constants/colors'

type Props = {
  avatars: Array<{
    text: string,
    imageUrl?: string,
  }>,
  avatars: Array<any>,
  classes: { +[string]: string },
  avatarSize: number,
  align: string,
  margin: number,
  maxAvatar?: number,
  overlapAvatars?: boolean,
  avatarStyles?: { [string]: any },
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  rightAlignContent: {
    flexDirection: 'row-reverse',
  },
  leftAlignContent: {
    flexDirection: 'row',
  },
  avatars: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.white,
  },
  tooltip: {
    margin: 0,
    fontSize: 12,
    backgroundColor: Colors.blackDark,
    '& .tooltip-arrow:before': {
      borderColor: Colors.blackDark + ' transparent transparent transparent !important',
    },
  },
  numberedAvatar: {
    fontSize: 14,
  },
  colorDefault: { //numberedAvatar backgroundColor
    backgroundColor: theme.palette.primary.main,
  },
})

const GroupedAvatars = ({
  classes,
  avatars,
  avatarSize,
  align,
  overlapAvatars,
  margin,
  avatarStyles,
  maxAvatar,
}: Props) => {
  
  function getAvatarStyle(index) {
    const avatarStyle = {
      width: avatarSize,
      height: avatarSize,
      zIndex: index,
      marginTop: margin,
      marginBottom: margin,
      ...avatarStyles,
    }

    switch (align) {
      case 'left':
        Object.assign(avatarStyle, {
          left: overlapAvatars && index !== 0 ? -(avatarSize / 2) * index : 'initial',
          marginLeft: margin,
        })
        break
      case 'right':
      default:
        Object.assign(avatarStyle, {
          right: overlapAvatars && index !== 0 ? -(avatarSize / 2) * index : 'initial',
          marginRight: margin,
        })
    }

    return avatarStyle
  }

  const slicedAvatars = (maxAvatar && maxAvatar > 0 && avatars.length > maxAvatar) ? [
    ...avatars.slice(0, maxAvatar),
    {
      text: `+${avatars.length - maxAvatar > 99 ? 99 : avatars.length - maxAvatar}`,
      isMoreAvatar: true,
    },
  ] : avatars

  return (
    <div className={classNames(classes.avatars, classes[`${align}AlignContent`])}>
      {
        slicedAvatars.map(({ name = '', text = '', imageUrl, isMoreAvatar }, index) => (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={name}
            placement="top"
            key={`avatar-${index}`}
            disableHoverListener={isMoreAvatar}
            disableFocusListener={isMoreAvatar}
            disableTouchListener={isMoreAvatar}
          >
            <Avatar
              alt={name}
              src={imageUrl}
              className={classNames(classes.avatar, { [classes.colorDefault]: !imageUrl })}
              style={getAvatarStyle(index)}
            >
              <span className={classes.numberedAvatar}>
                { isMoreAvatar ? text : text.substring(0, 1) }
              </span>
            </Avatar>
          </Tooltip>
        ))
      }
    </div>
  )
}

GroupedAvatars.defaultProps = {
  avatars: [],
  avatarSize: 40,
  align: 'left',
  margin: 5,
}

export default withStyles(styles)(GroupedAvatars)
