(function () {
	'use strict';

	angular.module('wf.animations', [ 'ngAnimate' ])
		.animation('.fold-animation', FoldAnimation)
		.animation('.slide-animation', SlideAnimation);

	SlideAnimation.$inject = [ '$animateCss' ];
	function SlideAnimation($animateCss) {
		return {
			enter: function(element, doneFn) {
				var
					innerElement = element.children(),
					innerElementHeight = innerElement[0].offsetHeight,
					index = element.data().index,
					jumping = element.data().jumping
				;

				element.height(innerElementHeight);

				$animateCss(innerElement, {
					from: { marginTop: (-innerElementHeight) + 'px' },
					to: { marginTop: '0px' },
					duration: index == 0 ? 0 : .2,
					easing: 'ease-out',
					delay: index > 0 && jumping ? index * .12 : .1
				}).start().done(function () {
					element.css({ height: "" });
				});
			},
			leave: function(element, doneFn) {
				var
					innerElement = element.children(),
					innerElementHeight = innerElement[0].offsetHeight,
					index = element.data().index,
					jumping = element.data().jumping
				;

				return $animateCss(innerElement, {
					from: { marginTop: '0px' },
					to: { marginTop: (-innerElementHeight) + 'px' },
					duration: index == 0 ? 0 : .2,
					easing: 'ease-out',
					delay: index > 0 && jumping ? index * .12 : .1
				});
			}
		}
	}

	FoldAnimation.$inject = [ '$animateCss' ];
	function FoldAnimation($animateCss) {
		return {
			enter: function(element, doneFn) {
				var
					innerElement = element.children(),
					innerElementHeight = innerElement[0].offsetHeight,
					index = element.data().index,
					jumping = element.data().jumping
				;

				element.height(innerElementHeight);

				$animateCss(innerElement, {
					from: { marginTop: (-innerElementHeight) + 'px' },
					to: { marginTop: '0px' },
					duration: index == 0 ? 0 : .2,
					easing: 'ease-out',
					delay: index > 0 && jumping ? index * .12 : .1
				}).start().done(function () {
					element.css({ height: "" });
				});
			},
			leave: function(element, doneFn) {
				var
					innerElement = element.children(),
					innerElementHeight = innerElement[0].offsetHeight;

				element.height(0);

				return $animateCss(innerElement, {
					// from: { marginTop: '0px' },
					// to: { marginTop: (-innerElementHeight) + 'px' },
					duration: 0,
					// easing: 'ease-out'
				});
			}
		}
	}
})();