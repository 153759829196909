// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import PickerList from '../PickerList'
import Divider from '../Divider'
import { injectIntl } from 'react-intl'

const styles = {
  overlay: {
    borderRadius: 4,
  },
}

const rowStyles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    padding: 8,
    cursor: 'pointer',
  },
  image: {
    marginRight: 16,
  },
  textContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  name: {
    ...theme.typography.h6,
    paddingRight: 36,
    lineHeight: 1.2,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  contributorInfo: {
    ...theme.typography.body2,
    marginTop: 2,
    fontWeight: 400,
    color: theme.palette.common.gray,
  },
})

const ContributorSeparator = () => (
  <div style={{ paddingLeft: 64 }}>
    <Divider
      height={1}
      width={'100%'}
      color={'rgba(221, 221, 221, 0.5)'}
      style={{ marginBottom: 2, marginTop: 2 }}
    />
  </div>
)

const ContributorRow = withStyles(rowStyles)(({ classes, imageUrl, name, email, position, phone_number }) => {
  return (
    <div className={classes.root}>
      <Avatar src={imageUrl} className={classes.image}>{name[0]}</Avatar>
      <div className={classes.textContainer}>
        <div title={name} className={classes.name}>{name}</div>
        <div className={classes.contributorInfo}>
          { [email, position, phone_number].filter(Boolean).join(' • ') }
        </div>
      </div>
    </div>
  )
})

const keyExtractor = ({ wfid }) => `contributor-${wfid}`
const renderItem = ({ item }) => <ContributorRow {...item} />

const ContributorPicker = (
  {
    classes,
    colleagues,
    filteredColleagues,
    selectedContributors,
    onContributorSelect,
    intl,
    ...rest
  },
) => {
  const selectedIds = selectedContributors.map(item => item.wfid)

  function onSelect(event, selectedIds) {
    const contributors = colleagues.filter(({ wfid }) => selectedIds.includes(wfid))
    onContributorSelect(event, contributors)
  }

  return (
    <PickerList
      items={filteredColleagues.map(item => ({ id: item.wfid, value: item }))}
      selectedIds={selectedIds}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ItemSeparatorComponent={ContributorSeparator}
      classes={{ overlay: classes.overlay }}
      onSelect={onSelect}
      multiselect
      {...rest}
    />
  )
}

ContributorPicker.defaultProps = {
  onContributorSelect: () => {},
}

export default injectIntl(withStyles(styles)(ContributorPicker))
