(function() {
'use strict';

	angular
		.module('wf.common')
		.controller('RootStarterController', RootStarterController);

	RootStarterController.$inject = [ '$scope', '$q', '$timeout', 'apiProxy', 'wfObject', '$rootScope', '$stateParams', '$state', 'screenLoaderService', 'dataOperationsService', '$translate', 'wfTranslate', 'wfAuth', 'modalService'];
	function RootStarterController($scope, $q, $timeout, apiProxy, wfObject, $rootScope, $stateParams, $state, screenLoader, dataOps, $translate, wfTranslate, wfAuth, modal) {
		var vm = this;
		const promises = []
		
		activate();

		function activate() {
			var 
				orgSettingStructure,
				rootStructure = undefined,
				reportingOrgRootStructure = 12756,
				isReportingUser = false
			;
			
			if ($state.current.url === "/intro") {
				const promise = dataOps.getObject("71-10010", {
					objectId: 10010,
					objectType: 71,
					childrenLoadDepth: 3
				})
				promises.push(promise);				
			}
			
			const promise = dataOps.getObject({
				objectId: 10010,
				objectType: 71,
				childrenLoadDepth: 3
			})
			promises.push(promise);

			$q.all(promises).then(function () {
				if ($state.current.url === "/intro") {
					orgSettingStructure = wfObject.get("71-14490");	
					if (!orgSettingStructure) {
						$state.go("root");
						return;
					}
					else {
						openOrganizationIntroModal();
						return
					}
				}

				let res = wfObject.get("71-10010").childs[0]
				var userHasNoOrganizations = false;
				var menu = _.reject(res.childContent.childs, function (relation) {
					return _.get(relation, "childContent.conditions.contextVariable1") !== "SolutionPackage";
				});

				rootStructure = wfAuth.getRootStructureInfo();
				isReportingUser = rootStructure.id === reportingOrgRootStructure;

				// ------------- Mocking (same in navigationController.js) --------------
				// menu = [ menu[0] ];
				// -----------------------------------------------------------------
				
				vm.useRootSubMenuSelector = _.get(res.childContent, "conditions.uiSettings.useRootSubMenuSelector");
				
				if (vm.useRootSubMenuSelector && menu.length === 1) {
					vm.useRootSubMenuSelector = false;
					$stateParams.objectId = res.childContent.childs[0].childId;
					res = res.childContent.childs[0];
				}

				if (!wfAuth.getOrganizationId()) {
					dataOps.getObject("71-10052").then(function (res) {
						var orgCount = _.get(res, "childs.length")
						
						if (orgCount > 0)
							$state.go("accountOrganizations", null, { location: "replace" });
						else {
							userHasNoOrganizations = true;
							setUiStates()
						}
					});
				}
				else
					setUiStates()

				function setUiStates() {
					if (userHasNoOrganizations) {
						$rootScope.setPageTitle("Claim organization");
						vm.useClaimOrganization = true;
					}
					else if (res.childContent.conditions.contextVariable1 === "Dashboard") {
						isReportingUser ? vm.useReportPage = true : vm.useDashboard = true;
					}
					else if (res.childContent.conditions.contextVariable1 === "MaterialityLandingPage") {
						vm.useMaterialityLandingPage = true;
					}
					else if (res.childContent.conditions.contextVariable1 === "SolutionPackage") {
						vm.usePackageSolutionPage = true;
					}
					else {
						vm.usePageController = true;
					}

					if (vm.useReportPage) {
						$state.go("networkLandingScene", null, { location: "replace" })
					}
					
					vm.loaded = true;
					$timeout();
				}
			});
		}

		function openOrganizationIntroModal() {
			var modalOptions = {
				controller: "OrganizationIntroController",
				controllerAs: "vm",
				bindToController: true,
				templateUrl: "scripts/wf/organization/organizationIntro.controller.html",
				windowClass: "wf-modal organization-intro modal-width-700",
				keyboard: false,
				backdrop: 'static', 
				animate: true
			};
			modal.open(modalOptions);
		}
	}
})();
