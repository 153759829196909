import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';

	angular
		.module('wf.common')
		.service('pickerService', pickerService);

	pickerService.$inject = [ '$q', 'dataOperationsService', '$timeout', 'dataQuery', '$translate', 'modalService', '$ngBootbox', 'apiProxy', 'wfAuth' ];
	function pickerService($q, dataOps, $timeout, dataQuery, $translate, modal, $ngBootbox, apiProxy, wfAuth) {
		var service = {
			availableObjectTypes: defineAvailableObjectTypes(),
			injectInStore: injectInStore
		};

		return service;

		function defineAvailableObjectTypes() {
			return _.keyBy([
				{ type: enums.objectType.orgActivity,     structureId: 10015, icon: "fa fa-calendar-check-o" }, //Activities
				{ type: enums.objectType.orgDocument,     structureId: 10016, icon: "fa fa-file" }, //Documents
				{ type: enums.objectType.statement,       structureId: 10018, icon: "fa fa-quote-right" }, //Statements
				{ type: enums.objectType.link,            structureId: 10017, icon: "fa fa-link" }, //Links
				{ type: enums.objectType.structure,       structureId: 16125, getterConditions: { structureGetterCondition: 1 } }, //Structures
				{ type: enums.objectType.question,        structureId: 12099 }, // Questions - Only questions by the authenticatedorganization
				{ type: enums.objectType.measure,         structureId: 12100 }, // Measures - Only measures by the authenticatedorganization
				{ type: enums.objectType.relativeMeasure, structureId: 20361 }, // RelativeMeasures - Only relative measures by the authenticatedorganization
				{ type: enums.objectType.embed,           structureId: 11843, icon: "fa fa-play-circle" },  //Embeds
				{ type: enums.objectType.location,        structureId: 11970 },  //Location
				{ type: enums.objectType.certificate,     structureId: 13802 },  //Certificate
				{ type: enums.objectType.country,         structureId: 13804, canCreate: false },  //Countries
				{ type: enums.objectType.productionSite,  structureId: 13874, addToStructureAsDataRelation: true, kind: enums.subItemsKind.children },  //ProductionSites
				{ type: enums.objectType.productService,  structureId: 20243, addToStructureAsDataRelation: true, kind: enums.subItemsKind.children },  //ProductService
				{ type: enums.objectType.network,         structureId: 11009 },  // Networks that the organization has accessto
				{ type: enums.objectType.individual,      structureId: 10051 },  // Colleagues (users with access to the currently authenticatedorganization)
				{ type: enums.objectType.person,          structureId: 16091 }, // Person - for generic people (not realusers)
				{ type: enums.objectType.dateItem,        structureId: 23762, canPick: false },
				{ type: enums.objectType.finding,         structureId: 0 }, //Finding
				{
					type: enums.objectType.supplier,
					actualType: enums.objectType.organization,
					structureId: 17066,
					addToStructureAsDataRelation: true,
					addButtonCaptionTranslate: [ "Add", { key: "Supplier", textTransform: "lowercase" } ],
					kind: enums.subItemsKind.children,
					useFirstRelationTargetAsSource: false,
					hideFilters: false,
					inheritSettingsFrom: enums.objectType.organization
				},
				{
					type: enums.objectType.holding,
					actualType: enums.objectType.organization,
					structureId: 17067,
					addToStructureAsDataRelation: true,
					addButtonCaptionTranslate: [ "Add", { key: "PortfolioCompany", textTransform: "lowercase" } ],
					kind: enums.subItemsKind.children,
					useFirstRelationTargetAsSource: false,
					hideFilters: false,
					inheritSettingsFrom: enums.objectType.organization
				},
				{
					type: enums.objectType.organization,
					structureId: "organizations",
					addButtonCaptionTranslate: [ "Add", { key: "Organization", textTransform: "lowercase" } ],
					useFirstRelationTargetAsSource: true,
					hideFilters: true,
					// searchServerOptions: { method: 'multi.getObjects', baseParams: { objectType: 101 } },
					form: function (formControl) {
						var isVatOrRegNumberValid = false;
						var instantVatOrRegNumberValidation = false;
						var regNumberValid = false;
						var vatNumberValid = false;

						return {
							replaceFormSpecification: true,
							customSpecification: {
								form: [
									{
										key: "name",
										title: $translate.instant("modules.organization.introModal.form.orgName")
									},
									{
										type: "section",
										htmlClass: "registration-vat row mr0",
										items: [
											{
												key: "registrationNumber",
												title: $translate.instant("modules.organization.introModal.form.regNumber"),
												htmlClass: "col-sm-4",
												validationMessage: {
													vatOrRegNumber: $translate.instant("validationMessages.vatRegOrGlnNumber")
												},
												$validators: {
													vatOrRegNumber: function (value) {
														if (!instantVatOrRegNumberValidation) return true;

														var result = !!(vatNumberValid || value)
														regNumberValid = !!value;

														if (isVatOrRegNumberValid !== result) {
															isVatOrRegNumberValid = result;
															formControl.$scope.$broadcast('schemaForm.error.vatNumber', 'vatOrRegNumber', result);
														}

														return result;
													}
												}
											},
											{
												key: "vatNumber",
												title: $translate.instant("modules.organization.introModal.form.vatNumber"),
												htmlClass: "col-sm-4",
												validationMessage: {
													vatOrRegNumber: $translate.instant("validationMessages.vatRegOrGlnNumber")
												},
												$validators: {
													vatOrRegNumber: function (value) {
														if (!instantVatOrRegNumberValidation) return true;

														var result = !!(regNumberValid || value)
														vatNumberValid = !!value;

														if (isVatOrRegNumberValid !== result) {
															isVatOrRegNumberValid = result;
															formControl.$scope.$broadcast('schemaForm.error.registrationNumber', 'vatOrRegNumber', result);
														}

														return result;
													}
												}
											},
											{
												key: "gln",
												title: $translate.instant("GLNNumber"),
												htmlClass: "col-sm-4"
											},
										]
									}
								],
								schema: {
									"type": "object",
									"properties": {
										"name": {
											"title": $translate.instant("Name"),
											"type": "string"
										},
										"registrationNumber": {
											"title": $translate.instant("RegistrationNumber"),
											"type": "string"
										},
										"vatNumber": {
											"title": $translate.instant("VATNumber"),
											"type": "string"
										},
										"gln": {
											"title": $translate.instant("GLNNumber"),
											"type": "string"
										}
									},
									"required": [ "name" ]
								}
							}, // customSpecification
							bypassAdapter: true,
							onBeforeSubmitTriggered: function (event) {
								var
									orgModel = event.getModel(),
									formControl = formControl
								;

								orgModel.type == 101;

								event.setModel(orgModel);

								if (!orgModel.registrationNumber && !orgModel.vatNumber) {
									instantVatOrRegNumberValidation = true;
									formControl.$scope.$broadcast('schemaForm.error.registrationNumber', 'vatOrRegNumber', false);
									formControl.$scope.$broadcast('schemaForm.error.vatNumber', 'vatOrRegNumber', false);
									event.cancelSubmit();
									return;
								}

								apiProxy("utility.getOrganizationByCondition", {
									registrationNumber: orgModel.registrationNumber,
									vatNumber: orgModel.vatNumber
								}).then(function (orgs) {
									var org, orgSet = false;

									if (!orgs.length)
										event.continueSubmit();
									else {
										if (orgs.length === 0) {
											org = orgs[0];
											$ngBootbox.customDialog({
												title: $translate.instant('modules.valueChain.organizations.alreadyExists.modalTitle'),
												message: $translate.instant('modules.valueChain.organizations.alreadyExists.modalMessage', {
													orgname: org.name,
													orgnumber: org.registrationNumber
												}),
												onEscape: true,
												className: "valueChain-modal-orgAlreadyExists",
												buttons: {
													cancel: {
														label: $translate.instant('No'),
														className: "btn-default",
														callback: function() {
															event.cancelSubmit();
														}
													},
													primary: {
														label: $translate.instant('Yes'),
														className: "btn-primary",
														callback: function() {
															wfObject.inject(org);
															org = wfObject.get(org.wfid);
															event.setResultAndFinalize(org);
														}
													}
												}
											});
										}
										else {
											var sourceList = [];

											sourceList = _.map(orgs, function (org) {
												return {
													data: org,
													wfid: org.wfid
												};
											});

											modal.openCreatorAndPicker({
												title: $translate.instant('modules.valueChain.organizations.multipleAlreadyExists2.modalTitle'),
												description: $translate.instant('modules.valueChain.organizations.multipleAlreadyExists2.modalMessage'),
												singlePick: true,
												relationBucket: { preSelected: [], allSelected: [] },
												sourceList: sourceList,
												buttons: [
													{
														label: "OK",
														callback: function ($scope, relationBucketResult) {
															org = relationBucketResult.allSelected[0];
															if (org) {
																wfObject.inject(org);
																org = wfObject.get(org.wfid);
																orgSet = true;
																event.setResultAndFinalize(org);
															}
															$scope.$close();
														}
													}
												]
											}).closed(function (relationBucketResult) {
												if (!orgSet)
													event.cancelSubmit();
											});
										}
									}
								});
							// event.closeModal();
							}
						};
					} // form
				}  // Organizations
			], "type");
		}

		// Creates a virtualDataRelation between the structure that holds all the items of a specific type and the newly created item
		// so that the new items shows up in the picker.
		// If addToStructureAsDataRelation is true a real dataRelation is created.
		function injectInStore(options) { // { newlyCreatedItem, typeStructureId, addToStructureAsDataRelation, useItemComposites }
			return $q(function (resolve, reject) {
				const item = options.newlyCreatedItem
				const parentWfid = "71-" + options.typeStructureId;
				var virtualDataRelation;

				if (options.addToStructureAsDataRelation) { // Used for types productionSite, productService, supplier (sub supplier) and holding (portfolio company)
					dataOps.createSubItemRelation(parentWfid, item, {
						kind: enums.subItemsKind.childrenByUser
					}).then(function (dataRelation) {
						resolve(dataRelation);
					});
				}
				else {
					// Creates a VirtualDataRelation and injects it into the store
					virtualDataRelation = wfObject.inject({
						childData1: null,
						childId: item.id,
						childType: item.type,
						createdAt: null,
						id: 0,
						organizationId: item.creatorOrganizationId || item.organizationId,
						parentData1: null,
						parentId: options.typeStructureId,
						parentType: 71,
						type: 81,
						userId: item.userId,
						wfcid: item.wfid,
						wffid: parentWfid,
						wfid: "81-|" + parentWfid + "|" + item.wfid
					});

					if (options.useItemComposites) {
						resolve(dataQuery.makeItemComposites([{
							childContent: item
						}])[0]);
					}
					else {
						resolve(virtualDataRelation);
					}

				}
			})
		}
	}
})();
