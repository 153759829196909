import * as enums from '@worldfavor/constants/enums'

// See documentation in WorldfavorKnowledgeBase
// docs/Front-end/XML Importer/Xml-importer.md

(function() {
	'use strict';

	angular
		.module('wf.common')
		.component('wfXmlImporter', {
			templateUrl: "scripts/wf/xmlImporter/wfXmlImporter.component.html",
			controller: wfXmlImporterController,
			controllerAs: "vm",
			bindings: {},
		});

	wfXmlImporterController.$inject = [ "$scope", "importExportService", "XmlImporter", "wfObject", "modalService", "$uibModal", "moment", "wfAuth", "$q", "dataOperationsService" ];
	function wfXmlImporterController($scope, importExportService, XmlImporter, wfObject, modalService, $uibModal, moment, wfAuth, $q, dataOps) {
		var vm = this;
		var rootObjectParentType = "71";
		var rootObjectParentId = "17434";

		_.assign(vm, {
			xmlImporter: undefined,
			isRootItemAlreadyImported: false,
			isRootItemInjected: true,
			progressPercentage: 0,
			numberOfItemsImported: 1,
			hierarchicalVmOverride: {
				showTypeSpecificTools: false,
				itemSettings: {
					showDropdown: false,
					showMetadata: false
				},
			},

			// Options --------------------------
			editorModel: "",
			requireLoaded: false,
			editorOptions: {
				lineWrapping : true,
				lineNumbers: true,
				addModeClass: true,
				mode: "xml",
			},
			wfDroppableOptions: {
				singleFile: true,
				limitFiles: 1,
				disableOsFileDrop: false,
				disableWfItemDrop: true,
				hideMessageInstantly: true,
				acceptOnlyFileType: "text/xml",
				onFileDropped: function (file) {
					var deferred = $q.defer();
					deferred.resolve(readXmlFile(file));
					return deferred.promise;
				},
				messages: {
					length: { icon: " ", header: " ", description: " " },
					dragEnter: { icon: "fas fa-arrow-down", header: "Drop an XML file here", description: " " },
					revealDraggable: { icon: "fas fa-arrow-down", header: "You can drop here", description: " " },
				}
			},

			// Toggles --------------------------
			xmlLoaded: false,
			previousJsonLoaded: false,
			productionDbInUse: undefined,
			productionDbImportConfirmed: false,

			importingEntireStandard: false,
			stopButtonClicked: false,
			unsavedProgress: false,
			unsavedItems: 0,
			showEditor: false,
			showLog: false,
			hasDiscardedTags: false,
			enablePreview: false,
			allowLoadingHierarchical: false,
			jsonFilePreview: undefined,

			// Functions --------------------------
			readXmlFile: readXmlFile,
			convertXmlToJson: convertXmlToJson,
			previewInHierarchicalView: previewInHierarchicalView,
			clearAllOptionsAndToggles: clearAllOptionsAndToggles,
			downloadJsonFile: downloadJsonFile,
			loadJSONFile: loadJSONFile,
			openLogModal: openLogModal,
			editItem: editItem,
			importRootItem: importRootItem,
			importSingleItem: importSingleItem,
			importEntireStandard: importEntireStandard,
			pauseImporting: pauseImporting,
			extractReferencesAndIdsAndDownloadJson: extractReferencesAndIdsAndDownloadJson,
			openInstructionModal: openInstructionModal
		});

		loadRequireJs();

		//-----------Phase 1 - Converting XML to JSON------------------
		//--------------------------  &  ------------------------------
		//-------Phase 2 - Preparing JSON objects for preview----------

		function readXmlFile(file) {
			importExportService.extractXmlFromFile(file).then(function(xmlAsString) {
				vm.editorModel = xmlAsString;
				convertXmlToJson().then(function(resolvedXmlFile) {
					if (resolvedXmlFile.varified) {
						vm.showEditor = true;
						vm.showLog = true;
						vm.xmlLoaded = true;
					}
				}, function(rejectedResult) {
					modalService.alert({
						title: rejectedResult.modalMessage.title,
						message: "\n" + rejectedResult.modalMessage.message,
						type: "warning"
					});
				});
			});
		}

		function convertXmlToJson() {
			var deferred = $q.defer();

			if (vm.requireLoaded) {
				vm.xmlImporter = undefined;
				vm.hasDiscardedTags = false;
				vm.allowLoadingHierarchical = false;

				var xmlAsString = vm.editorModel;
				vm.xmlImporter = new XmlImporter();
				vm.xmlImporter.parseXmlString(xmlAsString).then(function(xmlInJsonFormat) {
					if (isIsoStandardXmlFile(xmlInJsonFormat)) {
						vm.xmlImporter.prepareJsonAndLog(xmlInJsonFormat);
						vm.enablePreview = true;

						if (vm.xmlImporter.log.summary.discardedTags.length > 0) {
							vm.hasDiscardedTags = true;
							console.log("Discarded tags - ", vm.xmlImporter.log.summary.discardedTags);
						}
						deferred.resolve({ modalMessage: { title: "XML is an ISO standard", message: "All ok" }, item: xmlInJsonFormat, varified: true });
					}
					else {
						deferred.reject({ modalMessage: { title: "The XML file you selected is not an ISO Standard!", message: "Please make sure that you choose an ISO Standards Tag Set (ISOSTS) XML file." }, item: xmlInJsonFormat, varified: false });
					}
				});
			}
			else {
				deferred.reject({ modalMessage: { title: "RequireJS not loaded", message: "Please refresh the page" }, item: undefined, varified: false });
			}

			function isIsoStandardXmlFile(xmlAsJson) {
				var isXmlIsoStandard = false;
				var hasFrontTag = false;
				var hasBodyTag = false;

				if (xmlAsJson && xmlAsJson.name === "standard") {
					if (xmlAsJson.children && xmlAsJson.children.length > 0) {
						hasFrontTag = _.some(xmlAsJson.children, { name: "front" });
						hasBodyTag = _.some(xmlAsJson.children, { name: "body" });

						if (hasFrontTag && hasBodyTag)
							isXmlIsoStandard = true;
					}
				}

				return isXmlIsoStandard;
			}

			return deferred.promise;
		}

		function openLogModal() {
			$uibModal.open({
				animation: true,
				backdrop: 'static',
				size: 'width-500',
				windowClass: 'log-modal',
				templateUrl: 'scripts/wf/xmlImporter/wfXmlLog.template.html',
				scope: $scope
			});
		}

		function loadJSONFile(file) {
			if (file && file !== null) {
				importExportService.extractJSONFromFile(file).then(function(json) {
					if (isVerified(json)) {
						vm.jsonFilePreview = json;
						if (!vm.xmlImporter)
							vm.xmlImporter = new XmlImporter();
						vm.xmlImporter.log = json.log;
						vm.xmlImporter.standardItems = json.standardItems;
						vm.previousJsonLoaded = true;
						vm.enablePreview = true;
						vm.showLog = true;
					}
					else {
						modalService.alert({
							title: "Not supported file!",
							message: "\n" + "Please make sure that you select the JSON file which was previously converted from ISO Standard (ISOSTS) XML to JSON.",
							type: "warning"
						});
					}
				}, function() {
					modalService.alert({
						title: "Not supported file!",
						message: "\n" + "Please make sure that you select the JSON file which was previously converted from ISO Standard (ISOSTS) XML to JSON.",
						type: "warning"
					});
				});
			}

			function isVerified(json) {
				var verified = false;
				if (json && json.standardItems && !_.isEmpty(json.standardItems)) {
					if (json.standardItems && json.standardItems.length > 0) {
						if (_.find(json.standardItems, { isRootItem: true }))
							verified = true;
					}
				}
				return verified;
			}
		}

		function loadRequireJs() {
			var require, requireConfig;
			var head = document.getElementsByTagName("head")[0];

			require = document.createElement("script");
			require.type = "text/javascript";
			require.src = "node_modules/requirejs/require.js";
			require.onload = function() {
				// console.log("RequireJS loaded");
				requireConfig = document.createElement("script");
				requireConfig.type = "text/javascript";
				requireConfig.src = "assets/plugins/requirejs/requireConfig.js";
				requireConfig.onload = function() {
					// console.log("RequireJS config loaded");
					vm.requireLoaded = true;
				}
				head.appendChild(requireConfig);
			}
			head.appendChild(require);
		}

		//-------Phase 3 - Previewing and importing JSON objects into database-------
		//----------------------------------------------------------------------------

		function previewInHierarchicalView() {
			if (!vm.xmlImporter.jsonLoadedToJsData)
				vm.xmlImporter.injectStandardToJsData();

			if (vm.previousJsonLoaded && !vm.xmlLoaded)
				traverseItemsToEnableImporting();

			vm.allowLoadingHierarchical = true;
		}

		function traverseItemsToEnableImporting() {
			var queue;
			var rootItemFromJsData;

			if (!vm.isRootItemAlreadyImported) {

				isRootItemImported();
			}
			else {
				rootItemFromJsData = wfObject.get(vm.xmlImporter.rootItem.wfid);
				queue = _.clone(rootItemFromJsData.childs);
				checkNextItem(queue.shift(), queue);
			}

			function checkNextItem(fakeRelation, previousQueue) {
				var innerQueue;
				var itemImporter = fakeRelation.itemImporter;
				var fakeContent = fakeRelation.childContent;
				var realContent;
				var realRelation;

				var standardItem;
				var standardItemChild;

				if (itemImporter && itemImporter.relation) {
					itemImporter.relation.allowImporting = true;

					if (itemImporter.relation.imported && itemImporter.content.imported && fakeContent) {
						realRelation = wfObject.get(fakeRelation.itemImporter.relation.real.wfid);
						if (realRelation) {
							realContent = realRelation.childContent;

							realRelation.parentId = fakeRelation.parentId;
							realRelation.wffid = fakeRelation.wffid;

							//Replace ids in standardItems
							standardItem = _.find(vm.xmlImporter.standardItems, { wfid: fakeRelation.wfid });

							if (standardItem) {
								standardItem.childId = realRelation.childId;
								standardItem.wfcid = realRelation.wfcid;
								standardItem.id = realRelation.id;
								standardItem.wfid = realRelation.wfid;
							}

							//Take care of a childContent
							if (fakeContent && fakeContent.childs.length > 0) {
								_.each(fakeContent.childs, function(child) {
									child.parentId = realRelation.childContent.id;
									child.wffid = realRelation.childContent.wfid;

									//Replace ids from standardItems
									standardItemChild = _.find(vm.xmlImporter.standardItems, { wfid: child.wfid });
									if (!standardItemChild)
										standardItemChild = _.find(vm.xmlImporter.injectedItems, { wfid: child.wfid });

									if (!standardItemChild)
										console.log("StandardItemChild not defined - ", child);

									standardItemChild.parentId = realRelation.childContent.id;
									standardItemChild.wffid = realRelation.childContent.wfid;
								});
							}

							realRelation.itemImporter = itemImporter;
							itemImporter.relation.allowImporting = false;
						}
						else console.log("Couldn't find fake or real item.");

						if (realContent.childs.length > 0) {
							innerQueue = _.clone(realContent.childs);
							checkNextItem(innerQueue.shift(), innerQueue);

							if (innerQueue.length > 0)
								checkNextItem(innerQueue.shift(), innerQueue);
						}
					}
				}

				if (previousQueue.length > 0)
					checkNextItem(previousQueue.shift(), previousQueue);
			}
		}

		function importRootItem() {
			var deferred = $q.defer();

			isProductionDbInUse();
			if (vm.productionDbInUse && !vm.productionDbImportConfirmed) {
				showProductionDbMessage().then(function(approved) {
					if (approved)
						initiateImport();
				});
			}
			else {
				initiateImport();
			}

			function initiateImport() {
				var rootItem = vm.xmlImporter.rootItem; // wfObject
				var itemImporterContent = vm.xmlImporter.rootItem.itemImporter.content;
				var itemImporterRelation = vm.xmlImporter.rootItem.itemImporter.relation;
				var rootItemInStandardsItem = _.find(vm.xmlImporter.standardItems, { isRootItem: true });

				if (rootItem.isRootItem) {
					vm.xmlImporter.rootItem.importing = true;
					// Content
					rootItem = _.omit(rootItem, [ 'itemImporter', 'standardNumber', 'standardCode', 'ancestorWfid', 'ancestorId' ]); // circular JSON if itemImporter is not omitted
					vm.xmlImporter.createRealStructure(rootItem).then(function(resolvedContent) {
						if (resolvedContent) {
							_.assign(itemImporterContent, {
								fake: dataOps.prepareWfObject(rootItem),
								real: dataOps.prepareWfObject(resolvedContent),
								imported: true
							});

							dataOps.saveSettings({
								item: resolvedContent,
								settings: {
									urlPath: "/hier/" + resolvedContent.wfid,
									templateId: 75,
									dataRelation: true,
									hideChildrenInMenu: true
								}
							});

							// Relation
							vm.xmlImporter.createRealRelation({ id: rootObjectParentId, type: rootObjectParentType }, itemImporterContent.real).then(function(resolvedRelation) {
								if (resolvedRelation) {
									_.assign(itemImporterRelation, {
										fake: undefined,
										real: dataOps.prepareWfObject(resolvedRelation),
										imported: true,
										allowImporting: false
									});
									rootItemInStandardsItem.itemImporter = vm.xmlImporter.rootItem.itemImporter;

									rootItemInStandardsItem.isRootItem = true;
									vm.isRootItemInjected = false;

									enableWarningForUnsavedChanges();
									vm.unsavedItems++;

									isRootItemImported();
									vm.xmlImporter.rootItem.importing = false;
									delete vm.xmlImporter.rootItem.importing;
									deferred.resolve(true);
								}
								else console.error("Couldn't create relation");
							}, function(rejectedRelation) {
								deferred.reject(rejectedRelation)
							});
						}
					}, function(rejectedContent) {
						deferred.reject(rejectedContent);
					});
				}
			}

			return deferred.promise;
		}

		function isRootItemImported() {
			var rootItem = vm.xmlImporter.rootItem; // wfObject
			var itemImporter = rootItem.itemImporter;
			var imported = false;

			var importedRootItemContent;
			var rootItemFromStandardItems;

			if (rootItem && itemImporter) {
				if (itemImporter.relation.imported && itemImporter.content.imported) {
					if (vm.isRootItemInjected) {
						imported = true;
						vm.xmlImporter.rootItem.isRootItem = true;
						vm.isRootItemAlreadyImported = true;
						rootItemFromStandardItems = _.find(vm.xmlImporter.standardItems, { wfid: rootItem.wfid });
						rootItemFromStandardItems.wfid = itemImporter.content.real.wfid;
						rootItemFromStandardItems.id = itemImporter.content.real.id;
						rootItemFromStandardItems.ancestorId = itemImporter.relation.real.id;
						rootItemFromStandardItems.ancestorWfid = itemImporter.relation.real.wfid;
						vm.numberOfItemsImported++;
						traverseItemsToEnableImporting();
					}
					else if (!itemImporter.content.real.wfid.includes("FAKE")) {
						if (itemImporter.relation.real && itemImporter.content.real) {
							checkIfItemIsInJsData(itemImporter.relation.real).then(function(importedRootItemRelation) {
								importedRootItemContent = importedRootItemRelation.childContent;
								importedRootItemContent.isRootItem = true;
								importedRootItemContent.itemImporter = itemImporter;

								if (importedRootItemRelation.wfid && importedRootItemContent.wfid === itemImporter.content.real.wfid) {
									rootItemFromStandardItems = _.find(vm.xmlImporter.standardItems, { wfid: rootItem.wfid });

									importedRootItemContent.childs = rootItem.childs;

									//Take care of a childContent
									if (importedRootItemContent.childs && importedRootItemContent.childs.length > 0) {
										_.each(importedRootItemContent.childs, function(child) {
											child.parentId = importedRootItemContent.id;
											child.wffid = importedRootItemContent.wfid;

											//Replace ids from standardItems
											var standardItemChild = _.find(vm.xmlImporter.standardItems, { wfid: child.wfid });
											standardItemChild.parentId = importedRootItemContent.id;
											standardItemChild.wffid = importedRootItemContent.wfid;
										});
									}

									rootItemFromStandardItems.wfid = importedRootItemContent.wfid;
									rootItemFromStandardItems.id = importedRootItemContent.id;
									rootItemFromStandardItems.ancestorId = importedRootItemRelation.id;
									rootItemFromStandardItems.ancestorWfid = importedRootItemRelation.wfid;
									rootItemFromStandardItems.isRootItem = true;
									vm.isRootItemAlreadyImported = true;
									vm.numberOfItemsImported++;

									imported = true;
									_.assign(rootItem, importedRootItemContent);
									_.assign(importedRootItemContent, rootItem);
									importedRootItemContent = rootItem;

									traverseItemsToEnableImporting();
								}
								else console.error("Root item not imported");
							});
						}
					}
				}
			}
			else modalService.alert({ title: "Root item not found", message: "\n" + "Couldn't find Root Item. The JSON file might be corrupted.", type: "warning" });

			return imported;

			//Returns promise
			function checkIfItemIsInJsData(realItemDataRealtion) {
				var deferred = $q.defer();

				dataOps.getObject(realItemDataRealtion.wfid).then(function(resolvedItem) {
					deferred.resolve(resolvedItem);
				}, function(rejectedItem) {
					deferred.reject(rejectedItem);
				});

				return deferred.promise;
			}
		}

		function importSingleItem(item) {
			var deferred = $q.defer();

			isProductionDbInUse();
			if (vm.productionDbInUse && !vm.productionDbImportConfirmed) {
				showProductionDbMessage().then(function(approved) {
					if (approved)
						initiateImport();
				});
			}
			else {
				initiateImport();
			}

			function initiateImport() {
				var itemImported = false, parentContent, itemImporter, fakeItemContent, fakeItemRelation, realRootItemContent, parentItemImporter, rootItemImporter, itemInStandardsItem;
				if (item.composite) {
					parentContent = item.parent.content;
					if (item.parent.dataRelation && item.parent.content && item.parent.dataRelation.itemImporter && item.parent.dataRelation.itemImporter.content.imported && item.parent.dataRelation.itemImporter.relation.imported) {
						parentContent = wfObject.get(item.parent.dataRelation.itemImporter.content.real.wfid);
					}

					itemImporter = item.dataRelation.itemImporter;
					fakeItemContent = item.content;
					fakeItemRelation = item.dataRelation;
					itemInStandardsItem = _.find(vm.xmlImporter.standardItems, { wfcid: item.content.wfid });
				}
				else {
					parentContent = item.parentContent;
					itemImporter = item.itemImporter;

					if (itemImporter.content.imported && itemImporter.relation.imported)
						itemImported = true;

					fakeItemContent = item.childContent;
					fakeItemRelation = item;
					itemInStandardsItem = _.find(vm.xmlImporter.standardItems, { wfid: item.wfid });
				}

				parentItemImporter = undefined;
				realRootItemContent = vm.xmlImporter.rootItem.itemImporter.content.real;
				var	itemImporterContent = itemImporter.content;
				var itemImporterRelation = itemImporter.relation;
				var parentIsRootItem = parentContent.wfid === vm.xmlImporter.rootItem.wfid;

				if (!itemImported) {
					itemImporterRelation.importing = true;

					if (parentIsRootItem)
						parentItemImporter = _.find(vm.xmlImporter.standardItems, { wfid: parentContent.wfid }).itemImporter;
					else
						parentItemImporter = _.find(vm.xmlImporter.standardItems, { wfcid: parentContent.wfid }).itemImporter;

					if (parentItemImporter.content.imported && parentItemImporter.relation.imported && wfObject.get(parentItemImporter.content.real.wfid)) {
						// Content

						//Add ancestor to the fakeItemContent here
						_.assign(fakeItemContent, {
							ancestorId: parseInt(realRootItemContent.id),
							ancestorWfid: realRootItemContent.wfid
						});

						fakeItemContent = _.omit(fakeItemContent, [ 'itemImporter', 'createdAt' ]); // circular JSON if itemImporter is not omitted

						vm.xmlImporter.createRealStructure(fakeItemContent).then(function(content) {
							if (content) {
								_.assign(itemImporterContent, {
									fake: dataOps.prepareWfObject(fakeItemContent),
									real: dataOps.prepareWfObject(content),
									imported: true
								});

								fakeItemRelation = _.omit(fakeItemRelation, [ 'itemImporter', 'wfid', 'createdAt' ]); // circular JSON if itemImporter is not omitted
								rootItemImporter = vm.xmlImporter.rootItem.itemImporter;
								// Relation
								vm.xmlImporter.createRealRelation(wfObject.get(parentItemImporter.content.real.wfid), itemImporterContent.real, fakeItemRelation.order).then(function(relation) {
									vm.xmlImporter.rootItem.itemImporter = rootItemImporter;
									vm.xmlImporter.rootItem.isRootItem = true;
									if (relation) {
										_.assign(itemImporterRelation, {
											fake: dataOps.prepareWfObject(fakeItemRelation),
											real: dataOps.prepareWfObject(relation),
											imported: true,
											allowImporting: false
										});
										itemInStandardsItem.itemImporter = itemImporter;
										_.assign(itemInStandardsItem, itemImporterRelation.real);
										_.assign(itemInStandardsItem.childContent, itemImporterContent.real);

										enableWarningForUnsavedChanges();
										vm.unsavedItems++;
										vm.numberOfItemsImported++;


										traverseItemsToEnableImporting();
										itemImporterRelation.importing = false;
										delete itemImporterRelation.importing;
										deferred.resolve(relation);
									}
									else {
										console.error("Couldn't create relation");
										deferred.reject(item);
									}
								});
							}
							else {
								console.error("Content not created");
								deferred.reject(item);
							}
						});
					}
					else {
						console.log("Parent item not imported");
						deferred.reject(item);
					}
				}
				else {
					vm.numberOfItemsImported++;
					deferred.resolve(item);
				}
			}

			return deferred.promise;
		}

		function importEntireStandard() {
			var queue;
			var numberOfItemsToImport = vm.xmlImporter.injectedItems.length;
			var promiseResultMessages = {
				resolved: {
					title: "Import succeeded!",
					message: "All items are imported",
					importStoppedByUser: false,
					importFailed: false,
					importSucceeded: true,
					item: undefined
				},
				rejected: {
					title: "Import failed",
					message: "Something went wrong when importing an item. See console for more info.",
					importStoppedByUser: false,
					importFailed: true,
					item: undefined
				},
				stopped: {
					title: "Import stopped",
					message: "Not all items are imported.",
					importStoppedByUser: true,
					importFailed: false,
					item: undefined
				}
			};

			if (isProductionDbInUse() && !vm.productionDbImportConfirmed) {
				showProductionDbMessage().then(function(approved) {
					if (approved)
						confirmImport();
				});
			}
			else {
				confirmImport();
			}

			function confirmImport() {
				modalService.alert({
					title: "Import entire standard",
					message: "\n" + "You are about to import " + numberOfItemsToImport + " items into a Worldfavor database!" + "\n" + "Items that are already imported will be skipped" + "\n" + "Continue?",
					type: "info",
					buttons: {
						yes: {
							label: "Import All",
							className: "btn-hollow action",
							callback: function() {
								vm.importingEntireStandard = true;
								if (!vm.isRootItemAlreadyImported) {
									importRootItem().then(function(res) {
										res ?  initiateImport() : modalService.alert(promiseResultMessages.rejected);
									});
								}
								else {
									initiateImport();
								}
							}
						},
						no: {
							label: "Cancel",
							className: "btn-hollow"
						}
					}
				});
			}

			function updateProgressBar() {
				var amount = vm.xmlImporter.standardItems.length;
				vm.progressPercentage = (vm.numberOfItemsImported / amount) * 100;
			}

			function initiateImport() {
				queue = _.clone(wfObject.get(vm.xmlImporter.rootItem.wfid).childs);
				if (queue && queue.length > 0) {
					importNextItem(queue.shift(), queue).then(function(result) {
						if (!result)
							result = promiseResultMessages.stopped;

						vm.importingEntireStandard = false;
						modalService.alert({
							title: result.title,
							message: "\n" + result.message,
							type: result.importSucceeded ? "success" : "warning"
						});

						if (!result.importSucceeded || result.importStoppedByUser) {
							console.log("Last item to import - ", result.item);
							traverseItemsToEnableImporting();
							vm.stopButtonClicked = false;
						}
					}, function(result) {
						console.error("Something went wrong while importing - ", result);
					});
				}

				function importNextItem(item, currentQueue) {
					var deferred = $q.defer();
					var innerQueue;

					updateProgressBar();

					if (!vm.stopButtonClicked) {
						importSingleItem(item).then(function(importedItem) {
							if (importedItem.childContent.childs && importedItem.childContent.childs.length > 0) {
								innerQueue = _.clone(importedItem.childContent.childs);
								importNextItem(innerQueue.shift(), innerQueue).then(function(resolvedItem) {
									if (currentQueue && currentQueue.length > 0) {
										importNextItem(currentQueue.shift(), currentQueue).then(function(res) {
											promiseResultMessages.resolved.item = res;
											deferred.resolve(res);
										}, function(rejectedItem) {
											promiseResultMessages.rejected.item = rejectedItem;
											deferred.reject(promiseResultMessages.rejected);
										});
									}
									else {
										promiseResultMessages.resolved.item = resolvedItem;
										deferred.resolve(promiseResultMessages.resolved.item);
									}
								}, function(rejectedItem) {
									promiseResultMessages.rejected.item = rejectedItem;
									deferred.reject(promiseResultMessages.rejected);
								});
							}
							else if (currentQueue && currentQueue.length > 0) {
								importNextItem(currentQueue.shift(), currentQueue).then(function(resolvedItem) {
									promiseResultMessages.resolved.item = resolvedItem;
									deferred.resolve(promiseResultMessages.resolve);
								}, function(rejectedItem) {
									promiseResultMessages.rejected.item = rejectedItem;
									deferred.reject(promiseResultMessages.rejected);
								});
							}
							else {
								promiseResultMessages.resolved.item = importedItem;
								deferred.resolve(promiseResultMessages.resolved);
							}

						}, function(rejectedItem) {
							promiseResultMessages.rejected.item = rejectedItem;
							deferred.reject(promiseResultMessages.rejected);
						});
					}
					else {
						promiseResultMessages.stopped.item = item;
						deferred.resolve(promiseResultMessages.stopped);
					}

					return deferred.promise;
				}
			}
		}

		function pauseImporting() {
			if (vm.importingEntireStandard)
				vm.stopButtonClicked = true;
		}

		function editItem(itemComposite) {
			var
				customFormSpecification,
				additionalFormItems,
				isItemImported = itemComposite.dataRelation.itemImporter && itemComposite.dataRelation.itemImporter.content.imported && itemComposite.dataRelation.itemImporter.relation.imported,
				itemFromStandardItems,
				itemToUpdate = undefined,
				updatingRealItem = false,
				form = [
					{
						"key": "text",
						"type": "textarea",
						"placeholder": "Enter text here"
					},
					"reference"
				],
				formSchema = {
					"type": "object",
					"properties": {
						"title": {
							"title": "Title",
							"type": "string"
						},
						"reference": {
							"title": "Reference",
							"type": "string"
						},
						"text": {
							"title": itemComposite.content.type === enums.objectType.question ? "Text" : "Description",
							"type": "string"
						},
						"guidance": {
							"title": "Guidance",
							"type": "string"
						}
					}
				}
			;

			if (isItemImported)
				itemFromStandardItems = _.find(vm.xmlImporter.standardItems, { wfcid: itemComposite.dataRelation.itemImporter.content.real.wfid });
			else
				itemFromStandardItems = _.find(vm.xmlImporter.standardItems, { wfcid: itemComposite.wfid });

			//Check if item is already imported
			if (itemFromStandardItems && isItemImported) {
				if (itemFromStandardItems.itemImporter.content.real && !_.isEmpty(itemFromStandardItems.itemImporter.content.real)) {
					itemToUpdate = wfObject.get(itemFromStandardItems.childContent.wfid);
					updatingRealItem = true;
				}
			}
			else itemToUpdate = _.clone(itemComposite.content);

			if (itemToUpdate) {
				if (itemComposite.content.type !== enums.objectType.question) {
					additionalFormItems = [ "title" ];
					form = additionalFormItems.concat(form);

					form.push({
						"key": "guidance",
						"type": "textarea",
						"placeholder": "Enter guidance here"
					});
				}

				customFormSpecification = {
					form: form,
					schema: formSchema
				};

				//Remove id and type in order not to request a form from the server
				if (!updatingRealItem) {
					delete itemToUpdate.id;
					delete itemToUpdate.type;

					modalService.editor(itemToUpdate, {
						title: "Edit item",
						customFormSpecification: customFormSpecification,
						action: updateItem
					}).then(formSubmitted);
				}
				else {
					modalService.editor(itemToUpdate, {
						title: "Editing real item (in database)",
						customFormSpecification: customFormSpecification,
					}).then(updateItemInDB);
				}
			}
			else console.error("Could not find real item.");

			function updateItemInDB(item) {
				updateItem(item);
				_.assign(itemComposite.content, {
					text: item.text,
					title: item.title,
					guidance: item.guidance,
					reference: item.reference
				});
				console.log(itemComposite);
				formSubmitted();
			}

			function updateItem(item) {
				var deferred = $q.defer();
				var itemFromJsData, itemImporterContent, itemImporterDataRelation, propertiesToUpdate;

				var result = vm.xmlImporter.findItemInJsDataAndXmlImporter(item);
				itemImporterDataRelation = result.itemFromImporter;
				itemImporterContent = result.itemFromImporter.childContent;
				itemFromJsData = result.itemFromJsData;

				propertiesToUpdate = {
					text: item.text,
					title: item.title,
					guidance: item.guidance,
					reference: item.reference
				};

				itemImporterDataRelation.itemImporter.content.edited = true;
				itemFromJsData.parents[0].itemImporter.content.edited = true;

				_.assign(itemImporterContent, propertiesToUpdate);
				_.assign(itemFromJsData, propertiesToUpdate);

				deferred.resolve(item);

				return deferred.promise;
			}

			function formSubmitted(res) {
				itemComposite.$scope.contentCompiler.compile();
				// console.log(res);
				//Add edited sign here
			}
		}

		//-----------Phase 4 - Saving current progress------------------
		//--------------------------------------------------------------

		function downloadJsonFile() {
			var isoStandardJsonFile;

			if (vm.xmlImporter.standardItems) {
				if (vm.xmlImporter.log)
					delete vm.xmlImporter.log.summary.discardedTags; // Cannot export discardedTags becuase of circular JSON error

				isoStandardJsonFile = {
					fileName: "Worldfavor_ISO_STANDARD_IMPORT_" + moment().format("YYYY-MM-DD") + ".json",
					dateImported: moment().format(),
					importedBy: wfAuth.getWorldfavorUser(),
					log: vm.xmlImporter.log,
					standardItems: vm.xmlImporter.standardItems
				}

				var stringifiedJSON = importExportService.stringifyJSON(isoStandardJsonFile);
				importExportService.downloadFile(stringifiedJSON, isoStandardJsonFile.fileName, "application/json");
				window.onbeforeunload = null;
				vm.unsavedProgress = false;
				vm.unsavedItems = 0;
			}
			else {
				modalService.alert({
					title: "Could not find JSON to save it to a file!",
					message: "\n" + "Make sure that the XML is of right structure and that it is properly converted from XML to JSON",
					type: "warning"
				});
			}
		}

		//----------------------HELPER FUNCTIONS-----------------------
		//-------------------------------------------------------------

		function enableWarningForUnsavedChanges() {
			if (!vm.unsavedProgress) {
				vm.unsavedProgress = true;
				window.onbeforeunload = function() {
					// Custom message for new versions of Chrome are not supported anymore
					return 'You have unsaved changes! Please save a json file to make a copy of your import progress. You can then continue when you left of next time.';
				};
			}
		}

		function isProductionDbInUse() {
			if (vm.productionDbInUse === undefined) {
				if ($.proxies.baseUrl === "https://staging.api1.worldfavor.com/" || $.proxies.baseUrl === "https://api1.worldfavor.com/")
					vm.productionDbInUse = true;
				else
					vm.productionDbInUse = false;
			}

			return vm.productionDbInUse;
		}

		function showProductionDbMessage() {
			var deferred = $q.defer();
			modalService.alert({
				title: "You are trying to import items in production database",
				message: "\n" + "Continue?",
				type: "warning",
				buttons: {
					yes: {
						label: "Yes, import to production DB",
						className: "btn-success action",
						callback: function() {
							vm.productionDbImportConfirmed = true;
							deferred.resolve(true);
						}
					},
					no: {
						label: "Cancel",
						className: "btn-hollow",
						callback: function() {
							deferred.resolve(false);
						}
					},
				}
			});

			return deferred.promise;
		}

		function clearAllOptionsAndToggles() {
			if (vm.unsavedProgress) {
				modalService.alert({
					title: "You haven't saved the changes you made",
					message: "\n" + "You will not be able to continue where you left off next time." + "\n" + "Please save the current progress.",
					type: "warning",
					buttons: {
						saveJson: {
							label: "Save changes",
							className: "btn-success",
							callback: downloadJsonFile
						},
						discardChanges: {
							label: "Discard changes",
							className: "btn-hollow",
							callback: function() {
								ejectAllFakeItemsFromJsData();
								resetAllToggles();
							}
						},
						no: {
							label: "Go back",
							className: "btn-hollow"
						},
					}
				});
			}
			else {
				ejectAllFakeItemsFromJsData();
				resetAllToggles();
			}

			function resetAllToggles() {
				_.assign(vm, {
					// Options --------------------------
					editorModel: "",
					progressPercentage: 0,
					xmlImporter: undefined,
					isRootItemAlreadyImported: false,
					isRootItemInjected: true,

					// Toggles --------------------------
					productionDbInUse: undefined,
					productionDbImportConfirmed: false,
					stopButtonClicked: false,
					unsavedProgress: false,
					unsavedItems: 0,
					xmlLoaded: false,
					previousJsonLoaded: false,
					showEditor: false,
					showLog: false,
					hasDiscardedTags: false,
					enablePreview: false,
					allowLoadingHierarchical: false,
					jsonFilePreview: undefined
				});
			}
		}

		function ejectAllFakeItemsFromJsData() {
			if (vm.xmlImporter.jsonLoadedToJsData) {
				if (vm.xmlImporter.injectedItems && !_.isEmpty(vm.xmlImporter.injectedItems)) {
					_.each(vm.xmlImporter.injectedItems, function(item) {
						if (item.type === enums.objectType.dataRelation)
							wfObject.eject(item.wfcid);
						wfObject.eject(item.wfid);

					});
					vm.xmlImporter.jsonLoadedToJsData = false;
				}
				else console.error("Could not eject fake items from JsData! - ", vm.xmlImporter.injectedItems);
			}
		}

		function extractReferencesAndIdsAndDownloadJson() {
			var items = _.map(vm.jsonFilePreview.standardItems, "itemImporter.content"), extractedItems = [], rootItem, jsonToExport, orderedItems, stringifiedJSON;

			_.each(items, function(item) {
				if (item.fake.isRootItem)
					rootItem = item.real;
				if (item.fake.reference)
					extractedItems.push({ id: parseInt(item.real.id), ref: item.fake.reference })
			});
			orderedItems = _.orderBy(extractedItems, "id");

			jsonToExport = {
				rootItem: {
					id: parseInt(rootItem.id),
					title: rootItem.title
				},
				orderedItems: {
					ids: _.map(orderedItems, "id").join(","),
					refs: _.map(orderedItems, "ref").join("~")
				}
			}

			stringifiedJSON = importExportService.stringifyJSON(jsonToExport);
			importExportService.downloadFile(stringifiedJSON, rootItem.title + ".json", "application/json");
		}

		function openInstructionModal() {
			modalService.openGuidance({
				title: "XML Importer checklist and instructions",
				message:
				'<div style="margin-bottom: 20px;">' +
					'<h4 style="color: #e74c3c;">Checklist</h4>' +
					'<span style="display: block; color: #e74c3c;">1. Please check that you are logged in as <strong style="text-decoration: underline">Worldfavor</strong> or <strong style="text-decoration: underline">WorldfavorSystem</strong> before importing</span>' +
					'<span style="display: block; color: #e74c3c;">2. <strong style="text-decoration: underline">Always</strong> save the JSON file after importing to the production!</span>' +
					'<span style="display: block; color: #e74c3c;">3. <strong style="text-decoration: underline">Keep</strong> the file safe since it can be reused to make faster changes on the imported standards!</span>' +
				'</div>' +
				'<div>' +
					'<h4 style="font-weight: 500">Before importing</h4>' +
					'<ul>' +
						'<li>An XML file has to be of <strong>ISOSTS</strong> format (given by SIS, Swedish Institute of Standards) in order to extract items from the file.</li>' +
						'<li>If you want to import individual items, you need to import the <strong>Root Item</strong> first, and then other items will be available for importing.</li>' +
						'<li>You can edit the content of each item (except the Root item) by clicking the <strong>Edit</strong> button. It is advised to edit the item before importing.</li>' +
						'<li>The items of the entire standard will be imported as it is seen in the preview, with the exact order of items and exact content.</li>' +
						'<li>Items marked with a letter <strong>S</strong> represent the structure of the hierarchy, where letter <strong>Q</strong> represents a question (once imported it will show the answering options)</li>' +
					'</ul>' +
				'</div>' +
				'<div>' +
					'<h4 style="font-weight: 500">While importing</h4>' +
					'<ul>' +
						'<li>Depending on the amount of items, the standard can take a while to import. (For instance, 150 items take approximately 15min to import)</li>' +
						'<li>You can pause the import if you want to continue it another time, but you MUST save the JSON file after pausing. Save that JSON file and next time load it instead of XML file. </li>' +
						'<li><strong>* Note</strong> that if you navigate to other tabs in the same browser window, the import process can get significantly slower. To import as fast as possible, please remain on the same tab/window and let the process complete faster.</li>' +
					'</ul>' +
				'</div>' +
				'<div>' +
					'<h4 style="font-weight: 500">After importing</h4>' +
					'<ul>' +
						'<li>As soon as the import is done, please save the JSON file and keep it safe so that any changes can be easily tracked and modified later on</li>' +
						'<li>Usually, standards have a license of usage attached to the page. At this point no license is attached. You need to manually insert license HTML text in English and Swedish in the Root Item\'s structure</li>' +
						'<li>Each standard has a nice image set on the Root Item. You can add it by uploading an image on the Root Item\'s structure</li>' +
						'<li>The standard is visible only to Worldfavor at this point. It is <strong>not yet accessable</strong> to customers, special permissions are needed. To give permissions to a specific customer/organization, please talk with the IT administrator.</li>' +
					'</ul>' +
				'</div>' +
				'<span style="display: block; color: #f39c12; margin-top: 20px;">When all items are imported, at the end of the import process the confirm message might be inccorect saying that <strong>Not all items are imported</strong>. Ignore that for now.</span>'

			});
		}
	}
})();
