import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';
	
	angular
		.module('wf.common')
		.component('wfFindingStepAnswer', {
			templateUrl: "scripts/wf/findings/wfFindingStepAnswer.component.html",
			controller: wfFindingStepAnswerController,
			controllerAs: "vm",
			require: {
				"findingVm": "^^wfFinding",
				"findingStepVm": "^^wfFindingStep"
			},
			bindings: {
				ticket: "<",
				answer: "<item"
			}
		});

	wfFindingStepAnswerController.$inject = [ "$scope", "$timeout", "$element", "$rootScope", "$state", "requirements", "$transclude", "$compile", "$translate", "$q", "dataOperationsService", "wfObject", "statisticsService", "findingService", "wfAuth", "modalService" ];
	function wfFindingStepAnswerController($scope, $timeout, $element, $rootScope, $state, requirementService, $transclude, $compile, $translate, $q, dataOps, wfObject, statisticsService, findingService, wfAuth, modal) {
		var
			vm = this,
			answer = vm.answer,
			currentAnswerState // Used to determine if the state has changed and if wfFinding
		;
		
		_.assign(vm, {
			answerCompiler: {},
			attachedInfoDropdownActions: [ { text: 'DeleteTag', icon: 'fa fa-trash', action: function (itemContent, itemRelation) {
				removeAttachedInformationItem(itemRelation);
			} } ],

			loading: false,
			editAnswer: editAnswer,
			attachInformation: attachInformation,
			removeAttachedInformationItem: removeAttachedInformationItem,
			verifyAnswer: verifyAnswer,
			
			//Only for development purposes
			deleteAnswer: deleteAnswer
		});

		this.$onInit = $onInit;


		function $onInit() {
			var initialPromises = [];

			vm.findingVm = this.findingVm;
			vm.findingStepVm = this.findingStepVm;

			vm.isAdminMode = vm.findingVm.isAdminMode;
			vm.isWorkMode = vm.findingVm.isWorkMode;

			syncView(true);

			// console.log("wfFindingStepAnswer - ", vm);
		}

		function syncView(initial) {
			formatHistory();
			
			vm.verification = _.find(_.map(vm.answer.verifications, "childContent"), { "latest": true });

			formatAnswerState();

			if (!initial) {
				if (typeof vm.answerCompiler.compile === "function")
					vm.answerCompiler.compile();
				
				vm.findingStepVm.step.syncAnswers();
				vm.findingVm.syncSteps();
				vm.findingVm.onAnswerStateChanged(answer);
				vm.findingVm.determineNeededAction();
				$timeout();
			}
		}

		function editAnswer() {
			return modal.edit(answer, {
				onBeforeSubmitTriggered: function(event) {
					var model = event.getModel();
					
					model.state = enums.findingStepAnswerState.answerUpdated;
					event.setModel(model);
					event.continueSubmit();
				}
			}).then(function() {
				syncView();
			});
		}

		function verifyAnswer() {
			return $q(function (resolve, reject) {
				dataOps.getObject({
					objectId: 322825, // Contains the current organization's users
					objectType: enums.objectType.dataRelation
				}).then(function (colleaguesStructureDataRelation) {
					var
						users = _.sortBy(colleaguesStructureDataRelation.childContent.childs, "childContent.name"),
						titleMap = []
					;

					_.forEach(users, function (item) {
						var user = item.childContent;
						if (user.worldfavorUserId > 0)
							titleMap.push({ value: user.worldfavorUserId, name: user.name + (user.email.length && user.given_name && user.given_name.length ? " (" + user.email + ")" : "") });
					});

					var modalPromise = modal.editor({
						targetOrganizationId: answer.creatorOrganizationId,
						verifierUserId: wfAuth.getWorldfavorUserId(),
						verifiedAt: moment().format()
					},
					{
						title: $translate.instant("Verify"),
						action: function (model) {
							return dataOps.setVerification(answer, model)
						},
						customFormSpecification: {
							schema: {
								type: "object",
								required: [ "isVerified", "comment", "verifierUserId", "verifiedAt" ],
								properties: {
									"isVerified": {
										title: $translate.instant("modules.findings.verification.varifiedLabel"),
										type: "boolean"
									},
									"verifierUserId": {
										title: $translate.instant("modules.findings.verification.verifierUser"),
										type: "integer"
									},
									"verifiedAt": {
										title: $translate.instant("modules.findings.verification.verifiedAt"),
										type: "string",
										format: "date"
									},
									"comment": {
										title: $translate.instant("modules.findings.verification.commentLabel"),
										type: "string"
									}
								}
							},
							form: [
								{
									key: "isVerified",
									type: 'select',
									titleMap: [
										{ value: true, name: $translate.instant("modules.findings.verification.approved") },
										{ value: false, name: $translate.instant("modules.findings.verification.denied") }
									]
								},
								{
									key: "comment",
									type: 'textarea'
								},
								{
									key: "verifierUserId",
									type: 'select',
									titleMap: titleMap
								},
								{
									key: "verifiedAt"
								}
							]
						}
					});
	
					modalPromise.then(function () {
						syncView();
						resolve();
					});

					modalPromise.cancelled(function () {
						resolve();
					});
				});
			});
		}

		function attachInformation() {
			return $q(function(resolve, reject) {
				var relatedContentCount = answer.relatedContentByUser.length;
				findingService.openInfoAttacherPicker(answer, afterSubmitFunction, vm.findingVm.ticket);
				
				function afterSubmitFunction() {
					if (answer.relatedContentByUser.length > relatedContentCount) {
						answer.state = enums.findingStepAnswerState.answerUpdated;
						dataOps.update(answer).then(function() {
							syncView();
							resolve();
						}, function(rejectedItem) {
							console.error("Could not update answer!");
							reject(rejectedItem);
						});
					}
					else {
						resolve();
					}
				}
			});
		}

		function removeAttachedInformationItem(item) {
			modal.confirmDelete(item).then(function () {
				syncView();
			});
		}

		function formatAnswerState() {
			var
				output,
				translationKey,
				isApproved = _.get(vm.verification, "isVerified"),
				iconClass
			;

			// if (vm.findingStepVm.step.isOptional) {
			// 	vm.stateCssClass = "optional-answer-updated";
			// 	vm.stateIconClass = "fa fa-check"
			// }
			// else
			if (answer.state === enums.findingStepAnswerState.answerUpdated) {
				vm.stateCssClass = "answer-updated";
				vm.stateIconClass = "fas fa-sync-alt"
			}
			else if (isApproved === true) {
				vm.stateCssClass = "answer-approved";
				vm.stateIconClass = "fa fa-check"
			}
			else if (isApproved === false) {
				vm.stateCssClass = "answer-denied";
				vm.stateIconClass = "fa fa-times"
			}

			if (vm.isWorkMode) {
				// if (vm.findingStepVm.step.isOptional)
				// 	translationKey = "modules.findings.answerMessages.reporter.optionalAnswerUpdated";
				// else
				if (answer.state === enums.findingStepAnswerState.answerUpdated)
					translationKey = "modules.findings.answerMessages.reporter.answerUpdated"
				else if (isApproved === true)
					translationKey = "modules.findings.answerMessages.reporter.answerApproved"
				else if (isApproved === false)
					translationKey = "modules.findings.answerMessages.reporter.answerDenied"
			}
			else if (vm.isAdminMode) {
				// if (vm.findingStepVm.step.isOptional)
				// 	translationKey = "modules.findings.answerMessages.verifier.optionalAnswerUpdated";
				// else
				if (answer.state === enums.findingStepAnswerState.answerUpdated)
					translationKey = "modules.findings.answerMessages.verifier.answerUpdated"
				else if (isApproved === true)
					translationKey = "modules.findings.answerMessages.verifier.answerApproved"
				else if (isApproved === false)
					translationKey = "modules.findings.answerMessages.verifier.answerDenied"
			}

			vm.stateMessageText = $translate.instant(translationKey, { step: vm.findingStepVm.step.title, verifier: _.get(vm.findingVm.finding, "creatorOrganization.name") })
		}

		function formatHistory() {
			var output = []
			_.each(answer.history, function (historyEntry) {
				if (historyEntry.state !== enums.findingStepAnswerState.answerUpdated)
					return;

				output.push({
					type: historyEntry.type,
					datetime: historyEntry.stateUpdatedAt,
					state: historyEntry.state,
					text: historyEntry.text,
					// content: historyEntry
				})
			});

			_.each(answer.verifications, function (relation) {
				var verification = relation.childContent;

				output.push({
					type: verification.type,
					datetime: verification.createdAt,
					comment: verification.comment,
					// isVerified: verification,
					isApproved: verification.isVerified,
					isDenied: !verification.isVerified,
					// content: verification
				})
			});

			output = _.orderBy(output, "datetime", "desc");
		}

		//Development purposes
		function deleteAnswer() {
			return dataOps.destroy(answer).then(function() {
				syncView();
			});
		}
	}
})();
