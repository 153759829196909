import * as enums from '@worldfavor/constants/enums'

(function() {
'use strict';

	angular
		.module('wf.common')
		.service('informationRequestService', informationRequestService);

	informationRequestService.$inject = [ "$timeout", "dataOperationsService", "apiProxy", "$q", "dataQuery" ];
	function informationRequestService($timeout, dataOps, apiProxy, $q, dataQuery) {
		var service = {
			//Variables
			items: undefined,
			requestButtonStages: {
				default: {
					icon: '',
					class: 'btn-primary',
					label: 'Request',
					block: false
				},
				sendingRequest: {
					icon: '',
					class: 'btn-primary loading',
					label: 'Sending',
					block: true
				},
				requestSent: {
					icon: 'fa fa-check',
					class: 'btn-default animate',
					label: 'Requested',
					block: true
					
				}
			},

			// Functions
			loadItems: loadItems,
			setButtonStage: setButtonStage
		}

		return service;

		function loadItems(influence) {
			// return service.items;

			return $q(function (resolve) {

				if (service.items == undefined) {
					service.items = influence.childContent.childs;

					// Load influence (outside jsdata) without hierarchy branches that contains userdata
					apiProxy("multi.getObject", {
						objectType: enums.objectType.influence,
						objectId: influence.id,
						wrapInRelations: false,
						readFromCache: true,
						ignoreAdditionalLoadDepthLimits: true,
						getterConditions: {
							hierarchyFilter: 2
						},
						culture: "en-US"
					}).then(function (influenceWithoutUserdata) {
						var
							flatItemsWithUserdata = dataQuery.getHierarchyAsList(influence.childContent, [ enums.objectType.structure ], { maxDepth: 3 }),
							flatItemsWithoutUserdata = dataQuery.getHierarchyAsList(influenceWithoutUserdata.childContent, [ enums.objectType.structure ], { maxDepth: 5, accessPropertiesDirectly: true })
						;

						// console.log("flatItemsWithUserdata", _.map(flatItemsWithUserdata, "wfid"));
						// console.log("flatItemsWithoutUserdata", _.map(flatItemsWithoutUserdata, "wfid"));
						_.each(flatItemsWithoutUserdata, function (item) {
							var parent;
							if (item.content.title && item.content.title.indexOf("Disclosure ") !== -1) {
								_.assign(item, {
									include: true,
									showButton: true,
									buttonIcon: service.requestButtonStages.default.icon,
									buttonClass: service.requestButtonStages.default.class,
									buttonLabel: service.requestButtonStages.default.label,
									buttonDisabled: service.requestButtonStages.default.block,
								});

								parent = item.parent;

								while (parent) {
									parent.include = true;
									parent = parent.parent;
								}
							}
						});

						flatItemsWithoutUserdata = _.filter(flatItemsWithoutUserdata, { include: true });

						service.items = _.map(flatItemsWithoutUserdata, function(itemComposite) {
							if (itemComposite.content.friendlyTitle) {
								itemComposite.content.description = itemComposite.content.title
								itemComposite.content.title = itemComposite.content.friendlyTitle
							}
							else {
								itemComposite.content.description = undefined;
							}

							return _.assign({
								buttonIcon: service.requestButtonStages.default.icon,
								buttonClass: service.requestButtonStages.default.class,
								buttonLabel: service.requestButtonStages.default.label,
								buttonDisabled: service.requestButtonStages.default.block,
							}, itemComposite)
						});
						resolve(service.items);
					});
				}
				else
					resolve(service.items);
			})
		}

		function setButtonStage(item, stage) {
			item.buttonIcon = stage.icon;
			item.buttonClass = stage.class;
			item.buttonLabel = stage.label;
			item.buttonDisabled = stage.block;
			$timeout();
		}
	}
})();
