// @flow
import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { FormattedMessage, injectIntl } from 'react-intl'
import ContributorPicker from '@worldfavor/components/Picker/ContributorPicker'
import CircularProgressWrapper from '@worldfavor/components/CircularProgressWrapper'
import { differenceBy } from 'lodash'
import SearchBar from '@worldfavor/components/SearchBar'
import SearchCount from '@worldfavor/components/SearchBar/SearchCount'
import { regExpSearchMapper } from '@worldfavor/utils/helpers'
import EmptyState from '@worldfavor/components/EmptyState'
import { Link } from 'react-router-dom'
import Colors, { hexToRgbA } from '@worldfavor/constants/colors'

const MAX_ITEM = 7
const ITEM_HEIGHT = 63
const DIVIDER_HEIGHT = 5

const styles = {
  searchBar: {
    marginBottom: 32,
  },
  picker: {
    maxHeight: MAX_ITEM * ITEM_HEIGHT + (MAX_ITEM - 1) * DIVIDER_HEIGHT,
    minHeight: MAX_ITEM * ITEM_HEIGHT + (MAX_ITEM - 1) * DIVIDER_HEIGHT,
    minWidth: 420,
    overflowY: 'auto',
  },
  dialogTitle: {
    fontSize: 24,
  },
  dialogContent: {
    minWidth: 525,
  },
  circularProgressWrapper: {
    minHeight: MAX_ITEM * ITEM_HEIGHT + (MAX_ITEM - 1) * DIVIDER_HEIGHT,
  },
  inviteButton: {
    marginTop: 20,
    backgroundColor: Colors.vividPurple,
    color: 'white',
    textAlign: 'center',
    '&:hover': {
      color: 'white',
      backgroundColor: hexToRgbA(Colors.vividPurple, 0.8),
    },
  },
}

const ContributorPickerDialog = (
  {
    classes,
    intl,
    colleagues,
    contributors,
    onCancel,
    onSave,
    onCreate,
    saving,
    colleaguesLoading,
    contributorsLoading,
    ...rest
  }) => {
  const [selectedContributors, setSelectedContributors] = useState(contributors)
  const [searchText, setSearchText] = useState('')
  const [searchCount, setSearchCount] = useState(colleagues.length)
  const searchMapper = regExpSearchMapper(['name', 'email', 'phone_number'], searchText)
  const [filteredColleagues, setFilteredColleagues] = useState(colleagues.map(searchMapper))

  useEffect(() => {
    const filterResult = colleagues.map(searchMapper).filter(x => Boolean(x))
    onDataFiltered(filterResult)
  }, [colleagues, searchText])
  
  function onDataFiltered(data) {
    const filteredData = data.map(x => x.item)
    setFilteredColleagues(filteredData)
    if (searchCount !== filteredData.length) {
      setSearchCount(filteredData.length)
    }
  }

  function _onSave(event) {
    const contributorsToAdd = differenceBy(selectedContributors, contributors, 'wfid')
    const contributorsToRemove = differenceBy(contributors, selectedContributors, 'wfid')

    onSave(event, contributorsToAdd, contributorsToRemove)
  }

  function onContributorSelect(event, selectedContributor) {
    setSelectedContributors(selectedContributor)
  }

  function onSearchClear() {
    setSearchText('')
  }

  function renderCount() {
    if (colleagues.length && !filteredColleagues) {
      return null
    }

    return <SearchCount count={searchCount} total={colleagues.length} />
  }

  function onSearchChange(event) {
    setSearchText(event.target.value)
  }

  return (
    <Dialog
      onClose={onCancel}
      disableBackdropClick={saving}
      disableEscapeKeyDown={saving}
      {...rest}
    >
      <DialogTitle>
        <span className={classes.dialogTitle}>
          <FormattedMessage id={'picker.contributors.dialogTitle'} />
        </span>
      </DialogTitle>

      <DialogContent classes={{ root: classes.dialogContent }}>
        <SearchBar
          onClear={onSearchClear}
          renderCount={renderCount}
          compact
          rounded
          classes={{ root: classes.searchBar }}
          inputProps={{
            placeholder: intl.formatMessage({ id: 'search.keywordPlaceholder' }),
            value: searchText,
            onChange: onSearchChange,
          }}
        />
        <div className={classes.picker}>
          <CircularProgressWrapper
            className={classes.circularProgressWrapper}
            loading={colleaguesLoading && contributorsLoading}
          >
            {
              filteredColleagues && filteredColleagues.length > 0 ? (
                <ContributorPicker
                  colleagues={colleagues}
                  filteredColleagues={filteredColleagues}
                  selectedContributors={selectedContributors}
                  onContributorSelect={onContributorSelect}
                />
              ) : (
                <EmptyState
                  iconClass={'fas fa-user'}
                  title={<FormattedMessage id={'search.noContributorMatchTitle'} />}
                  description={<FormattedMessage id={'search.noContributorMatchDescription'} />}
                  classes={{ root: classes.noColleaguePlaceholder }}
                >
                  <Button className={classes.inviteButton} component={Link} to={`/account/organization/users`}>
                    <FormattedMessage id={'networkExchanges.inviteColleague.buttonLabel'} />
                  </Button>
                </EmptyState>
              )
            }
          </CircularProgressWrapper>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel} color="primary" disabled={saving}>
          <FormattedMessage id={'general.cancel'} />
        </Button>

        <CircularProgressWrapper loading={saving}>
          <Button
            disabled={!selectedContributors || saving}
            onClick={_onSave}
            color="primary"
            autoFocus
          >
            <FormattedMessage id={'general.save'} />
          </Button>
        </CircularProgressWrapper>
      </DialogActions>
    </Dialog>
  )
}

ContributorPickerDialog.defaultProps = {
  onSave: () => {},
  onCancel: () => {},
}

export default injectIntl(withStyles(styles)(ContributorPickerDialog))
