// @flow
import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'

type Props = {
  children: string | React.Node
}

const useStyles = makeStyles(theme => ({
  description: {
    color: theme.palette.primary.main,
    marginTop: 10,
    fontSize: 16,
    whiteSpace: 'pre-wrap',
  },
}))

const Description = (props: Props) => {
  const { children } = props
  const classes = useStyles(props)

  if (!React.isValidElement(children)) {
    return <div className={classes.description}>{ children }</div>
  }
  
  return children
}

export default Description
