(function() {
	'use strict';

	angular
		.module('wf.common')
		.service('responsiveUi', ResponsiveUiService);

	ResponsiveUiService.$inject = [ "$rootScope" ];
	function ResponsiveUiService($rootScope) {
		var vm = this;

		_.assign(vm, {
			headerElement: undefined,
			themeColor: undefined,
			viewport: undefined,

			//Functions
			isResponsive: isResponsive,
			enableResponsiveness: enableResponsiveness,
			disableResponsiveness: disableResponsiveness,
			disableResponsivenessOnScopeDestroy: disableResponsivenessOnScopeDestroy,
			hideNavbarOnScroll: hideNavbarOnScroll
		});

		function isResponsive() {
			return $rootScope.responsiveUi;
		}

		function enableResponsiveness() {
			if (!$rootScope.responsiveUi) {
        $(document.body).addClass('wf-responsive-ui')
				$rootScope.responsiveUi = true;
				vm.themeColor = $('<meta name="theme-color" content="#213C50" /><meta name="apple-mobile-web-app-status-bar-style" content="#213C50">').appendTo('head'); // Add a color in the browser UI to match our page
				vm.viewport = $('<meta name="viewport" content="width=device-width, initial-scale=1.0">').appendTo('head'); // Allow media queries and responsiveness
			}
		}

		function disableResponsiveness() {
			if ($rootScope.responsiveUi) {
				if (vm.viewport) {
					vm.viewport.remove();
					vm.viewport = undefined;
				}

				if (vm.themeColor) {
					vm.themeColor.remove();
					vm.themeColor = undefined;
				}

				$(document.body).removeClass('wf-responsive-ui')
				$rootScope.responsiveUi = false;
			}
		}

		function disableResponsivenessOnScopeDestroy(scope) {
			if (scope) {
				scope.$on("$destroy", function() {
					disableResponsiveness();
				});
			}
		}

		function hideNavbarOnScroll(scope, customOptions) {
			var
				minDesktopScreenWidth = 992,
				currentScroll,
				lastScrollTop = 0,
				navbarElement = $("header#topnav"),
				navbarHeight = navbarElement.outerHeight(),
				navBarHidden = false,
				win = $(window),
				doc = $(document),
				options = {
					enableOnDesktop: false,
					delta: 5,
					throttle: 250
				}
			;

			if (customOptions)
				_.assign(options, customOptions);
			
			win.on('scroll.responsiveUi', _.throttle(function() {
				currentScroll = win.scrollTop();

				if (navBarHidden && win.outerWidth() > minDesktopScreenWidth)
					showNavBar();
				if (!options.enableOnDesktop && win.outerWidth() > minDesktopScreenWidth)
					return;
				if (Math.abs(lastScrollTop - currentScroll) <= options.delta)
					return;

				if (currentScroll > lastScrollTop && currentScroll > navbarHeight) {
					//Scroll down
					if (!navBarHidden)
						hideNavbar();
				}
				else {
					//Scroll up
					if (currentScroll + win.height() < doc.height()) {
						if (navBarHidden)
							showNavBar();
					}
				}
				lastScrollTop = currentScroll;
			}, options.throttle));
			
			if (scope) {
				scope.$on("$destroy", function() {
					if (navBarHidden)
						showNavBar();
					win.off('scroll.responsiveUi');
				});
			}
			else {
				console.log("Please pass scope to hideNavbarOnScroll() function in order to unbind the scroll event after the user navigates elsewhere.");
			}


			function hideNavbar() {
				var wfNavBarElement = navbarElement.find("div.wf-navbar.collapse");
				var wfNavbarExpanded = wfNavBarElement.hasClass("in");
				
					navbarElement.addClass('nav-up');
					if (wfNavbarExpanded)
						wfNavBarElement.collapse('hide');

					navBarHidden = true;
			}

			function showNavBar() {
				navBarHidden = false;
				navbarElement.removeClass('nav-up');
			}

		}
	}
})();
