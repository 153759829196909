// @flow
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import Api from '../../api'
import CircularProgressWrapper from '../../../components/CircularProgressWrapper'
import Colors from '@worldfavor/constants/colors'

const useStyles = makeStyles(theme => ({
  titleContainer: {
    paddingRight: 40,
  },
  text: {
    fontSize: 14,
    paddingBottom: 30,
  },
  dialogContent: {
    minHeight: 70,
    paddingTop: 20,
  },
  organizationCell: {
    verticalAlign: 'baseline',
  },
  error: {
    color: Colors.red,
    fontSize: 14,
    display: 'flex',
  },
  errorText: {
    marginLeft: 10,
  },
}))

type Props = {
  open: boolean,
  mailPurpose: Number,
  networkId: Number,
  onClosed: () => void,
  intl: any
}

const NextEmailSendoutInfoDialog = (props: Props) => {
  const { open, mailPurpose, networkId, onClosed, intl } = props
  const classes = useStyles(props)
  const [date, setDate] = useState(undefined)
  const [organizationEmailInfo, setOrganizationEmailInfo] = useState(undefined)
  const [loading, setLoading] = useState(undefined)
  const [dataExists, setDataExists] = useState(undefined)
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  useEffect(() => {
    getNextEmailSendoutInfo()
    if (open) {
      setLoading(true)
    }
    else {
      resetData()
      onClosed()
    }
  }, [open])

  const resetData = () => {
    setDate(undefined)
    setOrganizationEmailInfo(undefined)
    setLoading(false)
    setDataExists(false)
    setShowErrorMessage(false)
  }

  const getNextEmailSendoutInfo = async () => {
    if (open) {
      try {
        const emailInfo = await Api.post(Api.endpoints.getNextEmailSendoutInfo,
          {
            mailPurpose,
            networkId,
          }, {
          }, null, { responseType: 'application/json' })

        if (emailInfo.data.dateTime && emailInfo.data.organizationEmailInfo && emailInfo.data.organizationEmailInfo.length > 0) {
          setDate(emailInfo.data.dateTime)
          setOrganizationEmailInfo(emailInfo.data.organizationEmailInfo)
          setDataExists(true)
          setLoading(false)
        }
        else {
          resetData()
        }
      }
      catch {
        resetData()
        setShowErrorMessage(true)
      }
    }
  }

  return (
    <>
      <Dialog open={open} maxWidth="lg">
        <DialogTitle>
          <div className={classes.titleContainer}>
            <FormattedMessage
              id={'nextEmailSendoutInfoDialog.dialogTitle'}
              values={{
                mailPurpose: (
                  <FormattedMessage id={mailPurpose === 3 ? 'nextEmailSendoutInfoDialog.reminderEmail' : 'nextEmailSendoutInfoDialog.invitationEmail'} />
                ),
              }}
            />
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <CircularProgressWrapper
            loading={loading}
          >
            {!loading && dataExists && (
              <div>
                <div className={classes.text}>
                  <FormattedMessage id={'nextEmailSendoutInfoDialog.dateText'} />
                  <FormattedDate
                    weekday={'long'}
                    year={'numeric'}
                    day={'numeric'}
                    month={'long'}
                    hour={'2-digit'}
                    minute={'2-digit'}
                    timeZone={'utc'}
                    value={date}
                  />
                </div>
                <TableContainer>
                  <Table stickyHeader aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{intl.formatMessage({ id: 'nextEmailSendoutInfoDialog.organizations' })}</TableCell>
                        <TableCell>{intl.formatMessage({ id: 'nextEmailSendoutInfoDialog.contactPersons' })}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {organizationEmailInfo.map(row => (
                        <TableRow key={row.organizationName}>
                          <TableCell className={classes.organizationCell}>{row.organizationName}</TableCell>
                          <TableCell>{row.contactPersons.map(contactPerson => (<div key={contactPerson.email}>{`${contactPerson.name} <${contactPerson.email}>`}</div>))}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
            {!dataExists && !loading && !showErrorMessage &&
              (
                <div className={classes.text}>
                  {intl.formatMessage({ id: 'nextEmailSendoutInfoDialog.noEmailsScheduled' })}
                </div>
              )
            }
            {showErrorMessage &&
              (
                <div className={classes.error}><ErrorIcon />
                  <div className={classes.errorText}>
                    {intl.formatMessage({ id: 'nextEmailSendoutInfoDialog.errorMessage' })}
                  </div>
                </div>
              )
            }
          </CircularProgressWrapper>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClosed}>
            <FormattedMessage id={'exportDialog.close'} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default injectIntl(NextEmailSendoutInfoDialog)
