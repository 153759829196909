// @flow
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { getNodeFromNodeId, getSubNodesFromNodeId } from '@worldfavor/portal/selectors/dataSelector'
import { getAuthUserOrganizationWfid, getCurrentUser } from '@worldfavor/portal/selectors/appSelector'
import { getColleagues, getContributors } from '@worldfavor/portal/selectors/userSelector'
import { StaticIds } from '@worldfavor/constants'
import { loadItem, loadSubItemsOfAll } from '@worldfavor/portal/actions/dataThunk'
import { SubItemsKind } from '@worldfavor/constants/enums'
import { hexToRgbA } from '@worldfavor/constants/colors'
import { FormattedMessage } from 'react-intl'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import MaxWidthGrid from '@worldfavor/components/MaxWidthGrid'
import Loading from './Loading'
import _ from 'lodash'

import PageHeader from '@worldfavor/components/Header/PageHeader'
import CircularProgressWrapper from '@worldfavor/components/CircularProgressWrapper'
import Contributors from '@worldfavor/components/Contributors'
import EmptyState from '@worldfavor/components/EmptyState'
import GradientOverlay from '@worldfavor/components/GradientOverlay'
import GuidanceButton from '@worldfavor/components/Button/GuidanceButton'
import ReceivedInfluenceList from '@worldfavor/portal/components/Influence/ReceivedInfluenceList'
import Images from '@worldfavor/assets/Images'
import Image from '@worldfavor/components/Image'
import MultiLineTextEllipsis from '@worldfavor/components/Text/MultiLineTextEllipsis'
import { addContributors, removeContributors } from '@worldfavor/portal/actions/userThunk'
import { sortNaturally } from '@worldfavor/utils/helpers'

const useStyles = makeStyles(theme => ({
  influenceList: {
    position: 'relative',
    top: '-120px',
  },
  influenceListTitle: {
    fontSize: theme.typography.fontSizes.xxLarge,
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 36,
  },
  panel: {
    padding: 36,
    marginTop: 36,
    minHeight: 300,
    '&:first-child': {
      marginTop: 0,
    },
  },
  guidanceRoot: {
    justifyContent: 'flex-end',
    marginTop: 45,
  },
  guidanceButton: {
    fontSize: theme.typography.fontSizes.default,
    color: hexToRgbA(theme.palette.common.white, 0.87),
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeights.medium,
    letterSpacing: '0.15em',

    '& i': {
      fontSize: theme.typography.fontSizes.default,
    },
  },
  noRequestsEmptyState: {
    padding: 46,
  },
  contributors: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const useTitleStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSizes.xxxLarge,
    fontWeight: theme.typography.fontWeights.light,
    display: 'flex',
    alignItems: 'center',
  },
}))

const useDescriptionStyles = makeStyles(theme => ({
  description: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSizes.larger,
    fontWeight: theme.typography.fontWeights.light,
    width: '100%',
  },
  readMoreClass: {
    backgroundColor: hexToRgbA(theme.palette.common.black, 0.4),
    color: theme.palette.common.white,
    padding: '4px 6px',
    fontSize: theme.typography.fontSizes.medium,
    borderRadius: 3,
    position: 'relative',
    top: '-2px',
  },
}))

const PageTitle = ((props) => {
  const { text } = props
  const classes = useTitleStyles(props)

  return (<span className={classes.title}>{text}</span>)
})

const PageDescription = ((props) => {
  const { text } = props
  const classes = useDescriptionStyles(props)

  return (
    <MultiLineTextEllipsis
      lines={3}
      className={classes.description}
      classes={{ readMore: classes.readMoreClass, readLess: classes.readMoreClass }}
    >
      {text}
    </MultiLineTextEllipsis>
  )
})

const NetworkInfluencesScene = (props) => {
  const classes = useStyles(props)
  const { match } = props
  const dispatch = useDispatch()
  const { networkWfid } = match.params
  const organizationWfid = useSelector(state => getAuthUserOrganizationWfid(state))
  const currentUser = useSelector(getCurrentUser)
  const network = useSelector(state => getNodeFromNodeId(state, networkWfid))
  const creatorOrganization = useSelector(state => network ? getNodeFromNodeId(state, network.creatorOrganizationWfid) : undefined)
  const contributors = useSelector(state => getContributors(state, networkWfid))
  const contributorEdges = contributors.reduce((acc, { edge, node }) => {
    return {
      ...acc,
      [node.wfid]: edge,
    }
  }, {})
  const contributorNodes = contributors.map(({ node }) => node)
  const receivedInfluences = useSelector(state => network ? getSubNodesFromNodeId(state, StaticIds.ReceivedInfluences).filter(x => x.node && x.node.channelId === network.id).map(item => item.node) : [])
  const colleagues = useSelector(state => getColleagues(state))

  const [colleaguesLoading, setColleaguesLoading] = useState(false)
  const [contributorsLoading, setContributorsLoading] = useState(false)
  const [networkLoading, setNetworkLoading] = useState(false)
  const [receivedInfluencesLoading, setReceivedInfluencesLoading] = useState(false)

  const requestedInfluences = _.chain(filterInfluencesBy(isRequestedInfluence))
    .groupBy(x => (x.userId === currentUser.id ? 1 : 0) + (x.taggedUserIds && x.taggedUserIds.includes(currentUser.id) ? 1 : 0))
    .map((val, key) => ({ val, key }))
    .orderBy([ 'key' ], [ 'desc' ])
    .map(x => x.val)
    .flatten()
    .value()
  const influencesToExplore = filterInfluencesBy(isInfluenceToExplore)
  const thirdPartyInfluences = filterInfluencesBy(hasThirdPartyTarget)
  const standardsInfluences = filterInfluencesBy(isTool)

  function filterInfluencesBy(checkerFnc) {
    return sortNaturally(receivedInfluences.filter(influence => checkerFnc(influence)), 'title')
  }

  function isRequestedInfluence(influence) {
    return !influence.isContentReadOnly && !hasThirdPartyTarget(influence) && !isTool(influence)
  }

  function isInfluenceToExplore(influence) {
    return influence.isContentReadOnly && !hasThirdPartyTarget(influence) && !isTool(influence)
  }

  function hasThirdPartyTarget(influence) {
    return influence.hasThirdPartyTarget
  }

  function isTool(influence) {
    return influence.isTool
  }

  async function onSaveContributors(contributorsToAdd, contributorsToRemove) {
    const promises = []

    if (contributorsToAdd && contributorsToAdd.length) {
      const contributorWfids = contributorsToAdd.map(x => x.wfid)
      promises.push(dispatch(addContributors(networkWfid, contributorWfids, organizationWfid)))
    }

    if (contributorsToRemove && contributorsToRemove.length) {
      const contributorIds = contributorsToRemove.map(({ wfid }) => wfid)
      const contributorEdgesToRemove = contributorIds.map(wfid => contributorEdges[wfid])
      promises.push(dispatch(removeContributors(contributorEdgesToRemove)))
    }

    await Promise.all(promises)
  }

  useEffect(() => {
    async function fetchContributors() {
      setContributorsLoading(true)
      try {
        const colleagueWfids = colleagues.map(colleague => colleague.wfid)
        await dispatch(loadSubItemsOfAll(colleagueWfids, SubItemsKind.parents,
          { ticket: { contextParentWfids: [organizationWfid] } }))
      } catch (e) {
        // TODO handle error
      } finally {
        setContributorsLoading(false)
      }
    }

    fetchContributors()
  }, [colleagues.length, organizationWfid])

  useEffect(() => {
    async function fetchColleagues() {
      const [type, id] = StaticIds.Colleagues.split('-')
      setColleaguesLoading(true)
      try {
        await dispatch(loadItem(id, type))
      } catch (e) {
        // TODO handle error
      } finally {
        setColleaguesLoading(false)
      }
    }

    fetchColleagues()
  }, [])

  useEffect(() => {
    async function fetchReceivedInfluences() {
      const [type, id] = StaticIds.ReceivedInfluences.split('-')
      setReceivedInfluencesLoading(true)
      try {
        await dispatch(loadItem(id, type))
      } catch (e) {
        // TODO handle error
      } finally {
        setReceivedInfluencesLoading(false)
      }
    }

    fetchReceivedInfluences()
  }, [])

  useEffect(() => {
    async function fetchNetwork() {
      const [type, id] = networkWfid.split('-')
      setNetworkLoading(true)
      try {
        await dispatch(loadItem(id, type, { getterConditions: { includeOrganizations: true } }))
      } catch (e) {
        // TODO handle error
      } finally {
        setNetworkLoading(false)
      }
    }

    fetchNetwork()
  }, [networkWfid])

  if (networkLoading || !creatorOrganization || !network) {
    return <Loading />
  }

  return (
    <>
      <Image
        src={network.imageUrlLarge || Images.defaultCoverImage}
        style={{ minHeight: 300, paddingBottom: 120 }}
        overlay={<GradientOverlay />}
        size={'cover'}
      >
        <Grid container justify={'center'}>
          <MaxWidthGrid item xs={8}>
            <PageHeader
              title={<PageTitle text={creatorOrganization.name} />}
              description={<PageDescription text={network.description} />}
              verticalLineColor={'white'}
              imageUrl={creatorOrganization.imageUrlLarge}
            >
              <div className={classes.contributors}>
                <CircularProgressWrapper loading={contributorsLoading && colleaguesLoading}>
                  <Contributors
                    maxAvatar={5}
                    networkWfid={networkWfid}
                    contributors={contributorNodes}
                    colleagues={colleagues}
                    onSaveContributors={onSaveContributors}
                  />
                </CircularProgressWrapper>
                {
                  network.guidance && (
                    <GuidanceButton
                      icon={'fas fa-book-open'}
                      classes={{ root: classes.guidanceRoot, guidanceButton: classes.guidanceButton }}
                      guidance={network.guidance}
                      dialogTitle={<FormattedMessage id={'general.guidance'} />}
                      buttonLabel={
                        <FormattedMessage id="general.guidance" />
                      }
                    />
                  )
                }
              </div>
            </PageHeader>
          </MaxWidthGrid>
        </Grid>
      </Image>
      <div className={classes.influenceList}>
        <Grid container justify={'center'}>
          <MaxWidthGrid item xs={12}>
            { receivedInfluencesLoading ? (
              <Paper>
                <Loading />
              </Paper>
            ) : (
              <>
                {
                  (!receivedInfluences || !receivedInfluences.length) && (
                    <Paper elevation={1} classes={{ root: classes.panel }}>
                      <h2 className={classes.influenceListTitle} data-cy="influence-list-title">
                        <FormattedMessage id={'general.requests'} />
                      </h2>
                      <EmptyState
                        title={<FormattedMessage id={'requests.emptyState.title'} />}
                        description={<FormattedMessage id={'requests.emptyState.description'} />}
                        iconClass={'fas fa-inbox'}
                        className={classes.noRequestsEmptyState}
                      />
                    </Paper>
                  )
                }
                {
                  requestedInfluences && requestedInfluences.length > 0 &&  (
                    <Paper elevation={1} classes={{ root: classes.panel }}>
                      <h2 className={classes.influenceListTitle} data-cy="influence-list-title">
                        <FormattedMessage id={'general.requests'} />
                      </h2>
                      <ReceivedInfluenceList influences={requestedInfluences} />
                    </Paper>
                  )
                }
                {
                  influencesToExplore && influencesToExplore.length > 0 && (
                    <Paper elevation={1} classes={{ root: classes.panel }}>
                      <h2 className={classes.influenceListTitle}>
                        <FormattedMessage id={'general.explore'} />
                      </h2>
                      <ReceivedInfluenceList influences={influencesToExplore} hideStatus />
                    </Paper>
                  )
                }
                {
                  thirdPartyInfluences && thirdPartyInfluences.length > 0 && (
                    <Paper elevation={1} classes={{ root: classes.panel }}>
                      <h2 className={classes.influenceListTitle} data-cy="on-behalf-list-title">
                        <FormattedMessage id={'supplyChain.thirdPartyReporting.thirdPartyInfluenceBoxTitle'} />
                      </h2>
                      <ReceivedInfluenceList influences={thirdPartyInfluences} />
                    </Paper>
                  )
                }
                {
                  standardsInfluences && standardsInfluences.length > 0 && (
                    <Paper elevation={1} classes={{ root: classes.panel }}>
                      <h2 className={classes.influenceListTitle} data-cy="on-behalf-list-title">
                        <FormattedMessage id={'general.tools'} />
                      </h2>
                      <ReceivedInfluenceList influences={standardsInfluences} />
                    </Paper>
                  )
                }
              </>
            )
            }
          </MaxWidthGrid>
        </Grid>
      </div>
    </>
  )
}

export default NetworkInfluencesScene
