import React from 'react'
import { react2angular } from 'react2angular'
import DataCollectorImporter from '@worldfavor/portal/components/Importer/DataCollectorImporter'
import { WrapProvider } from './providers'

const props = [
  'open',
  'organizationName',
  'solutionType',
  'networkId',
  'onClosed',
]

class DataCollectorImporterWrapper extends React.Component {
  prepareProps = (props) => {
    return {
      ...props
    }
  }

  render() {
    const { wfAuth, ...rest } = this.props
    return (
      <WrapProvider wfAuth={wfAuth}>
        <DataCollectorImporter {...this.prepareProps(rest)} />
      </WrapProvider>
    )
  }
}

export default react2angular(DataCollectorImporterWrapper, props, ['wfAuth'])
