// @flow
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage, injectIntl } from 'react-intl'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Api from '../../api'
import Colors from '@worldfavor/constants/colors'
import dateFns from 'date-fns'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CircularProgressWrapper from '../../../components/CircularProgressWrapper'

const largeCheckBoxFontSize = '1.3em'

const useStyles = makeStyles(theme => ({
  titleContainer: {
    paddingRight: 40,
  },
  settingContainer: {
    padding: '17px 16px 12px',

    '&:not(:last-child)': {
      borderBottom: '1px solid #eaeaea',
    },
  },
  mainLabel: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  label: {
    fontSize: theme.typography.fontSizes.medium,
  },
  text: {
    fontSize: theme.typography.fontSizes.medium,
  },
  paperRoot: {
    maxHeight: 200,
    overflowY: 'auto',
    marginBottom: 8,
  },
  menuItem: {
    fontSize: theme.typography.fontSizes.medium,
    paddingTop: 3,
    paddingBottom: 3,
  },
  selectAllButton: {
    color: Colors.blue,
    textTransform: 'none',
    width: 90,
    '&:hover': {
      color: Colors.blueDark,
      background: 'none',
    },
  },
  buttonText: {
    fontSize: theme.typography.fontSizes.medium,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      fontSize: theme.typography.fontSizes.medium,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  alertDialogContent: {
    display: 'flex',
    alignItems: 'center',
  },
  alertDialogIcon: {
    fontSize: 56,
    marginRight: 20,

    '&.info': {
      color: Colors.blue,
    },
    '&.success': {
      color: Colors.green,
    },
    '&.error': {
      color: Colors.red,
    },
  },
}))

type Props = {
  open: boolean,
  categories: any[],
  packages: any[],
  networkId: number,
  onClosed: () => void,
  intl: any
}

const NetworkOrganizationsExporter = (props: Props) => {
  const { open, categories, packages, networkId, onClosed, intl } = props
  const classes = useStyles(props)
  const [loading, setLoading] = useState(false)

  const categoriesCheckedDefault = {}
  categories.forEach(c => categoriesCheckedDefault[c.id] = true)
  const categoryTitles = {}
  categories.forEach(c => categoryTitles[c.id] = c.title)

  const packagesCheckedDefault = {}
  packages.forEach(c => packagesCheckedDefault[c.id] = true)
  const packageTitles = {}
  packages.forEach(c => packageTitles[c.id] = c.title)

  const [includeCategorizations, setIncludeCategorizations] = useState(true)
  const [checkedCategories, setCheckedCategories] = useState([])
  const [useCategoriesGrouping, setUseCategoriesGrouping] = useState(true)
  const [putCategorizationsLast, setPutCategorizationsLast] = useState(false)

  const [includePackages, setIncludePackages] = useState(true)
  const [checkedPackages, setCheckedPackages] = useState([])
  const [includeFulfillmentState, setIncludeFulfillmentState] = useState(false)
  const [includeLatestFulfillmentDate, setIncludeLatestFulfillmentDate] = useState(false)

  const [includeReportedData, setIncludeReportedData] = useState(false)
  const [includeAllPickerOptions, setIncludeAllPickerOptions] = useState(false)
  const [includeReportedAt, setIncludeReportedAt] = useState(false)

  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false)
  const [alertDialogTitle, setAlertDialogTitle] = useState()
  const [alertDialogContent, setAlertDialogContent] = useState()
  const [alertDialogType, setAlertDialogType] = useState()
  const [AlertDialogIconComponent, setAlertDialogIconComponent] = useState()

  const [fileExportSuccess, setFileExportSuccess] = useState(false)

  useEffect(() => {
    setCheckedCategories(categoriesCheckedDefault)
    setCheckedPackages(packagesCheckedDefault)
  }, [categories, packages])

  function onClose() {
    if (loading) {
      showBusyDialog()
      return
    }

    onClosed && onClosed()
    setIsAlertDialogOpen(false)

    setTimeout(() => {
      setFileExportSuccess(false)
      setLoading(null)
      setAlertDialogTitle(null)
      setAlertDialogContent(null)
      setAlertDialogType(null)
      setAlertDialogIconComponent(null)
    }, 250)
  }

  async function onExportClick() {
    setLoading(true)
    onExport()
  }

  const getDisabled = (disabled = false) => {
    if (loading) {
      return true
    }
    else {
      return disabled
    }
  }

  async function onExport() {
    try {
      const selectedPackageIds = packages.filter(p => checkedPackages[p.id]).map(p => p.id)
      const selectedCategoryIds = categories.filter(c => checkedCategories[c.id]).map(c => c.id)

      const exportResult = await Api.post(Api.endpoints.exportNetworkOrganizations,
        {
          networkId,
          packageIds: selectedPackageIds,
          includePackages,
          includeFulfillmentState,
          includeLatestFulfillmentDate,
          includeCategorizations,
          categoryIds: selectedCategoryIds,
          useCategoryGrouping: useCategoriesGrouping,
          includeAnswers: includeReportedData,
          includeAllPickerOptions,
          includeAnswersReportedAt: includeReportedAt,
        }, {
        }, null, { overrideData: true, responseType: 'blob' })

      const now = dateFns.format(new Date(), 'YYMMDD_HHmmss')
      const downloadedFileName = `Network ${networkId} Organizations ${now} CONFIDENTIAL.xlsx`

      setTimeout(() => {
        setFileExportSuccess(true)
        showSuccessDialog(downloadedFileName)
      }, 250)

      const url = URL.createObjectURL(exportResult.data)
      const link = document.createElement('a')
      link.href = url
      link.download = downloadedFileName
      link.style = { display: 'none' }
      document.body.append(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)

    } catch {
      setTimeout(() => showErrorDialog(), 250)
    } finally {
      setIsAlertDialogOpen(false)
      setTimeout(() => setLoading(false), 400)
    }
  }

  function showSuccessDialog(downloadedFileName) {
    setAlertDialogTitle(intl.formatMessage({ id: 'networkOrganizationsExporter.infoDialog.successExportTitle' }))
    setAlertDialogContent(intl.formatMessage({ id: 'networkOrganizationsExporter.infoDialog.successExportDescription' }, {
      downloadedFileName: <strong>{downloadedFileName}</strong>,
      br: <br />,
    }))
    setAlertDialogType('success')
    setAlertDialogIconComponent(CheckCircleIcon)
    setIsAlertDialogOpen(true)
  }

  function showBusyDialog() {
    setAlertDialogTitle(intl.formatMessage({ id: 'networkOrganizationsExporter.infoDialog.busyExportingTitle' }))
    setAlertDialogContent(intl.formatMessage({ id: 'networkOrganizationsExporter.infoDialog.busyExportingDescription' }))
    setAlertDialogType('info')
    setAlertDialogIconComponent(InfoIcon)
    setIsAlertDialogOpen(true)
  }

  function showErrorDialog() {
    setAlertDialogTitle(intl.formatMessage({ id: 'networkOrganizationsExporter.infoDialog.errorTitle' }))
    setAlertDialogContent(intl.formatMessage({ id: 'networkOrganizationsExporter.infoDialog.errorDescription' }))
    setAlertDialogType('error')
    setAlertDialogIconComponent(ErrorIcon)
    setIsAlertDialogOpen(true)
  }

  function closeAlertDialog() {
    setTimeout(() => {
      setAlertDialogTitle(null)
      setAlertDialogContent(null)
      setAlertDialogType(null)
      setAlertDialogIconComponent(null)
    }, 500)

    setIsAlertDialogOpen(false)
  }

  const handleCheckboxChange = (setState, event) => {
    setFileExportSuccess(false)
    setState(event.target.checked)
  }

  const handleCategoriesChange = (id) => {
    setFileExportSuccess(false)
    const newObj = { ...checkedCategories, [id]: !checkedCategories[id] }
    setCheckedCategories(newObj)
  }

  const handlePackagesChange = (id) => {
    setFileExportSuccess(false)
    const newObj = { ...checkedPackages, [id]: !checkedPackages[id] }
    setCheckedPackages(newObj)
  }

  const onChangeAllCategories = (checked) => {
    setFileExportSuccess(false)
    const newObj = {}
    Object.keys(checkedCategories).forEach(id => newObj[id] = checked)
    setCheckedCategories(newObj)
  }

  const onChangeAllPackages = (checked) => {
    setFileExportSuccess(false)
    const newObj = {}
    Object.keys(checkedPackages).forEach(id => newObj[id] = checked)
    setCheckedPackages(newObj)
  }

  const ChangeAllButton = (disabled, text, onChange, checked) => {
    return (
      <Button
        disabled={getDisabled(disabled)}
        className={classes.selectAllButton}
        onClick={() => onChange(checked)}
        classes={{ label: classes.buttonText }}
      >
        {text}
      </Button>
    )
  }

  const LargeCheckBox = ({ checked, label, onChange }) => {
    return (
      <FormControlLabel
        disabled={loading}
        classes={{
          label: classes.mainLabel,
        }}
        control={(
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: largeCheckBoxFontSize }} />}
            checkedIcon={<CheckBoxIcon style={{ fontSize: largeCheckBoxFontSize }} />}
            disabled={loading}
            checked={checked}
            onChange={onChange}
          />
        )}
        label={label}
      />
    )
  }

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>
          <div className={classes.titleContainer}>
            <FormattedMessage
              id={'networkOrganizationsExporter.dialogTitle'}
            />
          </div>
        </DialogTitle>
        <DialogContent style={{ minWidth: 500 }}>

          <div>
            <div className={classes.settingContainer}>
              <Grid container style={{ alignItems: 'center' }}>
                <Grid item xs className={classes.selection}>
                  <LargeCheckBox
                    checked={includeCategorizations}
                    onChange={e => handleCheckboxChange(setIncludeCategorizations, e)}
                    label={intl.formatMessage({ id: 'networkOrganizationsExporter.includeCategorizations' })}
                  />
                </Grid>
                <Grid item xs style={{ textAlign: 'right' }}>
                  {ChangeAllButton(!includeCategorizations, intl.formatMessage({ id: 'networkOrganizationsExporter.selectAll' }), onChangeAllCategories, true)}
                  {ChangeAllButton(!includeCategorizations, intl.formatMessage({ id: 'networkOrganizationsExporter.unselectAll' }), onChangeAllCategories, false)}
                </Grid>
                <Grid item xs={12}>
                  <Paper elevation={1} classes={{ root: classes.paperRoot }}>
                    <MenuList>
                      {
                        checkedCategories && (
                          Object.entries(checkedCategories).map(([id, checked]) => (
                            <MenuItem
                              key={`category-${id}`}
                              onClick={() => handleCategoriesChange(id)}
                              disabled={getDisabled(!includeCategorizations)}
                              selected={checked}
                              classes={{ root: classes.menuItem }}
                            >
                              <Checkbox
                                checked={checked}
                                onChange={() => handleCategoriesChange(id)}
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                                disabled={getDisabled(!includeCategorizations)}
                              />
                              {categoryTitles[id]}
                            </MenuItem>
                          ))
                        )
                      }
                    </MenuList>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    disabled={getDisabled(!includeCategorizations)}
                    control={(
                      <Checkbox
                        checked={useCategoriesGrouping}
                        onChange={e => handleCheckboxChange(setUseCategoriesGrouping, e)}
                      />
                    )}
                    label={intl.formatMessage({ id: 'networkOrganizationsExporter.useCategoriesGrouping' })}
                  />
                  <FormControlLabel
                    disabled={getDisabled(!includeCategorizations)}
                    control={(
                      <Checkbox
                        checked={putCategorizationsLast}
                        onChange={e => handleCheckboxChange(setPutCategorizationsLast, e)}
                      />
                    )}
                    label={intl.formatMessage({ id: 'networkOrganizationsExporter.putCategorizationsLast' })}
                  />
                </Grid>
              </Grid>
            </div>

            <div className={classes.settingContainer}>
              <Grid container style={{ alignItems: 'center' }}>
                <Grid item className={classes.selection}>
                  <LargeCheckBox
                    checked={includePackages}
                    onChange={e => handleCheckboxChange(setIncludePackages, e)}
                    label={intl.formatMessage({ id: 'networkOrganizationsExporter.includePackages' })}
                  />
                </Grid>
                <Grid item xs style={{ textAlign: 'right' }}>
                  {ChangeAllButton(!includePackages, intl.formatMessage({ id: 'networkOrganizationsExporter.selectAll' }), onChangeAllPackages, true)}
                  {ChangeAllButton(!includePackages, intl.formatMessage({ id: 'networkOrganizationsExporter.unselectAll' }), onChangeAllPackages, false)}
                </Grid>
                <Grid item xs={12}>
                  <Paper elevation={1} classes={{ root: classes.paperRoot }}>
                    <MenuList>
                      {
                        checkedPackages && (
                          Object.entries(checkedPackages).map(([id, checked]) => (
                            <MenuItem
                              key={`package-${id}`}
                              onClick={() => handlePackagesChange(id)}
                              disabled={getDisabled(!includePackages)}
                              selected={checked}
                              classes={{ root: classes.menuItem }}
                            >
                              <Checkbox
                                checked={checked}
                                onChange={() => handlePackagesChange(id)}
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                                disabled={getDisabled(!includePackages)}
                              />
                              {packageTitles[id]}
                            </MenuItem>
                          ))
                        )
                      }
                    </MenuList>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    disabled={getDisabled(!includePackages)}
                    control={(
                      <Checkbox
                        checked={includeFulfillmentState}
                        onChange={e => handleCheckboxChange(setIncludeFulfillmentState, e)}
                      />
                    )}
                    label={intl.formatMessage({ id: 'networkOrganizationsExporter.includeFulfillmentState' })}
                  />
                  <FormControlLabel
                    disabled={getDisabled(!includePackages)}
                    control={(
                      <Checkbox
                        checked={includeLatestFulfillmentDate}
                        onChange={e => handleCheckboxChange(setIncludeLatestFulfillmentDate, e)}
                      />
                    )}
                    label={intl.formatMessage({ id: 'networkOrganizationsExporter.includeLatestFulfillmentDate' })}
                  />
                </Grid>
              </Grid>
            </div>

            <div className={classes.settingContainer}>
              <Grid container>
                <Grid item xs={12} className={classes.selection}>
                  <LargeCheckBox
                    checked={includeReportedData}
                    onChange={e => handleCheckboxChange(setIncludeReportedData, e)}
                    label={intl.formatMessage({ id: 'networkOrganizationsExporter.includeReportedData' })}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    disabled={getDisabled(!includeReportedData)}
                    control={(
                      <Checkbox
                        checked={includeAllPickerOptions}
                        onChange={e => handleCheckboxChange(setIncludeAllPickerOptions, e)}
                      />
                    )}
                    label={intl.formatMessage({ id: 'networkOrganizationsExporter.includeAllPickerOptions' })}
                  />
                  <FormControlLabel
                    disabled={getDisabled(!includeReportedData)}
                    control={(
                      <Checkbox
                        checked={includeReportedAt}
                        onChange={e => handleCheckboxChange(setIncludeReportedAt, e)}
                      />
                    )}
                    label={intl.formatMessage({ id: 'networkOrganizationsExporter.includeReportedAt' })}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            <FormattedMessage id={'exportDialog.close'} />
          </Button>
          <CircularProgressWrapper
            loading={loading}
          >
            <Button
              disabled={loading || fileExportSuccess}
              onClick={onExportClick}
              variant="contained"
              style={fileExportSuccess ? { backgroundColor: Colors.green } : loading ? null : { backgroundColor: Colors.blue }}
              color="primary"
            >
              {fileExportSuccess ? <CheckCircleIcon /> : <FormattedMessage id={'exportDialog.export'} />}
            </Button>
          </CircularProgressWrapper>
        </DialogActions>
      </Dialog>

      <Dialog open={isAlertDialogOpen}>
        <DialogTitle>
          {alertDialogTitle}
        </DialogTitle>
        <DialogContent>
          <div className={classes.alertDialogContent}>
            {AlertDialogIconComponent && <AlertDialogIconComponent className={classNames(classes.alertDialogIcon, alertDialogType)} />}
            <div>
              {alertDialogContent}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAlertDialog} color="secondary">
            <FormattedMessage id={'general.ok'} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default injectIntl(NetworkOrganizationsExporter)
