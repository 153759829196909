export const FontFamily = 'Roboto, Helvetica, Arial, sans-serif'

export const FontWeights = {
  thin: 100,
  extraLight: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
  extraBlack: 950,
}

export const FontSizes = {
  xxSmall: 2,
  xSmall: 4,

  smallest: 6,
  smaller: 8,
  small: 10,

  default: 12,
  medium: 14,

  large: 16,
  larger: 18,
  largest: 24,

  xLarge: 28,
  xxLarge: 34,
  xxxLarge: 42,
}
