(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfInclude', wfInclude);

	wfInclude.$inject = [];
	
	function wfInclude() {
		var directive = {
			restrict: "AE",
			templateUrl: function(element, attrs) {
				return attrs.src;
			}
		};
		return directive;
	}
})();