// @flow
import * as React from 'react'
import Colors from '@worldfavor/constants/colors'

type Props = {
  width?: number | string,
  height?: number | string,
  color?: string,
  margin?: number | string,
  style?: { [string]: any },
  wrapperClass?: { [string]: any },
}

const Divider = ({ width, height, color, margin, style, ...rest }: Props) => (
  <div
    style={{
      width,
      height,
      margin,
      backgroundColor: color,
      position: 'relative',
      ...style,
    }}
    {...rest}
  />
)

Divider.defaultProps = {
  width: '100%',
  height: '100%',
  color: Colors.blackDark,
  style: {},
}

export default React.memo<Props>(Divider)
