import * as enums from '@worldfavor/constants/enums'

(function() {
'use strict';

	angular
		.module('wf.common')
		.controller('SupplierPlatformController', SupplierPlatformController);

	SupplierPlatformController.$inject = ['$scope', '$element', '$attrs', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', '$timeout', '$q'];
	function SupplierPlatformController($scope, $element, $attrs, dataOps, modal, wfObject, dataQuery, $translate, $timeout, $q) {
		var
			vm = this,
			mainCategory
		;
		_.assign(this, $scope.$parent.vm);

		_.assign(vm, {
			createSupplierGroup: createSupplierGroup,
			createDemandDocument: createDemandDocument,
			groupsStructure: null,
			demandsStructure: null,
			supplierGroups: [],
			supplierDemands: [],
			loading: true
		})

		activate();

		function activate() {
			dataOps.getSubItems(vm.item.childContent, 1, { childrenLoadDepth: 1}).then(function (res) {
				// console.log(res);
				vm.groupsStructure = res[0].childContent;
				vm.demandsStructure = res[1].childContent;
				vm.groupsStructureDR = res[0];
				vm.demandsStructureDR = res[1];
				// console.info(vm.groupsStructure)

				dataOps.getSubItems(vm.groupsStructure, 7, { childrenLoadDepth: 1 }).then(function (supplierGroups) {
					// console.log(supplierGroups);
					vm.supplierGroups = supplierGroups;

					dataOps.getSubItems(vm.demandsStructure, 7, { childrenLoadDepth: 1 }).then(function (supplierDemands) {
						vm.supplierDemands = supplierDemands;
						vm.loading = false;
						$timeout(function () {});
					});
				});


			});
		}

		function createSupplierGroup() {
			var
				jqDf = $.Deferred()
			;

			modal.createWithRelation({
				objectType: 71,
				simplifyForm: true,
				dataRelationOptions: {
					kind: enums.subItemsKind.childrenByUser, item1: vm.groupsStructure
				}
			}).then(function (dataRelationItem) {
				var newGroupStructure;

				if (!dataRelationItem) {
					jqDf.resolve(false);
					return;
				}
				newGroupStructure = dataRelationItem.childContent;

				dataOps.create({
					type: 52,
					title: newGroupStructure.title
				}).then(function (network) {
					// Add settings on structure
					dataOps.saveSettings({
						item: newGroupStructure,
						settings: {
							dataRelation: null,
							networkId: network.id
						}
					}).then(function () {
						// Add settings on dataRelation
						dataOps.saveSettings({
							item: dataRelationItem,
							settings: {
								templateId: 54,
								childrenLoadDepth: 1
							}
						}).then(function () {
							// Create first child (Kategorier)
							dataOps.create({
								type: enums.objectType.structure,
								title: { sv: "Underkategorier", en: "Sub-categories", generic: null }
							}).then(function (categoriesStructure) {
								// Create first child dataRelation
								dataOps.createSubItemRelation(newGroupStructure, categoriesStructure, enums.subItemsKind.childrenByUser, null, 1).then(function (res) {
									// Add first child settings
									dataOps.saveSettings({
										item: categoriesStructure,
										settings: {
											dataRelation: true,
											// dataRelationByUser: true,
											childrenTemplateId: 55
										}
									}).then(function () {

										// Create second child (Krav)
										dataOps.create({
											type: enums.objectType.structure,
											title: { sv: "Kravpaket", en: "Requirement packages", generic: null }
										}).then(function (groupDemandsStructure) {
											// Create first child dataRelation
											dataOps.createSubItemRelation(newGroupStructure, groupDemandsStructure, enums.subItemsKind.childrenByUser, null, 2).then(function (res) {
												// Add first child settings
												dataOps.saveSettings({
													item: groupDemandsStructure,
													settings: {
														dataRelation: true
														// dataRelationByUser: true
													}
												}).then(function () {
													jqDf.resolve();
													vm.supplierGroups = vm.groupsStructure.childs;
												});
											})
										});
									});
								})
							});
						});
					});
				});
			});

			return jqDf.promise();
		}

		function createDemandDocument() {
			var
				jqDf = $.Deferred()
			;

			modal.createWithRelation({
				objectType: 71,
				simplifyForm: true,
				dataRelationOptions: {
					kind: enums.subItemsKind.childrenByUser, item1: vm.demandsStructure
				}
			}).then(function (res) {
				vm.supplierDemands = vm.demandsStructure.childs;
				jqDf.resolve();
			});

			return jqDf.promise();
		}
	}
})();
