// @flow
import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Colors from '@worldfavor/constants/colors'
import classNames from 'classnames'

type Props = {
  children?: React.Node,
  compact?: boolean,
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(12),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  compact: {
    '& $circle': {
      height: 86,
      width: 86,
    },

    '& $icon': {
      fontSize: 28,
    },

    '& $title': {
      fontSize: 16,
    },
  },
  circle: {
    height: 158,
    width: 158,
    borderRadius: '100%',
    backgroundColor: Colors.grayLighter,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    fontSize: 48,
    color: Colors.gray,
  },
  title: {
    ...theme.typography.h6,
    fontSize: 18,
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
}))

// TODO add translations
const DefaultTitle = () => (
  <React.Fragment>
    <div>Oups! Nothing found.</div>
    <div>Try searching again</div>
  </React.Fragment>
)

const EmptyOrganizationList = (props: Props) => {
  const { compact, children } = props
  const classes = useStyles(props)
  return (
    <div className={classNames(classes.root, compact && classes.compact)}>
      <div className={classes.circle}>
        <i className={classNames(classes.icon, 'fas fa-building')} />
      </div>
      <div className={classes.title}>
        {
          children || <DefaultTitle />
        }
      </div>
    </div>
  )
}

export default EmptyOrganizationList
