import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';
	
	angular
		.module('wf.common')
		.component('wfAnalyzeJob', {
			templateUrl: "scripts/wf/analyze/job/wfAnalyzeJob.component.html",
			controller: wfAnalyzeJobController,
			controllerAs: "vm",
			bindings: {
				ticket: "<",
				item: "<",
				jobId: "<"
			}
		});

		wfAnalyzeJobController.$inject = [ "$scope", "$timeout", "$element", "$rootScope", "$state", "requirements", "$transclude", "$compile", "$translate", "$q", "dataOperationsService", "wfObject", "statisticsService", "analyzeService" ];
	function wfAnalyzeJobController($scope, $timeout, $element, $rootScope, $state, requirementService, $transclude, $compile, $translate, $q, dataOps, wfObject, statisticsService, analyzeService) {
		var
			vm = this
		;

		this.$onInit = $onInit;

		function $onInit() {
			_.assign(vm, {
				loaded: false,
				analyzeJob: undefined,
				jobChildContent: undefined,
				useHierarchical: true,

				uiMode: enums.uiMode.view,
				hierarchicalVmOverride: {
					isWorkMode: false,
					itemSettings: {
						showDropdown: false,
						showMetadata: false,
						showDropdownByType: undefined,
						metadataConfig: {
							popoverItemSettings: {
								showDropdown: false,
								showDropdownByKind: undefined,
								metadataConfig: {
									limitRelationKinds: [],
									showDetailedViewLink: false,
									showEmbeddedObjectViewer: false
								}
							}
						}
					}
				},
				updateAllItemsResults: function () {
					$scope.$broadcast("updateLatestItemAnalyzeResults");
				}
			});
				
			dataOps.getObject({
				objectType: enums.objectType.analyzeJob,
				objectId: vm.jobId
			}).then(function (analyzeJob) {
				vm.analyzeJob = analyzeJob;
				
				if (analyzeJob) {
					vm.ticket = {
						organizationId: analyzeJob.targetOrganizationId,
						networkId: analyzeJob.networkId,
						contextParentWfid: analyzeJob.contextParentWfid
					};

					vm.jobChildContent = analyzeJob.childContent;

					dataOps.getObject({
						objectType: analyzeJob.objectType,
						objectId: analyzeJob.objectId,
						childrenLoadDepth: 10,
						getterConditions: {
							dataRelationOrganizationMatchMode: 2,
							onlyLatestAnswers: true,
							loadRequirements: true,
							loadAnalyzeRequirements: true,
							loadAnalyzeResults: true
						},
						ticket: {
							organizationId: analyzeJob.targetOrganizationId,
							networkId: analyzeJob.networkId,
							contextParentWfid: analyzeJob.contextParentWfid
						}
					}).then(function (jobChildContent) {
						vm.jobChildContent = jobChildContent;

						dataOps.getObject({
							objectType: enums.objectType.organization,
							objectId: analyzeJob.targetOrganizationId
						}).then(function (res) {
							vm.targetOrganization = res;

							vm.useMaterialityAnalysis = _.get(jobChildContent, "conditions.uiSettings.analyzePackageSettings.useMaterialityAnalysis") === true; // Create a new setting in backend called "analyzePackageSettings" for the future
							if (vm.useMaterialityAnalysis) {
								setupMaterialityAnalysisUsage();
							}
							
							vm.loaded = true;
							$timeout();
						});
		
					});
				}
			});
		}

		function setupMaterialityAnalysisUsage() {
			vm.useHierarchical = false;

			vm.materialityAnalysisConfig = {
				ticket: {
					organizationId: vm.analyzeJob.targetOrganizationId,
					networkId: vm.analyzeJob.networkId
				},
				allowExpanding: true,
				scopeCategoryId: 23963,
				title: $translate.instant("modules.materialityAnalysis.griHeaderWithOrgName", { orgname: vm.targetOrganization.name }),
				wording: {
					addedToScope_header: "Is it in included by the organization?",
					addedToScope: "Included"
				},
				scopeCategorySubItemsKind: enums.subItemsKind.relatedContentByUser,
				scopeCategoryLookup: { // Disclosure IDs by 2nd level GRI categories
					"71-10664": [ 12759, 12760, 12761, 10673, 10674, 10675, 10676 ],
					"71-10665": [ 12762, 12763, 12764, 10682, 10683 ],
					"71-10666": [ 12765, 12766, 12767, 10688, 10690 ],
					"71-10667": [ 12768, 12769, 12770, 10694 ],
					"71-10668": [ 12771, 12772, 12773, 10698, 10701, 10703 ],
					"71-10669": [ 12774, 12775, 12776, 10707 ],
					"71-10710": [ 12777, 12778, 12779, 10720, 10722, 10724 ],
					"71-10711": [ 12780, 12781, 12782, 10740, 10742, 10744, 10746, 10748 ],
					"71-10712": [ 12783, 12784, 12785, 10752, 10754, 10756 ],
					"71-10713": [ 12786, 12787, 12788, 10760, 10762, 10764, 10766 ],
					"71-10714": [ 12789, 12790, 12791, 10770, 10772, 10774, 10776, 10778, 10780, 10782 ],
					"71-10715": [ 12792, 12793, 12794, 10786, 10789, 10793, 12168, 12169 ],
					"71-10716": [ 12795, 12796, 12797, 10800 ],
					"71-10717": [ 12798, 12799, 12800, 10806, 10809 ],
					"71-11021": [ 12801, 12802, 12803, 11042, 11046, 11050 ],
					"71-11022": [ 12804, 12805, 12806, 11059 ],
					"71-11023": [ 12807, 12808, 12809, 11065, 11070, 11078, 11075 ],
					"71-11024": [ 12810, 12811, 12812, 11084, 11087, 11091 ],
					"71-11025": [ 12813, 12814, 12815, 11096, 11100 ],
					"71-11026": [ 12816, 12817, 12818, 11106 ],
					"71-11027": [ 12819, 12820, 12821, 11112 ],
					"71-11028": [ 12822, 12823, 12824, 11118 ],
					"71-11029": [ 12825, 12826, 12827, 11125 ],
					"71-11030": [ 12828, 12829, 12830, 11131 ],
					"71-11031": [ 12831, 12832, 12833, 11137 ],
					"71-11032": [ 12834, 12835, 12836, 11143, 11146, 11150 ],
					"71-11033": [ 12837, 12838, 12839, 11156, 11159 ],
					"71-11034": [ 12840, 12841, 12842, 11164, 11167 ],
					"71-11035": [ 12843, 12844, 12845, 11176 ],
					"71-11036": [ 12846, 12847, 12848, 11183, 11186 ],
					"71-11037": [ 12849, 12850, 12851, 11192, 11196, 11200 ],
					"71-11038": [ 12852, 12853, 12854, 11206 ],
					"71-11039": [ 12855, 12856, 12857, 11213 ]
				}
			};

		}
	}
})();
