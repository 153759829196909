(function() {
'use strict';

	angular
		.module('wf.common')
		.controller('VerificationViewerController', VerificationViewerController);

	VerificationViewerController.$inject = ['$scope', '$element', '$attrs', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', '$timeout', '$state', 'pdfGenerator','moment'];

	function VerificationViewerController($scope, $element, $attrs, dataOps, modal, wfObject, dataQuery, $translate, $timeout, $state, pdfGenerator, moment) {
		var
			vm = this,
			mainStructure
		;
		_.assign(this, $scope.$parent.vm);

		vm.title = $translate.instant('Verification');
		vm.$state = $state;
		vm.verification = vm.item;
		vm.verifiedContent = null; // Display the "main" verified object, the structure
		vm.generatePdf = generatePdf;
		vm.templateId = vm.context && vm.context.templateId ? vm.context.templateId : 41;
		vm.influence = vm.context && vm.context.influence ? vm.context.influence : null;
		// Get item that this verification verifies (6 = SubItemsKind.Verifies)
		dataOps.getSubItems(vm.verification, 6).then(function (res) {
			// Assume that the only structure in the list of verified objects is the one to render.
			var
				mainStructureDataRelation = _.filter(res, { parentType: 71 })[0]
			;

			mainStructure = mainStructureDataRelation ? mainStructureDataRelation.parentContent : null

			if (mainStructure) {
				// Get content of the structure
				dataOps.getObject(mainStructure.wfid, { bypassCache: true, childrenLoadDepth: 10 }).then(function (res) {
					dataOps.getObject({
						objectType: 101,
						objectId: vm.verification.verifierOrganizationId,
						skipExtras: true,
					}).then(function (organization) {
						$timeout(function () {
							vm.verifiedContent = mainStructure;

							vm.intendedFor_detailsList = [
								{
									label: $translate.instant("modules.verification.organizationName"),
									value: organization.name
								},
								{
									label: $translate.instant("modules.verification.registrationNumber"),
									value: organization.registrationNumber
								},
								{
									label: $translate.instant("modules.verification.contactPerson"),
									value: vm.verification.verifierName
								},
								{
									label: $translate.instant("modules.verification.city"),
									value: vm.verification.city
								}
							];

							vm.verification_detailsList = [
								{
									label: $translate.instant("modules.verification.cityAndDate"),
									value: vm.verification.city + " " + moment(vm.verification.createdAt).format('YYYY-MM-DD')
								},
								{
									label: $translate.instant("modules.verification.signature"),
									value: $translate.instant("modules.verification.digitallySigned"),
									value2: $translate.instant("modules.verification.reference") + ": " + vm.verification.token
								},
								{
									label: $translate.instant("modules.verification.name"),
									value: vm.verification.verifierName
								},
								{
									label: $translate.instant("modules.verification.email"),
									value: vm.verification.verifierEmail
								},
								{
									label: $translate.instant("modules.verification.phoneNumber"),
									value: vm.verification.verifierPhoneNumber
								}
							];




							if (vm.templateId === 75) { // Hierarchical
								var listener = $scope.$on("hierarchicalLoaded", function(result) {
									$scope.$emit("verifiedContentLoaded", mainStructure);
									listener();
								});
							}
							else {
								$scope.$emit("verifiedContentLoaded", mainStructure);
							}
						});
					});
				})
			}
		});

		function generatePdf($event) {
			var element = $($event.target).closest('.pdfGeneratorElement');
			if (element.length)
				pdfGenerator.fromElement(element, mainStructure.title + " " + moment().format("YYYY-MM-DD")).then(function (document) {
					dataOps.createSubItemRelation(vm.verification, document, 7).then(function (dr) {
						console.log(dr);
					});
				});
			else
				console.log('No element found to generate PDF from.')
		}
	}
})();
