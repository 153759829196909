(function() {
	"use strict";

	// Usage:
	// 
	// Creates:
	// 

	angular
		.module("wf.common")
		.component("wfButton", {
			templateUrl: "scripts/wf/common/wfButton.component.html",
			controller: wfButtonController,
			controllerAs: "vm",
			bindings: {
				icon: "<",
				caption: "<",
				captionTranslate: "<",
				dropdownActions: "<",
				clickAction: "&",
				buttonClass: "@"
			},
		});

	wfButtonController.$inject = [ "$translate" ];
	function wfButtonController($translate) {
		var vm = this;

		

		_.assign(vm, {
			
		})

		////////////////

		vm.$onInit = function() {
			if (vm.buttonClass) {
				if (vm.buttonClass.indexOf("btn ") === -1)
					vm.actualButtonClass = "btn btn-primary " + vm.buttonClass;
				else
					vm.actualButtonClass = vm.buttonClass;
			}
			else
				vm.actualButtonClass = "btn btn-primary";

			if (vm.dropdownActions && vm.dropdownActions.length)
				vm.useDropdown = true;
			else
				vm.useButton = true;

			if (vm.captionTranslate) {
				vm.caption = $translate.instant(vm.captionTranslate);
			}
			// if (typeof vm.clickAction === "function") {
			// 	vm.clickAction();
			// 	// // vm.onButtonClick();
			// }
		};
		vm.$onChanges = function(changesObj) { };
		vm.$onDestroy = function() { };
	}
})();