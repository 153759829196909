import {
  ADD_GRAPH_ITEM,
  ADD_GRAPH_ITEMS,
  REMOVE_GRAPH_ITEM,
  REMOVE_GRAPH_ITEMS,
  RESET_GRAPH_STATE,
} from './'

export const addItem = (item) => {
  return {
    type: ADD_GRAPH_ITEM,
    item,
  }
}

export const addItems = (items) => {
  return {
    type: ADD_GRAPH_ITEMS,
    items,
  }
}

export const removeItem = (id) => {
  return {
    type: REMOVE_GRAPH_ITEM,
    id,
  }
}

export const removeItems = (ids) => {
  return {
    type: REMOVE_GRAPH_ITEMS,
    ids,
  }
}

export const resetState = () => {
  return {
    type: RESET_GRAPH_STATE,
  }
}
