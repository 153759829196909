// @flow
import * as React from 'react'
import Avatar from './Avatar'

type Props = {
  creator: {
    imageUrl: string,
    name: string,
  },
  avatarStyle: { [string]: any },
  avatarSize: number,
  nameStyle: { [string]: any },
}

class CreatorHeader extends React.PureComponent<Props> {

  static defaultProps = {
    avatarStyle: {},
    avatarSize: 12,
    nameStyle: {},
  }

  render() {
    const { creator: { imageUrl, name }, avatarStyle, avatarSize, nameStyle } = this.props
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          url={imageUrl}
          size={avatarSize}
          style={{
            marginRight: 6,
            ...avatarStyle,
          }}
        />
        <span style={{ paddingTop: 2, ...nameStyle }}>
          { name }
        </span>
      </div>
    )
  }
}

export default CreatorHeader
