import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import MaxWidthGrid from '@worldfavor/components/MaxWidthGrid'
import Paper from '@material-ui/core/Paper'
import Colors from '@worldfavor/constants/colors'
import { loadItem, loadSubItemsPage } from '../actions/dataThunk'
import Loading from './Loading'
import Table from '@worldfavor/components/Table'
import TableHeaderCell from '@worldfavor/components/Table/TableHeaderCell'
import InfluenceTableRowCell from '../components/Influence/InfluenceTableRowCell'
import { deleteItem } from '@worldfavor/portal/actions/dataThunk'
import { useDialogState } from '@worldfavor/hooks'
import { StaticIds } from '@worldfavor/constants'
import withPage from '../hoc/withPage'
import StyledPageHeader from '@worldfavor/components/Header/StyledPageHeader'
import Dialog from '@worldfavor/components/Dialog'
import { resetState as resetPaginationState } from '@worldfavor/portal/actions/paginationActions'
import { exchangePath, influencePath } from '@worldfavor/constants/paths'
import useDataFetcher from '@worldfavor/hooks/dataFetcher'
import { apiCallError, beginDataApiCall, dataApiCallSuccess } from '../actions/apiCallStatusActions'

const WrappedTable = withPage(({ items, totalElements, ...other }) => (
  items ? <Table data={items} count={totalElements} {...other} /> : null
))

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  tableHeaders: {
    fontSize: theme.typography.fontSizes.default,
    color: Colors.defaultColor,
  },
  row: {
    cursor: 'pointer',
  },
  preTableInfo: {
    paddingTop: 15,
    paddingBottom: 15,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  resultCount: {
    fontWeight: theme.typography.fontWeights.medium,
    fontSize: theme.typography.fontSizes.medium,
  },
}))

const rowKeyExtractor = ({ node }) => `row-${node.wfid}`
const rowCellKeyExtractor = ({ row, columnIndex }) => `rowCell-${row.node.wfid}-${columnIndex}`

const ThirdPartyRequestsScene = (props) => {
  const { match, history } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const { networkId } = match.params
  const [saving, setSaving] = useState(false)
  const [pageHeader, setPageHeader] = useState({})
  const dataLoading = useSelector(state => state.apiCallsInProgress > 0)
  const [shouldReloadData, setShouldReloadData] = useState(false)
  const [influenceToDelete, setInfluenceToDelete] = useState(null)
  const [targetOrganizationName, setTargetOrganizationName] = useState()
  const [reporterOrganizationName, setReporterOrganizationName] = useState()

  const [reportingOnBehalfDialogOpen, openReportingOnBehalfDialog, closeReportingOnBehalfDialog] = useDialogState(false)

  const dialogActions = [
    {
      label: <FormattedMessage id={'general.close'} />,
      onClick: _closeReportingOnBehalfDialog,
      variant: 'text',
    },
    {
      label: <FormattedMessage id={'supplyChain.thirdPartyReporting.removePermissionsButtonText'} />,
      onClick: onRemovePrivilege,
      variant: 'text',
      saving,
    },
  ]

  useEffect(() => {

    async function fetchUsers() {
      const [type, id] = StaticIds.Colleagues.split('-')
      try {
        await dispatch(loadItem(id, type))
      } catch (e) {
        // TODO handle error
      }
    }

    async function fetchData() {
      const [type, id] = StaticIds.SentThirdPartyInfluences.split('-')
      let pageHeader
      try {
        pageHeader = await dispatch(loadItem(id, type, { childrenLoadDepth: -1 }))
      } catch (e) {
        // TODO handle error
      } finally {
        setPageHeader(pageHeader)
      }
    }

    fetchData()
    fetchUsers()
  }, [])

  const fetchData = (source, pageNumber, pageSize) => dispatch(
    loadSubItemsPage(
      StaticIds.SentThirdPartyInfluences, pageNumber, pageSize, StaticIds.SentThirdPartyInfluences,
      source, { getterConditions: { networkId } },
    ),
  )

  const {
    resultCount,
    pageNumber,
    pageSize,
    pageId,
    requestId,
    setPageNumber,
    setPageSize,
  } = useDataFetcher(fetchData, [shouldReloadData], {
    onBeforeSend: () => {
      setShouldReloadData(false)
      //TO BE FIXED
      // dispatch(resetPaginationState())
    },
  })

  function openDeleteInfluenceDialog(influence) {
    openReportingOnBehalfDialog()
    setInfluenceToDelete(influence)
  }

  function _closeReportingOnBehalfDialog() {
    closeReportingOnBehalfDialog()
    setInfluenceToDelete(null)
  }

  function getReporterOrganizationName() {
    if (reporterOrganizationName) {
      return reporterOrganizationName
    }
    return <FormattedMessage id={'supplyChain.thirdPartyReporting.reporter'} />
  }

  function getTargetOrganizationName() {
    if (targetOrganizationName) {
      return targetOrganizationName
    }
    return <FormattedMessage id={'supplyChain.thirdPartyReporting.reporter'} />
  }

  function onRemovePrivilege() {
    if (influenceToDelete) {
      setSaving(true)
      removeReportingPrivilege(influenceToDelete)
    }
    setSaving(false)
    _closeReportingOnBehalfDialog()
  }

  async function removeReportingPrivilege(item) {
    try {
      dispatch(beginDataApiCall)
      await dispatch(deleteItem(item))
      await dispatch(resetPaginationState())
      setShouldReloadData(true)
    } catch (e) {
      dispatch(apiCallError)
      console.log('There was an error removing reporting privilege: ' + e)
    } finally {
      dispatch(dataApiCallSuccess)
    }
  }

  function renderColumnCell({ column }) {
    const columns = {
      title: <TableHeaderCell label={<FormattedMessage id="general.request" values={{ count: 1 }} />} />,
      organization: <TableHeaderCell label={<FormattedMessage id="supplyChain.thirdPartyReporting.reporter" />} />,
      onBehalfOf: <TableHeaderCell label={<FormattedMessage id="supplyChain.thirdPartyReporting.reportingFor" />} />,
      activatedAt: <TableHeaderCell label={<FormattedMessage id="supplyChain.influences.activatedAt" />} />,
      deadline: <TableHeaderCell label={<FormattedMessage id="general.deadline" />} />,
      fulfillmentStatus: <TableHeaderCell label={<FormattedMessage id="general.fulfillmentStatus" />} />,
      actions: null,
    }

    return typeof columns[column] === undefined ? <TableHeaderCell label={<FormattedMessage id={`general.${column}`} />} /> : columns[column]
  }

  function renderRowCell(attributes) {
    return (
      <InfluenceTableRowCell
        onRemovePermissionDialog={openDeleteInfluenceDialog}
        setReporterOrganizationName={setReporterOrganizationName}
        setTargetOrganizationName={setTargetOrganizationName}
        {...attributes}
      />
    )
  }

  function onRowClick(event, row) {
    history.push(row.node.useNewRoute ? exchangePath(row.node.wfid) : influencePath(row.node.id))
  }

  function onPageChange(event, pageNumber) {
    setPageNumber(pageNumber)
  }

  function onChangeRowsPerPage(event) {
    setPageSize(event.target.value)
  }

  return (
    <Grid container justify={'center'}>
      <MaxWidthGrid item xs={12} sm={12}>
        {
          pageHeader && (
            <div data-cy="third-party-requests-title">
              <StyledPageHeader title={pageHeader.title} description={pageHeader.description} />
            </div>
          )}
        {resultCount ?
          (
            <div className={classes.preTableInfo}>
              <span className={classes.resultCount}>
                <FormattedMessage id="search.resultsCount" values={{ count: `${resultCount}` }} />
              </span>
            </div>
          ) : null
        }
        <Paper className={classes.root}>
          {(dataLoading || !requestId || !pageId) && <Loading />}
          {
            (!dataLoading && requestId && pageId) && (
              <WrappedTable
                requestId={requestId}
                pageId={pageId}

                classes={{ row: classes.row }}
                className={classes.table}
                columns={['title', 'organization', 'onBehalfOf', 'activatedAt', 'deadline', 'fulfillmentStatus', 'actions']}
                columnWidths={['20%', '15%', '15%', '15%', '10%', '20%', '5%']} // Part of columns prop
                renderRowCell={renderRowCell}
                renderColumnCell={renderColumnCell}
                rowKeyExtractor={rowKeyExtractor}
                rowCellKeyExtractor={rowCellKeyExtractor}
                rowsPerPage={pageSize}
                page={pageNumber}
                onPageChange={onPageChange}
                onChangeRowsPerPage={onChangeRowsPerPage}
                controlledPagination
                enableSparsePage
                enablePagination
                rowHover
                rowsPerPageOptions={[5, 10, 20]}
                onRowClick={onRowClick}
              />
            )
          }
        </Paper>
      </MaxWidthGrid>

      <Dialog
        open={reportingOnBehalfDialogOpen}
        title={<FormattedMessage id={'supplyChain.thirdPartyReporting.removePrivilegesDialog.title'} values={{ organizationName: getReporterOrganizationName() }} />}
        description={(
          <FormattedMessage
            id={'supplyChain.thirdPartyReporting.removePrivilegesDialog.thirdPartyDescription'}
            values={{ reporterOrganizationName: getReporterOrganizationName(), targetOrganizationName: getTargetOrganizationName() }}
          />
        )}
        actions={dialogActions}
        info
      />
    </Grid>
  )
}

export default withRouter(ThirdPartyRequestsScene)
