(function () {
	'use strict';

	angular
		.module('wf.data')
		.service('communicationHub', communicationHub);

	communicationHub.$inject = ['$rootScope'];

	function communicationHub($rootScope) {
		var
			valuesByIdentifier = {},
			subscribersByIdentifier = {}
		;

		var service = {
			setValue: setValue,
			getValue: getValue,
			onValueChange: onValueChange
		};

		activate();

		return service;

		function activate() {

		}

		function setValue(key, value) {
			valuesByIdentifier[key] = value;
			//console.log(valuesByIdentifier);
			broadcastChanges(key);
		}

		function getValue(key, property) {
			var x = valuesByIdentifier[key];
			if (x && property)
				return x[property];
			else
				return x;
		}

		function onValueChange(scope, key, property, callback) {
			//scope.$on(key + '|' + property, callback);
			//console.log(key + '|' + property, scope.$id);
			subscribersByIdentifier[key] = subscribersByIdentifier[key] || {};

			subscribersByIdentifier[key][property] = subscribersByIdentifier[key][property] || []

			subscribersByIdentifier[key][property].push(callback)

			//console.log(subscribersByIdentifier);
		}

		function broadcastChanges(key, value) {
			var i;
			//console.warn(key);
			if (subscribersByIdentifier[key])
			{
				for (var property in subscribersByIdentifier[key]) {
					i = subscribersByIdentifier[key][property].length
					while (i--) {
						//console.log(key, property, valuesByIdentifier[key][property]);
						subscribersByIdentifier[key][property][i](valuesByIdentifier[key][property]);
					}

				}
				//while (i--) {
				//	console.log(key + '|' + subscribersByIdentifier[key][i]);
				//}
			}

			//$rootScope.$apply();
		}
	}
})();