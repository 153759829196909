import React from 'react'
import { react2angular } from 'react2angular'
import ItemSummaryList from '@worldfavor/components/Items/ItemSummaryList'
import { WrapProvider } from './providers'

const props = [
  'subItems',
  'relationProp',
  'style',
]

class ItemSummaryListWrapper extends React.Component {

  prepareProps = (props) => {
    const { subItems = [], relationProp, ...rest } = props
    return {
      ...rest,
      subItems: subItems.map(subItem => subItem.childContent),
      dataRelation: relationProp,
      style: {
        ...props.style,
        maxHeight: 'none',
      },
    }
  }

  render() {
    const { wfAuth, ...rest } = this.props
    return (
      <WrapProvider wfAuth={wfAuth}>
        <ItemSummaryList {...this.prepareProps(rest)} />
      </WrapProvider>
    )
  }
}

export default react2angular(ItemSummaryListWrapper, props, ['wfAuth'])
