(function() {
	'use strict';
	angular
		.module('wf.common')
		.component('wfCreatorTimeBubble', {
			templateUrl: 'scripts/wf/creatorTime/wfCreatorTimeBubble.component.html',
			controller: wfCreatorTimeBubbleController,
			controllerAs: 'vm',
			require: {
				wfItemUse: '?^wfItemUse'
			},
			bindings: {
				item: "<",
			},
		});

	wfCreatorTimeBubbleController.$inject = [ '$translate', '$sanitize', '$sce' ];
	function wfCreatorTimeBubbleController($translate, $sanitize, $sce) {
		var vm = this;

		_.assign(vm, {
			creator: undefined,
			createdAt: undefined,
			tooltipText: "",
			config: {
				formatDate: 'ddd, D MMM YYYY',
				formatTime: 'HH:mm',
				display: {
					creator: true,
					creatorImage: true,
					creatorName: true,
					dateAndTime: true,
					date: true,
					time: true
				}
			}
		});

		vm.$onInit = function () {
			if (!vm.item && vm.wfItemUse)
				vm.item = vm.wfItemUse.item;

			if (!vm.item) {
				console.warn("vm.item is undefined in wfCreatorTimeBubble");
				return
			}
			
			assignAttributesToVm();
			initializeCreator();
			initializeTime();

			if (vm.creator) {
				vm.tooltipText = $sanitize($translate.instant("ReportedBy") + " " + vm.creator.name);
			}

			if (vm.createdAt) {
				if (vm.tooltipText)
					vm.tooltipText += " ";

				vm.tooltipText += $sanitize("@ " + vm.createdAt.date + " " + vm.createdAt.time);
			}

			vm.tooltipText = vm.tooltipText;
		};
		
		function initializeCreator() {
			if (vm.item.creatorUser) {
				vm.creator = {
					imageUrl: vm.item.creatorUser.imageUrl,
					name: vm.item.creatorUser.name
				};
			}
			else if (vm.item.content.isUserDataType() && vm.item.content.creatorUser) {
				vm.creator = {
					imageUrl: vm.item.content.creatorUser.imageUrl,
					name: vm.item.content.creatorUser.name
				};
			}
		}

		function initializeTime() {
			if (vm.item.createdAt) {
				let momentInstance = moment(vm.item.createdAt);

				vm.createdAt = {
					date: momentInstance.format(vm.config.formatDate),
					time: momentInstance.format(vm.config.formatTime)
				}
			}
		}

		function assignAttributesToVm() {
			if (!_.isEmpty(vm.configFromAttribute))
				vm.config = _.defaultsDeep(vm.configFromAttribute, vm.config);

			// _.each(vm, function (value, key) {
			// 	if (key in $attrs) {
			// 		if ($attrs[key] && $attrs[key].length)
			// 			vm[key] = $parse($attrs[key])($scope);
			// 		else if (typeof value === "boolean")
			// 			vm[key] = true;
			// 	}
			// });
		}
	}
})();