// @flow
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'

import classNames from 'classnames'

import { useDialogState } from '@worldfavor/hooks'
import Colors from '@worldfavor/constants/colors'
import { saveMappingTransparencyLevel } from '../../actions/productThunk'
import { capitalize } from '@worldfavor/utils/helpers'
import ErrorDialog from '@worldfavor/components/Dialog/ErrorDialog'
import TransparencyLevelDialog from './TransparencyLevelDialog'

type Props = {
  level: number,
  mappingEntity: Object,
  enableEditing?: boolean,
  showLabel?: boolean,
  compact?: boolean,
  compactButton?: boolean,
  onEdited?: Function,
}

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonWrapper: {
    marginLeft: 24,
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
  },
  editIcon: {
    color: theme.palette.primary.main,
  },
  flagIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    marginRight: 16,
  },
  flag: {
    fontSize: 18,
  },
  flagNotMapped: {
    color: '#A8A8A8',
  },
  flagPartiallyMapped: {
    color: Colors.orange,
  },
  flagFullyMapped: {
    color: Colors.green,
  },
  textWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  labelText: {
    color: theme.palette.text.secondary,
    fontSize: 12,
  },
  statusText: {
    color: theme.palette.common.black,
    fontWeight: 500,
  },
  tooltip: {
    margin: 0,
    fontSize: 12,
    backgroundColor: theme.palette.primary.dark,
    '& .tooltip-arrow:before': {
      borderColor: theme.palette.primary.dark + ' transparent transparent transparent !important',
    },
  },
  compact: {
    '& $flagIconContainer': {
      width: 25,
      height: 25,
      marginRight: 10,
    },
    '& $flag': {
      fontSize: 13,
    },
    '& $textWrapper': {
      flexDirection: 'row',
      alignItems: 'center',
    },
    '& $labelText': {
      marginRight: 6,
    },
    '& $statusText': {
      fontSize: 12,
    },
    '& $buttonWrapper': {
      marginLeft: 10,
    },
    '& $editIcon': {
      width: 20,
      height: 20,
    },
  },
  clickable: {
    cursor: 'pointer',
  },
}))

const getMappingStatus = (level) => {
  if (!level || level < 50) {
    return 'notMapped'
  }
  if (level < 100) {
    return 'partiallyMapped'
  }
  return 'fullyMapped'
}

const MappingStatus = (props: Props) => {
  const {
    level,
    enableEditing,
    showLabel,
    compact,
    compactButton,
    mappingEntity,
    onEdited,
  } = props
  const status = getMappingStatus(level)
  const classes = useStyles(props)
  const [isTransparencyDialogOpen, openTransparencyDialog, closeTransparencyDialog] = useDialogState(false)
  const [isErrorDialogOpen, openErrorDialog, closeErrorDialog] = useDialogState(false)
  const dispatch = useDispatch()

  async function onTransparencyChange(transparencyLevel) {
    try {
      if (typeof transparencyLevel === 'number') {
        await dispatch(saveMappingTransparencyLevel(mappingEntity, transparencyLevel))
        closeTransparencyDialog()
        onEdited && onEdited(transparencyLevel)
      }
    } catch (e) {
      openErrorDialog()
      console.error(e)
    }
  }

  function renderEditButton() {
    if (compactButton) {
      return (
        <div className={classes.buttonWrapper}>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={<FormattedMessage id={'supplyChain.mappingStatus.editTooltipText'} />}
            placement="top"
          >
            <IconButton
              className={classes.iconButton}
              size={compactButton ? 'small' : 'medium'}
            >
              <EditIcon classes={{ root: classes.editIcon }} />
            </IconButton>
          </Tooltip>
        </div>
      )
    }
    
    return (
      <div className={classes.buttonWrapper}>
        <Button variant="outlined">
          <FormattedMessage id={'supplyChain.mappingStatus.editTooltipText'} />
        </Button>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div
        onClick={enableEditing ? openTransparencyDialog : undefined}
        className={
          classNames(classes.root, {
            [classes.compact]: compact,
            [classes.clickable]: enableEditing,
          })
        }
      >
        <div className={classes.statusWrapper}>
          <div className={classes.flagIconContainer}>
            <i className={classNames('fas fa-flag', classes.flag, classes[`flag${capitalize(status)}`])} />
          </div>
          <div className={classes.textWrapper}>
            {
              showLabel && (
                <span className={classes.labelText}><FormattedMessage id={'general.mappingStatus'} />{':'}</span>
              )
            }
            <span className={classes.statusText}>
              <FormattedMessage id={`supplyChain.mappingState.${status}`} />
            </span>
          </div>
        </div>
        { enableEditing && renderEditButton() }
      </div>

      <TransparencyLevelDialog
        open={isTransparencyDialogOpen}
        onCancel={closeTransparencyDialog}
        onSave={onTransparencyChange}
        level={level}
      />

      <ErrorDialog
        open={isErrorDialogOpen}
        closeDialog={closeErrorDialog}
        showReloadButton
        showContactSupport
      />
    </React.Fragment>
  )
}

export default MappingStatus
