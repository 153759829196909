import * as enums from '@worldfavor/constants/enums'

(function () {
	'use strict';

	angular
		.module('wf.data')
		.factory('TheNewLoaderItemFactory', TheNewLoaderItemFactory);

		TheNewLoaderItemFactory.$inject = [ '$q', '$timeout', 'apiProxy', '$rootScope', '$ngBootbox', '$translate', 'wfAuth', 'dataOperationsCache', "DataNegotiator", "$uibModal", "dataOperationsService", "dataQuery", "modalService", "dropdownUtility", "wfPropertyExtractor", "wfTranslate", "requirements" ];

	function TheNewLoaderItemFactory($q, $timeout, apiProxy, $rootScope, $ngBootbox, $translate, wfAuth, dataOpsCache, DataNegotiator, $uibModal, dataOps, dataQuery, modal, dropdownUtility, wfPropertyExtractor, wfTranslate, requirementService) {
		_.assign(TheNewLoaderItemFactory.prototype, {
		});

		TheNewLoaderItemFactory.instantiate = instantiate

		return TheNewLoaderItemFactory;

		function instantiate(instanceOrOptions) {
			if (instanceOrOptions instanceof TheNewLoaderItemFactory)
				return instanceOrOptions
			else
				return new TheNewLoaderItemFactory(instanceOrOptions);
		}


		function TheNewLoaderItemFactory(options) {
			var
				loader = options.loader,
				itemCompositePrototype
			;

			itemCompositePrototype = this.itemCompositePrototype = {
				loader: loader,
				lockedUiMode: false,
				uiMode: loader.uiMode,

				isViewMode: loader.isViewMode,
				isWorkMode: loader.isWorkMode,
				isAdminMode: loader.isAdminMode,
				answerModules: [],
				secondaryAnswerModules: [],
				onFulfillmentSyncedListenerFunctions: [],

				// Functions
				setToViewMode:          itemPrototype_setToViewMode,
				setToWorkMode:          itemPrototype_setToWorkMode,
				setUiMode:              itemPrototype_setUiMode,
				getSubItems:            itemPrototype_getSubItems,
				populateSubItems:       itemPrototype_populateSubItems,
				syncFulfillment:        itemPrototype_syncFulfillment,
				addSubItem:             itemPrototype_addSubItem,
				removeSubItem:          itemPrototype_removeSubItem,
				sortSubItems:           itemPrototype_sortSubItems,
				onFulfillmentSynced:    itemPrototype_onFulfillmentSynced,
				categorizeToUser:       itemPrototype_categorizeToUser,
				reloadAnyDependents:    itemPrototype_reloadAnyDependents,
				getParentItemComposite: itemPrototype_getParentItemComposite,
				onDataReported:         itemPrototype_onDataReported,
				invokeAnalyze:          itemPrototype_invokeAnalyze,
			}

			_.assign(this, {
				openItemCompositeOverlay: openItemCompositeOverlay,
				createVirtualDataRelation: createVirtualDataRelation,
				createContainer_attachedInformation: createContainer_attachedInformation,
				createContainer_questions: createContainer_questions,
				createContainer_question: createContainer_question
			})

			function itemPrototype_syncFulfillment() {
				const itemComposite = this;
				const requirement = itemComposite.requirement;
				var jqDf = $.Deferred();

				if (!requirement) {
					jqDf.resolve();
				}
				else {
					itemComposite.fulfillmentResultUpdated = false;
					$timeout();

					requirementService.maybeSetAssessmentNeedOnItem(itemComposite, {
						influence: loader.influence,
						forceFulfillmentState: null
					}).then(function () {
						const fulfillmentResult = requirementService.checkLocalFulfillment(itemComposite.content, itemComposite.dataRelation, itemComposite.requirement, itemComposite.ticket, { useDetailedResult: true });

						itemComposite.fulfillmentResult = fulfillmentResult;

						if (typeof itemComposite.syncFulfillmentResultComponent === "function")
							itemComposite.syncFulfillmentResultComponent();

						loader.syncFulfillmentStatistics();
						jqDf.resolve(fulfillmentResult);
						itemComposite.onFulfillmentSyncedListenerFunctions.forEach(x => x());
						$timeout(() => itemComposite.fulfillmentResultUpdated = true, 200);
					});
				}

				return jqDf.promise();
			}

			function itemPrototype_addSubItem(relation, targetKind) {
				const itemComposite = this;
				const newItemComposite = dataQuery.makeItemComposites([ relation ], {
					targetKind: targetKind,
					itemPrototype:  _.defaults({
						compiler: {},
						subItemsLoaded: loader.allContentPreloaded,
						subItemsKind: enums.subItemsKind.relatedContentByUser
					}, itemCompositePrototype)
				})[0];

				itemComposite.subItems.unshift(newItemComposite);

				if (itemComposite.container && itemComposite.container.items) {
					itemComposite.container.items.unshift(newItemComposite);
				}

				itemComposite.sortSubItems();
				itemComposite.onDataReported();
			}

			function itemPrototype_removeSubItem(item) {
				var itemComposite = this;
				if (item.type === enums.objectType.dataRelation) {
					_.remove(itemComposite.subItems, { relationWfid: item.wfid })
				}
				else if (item.isComposite) {
					_.remove(itemComposite.subItems, item)
				}

				itemComposite.onDataReported();
			}

			function itemPrototype_getSubItems() {
				const itemComposite = this;
				const deferred = itemComposite.subItemsLoaderDeferred || (itemComposite.subItemsLoaderDeferred = $q.defer());
				var pickerSettingsSourceItemPromise

				if (!itemComposite.subItemsParentItem) {
					if (_.get(itemComposite.getParentItemComposite(), "content.conditions.subItemsSettings.pickerSettings.relationTarget.useRelationItem")) {
						itemComposite.subItemsParentItem = itemComposite.dataRelation;
					}
					else if (_.get(itemComposite.content, "conditions.pickerSettings.relationTarget.item") === "@currentContextParent" && loader.ticket.contextParentWfid) {
						itemComposite.subItemsParentItem = wfObject.get(loader.ticket.contextParentWfid);
						itemComposite.subItemsLoaded = false;
						itemComposite.relationTargetIsCurrentContextParent = true;
						itemComposite.subItemsKind = _.get(itemComposite.content, "conditions.pickerSettings.relationTarget.kind") || itemComposite.subItemsKind;

						let pickerSettingsSourceItem = _.get(itemComposite.content, "conditions.pickerSettings.sourceItem");
						pickerSettingsSourceItemPromise = dataOps.getSubItems(pickerSettingsSourceItem, enums.subItemsKind.children);
					}
					else {
						itemComposite.subItemsParentItem = itemComposite.content;
					}
				}

				if (itemComposite.subItemsLoaded) {

					if (!itemComposite.subItemsPopulated) {
						itemComposite.populateSubItems()
						deferred.resolve(itemComposite.subItems)
					}
					else
						deferred.resolve(itemComposite.subItems)
				}
				else if (itemComposite.subItemsLoading) {
					// Keep calm and wait for the promise
				}
				else {
					itemComposite.subItemsLoading = true;

					dataOps.getSubItems(itemComposite.subItemsParentItem, itemComposite.subItemsKind, { ticket: loader.ticket, skipExtras: true, loadCreators: true }).then(function (res) {
						if (pickerSettingsSourceItemPromise) {
							pickerSettingsSourceItemPromise.then(x => {
								itemComposite.subItemsLoading = false;
								itemComposite.subItemsLoaded = true;
								itemComposite.populateSubItems()
								deferred.resolve(itemComposite.subItems); // questionAnswers, measureAnswers or attached information
							});
						}
						else {
							itemComposite.subItemsLoading = false;
							itemComposite.subItemsLoaded = true;
							itemComposite.populateSubItems()
							deferred.resolve(itemComposite.subItems); // questionAnswers, measureAnswers or attached information
						}

						itemComposite.syncFulfillment();
					});
				}

				return deferred.promise;
			}

			function itemPrototype_populateSubItems() {
				var itemComposite = this, subItemRelations;

				if (_.get(itemComposite, "content.conditions.pickerSettings.relationTarget.item") === "@currentContextParent" && loader.ticket.contextParentWfid) {
					let
						contextParent = wfObject.get(loader.ticket.contextParentWfid)
					;

					itemComposite.subItemsKind = _.get(itemComposite, "content.conditions.pickerSettings.relationTarget.kind") || itemComposite.subItemsKind

					subItemRelations = contextParent.getSubListOfKind(itemComposite.subItemsKind, { organizationId: loader.ticket.organizationId });

					let pickerSettingsSourceItem = dataQuery.getRelations({
						kind: enums.subItemsKind.children,
						organizationIds: [ null, loader.ticket.organizationId ],
						parentWfid: _.get(itemComposite, "content.conditions.pickerSettings.sourceItem"),
						includeVirtualRelations: true
					});

					subItemRelations = _.intersectionWith(subItemRelations, pickerSettingsSourceItem, (x, y) => x.wffid === y.wfcid);
				}
				else {
					itemComposite.subItemsKind = _.get(itemComposite, "content.conditions.pickerSettings.relationTarget.kind")
						|| (itemComposite.subItemsParentItem.type === enums.objectType.question || itemComposite.subItemsParentItem.type === enums.objectType.measure ? enums.subItemsKind.childrenByUser : enums.subItemsKind.relatedContentByUser);

					subItemRelations = dataQuery.getIntersectedSubItems(itemComposite.subItemsParentItem, _.assign({
						kind: itemComposite.subItemsKind,
					}, loader.ticket));
				}

				itemComposite.subItems = dataQuery.makeItemComposites(subItemRelations, {
					targetKind: itemComposite.subItemsKind,
					itemPrototype:  _.defaults({
						compiler: {},
						subItemsLoaded: loader.allContentPreloaded,
						subItemsKind: itemComposite.subItemsKind
					}, itemCompositePrototype)
				});

				itemComposite.subItemsPopulated = true;

				itemComposite.sortSubItems();
			}

			function itemPrototype_sortSubItems() {
				var itemComposite = this, sortedArray;

				if (!itemComposite.subItems || !itemComposite.subItems.length || itemComposite.subItems.length === 1) {
					return;
				}

				if (itemComposite.type === enums.objectType.measure) {
					sortedArray = _.orderBy(itemComposite.subItems, [ "content.period_epoch", "content.createdAt" ], [ "desc", "desc" ]);
				}
				else {
					sortedArray = _.orderBy(itemComposite.subItems, [ "dataRelation.createdAt" ], [ "desc" ]);
				}

				itemComposite.subItems.length = 0
				Array.prototype.push.apply(itemComposite.subItems, sortedArray);
			}

			function itemPrototype_onDataReported() {
				var itemComposite = this;

				itemComposite.reloadAnyDependents();
				itemComposite.invokeAnalyze();
			}

			function itemPrototype_invokeAnalyze() {
				var itemComposite = this;

				if (!_.get(itemComposite.dataRelation, "settings.analyzeInvocationSettings")) {
					return;
				}


				if (itemComposite.analyzeXhrRequest) {
					itemComposite.analyzeXhrRequest.abort();
					itemComposite.analyzeXhrRequest = undefined;
					loader.analyzingItemsCount--
				}

				loader.analyzingItemsCount++;
				loader.negotiator.abortOngoingXhrRequests();
				loader.syncingItems = false;
				itemComposite.analyzeRunning = true;
				itemComposite.analyzeXhrRequest = apiProxy.raw("analyze.calculateFromLinkedItem", {
					itemWfid: itemComposite.dataRelation.wfid,
					influenceId: loader.influence.id,
					networkId: loader.influence.channelId,
				});

				itemComposite.analyzeXhrRequest.then(function (res) {
					itemComposite.analyzeXhrRequest = undefined;
					itemComposite.analyzeRunning = false;
					loader.analyzingItemsCount--;

					if (!loader.analyzingItemsCount) {
						loader.syncItems();
					}
				});
				$timeout();
			}

			function itemPrototype_reloadAnyDependents() {
				var itemComposite = this;

				if (itemComposite.wfid in loader.itemDependenciesByWfid) {
					loader.itemDependenciesByWfid[itemComposite.wfid].forEach(depItemComposite => {
						if (depItemComposite.type === enums.objectType.relativeMeasure) {
							if (depItemComposite.chartVm)
								depItemComposite.chartVm.loadDataAndInitialize({ forceNewNegotiator: true });
						}
					})
				}
			}

			function itemPrototype_onFulfillmentSynced(func) {
				var itemComposite = this;

				itemComposite.onFulfillmentSyncedListenerFunctions.push(func);

				return function () {
					_.remove(itemComposite.onFulfillmentSyncedListenerFunctions, func);
				};
			}

			function itemPrototype_categorizeToUser(influence) {
				var itemComposite = this;

				modal.openCreatorAndPicker({
					showTopItemAboveTitle: true,
					hideListHeader: false,
					hideFilters: false,
					translations: {
						addWord: $translate.instant('Assign'),
						toWord: $translate.instant('To'),
						filterButton_all: $translate.instant('AllUsers'),
						filterButton_selected: $translate.instant('AssignedTo')
					},
					compilerControl: null, //vm.context.itemCompilers[item.wfid],
					title: $translate.instant('Assign'),
					create: false,
					objectTypes: [ enums.objectType.individual ],
					relationTarget: { item: itemComposite.content, kind: enums.subItemsKind.parentsByUser }
				}).closed(function () {
					// Checking if any tagged user does not have org access anymore and deletes those tags (relations)
					const userIdsWithOrgAccess = dataQuery
						.getRelations({
							parentWfid: '71-10051',
							kind: enums.subItemsKind.childrenByUser,
							useVirtual: true,
							organizationId: influence.organizationId,
						})
						.map(x => x.childId)
					const userCategorizationRelations = dataQuery
						.getRelations({
							child: itemComposite.content,
							kind: enums.subItemsKind.parentsByUser,
							parentType: enums.objectType.individual,
							organizationId: influence.organizationId,
						})
					const userIdsCategorizedOnItem = userCategorizationRelations.map(x => x.parentId)
					const userIdsMissingOrgAccess = _.difference(userIdsCategorizedOnItem, userIdsWithOrgAccess);

					if (userIdsMissingOrgAccess.length) {
						const promises = []
						const userCategorizationRelationsWithMissingOrgAccess = userCategorizationRelations.filter(x => userIdsMissingOrgAccess.includes(x.parentId))
						userCategorizationRelationsWithMissingOrgAccess.forEach(relationToDestroy => {
							promises.push(dataOps.destroy(relationToDestroy));
						})

						$q.all(promises).then(() => syncUserCategorizationsAndUpdateInfluence())
					}
					else {
						syncUserCategorizationsAndUpdateInfluence()
					}

					function syncUserCategorizationsAndUpdateInfluence() {
						loader.syncUserCategorizations().then(() => {
							// Checking if the aggregated list of tagged users in the influence have changed, and if so, updates the influence
							const updatedTaggedUserIds = Object.values(loader.userCategorizationsStatistics).map(x => x.userId)
							if ((influence.taggedUserIds && influence.taggedUserIds.join(',') || '') !== updatedTaggedUserIds.join(',')) {
								influence.taggedUserIds = updatedTaggedUserIds
								dataOps.update(influence).then(function (res) {
								});
							}
						});
					}
				});
			}

			function itemPrototype_getParentItemComposite() {
				var itemComposite = this;

				if (!itemComposite.parent && itemComposite.parentWfid) {
					itemComposite.parent = loader.itemsByWfid[itemComposite.parentWfid];
				}

				return itemComposite.parent;
			}


			function createVirtualDataRelation(options) {
				var
					output,
					relationWfid,
					parentWfid,
					parentType, parentId,
					childType, childId,
					childWfid,
					childContent
				;

				if (options.fromDataRelation) {
					parentWfid = options.fromDataRelation.wffid
					parentType = options.fromDataRelation.parentType
					parentId = options.fromDataRelation.parentId
					childWfid = options.fromDataRelation.wfcid
					childType = options.fromDataRelation.childType
					childId = options.fromDataRelation.childId
				}

				if (options.withParent) {
					parentWfid = options.withParent.wfid
					parentType = options.withParent.type
					parentId = options.withParent.id
				}

				if (options.childContentTitle) {
					childContent = {
						type: childType = enums.objectType.structure,
						wfid: childWfid = "71-dashPicker:forType-" + options.forObjectType + "-" + options.childContentTitle,
						id: childId = childWfid,
						title: options.childContentTitle,
						conditions: options.childContentConditions
					}
				}

				relationWfid = "81-dashPicker:" + parentWfid + "/" + childWfid;

				output = {
					type: enums.objectType.virtualDataRelation,
					id: 0,
					wfid: relationWfid,
					wffid: parentWfid,
					wfcid: childWfid,
					parentType: parentType,
					parentId: parentId,
					parentData1: null,
					childType: childType,
					childId: childId,
					depth: options.depth,
					childContent: childContent || options.withChild,
					itemCompositeInstructions: {
						childContent: childContent || options.withChild
					}
				};

				return output;
			}

			function itemPrototype_setToViewMode() {
				this.setUiMode(enums.uiMode.view);
			}

			function itemPrototype_setToWorkMode() {
				this.setUiMode(enums.uiMode.work);
			}

			function itemPrototype_setUiMode(uiMode, onlySetItemValues) {
				var itemComposite = this, shouldRecompile;

				if (!itemComposite.lockedUiMode) {
					shouldRecompile = itemComposite.uiMode !== uiMode;

					itemComposite.uiMode = uiMode;
					itemComposite.isViewMode = uiMode === enums.uiMode.view;
					itemComposite.isWorkMode = uiMode === enums.uiMode.work;
					itemComposite.isAdminMode = uiMode === enums.uiMode.admin;

					// if (shouldRecompile && loader.viewItems.includes(itemComposite) && itemComposite.compiler.compile) {
					// 	itemComposite.compiler.compile();
					// }

					if (!onlySetItemValues) {
						if (uiMode === enums.uiMode.view) {
							if (loader.subLevelIntersectedItems.filter((item) => !item.lockedUiMode).every((item) => item.isViewMode ))
								loader.setUiMode(uiMode, true);
						}
						else if (uiMode === enums.uiMode.work) {
							if (loader.subLevelIntersectedItems.filter((item) => !item.lockedUiMode).every((item) => item.isWorkMode ))
								loader.setUiMode(uiMode, true);
						}
						else if (uiMode === enums.uiMode.admin) {
							if (loader.subLevelIntersectedItems.filter((item) => !item.lockedUiMode).every((item) => item.isAdminMode ))
								loader.setUiMode(uiMode, true);
						}

						if (itemComposite.type === enums.objectType.question) {
							loader.syncItemGrouping(itemComposite.parent)
						}
					}
				}

				if (itemComposite.isContainer && itemComposite.container.isQuestions && uiMode === enums.uiMode.work) {
					itemComposite.container.items.forEach((question, index) => {
						question.setUiMode(enums.uiMode.work, index !== itemComposite.container.items.length - 1);
					});
				}
			}



			function createContainer_attachedInformation(fromItemComposite, options) {
				var
					transformItem = options && options.transformItem,
					container,
					item,
					containerItemObject = {
						isContainer: true,
						content: fromItemComposite.content,
						subItemsKind: enums.subItemsKind.relatedContentByUser,
						container: container = {
							isAttachedInformation: true,
							title: $translate.instant("AttachedInformation"),
							init: init,
							populateContainer: populateContainer,
							canAttach: true,
							dropdownActions: dropdownUtility.buildDropdownActions({
								actions: "attachInformation",
								item: fromItemComposite,
								callback: function () {
									populateContainer();
								}
							})
						},
						useGridStyle: true
					}
				;

				if (loader.ticket.receivingOrganizationsAndLimitedDataAccessFromInfluenceId) {
					containerItemObject.container.canAttach = false;
				}

				if (fromItemComposite.wfid in loader.attachedInformationContainersByWfid)
					containerItemObject.container = container = loader.attachedInformationContainersByWfid[fromItemComposite.wfid];
				else
					loader.attachedInformationContainersByWfid[fromItemComposite.wfid] = containerItemObject.container;

				// If transformItem is true then transform fromItemComposite into a container item
				if (transformItem) {
					container.selfContained = true;
					fromItemComposite.attachedInformationContainer = containerItemObject.container;
					fromItemComposite.open = open;
					item = _.assign(fromItemComposite, containerItemObject, itemCompositePrototype);

					let firstObjectType = _.get(item.content, "conditions.objectTypes[0]")

					if (firstObjectType === enums.objectType.structure || firstObjectType === enums.objectType.country) {
						container.canAttach = false;
					}
				}
				else { // Otherwise make a new container item
					fromItemComposite.attachedInformationContainer = container;
					return item = _.assign({
						uniqueId: fromItemComposite.wfid + "-attachedInformation|" + _.uniqueId(),
						wfid: fromItemComposite.wfid + "-attachedInformation",
						content: {},
						relation: {},
						parentWfid: fromItemComposite.wfid,
						parent: fromItemComposite,
						useGridStyle: true,
						open: open
					}, containerItemObject, itemCompositePrototype);
				}


				function init(_item) {
					// There is a problem with item instances being changed when data is reloaded after adding items to the scope
					// so the item is always passed in to init function instead.
					if (_item && _item.wfid) {
						item = _item
					}
					item.getSubItems().then(function () {
						populateContainer();
					});
				}

				function open() {
					openItemCompositeOverlay.call(fromItemComposite, { mainHierarchyKind: enums.subItemsKind.relatedContentByUser, showVisualization: true, customChartDatasets: container.chartSummaryDatasets, chartConfig: { colors: [ fromItemComposite.color ] } });
				}

				function populateContainer(_item) {
					var datasets = [];

					if (!fromItemComposite.content)
						return;

					container.items = _.clone(item.subItems);
					//if all items are structures or countries
					if (container.items && container.items.length && (_.every(container.items, { 'type': enums.objectType.country }) || _.every(container.items, { 'type': enums.objectType.structure })) ) {

						var clonedItems = _.chain(container.items).map("content").clone().value();
						if (clonedItems && clonedItems.length)
							partitionDatasets(clonedItems.shift(), clonedItems);

						if (datasets && datasets.length) {
							container.chartSummaryDatasets = {
								datasets: [ { id: "period", data: datasets } ]
							};
						}
					}

					if (!transformItem && item.subItems.length === 0) {
						delete fromItemComposite.attachedInformationContainer
						loader.removeItem(item);
					}

					$timeout();

					function partitionDatasets(item, previousItems) {
						var addedItem = undefined;
						var getterProperty = "", getterProperty2 = "";

						if (item.type === enums.objectType.structure) {
							getterProperty = "title"
							getterProperty2 = "description"
						}
						else if (item.type === enums.objectType.country)
							getterProperty = "name"

						if (getterProperty === "") {
							console.error("Getter property not defined")
							return;
						}

						if (datasets && datasets.length) {
							addedItem = _.find(datasets, { 'label': item[getterProperty] }) || (getterProperty2 && _.find(datasets, { 'label': item[getterProperty2] }));
							if (addedItem)
								addedItem.value++;
							else
								datasets.push({ label: item[getterProperty] || (getterProperty2 && item[getterProperty2]), value: 1 });
						}
						else
							datasets.push({ label: item[getterProperty] || (getterProperty2 && item[getterProperty2]), value: 1 });

						if (previousItems.length > 0)
							partitionDatasets(previousItems.shift(), previousItems);
					}
				}
			}

			function createContainer_questions(fromItemComposite, questions) {
				var container, item = {
					uniqueId: fromItemComposite.wfid + "-questions|" + _.uniqueId(),
					wfid: fromItemComposite.wfid + "-questions",
					isContainer: true,
					container: container = {
						items: questions,
						itemContents: questions.map(question => question.content),
						isQuestions: true,
						title: $translate.instant("Questions"),
						init: init,
						alwaysShowPercentages: Boolean(loader.ticket.organizationIds)
					},
					content: {},
					relation: {},
					parentWfid: fromItemComposite.wfid,
					parent: fromItemComposite,
					useGridStyle: true,
					excludeFromMainList: loader.influece ? loader.isWorkMode : false,
					open: function () {
						var preloadedItem;

						preloadedItem = {
							type: enums.objectType.structure,
							id: 0,
							wfid: fromItemComposite.wfid + "|childrenQuestions",
							title: fromItemComposite.content.title
						}
						preloadedItem.childs = _.map(questions, function (itemComposite) {
							var vdr = createVirtualDataRelation({
								withParent: preloadedItem,
								fromDataRelation: itemComposite.dataRelation,
								withChild: itemComposite.content
							})
							vdr.type = 73;
							return vdr;
						});

						wfObject.inject(preloadedItem);
						preloadedItem = wfObject.get(preloadedItem.wfid);

						modal.open({
							template: '<wf-hierarchical use-item-as-is item="structure" consolidate="consolidationSettings" hooks="hierHooks" override="overrideObject" vm-prototype="hierVm" hide-filter-bars></wf-hierarchical>',
							scope: {
								structure: preloadedItem,
								hierVm: {
									mainPanelFullWidth: true,
									showFilterBars: false
								},
								loaded: false,
								consolidationSettings: loader.ticket.receivingOrganizationsAndLimitedDataAccessFromInfluenceId ? {
									receivingOrganizationsAndLimitedDataAccessFromInfluenceId: loader.ticket.receivingOrganizationsAndLimitedDataAccessFromInfluenceId
								} : {
									networkId: loader.ticket.networkId,
									organizationIds: loader.ticket.organizationIds,
									contextParentWfids: loader.ticket.contextParentWfids,
									contextParentType: loader.ticket.contextParentType,
									onlyStatistics: true
								}
								// overrideObject: {
								// 	headerOptions: {
								// 		title: $translate.instant("modules.valueChain.aggregatedView.title"),
								// 		description: structure.title + "\n\n"
								// 			+ $translate.instant("modules.valueChain.organizationCount") + ": " + organizationIds.length
								// 			+ (selectedFilterOptions && selectedFilterOptions.length ? ("\n" + $translate.instant("modules.valueChain.basedOnFiltering") + ":\n• " + selectedFilterOptions.join("\n• ")) : "")
								// 	}
								// },
							},
							windowClass: "modal-width-1000",
							onLoaded: function ($scope, $element) {
								// modalElement = $element;
							}
						});
					}
				};

				fromItemComposite.questions = questions;

				fromItemComposite.questionsContainerItem = _.assign(item, _.assign(item, itemCompositePrototype));// { setToViewMode: setToViewMode, setToWorkMode: setToWorkMode }));
				item.lockedUiMode = true;
				item.uiMode = enums.uiMode.view;
				item.isViewMode = true;
				item.isWorkMode = item.isAdminMode = false;

				if (loader.influence) {
					Object.defineProperty(item, "absoluteOrder", { get: () => _.get(_.first(questions), "absoluteOrder") })
				}

				return item;

				function init() {
					if (item.subItemsLoaded) {
						populateContainer()
					}
					else {
						dataOps.getSubItemsOfAll(questions, enums.subItemsKind.childrenByUser, {
							ticket: loader.ticket,
							skipExtras: true
						}).then(populateContainer);
					}
				}

				function populateContainer() {
					var questions = container.items;
					item.subItemsLoaded = true;
					var answerRelations = dataQuery.getAll.answersOnQuestions(questions, loader.ticket);
					var totalPercentagePerAnswerType = {};
					var percentagePerAnswerTypePerQuestion = _.chain(answerRelations)
						.sortBy("createdAt")
						.groupBy("wffid")
						.mapValues(function (answerRelationsForQuestion) {
							// Get latest answers by grouping by organization, selecting latest answer per org and then ungroup
							var latestAnswersForQuestion = _.chain(answerRelationsForQuestion)
								.groupBy("organizationId")
								.mapValues(function (answerRelationsForQuestionFromOrganization) {
									return _.last(answerRelationsForQuestionFromOrganization).childContent;
								})
								.map()
								.value()
							;

							var percentagePerAnswerTypeForQuestion = _.chain(latestAnswersForQuestion)
								.groupBy("questionAnswerTypeId")
								.mapValues(function (answers, questionAnswerTypeId) {
									var percentage = answers.length / latestAnswersForQuestion.length * 100.0;

									// Prepare totalPercentagePerAnswerType
									if (questionAnswerTypeId in totalPercentagePerAnswerType)
										totalPercentagePerAnswerType[questionAnswerTypeId] += percentage;
									else
										totalPercentagePerAnswerType[questionAnswerTypeId] = percentage;

									return percentage;
								})
								.value()
							;

							return percentagePerAnswerTypeForQuestion;
						})
						.value()
					;

					var totalPercentagesSum = _.chain(totalPercentagePerAnswerType).map().sum().value();
					// Set correct values in totalPercentagePerAnswerType using totalPercentagesSum
					_.each(totalPercentagePerAnswerType, function (value, questionAnswerTypeId) {
						totalPercentagePerAnswerType[questionAnswerTypeId] = value / totalPercentagesSum * 100.0;
					});
					container.totalPercentagesSum = totalPercentagesSum;
					container.percentagePerAnswerTypePerQuestion = percentagePerAnswerTypePerQuestion;
					container.totalPercentagePerAnswerType = totalPercentagePerAnswerType;
					container.preparedTotals = _.chain(container.totalPercentagePerAnswerType)
						.mapValues(function (value, questionAnswerTypeId) {
							value = numeral(value).format("0,0.[0]");
							return {
								subTitle: "",
								title: wfPropertyExtractor.getQuestionAnswerTypeText(questionAnswerTypeId),
								value: value + "%",
								shortValue: value + "%"
							}
						})
						.map()
						.value()
					;

					_.each(questions, function (itemComposite) {
						itemComposite.preparedTotals = percentagePerAnswerTypePerQuestion[itemComposite.wfid]
							&& _.map(percentagePerAnswerTypePerQuestion[itemComposite.wfid], function (value, questionAnswerTypeId) {
								value = numeral(value).format("0,0.[0]");
								return {
									subTitle: "",
									title: wfPropertyExtractor.getQuestionAnswerTypeText(questionAnswerTypeId),
									value: value + "%",
									shortValue: value + "%"
								}
						});
					});

					$timeout();
				}

				// function setToViewMode() {
				// 	item.questions.forEach((itemComposite) => {
				// 		loader.removeItem(itemComposite);
				// 	});

				// 	loader.addItem({ appendToChildrenOf: fromItemComposite, addToView: loader.filteredItems }, item);
				// }

				// function setToWorkMode() {
				// 	loader.removeItem(item);

				// 	item.questions.forEach((itemComposite) => {
				// 		loader.addItem({ appendToChildrenOf: fromItemComposite, addToView: loader.filteredItems }, itemComposite);
				// 	})
				// }
			}


			function createContainer_question(fromItemComposite, options) {
				var
					transformItem = options && options.transformItem,
					container,
					item,
					containerItemObject
				;

				containerItemObject = {
					isContainer: true,
					content: fromItemComposite.content,
					subItemsKind: enums.subItemsKind.childrenByUser,
					container: container = {
						isQuestion: true,
						title: $translate.instant("Question"),
						init: init,
						populateContainer: populateContainer,
					},
					useGridStyle: true
				}


				// If transformItem is true then transform fromItemComposite into a container item
				if (transformItem) {
					containerItemObject.container.selfContained = true;
					fromItemComposite.questionContainer = containerItemObject.container;
					fromItemComposite.open = open;
					item = _.assign(fromItemComposite, containerItemObject, itemCompositePrototype);
					item.container = fromItemComposite.container = container
				}
				else { // Otherwise make a new container item
					fromItemComposite.questionContainer = container;
					return item = _.assign({
						uniqueId: fromItemComposite.wfid + "-question|" + _.uniqueId(),
						wfid: fromItemComposite.wfid + "-question",
						content: {},
						relation: {},
						parentWfid: fromItemComposite.wfid,
						parent: fromItemComposite,
						useGridStyle: true,
						open: open
					}, containerItemObject, itemCompositePrototype);
				}

				function init() {
					if (item.subItemsLoaded) {
						populateContainer()
					}
					else {
						dataOps.getSubItemsOfAll([ fromItemComposite ], enums.subItemsKind.childrenByUser, {
							ticket: loader.ticket,
							skipExtras: true
						}).then(populateContainer);
					}
				}

				function open() {
					openItemCompositeOverlay.call(fromItemComposite, { mainHierarchyKind: enums.subItemsKind.childrenByUSer, showVisualization: true });
				}

				function populateContainer() {
					var question = fromItemComposite;
					var answerRelations = dataQuery.getAll.answersOnQuestions([ question ], loader.ticket);
					var totalPercentagePerAnswerType = {};
					var percentagePerAnswerTypePerQuestion = _.chain(answerRelations)
						.sortBy("createdAt")
						.groupBy("wffid")
						.mapValues(function (answerRelationsForQuestion) {
							// Get latest answers by grouping by organization, selecting latest answer per org and then ungroup
							var latestAnswersForQuestion = _.chain(answerRelationsForQuestion)
								.groupBy("organizationId")
								.mapValues(function (answerRelationsForQuestionFromOrganization) {
									return _.last(answerRelationsForQuestionFromOrganization).childContent;
								})
								.map()
								.value()
							;

							var percentagePerAnswerTypeForQuestion = _.chain(latestAnswersForQuestion.filter(x => x && x.questionAnswerTypeId))
								.groupBy("questionAnswerTypeId")
								.mapValues(function (answers, questionAnswerTypeId) {

									var percentage = answers.length / latestAnswersForQuestion.length * 100.0;

									// Prepare totalPercentagePerAnswerType
									if (questionAnswerTypeId in totalPercentagePerAnswerType)
										totalPercentagePerAnswerType[questionAnswerTypeId] += percentage;
									else
										totalPercentagePerAnswerType[questionAnswerTypeId] = percentage;

									return percentage;
								})
								.value()
							;

							return percentagePerAnswerTypeForQuestion;
						})
						.value()
					;

					var totalPercentagesSum = _.chain(totalPercentagePerAnswerType).map().sum().value();
					// Set correct values in totalPercentagePerAnswerType using totalPercentagesSum
					_.each(totalPercentagePerAnswerType, function (value, questionAnswerTypeId) {
						totalPercentagePerAnswerType[questionAnswerTypeId] = value / totalPercentagesSum * 100.0;
					});
					container.totalPercentagesSum = totalPercentagesSum;
					container.percentagePerAnswerTypePerQuestion = percentagePerAnswerTypePerQuestion;
					container.totalPercentagePerAnswerType = totalPercentagePerAnswerType;
					container.preparedTotals = _.chain(container.totalPercentagePerAnswerType)
						.mapValues(function (value, questionAnswerTypeId) {
							value = numeral(value).format("0,0.[0]");
							return {
								subTitle: "",
								title: wfPropertyExtractor.getQuestionAnswerTypeText(questionAnswerTypeId),
								value: value + "%",
								shortValue: value + "%"
							}
						})
						.map()
						.value()
					;

					_.each([ question ], function (itemComposite) {
						itemComposite.preparedTotals = percentagePerAnswerTypePerQuestion[itemComposite.wfid]
							&& _.map(percentagePerAnswerTypePerQuestion[itemComposite.wfid], function (value, questionAnswerTypeId) {
								value = numeral(value).format("0,0.[0]");
								return {
									subTitle: "",
									title: wfPropertyExtractor.getQuestionAnswerTypeText(questionAnswerTypeId),
									value: value + "%",
									shortValue: value + "%"
								}
							});
					});

					fromItemComposite.container = { ...container }

					item.subItemsLoaded = true;
					$timeout();
				}
			}



			function openItemCompositeOverlay(config, preloadedItem) {
				var itemComposite = this;

				modal.open({
					size: 'width-1000',
					template: '<wf-list-interface-statistical item="::item" ticket="::ticket" config="::config" preloaded-item="preloadedItem" />',
					scope: {
						item: itemComposite.content,
						preloadedItem: preloadedItem,
						ticket: loader.ticket,
						config: _.defaults(config, { chartConfig: {
							showSaveButton: true,
							itemForSavedSettings: itemComposite.dataRelation,
							useEmptyState: true, showLegend: true, colors: [ this.color ], showArea: true, useFullWidth: true, showTypeToggler: true, saveTypeOnToggle: true
						} })
					}
				});
			}
		}



	}
})();
