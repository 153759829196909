// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Draggable from '@worldfavor/components/DragAndDrop/Draggable'
import OrganizationRow from './OrganizationRow'

type Props = {
  organization: {
    wfid: string,
    imageUrl: string,
    name: string,
    actorTypes: Array<{
      title: string,
    }>
  },
  match: { search: string },
}

const useStyles = makeStyles({
  dragging: {
    opacity: 0.4,
  },
})

const DraggableOrganizationRow = (props: Props) => {
  const { organization, match, ...rest } = props
  const classes = useStyles(props)
  return (
    <Draggable data={organization.wfid} sourceType={'ORGANIZATION-BOX'}>
      {
        isDragging => (
          <OrganizationRow
            organization={organization}
            match={match}
            classes={{
              root: isDragging && classes.dragging,
            }}
            {...rest}
          />
        )
      }
    </Draggable>
  )
}

export default DraggableOrganizationRow
