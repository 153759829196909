//@flow
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import InputBase from '@material-ui/core/InputBase'
import { fade } from '@material-ui/core/styles/colorManipulator'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import CircularProgress from '@material-ui/core/CircularProgress'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',

    height: 48,
    boxSizing: 'border-box',
    border: '1px solid #e0e0e0',
    borderRadius: theme.shape.borderRadius,

    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
  },
  onFocusBorder: {
    border: '1px solid #c0c0c0',
  },
  compact: {
    height: 38,

    '& $rounded': {
      borderRadius: 19,
    },
  },
  rounded: {
    borderRadius: 24,
  },
  searchIcon: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
  },
  compactSearchIcon: {
    width: theme.spacing(6),
  },
  inputRoot: {
    flex: 1,
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: 13,
    paddingRight: theme.spacing(1),
    paddingBottom: 14,
    paddingLeft: theme.spacing(10),
    width: '100%',
  },
  compactInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(6),
  },
  clearButton: {
    padding: 7,
    margin: 4,
  },
  compactClearButton: {
    padding: 5,
    margin: 3,
  },
  loader: {
    color: theme.palette.secondary.main,
    marginRight: 12,
  },
  disabled: {
    opacity: 0.7,
  },
}))

type Props = {
  // MANDATORY PROPS
  inputProps: { value: string, placeholder: string, onChange: () => void },

  onClear?: () => void,
  renderCount?: () => void,
  compact?: boolean,
  rounded?: boolean,
  showClearButton?: boolean,
  inputClasses?: Object,
  isLoading: boolean,
}

const SearchBar = (props: Props) => {
  const [focused, setFocused] = useState(false)
  const {
    //MANDATORY PROPS
    inputProps,

    onClear,
    renderCount,
    compact,
    rounded,
    showClearButton,
    inputClasses,
    showLoader,
    isLoading,
    onFocus,
  } = props

  const { value, disabled } = inputProps

  function toggleSearchBarFocused() {
    setFocused(!focused)
    onFocus && onFocus()
  }

  const classes = useStyles(props)
  return (
    <div
      className={
        classNames(classes.root,
          {
            [classes.compact]: compact,
            [classes.rounded]: rounded,
            [classes.disabled]: disabled,
          },
          focused && classes.onFocusBorder,
        )
      }
    >
      <div className={classNames(classes.searchIcon, { [classes.compactSearchIcon]: compact })}>
        <SearchIcon />
      </div>
      <InputBase
        data-cy="search-bar"
        onFocus={toggleSearchBarFocused}
        onBlur={toggleSearchBarFocused}
        classes={{
          root: classes.inputRoot,
          input: classNames(classes.inputInput, { [classes.compactInput]: compact }),
          ...inputClasses,
        }}
        {...inputProps}
      />
      {
        value && (
          <>
            {
              renderCount && renderCount()
            }
            {
              (showClearButton && !isLoading) && (
                <IconButton
                  data-cy="clear-button"
                  aria-label="Clear"
                  classes={{ root: classNames(classes.clearButton, { [classes.compactClearButton]: compact }) }}
                  onClick={onClear}
                >
                  <ClearIcon fontSize={compact ? 'small' : 'default'} />
                </IconButton>
              )
            }
            {
              (showLoader && isLoading) && (
                <CircularProgress
                  className={classes.loader}
                  size={24}
                />
              )
            }
          </>
        )
      }
    </div>
  )
}

SearchBar.defaultProps = {
  showClearButton: true,
}

export default SearchBar
