// @flow
import React, { useState, useReducer, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import uuid from 'uuid/v1'
import _ from 'lodash'

import Divider from '@worldfavor/components/Divider'
import Colors from '@worldfavor/constants/colors'
import TransparencyLevelDialog from '../TransparencyLevelDialog'
import LeavePrompt from '@worldfavor/components/LeavePrompt'
import MapperLeftPanel from './MapperLeftPanel'
import MapperRightPanel from './MapperRightPanel'
import { useDialogState } from '@worldfavor/hooks'

type Props = {
  rootNodeId: string,
  nodes: { [string]: Object },
  edges: Array<Object>,
  onSave?: (
    addedNodes: Array<Object>, addedEdges: Array<Object>,
    dropNodeIds: Array<string>, dropEdgeIds: Array<string>,
  ) => void,
  onDone?: (
    hasChanged: boolean,
    addedNodes?: Array<Object>, addedEdges?: Array<Object>,
    dropNodeIds?: Array<string>, dropEdgeIds?: Array<string>, transparencyLevel?: number,
  ) => void,

  organizations: Array<Object>,
  uniqueOrganizations?: Array<Object>,
  mappingEntity: Object,
  networkWfid?: string,
  influenceWfid?: string,

  onAddOrganization?: (event: SyntheticEvent<HTMLButtonElement>) => void,
  onDeleteOrganization: (mappingEntityWfid: string, organizationWfid: string) => void,
  onSetActorType?: (event: SyntheticEvent<HTMLButtonElement>, actorType: string) => void,
}

type State = {
  nodes: any,
  edges: any,
}

function reducer(state: State, action) {
  switch (action.type) {
    case 'SET_NODES':
      return {
        ...state,
        nodes: action.nodes,
      }

    case 'SET_EDGES':
      return {
        ...state,
        edges: action.edges,
      }

    default:
      return state
  }
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    height: '100%',
    minHeight: 600,
    overflow: 'hidden',
  },
})

const Mapper = (props: Props) => {
  const {
    rootNodeId,
    edges,
    nodes,
    onSave,
    onDone,
    organizations,
    uniqueOrganizations,
    mappingEntity,
    networkWfid,
    influenceWfid,
    availableActorTypesWfid,
    onAddOrganization,
    onDeleteOrganization,
    onSetActorType,
  } = props
  const [state, dispatch] = useReducer(reducer, { nodes, edges })
  const classes = useStyles(props)
  const [treeKey, setTreeKey] = useState(new Date().getTime())
  const [hasChanged, setHasChanged] = useState(false)
  const [hasChangedBefore, setHasChangedBefore] = useState(false)
  const [search, setSearch] = useState('')

  const [transparencyDialogOpen, openTransparencyDialog, closeTransparencyDialog] = useDialogState(false)

  useEffect(() => {
    const { hasChanged, addedNodes, addedEdges, dropNodeIds, dropEdgeIds } = getModifiedData()
    setHasChanged(hasChanged)
    if (hasChanged) {
      setHasChangedBefore(true)
      onSave && onSave(addedNodes, addedEdges, dropNodeIds, dropEdgeIds)
    }
  }, [state.edges, state.nodes])

  useEffect(() => {
    // setHasChanged(getModifiedData().hasChanged)
    dispatch({ type: 'SET_EDGES', edges })
    dispatch({ type: 'SET_NODES', nodes })
  }, [nodes, edges])

  function onRemoveNode(nodeWfid) {
    const { edges, nodes } = state

    const getEdges = (fromWfid) => {
      return edges.filter(edge => edge.fromWfid === fromWfid)
        .reduce((acc, edge) => [
          ...acc,
          edge,
          ...getEdges(edge.toWfid),
        ], [])
    }

    const edgesToRemove = [
      ...edges.filter(({ toWfid }) => toWfid === nodeWfid),
      ...getEdges(nodeWfid),
    ]
    const edgeIds = edgesToRemove.map(({ wfid }) => wfid)
    const nodeIds = [
      nodes[nodeWfid].wfid,
      ...edgesToRemove.map(({ toWfid }) => toWfid),
    ]

    const newNodes = (Object.values(nodes): any)
      .filter(({ wfid }) => !nodeIds.includes(wfid))
      .reduce((acc, node) => ({
        ...acc,
        [node.wfid]: node,
      }), {})
    const newEdges = edges.filter(({ wfid }) => !edgeIds.includes(wfid))

    setTreeKey(new Date().getTime())

    dispatch({ type: 'SET_EDGES', edges: newEdges })
    dispatch({ type: 'SET_NODES', nodes: newNodes })

    return {
      dropEdges: edgeIds,
      dropNodes: nodeIds,
    }
  }

  function createEdge(parentWfid, organizationWfid) {
    const { edges, nodes } = state

    // check that the organization doesn't exist on the same level
    // get all edges from parentWfid and nodes
    const outNodeIds = edges
      .filter(edge => edge.fromWfid === parentWfid)
      .map(({ toWfid }) => toWfid)
    const outNodes = outNodeIds.map(id => nodes[id]).filter(Boolean)
    const exist = outNodes.some(({ properties }) => organizationWfid === _.get(properties, 'organizationWfid'))

    if (!exist) {
      const nodeId = `tmpMappedOrganization-${uuid()}`
      const newNode = {
        wfid: nodeId,
        id: nodeId,
        label: 'mappedProductSupplier',
        type: 'vertex',
        temporary: true,
        properties: {
          productWfid: rootNodeId,
          organizationWfid,
        },
      }

      const edgeId = `tmpEdge-${uuid()}`
      const newEdge = {
        wfid: edgeId,
        id: edgeId,
        label: 'suppliedFrom',
        type: 'edge',
        toLabel: 'mappedProductSupplier',
        fromLabel: 'mappedProductSupplier',
        toWfid: newNode.id,
        toId: newNode.id,
        fromWfid: parentWfid,
        fromId: parentWfid,
        temporary: true,
        properties: {
          productWfid: rootNodeId,
        },
      }

      dispatch({
        type: 'SET_EDGES',
        edges: [
          ...state.edges,
          newEdge,
        ],
      })
      dispatch({
        type: 'SET_NODES',
        nodes: {
          ...state.nodes,
          [newNode.wfid]: newNode,
        },
      })

      return newNode
    }
    return null
  }

  function getModifiedData() {
    const { edges: newEdges, nodes: newNodes } = state
    const newEdgeIds = newEdges.map(({ wfid }) => wfid).filter(({ temporary }) => !temporary)
    const newNodeIds = (Object.values(newNodes): any)
      .map(({ wfid }) => wfid)
      .filter(({ temporary }) => !temporary)

    const addedNodes = (Object.values(newNodes): any)
      .filter(({ temporary }) => temporary)
    const addedEdges = newEdges.filter(({ temporary }) => temporary)

    const dropNodeIds = (Object.values(nodes): any)
      .filter(({ wfid }) => !newNodeIds.includes(wfid))
      .map(({ wfid }) => wfid)
    const dropEdgeIds = edges
      .filter(({ wfid }) => !newEdgeIds.includes(wfid))
      .map(({ wfid }) => wfid)

    return {
      hasChanged: dropNodeIds.length > 0 || dropEdgeIds.length > 0
        || addedNodes.length > 0 || addedEdges.length > 0,
      addedNodes,
      addedEdges,
      dropNodeIds,
      dropEdgeIds,
    }
  }

  function _onDone() {
    if (hasChangedBefore) {
      openTransparencyDialog()
      return
    }
    onDone && onDone(false)
  }

  async function _onSetActorType(event, actorType, node) {
    await onSetActorType(event, actorType, node)
    setHasChangedBefore(true)
  }

  async function deleteOrganizationFromList(deleteOrganizationWfid) {
    await onDeleteOrganization(mappingEntity.wfid, deleteOrganizationWfid)
  }

  function onTransparencySave(transparencyLevel) {
    const { hasChanged, addedNodes, addedEdges, dropNodeIds, dropEdgeIds } = getModifiedData()

    closeTransparencyDialog()
    onDone && onDone(hasChanged, addedNodes, addedEdges, dropNodeIds, dropEdgeIds, transparencyLevel)
  }

  function onMappingStatusEditedManually() {
    setHasChangedBefore(false)
  }

  return (
    <LeavePrompt when={hasChanged}>
      <div className={classes.root}>
        <MapperLeftPanel
          organizations={organizations}
          nodes={state.nodes}
          search={search}
          onSearchChange={setSearch}
          onAddOrganization={onAddOrganization}
          onDeleteOrganization={deleteOrganizationFromList}
        />

        <Divider width={1} height={64} color={Colors.gray} style={{ minWidth: 1 }} />

        <MapperRightPanel
          treeKey={treeKey}
          rootNodeId={rootNodeId}
          mappingEntity={mappingEntity}
          networkWfid={networkWfid}
          influenceWfid={influenceWfid}
          availableActorTypesWfid={availableActorTypesWfid}
          organizations={organizations}
          uniqueOrganizations={uniqueOrganizations}
          nodes={state.nodes}
          edges={state.edges}
          search={search}
          onDone={_onDone}
          createEdge={createEdge}
          onRemoveNode={onRemoveNode}
          onSetActorType={_onSetActorType}
          onMappingStatusEdited={onMappingStatusEditedManually}
        />

        <TransparencyLevelDialog
          open={transparencyDialogOpen}
          level={mappingEntity.transparencyLevel}
          onCancel={closeTransparencyDialog}
          onSave={onTransparencySave}
        />
      </div>
    </LeavePrompt>
  )
}

Mapper.defaultProps = {
  mappingEntity: {},
  onDeleteOrganization: () => { },
}

export default Mapper
