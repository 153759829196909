import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Colors from '@worldfavor/constants/colors'
import PageHeader from '@worldfavor/components/Header/PageHeader'

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    color: Colors.black,
    fontSize: 42,
    fontWeight: theme.typography.fontWeightLight,
  },
  description: {
    color: Colors.black,
    fontSize: 18,
    whiteSpace: 'pre-wrap',
  },
}))

const StyledPageHeader = (props) => {
  const { title, description, ...rest } = props
  const classes = useStyles(props)

  return (
    <PageHeader
      className={classes.root}
      title={title && <span className={classes.title}>{title}</span>}
      description={description && <span className={classes.description}>{description}</span>}
      {...rest}
    />
  )
}

export default React.memo(StyledPageHeader)
