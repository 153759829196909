// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Colors from '@worldfavor/constants/colors'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  labelName: {
    color: Colors.grayText,
    marginRight: 5,
  },
  labelText: {
    color: Colors.grayText,
    fontWeight: theme.typography.fontWeightMedium,
  },
  statusIndicator: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 4,
    fontSize: 12,
  },
  progress: {
    color: Colors.grayText,
    marginLeft: 10,
  },
  checkedIcon: {
    marginLeft: 5,
    color: Colors.green,
  },
  progressBar: {
    width: '100%',
    height: 6,
    backgroundColor: 'rgba(0, 0, 0, 0.075)',
    borderRadius: 3,
  },
  fillBar: {
    height: 6,
    backgroundColor: Colors.green,
    borderRadius: 3,
  },
})
  
const ProgressionStatusIndicator = (props) => {
  const { classes, label, checked, percentage, progress, total, showStatusLabel, hideProgressBar, hideProgress } = props
  return (
    <div className={classes.container}>
      <div className={classes.statusIndicator}>
        <div className={classes.labelWrapper}>
          {
            showStatusLabel && (
              <span className={classes.labelName}>
                <FormattedMessage id={'general.status'} />:
              </span>
            )
          }
          <span className={classes.labelText}>{label}</span>
          { checked && (<i className={classNames(classes.checkedIcon, 'fas fa-check')} />)}
        </div>
        {
          typeof progress === 'number' && total > 0 && !hideProgress && (
            <div className={classes.progress}>
              {progress}/{total}
            </div>
          )
        }
      </div>
      {
        typeof percentage === 'number' && !hideProgressBar && (
          <div className={classes.progressBar}>
            <div className={classes.fillBar} style={{ width: `${percentage}%` }} />
          </div>
        )
      }
    </div>
  )
}

export default withStyles(styles)(ProgressionStatusIndicator)
