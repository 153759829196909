import React from 'react'
import ProgressionStatusIndicator from './ProgressionStatusIndicator'
import { fulfillmentStateTranslateMap } from '@worldfavor/utils/propertyExtractors'
import { FormattedMessage } from 'react-intl'

const FulfillmentStatusIndicator = (props) => {
  const { influence, hideProgressBar, hideProgress } = props
  const {
    fulfilled,
    fulfillmentProgress,
    fulfillmentProgressPercentage,
    fulfillmentProgressTotal,
    fulfillmentState,
  } = influence
  
  let label = undefined
  if (typeof fulfillmentState === 'number') {
    label = <FormattedMessage id={fulfillmentStateTranslateMap[fulfillmentState]} />
  }
  else if (typeof fulfillmentState !== 'number' && !fulfilled) {
    label = <FormattedMessage id={fulfillmentStateTranslateMap[0]} />
  }
  
  return (
    <ProgressionStatusIndicator
      label={label}
      progress={fulfillmentProgress}
      percentage={fulfillmentProgressPercentage}
      total={fulfillmentProgressTotal}
      checked={fulfilled}
      hideProgressBar={hideProgressBar}
      hideProgress={hideProgress}
    />
  )
}

FulfillmentStatusIndicator.defaultProps = {
  influence: {},
}

export default FulfillmentStatusIndicator
