import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Colors from '@worldfavor/constants/colors'
import classNames from 'classnames'

const styles = {
  root: {
    position: 'relative',
  },
  circle: {
    color: Colors.blackLight,
  },
  childrenBlocker: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255,255,255,0.6)'
  },
  circularProgressRoot: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
}

const CircularProgressWrapper = ({ classes, loading, hideChildren, blockChildren, children, size, className, ...rest }) => {
  const margin = size / -2

  return (
    <div className={classNames(classes.root, className)}>
      <div style={loading && hideChildren ? { visibility: 'hidden' } : undefined}>
        {children}
      </div>

      {
        loading && blockChildren && (
          <div className={classes.childrenBlocker}></div>
        )
      }
      {
        loading && (
          <CircularProgress
            classes={{ root: classes.circularProgressRoot, circle: classes.circle }}
            size={size}
            style={{ marginTop: margin, marginLeft: margin }}
            {...rest}
          />
        )
      }
    </div>
  )
}

CircularProgressWrapper.defaultProps = {
  size: 24,
}

export default withStyles(styles)(CircularProgressWrapper)
