(function() {
	'use strict';

	angular
		.module('wf.common')
		.service('wfUtilityService', wfUtilityService);

	wfUtilityService.$inject = [ '$templateCache', '$http' ];
	function wfUtilityService($templateCache, $http) {
		var 
			templateRequests = {},
			templates = {}
		;

		var service = {
			loadTemplateUrlInCache: loadTemplateUrlInCache,
			getTemplateFromCache: getTemplateFromCache
		}
		
		return service;
	
		function loadTemplateUrlInCache(url) {
			var html = $templateCache.get(url);
	
			if (html) {
				templates[url] = html;
				$templateCache.put(url, html);
				return;
			}
	
			if (!templateRequests[url]) {
				templateRequests[url] = $http.get(url);
	
				templateRequests[url].then(function (res) {
					templates[url] = res.data;
				});
			}
	
			templateRequests[url].then(function (res) {
				$templateCache.put(url, res.data);
			});
		}

		function getTemplateFromCache(templateToLoad) {
			return $templateCache.get(templateToLoad);
		}
	}
})();