
import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import MuiTableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import { FormattedMessage } from 'react-intl'
import { DefaultTablePageSize } from '@worldfavor/constants'
import Colors from '@worldfavor/constants/colors'

export * from './TableHeaderCell'

const styles = {
  table: {
    tableLayout: 'fixed',
    width: '100%',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: Colors.grayTableOddRow,
    },
    '&:hover': {
      backgroundColor: Colors.grayTableHover,
    },
  },
  tableCell: {
    paddingRight: 24,
  },
}

const TableRow = ({ classes, columns, row, index, onClick, renderCell, hover }) => {
  function _onClick(event) {
    onClick && onClick(event, row, index)
  }

  return (
    <MuiTableRow className={classes.row} onClick={_onClick} hover={hover}>
      {
        columns.map((column, columnIndex) => renderCell({ row, column, rowIndex: index, columnIndex }))
      }
    </MuiTableRow>
  )
}

const Table = ({
  classes,
  data,
  renderRowCell,
  renderColumnCell,
  columns = [],
  columnWidths,
  onPageChange,
  onChangeRowsPerPage,
  onRowClick,
  enablePagination,
  enableSparsePage,
  rowKeyExtractor,
  rowCellKeyExtractor,
  columnCellKeyExtractor,
  rowHover,

  controlledPagination, //controlled by the parent
  rowsPerPageOptions,
  count,
  rowsPerPage,
  page,
}) => {
  const [pageNumber, setPageNumber] = useState(0)
  const [pageSize, setPageSize] = useState(DefaultTablePageSize)

  function _renderRowCell({ row, column, rowIndex, columnIndex }) {
    return renderCell(rowCellKeyExtractor, renderRowCell, { row, column, rowIndex, columnIndex })
  }

  function _renderColumnCell({ column, columnIndex }) {
    return renderCell(columnCellKeyExtractor, renderColumnCell, { column, columnIndex })
  }

  function renderCell(keyExtractor, renderCellFunc, data) {
    const content = renderCellFunc({ ...data, keyExtractor })
    let wrapContent = true
    let cellStyle = null

    if (columnWidths && typeof columnWidths[data.columnIndex] !== 'undefined') {
      cellStyle = { style: { width: columnWidths[data.columnIndex] } }
    }

    if (React.Children.count(content) === 1) {
      React.Children.forEach(content, (child) => {
        if (child.type === TableCell) {
          wrapContent = false
        }
      })
    }

    if (wrapContent) {
      return (
        <TableCell key={keyExtractor(data)} classes={{ root: classes.tableCell }} {...cellStyle}>{content}</TableCell>
      )
    }

    return React.cloneElement(content, {
      ...cellStyle,
    })
  }

  function _onPageChange(event, pageNumber) {
    if (!controlledPagination) {
      setPageNumber(pageNumber)
    }
    onPageChange && onPageChange(event, pageNumber)
  }

  function _onChangeRowsPerPage(event) {
    if (!controlledPagination) {
      setPageSize(event.target.value)
    }
    onChangeRowsPerPage && onChangeRowsPerPage(event)
    onPageChange && onPageChange(event, 0)
  }

  function getTableData() {
    if (!controlledPagination) {
      return data.slice(pageNumber * pageSize, pageNumber * pageSize + pageSize)
    }
    if (!enableSparsePage) {
      return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }
    return data
  }

  return (
    <React.Fragment>
      <MuiTable className={classes.table} size="small" data-cy="table">
        <TableHead>
          <MuiTableRow>
            {
              columns.map((column, columnIndex) => _renderColumnCell({ column, columnIndex }))
            }
          </MuiTableRow>
        </TableHead>
        <TableBody>
          {
            getTableData().map((row, rowIndex) => (
              <TableRow
                classes={classes}
                key={rowKeyExtractor(row, rowIndex)}
                row={row}
                index={rowIndex}
                columns={columns}
                renderCell={_renderRowCell}
                onClick={onRowClick}
                hover={rowHover}
              />
            ))
          }
        </TableBody>
      </MuiTable>
      {
        Boolean(enablePagination) && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            count={controlledPagination ? count : data.length}
            rowsPerPage={controlledPagination ? rowsPerPage : pageSize}
            page={controlledPagination ? page : pageNumber}
            labelRowsPerPage={<FormattedMessage id={'table.labelRowPerPage'} />}

            component="div"
            onChangePage={_onPageChange}
            onChangeRowsPerPage={_onChangeRowsPerPage}
          />
        )
      }
    </React.Fragment>
  )
}

Table.defaultProps = {
  rowsPerPageOptions: [5, 10, 25],
  columnCellKeyExtractor: ({ columnIndex }) => `columnCell-${columnIndex}`,
  rowCellKeyExtractor: ({ rowIndex, columnIndex }) => `rowCell-${rowIndex}-${columnIndex}`,
  rowKeyExtractor: (row, rowIndex) => `row-${rowIndex}`,
}

export default withStyles(styles)(Table)
