(function() {
'use strict';

	angular
		.module('wf.common')
		.controller('supplierDemandDocumentController2', supplierDemandDocumentController2);

	supplierDemandDocumentController2.$inject = ['$uibModal', '$ngBootbox', '$scope', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', '$timeout','$q','pdfGenerator','apiProxy','$compile','screenLoaderService','wfAuth'];
	
	function supplierDemandDocumentController2($uibModal, $ngBootbox, $scope, dataOps, modal, wfObject, dataQuery, $translate, $timeout, $q, pdfGenerator, apiProxy, $compile, screenLoader, wfAuth) {
		var
			vm,
			allQuestionDataRelations = [],
			allQuestions = [],
			structure,
			pdfGenerator,
			organizationId,
			// influence,
			verifications
		;
		// _.assign(this, modalContext);
		_.assign(this, $scope.$parent.vm);
		vm = this;
		vm.submit = submit;
		// MODES
		vm.isWorkMode = false;
		vm.isPrintMode = vm.context.isPrintMode;
		vm.isAggregateMode = !!vm.context.aggregateObjectType;
		vm.verificationDocuments = [];
		vm.listControls = {};
		vm.relatedContentLists = {};
		
		if (vm.item.type == 73)
			vm.mainStructure = vm.item.childContent;
		else if (vm.item.type == 71)
			vm.mainStructure = vm.item;
		
		if (vm.context.influence)
		{
			vm.organizationId = vm.context.influence.organizationId;
			vm.influence = vm.context.influence;
			vm.mainStructure = wfObject.get(vm.influence.objectType + '-' + vm.influence.objectId);
			vm.isFulfilled = vm.context.influence.fulfilled;
			
			verifications = vm.mainStructure.filterVerifications({ organizationId: vm.organizationId });
			if (verifications.length > 0) {
				_.forEach(verifications, function (verificationDataRelation) {
					Array.prototype.push.apply(vm.verificationDocuments, verificationDataRelation.childContent.filterChildren({ childType: 18 }));
				});
				vm.verificationDocuments = _.orderBy(vm.verificationDocuments, [ 'createdAt' ], [ 'desc' ]);
			}
			
			if (vm.context.influence.organizationId == wfAuth.getOrganizationId())
				vm.isWorkMode = true;
				
			activate();
		}
		else if (vm.context.verification)
		{
			vm.organizationId = vm.context.verification.organizationId; 
			activate();
		}
		else
		{
			
			dataOps.getSubItems(vm.mainStructure, 1, { childrenLoadDepth: 10, aggregate: vm.context.aggregateQuestionAnswers, networkId: vm.context.networkId, aggregateObjectType: vm.context.aggregateObjectType  }).then(function () {
				$timeout(function () {
					activate();
				});
			});
		}
		

		// console.log(vm);

		////////////////

		function activate() {
			$scope.modal = modal;
			$scope.wfObject = wfObject;
			$scope.dataQuery = dataQuery;
			
			vm.childs = vm.mainStructure.childs;
			vm.selectedId = null; //vm.context.onlyShowAll || vm.context.canShowAll ? null : vm.childs[0].childId;
			vm.context.canShowAll = true;
			
			structure = vm.mainStructure;
			vm.canAddDocStatement = !!vm.mainStructure.conditions.loadRelatedContentByUser;
			vm.docStatement = null;
			vm.docStatementText = null;
			
			_.each(vm.childs, function(item) {
				Array.prototype.push.apply(allQuestionDataRelations, item.childContent.childs)
			});
			
			_.each(allQuestionDataRelations, function(item) {
				allQuestions.push(item.childContent);
			});
			
			if (vm.canAddDocStatement && !vm.isAggregateMode) {
				vm.docStatement = vm.mainStructure.filterRelatedContent({ organizationId: vm.organizationId })[0];
				
				if (vm.docStatement) {
					vm.docStatement = vm.docStatement.childContent;
					vm.docStatementText =  vm.docStatement.text;
				}
			}
			
			vm.loaded = true;
			$scope.isWorkMode = vm.isWorkMode;
		}
		
		function submit($event) {
			var
				jqDf = $.Deferred(),
				message,
				questionsLeft,
				questionNumbers
			;

			if (!areAllAnswersLikeRequired()) {
				message = [
					$translate.instant("modules.valueChain.influence.message_questionsLeft"),
					"",
					$translate.instant("modules.valueChain.influence.message_haveToFulfillQuestions"),
				].join("<br />");

				questionsLeft = vm.questionAnsweringManager.getLocalFulfillmentData().unfulfilled;
				questionNumbers = _.map(questionsLeft, function (qdr) {
					return "<b>" + qdr.order + "</b>";
				});

				if (questionNumbers.length > 1)
					message += _.take(questionNumbers, questionNumbers.length - 1).join(", ") + " " + $translate.instant("And").toLowerCase() + " " + _.last(questionNumbers);
				else
					message += questionNumbers[0]

				message += ".";

				$ngBootbox.customDialog({
					title: $translate.instant("modules.valueChain.influence.message_header"),
					message: message,
					onEscape: true,
					buttons: {
						primary: {
							label: "OK",
							className: "btn-primary",
							callback: function() {

							}
						}
					}
				});
				return;
			}

			// dataOps.getObject({
			// 	objectId: 3530,
			// 	objectType: 67
			// }).then(function (verification) {


			// screenLoader

			// Fix
			modal.verifyItem(vm.mainStructure, vm.influence).then(function (verification) {
				var
					verificationScope,
					compiledVerificationHtml,
					element
				;

				if (verification)
				{
					verificationScope = $scope.$new();
					compiledVerificationHtml = $compile('<wf-item template-id="52"></wf-item>'); // Generates a link function
					verificationScope.item = verification;
					vm.context.verification = verification;
					vm.context.templateId = 58;
					element = compiledVerificationHtml(verificationScope); // Executes link function using the new scope
// 									jqDf.resolve();
// return;					
					$scope.$on("verifiedContentLoaded", function (event, verifiedContent) {
						console.log(verifiedContent);
						delete vm.context.verification;

						screenLoader.show($translate.instant("modules.valueChain.influence.message_generatingPdf"))
						pdfGenerator.fromElement(element.find("div.pdfGeneratorElement"), vm.influence.title + " " + moment().format("YYYY-MM-DD"), vm.influence.channelId).then(function (document) {
							dataOps.createSubItemRelation(verification, document, 7).then(function (dr) {
								
								
								screenLoader.show($translate.instant("modules.valueChain.influence.message_calculatingFulfillment"))
								apiProxy("fulfillment.calculate", {
									item: dataOps.prepareWfObject(vm.influence)
								}).then(function (fulfillmentResult) {
									var verifications;
									console.log(fulfillmentResult);
									vm.influence.fulfilled = vm.isFulfilled = fulfillmentResult.fulfillment.fulfills;

									verifications = vm.mainStructure.filterVerifications({ organizationId: vm.organizationId });
									if (verifications.length > 0) {
										$timeout(function () {
											vm.verificationDocuments = [];
											_.forEach(verifications, function (verificationDataRelation) {
												Array.prototype.push.apply(vm.verificationDocuments, verificationDataRelation.childContent.filterChildren({ childType: 18 }));
											});
											vm.verificationDocuments = _.orderBy(vm.verificationDocuments, [ 'createdAt' ], [ 'desc' ]);
										});
										screenLoader.hide();
									}
									jqDf.resolve();
								});
							});
						});
					});
				}
				else
				{
					screenLoader.hide();
					jqDf.resolve(false);
				}
			})

			return jqDf.promise();

			// return;

			// var jqDf = $.Deferred();
			// modal.verifyItem(vm.mainStructure).then(function (res) {

			// 	wfObject.inject(res);
			// 	verificationScope.item = wfObject.get(res.wfid);
			// 	jqDf.resolve();
			// 	// modal.openItem({
			// 	// 	item: wfObject.get(res.wfid),
			// 	// 	settings: {
			// 	// 		templateId: 52
			// 	// 	}
			// 	// });


			// 	// 	jqDf.resolve();
			// 	// });
			// });
			
			// return jqDf.promise();
			// var element = $($event.target).closest('.pdfGeneratorElement');
			// if (element.length)
			// 	pdfGenerator.fromElement(element);
			// else
			// 	console.log('No element found to generate PDF from.')
		}
		
		function areAllAnswersLikeRequired() {
			return vm.questionAnsweringManager.fulfillsAllLocally()
		}
	}
})();