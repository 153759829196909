// @flow
import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'

import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import CircularProgressWrapper from '@worldfavor/components/CircularProgressWrapper'
import Colors from '@worldfavor/constants/colors'
import InfluenceEditor from './InfluenceEditor'
import { useFormValidation } from '@worldfavor/hooks'
import dateFns from 'date-fns'

const formDescriptors = {
  creatorUserId: {
    required: true,
  },
  activatedAt: {
    type: 'date',
  },
  fulfillmentDueAt: {
    type: 'date',
  },
  comment: {},
}

const styles = {
  loader: {
    color: Colors.gray,
    margin: '100px auto',
    display: 'block',
  },
}

const InfluenceEditorDialog = ({
  classes,
  open,
  onClose,
  onSave,
  saving,
  influence,
  users,
  editable,
  ...rest
}) => {
  const { comment, activatedAt, fulfillmentDueAt, creatorUserId } = influence || {}
  const [form, setFormField, formValidation, validateForm, setForm] = useFormValidation({
    comment: comment || '',
    activatedAt: activatedAt ? dateFns.format(activatedAt, 'YYYY-MM-DD') : '',
    fulfillmentDueAt: fulfillmentDueAt ? dateFns.format(fulfillmentDueAt, 'YYYY-MM-DD') : '',
    creatorUserId: creatorUserId || '',
  }, formDescriptors)
  const [showValidation, setShowValidation] = useState(false)

  function _onSave(event) {
    if (!validateForm()) {
      setShowValidation(true)
      return
    }

    onSave(event, form)
  }

  function onChangeField(event, key, value) {
    setFormField(key)(value)
  }

  useEffect(() => {
    if (open) {
      setForm({
        comment: comment || '',
        activatedAt: activatedAt ? dateFns.format(activatedAt, 'YYYY-MM-DD') : '',
        fulfillmentDueAt: fulfillmentDueAt ? dateFns.format(fulfillmentDueAt, 'YYYY-MM-DD') : '',
        creatorUserId: creatorUserId || '',
      })
    }
  }, [open])

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      {...rest}
    >
      <DialogTitle>
        <FormattedMessage id={'supplyChain.influences.editor.title'} values={{ influence: (influence && influence.title) || '' }} />
      </DialogTitle>
      <DialogContent>
        <InfluenceEditor
          form={form}
          formValidation={formValidation}
          showValidation={showValidation}
          users={users}
          onChangeField={onChangeField}
          classes={{ root: classes.influenceEditor }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={saving}>
          <FormattedMessage id={'general.cancel'} />
        </Button>
        <div>
          <CircularProgressWrapper loading={saving}>
            <Button color="primary" disabled={saving} onClick={_onSave}>
              <FormattedMessage id={'general.save'} />
            </Button>
          </CircularProgressWrapper>
        </div>
      </DialogActions>
    </Dialog>
  )
}

InfluenceEditorDialog.defaultProps = {
  influence: {},
}

export default withStyles(styles)(InfluenceEditorDialog)
