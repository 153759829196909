// @flow
import * as types from '../actions'

type State = {
  +[string]: Object,
}

const initialState: State = {}

export default (state: State = initialState, action: Object): State => {
  switch (action.type) {
    case types.ADD_GRAPH_ITEM:
      return {
        ...state,
        [action.item.wfid]: action.item,
      }

    case types.ADD_GRAPH_ITEMS: {
      return {
        ...state,
        ...action.items
          .reduce((acc, item) => ({
            ...acc,
            [item.wfid]: item,
          }), {}),
      }
    }

    case types.REMOVE_GRAPH_ITEM: {
      const { [action.id]: removed, ...rest } = state
      return {
        ...rest,
      }
    }

    case types.REMOVE_GRAPH_ITEMS: {
      return {
        ...Object.values(state)
          .filter(({ wfid }) => !action.ids.includes(wfid))
          .reduce((acc, item) => ({
            ...acc,
            [item.wfid]: item,
          }), {}),
      }
    }

    case types.RESET_GRAPH_STATE:
    case types.CHANGE_ORGANIZATION:
    case types.LOGOUT:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
