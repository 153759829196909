import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';

	angular
		.module('wf.common')
		.component('wfAnsweringFeedAttachments', {
			templateUrl: 'scripts/wf/workItem/wfAnsweringFeedAttachments.component.html',
			controller: wfAnsweringFeedAttachmentsController,
			controllerAs: 'vm',
			require: {
				"wfWorkItem": "?^^wfWorkItem",
			},
			bindings: {
				itemComposite: "<", // REQUIRED
				ticket: "<",
				exposeFirstItem: "<",
			}
		});

	wfAnsweringFeedAttachmentsController.$inject = [ '$scope', '$element', '$q', 'dataQuery', 'dataOperationsService', '$timeout', '$translate', 'wfAuth' ];
	function wfAnsweringFeedAttachmentsController($scope, $element, $q, dataQuery, dataOps, $timeout, $translate, wfAuth) {
		var vm = this;
		const authOrgId = wfAuth.getOrganizationId();
		var relatedContentByUser = undefined;

		_.assign(vm, {
			attachmentsToExpose: [],
			allAttachments: [],
			attachmentsCount: 0,
			listExpanded: false,
			hideItemImage: false,
			toggleText: "See all attachments",
			minItemsLimit: 5,
			creatorTimeConfig: {
				display: {}
			},

			// Functions
			toggleAttachmentsVisibility: toggleAttachmentsVisibility,
			onItemDeleted: onItemDeleted,
			onItemUpdated: onItemUpdated
		});

		vm.$onInit = function() {
			vm.itemComposite.getSubItems().then(subItems => {
				vm.allAttachments = subItems;
				vm.attachmentsCount = vm.allAttachments.length;

				// --------- MOCKED -----------
				// vm.itemComposite.fulfillmentResult = {
				// 	attachmentsNeeded: true,
				// 	latestAnswerContent: subItems.slice(0, 1)[0].content
				// }

				vm.loaded = true;
			});

			vm.watcher = $scope.$watchCollection("vm.allAttachments", function () {
				vm.attachmentsCount = vm.allAttachments.length;

				vm.allAttachments.forEach(x => {
					if (vm.ticket && vm.ticket.thirdParty
						&& x.dataRelation.reportingOrganizationId !== authOrgId
					)
					{
						return;
					}
					x.dropdownActions = x.content.isUserDataType() ? [ "userData" ] : [ "deleteRelation" ]
				});

				if (!vm.listExpanded)
					vm.attachmentsToExpose = _.take(vm.allAttachments, vm.minItemsLimit);
			});

			// vm.dropdownActions = vm.itemComposite.content.isUserDataType() ? [ "userData" ] : [ "deleteRelation" ]
			// vm.dropdownActions = vm.wfWorkItem.uiMode === enums.uiMode.work || vm.wfWorkItem.uiMode === enums.uiMode.admin ? [ 'userData' ] : undefined;


			// vm.attachmentsCount = getMetadataCount();

			// if (vm.attachmentsCount === 0) {
			// 	$element.remove();
			// 	return;
			// }

			// if (relatedContentByUser && relatedContentByUser.length) {
			// 	vm.attachmentsCount = relatedContentByUser.length;
			// }
			// else {
			// 	getAllAttachments().then(function(allAttachments) {
			// 		if (!allAttachments) {
			// 			$element.remove();
			// 			return;
			// 		}
			// 		else {
			// 			vm.attachmentsCount = allAttachments.length;
			// 			if (vm.exposeFirstItem)
			// 				vm.attachmentsToExpose = allAttachments.slice(0, 1);
			// 			else
			// 				vm.attachmentsToExpose = [];

			// 			vm.dropdownActions = vm.wfWorkItem.uiMode === enums.uiMode.work || vm.wfWorkItem.uiMode === enums.uiMode.admin ? [ 'userData' ] : undefined;
			// 		}
			// 	});
			// }

			setCreatorTimeDisplaySettings();
		};

		vm.$onDestroy = function() {
			vm.watcher();
		};


		function toggleAttachmentsVisibility() {
			if (!vm.listExpanded) {
				vm.attachmentsToExpose = vm.allAttachments;
				vm.toggleText = $translate.instant("modules.report.hidePreviousAnswers");
			}
			else {
				scrollToTopOfAttachments();
				vm.attachmentsToExpose = _.take(vm.allAttachments, vm.minItemsLimit);
				vm.toggleText = $translate.instant("modules.report.showPreviousAnswers");
			}

			vm.listExpanded = !vm.listExpanded;
		
			$timeout();
		}

		function onItemDeleted(relation) {
			if (vm.itemComposite) {
				vm.itemComposite.removeSubItem(relation);
				vm.wfWorkItem.itemComposite.syncFulfillment();

				if (vm.itemComposite.currentAnswerModule)
					vm.itemComposite.currentAnswerModule.maybeSyncPickerItems();
			}
		}






		function getAllAttachments() {
			var deferred = $q.defer();
			relatedContentByUser = vm.itemComposite.content.relatedContentByUser;

			if (!vm.allAttachments.length) {
				if (relatedContentByUser && relatedContentByUser.length) {
					vm.allAttachments = dataQuery.makeItemComposites(relatedContentByUser);
					deferred.resolve(vm.allAttachments);
				}
				else {
					dataOps.getSubItems(vm.itemComposite.content, enums.subItemsKind.relatedContentByUser).then(function(res) {
						if (res && res.length) {
							vm.allAttachments = dataQuery.makeItemComposites(res);
							deferred.resolve(vm.allAttachments);
						}
						else 
							deferred.resolve(false);
					});
				}
			}
			else {
				deferred.resolve(vm.allAttachments);
			}

			return deferred.promise;
		}

		function getMetadataCount() {
			var countByRelationKind = _.get(vm.itemComposite.content, "metadata.countByRelationKind");
			return countByRelationKind ? countByRelationKind[enums.subItemsKind.relatedContentByUser] : undefined;
		}

		function toggleAttachmentsVisibility() {
			if (!vm.listExpanded) {
				getAllAttachments().then(function(allAttachments) {
					vm.attachmentsCount = allAttachments.length;
					vm.attachmentsToExpose = allAttachments;
				});
				vm.toggleText = "Hide attachments";
			}
			else {
				if (vm.attachmentsCount > 5)
					scrollToTopOfAttachments();
				vm.attachmentsToExpose = [];
				vm.toggleText = "See all attachments";
			}

			vm.listExpanded = !vm.listExpanded

			$timeout();
		}

		function setCreatorTimeDisplaySettings() {
			switch (vm.itemComposite.type) {
				case enums.objectType.country:
					vm.creatorTimeConfig.display.creator = false;
					vm.creatorTimeConfig.display.dateAndTime = true;
					break;
				default:
					vm.hideItemImage = false;
					vm.creatorTimeConfig.display.creator = true;
					vm.creatorTimeConfig.display.dateAndTime = true;
					break;
			}
		}

		function onItemDeleted(relation) {
			vm.itemComposite.removeSubItem(relation);
			if (vm.wfWorkItem) {
				vm.wfWorkItem.itemComposite.syncFulfillment().then(function () {
					$scope.$emit("checkRequirement");
				});
			}

			if (vm.itemComposite.currentAnswerModule) {
				vm.itemComposite.currentAnswerModule.maybeSyncPickerItems();
			}
		}

		function onItemUpdated(relation) {
			if (vm.wfWorkItem) {
				vm.wfWorkItem.itemComposite.syncFulfillment();
			}

			if (vm.itemComposite.currentAnswerModule) {
				vm.itemComposite.currentAnswerModule.maybeSyncPickerItems();
			}
		}
				
		function scrollToTopOfAttachments() {
			var gap = 60;
			$('html, body').animate({ scrollTop: $element.offset().top - gap }, 500, null);
		}
	}
})();
