import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';

	angular
		.module('wf.common')
		.controller('isoStandardsController', isoStandardsController);

	isoStandardsController.$inject = [ 'dataOperationsService', 'modalService', '$state' ];
	function isoStandardsController(dataOps, modal, $state) {
		var vm = this;
		
		_.assign(vm, {
			standards: undefined,
			showPromotion: false,

			//Functions
			openOrderProcess: openOrderProcess
		});
	
		activate();

		function activate() {
			dataOps.getObject({
				objectType: enums.objectType.structure,
				objectId: 13546
			}).then(function (res) {
				vm.mainStructure = res;
				vm.standards = res.childs;
				
				if (vm.standards.length === 0) {
					$state.go("promotion", { nameRef: 'IsoStandards' }, { location: false });
					return;
				}

				dataOps.getObject({
					objectType: enums.objectType.structure,
					objectId: 13589
				}).then(function (res) {
					if (res.childs.length != 0) {
						vm.showPromotion = true;
					}
					vm.loaded = true;
				});
			});
		}

		function openOrderProcess(productCatalogue) {
			modal.startOrdering(productCatalogue);
		}
	}
})();
