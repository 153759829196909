export const addTransparency = (color, transparency = 1) => {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)) {
    let c = color.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = `0x${c.join('')}`
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${transparency})`
  }
  else if (/^rgba?\(\s*\d*\s*,\s*\d*\s*,\s*\d*.*?\)$/.test(color)) {
    const { r, g, b } = rgbToHex(color)
    return `rgba(${r},${g},${b},${transparency})`
  }
}

export const rgbToHex = (rgb) => {
  const [all, r, g, b] = rgb.match(/^rgba?\(\s*(\d*)\s*,\s*(\d*)\s*,\s*(\d*).*?\)$/) // eslint-disable-line no-unused-vars
  return { r, g, b }
}

export const hexToRgbA = (hex, transparency = 1) => {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    let c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = `0x${c.join('')}`
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${transparency})`
  }
  throw new Error('Bad Hex')
}

export default {
  defaultColor: '#677686',
  grayOffWhite: '#f7f7f7',
  grayTableOddRow: 'rgba(0,0,0, 0.02)',
  grayTableHover: 'rgba(0,0,0, 0.03)',
  grayHover: 'rgba(0,0,0,0.05)',
  grayLighter: '#F2F5F6',
  grayLight: '#ECF0F1',
  graySemiLight: '#D7DDDF',
  gray: '#BDC3C7',
  grayDark: '#95A5A6',
  grayText: '#707980',
  blackLight: '#667484',
  black: '#34495E',
  blackDark: '#2C3E50',
  blackDarker: '#1F2D3B',
  blue: '#3498DB',
  blueDark: '#2980B9',
  green: '#2ECC71',
  greenDark: '#27AE60',
  yellow: '#F1C40F',
  yellowDark: '#F39C12',
  orange: '#E67E22',
  orangeDark: '#D35400',
  red: '#E74C3C',
  redDark: '#C0392B',
  turquoise: '#1ABC9C',
  turquoiseDark: '#16A085',
  purple: '#9B59B6',
  purpleDark: '#8E44AD',
  vividPurple: '#6200EE',
}
