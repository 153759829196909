import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';

	angular
		.module('wf.common')
		.service('wfAnsweringService', wfAnsweringService);

	wfAnsweringService.$inject = [ '$translate', '$q', 'apiProxy', '$ngBootbox', 'modalService', 'wfTranslate', 'wfPropertyExtractor', '$timeout', 'wfMeasureService', 'wfAuth', '$interpolate', 'dataOperationsService', '$sce', 'pickerService', 'dataQuery', '$rootScope' ];
	function wfAnsweringService($translate, $q, apiProxy, $ngBootbox, modal, wfTranslate, propertyExtractor, $timeout, wfMeasureService, wfAuth, $interpolate, dataOps, $sce, pickerService, dataQuery, $rootScope) {
		var
			answerModuleConfigurationsByType = getInlineAnswerModuleConfigurations(),
			answerModulePrototype = {
				type: undefined, // The object type
				itemComposite: undefined, // The main itemComposite with the settings that defined this answerModule
				ticket: undefined,

				primaryAnswerModule: false,
				secondaryAnswerModule: false,
				label: undefined,
				icon: undefined,

				selected: false, // If the answerModule is currently selected in the component wfInlineAnswering
				loaded: false, // Form or picker?
				loading: true, // Form or picker?
				isQuestionOrMeasureAnswer: false,

				uiSettings: {
					showUserIcon: true,
					enableFileDrop: false,
					enableSubmittingMultipleAnswersAtOnce: false,
					showOptionalFields: true,
					allowDiscardingForm: false,
					submitButtonLabel: "Submit Form",
					chooseExistingButtonLabel: "Choose existing",
					createNewButtonLabel: "Create New"
				},

				enableForm: true, // Default value
				form: {
					visible: true,
					model: {},
					formControl: {},
					customSpecification: undefined,
					replaceFormSpecification: false,
					onBeforeSubmitTriggered: undefined,
				},
				formCompiler: {},
				submitForm: prototype_submitForm,

				enablePicker: true, // Default value
				picker: {
					visible: false,
					pickerSettings: undefined, // { relationTarget: { item, kind } }
					instance: undefined // An instance of the Picker factory
				},

				initialize: prototype_initialize,
				maybeSyncPickerItems: prototype_maybeSyncPickerItems,
				clone: prototype_clone
				// initForm: initForm,
				// initPicker: initPicker

				//requirements config: {} ...
				// validationMessages: {},
				// customLabels: {
				// 	createNewButtonLabel: "New Document",
				// 	chooseExistingButtonLabel: "Choose Existing Document",
				// 	saveButtonLabel: "Save and add document",
				// },
			}
		;

		var service = {
			getAnswerModuleConfiguration: getAnswerModuleConfiguration,
			defineAnswerModules: defineAnswerModules,
			getDropdownActionSpecifications: getDropdownActionSpecifications,
		}

		return service;

		function getAnswerModuleConfiguration(type) {
			return _.cloneDeep(answerModuleConfigurationsByType[type]);
		}

		function getInlineAnswerModuleConfigurations() {
			var configurations = [
				{
					type: enums.objectType.questionAnswer,
					subItemsKind: enums.subItemsKind.childrenByUser,
					enablePicker: false,
					hideSubmitButton: true
				},
				{
					type: enums.objectType.measureAnswer,
					subItemsKind: enums.subItemsKind.childrenByUser,
					enablePicker: false,
					form: {
						customSpecification: undefined, // defined in initalizeAnswerModule function
						onBeforeSubmitTriggered: function (event, form) {
							var
								model = event.getModel(),
								periodRange
							;

							if (!model.periodSelection) {
								event.cancelSubmit();
								return;
							}

							periodRange = form.periodRangesByIndex[model.periodSelection];

							model.period = periodRange.period;
							model.periodEnd = periodRange.periodEnd;
							model.intervalNameSpecification = periodRange.nameSpecification || null;

							event.setModel(model);

							event.continueSubmit();
						}
					}
				},
				{
					type: enums.objectType.orgDocument,
					uiSettings: {
						enableSearchForSelectedInputs: true,
						allowDiscardingForm: false,
						enableFileDrop: true
					},
					form: {
						customSpecification: {
							form: [
								{
									type: "section",
									htmlClass: "inline-upload-field",
									items: [
										{
											key: "fileUpload",
											useSimpleForm: true
										}
									],
								},
								{
									type: "section",
									htmlClass: "flex-column",
									items: [
										"title",
										"description"
									]
								}
							]
						}
					}
				},
				{
					type: enums.objectType.certificate,
					uiSettings: {
						enableSearchForSelectedInputs: true,
						allowDiscardingForm: false,
						enableFileDrop: true
					},
					form: {
						customSpecification: {
							form: [
								{
									type: "section",
									htmlClass: "inline-upload-field",
									items: [
										{
											key: "fileUpload",
											useSimpleForm: true
										}
									],
								},
								{
									type: "section",
									htmlClass: "flex-column",
									items: [
										{
											type: "section",
											items: [
												"title",
												"validFromDate",
												"validUntilDate",
											]
										},
										{
											key: "description"
										}
									]
								}
							]
						}
					}
				},
				{
					type: enums.objectType.location,
					form: {
						model: {
							mapInPopover: true
						}
					}
				},
				{
					type: enums.objectType.statement,
				},
				{
					type: enums.objectType.embed,
				},
				{
					type: enums.objectType.link,
					form: {
						customSpecification: {
							form: [
								{
									type: "section",
									htmlClass: "flex-column",
									items: [
										{
											type: "section",
											htmlClass: "",
											items: [
												"title",
												"url"
											],
										},
										"description"
									]
								}
							]
						}
					}
				},
				{
					type: enums.objectType.orgActivity,
					uiSettings: {
						enableSearchForSelectedInputs: true,
						allowDiscardingForm: false,
						enableFileDrop: true
					},
					form: {
						customSpecification: {
							form: [
								{
									type: "section",
									htmlClass: "inline-upload-field",
									items: [
										{
											key: "imageUpload",
											useSimpleForm: true
										}
									],
								},
								{
									type: "section",
									htmlClass: "flex-column",
									items: [
										{
											type: "section",
											items: [
												"title",
												"whenDate",
												"untilDate"
											]
										},
										"description"
									]
								}
							]
						}
					}
				},
				{
					type: enums.objectType.person,
					form: {
						customSpecification: {
							form: [
								{
									type: "section",
									htmlClass: "flex-column",
									items: [
										{
											type: "section",
											htmlClass: "",
											items: [
												"given_name",
												"family_name",
												"position"
											],
										},
										{
											type: "section",
											htmlClass: "",
											items: [
												"email",
												"phone_number"
											]
										}
									]
								}
							]
						}
					}
				},
				{
					type: enums.objectType.organization,
					enablePicker: false,
					form: {
						replaceFormSpecification: true,
						customSpecification: getOrganizationFormSpecification, // defined inside initializeForm function (it needs the formControl for the validators)
						onBeforeSubmitTriggered: function (event, form) {
							return defineOrganizationOnBeforeSubmitFunction(event, form);
						}
					}
				},
				{
					type: enums.objectType.dateItem,
					enablePicker: false
				},
				{
					type: enums.objectType.country,
					enableForm: false
				}
			];

			return _.keyBy(configurations, "type");
		}

		function defineAnswerModules(itemComposite, ticket, pickerSettings) {
			// This function defines different objectTypes to attach based on the pickerSettings
			// Also it determines if a specific type should display the form or the picker (or both)
			var
				answerModules = [],
				primaryAnswerModules = [],
				secondaryAnswerModules = [],

				answerModuleToExpose = undefined,
				typeSpecificAnswerModuleConfig = undefined,
				answerObjectType = getAnswerableObjectTypeFromItemComposite(itemComposite)
			;

			if (answerObjectType) {
				typeSpecificAnswerModuleConfig = getAnswerModuleConfiguration(answerObjectType);

				if (typeSpecificAnswerModuleConfig) {
					answerModuleToExpose = _.defaultsDeep({
						itemComposite,
						ticket,
						primaryAnswerModule: true,
						label: wfTranslate.instant('MAP_ObjectType', { type: typeSpecificAnswerModuleConfig.type }),
						enableForm: true,
						isQuestionOrMeasureAnswer: true
					}, typeSpecificAnswerModuleConfig, answerModulePrototype);

					removeFormOrPickerConfigIfDisabled(answerModuleToExpose);
					answerModules.push(answerModuleToExpose);
				}
				checkPickerSettingsAndGetAnswerModule({ secondaryAnswerModule: true });
			}
			else {
				checkPickerSettingsAndGetAnswerModule({ primaryAnswerModule: true });

				let subItemsPickerSettings = _.get(itemComposite.content, "conditions.subItemsSettings.pickerSettings");

				if (subItemsPickerSettings) {
					pickerSettings = dataQuery.getPickerSettings(itemComposite, ticket, null, true); // fromSubItemsSettingsCondition = true
					checkPickerSettingsAndGetAnswerModule({ secondaryAnswerModule: true });
				}
			}

			function checkPickerSettingsAndGetAnswerModule(answerModuleValues) {
				if (pickerSettings) {
					if (pickerSettings.sourceItem) {
						answerModuleToExpose = _.defaultsDeep({}, answerModuleValues, {
							itemComposite,
							ticket,
							type: enums.objectType.structure,
							label: "List", // Should be title of the sourceItem structure
							enablePicker: true,
							picker: { visible: true, pickerSettings },
							enableForm: false
						}, answerModulePrototype);

						removeFormOrPickerConfigIfDisabled(answerModuleToExpose);
						answerModules.push(answerModuleToExpose);
					}
					else if (pickerSettings.objectTypes) {
						_.each(pickerSettings.objectTypes, function(objType) {
							answerModuleToExpose = undefined;
							typeSpecificAnswerModuleConfig = getAnswerModuleConfiguration(objType);

							if (typeSpecificAnswerModuleConfig) {
								answerModuleToExpose = _.defaultsDeep({}, answerModuleValues, {
									itemComposite,
									ticket,
									label: wfTranslate.instant('MAP_ObjectType', { type: typeSpecificAnswerModuleConfig.type }),
									icon: propertyExtractor.iconCollection[typeSpecificAnswerModuleConfig.type],
									picker: { pickerSettings }
								}, typeSpecificAnswerModuleConfig, answerModulePrototype);

								let customSettingsForObjectType = _.find(pickerSettings.customSettingsForObjectTypes, { type: objType });
								if (customSettingsForObjectType) {
									customSettingsForObjectType = _.cloneDeep(customSettingsForObjectType);
									_.assign(answerModuleToExpose, _.defaultsDeep(customSettingsForObjectType, answerModuleToExpose));
								}

								if (itemComposite.type === enums.objectType.questionAnswer || itemComposite.type === enums.objectType.measureAnswer) {
									if (objType === enums.objectType.certificate || objType === enums.objectType.country)
										answerModuleToExpose.enablePicker = true;
									else
										answerModuleToExpose.enablePicker = false;
								}

								removeFormOrPickerConfigIfDisabled(answerModuleToExpose);
								answerModules.push(answerModuleToExpose);
							}
							else
								console.log("------> Answerable ObjectType Configuration not defined - ", objType);
						});
					}
				}
			}

			primaryAnswerModules = _.filter(answerModules, "primaryAnswerModule");
			if (primaryAnswerModules.length > 0)
				secondaryAnswerModules = _.filter(answerModules, "secondaryAnswerModule");
			else
				primaryAnswerModules = answerModules;

			return { primaryAnswerModules: primaryAnswerModules, secondaryAnswerModules: secondaryAnswerModules };
		}

		function removeFormOrPickerConfigIfDisabled(answerModule) {
			if (!answerModule.enableForm)
				answerModule.form = undefined;
			else
				answerModule.form.visible = true;

			if (!answerModule.enablePicker)
				answerModule.picker = undefined;
			else if (!answerModule.enableForm)
				answerModule.picker.visible = true;
		}

		function getAnswerableObjectTypeFromItemComposite(itemComposite) {
			var answerObjectType = undefined;

			switch (itemComposite.type) {
				case enums.objectType.measure:
					answerObjectType = enums.objectType.measureAnswer;
					break;
				case enums.objectType.question:
					answerObjectType = enums.objectType.questionAnswer;
					break;
				case enums.objectType.parameter:
					console.error("Parameter objectType is not supported yet")
					break;
				default:
					break;
			}

			return answerObjectType;
		}

		function prototype_initialize(element) {
			const answerModule = this;
			const itemComposite = answerModule.itemComposite
			const ticket = answerModule.ticket

			return $q((resolve, reject) => {
				var innerPromise;

				// console.log("Initializing answer module - ", answerModule, itemComposite);

				answerModule.loading = true;

				if (answerModule.enableForm && answerModule.form) {
					if (typeof answerModule.form.customSpecification === "function")
						answerModule.form.customSpecification = answerModule.form.customSpecification(answerModule.form);

					if (answerModule.type === enums.objectType.questionAnswer) {
						answerModule.form.answerButtonsHtml = getQuestionAnswerButtons(itemComposite, ticket);
					}

					if (answerModule.type === enums.objectType.measureAnswer) {
						// var measureCreatorOptions = {
						// 	additionalPropertiesForInitialValues: { year: initialValues.year },
						// 	influence: influence,
						// 	networkId: networkId,
						// 	contextParents: contextParentWfids,
						// 	intersectionSettings: intersectionSettings,
						// 	showAttachInformation: requirement && (requirement.rule === enums.requirementRule.anyValueWithRelatedContent || requirement.rule === enums.requirementRule.inPeriodWithRelatedContent)
						// };
						innerPromise = getMeasureAnswerFormSpec(itemComposite, ticket, answerModule.form).then(function(formSpec) {
							setMeasureAnswerInitialModelValues(answerModule);
							answerModule.form.customSpecification = formSpec;

							// Added because the form spec is needed in wfDropdown and the answerModule instance is cloned somewhere so the form reference is lost
							// but the itemComposite reference is the same.
							answerModule.itemComposite.primaryAnswerModuleForm = answerModule.form.customSpecification;
						});
					}
				}

				if (answerModule.enablePicker && answerModule.picker) {

				}

				$q.all([ innerPromise ].filter(Boolean)).then(function () {
					answerModule.loaded = true;
					answerModule.loading = false;
					resolve();
				});
			});

			function getQuestionAnswerButtons() {
				var
					answerButtonsHtml = "",
					availableAnswerTypeIds,
					interpolateFunc,
					settings = _.get(itemComposite, "dataRelation.settings");
				;

				const
					authOrganizationId = wfAuth.getOrganizationId()
				;

				if (settings && settings.limitQuestionAnswerTypes)
					availableAnswerTypeIds = settings.limitQuestionAnswerTypes;

				if (authOrganizationId === ticket.organizationId || authOrganizationId === ticket.thirdPartyOrganizationId) {
					interpolateFunc = $interpolate('<div class="_{{answerTypeId}} btn btn-default" data-id="{{answerTypeId}}">{{answerTypeText}}</div>')

					if (!availableAnswerTypeIds || !availableAnswerTypeIds.length)
						availableAnswerTypeIds = [ 4, 3, 2, 1 ] // Use default (Processing, Not relevant, No, Yes)

					initializeClickEvents();
					for (var i = 0, len = availableAnswerTypeIds.length, answerTypeId; i < len; i++) {
						answerTypeId = availableAnswerTypeIds[i];

						answerButtonsHtml += interpolateFunc({
							answerTypeId: answerTypeId,
							answerTypeText: propertyExtractor.getQuestionAnswerTypeText(answerTypeId)
						})
					}
				}

				function initializeClickEvents() {
					var busy;

					element.on('click', 'div.btn', function() {
						var
							btn = $(this),
							questionAnswerTypeId = btn.data().id,
							allButtons
						;

						if (!allButtons)
							allButtons = btn.siblings().andSelf();

						if (busy || btn.hasClass('selected')) return;
						busy = true;

						btn.addClass("loading");
						// element.find("div.invisible-answer-available").hide(); // COME BACK TO THIS WHEN IMPLEMENTING ANSWER FEED
						dataOps.createAnswerOnQuestion({ id: questionAnswerTypeId }, itemComposite.content, {
							ticket: ticket
							// useContextAwareRelations: useContextAwareRelations // COME BACK TO THIS!
						}).then(function(relation) {
							busy = false;
							relation.createdAt = moment().format();
							btn.removeClass("loading");

							itemComposite.addSubItem(relation);
							answerModule.maybeSyncPickerItems();
							itemComposite.syncFulfillment();

							// UPDATE THE ANSWER FEED
						});
					});
				}

				return $sce.trustAsHtml(answerButtonsHtml);
			}

			function getMeasureAnswerFormSpec(itemComposite, ticket, form) {
				return wfMeasureService.getFullMeasureAnswerFormSpecification(itemComposite.content, itemComposite.dataRelation, form, { getLatestValueInPeriod: getLatestMeasureAnswerInPeriodFunc(answerModule, itemComposite) })
			}

		}

		function getLatestMeasureAnswerInPeriodFunc(answerModule, itemComposite) {
			return (periodIndexString) => {
				return $q(resolve => {
					itemComposite.getSubItems().then((subItems) => {
						const previousPeriodRange = answerModule.form.periodRangesByIndex[parseInt(periodIndexString) + 1];
						if (previousPeriodRange) {
							const itemsInPeriod = subItems.filter(x => x.content.period === previousPeriodRange.period);
							const latestItemInPreviousPeriod = _.orderBy(itemsInPeriod, "createdAt")[itemsInPeriod.length - 1]
							if (latestItemInPreviousPeriod && latestItemInPreviousPeriod.content && latestItemInPreviousPeriod.content.value) {
								const latestValueInPreviousPeriod = parseFloat(latestItemInPreviousPeriod.content.value);
								const latestBaseUnitFactorInPreviousPeriod = latestItemInPreviousPeriod.content.childContent.baseUnitFactor
								const latestValueUnitInPreviousPeriod = latestItemInPreviousPeriod.content.childContent
								resolve({ value: latestValueInPreviousPeriod, baseUnitFactor: latestBaseUnitFactorInPreviousPeriod, unit: latestValueUnitInPreviousPeriod });
								return;
							}
						}
						resolve({})
					});
				})
			}
		}

		function setMeasureAnswerInitialModelValues(answerModule) {
			const itemComposite = answerModule.itemComposite;
			var
				lockedPeriod = _.get(itemComposite.dataRelation, "settings.measurePeriodSettings.lockPeriod"),
				showUnitSelector = _.get(itemComposite.dataRelation, "settings.measurePeriodSettings.showUnitSelector"),
				requiredPeriod = _.get(itemComposite.fulfillmentResult, "requiredMeasurePeriod"),
				itemWithRequiredPeriod = requiredPeriod && _.find(itemComposite.subItems, x => x.content.period === requiredPeriod.start && x.content.periodEnd === requiredPeriod.end),
				itemWithMaxPeriod = _.maxBy(itemComposite.subItems, x => x.content.period_epoch),
				maxPeriod = itemWithMaxPeriod && itemWithMaxPeriod.content.period,
				unitId = itemWithMaxPeriod && itemWithMaxPeriod.content.unitId
			;
			answerModule.maxMeasureAnswerPeriod = maxPeriod;

			const getLatestValueInPeriod = getLatestMeasureAnswerInPeriodFunc(answerModule, itemComposite)

			// console.log(itemComposite.subItems, itemWithMaxPeriod, maxPeriod)

			var periodIndex = null;

			if (lockedPeriod) {
				const startDate = lockedPeriod.split('|')[0]
				periodIndex = _.findKey(answerModule.form.periodRangesByIndex, { period: startDate });
			}
			else if (!itemWithRequiredPeriod && requiredPeriod) {
				periodIndex = _.findKey(answerModule.form.periodRangesByIndex, { period: requiredPeriod.start });
			}
			else {
				if (maxPeriod) {
					periodIndex = _.findKey(answerModule.form.periodRangesByIndex, { period: maxPeriod });
					if (periodIndex && parseInt(periodIndex) > 0) {
						periodIndex = (parseInt(periodIndex) - 1).toString();
					}
				}
			}

			getLatestValueInPeriod(periodIndex).then(({ value, baseUnitFactor, unit }) => {
				$rootScope.$broadcast('periodChanged_' + answerModule.form.model.measureAnswerFormId, value, baseUnitFactor, unit);
				answerModule.form.model.latestValueInPreviousPeriod = value;
				answerModule.form.model.latestBaseUnitFactorInPreviousPeriod = baseUnitFactor;
				answerModule.form.model.latestValueUnitInPreviousPeriod = unit;
			});

			_.assign(answerModule.form.model, {
				periodSelection: periodIndex
			});

			if (showUnitSelector) {
				if (unitId) {
					_.assign(answerModule.form.model, {
						unitId: unitId
					});
				}
			}
		}

		function prototype_submitForm() {
			const answerModule = this;
			const {
				type: objectType,
				itemComposite,
				ticket,
			} = answerModule;

			answerModule.saving = true;

			const formControl = _.get(answerModule, "form.formControl");
			const form = _.get(answerModule, "form");
			const relationTarget = _.get(answerModule, "picker.pickerSettings.relationTarget");

			let relationTargetItem = relationTarget && relationTarget.item || itemComposite.content;
			const relationTargetKind = relationTarget && relationTarget.kind || itemComposite.subItemsKind;

			return $q((resolve, reject) => {
				validateAndSubmit().then(result => {
					if (result) {// Valid
						formControl.reset();
						if (answerModule.type === enums.objectType.measureAnswer) {
							setMeasureAnswerInitialModelValues(answerModule);
						}
						answerModule.formCompiler.compile();
						resolve();
					}
					else { // Not valid
						resolve();
					}
				})
			});

			function validateAndSubmit() {
				return formControl.submit(function (model, deferred) {
					// Function that runs if the form validates


					// if (objectType === enums.objectType.finding) {

					// 	model.contextParentWfid = _.get(ticket, "contextParents");

					// 	if (vm.influence
					// 		&& vm.influence.contextParentWfids
					// 		&& vm.influence.organizationId === authOrgId
					// 		&& vm.influence.contextParentWfids.indexOf("101-") === 0
					// 	) {
					// 		model.targetOrganizationId = parseInt(vm.influence.contextParentWfids.split("-")[1]);
					// 		model.contextParentWfid = null;
					// 	}
					// 	else if (ticket) {
					// 		model.targetOrganizationId = vm.intersectionSettings.organizationId;
					// 	}
					// 	else {
					// 		model.targetOrganizationId = authOrganizationId
					// 	}

					// 	model.networkId = _.get(ticket, "networkId");
					// }

					if (form && form.onBeforeSubmitTriggered) {
						form.onBeforeSubmitTriggered({
							getModel: function () {
								return model;
							},
							closeModal: function () {
							},
							setResultAndFinalize: function (result) {
								finializeSubmit(result);
								formControl.reset();
								answerModule.formCompiler.compile();
							},
							continueSubmit: function () {
								createAndFinalizeSubmit(model);
							},
							setModel: function (newModel) {
								model = newModel;
							},
							cancelSubmit: function () {
								deferred.resolve(false);
							}
						}, answerModule.form)
					}
					else {
						createAndFinalizeSubmit();
					}

					function createAndFinalizeSubmit() {
						const isRelationByUser = _.includes([
							enums.subItemsKind.relatedContentByUser,
							enums.subItemsKind.childrenByUser,
							enums.subItemsKind.parentsByUser,
							enums.subItemsKind.verifications,
							enums.subItemsKind.verifies
						], relationTargetKind);

						model.type = objectType;

						if (model.type === enums.objectType.measureAnswer) {
							delete model.latestValueInPreviousPeriod;
							delete model.latestBaseUnitFactorInPreviousPeriod;
							delete model.latestValueUnitInPreviousPeriod;
						}

						dataOps.create(model, {
							byUser: isRelationByUser,
							// influence: vm.influence,
							networkId: _.get(ticket, "networkId"),
							thirdParty: _.get(ticket, "thirdParty"),
						}).then(function (newlyCreatedItem) {
							finializeSubmit(newlyCreatedItem);
						});
					}

					function finializeSubmit(newlyCreatedItem) {
						var promises = [];

						pickerService.injectInStore({
							newlyCreatedItem: newlyCreatedItem,
							typeStructureId: _.get(answerModule.picker, "instance.structureId"),
							useItemComposites: _.get(answerModule.picker, "instance.useItemComposites"),
							addToStructureAsDataRelation: _.get(answerModule.picker, "instance.addToStructureAsDataRelation") // Used for types productionSite, productService, supplier (sub supplier) and holding (portfolio company)
						}).then(function () { // dataRelation can be both a normal dataRelation, virtual dataRelation or itemComposite
							promises.push(dataOps.createSubItemRelation(relationTargetItem, newlyCreatedItem, {
								kind: relationTargetKind,
								networkId: _.get(ticket, "networkId"),
								contextParentWfid: _.get(ticket, "contextParentWfid"), // CHECK BACKEND AND SPELLING
								thirdParty: _.get(ticket, "thirdParty"),
							}).then(function (relation) {
								itemComposite.addSubItem(relation);
							}));

							$q.all(promises).then(function () {
								answerModule.maybeSyncPickerItems();

								if (answerModule.secondaryAnswerModule && answerModule.wfWorkItem) {
									answerModule.wfWorkItem.itemComposite.syncFulfillment();
								}
								else {
									itemComposite.syncFulfillment();
								}

								answerModule.saving = false;
								deferred.resolve(newlyCreatedItem)
							});
						});
					}
				});

			}

		}

		function getDropdownActionSpecifications() {
			const attachWord = $translate.instant("Attach");
			var specifications = [
				{ actionName: "inlineAttachMultiPicker", type: undefined, text: attachWord, icon: 'fas fa-paperclip', action: undefined },

				{ actionName: "attachActivity", type: enums.objectType.orgActivity, icon: 'fa fa-calendar-check-o', action: undefined }, // actions are defined inside wfAnsweringFeedItem.component.js
				{ actionName: "attachDocument", type: enums.objectType.orgDocument, icon: 'fa fa-file', action: undefined },
				{ actionName: "attachStatement", type: enums.objectType.statement, icon: 'fa fa-quote-right', action: undefined },
				{ actionName: "attachLink", type: enums.objectType.link, icon: 'fa fa-link', action: undefined },
				{ actionName: "attachMedia", type: enums.objectType.embed, icon: 'fa fa-play-circle', action: undefined },
				{ actionName: "attachCertificate", type: enums.objectType.certificate, icon: 'fa fa-certificate', action: undefined },
				{ actionName: "attachPerson", type: enums.objectType.person, icon: 'fa fa-user-plus', action: undefined },
				{ actionName: "attachOrganization", type: enums.objectType.organization, icon: 'fa fa-building', action: undefined },
				{ actionName: "attachLocation", type: enums.objectType.location, icon: 'fas fa-map-marker-alt', action: undefined },
				{ actionName: "attachCountry", type: enums.objectType.country, icon: 'fas fa-flag', action: undefined }
			];

			specifications.forEach(x => {
				if (x.type) {
					x.text = attachWord + " " + wfTranslate.instant('MAP_ObjectType', { type: x.type }).toLowerCase();
				}
			});

			return _.mapKeys(_.cloneDeep(specifications), function(value) { return value.actionName });
		}

		// ----------------- TYPE SPECIFIC FUNCTIONS ---------------------

		function getOrganizationFormSpecification(form) {
			var
				formSpec = {
					schema: {},
					form: [],
					instantVatOrRegNumberValidation: false,
				},
				isVatOrRegNumberValid = false,

				regNumberValid = false,
				vatNumberValid = false
			;

			formSpec.form = [
				{
					type: "section",
					htmlClass: "flex-column",
					items: [
						"name",
						{
							type: "section",
							items: [
								"registrationNumber",
								"vatNumber",
								"gln"
							]
						}
					]
				}
			];

			formSpec.schema = {
				"type": "object",
				"properties": {
					"name": {
						"title": $translate.instant("OrgName"),
						"type": "string",
						"x-schema-form": {}
					},
					"registrationNumber": {
						"title": $translate.instant("RegistrationNumber"),
						"type": "string",
						"x-schema-form": {
							validationMessage: {
								vatOrRegNumber: $translate.instant("validationMessages.vatRegOrGlnNumber")
							},
							$validators: {
								vatOrRegNumber: function (value) {
									if (!formSpec.instantVatOrRegNumberValidation) return true;

									var result = !!(vatNumberValid || value)
									regNumberValid = !!value;

									if (isVatOrRegNumberValid !== result) {
										isVatOrRegNumberValid = result;
										form.formControl.$scope.$broadcast('schemaForm.error.vatNumber', 'vatOrRegNumber', result);
									}

									return result;
								}
							}
						}
					},
					"vatNumber": {
						"title": $translate.instant("VATNumber"),
						"type": "string",
						"x-schema-form": {
							validationMessage: {
								vatOrRegNumber: $translate.instant("validationMessages.vatRegOrGlnNumber")
							},
							$validators: {
								vatOrRegNumber: function (value) {
									if (!formSpec.instantVatOrRegNumberValidation) return true;

									var result = !!(regNumberValid || value)
									vatNumberValid = !!value;

									if (isVatOrRegNumberValid !== result) {
										isVatOrRegNumberValid = result;
										form.formControl.$scope.$broadcast('schemaForm.error.registrationNumber', 'vatOrRegNumber', result);
									}

									return result;
								}
							}
						}
					},
					"gln": {
						"title": $translate.instant("GLNNumber"),
						"type": "string"
					}
				},
				"required": [ "name" ]
			}

			return formSpec;
		}

		function defineOrganizationOnBeforeSubmitFunction(event, form) {
			var
				orgModel = form.formControl.getModel(),
				formControl = form.formControl
			;

			orgModel.type == 101;

			event.setModel(orgModel);
			// formControl.setModel(orgModel);
			// isValid = formControl.isValid();

			if (!orgModel.registrationNumber && !orgModel.vatNumber) {
				form.customSpecification.instantVatOrRegNumberValidation = true;
				formControl.$scope.$broadcast('schemaForm.error.registrationNumber', 'vatOrRegNumber', false);
				formControl.$scope.$broadcast('schemaForm.error.vatNumber', 'vatOrRegNumber', false);
				event.cancelSubmit();
				return;
			}
			else {
				// If the organization is not already in the Value Chain then
				// check with server if it already exists in the database (using registration number)
				apiProxy("utility.getOrganizationByCondition", {
					registrationNumber: orgModel.registrationNumber,
					vatNumber: orgModel.vatNumber
				}).then(function (orgs) {
					var org;

					if (!orgs.length) {
						event.continueSubmit();
					}
					else {
						if (orgs.length === 0) {
							org = orgs[0];
							$ngBootbox.customDialog({
								title: $translate.instant('modules.valueChain.organizations.alreadyExists.modalTitle'),
								message: $translate.instant('modules.valueChain.organizations.alreadyExists.modalMessage', {
									orgname: org.name,
									orgnumber: org.registrationNumber
								}),
								onEscape: true,
								className: "valueChain-modal-orgAlreadyExists",
								buttons: {
									cancel: {
										label: $translate.instant('No'),
										className: "btn-default",
										callback: function() {
											event.cancelSubmit();
										}
									},
									primary: {
										label: $translate.instant('Yes'),
										className: "btn-primary",
										callback: function() {
											wfObject.inject(org);
											org = wfObject.get(org.wfid);
											event.setResultAndCloseModal(org);
										}
									}
								}
							});
						}
						else {
							var sourceList = [];

							sourceList = _.map(orgs, function (org) {
								return {
									data: org,
									wfid: org.wfid
								};
							});

							modal.openCreatorAndPicker({
								title: $translate.instant('modules.valueChain.organizations.multipleAlreadyExists.modalTitle'),
								description: $translate.instant('modules.valueChain.organizations.multipleAlreadyExists.modalMessage'),
								singlePick: true,
								relationBucket: { preSelected: [], allSelected: [] },
								sourceList: sourceList,
								buttons: [
									{
										label: "OK",
										callback: function ($scope, relationBucketResult) {
											org = relationBucketResult.allSelected[0];
											if (org) {
												wfObject.inject(org);
												org = wfObject.get(org.wfid);
												event.setResultAndFinalize(org);
											}
											$scope.$close();
										}
									}
								]
							}).closed(function (relationBucketResult) {
								event.cancelSubmit();
							});
						}
					}
				});
			}
		}

		function prototype_maybeSyncPickerItems() {
			var self = this;
			if (self.picker && self.picker.instance) {
				self.picker.instance.syncItems();
			}
		}

		function prototype_clone() {
			var self = this;
			var output = _.cloneDeep(self);

			output.itemComposite = self.itemComposite;
			output.ticket = self.ticket;

			return output;
		}
	}

})();
