import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';

	angular
		.module('wf.common')
		.component('wfAnsweringFeed', {
			templateUrl: 'scripts/wf/workItem/wfAnsweringFeed.component.html',
			controller: wfAnsweringFeedController,
			controllerAs: 'vm',
			require: {
				"wfWorkItem": "?^^wfWorkItem",
			},
			bindings: {
				itemComposite: '=', // REQUIRED
				ticket: '=', // REQUIRED
			}
		});

	wfAnsweringFeedController.$inject = [ '$scope', 'wfAuth', 'dataQuery', '$q', '$element', '$timeout', '$translate' ];
	function wfAnsweringFeedController($scope, wfAuth, dataQuery, $q, $element, $timeout, $translate) {
		var
			vm = this,
			onFulfillmentSyncedListener
		;

		_.assign(vm, {
			hasSubItems: false,
			loaded: false,
			allSubItems: [],
			subItemsToExpose: [],
			listExpanded: false,
			toggleText: $translate.instant("modules.report.showPreviousAnswers"),
			minItemsLimit: 2,
			subItemsCount: undefined,
			
			// Functions
			toggleItemsVisibility: toggleItemsVisibility,
			onItemDeleted: onItemDeleted,
			onItemUpdated: onItemUpdated
		});

		vm.$onInit = function() {
			if (!vm.itemComposite || !vm.itemComposite.composite) {
				console.error("itemComposite not passed or defined");
				return;
			}
			vm.itemComposite.getSubItems().then(subItems => {
				vm.allSubItems = subItems;
				vm.subItemsCount = vm.allSubItems.length;

				// --------- MOCKED -----------
				// vm.itemComposite.fulfillmentResult = {
				// 	attachmentsNeeded: true,
				// 	latestAnswerContent: subItems.slice(0, 1)[0].content
				// }

				vm.loaded = true;
			});

			vm.dropdownActions = vm.wfWorkItem.uiMode === enums.uiMode.work || vm.wfWorkItem.uiMode === enums.uiMode.admin ? [ 'userData' ] : undefined;


			vm.watcher = $scope.$watchCollection("vm.allSubItems", function () {
				if (vm.allSubItems.length > vm.subItemsCount) {
					$scope.$broadcast("closeAttachers");
				}
				vm.subItemsCount = vm.allSubItems.length;

				if (!vm.listExpanded)
					vm.subItemsToExpose = _.take(vm.allSubItems, vm.minItemsLimit);

				checkIfItemsNeedingAttachmentIsHidden();
			});

			onFulfillmentSyncedListener = vm.itemComposite.onFulfillmentSynced(function () {
				checkIfItemsNeedingAttachmentIsHidden();
			})
		};

		vm.$onDestroy = function() {
			vm.watcher();
			onFulfillmentSyncedListener(); // Removes listener
		};

		function toggleItemsVisibility() {
			if (!vm.listExpanded) {
				vm.subItemsToExpose = vm.allSubItems;
				vm.toggleText = $translate.instant("modules.report.hidePreviousAnswers");
			}
			else {
				vm.wfWorkItem.scrollToTopOfFeed();
				vm.subItemsToExpose = _.take(vm.allSubItems, vm.minItemsLimit);
				vm.toggleText = $translate.instant("modules.report.showPreviousAnswers");
			}

			vm.listExpanded = !vm.listExpanded
			checkIfItemsNeedingAttachmentIsHidden();
		
			$timeout();
		}

		function checkIfItemsNeedingAttachmentIsHidden() {
			if (vm.subItemsToExpose.length < vm.subItemsCount) {
				let fulfillmentResult = vm.itemComposite.fulfillmentResult;

				if (fulfillmentResult) {
					let areAttachmentsNeeded = fulfillmentResult.attachmentsNeeded;
					let latestAnswerContent = fulfillmentResult.latestAnswerContent;
	
					if (areAttachmentsNeeded && latestAnswerContent) {
						if (!(latestAnswerContent instanceof Array)) {
							latestAnswerContent = [ latestAnswerContent ]
						}

						vm.allSubItems.forEach(itemComposite => {
							if ((latestAnswerContent instanceof Array && latestAnswerContent.find(x => x.wfid === itemComposite.wfid)) || latestAnswerContent.wfid === itemComposite.wfid) {
								if (!vm.subItemsToExpose.find(x => x.wfid === itemComposite.wfid)) {
									vm.subItemsToExpose.push(itemComposite);
								}
							}
						});
					}
				}
			}
		}

		function onItemDeleted(relation) {
			if (vm.wfWorkItem) {
				vm.wfWorkItem.itemComposite.removeSubItem(relation);
				vm.wfWorkItem.itemComposite.syncFulfillment().then(function () {
					// $scope.$broadcast("checkRequirement");
				});

				if (vm.itemComposite.currentAnswerModule)
					vm.itemComposite.currentAnswerModule.maybeSyncPickerItems();
			}
		}

		function onItemUpdated(relation) {
			if (vm.wfWorkItem) {
				vm.wfWorkItem.itemComposite.onDataReported();
				vm.wfWorkItem.itemComposite.syncFulfillment();

				if (vm.itemComposite.currentAnswerModule)
					vm.itemComposite.currentAnswerModule.maybeSyncPickerItems();
				
			}
			
		}
	}
})();
