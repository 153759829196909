import * as enums from '@worldfavor/constants/enums'
import { formatDuration, sortNaturally } from '@worldfavor/utils/helpers'

(function () {
	'use strict';

	angular
		.module('wf.common')
		.controller('ValueChainAnalyzePackagesController', ValueChainAnalyzePackagesController);

	ValueChainAnalyzePackagesController.$inject = ['$scope', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', 'formSchemaService', '$q', 'wfTranslate', '$timeout', '$stateParams', '$rootScope', 'apiProxy', 'wfAuth', 'wfItemToolsService', '$window', 'valueChainService'];
	function ValueChainAnalyzePackagesController($scope, dataOps, modal, wfObject, dataQuery, $translate, formSchemaService, $q, wfTranslate, $timeout, $stateParams, $rootScope, apiProxy, wfAuth, wfItemToolsService, $window, valueChainService) {
		var
			vm = this,
			authOrgId = wfAuth.getOrganizationId(),
			authSolutionType = wfAuth.getSolution(),
			permissions
		;

		_.assign(vm, {
			window: $window,
			loaded: false,
			network: null,
			mainStructure: null,
			showCreateButton: true,
			showStandardPackagesButton: [ "Sustainability Management", "Sustainable Investments", "Sustainable Sourcing" ].includes(authSolutionType) && wfAuth.isAdmin(),
			showDropdown: true,
			canUpdate: true,
			dropdownActions: "update,delete", // This are the default actions but will be based on permissions on mainStructure
			simplifyForm: [ "title", "description", "imageUpload" ],
			customFormSpecification: {
				form: [
					"title",
					"description",
					"imageUpload"
				]
			},
			showBatchCalcFunctions: $.proxies.baseUrl === "https://api1.worldfavor.com/" || document.location.hostname === "local.worldfavor.com",
			isCalculationScheduleDialogOpen: false,
			calculationScheduleDays: {},
			calculationSchedulePackageId: null,

			// Arrays
			items: [], // Intersected relations from mainStructure and network
			packageDataRelations: [], // All relations. Packages always belongs to both mainStructure and network
			packageIdsWithActiveJobs: [],
			packageIdsWithNewlyStartedJobs: [],

			// Lookup objects
			dataRelationsByPackageIds: {}, // All relations by childId. Packages always belongs to both mainStructure and network

			// Functions
			createPackage: createPackage,
			recalculateAnalyzeJobs: recalculateAnalyzeJobs,
			abortJobInvocation: abortJobInvocation,
			getActiveOrLatestJob: getActiveOrLatestJob,
			openAnalyzeJobsCreatorModal: openAnalyzeJobsCreatorModal,
			openAnalyzeJobsDeleterModal: openAnalyzeJobsDeleterModal,
			openStandardPackagesPicker: openStandardPackagesPicker,
			isItemOwned: isItemOwned,
			openCalculationScheduleDialog: openCalculationScheduleDialog,
			closeCalculationScheduleDialog: closeCalculationScheduleDialog,
			onSaveCalculationSchedule: onSaveCalculationSchedule,
		});

		activate();

		////////////////

		function activate() {
			$scope.$on("itemToolsActionExecuted", function ($event, operation, item, dataRelation) {
				var relationToDestroy;
				if (operation === "delete") {
					if (_.remove(vm.items, { wfid: item.wfid }).length || _.remove(vm.items, { wfid: dataRelation.wfid }).length) {

						// The first delete operation only deletes the structure, so the relations are deleted now (both from JSData and the relations array).
						relationToDestroy = _.remove(vm.packageDataRelations, { wfcid: dataRelation.wfcid, parentType: enums.objectType.structure })[0];
						if (relationToDestroy) dataOps.destroy(relationToDestroy);

						relationToDestroy = _.remove(vm.packageDataRelations, { wfcid: dataRelation.wfcid, parentType: enums.objectType.network })[0];
						if (relationToDestroy) dataOps.destroy(relationToDestroy);

						$timeout();
					}
				}
			});

			valueChainService.loadNetworks().then(function (result) {
				var
					network = result.networksById[$stateParams.networkId],
					mainStructure = result.structure
					;

				if (!network) {
					$state.go("valueChain-root");
					return;
				}

				vm.network = network;

				dataOps.getObject({
					objectType: network.type,
					objectId: network.id,
					childrenLoadDepth: 1,
					getterConditions: {
						objectTypes: [enums.objectType.structure]
					}
					// bypassCache: true
				}).then(function (obj) {
					var
						relationsInNetwork = _.filter(obj.childs, { childType: enums.objectType.structure }),
						relationsInNetworkByChildId = _.keyBy(relationsInNetwork, "childId")
					;

					dataOps.getObject({
						objectId: valueChainService.ids.analyzePackagesStructure, // Contains the current organization's data collector requirement packages
						objectType: enums.objectType.structure,
						childrenLoadDepth: 1
					}).then(function (res) {
						var dropdownActionsFromPermissions;
						vm.mainStructure = res;
						$rootScope.setPageTitle(vm.mainStructure.title);

						// Filter mainStructure childs to only get the children that are also in the network.
						vm.items = sortNaturally(_.chain(vm.mainStructure.childs).filter(function (dataRelation) {
							var networkDataRelation = relationsInNetworkByChildId[dataRelation.childId];
							if (networkDataRelation) {
								// Add to utility arrays
								vm.packageDataRelations.push(networkDataRelation);
								vm.packageDataRelations.push(dataRelation);

								return true;
							}
						}).value(), "childContent.title");

						// Check permissions
						permissions = vm.mainStructure.permissions
						if (permissions) {
							vm.showCreateButton = permissions.canCreate;

							dropdownActionsFromPermissions = [];

							vm.canUpdate = permissions.canUpdate;

							if (!permissions.canUpdate && !permissions.canDelete)
								vm.showDropdown = false;
							else {
								if (permissions.canUpdate) {
									dropdownActionsFromPermissions.push("update");
								}
								if (permissions.canDelete)
									dropdownActionsFromPermissions.push("delete");

								vm.dropdownActions = dropdownActionsFromPermissions.join(",");
							}
						}

						// Create utility object for easy lookup of relations by structure id
						vm.dataRelationsByPackageIds = _.groupBy(vm.packageDataRelations, "childId");
						vm.loaded = true;

						valueChainService.getAnalyzePackagesStatistics({
							networkId: vm.network.id,
							objectIds: _.map(vm.items, "childId"),
							objectType: enums.objectType.structure,
						}).then(function (res) {
							/* res = {
							/* res = {
								"71-123": {
									objectType: 71,
									objectId: 123,
									jobInvocations: { active: [ ], previous: [] },
									analyzeJobs: {
										totalCount: 0,
										averageScore: 0
									}
								}
							}
							*/
							vm.packageStatsByWfid = res;

							_.each(vm.packageStatsByWfid, function (value, key) {
								if (_.get(value, "jobInvocations.active.length"))
									startGettingPackagesStatisticsPeriodically(value.objectId);

								handlePackageStatisticsItem(value);
							});

							$timeout();
						});
					});
				});

			});
		}

		function createPackage() {
			var jqDf = $.Deferred();
			modal.createWithRelation({
				simplifyForm: vm.simplifyForm,
				objectType: 71,
				objectTypeWord: $translate.instant("AnalyzePackage").toLowerCase(),
				customFormSpecification: vm.customFormSpecification,
				dataRelationOptions: { item1: vm.mainStructure, kind: enums.subItemsKind.childrenByUser },
				additionalDataRelations: [
					{ item1: vm.network, kind: enums.subItemsKind.childrenByUser }
				],
				submitCaption: $translate.instant("Create")
			}).then(function (dataRelation, additionalDataRelations) {
				if (dataRelation) {
					dataOps.saveSettings({
						item: dataRelation.childContent,
						settings: {
							dataRelation: null,
							templateId: 75
						}
					}).then(function () {
						vm.dataRelationsByPackageIds[dataRelation.childId] = _.concat([dataRelation], additionalDataRelations);
						vm.packageDataRelations.push(dataRelation);
						vm.packageDataRelations.push(additionalDataRelations[0]);

						vm.items.unshift(dataRelation);

						jqDf.resolve();
						$timeout();
					});
				}
				else {
					jqDf.resolve();
				}
			});

			return jqDf.promise();
		}



		function recalculateAnalyzeJobs(analyzePackage) {
			valueChainService.recalculateAnalyzeJobs({
				title: analyzePackage.title,
				networkId: vm.network.id,
				objectType: analyzePackage.type,
				objectId: analyzePackage.id
			}).then(function (res) {
				if (res) {
					if (!vm.packageStatsByWfid[analyzePackage.wfid]) {
						vm.packageStatsByWfid[analyzePackage.wfid] =  { jobInvocations: { } };
					}

					vm.packageStatsByWfid[analyzePackage.wfid].jobInvocations.activeJob = res;
					vm.packageStatsByWfid[analyzePackage.wfid].jobInvocations.activeStartDate = res.startedAt && moment(res.startedAt).format("YYYY-MM-DD HH:mm");
					vm.packageStatsByWfid[analyzePackage.wfid].jobInvocations.activePercentageProgress = 0;

					startGettingPackagesStatisticsPeriodically(analyzePackage.id)

					$timeout();
				}
			})
		}

		function abortJobInvocation(analyzePackageWfid, jobInvocation) {
			const packageStats = vm.packageStatsByWfid[analyzePackageWfid]

			return valueChainService.abortJobInvocation({ jobInvocationId: jobInvocation.id }).then(function (res) {
				packageStats.jobInvocations.activeJob = undefined;
				packageStats.jobInvocations.latestEndedJob = jobInvocation;
				packageStats.jobInvocations.latestEndedJobStartDate = moment(jobInvocation.createdAt).format("YYYY-MM-DD HH:mm")
				packageStats.jobInvocations.latestEndedJobDuration = formatDuration(jobInvocation.createdAt, new Date())
				$timeout()
			})
		}

		function handlePackageStatisticsItem(value) {
			var latestEndedJob, activeJob;

			if (value.jobInvocations.previous && value.jobInvocations.previous.length) {
				value.jobInvocations.latestEndedJob = latestEndedJob = value.jobInvocations.previous[0];
				value.jobInvocations.latestEndedJobStartDate = moment(latestEndedJob.createdAt).format("YYYY-MM-DD HH:mm")
				value.jobInvocations.latestEndedJobDuration = formatDuration(latestEndedJob.createdAt, latestEndedJob.endedAt)
			}

			if (value.jobInvocations.active && value.jobInvocations.active.length) {
				value.jobInvocations.activeJob = activeJob = value.jobInvocations.active[0];
				value.jobInvocations.activeStartDate = activeJob.startedAt && moment(activeJob.startedAt).format("YYYY-MM-DD HH:mm");
				value.jobInvocations.activePercentageProgress = activeJob.startedAt ? activeJob.totalCount === 0 ? 0 : (activeJob.progressCount + activeJob.errorsCount) / activeJob.totalCount * 100 : 1;
				// value.jobInvocations.activeJobDuration = moment.utc(moment( something  ).diff(moment(activeJob.createdAt))).format("H[h] m[m] s[s]");

			}
		}

		function startGettingPackagesStatisticsPeriodically(newPackageIdToCheck, getStatsNow) {

			if (!vm.periodicStatsCheckingStarted) {
				vm.packageIdsWithActiveJobs.push(newPackageIdToCheck);
				vm.periodicStatsCheckingStarted = true;

				if (!getStatsNow) {
					getPackagesStatisticsPeriodically();
				}
			}
			else {
				if (vm.packageStatsRequestLoading)
					vm.packageIdsWithNewlyStartedJobs.push(newPackageIdToCheck);
				else
					vm.packageIdsWithActiveJobs.push(newPackageIdToCheck);
			}

			if (getStatsNow) {
				getPackagesStatisticsNow()
			}
		}

		function getPackagesStatisticsPeriodically() {
			setTimeout(function () {
				getPackagesStatisticsNow(true)
			}, 10000);
		}

		function getPackagesStatisticsNow(queueNextCheck) {
			if ($scope.$$destroyed)
				return;

			vm.packageStatsRequestLoading = true;
			valueChainService.getAnalyzePackagesStatistics({
				networkId: vm.network.id,
				objectIds: vm.packageIdsWithActiveJobs,
				objectType: enums.objectType.structure,
			}).then(function (res) {
				vm.packageIdsWithActiveJobs = _.chain(res)
					.filter(function (value, key) {
						handlePackageStatisticsItem(value);
						return value.jobInvocations.activeJob;
					})
					.mapValues(function (value) {
						return parseInt(value.objectId);
					})
					.map()
					.value();

				_.assign(vm.packageStatsByWfid, res);

				if (vm.packageIdsWithNewlyStartedJobs.length) {
					Array.prototype.push.apply(vm.packageIdsWithActiveJobs, vm.packageIdsWithNewlyStartedJobs);
					vm.packageIdsWithNewlyStartedJobs.length = 0;
					vm.packageIdsWithActiveJobs = _.uniq(vm.packageIdsWithActiveJobs);
				}

				vm.packageStatsRequestLoading = false;

				if (queueNextCheck) {
					if (vm.packageIdsWithActiveJobs.length)
						getPackagesStatisticsPeriodically();
					else
						vm.periodicStatsCheckingStarted = false;
				}

				$timeout();
			});
		}

		function getActiveOrLatestJob(wfid) {
			const obj = vm.packageStatsByWfid[wfid]
			if (obj && obj.jobInvocations) {
				return obj.jobInvocations.activeJob || obj.jobInvocations.latestEndedJob
			}
		}

		function openAnalyzeJobsCreatorModal(analyzePackage) {
			return valueChainService.openAnalyzeJobsCreator({
				title: analyzePackage.title,
				networkId: vm.network.id,
				objectType: analyzePackage.type,
				objectId: analyzePackage.id
			}).then(function (res) {
				if (res) {
					startGettingPackagesStatisticsPeriodically(analyzePackage.id, true)

					$timeout();
				}
			})
		}

		function openAnalyzeJobsDeleterModal(analyzePackage) {
			return valueChainService.openAnalyzeJobsDeleter({
				title: analyzePackage.title,
				networkId: vm.network.id,
				objectType: analyzePackage.type,
				objectId: analyzePackage.id
			}).then(function (res) {
				if (res) {
					startGettingPackagesStatisticsPeriodically(analyzePackage.id, true)

					$timeout();
				}
			})
		}

		function openStandardPackagesPicker() {
			let sourceItemId;
			let sourceItemFirstLevelAsFilter = false;

			sourceItemId = {
				"Sustainability Management": enums.ids.common.standardAnalyzePackagesPicker_MgmtAndInvestments,
				"Sustainable Investments": enums.ids.common.standardAnalyzePackagesPicker_MgmtAndInvestments,
				"Sustainable Sourcing": enums.ids.common.standardAnalyzePackagesPicker_Sourcing,
			}[authSolutionType]

			// if (authSolutionType !== "Sustainable Sourcing") {
			// 	sourceItemFirstLevelAsFilter = true
			// }

			var pickerPromise = modal.openCreatorAndPicker({
				sourceItem: `71-${sourceItemId}`,
				sourceItemFirstLevelAsFilter: sourceItemFirstLevelAsFilter,
				openerButtonTranslate: "Select",
				create: false,
				title: $translate.instant(vm.useInternalPackages ? "modules.valueChain.analyzePackages.standardPackages.pickerHeader" : "modules.valueChain.analyzePackages.standardPackages.pickerHeader"),
				relationTarget: [
					{
						item:  `71-${valueChainService.ids.analyzePackagesStructure}`,
						kind: enums.subItemsKind.childrenByUser
					},
					{
						item: vm.network,
						kind: enums.subItemsKind.childrenByUser
					}
				],
			});

			pickerPromise.modal.closed.then(function () {
				const currentItems = vm.items
				const withNewItems = _.intersectionBy(vm.mainStructure.childs, vm.network.childs, "wfcid")
				const onlyNewItems = _.differenceBy(withNewItems, currentItems, "wfcid")
				const onlyNewItemsNetworkRelations = _.intersectionBy(vm.network.childs, onlyNewItems, "wfcid")
				const onlyRemovedItems = _.differenceBy(currentItems, withNewItems, "wfcid")

				onlyNewItems.forEach(dataRelation => {
					const networkRelations = onlyNewItemsNetworkRelations.filter(x => x.wfcid = dataRelation.wfcid);

					vm.dataRelationsByPackageIds[dataRelation.childId] = _.concat([dataRelation], onlyNewItemsNetworkRelations.filter(x => x.wfcid = dataRelation.wfcid));
					vm.packageDataRelations.push(dataRelation);
					vm.packageDataRelations.push(networkRelations[0]);

					vm.items.unshift(dataRelation);
				})

				onlyRemovedItems.forEach(dataRelation => {
					_.remove(vm.items, { wfid: dataRelation.wfid })
					_.remove(vm.packageDataRelations, { wfcid: dataRelation.wfcid, parentType: enums.objectType.structure })
					_.remove(vm.packageDataRelations, { wfcid: dataRelation.wfcid, parentType: enums.objectType.network })
				})

				$timeout();
			});
		}

		function isItemOwned(item) {
			return item.creatorOrganizationId === authOrgId
		}

		function closeCalculationScheduleDialog() {
			vm.isCalculationScheduleDialogOpen = false
			$timeout()
		}

		function openCalculationScheduleDialog(packageId) {
			return valueChainService.getCalculationSchedule({
				networkId: vm.network.id,
				packageType: enums.packageType.analyzePackage,
				packageId: packageId,
				days: [],
			}).then((result) => {
				const calculationScheduleDays = {}
				Object.entries(enums.daysOfWeek).forEach(([day, number]) => {
					calculationScheduleDays[day] = result.includes(number)
				})

				vm.calculationScheduleDays = calculationScheduleDays
				vm.isCalculationScheduleDialogOpen = true
				vm.calculationSchedulePackageId = packageId
				$timeout()
			})
		}

		function onSaveCalculationSchedule(newCalculationSchedule) {
			vm.isCalculationScheduleDialogOpen = false
			vm.calculationScheduleDays = newCalculationSchedule

			const days = []
			Object.entries(newCalculationSchedule).forEach(([key, value]) => {
				if(value) {
					days.push(enums.daysOfWeek[key]);
				}
			})

			valueChainService.setCalculationSchedule({
				networkId: vm.network.id,
				packageType: enums.packageType.analyzePackage,
				packageId: vm.calculationSchedulePackageId,
				days: days,
			})

			$timeout()
		}
	}
})();
