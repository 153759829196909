// @flow
import * as React from 'react'
import ItemSummaryStats from './ItemSummaryStats'
import ItemSummaryList from './ItemSummaryList'
import Map from './Map'
import type { Item } from '@worldfavor/types'

const MAX_ITEM_PER_BOX = 20

type Props = {
  subItems: Array<Item>,
}

class ItemSummaryContainer extends React.PureComponent<Props> {

  static defaultProps = {
    subItems: [],
  }

  render() {
    const { subItems } = this.props

    // Display attached items as a map only if all items have the same type
    // and latitude / longitude
    if (subItems.every((item, index, array) => item.latitude && item.longitude
      && (index === 0 || item.type === array[index - 1].type))) {
      return <Map items={subItems} clusterize />
    }

    if (subItems.length > MAX_ITEM_PER_BOX) {
      return <ItemSummaryStats subItems={subItems} />
    }
    return (
      <ItemSummaryList
        {...this.props}
      />
    )
  }
}

export default ItemSummaryContainer
