import * as enums from '@worldfavor/constants/enums'

/**
 * @ngdoc directive
 * @name wfFiltering
 * 
 * @description 
 * Used to generate filters and their options. Possible filter types are: buttons, dropdowns, sliders, date range pickers and a search input fields.
 * 
 * @param {Object=} config Used for declaring the types of filters that will be used as well as their properties.
 * @property boolean infiniteScroll ???
 * @property Array items An array of items used when filtering without server requests.
 * @property {Object} useServer Required if all filter selection needs to be fetched from the server. The object requires two properties: method used for fetching the data (example: 'getObject') and baseParams which specify the objectId and objectType (more options can be added for base Params).
 * @property Array filters Accepts an array of objects that define each filter type along with its properties.
 * 
 * @property Object Slider Used to create a new instance of a Slider object. 
 * 
 * @example Front-end filtering (without server requests)
 * <div wf-filtering config={ items: vm.allItems, filters=[ { displayAs: 'buttons' } ] }></div>
 * 
 * @example Back-end filtering (with server requests)
 * <div wf-filtering config= {
 * 	infiniteScroll: true,
 * 	useServer: { 
 * 		method: 'getObject', 
 * 		baseParams: { 
 * 			objectId: 12345, 
 * 			objectType: 67 
 * 		} 
 * 	},
 * 	filters: [
 * 		{
 * 			placeholder: 'My placeholder',
 * 			label: 'My filter',
 * 			filterOptionsSource: vm.items,
 * 			getterParamName: 'objectTypes',
 * 			displayAs: 'dropdown'
 *   	}
 * 	]
 * ></div>
 * 
 * @example Date range picker
 * <div wf-filtering config= {
 * 	infiniteScroll: true,
 * 	useServer: { 
 * 		method: 'getObject', 
 * 		baseParams: { 
 * 			objectId: 12345, 
 * 			objectType: 67 
 * 		} 
 * 	},
 * 	filters: [
 * 		{
 * 			label: 'Date range filter',
 * 			displayAs: 'dateRangePicker',
 * 			includeSlider: true,
 * 			minValue: vm.firstDate
 *   	}
 * 	]
 * ></div>
 * 
 * @example Slider
 * <div wf-filtering config= {
 * 	filters: [
 * 		{
 * 			label: 'Slider',
 * 			displayAs: 'dateRangePicker',
 * 			filterOptionsSource: [
 * 				{ value: 1, legend: 'One' },
 * 				{ value: 2, legend: 'Two' },
 * 				{ value: 3, legend: 'Three' },
 * 				{ value: 4, legend: 'Four' },
 * 				{ value: 5, legend: 'Five' }
 * 			],
 * 			// instead of filterOptionsSource you can alternatively use minValue & maxValue
 * 			// if maxValue is defined or rangeSelect is set to true the slider will take the range into consideration
 * 			minValue: 1,
 * 			maxValue: 100,
 * 			rangeSelect: true, // not needed if maxValue is defined
 * 			sliderOptions: { options: { showTicks: true, onEnd: function() { console.log('Do whatever!') } } }
 *   	}
 * 	]
 * ></div>
*/

(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfFiltering', wfFiltering);

	wfFiltering.$inject = [];
	
	function wfFiltering() {
		var directive = {
			restrict: "EA",
			templateUrl: "scripts/wf/filtering/wfFiltering.directive.html",
			controller: wfFilteringController,
			transclude: {
				"transcludeBottomRight": "?transcludeBottomRight"
			}
		};
		
		return directive;
	}

	wfFilteringController.$inject = [ "$scope", "$attrs", "$parse", "wfObject", "wfAuth", "dataOperationsService", "$q", "$translate", "$timeout", "$element", "wfPropertyExtractor", "$transclude" ];
	function wfFilteringController($scope, $attrs, $parse, wfObject, wfAuth, dataOps, $q, $translate, $timeout, $element, wfPropertyExtractor, $transclude) {
		var
			vm = $scope.filteringVm = {},
			authOrgId = wfAuth.getOrganizationId(),
			sourceArray,
			filterBySubItemsKind,
			config,
			negotiator,
			buttons = [],
			requestParameters = {},
			getterConditions = {},
			arrayWatcher,
			sourceIdPath,
			ignoreAuthOrgMatch = false,
			filters,
			actualFilters = [],
			initializationPromises = [],
			controllerAs,
			filteredItems,
			itemRequestLimit = 20,
			useServer,
			serverRequestMethod, 
			serverRequestBaseParameters,
			useInfiniteScroll,
			pagingFunctionActive,
			filterOptionForSearch, // The filter option that represents the search bar
			onFilteredCallback, // Comes from config.onFiltered
			initialSelectedFilterOptions
		;

		var // Protypes
			filterPrototype = {
				filterOptions: undefined,
				selectedOptions: undefined,
				placeholder: undefined,
				title: undefined,
				icon: undefined,
				iconColor: undefined,
				itemsByFilterOptionId: undefined,
				single: false,
				displayAs: "buttons", // 'buttons' or 'dropdown' of 'date-range' or 'slider'
				select: filterPrototype_select,
				unselect: filterPrototype_unselect,
				clearButtonTooltipText: $translate.instant("ClearFilter"),
				clear: filterPrototype_clear,
				searchControl: undefined,
				isDynamic: false,
				canClearAll: true,
				canDeselectAll: true,
				cssClass: undefined,

				// Functions
				filterOptionsSource: undefined // From configuration. Can be a function or an array
			},
			filterOptionPrototype = {
				id: undefined, // Usually a wfid
				type: undefined,
				title: undefined,
				content: undefined,
				count: undefined,
				icon: undefined,
				iconColor: undefined,
				isSelected: false,
				isInverted: false,
				filter: undefined, // The filter object that the filterOption belongs to
				toggle: filterOptionPrototype_toggle,
				unselect: filterOptionPrototype_unselect
			}
		;
		config = $parse($attrs.config)($scope);
		controllerAs = config.controllerAs || "vm";
		sourceArray = config.items;
		filters = config.filters;
		filterBySubItemsKind = config.filterBySubItemsKind;
		sourceIdPath = config.sourceIdPath || "wfcid";
		ignoreAuthOrgMatch = config.ignoreAuthOrgMatch;
		useServer = config.useServer;
		useInfiniteScroll = config.infiniteScroll;
		initialSelectedFilterOptions = config.initialSelectedFilterOptions;

		_.assign($scope[controllerAs], {
			filteredItems: filteredItems = [],
			infiniteLoad: false,
			filteredItemsLoaded: false,
			infinitePagingFunction: useInfiniteScroll ? infinitePagingFunction : undefined,
			filteringVm: vm
		});

		_.assign(vm, {
			subItemsBySupWfid: {},
			filterButtons: [],
			selectedFilters: {},
			finalFilters: [],
			items: [], // Used for quick search
			collapsable: false, // If filters should be collapsable
			collapsed: false, // If filters are currently collapsed
			mainCompiler: {},
			// filteredItems: _.clone(sourceArray),

			// Functions
			updateButtonBars: updateButtonBars,
			updateButtonBar: updateButtonBar,
			toggleFilter: toggleFilter,
			applyFilters: applyFilters,
			setInputFocus: setInputFocus,
			clearAll: clearAll,
			onSearch: onSearch
		});

		vm.collapsable = _.get(config, "collapsable") || false;
		vm.enableInvertedFiltering = _.get(config, "enableInvertedFiltering") || false;

		if (config.control) {
			_.assign(config.control, vm);
		}

		initializeFilters();

		if ("negotiator" in $attrs) {
			negotiator = $parse($attrs.negotiator)($scope);
			// onFilteredCallback = config.onFiltered;
			vm.items = sourceArray = [];
			vm.filteredItems = filteredItems = negotiator.items;

			sourceArray.length = 0;
			Array.prototype.push.apply(sourceArray, negotiator.rawItems);

			$scope.$watchCollection(function () {
				return negotiator.rawItems;
			}, function (value) {
				sourceArray.length = 0;
				Array.prototype.push.apply(sourceArray, value);
				updateButtonBars();
				if (initializationPromises.length) {
					$q.all(initializationPromises).then(function () {
						loaded();
					});
				}
				else
					loaded();
			});
		}
		else if ("items" in $attrs) {
			config = $parse($attrs.config)($scope);
			onFilteredCallback = config.onFiltered;
			vm.items = sourceArray = [];

			$scope.$watchCollection(function () {
				return $parse($attrs.items)($scope);
			}, function (value) {
				sourceArray.length = 0;
				Array.prototype.push.apply(sourceArray, value);
				updateButtonBars();
				if (initializationPromises.length) {
					$q.all(initializationPromises).then(function () {
						loaded();
					});
				}
				else
					loaded();
			});
		}
		else {
			arrayWatcher = $scope.$watchCollection(function () {
				// sourceArray might be undefined. If that is the case we need to do $parse again.
				if (!sourceArray) {
					config = $parse($attrs.config)($scope);
					sourceArray = config.items;
				}
				vm.items = sourceArray;
				return sourceArray;
			}, function () {
				updateButtonBars();
				if (initializationPromises.length) {
					$q.all(initializationPromises).then(function () {
						loaded();
					});
				}
				else
					loaded();
			});
		}
		
		$scope.$on("$destroy", function () {
			if (arrayWatcher)
				arrayWatcher();
		});

		if (useServer) {
			serverRequestMethod = useServer.method;
			serverRequestBaseParameters = useServer.baseParams;
		}

		function loaded() {
			vm.loaded = true;
			$timeout();
		}

		function initializeFilters() {
			vm.filters = filters;

			initializeAll(filters);

			function initializeAll(filters) {
				_.each(filters, function (filterSpec, index) {
					if (_.isArray(filterSpec)) {
						initializeAll(filterSpec);
					}
					else {
						_.assign(filterSpec, _.assign(_.clone(filterPrototype), filterSpec), {
							id: _.uniqueId(),
							filterOptions: [],
							selectedOptions: [],
							index: index,
							belongsTo: filters
						});
						
						if (filterSpec.bySearch) {
							initializeSearch(filterSpec);
						}

						if (filterSpec.headerTranslate)
							filterSpec.header = $translate.instant(filterSpec.headerTranslate);

						if (!filterSpec.groupBy)
							actualFilters.push(filterSpec);
					}
				});
			}
		}

		function initializeSearch(filterSpec) {
			filterSpec.displayAs = "search";
			filterOptionForSearch = _.assign(_.clone(filterOptionPrototype), {
				filter: filterSpec,
				id: "search",
				type: "search",
				title: undefined, // Is dynamically set to the current search string
				icon: "fa fa-search",
				iconColor: "#c6cdd4",
				iconSize: "13px"
			});

			filterSpec.filterOptions.push(filterOptionForSearch);
			
			vm.searchControl = {}
		}

		function updateButtonBars() {
			var
				promises = [],
				newFilterOptions
			;

			$element.height($element.height());

			removeDynamicFilters();
			updateAll(filters);
			
			$q.all(promises).then(function() {
				newFilterOptions = _.compact(_.concat.apply(null, _.map(actualFilters, "filterOptions")));
				_.each(vm.allSelectedOptions, function (prevSelected) {
					if (prevSelected.isSelected) {
						var newFilterOption = _.find(newFilterOptions, { type: prevSelected.type, id: prevSelected.id });
						if (newFilterOption)
						newFilterOption.isSelected = true;
					}
				});

				_.each(actualFilters, function (filter) {
					filter.selectedOptions = _.filter(filter.filterOptions, function (filterOption) {
						if (initialSelectedFilterOptions) {
							filterOption.isSelected = !!_.find(initialSelectedFilterOptions, { id: filterOption.id, isSelected: true });
							filterOption.isInverted = !!_.find(initialSelectedFilterOptions, { id: filterOption.id, isInverted: true });
						}

						return filterOption.isSelected || filterOption.isInverted;
					});
				});

				if (initialSelectedFilterOptions)
					initialSelectedFilterOptions = undefined


				// if (vm.finalFilters.length === 0) {
					// vm.finalFilters.length = 0;
					// vm.finalFilters = _.map(vm.filters, function (filter) {
					// 	var newFilter;

					// 	if (filter instanceof Array) {
					// 		return _.map(filter, function (filter) {
					// 			var newFilter;

					// 			newFilter = _.clone(filter);
					// 			newFilter.filterOptions = _.clone(filter.filterOptions);
					// 			newFilter.selectedOptions = _.clone(filter.selectedOptions);
					// 			newFilter.itemsByFilterOptionId = _.clone(filter.itemsByFilterOptionId);
					// 			return newFilter;
					// 		});
					// 	}
					// 	else {
					// 		newFilter = _.clone(filter);
					// 		newFilter.filterOptions = _.clone(filter.filterOptions);
					// 		newFilter.selectedOptions = _.clone(filter.selectedOptions);
					// 		newFilter.itemsByFilterOptionId = _.clone(filter.itemsByFilterOptionId);
					// 		return newFilter;
					// 	}
					// });
					// $timeout(function () {
						vm.finalFilters.length = 0;
						Array.prototype.push.apply(vm.finalFilters, vm.filters);
					// }, 5000)
				// }

				$element.css("height", "");
				$element.animate("opacity", 0);

				applyFilters();
			});

			function updateAll(filters) {
				_.each(filters, function (filterSpec) {
					var promise;

					if (filterSpec instanceof Array) {
						updateAll(filterSpec)
					}
					else {
						promise = updateButtonBar(filterSpec);
						if (promise)
							promises.push(promise);
					}
				})
			}
			
			function removeDynamicFilters(filters) {
				_.remove(actualFilters, { isDynamic: true });
				_.remove(filters, { isDynamic: true });
				
				_.each(filters, function (filterSpec) {
					if (filterSpec instanceof Array) {
						_.remove(filters, { isDynamic: true });
					}
				})
			}
		}

		function updateButtonBar(filterSpec) {
			var promises = [], promise;

			if (filterSpec.bySearch) {
				// Do nothing
			}
			else 
				filterSpec.filterOptions.length = 0;

			if (filterSpec.bySubItemsKind) {
				promise = aggregateFromRelations(filterSpec);
				if (promise && promise.then) {
					promises.push(promise);
					initializationPromises.push(promise);
				}
			}
			else if (filterSpec.byProperty) {
				aggregateFromProperty(filterSpec);
			}
			else if (filterSpec.byObjectType) {
				aggregateFromObjectLookup(filterSpec);
			}
			else if (filterSpec.displayAs === 'dateRangePicker') {
				initializeDateRangePicker(filterSpec);
				if (filterSpec.includeSlider) {
					filterSpec.sliderOptions = { sliderType: 'dateRange' };
					filterSpec.slider = new Slider(filterSpec);
				}
			}
			else if (filterSpec.displayAs === 'slider')  {
				filterSpec.slider = new Slider(filterSpec);
			}
			else if (filterSpec.filterOptionsSource) {
				filterSpec.itemsByFilterOptionId = {};

				if (typeof filterSpec.filterOptionsSource === "function") {
					// The responsibility of providing config and items for each filter option is by the implementation.

					filterSpec.filterOptions = _.each(filterSpec.filterOptionsSource(sourceArray), function (filterOption) {
						_.defaults(filterOption, _.clone(filterOptionPrototype), filterOption, {
							filter: filterSpec
						});
						filterSpec.itemsByFilterOptionId[filterOption.id] = filterOption.items;
					});
				}
				else {
					filterSpec.filterOptions = buildFilterOptions(filterSpec, filterSpec.filterOptionsSource, {});
				}
			}

			// if (promises.length) {
				return $q.all(promises)
			// }


			// Checking if filtering was active before the buttons were updated
			// if (!_.isEmpty(vm.selectedFilters)) {
				// console.log(_.clone(vm.selectedFilters))
			// 	var previouslySelectedFilterIds = _.keys(vm.selectedFilters);
			// 	vm.selectedFilters = {};

			// 	_.each(previouslySelectedFilterIds, function (filterId) {
			// 		if (vm.subItemsBySupWfid[filterId])
			// 			vm.selectedFilters[filterId] = _.find(vm.filterButtons, { id: filterId });
			// 	});
			// }

		}

		function toggleFilter(filter) {
			var
				wfid
			;

			if (typeof filter !== "undefined") {
				wfid = filter.id;

				if (vm.selectedFilters[wfid]) {
					delete vm.selectedFilters[wfid];
				}
				else {
					vm.selectedFilters[wfid] = filter;
				}
			}

			applyFilters();
		};

		function applyFilters() {
			var
				arraysToIntersect = [],
				result
			;

			if (useServer) {
				getterConditions = {};
				requestParameters = {};
			}

			_.each(actualFilters, function (filter) {
				if (filter.bySearch) {
					if (filter.selectedOptions.length) {
						arraysToIntersect.push(vm.searchResultItems); // The wf-search-quick directive automatically puts the searchResultItems array on vm.
					}
				}
				else if (filter.displayAs === 'dateRangePicker') {
					if (!filter.initialized && filter.includeSlider) {
						filter.syncDateRangePickerAndSlider(filter.minValue, moment(), true); //remove date-range
					}

					if (filter.datePicker && !filter.datePicker.clearDates) {
						_.assign(getterConditions, {
							'minDateTime': moment(filter.datePicker.date.startDate).format(),
							'maxDateTime': moment(filter.datePicker.date.endDate).format()
						});
						filter.selectedOptions = [ { date: filter.datePicker.date } ];
					}
					else 
						filter.selectedOptions = [];

				}
				else if (filter.displayAs === 'slider') {
					var sliderValues;

					if (filter.slider.maxValue)
						sliderValues = { minValue: filter.slider.minValue, maxValue: filter.slider.maxValue };
					else
						sliderValues = filter.slider.minValue;
				}
				else if (filter.selectedOptions.length > 0) {
					filter.selectionResult = [];
					if (useServer) {
						_.each(filter.selectedOptions, function(filterOption) {
							if (!_.isArray(getterConditions[filter.getterParamName]))
								getterConditions[filter.getterParamName] = [];

							if (filterOption.content)
								getterConditions[filter.getterParamName].push(filterOption.content.id);
							else
								getterConditions[filter.getterParamName].push(filterOption.id);
						});
					}
					else {
						_.each(filter.selectedOptions, function (filterOption) {
							if (filterOption.isInverted)
								Array.prototype.push.apply(filter.selectionResult, _.difference(vm.items, filterOption.items));
							else
								Array.prototype.push.apply(filter.selectionResult, filter.itemsByFilterOptionId[filterOption.id]);
						});
						_.uniq(filter.selectionResult);
						arraysToIntersect.push(_.uniq(filter.selectionResult));
					}
				}
			});
			
			$scope[controllerAs].activeFiltersCount = _.chain(actualFilters).map("selectedOptions.length").sum().value();
			vm.allSelectedOptions = _.compact(_.concat.apply(null, _.map(actualFilters, "selectedOptions")));
			
			if (useServer) {
				clearFilteredListFromCache();
				filteredItems.length = 0;

				if (serverRequestBaseParameters.getterConditions)
					_.assign(getterConditions, serverRequestBaseParameters.getterConditions);

				_.assign(requestParameters, serverRequestBaseParameters, {
					getterConditions: getterConditions,
					typeConditions: getterConditions
				});

				requestFromServer(serverRequestMethod, requestParameters);
			}
			else {
				filteredItems.length = 0;
				
				if (arraysToIntersect.length === 0) {
					Array.prototype.push.apply(filteredItems, sourceArray);
				}
				else {
					result = _.intersection.apply(null, arraysToIntersect);
					result = _.uniq(result);
					
					Array.prototype.push.apply(filteredItems, result);
				}

				if (_.get($scope[controllerAs], "filteredItems") && $scope[controllerAs].filteredItems !== filteredItems) {
					$scope[controllerAs].filteredItems = filteredItems;
				}

				if (typeof onFilteredCallback === "function") {
					onFilteredCallback(filteredItems, vm.allSelectedOptions);
				}

				if (negotiator) {
					negotiator.onFiltered(filteredItems, vm.allSelectedOptions);
				}
			}
		}

		function aggregateFromRelations(filterSpec) {
			var
				previouslySelectedFilterIds,
				supContentDataRelations,
				uniqueSupContents,
				uniqueSupContentWfids,
				itemWfids,
				subItemsBySupWfid,
				query,
				relationFilter = filterSpec.relationFilter,
				contentFilter = filterSpec.contentFilter,
				relationParentDataValue = wfObject.getRelationParentDataOfKind(filterSpec.bySubItemsKind),
				foreignKeyName = wfObject.getForeignKeyOfKind(filterSpec.bySubItemsKind),
				localKeyName = wfObject.getRelationKeyOfKind(filterSpec.bySubItemsKind),
				supContentType = wfObject.getSubContentTypeKeyOfKind(filterSpec.bySubItemsKind),
				intersectionWfids,
				promises = []
			;

			itemWfids = _.chain(sourceArray).map(filterSpec.sourceIdPath).value();

			query = {
				type: enums.objectType.dataRelation,
				relationType: null,
				parentData1: relationParentDataValue,
			};
			query[foreignKeyName] = { "in": itemWfids };
			query[supContentType] = filterSpec.supContentType || enums.objectType.structure;

			if (filterSpec.excludeTargetWfid) {
				if (_.isArray(filterSpec.organizationId))
					query[localKeyName] = { "notIn": filterSpec.excludeTargetWfid };
				else
					query[localKeyName] = { "!=": filterSpec.excludeTargetWfid };
			}
			
			if (filterSpec.organizationId) {
				if (_.isArray(filterSpec.organizationId))
					query.organizationId = { "in": filterSpec.organizationId };
				else
					query.organizationId = filterSpec.organizationId;
			}
			else if (!ignoreAuthOrgMatch) {
				query.organizationId = { '!=': null };
				// query.organizationId = authOrgId ? authOrgId : { '!=': null };
			}

			if (filterSpec.load) {
				filterSpec.load = false;
				promises.push(dataOps.getSubItemsOfAll(itemWfids, filterSpec.bySubItemsKind));
			}

			if (filterSpec.intersectionSourceWfid) { // Children of the item with this wfid will be used to intersect the filterButtons that will show.
				// if (filterSpec.intersectionSourceLoaded) {
					promises.push(dataOps.getSubItems(filterSpec.intersectionSourceWfid, enums.subItemsKind.children).then(function (res) {
						intersectionWfids = _.map(res, "wfcid");
					}));
				// }
			}

			return $q.all(promises).then(proceed);

			function proceed() {
				supContentDataRelations = wfObject.filter({ where: query });

				if (intersectionWfids) {
					// supContentDataRelations = _.intersectionWith(supContentDataRelations, intersectionWfids, function (dataRelation, wfid) {
					// 	return dataRelation[localKeyName] === wfid;
					// });
					// _.intersectionWith remove duplicate values from the first array based on the comparator so _.filter must be used instead
					supContentDataRelations = _.filter(supContentDataRelations, function (dataRelation) {
						return !!~intersectionWfids.indexOf(dataRelation[localKeyName]); // If value is included in array
					});
				}
				
				if (relationFilter)
					supContentDataRelations = _.filter(supContentDataRelations, relationFilter);

				uniqueSupContentWfids = _.chain(supContentDataRelations).map(localKeyName).uniq().value();
				
				uniqueSupContents = wfObject.filter({ where: {
					wfid: { in: uniqueSupContentWfids }
				} });

				if (contentFilter) {
					uniqueSupContents = _.filter(uniqueSupContents, contentFilter);
					uniqueSupContentWfids = _.map(uniqueSupContents, "wfid");
					supContentDataRelations = _.filter(supContentDataRelations, function (dr) {
						return _.includes(uniqueSupContentWfids, dr[localKeyName]);
					});
				}

				filterSpec.itemsByFilterOptionId = _.chain(supContentDataRelations).groupBy(localKeyName).mapValues(function (dataRelations, key) {
					var
						output = [],
						i = dataRelations.length
					;
					while (i--) {
						Array.prototype.push.apply(output, _.filter(sourceArray, _.matchesProperty(filterSpec.sourceIdPath, dataRelations[i][foreignKeyName] )));
					}
					return output;
				}).value();

				filterSpec.filterOptions = buildFilterOptions(filterSpec, uniqueSupContents, _.mapValues(filterSpec.itemsByFilterOptionId, function (items) {
					return items.length
				}));

				if (filterSpec.groupBy) { // groupBy will make this filterSpec be hidden and instead show some dynamically generated filter rows based on the groupBy rules
					var
						filterOptionParentRelations,
						filterOptionsByParentWfid,
						filterOptionParentWfids,
						filterOptionParentContents,
						output = [],
						filterOptionsWfidsWithAParent = []
					;

					filterOptionParentRelations = wfObject.filter({ where: { type: enums.objectType.dataRelation, wfcid: { "in": uniqueSupContentWfids } } })
					if (filterSpec.intersectionSourceWfid) {
						filterOptionParentRelations = _.reject(filterOptionParentRelations, { wffid: filterSpec.intersectionSourceWfid });
					}

					filterOptionsByParentWfid = _.groupBy(filterOptionParentRelations, "wffid");
					filterOptionParentWfids = _.chain(filterOptionParentRelations).filter({ parentType: enums.objectType.structure }).map("wffid").uniq().value();
					filterOptionParentContents = wfObject.filter({ where: { wfid: { "in": filterOptionParentWfids } } });

					_.each(filterOptionParentWfids, function (wfid) {
						var
							itemsByFilterOptionId,
							filterOptions,
							supContentWfids = _.map(filterOptionsByParentWfid[wfid], "wfcid"),
							newFilterSpec
						;

						Array.prototype.push.apply(filterOptionsWfidsWithAParent, supContentWfids);

						newFilterSpec = _.assign(_.clone(filterPrototype), {
							id: _.uniqueId(),
							selectedOptions: [],
							isDynamic: true,
							header: _.get(_.find(filterOptionParentContents, { wfid: wfid }), "title")
						});
							
						newFilterSpec.itemsByFilterOptionId = _.pickBy(filterSpec.itemsByFilterOptionId, function (value, key) {
							return !!~supContentWfids.indexOf(key); // If wfid is included in array
						});

						newFilterSpec.filterOptions = _.chain(filterSpec.filterOptions).filter(function (filterOption) {
							return !!~supContentWfids.indexOf(filterOption.id); // If wfid is included in array
						}).map(function (filterOption) {
							filterOption = _.clone(filterOption);
							filterOption.filter = newFilterSpec;

							return filterOption;
						}).value();

						output.push(newFilterSpec);
						actualFilters.push(newFilterSpec);
					});


					// TODO: Refactor (repetitive)
					// ---------------------------------------------------------

					filterOptionsWfidsWithAParent = _.uniq(filterOptionsWfidsWithAParent);

					var newFilterSpec = _.assign(_.clone(filterPrototype), {
						id: _.uniqueId(),
						selectedOptions: [],
						isDynamic: true,
						header: filterSpec.header
					});
						
					newFilterSpec.itemsByFilterOptionId = _.pickBy(filterSpec.itemsByFilterOptionId, function (value, key) {
						return !!!~filterOptionsWfidsWithAParent.indexOf(key); // If wfid is not included in array
					});

					newFilterSpec.filterOptions = _.chain(filterSpec.filterOptions).filter(function (filterOption) {
						return !!!~filterOptionsWfidsWithAParent.indexOf(filterOption.id); // If wfid is not included in array
					}).map(function (filterOption) {
						filterOption = _.clone(filterOption);
						filterOption.filter = newFilterSpec;

						return filterOption;
					}).value();

					output.unshift(newFilterSpec);
					actualFilters.push(newFilterSpec);

					// _.filter(filterOptionParentWfids, { wfid:  }, function (wfid) {
					// });

					filterSpec.dynamicFilterSpecs = output;
					filterSpec.belongsTo.splice.apply(filterSpec.belongsTo, [ filterSpec.index + 1, 0 ].concat(filterSpec.dynamicFilterSpecs));
				}
			}
		}

		function aggregateFromProperty(filterSpec) {
			var
				itemsByPropertyValue,
				countByPropertyValue,
				uniquePropertyValues
			;

			itemsByPropertyValue = _.chain(sourceArray).groupBy(filterSpec.byProperty).value();
			countByPropertyValue = _.mapValues(itemsByPropertyValue, function (items) {
				return items.length;
			});

			uniquePropertyValues = _.chain(itemsByPropertyValue).keys().uniq().value();

			filterSpec.itemsByFilterOptionId = itemsByPropertyValue;
			filterSpec.filterOptions = buildFilterOptions(filterSpec, uniquePropertyValues, countByPropertyValue);
		}

		function aggregateFromObjectLookup(filterSpec) {
			var
				itemsBySourceId,
				countBySourceId,
				sourceIds,
				objectTypeLookup = filterSpec.byObjectType,
				foundObjects
			;

			itemsBySourceId = _.chain(sourceArray).groupBy(filterSpec.sourceIdPath).value();
			countBySourceId = _.mapValues(itemsBySourceId, function (items) {
				return items.length;
			});
			sourceIds = _.chain(itemsBySourceId).keys().uniq().value();

			foundObjects = wfObject.filter({ where: { type: objectTypeLookup, wfid: { "in": sourceIds } } });

			filterSpec.itemsByFilterOptionId = itemsBySourceId;
			filterSpec.filterOptions = buildFilterOptions(filterSpec, foundObjects, countBySourceId);
		}

		function buildFilterOptions(filterSpec, items, countByItemWfid) {
			var output = [], filterOption

			_.each(items, function (item, index) {
				if (filterSpec.byProperty) {
					output.push(_.assign(_.clone(filterOptionPrototype), {
						filter: filterSpec,
						id: item,
						type: "propertyValue",
						title: item,
						content: item,
						count: countByItemWfid[item],
						icon: '' || item.icon
					}));
				}
				else if (item.wfid) {
					output.push(_.assign(_.clone(filterOptionPrototype), {
						filter: filterSpec,
						id: item.wfid,
						type: item.type,
						title: wfPropertyExtractor.getHeaderText(item),
						content: item,
						count: countByItemWfid[item.wfid],
						icon: '' || item.icon
					}));
				}
				else {
					output.push(filterOption = _.assign(_.clone(filterOptionPrototype), {
						filter: filterSpec,
						id: item.id,
						type: 'custom',
						title: item.title,
						content: item.content,
						icon: '' || item.icon,
						items: item.items
					}));

					if (item.items) {
						filterOption.items = item.items;
						filterOption.count = item.items.length;
						filterSpec.itemsByFilterOptionId[filterOption.id] = filterOption.items;
					}
				}
			});

			return _.chain(output).sortBy("title").value();
		}

		function filterOptionPrototype_toggle(useInvertedResult, $event) {
			var self = this;

			if ($event)
				$event.stopPropagation();

			if (!self.filter.canDeselectAll && self.filter.selectedOptions.length === 1 && self.isSelected && !this.isInverted) {
				return;
			}
			 
			if (vm.enableInvertedFiltering && useInvertedResult && !this.isInverted) {
				this.isSelected = false;
				this.isInverted = !this.isInverted;
			}
			else {
				this.isSelected = !this.isSelected;
				this.isInverted = false;
			}

			if (!this.isSelected && this.id === "search" && vm.searchControl)
				vm.searchControl.clear();

			if (this.filter.single && this.isSelected) {
				_.each(this.filter.filterOptions, function (filterOption) {
					if (filterOption !== self)
						filterOption.isSelected = false;
				});
			}

			this.filter.selectedOptions = _.filter(this.filter.filterOptions, function(option) { return option.isInverted || option.isSelected });

			applyFilters();

			$timeout();
		}

		function filterOptionPrototype_unselect() {
			this.isSelected = false;
			this.isInverted = false;

			if (this.id === "search" && vm.searchControl)
				vm.searchControl.clear();
		}

		function filterPrototype_select(filterOption) {
			_.invokeMap(this.filterOptions, "unselect");

			if (filterOption)
				filterOption.isSelected = true;

			this.selectedOptions = _.filter(this.filterOptions, function(option) { return option.isInverted || option.isSelected });

			applyFilters();
		}

		function filterPrototype_unselect(filterOption) {
			filterOption.isSelected = false;
			filterOption.isInverted = false;

			if (filterOption.id === "search" && vm.searchControl)
				vm.searchControl.clear();

			this.selectedOptions = _.filter(this.filterOptions, function(option) { return option.isInverted || option.isSelected });

			applyFilters();
		}

		function filterPrototype_clear() {
			_.invokeMap(this.filterOptions, "unselect");
			this.selectedOptions.length = 0;
			this.selectedOption = undefined;

			applyFilters();
		}

		function clearAll() {
			_.invokeMap(vm.allSelectedOptions, "unselect");
			_.each(vm.filters, function (filter) {
				filter.selectedOptions.length = 0;
			});
			applyFilters();
		}

		function requestFromServer(method, requestParameters, infiniteLoad) {
			if (infiniteLoad)
				$scope[controllerAs].infiniteLoad = true;
			
			$scope[controllerAs].filteredItemsLoaded = false;

			requestParameters.limit = itemRequestLimit;
			

			// TODO: Implement negotiator
			// if (useNegotiator) {

			// 	negotiator.requestFromServer(requestParameters);
			// }
			// else {

			// }

			dataOps[method](requestParameters).then(function(result) {
				var
					itemChilds = result instanceof Array ? result : result.childs,
					itemCount = itemChilds ? itemChilds.length : 0
				;
				if (typeof config.useServer.handleResponse === "function") {
					itemChilds = config.useServer.handleResponse(itemChilds);
				}
				
				var childs = _.orderBy(!requestParameters.skip ? itemChilds : _.difference(itemChilds, filteredItems), 'childContent.createdAt', 'desc');

				if (useInfiniteScroll) {
					$scope[controllerAs].pagingFunctionActive = pagingFunctionActive = itemCount >= itemRequestLimit;
				}

				$timeout(function() {
					Array.prototype.push.apply(filteredItems, childs);
					$scope[controllerAs].filteredItemsLoaded = true;
					if (!$scope[controllerAs].initialFilteredItemsLoaded) {
						setTimeout(function () {
							$scope.$broadcast('rzSliderForceRender');
						});
					}
					$scope[controllerAs].initialFilteredItemsLoaded = true;
					$scope[controllerAs].infiniteLoad = false;
				});
			});
		}

		function clearFilteredListFromCache() {
			_.each(filteredItems, function(child) {
				wfObject.eject(child.wfid);
			});
		}

		function initializeDateRangePicker(filterSpec) {
			var today = moment().format();
			var yesterday = moment().subtract(1, 'days').format();
			filterSpec.initialized = false;
			filterSpec.placeholderText = $translate.instant("modules.filters.dateFilterPlaceholder");

			var customFunctions = {
				openDatePicker: function(filter) {
					if (!filter.dataRangePickerHandle) {
						filter.inputElement = $element.find('#daterange-' + filter.id);
						filter.dataRangePickerHandle = filter.inputElement.data('daterangepicker');
					}

					filter.dataRangePickerHandle.show();
				},
				clearDatePicker: function(filter, event) {
					event.stopPropagation();
					if (!filter.dataRangePickerHandle) {
						filter.inputElement = $element.find('#daterange-' + filter.id);
						filter.dataRangePickerHandle = filter.inputElement.data('daterangepicker');
					}
					filter.dataRangePickerHandle.clickCancel();
				},
				syncDateRangePickerAndSlider: syncDateRangePickerAndSlider
			};

			_.assign(filterSpec, customFunctions, {
				startDate: undefined,
				endDate: undefined,
				datePicker : {
					active: false, // used for highlighting the input with css id: #date-range-picker
					clearDates: true,
					date: { //modal - here we set the initial date when the page loads
						startDate: filterSpec.minValue ? moment(filterSpec.minValue).format() : moment().subtract(3, 'months').format(),
						endDate: today
					},
					options: {
						applyClass: 'btn-primary',
						cancelClass: 'btn-hollow',
						maxDate: today,
						alwaysShowCalendars: true,
						linkedCalendars: false,
						autoUpdateInput: true, //overridden by angular-daterangepicker.js (it is always false)
						locale: {
							firstDay: 1, // setting Monday to be first day of the week
							opens: 'right',
							format: "YYYY-MM-DD",
							applyLabel: $translate.instant("modules.dateRangePicker.applyLabel"),
							cancelLabel: $translate.instant("modules.dateRangePicker.cancelLabel"),
							monthNames: moment.monthsShort(),
							daysOfWeek: moment.weekdaysShort(),
							customRangeLabel: $translate.instant('modules.dateRangePicker.ranges.customRange')
						},
						ranges: (function () {
							var output = {};
							output[$translate.instant('modules.dateRangePicker.ranges.today')] = [ yesterday, today ];
							output[$translate.instant('modules.dateRangePicker.ranges.lastWeek')] = [ moment().subtract(1, 'weeks').format(), today ];
							output[$translate.instant('modules.dateRangePicker.ranges.lastMonth')] = [ moment().subtract(1, 'months').format(), today ];
							output[$translate.instant('modules.dateRangePicker.ranges.lastYear')] = [ moment().subtract(1, 'years').format(), today ];
							return output;
						})(),
						eventHandlers: {
							'apply.daterangepicker': function(event) {
								filterSpec.datePicker.clearDates = false;
								var startDate = event.model.startDate.format();
								var endDate = event.model.endDate.format();
								
								filterSpec.datePicker.active = true;
								filterSpec.syncDateRangePickerAndSlider(startDate, endDate);
								applyFilters();
							},
							'cancel.daterangepicker': function(event) {
								var startDate = event.model.startDate = filterSpec.minValue ? moment(filterSpec.minValue).format() : moment().subtract(3, 'months').format()
								var endDate = event.model.endDate = today;

								filterSpec.datePicker.active = false;
								filterSpec.syncDateRangePickerAndSlider(startDate, endDate, true);
								applyFilters();
							}
						}
					}
				}
			});

			function syncDateRangePickerAndSlider(startDate, endDate, clearDates) {
				if (!this.inputElement) {
					this.inputElement = $element.find('#daterange-' + this.id);
				}

				startDate = moment(startDate);
				endDate = moment(endDate);
				
				if (clearDates) {
					this.datePicker.clearDates = true;
					this.inputElement.val("");
					
					if (this.includeSlider) {
						this.slider.minValue = startDate.format('YYYY-MM-DD');
						this.slider.maxValue = endDate.format('YYYY-MM-DD');
					}
					
					this.datePicker.date.endDate = this.initialized ? moment() : null;
					this.datePicker.date.startDate = this.initialized ? moment() : null;
					
					if (!this.initialized)
						this.initialized = true;
					
					return;
				}
				
				this.startDate = startDate;
				this.endDate = endDate;

				if (this.includeSlider) {
					this.slider.minValue = startDate.format('YYYY-MM-DD');
					this.slider.maxValue = endDate.format('YYYY-MM-DD');
				}

				this.datePicker.date.endDate = this.endDate;
				this.datePicker.date.startDate = this.startDate;

				this.inputElement.val(startDate.format('YYYY-MM-DD') + " - " + endDate.format('YYYY-MM-DD'));
			}
		}

		function Slider(filterSpec) {
			var sliderOptions = {};

			if (!filterSpec.sliderOptions || !filterSpec.sliderOptions.sliderType || filterSpec.sliderOptions.sliderType === 'default') {
				_.defaultsDeep(sliderOptions, filterSpec.sliderOptions, {
					minValue: filterSpec.minValue || undefined,
					maxValue: filterSpec.maxValue || undefined,
					options: {
						id: _.uniqueId(),
						floor: 0,
						showTicks: true,
						showSelectionBar: true,
						draggableRange: true,
						onEnd: function() {
							applyFilters();
						}
					}
				});

				if (filterSpec.filterOptionsSource) {
					if (!('value' in filterSpec.filterOptionsSource[0]) || !('legend' in filterSpec.filterOptionsSource[0])) {
						filterSpec.filterOptionsSource = _.map(filterSpec.filterOptionsSource, function (item) {
							return { value: item.id, legend: item.title }
						});
					}
				}

				initialize();
			}
			else if (filterSpec.sliderOptions.sliderType === 'dateRange') {
				var scale = {
					stepBy: 0, //how many ticks to skip when showing the legend
					formatLegend: "D MMM",
					formatValue: "D MMM"
				};

				_.assign(sliderOptions, filterSpec.sliderOptions, {
					sliderType: 'dateRange',
					maxValue: 1,
					minValue: 1,
					options: {
						draggableRange: true,
						showSelectionBar: true,
						floor: 0,
						translate: function(value) {
							if (moment(value).isSame(moment(), 'd'))
								return "Today";
							else
							return moment(value).format(scale.formatValue); 
						},
						onEnd: function(sliderId, startDate, endDate) {
							filterSpec.datePicker.clearDates = false;
							filterSpec.datePicker.active = true;
							endDate = moment(endDate).add(86399, 'seconds').format();
							filterSpec.syncDateRangePickerAndSlider(startDate, endDate);
							applyFilters();
						}
					}
				});
				initializeDateRange();
			}
			
			return sliderOptions;
			
			function initialize() {
				if (filterSpec.filterOptionsSource) {
					sliderOptions.options.ceil = filterSpec.filterOptionsSource.length;
					sliderOptions.options.stepsArray = filterSpec.filterOptionsSource;
					if (filterSpec.rangeSelect) {
						sliderOptions.minValue = _.first(filterSpec.filterOptionsSource).value;
						sliderOptions.maxValue = _.last(filterSpec.filterOptionsSource).value;
					}
					else 
						sliderOptions.minValue = _.first(filterSpec.filterOptionsSource).value;
				}
			}

			function initializeDateRange() {
				var today = moment().add(1, 'days');
				var minDateSpecValue = filterSpec.minValue ? moment(filterSpec.minValue).format() : moment().subtract(3, 'months').format();
				var sliderDates = [];

				// --------------------------------------------------
				// --------------SCALING THE SLIDER------------------
				
				var differenceInDays = today.diff(minDateSpecValue, "days");
				var differenceInMonths = today.diff(minDateSpecValue, "months");
				var differenceInYears = today.diff(minDateSpecValue, "years");

				if (differenceInYears >= 1) {
					scale.stepBy = _.round(differenceInDays / 4) - 1;
					scale.formatLegend = "MMM YYYY";
					scale.formatValue = "D MMM YYYY";
				}
				else if (differenceInMonths > 1 && differenceInMonths <= 12) {
					scale.stepBy = _.round(differenceInDays / 4) - 1;
					scale.formatLegend = "MMM";
					scale.formatValue = "D MMM YYYY";
				}
				else if (differenceInDays <= 10)
					scale.stepBy = 1;
				else scale.stepBy = 3;

				// --------------------------------------------------

				for (var day = 0; day < differenceInDays; day++) {
					var daysAgo = moment().subtract(day, 'days');
					sliderDates.push({ value: daysAgo.format("YYYY-MM-DD"), legend: daysAgo.format(scale.formatLegend) });
				}

				sliderOptions.options.ceil = differenceInDays;
				sliderOptions.options.maxRange = differenceInDays;
				sliderOptions.options.showTicks = scale.stepBy;
				sliderOptions.options.stepsArray = _.reverse(sliderDates);
				sliderOptions.maxValue = moment().format('YYYY-MM-DD');
				sliderOptions.minValue = moment(minDateSpecValue).format('YYYY-MM-DD');
			}
		}

		function infinitePagingFunction() {
			if (pagingFunctionActive && $scope[controllerAs].filteredItemsLoaded) {
				requestParameters.skip = filteredItems.length;

				requestFromServer(serverRequestMethod, requestParameters, true);
			}
		}

		function setInputFocus($select) {
			$select.searchInput[0].focus();
		}

		function onSearch(searchString, items, searchActive) {
			filterOptionForSearch.filter.selectedOptions.length = 0;
			filterOptionForSearch.title = '"' + searchString + '"';
			filterOptionForSearch.isSelected = searchActive;
			filterOptionForSearch.items = items;

			if (searchActive) {
				filterOptionForSearch.filter.selectedOptions.push(filterOptionForSearch);
			}

			applyFilters();
		}
	}
})();
