// @flow
import React from 'react'
import EmptyOrganizationList from './EmptyOrganizationList'
import DraggableOrganizationRow from './DraggableOrganizationRow'
import OrganizationRow from './OrganizationRow'

import SearchList from '@worldfavor/components/List/SearchList'
import Divider from '@worldfavor/components/Divider'

type Props = {
  data: Array<Object>,
  searchText: string,
  onDataFiltered: () => void,
  draggable?: boolean,
  showMore?: boolean,
  onClickMore?: () => void,
}

const ItemSeparator = () => <Divider height={1} color={'rgba(221, 221, 221, 0.5)'} />

const keyExtractor = item => `organization-row-${item.wfid}`

const OrganizationList = (props: Props) => {
  const { data, searchText, onDataFiltered, draggable, showMore, onClickMore, ...rest } = props

  function renderItem({ item: organization, match }) {
    const Component = draggable ? DraggableOrganizationRow : OrganizationRow
    return (
      <Component
        match={match}
        organization={organization}
        onClickMore={onClickMore}
        showMore={showMore}
      />
    )
  }

  return (
    <SearchList
      data={data}
      searchText={searchText}
      filterKeys={['name']} // TODO set correct search filter
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ItemSeparatorComponent={ItemSeparator}
      ListEmptyComponent={EmptyOrganizationList}
      onDataFiltered={onDataFiltered}
      minSearchLength={2}
      {...rest}
    />
  )
}

export default OrganizationList
