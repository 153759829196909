import * as enums from '@worldfavor/constants/enums'
import { formatDuration } from '@worldfavor/utils/helpers'
import { sortNaturally } from '@worldfavor/utils/helpers'

(function () {
	'use strict';

	angular
		.module('wf.common')
		.controller('ValueChainPackagesController', ValueChainPackagesController);

	ValueChainPackagesController.$inject = ['$scope', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', 'formSchemaService', '$q', 'wfTranslate', '$timeout', '$stateParams', '$rootScope', 'apiProxy', 'wfAuth', 'wfItemToolsService', '$window', 'valueChainService', "$state",  'requirements'];
	function ValueChainPackagesController($scope, dataOps, modal, wfObject, dataQuery, $translate, formSchemaService, $q, wfTranslate, $timeout, $stateParams, $rootScope, apiProxy, wfAuth, wfItemToolsService, $window, valueChainService, $state,  requirements) {
		var
			vm = this,
			authOrgId = wfAuth.getOrganizationId(),
			authSolutionType = wfAuth.getSolution(),
			permissions,
			internalPackagesRouteName = "valueChainInternalPackages"
		;

		_.assign(vm, {
			window: $window,
			loaded: false,
			network: null,
			mainStructure: null,
			showCreateButton: true,
			showStandardPackagesButton: [ "Sustainability Management", "Sustainable Investments", "Sustainable Sourcing" ].includes(authSolutionType) && wfAuth.isAdmin(),
			showDropdown: true,
			canUpdate: true,
			dropdownActions: "update,delete", // This are the default actions but will be based on permissions on mainStructure
			simplifyForm: [ "title", "description", "friendlyTitle", "imageUpload", "guidance" ],
			customFormSpecification: {
				form: [
					"title",
					"description",
					{
					key: "friendlyTitle",
						title: $translate.instant("modules.valueChain.demands.packageFriendlyTitle")
					},
					"imageUpload",
					"guidance"
				]
			},
			useInternalPackages: _.includes($state.current.name, internalPackagesRouteName),
			showBatchCalcFunctions: $.proxies.baseUrl === "https://api1.worldfavor.com/" || document.location.hostname === "local.worldfavor.com",
			isRequirementPackageExporterOpen: false,
			onRequirementPackageExporterClosed: onRequirementPackageExporterClosed,
			isCalculationScheduleDialogOpen: false,
			calculationScheduleDays: {},
			calculationSchedulePackageId: null,
			enums: enums,

			// Arrays
			items: [], // Intersected relations from mainStructure and network
			packageDataRelations: [], // All relations. Packages always belongs to both mainStructure and network
			packageIdsWithActiveJobs: [],
			packageIdsWithNewlyStartedJobs: [],

			// Lookup objects
			dataRelationsByPackageIds: {}, // All relations by childId. Packages always belongs to both mainStructure and network

			// Functions
			createPackage: createPackage,
			openExportDialog: openExportDialog,
			recalculateInfluences: recalculateInfluences,
			abortJobInvocation: abortJobInvocation,
			openInfluencesCreatorModal: openInfluencesCreatorModal,
			openInfluencesUpdaterModal: openInfluencesUpdaterModal,
			openInfluencesDeleterModal: openInfluencesDeleterModal,
			getActiveOrLatestJob: getActiveOrLatestJob,
			openStandardPackagesPicker: openStandardPackagesPicker,
			isItemOwned: isItemOwned,
			openCalculationScheduleDialog: openCalculationScheduleDialog,
			closeCalculationScheduleDialog: closeCalculationScheduleDialog,
			onSaveCalculationSchedule: onSaveCalculationSchedule,
			bulkChangeSettings: bulkChangeSettings,
			bulkChangeRequirements: bulkChangeRequirements,
		});

		activate();

		////////////////

		function activate() {
			var sourceStructureId = vm.useInternalPackages
										? valueChainService.ids.internalPackagesStructure
										: valueChainService.ids.packagesStructure

			$scope.$on("itemToolsActionExecuted", function ($event, operation, item, dataRelation) {
				var relationToDestroy;
				if (operation === "delete") {
					if (_.remove(vm.items, { wfid: item.wfid }).length || _.remove(vm.items, { wfid: dataRelation.wfid }).length) {

						// The first delete operation only deletes the structure, so the relations are deleted now (both from JSData and the relations array).
						relationToDestroy = _.remove(vm.packageDataRelations, { wfcid: dataRelation.wfcid, parentType: enums.objectType.structure })[0];
						if (relationToDestroy) dataOps.destroy(relationToDestroy);

						relationToDestroy = _.remove(vm.packageDataRelations, { wfcid: dataRelation.wfcid, parentType: enums.objectType.network })[0];
						if (relationToDestroy) dataOps.destroy(relationToDestroy);

						$timeout();
					}
				}
			});

			valueChainService.loadNetworks().then(function (result) {
				var
					network = result.networksById[$stateParams.networkId],
					mainStructure = result.structure
				;

				if (!network) {
					$state.go("valueChain-root");
					return;
				}

				vm.network = network;

				dataOps.getObject({
					objectType: network.type,
					objectId: network.id,
					childrenLoadDepth: 1,
					getterConditions: {
						objectTypes: [ enums.objectType.structure ]
					}
					// bypassCache: true
				}).then(function (obj) {
					var
						relationsInNetwork = _.filter(obj.childs, { childType: enums.objectType.structure }),
						relationsInNetworkByChildId = _.keyBy(relationsInNetwork, "childId")
					;

					dataOps.getObject({
						objectId: sourceStructureId, // Contains the current organization's data collector requirement packages
						objectType: enums.objectType.structure,
						childrenLoadDepth: 1
					}).then(function (res) {
						var dropdownActionsFromPermissions;
						vm.mainStructure = res;
						$rootScope.setPageTitle(vm.mainStructure.title);

						// Filter mainStructure childs to only get the children that are also in the network.
						vm.items = sortNaturally(_.chain(vm.mainStructure.childs).filter(function (dataRelation) {
							var networkDataRelation = relationsInNetworkByChildId[dataRelation.childId];
							if (networkDataRelation) {
								// Add to utility arrays
								vm.packageDataRelations.push(networkDataRelation);
								vm.packageDataRelations.push(dataRelation);

								return true;
							}
						}).value(), "childContent.title");

						// Check permissions
						permissions = vm.mainStructure.permissions
						if (permissions) {
							vm.showCreateButton = permissions.canCreate;

							dropdownActionsFromPermissions = [];

							vm.canUpdate = permissions.canUpdate;

							if (!permissions.canUpdate && !permissions.canDelete)
								vm.showDropdown = false;
							else {
								if (permissions.canUpdate) {
									dropdownActionsFromPermissions.push("update");
								}
								if (permissions.canDelete)
									dropdownActionsFromPermissions.push("delete");

								vm.dropdownActions = dropdownActionsFromPermissions.join(",");
							}
						}

						// Create utility object for easy lookup of relations by structure id
						vm.dataRelationsByPackageIds = _.groupBy(vm.packageDataRelations, "childId");
						vm.loaded = true;

						valueChainService.getRequirementPackagesStatistics({
							networkId: vm.network.id,
							objectIds: _.map(vm.items, "childId"),
							objectType: enums.objectType.structure,
							isInternal: vm.useInternalPackages,
						}).then(function (res) {
							/* res = {
								"71-123": {
									objectType: 71,
									objectId: 123,
									jobInvocations: { active: [ ], previous: [] },
									influences: {
										totalCount: 0,
										overdueCount: 0,
										inactiveInfluences: 0,
										activeInfluences: 0,
										countByFulfillmentState: {
											<enums.fulfillmentState>: 0
										}
									}
								}
							}
							*/
							vm.packageStatsByWfid = res;
							_.each(vm.packageStatsByWfid, function (value, key) {
								if (_.get(value, "jobInvocations.active.length"))
									startGettingPackagesStatisticsPeriodically(value.objectId);

								handlePackageStatisticsItem(value);
							});

							$timeout();
						});

						vm.questionObjectSettings = valueChainService.getQuestionObjectSettings();
						vm.measureObjectSettings = valueChainService.getMeasureObjectSettings();
						vm.questionRequirementSettings = requirements.getQuestionRequirementSettings();
						vm.measureRequirementSettings = requirements.getMeasureSettingsFromPeriodSettings(null, enums.uiMode.admin, $scope, { frequency: enums.calendarFrequency.yearly });
						vm.taskStructureRequirementSettings = requirements.getTaskStructureRequirementSettings();

					});
				});

			});
		}

		function bulkChangeSettings(objectType) {
			let initialSettings = undefined;

			const definition = {
				[enums.objectType.question]: vm.questionObjectSettings,
				[enums.objectType.measure]: vm.measureObjectSettings,
			}[objectType];

			modal.editFormattedObjectSettings({
				showSettingToggles: true,
				item: { type: objectType === enums.objectType.structure ? objectType : enums.objectType.dataRelation, id: null, settings: initialSettings },
				objectSettings: definition.settings,
				onBeforeSubmit: definition.onBeforeSubmit,
				action: function (model) {
					return $q(function (resolve, reject) {
						if (!Object.values(model.settingsToggledState).some(val => val)) {
							resolve()
							return
						}

						for (const key in model.settingsToggledState) {
							if (key.includes("measurePeriodSettings")) continue;

							if (model.settingsToggledState[key] && !model.items.find(x => x.settingKind === key)) {
								model.items.push({ settingKind: key, value: null })
							}
						}

						let requirementsPackagesSettings = [];
						model.items = model.items.map( x => ({settingKind: enums.objectSettingKind[x.settingKind], value: x.value}))

						vm.items.forEach(x => requirementsPackagesSettings.push({
							packageIds: [parseInt(x.childContent.id)],
							objectType: objectType,
							settings: model.items
						}))

						const progressModalScope = $rootScope.$new()
						progressModalScope.progressWidth = 0;
						progressModalScope.loading = true;
						progressModalScope.reload = () => location.reload()
						progressModalScope.savingSettings = true

						modal.open({
							templateUrl: 'scripts/wf/valueChain/popovers/batchObjectChangeProgress.template.html',
							scope: progressModalScope,
							windowClass: "modal-width-500",
							backdrop: 'static',
							keyboard: false,
							onLoaded: function ($scope, $element) {

							}
						});

						const saveObjectSettings = settingsObject => {
							apiProxy("valuechainapi.bulkChangeRequirementsPackageSettings", dataOps.prepareWfObject(settingsObject)).then(res => {
								progressModalScope.progressWidth = ((totalCount - requirementsPackagesSettings.length) / totalCount) * 100
								$timeout()

								if (requirementsPackagesSettings.length) {
									saveObjectSettings(requirementsPackagesSettings.shift())
								} else {
									setTimeout(() => {
										progressModalScope.loading = false;
										$timeout()
									}, 300)
								}
							}).catch(e => {
								progressModalScope.loading = false;
								progressModalScope.error = true;
							})
						}
						const totalCount = requirementsPackagesSettings.length;
						saveObjectSettings(requirementsPackagesSettings.shift());

						resolve()

					})
				}

			}).then(function () {

			});
		}

		function bulkChangeRequirements(objectType) {
			let requirementSettings = {
				[enums.objectType.question]: vm.questionRequirementSettings.requirementOptions,
				[enums.objectType.measure]: vm.measureRequirementSettings.requirementOptions,
				[enums.objectType.structure]: vm.taskStructureRequirementSettings.requirementOptions
			}[objectType];

			if (objectType === enums.objectType.measure) {
				const firstMeasurePeriodSettings =  { frequency: 1 }
				requirementSettings = requirements.getMeasureSettingsFromPeriodSettings(null, null, null, firstMeasurePeriodSettings).requirementOptions;
			}

			const selectorOptions = _.filter(requirementSettings, function (item) {
				return item.selectable !== false;
			});

			modal.editRequirement({
				showSettingToggles: true,
				item: { type: enums.objectType.dataRelation, childType: objectType, id: null  },
				selectorOptions: selectorOptions,
				action: function (model) {
					return $q(function (resolve, reject) {
						if (!Object.values(model.settingsToggledState).some(val => val)) {
							resolve()
							return
						}

						if (model.rule === undefined) {
							model['rule'] = null
						}

						if (model.settingsToggledState.presetMaxAge && model.maxAgeInDays === "")
						{
							model.maxAgeInDays = -1;
						}
						else {
							model.maxAgeInDays = parseInt(model.maxAgeInDays) || null
						}

						const requirementsToSave = [];
						vm.items.forEach(x => requirementsToSave.push({
							packageIds: [parseInt(x.childContent.id)],
							objectType: objectType,
							requirement: model
						}))

						const progressModalScope = $rootScope.$new()
						progressModalScope.progressWidth = 0;
						progressModalScope.loading = true;
						progressModalScope.reload = () => location.reload()
						progressModalScope.savingRequirements = true

						modal.open({
							templateUrl: 'scripts/wf/valueChain/popovers/batchObjectChangeProgress.template.html',
							scope: progressModalScope,
							windowClass: "modal-width-500",
							backdrop: 'static',
							keyboard: false,
							onLoaded: function ($scope, $element) {

							}
						});

						const saveRequirement = requirementObject => {
							apiProxy("valuechainapi.bulkChangeRequirementsPackageRequirements", dataOps.prepareWfObject(requirementObject)).then(res => {
								progressModalScope.progressWidth = ((totalCount - requirementsToSave.length) / totalCount) * 100
								$timeout()

								if (requirementsToSave.length) {
									saveRequirement(requirementsToSave.shift())
								}
								else {
									setTimeout(() => {
										progressModalScope.loading = false;
										$timeout()
									}, 300)
								}
							}).catch(e => {
								progressModalScope.loading = false;
								progressModalScope.error = true;
							});
						}

						const totalCount = requirementsToSave.length;
						saveRequirement(requirementsToSave.shift());

						resolve();
					});
				},
			}).then(function () {

			});
		}

		function openExportDialog() {
			vm.packagesForExport = vm.items
				.filter(i => i.childContent.conditions.templateId === 75)
				.map(dr => ({ id: dr.childId, title: dr.childContent.title }))
			vm.isRequirementPackageExporterOpen = true
			$timeout();
		}

		function createPackage() {
			var jqDf = $.Deferred();
			modal.createWithRelation({
				simplifyForm: vm.simplifyForm,
				objectType: 71,
				objectTypeWord: $translate.instant("RequirementPackage").toLowerCase(),
				customFormSpecification: vm.customFormSpecification,
				dataRelationOptions: { item1: vm.mainStructure, kind: enums.subItemsKind.childrenByUser },
				additionalDataRelations: [
					{ item1: vm.network, kind: enums.subItemsKind.childrenByUser }
				],
				submitCaption: $translate.instant("Create")
			}).then(function (dataRelation, additionalDataRelations) {
				if (dataRelation) {
					dataOps.saveSettings({
						item: dataRelation.childContent,
						settings: {
							dataRelation: null,
							templateId: 75,
							requirementPackageSettings: JSON.stringify({
								hideRequirementTexts: true,
								useNewReportingUi: true
							})
						}
					}).then(function () {
						apiProxy("multi.saveRequirements", {
							"objectId": dataRelation.childId,
							"objectType": dataRelation.childType,
							"derivedType": null,
							"comment": null,
							"creatorOrganizationId": wfAuth.getOrganizationId(),
							"rule": enums.requirementRule.allSubItemsFulfilled,
							"value": null,
							"maxAgeInDays": null,
							"type": enums.objectType.requirement
						}).then(function (requirement) {
							wfObject.inject(requirement);
							vm.dataRelationsByPackageIds[dataRelation.childId] = _.concat([dataRelation], additionalDataRelations);
							vm.packageDataRelations.push(dataRelation);
							vm.packageDataRelations.push(additionalDataRelations[0]);

							vm.items.unshift(dataRelation);

							jqDf.resolve();
							$timeout();
						});
					});
				}
				else {
					jqDf.resolve();
				}
			});

			return jqDf.promise();
		}

		function recalculateInfluences(requirementPackage) {
			valueChainService.recalculateInfluences({
				title: requirementPackage.title,
				networkId: vm.network.id,
				objectType: requirementPackage.type,
				objectId: requirementPackage.id
			}).then(function (res) {
				if (res) {
					if (!vm.packageStatsByWfid[requirementPackage.wfid]) {
						vm.packageStatsByWfid[requirementPackage.wfid] =  { jobInvocations: { } };
					}

					vm.packageStatsByWfid[requirementPackage.wfid].jobInvocations.activeJob = res;
					vm.packageStatsByWfid[requirementPackage.wfid].jobInvocations.activeStartDate = res.startedAt && moment(res.startedAt).format("YYYY-MM-DD HH:mm");
					vm.packageStatsByWfid[requirementPackage.wfid].jobInvocations.activePercentageProgress = 0;

					startGettingPackagesStatisticsPeriodically(requirementPackage.id)

					$timeout();
				}
			})
		}

		function abortJobInvocation(requirementPackageWfid, jobInvocation) {
			const packageStats = vm.packageStatsByWfid[requirementPackageWfid]

			return valueChainService.abortJobInvocation({ jobInvocationId: jobInvocation.id }).then(function (res) {
				packageStats.jobInvocations.activeJob = undefined;
				packageStats.jobInvocations.latestEndedJob = jobInvocation;
				packageStats.jobInvocations.latestEndedJobStartDate = moment(jobInvocation.createdAt).format("YYYY-MM-DD HH:mm")
				packageStats.jobInvocations.latestEndedJobDuration = formatDuration(jobInvocation.createdAt, new Date())
				$timeout()
			})
		}

		function openInfluencesCreatorModal(requirementPackage) {
			return valueChainService.openInfluencesCreator({
				title: requirementPackage.title,
				networkId: vm.network.id,
				objectType: requirementPackage.type,
				objectId: requirementPackage.id,
				useInternal: vm.useInternalPackages,
			}).then(function (res) {
				if (res) {
					startGettingPackagesStatisticsPeriodically(requirementPackage.id)

					$timeout();
				}
			})
		}

		function openInfluencesUpdaterModal(requirementPackage) {
			return valueChainService.openInfluencesUpdater({
				title: requirementPackage && requirementPackage.title,
				networkId: vm.network.id,
				objectType: requirementPackage && requirementPackage.type,
				objectId: requirementPackage && requirementPackage.id,
				useInternal: vm.useInternalPackages,

			}).then(function (res) {
				if (res) {
					startGettingPackagesStatisticsPeriodically(requirementPackage && requirementPackage.id)

					$timeout();
				}
			})
		}

		function openInfluencesDeleterModal(requirementPackage) {
			return valueChainService.openInfluencesDeleter({
				title: requirementPackage && requirementPackage.title,
				networkId: vm.network.id,
				objectType: requirementPackage && requirementPackage.type,
				objectId: requirementPackage && requirementPackage.id,
				useInternal: vm.useInternalPackages,

			}).then(function (res) {
				if (res) {
					startGettingPackagesStatisticsPeriodically(requirementPackage && requirementPackage.id)

					$timeout();
				}
			})
		}

		function openStandardPackagesPicker() {
			let sourceItemId;
			let sourceItemFirstLevelAsFilter = false;

			if (vm.useInternalPackages) {
				sourceItemId = enums.ids.common.standardInternalPackagesPicker
			}
			else {
				sourceItemId = {
					"Sustainability Management": enums.ids.common.standardPackagesPicker_MgmtAndInvestments,
					"Sustainable Investments": enums.ids.common.standardPackagesPicker_MgmtAndInvestments,
					"Sustainable Sourcing": enums.ids.common.standardPackagesPicker_Sourcing,
				}[authSolutionType]

				if (authSolutionType !== "Sustainable Sourcing") {
					sourceItemFirstLevelAsFilter = true
				}
			}
			var pickerPromise = modal.openCreatorAndPicker({
				sourceItem: `71-${sourceItemId}`,
				sourceItemFirstLevelAsFilter: sourceItemFirstLevelAsFilter,
				openerButtonTranslate: "Select",
				create: false,
				title: $translate.instant(vm.useInternalPackages ? "modules.valueChain.demands.standardInternalPackages.pickerHeader" : "modules.valueChain.demands.standardPackages.pickerHeader"),
				relationTarget: [
					{
						item:  `71-${vm.useInternalPackages ? valueChainService.ids.internalPackagesStructure : valueChainService.ids.packagesStructure}`,
						kind: enums.subItemsKind.childrenByUser
					},
					{
						item: vm.network,
						kind: enums.subItemsKind.childrenByUser
					}
				],
			});

			pickerPromise.modal.closed.then(function () {
				const currentItems = vm.items
				const withNewItems = _.intersectionBy(vm.mainStructure.childs, vm.network.childs, "wfcid")
				const onlyNewItems = _.differenceBy(withNewItems, currentItems, "wfcid")
				const onlyNewItemsNetworkRelations = _.intersectionBy(vm.network.childs, onlyNewItems, "wfcid")
				const onlyRemovedItems = _.differenceBy(currentItems, withNewItems, "wfcid")

				onlyNewItems.forEach(dataRelation => {
					const networkRelations = onlyNewItemsNetworkRelations.filter(x => x.wfcid = dataRelation.wfcid);

					vm.dataRelationsByPackageIds[dataRelation.childId] = _.concat([dataRelation], onlyNewItemsNetworkRelations.filter(x => x.wfcid = dataRelation.wfcid));
					vm.packageDataRelations.push(dataRelation);
					vm.packageDataRelations.push(networkRelations[0]);

					vm.items.unshift(dataRelation);
				})

				onlyRemovedItems.forEach(dataRelation => {
					_.remove(vm.items, { wfid: dataRelation.wfid })
					_.remove(vm.packageDataRelations, { wfcid: dataRelation.wfcid, parentType: enums.objectType.structure })
					_.remove(vm.packageDataRelations, { wfcid: dataRelation.wfcid, parentType: enums.objectType.network })
				})

				$timeout();
			});
		}

		function handlePackageStatisticsItem(value) {
			var latestEndedJob, activeJob;
			value.influences.fulfilledCount = value.influences.countByFulfillmentState[enums.fulfillmentState.fulfilled];

			if (value.jobInvocations.previous && value.jobInvocations.previous.length) {
				value.jobInvocations.latestEndedJob = latestEndedJob = value.jobInvocations.previous[0];
				value.jobInvocations.latestEndedJobStartDate = moment(latestEndedJob.createdAt).format("YYYY-MM-DD HH:mm")
				value.jobInvocations.latestEndedJobDuration = formatDuration(latestEndedJob.createdAt, latestEndedJob.endedAt)
			}

			if (value.jobInvocations.active && value.jobInvocations.active.length) {
				value.jobInvocations.activeJob = activeJob = value.jobInvocations.active[0];
				value.jobInvocations.activeStartDate = activeJob.startedAt && moment(activeJob.startedAt).format("YYYY-MM-DD HH:mm");
				value.jobInvocations.activePercentageProgress = activeJob.startedAt ? activeJob.totalCount === 0 ? 0 : (activeJob.progressCount + activeJob.errorsCount) / activeJob.totalCount * 100 : 1;
				// value.jobInvocations.activeJobDuration = moment.utc(moment( something  ).diff(moment(activeJob.createdAt))).format("H[h] m[m] s[s]");

			}

			if (value.influences.totalCount > 0) {
				value.influences.percentageFulfilled = _.round(value.influences.fulfilledCount / value.influences.totalCount * 100, 1);
				value.influences.percentageOverdue = _.round(value.influences.overdueCount / value.influences.totalCount * 100, 1);
			}
			else {
				value.influences.percentageFulfilled = 0;
				value.influences.percentageOverdue = 0;
			}
		}

		function startGettingPackagesStatisticsPeriodically(newPackageIdToCheck) {

			if (!vm.periodicStatsCheckingStarted) {
				vm.packageIdsWithActiveJobs.push(newPackageIdToCheck);
				getPackagesStatisticsPeriodically();
			}
			else {
				if (vm.packageStatsRequestLoading)
					vm.packageIdsWithNewlyStartedJobs.push(newPackageIdToCheck);
				else
					vm.packageIdsWithActiveJobs.push(newPackageIdToCheck);
			}
		}

		function getPackagesStatisticsPeriodically() {
			setTimeout(function () {
				if ($scope.$$destroyed)
					return;

				vm.packageStatsRequestLoading = true;
				valueChainService.getRequirementPackagesStatistics({
					networkId: vm.network.id,
					objectIds: vm.packageIdsWithActiveJobs,
					objectType: enums.objectType.structure,
					isInternal: vm.useInternalPackages,
				}).then(function (res) {
					vm.packageIdsWithActiveJobs = _.chain(res)
						.filter(function (value, key) {
							handlePackageStatisticsItem(value);
							return value.jobInvocations.activeJob;
						})
						.mapValues(function (value) {
							return parseInt(value.objectId);
						})
						.map()
						.value();

					_.assign(vm.packageStatsByWfid, res);

					if (vm.packageIdsWithNewlyStartedJobs.length) {
						Array.prototype.push.apply(vm.packageIdsWithActiveJobs, vm.packageIdsWithNewlyStartedJobs);
						vm.packageIdsWithNewlyStartedJobs.length = 0;
						vm.packageIdsWithActiveJobs = _.uniq(vm.packageIdsWithActiveJobs);
					}

					vm.packageStatsRequestLoading = false;

					if (vm.packageIdsWithActiveJobs.length)
						getPackagesStatisticsPeriodically();
					else
						vm.periodicStatsCheckingStarted = false;


					$timeout();
				});
			}, vm.periodicStatsCheckingStarted ? 7500 : 0);

			vm.periodicStatsCheckingStarted = true;
		}

		function getActiveOrLatestJob(wfid) {
			const obj = vm.packageStatsByWfid[wfid]
			if (obj && obj.jobInvocations) {
				return obj.jobInvocations.activeJob || obj.jobInvocations.latestEndedJob
			}
		}

		function isItemOwned(item) {
			return item.creatorOrganizationId === authOrgId
		}

		function onRequirementPackageExporterClosed() {
			vm.isRequirementPackageExporterOpen = false
			$timeout()
		}

		function closeCalculationScheduleDialog() {
			vm.isCalculationScheduleDialogOpen = false
			$timeout()
		}

		function openCalculationScheduleDialog(packageId) {
			return valueChainService.getCalculationSchedule({
				networkId: vm.network.id,
				packageType: enums.packageType.requirementPackage,
				packageId: packageId,
				days: [],
			}).then((result) => {
				const calculationScheduleDays = {}
				Object.entries(enums.daysOfWeek).forEach(([day, number]) => {
					calculationScheduleDays[day] = result.includes(number)
				})

				vm.calculationScheduleDays = calculationScheduleDays
				vm.isCalculationScheduleDialogOpen = true
				vm.calculationSchedulePackageId = packageId
				$timeout()
			})
		}

		function onSaveCalculationSchedule(newCalculationScheduleDays) {
			vm.isCalculationScheduleDialogOpen = false
			vm.calculationScheduleDays = newCalculationScheduleDays

			const days = []
			Object.entries(newCalculationScheduleDays).forEach(([key, value]) => {
				if(value) {
					days.push(enums.daysOfWeek[key]);
				}
			})

			valueChainService.setCalculationSchedule({
				networkId: vm.network.id,
				packageType: enums.packageType.requirementPackage,
				packageId: vm.calculationSchedulePackageId,
				days: days,
			})

			$timeout()
		}
	}
})();
