(function() {
'use strict';

	angular
		.module('wf.common')
		.controller('PromotionController', PromotionController);

	PromotionController.$inject = [ '$scope', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', 'formSchemaService', '$q', 'wfTranslate', '$timeout', '$stateParams', '$rootScope', 'apiProxy', 'wfAuth', '$sanitize', '$state', '$compile', '$ngBootbox', '$interpolate' ];
	function PromotionController($scope, dataOps, modal, wfObject, dataQuery, $translate, formSchemaService, $q, wfTranslate, $timeout, $stateParams, $rootScope, apiProxy, wfAuth, $sanitize, $state, $compile, $ngBootbox, $interpolate) {
		var
			vm = this,
			objectId,
			element,
			conditions,
			idByNameRef = {
				"Manage": 13425,
				"Visualize": 13426,
				"ValueChain": 13427,
				"IsoStandards": 13424
			},
			currentNameRef
		;

		_.assign(vm, {
			//Variables
			loaded: true,
			isContentCompiled: false,


			//Functions
			openVideo: openVideo,
			primaryAction:primaryAction,
			contactToOrder: contactToOrder,

			//Invoice Functions
			openOrderProcess: openOrderProcess,
		});


		activate()

		function activate() {
			element = $("div.promo-container");
			element.html("");

			if ($stateParams.nameRef) {
				currentNameRef = $stateParams.nameRef
				objectId = idByNameRef[currentNameRef];
			}
			else if ($stateParams.objectId)
				objectId = $stateParams.objectId;
			
			if (objectId) {
				// Prevents the loader gif from blinking if the object is already in cache
				$timeout(function () {
					if (!vm.mainStructure)
						vm.loaded = false;
				}, 0);

				dataOps.getObject({
					objectId: objectId,
					objectType: 71
				}).then(function (res) {
					vm.mainStructure = res;
					$rootScope.setPageTitle(res.title);
					conditions = res.conditions;

					if (conditions) {
						vm.color = conditions.color1;
					}

					element.hide();
					element.html(res.promotionHtml);
					$compile(element.contents())($scope);

					// Content shows for a split second without being compiled.
					// Fixed with a timeout.
					setTimeout(function () {
						element.show();
					}, 0);

					vm.loaded = true;
					$timeout();
				});
			}
		}

		function primaryAction() {
			openMainPrompt({
				header: $translate.instant("modules.promotion.demoBookingPrompt.header", { name: vm.mainStructure.title }),
				description: $translate.instant("modules.promotion.demoBookingPrompt.description"),
				confirmationText: $translate.instant("modules.promotion.demoBookingPrompt.confirmationText"),
				errorMessage:  $translate.instant("modules.promotion.demoBookingPrompt.errorMessage")
			});
		}

		function contactToOrder() {
			openMainPrompt({
				header: vm.mainStructure.title,
				description: $translate.instant("modules.promotion.orderContactPrompt.description"),
				confirmationText: $translate.instant("modules.promotion.orderContactPrompt.confirmationText"),
				errorMessage:  $translate.instant("modules.promotion.orderContactPrompt.errorMessage")
			});
		}

		function openMainPrompt(options) {
			var
				user = wfAuth.getWorldfavorUser(),
				currentUserInterpolateFunc = $interpolate("<div class='pt5 pb15'><div class='text-bold'>{{userName}}</div><div>{{userEmail}}</div></div>")
			;
			var formSpec = {
				schema: {},
				form: [
					{
						type: "help",
						helpvalue: options.description + "<br /><br /><br />"
					},
					// {
					// 	type: "help",
					// 	helpvalue: currentUserInterpolateFunc({ userName: $sanitize(user.name), userEmail: $sanitize(user.email)})
					// },
					"phone",
					"message"
				]
				// onBeforeRender: function (model) {
				// },
				// onBeforeSubmit: function (model) {
				// }
			};

			formSpec.schema = {
				"type": "object",
				"properties": {
					"phone": {
						"title": $translate.instant("PhoneNumber"),
						"type": "string"
					},
					"message": {
						"title": $translate.instant("modules.promotion.demoBookingPrompt.messageInputLabel"),
						"type": "string",
						"x-schema-form": {
							type: "textarea"
						}
					}
				}
			}

			modal.edit({
					phone: user.phone_number
				},
				{
					title: options.header,
					action: function (model) {
						return $q(function (resolve, reject) {
							var
								organization = wfAuth.getOrganization(),
								payload = {
									userName: user.name,
									userEmail: user.email,
									orgName: organization.name,
									orgId: organization.id,
									orgNumber: organization.registrationNumber,
									phone: user.phone_number,
									message: model.message,
									for: currentNameRef
								}
							;
							
							// payload = { test: 123 };

							// console.log(payload);
							// setTimeout(function () {
							// 	resolve(payload);
							// }, 1000);

							$.ajax({
								method: "POST",
								url: "https://hooks.zapier.com/hooks/catch/30695/mzi4wm/",
								data: payload,
								xhrFields: {
									withCredentials: false
								}
							}).then(function (res) {
								resolve(res);
							}).fail(function (res) {
								var message = options.errorMessage;

								$ngBootbox.customDialog({
									message: '<i class="fa fa-exclamation-circle bootbox-icon"></i><div class="bootbox-text">' + $sanitize(message) + "</div>",
									// onEscape: true,
									closeButton: false,
									className: "centerWithIcon",
									buttons: {
										primary: {
											label: $translate.instant('OK'),
											className: "btn-primary"
										}
									}
								});

								reject();
							});
						});
					},
					customFormSpecification: formSpec,
					submitCaption: $translate.instant("modules.promotion.demoBookingPrompt.submitButtonCaption")
				}
			).then(function () {
				$ngBootbox.customDialog({
					message: '<div class="text-center pt40 pb30 mr20">' + options.confirmationText + "</div>",
					// onEscape: true,
					closeButton: false,
					buttons: {
						primary: {
							label: $translate.instant('OK'),
							className: "btn-primary"
						}
					}
				});
			});

			// https://hooks.zapier.com/hooks/catch/30695/mzi4wm/

			// { userName: 'Purist Thunderwrath the Omniknight', userEmail: 'omniknight@gmail.com', orgName: 'Omniscience', orgId: 1, orgNumber: '5555555-5555', phone: '+993016849512', message: 'My hammer is ready in the service of the All-knowing One.', for: 'ValueChain' }

		}

		function openVideo(url) {
			var wistiaInterpolateFunc = $interpolate('<div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="{{url}}" title="Wistia video player" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="100%"></iframe></div></div>');

			// url = "//fast.wistia.net/embed/iframe/wa3nbqafeg?videoFoam=true";
			$ngBootbox.customDialog({
				title: vm.mainStructure.title,
				message: wistiaInterpolateFunc({ url: url }),
				animate: false,
				// onEscape: true,
				closeButton: true,
				className: "modal-width-900"
			});
		}

		function openOrderProcess(productCatalogue) {
			modal.startOrdering(productCatalogue);
		}
	}
})();
