import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { hexToRgbA } from '@worldfavor/constants/colors'
import Image from '@worldfavor/components/Image'
import classNames from 'classnames'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'

const influenceTargetsStyles = makeStyles(theme => ({
  label: {
    color: hexToRgbA(theme.palette.common.black, 0.54),
  },
  targetsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  target: {
    marginBottom: 3,
  },
  targetInsidePoper: {
    marginBottom: 8,
  },
  poper: {
    padding: 12,
  },
  moreTargetsIndicator: {
    marginLeft: 16,
    fontSize: theme.typography.fontSizes.medium,
    fontWeight: theme.typography.fontWeights.light,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
}))

const targetStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  targetName: {
    fontWeight: theme.typography.fontWeights.bold,
    color: theme.palette.text.primary,
  },
  textCut: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '175px',
  },
}))

const Target = (props) => {
  const { target, className, showImage } = props
  const classes = targetStyles(props)
  return (
    <div className={classNames(classes.root, className)}>
      {
        showImage && (
          <Image
            src={target.imageUrl}
            style={{ width: 20, height: 20, borderRadius: 2, marginRight: 6 }}
            size={'cover'}
          />
        )
      }
      <span className={classNames(classes.targetName, classes.textCut)}>{target.name}</span>
    </div>
  )
}

const InfluenceTargets = (props) => {
  const { targets, label } = props
  const classes = influenceTargetsStyles(props)
  const [anchorEl, setAnchorEl] = useState(null)
  const targetsListLimit = 2
  const targetsListExceedLimit = targets.length > targetsListLimit
  let targetsList = targets
  
  if (targetsListExceedLimit) {
    targetsList = targets.slice(0, targetsListLimit)
  }

  const isPoperOpen = Boolean(anchorEl && targetsListExceedLimit)

  function handlePopoverOpen(event) {
    setAnchorEl(event.currentTarget)
  }

  function handlePopoverClose() {
    setAnchorEl(null)
  }

  function handleOnClick(event) {
    event.stopPropagation()
    event.preventDefault()
  }

  return (
    <div
      onClick={handleOnClick}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      className={classNames({ [classes.cursorPointer]: targetsListExceedLimit })}
    >
      <span className={classes.label}>
        {label}:
      </span>
      <div className={classes.targetsWrapper}>
        <div className={classes.targets}>
          {
            targetsList.map((target, index) => (
              <Target
                target={target}
                key={`target-${index}`}
                className={{ [classes.target]: targets.length - 1 > index }}
              />
            ))
          }
        </div>
        {
          targetsListExceedLimit && (
            <div className={classes.moreTargetsIndicator}>
              +{targets.length - targetsListLimit}
            </div>
          )
        }
      </div>
      {
        targetsListExceedLimit && (
          <Popper
            id="mouse-over-popover"
            open={isPoperOpen}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            placement={'bottom-start'}
          >
            <Paper>
              <div className={classes.poper}>
                {
                  targets.map((target, index) => (
                    <div key={`target-${index}`}>
                      <Target target={target} className={{ [classes.targetInsidePoper]: targets.length - 1 > index }} />
                    </div>
                  ))
                }
              </div>
            </Paper>
          </Popper>
        )
      }
    </div>
  )
}

export default InfluenceTargets
