import React from 'react'
import ChangeOrganization from './scenes/ChangeOrganization'
import Product from './scenes/Product'
import Influence from './scenes/Influence'
import NetworksLandingScene from './scenes/NetworksLandingScene'
import InfluencesScene from './scenes/InfluencesScene'
import ProductsScene from './scenes/ProductsScene'
import NetworkInfluencesScene from './scenes/NetworkInfluencesScene'
import DataExplorerScene from './scenes/DataExplorerScene'
import DataManagerScene from './scenes/DataManagerScene'
import ThirdPartyRequestsScene from './scenes/ThirdPartyRequestsScene'
import AnalyzeResultsScene from './scenes/AnalyzeResultsScene'
import { ConstVars } from '@worldfavor/constants'

/**
 * List all possible routes handled by react.
 * If one route doesn't seem to work, refer to AngularWrapper
 * and the compatibility layer between ui-router from Angular
 * and react-router, for possible issues.
 */
const Routes = [
  {
    name: 'changeOrganization',
    path: '/r/account/organizations',
    exact: true,
    component: ChangeOrganization,
  },
  {
    name: 'networkLandingScene',
    path: '/exchanges',
    exact: true,
    component: NetworksLandingScene,
  },
  {
    name: 'receivedNetworkInfluences',
    path: '/exchanges/:networkWfid',
    component: NetworkInfluencesScene,
    exact: true,
  },
  {
    name: 'reactinfluenceView',
    path: '/exchange/:influenceWfid',
    component: Influence,
    exact: true,
  },
  {
    name: 'product',
    path: '/exchange/:influenceWfid/product/:productWfid',
    exact: true,
    component: Product,
  },
  {
    name: 'productInSolution',
    path: '/valuechain/:networkId/product/:productWfid',
    exact: true,
    component: Product,
  },
  {
    name: 'reactNetworkExchangeList',
    path: '/valuechain/:networkId/exchanges',
    component: InfluencesScene,
    exact: true,
  },
  {
    name: 'thirdPartyRequests',
    path: '/valuechain/:networkId/third-party-exchanges',
    component: ThirdPartyRequestsScene,
    exact: true,
  },
  {
    name: 'reactNetworkProductList',
    path: '/valuechain/:networkId/products',
    component: ProductsScene,
    exact: true,
  },
  {
    name: 'reactNetworkExchangeListInSolution',
    path: ConstVars.dataManagerPath + ConstVars.exchanges,
    component: InfluencesScene,
    exact: true,
  },
  {
    name: 'reactNetworkProductListInSolution',
    path: ConstVars.dataManagerPath + ConstVars.products,
    component: ProductsScene,
    exact: true,
  },
  {
    name: 'reactNetworkThirdPartyExchangeListInSolution',
    path: ConstVars.dataManagerPath + ConstVars.thirdParty,
    component: ThirdPartyRequestsScene,
    exact: true,
  },
  {
    name: 'dataExplorer',
    path: '/data-explorer',
    component: DataExplorerScene,
    exact: true,
  },
  {
    name: 'dataManagerOrganizations',
    path: ConstVars.dataManagerPath + ConstVars.organizations,
    render: () => <DataManagerScene contentType={ConstVars.organizations} />,
    exact: true,
  },
  {
    name: 'dataManagerContributors',
    path: ConstVars.dataManagerPath + ConstVars.contributors,
    render: () => <DataManagerScene contentType={ConstVars.contributors} />,
    exact: true,
  },
  {
    name: 'dataManagerCategorizations',
    path: ConstVars.dataManagerPath + ConstVars.categorizations,
    render: () => <DataManagerScene contentType={ConstVars.categorizations} />,
    exact: true,
  },
  {
    name: 'dataManagerAnalyzeJobs',
    path: ConstVars.dataManagerPath + ConstVars.analyzeJobs,
    render: () => <DataManagerScene contentType={ConstVars.analyzeJobs} />,
    exact: true,
  },
  {
    name: 'dataManagerAnalyzeResults',
    path: ConstVars.dataManagerPath + ConstVars.analyzeResults,
    component: AnalyzeResultsScene,
    exact: true,
  },
]

export default Routes
