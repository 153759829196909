import * as enums from '@worldfavor/constants/enums'

(function() {
'use strict';

	angular
		.module('wf.common')
		.controller('HierarchicalController', HierarchicalController);

	HierarchicalController.$inject = [ '$scope', '$element', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', 'formSchemaService', '$q', '$injector', 'wfTranslate', '$timeout', '$rootScope', 'apiProxy', 'wfAuth', 'wfItemToolsService', '$state', '$compile', '$window', 'requirements', 'pdfGenerator', '$uibModal' ];
	function HierarchicalController($scope, $element, dataOps, modal, wfObject, dataQuery, $translate, formSchemaService, $q, $injector, wfTranslate, $timeout, $rootScope, apiProxy, wfAuth, wfItemToolsService, $state, $compile, $window, requirementService, pdfGenerator, $uibModal) {
		var
			vm = this,
			previousPageTitle = document.title,
			authOrgId = wfAuth.getOrganizationId(),
			authUserId = wfAuth.getWorldfavorUserId(),
			orgAccessAdminMode = false,
			objectId = -1,
			objectType = enums.objectType.dataRelation,
			initialJumpObject,
			initialIntersectionObject,
			requirementUiMode,
			childrenLoadDepth = $scope.limitDepth || 10,
			// infiniteScrollChunkSize = 10,
			// useInfiniteScroll = true,
			mainStructureConditions,
			requestTicket,
			ignoreChildrenOfParentWfids,
			prePromise,
			includeRelatedContentAsChildren = false, // If true, makes relatedContent appear as children in the hierarchy
			useVirtualDataRelationOnRootLevel = false,
			mainContextParentItems = [], // Array of main contextParent items. Can be specified by an influence (influence.contextParentWfids) if opened via wfHierarchical directive.
			customChildrenGetter, // Module for working with custom children on items (Module pattern)
			hierarchyFilter = undefined,
			additionalRelationTargetWhenExtending,
			userDataObjectTypes = _.keyBy([
				enums.objectType.orgActivity,
				enums.objectType.questionAnswer,
				enums.objectType.measureAnswer,
				enums.objectType.orgDocument,
				enums.objectType.statement,
				enums.objectType.link,
				enums.objectType.embed,
				enums.objectType.dateItem,
				enums.objectType.certificate,
				enums.objectType.person
			]),
			words = {
				all: $translate.instant("All")
			},
			materialityCategoryWfids = [ "71-11967", "71-11968", "71-11969" ],
			deviationCategoryWfids = [ "71-15244", "71-15245", "71-15246", "71-15247", "71-15248" ],
			scopeCategoryWfids = [ "71-19237", "71-19238" ],

			childrenRelationType = enums.objectType.dataRelation, // The type used to query children on each level.
			relationIntersectionTargetWfid = null,
			intersectionSettings, // Standardized object containing organizationId (integer), networkId (integer) and contextParents (string array)

			aggregatedParents = {},
			originalItems = {
				all: [],
				byWfid: {}
			},

			// Arrays
			allItems = [], // All items in the hierarchy based on itemPrototype
			levels = [], // Levels based on levelPrototype
			itemsWithFilteredChildren = [],
			allCascadingItems = [],
			cascadingItems = [],

			// Lookup objects
			originalAllItemsByWfid,
			itemsByDepth = {},
			itemsByWfid = {}, // All items grouped by wfid. The value of each property is an array and not an actual item because of
			                  // the possibility of data to appear in multiple places in the hierarchy (tagged user data via "Add information" button)

			itemsByParentWfid = {}

			// allItems = {
			// 	dataRelations: [],
			// 	childContents: [],
			// 	childWfids: []
			// }
		;
		var // Prototypes

			// Used for filter bars on each level in the hierarchy
			levelPrototype = {
				depth: -1,
				searchString: "",
				items: [],
				itemsByAnything: {},
				selectedFilter: null,
				allFilterButtons: undefined,
				currentFilterButtons: undefined,
				setFilter: levelPrototype_setFilter,
				scrollToTopAndSetFilter: levelPrototype_scrollToTopAndSetFilter,
				resetFilter: levelPrototype_resetFilter
			},

			// Used for each item in the hierarchy
			itemPrototype = {
				composite: true,
				wfid: undefined, // Wfid of the childContent
				relationWfid: undefined, // Wfid of dataRelation
				parentWfid: undefined, // Wfid of parent
				parent: undefined,
				depth: -1,
				order: -1, // Order value from dataRelation
				absoluteOrder: undefined,
				searchSource: "",
				type: undefined, // Type of childContent (childType)
				content: undefined, // ChildContent
				dataRelation: undefined, // DataRelation
				belongsTo: undefined,
				childs: undefined, // Children of childContent (also instances of itemPrototype)
				filteredChildren: undefined, // Filtered children - what will be shown in the view - is determined in applySelectedFilters()
				intersected: false,
				highlight: false,
				originalRelation: undefined, // When the hierarchy is intersected in the front-end, this property holds the dataRelation that is the child of the intersectionObject
				// hasRequirement: false,

				isUserData: false,
				hasChildren: false,
				hasParents: false,
				childrenToggled: true,
				include: true,
				toggleChildren: itemPrototype_toggleChildren,
				updateMetadata: itemPrototype_updateMetadata,
				updateFulfillment: itemPrototype_updateFulfillment,
				getNthParent: levelPrototype_getNthParent,
				updateChildren: function () {
					updateChildrenOfItem(this)
				},
				relatedContent: undefined,
				numbering: undefined // Used to display item numbering, like 1.1, 1.2, 1.2.3 etc
			}
		;


		_.assign(vm, {
			loaded: false,
			$scope: $scope,
			orgAccessAdminMode: false,
			rootItem: undefined, // ?
			mainStructure: undefined,
			organizationId: authOrgId,
			authOrgId: authOrgId,
			influenceOpenedByCreator: false,
			sunburstControl: {
				onLoaded: onSunburstLoaded
			},
			tagMultipleTooltiptext: $translate.instant("TagMultipleTooltip"),
			questionAnsweringManager: {},
			measureAnsweringManager: {},
			requirementFulfillmentManager: {},
			window: $window,
			hideFilters: false,
			aggregatedParents: aggregatedParents,
			searchSourceItems: [],
			pageHeaderObject: undefined,
			pagePreTitle: undefined,
			intersectionObject: undefined,
			answeringUiMode: enums.uiMode.work,
			includeInList: false,
			influence: undefined,
			influenceMode: false,
			isWorkMode: true,
			filtersElement: undefined,
			showAllActiveFilters: false,
			hasFindingsItems: false,

			// Page heading
			showPageHeading: true,
			showBackButton: true,
			showPdfExportButton: false,
			showParentsStatistics: true,

			// Main column and wf-hierarchical-item
			showFilterBars: true,
			showQuestionAnswerStatistics: true,
			showRequirements: true,
			showLevelsFilterBar: true,
			showItemNumbering: false,
			showMetadata: true,

			// Side panels
			showSigning: false,
			showLinkagesPanel: false,
			showFulfillment: false,
			showInfluenceSigningPanel: undefined,
			showFullfilmentProgress: undefined,
			showContextStructureProgress: undefined,
			showMaterialityProgress: undefined,
			showDeviationProgress: undefined,
			showScopeProgress: undefined,
			showCustomFrameworkProgress: undefined,
			showCustomCategoryProgress: undefined,
			showReportingCategoriesProgress: undefined,
			showAnswerTypeProgress: undefined,
			showSunburstChart: undefined,
			showLicense: undefined,

			translate: $translate.instant,
			loadRelatedContentByUserOnAllItems: false,
			infiniteScrollChunkSize: 10,
			useInfiniteScroll: true,
			infiniteScrollContainer: undefined,
			metadataUiMode: enums.uiMode.view,
			orgHasInternalValueChain: false,
			showItemDropdown: true, //not used anywhere
			showItemMeasureChart: false,
			waitForFindingsToLoad: false,
			showAllItemImages: false,
			showTypeSpecificTools: true, // If type specific tools should be displayed. Such as wfQuestionAnswering, wfMeasureAnswering, wfDataAnswering
			showCustomCategories: false,
			mainPanelFullWidth: false,
			hierarchyHasCustomBranchDepths: false,
			ignoreAdditionalLoadDepthLimits: false,
			generatingPdf: false,
			isPrintMode: false,
			showStatsOnTopOfHierarchical: false,

			// Arrays
			allItems: allItems, // All items in the hierarchy
			allCascadingItems: allCascadingItems,
			cascadingItems: cascadingItems,
			levels: levels, // The available levels
			levelFunnels: [], // The currently visible filter bars - one for each level
			measuresToLoad: [],
			findingsToLoad: [],

			// Lookup objects
			itemsByDepth: itemsByDepth,
			itemSettings: {
				// key-values of settings
				// or key-values of settings grouped by type (byType: { 71: { settings }, 11: { settings } })
			},
			allItemSettings: [], // All specified item settings,
			customCategoryProgressBarsByWfid: {},

			// Functions
			openObjectViewer: openObjectViewer,
			openModal_sendTo: openModal_sendTo,
			openModal_assignTask: openModal_assignTask,
			openModal_categorizeTo_materiality: openModal_categorizeTo_materiality,
			openModal_categorizeTo_deviation: openModal_categorizeTo_deviation,
			openModal_categorizeTo_scope: openModal_categorizeTo_scope,
			openModal_categorizeTo_customFrameworks: openModal_categorizeTo_customFrameworks,
			openModal_categorizeTo_customCategories: openModal_categorizeTo_customCategories,
			openModal_addInformation: openModal_addInformation,
			openModal_setRequirement: openModal_setRequirement,
			openModal_addOwnChild: openModal_addOwnChild,
			editItem: editItem,
			deleteItem: deleteItem,
			deleteRelation: deleteRelation,
			setMeasureReportingPeriod: setMeasureReportingPeriod,
			openModal_attachInformation: openModal_attachInformation,
			pagingFunction: pagingFunction,
			onSearch: onSearch,
			toggleItemRelation: toggleItemRelation,
			updateChildrenOfItem: updateChildrenOfItem,
			clearAllFilters: clearAllFilters,
			createMainStructureCategory: createMainStructureCategory,
			createMainStructureReportingCategory: createMainStructureReportingCategory,
			syncMainStructureCategories: syncMainStructureCategories,
			applySelectedFilters: applySelectedFilters,
			emitHierarchicalLoaded: emitHierarchicalLoaded,
			goToAnchor: goToAnchor,
			toggleActiveFilters: toggleActiveFilters
		});

		activate();

		function activate() {
			var
				objectIdParts,
				conditions
			;

			if ($scope.vmPrototype)
				_.assign(vm, $scope.vmPrototype);

			customChildrenGetter = CustomChildrenGetter();
			if ($scope.openedViaDirective) {
				$scope.$parent.hierVm = vm;

				includeRelatedContentAsChildren = $scope.includeRelatedContentAsChildren; // If true, makes relatedContent appear as children in the hierarchy
				vm.transcludeItemSide = $scope.transcludeItemSide;
				vm.useItemInstanceFromDirective = $scope.useItemInstanceFromDirective;
				vm.hooks = $scope.hooks;

				vm.consolidationSettings = $scope.consolidationSettings;
				vm.consolidate = !!vm.consolidationSettings;

				if (vm.consolidate) {
					vm.showSunburstChart = false;
					vm.showFilterBars = false;
				}

				if ($scope.hideFilterBars)
					vm.showFilterBars = false;

				if ($scope.showPdfExportButton)
					vm.showPdfExportButton = true;

				if ($scope.hidePageHeading)
					vm.showPageHeading = false;

				if ($scope.hideSunburstChart)
					vm.showSunburstChart = false;

				if ($scope.hideBackButton)
					vm.hideBackButton = true;

				if ($scope.uiMode) {
					vm.answeringUiMode = $scope.uiMode;
					vm.uiMode = $scope.uiMode;
				}

				if ($scope.uiComponents)
					vm.uiComponents = $scope.uiComponents;

				if ($scope.orgAccessAdmin) {
					orgAccessAdminMode = vm.orgAccessAdminMode = true;
					vm.organizationId = $scope.orgAccessAdmin.orgId;
					vm.userId = $scope.orgAccessAdmin.userId;

					$scope.objectWfid = $scope.orgAccessAdmin.objectWfid; // Is set in users.controller.js
				}

				if ($scope.ticket)
					vm.incomingTicket = $scope.ticket;

				objectIdParts = $scope.objectWfid.split("-");
				if (objectIdParts.length === 2) {
					objectType = parseInt(objectIdParts[0]);
					objectId = parseInt(objectIdParts[1]);
				}

				if ($scope.include) {
					vm.showBackButton = $scope.include.backButton !== false;
				}

				if ($scope.override) {
					if ($scope.override.headerOptions) {
						vm.pageHeaderObject = $scope.override.headerOptions;
						if ($scope.override.headerOptions) {
							vm.pageHeaderPreTitle = $scope.override.headerOptions.preTitle;
							vm.isCustomHeaderPreTitle = true;
						}
					}
				}

				if ($scope.openedInModal) {
					vm.infiniteScrollContainer = "div.modal[uib-modal-window]";
				}

				initialIntersectionObject = $scope.intersectionWfid;

				vm.influence = $scope.influenceFromDirective;
				vm.influenceMode = !!$scope.influenceFromDirective;


				if ($scope.itemFromDirective)
					conditions = $scope.itemFromDirective.conditions;

				if (vm.influenceMode) {
					vm.showSunburstChart = false;
					vm.showItemNumbering = $scope.showNumbering !== false && _.get(conditions, "requirementPackageSettings.showItemNumbering") !== false;
					vm.showQuestionAnswerStatistics = false;
					vm.showFilterBars = false;
					vm.showSigning = true;
					vm.organizationId = vm.influence.organizationId;

					vm.itemSettings.hideRequirementTexts = _.get(conditions, "requirementPackageSettings.hideRequirementTexts") || false;

					if (vm.influence.contextParentWfids) {
						// If opened with an influence then wfInfluenceViewer directive should have already loaded the contextParents
						mainContextParentItems = _.map(vm.influence.contextParentWfids.split(","), function (wfid) {
							return wfObject.get(wfid);
						});
					}

					// console.log(vm.influence.creatorOrganizationId, authOrgId, vm.influence.organizationId);
					// if (authOrgId) {
					// 	if (vm.influence.creatorOrganizationId === authOrgId && (!vm.influence.isInternal || vm.influence.userId !== authUserId)) {
					// 		vm.isWorkMode = false;
					// 		vm.answeringUiMode = enums.uiMode.view;
					// 		requirementUiMode = enums.uiMode.admin;
					// 	}
					// 	else if (vm.influence.organizationId === authOrgId) {
					// 		vm.isWorkMode = true;
					// 		vm.answeringUiMode = enums.uiMode.work;
					// 		requirementUiMode = enums.uiMode.work
					// 	}
					// }
					// else {
					// 	vm.isWorkMode = false;
					// 	vm.answeringUiMode = enums.uiMode.view;
					// 	requirementUiMode = enums.uiMode.view;
					// }
				}

				if (conditions) {
					if (vm.influenceMode) {// Change to condition
						// if (vm.mainStructure.requirements.length > 0) {
						// 	vm.showSigning = true;
						// 	vm.showRequirements = true;
						// }
						if (conditions.intersectionCategoryWfid) {
							childrenRelationType = enums.objectType.virtualDataRelation;
						}
					}



					if (conditions.intersectionSourceWfid) {
						if (conditions.intersectionSourceWfid === $scope.itemFromDirective.wfid)
							useVirtualDataRelationOnRootLevel = true
						else {
							relationIntersectionTargetWfid = $scope.itemFromDirective.wfid;
							childrenRelationType = enums.objectType.virtualDataRelation;
						}

						vm.showSunburstChart = true;
						vm.showItemNumbering = false;
						vm.showRequirements = true;
						vm.showSigning = false;
						vm.showQuestionAnswerStatistics = true;
						vm.showFilterBars = true;
						vm.showInlineRelatedContent = false;
					}
					else {
						vm.showInlineRelatedContent = true;

						if (_.get(conditions, "objectTypes[0]") === enums.objectType.finding) {
							childrenRelationType = enums.objectType.virtualDataRelation;
						}
					}

					if ("showSigning" in conditions) {
						vm.showSigning = conditions.showSigning;
					}
				}

				if ($scope.isPrinting) {
					vm.showSunburstChart = false;
					vm.showRequirements = false;
					vm.showSigning = false;
					vm.answeringUiMode = enums.uiMode.view;
					vm.isWorkMode = false;
					vm.showInlineRelatedContent = true;
					vm.isPrintMode = true;
				}

				if (!vm.isWorkMode) {
					vm.infiniteScrollChunkSize = 1000;
				}
			}
			else if ($injector.has("$stateParams")) {
				$injector.invoke([ "$stateParams", function ($stateParams) {
					vm.ignoreAdditionalLoadDepthLimits = $stateParams.ignoreAdditionalLoadDepthLimits;
					hierarchyFilter = $stateParams.hierarchyFilter;
					includeRelatedContentAsChildren = $stateParams.includeRelatedContentAsChildren;

					if ($stateParams.objectId) {
						objectIdParts = $stateParams.objectId.split("-");
						if (objectIdParts.length === 2) {
							objectType = parseInt(objectIdParts[0]);
							objectId = parseInt(objectIdParts[1]);
						}
						else {
							objectType = enums.objectType.dataRelation;
							objectId = parseInt(objectIdParts);
						}
					}

					initialJumpObject = $stateParams.jump;
					initialIntersectionObject = $stateParams.intersect;
					vm.ignoreAdditionalLoadDepthLimits = !!$stateParams.ignoreDepthLimits;

					if ($stateParams.orgAccessAdmin) {
						orgAccessAdminMode = vm.orgAccessAdminMode = true;
						vm.organizationId = $stateParams.orgId;
						vm.userId = $stateParams.userId;
					}
				} ]);
			}

			if (orgAccessAdminMode) {
				authOrgId = vm.organizationId;
				childrenLoadDepth = 3;
				requestTicket = {
					organizationId: authOrgId
				};
				vm.isCustomHeaderPreTitle = true;

				prePromise = $q(function (resolve, reject) {
					if (vm.userId) { // Organization settings access on its own users (root already loaded)
						resolve();
					}
					else {
						// Worldfavor setting access on organization
						apiProxy("authentication.getOrganizationRoot", { organizationId: authOrgId, culture: wfAuth.getCulture() }).then(function (rootObjectSimplified) {
							objectId = rootObjectSimplified.id;
							objectType = rootObjectSimplified.type;
							resolve();
						});
					}
				});

				if (vm.userId) {
					requestTicket.userId = vm.userId;
					requestTicket.ignoreAccessTagPolicyForUser = true;
					vm.showLevelsFilterBar = false;
					vm.pageHeaderPreTitle = $translate.instant("EditPermissions");
				}
				else {
					ignoreChildrenOfParentWfids = [
						"71-203", // Custom frameworks
						// "71-10013", // Information repo
						// "71-12226", // Data Collector - Overview
						"71-12224", // Data Collector - Catagories
						"71-12225", // Data Collector - Requirement packages
						"71-12365" // Data Collector - Email settings
					];

					requestTicket.ignoreAccessTagPolicyForOrganization = true;
					vm.pageHeaderPreTitle = $translate.instant("EditAccess");
					dataOps.getObject({
						objectType: enums.objectType.organization,
						objectId: vm.organizationId
					}).then(function (org) {
						vm.pageHeaderObject = {
							title: org.name
						}
					});
				}
			}
			else {
				prePromise = $q(function (resolve, reject) {
					dataOps.getObject("71-13593").then(function () {
						vm.orgHasInternalValueChain = !!wfObject.get("71-13593");

						dataOps.getObject("71-203",  {
							objectType: 71,
							objectId: 203,
							childrenLoadDepth: 0
						}).then(function () {
							vm.orgHasCustomFrameworks = !!wfObject.get("71-203");

							resolve();
						});
					});
				});
			}

			if (vm.influence && vm.authOrgId && vm.influence.creatorOrganizationId === vm.authOrgId)
				vm.influenceOpenedByCreator = true;

			// console.log("Loading data")
			// console.time("Data loaded")

			if (prePromise) {
				prePromise.then(function () {
					startInitialLoading();
				});
			}
			else {
				startInitialLoading();
			}

			function startInitialLoading() {
				var initialLoadingPromises = [];
				var rootObject;

				if (vm.influenceMode || vm.useItemInstanceFromDirective) {
					initialLoadingPromises.push($timeout(function () {
						return rootObject = $scope.itemFromDirective;
					}, 0));
					handleResult();
				}
				else {
					initialLoadingPromises.push(dataOps.getObject({
						objectId: objectId,
						objectType: objectType,
						childrenLoadDepth: childrenLoadDepth,
						// readFromCache: true,
						ignoreAdditionalLoadDepthLimits: vm.ignoreAdditionalLoadDepthLimits ? true : undefined, // When opened with server intersection additional load depth limits are always ignored
						getterConditions: {
							dataRelationOrganizationMatchMode: 2,
							contextVariable1: "onlyLatestAnswers",
							applyIntersectionIfPossible: true,
							loadRequirements: true,
							hierarchyFilter: hierarchyFilter
						},
						ticket: requestTicket
					}).then(function (res) {
						rootObject = res;

						if (rootObject.conditions && rootObject.conditions.intersectionSourceWfid) { // Open with server intersection
							vm.ignoreAdditionalLoadDepthLimits = true;
							initialLoadingPromises.push(dataOps.getObject(rootObject.conditions.intersectionSourceWfid, {
								childrenLoadDepth: -1
							}));

							additionalRelationTargetWhenExtending = { item: rootObject, kind: enums.subItemsKind.childrenByUser };
						}

						if (vm.loadRelatedContentByUserOnAllItems) {
							var flatItems = dataQuery.getHierarchyAsList(rootObject);
							initialLoadingPromises.push(dataOps.getSubItemsOfAll(flatItems, enums.subItemsKind.relatedContentByUser, { bypassCache: true }));
						}

						handleResult();
					}));
				}

				function handleResult() {
					$q.all(initialLoadingPromises).then(function () {
						handleInitialData(rootObject).then(function () {
							vm.searchSourceItems = _.filter(originalItems.all, function (item) {
								return item.depth > 1;
							});

							if (vm.influenceMode && vm.influence) {
								handleInfluenceMode();
							}

							if ($scope.vmPrototype) {
								_.assign(vm, $scope.vmPrototype);
							}

							if (vm.intersectionSettings.contextParents && vm.intersectionSettings.contextParents.length)
								vm.intersectionSettings.contextParentWfid =  vm.intersectionSettings.contextParents[0];



							if ($scope.vmPrototype) {
								_.assign(vm, $scope.vmPrototype);
							}

							handleAggregatedParentsByUser();

							if (vm.showRequirements) {
								if (!vm.influenceMode)
									requirementUiMode = enums.uiMode.view;

								vm.requirements = {
									settingsByType: {}
								}

								vm.requirementUiMode = requirementUiMode;

								vm.requirements.settingsByType[enums.objectType.structure + "_main"] = requirementService.getMainStructureSettings(vm.influence ? vm.influence.organizationId : vm.organizationId, requirementUiMode, $scope);
								vm.requirements.settingsByType[enums.objectType.measure] = requirementService.getMeasureSettingsFromPeriodSettings(vm.influence ? vm.influence.organizationId : vm.organizationId, requirementUiMode, $scope, { frequency: enums.calendarFrequency.yearly });
								vm.requirements.settingsByType[enums.objectType.question] = requirementService.getQuestionSettings(vm.influence ? vm.influence.organizationId : vm.organizationId, requirementUiMode, $scope);
								vm.requirements.settingsByType[enums.objectType.parameter] = requirementService.getParameterSettings(vm.influence ? vm.influence.organizationId : vm.organizationId, requirementUiMode, $scope);
								vm.requirements.settingsByType[enums.objectType.structure + "_task"] = requirementService.getTaskStructureSettings(vm.influence ? vm.influence.organizationId : vm.organizationId, requirementUiMode, $scope);
								vm.requirements.settingsByType[enums.objectType.finding] = requirementService.getFindingSettings(vm.influence ? vm.influence.organizationId : vm.organizationId, requirementUiMode, $scope);

								if (!vm.influenceMode || (vm.influence && vm.influence.isInternal)) {
									vm.showFulfillmentIndicatorOnRequirement = true;
									_.each(vm.requirements.settingsByType, function (typeSettings) {
										typeSettings.showFulfillmentIndicator = true;
										typeSettings.hideAdminTools = true;
										typeSettings.enableIndicatorPopover = false;
									});
								}
							}


							updateLocalFulfillmentProgress();
							syncFindingsFilterButtons();

							// if (vm.isCustomFramework || vm.intersectionSourceStructure || (vm.influenceMode && getInfluenceUiMode() === enums.uiMode.admin))
							// 	apiProxy("statistics.countVisibleSubItemsInNetwork", { wfids: _.map(allItems, "wfid") }).then(
							// 		handleConsolidatedDataStats,
							// 		handleConsolidatedDataStats
							// 	);
							// else
								checkOnLoadedHook()

							function handleConsolidatedDataStats(consolidatedDataStats) {
								if (consolidatedDataStats) {
									vm.consolidatedDataCountByWfid = consolidatedDataStats.totals;
									vm.consolidatedDataCountByWfidAndNetwork = consolidatedDataStats.byNetwork;
								}

								checkOnLoadedHook()
							}

							function checkOnLoadedHook() {
								var onLoadedHookPromise;

								if (vm.hooks && vm.hooks.onLoaded) {
									onLoadedHookPromise = vm.hooks.onLoaded(vm);
									if (onLoadedHookPromise && onLoadedHookPromise.then) {
										onLoadedHookPromise.then(function () {
											finish();
										});
										return;
									}
								}

								finish();
							}

							function finish() {
								vm.loaded = true;
								setUiComponentVisibility();
								applySelectedFilters();

								setTimeout(function () {
									if (vm.showItemMeasureChart || vm.waitForFindingsToLoad) {
										vm.measuresToLoad = _.filter(allCascadingItems, { type: enums.objectType.measure });

										if (vm.measuresToLoad.length === 0 && vm.findingsToLoad.length === 0)
											emitHierarchicalLoaded();
									}
									else
										emitHierarchicalLoaded();

									handleTransclusion();
								}, 0);
							}


							// Uncomment below console.logs to get a lookup object of top categories membership

							// (function () {
							// 	var prettyTitles = {
							// 		"GRI 200: Economic 2016": "Economic",
							// 		"GRI 300: Environmental 2016": "Environmental",
							// 		"GRI 400: Social 2016": "Social",
							// 		"GRI 101: Foundation 2016": "Foundation",
							// 		"GRI 102: General Disclosures 2016": "General Disclosures",
							// 		"GRI 103: Management Approach 2016": "Management Approach"
							// 	};

							// 	console.log(_.chain(allItems).map(function (item) {
							// 		var maxReq = 5, parent = item.parent;
							// 		var output, parentWfidsByDepth = {};

							// 		if (!_.includes(item.content.title, "Target"))
							// 			return;

							// 		while (maxReq--) {
							// 			if (parent) {
							// 				parentWfidsByDepth[parent.depth] = parent.wfid;// prettyTitles[_.get(parent, "content.title") || _.get(parent, "content.text")];
							// 				parent = parent.parent;
							// 			}
							// 			else
							// 				break;
							// 		}
							// 		return {
							// 			wfid: item.wfid,
							// 			title: item.content.title,
							// 			parentWfid: parentWfidsByDepth[1]
							// 		};
							// 		// return item.wfid + "\t" + item.content.getMainTextual();
							// 	}).compact().keyBy("wfid").mapValues("parentWfid").value());
							// })();



							// Uncomment below console.logs to get a summary of loaded items in the console.

							// console.log(_.map(allItems, function (item) {
							// 	return item.wfid + "\t" + item.content.getMainTextual();
							// }).join("\n"));

							// console.log("\n######################################################");
							// console.log(vm.mainStructure.title);
							// console.log(vm.mainStructure.wfid)

							// console.info("\nStructures");
							// console.log("ID IN(" + _(allItems).filter({ type: enums.objectType.structure }).map(function (item) {
							// 	return item.content.id;
							// }).value().join(",") + ")\n\n");

							// console.info("Questions");
							// console.log("ID IN(" + _(allItems).filter({ type: enums.objectType.question }).map(function (item) {
							// 	return item.content.id;
							// }).value().join(",") + ")\n\n");

							// console.info("Measures");
							// console.log("ID IN(" + _(allItems).filter({ type: enums.objectType.measure }).map(function (item) {
							// 	return item.content.id;
							// }).value().join(",") + ")\n\n");


							// console.info("\nStructures grouped by ancestor");
							// console.log(_(allItems).filter({ type: enums.objectType.structure }).groupBy(function (item) {
							// 	return item.content.ancestorId;
							// }).mapValues(function (items) { return _.map(items, function (item) { return parseInt(item.content.id); }); }).value());

							// console.info("\nQuestions grouped by ancestor");
							// console.log(_(allItems).filter({ type: enums.objectType.question }).groupBy(function (item) {
							// 	return item.content.ancestorId;
							// }).mapValues(function (items) { return _.map(items, function (item) { return parseInt(item.content.id); }); }).value());

							// console.info("\nMeasures grouped by ancestor");
							// console.log(_(allItems).filter({ type: enums.objectType.measure }).groupBy(function (item) {
							// 	return item.content.ancestorId;
							// }).mapValues(function (items) { return _.map(items, function (item) { return parseInt(item.content.id); }); }).value());
						})

						// setTimeout(function () {
						// 	if (vm.sunburstControl && vm.sunburstControl.onNodeClick) {
						// 		// console.log("nice")
						// 		vm.sunburstControl.onNodeClick(function (d) {
						// 			var levelFilter;


						// 			if (d.wfid === 'root')
						// 				levelFilter = _.find(levels[0].allFilterButtons, { id: "all" });
						// 			else
						// 				levelFilter = _.find(levels[0].allFilterButtons, { id: d.wfid });

						// 			if (levelFilter) {
						// 				levels[0].setFilter(levelFilter);
						// 				$timeout();
						// 			}
						// 		});
						// 	}
						// }, 1000);


						// console.log(allItems);
						// console.log(itemsByWfid[rootRelation.wfcid]);
					});
				}
			}


			$scope.$on("questionAnswerChanged", function ($event, questionData) {
				var itemOccurences = itemsByWfid[questionData.wfid];

				if (vm.sunburstControl && vm.sunburstControl.setQuestionAnswerState)
					vm.sunburstControl.setQuestionAnswerState(questionData.wfid, questionData.latestQuestionAnswerType);
				// console.log(itemOccurences, question);

				if (itemOccurences && itemOccurences.length)
					_.invokeMap(itemOccurences, "updateMetadata");

				setTimeout(function () {
					updateLocalFulfillmentProgress()
				}, 500);
			});

			$scope.$on("measureAnswerChanged", function ($event, data) {
				var itemOccurences = itemsByWfid[data.wfid];

				// console.log(itemOccurences, measure);

				if (itemOccurences && itemOccurences.length)
					_.invokeMap(itemOccurences, "updateMetadata");

				setTimeout(function () {
					updateLocalFulfillmentProgress()
				}, 500);
			});

			$scope.$on("dataAnswerChanged", function ($event, data, skip) {
				if (skip) return;

				var itemOccurences = itemsByWfid[data.itemContent.wfid];

				// console.log(itemOccurences, measure);

				if (itemOccurences && itemOccurences.length)
					_.invokeMap(itemOccurences, "updateMetadata");

				setTimeout(function () {
					updateLocalFulfillmentProgress()
					syncFindingsFilterButtons();
				}, 500);

				$scope.$broadcast("dataAnswerChanged", data, true);
			});

			$scope.$on("manualFulfillmentChanged", function ($event) {
				setTimeout(function () {
					updateLocalFulfillmentProgress()
				}, 500);
			});

			$scope.$on("findingLoaded", function(res) {
				if (vm.waitForFindingsToLoad && vm.findingsToLoad.length > 0) {
					vm.findingsToLoad.splice(_.findIndex(vm.findingsToLoad, { wfid: res.targetScope.vm.finding.wfid }), 1);
					if (vm.findingsToLoad.length === 0 && vm.loaded) {
						setTimeout(function() {
							emitHierarchicalLoaded();
						}, 100);
					}

				}
			});

			vm.onDeleted = function (item) {
				onItemDeleted(item);
			}

			// $scope.$watchCollection("vm.searchResultItems", function (items) {
			// 	console.log(items ? items.length : undefined);

			// 	if (items && "length" in items) {
			// 		vm.aggregatedParents.itemsByParentWfid["search"] = items;
			// 		vm.aggregatedParents.selectedFiltersById["search"] = {
			// 			id: "search",
			// 			type: "searchFilter"
			// 		};
			// 		vm.aggregatedParents.toggleFilter();
			// 	}
			// })
		}

		function setUiComponentVisibility() {
			var contextVariable1 = _.get(vm, "mainStructure.conditions.contextVariable1");

			_.assign(vm, {

				showLevelsPanel: vm.isWorkMode && vm.showLevelsFilterBar,

				// Filter Bars
				showMaterialityFilterBar: (!vm.influenceMode || vm.showFilterBars) && !vm.orgAccessAdminMode,
				showDeviationFilterBar: (!vm.influenceMode || vm.showFilterBars) && !vm.orgAccessAdminMode,
				showScopeFilterBar: (!vm.influenceMode || vm.showFilterBars) && !vm.orgAccessAdminMode,
				showCustomFrameworkFilterBar: (!vm.influenceMode || vm.showFilterBars) && !vm.orgAccessAdminMode,
				showCustomCategoryFilterBar: (!vm.influenceMode || vm.showFilterBars) && !vm.orgAccessAdminMode,
				showCustomReportFilterBar: (!vm.influenceMode || vm.showFilterBars) && !vm.orgAccessAdminMode,
				showUserFilterBar: (!vm.influenceMode || vm.showFilterBars) && !vm.orgAccessAdminMode,
				showAnswerTypeFilterBar: vm.showQuestionAnswerStatistics && vm.aggregatedParents.answerTypeFilterButtons.length > 0,
				showRelatedContentFilterBar: (!vm.influenceMode && vm.showFilterBars) && !vm.orgAccessAdminMode,
				showViewModeOptions: !vm.influenceMode && vm.showFilterBars && vm.mainStructure.conditions && contextVariable1 == 'GRI_STANDARDS',
				showDepthOptions: !vm.influenceMode && vm.showFilterBars && contextVariable1 == 'GRI_STANDARDS' && vm.hierarchyHasCustomBranchDepths,
				showSelectedFilters: vm.showFilterBars,
				showFulfillmentFilterBar: !vm.orgAccessAdminMode && !vm.consolidate,
				showFindingsFilterBar: vm.hasFindingsItems, //To be done, set the proper options

				// Side panels
				showFindingsStatsPanel: vm.hasFindingsItems && vm.filteredFindingsCount,
				showInfluenceSigningPanel: vm.influenceMode && (vm.showFulfillment || vm.showSigning),
				showFullfilmentProgress: !vm.influenceMode && vm.isWorkMode && !vm.orgAccessAdminMode && !vm.consolidate,
				showContextStructureProgress: (!vm.influenceMode || vm.showFilterBars) && vm.isWorkMode && !vm.orgAccessAdminMode,
				showMaterialityProgress: (!vm.influenceMode || vm.showFilterBars) && vm.isWorkMode && !vm.orgAccessAdminMode,
				showDeviationProgress: (!vm.influenceMode || vm.showFilterBars) && vm.isWorkMode && !vm.orgAccessAdminMode,
				showScopeProgress: (!vm.influenceMode || vm.showFilterBars) && vm.isWorkMode && !vm.orgAccessAdminMode,
				showCustomFrameworkProgress: (!vm.influenceMode || vm.showFilterBars) && vm.isWorkMode && !vm.orgAccessAdminMode,
				showCustomCategoryProgress: (!vm.influenceMode || vm.showFilterBars) && vm.isWorkMode && !vm.orgAccessAdminMode && vm.showCustomCategories,
				showReportingCategoriesProgress: (!vm.influenceMode || vm.showFilterBars) && vm.isWorkMode && !vm.orgAccessAdminMode && vm.showCustomCategories,
				showAnswerTypeProgress: vm.rootItem.childs.length && vm.showQuestionAnswerStatistics && !vm.orgAccessAdminMode,
				showSunburstChart: vm.rootItem.childs.length && !(vm.showSunburstChart === false) && !vm.orgAccessAdminMode,
				showLicense: vm.mainStructure.license
			});

			if (vm.uiComponents && !_.isEmpty(vm.uiComponents)) {
				_.assign(vm, vm.uiComponents);
			}
		}

		function emitHierarchicalLoaded() {
			$scope.$emit("hierarchicalLoaded");
		}

		function syncFindingsFilterButtons() {
			var filterGroups;

			vm.findings = _.chain(vm.allItems).filter(function(item) {
				return item.type === enums.objectType.finding && item.dataRelation.parentData1 === null;
			}).value();

			if (vm.findings.length)
				vm.findings = _.uniqBy(vm.findings, 'wfid');
			else
				return;

			filterGroups = getFilterGroups();

			if (!vm.filteredFindingsCount)
				vm.filteredFindingsCount = vm.findings.length;

			if (!vm.aggregatedParents.findingsFilterGroups)
				vm.aggregatedParents.findingsFilterGroups = _.filter(filterGroups, { showGroup: true });

			if (vm.findings && vm.findings.length && vm.aggregatedParents.findingsFilterGroups) {
				_.each(vm.aggregatedParents.findingsFilterGroups, function(group) {
					vm.hasFindingsItems = true;

					if (vm.findings && vm.findings.length) {
						if (group.specs && !_.isEmpty(group.specs)) {
							_.each(group.specs, function(spec) {
								if (group.useButtons)
									addFilterButton(spec, group);

								if (group.useDateRangePicker)
									addDateRangePicker(spec, group);
							});

							if (!vm.showFindingsFilterBar) {
								vm.showFindingsFilterBar = true;
								$timeout();
							}
						}
						else
							vm.showFindingsFilterBar = false;
					}
					else {
						console.error("Could not construct filter buttons for findings (severities)")
					}
				});
			}
			else
				vm.showFindingsFilterBar = false;

			function getFilterGroups() {
				return [
					{
						useButtons: true,
						property: "severity",
						showGroup: true,
						customIdString: "0-severityState-",
						tooltipText: $translate.instant("modules.findings.severity"),
						iconClass: "fas fa-exclamation-circle",
						filters: [],
						specs: [
							{ state: enums.findingSeverity.observation, color: "#48C72B", icon: "", items: [], count: 0, title: $translate.instant("modules.findings.severities.observation") },
							{ state: enums.findingSeverity.minor, color: "#48C72B", icon: "", items: [], count: 0, title: $translate.instant("modules.findings.severities.minor") },
							{ state: enums.findingSeverity.major, color: "#48C72B", icon: "", items: [], count: 0, title: $translate.instant("modules.findings.severities.major") },
							{ state: enums.findingSeverity.critical, color: "#48C72B", icon: "", items: [], count: 0, title: $translate.instant("modules.findings.severities.critical") },
							{ state: enums.findingSeverity.zeroTolerance, color: "#48C72B", icon: "", items: [], count: 0, title: $translate.instant("modules.findings.severities.zeroTolerance") },
						]
					},
					{
						useButtons: true,
						property: "closed",
						showGroup: true,
						customIdString: "0-closedState-",
						tooltipText: "",
						iconClass: "fas fa-check",
						filters: [],
						specs: [
							{ state: true, color: "", icon: "", items: [], count: 0, title: $translate.instant("modules.findings.filterLabels.closed") },
							{ state: false, color: "", icon: "", items: [], count: 0, title: $translate.instant("modules.findings.filterLabels.open") },
						]
					},
					{
						useButtons: true,
						property: "locked",
						showGroup: !vm.influenceMode || vm.influenceOpenedByCreator,
						customIdString: "0-lockedState-",
						tooltipText: "",
						iconClass: "fas fa-lock",
						filters: [],
						specs: [
							{ state: true, color: "", icon: "", items: [], count: 0, title: $translate.instant("modules.findings.filterLabels.locked") },
							{ state: false, color: "", icon: "", items: [], count: 0, title: $translate.instant("modules.findings.filterLabels.unlocked") },
						]
					},
					{
						useButtons: true,
						property: "wasClosedOnTime",
						showGroup: !vm.influenceMode || vm.influenceOpenedByCreator,
						customIdString: "0-closedOnTimeState-",
						tooltipText: "",
						iconClass: "fas fa-clock",
						filters: [],
						specs: [
							{ state: true, color: "", icon: "", items: [], count: 0, title: $translate.instant("modules.findings.filterLabels.closedOnTime") },
							{ state: false, color: "", icon: "", items: [], count: 0, title: $translate.instant("modules.findings.filterLabels.notClosedOnTime") },
						]
					},
					{
						useDateRangePicker: true,
						showGroup: !vm.influenceMode || vm.influenceOpenedByCreator,
						tooltipText: "",
						filters: [],
						iconClass: "fas fa-clock",
						specs: [
							{ id: _.uniqueId(), property: "openedAt_epoch", items: [], title: $translate.instant("modules.findings.filterLabels.openedAt") },
							{ id: _.uniqueId(), property: "closedAt_epoch", items: [], title: $translate.instant("modules.findings.filterLabels.closedAt") },
							{ id: _.uniqueId(), property: "deadlineAt_epoch", items: [], title: $translate.instant("modules.findings.filterLabels.deadlineAt") },
						]
					}
				];
			}

			function addFilterButton(spec, group) {
				var filterButtonId = group.customIdString + spec.state;
				var addedFilterButton = _.find(group.filters, { "id": filterButtonId });
				var itemsByState = _.filter(vm.findings, function(finding) { return finding.content[group.property] === spec.state });

				if (itemsByState) {
					spec.items = itemsByState;
					spec.count = itemsByState.length;
					vm.aggregatedParents.itemsByParentWfid[filterButtonId] = spec.items;
				}
				else
					spec.disabled = true;

				if (addedFilterButton)
					addedFilterButton.count = spec.count;
				else
					createButton(spec, group);


				function createButton() {
					var button = {
						id: filterButtonId,
						fulfillmentState: spec.state,
						count: spec.count,
						title: spec.title,
						iconClass: spec.icon,
						iconColor: spec.color,
						disabled: spec.disabled,
						type: "finding-" + group.property,
						showButton: true,
						showProgressBar: false,
						toggle: function () {
							vm.aggregatedParents.toggleFilter(this);
						}
					};

					group.filters.push(button);
				}
			}

			function addDateRangePicker(spec, group) {
				var addedFilterPicker = _.find(group.filters, { "id": spec.id });
				var customFilterId = "0-openedAt-" + spec.id;

				if (!addedFilterPicker)
					createDateRangePicker();

				function createDateRangePicker() {
					var today = moment().format();
					var yesterday = moment().subtract(1, 'days').format();

					var picker = {
						id: customFilterId,
						dateSpan: {
							startDate: undefined,
							endDate: undefined
						},
						active: false,
						title: spec.title,
						disabled: spec.disabled,
						type: "finding-" + spec.property,
						dateSpanResult: {},
						dateSpanResult_epoch: {},
						init: function () {
							if (!this.dataRangePickerHandle) {
								this.inputElement = $(document).find('#daterangepicker-' + picker.id);
							}
						},
						clearFilter: function() {
							picker.active = false;
							picker.dateSpanResult.startDate = undefined;
							picker.dateSpanResult.endDate = undefined;
							picker.dateSpanResult_epoch.startDate = undefined;
							picker.dateSpanResult_epoch.endDate = undefined;

							if (!picker.dataRangePickerHandle)
								picker.inputElement = $(document).find('#daterangepicker-' + picker.id);

							if (picker.inputElement)
								picker.inputElement.val("");

							picker.subTitle = undefined;
							vm.aggregatedParents.itemsByParentWfid[customFilterId] = [];

							$timeout();
							setTimeout(function () {
								vm.aggregatedParents.toggleFilter(picker);
							}, 100);
						},
						datePickerOptions: {
							applyClass: 'btn-primary',
							cancelClass: 'btn-hollow',
							alwaysShowCalendars: true,
							linkedCalendars: false,
							locale: {
								firstDay: 1, // setting Monday to be first day of the week
								opens: 'right',
								format: "YYYY-MM-DD",
								applyLabel: $translate.instant("modules.dateRangePicker.applyLabel"),
								cancelLabel: $translate.instant("modules.dateRangePicker.cancelLabel"),
								monthNames: moment.monthsShort(),
								daysOfWeek: moment.weekdaysShort(),
								customRangeLabel: $translate.instant('modules.dateRangePicker.ranges.customRange')
							},
							ranges: (function () {
								var output = {};
								output[$translate.instant('modules.dateRangePicker.ranges.today')] = [ today, yesterday ];
								output[$translate.instant('modules.dateRangePicker.ranges.lastWeek')] = [ moment().subtract(1, 'weeks').format(), yesterday ];
								output[$translate.instant('modules.dateRangePicker.ranges.lastMonth')] = [ moment().subtract(1, 'months').format(), yesterday ];
								output[$translate.instant('modules.dateRangePicker.ranges.last3Months')] = [ moment().subtract(3, 'months').format(), yesterday ];
								output[$translate.instant('modules.dateRangePicker.ranges.last6Months')] = [ moment().subtract(6, 'months').format(), yesterday ];
								output[$translate.instant('modules.dateRangePicker.ranges.lastYear')] = [ moment().subtract(1, 'years').format(), yesterday ];
								output[$translate.instant('modules.dateRangePicker.ranges.last2Years')] = [ moment().subtract(2, 'years').format(), yesterday ];
								output[$translate.instant('modules.dateRangePicker.ranges.last3Years')] = [ moment().subtract(3, 'years').format(), yesterday ];
								return output;
							})(),
							eventHandlers: {
								'apply.daterangepicker': function(event) {
									picker.active = true;
									picker.dateSpanResult = {
										startDate: picker.dateSpan.startDate,
										endDate: picker.dateSpan.endDate
									};
									picker.dateSpanResult_epoch = {
										startDate: Math.floor(picker.dateSpan.startDate.toDate().getTime() / 1000),
										endDate: Math.floor(picker.dateSpan.endDate.clone().add(86399, "seconds").toDate().getTime() / 1000)
									};

									picker.subTitle = picker.dateSpanResult.startDate.format("YYYY-MM-DD") + " - " + picker.dateSpanResult.endDate.format("YYYY-MM-DD");
									picker.inputElement.val(picker.subTitle);

									var itemsWithinRange = _.filter(vm.findings, function(finding) {
										return picker.dateSpanResult_epoch.startDate < finding.content[spec.property] && finding.content[spec.property] < picker.dateSpanResult_epoch.endDate;
									});

									if (itemsWithinRange)
										vm.aggregatedParents.itemsByParentWfid[customFilterId] = itemsWithinRange;

									$timeout();
									setTimeout(function () {
										vm.aggregatedParents.toggleFilter(picker);
									}, 100)
								},
								'cancel.daterangepicker': function(event) {
									picker.clearFilter();
								}
							}
						}
					}

					group.filters.push(picker);
				}
			}
		}

		function updateLocalFulfillmentProgress() {
			var
				actualRequirementsMap = requirementService.getActualRequirementOnItems(originalItems.all, vm.organizationId, vm.influence),
				fulfillCount = 0,
				count_itemsWithRequirement = actualRequirementsMap.length,
				useDetailedFulfillmentResult = !vm.isCustomFramework && !vm.isStandardOrGuideline,
				useGoalWording = !useDetailedFulfillmentResult, // Use the word 'Goal' for requirements
				fulfillmentStateSpec,
				fulfillmentStateSpecByState
			;

			// TEMP
			_.remove(actualRequirementsMap, function (item) {
				return item.itemContent.type === enums.objectType.finding && item.dataRelation.type !== enums.objectType.virtualDataRelation;
			});

			if (useGoalWording) {
				fulfillmentStateSpec = [
					{ state: enums.fulfillmentState.fulfilled, color: "#47CF76", icon: "fa fa-flag", items: [], count: 0, title: $translate.instant("FinishedGoals") },
					{ state: enums.fulfillmentState.unfulfilled, color: "#BCBCBC", icon: "fa fa-flag", items: [], count: 0, title: $translate.instant("UnfinishedGoals") },
				]
			}
			else {
				fulfillmentStateSpec = [
					{ state: enums.fulfillmentState.fulfilled, color: "#48C72B", icon: "fa fa-check", items: [], count: 0, title: $translate.instant("Fulfills") },
					{ state: enums.fulfillmentState.unfulfilled, color: "#DF4A37", icon: "fa fa-times", items: [], count: 0, title: $translate.instant("NotFulfilled") },
					{ state: "partiallyFulfilled", color: "#DBAE0A", icon: "fa fa-minus-circle", items: [], count: 0, title: $translate.instant("fulfillmentStates.partiallyFulfilled") },
					{ state: enums.fulfillmentState.assessmentNeeded, color: "", icon: "fa fa-question-circle", items: [], count: 0, title: $translate.instant("fulfillmentStates.assessmentNeeded") },
					{ state: enums.fulfillmentState.reportingNeeded, color: "#34495e", icon: "fa fa-repeat", items: [], count: 0, title: $translate.instant("fulfillmentStates.toBeReported") },
					{ state: enums.fulfillmentState.expired, color: "", icon: "fa fa-exclamation-triangle", items: [], count: 0, title: $translate.instant("fulfillmentStates.expired") },
					{ state: enums.fulfillmentState.certificateExpired, color: "", icon: "fa fa-exclamation-triangle", items: [], count: 0, title: $translate.instant("fulfillmentStates.certificateExpired") },
				]
			}

			fulfillmentStateSpecByState = _.keyBy(fulfillmentStateSpec, "state");

			if (actualRequirementsMap.length) {
				_.each(actualRequirementsMap, function (item) {
					// When option useDetailedResult is true, the function will return an object with detailed info instead of a boolean
					var fulfillmentResult = requirementService.checkLocalFulfillment(item.itemContent, item.dataRelation, item.requirement, item.intersectionSettings, { useDetailedResult: useDetailedFulfillmentResult });
					// console.log(fulfills, item.itemContent.getMainTextual(), item.requirement)

					if (useDetailedFulfillmentResult) {
						if (fulfillmentResult.fulfillmentState === null) // If no fulfillment is set then use reportingNeeded so that a filter button always appear
							fulfillmentResult.fulfillmentState = enums.fulfillmentState.reportingNeeded;

						if (!fulfillmentStateSpecByState[fulfillmentResult.fulfillmentState]) // If the state does't exist in fulfillmentStateSpec then ignore it
							return;

						fulfillmentStateSpecByState[fulfillmentResult.fulfillmentState].items.push(item.itemComposite); // Add item to the array of items with this state
						fulfillmentStateSpecByState[fulfillmentResult.fulfillmentState].count++; // Increment the number of items with this state
						if (fulfillmentResult.actuallyFulfills) // if fulfills is true and state is not assessmentNeeded or reportingNeeded (should never be the case but might be needed for some legacy fulfillments)
							fulfillCount++;
					}
					else {
						if (fulfillmentResult) {
							fulfillCount++;
							fulfillmentStateSpecByState[enums.fulfillmentState.fulfilled].items.push(item.itemComposite);
							fulfillmentStateSpecByState[enums.fulfillmentState.fulfilled].count++;
						}
						else {
							fulfillmentStateSpecByState[enums.fulfillmentState.unfulfilled].items.push(item.itemComposite);
							fulfillmentStateSpecByState[enums.fulfillmentState.unfulfilled].count++;
						}
					}
				})

				vm.fulfillmentPercentage = getPercentage(fulfillCount, count_itemsWithRequirement)
				vm.requirementsCount = count_itemsWithRequirement;
				vm.fulfillmentCount = fulfillCount;

				if (!vm.aggregatedParents.requirementFilterButtons)
					vm.aggregatedParents.requirementFilterButtons = [];

				if (!vm.aggregatedParents.itemsByFulfillmentState)
					vm.aggregatedParents.itemsByFulfillmentState = {};

				var statesWithZeroCount = [];

				_.each(fulfillmentStateSpec, function (spec) {
					var
						filterButtonId = "0-fulfillmentState-" + spec.state,
						existingFilterButton = _.find(vm.aggregatedParents.requirementFilterButtons, { id: filterButtonId })
					;

					if (!useGoalWording) {
						if (spec.count === 0) {
							delete vm.aggregatedParents.itemsByParentWfid[filterButtonId];
							delete vm.aggregatedParents.itemsByFulfillmentState[spec.state];
							_.remove(fulfillmentStateSpec, { id: filterButtonId });
							statesWithZeroCount.push(spec.state);
							return;
						}
					}

					vm.aggregatedParents.itemsByParentWfid[filterButtonId] = spec.items;
					vm.aggregatedParents.itemsByFulfillmentState[spec.state] = spec.items;  // The items with a certain state is also put in the itemsByFulfillmentState lookup. Not sure why it is needed.

					if (existingFilterButton) {
						existingFilterButton.count = spec.count;
						existingFilterButton.percentage = getPercentage(spec.count, count_itemsWithRequirement);
					}
					else {
						vm.aggregatedParents.requirementFilterButtons.push({
							id: filterButtonId,
							fulfillmentState: spec.state,
							count: spec.count,
							title: spec.title,
							iconClass: spec.icon,
							iconColor: spec.color,
							type: "requirement",
							percentage: getPercentage(spec.count, count_itemsWithRequirement),
							showButton: true,
							showProgressBar: false,
							toggle: function () {
								// if (this.id === "0-requirementsFulfilled")
								// 	delete vm.aggregatedParents.selectedFiltersById["0-requirementsUnfulfilled"];
								// else
								// 	delete vm.aggregatedParents.selectedFiltersById["0-requirementsFulfilled"];

								vm.aggregatedParents.toggleFilter(this);
							}
						});
					}

					vm.aggregatedParents.requirementFilterButtons = _.orderBy(vm.aggregatedParents.requirementFilterButtons, "fulfillmentState");
				});

				if (!useGoalWording) {
					// Lookup objects and buttons that existed before but now has zero count are removed
					for (var i = 0, len = statesWithZeroCount.length, filterButtonId, filterButton; i < len; i++) {
						filterButtonId = "0-fulfillmentState-" + statesWithZeroCount[i];
						delete vm.aggregatedParents.itemsByParentWfid[filterButtonId];
						delete vm.aggregatedParents.itemsByFulfillmentState[statesWithZeroCount[i]];
						filterButton = _.remove(vm.aggregatedParents.requirementFilterButtons, { id: filterButtonId })[0];
						if (filterButton && vm.aggregatedParents.selectedFiltersById[filterButtonId])
							filterButton.toggle();
					}
				}
			}
			else {
				vm.fulfillmentPercentage = 0;
				vm.requirementsCount = 0;
				vm.fulfillmentCount = 0;
			}

			$timeout();
		}

		function handleInitialData(rootObject) {
			var
				promises = [],
				rootContent,
				rootDataRelation,
				maxDepth = childrenLoadDepth,
				rootItem,
				parentWfids,
				parentItemsByWfid,
				depthResult,
				itemSettings,
				multipleItemSettings,
				extractItemSettings = function(itemSettings, obj) {
					if (!obj)
						obj = {};

					if (typeof itemSettings.showDropdown === "boolean")
						obj.showDropdown = itemSettings.showDropdown;

					if (typeof itemSettings.showMetadata === "boolean")
						obj.showMetadata = itemSettings.showMetadata;

					if (typeof itemSettings.metadata === "object") {
						obj.metadataConfig = itemSettings.metadata;
						obj.showMetadata = true;
					}

					if (typeof itemSettings.showMeasureChart === "boolean")
						obj.showMeasureChart = itemSettings.showMeasureChart;

					if (typeof itemSettings.alwaysShowImage === "boolean")
						obj.alwaysShowImage = itemSettings.alwaysShowImage;

					if (typeof itemSettings.showRelatedContentAsChildren === "boolean") {
						includeRelatedContentAsChildren = itemSettings.showRelatedContentAsChildren;
					}

					if (includeRelatedContentAsChildren) { // Can also be set from $stateParams
						var flatItems = dataQuery.getHierarchyAsList(rootContent);
						promises.push(dataOps.getSubItemsOfAll(flatItems, enums.subItemsKind.relatedContentByUser, { bypassCache: true }));
					}

					vm.allItemSettings.push(obj);

					return obj;
				}
			;


			if (vm.influence)
				vm.organizationId = vm.influence.organizationId;

			if (vm.incomingTicket) {
				vm.intersectionSettings = vm.incomingTicket;
				vm.organizationId = vm.incomingTicket.organizationId;
			}
			else {
				vm.intersectionSettings = intersectionSettings = {
					organizationId: vm.organizationId,
					networkId: vm.influence && vm.influence.channelId > 0 ? vm.influence.channelId : undefined,
					contextParents: vm.influence && vm.influence.contextParentWfids ? vm.influence.contextParentWfids.split(",") : undefined,
					influence: vm.influence
				};
			}

			if (vm.intersectionSettings
				&& vm.influence
				&& vm.influence.childContent.childs.length == 1
				&& _.get(vm.influence, "childContent.conditions.intersectionSourceWfid")
				&& _.get(vm.influence, "childContent.childs[0].originalRelation.settings.contextParentWfids")
			) {
				vm.intersectionSettings.contextParents = _.get(vm.influence, "childContent.childs[0].originalRelation.settings.contextParentWfids");
			}

			return $q(function (resolve, reject) {
				if (rootObject.type === enums.objectType.dataRelation) {
					rootContent = rootObject.childContent;
					rootDataRelation = rootObject;
				}
				else {
					rootContent = rootObject;
					rootDataRelation = {};
				}

				if (rootObject.conditions && rootObject.conditions.intersectionSourceWfid) {
					if ($scope.itemFromDirective && rootObject.conditions.intersectionSourceWfid === $scope.itemFromDirective.wfid)
						useVirtualDataRelationOnRootLevel = true;
					else {
						vm.intersectionSourceStructure = wfObject.get(rootObject.conditions.intersectionSourceWfid);
						relationIntersectionTargetWfid = rootObject.wfid;
						childrenRelationType = enums.objectType.virtualDataRelation;
					}

					if (!vm.influenceMode) {
						vm.showLinkagesPanel = true;
						if (vm.intersectionSourceStructure)
							vm.pageHeaderPreTitle = vm.intersectionSourceStructure.title;
					}
				}

				if (rootObject.conditions && rootObject.conditions.uiSettings) {
					if (rootObject.conditions.uiSettings.eachItem) {
						vm.itemMetadataConfig = rootObject.conditions.uiSettings.eachItem.metadata;
						vm.showMetadata = true;
					}
				}

				parentWfids = [ rootContent.wfid ]

				vm.mainStructure = rootContent;

				// vm.pageHeaderObject can be overrided when hierarchical is loaded as a directive
				// so only set it from rootContent if it is undefined.
				// Also, title of the browser window is not set if header is overriden.
				if (typeof vm.pageHeaderObject === "undefined") {
					vm.pageHeaderObject = _.clone(rootContent); // Since the vm.pageHeaderObject might be modified elsewhere it has to be cloned so that the values of the rootContent doesn't get changed.
					if (vm.intersectionSourceStructure && !vm.influenceMode)
						vm.pageHeaderPreTitle_gotoState = { stateName: 'hier', stateParams: { objectId: vm.intersectionSourceStructure.wfid } };
					else
						vm.pageHeaderPreTitle_gotoState = { stateName: 'hier', stateParams: { objectId: vm.mainStructure.wfid } };

					$rootScope.setPageTitle(vm.mainStructure.title);
					if ($scope.openedViaDirective) {
						$scope.$on("$destroy", function () {
							document.title = previousPageTitle;
						});
					}
				}

				mainStructureConditions = vm.mainStructure.conditions;

				if (_.get(mainStructureConditions, "contextParentWfids")) {
					vm.intersectionSettings.contextParents = mainStructureConditions.contextParentWfids;
				}

				if ((_.get(mainStructureConditions, "uiSettings.showPdfExport") || _.get(vm.intersectionSourceStructure, "conditions.uiSettings.showPdfExport"))) {
					vm.showPdfExportButton = true;
				}

				if (!vm.influenceMode) {
					vm.itemSettings = {
						showDropdown: true,
						showMetadata: true
					};

					if ($scope.vmPrototype && $scope.vmPrototype.itemSettings)
						_.assign(vm.itemSettings, $scope.vmPrototype.itemSettings);
				}

				if (mainStructureConditions) {
					if (mainStructureConditions.contextVariable1 === "GRI_G4") {
						vm.showQuestionAnswerStatistics = false;
					}

					if (mainStructureConditions.uiSettings) {
						if (typeof mainStructureConditions.uiSettings.showFilterBars === "boolean")
							vm.showFilterBars = mainStructureConditions.uiSettings.showFilterBars;

						if (typeof mainStructureConditions.uiSettings.showSunburstChart === "boolean")
							vm.showSunburstChart = mainStructureConditions.uiSettings.showSunburstChart;

						if (typeof mainStructureConditions.uiSettings.showLevelsFilterBar === "boolean")
							vm.showLevelsFilterBar = mainStructureConditions.uiSettings.showLevelsFilterBar;

						if (typeof mainStructureConditions.uiSettings.uiMode === "number")
							vm.answeringUiMode = mainStructureConditions.uiSettings.uiMode;

						if (mainStructureConditions.uiSettings.mainPanelFullWidth)
							vm.mainPanelFullWidth = true;

						if (mainStructureConditions.uiSettings.eachItem) {
							itemSettings = mainStructureConditions.uiSettings.eachItem;

							if (_.isArray(itemSettings)) {
								multipleItemSettings = itemSettings;
								_.each(multipleItemSettings, function (itemSettings) {
									if ("filter_types" in itemSettings) {
										if (!vm.itemSettings.byType)
											vm.itemSettings.byType = {};

										_.each(itemSettings.filter_types, function (objectType) {
											vm.itemSettings.byType[objectType] = extractItemSettings(itemSettings);
										});
									}
								});
							}
							else {
								vm.itemSettings = extractItemSettings(itemSettings);
							}
						}
					}
				}


				vm.rootItem = rootItem = addToCache(0, rootContent, rootDataRelation);
				// parentItemsByWfid = _.keyBy([ rootItem ], "wfid");

				// for (var depth = 1; depth < maxDepth; depth++) {
				// 	depthResult = handleChildren(parentWfids, depth, parentItemsByWfid);

				// 	parentWfids = depthResult.childWfids;

				// 	if (parentWfids.length === 0)
				// 		break;

				// 	parentItemsByWfid = _.keyBy(depthResult.newItems, "wfid");
				// 	// itemsByDepth[depth] = depthResult;

				// 	// Array.prototype.push.apply(allItems.dataRelations, depthResult.dataRelations);
				// 	// Array.prototype.push.apply(allItems.childContents, depthResult.childContents);
				// 	// Array.prototype.push.apply(allItems.childWfids, depthResult.childWfids);
				// }

				// Check if the loaded structure (or the intersection source structure) is a standard or guideline provided by Worldfavor
				if (_.some(_.concat(vm.mainStructure.parents, _.get(vm.intersectionSourceStructure, "parents")), function (parentRelation) {
					var contextVariable1 = _.get(parentRelation, "parentContent.conditions.contextVariable1");

					return contextVariable1 === "STANDARDS" || contextVariable1 === "STANDARDS_v2" || contextVariable1 === "ISO_STANDARDS";
				})) {
					vm.isStandardOrGuideline = true;
				}

				if (_.some(vm.mainStructure.parents, function (parentRelation) {
					return parentRelation.wffid === "71-203"
				})) {
					vm.isCustomFramework = true;
					vm.showPdfExportButton = true;
				}

				if (_.get(vm.mainStructure.conditions, "uiSettings.showCustomCategories") === true) {
					vm.showCustomCategories = true;
					promises.push(dataOps.getSubItems(vm.mainStructure, enums.subItemsKind.relatedContentByUser).then(function () {
						syncMainStructureCategories();
					}));
				}

				$q.all(promises).then(function () {
					handleChildrenOfItems([ rootItem ], maxDepth);

					itemsByWfid = _.groupBy(allItems, "wfid");

					maybeIntersect().then(function () {
						linkItems().then(function () {

							// Add absoluteOrder value and maybe itemNumbering value
							addAdditionalValuesOnItems();

							buildLevelFilters();

							// console.timeEnd("Data handled");

							resolve();
						});
					});
				});

				function handleChildrenOfItems(items, currentDepth, currentCustomDepth) {
					var
						parentItemsByWfid,
						parentWfids,
						hasCustomBranchDepth = typeof currentCustomDepth === "number",
						groups
					;

					currentDepth--;
					if (hasCustomBranchDepth) {
						currentCustomDepth--;
					}

					if (orgAccessAdminMode) {
						items = _.filter(items, function (item) {
							return !_.get(item, "content.conditions.hideChildrenInMenu")
								|| item.wfid === "71-12226" // Data Collector > Overview (selectable networks)
						});
					}

					parentItemsByWfid = _.keyBy(items, "wfid");
					parentWfids = _.keys(parentItemsByWfid);

					if (ignoreChildrenOfParentWfids) {
						_.remove(parentWfids, function (wfid) {
							return _.includes(ignoreChildrenOfParentWfids, wfid);
						});
						_.remove(parentItemsByWfid, function (value, wfid) {
							return _.includes(ignoreChildrenOfParentWfids, wfid);
						});
					}

					depthResult = handleChildren(parentWfids, maxDepth - currentDepth, parentItemsByWfid);

					if (currentDepth > 0 && (!hasCustomBranchDepth || currentCustomDepth > 0) && depthResult.newItems.length > 0) {
						groups = _.groupBy(depthResult.newItems, function (item) {
							var conditions, localHasCustomBranchDepth;

							if (item.type === enums.objectType.structure && (conditions = item.content.conditions) && typeof conditions.childrenLoadDepth === "number") {
								vm.hierarchyHasCustomBranchDepths = true;
								localHasCustomBranchDepth = true;
							}

							if (!vm.ignoreAdditionalLoadDepthLimits && localHasCustomBranchDepth) {
								return (conditions.childrenLoadDepth + 1).toString();
							}
							else
								return "default";
						});
						_.each(groups, function (items, newCustomDepth) {
							handleChildrenOfItems(items, currentDepth, newCustomDepth === "default" ? currentCustomDepth : parseInt(newCustomDepth));
						});
					}
				}
			});
			// console.log(allItems);
		}

		function onSearch(searchString, items, searchActive) {
			if (searchActive) {
				// if (items && "length" in items) {
				// }
				if (items.length === 0) {
					items = [ "searchDummy" ]
				}

				vm.aggregatedParents.itemsByParentWfid["search"] = items;
				vm.aggregatedParents.selectedFiltersById["search"] = {
					id: "search",
					type: "searchFilter"
				};
			}
			else {
				delete vm.aggregatedParents.itemsByParentWfid["search"]
				delete vm.aggregatedParents.selectedFiltersById["search"];
			}

			if (vm.aggregatedParents.toggleFilter)
				vm.aggregatedParents.toggleFilter();
		}

		function linkItems() {
			itemsByDepth = _.groupBy(allItems, "depth");
			itemsByParentWfid = _.groupBy(allItems, "parentWfid");
			// console.log(itemsByDepth);
			// console.log(itemsByParentWfid);

			_.each(allItems, function (item) {
				var children = itemsByParentWfid[item.wfid];
				item.hasChildren = !!children;

				item.childs = item.hasChildren ? _.sortBy(children, "order") : [];

				item.filteredChildren = _.clone(item.childs);
			});

			return customChildrenGetter.handleAllItems();
		}

		function CustomChildrenGetter() {
			var
				itemsWithCustomGetters
			;

			return {
				handleAllItems: handleAllItems,
				handleItem: handleItem,
				hasItemCustomGetter: hasItemCustomGetter
			}

			function hasItemCustomGetter(item, typeOut) {
				var output = false;

				if (hasItemCustomRelationSource(item)) {
				  if (typeOut)
					typeOut.type = "customRelationSource";

				  return true;
				}
				else if (hasItemRelatedContentToShowAsChildren(item)) {
				  if (typeOut)
					typeOut.type = "relatedContent";

				  return true;
				}
				else
				  return false;
			}

			function hasItemCustomRelationSource(item) {
				return mainContextParentItems.length && item.type === enums.objectType.structure
					&& _.get(item, "content.conditions.pickerSettings.relationTarget.item") === "@currentContextParent"
			}

			function hasItemRelatedContentToShowAsChildren(item, itemWithRequirement) {
				var requirement, itemConditions;

				if (!itemWithRequirement)
					itemWithRequirement = item;

				if ((itemWithRequirement.type === enums.objectType.structure || itemWithRequirement.type === enums.objectType.organization || itemWithRequirement.type === enums.objectType.question) && !itemWithRequirement.root) {
					requirement = requirementService.getActualRequirement({
						itemRelation: itemWithRequirement.dataRelation,
						itemComposite: itemWithRequirement,
						organizationId: vm.organizationId
					});

					itemConditions = _.get(item.content, "conditions");

					if (itemWithRequirement.type === enums.objectType.question) {
						if (item.dataRelation.settings && item.dataRelation.settings.attachObjectTypes) {
							item.activeCustomGetter = "relatedContentAsChildren";
							return true;
						}
					}
					else {
						if ((requirement && (requirement.rule === enums.requirementRule.anyValue || requirement.rule === enums.requirementRule.manual)) ||
							((item.dataRelation.settings && item.dataRelation.settings.attachObjectTypes) ||
							(item.dataRelation.originalRelation && item.dataRelation.originalRelation.settings && item.dataRelation.originalRelation.settings.attachObjectTypes))
							|| (itemConditions && itemConditions.pickerSettings) || _.get(item.parent, "content.conditions.subItemsSettings.pickerSettings")
							|| (itemConditions && ("dataRelationByUser" in itemConditions) && itemConditions.objectTypes)
						) {
							item.activeCustomGetter = "relatedContentAsChildren";
							return true;
						}
					}

					// Old check:
					// if (requirement && (requirement.rule == enums.requirementRule.anyValue || requirement.rule == enums.requirementRule.manual)) {
					// 	return true;
					// }
				}

				return false;
			}

			function handleAllItems() {
				var promises = []
				if (vm.influenceMode) {
					itemsWithCustomGetters = _.filter(allItems, function (item) {
						promises.push(handleItem(item)[0]);
					});
				}

				return $q.all(promises);
			}

			function handleItem(item, itemWithRequirement, outResult, options) {
				var
					firstContextParent,
					firstContextParentSubItems,
					relationKind,
					pickerSourceItemWfid,
					pickerSourceItem,
					pickerSourceItemChildren
				;

				if (!itemWithRequirement)
					itemWithRequirement = item;

				if (hasItemCustomRelationSource(item)) {
					relationKind = _.get(item, "content.conditions.pickerSettings.relationTarget.kind");

					firstContextParent = mainContextParentItems[0];
					if (firstContextParent) {

						pickerSourceItemWfid = _.get(item, "content.conditions.pickerSettings.sourceItem");
						pickerSourceItem = wfObject.get(pickerSourceItemWfid);

						// if (!pickerSourceItem) {
						return [ $q(function (resolve) {
							dataOps.getObject(pickerSourceItemWfid).then(function (res) {
								pickerSourceItem = res
								dataOps.getSubItems(pickerSourceItemWfid, enums.subItemsKind.children).then(function () {
									pickerSourceItemChildren = dataQuery.getSubItems(pickerSourceItemWfid, enums.subItemsKind.children);
									addCustomChildren(item, firstContextParent,
										firstContextParentSubItems,
										relationKind,
										pickerSourceItemWfid,
										pickerSourceItem,
										pickerSourceItemChildren);

									resolve();
								});
							});
						}) ]
						// }
						// else
						// 	pickerSourceItemChildren = pickerSourceItem.childs;

						// addCustomChildren(item);
					}
				}
				else if (hasItemRelatedContentToShowAsChildren(item, itemWithRequirement)) {
					addRelatedContentAsChildren(item, outResult, options);
				}

				return [];
			}

			function addCustomChildren(item,
					firstContextParent,
					firstContextParentSubItems,
					relationKind,
					pickerSourceItemWfid,
					pickerSourceItem,
					pickerSourceItemChildren)
			{
				firstContextParentSubItems = firstContextParent.getSubListOfKind(relationKind, vm.influence ? vm.influence.organizationId : vm.organizationId);

				firstContextParentSubItems = _.intersectionWith(firstContextParentSubItems, pickerSourceItemChildren, function (dr1, dr2) {
					return dr1[wfObject.getRelationKeyOfKind(relationKind)] === dr2.wfcid
				});

				item.childs = dataQuery.makeItemComposites(
					firstContextParentSubItems,
					{ itemPrototype: itemPrototype }
				);

				_.each(item.childs, function (child) {
					child.parent = item;
					child.parentWfid = item.wfid;
					child.depth = item.depth + 1;
					child.content = child.dataRelation.getSubContentOfKind(relationKind);
					child.excludeNumbering = true;

					if (itemsByWfid[child.wfid])
						itemsByWfid[child.wfid].push(child);
					else
						itemsByWfid[child.wfid] = [ child ];
				});

				itemsByParentWfid[item.wfid] = _.clone(item.childs);
				item.hasChildren = item.childs.length > 0;
				item.filteredChildren = _.clone(item.childs);

				Array.prototype.push.apply(allItems, item.childs);
			}


			function addRelatedContentAsChildren(item, outResult, options) {
				var dataRelations = dataQuery.getIntersectedSubItems(item.content, _.assign({
					kind: enums.subItemsKind.relatedContentByUser
				}, intersectionSettings));

				var relatedContentArray = dataQuery.makeItemComposites(
					dataRelations,
					{ itemPrototype: itemPrototype }
				);

				if (options && options.replaceChildren) {
					if (relatedContentArray.length) {
						item.childs = relatedContentArray;
					}
					else
						item.childs = [];
				}
				else {
					if (relatedContentArray.length) {
						item.childs = item.childs && item.childs.length ? _.concat(relatedContentArray, item.childs) : relatedContentArray;
					}
					else
						item.childs = item.childs || [];
				}

				_.each(relatedContentArray, function (child) {
					child.parent = item;
					child.parentWfid = item.wfid;
					child.depth = item.depth + 1;
					child.content = child.dataRelation.getSubContentOfKind(enums.subItemsKind.relatedContentByUser);
					child.excludeNumbering = true;

					if (itemsByWfid[child.wfid])
						itemsByWfid[child.wfid].push(child);
					else
						itemsByWfid[child.wfid] = [ child ];

					handleItem(child, item);
				});

				itemsByParentWfid[item.wfid] = _.clone(item.childs);
				item.hasChildren = item.childs.length > 0;
				item.filteredChildren = _.clone(item.childs);

				if (outResult)
					outResult.addedItems = relatedContentArray;


				Array.prototype.push.apply(allItems, relatedContentArray);

				// Below code not working so attached info can't be filtered right now
				// _.each(relatedContentArray, function (itemComposite) {
				// 	_.remove(originalItems.all, { relationWfid: itemComposite.relationWfid });
				// 	originalItems.all.push(itemComposite);
				// });
				// Here should also be code that updated more array and lookups
			}
		}

		function addAdditionalValuesOnItems() {
			var
				absoluteOrder = 0,
				handleChildren = function (children, numberingPath) {
					if (!children || !children.length)
						return;

					if (vm.showItemNumbering) {
						if (numberingPath)
							numberingPath += "."
						else
							numberingPath = "";
					}

					for (var i = 0, len = children.length, item; i < len; i++) {
						item = children[i];
						item.absoluteOrder = absoluteOrder++;

						if (vm.showItemNumbering && !item.excludeNumbering)
							item.numbering = numberingPath + (i + 1);

						handleChildren(item.childs, item.numbering);
					}
				}
			;

			handleChildren(vm.rootItem.childs)
		}

		function buildLevelFilters() {
			levels.length = 0;

			_.each(itemsByDepth, function (items, depth) { // value, key
				var newLevel;

				depth = parseInt(depth);

				if (depth === 0) return; // Level 0 only contains the root item and should not be included in the filter bar

				newLevel = _.assign(_.clone(levelPrototype), {
					depth: depth,
					searchString: "",
					items: items,
					currentFilterButtons: [],
					allFilterButtons: (function (items) {
						return _.chain(items).filter(function (item) {
							return item.type === enums.objectType.structure
						}).sortBy("order").map(function (item) {
							var title = item.content.getHeaderText();

							if (!title || !title.length) {
								title = item.content.getBodyText();
							}

							if (item.numbering)
								title = item.numbering + " " + title;

							return {
								id: item.wfid,
								title: title,
								content: item,
								filterType: "child",
								count: item.childs.length,
								parentWfid: item.parentWfid
							};
						}).value();
					})(items),
					// itemsByParentWfid: _.groupBy(items, "parentWfid"),
					// itemsByFilteringId: (function (items) {
					// 	// Will do more in the future
					// 	var output;
					// 	output = _.groupBy(items, "wfid");
					// 	return output
					// })(items),
					selectedFilteringId: null
				});

				if (newLevel.allFilterButtons.length === 0) {
					return;
				}

				// if (newLevel.allFilterButtons.length > 1) {
					newLevel.allFilterButtons.unshift(newLevel.selectedFilter = {
						id: 'all',
						title: words.all
					});
				// }

				newLevel.currentFilterButtons = _.clone(newLevel.allFilterButtons);
				newLevel.currentFilterButtons = _.uniqBy(newLevel.currentFilterButtons, "id");

				levels.push(newLevel);
			});

			if (levels.length)
				levels[0].funnelActive = true;
		}

		// Every time a filter button is clicked this will be called
		function applySelectedFilters(skipUpdatingFilterBars) {
			var filteredArrayInstance, unfilteredArrayInstance, filterBarsSource = [];
			// console.time("applySelectedFilters");
			vm.levelFunnels.length = 0;
			Array.prototype.push.apply(vm.levelFunnels, _.filter(levels, { funnelActive: true }));

			if (vm.levelFunnels.length > 0) {
				if (!skipUpdatingFilterBars && vm.showFilterBars)
					vm.aggregatedParents.resetFilters();

				_.each(itemsWithFilteredChildren, function (item) {
					item.filteredChildren.length = 0;
					Array.prototype.push.apply(item.filteredChildren, item.childs);
				})
				itemsWithFilteredChildren.length = 0;

				_.each(vm.levelFunnels, function (level, index) {
					var
						previousLevelFunnel = vm.levelFunnels[index - 1]
					;

					filteredArrayInstance = [];
					unfilteredArrayInstance = [];
					// console.log("level", level.depth, level.selectedFilter.id);

					if (index === 0) {
						unfilteredArrayInstance = vm.rootItem.childs || [];
						filteredArrayInstance = vm.rootItem.filteredChildren || [];
						itemsWithFilteredChildren.push(vm.rootItem);
					}
					else if (previousLevelFunnel) {
						if (previousLevelFunnel.selectedFilter.filterType === "child") {
							unfilteredArrayInstance = previousLevelFunnel.selectedFilter.filterType === "child" ? previousLevelFunnel.selectedFilter.content.childs : [];
							filteredArrayInstance = previousLevelFunnel.selectedFilter.filterType === "child" ? previousLevelFunnel.selectedFilter.content.filteredChildren : [];
							itemsWithFilteredChildren.push(previousLevelFunnel.selectedFilter.content);
						}
					}

					if (filteredArrayInstance && unfilteredArrayInstance) {
						filteredArrayInstance.length = 0;
						if (level.selectedFilter.id === "all") {
							Array.prototype.push.apply(filteredArrayInstance, unfilteredArrayInstance);
						}
						else if (level.selectedFilter.filterType === "child") {
							Array.prototype.push.apply(filteredArrayInstance, _.filter(unfilteredArrayInstance, { wfid: level.selectedFilter.id }));
						}
					}
				})

				if (!skipUpdatingFilterBars && filteredArrayInstance && vm.aggregatedParents.updateFilterBars) {
					vm.aggregatedParents.updateFilterBars(getHierarchyFromItemsAsList(filteredArrayInstance));
				}
			}

			allCascadingItems.length = 0;
			cascadingItems.length = 0;
			offset = 0;
			// console.log("Rebuilding cascading list");
			if (vm.useInfiniteScroll) {
				vm.allCascadingItems = allCascadingItems = getFilteredHierarchyAsList(vm.rootItem);

				_.each(_.difference(allItems, allCascadingItems), function (item) {
					// console.info(item)
					if (item.includeInList) {// So that the root item is not included
						allCascadingItems.push(item);
						delete item.includeInList;
					}
				});

				vm.allCascadingItems = allCascadingItems = _.sortBy(allCascadingItems, "absoluteOrder");
				if (vm.limitItemRelationWfids) {
					var limitItemRelationsByWfid = _.keyBy(vm.limitItemRelationWfids);

					vm.allCascadingItems = allCascadingItems = _.filter(vm.allCascadingItems, function(item) {
						return limitItemRelationsByWfid[item.relationWfid]
							// Or if the item is attached to any of the items in vm.limitItemRelationWfids
							|| (item.dataRelation.parentData1 === 1 && limitItemRelationsByWfid[item.parent.relationWfid])
					});
				}

				syncLinkage(allCascadingItems);

				Array.prototype.push.apply(cascadingItems, _.take(allCascadingItems, vm.infiniteScrollChunkSize))
			}

			// console.log(filteredArrayInstance);

			// var deepestFilter = _.chain(levels).filter(function (level) {
			// 	return level.selectedFilter && level.selectedFilter.id !== "all";
			// }).map("selectedFilter").last().value();

			// _.forEach(allCascadingItems, function (item) {
			// 	console.log(new Array(item.depth + 1).join("  "), item.content.getMainTextual());
			// });
			// console.timeEnd("applySelectedFilters");
		}

		// Returns an array of items based on the hierarchy of filteredChildren from the provided item.
		function getFilteredHierarchyAsList(parent, ignoreChildrenVisibilityState) {
			var output = [];

			buildCascadingList(parent)

			// Some old imported structures resulted in duplicates in the array. Unsure why, so a uniqBy operation is done to overcome that.
			output = _.uniqBy(output, "relationWfid");

			return output;

			function buildCascadingList(parent) {
				if (parent.hasChildren && (parent.childrenToggled || ignoreChildrenVisibilityState)) {
					if (ignoreChildrenVisibilityState) {
						// Is true when called from function itemPrototype_toggleChildren.
						// Set to false here because we only want to toggle the original parent's children and not of any grand-child.
						ignoreChildrenVisibilityState = false;
					}

					if ((parent.type === enums.objectType.question || parent.type === enums.objectType.measure) && (includeRelatedContentAsChildren || parent.activeCustomGetter === "relatedContentAsChildren")) {
						_.remove(parent.filteredChildren, { type: parent.type === enums.objectType.question ? enums.objectType.questionAnswer : enums.objectType.measureAnswer });
					}

					_.forEach(parent.filteredChildren, function (item) {
						output.push(item);

						if (!includeRelatedContentAsChildren && (item.type === enums.objectType.question || item.type === enums.objectType.measure) && item.activeCustomGetter !== "relatedContentAsChildren")
							return;

						if (item.type === enums.objectType.structure || item.type === enums.objectType.organization || item.type === enums.objectType.question || item.type === enums.objectType.measure) {
							buildCascadingList(item);
						}
					})
				}
			}
		}

		function addToCache(depth, content, relation, parent) {
			var
				newItem,
				wfid = content.wfid,
				itemOccurenceArray,
				order
			;

			order = relation.order || 0;

			if (relation.organizationId) {
				order = order - 10000000; // Relations by user should appear above relations by system. The order value is offset to make it happen.
			}

			newItem = _.assign(_.clone(itemPrototype), {
				type: content.type,
				root: depth === 0,
				depth: depth,
				wfid: content.wfid,
				content: content,
				dataRelation: relation,
				relationWfid: relation.wfid || "noRelation",
				parentWfid: relation.wffid || "noParent",
				parent: parent,
				isUserData: !!userDataObjectTypes[content.type],
				order: order,
				searchSource: content.getAllTextual()

				// _headerText: content.getHeaderText(),
				// _bodyText: content.getBodyText()
			})

			allItems.push(newItem);

			// // itemsByWfid is a lookup object of all items grouped by wfid
			// // and each value is an array with all occurences of the item
			// if ((itemOccurenceArray = itemsByWfid[newItem.wfid])) // Assignment, not typo
			// 	itemOccurenceArray.push(newItem);
			// else
			// 	itemsByWfid[newItem.wfid] = [ newItem ];

			return newItem;
		}

		function handleChildren(parentWfids, depth, parentItemsByWfid) {
			var
				query,
				dataRelations,
				dataRelationsByChildWfid,
				childContents,
				newItems = [],
				childWfids,
				parentContents
			;

			// console.log(depth, useVirtualDataRelationOnRootLevel)

			// Get only the relations
			query = { where: {
				type: useVirtualDataRelationOnRootLevel && depth === 1 ? enums.objectType.virtualDataRelation : childrenRelationType,
				parentData1: null,
				wffid: { "in": parentWfids }
			} };

			if (relationIntersectionTargetWfid)
				query.where.intersectionTargetWfid = relationIntersectionTargetWfid;

			dataRelations = wfObject.filter(query);

			if (includeRelatedContentAsChildren) {
				Array.prototype.push.apply(dataRelations, wfObject.filter({ where: {
					type: enums.objectType.dataRelation,
					parentData1: 1,
					organizationId: vm.influence ? vm.influence.organizationId : vm.organizationId,
					wffid: { "in": parentWfids }
				}}));
			}
			// console.log(_.map(dataRelations, "wfid"));
			dataRelationsByChildWfid = _.groupBy(dataRelations, "wfcid");

			childWfids = _.map(dataRelations, "wfcid");

			// Get childContent of all the relations
			childContents = wfObject.filter({ where: {
				wfid: { "in": childWfids }
			} })

			_.each(childContents, function (content) {
				var relations = dataRelationsByChildWfid[content.wfid];

				for (var i = 0, len = relations.length, relation; i < len; i++) {
					relation = relations[i]
					newItems.push(addToCache(depth, content, relation, parentItemsByWfid[relation.wffid]))
				}
			});

			// Get all relations with those childContents (to get other parents that are not included in the current hierarchy)
			// parentContents = wfObject.filter({ where: {
			// 	type: { "in": [ enums.objectType.dataRelation, enums.objectType.visibilityTag ],
			// 	wfcid: { "in": childWfids }
			// }})

			return {
				dataRelations: dataRelations,
				childContents: childContents,
				childWfids: childWfids,
				newItems: newItems
				//distinctParentContents: parentContents
			}
		}

		function levelPrototype_setFilter(filter, skipApply, skipSunburstNavigation) {
			var
				_this = this,
				previousLevel = _.find(levels, { depth: this.depth - 1 }),
				nextLevel = _.find(levels, { depth: this.depth + 1 }),
				allNextLevels = _.filter(levels, function (level) {
					return level.depth > _this.depth
				})
			;
			// console.log(nextLevel, levels)
			if (this.selectedFilter === filter)
				return;

			this.selectedFilter = filter;

			_.invokeMap(allNextLevels, "resetFilter");
			_.each(allNextLevels, function (level) {
				level.currentFilterButtons.length = 0;
				Array.prototype.push.apply(level.currentFilterButtons, level.allFilterButtons);
				level.currentFilterButtons = _.uniqBy(level.currentFilterButtons, "id");

				if (filter.id === "all")
					level.funnelActive = false;
			});

			if (filter.id !== "all") {
				if (nextLevel) {
					nextLevel.funnelActive = true;

					if (filter.filterType === "child") {
						nextLevel.currentFilterButtons.length = 0;
						Array.prototype.push.apply(nextLevel.currentFilterButtons, _.filter(nextLevel.allFilterButtons, function (filterButton) {
							return filterButton.id === "all" || filterButton.parentWfid === filter.id;
						}));

						if (nextLevel.currentFilterButtons.length === 1 && nextLevel.currentFilterButtons[0].id === "all") { // If only the "All" button
							nextLevel.funnelActive = false;
						}
					}

					nextLevel.currentFilterButtons = _.uniqBy(nextLevel.currentFilterButtons, "id");
				}
			}

			if (!skipSunburstNavigation && vm.sunburstControl.gotoNode) {
				if (filter.id === "all") {
					if (previousLevel && previousLevel.selectedFilter.id !== "all")
						vm.sunburstControl.gotoNode(previousLevel.selectedFilter.id)
					else
						vm.sunburstControl.gotoNode("root")
				}
				else {
					vm.sunburstControl.gotoNode(filter.id)
				}
			}

			if (!skipApply)
				applySelectedFilters();
		}

		function itemPrototype_updateMetadata() {
			if (this.$scope && typeof this.$scope.updateMetadata === "function")
				this.$scope.updateMetadata();
			// var
			// 	metadataElement = this.$element.children("div.hier-content").find("wf-object-metadata"),
			// 	metadataContainer = metadataElement.parent(),
			// 	scope = this.$scope
			// ;
			// // metadataElement.parent().addClass("random");metadataElement
			// metadataElement.html("");
			// setTimeout(function () {

			// 	// console.log(this.$scope, $rootScope);
			// 	var newMetadata = $compile(metadataContainer.contents())(scope);
			// 	console.log(newMetadata);
			// 	metadataContainer.append(newMetadata);
			// }, 1000);
		}

		function itemPrototype_updateFulfillment() {
			if (vm.requirementFulfillmentManager) {
				vm.requirementFulfillmentManager.update(this.wfid, undefined, this.intersectionSettings);
			}
		}

		function itemPrototype_toggleChildren(event) {
			var
				item = this,
				itemIndex,
				localHierarchyItems
			;

			if (item.type !== enums.objectType.structure)
				return;

			localHierarchyItems = getFilteredHierarchyAsList(item, true);


			if (item.childrenToggled) {
				_.pullAll(allCascadingItems, localHierarchyItems);
				_.pullAll(cascadingItems, localHierarchyItems);

				if (cascadingItems.length < 10)
					pagingFunction();
			}
			else {
				itemIndex = allCascadingItems.indexOf(item);
				allCascadingItems.splice.apply(allCascadingItems, [ itemIndex + 1, 0 ].concat(localHierarchyItems));
				cascadingItems.splice.apply(cascadingItems, [ itemIndex + 1, 0 ].concat(_.take(localHierarchyItems, vm.infiniteScrollChunkSize)));

				if (localHierarchyItems.length > vm.infiniteScrollChunkSize) {
					cascadingItems.splice(itemIndex + 1 + vm.infiniteScrollChunkSize);
				}

				// Sometimes there are duplicates in the ng-repeat (Angular throws error) so an additional _.uniqBy is done here. Unclear if that still happens.
				vm.allCascadingItems = allCascadingItems = _.uniqBy(allCascadingItems, "relationWfid");
				vm.cascadingItems = cascadingItems = _.uniqBy(cascadingItems, "relationWfid");
			}

			item.childrenToggled = !item.childrenToggled;
		}

		function levelPrototype_resetFilter() {
			var previousLevel = _.find(levels, { depth: this.depth + -1 });
			this.selectedFilter = _.first(this.allFilterButtons); // The "all" button or the only button
			if (previousLevel && previousLevel.selectedFilter && previousLevel.selectedFilter.id === "all")
				this.funnelActive = false;
		}

		function openModal_assignTask(item) {
			var actualRelation;

			if (item.originalRelation)
				actualRelation = item.originalRelation
			else if (item.dataRelation.originalRelation)
				actualRelation = item.dataRelation.originalRelation
			else
				actualRelation = item.dataRelation

			modal.openInfluenceCreator({
				isInternal: true,
				organizationId: vm.organizationId,
				item: actualRelation
			});
		}

		function openModal_sendTo(item) {
			modal.openCreatorAndPicker({
				showTopItemAboveTitle: true,
				hideListHeader: false,
				hideFilters: false,
				translations: {
					addWord: $translate.instant('Send'),
					toWord: $translate.instant('To'),
					filterButton_all: $translate.instant('AllUsers'),
					filterButton_selected: $translate.instant('Sent')
				},
				compilerControl: null, //vm.context.itemCompilers[item.wfid],
				title: $translate.instant('Send'),
				create: false,
				objectTypes: [ enums.objectType.individual ],
				relationTarget: { item: item.content, kind: enums.subItemsKind.parentsByUser }
			}).closed(function () {
				item.updateMetadata();
			});
		}

		// 15243

		function openModal_categorizeTo_materiality(item) {
			modal.openCreatorAndPicker({
				hideListHeader: true,
				title: $translate.instant('AddTo'),
				create: false,
				sourceItem: '71-11966',
				relationTarget: { item: item.content, kind: enums.subItemsKind.parentsByUser }
			}).closed(function () {
				// item.updateMetadata();
				// vm.aggregatedParents.updateFilterBars()
			});
		}

		function openModal_categorizeTo_deviation(item) {
			modal.openCreatorAndPicker({
				hideListHeader: true,
				title: $translate.instant('AddTo'),
				create: false,
				sourceItem: '71-15243',
				relationTarget: { item: item.content, kind: enums.subItemsKind.parentsByUser }
			}).closed(function () {
				// item.updateMetadata();
				// vm.aggregatedParents.updateFilterBars()
			});
		}

		function openModal_categorizeTo_scope(item) {
			modal.openCreatorAndPicker({
				hideListHeader: true,
				title: $translate.instant('AddTo'),
				create: false,
				sourceItem: '71-19236',
				relationTarget: { item: item.content, kind: enums.subItemsKind.parentsByUser }
			}).closed(function () {
				// item.updateMetadata();
				// vm.aggregatedParents.updateFilterBars()
			});
		}

		function openModal_categorizeTo_customFrameworks(item) {
			modal.openCreatorAndPicker({
				hideListHeader: true,
				title: $translate.instant('AddTo'),
				create: false,
				sourceLists: [
					{
						hideImages: true,
						items: function () {
							var self = this;
							var alreadyAddedInList = {};
							return $q(function (resolve, reject) {
								dataOps.getObject("71-203",  {
									objectType: 71,
									objectId: 203,
									childrenLoadDepth: 1
								}).then(function (res) {
									var
										output,
										promises = []
									;

									_.each(res.childs, function (dataRelation) {
										promises.push(dataOps.getObject({
											objectId: dataRelation.childId,
											objectType: dataRelation.childType,
											childrenLoadDepth: 10,
											getterConditions: {
												contextVariable1: "onlyLatestAnswers",
												applyIntersectionIfPossible: true,
											}
										}));
									});

									$q.all(promises).then(function () {
										output = dataQuery.getHierarchyAsList(res, [ enums.objectType.structure, enums.objectType.question, enums.objectType.measure, enums.objectType.relativeMeasure ], {
											asItemComposites: true,
											filter: function (item) {
												if (alreadyAddedInList[item.wfid])
													return false;
												else
													alreadyAddedInList[item.wfid] = true;

												return !_.includes(originalItems.wfids, item.wfid) && !item.content.ancestorId
													&& item.dataRelation.childType === enums.objectType.structure
												;
											}
										});
										self.title = res.title;
										resolve(output);
									});
								});
							});
						}
					}
				],
				relationTarget: { item: item.content, kind: enums.subItemsKind.parentsByUser }
			}).closed(function () {
				// item.updateMetadata();
				// vm.aggregatedParents.updateFilterBars()
			});
		}

		function openModal_categorizeTo_customCategories(item) {
			modal.openCreatorAndPicker({
				hideListHeader: true,
				title: $translate.instant('AddTo'),
				create: false,
				sourceLists: [
					{
						title: $translate.instant("Categories"),
						items: dataQuery.makeItemComposites(vm.mainStructure.relatedContentByUser)
					}
				],
				relationTarget: { item: item.content, kind: enums.subItemsKind.parentsByUser }
			}).closed(function () {
				// item.updateMetadata();
				// vm.aggregatedParents.updateFilterBars()
			});
		}

		// Grabs the children of the specified item, updates internal caching / cascadingItems array and updates the view
		function updateChildrenOfItem(item) {
			var
				query,
				dataRelations,
				dataRelationWfids,
				dataRelationsByChildWfid,
				childContents,
				childWfids,
				parentContents,
				newItemChildsArray,
				itemsToRemove,
				itemIndex,
				sortedArray,
				customGetterType = {},
				outResult = {},
				addedWithNormalChildren = false
			;

			if (customChildrenGetter.hasItemCustomGetter(item, customGetterType)) {

				if (customGetterType.type === "relatedContent") {
				  // _.pullAll(allCascadingItems, item.childs);
				  // _.pullAll(cascadingItems, item.childs);
				  if (!_.get(item, "content.conditions.subItemsSettings.pickerSettings") && !_.get(item.parent, "content.conditions.subItemsSettings.pickerSettings") && !(item.type === enums.objectType.question || item.type === enums.objectType.measure)) {
				    // item.childs = [];
					getChildrenFromJsData();
					addedWithNormalChildren = true;
				    // _.each(item.childs, function (item) {
				    //   delete item.$scope;
				    // });
				  }
				  else
				    itemsToRemove = item.childs;
				}
				else
				  itemsToRemove = item.childs;

				if (_.get(item, "content.conditions.subItemsSettings.pickerSettings")) {
					_.each(item.childs, function (subItem) {
						Array.prototype.push.apply(itemsToRemove, subItem.childs);
					});
				}

				$q.all(customChildrenGetter.handleItem(item, null, outResult, { replaceChildren: !addedWithNormalChildren })).then(function () {
					_.pullAll(allCascadingItems, itemsToRemove);
					_.pullAll(cascadingItems, itemsToRemove);

					itemIndex = allCascadingItems.indexOf(item);

					// Sometimes, item variable is not the same as the item in allCascadingItems/cascadingItems so .indexOf returns -1.
					// Reason is unclear but the item probably gets recreated and the wfDataAnswering directive that is calling updateChildren uses the old reference.
					if (itemIndex === -1)
						return;

					if (addedWithNormalChildren && outResult) {
						allCascadingItems.splice.apply(allCascadingItems, [ itemIndex + 1, 0 ].concat(outResult.addedItems));
						cascadingItems.splice.apply(cascadingItems, [ itemIndex + 1, 0 ].concat(_.take(outResult.addedItems, vm.infiniteScrollChunkSize)));
					}
					else {
						if ((item.type === enums.objectType.question || item.type === enums.objectType.measure) && (includeRelatedContentAsChildren || item.activeCustomGetter === "relatedContentAsChildren")) {
							_.remove(item.childs, { type: item.type === enums.objectType.question ? enums.objectType.questionAnswer : enums.objectType.measureAnswer });
						}
						allCascadingItems.splice.apply(allCascadingItems, [ itemIndex + 1, 0 ].concat(item.childs));
						cascadingItems.splice.apply(cascadingItems, [ itemIndex + 1, 0 ].concat(_.take(item.childs, vm.infiniteScrollChunkSize)));

						if (_.get(item, "content.conditions.subItemsSettings.pickerSettings")) {
							_.each(item.childs, function (subItem) {
								itemIndex = allCascadingItems.indexOf(subItem);
								allCascadingItems.splice.apply(allCascadingItems, [ itemIndex + 1, 0 ].concat(subItem.childs));
								cascadingItems.splice.apply(cascadingItems, [ itemIndex + 1, 0 ].concat(_.take(subItem.childs, vm.infiniteScrollChunkSize)));
							});
						}
					}

					addAdditionalValuesOnItems();
					sortedArray = _.sortBy(allCascadingItems, "absoluteOrder")
					allCascadingItems.length = 0;
					Array.prototype.push.apply(allCascadingItems, sortedArray);

					sortedArray = _.sortBy(cascadingItems, "absoluteOrder")
					cascadingItems.length = 0;
					Array.prototype.push.apply(cascadingItems, sortedArray);

					$timeout();
					item.updateMetadata();
					item.updateFulfillment();
				});

				// if (_.get(item, "content.conditions.subItemsSettings.pickerSettings")) {
				// 	_.each(item.childs, function (item) {
				// 		updateChildrenOfItem(item);
				// 	});
				// }

				return;
			}
			else {
				getChildrenFromJsData();

				addAdditionalValuesOnItems();
					sortedArray = _.sortBy(allCascadingItems, "absoluteOrder")
					allCascadingItems.length = 0;
					Array.prototype.push.apply(allCascadingItems, sortedArray);

					sortedArray = _.sortBy(cascadingItems, "absoluteOrder")
					cascadingItems.length = 0;
					Array.prototype.push.apply(cascadingItems, sortedArray);

					$timeout();
					item.updateMetadata();
					item.updateFulfillment();
			}


			function getChildrenFromJsData() {

				// Get latest data from JSData
				query = { where: {
					type: childrenRelationType,
					parentData1: null,
					wffid: { "in": item.wfid }
				} };

				if (relationIntersectionTargetWfid)
					query.where.intersectionTargetWfid = relationIntersectionTargetWfid;

				dataRelations = wfObject.filter(query);
				dataRelationWfids = _.map(dataRelations, "wfid");
				dataRelationsByChildWfid = _.groupBy(dataRelations, "wfcid");

				childWfids = _.map(dataRelations, "wfcid");

				// Get childContent of all the relations
				childContents = wfObject.filter({ where: {
					wfid: { "in": childWfids }
				} })

				_.each(childContents, function (content) {
					var relations = dataRelationsByChildWfid[content.wfid];

					for (var i = 0, len = relations.length, relation; i < len; i++) {
						relation = relations[i]
						if (!_.find(allItems, { relationWfid: relation.wfid })) // Only add new relations to the cache
							originalItems.all.push(addToCache(item.depth + 1, content, relation, item));
					}
				});

				// Remove items that were deleted (start with item.childs beacuse that is faster than allItems)
				itemsToRemove = _.remove(item.childs, function (_item) {
					return !_.includes(dataRelationWfids, _item.relationWfid);
				});

				if (itemsToRemove.length > 0) {
					// Now, remove from allItems using equality check.
					// _.filter can not be used because no items will be removed from the original array
					_.remove(allItems, function (_item) {
						return _item.parentWfid === item.wfid && _.includes(itemsToRemove, _item);
					});
					_.remove(originalItems.all, function (_item) {
						return _item.parentWfid === item.wfid && _.includes(itemsToRemove, _item);
					});
				}

				// Rebuild lookup objects
				itemsByWfid = _.groupBy(allItems, "wfid");
				itemsByDepth = _.groupBy(allItems, "depth");
				itemsByParentWfid = _.groupBy(allItems, "parentWfid");

				// Empty child arrays
				if (!item.childs)
					item.childs = []
				else
					item.childs.length = 0;

				if (!item.filteredChildren)
					item.filteredChildren = [];
				else
					item.filteredChildren.length = 0;

				// Set children arrays and hasChildren prop on item
				newItemChildsArray = itemsByParentWfid[item.wfid];
				item.hasChildren = !!newItemChildsArray

				_.remove(allCascadingItems, { parentWfid: item.wfid });
				_.remove(cascadingItems, { parentWfid: item.wfid });

				if (item.hasChildren) {
					newItemChildsArray = _.sortBy(newItemChildsArray, "order");
					_.assign(item.childs, newItemChildsArray);
					_.assign(item.filteredChildren, newItemChildsArray);

					var parentIndex = allCascadingItems.indexOf(item);
					var addedCounter = 1
					_.each(newItemChildsArray, function (item) {
						allCascadingItems.splice(parentIndex + addedCounter, 0, item);
						cascadingItems.splice(parentIndex + addedCounter, 0, item);
						addedCounter++;
					});
				}
			}
		}

		function openModal_addInformation(item) {
			modal.openCreatorAndPicker({
				title: $translate.instant('AddInformation'),
				relationTarget: { item: item.content, kind: enums.subItemsKind.childrenByUser },
				objectTypes: item.content.conditions ? item.content.conditions.objectTypes : undefined,
				intersection: item.intersectionSettings
			}).closed(function () {
				var
					dataRelations,
					dataRelationWfids,
					dataRelationsByChildWfid,
					childContents,
					childWfids,
					parentContents,
					newItemChildsArray,
					itemsToRemove
				;

				// Get latest data from JSData
				dataRelations = wfObject.filter({ where: {
					type: enums.objectType.dataRelation,
					parentData1: null,
					wffid: { "in": item.wfid }
				} })
				dataRelationWfids = _.map(dataRelations, "wfid");
				dataRelationsByChildWfid = _.groupBy(dataRelations, "wfcid");

				childWfids = _.map(dataRelations, "wfcid");

				// Get childContent of all the relations
				childContents = wfObject.filter({ where: {
					wfid: { "in": childWfids }
				} })

				_.each(childContents, function (content) {
					var relations = dataRelationsByChildWfid[content.wfid];

					for (var i = 0, len = relations.length, relation; i < len; i++) {
						relation = relations[i]
						if (!_.find(allItems, { relationWfid: relation.wfid })) // Only add new relations to the cache
							originalItems.all.push(addToCache(item.depth + 1, content, relation, item));
					}
				});

				// Remove items that were deleted (start with item.childs beacuse that is faster than allItems)
				itemsToRemove = _.remove(item.childs, function (_item) {
					return !_.includes(dataRelationWfids, _item.relationWfid);
				});

				if (itemsToRemove.length > 0) {
					// Now, remove from allItems using equality check.
					// _.filter can not be used because no items will be removed from the original array
					_.remove(allItems, function (_item) {
						return _item.parentWfid === item.wfid && _.includes(itemsToRemove, _item);
					});
					_.remove(originalItems.all, function (_item) {
						return _item.parentWfid === item.wfid && _.includes(itemsToRemove, _item);
					});
				}

				// Rebuild lookup objects
				itemsByWfid = _.groupBy(allItems, "wfid");
				itemsByDepth = _.groupBy(allItems, "depth");
				itemsByParentWfid = _.groupBy(allItems, "parentWfid");

				// Empty child arrays
				item.childs.length = 0;
				item.filteredChildren.length = 0;

				// Set children arrays and hasChildren prop on item
				newItemChildsArray = itemsByParentWfid[item.wfid];
				item.hasChildren = !!newItemChildsArray

				_.remove(allCascadingItems, { parentWfid: item.wfid });
				_.remove(cascadingItems, { parentWfid: item.wfid });

				if (item.hasChildren) {
					newItemChildsArray = _.sortBy(newItemChildsArray, "order");
					_.assign(item.childs, newItemChildsArray);
					_.assign(item.filteredChildren, newItemChildsArray);

					var parentIndex = allCascadingItems.indexOf(item);
					var addedCounter = 1
					_.each(newItemChildsArray, function (item) {
						allCascadingItems.splice(parentIndex + addedCounter, 0, item);
						cascadingItems.splice(parentIndex + addedCounter, 0, item);
						addedCounter++;
					});
				}

				addAdditionalValuesOnItems();

				$timeout();
				item.updateMetadata();
				item.updateFulfillment();
			});
		}

		function openObjectViewer(item) {
			// $state.go("objectViewer", { objectWfid: item.wfid });
			var url = $state.href("objectViewer_encoded", { encodedData: item.content.getEncodedIdentifiers(item.dataRelation) });
			window.open(url, '_blank');

		}

		function openModal_attachInformation(item, type) {
			var contextParentWfids;
			var pickerOptions = {
				objectTypes: [ type ],
				relationTarget: {
					item: item.content,
					kind: enums.subItemsKind.relatedContentByUser
				}
			};

			// if (vm.intersectionObject && item.originalRelation.settings && item.originalRelation.settings.contextParentWfids) {
			// 	contextParentWfids = item.originalRelation.settings.contextParentWfids;
			// }
			// else if (item.dataRelation.settings && item.dataRelation.settings.contextParentWfids) {
			// 	contextParentWfids = item.dataRelation.settings.contextParentWfids;
			// }

			// if (contextParentWfids) {
				pickerOptions.intersection = item.intersectionSettings;//{ contextParents: contextParentWfids };
			// }

			modal.openCreatorAndPicker(pickerOptions).closed(function () {
				item.updateMetadata();
			});
		}

		function setMeasureReportingPeriod(item) {
			var objectSettings = {
				uiMode: vm.uiMode,
				settings: [
					{
						label: $translate.instant("modules.valueChain.objectSettings.measurePeriodSettings.label"),
						settingKind: "measurePeriodSettings",
						options: [
							{
								whenNotSet: true,
								name: $translate.instant("calendarFrequency.yearly"),
								checkValue: function (value) {
									return !value || value.frequency === enums.calendarFrequency.yearly
								},
								setValue: function (value) {
									return { frequency: enums.calendarFrequency.yearly };
								}
							},
							{
								name: $translate.instant("calendarFrequency.halfYearly"),
								checkValue: function (value) {
									return value && value.frequency === enums.calendarFrequency.halfYearly
								},
								setValue: function (value) {
									return { frequency: enums.calendarFrequency.halfYearly };
								}
							},
							{
								name: $translate.instant("calendarFrequency.quarterly"),
								checkValue: function (value) {
									return value && value.frequency === enums.calendarFrequency.quarterly
								},
								setValue: function (value) {
									return { frequency: enums.calendarFrequency.quarterly };
								}
							},
							{
								name: $translate.instant("calendarFrequency.monthly"),
								checkValue: function (value) {
									return value && value.frequency === enums.calendarFrequency.monthly
								},
								setValue: function (value) {
									return { frequency: enums.calendarFrequency.monthly };
								}
							},
							{
								name: $translate.instant("calendarFrequency.custom"),
								checkValue: function (value) {
									return value && value.frequency === enums.calendarFrequency.custom
								},
								setValue: function (value) {
									return { frequency: enums.calendarFrequency.custom };
								},
								validate: function (value) {
									if (!value.ranges || !value.ranges.length) {
										modal.alert({
											// title: translate("measurePeriod.intervalsMissingModal.title"),
											message:  $translate.instant("measurePeriod.intervalsMissingModal.message"),
											type: "info"
										});
										return false;
									}
									else
										return true;
								},
								additionalWhenSelected: {
									template: "<wf-measure-period-settings-editor form='form' value='form.value'></wf-measure-period-settings-editor>"
								}
							}
						]
					}
				],
				onUpdated: function (updatedSettings) {
					$timeout();
				}
			};

			modal.editFormattedObjectSettings({
				item: item.dataRelation.originalRelation,
				objectSettings: objectSettings.settings
			}).then(function () {
				if (typeof item.$scope.handleRequirementsSettings === "function")
					item.$scope.handleRequirementsSettings();

				if (typeof item.$scope.contentCompiler.compile === "function")
					item.$scope.contentCompiler.compile();

			});
		}

		function editItem(item) {
			modal.edit(item.content).then(function (res) {
				if (item.type === enums.objectType.finding)
					syncFindingsFilterButtons();

				var itemWithScope;

				if (!item.$scope) { // Needed because of duplication bug
					itemWithScope = _.find(itemsByWfid[item.wfid], function (_item) {
						return _item.relationWfid === item.relationWfid && _item.$scope;
					});
					if (itemWithScope) {
						if (itemWithScope.contentCompiler && typeof itemWithScope.contentCompiler.compile === "function")
							itemWithScope.contentCompiler.compile();
					}
				}
				else {
					if (typeof item.$scope.contentCompiler.compile === "function")
						item.$scope.contentCompiler.compile();
				}

			});
		}

		function deleteItem(item) {
			modal.confirmDelete(item.content).then(function () {
				onItemDeleted(item);
			});

		}

		function deleteRelation(item) {
			modal.confirmDelete(item.dataRelation).then(function () {
				var itemOccurences = itemsByWfid[item.wfid];
				var parentItemOccurences = itemsByWfid[item.parentWfid];

				$scope.$broadcast("dropdownActionExecuted", "delete", item.content, item.dataRelation);

				_.each(parentItemOccurences, function (parentItem) {
					_.remove(parentItem.childs, function (_item) {
						return _item === item;
					});
					_.remove(parentItem.filteredChildren, function (_item) {
						return _item === item;
					});
				});

				_.remove(itemOccurences, function (_item) {
					return _item === item;
				});

				if (itemOccurences.length === 0) {
					delete item.wfid in itemsByWfid;
				}

				_.remove(itemsByParentWfid[item.parentWfid], function (_item) {
					return _item === item;
				});

				_.remove(allItems, function (_item) {
					return _item === item || _item.relationWfid === item.relationWfid; //relationWfid needed because of duplication bug
				});

				_.remove(originalItems.all, function (_item) {
					return _item === item || _item.relationWfid === item.relationWfid; //relationWfid needed because of duplication bug
				});

				_.remove(allCascadingItems, { relationWfid: item.relationWfid });
				_.remove(cascadingItems, { relationWfid: item.relationWfid });

				$timeout();

				item.parent.updateMetadata();
				item.parent.updateFulfillment();
			});
		}

		function onItemDeleted(item) {
			var itemOccurences = itemsByWfid[item.wfid];
			var parentItemOccurences = itemsByWfid[item.parentWfid];

			$scope.$broadcast("dropdownActionExecuted", "delete", item.content, item.dataRelation);

			_.each(parentItemOccurences, function (parentItem) {
				_.remove(parentItem.childs, function (_item) {
					return _item === item;
				});
				_.remove(parentItem.filteredChildren, function (_item) {
					return _item === item;
				});
			})

			_.remove(itemOccurences, function (_item) {
				return _item === item;
			});

			if (itemOccurences.length === 0) {
				delete item.wfid in itemsByWfid;
			}

			_.remove(itemsByParentWfid[item.parentWfid], function (_item) {
				return _item === item;
			});

			_.remove(allItems, function (_item) {
				return _item === item || _item.relationWfid === item.relationWfid; //relationWfid needed because of duplication bug
			});

			_.remove(originalItems.all, function (_item) {
				return _item === item || _item.relationWfid === item.relationWfid; //relationWfid needed because of duplication bug
			});

			_.remove(allCascadingItems, { relationWfid: item.relationWfid });
			_.remove(cascadingItems, { relationWfid: item.relationWfid });

			$timeout();

			item.parent.updateMetadata();
			item.parent.updateFulfillment();
		}

		var
			chunkSize = vm.infiniteScrollChunkSize,
			offset = 0
		;

		function pagingFunction() {
			var newChunk;
			newChunk = _.chain(allCascadingItems).slice(cascadingItems.length).take(chunkSize).value();

			if (newChunk.length) {
				Array.prototype.push.apply(cascadingItems, newChunk)
			}

		}

		function onSunburstLoaded() {
			if (vm.sunburstControl && vm.sunburstControl.onNodeClick) {
				// console.log("nice")
				vm.sunburstControl.onNodeClick(function (d) {
					var levelFilter;

					// if (d.wfid === 'root')
					// 	levelFilter = _.find(levels[0].allFilterButtons, { id: "all" });
					// else
					// 	levelFilter = _.find(levels[0].allFilterButtons, { id: d.wfid });

					// if (levelFilter) {
					// 	levels[0].setFilter(levelFilter, false, true);
					// }

					if (d.wfid === 'root') {
						levelFilter = _.find(levels[0].allFilterButtons, { id: "all" });
						if (levelFilter)
							levels[0].setFilter(levelFilter, false, true);
					}
					else {
						jumpToObject(d.wfid)
					}

					$timeout();

				});
			}

			jumpToObject(initialJumpObject)
		}

		function jumpToObject(wfid) {
			var
				jumpObjectParts,
				jumpObjectId,
				jumpObjectType,
				itemOccurences,
				item,
				parent,
				level,
				higherLevel,
				filterButton,
				filterOnEachLevel = []
			;

			if (wfid) {
				jumpObjectParts = wfid.split("-");
				jumpObjectType = parseInt(jumpObjectParts[0]);
				jumpObjectId = parseInt(jumpObjectParts[1]);

				item = _.first(itemsByWfid[wfid]);
				if (item) {
					parent = item.parent;
					level = _.find(levels, { depth: item.depth });
					higherLevel = _.find(levels, function (level) {
						return level.depth > item.depth;
					});

					if (!level && parent) {
						item = parent;
						level = _.find(levels, { depth: item.depth });
					}

					while (level) {
						filterButton = _.find(level.allFilterButtons, { id: item.wfid });
						filterOnEachLevel.unshift({ level: level, filter: filterButton });

						if (level.depth > 1 && item.parent) {
							item = item.parent;
							level = _.find(levels, { depth: level.depth - 1 });
						}
						else
							break;
					}

					_.each(filterOnEachLevel, function (temp) {
						if (temp.filter)
							temp.level.setFilter(temp.filter, true);
					});

					if (higherLevel) {
						higherLevel.setFilter(_.find(higherLevel.allFilterButtons, { id: "all" }), true);
					}

					vm.isJumpingFiltering = true;

					applySelectedFilters();
					$timeout(function () {
						vm.isJumpingFiltering = undefined;
					}, 700)
				}
			}
		}

		function maybeIntersect() {
			var
				item,
				parent,
				intersectionWfids,
				addChildren = function (children, depth) {
					if (!children || !children.length)
						return;

					for (var i = 0, len = children.length, item; i < len; i++) {
						item = children[i];
						item.intersected = true;
						addChildren(_.filter(allItems, { parentWfid: item.wfid }), depth + 1);
					}
				}
				// intersectionSource
				// intersectionSourceObjParts,
				// intersectionSourceObjType,
				// intersectionSourceObjId,
			;

			return $q(function (resolve, reject) {
				originalItems.all = _.clone(allItems);
				originalItems.byWfid = _.groupBy(allItems, "wfid");
				originalItems.wfids = _.chain(originalItems.all).map("wfid").uniq().value();

				if (initialIntersectionObject) {
					dataOps.getSubItems({ wfid: initialIntersectionObject }, enums.subItemsKind.childrenByUser, { getterConditions: { loadRequirements: true } }).then(function () {
						var
							intersectionSourceDataRelations = _.chain(wfObject.filter({
								type: enums.objectType.dataRelation,
								wffid: initialIntersectionObject,
								// organizationId: authOrgId
							})).uniqBy("wfcid").value(),
							intersectionSourceDataRelationsByWfcid = _.keyBy(intersectionSourceDataRelations, "wfcid");
						;

						intersectionWfids = _.map(intersectionSourceDataRelations, "wfcid");
						// if (intersectionWfids.length > 0) {
							vm.rootItem.intersected = true;

							_.each(intersectionWfids, function (wfid) {
								item = _.first(itemsByWfid[wfid]);

								if (item) {
									item.originalRelation = intersectionSourceDataRelationsByWfcid[wfid];
									// addChildren(_.filter(allItems, { parentWfid: item.wfid, intersected: false }), 1);
									item.intersected = true;
									// parent = item.parent;

									// while (parent) {
									// 	parent.intersected = true;
									// 	parent = parent.parent;
									// }
								}
							})

							_.remove(allItems, { intersected: false });
							_.each(allItems, function (item) {
								item.intersected = false;
							});
							itemsByWfid = _.groupBy(allItems, "wfid");
						// }

						originalItems.all = _.clone(allItems);
						originalItems.byWfid = _.groupBy(allItems, "wfid");
						originalItems.wfids = _.chain(originalItems.all).map("wfid").uniq().value();

						_.each(originalItems.all, function (item) {
							// If items does not have a parent that was included in the intersection then make them children of the rootItem
							if (item !== vm.rootItem && (!item.parentWfid || !originalItems.byWfid[item.parentWfid])) {
								item.parentWfid = vm.rootItem.wfid;
								item.parent = vm.rootItem;
							}
						})

						dataOps.getObject(initialIntersectionObject).then(function (intersectionObject) {
							vm.intersectionObject = intersectionObject;
							vm.pageHeaderObject = intersectionObject;
							vm.pageHeaderPreTitle = vm.mainStructure.title;
							resolve();
						});
					});
				}
				else {
					resolve();
				}
			});
		}

		function buildLimitedHierachy(items, intersectionResultArrays) {
			var
				item,
				parent,
				intersectionWfids = _.chain(items).map("wfid").uniq().value(),
				addChildren = function (children, depth) {
					if (!children)
						return;

					for (var i = 0, len = children.length, item; i < len; i++) {
						item = children[i];
						// console.log(item.wfid)
						item.intersected = true;
						item.includeInList = true;
						// console.log(i, item.wfid, item.childs);
						if (item.type === enums.objectType.structure) {
							// console.log(new Array(depth + 1).join("-"), item.wfid);
							addChildren(_.filter(allItems, { parentWfid: item.wfid }), depth + 1);
						}
					}
				},
				intersectToRootByWfid = {}, // Items that should include all its parents in the intersected result (when intersecting on answer types)
				intersectAllChildrenByWfid = {} // Items that should include all its children in the intersected result (when intersecting on search)
			;

			allItems = _.clone(originalItems.all);

			if (intersectionResultArrays && intersectionResultArrays.intersectedWithAnswerTypes && intersectionResultArrays.intersectedWithAnswerTypes.length) {
				intersectToRootByWfid = _.keyBy(intersectionResultArrays.intersectedWithAnswerTypes, "wfid");
			}
			if (intersectionResultArrays && intersectionResultArrays.intersectedWithSearch && intersectionResultArrays.intersectedWithSearch.length) {
				intersectAllChildrenByWfid = _.keyBy(intersectionResultArrays.intersectedWithSearch, "wfid");
				_.assign(intersectToRootByWfid, intersectAllChildrenByWfid);
			}

			if (intersectionResultArrays && intersectionResultArrays.intersectedWithRequirements && intersectionResultArrays.intersectedWithRequirements.length) {
				_.assign(intersectToRootByWfid, _.keyBy(intersectionResultArrays.intersectedWithRequirements, "wfid"));
			}

			if (intersectionResultArrays && intersectionResultArrays.intersectedWithFindingsSeverity && intersectionResultArrays.intersectedWithFindingsSeverity.length) {
				_.assign(intersectToRootByWfid, _.keyBy(intersectionResultArrays.intersectedWithFindingsSeverity, "wfid"));
			}

			if (intersectionResultArrays && intersectionResultArrays.intersectedWithFindingsClosed && intersectionResultArrays.intersectedWithFindingsClosed.length) {
				_.assign(intersectToRootByWfid, _.keyBy(intersectionResultArrays.intersectedWithFindingsClosed, "wfid"));
			}

			if (intersectionResultArrays && intersectionResultArrays.intersectedWithFindingsLocked && intersectionResultArrays.intersectedWithFindingsLocked.length) {
				_.assign(intersectToRootByWfid, _.keyBy(intersectionResultArrays.intersectedWithFindingsLocked, "wfid"));
			}

			if (intersectionResultArrays && intersectionResultArrays.intersectedWithFindingsWasClosedOnTime && intersectionResultArrays.intersectedWithFindingsWasClosedOnTime.length) {
				_.assign(intersectToRootByWfid, _.keyBy(intersectionResultArrays.intersectedWithFindingsWasClosedOnTime, "wfid"));
			}

			// console.log(items, intersectionWfids)

			if (items) {
				itemsByWfid = _.groupBy(allItems, "wfid");

				vm.rootItem.intersected = true;

				// console.log(intersectionWfids);
				_.each(intersectionWfids, function (wfid) {
					item = _.first(itemsByWfid[wfid]);

					if (item) {
						// console.log(item.wfid, "addChildren")
						if (item.wfid in intersectAllChildrenByWfid && item.type === enums.objectType.structure) { // <-----------------
							addChildren(_.filter(allItems, { parentWfid: item.wfid }), 1);
						}
						item.intersected = true;
						item.includeInList = true;

						if (item.wfid in intersectToRootByWfid) {
							parent = item.parent;

							while (parent) {
								parent.intersected = true;
								parent = parent.parent;
							}
						}
					}
				})

				delete vm.rootItem.includeInList;

				_.remove(allItems, { intersected: false });

				_.each(originalItems.all, function (item) {
					item.intersected = false;
				});
			}

			itemsByWfid = _.groupBy(allItems, "wfid");

			// console.log(allItems)

			linkItems();

			// Add absoluteOrder value and maybe itemNumbering value
			addAdditionalValuesOnItems();
		}

		function getHierarchyFromItemsAsList(items, parentsToInclude) {
			var output = [], parent;
			var addChildren = function (item) {
				var children = _.filter(allItems, { parentWfid: item.wfid });

				for (var i = 0, len = children.length, child; i < len; i++) {
					child = children[i];
					output.push(child);
					if (child.type === enums.objectType.structure) {
						addChildren(child);
					}
				}
			};
			// console.info("getAllChildrenFromItems", items);

			for (var i = 0, len = items.length, child; i < len; i++) {
				output.push(items[i]);
				addChildren(items[i]);
			}

			if (items[0]) {
				parent = items[0].parent;
				while (parent) {
					output.push(parent)
					parent = parent.parent;
				}
			}

			return output;
		}

		function handleAggregatedParentsByUser() {
			var
				parentDataRelations,
				uniqueParentContents,
				uniqueParentWfids,
				itemWfids,
				childItemsByParentWfid
			;

			vm.aggregatedParents.itemsByParentWfid = {};
			vm.aggregatedParents.selectedFiltersById = {};
			vm.aggregatedParents.answerTypeFilterButtons = [];
			vm.aggregatedParents.structureFilterButtons = [];
			vm.aggregatedParents.userFilterButtons = [];
			vm.aggregatedParents.filterButtons = [];

			// if (!vm.showFilterBars) return;

			vm.aggregatedParents.anyFiltersSelected = function () {
				return !_.isEmpty(vm.aggregatedParents.selectedFiltersById);
			};

			vm.aggregatedParents.updateFilterBars = function (items) {
				var totalItemsCount;

				if (!vm.showFilterBars) return;

				vm.aggregatedParents.resetFilters();

				if (!items) {
					items = _.clone(allItems);
				}
				_.remove(items, { parentWfid: "noParent" });

				vm.aggregatedParents.sourceItems = items;
				vm.searchSourceItems.length = 0;
				_.remove(vm.aggregatedParents.sourceItems, vm.rootItem);

				Array.prototype.push.apply(vm.searchSourceItems, items);
				totalItemsCount = vm.aggregatedParents.sourceItems.length;
				if (!vm.showFilterBars) return;

				itemWfids = _.chain(items).filter({ isUserData: false }).map("wfid").value();

				parentDataRelations = wfObject.filter({ where: {
					type: enums.objectType.dataRelation,
					relationType: null,
					wfcid: { "in": itemWfids },
					parentData1: null,
					organizationId: authOrgId,

					// Excluding legacy categories (Relevanta, Ej relevanta, Betydande, Ej betydande, Prioriterade, Ej prioriterade)
					// from "ISO 26k Process Tool" (71-10109) and "Process Tool with ISO 26000, GRI & SDG" (71-10236)
					parentId: { "notIn": [ 10237, 10238,  10239, 10240, 10241, 10242 ] }
				} });

				if (orgAccessAdminMode) {
					Array.prototype.push.apply(parentDataRelations, wfObject.filter({ where: {
						type: enums.objectType.accessTag,
						wfcid: { "in": itemWfids },
						organizationId: authOrgId
					} }));
				}

				if (vm.intersectionObject) {
					_.remove(parentDataRelations, { wffid: vm.intersectionObject.wfid });
				}

				_.remove(parentDataRelations, function (dataRelation) {
					return _.includes(originalItems.wfids, dataRelation.wffid);
				});


				uniqueParentWfids = _.chain(parentDataRelations).map("wffid").uniq().value();
				uniqueParentWfids = _.difference(uniqueParentWfids, [ "71-17645" ]) // Exclude structure "Disclosures" used in Insights feature for the materiality analysis

				uniqueParentContents = wfObject.filter({ where: {
					wfid: { in: uniqueParentWfids }
				} });

				vm.aggregatedParents.itemsByParentWfid = _.chain(parentDataRelations).groupBy("wffid").mapValues(function (dataRelations, key) {
					var
						output = [],
						i = dataRelations.length,
						x
					;
					while (i--) {
						Array.prototype.push.apply(output, _.filter(items, { wfid: dataRelations[i].wfcid }));
					}
					return output;
				}).value();

				vm.aggregatedParents.filterButtons = _.chain(uniqueParentContents).map(function (parent) {
					var count = vm.aggregatedParents.itemsByParentWfid[parent.wfid].length;
					// console.log(_.some(vm.aggregatedParents.filterButtons, { highlight: true, id: parent.wfid }));
					return {
						id: parent.wfid,
						type: parent.type,
						title: parent.getMainTextual({ dontSanitize: true }),
						content: parent,
						count: count,
						percentage: getPercentage(count, totalItemsCount),
						showButton: true,
						showProgressBar: true,
						// highlight: vm.aggregatedParents.highlightedFilterId === parent.wfid
						// imageUrl: parent.getImageUrl()
					};
				}).sortBy("title").value();

				vm.aggregatedParents.splitUpStructureCategorizations();

				if (orgAccessAdminMode) {
					vm.aggregatedParents.accessTagFilterButtons = _.filter(vm.aggregatedParents.filterButtons, { type: enums.objectType.organization });
				}

				vm.aggregatedParents.syncStructureProgressBarsWithButtons();
				vm.aggregatedParents.sortFilterButtonsAndProgressBars();
				vm.aggregatedParents.syncAccessTagProgressBarsWithButtons();

				//-------------------------------------------------------------------------
				var questions = _.chain(items).filter({ type: enums.objectType.question }).value();
				if (questions.length > 0) {
					var questionsByWfid = _.keyBy(questions, "wfid");
					var questionWfids = _.map(questions, "wfid");
					var latestQuestionAnswerDataRelations = _.chain(wfObject.filter({ where: {
						type: 73,
						parentData1: null,
						wffid: { "in": questionWfids },
						parentType: enums.objectType.question,
						childType: enums.objectType.questionAnswer,
						organizationId: authOrgId
					} })).sortBy([ "wffid", "createdAt" ]).groupBy("wffid").mapValues(function (dataRelations) {
						return _.last(dataRelations);
					}).map().value();
					var latestAnswers = wfObject.filter({ where: {
						type: enums.objectType.questionAnswer,
						wfid: { "in": _.map(latestQuestionAnswerDataRelations, "wfcid") } }
					});
					var uniqueQuestionAnswerTypes = _.chain(latestAnswers).uniqBy("wfcid").map("childContent").uniqBy("wfid").value();
					var questionItemsByLatestAnswerTypeWfid = _.chain(uniqueQuestionAnswerTypes).keyBy("wfid").mapValues(function (questionAnswerType) {
						var output = [];

						var answerWfids = _.chain(latestAnswers).filter({ wfcid: questionAnswerType.wfid }).map("wfid").value();
						var relations = _.filter(latestQuestionAnswerDataRelations, function (dataRelation) {
							return _.includes(answerWfids, dataRelation.wfcid);
						});
						var questionsWithSpecificAnswer = _.map(relations, function (dataRelation) {
							return questionsByWfid[dataRelation.wffid];
						})
						output = questionsWithSpecificAnswer

						return output;
					}).value();
					var questionWfids_withAnswers = _.map(latestQuestionAnswerDataRelations, "wffid");
					var unansweredQuestionItems = _.filter(questions, function (item) {
						return !_.includes(questionWfids_withAnswers, item.wfid);
					})
					var unansweredQuestionItemsCount = unansweredQuestionItems.length;
					var answeredQuestionItemsCount = latestQuestionAnswerDataRelations.length;
					vm.aggregatedParents.categorizableItems = items.length;
					vm.aggregatedParents.questionsCount = questions.length;


					vm.aggregatedParents.answerTypeFilterButtons = _.chain(uniqueQuestionAnswerTypes).map(function (questionAnswerType) {
						var items = questionItemsByLatestAnswerTypeWfid[questionAnswerType.wfid];
						vm.aggregatedParents.itemsByParentWfid[questionAnswerType.wfid] = items;

						return {
							id: questionAnswerType.wfid,
							type: questionAnswerType.type,
							title: questionAnswerType.getMainTextual(),
							content: questionAnswerType,
							count: items.length,
							percentage: getPercentage(items.length, vm.aggregatedParents.questionsCount),
							showButton: true,
							showProgressBar: true
							// imageUrl: parent.getImageUrl()
						};
					}).sortBy("id").value();


					if (unansweredQuestionItemsCount > 0) {
						vm.aggregatedParents.answerTypeFilterButtons.unshift({
							id: "0-unansweredQuestions",
							type: enums.objectType.questionAnswerType,
							title: $translate.instant("Unanswered"),
							count: unansweredQuestionItemsCount,
							showButton: true
						});
						vm.aggregatedParents.itemsByParentWfid["0-unansweredQuestions"] = unansweredQuestionItems;
					}

					vm.aggregatedParents.answerTypeFilterButtons.unshift({
						id: "0-answeredQuestions",
						type: enums.objectType.questionAnswerType,
						title: $translate.instant("TotalAnswered"),
						count: answeredQuestionItemsCount,
						percentage: getPercentage(answeredQuestionItemsCount, vm.aggregatedParents.questionsCount),
						showProgressBar: true
					});
				}
				else {
					vm.aggregatedParents.answerTypeFilterButtons = [];
				}

				//-------------------------------------------------------------------------

				_.each([
					enums.fulfillmentState.unfulfilled,
					enums.fulfillmentState.fulfilled,
					enums.fulfillmentState.assessmentNeeded,
					enums.fulfillmentState.reportingNeeded
				], function (state) {
					if (vm.aggregatedParents.itemsByFulfillmentState && vm.aggregatedParents.itemsByFulfillmentState[state])
						vm.aggregatedParents.itemsByParentWfid["0-fulfillmentState-" + state] = vm.aggregatedParents.itemsByFulfillmentState[state]; // The items with a certain state is put in the itemsByFulfillmentState lookup. Not sure why it is needed.
				});

				//-------------------------------------------------------------------------

				vm.aggregatedParents.selectedFiltersById = {};

				vm.aggregatedParents.syncRelatedContentFilterButtons()
				// console.log(vm.aggregatedParents)
			};

			vm.aggregatedParents.resetFilters = function () {
				// _.each(vm.aggregatedParents.itemsByParentWfid, function (value, key) {
				// 	console.log("In " + key + ", 71-116: ", _.find(value, { wfid: "71-116" }));
				// });
				vm.aggregatedParents.itemsByParentWfid = {};
				vm.aggregatedParents.selectedFiltersById = {};
				vm.aggregatedParents.toggleFilter();
			};

			vm.aggregatedParents.sortFilterButtonsAndProgressBars = function() {
				vm.aggregatedParents.deviationFilterButtons = _.sortBy(vm.aggregatedParents.deviationFilterButtons, function(item) {
					return deviationCategoryWfids.indexOf(item.id);
				});

				vm.aggregatedParents.materialityFilterButtons = _.sortBy(vm.aggregatedParents.materialityFilterButtons, function(item) {
					return materialityCategoryWfids.indexOf(item.id);
				});


				vm.aggregatedParents.deviationProgressBars = _.sortBy(vm.aggregatedParents.deviationProgressBars, function(item) {
					return deviationCategoryWfids.indexOf(item.id);
				});

				vm.aggregatedParents.materialityProgressBars = _.sortBy(vm.aggregatedParents.materialityProgressBars, function(item) {
					return materialityCategoryWfids.indexOf(item.id);
				});

				vm.aggregatedParents.scopeProgressBars = _.sortBy(vm.aggregatedParents.scopeProgressBars, function(item) {
					return scopeCategoryWfids.indexOf(item.id);
				});
			}

			$scope.$on("wfObject.created", function ($event, wfid, obj) {
				var parent, answer, answerType, questionWfid, filterButton, questionNewlyAnswered, count;

				if (!vm.showFilterBars)
					return;

				// console.log("created", wfid, obj)
				if (obj.type === enums.objectType.dataRelation && originalItems.byWfid[obj.wfcid]) {
					if (!vm.aggregatedParents.itemsByParentWfid[obj.wffid]) {
						vm.aggregatedParents.itemsByParentWfid[obj.wffid] = [];

						parent = obj.parentContent;
						vm.aggregatedParents.filterButtons.push({
							id: parent.wfid,
							type: parent.type,
							title: parent.getMainTextual({ dontSanitize: true }),
							content: parent,
							count: 1,
							percentage: getPercentage(1, vm.aggregatedParents.sourceItems.length),
							showButton: true,
							showProgressBar: true
						});
						vm.aggregatedParents.filterButtons = _.sortBy(vm.aggregatedParents.filterButtons, "title");
						vm.aggregatedParents.splitUpStructureCategorizations();
					}
					else {
						filterButton = _.find(vm.aggregatedParents.filterButtons, { id: obj.wffid });
						if (filterButton) {
							filterButton.count++;
							filterButton.percentage = getPercentage(filterButton.count, vm.aggregatedParents.sourceItems.length);
						}
					}

					vm.aggregatedParents.itemsByParentWfid[obj.wffid].push(_.first(originalItems.byWfid[obj.wfcid]));

					vm.aggregatedParents.syncStructureProgressBarsWithButtons();
					vm.aggregatedParents.sortFilterButtonsAndProgressBars();
					// vm.aggregatedParents.toggleFilter();

					if (vm.aggregatedParents.highlightedFilterId === obj.wffid)
						vm.aggregatedParents.toggleHighlighting();
				}
				else if (obj.type === enums.objectType.dataRelation
					&& obj.parentType === enums.objectType.question
					&& obj.childType === enums.objectType.questionAnswer
					&& originalItems.byWfid[obj.wffid]
				) {
					answer = obj.childContent;
					answerType = answer.childContent;
					questionWfid = obj.wffid;
					questionNewlyAnswered = false;

					_.each(vm.aggregatedParents.answerTypeFilterButtons, function (filterButton) {
						var removed;
						if (!filterButton || filterButton.id === "0-answeredQuestions")
							return;
						// console.log(filterButton);

						removed = _.remove(vm.aggregatedParents.itemsByParentWfid[filterButton.id], { wfid: questionWfid });
						if (removed.length) {
							filterButton.count--;
							if (filterButton.id === "0-unansweredQuestions")
								questionNewlyAnswered = true; // Means that the question was previously not answered and now it is.
						}

						if (vm.aggregatedParents.itemsByParentWfid[filterButton.id].length === 0) {
							_.remove(vm.aggregatedParents.answerTypeFilterButtons, { id: filterButton.id });
							delete vm.aggregatedParents.itemsByParentWfid[filterButton.id];
						}
					});

					if (!vm.aggregatedParents.itemsByParentWfid[answerType.wfid]) {
						vm.aggregatedParents.itemsByParentWfid[answerType.wfid] = [];

						vm.aggregatedParents.answerTypeFilterButtons.push({
							id: answerType.wfid,
							type: enums.objectType.questionAnswerType,
							title: answerType.getMainTextual(),
							content: answerType,
							count: 1,
							percentage: getPercentage(1, vm.aggregatedParents.questionsCount),
							showButton: true,
							showProgressBar: true
						});
						vm.aggregatedParents.answerTypeFilterButtons = _.sortBy(vm.aggregatedParents.answerTypeFilterButtons, "id");
					}
					else {
						filterButton = _.find(vm.aggregatedParents.answerTypeFilterButtons, { id: answerType.wfid });
						if (filterButton) {
							filterButton.count++;
							filterButton.percentage = getPercentage(filterButton.count, vm.aggregatedParents.questionsCount)
						}
					}

					if (questionNewlyAnswered) {
						filterButton = _.find(vm.aggregatedParents.answerTypeFilterButtons, { id: "0-answeredQuestions" });
						if (filterButton) {
							filterButton.count++;
							filterButton.percentage = getPercentage(filterButton.count, vm.aggregatedParents.questionsCount)

						}
					}

					vm.aggregatedParents.itemsByParentWfid[answerType.wfid].push(_.first(originalItems.byWfid[questionWfid]));
					$timeout();
				}
				else if (obj.type === enums.objectType.dataRelation
					&& obj.parentData1 === 1 // RelatedContent kind (Attached information)
					&& originalItems.byWfid[obj.wffid]
				) {
					vm.aggregatedParents.syncRelatedContentFilterButtons();
				}
			});

			$scope.$on("wfObject.destroyed", function ($event, wfid, obj) {
				var filterButton;
				if (obj.type === enums.objectType.dataRelation && obj.parentData1 === null && vm.aggregatedParents.itemsByParentWfid[obj.wffid]) {
					_.remove(vm.aggregatedParents.itemsByParentWfid[obj.wffid], { wfid: obj.wfcid });

					if (vm.aggregatedParents.itemsByParentWfid[obj.wffid].length === 0) {
						if (vm.aggregatedParents.selectedFiltersById[obj.wffid])
							vm.aggregatedParents.toggleFilter(vm.aggregatedParents.selectedFiltersById[obj.wffid]);

						_.remove(vm.aggregatedParents.filterButtons, { id: obj.wffid });
						delete vm.aggregatedParents.itemsByParentWfid[obj.wffid];

						vm.aggregatedParents.splitUpStructureCategorizations();
					}
					else {
						filterButton = _.find(vm.aggregatedParents.filterButtons, { id: obj.wffid });
						if (filterButton) {
							filterButton.count--;
							filterButton.percentage = getPercentage(filterButton.count, vm.aggregatedParents.sourceItems.length);
						}

						// vm.aggregatedParents.toggleFilter();
					}

					vm.aggregatedParents.syncStructureProgressBarsWithButtons();
					vm.aggregatedParents.sortFilterButtonsAndProgressBars();

					if (vm.aggregatedParents.highlightedFilterId === obj.wffid)
						vm.aggregatedParents.toggleHighlighting();
				}
				else if (obj.type === enums.objectType.dataRelation
					&& obj.parentData1 === 1 // RelatedContent kind (Attached information)
					&& originalItems.byWfid[obj.wffid]
				) {
					vm.aggregatedParents.syncRelatedContentFilterButtons();
				}
			});

			vm.aggregatedParents.syncRequirementFilterButtons = function () {

			};

			vm.aggregatedParents.syncRelatedContentFilterButtons = function () {
				var
					items = vm.aggregatedParents.sourceItems || [],
					totalItemsCount = items.length,
					// allItemWfids = _.map(items, "wfid"),
					// uniqueRelatedContentRelationWffids,
					items_withRelatedContent,
					items_withoutRelatedContent,
					count_withRelatedContent,
					count_withoutRelatedContent
				;

				items_withRelatedContent = _.filter(items, function (item) {
					return _.get(item, "content.metadata.countByRelationKind[5]") > 0 // Check if relatedContentByUser count is defined and larger than zero
				});
				items_withoutRelatedContent = _.difference(items, items_withRelatedContent);

				count_withRelatedContent = items_withRelatedContent.length;
				count_withoutRelatedContent = items_withoutRelatedContent.length;
				// uniqueRelatedContentRelationWffids = _.chain(
				// 	wfObject.filter({ where: {
				// 			type: enums.objectType.dataRelation,
				// 			wffid: { "in": allItemWfids },
				// 			parentData1: 1 // RelatedContent kind
				// 	} })
				// )
				// .map("wffid")
				// .uniq()
				// .value();

				// count_withRelatedContent = uniqueRelatedContentRelationWffids.length;
				// count_withoutRelatedContent = allItemWfids.length - count_withRelatedContent;

				vm.aggregatedParents.hasAnyItemRelatedContentByUser = items_withRelatedContent.length > 0;
				vm.aggregatedParents.itemsByParentWfid["0-withRelatedContent"] = items_withRelatedContent;
				vm.aggregatedParents.itemsByParentWfid["0-withoutRelatedContent"] = items_withoutRelatedContent;

				if (vm.aggregatedParents.relatedContentFilterButtons) {
					_.assign(_.find(vm.aggregatedParents.relatedContentFilterButtons, { id: "0-withRelatedContent" }), {
						count: count_withRelatedContent,
						percentage: getPercentage(count_withRelatedContent, totalItemsCount),
					});
					_.assign(_.find(vm.aggregatedParents.relatedContentFilterButtons, { id: "0-withoutRelatedContent" }), {
						count: count_withoutRelatedContent,
						percentage: getPercentage(count_withoutRelatedContent, totalItemsCount),
					});
				}
				else {
					vm.aggregatedParents.relatedContentFilterButtons = _.map([
						{
							id: "0-withRelatedContent",
							count: count_withRelatedContent,
							title: $translate.instant("WithAttachedInformation"),
						},
						{
							id: "0-withoutRelatedContent",
							count: count_withoutRelatedContent,
							title: $translate.instant("WithoutAttachedInformation"),
						}
					], function (filter) {
						return _.assign({
							type: "relatedContent",
							count: filter.count,
							percentage: getPercentage(filter.count, totalItemsCount),
							showButton: true,
							showProgressBar: false,
							toggle: function () {
								if (this.id === "0-withRelatedContent")
									delete vm.aggregatedParents.selectedFiltersById["0-withoutRelatedContent"];
								else
									delete vm.aggregatedParents.selectedFiltersById["0-withRelatedContent"];

								vm.aggregatedParents.toggleFilter(this);
							}
						}, filter);
					});
				}
			};

			vm.aggregatedParents.splitUpStructureCategorizations = function () {
				vm.aggregatedParents.userFilterButtons = _.filter(vm.aggregatedParents.filterButtons, { type: enums.objectType.individual });
				vm.aggregatedParents.structureFilterButtons = _.difference(vm.aggregatedParents.filterButtons, vm.aggregatedParents.userFilterButtons);
				vm.aggregatedParents.materialityFilterButtons = _.chain(vm.aggregatedParents.structureFilterButtons).intersectionWith(materialityCategoryWfids, function (item, wfid) {
					return item.id === wfid;
				}).map(function (item) {
					item.isMaterialityCategory = true;
					return item;
				}).value();

				vm.aggregatedParents.deviationFilterButtons = _.chain(vm.aggregatedParents.structureFilterButtons).intersectionWith(deviationCategoryWfids, function (item, wfid) {
					return item.id === wfid;
				}).map(function (item) {
					item.isDeviationCategory = true;
					return item;
				}).value();

				vm.aggregatedParents.scopeFilterButtons = _.chain(vm.aggregatedParents.structureFilterButtons).intersectionWith(scopeCategoryWfids, function (item, wfid) {
					return item.id === wfid;
				}).map(function (item) {
					item.isScopeCategory = true;
					return item;
				}).value();

				vm.aggregatedParents.customCategoryFilterButtons = [];
				vm.aggregatedParents.customReportFilterButtons = [];
				vm.aggregatedParents.customFrameworkFilterButtons = [];

				var customCategoryStructureWfids = _.map(vm.mainStructureCategories, "wfcid");
				var customReportingStructureWfids = _.map(vm.mainStructureReportingCategories, "wfcid");

				_.each(_.difference(vm.aggregatedParents.structureFilterButtons, vm.aggregatedParents.materialityFilterButtons, vm.aggregatedParents.deviationFilterButtons, vm.aggregatedParents.scopeFilterButtons), function (filterButton) {
					if (!!~customCategoryStructureWfids.indexOf(filterButton.id) || (_.get(filterButton.content, "conditions.intersectionSourceWfid") && !_.get(filterButton.content, "conditions.contextParentWfids"))) {
						vm.aggregatedParents.customCategoryFilterButtons.push(filterButton);
						filterButton.isCustomCategory = true;
					}
					else if (!!~customReportingStructureWfids.indexOf(filterButton.id)) {
						vm.aggregatedParents.customReportFilterButtons.push(filterButton);
						filterButton.isCustomCategory = true;
					}
					else if (!_.get(filterButton.content, "conditions.intersectionSourceWfid") && !_.get(filterButton.content, "conditions.contextParentWfids")) { // Never show these kinds of category if intersected on server (scoped mode)
						vm.aggregatedParents.customFrameworkFilterButtons.push(filterButton);
						filterButton.isCustomFramework = true;
					}
				});
			}

			vm.aggregatedParents.syncStructureProgressBarsWithButtons = function () {
				if (!vm.aggregatedParents.structureProgressBars) {
					vm.aggregatedParents.structureProgressBars = _.clone(vm.aggregatedParents.structureFilterButtons);
				}
				else {
					_.each(vm.aggregatedParents.structureProgressBars, function (progressBar) {
						var filterButton = _.find(vm.aggregatedParents.structureFilterButtons, { id: progressBar.id });
						if (filterButton) {
							progressBar.count = filterButton.count;
							progressBar.percentage = filterButton.percentage;
						}
						else {
							progressBar.count = progressBar.percentage = 0;
						}
					});

					_.each(vm.aggregatedParents.structureFilterButtons, function (filterButton) {
						var progressBar = _.find(vm.aggregatedParents.structureProgressBars, { id: filterButton.id });
						if (!progressBar) {
							vm.aggregatedParents.structureProgressBars.push(filterButton);
						}
					});

				}

				vm.aggregatedParents.contextStructureProgressBars = _.remove(vm.aggregatedParents.structureProgressBars, function (progressBar) {
					return progressBar.content.conditions && progressBar.content.conditions.contextParentWfids && !progressBar.content.conditions.intersectionSourceWfid;
				});

				vm.aggregatedParents.materialityProgressBars = [];
				vm.aggregatedParents.deviationProgressBars = [];
				vm.aggregatedParents.scopeProgressBars = [];
				vm.aggregatedParents.customCategoryProgressBars = [];
				vm.aggregatedParents.customFrameworkProgressBars = [];

				_.each(vm.aggregatedParents.structureProgressBars, function (progressBar) {
					if (progressBar.isMaterialityCategory)
						vm.aggregatedParents.materialityProgressBars.push(progressBar);

					else if (progressBar.isDeviationCategory)
						vm.aggregatedParents.deviationProgressBars.push(progressBar);

					else if (progressBar.isScopeCategory)
						vm.aggregatedParents.scopeProgressBars.push(progressBar);

					else if (progressBar.isCustomCategory) {
						if (vm.customCategoryProgressBarsByWfid[progressBar.id])
							progressBar.highlight = vm.customCategoryProgressBarsByWfid[progressBar.id].highlight;

						vm.aggregatedParents.customCategoryProgressBars.push(progressBar);
					}

					else if (progressBar.isCustomFramework)
						vm.aggregatedParents.customFrameworkProgressBars.push(progressBar);
				});
				var newCustomCategoryProgressBarsByWfid = _.keyBy(vm.aggregatedParents.customCategoryProgressBars, "id");

				if (vm.mainStructureCategories || vm.mainStructureReportingCategories) {

					_.each(_.concat(vm.mainStructureCategories, vm.mainStructureReportingCategories), function (categoryRelation) {
						if (!newCustomCategoryProgressBarsByWfid[categoryRelation.wfcid]) {
							vm.aggregatedParents.customCategoryProgressBars.push({
								id: categoryRelation.wfcid,
								type: categoryRelation.childType,
								highlight: !!((vm.customCategoryProgressBarsByWfid[categoryRelation.wfcid] || {}).highlight),
								count: 0,
								percentage: 0
							});
						}
					});

					vm.customCategoryProgressBarsByWfid = _.keyBy(vm.aggregatedParents.customCategoryProgressBars, "id");
				}
			}

			vm.aggregatedParents.syncAccessTagProgressBarsWithButtons = function () {
				if (!vm.aggregatedParents.accessTagProgressBars) {
					vm.aggregatedParents.accessTagProgressBars = _.clone(vm.aggregatedParents.accessTagFilterButtons);
				}
				else {
					_.each(vm.aggregatedParents.accessTagProgressBars, function (progressBar) {
						var filterButton = _.find(vm.aggregatedParents.accessTagFilterButtons, { id: progressBar.id });
						if (filterButton) {
							progressBar.count = filterButton.count;
							progressBar.percentage = filterButton.percentage;
						}
						else {
							progressBar.count = progressBar.percentage = 0;
						}
					});

					_.each(vm.aggregatedParents.accessTagFilterButtons, function (filterButton) {
						var progressBar = _.find(vm.aggregatedParents.accessTagProgressBars, { id: filterButton.id });
						if (!progressBar) {
							vm.aggregatedParents.accessTagProgressBars.push(filterButton);
						}
					});
				}
			}

			vm.aggregatedParents.toggleFilter = function (filter, onlyFilteringPreview) {
				var
					selectedFiltersById,
					intersectedWithSearch = [],
					intersectedWithStructures = [],
					intersectedWithUsers = [],
					intersectedWithAnswerTypes = [],
					intersectedWithRelatedContent = [],
					intersectedWithRequirements,

					intersectedWithFindingsSeverity,
					intersectedWithFindingsClosed,
					intersectedWithFindingsLocked,
					intersectedWithFindingsWasClosedOnTime,
					intersectedWithFindingsOpenedAt,
					intersectedWithFindingsClosedAt,

					arraysToIntersect = [],
					intersectionItems = [],
					wfid
				;

				// _.each(vm.aggregatedParents.itemsByParentWfid, function (value, key) {
				// 	console.info("---", _.find(value, { wfid: "71-116" }));
				// });

				if (onlyFilteringPreview) {
					selectedFiltersById = _.clone(vm.aggregatedParents.selectedFiltersById);

					// filteringPreviewRemoverFunc = function (filterOption) {
					// 	if (filter.type == "parent")
					// 		return filterOption.parentGroupWfid === filter.parentGroupWfid;
					// 	else
					// 		return filterOption.type === filter.type || filterOption === filter;
					// }

					// _.each(selectedFiltersById, function (filterOption, key) {
					// 	if (filteringPreviewRemoverFunc(filterOption))
					// 		delete selectedFiltersById[key];
					// });
				}
				else {
					selectedFiltersById = vm.aggregatedParents.selectedFiltersById;
				}

				if (typeof filter !== "undefined") {
					wfid = filter.id;

					if (selectedFiltersById[wfid]) {
						// console.info("delete", filter.id, vm.aggregatedParents.selectedFiltersById[filter.id])
						delete selectedFiltersById[wfid];
						// console.info("deleteed?", filter.id, vm.aggregatedParents.selectedFiltersById[filter.id])

					}
					else {
						//  vm.aggregatedParents.selectedFiltersById[filter.id] = true
						// console.log(filter.id, vm.aggregatedParents.selectedFiltersById[filter.id], vm.aggregatedParents.itemsByParentWfid[filter.id])
						selectedFiltersById[wfid] = filter;
					}
				}

				_.each(selectedFiltersById, function (value, key) {
					var array;
					switch (value.type) {
						case "searchFilter":
							array = intersectedWithSearch;
							break;
						case enums.objectType.individual:
							array = intersectedWithUsers;
							break;
						case enums.objectType.questionAnswerType:
							array = intersectedWithAnswerTypes;
							break;
						case "relatedContent":
							array = intersectedWithRelatedContent;
							break;
						case "requirement":
							array = intersectedWithRequirements || (intersectedWithRequirements = []);
							break;
						case "finding-severity":
							array = intersectedWithFindingsSeverity || (intersectedWithFindingsSeverity = []);
							break;
						case "finding-closed":
							array = intersectedWithFindingsClosed || (intersectedWithFindingsClosed = []);
							break;
						case "finding-locked":
							array = intersectedWithFindingsLocked || (intersectedWithFindingsLocked = []);
							break;
						case "finding-wasClosedOnTime":
							array = intersectedWithFindingsWasClosedOnTime || (intersectedWithFindingsWasClosedOnTime = []);
							break;
						case "finding-openedAt_epoch":
							array = intersectedWithFindingsOpenedAt || (intersectedWithFindingsOpenedAt = []);
							break;
						case "finding-closedAt_epoch":
							array = intersectedWithFindingsClosedAt || (intersectedWithFindingsClosedAt = []);
							break;
						default:
							array = intersectedWithStructures;
							break;
					}
					// console.info(_.find(vm.aggregatedParents.itemsByParentWfid[key], { wfid: "71-116" }));
					Array.prototype.push.apply(array, vm.aggregatedParents.itemsByParentWfid[key]);
				});

				// console.log(vm.aggregatedParents.itemsByParentWfid);

				if (intersectedWithSearch.length > 0)
					arraysToIntersect.push(intersectedWithSearch);
				if (intersectedWithUsers.length > 0)
					arraysToIntersect.push(intersectedWithUsers);
				if (intersectedWithAnswerTypes.length > 0)
					arraysToIntersect.push(intersectedWithAnswerTypes);
				if (intersectedWithStructures.length > 0)
					arraysToIntersect.push(intersectedWithStructures);
				if (intersectedWithRelatedContent.length > 0)
					arraysToIntersect.push(intersectedWithRelatedContent);
				if (intersectedWithFindingsSeverity)
					arraysToIntersect.push(intersectedWithFindingsSeverity);
				if (intersectedWithFindingsClosed)
					arraysToIntersect.push(intersectedWithFindingsClosed);
				if (intersectedWithFindingsLocked)
					arraysToIntersect.push(intersectedWithFindingsLocked);
				if (intersectedWithFindingsWasClosedOnTime)
					arraysToIntersect.push(intersectedWithFindingsWasClosedOnTime);
				if (intersectedWithFindingsOpenedAt)
					arraysToIntersect.push(intersectedWithFindingsOpenedAt);
				if (intersectedWithFindingsClosedAt)
					arraysToIntersect.push(intersectedWithFindingsClosedAt);
				if (intersectedWithRequirements)
					arraysToIntersect.push(intersectedWithRequirements);

				intersectionItems = _.intersection.apply(null, arraysToIntersect);

				intersectionItems = _.uniq(intersectionItems);
				// console.log(_.map(intersectionItems, "wfid").join());


				// console.log(_.sortBy(_.map(intersectionItems, "wfid"), "wfid"));

				if (onlyFilteringPreview)
					return intersectionItems;

				if (arraysToIntersect.length) {

					buildLimitedHierachy(intersectionItems, {
						intersectedWithAnswerTypes: intersectedWithAnswerTypes,
						intersectedWithSearch: intersectedWithSearch,
						intersectedWithRequirements: intersectedWithRequirements,
						intersectedWithFindingsSeverity: intersectedWithFindingsSeverity,
						intersectedWithFindingsClosed: intersectedWithFindingsClosed,
						intersectedWithFindingsLocked: intersectedWithFindingsLocked,
						intersectedWithFindingsWasClosedOnTime: intersectedWithFindingsWasClosedOnTime,
						intersectedWithFindingsOpenedAt: intersectedWithFindingsOpenedAt,
						intersectedWithFindingsClosedAt: intersectedWithFindingsClosedAt
					});
					// console.log(allItems)
					if (vm.sunburstControl && vm.sunburstControl.highlightNodes)
						vm.sunburstControl.highlightNodes(_.map(allItems, "wfid"));
					// vm.sunburstControl.gotoNode("root");
				}
				else {

					buildLimitedHierachy();
					if (vm.sunburstControl && vm.sunburstControl.resetHighlightedNodes)
						vm.sunburstControl.resetHighlightedNodes();
				}

				if (vm.findings)
					vm.filteredFindingsCount = arraysToIntersect.length ? intersectionItems.length : vm.findings.length;


				// buildLevelFilters();

				// _.each(selectedLevelFiltersBeforeIntersection, function (selectedLevelFilter, index) {
				// 	var
				// 		level = levels[index],
				// 		filter
				// 	;

				// 	// Check if a filter bar on the same level exists after intersection
				// 	if (level) {
				// 		filter = _.find(level.allFilterButtons, { id: selectedLevelFilter.id });

				// 		// Check if the same filter button exists on the level
				// 		if (filter) {
				// 			level.setFilter(filter, true, true); // Set the filter (Without applying the filtering. This is done below)
				// 		}
				// 	}
				// });

				// var deepestFilter = _.chain(levels).filter(function (level) {
				// 	return level.selectedFilter && level.selectedFilter.id !== "all";
				// }).map("selectedFilter").last().value();

				// vm.sunburstControl.gotoNode(deepestFilter ? deepestFilter.id : "root");

				applySelectedFilters(true);


				$timeout(function() {
					if (vm.showAllActiveFilters)
						toggleActiveFilters(null, true);
				}, 100);

				// setFiltersIntersectedPreviewState(filter);

				// vm.sunburstCompiler.compile();
			// console.log(vm.aggregatedParents);
			};

			vm.aggregatedParents.toggleHighlighting = function (filter) {
				var highlight;
				_.each(allItems, function (item) {
					item.highlight = false;
				});

				if (filter)
					highlight = filter.highlight;
				else if (vm.aggregatedParents.highlightedFilterId)
					highlight = true;

				if (highlight) {
					_.each(vm.aggregatedParents.itemsByParentWfid[vm.aggregatedParents.highlightedFilterId], function (item) {
						item.highlight = true;
					});
				}
			};

			vm.aggregatedParents.highlightChanged = function (filterList, filter) {
				var activeFilter = undefined;
				if (vm.aggregatedParents.highlightedFilterId && vm.aggregatedParents.highlightedFilterList) {
					activeFilter = _.find(vm.aggregatedParents.highlightedFilterList, { 'id': vm.aggregatedParents.highlightedFilterId });
					if (activeFilter) {
						activeFilter.highlight = false; //required
						vm.aggregatedParents.toggleHighlighting(activeFilter);
					}
					else
						console.error("Cannot find active filter");
				}

				vm.aggregatedParents.highlightedFilterId = filter.highlight ? filter.id : undefined;
				vm.aggregatedParents.highlightedFilterList = filterList;

				_.each(_.difference(filterList, [ filter ]), function (filter) {
					filter.highlight = false;
				});

				vm.aggregatedParents.toggleHighlighting(filter);
			};

			vm.aggregatedParents.updateFilterBars(allItems);

		}

		function setFiltersIntersectedPreviewState(triggeringFilterOption) {
			var filterOptionsToCheck;

			if (vm.aggregatedParents.anyFiltersSelected()) {
				filterOptionsToCheck = _.chain(vm.aggregatedParents.filterButtons)
											.map()
											.reject({ id: "all" });

				if (triggeringFilterOption)
					filterOptionsToCheck = filterOptionsToCheck
											.reject({ id: triggeringFilterOption.id })
											// .reject({ disabled: true });

				filterOptionsToCheck = filterOptionsToCheck.value();

				// if (vm.filters.selectedParents.length == 1) {
				// 	_.remove(filterOptionsToCheck, vm.filters.selectedParents[0]);
				// 	_.assign(vm.filters.selectedParents[0], {
				// 		actualCount: undefined,
				// 		disabled: false
				// 	})
				// }

				_.each(filterOptionsToCheck, function (filterOption) {
					var intersectionResult = vm.aggregatedParents.toggleFilter(filterOption, true);

					filterOption.actualCount = intersectionResult.length;

					if (filterOption.actualCount > filterOption.count)
						filterOption.actualCount = undefined;

					filterOption.disabled = intersectionResult.length === 0;
				});
			}
			else {
				filterOptionsToCheck = _.map(vm.aggregatedParents.allFiltersById);
				_.each(filterOptionsToCheck, function (filterOption) {
					filterOption.actualCount = undefined;
					filterOption.disabled = false;
				});
			}
		}

		function toggleItemRelation(item) {
			var parentWfObject, dataRelationToDelete;
			if (vm.aggregatedParents.highlightedFilterId && !item.busy) {

				parentWfObject = wfObject.get(vm.aggregatedParents.highlightedFilterId);
				// console.log(parentWfObject)

				if (!parentWfObject)
					return;

				if (item.highlight) {
					dataRelationToDelete = wfObject.filter({ where: {
						type: enums.objectType.dataRelation,
						wfcid: item.wfid,
						wffid: parentWfObject.wfid,
						organizationId: authOrgId
					} });
					if (dataRelationToDelete.length > 0)  {
						item.busy = true;
						dataOps.destroy(dataRelationToDelete[0]).then(function () {
							item.busy = false;
							item.highlight = false;
							item.updateMetadata();
							// vm.aggregatedParents.updateFilterBars();
						});
					}
				}
				else {
					item.busy = true;
					dataOps.createSubItemRelation(item.content, parentWfObject, enums.subItemsKind.parentsByUser).then(function (res2) {
						item.highlight = true;
						item.busy = false;
						item.updateMetadata();
						// vm.aggregatedParents.updateFilterBars();

					});
				}
			}
		}

		function getPercentage(part, total) {
			var output = part / total * 100.0;

			output = Math.round(output * 10) / 10;
			output = output.toString();

			return output;
		}

		function openModal_setRequirement(item) {
			if (item.$scope.wfRequirementControl) {
				item.$scope.wfRequirementControl.editSpecificRequirement({ useGoal: true, includeUnselectable: true }).then(function () {
					if (typeof item.$scope.handleRequirementsSettings === "function")
						item.$scope.handleRequirementsSettings();

					if (typeof item.$scope.contentCompiler.compile === "function")
						item.$scope.contentCompiler.compile();
				});;
			}
		}

		function openModal_addOwnChild(item, objectType) {
			var promise, additionalDataRelations;
			var relationsBeforeChange = _.filter(item.content.childs, { organizationId: authOrgId, type: enums.objectType.dataRelation });

			if (additionalRelationTargetWhenExtending) { // If opened with category, the items needs to be intersected
				relationsBeforeChange = _.intersectionBy(relationsBeforeChange, _.filter(additionalRelationTargetWhenExtending.item.childs, { organizationId: authOrgId }), "wfcid");
			}

			switch (objectType) {
				case enums.objectType.structure:
					promise = $q(function (resolve, reject) {
						if (additionalRelationTargetWhenExtending) {
							additionalDataRelations = _.clone(additionalRelationTargetWhenExtending);
							additionalDataRelations.item1 = additionalDataRelations.item; // In modal.createWithRelation, the additionalDataRelations prop is an array and the "item" prop must be named "item1
							delete additionalDataRelations.item;
							additionalDataRelations = [ additionalDataRelations ];
						}

						modal.createWithRelation({
							simplifyForm: true,
							objectType: 71,
							dataRelationOptions: {
								id: "main",
								item1: item.content, kind: enums.subItemsKind.childrenByUser
							},
							additionalDataRelations: additionalDataRelations
						}).then(function (dataRelation) {
							if (dataRelation) {
								dataOps.saveSettings({
									item: dataRelation.childContent,
									settings: {
										dataRelation: null
									}
								}).then(function () {
									resolve();
								});
							}
						});
					});
					break;
					case enums.objectType.question:
				case enums.objectType.measure:
					promise = modal.openCreatorAndPicker({
						relationTarget: _.compact([ { item: item.content, kind: enums.subItemsKind.childrenByUser }, additionalRelationTargetWhenExtending ]),
						objectTypes: [ objectType ]
					});
					break;
			}

			((promise.modal && promise.modal.closed) || promise).then(function() {
				var
					relationsAfterChange = _.filter(item.content.childs, { organizationId: authOrgId, type: enums.objectType.dataRelation }),
					relationsInCategory,
					newRelations,
					oldRelations,
					orderedRelations
				;

				if (!relationsAfterChange.length) {
					item.updateChildren();
					return;
				}

				if (additionalRelationTargetWhenExtending) { // If opened via intersection, the items needs to be intersected
					relationsAfterChange = _.intersectionBy(relationsAfterChange, _.filter(additionalRelationTargetWhenExtending.item.childs, { organizationId: authOrgId }), "wfcid");
				}

				newRelations = _.difference(relationsAfterChange, relationsBeforeChange);
				oldRelations = _.difference(relationsAfterChange, newRelations);
				oldRelations = _.orderBy(oldRelations, [ "order", "createdAt" ], [ "asc", "asc" ]);

				// Add on top
				newRelations = _.orderBy(newRelations, [ "createdAt" ], [ "desc" ]);
				orderedRelations = _.concat(newRelations, oldRelations);

				// // Add to bottom
				// newRelations = _.orderBy(newRelations, [ "createdAt" ], [ "asc" ]);
				// orderedRelations = _.concat(oldRelations, newRelations);

				if (additionalRelationTargetWhenExtending) {
					_.each(newRelations, function (newRelation) {
						var vdr = _.assign(_.clone(dataOps.prepareWfObject(newRelation)), {
							type: enums.objectType.virtualDataRelation,
							id: newRelation.id,
							wfid: '81-|' + newRelation.wffid + '|' + newRelation.wfcid,
							intersectionTargetWfid: additionalRelationTargetWhenExtending.item.wfid,

							// Relation between category and the item
							originalRelationWfid: _.get(wfObject.filter({ where: { type: 73, wffid: additionalRelationTargetWhenExtending.item.wfid, wfcid: newRelation.wfcid } })[0], "wfid"),

							// Relation between item and sub-item - the position in the hierarchy
							positionRelationWfid: newRelation.wfid
						});

						wfObject.inject(vdr);
					});
				}

				if (!orderedRelations.length) {
					item.updateChildren();
					return;
				}

				dataOps.saveDataRelationsOrder(orderedRelations).then(function () {
					// If the relations are the original relations of any virtualDataRelations then they also need to get the new order
					var relationWfids = _.map(orderedRelations, "wfid");

					_.each(wfObject.filter({ where: {
						type: enums.objectType.virtualDataRelation,
						positionRelationWfid: { "in": relationWfids },
						intersectionTargetWfid: additionalRelationTargetWhenExtending.item.wfid,
					} }), function (vdr) {
						vdr.order = _.find(orderedRelations, { wfid: vdr.positionRelationWfid }).order;
					});

					_.chain(wfObject.filter({ where: {
						type: enums.objectType.virtualDataRelation,
						positionRelationWfid: { "in": relationWfids },
						intersectionTargetWfid: additionalRelationTargetWhenExtending.item.wfid,
					} })).orderBy([ function (relation) {
						return typeof relation.organizationId === "number"
					}, "order" ])
					.map(function (vdr) {
						console.log(vdr.organizationId, vdr.order, vdr.childContent.getMainTextual(), vdr);
					});

					item.updateChildren();
				});
			});
		}

		function getInfluenceUiMode() {
			if (!vm.influenceMode)
				return undefined;

			let _requirementUiMode = null

			if (authOrgId) {
				if ((!vm.uiMode || vm.uiMode === enums.uiMode.admin) && (vm.influence.creatorOrganizationId === authOrgId || vm.influence.networkCreatorOrganizationId === authOrgId) && (!vm.influence.isInternal || vm.influence.userId !== authUserId)) {
					vm.isWorkMode = false;
					vm.answeringUiMode = enums.uiMode.view;
					vm.metadataUiMode = enums.uiMode.view;
					_requirementUiMode = (vm.influence.isMainCreator || vm.influence.networkCreatorOrganizationId === authOrgId) ? enums.uiMode.admin : enums.uiMode.view;
				}
				else if ((!vm.uiMode || vm.uiMode === enums.uiMode.work) && vm.influence.organizationId === authOrgId) {
					vm.isWorkMode = true;
					vm.answeringUiMode = enums.uiMode.work;
					vm.metadataUiMode = enums.uiMode.work;
					_requirementUiMode = enums.uiMode.work;
				}

				if (vm.isPrintMode) {
					vm.isWorkMode = false;
					vm.answeringUiMode = enums.uiMode.view;
					vm.metadataUiMode = enums.uiMode.view;
					_requirementUiMode = enums.uiMode.view;
				}
			}
			else {
				vm.isWorkMode = false;
				vm.answeringUiMode = enums.uiMode.view;
				_requirementUiMode = enums.uiMode.view;
			}

			if (!_requirementUiMode) {
				vm.isWorkMode = false;
				vm.answeringUiMode = enums.uiMode.view;
				vm.metadataUiMode = enums.uiMode.view;
				requirementUiMode = enums.uiMode.view;
				vm.showRequirements = false
				vm.showPageHeading = false
				vm.hideFulfillmentFilterBar_override = true
				vm.hideInfluenceSigningPanel_override = true
				vm.mainPanelFullWidth = true
			}
			else {
				requirementUiMode = _requirementUiMode
			}

			return requirementUiMode;
		}

		function handleInfluenceMode() {
			var
				answerableItemDefaultSettings,
				questionItemDefaultSettings,
				measureItemDefaultSettings,
				itemDefaultSetting
			;

			vm.organizationId = vm.influence.organizationId;

			vm.pageHeaderObject.title = vm.influence.title;

			getInfluenceUiMode();

			answerableItemDefaultSettings = {
				"showMetadata": true,
				"metadataConfig":{
					"limitRelationKinds": [ enums.subItemsKind.childrenByUser ], // Show only metadata/popover of kind childrenByUser
					"showDetailedViewLink": false,
					"showEmbeddedObjectViewer": false,
					popoverItemSettings: { // Settings for childrenByUser popover
						showDropdown: false,
						showDropdownByKind: vm.isWorkMode ? { 7: true } : undefined, // Show item dropdown only in childrenByUser popover
						dropdownActionsByKind: vm.isWorkMode ? { 7: [ "attachInformation", "edit", "deleteContent" ] } : undefined, // In the item dropdown in childrenByUser popover, show only attachInformation action
						metadataConfig: { // Metadata settings for items in the childrenByUser popover
							limitRelationKinds: [ enums.subItemsKind.relatedContentByUser ], // Show only metadata/popover of kind relatedContentByUser
							showDetailedViewLink: false,
							showEmbeddedObjectViewer: false,
							popoverItemSettings: { // Settings for relatedContentByUser popover
								metadataConfig: {
									limitRelationKinds: [],
									showDetailedViewLink: false,
									showEmbeddedObjectViewer: false
								}
							}
						}
					}
				}
			};

			itemDefaultSetting = {
				hideRequirementTexts: vm.itemSettings.hideRequirementTexts,
				showDropdown: false,
				showMetadata: false,
				showDropdownByType: vm.isWorkMode ? { "userData": true, "systemData": false, 101: true, 112: true } : undefined, // 101 = Organization, 112 = Person. They are types that can be attached to a structure but they are not strictly user data.
				metadataConfig: {
					popoverItemSettings: {
						showDropdown: false,
						showDropdownByKind: vm.isWorkMode ? { 5: true } : undefined, // Inside metadata popover, show item dropdown for relation kind 5 (relatedContentByUser) but nothing else
						metadataConfig: {
							limitRelationKinds: [],
							showDetailedViewLink: false,
							showEmbeddedObjectViewer: false
						}
					}
				}
			};

			if (!$scope.isPrinting) {
				if (vm.mainStructure.requirements.length > 0) {
					vm.showRequirements = true;
					vm.showFulfillment = true;
				}
				else {
					vm.showSigning = false;
					vm.showFulfillment = false;
				}

				if (vm.isWorkMode && mainStructureConditions && typeof mainStructureConditions.showRequirements === "boolean" && mainStructureConditions.showRequirements) {
					vm.showRequirements = mainStructureConditions.showRequirements;
				}
				// vm.showMetadata = true;
				// vm.showItemDropdown = false;
				vm.showFilterBars = false;

				if (mainStructureConditions && mainStructureConditions.uiSettings) {
					if (vm.isWorkMode && mainStructureConditions.uiSettings.showFilterBars) {
						vm.showFilterBars = true;
					}
					else
						vm.showFilterBars = false;

					if (!vm.isWorkMode)
						setOverallItemSetting("showDropdown", false, true);

					// if (vm.isWorkMode && mainStructureConditions.uiSettings.eachItem && mainStructureConditions.uiSettings.eachItem.showDropdown) {
					// 	vm.showItemDropdown = true;
					// }
					// else
					// 	vm.showItemDropdown = false;

					// if (mainStructureConditions.uiSettings.eachItem && mainStructureConditions.uiSettings.eachItem.showMetadata) {
					// 	vm.showMetadata = true;
					// }
					// else
					// 	vm.showMetadata = false;

					if ("showLevelsFilterBar" in mainStructureConditions.uiSettings) {
						vm.showLevelsFilterBar = mainStructureConditions.uiSettings.showLevelsFilterBar;
					}
					else
						vm.showLevelsFilterBar = true;
				}

				vm.allItemSettings.push(vm.itemSettings);

				// If no settings for objectType question is specified then use default settings
				if (!_.get(vm, "itemSettings.byType.11")) {
					questionItemDefaultSettings = _.cloneDeep(answerableItemDefaultSettings);
					vm.allItemSettings.push(questionItemDefaultSettings);

					if (_.get(vm, "itemSettings.byType"))
						vm.itemSettings.byType[enums.objectType.question] = questionItemDefaultSettings;
					else {
						vm.itemSettings = {
							common: vm.itemSettings
						};
						vm.itemSettings.byType = {
							11: questionItemDefaultSettings
						}
					}
				}
				// If no settings for objectType measure is specified then use default settings
				if (!_.get(vm, "itemSettings.byType.21")) {
					measureItemDefaultSettings = _.cloneDeep(answerableItemDefaultSettings);
					vm.allItemSettings.push(measureItemDefaultSettings);

					if (_.get(vm, "itemSettings.byType"))
						vm.itemSettings.byType[enums.objectType.measure] = measureItemDefaultSettings;
					else {
						vm.itemSettings = {
							common: vm.itemSettings
						};
						vm.itemSettings.byType = {
							21: measureItemDefaultSettings
						}
					}
				}
			}
			else {
				// _.each(vm.allItemSettings, function (setting) {
				// 	setting.showMetadata = false;
				// 	setting.metadata = undefined;
				// });
				setOverallItemSetting("showMetadata", false);
				setOverallItemSetting("showDropdown", false);
				// vm.showMetadata = false;
				vm.showLevelsFilterBar = false;
				vm.answeringUiMode = enums.uiMode.view;
				vm.isWorkMode = false;
				vm.showFilterBars = false;
				// vm.showItemDropdown = false;
			}

			if (vm.influence.isInternal) {
				vm.showSunburstChart = false;
				vm.showFulfillment = true;

				if (vm.influence.userId === authUserId) {
					vm.metadataUiMode = enums.uiMode.work;
					setOverallItemSetting("showMetadata", true, true);
				}
				else if (!vm.influence.userId && vm.influence.contextParentWfids) {
					vm.metadataUiMode = enums.uiMode.work;
					setOverallItemSetting("metadataConfig", {
						showCreator: true,
						"limitRelationKinds": [ enums.subItemsKind.childrenByUser ],
						showDetailedViewLink: false,
						showEmbeddedObjectViewer: false,
						popoverItemSettings: {
							showDropdown: false,
							showDropdownByKind: { 7: true }, // Show item dropdown only in childrenByUser popover
							dropdownActionsByKind: { 7: [ "deleteContent" ] }, // In the item dropdown in childrenByUser popover, show only attachInformation action
							metadataConfig: {
								showCreator: false,
								limitRelationKinds: [],
								showDetailedViewLink: false,
								showEmbeddedObjectViewer: false
							}
						}
					});
				}
				else {
					vm.metadataUiMode = enums.uiMode.view;
					setOverallItemSetting("showMetadata", false);
				}
					// vm.showMetadata = false;
			}

			if ($scope.isPrinting) {
				setOverallItemSetting("showMetadata", false);
				setOverallItemSetting("showDropdown", false);
			}
			else {
				applyDefaultItemSettings(itemDefaultSetting);
			}
			// if (vm.itemMetadataConfig && !$scope.isPrinting) {
			// 	vm.showMetadata = true;
			// }


			vm.questionAnsweringManager.onLoaded = function () {
				vm.questionAnsweringManager.addCompositeItems(_.filter(originalItems.all, { type: enums.objectType.question }), intersectionSettings);
			};

			vm.measureAnsweringManager.onLoaded = function () {
				vm.measureAnsweringManager.addCompositeItems(_.filter(originalItems.all, { type: enums.objectType.measure }), vm.influence.organizationId);
			}

			vm.requirementFulfillmentManager.onLoaded = function () {
				vm.requirementFulfillmentManager.addCompositeItems(_.filter(originalItems.all, { type: enums.objectType.structure, root: false }), vm.influence.organizationId, intersectionSettings);
				vm.requirementFulfillmentManager.addCompositeItems(_.filter(originalItems.all, { type: enums.objectType.finding, root: false }), vm.influence.organizationId, intersectionSettings);
			}

			vm.answerableItemsWithAnswerStatement = {};
			vm.answerableItemsCommentCompilers = {};
			vm.answerableItemsRelatedContentCompilers = {};
			vm.answerableItemsWithCommentRequirement = {};
			vm.answerableItemsWithRelatedContentRequirement = {};
			vm.answerableItemsWithRelatedContentButton = {};

			_.each(_.filter(originalItems.all, function (item) {
				return item.type === enums.objectType.question || item.type === enums.objectType.measure;
			}), function (item) {
				var
					requirement = item.dataRelation.getRequirement(),
					objectTypeSetting,
					itemSpec
				;
				if (requirement && requirement.rule == enums.requirementRule.preferredValue) {
					vm.answerableItemsWithCommentRequirement[item.wfid] = true;
				}
				else if (requirement
					&& (requirement.rule == enums.requirementRule.preferredValueWithRelatedContent
						|| requirement.rule == enums.requirementRule.anyValueWithRelatedContent
						|| requirement.rule == enums.requirementRule.specificValueWithRelatedContent
						|| requirement.rule == enums.requirementRule.inPeriodWithRelatedContent
						|| requirement.rule == enums.requirementRule.inPeriodWithRelatedContentExceptIfNA
						|| requirement.rule == enums.requirementRule.anyValueWithRelatedContentExceptIfNA
						|| requirement.rule == enums.requirementRule.inPeriodWithRelatedContentIfNA
				)) {
					objectTypeSetting = item.dataRelation.settings && item.dataRelation.settings.objectTypes ? item.dataRelation.settings.objectTypes : [];
					itemSpec = vm.answerableItemsWithRelatedContentRequirement[item.wfid] = {
						statement: _.includes(objectTypeSetting, enums.objectType.statement),
						document: _.includes(objectTypeSetting, enums.objectType.orgDocument),
						certificate: _.includes(objectTypeSetting, enums.objectType.certificate),
						activity: _.includes(objectTypeSetting, enums.objectType.orgActivity),
						country: _.includes(objectTypeSetting, enums.objectType.country),
						finding: _.includes(objectTypeSetting, enums.objectType.finding),
						organization: _.includes(objectTypeSetting, enums.objectType.organization),
						dateItem: _.includes(objectTypeSetting, enums.objectType.dateItem),
						person: _.includes(objectTypeSetting, enums.objectType.person)
					};

					if (!itemSpec.statement && !itemSpec.document && !itemSpec.certificate && !itemSpec.country) {
						itemSpec.statement = true;
					}

					vm.answerableItemsWithRelatedContentButton[item.wfid] = itemSpec;
				}
				else if ((objectTypeSetting = item.dataRelation.settings && item.dataRelation.settings.objectTypes ? item.dataRelation.settings.objectTypes : undefined)) {
					vm.answerableItemsWithRelatedContentRequirement[item.wfid] = {
						statement: _.includes(objectTypeSetting, enums.objectType.statement),
						document: _.includes(objectTypeSetting, enums.objectType.orgDocument),
						certificate: _.includes(objectTypeSetting, enums.objectType.certificate),
						activity: _.includes(objectTypeSetting, enums.objectType.orgActivity),
						country: _.includes(objectTypeSetting, enums.objectType.country),
						finding: _.includes(objectTypeSetting, enums.objectType.finding),
						organization: _.includes(objectTypeSetting, enums.objectType.organization),
						dateItem: _.includes(objectTypeSetting, enums.objectType.dateItem),
						person: _.includes(objectTypeSetting, enums.objectType.person)
					};
					vm.answerableItemsWithRelatedContentButton[item.wfid] = vm.answerableItemsWithRelatedContentRequirement[item.wfid];
				}
			});
		}

		function levelPrototype_scrollToTopAndSetFilter(filter) {
			var level = this;
			var windowTop = $(window).scrollTop();
			var scrollTo = 0;

			if ($('div.hierarchicalPublicLayout').offset() != undefined)
				scrollTo = $('div.hierarchicalPublicLayout').offset().top;

			if (windowTop > scrollTo) {
				$('html, body').animate({ scrollTop: scrollTo }, 500, function() {
					level.setFilter(filter);
					$timeout();
				});
			}
			else
				level.setFilter(filter);
		}

		function levelPrototype_getNthParent(depth) {
			var propertyPath;

			propertyPath = Array(depth + 1).join('parent.');
			propertyPath = propertyPath.substr(0, propertyPath.length - 1);
			return _.get(this, propertyPath);
		}

		function setOverallItemSetting(key, value, onlySetIfUndefined) {
			_.each(vm.allItemSettings, function (setting) {
				if (onlySetIfUndefined && typeof setting[key] !== "undefined")
					return;

				setting[key] = value;
				if (key === "showMetadata" && value === false)
					setting.metadataConfig = undefined;
			});
		}

		function applyDefaultItemSettings(defaultSettings) {
			_.each(vm.allItemSettings, function (settings) {
				var _defaultSettings = _.cloneDeep(defaultSettings);

				if (settings.metadataConfig) {
					delete _defaultSettings.showMetadata;
					_.defaultsDeep(settings.metadataConfig, _defaultSettings.metadataConfig, settings.metadataConfig);
					delete _defaultSettings.metadataConfig;
				}

				_.defaultsDeep(settings, _defaultSettings, _.cloneDeep(settings));
			});
		}

		function clearAllFilters(event) {
			event.stopPropagation();

			_.each(vm.aggregatedParents.selectedFiltersById, function (filter, key) {
				delete vm.aggregatedParents.selectedFiltersById[key];
			});

			applySelectedFilters();
		}

		function syncMainStructureCategories() {
			var allCustomCategories = _.filter(vm.mainStructure.relatedContentByUser, { childType: enums.objectType.structure });

			vm.mainStructureReportingCategories = _.filter(allCustomCategories, function (relation) {
				return _.get(relation.childContent, "conditions.contextParentWfids");
			});

			vm.mainStructureCategories = _.difference(allCustomCategories, vm.mainStructureReportingCategories);
		}

		function createMainStructureCategory() {
			modal.createWithRelation({
				simplifyForm: true,
				objectType: 71,
				objectTypeWord: $translate.instant("Category").toLowerCase(),
				customFormSpecification: {
					ignoreUpload: true,
					form: [
						"title"
					]
				},
				dataRelationOptions: { item1: vm.mainStructure, kind: enums.subItemsKind.relatedContentByUser },
				submitCaption: $translate.instant("Create")
			}).then(function (dataRelation) {
				if (dataRelation) {
					dataOps.saveSettings({
						item: dataRelation.childContent,
						settings: {
							dataRelation: true,
							intersectionSourceWfid: vm.mainStructure.wfid
						}
					}).then(function () {
						syncMainStructureCategories();
						vm.aggregatedParents.syncStructureProgressBarsWithButtons();
					});
				}
			});
		}

		function createMainStructureReportingCategory() {
			modal.createWithRelation({
				simplifyForm: true,
				objectType: 71,
				objectTypeWord: $translate.instant("Category").toLowerCase(),
				customFormSpecification: {
					ignoreUpload: true,
					form: [
						"title"
					]
				},
				dataRelationOptions: { item1: vm.mainStructure, kind: enums.subItemsKind.relatedContentByUser },
				submitCaption: $translate.instant("Create"),
				titleTranslate: "modules.hierarchical.createUniqueReport"
			}).then(function (dataRelation) {
				if (dataRelation) {
					dataOps.saveSettings({
						item: dataRelation.childContent,
						settings: {
							dataRelation: true,
							intersectionSourceWfid: vm.mainStructure.wfid,
							contextParentWfids: dataRelation.wfcid
						}
					}).then(function () {
						syncMainStructureCategories();
						vm.aggregatedParents.syncStructureProgressBarsWithButtons();
					});
				}
			});
		}

		function syncLinkage(items) {
			var
				culture = wfAuth.getCulture(),
				apiParams = { items: null, culture: culture },
				kind = enums.subItemsKind.relatedContent
			;

			if (!vm.showLinkagesPanel)
				return;

			vm.linkagesLoading = false;
			vm.linkages = [];

			if (vm.linkageXhrRequest) {
				vm.linkageXhrRequest.abort();
				vm.linkageXhrRequest = undefined;
			}

			if (items.length === 0) {
				return;
			}

			apiParams.items = _.map(items, function (item) {
				return {
					item: {
						id: item.content.id,
						type: item.type,
					},
					onlyLoadRelations: true,
					kind: kind
				};
			});

			vm.linkagesLoading = true;

			vm.linkageXhrRequest = apiProxy.raw("multi.getSubItemsOfAll", apiParams);

			vm.linkageXhrRequest.then(function (relations) {
				var
					distinctChildWfids = _.chain(relations).map("wfcid").uniq().value()
				;

				if (distinctChildWfids.length === 0) {
					vm.linkages = [];
					vm.linkagesLoading = false;
					$timeout();
					return;
				}

				vm.linkageXhrRequest = apiProxy.raw("multi.getObjects", {
					wfids: distinctChildWfids,
					culture: culture
				});

				vm.linkageXhrRequest.then(function (contents) {
					var
						relationsByWfcid = _.groupBy(relations, "wfcid"),
						ancestorWfids = _.chain(contents).map("ancestorWfid").compact().uniq().value(),
						itemOutput,
						output = []
					;

					// Excluding linkage from these ancestors:
					// 71-12386 = European directive on non-financial and diversity disclosure
					// 71-12387 = Hållbarhetsrapport enligt årsredovisningslagen
					ancestorWfids = _.difference(ancestorWfids, [ "71-12386", "71-12387", "71-61" ]);

					if (ancestorWfids.length === 0) {
						vm.linkages = [];
						vm.linkagesLoading = false;
						$timeout();
						return;
					}

					vm.linkageXhrRequest = apiProxy.raw("multi.getObjects", {
						wfids: ancestorWfids,
						culture: culture
					});

					vm.linkageXhrRequest.then(function (ancestors) {
						var
							ancestorsByWfid = _.keyBy(ancestors, "wfid"),
							dropdownActions = getDropdownActions()
						;

						for (var i = 0, len = contents.length, content; i < len; i++) {
							content = contents[i];

							if (content.creatorOrganizationId === 1 || content.creatorOrganizationId === 4536) {
								relations = relationsByWfcid[content.wfid];


								if (relations) {
									if (!ancestorsByWfid[content.ancestorWfid])
										continue;

									itemOutput = {
										count: relations.length,
										content: content,
										title: content.title,
										ancestorId: content.ancestorId,
										ancestor: ancestorsByWfid[content.ancestorWfid],
										forItems: getSourceItems(relations),
										dropdownActions: dropdownActions
									}

									if (itemOutput.ancestor)
										itemOutput.ancestorTitle = itemOutput.ancestor.title;

									output.push(itemOutput);
								}
							}
						}

						output = _.orderBy(output, [ "ancestorTitle", "title" ])

						vm.linkages = output;
						vm.linkagesLoading = false;
						$timeout();
					});
				});
			});

			function getSourceItems(relations) {
				var
					wfids = _.chain(relations).map("wffid").uniq().value(),
					itemGroups = _.pick(itemsByWfid, wfids),
					items = _.chain(itemGroups).map().flatten().uniqBy("wfid").value()
				;

				return items;
			}

			function getDropdownActions() {
				return [
					{ text: 'OpenObjectViewer', icon: 'fa fa-external-link', action: 'vm.hierVm.openObjectViewer(vm.item)' },
					'-',
					{ text: "Materiality", icon: "fa fa-plus", action: 'vm.openModal_categorizeTo_materiality(item)' },
					{ text: "Deviation", icon: "fa fa-plus", action: 'vm.openModal_categorizeTo_deviation(item)' },
					{ text: "Scope", icon: "fa fa-plus", action: 'vm.openModal_categorizeTo_scope(item)' },
					{ text: "CustomFrameworks", icon: "fa fa-plus", action: 'vm.openModal_categorizeTo_customFrameworks(item)', condition: vm.orgHasCustomFrameworks },
					{ text: "Categories", icon: "fa fa-plus", action: 'vm.openModal_categorizeTo_customCategories(item)', condition: vm.showCustomCategories },
					{ text: "AttachInformation", icon:"fa fa-paperclip", subActions: [
						{ text: 'Activity', icon: 'fa fa-calendar-check-o', action: 'vm.openModal_attachInformation(item, 15)' },
						{ text: 'Document', icon: 'fa fa-file', action: 'vm.openModal_attachInformation(item, 18)' },
						{ text: 'Statement', icon: 'fa fa-quote-right', action: 'vm.openModal_attachInformation(item, 44)' },
						{ text: 'Link', icon: 'fa fa-link', action: 'vm.openModal_attachInformation(item, 50)' },
						{ text: 'Embed', icon: 'fa fa-play-circle', action: 'vm.openModal_attachInformation(item, 90)' }
					] }
				];
			}
		}

		function handleTransclusion() {
			var
				elementClone
			;

			if ($scope.transcludeInterfaceSide) {
				elementClone = $scope.transcludeInterfaceSide.elementClone.cloneNode(true);

				$compile(elementClone)($scope);

				$scope.outerVm = $scope.transcludeInterfaceSide.outerVm;
				$element.find("div.transclude-interface-side").append(elementClone.childNodes);
			}
		}

		function goToAnchor(id) {
			var gap = 60;
			if (!vm.filtersElement)
				vm.filtersElement = $("#filters.collapse");

			if (id) {
				$('html, body').animate({
					scrollTop: $("#" + id).offset().top - gap
				}, 500, null, function() {
					if (vm.hideFilters) {
						vm.hideFilters = false;
						vm.filtersElement.collapse("show");
						$timeout();
					}
				});
			}
		}

		function toggleActiveFilters(event, checkHeight) {
			if (event && !vm.btnGroupElement)
				vm.btnGroupElement = $($(event.currentTarget).closest("div.filter-options")).children("div.btn-group");

			if (vm.showAllActiveFilters && vm.btnGroupElement && checkHeight) {
				if (vm.btnGroupElement.outerHeight() > 80)
					return;
			}

			vm.showAllActiveFilters = !vm.showAllActiveFilters;
		}
	}
})();
