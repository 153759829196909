import * as enums from '@worldfavor/constants/enums'

(function() {
'use strict';

	angular
		.module('wf.common')
		.controller('Admin_AccountsController', Admin_AccountsController);

	Admin_AccountsController.$inject = ['$scope', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', 'formSchemaService', '$q',
		'wfTranslate', '$timeout','$stateParams','$rootScope','apiProxy','wfAuth','wfItemToolsService','$state','$compile','$window'];
	function Admin_AccountsController($scope, dataOps, modal, wfObject, dataQuery, $translate, formSchemaService, $q,
		wfTranslate, $timeout, $stateParams, $rootScope, apiProxy, wfAuth, wfItemToolsService, $state, $compile, $window) {
		var
			vm = this
		;

		activate();

		function activate() {
			dataOps.getObjects({
				objectType: enums.objectType.account,
				wrapInRelations: false
			}).then(function (res) {
				vm.accounts = _.filter(res, function (item) {
					return item.organization
				});
				vm.loaded = true;
				$timeout();
			});
		}
	}
})();
