// @flow
import * as types from '../actions'

type State = {|
  +enforcedCulture: ?string,
  +token: ?string,
  +userInfo: ?any,
  +authUser: ?any,
  +currentUser: ?any,
|}

const initialState: State = {
  enforcedCulture: null,
  token: null,
  userInfo: null,
  authUser: null,
  currentUser: null,
}

export default (state: State = initialState, action: Object): State => {
  switch (action.type) {
    case types.SET_ENFORCED_CULTURE:
      return {
        ...state,
        enforcedCulture: action.enforcedCulture,
      }

    case types.SET_USER_INFO:
      return {
        ...state,
        userInfo: action.userInfo,
      }

    case types.SET_TOKEN:
      return {
        ...state,
        token: action.token,
      }

    case types.SET_AUTH_USER:
      return {
        ...state,
        authUser: action.authUser,
      }

    case types.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.currentUser,
      }
    case types.RESET_APP_STATE:
    case types.LOGOUT:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
