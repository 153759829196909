import * as enums from '@worldfavor/constants/enums'

(function () {
	'use strict';

	var measureUnitExtractor = measureUnitExtractorHandler();

	angular
		.module('wf.common')
		.directive('wfItemUse', wfItemUse)
		.directive('wfItemHeadertext', wfItemHeadertext)
		.directive('wfItemSubHeader', wfItemSubHeader)
		.directive('wfItemPreHeader', wfItemPreHeader)
		.directive('wfItemBodytext', wfItemBodytext)
		.directive('wfItemActivitydates', wfItemActivitydates)
		.directive('wfItemImage', wfItemImage)
		.directive('wfItemCreatorImage', wfItemCreatorImage)
		.directive('wfItemCreatorName', wfItemCreatorName)
		.directive('wfItemCreatorOrgname', wfItemCreatorOrgname)
		.directive('wfItemCreatedAt', wfItemCreatedAt)
		.directive('wfItemCreatorBar', wfItemCreatorBar)
		.directive('wfItemGoto', wfItemGoto)
		.directive('wfItemHref', wfItemHref)
		.directive('wfItemIf', wfItemIf)
		.service('wfPropertyExtractor', wfPropertyExtractorService)
		;

	wfItemUse.$inject = [ '$parse', 'moment' ];

	function wfItemUse($parse, moment) {
		var directive = {
			restrict: 'A',
			scope: false,
			controller: [ '$scope', '$attrs', '$element', wfItemUseController ]
		};

		return directive;

		function wfItemUseController($scope, $attrs, $element) {
			var item = $parse($attrs.wfItemUse)($scope);

			try {
				if (!item) {
					return;
					console.error("Error in propertyExtractor.directive.js: item is undefined", {
						'$scope': $scope, "$attrs": $attrs, "$element": $element
					})
				}
				else {

					if (!item.type) {
						this.item = item.display;
						this.goto = item.goto;
						this.gotoState = item.gotoState;
						this.useFriendlyTitle = item.useFriendlyTitle;
						this.imageIconColor = item.imageIconColor;

						if (this.goto.type === 81 && this.goto.childType == 52) // VirtualDataRelation & Network
						{
							this.goto = this.goto.childContent;
						}
						else if (this.item.type === 13) { // Influence
							this.headerItem = this.item; // Influences uses constructed titles if there are a contextParents defined
							this.item = this.item.childContent;
							this.goto = this.goto.childContent;
						}
					}
					else {
						if (item.type === 13) { // Influence
							this.item = item.childContent;
							this.headerItem = item; // Influences uses constructed titles if there are a contextParents defined
							this.goto = item;
						}
						else
							this.item = this.goto = item;
					}

					$element.addClass('objType' + this.item.type);
				}
			}
			catch (e) {

			}
		}

	}

	var guidanceTemplate = '<i class="fa fa-info-circle guidance-btn"></i>';

	wfItemHeadertext.$inject = [ '$translate', '$sanitize', 'modalService', 'wfPropertyExtractor', '$parse' ];

	function wfItemHeadertext($translate, $sanitize, modal, wfPropertyExtractor, $parse) {
		var directive = {
			require: [ '^wfItemUse', '?^wfItemIf' ],
			restrict: 'A',
			link: function (scope, element, attrs, controllers) {
				var
					wfItemUseCtrl = controllers[0],
					wfItemIfCtrl = controllers[1],
					text,
					asHtml,
					elementNode,
					item = wfItemUseCtrl.headerItem || wfItemUseCtrl.item,
					useFriendlyTitle = wfItemUseCtrl.useFriendlyTitle,
					attrOptions = $parse(attrs.wfItemHeadertext)(scope),
					includeReference = attrOptions && attrOptions.includeReference && item.type === enums.objectType.structure && item.reference,
					url
				;

				if (item) {
					if (item._headerText_)
						text = item._headerText_;
					else if (item.type == 31)  {// item.childContent is questionAnswerType
						if (item.childContent && item.childContent.text) {
							text = item.childContent.text;
						}
						else {
							text = wfPropertyExtractor.getQuestionAnswerTypeText(item);
						}
					}
					else if (item.type == 25)// && item.childContent) // item.childContent is unit (hours, kilos, metric tons etc)
					{
						asHtml = true;
						// console.log(item);
						text = "<span class='measure-answer-year'>" + wfPropertyExtractor.getMeasureAnswerPeriod(item) + ":</span>" + wfPropertyExtractor.getFormattedMeasureAnswerValue(item, true, true);
					}
					else if (item.type == enums.objectType.account) {
						text = item.organization.name;
					}
					else if (item.type == enums.objectType.parameterValue && item.childContent) {
						text = item.childContent.name;
					}
					else if (useFriendlyTitle && item.friendlyTitle)
						text = item.friendlyTitle;
					else
						text = item.title || item.name || item.displayName;
				}

				if (text) {
					if (includeReference)
						text = item.reference + String.fromCharCode(160) + " " + text;

					if ("wfItemNoBind" in attrs) return;

					element.addClass("wf-headerText");
					elementNode = element[0];

					if ("wfItemAsLink" in attrs && (item.type == 91 || item.url)) {
						if (item.type == 91)
							url = "/room-admin/" + item.id;
						else
							url = item.url;

						var additionalText = "";
						if (item.fileType)
							additionalText = item.fileType;
						if (item.providerName)
							additionalText = item.providerName;

						elementNode.innerHTML = "<a href=\"" + $sanitize(url) + "\" target=\"_blank\">" + (asHtml ? text : $sanitize(text)) + "<span class='external-link hidden-xs'><i class='fa fa-external-link' aria-hidden='true'></i>" + $sanitize(additionalText) + "</span></a>";

					}
					else {
						elementNode[asHtml ? "innerHTML" : "textContent"] = text;
					}

					if (item.guidance) {
						elementNode.innerHTML += "&nbsp;";
						element.append($(guidanceTemplate).click(function (event) {
							event.stopPropagation();
							modal.openGuidance({
								title: item.title || $translate.instant('Guidance'),
								message: item.guidance
							});
						}))
					}
				}
				else {
					element.remove();

					// TODO: Make it work
					// if (wfItemIfCtrl && wfItemIfCtrl.removeElement)
					// 	wfItemIfCtrl.removeElement("headerText");
				}
			}
		};
		return directive;
	}


	wfItemBodytext.$inject = [ 'wfPropertyExtractor', '$translate', 'modalService', '$filter' ];

	function wfItemBodytext(wfPropertyExtractor, $translate, modal, $filter) {
		var directive = {
			require: [ '^wfItemUse', '?^wfItemIf' ],
			restrict: 'A',
			link: function (scope, element, attrs, controllers) {
				var
					output,
					wfItemUseCtrl = controllers[0],
					wfItemIfCtrl = controllers[1],
					item = wfItemUseCtrl.item,
					detailsOutput = {},
					elementNode
				;

				if (item) {
					output = wfPropertyExtractor.getBodyText(item, detailsOutput);
					// if (wfItemUseCtrl.item && (text = wfItemUseCtrl.item.text || wfItemUseCtrl.item.description || wfItemUseCtrl.item.email || wfItemUseCtrl.item.username)) {
					// }
					// else if (item.type == enums.objectType.account) {
					// 	text = item.subscriptionId;
					// }
				}

				if (output) {
					element.addClass("wf-bodyText");
					elementNode = element[0];
					// if (detailsOutput.asHtml)
						elementNode.innerHTML = output;
					// else
					// 	element.textContent = output;
					if (item.guidance && item.guidance.length) {
						if ((item.type === enums.objectType.question || item.type === enums.objectType.measure || item.type === enums.objectType.relativeMeasure)
							|| (item.type === enums.objectType.structure && !item.title)
						) {
							elementNode.innerHTML += "&nbsp;";
							element.append($(guidanceTemplate).click(function ($event) {
								var message = item.guidance;
								if (item.reference && item.reference.length)
									message = item.reference + "<br /><br />" + message;

								modal.openGuidance({
									title: item.text || $translate.instant('Guidance'),
									message: message
								});
							}))
						}
					}
				}
				else {
					element.remove();

					// TODO: Make it work
					// if (wfItemIfCtrl && wfItemIfCtrl.removeElement)
					// 	wfItemIfCtrl.removeElement("bodyText");
				}
			}
		};
		return directive;
	}


	wfItemActivitydates.$inject = [];

	function wfItemActivitydates() {
		var directive = {
			require: '^wfItemUse',
			restrict: 'A',
			link: function (scope, element, attrs, wfItemUseCtrl) {
				var
					text,
					item = wfItemUseCtrl.item
					;
				if (item && item.type == 15) {
					if (item.whenDateMode && item.whenDate)
						text = moment(item.whenDate).format('MMM YYYY')
					else
						text = "";

					if (!item.isOneTime && item.untilDateMode && item.untilDate)
						text += " - " + moment(item.untilDate).format('MMM YYYY')

				}

				if (text) {
					element.addClass("wf-activityDates");
					element = element[0];
					element.innerHTML = "<small>" + text + "</small>";
				}
				else
					element.remove();
			}
		};
		return directive;
	}


	wfItemImage.$inject = [ 'wfPropertyExtractor'];

	function wfItemImage(wfPropertyExtractor) {
		var directive = {
			require: [ '^wfItemUse', '?^wfItemIf' ],
			restrict: 'A',
			link: function (scope, element, attrs, controllers) {
				var
					wfItemUseCtrl = controllers[0],
					wfItemIfCtrl = controllers[1],
					options = {
						skipClass: false,
						defaults: undefined,
						standalone: undefined,
						noDefault: undefined,
						noSystemData: undefined
					}
				;

				if (wfItemUseCtrl.item) {
					if (attrs.wfItemImage.length) {
						_.assign(options, scope.$eval(attrs.wfItemImage));
					}

					if (options.noDefault && !wfItemUseCtrl.item.hasImage) {
						element.remove();
						return;
					}

					if (options.noSystemData && !wfItemUseCtrl.item.isUserDataType()) {
						element.remove();
						return;
					}

					if (options.hideElementIfImageExists && !wfItemUseCtrl.item.hasImage) {
						element.remove();
						return;
					}

					if (!options.skipClass)
						element.addClass('bgImage wf-image');

					// if (wfItemUseCtrl.item.type == enums.objectType.individual || wfItemUseCtrl.item.type == enums.objectType.person)
					// 	element.addClass('user-icon');

					if (wfItemUseCtrl.item) {
						var image = wfPropertyExtractor.getImageUrlOrClass(wfItemUseCtrl.item, options.defaults);
						if (image.type === "url") {
							element.css('backgroundImage', 'url(' + image.value + ')');
						}
						else {
							element.addClass(image.value);
							if (wfItemUseCtrl.imageIconColor) {
								element.css('color', wfItemUseCtrl.imageIconColor);
							}
						}
					}
					else {
						element.addClass('fa fa-globe');
					}

					if (options.standalone) {
						element.css({
							position: "static",
							width: options.standalone[0],
							height: options.standalone[1]
						})
					}
				}
				else {
					element.remove();

					// TODO: Make it work
					// if (wfItemIfCtrl && wfItemIfCtrl.removeElement)
					// 	wfItemIfCtrl.removeElement("image");
				}
			}
		};
		return directive;
	}

	wfItemHref.$inject = [ 'wfPropertyExtractor' ];

	function wfItemHref(wfPropertyExtractor) {
		var directive = {
			require: '^wfItemUse',
			restrict: 'A',
			link: function (scope, element, attrs, wfItemUseCtrl) {
				var urlPath;

				if (wfItemUseCtrl.goto) {
					urlPath = wfPropertyExtractor.getUrlPath(wfItemUseCtrl.goto, wfItemUseCtrl.gotoState);
					if (urlPath) {
						element.attr("href", urlPath);
					}
				}
			}
		};
		return directive;
	}

	wfItemIf.$inject = [];

	function wfItemIf() {
		var directive = {
			require: '^wfItemUse',
			restrict: 'A',
			controller: [ "$scope", "$element", "$attrs", "$parse", function ($scope, $element, $attrs, $parse) {
				var conditionalParts = $parse($attrs.wfItemIf)($scope);

				this.removeElement = function (itemPart) {
					if (!!~conditionalParts.indexOf(itemPart)) { // Check if itemPart is in array
						_.pull(conditionalParts, itemPart)

						if (conditionalParts.length === 0)
							$element.hide();
					}
				}
			}]
		};
		return directive;
	}


	wfItemGoto.$inject = [ '$parse', '$state', '$location', '$timeout', 'valueChainService', 'wfObject', 'wfPropertyExtractor' ];

	function wfItemGoto($parse, $state, $location, $timeout, valueChainService, wfObject, wfPropertyExtractor) {
		var directive = {
			require: '^wfItemUse',
			restrict: 'A',
			link: function (scope, element, attrs, wfItemUseCtrl) {
				var itemNavigationLookupSpec;
				// var fn;
				// if (attrs.wfItemGoto)
				// 	fn = $parse(attrs.wfItemGoto);
				if (wfItemUseCtrl.goto) {

					element.addClass("wf-goto");
					element.on("click", function (event) {
						var
							item = wfItemUseCtrl.goto,
							gotoState = wfItemUseCtrl.gotoState
						;
						var itemParentContent, itemChildContent;

						if (event.originalEvent.dropdown)
							return;

						// console.log(gotoState)
						if (gotoState) {
							$state.go(gotoState.name, gotoState.params);
							return;
						}

						if (item.type == enums.objectType.dataRelation && $state.includes('^.child')) {
							$state.go($state.current.name, { "objectId": item.id });
							return;
						}

						// The same logic happens twice and needs refactoring.
						// Both in service function getUrlPath and the directive wfItemGoto (here).

						switch (item.type) {
							case 73: // DataRelation
							case 81: // DataRelation
								if (wfPropertyExtractor.itemNavigationLookup.byRelation[item.wfid]) {
									if (wfPropertyExtractor.itemNavigationLookup.byRelation[item.wfid]) {
										itemNavigationLookupSpec = wfPropertyExtractor.itemNavigationLookup.byRelation[item.wfid];
										if (itemNavigationLookupSpec) {
											// If urlPattern is used and current state has networkId param and the state parent is a root sub menu solution then construct the url
											if (itemNavigationLookupSpec.urlPattern && _.get($state, "params.networkId") && _.get($state, "$current.parent.self.data.isSolutionRoot")) {
												$location.url((itemNavigationLookupSpec.urlPattern + "").replace("{networkId}", $state.params.networkId).replace("{solution}", $state.$current.parent.self.url));
											}
											else {
												$location.url(itemNavigationLookupSpec.url);
											}
											$timeout();
											return;
										}
									}
								}

								itemChildContent = item.childContent;
								if (item.settings && item.settings.urlPath) {
									$location.url(item.settings.urlPath);
									$timeout();
									return;
								}

								if (itemChildContent.conditions && itemChildContent.conditions.contextVariable1 === "ValueChain") {
									(function () {
										var
											valueChainRootUrlPath = itemChildContent.conditions.urlPath,
											valueChainNetworkIds = _.map(wfObject.filter({ where: {
												type: enums.objectType.dataRelation,
												wffid: "71-" + valueChainService.ids.networksStructure
											} }), "childId")
										;

										if (valueChainNetworkIds.length === 1)
											$location.path(valueChainRootUrlPath.replace('valuechain', 'valuechain/' + valueChainNetworkIds[0]));
										else
											$location.path(valueChainRootUrlPath);
										$timeout();
									})();
								}
								else if (itemChildContent.conditions && itemChildContent.conditions.urlPath) {
									$location.path(itemChildContent.conditions.urlPath);
									$timeout();
								}
								else if ((itemParentContent = item.parentContent) && itemParentContent.conditions && itemParentContent.conditions.contextVariable1 === "STANDARDS_v2") {
									$state.go('hier', { objectId: item.wfcid });
								}
								else if (itemChildContent.type === 13) {
									$state.go('influence', { idPath: itemChildContent.id });
								}
								else {
									$state.go('dataRelation', { idPath: item.id });
								}
								break;
							case 71: // Structure
								$state.go('structure', { idPath: item.id });
								break;
							case 52: // Network
								$state.go('network', { idPath: item.id });
								break;
							case 13: // Influence
								if (item.useNewRoute) {
									$location.path('/exchange/' + item.wfid);
									$timeout();
								}
								else {
									$state.go('influence', { idPath: item.id });
								}
								break;
						}

						if (!itemChildContent)
							itemChildContent = item.childContent;

						if (itemChildContent) {
							if (itemChildContent.type === 90) {
								var win = window.open(itemChildContent.url, '_blank');
								if (win) {
									//Browser has allowed it to be opened
									win.focus();
								}
							}
						}
					});
				}
			}
		};
		return directive;
	}

	wfItemSubHeader.$inject = [ 'wfPropertyExtractor' ];

	function wfItemSubHeader(wfPropertyExtractor) {
		var directive = {
			require: '^wfItemUse',
			restrict: 'A',
			link: function (scope, element, attrs, wfItemUseCtrl) {
				var
					text,
					item = wfItemUseCtrl.item
				;

				text = wfPropertyExtractor.getSubHeaderText(item);

				if (text) {
					element.addClass("wf-subHeader");
					element = element[0];
					element.innerHTML = text;

					if (item.type === enums.objectType.orgActivity) {
						element.innerHTML = '<i class="fa fa-calendar-check-o pr5"></i>' + element.innerHTML;
					}
				}
				else
					element.remove();
			}
		};
		return directive;
	}

	wfItemPreHeader.$inject = [ 'wfPropertyExtractor' ];

	function wfItemPreHeader(wfPropertyExtractor) {
		var directive = {
			require: '^wfItemUse',
			restrict: 'A',
			link: function (scope, element, attrs, wfItemUseCtrl) {
				var
					text,
					item = wfItemUseCtrl.item
				;

				text = wfPropertyExtractor.getPreHeaderText(item);

				if (text) {
					element.addClass("wf-preHeader");
					element = element[0];
					element.innerHTML = text;
				}
				else
					element.remove();
			}
		};
		return directive;
	}


	wfItemCreatorImage.$inject = [ 'wfPropertyExtractor' ];

	function wfItemCreatorImage(wfPropertyExtractor) {
		var directive = {
			require: '^^wfItemUse',
			restrict: 'A',
			link: function (scope, element, attrs, wfItemUseCtrl) {
				var
					url,
					item = wfItemUseCtrl.item
				;

				url = wfPropertyExtractor.getCreatorImageUrl(item);

				if (url) {
					element.addClass("wf-creatorImage");
					element.css('backgroundImage', 'url(' + url + ')');
				}
				else
					element.remove();
			}
		};
		return directive;
	}


	wfItemCreatorName.$inject = [ 'wfPropertyExtractor', '$sanitize' ];

	function wfItemCreatorName(wfPropertyExtractor, $sanitize) {
		var directive = {
			require: '^^wfItemUse',
			restrict: 'A',
			link: function (scope, element, attrs, wfItemUseCtrl) {
				var
					text,
					item = wfItemUseCtrl.item
				;

				text = wfPropertyExtractor.getCreatorName(item);

				if (text) {
					element.addClass("wf-creatorName");
					element = element[0];
					element.innerHTML = $sanitize(text);
				}
				else
					element.remove();
			}
		};
		return directive;
	}


	wfItemCreatorOrgname.$inject = [ 'wfPropertyExtractor', '$sanitize', 'wfAuth' ];

	function wfItemCreatorOrgname(wfPropertyExtractor, $sanitize, wfAuth) {
		var directive = {
			require: '^^wfItemUse',
			restrict: 'A',
			link: function (scope, element, attrs, wfItemUseCtrl) {
				var
					text,
					item = wfItemUseCtrl.item,
					organizationInfoOutput = {}
				;

				text = wfPropertyExtractor.getCreatorOrganizationName(item, organizationInfoOutput);

				if (organizationInfoOutput.id === wfAuth.getOrganizationId() && !organizationInfoOutput.contextParentAppended) {
					element.remove();
					return;
				}

				if (text) {
					element.addClass("wf-creatorOrgName");
					element = element[0];
					element.innerHTML = $sanitize(text);
				}
				else
					element.remove();
			}
		};
		return directive;
	}


	wfItemCreatedAt.$inject = [ 'wfPropertyExtractor', '$sanitize', '$parse' ];

	function wfItemCreatedAt(wfPropertyExtractor, $sanitize, $parse) {
		var directive = {
			require: '^^wfItemUse',
			restrict: 'A',
			link: function (scope, element, attrs, wfItemUseCtrl) {
				var
					text,
					item = wfItemUseCtrl.item
				;

				if (attrs.wfItemCreatedAt && typeof attrs.wfItemCreatedAt === "string") {
					text = $parse(attrs.wfItemCreatedAt)(scope);
					text = wfPropertyExtractor.getFormattedCreatedAt(text);
				}
				else {
					text = wfPropertyExtractor.getFormattedCreatedAt(item);
				}

				if (text) {
					element.addClass("wf-createdAt");
					element = element[0];
					element.innerHTML = $sanitize(text);
				}
				else
					element.remove();
			}
		};
		return directive;
	}


	wfItemCreatorBar.$inject = [ 'wfPropertyExtractor', '$sanitize' ];

	function wfItemCreatorBar(wfPropertyExtractor, $sanitize) {
		var directive = {
			require: '^^wfItemUse',
			restrict: 'E',
			template:
				'<div class="item-creator-time">'
					+ '<div>'
						+ '<span wf-item-creator-image></span>'
						+ '<span wf-item-creator-name></span>'
						+ '<span wf-item-creator-orgname class="partDivider-left"></span>'
						+ '<span class="item-details-partDivider"></span>'
						+ '<span wf-item-created-at></span>'
					+ '</div>'
				+ '</div>',
			link: function (scope, element, attrs, wfItemUseCtrl) {
				// var
				// 	text,
				// 	item = wfItemUseCtrl.item
				// ;

				// text = wfPropertyExtractor.getCreatorOrganizationName(item);

				// if (text) {
				// 	element.addClass("wf-creatorOrgName");
				// 	element = element[0];
				// 	element.innerHTML = $sanitize(text);
				// }
				// else
				// 	element.remove();
			}
		};
		return directive;
	}


	wfPropertyExtractorService.$inject = [ "$sanitize", "$state", "$translate", "$filter", "wfMeasureService" ]

	function wfPropertyExtractorService($sanitize, $state, $translate, $filter, wfMeasureService) { // Circular reference error if wfObject or valueChainService is injected
		var
			itemNavigationLookup = {
				byRelation: {},
				byContent: {}
			},
			service = {
				registerItemNavigationUrl: registerItemNavigationUrl,
				clearRegisteredItemNavigationLookup: clearRegisteredItemNavigationLookup,
				itemNavigationLookup: itemNavigationLookup,
				getContent: getContent,
				getHeaderText: getHeaderText,
				getMainTextual: getMainTextual,
				getSubHeaderText: getSubHeaderText,
				getPreHeaderText: getPreHeaderText,
				getBodyText: getBodyText,
				getImageUrl: getImageUrl,
				getImageUrlOrClass: getImageUrlOrClass,
				getOuterCreatedAt: getOuterCreatedAt,
				getInnerCreatedAt: getInnerCreatedAt,
				getMeasureAnswerUnit: getMeasureAnswerUnit,
				getUrlPath: getUrlPath,
				getCreatorImageUrl: getCreatorImageUrl,
				getCreatorName: getCreatorName,
				getCreatorOrganizationName: getCreatorOrganizationName,
				getFormattedCreatedAt: getFormattedCreatedAt,
				getFormattedMeasureAnswerValue: getFormattedMeasureAnswerValue,
				getMeasureAnswerPeriod: getMeasureAnswerPeriod,
				getQuestionAnswerTypeText: getQuestionAnswerTypeText,
				getIconCollection: undefined,
				getQuestionAnswerTypeTranslateMap: function () { return _.clone(questionAnswerTypeTranslateMap); },
				measureUnitExtractor: measureUnitExtractor,
				getItemSummary: getItemSummary,
				getTextFromProperty: getTextFromProperty
			},
			objType = enums.objectType,
			eventType = enums.eventType,
			defaultObjectImageByType = {},
			defaultImageCollections = {
				'objectTypeIconPack1': {}
			},

			// Integer keys are not supported in JS and will be auto converted to string keys,
			// however they can still be accessed using either an integer or string.
			questionAnswerTypeTranslateMap = {
				1: "Processing",
				2: "NotRelevant",
				3: "No",
				4: "Yes",
				5: "modules.questionAnswerTypes.accredited",
				6: "modules.questionAnswerTypes.compliant",
				7: "modules.questionAnswerTypes.notCompliant",
				8: "modules.questionAnswerTypes.registered",
				9: "modules.questionAnswerTypes.notRegistered",
				10: "modules.questionAnswerTypes.agree",
				11: "modules.questionAnswerTypes.doNotAgree",
				13: "modules.questionAnswerTypes.partlyCompliant",
				14: "modules.questionAnswerTypes.approved",
				15: "modules.questionAnswerTypes.notApproved",
				16: "modules.questionAnswerTypes.noTime",
				17: "modules.questionAnswerTypes.notApplicable",
				18: "modules.questionAnswerTypes.checked",
				19: "modules.questionAnswerTypes.notChecked",
				20: "modules.questionAnswerTypes.partly",
				21: "modules.questionAnswerTypes.noInfoAvailable",
				22: "modules.questionAnswerTypes.dontKnow",
				23: "modules.questionAnswerTypes.certified",
				24: "modules.questionAnswerTypes.lowRisk",
				25: "modules.questionAnswerTypes.mediumRisk",
				26: "modules.questionAnswerTypes.highRisk",
				27: "modules.questionAnswerTypes.inProgress",
				"unanswered": "Unanswered"
			}
		;

		activate();

		return service;

		function activate() {
			service.iconCollection = defineIconCollection();
		}

		function defineIconCollection() {
			var collection;

			collection = defaultObjectImageByType;

			collection[enums.objectType.statement] = "fa fa-quote-right";
			collection[enums.objectType.question] = "fa fa-comment";
			collection[enums.objectType.measure] = "fa fa-line-chart";
			collection[enums.objectType.relativeMeasure] = "fa fa-line-chart";
			collection[enums.objectType.measureAnswer] = "fa fa-line-chart";
			collection[enums.objectType.location] = "fa fa-map-marker";
			collection[enums.objectType.link] = "fa fa-link";
			collection[enums.objectType.embed] = "fa fa-play-circle";
			collection[enums.objectType.orgDocument] = "fa fa-file";
			collection[enums.objectType.questionAnswer] = "fa fa-comments-o";
			collection[enums.objectType.orgActivity] = "fa fa-calendar-check-o";
			collection[enums.objectType.productionSite] = "fa fa-industry";
			collection[enums.objectType.productService] = "fa fa-cube";
			collection[enums.objectType.certificate] = "fa fa-certificate";
			collection[enums.objectType.finding] = "fas fa-comments";
			collection[enums.objectType.organization] = "fas fa-building";
			collection[enums.objectType.person] = "fa fa-user";
			collection[enums.objectType.dateItem] = "fa fa-calendar";
			collection[enums.objectType.country] = "fa fa-flag";
			collection[0] = "image-placeholder";

			collection = defaultImageCollections.objectTypeIconPack1;

			collection[enums.objectType.statement] = "fa fa-quote-right";
			collection[enums.objectType.question] = "fa fa-comment";
			collection[enums.objectType.measure] = "fa fa-line-chart";
			collection[enums.objectType.relativeMeasure] = "fa fa-line-chart";
			collection[enums.objectType.measureAnswer] = "fa fa-line-chart";
			collection[enums.objectType.location] = "fa fa-map-marker";
			collection[enums.objectType.link] = "fa fa-link";
			collection[enums.objectType.embed] = "fa fa-play-circle";
			collection[enums.objectType.orgDocument] = "fa fa-file";
			collection[enums.objectType.questionAnswer] = "fa fa-comments-o";
			collection[enums.objectType.orgActivity] = "fa fa-calendar-check-o";
			collection[enums.objectType.productionSite] = "fa fa-industry";
			collection[enums.objectType.productService] = "fa fa-cube";
			collection[enums.objectType.organization] = "fas fa-building";
			collection[enums.objectType.finding] = "fas fa-comments";
			collection[enums.objectType.certificate] = "fa fa-certificate";
			collection[enums.objectType.person] = "fa fa-user";
			collection[enums.objectType.dateItem] = "fa fa-calendar";
			collection[enums.objectType.country] = "fa fa-flag";
			collection[0] = "fa fa-globe";

			return collection;
		}

		function registerItemNavigationUrl(spec) {
			if (spec.relationWfid)
				itemNavigationLookup.byRelation[spec.relationWfid] = spec;

			if (spec.contentWfid)
				itemNavigationLookup.byContent[spec.contentWfid] = spec;
		}

		function clearRegisteredItemNavigationLookup() {
			itemNavigationLookup.byRelation = {};
			itemNavigationLookup.byContent = {};
		}

		function getContent(item, stopOnType) {
			// console.info("getContent", item)
			if (!item || !item.type) {
				return null;
			}
			else if (!item.wfcid) {
				// console.log("!item.childContent", item)
				return item;
			}
			else {
				return _getInnerContent(_getInnerContent(item, stopOnType), stopOnType);
			}
		}

		function _getInnerContent(item, stopOnType) {
			// if (!item || !item.childContent) {

			// 	return item;
			// }

			if (!item)
				return item;

			if (stopOnType === item.type)
				return item;

			switch (item.type) {
				case objType.influence:
					return item.childContent
				case objType.dataRelation:
				case objType.virtualDataRelation:
				case objType.filteredDataRelation:
					return item.childContent
				default:
					return item;
			}
		}

		function getHeaderText(item2, options) {
			var output, asHtml, item;
			// console.info(item2);
			item = getContent(item2, objType.influence);

			if (item) {
				if (item._headerText_)
					output = item._headerText_;
				else if (item.type === enums.objectType.influence)
					output = item.title;
				else if (item.type === objType.questionAnswer) {// item.childContent is questionAnswerType
					if (item.childContent && item.childContent.text)
						output = item.childContent.text;
					else
						output = getQuestionAnswerTypeText(item);
				}
				else if (item.type === objType.measureAnswer)// && item.childContent) // itme.childContent is unit (hours, kilos, metric tons etc)
				{
					if (options && options.html === false) {
						output = getFormattedMeasureAnswerValue(item, true) + " (" + getMeasureAnswerPeriod(item) + ")";
					}
					else {
						asHtml = true;
						// console.log(item.childContent);
						output = "<span class='measure-answer-year'>" + $sanitize(getMeasureAnswerPeriod(item)) + ":</span>" + $sanitize(getFormattedMeasureAnswerValue(item, true, true))
						// console.log(output);
					}
				}
				else if (item.type === objType.account) {
					output = item.organization.name;
				}
				else if (options && options.useFriendlyTitle && item.friendlyTitle)
					output = item.friendlyTitle;
				else
					output = item.title || item.name || item.displayName;
			}

			if (!asHtml && (!options || !options.dontSanitize))
				output = $sanitize(output);
			return output;
		}

		function getMainTextual(item, options) {
			var output, body;
			switch (this.type) {
				case enums.objectType.statement:
				case enums.objectType.question:
				case enums.objectType.measure:
				case enums.objectType.relativeMeasure:
					return getBodyText(item, options);
				default:
					output = getHeaderText(item, options);

					if (!output || !output.length)
						output = getBodyText(item, options);
					else if (options && options.alwaysIncludeBody) {
						body = getBodyText(item, options);
						if (body)
							output = output + " - " + body
					}
					return output;
			}
		}

		function getPreHeaderText(item) {
			var output, asHtml;
			item = getContent(item);

			if (!asHtml) {
				output = $sanitize(output);
			}

			return output;
		}

		function getSubHeaderText(item) {
			var output, asHtml;
			item = getContent(item);

			if (item) {
				if (item.type === enums.objectType.certificate) {
					if (item.validFromDate)
						output = moment(item.validFromDate).format('D MMM YYYY');

					if (item.validUntilDate)
						output = output + " - " + moment(item.validUntilDate).format('D MMM YYYY');
				}
				else if (item.type === enums.objectType.orgActivity) {
					if (item.whenDateMode && item.whenDate)
						output = moment(item.whenDate).format('D MMM YYYY')
					else
						output = "";

					if (!item.isOneTime && item.untilDateMode && item.untilDate)
						output += " - " + moment(item.untilDate).format('D MMM YYYY')
				}
				else if (item.type === enums.objectType.organization) {
					output = _.compact([ item.registrationNumber, item.vatNumber, item.gln ]).join(" • ");
				}
				else if (item.type === enums.objectType.productService) {
					output = [];

					if (item.identifierCode)
						output.push("ID: " + item.identifierCode);
					if (item.ean)
						output.push("EAN: " + item.ean);
					if (item.upc)
						output.push("UPC: " + item.upc);
					if (item.isbn)
						output.push("ISBN: " + item.isbn);

					if (output.length)
						output = output.join(" • ");
					else
						output = undefined;
				}
				else if (item.type === enums.objectType.location) {
					// output = item.latitude + ", " + item.longitude
				}
				else if (item.type === enums.objectType.productionSite && item.gln) {
					output = "GLN: " + item.gln;
				}
			}

			if (!asHtml) {
				output = $sanitize(output);
			}

			return output;
		}

		function getBodyText(item, detailsOutput) {
			var output, asHtml;
			item = getContent(item);

			if (item) {
				if (item.type === objType.account) {
					output = item.subscriptionId;
				}
				else if (item.type === objType.location || item.type === objType.productionSite) {
					output = item.formattedAddress;
				}
				else if (item.type === objType.parameterValue && item.parentContent) {
					output = item.parentContent.text + ": " + " " + item.value;
				}
				else if (item.type === enums.objectType.individual || item.type === enums.objectType.person) {
					output = _.compact([ item.email || item.username, item.phone_number, item.position ]).join(" • ");
				}
				else if (item.type === objType.dateItem) {
					output = moment(item.date).format("D MMMM YYYY");
					if (item.endDate)
						output += " - " + moment(item.endDate).format("D MMMM YYYY");
				}
				else {
					output = item.text || item.description || item.email || item.username;// || item.registrationNumber;
				}
			}

			if (!asHtml && !detailsOutput || (!detailsOutput.dontSanitize && !(detailsOutput.html === false)))
				output = $filter('linky')(output, "_blank");

			if (detailsOutput)
				detailsOutput.asHtml = asHtml;

			return output;
		}

		function getImageUrlOrClass(item, defaults) {
			var
				output = {},
				imageUrl = getImageUrl(item, defaults),
				imageFileTypes = [ "jpg", "png", "jpeg", "jfif", "tif", "tiff", "gif", "bmp", "svg", "exif", "ppm", "pgm", "pbm", "pnm" ]
			;

			if (imageUrl) {
				return {
					value: imageUrl,
					type: "url"
				}
			}
			if (!defaults)
				defaults = defaultObjectImageByType;

			if (!imageUrl && typeof defaults === "string") {
				if (typeof defaultImageCollections[defaults] === "undefined") {
					console.error("Error in propertyExtractor.directive.js: Default image collection '" + defaults + "' is not defined");
					return "";
				}
				else {
					defaults = defaultImageCollections[defaults];
				}
			}

			if (item.type === enums.objectType.country) {
				output.value = `flag-icon-background flag-icon-squared flag-icon-${item.iso3166_alpha2}`
				output.type = "class";
			}
			else if (defaults[item.type]) {
				if (item.type === enums.objectType.orgDocument) {
					if (item.fileType) {
						if (!!~imageFileTypes.indexOf(item.fileType))
							output.value = "fa fa-file-image-o"
						else if (item.fileType === "txt")
							output.value = "fa fa-file-text-o"
						else if (item.fileType === "pdf")
							output.value = "fa fa-file-pdf-o"
						else
							output.value = defaults[item.type]
					}
				}
				else {
					output.value = defaults[item.type];
				}
				output.type = "class";
			}
			else {
				output.value = defaults[0];
				output.type = "class";
			}

			return output;
		}

		function getImageUrl(item, defaults) {
			var output;
			var url;
			var useStructureLocalImage = false;//!defaults;

			item = getContent(item);

			if (item) {
				if (item.type === 71 && item.wfid.indexOf("|13-") > 0) { // Checking if it is a virtual structure (used in influences)
					return "/assets/img/internalVC_influenceImage.png";
				}

				if (item.hasImage) {
					output = item.imageUrl;
				}
				else if (item.type === 71 && useStructureLocalImage) {
					output = "/assets/img/wfitems/" + item.wfid + ".png";
				}
				else if (item.type == 58) {
					var googleKey = "AIzaSyChfzQUypvsAJdjIdCtQIzAUP_P0GHIbiM"
					var longitude = item.longitude;
					var latitude = item.latitude;
					var googleStaticMapURL = "https://maps.googleapis.com/maps/api/staticmap?zoom=17&size=100x100&maptype=roadmap&markers=color:red%7Alabel:C%7C" + latitude + "," + longitude + "&key=" + googleKey;

					output = googleStaticMapURL;
				}
			}

			return output;
			// return $sanitize(output); // Sanitizing breaks urls with querystring (like Auth0's default image).
		}

		function getCreatorImageUrl(item) {
			var output;

			item = getContent(item);

			if (item && item.creatorUser) {
				output = item.creatorUser.imageUrl
			}

			return output;
		}

		function getCreatorName(item) {
			var output;

			item = getContent(item);

			if (item && item.creatorUser) {
				output = item.creatorUser.name
			}

			return output;
		}

		function getCreatorOrganizationName(item, organizationInfoOutput) {
			var output, org, contextParent;

			// if (item.dataRelation && item.dataRelation.contextParentType === enums.objectType.organization) {
			// 	if (organizationInfoOutput)	organizationInfoOutput.id =
			// 	org = wfObject.get(_.get(item.dataRelation, "wfxpid"));

			// 	if (org && organizationInfoOutput) {
			// 		organizationInfoOutput.id = org.id
			// 		organizationInfoOutput.wfid = org.wfid;
			// 	}

			// 	return _.get(org, "name");
			// }
			// else {
				item = getContent(item);

				if (item && item.creatorOrganization) {
					if (organizationInfoOutput) {
						organizationInfoOutput.id = item.creatorOrganization.id
						organizationInfoOutput.wfid = item.creatorOrganization.wfid;
					}

					output = item.creatorOrganization.name

					if (item.dataRelation && item.dataRelation.wfxpid) {
						contextParent = item.dataRelation.contextParentContent;
						organizationInfoOutput.contextParentAppended = true;

						if (contextParent)
							output += " - " + contextParent.name
					}
				}
			// }

			return output;
		}

		function getFormattedCreatedAt(itemOrValue) {
			var output, momentInstance, item, value;

			if (typeof itemOrValue === "object") {
				item = getContent(itemOrValue);
				if (item && item.createdAt) {
					if (!item._formattedCreatedAt) {
						momentInstance = moment(item.createdAt);
						item._formattedCreatedAt = momentInstance.format("ddd, D MMMM YYYY, HH:mm");
						item._formattedCreatedAtTimeAgo = momentInstance.fromNow();
					}

					output = item._formattedCreatedAtTimeAgo
				}
			}
			else {
				momentInstance = moment(itemOrValue);
				output = momentInstance.format("ddd, D MMMM YYYY, HH:mm");
			}



			return output;
		}

		function getOuterCreatedAt(item) {
			var output;
			// item = getContent(item);

			if (item.type) {
				output = item.createdAt;
			}

			return output;
		}

		function getInnerCreatedAt(item) {
			var output;
			item = getContent(item);

			if (item.type) {
				output = item.createdAt;
			}

			return output;
		}

		function getMeasureAnswerUnit(measureAnswer) {
			return measureUnitExtractor.getUnitSymbolFromMeasure(measureAnswer);
		}

		function getFormattedMeasureAnswerValue(measureAnswer, includeUnit, includeThousandsSeparators, useSourceIfConverted, useDotAsDecimalSymbol) {
			var output, unit, value;

			if (useSourceIfConverted && measureAnswer.isConverted) {
				value = measureAnswer.sourceValue;
			}
			else {
				value = measureAnswer.value;
			}

			if (measureAnswer.isNumber) {
				if (includeThousandsSeparators)
					output = numeral(parseFloat(value)).format('0,0.[00000000000000000000]');
				else
					output = numeral(parseFloat(value)).format('0.[00000000000000000000]');

				if (includeUnit) {
					unit = measureUnitExtractor.getUnitSymbolFromMeasure(measureAnswer, useSourceIfConverted);

					if (unit && unit !== "%")
						unit = " " + unit; // Non-break space (Alt+0160)

					output += unit;
				}

				if (useDotAsDecimalSymbol && !includeThousandsSeparators) {
					output = output.replace(",", ".")
				}

				return output;
			}
			else if (measureAnswer.value === null) {
				return "N/A"
			}
			else
				return measureAnswer.value;
		}

		function getMeasureAnswerPeriod(measureAnswer, options) {
			var
				startMoment,
				endMoment
			;

			if (measureAnswer.periodName)
				return measureAnswer.periodName;

			if (!measureAnswer.frequency)
				return measureAnswer.year;

			if (measureAnswer.frequency !== enums.calendarFrequency.custom) {
				startMoment = moment(measureAnswer.period);
			}

			switch (measureAnswer.frequency) {
				case enums.calendarFrequency.yearly:
					return startMoment.year();
				case enums.calendarFrequency.halfYearly:
					return "H" + measureAnswer.halfYear + " " +  startMoment.year();
				case enums.calendarFrequency.quarterly:
					return "Q" + measureAnswer.quarter + " " +  startMoment.year();
				case enums.calendarFrequency.monthly:
					if (options && options.useNumericMonthlyFormat) {
						return startMoment.format("YYYY-MM");
					}
					else {
						return startMoment.format("MMM YYYY");
					}
				case enums.calendarFrequency.custom:
					return wfMeasureService.formatPeriodNameFromAnswer(measureAnswer, measureAnswer.intervalNameSpecification);
			}
		}

		function getQuestionAnswerTypeText(questionAnswerOrId) {
			var questionAnswerTypeId;

			if (typeof questionAnswerOrId === "number") {
				questionAnswerTypeId = questionAnswerOrId;
			}
			else if (typeof questionAnswerOrId === "string") {
				questionAnswerTypeId = questionAnswerOrId === "unanswered" ? questionAnswerOrId : parseInt(questionAnswerOrId)
			}
			else {
				questionAnswerTypeId = questionAnswerOrId.questionAnswerTypeId;
			}

			return $translate.instant(questionAnswerTypeTranslateMap[questionAnswerTypeId]);
		}

		function getUrlPath(item, gotoState) {
			var itemParentContent, itemChildContent, itemNavigationLookupSpec;

			// console.log(gotoState)
			if (gotoState) {
				return $state.href(gotoState.name, gotoState.params);
			}

			if (item.type == enums.objectType.dataRelation && $state.includes('^.child')) {
				return $state.href($state.current.name, { "objectId": item.id });
			}

			// The same logic happens twice and needs refactoring.
			// Both in service function getUrlPath (here) and the directive wfItemGoto.

			switch (item.type) {
				case 73: // DataRelation
				case 81: // VirtualDataRelation
					if (itemNavigationLookup.byRelation[item.wfid]) {
						itemNavigationLookupSpec = itemNavigationLookup.byRelation[item.wfid];
						if (itemNavigationLookupSpec) {
							// If urlPattern is used and current state has networkId param and the state parent is a root sub menu solution then construct the url
							if (itemNavigationLookupSpec.urlPattern && _.get($state, "params.networkId") && _.get($state, "$current.parent.self.data.isSolutionRoot"))
								return (itemNavigationLookupSpec.urlPattern + "").replace("{networkId}", $state.params.networkId).replace("{solution}", $state.$current.parent.self.url);
							else
								return itemNavigationLookupSpec.url;
						}
					}

					itemChildContent = item.childContent;
					if (item.settings && item.settings.urlPath) {
						return item.settings.urlPath;
					}

					if (itemChildContent) {
						if (itemChildContent.conditions && itemChildContent.conditions.contextVariable1 === "ValueChain") {
							return (function () {
								var
								valueChainRootUrlPath = itemChildContent.conditions.urlPath,
								valueChainNetworkIds = _.map(wfObject.filter({ where: {
									type: enums.objectType.dataRelation,
									wffid: "71-12226" // + valueChainService.ids.networksStructure
								} }), "childId")
								;

								if (valueChainNetworkIds.length === 1)
								return valueChainRootUrlPath.replace('valuechain', 'valuechain/' + valueChainNetworkIds[0]);
								else
								return valueChainRootUrlPath;
							})();
						}
						else if (itemChildContent.conditions && itemChildContent.conditions.urlPath) {
							return itemChildContent.conditions.urlPath;
						}
						else if ((itemParentContent = item.parentContent) && itemParentContent.conditions && itemParentContent.conditions.contextVariable1 === "STANDARDS_v2") {
							return $state.href('hier', { objectId: item.wfcid });
						}
						else if (itemChildContent.type === 13) {
							return $state.href('influence', { idPath: itemChildContent.id });
						}
						else {
							return $state.href('dataRelation', { idPath: item.id });
						}
					}
					else {
						return $state.href('dataRelation', { idPath: item.id });
					}
				case 71: // Structure
					return $state.href('structure', { idPath: item.id });
				case 52: // Network
					return $state.href('network', { idPath: item.id });
				case 13: // Influence
					if (item.useNewRoute) {
						return '/exchange/' + item.wfid;
					}
					else {
						return $state.href('influence', { idPath: item.id });
					}
			}

			if (!itemChildContent)
				itemChildContent = item.childContent;

			if (itemChildContent) {
				if (itemChildContent.type === 90) {
					return itemChildContent.url;
				}
			}
		}

		function getItemSummary(item) {
			var output;
			item = getContent(item);

			if (!item) {
				return;
			}

			output = [
				getHeaderText(item, { html: false, dontSanitize: true }),
				item.url || item.imageUrl,
				getSubHeaderText(item, { html: false, dontSanitize: true }),
				getBodyText(item, { html: false, dontSanitize: true }),
			].filter(Boolean).join("\n")

			return output;
		}

		function getTextFromProperty(property, culture) {
			if (property.multilingual) {
				let output = property.multilingual[culture];

				if (!output) {
					output = property.multilingual["en-US"];
				}

				return output;
			}
			else if (property.translate) {
				return $translate.instant(property.translate)
			}
			else {
				console.error("Could not getTextFromProperty. Make sure that the property has either multilingual: { en_US: 'English', sv_SE: 'Svenska' }, or translate: 'modules.subModule.text'");
				return "";
			}
		}
	}

	function measureUnitExtractorHandler() {
		var
			cacheByUnitId = {}
			;

		return {
			getUnitSymbolFromMeasure: getUnitSymbolFromMeasure
		};

		function getUnitSymbolFromMeasure(item, useSourceUnitIfConverted) {
			var unitObject, unitId, usingSourceUnit;

			if (useSourceUnitIfConverted && item.sourceUnit) {
				unitId = item.sourceUnitId;
				usingSourceUnit = true;
			}
			else {
				unitId = item.unitId;
			}

			if (unitId) {
				if (!item.wfcid) {
					item.wfcid = "84-" + unitId;
				}

				if (cacheByUnitId[unitId]) {
					unitObject = cacheByUnitId[unitId];
				}
				else if (unitId === -1) {
					unitObject = item.childContent || item.unit;
				}
				else if (usingSourceUnit) {
					unitObject = cacheByUnitId[unitId] = item.sourceUnit;
				}
				else {
					unitObject = cacheByUnitId[unitId] = (item.childContent || item.unit);
				}
			}
			else {
				if (item.unit) {
					unitObject = unit
				}
				else if (item.unitName || item.unitSymbol) {
					unitObject = { name: item.unitName, symbol: item.unitSymbol }
				}
			}

			if (unitObject) {
				return (unitObject["symbol"] || "");
			}
			else {
				return "";
			}
		}
	}
})();
