import en_US from "./wf/locales/en-US.js";
import sv_SE from "./wf/locales/sv-SE.js";

(function () {
	'use strict';

	angular.module('wf.multilingual', [
		'pascalprecht.translate'
	])
	.config([ '$translateProvider', function ($translateProvider) {
		$translateProvider.useSanitizeValueStrategy('sanitize');
		
		//$translateProvider.useInterpolation('customTranslateInterpolation');
		
		// Utrum/Neutrum: https://sv.wiktionary.org/wiki/ny

		$translateProvider.translations('en-US', en_US);
		$translateProvider.translations('sv-SE', sv_SE);

		$translateProvider.fallbackLanguage('en-US');
		$translateProvider.preferredLanguage('sv-SE');
	}]);
})();