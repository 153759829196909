// See documentation in WorldfavorKnowledgeBase 
// docs/Front-end/XML Importer/Xml-importer.md
import * as enums from '@worldfavor/constants/enums'

(function () {
	'use strict';

	angular
		.module('wf.common')
		.factory('XmlImporter', XmlImporterFactory)
	;

	XmlImporterFactory.$inject = [ 'dataOperationsService', 'wfObject', '$q', 'dataQuery', 'moment' ];
	function XmlImporterFactory( dataOps, wfObject, $q, dataQuery, moment) {

		return Importer;

		function Importer() {
			var self = this;

			_.assign(self, {
				//Variables
				standardItems: [],
				injectedItems: [],
				log: undefined,
				rootItem: undefined,

				jsonLoadedToJsData: false,

				//Functions
				parseXmlString: parseXmlString,
				prepareJsonAndLog: prepareJsonAndLog,
				injectStandardToJsData: injectStandardToJsData,
				findItemInJsDataAndXmlImporter: findItemInJsDataAndXmlImporter,
				createRealStructure: createRealStructure,
				createRealRelation: createRealRelation
			});

			//-----------Phase 1 - Converting XML to JSON------------------
			//-------------------------------------------------------------

			function parseXmlString(xmlString) {
				var deferred = $q.defer();
				require([ "parseXmlString" ], function (parseXmlString) {
					deferred.resolve(parseXmlString.parse(xmlString));
				});
				return deferred.promise;
			}

			//-------Phase 2 - Preparing JSON objects for preview----------
			//-------------------------------------------------------------

			function prepareJsonAndLog(xmlInJsonFormat) {
				var frontNode = _.find(xmlInJsonFormat.children, { name: "front" });
				var bodyNode = _.find(xmlInJsonFormat.children, { name: "body" });
				// var backNode = _.find(xmlInJsonFormat.children, { name: "back" });

				var log = {
					frontTags: {},
					bodyTags: {
						byType: {
							labels: 0,
							titles: 0,
							sections: 0,
							paragraphs: 0,
							guidelines: 0,
							stds: 0,
							stdRefs: 0,
							sups: 0,
							xrefs: 0,
							unnamedTags: 0,
							lists: 0,
							listItems: 0
						}
					},
					backTags: {},
					summary: {
						totalFakeStructuresCreated: 0,
						totalFakeRelationsCreated: 0,
						totalTagsTraversed: 0,
						includedTags: 0,
						discardedTags: []
					}
				};

				var tagSpecs = getTagSpecifications();
				var rootItem = createFakeStructure(getRootStructure(frontNode, true), enums.objectType.structure);
				var bodyTagsQueue = _.clone(bodyNode.children);
				traverseTags(bodyTagsQueue.shift(), bodyTagsQueue, rootItem, {}, 1);

				var flatList = dataQuery.getHierarchyAsList(rootItem, null, { accessPropertiesDirectly: true });

				flatList = _.map(flatList, "dataRelation");
				_.each(flatList, function(item) { delete item.childContent.childs });

				// Set root item
				delete rootItem.childs;
				rootItem.isRootItem = true;
				self.rootItem = rootItem;
				flatList.push(rootItem);

				self.standardItems = flatList;
				self.log = log;

				function traverseTags(tag, currentQueue, parent, options, order) {
					log.summary.totalTagsTraversed++;

					var
						tagName = tag.name,
						innerQueue,
						tagSpec,
						hascustomTagCheck
					;

					tagSpec = _.find(tagSpecs.bodyTags, { tag: tagName });
					hascustomTagCheck = tagSpec && tagSpec.customTagCheck ? true : false;

					if (tagSpec && hascustomTagCheck)
						tagSpec.allow = tagSpec.customTagCheck(tag);

					if (tagSpec && tagSpec.allow) {
						log.summary.includedTags++;

						if (tagSpec.customTagFunc) {
							tagSpec.customTagFunc(tag, parent, options, order);
							order++;
						}

						if (tag.children && tag.children.length > 0) {
							innerQueue = _.clone(tag.children);
							traverseTags(innerQueue.shift(), innerQueue, parent, options, 1);
						}
					}
					else {
						log.summary.discardedTags.push(tag);
					}

					if (currentQueue.length > 0) {
						traverseTags(currentQueue.shift(), currentQueue, parent, options, order);
					}
				}

				function createFakeStructure(options, objectType) {
					var
						id = parseInt(_.uniqueId()),
						rootId = rootItem ? rootItem.id : id,
						newObjecType = objectType
					;

					log.summary.totalFakeStructuresCreated++;

					return _.assign({
						type: newObjecType,
						wfid: newObjecType + "-FAKE|" + id,
						childs: [],
						id: id,
						organizationId: null,
						ancestorId: rootId,
						ancestorWfid: newObjecType + "-FAKE|" + rootId,
						createdAt: moment().format(),
					}, options);
				}

				function createFakeRelation(parent, child) {
					var dataRelationId = parseInt(_.uniqueId()),
						dataRelation = {
							childId: child.id,
							childType: child.type,
							createdAt: moment().format(),
							id: dataRelationId,
							order: 0,
							organizationId: null,
							parentId: parent.id,
							parentType: parent.type,
							type: enums.objectType.dataRelation,
							wfcid: child.wfid,
							wffid: parent.wfid,
							wfid: enums.objectType.dataRelation + "-FAKE|" + dataRelationId,
							childContent: child,
							itemImporter: {
								content: {
									edited: false,
									imported: false,
									real: null,
									fake: {}
								},
								relation: {
									allowImporting: false,
									imported: false,
									real: null,
									fake: {}
								}
							}
						}
					;

					parent.childs.push(dataRelation);
					log.summary.totalFakeRelationsCreated++;

					return dataRelation;
				}

				function createFakeStructureAndRelation(options, objectType, parent) {
					return createFakeRelation(parent, createFakeStructure(options, objectType));
				}

				function getRootStructure(frontNode, combineStandardCodeAndTitle) {
					var
						metaTag = _.find(frontNode.children, function(child) { return child.name === "iso-meta" || child.name === "reg-meta" }),
						rootStructure,
						title = "",
						description = "",
						standard = "",
						guidance = ""
					;

					if (metaTag && metaTag.children && metaTag.children.length > 0) {
						title = getTitle(_.find(metaTag.children, { "name": "title-wrap" })),
							description = getDescription(_.find(metaTag.children, { "name": "title-wrap" }));
						standard = getStandard();

						if (combineStandardCodeAndTitle)
							title = standard.code + " " + standard.number + " - " + title;

						rootStructure = {
							title: title,
							description: description,
							standardCode: standard.code,
							standardNumber: standard.number,
							guidance: guidance,
							hasImage: false,
							itemImporter: {
								content: {
									edited: false,
									imported: false,
									real: null,
									fake: {}
								},
								relation: {
									allowImporting: true,
									imported: false,
									real: null,
									fake: {}
								}
							}
						};
					}
					else console.warn("metaTag not found - could not get rootStructure in - ", frontNode);

					return rootStructure;

					function getTitle(titleWrap) {
						var intro, title = "";
						if (titleWrap.name === "title-wrap" && titleWrap.children && titleWrap.children.length > 0) {
							intro = _.find(titleWrap.children, { "name": "intro" });
							title = _.get(intro, ["children", "0", "value"])
						}

						if (!title || title === "")
							console.warn("Could not find title or title is empty in - ", metaTag);

						return title;
					}

					function getDescription(titleWrap) {
						var main, description = "";
						if (titleWrap.name === "title-wrap" && titleWrap.children && titleWrap.children.length > 0) {
							main = _.find(titleWrap.children, { "name": "main" });
							description = _.get(main, ["children", "0", "value"]);
						}

						if (!description || description === "")
							console.warn("Could not find description or description is empty in - ", metaTag);

						return description;
					}

					function getStandard() {
						var
							standardCode = "",
							docIdent = _.find(metaTag.children, { "name": "doc-ident" }),
							sdo = _.find(docIdent.children, { name: "sdo" }),

							standardNumber = "",
							stdIdent = _.find(metaTag.children, { "name": "std-ident" }),
							docNumber = _.find(stdIdent.children, { name: "doc-number" })
							;

						if (docIdent.children && docIdent.children.length > 0)
							standardCode = _.get(sdo, ["children", "0", "value"])

						if (stdIdent.children && stdIdent.children.length > 0)
							standardNumber = _.get(docNumber, ["children", "0", "value"])

						if (!standardCode || standardCode == "")
							console.warn("Could not find standardCode in - ", metaTag);

						if (!standardNumber || standardNumber == "")
							console.warn("Could not find standardNumber in - ", metaTag);

						return { code: standardCode, number: standardNumber };
					}
				}

				function getTextFromFirstChild(element) {
					var child;

					if (element.children && element.children.length > 0) {
						child = element.children[0];
						if (child.name === "" && child.type === "text")
							return child.value;
						else
							return undefined;
					}
					else
						return undefined;
				}

				function appendTextToParent(tag, parent, options) {
					var text = getTextFromFirstChild(tag);
					var subSectionQueue;
					var textTag;

					if (text && text !== "") {
						textTag = tag.children[0];

						if (parent && typeof parent.text === "string") {
							parent.text = formatTextEnding(_.trim(parent.text) + " " + _.trim(text));
							tag.children = _.pull(tag.children, textTag);
						}
						else if (options && options.previousListItem) {
							options.previousListItem.text = formatTextEnding(_.trim(options.previousListItem.text) + " " + _.trim(text));
							tag.children = _.pull(tag.children, textTag);
						}
						else {
							log.summary.discardedTags.push(tag);
						}
					}

					if (tag.children && tag.children.length > 0) {
						subSectionQueue = _.clone(tag.children);
						traverseTags(subSectionQueue.shift(), subSectionQueue, parent, options);
					}

					return text && text !== "" ? true : false;
				}

				function continueTraversingChildrenTags(tag, parent, options, tagToRemove) {
					var subSectionQueue;
					if (tag.children && tag.children.length > 0) {
						subSectionQueue = _.clone(tag.children);

						if (tagToRemove)
							tagToRemove.children = _.pull(tagToRemove.children, tagToRemove.children[0]);
						else
							tag.children = _.pull(tag.children, tag.children[0]);

						traverseTags(subSectionQueue.shift(), subSectionQueue, parent, options);
					}
				}

				function getTagSpecifications() {
					// Allowed tags are inserted into JSON object that will further be injected in WfObject.
					// Other tags will be logged and not injected.

					return {
						frontTags: [],
						bodyTags: [
							{
								tag: "sec",
								allow: true,
								customTagCheck: function (tag) {
									var secType = _.get(tag, "attributes['sec-type']");
									if (secType && (secType === "scope" || secType === "terms" || secType === "norm-refs"))
										return false;
									return true;
								},
								customTagFunc: function (tag, parent, options, order) {
									var
										subSectionQueue,
										newDataRelation,

										separatedTags = _.partition(tag.children, function (child) { return child.name == "label" || child.name === "title"; }),
										titleAndLabelTags = separatedTags[0],
										title = _.find(titleAndLabelTags, { name: "title" }),
										label = _.find(titleAndLabelTags, { name: "label" }),
										rest = separatedTags[1]
									;

									if (title && label) {
										newDataRelation = createFakeStructureAndRelation({
											title: getTextFromFirstChild(title),
											reference: getTextFromFirstChild(label),
										}, enums.objectType.structure, parent);

										newDataRelation.order = order;

										log.bodyTags.byType.sections++;
										log.bodyTags.byType.labels++;
										log.bodyTags.byType.titles++;

										//Remove title and label tags
										tag.children = _.pullAll(tag.children, titleAndLabelTags);

										if (rest && rest.length > 0) {
											subSectionQueue = _.clone(rest);
											//Remove rest of the items from the tag as they are going in the new loop with the subSectionQueue
											//If not removed the code will fail and duplicates will appear
											tag.children = _.pullAll(tag.children, rest);
											traverseTags(subSectionQueue.shift(), subSectionQueue, newDataRelation.childContent, options, 1);
										}
									}

									return tag;
								}
							},
							{
								tag: "p",
								allow: true,
								customTagCheck: undefined,
								customTagFunc: function (tag, parent, options) {
									var text = getTextFromFirstChild(tag);
									var subSectionQueue;
									var newDataRelation = undefined;

									if (text && text !== "") {
										if (options.appendText) {
											appendTextToParent(tag, parent, options);
											options.appendText = false;
										}
										else {
											newDataRelation = createFakeStructureAndRelation({ text: text }, enums.objectType.question, parent);
											log.bodyTags.byType.paragraphs++;
										}

										tag.children = _.pull(tag.children, tag.children[0]);
									}

									newDataRelation ? newDataRelation = newDataRelation.childContent : newDataRelation = parent;

									if (tag.children && tag.children.length > 0) {
										subSectionQueue = _.clone(tag.children);
										traverseTags(subSectionQueue.shift(), subSectionQueue, newDataRelation, options);
									}
								}
							},
							{
								tag: "list",
								allow: true,
								customTagCheck: undefined,
								customTagFunc: function (tag, parent, options) {
									var mainStructure = options && options.mainStructure ? options.mainStructure : undefined; // where all child items will be pushed (in listItem)
									var mainPText = options && options.mainPText ? options.mainPText : undefined;
									var tempMainPText;

									if (mainStructure && mainPText) { //This means that we are already in the list
										options.listTag = tag;
										tempMainPText = options.mainPText;
										options.mainPText = parent.text;

										removeMainPTextFromParent(parent);
										continueTraversingChildrenTags(tag, mainStructure, options);

										options.mainPText = tempMainPText;
									}
									else { // First list
										mainStructure = options.mainStructure = parent;
										mainPText = options.mainPText = getMainPTextFromParent(mainStructure);
										removeMainPTextFromParent(mainStructure);
										options.listTag = tag;
										continueTraversingChildrenTags(tag, parent, options);

										if (tag.parent.name !== "list-item") {
											mainPText = options.mainPText = undefined;
										}
										mainStructure = options.mainStructure = undefined;
									}

									log.bodyTags.byType.lists++;

									function getMainPTextFromParent(parent) {
										var lastChildFromParent;
										var pTagStructure;
										var mainPText = undefined;

										if (parent && parent.childs.length > 0) {
											lastChildFromParent = _.last(parent.childs);
											pTagStructure = lastChildFromParent.childContent;
											mainPText = pTagStructure.text;

											if (!mainPText && typeof mainPText !== "string")
												mainPText = undefined;
										}

										return mainPText;
									}

									function removeMainPTextFromParent(parent) {
										var mainPTextStructure;
										if (parent && parent.childs.length > 0) {
											mainPTextStructure = _.last(parent.childs);
											parent.childs = _.pull(parent.childs, mainPTextStructure);
										}
									}
								}
							},
							{
								tag: "list-item",
								allow: true,
								customTagCheck: undefined,
								customTagFunc: function (tag, parent, options) {
									if (options.mainPText) {
										var newStructure = createFakeStructure({ text: options.mainPText }, enums.objectType.question);
										options.appendText = true;
										continueTraversingChildrenTags(tag, newStructure, options, options.listTag);

										if (!options.mainStructure)
											options.mainStructure = parent;

										createFakeRelation(options.mainStructure, newStructure);
										log.bodyTags.byType.listItems++;
										options.appendText = undefined
									}
								}
							},
							{
								//Guidance
								tag: "non-normative-note",
								allow: true,
								customTagCheck: undefined,
								customTagFunc: function (tag, parent, options) {
									var subSectionQueue;
									var guidanceText = "";

									// tempStructure is not added to any relation; It is used only to gather the text that is in child tags
									var tempStructure = createFakeStructure({ text: guidanceText }, enums.objectType.structure);
									options.appendText = true;


									if (tag.children && tag.children.length > 0) {
										subSectionQueue = _.clone(tag.children);
										traverseTags(subSectionQueue.shift(), subSectionQueue, tempStructure, options);
									}

									if (parent.guidance && parent.guidance !== "") {
										parent.guidance = parent.guidance + "\n\n" + tempStructure.text;
									}
									else {
										parent.guidance = tempStructure.text;
										log.bodyTags.byType.guidelines++;
									}
								}
							},
							{
								tag: "label",
								allow: true,
								customTagCheck: undefined,
								customTagFunc: function (tag, parent, options) {
									var appended = appendTextToParent(tag, parent, options);
									if (appended)
										log.bodyTags.byType.labels++;
								}
							},
							{
								tag: "title",
								allow: true,
								customTagCheck: undefined,
								customTagFunc: function (tag, parent, options) {
									var appended = appendTextToParent(tag, parent, options);
									if (appended)
										log.bodyTags.byType.titles++;
								}
							},
							{
								tag: "xref",
								allow: true,
								customTagCheck: undefined,
								customTagFunc: function (tag, parent, options) {
									var appended = appendTextToParent(tag, parent, options);
									if (appended)
										log.bodyTags.byType.xrefs++;
								}
							},
							{
								tag: "std", // std represents a standard. It is a reference to another standard
								allow: true,
								customTagCheck: undefined,
								customTagFunc: function (tag, parent, options) {
									var appended = appendTextToParent(tag, parent, options);
									if (appended)
										log.bodyTags.byType.stds++;
								}
							},
							{
								tag: "std-ref",
								allow: true,
								customTagCheck: undefined,
								customTagFunc: function (tag, parent, options) {
									var appended = appendTextToParent(tag, parent, options);
									if (appended)
										log.bodyTags.byType.stdRefs++;
								}
							},
							{
								tag: "sup",
								allow: true,
								customTagCheck: undefined,
								customTagFunc: function (tag, parent, options) {
									var appended = appendTextToParent(tag, parent, options);
									if (appended)
										log.bodyTags.byType.sups++;
								}
							},
							{
								tag: "",
								allow: true,
								customTagCheck: undefined,
								customTagFunc: function (tag, parent, options) {
									// Append the remaining text
									if (parent && parent.text) {
										parent.text = formatTextEnding(parent.text + _.trim(tag.value));
										tag.parent.children = _.pull(tag.parent.children, tag);
									}
									else {
										tag.value = formatTextEnding(tag.value);
									}

									log.bodyTags.byType.unnamedTags++;
								}
							}
						],
						backTags: []
					}
				}

				function formatTextEnding(text) {
					// var 
					// 	texts = [ 
					// 		"interested parties that are relevant to the information security management system; and", 
					// 		"relevant to information security.", 
					// 		"ensuring that the resources needed for the information security management system are available;", 
					// 		"the capabilities, understood in terms of resources and knowledge (e.g. capital, time, people, processes, systems and technologies);", 
					// 		"supply chain, e.g. venue, product and service suppliers (including sponsors); this category could also include emergency services, fire, ambulance, etc.;",
					// 		"ensuring that the information security management system achieves its intended outcome(s);",
					// 		"Governing principles of sustainable development relating to event management" 
					// 	],
					// 	separator = " ";
					// ;

					// _.each(texts, function(text) { 
					// 	console.log(formatLastTwoWords(text.split(separator)));
					// });

					var
						separator = " ",
						text = text.split(separator),
						numberOfWords = text.length,
						lastWord = text[numberOfWords - 1],
						secondLastWord = text[numberOfWords - 2]
						;

					// Format endings
					if (secondLastWord)
						secondLastWord = formatSecondLastWord();

					lastWord = formatLastWord();

					replaceLastTwoWords(text);

					return text.join(separator);

					function formatSecondLastWord() {
						if (secondLastWord.endsWith(";"))
							secondLastWord = removeLastCharacter(secondLastWord);

						return secondLastWord;
					}

					function formatLastWord() {
						if (lastWord.endsWith(";"))
							lastWord = removeLastCharacter(lastWord);

						if (lastWord.endsWith("and"))
							lastWord = lastWord.replace("and", "");

						// if (!lastWord.endsWith("."))
						// 	lastWord = lastWord + ".";

						return lastWord;
					}

					function removeLastCharacter(word) {
						return word.substring(0, word.length - 1);
					}

					function replaceLastTwoWords(text) {
						text[numberOfWords - 1] = lastWord;
						text[numberOfWords - 2] = secondLastWord;
					}
				}
			}

			function injectStandardToJsData() {
				if (self.standardItems && !_.isEmpty(self.standardItems)) {
					self.injectedItems = wfObject.inject(_.cloneDeep(self.standardItems));
					self.rootItem = _.find(self.injectedItems, { isRootItem: true });
					if (self.rootItem && self.injectedItems && !_.isEmpty(self.injectedItems))
						self.jsonLoadedToJsData = true;
					else 
						console.error("Either rootItem or injectedItems are not defined!", self);
				} 
				else console.error("Could not load JSON to JsData! Make sure that you are injecting a json variable. - ", self.standardItems);
			}

			//-------Phase 3 - Previewing and importing JSON objects into database-------
			//---------------------------------------------------------------------------

			function createRealStructure(itemContent) {
				var deferred = $q.defer();
				var itemToCreate;

				if (itemContent) {
					itemToCreate = _.omit(itemContent, [ 'id', 'wfid', 'itemImporter', 'createdAt', 'creatorUserWfid', 'userId', 'organizationId' ]);
					itemToCreate = prepareItemForMultilingual(itemToCreate);

					dataOps.create(itemToCreate).then(function(createdItemContent) {
						deferred.resolve(createdItemContent);
					}, function(rejectedItemContent) {
						deferred.reject(rejectedItemContent);
						console.error("Could not create structure - ", rejectedItemContent);
					});
				}
				else console.error("Couldn't create structure. Item not found.")

				return deferred.promise;
			}

			function createRealRelation(parentWfObject, itemContent, order) {
				var deferred = $q.defer();

				var itemToCreate = _.omit(itemContent, [ 'createdAt' ]);
				var parentItemToCreate = _.omit(parentWfObject, [ 'createdAt' ]);
				
				dataOps.createSubItemRelation(parentItemToCreate, itemToCreate, { kind: enums.subItemsKind.children, order: order }).then(function(createdItemRelation) {
					if (order)
						createdItemRelation.order = order;
					deferred.resolve(createdItemRelation);
				}, function(rejectedItemRelation) {
					deferred.reject(rejectedItemRelation);
					console.error("Could not create relation - ", rejectedItemRelation);
				});

				return deferred.promise;
			}

			function findItemInJsDataAndXmlImporter(item) {
				var itemFromImporter = undefined;
				var itemFromJsData = undefined;
				var output = undefined;

				itemFromJsData = wfObject.get(item.wfid);

				if (itemFromJsData) {
					itemFromImporter = _.find(self.standardItems, { wfcid: item.wfid });

					if (itemFromImporter) {
						output = { itemFromJsData: itemFromJsData, itemFromImporter: itemFromImporter };
					}
					else {
						console.error("Couldn't find an item in JsData");
					}
				}
				else {
					console.error("Couldn't find an item in JsData");
				}

				return output;
			}

			function prepareItemForMultilingual(itemContent) {
				var title = itemContent.title;
				var text = itemContent.text;
				var guidance = itemContent.guidance;
				var description = itemContent.description;

				if (title && title.length !== 0)
					title = { en: title }

				if (text && text.length !== 0)
					text = { en: text }

				if (guidance && guidance.length !== 0)
					guidance = { en: guidance }

				if (description && description.length !== 0)
					description = { en: description }

				_.assign(itemContent, {
					title: title ? title : undefined,
					text: text ? text : undefined,
					guidance: guidance ? guidance : undefined,
					description: description ? description : undefined 
				});

				return itemContent;
			}
		}
	}
})();
