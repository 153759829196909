import React, { useState, useRef } from 'react'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const DropdownMenu = ({ id = 'dropdown-menu', IconComponent, IconButtonProps, IconProps, children, onClick, item, ...rest }) => {
  const [anchorEl, setAnchorEl] = useState()
  const ref_checkIfMounted = useRef(null)
  const open = Boolean(anchorEl)

  function onIconButtonClick(event) {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    onClick && onClick(event, item)
  }

  function handleClose(event) {
    if (event) {
      event.stopPropagation()
    }
    if (ref_checkIfMounted.current) {
      setAnchorEl(null)
    }
  }

  function handleMenuItemClick(callback = () => { }) {
    return async (event) => {
      event.stopPropagation()
      const shouldCloseMenu = await callback(event, item)
      if (shouldCloseMenu !== false) {
        handleClose()
      }
    }
  }

  return (
    <React.Fragment>
      <IconButton
        aria-label="Menu"
        aria-owns={open ? id : undefined}
        aria-haspopup="true"
        onClick={onIconButtonClick}
        {...IconButtonProps}
      >
        {IconComponent || <MoreVertIcon {...IconProps} />}
      </IconButton>

      <Menu
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        ref={ref_checkIfMounted}
        {...rest}
      >
        {
          React.Children.map(children, (child) => {
            if (child.type !== MenuItem) {
              return child
            }

            return React.cloneElement(child, {
              onClick: handleMenuItemClick(child.props.onClick),
            })
          })
        }
      </Menu>
    </React.Fragment>
  )
}

export default DropdownMenu
