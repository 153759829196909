// @flow
import * as React from 'react'
import type { Item } from '@worldfavor/types'
import ItemSummary from '../ItemSummary'
import ItemStatistics from './ItemStatistics'
import AnswerValue from './AnswerValue'

import '@worldfavor/less/components/question-container.less'
import { FormattedMessage } from 'react-intl'

type Props = {
  questions: Array<Item>,
  statistics: {
    percentagePerAnswerTypePerQuestion: { [string]: { [string]: number } },
    totalPercentagePerAnswerType: { [string]: number },
  },
  forceShowPercentage?: boolean,
  single?: boolean,
  style?: { [string]: any }
}

class QuestionBox extends React.PureComponent<Props> {

  static defaultProps = {
    statistics: {},
  }

  render() {
    const {
      questions,
      statistics: {
        percentagePerAnswerTypePerQuestion,
        totalPercentagePerAnswerType,
      },
      forceShowPercentage,
      single,
      style,
    } = this.props
    const question = single && questions[0]
    const statisticsProps = Object.keys(totalPercentagePerAnswerType || {})
      .map(answerType => ({
        value: totalPercentagePerAnswerType[answerType],
        type: answerType,
      }))

    // hide statistics if they are all 100%
    const showGlobalStatistics = (percentagePerAnswerTypePerQuestion && Object.keys(percentagePerAnswerTypePerQuestion)
      .map(key => Object.values(percentagePerAnswerTypePerQuestion[key])
        .filter((value: number) => value > 0 && value < 100))
      .filter(array => array.length > 0).length > 0)
      || forceShowPercentage

    if (single) {
      return (
        <>
          <div style={{ whiteSpace: 'pre-wrap', paddingRight: 100 }}>{question.text}</div>
          {statisticsProps.length
            ? (
              <ItemStatistics
                values={statisticsProps}
                forceShowPercentage={true}
              />)
            : (<div style={{ fontWeight: 'bold', fontSize: 15, alignContent: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', flex: 1, margin: 'auto' }}><div><FormattedMessage id="general.noReportedData" /></div></div>)
          }
        </>
      )
    }

    return (
      <div style={style}>
        {
          showGlobalStatistics && (
            <ItemStatistics
              values={statisticsProps}
              forceShowPercentage={forceShowPercentage}
            />
          )
        }
        {
          questions.map(item => (
            <div
              key={`question-row-${item.wfid}`}
              style={{ display: 'flex', flex: 1, paddingTop: 5, paddingBottom: 10 }}
            >
              <ItemSummary item={item} style={{ flex: 0.6 }} />
              <div style={{ flex: 0.4, display: 'flex', flexWrap: 'wrap' }}>
                {
                  Object.keys((percentagePerAnswerTypePerQuestion
                    && percentagePerAnswerTypePerQuestion[item.wfid]) || {})
                    .map(answerType => (
                      <AnswerValue
                        key={`answer-${item.wfid}-type-${answerType}`}
                        value={percentagePerAnswerTypePerQuestion[item.wfid][answerType]}
                        type={answerType}
                        style={{ flex: 1 }}
                        forceShowPercentage={forceShowPercentage}
                        small
                      />
                    ))
                }
              </div>
            </div>
          ))
        }
      </div>
    )
  }
}

export default QuestionBox
