import * as enums from '@worldfavor/constants/enums'

(function() {
'use strict';

	angular
		.module('wf.common')
		.component('wfHierarchicalItem', {
			templateUrl: [ "$attrs", function ($attrs) {
				if ("orgAccessAdmin" in $attrs)
					return "scripts/wf/hierarchical/wfHierarchicalItemOrgAccess.component.html";
				else if ("publicLayout" in $attrs)
					return "scripts/wf/hierarchical/wfHierarchicalItemPublicLayout.component.html";
				else if ("consolidate" in $attrs)
					return "scripts/wf/hierarchical/wfHierarchicalItemConsolidate.component.html";
				else
				 	return "scripts/wf/hierarchical/wfHierarchicalItem.component.html";
			}],
			require: "^^HierarchicalController",
			controller: wfHierarchicalItemController,
			controllerAs: "vm",
			bindings: {
				item: '=',
				numberPath: "=",
				hierVm: "=",
				belongsTo: "="
			},
			transclude: {
				'transcludeSide': '?transcludeSide'
			}
		});

	wfHierarchicalItemController.$inject = [ "$scope", "$timeout", "$element", "$rootScope", "$state", "requirements", "$transclude", "$compile", "$translate", "modalService" ];
	function wfHierarchicalItemController($scope, $timeout, $element, $rootScope, $state, requirements, $transclude, $compile, $translate, modal) {
		var
			vm = this,
			itemType = vm.item.type,
			itemConditions,
			item = vm.item,
			contextParentWfids,
			intersectionSettings = vm.hierVm.intersectionSettings, // { organizationId: vm.hierVm.organizationId },
			hierVm = vm.hierVm
		;

		////////////////

		vm.enums = enums;
		vm._scope = $scope;
		$scope.$rootScope = $rootScope;


		$scope.updateMetadata = function () {
			if ($scope.mdVm) {
				$scope.mdVm.updateNumbers();
			}
		};

		// if (vm.item.type === enums.objectType.structure) {
		// 	vm.childs = _.sortBy(vm.item.childContent.childs, "order");
		// }

		vm.$onInit = function() { };
		vm.$onChanges = function(changesObj) { };
		vm.$onDestory = function() { };

		vm.item.$scope = $scope;
		vm.item.$element = $element;
		$scope.contentCompiler = {};
		$scope.handleRequirementsSettings = handleRequirementsSettings;

		if (vm.orgAccessAdminMode) {
			initOrgAccessAdminMode();
		}

		if (vm.hierVm.consolidate) {
			if (vm.item.type === enums.objectType.question || vm.item.type === enums.objectType.measure || (vm.item.type === enums.objectType.structure && (_.get(vm.item.content, "conditions.pickerSettings") || _.get(vm.item.content, "conditions.objectTypes") || _.get(vm.item.content, "conditions.uiSettings.includeStatisticsInAggregated")))
				|| (_.get(item.dataRelation, "settings.attachObjectTypes") || _.get(item.dataRelation, "originalRelation.settings.attachObjectTypes")))
				vm.expandEnabled = true;
			else
				vm.expandEnabled = false;
		}

		// If intersected in front-end then the originalRelation is on the itemComposite
		if (item.originalRelation && item.originalRelation.settings && item.originalRelation.settings.contextParentWfids) {
			intersectionSettings.contextParents = item.originalRelation.settings.contextParentWfids;
		}
		// If intersected in back-end then the originalRelation is on the item.relation (that is a virtualDataRelation)
		else if (item.dataRelation.type === enums.objectType.virtualDataRelation && typeof item.dataRelation.originalRelationWfid === "string" && item.dataRelation.originalRelation && item.dataRelation.originalRelation.settings) {
			intersectionSettings.contextParents = item.dataRelation.originalRelation.settings.contextParentWfids;
		}
		// Else, use the settings object on item.relation
		else if (item.dataRelation.settings) {
			intersectionSettings.contextParents = item.dataRelation.settings.contextParentWfids;
		}

		itemConditions = vm.item.content.conditions;

		_.assign(vm, {
			$element: $element,
			isQuestionType: itemType == enums.objectType.question,
			isMeasureType: itemType === enums.objectType.measure || itemType === enums.objectType.relativeMeasure,
			isParameterType: itemType == enums.objectType.parameter,
			isFindingType: itemType == enums.objectType.finding,
			isAnswerableType: vm.item.content.isOfAnswerableType(),
			isUserDataType: isUserDataType(),
			isSystemDataType: isSystemDataType(),
			userCanAddData: itemType == enums.objectType.structure && itemConditions && ("dataRelationByUser" in itemConditions),
			intersectionSettings: item.intersectionSettings = intersectionSettings,
			expandText: $translate.instant('Expand'),
			collapseText: $translate.instant('Collapse'),
			setItemPadding: setItemPadding
		});

		vm.showNumbering = vm.hierVm.showItemNumbering && !vm.isUserDataType && item.type !== enums.objectType.country

		if (hierVm.itemSettings) {
			if (hierVm.itemSettings.byType) {
				if (itemType in hierVm.itemSettings.byType) {
					vm.settings = hierVm.itemSettings.byType[itemType];
				}
				else if ("common" in hierVm.itemSettings)
					vm.settings = hierVm.itemSettings.common;
			}
			else
				vm.settings = hierVm.itemSettings;
		}

		if (vm.hierVm.showRequirements) {
			handleRequirementsSettings();
		}

		if (vm.settings.showDropdown)
			vm.showDropdown = true;

		if (vm.settings.showDropdownByType) {
			if (typeof vm.settings.showDropdownByType["userData"] !== "undefined" && vm.isUserDataType)
				vm.showDropdown = vm.settings.showDropdownByType["userData"];
			if (typeof vm.settings.showDropdownByType["systemData"] !== "undefined" && vm.isSystemDataType)
				vm.showDropdown = vm.settings.showDropdownByType["systemData"];
			if (typeof vm.settings.showDropdownByType[item.type] !== "undefined")
				vm.showDropdown = vm.settings.showDropdownByType[item.type];
		}

		if (vm.hierVm.influenceMode) {
			intersectionSettings.networkId = vm.hierVm.influence.channelId;
			intersectionSettings.influence = vm.hierVm.influence;

			if (vm.item.type === enums.objectType.finding) {
				if (vm.hierVm.influence.organizationId === vm.hierVm.authOrgId && vm.item.content.targetOrganizationId === vm.hierVm.authOrgId)
					vm.showDropdown = false;
				else if (vm.hierVm.influence.creatorOrganizationId === vm.hierVm.authOrgId && vm.item.content.creatorOrganizationId === vm.hierVm.authOrgId)
					vm.showDropdown = true;
			}


			if (!intersectionSettings.contextParents && vm.hierVm.influence.contextParentWfids)
				intersectionSettings.contextParents = vm.hierVm.influence.contextParentWfids.split(",");

			if (!vm.item.relatedContent) {
				vm.item.relatedContent = _.chain(vm.item.content.relatedContent).filter(function (relation) {
					return !!relation.childContent.url;
				}).orderBy([ x => x.childContent.title.toLowerCase() ]).value();

			}

			if (vm.hierVm.influence.isInternal) {
				$scope.$on("newAnswerCreated", function () {
					if ($scope.mdVm)
						$scope.mdVm.expandObjectViewer();
				});
			}

			if (!vm.hierVm.isWorkMode && !vm.hierVm.orgAccessAdminMode && !vm.hierVm.consolidate
				&& item.type !== enums.objectType.finding
				&& (vm.isUserDataType || item.type === enums.objectType.country || vm.hierVm.influence.organizationId === vm.item.dataRelation.organizationId)) {

				vm.settings.showMetadata = true;
				vm.settings.metadataConfig = {
					limitRelationKinds: [],
					showDetailedViewLink: false,
					showEmbeddedObjectViewer: false,
				}
			}
		}

		if (vm.item.type === enums.objectType.finding && vm.hierVm.waitForFindingsToLoad)
			vm.hierVm.findingsToLoad.push(vm.item.content);

		vm.showSystemDataDropdown = vm.showDropdown && vm.settings.showDropdown && !vm.isParameterType && (!vm.hierVm.influenceMode || (vm.settings.showDropdown && vm.hierVm.isWorkMode)) && !vm.hierVm.orgAccessAdminMode;
		vm.showUserDataDropdown = vm.showDropdown && !vm.isSystemDataType && vm.hierVm.isWorkMode && !vm.hierVm.orgAccessAdminMode && !vm.hierVm.consolidate;

		if (vm.showDropdown) {
			if (vm.isUserDataType) {
				vm.dropdownActions = [
					{ text: 'Edit', icon: 'fa fa-pencil', action: 'vm.hierVm.editItem(vm.item)' },
					'visibility',
					'-',
					{ text: 'DeleteTag', icon: 'fa fa-times', action: 'vm.hierVm.deleteRelation(vm.item)', cssClass: 'removal' },
					{ text: 'Delete', icon: 'fa fa-trash', action: 'vm.hierVm.deleteItem(vm.item)', cssClass: 'removal' }
				];
			}
			else if (vm.isSystemDataType) {
				vm.dropdownActions = [
					{ text: 'OpenObjectViewer', icon: 'fa fa-external-link', action: 'vm.hierVm.openObjectViewer(vm.item)' },
					'-',
					{ text: "AttachInformation", icon:"fa fa-paperclip", subActions: [
						{ text: 'Activity', icon: 'fa fa-calendar-check-o', action: 'vm.hierVm.openModal_attachInformation(vm.item, 15)' },
						{ text: 'Document', icon: 'fa fa-file', action: 'vm.hierVm.openModal_attachInformation(vm.item, 18)' },
						{ text: 'Statement', icon: 'fa fa-quote-right', action: 'vm.hierVm.openModal_attachInformation(vm.item, 44)' },
						{ text: 'Link', icon: 'fa fa-link', action: 'vm.hierVm.openModal_attachInformation(vm.item, 50)' },
						{ text: 'Embed', icon: 'fa fa-play-circle', action: 'vm.hierVm.openModal_attachInformation(vm.item, 90)' }
					] },
					{ text: "Materiality", icon: "fa fa-plus", action: 'vm.hierVm.openModal_categorizeTo_materiality(vm.item)' },
					{ text: "Deviation", icon: "fa fa-plus", action: 'vm.hierVm.openModal_categorizeTo_deviation(vm.item)' },
					{ text: "Scope", icon: "fa fa-plus", action: 'vm.hierVm.openModal_categorizeTo_scope(vm.item)' },
					{ text: "CustomFrameworks", icon: "fa fa-plus", action: 'vm.hierVm.openModal_categorizeTo_customFrameworks(vm.item)', condition: vm.hierVm.orgHasCustomFrameworks },
					{ text: "Categories", icon: "fa fa-plus", action: 'vm.hierVm.openModal_categorizeTo_customCategories(vm.item)', condition: vm.hierVm.showCustomCategories },
					{ text: "SendTo", icon: "fa fa-share", action: 'vm.hierVm.openModal_sendTo(vm.item)' },
					{ text: "AssignTask", icon: "fa fa-share", action: 'vm.hierVm.openModal_assignTask(vm.item)', condition: vm.hierVm.orgHasInternalValueChain },
					{ text: "SetGoal", icon: "fa fa-check", action: 'vm.hierVm.openModal_setRequirement(vm.item)' }
				];
			}
			else {
				vm.dropdownActions = [
					{ text: 'DeleteTag', icon: 'fa fa-times', action: 'vm.hierVm.deleteRelation(vm.item)', cssClass: 'removal' },
				];
			}

			if (item.type === enums.objectType.measure && vm.hierVm.intersectionSourceStructure) {
				vm.dropdownActions.push(
					{ text: 'modules.valueChain.objectSettings.measurePeriodSettings.label', icon: 'fa fa-calendar', action: 'vm.hierVm.setMeasureReportingPeriod(vm.item)' }
				);
			}

			if (item.type === enums.objectType.relativeMeasure) {
				vm.dropdownActions = _.pullAllBy(vm.dropdownActions, [
						{ text: "SendTo", icon: "fa fa-share", action: 'vm.hierVm.openModal_sendTo(vm.item)' },
						{ text: "AssignTask", icon: "fa fa-share", action: 'vm.hierVm.openModal_assignTask(vm.item)', condition: vm.hierVm.orgHasInternalValueChain },
						{ text: "SetGoal", icon: "fa fa-check", action: 'vm.hierVm.openModal_setRequirement(vm.item)' }
					], "text"
				);
			}

			if (vm.hierVm.isStandardOrGuideline) {

				if (item.type === enums.objectType.structure) {
					Array.prototype.push.apply(vm.dropdownActions, [
						{ text: "modules.hierarchical.dropdownActions.extendWithOwnItems", icon:"fa fa-plus", subActions: [
							{ text: 'Category', icon: 'fa fa-plus', action: 'vm.hierVm.openModal_addOwnChild(vm.item, 71)' },
							{ text: 'Question', icon: 'fa fa-plus', action: 'vm.hierVm.openModal_addOwnChild(vm.item, 11)' },
							{ text: 'Measure', icon: 'fa fa-plus', action: 'vm.hierVm.openModal_addOwnChild(vm.item, 21)' }
						] }
					]);
				}
				// else if () { // If question or measure

				// }

				if (item.content.creatorOrganizationId === vm.hierVm.organizationId) {
					if (item.type === enums.objectType.structure || item.type === enums.objectType.question || item.type === enums.objectType.measure) {
						vm.dropdownActions.unshift(
							{ text: 'Edit', icon: 'fa fa-pencil', action: 'vm.hierVm.editItem(vm.item)' },
							{ text: 'DeleteTag', icon: 'fa fa-times', action: 'vm.hierVm.deleteRelation(vm.item)', cssClass: 'removal' },
							// { text: 'Delete', icon: 'fa fa-trash', action: 'vm.hierVm.deleteItem(vm.item)', cssClass: 'removal' },
							'-'
						);
					}
				}
			}
		}

		if (vm.hierVm.showRequirements && (!vm.isUserDataType || item.type === enums.objectType.finding) && !vm.hierVm.consolidate) {
			vm.showRequirement = true;
		}

		if (vm.item.type == enums.objectType.question && !vm.hierVm.consolidate) {
			vm.showDataAnswering = vm.item.dataRelation.settings && vm.item.dataRelation.settings.attachObjectTypes
		}
		else if (vm.hierVm.showTypeSpecificTools && !vm.hierVm.consolidate && (vm.item.type === enums.objectType.structure || vm.item.type === enums.objectType.organization)
			&& vm.hierVm.isWorkMode && (
				(vm.requirement && (vm.requirement.rule === enums.requirementRule.anyValue || vm.requirement.rule === enums.requirementRule.manual)) ||
				((item.dataRelation.settings && vm.item.dataRelation.settings.attachObjectTypes) ||
				(item.dataRelation.originalRelation && item.dataRelation.originalRelation.settings && item.dataRelation.originalRelation.settings.attachObjectTypes))
				|| (itemConditions && itemConditions.pickerSettings) || _.get(item.parent, "content.conditions.subItemsSettings.pickerSettings")
				|| (itemConditions && ("dataRelationByUser" in itemConditions) && itemConditions.objectTypes))
		) {
			vm.showDataAnswering = true;
		}

		handleTransclusion();

		$scope.$on("itemToolsActionExecuted", function ($event, operation, item) {
			if (operation === "delete") {
				if (_.remove(vm.childs, { wfid: item.wfid }).length) {
					$timeout();

					vm.packageVm.reorder(vm.childs).then(function () {
					});
				}
			}
		});

		$scope.$on("dropdownActionExecuted", function ($event, operation, item) {
			if (operation === 'attach') {
				$scope.$broadcast("requirementChanged");
			}
		});

		function isUserDataType() {
			switch (itemType) {
				case enums.objectType.orgActivity:
					return true;
				case enums.objectType.orgDocument:
					return true;
				case enums.objectType.link:
					return true;
				case enums.objectType.statement:
					return true;
				case enums.objectType.questionAnswer:
					return true;
				case enums.objectType.measureAnswer:
					return true;
				case enums.objectType.embed:
					return true;
				case enums.objectType.location:
					return true;
				case enums.objectType.finding:
					return true;
				case enums.objectType.dateItem:
					return true;
				case enums.objectType.person:
					return true;
			}
		}

		function isSystemDataType() {
			switch (itemType) {
				case enums.objectType.structure:
				case enums.objectType.question:
				case enums.objectType.measure:
				case enums.objectType.relativeMeasure:
				case enums.objectType.parameter:
					return true;
			}
		}

		function initOrgAccessAdminMode() {
			_.assign(vm, {
				toggleOrgAccess: toggleOrgAccess,
				canAccess: item.content.accessTag.canAccess
			});
		}

		function toggleOrgAccess() {
			if (vm.canAccess) {

			}
		}

		function handleRequirementsSettings() {
			var
				measurePeriodSettings
			;

			$scope.wfRequirementControl = {};

			if (vm.item.type === enums.objectType.measure) {
				measurePeriodSettings = _.get(vm.item.dataRelation.settings, "measurePeriodSettings") || _.get(vm.item.dataRelation.originalRelation, "settings.measurePeriodSettings")

				if (measurePeriodSettings) {
					vm.itemRequirementSettings = requirements.getMeasureSettingsFromPeriodSettings(null, vm.hierVm.uiMode, vm.hierVm.$scope, measurePeriodSettings)

					if (!vm.hierVm.influenceMode || (vm.hierVm.influence && vm.hierVm.influence.isInternal)) {
						vm.itemRequirementSettings.hideAdminTools = true;
						vm.itemRequirementSettings.showFulfillmentIndicator = true;
						vm.itemRequirementSettings.enableIndicatorPopover = false;
					}
				}
				else {
					vm.itemRequirementSettings = vm.hierVm.requirements.settingsByType[enums.objectType.measure];
				}
			}
			else {
				vm.itemRequirementSettings = vm.hierVm.requirements.settingsByType[vm.item.type + (vm.item.type === enums.objectType.structure ? "_task" : 0)];
			}

			vm.requirement = requirements.getActualRequirement({
				itemRelation: item.dataRelation,
				itemComposite: item,
				organizationId: vm.hierVm.organizationId
			});

			if (vm.itemRequirementSettings) {
				vm.itemRequirementSettings.uiMode = vm.hierVm.requirementUiMode;
				vm.itemRequirementSettings.forOrganizationId = vm.hierVm.organizationId;
				vm.itemRequirementSettings.showManualFulfillmentButton = !vm.isFindingType;

				if (vm.settings.hideRequirementTexts)
					vm.itemRequirementSettings.hideRequirementTexts = true;
			}
		}

		function handleTransclusion() {
			var
				hierVm = vm.hierVm,
				elementClone
			;

			if (hierVm.transcludeItemSide) {
				elementClone = hierVm.transcludeItemSide.elementClone.cloneNode(true);

				$scope.outerVm = hierVm.transcludeItemSide.outerVm;
				$scope.hierItemVm = vm;
				$element.find("div.transclude-side").append(elementClone.childNodes);
			}
		}

		function setItemPadding() {
			var depth = vm.item.depth;
			var isNumberingShown = vm.showNumbering;
			var pTop, pRight, pBottom, pLeft;

			if (depth === 0)
				pLeft = 20;
			else
				pLeft = vm.item.depth * 30;

			if (!vm.settings.showMetadata) {
				pTop = 22;
				pBottom = 26;
			}

			if (isNumberingShown && !vm.item.numbering)
				pLeft = pLeft + 30;

			return {
				"paddingTop": pTop,
				"paddingRight": pRight,
				"paddingBottom": pBottom,
				"paddingLeft": pLeft,
			}
		}
	}
})();
