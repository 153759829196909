import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfItem', wfItem);

	wfItem.$inject = ['RecursionHelper'];

	function wfItem(RecursionHelper) {
		var directive = {
			restrict: 'E',
			//require: '^wfCollection',
			//scope: {
			//	'item': '=',
			//	'collection': '='
			//},
			templateUrl: "scripts/wf/item/item.directive.html",
			// template: "nice",
			//template: "<div ng-if='actualItem' ng-include=\"'scripts/wf/item/item-template' + (vm.templateId) + '.directive.html'\"></div>",
			controller: ['$scope','$element','dataModeller','$state','$attrs','$parse','dataOperationsService','modalService','wfObject','dataQuery','wfProcessTool','moment','$translate','$sce','$stateParams','responsiveUi','$timeout', wfItemController],
			controllerAs: 'vm',
			compile: compile
		};
		return directive;

		function compile(element) {
				// To support nesting of the directive within itself
			return RecursionHelper.compile(element, function(scope, iElement, iAttrs, controller, transcludeFn) {
				// Define your normal link function here.
				// Alternative: instead of passing a function,
				// you can also pass an object with
				// a 'pre'- and 'post'-link function.

				if (scope.emitWhenItemLinked) {
					setTimeout(function () {
						scope.$emit("itemLinked", scope.item);
					}, 500);
				}
			});
		}
	}

	function wfItemController($scope, $element, dataModeller, $state, $attrs, $parse, dataOps, modalService, wfObject, dataQuery, wfProcessTool, moment, $translate, $sce, $stateParams, responsiveUi, $timeout) {
		/* jshint validthis: true */
		var
			vm = this,
			item = vm.item = ($scope.item || $scope.vm.item),
			itemDataRelation,
			itemChildContent,
			itemParentContent,
			collection = $scope.collection || {},
			actualItem,
			itemLoadWatch,
			contextAttrObserve,
			itemAttrLoadObserve,
			newContext,
			chunkSize = 10
		;

		// Info about $root:
		// $scope.$root holds a reference to the $rootScope.
		// Unfortunately, there IS a difference between using $scope.$root and using $rootScope:
		//   1. When $scope IS the root, its $root property is null
		//   2. $scope.$root is only assigned on isolate scopes: https://github.com/angular/angular.js/blob/v1.3.6/src/ng/rootScope.js#L204

		$scope.authUser      = $scope.$root.authUser;
		$scope.dataModeller  = $scope.dataModeller || dataModeller;
		$scope.wfObject      = $scope.wfObject || wfObject;
		$scope.dataOps       = $scope.dataOps || dataOps;
		$scope.modal         = $scope.modal || modalService;
		$scope.dataQuery     = $scope.dataQuery || dataQuery;
		$scope._             = $scope._ || _;
		$scope.wfProcessTool = $scope.wfProcessTool || wfProcessTool;
		$scope.moment        = $scope.moment || moment;
		$scope.$translate    = $scope.$translate || $translate;
		$scope.$sce          = $scope.$sce || $sce;
		$scope.enums         = $scope.enums || enums;
		$scope.$stateParams  = $state.params; // $stateParams is empty when assigning props to $stateParams in route state controller in app.module.js but $state.params seems to contain the values

		vm.getTagger = getTagger;
		//if ($attrs.context)
		//{
		//	contextAttrObserve = $attrs.$observe("context", function (val) {
		//		console.log($scope);
		//		vm.context = $parse(val)($scope)
		//		console.log(vm.context);
		//	})
		//}
		//else

		if ($scope.vm.context)
			vm.context = $scope.vm.context;
		else if ($scope.$parent.vm && $scope.$parent.vm.context)
			vm.context = $scope.$parent.vm.context;

		if ($attrs.context)
			newContext = $parse($attrs.context)($scope);
		else if ($scope.context)
			newContext = $scope.context;

		// console.log(vm.context);
		if (newContext)
			vm.context = _.assign(vm.context ? _.clone(vm.context) : {}, newContext);

		vm.hasChildren = hasChildren;
		vm.hasParents = hasParents;
		vm.hasVerifications = hasVerifications;
		vm.hasRelatedContent = hasRelatedContent;
		vm.hasRelatedContentByUser = hasRelatedContentByUser;
		vm.subListSettings = null;

		vm.createAttacher = createAttacher;

		//vm.showChildren = hasSubItemsOfKind('children', item);
		vm.showParents = false;
		vm.showVerifications = false;
		vm.showRelatedContent = false;
		vm.showRelatedContentByUser = false;

		vm.gotoItem = gotoItem;

		if ($attrs.gotoState) {
			vm.gotoState = $scope.$eval($attrs.gotoState); // Value of attrs.gotoState must be { name: "", params: {} }
		}

		if (vm.context && vm.context.influence) {
			vm.influence = vm.context.influence;
		}

		//vm.templateId = 3; // Default template
		vm.cssClass = "";

		if (item)
			activate();
		else
		{
			itemLoadWatch = $scope.$watch("item", function () {
				item = vm.item = ($scope.item || $scope.vm.item);
				itemLoadWatch(); // Destroy watch
				activate();
			});
		}

		$scope.$on('$destroy', function () {
			if (itemLoadWatch)
				itemLoadWatch();
			if (itemAttrLoadObserve)
				itemAttrLoadObserve();
			if (contextAttrObserve)
				contextAttrObserve();
		});

		//$scope.chartCount = null

		////vm.setChartCount = function (value) {
		////	vm.chartCount = value;
		////}

		////vm.getChartCount = function () {
		////	return vm.chartCount;
		////}

		//$scope.chartData = [{color:'#86C22B',value:$scope.chartCount},{color:'#F3F3F3',value:50}];

		//$scope.$watch('chartCount', function () {
		//	console.log(arguments);
		//})



		function activate() {
			if ($attrs.sublistSettings)
			{
				vm.subListSettings = $scope.$eval($attrs.sublistSettings);
			}

			if ($attrs.depth)
			{
				vm.depth = $scope.$eval($attrs.depth);
			}

			if ($attrs.item)
			{
				// This means thay a custom dot notated path to object was specified in the "item" attr
				// like this for example: item="item.childContent.children[0]"

				vm.item = {};
				itemAttrLoadObserve = $attrs.$observe('item', function (itemPath) {
					var subItem = $parse(itemPath)($scope);
					// console.log(itemPath, subItem, $scope.item.childContent;
					itemAttrLoadObserve();

					if (subItem)
					{
						item = vm.item = $scope.item = subItem;
						if (vm.influence) {
							vm.itemChildContent = itemChildContent = item;
						}
						else {
							if (item.type === enums.objectType.dataRelation || item.type === enums.objectType.virtualDataRelation || item.type === enums.objectType.visibilityTag) {
								itemDataRelation = item;
								vm.itemChildContent = itemChildContent = item.childContent;
							}
							else
								vm.itemChildContent = itemChildContent = item;
						}

						if ($attrs.sublistSettings)
							vm.subListSettings = $scope.$eval($attrs.sublistSettings);

						//console.log(vm.subListSettings);
						realActivate();
					}
					else if (itemPath.indexOf('item.childContent.childs') === 0 && (itemDataRelation = item) && (vm.itemChildContent = itemChildContent = itemDataRelation.childContent) && itemDataRelation.settings && itemDataRelation.settings.childrenLoadDepth)
					{
						// If the subItem is not defined and the item settings includes
						// childrenLoadDepth (which means that children up to a certain level should be loaded)
						// then we trigger the loading of children and then try to parse the "item" attribute again.

						dataOps.getSubItems(itemChildContent, 1).then(function () {
							item = itemDataRelation = vm.item = $parse(itemPath)($scope);
							if ($attrs.sublistSettings)
								vm.subListSettings = $scope.$eval($attrs.sublistSettings);

							//console.log(vm.subListSettings);

							realActivate();
						});
					}
				});
			}
			else {
				if (item.type === enums.objectType.dataRelation || item.type === enums.objectType.virtualDataRelation || item.type === enums.objectType.visibilityTag || (itemChildContent = item.childContent)) {
					itemDataRelation = item;
					vm.itemChildContent = itemChildContent = item.childContent;
				}
				else
					vm.itemChildContent = itemChildContent = item;

				realActivate();
			}

			function realActivate()
			{
				var templateIdAttrValue;
				// console.log(itemChildContent.conditions)

				if ($attrs.templateId && (templateIdAttrValue = $parse($attrs.templateId)($scope)))
					// If templateId attr is present and has a value
				{
					vm.templateId = templateIdAttrValue;
				}
				else if (vm.context && vm.context.templateId)
				{
					vm.templateId = vm.context.templateId;
				}
				else if (vm.context && vm.context.settings)
				{
					if (vm.context.settings.templateId)
					{
						vm.templateId = vm.context.settings.templateId;

						if (vm.context.settings.childrenTemplateId)
							vm.context.settings.templateId = null;
					}
					else if (vm.context.settings.childrenTemplateId)
					{
						vm.templateId = vm.context.settings.childrenTemplateId;
					}
				}
				else if (collection.settings && collection.settings.childrenSettings && collection.settings.childrenSettings) // If template is defined as a setting on the collection (which comes from the collection's parent)
				{
					vm.templateId = collection.settings.childrenSettings.templateId;
				}
				else if (collection.settings && collection.settings.childrenTemplateId) // If template is defined as a setting on the collection (which comes from the collection's parent)
				{
					vm.templateId = collection.settings.childrenTemplateId;
				}
				else if (item && item.settings && item.settings.templateId) // If the item is a DataRelation with childContent and settings property
				{
					vm.templateId = item.settings.templateId;
					vm.cssClass = item.settings.cssClass;
				}
				else if (itemDataRelation && (itemParentContent = itemDataRelation.parentContent) && itemParentContent.conditions && itemParentContent.conditions.childrenTemplateId) // If the item is a DataRelation with childContent and settings property
				{
					vm.templateId = itemParentContent.conditions.childrenTemplateId;
				}
				else if (itemChildContent && itemChildContent.conditions && itemChildContent.conditions.templateId) // If the item is a DataRelation with childContent and settings property
				{
					vm.templateId = itemChildContent.conditions.templateId;
					vm.cssClass = itemChildContent.conditions.cssClass;
				}

				if (!vm.templateId) // Final check to see if template is undefined
				{
					if ("templateIdFallback" in $attrs) {
						vm.templateId = $parse($attrs.templateIdFallback)($scope);
					}
					else
						vm.templateId = 3;
				}

				if (vm.templateId === 59) { // Accessible organizations page
					responsiveUi.enableResponsiveness();
					responsiveUi.disableResponsivenessOnScopeDestroy($scope);

					vm.allItems = _.uniqBy(vm.itemChildContent.childs, "wfid");
					vm.infiniteScrollContainer = undefined;
					vm.pagingFunction = pagingFunction;
					vm.onSearch = onSearch;
					vm.itemsInChunks = [];
				}
				if (vm.templateId === 3) {
					switch (vm.itemChildContent.type) {
						case enums.objectType.question:
						case enums.objectType.measure:
						case enums.objectType.structure:
						case enums.objectType.relativeMeasure:
							vm.showItemWfid = true
					}
				}

				$scope.actualItem = actualItem = function () {
					return itemChildContent || item;
				}

				$element.addClass("template" + vm.templateId);
				$element.addClass(vm.cssClass);
				$element.attr("wftemplate", vm.templateId);

				if (itemChildContent && itemChildContent.conditions && itemChildContent.conditions.contextVariable1)
				{
					vm.context = vm.context || {};
					vm.context.variable1 = itemChildContent.conditions.contextVariable1;
				}

				//if ((item.childContent || item).conditions)
				//	$element.addClass('asLink');

				//$scope.actualItem = item.childContent || item;

				//$scope.$on('reloadChildContent', function (event, updatedItem) {
				//	//console.log("CHECK Reload child content", updatedItem);
				//	if (typeof item.childContent !== "undefined" && item.id == updatedItem.id && item.type == updatedItem.type)
				//	{
				//		console.log("Reload child content", item);
				//		//$scope.actualItem = item.childContent;
				//	}
				//});

				//$scope.$on('reloadItemSubList', function (event, item, kind) {
				//	if (actualItem().id == item.id && actualItem().type == item.type)
				//	{
				//		$scope.$broadcast("reloadCollection", kind);
				//	}
				//});

				//if ($scope.collection)
				//{
				//	vm.itemPath = actualItem().itemPath = ($scope.collection.parent && $scope.collection.parent.itemPath ? $scope.collection.parent.itemPath : ($scope.collection.parent ? "/" + $scope.collection.parent.id : "")) + "/" + actualItem().id;
				//}
			}
		}

		function hasSubItemsOfKind(kind, item) {
			return item[kind] && item[kind].length;
		}

		function hasChildren(item) {
			//if (showAll) return true;

			if (item.settings && item.settings.childrenOpeningMode == 3)
			{
				console.log("has children true");
				return true;
			}
			else
				return hasSubItemsOfKind('childs', item);
		}

		function hasParents(item) {
			//if (showAll) return true;
			return hasSubItemsOfKind('parents', item);
		}

		function hasVerifications(item) {
			//if (showAll) return true;
			return hasSubItemsOfKind('verifications', item);
		}

		function hasRelatedContent(item) {
			//if (showAll) return true;
			return hasSubItemsOfKind('relatedContent', item);
		}

		function hasRelatedContentByUser(item) {
			//if (showAll) return true;
			return hasSubItemsOfKind('relatedContentByUser', item);
		}

		function gotoItem(item) {
			if (item.type === 73) // DataRelation
				$state.go('dataRelation', { idPath: item.id })
			else if (item.type === 71)
				$state.go('structure', { idPath: item.id })
		}

		function getTagger(item) {
			if (!item)
				return "-";

			if (item.creatorUser)
				return item.creatorUser.name;
			else
				return "-";
			// // console.log(item)
			// if (item.userId == 4741)
			// 	return "Hans Kruus";
			// else if (item.userId == 3)
			// 	return "Pär Gustafsson"
			// else if (item.userId == 4)
			// 	return "Andreas Liljendahl"
			// else
			// 	return "-";
		}

		//Used with infinite scroll (templateId: 59)
		function onSearch(searchString, items, searchActive) {
			vm.allItems = items;

			if (items) {
				vm.itemsInChunks.length = 0;
				Array.prototype.push.apply(vm.itemsInChunks, items);
			}

			if (!searchActive) {
				vm.itemsInChunks.length = 0;
				pagingFunction();
			}
		}

		//Used for infinite scroll (templateId: 59)
		function pagingFunction() {
			var newChunk = _.chain(vm.allItems).slice(vm.itemsInChunks.length).take(chunkSize).value();

			if (newChunk.length)
				Array.prototype.push.apply(vm.itemsInChunks, newChunk);
		}

		function createAttacher(options) {
			var jqDf = $.Deferred();

			modalService.createWithRelation({
				simplifyForm: true,
				objectType: 71,
				dataRelationOptions: { kind: 9, virtual: true, virtualItem1: vm.itemChildContent, item1: vm.itemChildContent },
				compilerControl: options.compilerControl
			}).then(function (dataRelation) {
				if (dataRelation) {
					dataOps.saveSettings({
						item: dataRelation.childContent,
						settings: {
							objectTypes: "44",
							dataRelationByUser: true,
							dataRelation: null
						}
					}).then(function () {
						jqDf.resolve();
					});
				}
				else {
					jqDf.resolve();
				}
			});

			return jqDf.promise();
		}
	}
})();
