// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import AccountBox from '@material-ui/icons/AccountBox'
import Email from '@material-ui/icons/Email'
import Phone from '@material-ui/icons/Phone'
import LanguageField from '@worldfavor/components/LanguageField'
import { FormattedMessage } from 'react-intl'

const styles = {
  root: {
    flex: 1,
    paddingTop: 28,
    paddingLeft: 117,
    paddingRight: 117,
  },
  inlineRow: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',

    '&>div': {
      flex: 1,
    },

    '& >div:not(:last-child)': {
      marginRight: 16,
    },
  },
  fieldIcon: {
    left: -52,
    position: 'absolute',
  },
}

const ContactPersonStep = ({
  classes,
  fields = {},
  descriptors = {},
  validation = {},
  showValidation,
  onChange,
}) => {
  const handleChange = key => (event) => {
    onChange && onChange(event, key, event.target.value)
  }

  function getTextField(key, props = {}) {
    const { id, labelId, required } = descriptors[key] || {}
    const FieldComponent = key === 'language' ? LanguageField : TextField

    return (
      <FieldComponent
        id={id}
        label={<FormattedMessage id={labelId} />}
        value={fields[key]}
        onChange={handleChange(key)}
        margin="normal"
        variant="filled"
        error={showValidation && validation[key]}
        required={required}
        {...props}
      />
    )
  }

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div className={classes.inlineRow}>

        <AccountBox className={classes.fieldIcon} size={'small'} />

        { getTextField('firstName') }
        { getTextField('lastName') }
      </div>

      { getTextField('title', { fullWidth: true }) }

      <div className={classes.inlineRow}>
        <Email className={classes.fieldIcon} size={'small'} />

        { getTextField('email', { fullWidth: true }) }
      </div>

      <div className={classes.inlineRow}>
        <Phone className={classes.fieldIcon} size={'small'} />

        { getTextField('phoneNumber', { fullWidth: true }) }
      </div>

      { getTextField('language', { fullWidth: true }) }
    </form>
  )
}

export default withStyles(styles)(ContactPersonStep)
