// @flow
import * as React from 'react'
import { escapeRegExp } from '@worldfavor/utils/helpers'

type Props = {
  text: string,
  match?: string,
  firstOnly?: boolean,
}

const HighlightedText = ({ text, match, firstOnly }: Props) => {
  if (typeof match === 'string') {
    match = (escapeRegExp(match))
  }
  if (!text || !match || typeof match !== 'string') {
    return text
  }

  let count = 0

  const result = new RegExp(match, 'i')

  return text.split(new RegExp(`(${match})`, 'i'))
    .map((part, index) => {
      if (part.match(result) && (!firstOnly || (firstOnly && count === 0))) {
        count++
        return <span key={`${part}-${index}`} style={{ backgroundColor: 'yellow' }}>{part}</span>
      }
      return part
    })
}

export default React.memo<Props>(HighlightedText)
