(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfDraggable', wfDraggable);

	wfDraggable.$inject = [];
	function wfDraggable() {
		var directive = {
			bindToController: true,
			controller: wfDraggableController,
			controllerAs: 'dragVm',
			link: link,
			restrict: 'A'
		};
		return directive;
		
		function link(scope, element, attrs) {
			var el = element[0];
			el.classList.add('wf-draggable');
			
			el.draggable = true;
	
			el.addEventListener(
				'dragstart',
				function(e) {
					e.dataTransfer.effectAllowed = 'move';
					e.dataTransfer.setData('Text', this.id);
					this.classList.add('drag');
					return false;
				},
				false
			);
	
			el.addEventListener(
				'dragend',
				function(e) {
					this.classList.remove('drag');
					return false;
				},
				false
			);
		}
	}
	
	wfDraggableController.$inject = [];
	function wfDraggableController() {
		
	}
})();