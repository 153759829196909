import React, { useState } from 'react'
import ReactTable from '@worldfavor/components/Table/ReactTable'
import { makeStyles } from '@material-ui/core/styles'
import Colors from '@worldfavor/constants/colors'
import Paper from '@material-ui/core/Paper'
import withPage from '@worldfavor/portal/hoc/withPage'
import { hexToRgbA } from '@worldfavor/constants/colors'
import { ConstVars } from '@worldfavor/constants'
import DataManagerTableSettings from './DataManagerTableSettings'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(theme => ({
  preTableInfo: {
    padding: 15,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  resultCount: {
    fontWeight: theme.typography.fontWeights.medium,
    fontSize: theme.typography.fontSizes.medium,
  },
  headerTableCell: {
    paddingTop: 20,
    paddingBottom: 20,
    textTransform: 'uppercase',
    fontSize: theme.typography.fontSizes.default,
    fontWeight: theme.typography.fontWeights.medium,
    color: Colors.grayText,
  },
  tableCell: {
    overflow: 'hidden',
    whiteSpace: 'pre',
    paddingRight: 8,
    textOverflow: 'ellipsis',
    display: 'flex !important',
    alignItems: 'center !important',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  row: props => ({
    cursor: props.contentType === ConstVars.analyzeJobs && 'pointer',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    '&:hover': {
      backgroundColor: Colors.grayTableHover,
    },
  }),
  oddRow: {
    backgroundColor: Colors.grayTableOddRow,
    '&:hover': {
      backgroundColor: Colors.grayTableHover,
    },
  },
  rowHighlight: {
    backgroundColor: hexToRgbA(theme.palette.secondary.main, 0.10),
  },
}))

export const DataManagerTable = (props) => {
  const {
    data,
    title,
    contentType,
    filters,
    page,
    onFilterChange,
    clearFilter,
    resultCount,
    disablePagination,
    disableControlledPagination,
    getTableColumns,
    renderRowCell,
    renderColumnCell,
    rowActions,
    ...otherProps
  } = props

  const classes = useStyles(props)
  const [settings, setSettings] = useState({
    expandCells: false,
    wordWrap: false,
    stickyHeader: false,
    columnResizing: false,
    enableDenseRows: false,
  })
  const { expandCells, wordWrap, stickyHeader, columnResizing, enableDenseRows } = settings
  const tableColumns = getTableColumns({ filters, onFilterChange, clearFilter, expandCells })

  return (
    <>
      <div className={classes.preTableInfo}>
        <span data-cy="result-count" className={classes.resultCount}><FormattedMessage id="search.resultsCount" values={{ count: `${resultCount}` }} /></span>
        <DataManagerTableSettings
          settings={settings}
          setSettings={setSettings}
        />
      </div>
      <Paper data-cy="data-manager-table">
        <ReactTable
          data={data}
          title={title}
          columns={tableColumns}
          renderRowCell={renderRowCell}
          renderColumnCell={renderColumnCell}
          rowActions={rowActions}
          columnResizing={columnResizing}
          stickyHeader={stickyHeader}
          wordWrap={wordWrap}
          resultCount={resultCount}
          enablePagination={!disablePagination}
          controlledPagination={!disableControlledPagination}
          controlledPageIndex={page}
          dense={enableDenseRows}
          classes={{
            headerTableCell: classes.headerTableCell,
            tableCell: classes.tableCell,
            tableWrapper: classes.tableWrapper,
            row: classes.row,
            oddRow: classes.oddRow,
          }}
          {...otherProps}
        />
      </Paper>
    </>
  )
}

export const DataManagerTableWithPage = withPage(({
  items,
  totalElements,
  onSearchChange,
  dataSource,
  title,
  ...other
}) => {
  const data = items.map(item => item.node)

  return (
    <DataManagerTable
      title={title}
      data={data}
      count={totalElements}
      {...other}
    />
  )
})
