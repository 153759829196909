// @flow
import * as React from 'react'
import { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import OrganizationSelectorRow from './OrganizationSelectorRow'
import SearchList from '../../List/SearchList'
import SearchBar from '../../SearchBar'
import Divider from '../../Divider'
import { FormattedMessage, injectIntl } from 'react-intl'

const emptyListStyles = {
  root: {
    padding: '50px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  searchCircle: {
    width: 100,
    height: 100,
    borderRadius: 50,
    border: '2px solid rgba(166,183,185,.84)',
    marginBottom: 20,
    textAlign: 'center',

    '& i': {
      fontSize: 42,
      lineHeight: '100px',
    },
  },
  text: {
    fontWeight: 700,
    fontSize: 15,
  },
}

const EmptyList = withStyles(emptyListStyles)(({ classes }) => (
  <div className={classes.root}>
    <div className={classes.searchCircle}>
      <i className={'fas fa-search'} />
    </div>
    <div className={classes.text}>
      <FormattedMessage id={'search.noOrganizationMatch'} />
    </div>
  </div>
))

const ItemSeparator = () => <Divider height={1} color={'rgba(221, 221, 221, 0.5)'} />

type Props = {
  organizations: Array<any>,
  selectedOrganizationId: ?string,
}

const styles = theme => ({
  headerDivider: {
    marginTop: 15,
    marginBottom: 10,
  },
  searchCounter: {
    color: '#9ca4b0',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
})

const OrganizationSelector = ({ classes, organizations, selectedOrganizationId, onSignIn, intl }) => {
  const [searchText, setSearchText] = useState('')
  const [searchCount, setSearchCount] = useState(organizations.length)

  function keyExtractor(item) {
    return `organization-row-${item.wfid}`
  }

  function renderItem({ item, match }) {
    return (
      <OrganizationSelectorRow
        onClick={onSignIn}
        match={match}
        organization={item}
        selected={item.wfid === selectedOrganizationId}
      />
    )
  }

  function onDataFiltered(data) {
    if (searchCount !== data.length) {
      setSearchCount(data.length)
    }
  }

  function onSearchChange(event) {
    setSearchText(event.target.value)
  }

  function onClear() {
    setSearchText('')
  }

  function renderCount() {
    return (
      <div className={classes.searchCounter}>
        <FormattedMessage
          id={'search.foundCountWithTotal'}
          values={{
            count: searchCount,
            total: organizations.length,
          }}
        />
      </div>
    )
  }

  return (
    <React.Fragment>
      <SearchBar
        onClear={onClear}
        renderCount={renderCount}
        compact
        inputProps={{
          placeholder: intl.formatMessage({ id: 'search.keywordPlaceholder' }),
          value: searchText,
          onChange: onSearchChange,
        }}
      />
      <Divider margin={'15px 0 10px 0'} height={1} color={'rgba(221, 221, 221, 0.5)'} />
      <SearchList
        data={organizations}
        searchText={searchText}
        filterKeys={['name', 'vatNumber', 'registrationNumber']}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        ItemSeparatorComponent={ItemSeparator}
        ListEmptyComponent={EmptyList}
        onDataFiltered={onDataFiltered}
        minSearchLength={2}
      />
    </React.Fragment>
  )
}

export default React.memo<Props>(injectIntl(withStyles(styles)(OrganizationSelector)))
