(function () {
	'use strict';

	angular
		.module('wf.common')
		.service('wfProcessTool', wfProcessTool);

	wfProcessTool.$inject = ['dataQuery','wfObject'];

	function wfProcessTool(dataQuery, wfObject) {
		var service = {
			getLookupOptions_Relevans: getLookupOptions_Relevans,
			getLookupOptions_Betydelse: getLookupOptions_Betydelse,
			getLookupOptions_Prioritet: getLookupOptions_Prioritet
		};

		activate();

		return service;

		function activate () {
		}

		function getLookupOptions_Relevans(vm) {
			var level2Children = wfObject.filter({
					where:
					{
						type: 73,
						parentData1: null,
						wffid: { in: _.map(vm.children_iso26k, 'wfcid') },
						wfcid:
						{
							in: _.map(vm.relevantItems, 'wfcid')
						}
					}
				});
			
			vm.relevantItems = dataQuery.withLastParentId(wfObject.filter(vm.query_relevantItems_parentsQuery), vm.structure_relevanta.childId);
			vm.aggregated_relevans = {
				items_level1: wfObject.filter(
				{
					where:
					{
						type: 73, wffid: '71-21',
						parentData1: null,
						wfcid:
						{
							in: _.map(_.uniqBy(wfObject.filter(
							{
								where:
								{
									type: 73,
									parentData1: null,
									wffid: { in: _.map(vm.children_iso26k, 'wfcid') },
									wfcid:
									{
										in: _.map(vm.relevantItems, 'wfcid')
									}
								}
							}), 'parentId'), 'wffid')
						}
					}
				}),
				parentsWithCustomChildren: _.keyBy(vm.children_iso26k, 'wfcid'),
				items_level2: level2Children,
				items_level2_byParentWfid: _.groupBy(level2Children, 'wffid')
			}
		}
		
		function getLookupOptions_Betydelse(vm) {
			var level2Children = wfObject.filter({
				where:
				{
					type: 73,
					parentData1: null,
					wffid: { in: _.map(vm.children_iso26k, 'wfcid') },
					wfcid:
					{
						in: _.map(vm.betydandeItems, 'wfcid')
					}
				}
			});
			vm.betydandeItems = dataQuery.withLastParentId(wfObject.filter(vm.query_betydandeItems_parentsQuery), vm.structure_betydande.childId);
			vm.aggregated_betydelse = {
				items_level1: wfObject.filter(
				{
					where:
					{
						type: 73, wffid: '71-21',
						parentData1: null,
						wfcid:
						{
							in: _.map(_.uniqBy(wfObject.filter(
							{
								where:
								{
									type: 73,
									parentData1: null,
									wffid: { in: _.map(vm.children_iso26k, 'wfcid') },
									wfcid:
									{
										in: _.map(vm.betydandeItems, 'wfcid')
									}
								}
							}), 'parentId'), 'wffid')
						}
					}
				}),
				parentsWithCustomChildren: _.keyBy(vm.children_iso26k, 'wfcid'),
				items_level2: level2Children,
				items_level2_byParentWfid: _.groupBy(level2Children, 'wffid')
			}
			
		}
		
		function getLookupOptions_Prioritet(vm) {
			var level2Children = wfObject.filter({
				where:
				{
					type: 73,
					parentData1: null,
					wffid: { in: _.map(vm.children_iso26k, 'wfcid') },
					wfcid:
					{
						in: _.map(vm.prioriteradeItems, 'wfcid')
					}
				}
			});
			vm.prioriteradeItems = dataQuery.withLastParentId(wfObject.filter(vm.query_prioriteradeItems_parentsQuery), vm.structure_prioriterade.childId);
			vm.aggregated_prioritet = {
				items_level1: wfObject.filter(
				{
					where:
					{
						type: 73, wffid: '71-21',
						parentData1: null,
						wfcid:
						{
							in: _.map(_.uniqBy(wfObject.filter(
							{
								where:
								{
									type: 73,
									parentData1: null,
									wffid: { in: _.map(vm.children_iso26k, 'wfcid') },
									wfcid:
									{
										in: _.map(vm.prioriteradeItems, 'wfcid')
									}
								}
							}), 'parentId'), 'wffid')
						}
					}
				}),
				parentsWithCustomChildren: _.keyBy(vm.children_iso26k, 'wfcid'),
				items_level2: level2Children,
				items_level2_byParentWfid: _.groupBy(level2Children, 'wffid')
			}	
		}
	}
})();