import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfSearch', wfSearch)
		.directive('wfSearchQuick', wfSearchQuick)
	;

	wfSearchQuick.$inject = [ '$parse' ];

	function wfSearchQuick($parse) {
		var
			escapeCharacters = /[-[\]{}()*+?.,\\/^$|#]/g,
			whiteSpaces = /\s+/g,
			minSearchStringLength = 2
		;

		var directive = {
			restrict: 'EA',
			templateUrl: "scripts/wf/common/searchQuick.directive.html",
			scope: {
				items: "&",
				sourcePropertyPath: "<",
				sourceItemPath: "<",
				placeholder: "=",
				placeholderTranslate: "=",
				controllerAs: "=",
				control: "=",
				onSearch: "&",
				additionalLookup: "<",
				useServer: '<',
				inputTypeSelect: '<',
				searchById: '<',
			},
			controllerAs: "vm",
			controller: wfSearchQuickController
		};

		wfSearchQuickController.$inject = [ '$scope', '$element', '$timeout', '$translate', '$attrs', 'apiProxy' ];

		return directive;

		function wfSearchQuickController($scope, $element, $timeout, $translate, $attrs, apiProxy) {
			var
				vm = this,
				currentSearchString,
				input = $element.find("input[type=search]"),
				allItems = [],
				items,
				control,
				sourcePropertyPath = $scope.sourcePropertyPath,
				sourceItemPath = $scope.sourceItemPath,
				additionalLookup = $scope.additionalLookup,
				searchById = $scope.searchById
			;

			$element.click(function() {
				input.off("blur");
				$element.addClass('expand-search')
				input.focus();
				input.blur(function () {
					if (!vm.searchActive) {
						$element.removeClass('expand-search');
						vm.clearSearch();
					}
				});
			});

			vm.collapseMode = false;
			if ('collapsed' in $attrs) {
				vm.collapseMode = true;
			}

			vm.items = [];

			if (typeof $scope.items === 'function')
				items = $scope.items();
			else
				items = $scope.items;

			Array.prototype.push.apply(vm.items, items);
			Array.prototype.push.apply(allItems, vm.items);

			if ($scope.placeholderTranslate) {
				vm.placeholder = $translate.instant($scope.placeholderTranslate) + "...";
			}
			else
				vm.placeholder = $scope.placeholder && $scope.placeholder.length ? $scope.placeholder : $translate.instant("Search") + "...";

			vm.filteredCount = vm.totalCount =  allItems.length;

			if ($scope.controllerAs) {
				$scope.controllerAs.searchResultItems = vm.items;
			}
			else
				$scope.$parent.vm.searchResultItems = vm.items;

			if ($scope.control) {
				_.assign($scope.control, {
					clear: clearSearch
				});
			}

			vm.useServer = $scope.useServer;
			vm.inputTypeSelect = $scope.inputTypeSelect;
			vm.showTotalCount = !vm.useServer;

			vm.onSearch = function (searchString, force) {
				var searchResultItems;

				if (currentSearchString === searchString && !force)
					return;

				if (vm.useServer) {
					if (vm.xhrRequest) {
						vm.xhrRequest.abort();
						vm.xhrRequest = undefined;
						vm.loading = false;
					}

					if (searchString.length) {
						vm.loading = true;
						vm.xhrRequest = apiProxy.raw(vm.useServer.method, _.assign(vm.useServer.baseParams, { searchString: searchString }));
						vm.xhrRequest.then(function (res) {
							searchResultItems = res;
							vm.loading = false;
							finializeSearch();
						});
					}
					else {
						searchResultItems = allItems;
						finializeSearch();
					}
				}
				else {
					searchResultItems = applySearchFilter(vm.items, searchString, allItems, sourcePropertyPath, sourceItemPath, additionalLookup, searchById);
					finializeSearch();
				}

				function finializeSearch() {
					if (searchString.length && searchString.length >= minSearchStringLength) {
						vm.searchActive = true;
					}
					else {
						vm.searchActive = false;
					}

					if ($scope.controllerAs)
						$scope.controllerAs.searchActive = vm.searchActive;
					else
						$scope.$parent.vm.searchActive = vm.searchActive;

					// console.log(vm.searchActive);
					// console.log("vm.onSearch", searchString);
					// console.log(searchResultItems.length);
					currentSearchString = searchString;
					vm.filteredCount = searchResultItems.length;
					if ($scope.controllerAs)
						$scope.controllerAs.searchResultItems = searchResultItems;
					else
						$scope.$parent.vm.searchResultItems = searchResultItems;

					if ($scope.onSearch) {
						$scope.onSearch({
							searchString: searchString,
							items: _.clone(searchResultItems),
							searchActive: vm.searchActive
						})
					}
					$timeout();
				}
			};
			$scope.$watchCollection(function () {
				return allItems;
			}, function (value) {
				vm.totalCount = allItems.length;

				if (currentSearchString && currentSearchString.length && currentSearchString.length >= minSearchStringLength) {
					vm.onSearch(currentSearchString, true);
				}
				else
					vm.filteredCount = vm.totalCount;
			});
			$scope.$watchCollection(function () {
				return items;
			}, function (value) {
				vm.items.length = 0;
				allItems.length = 0;
				// console.log("length changed");
				Array.prototype.push.apply(vm.items, items);
				Array.prototype.push.apply(allItems, vm.items);

				if (currentSearchString && currentSearchString.length && currentSearchString.length >= minSearchStringLength)
					vm.onSearch(currentSearchString, true);
				else
					vm.filteredCount = vm.totalCount;
			});

			input.on("keypress keydown change", _.debounce(function (event) {
				vm.onSearch(input.val());
			}, vm.useServer ? 500 : 150));

			vm.clearSearch = clearSearch;

			function clearSearch() {
				input.val("");
				vm.onSearch("");
			}
		}

		function applySearchFilter(items, searchString, allItems, sourcePropertyPath, sourceItemPath, additionalLookup, searchById) {
			var
				regexp, res, res2, words, wordsCount,
				result,
				useChildContent
			;

			allItems = _.filter(allItems, function (x) {
				var childContent = x && x.type !== enums.objectType.influence ? x.childContent : null;

				if (childContent) {
					useChildContent = true;
					return true
				}
				else if (x)
					return true;
				else
					return false;
			});

			if (searchString && searchString !== "" && searchString.length >= minSearchStringLength)
			{
				const trimmedSearchString = searchString.trim();
				words = _.uniq(trimmedSearchString.replace(whiteSpaces, ' ').replace(escapeCharacters, '\\$&').split(' '));
				regexp = new RegExp("(" + words.join(")|(") + ")", "gi");
				result = _.filter(allItems, function (x) {
					wordsCount = words.length;

					if (useChildContent) {
						return itemMatches(x.childContent, trimmedSearchString, regexp, res, res2, words, wordsCount, sourcePropertyPath, sourceItemPath, additionalLookup, searchById)
					}
					else {
						return itemMatches(x, trimmedSearchString, regexp, res, res2, words, wordsCount, sourcePropertyPath, sourceItemPath, additionalLookup, searchById);
					}
				});

				items.length = 0;
				Array.prototype.push.apply(items, result);

				return items;
			}
			else {
				items.length = 0;
				Array.prototype.push.apply(items, allItems);

				return items;
			}
		}

		function itemMatches(item, searchString, regexp, res, res2, words, wordsCount, sourcePropertyPath, sourceItemPath, additionalLookup, searchById) {
			var matches, value, additionalItem, output;

			if (additionalLookup) {
				additionalItem = additionalLookup.itemByLookup[item[additionalLookup.lookupProperty]];
				if (additionalItem) {
					value = _.get(additionalItem, additionalLookup.searchPropertyPath);
					res = value.match(regexp);
					if (res)
						return true;
				}
			}

			if (sourceItemPath) {
				item = _.get(item, sourceItemPath);
			}

			if (item.isComposite)
				item = item.content;

			if (sourcePropertyPath) {
				value = _.get(item, sourcePropertyPath);
				if (typeof value === "string") {
					res = value.match(regexp);
					matches = res && _.uniq(res).length >= wordsCount;
				}
				else
					matches = false
			}
			else if (item && item.searchSource)
			{
				res = item.searchSource.match(regexp);
				matches = res && _.uniq(res).length >= wordsCount;
			}
			else if (item.type == enums.objectType.organization)
			{
				value = item.name + " " + item.registrationNumber + " " + item.vatNumber + " " + item.gln;
				res = value.match(regexp);
				matches = res && _.uniq(res).length >= wordsCount;
			}
			else if (item && item.title)
			{
				res = item.title.match(regexp);
				matches = res && _.uniq(res).length >= wordsCount;

				if (!matches && item.description)
				{
					res = item.description.match(regexp);
					matches = res && _.uniq(res).length >= wordsCount;
				}
			}
			else if (item && item.name)
			{
				res = item.name.match(regexp);
				matches = res && _.uniq(res).length >= wordsCount;
			}
			else if (item && item.text)
			{
				res = item.text.match(regexp);
				matches = res && _.uniq(res).length >= wordsCount;
			}
			else if (item && item.description)
			{
				res = item.description.match(regexp);
				matches = res && _.uniq(res).length >= wordsCount;
			}
			else if (item && item.organization)
			{
				if (item.organization.searchSource)
				{
					res = item.organization.searchSource.match(regexp);
					matches = res && _.uniq(res).length >= wordsCount;
				}
				else {
					res = item.organization.name.match(regexp);
					matches = res && _.uniq(res).length >= wordsCount;
				}
			}
			else if (item.type == enums.objectType.levUtb_eduStudent && item.user)
			{
				res = item.user.displayName.match(regexp);
				res2 = item.user.email.match(regexp);
				matches = (res && _.uniq(res).length >= wordsCount) || (res2 && _.uniq(res2).length >= wordsCount);
			}
			else
				matches = false


			if (searchById && item.wfid) {
				matches = matches || item.wfid.indexOf(searchString) !== -1
			}

			return matches
		}
	}


	wfSearch.$inject = [ 'wfObject', 'dataOperationsService', '$timeout', '$compile' ];

	function wfSearch(wfObject, dataOps, $timeout, $compile) {
		var directive = {
			link: link,
			restrict: 'A',
			scope: {
				item: '=',
				kind: '='
			}
			//compile: function(element, attrs) {
			//	element.attr('ng-model', 'searchString');
			//	return {
			//		pre: function preLink(scope, iElement, iAttrs, controller) { },
			//		post: function postLink(scope, iElement, iAttrs, controller) {
			//			console.log(scope);
			//			$compile(iElement)(scope);
			//		}
			//	}
			//}
		};
		return directive;

		function link(scope, element, attrs) {
			var
				query,
				inputValueTimeout
			;

			//// This is what you will bind the filter to
			//$scope.filterText = '';

			//// Instantiate these variables outside the watch
			//var tempFilterText = '', filterTextTimeout;

			//$scope.$watch('searchText', function (val) {
			//	if (filterTextTimeout) $timeout.cancel(filterTextTimeout);

			//	tempFilterText = val;
			//	filterTextTimeout = $timeout(function() {
			//		$scope.filterText = tempFilterText;
			//	}, 250); // delay 250 ms
			//})

			//scope.$watch('searchString', function (val) {
			//	console.log(scope.searchString);
			//});

			scope.$watch(function () {
				//console.log(element.val());
				return element.val();
			}, function () {
				if (inputValueTimeout) $timeout.cancel(inputValueTimeout);

				//console.log('key');

				//tempFilterText = val;
				inputValueTimeout = $timeout(function() {
					//$scope.filterText = tempFilterText;
					console.log(element.val());
					dataOps.getSubItems(scope.item, scope.kind, { searchString: element.val() });
				}, 500); // delay 250 ms
			});

			element.bind("keypress keydown change", function (event) {
				$timeout(function() { });
			});

		}
	}
})();
