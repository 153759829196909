(function() {
	'use strict';

	angular
		.module('wf.common')
		.component('wfInfluenceHeader', {
			templateUrl: 'scripts/wf/influence/wfInfluenceHeader.component.html',
			controller: wfInfluenceHeaderController,
			controllerAs: 'vm',
			bindings: {
				influence: '=',
			},
		});

	wfInfluenceHeaderController.$inject = [ '$element', '$compile', '$timeout', '$scope', '$sanitize', 'modalService', '$translate', 'wfAuth' ];
	function wfInfluenceHeaderController($element, $compile, $timeout, $scope, $sanitize, modal, $translate, wfAuth) {
		var vm = this;
		var buttonWrapperElement;
		const user = wfAuth.getUser();

		_.assign(vm, {
			showImage: true,
			requestedFromText: undefined,
			comment: undefined,
			attachments: undefined,
			appendPopoverToBody: undefined,
			showFollowUpInformation: false,

			openGuidance: openGuidance,
			openAttachmentsPopover: openAttachmentsPopover,
			openCommentPopover: openCommentPopover
		});

		vm.$onInit = function() {
			var commentCreatorUser = undefined;

			if (vm.influence) {
				if (vm.influence.creatorOrganization)
					vm.creatorOrganization = vm.influence.creatorOrganization;

				vm.attachments = [ ...(_.get(vm.influence, "childContent.relatedContent") || []), ...(_.get(vm.influence, "relatedContent") || []) ];
				vm.guidance = _.get(vm.influence, "childContent.guidance");
				vm.influenceActivatedAt = moment(vm.influence.activatedAt).format("D MMM YYYY");

				vm.influenceDueAt = vm.influence.fulfillmentDueAt && moment(vm.influence.fulfillmentDueAt).format("YYYY-MM-DD");

				if (vm.influence.comment) {
					if (vm.influence.creatorUserWfid)
						commentCreatorUser = wfObject.get(vm.influence.creatorUserWfid); // what if the user is not in the JSData?

					vm.comment = {
						text: vm.influence.comment,
						creatorUser: commentCreatorUser
					};
				}

				if (vm.comment || vm.guidance || vm.attachments.length || vm.influenceDueAt)
					vm.showFollowUpInformation = true;

				vm.currentUserHaveQuestionsAssigned = vm.influence.taggedUserIds && vm.influence.taggedUserIds.includes(user.wfUserId)
			}
			else
				console.error("Could not initialize wfInfluenceHeader, influence object is not defined");
		};

		function openGuidance() {
			modal.openGuidance({ title: $translate.instant("Guidance"), message: vm.influence.childContent.guidance });
			$timeout();
		}

		function openCommentPopover($event) {
			var htmlTemplate = '<div class="comment">' + getCommentAsHtml(vm.comment); + '</div>'

			initializePopover($event, htmlTemplate);
			showPopover("comment");

			function getCommentAsHtml(comment) {
				var output = "";

				if (comment.text && comment.creatorUser) {
					output = '<div class="influence-comment-user">' +
						'<div class="user-icon" wf-tooltip="' + comment.creatorUser.name + '\n' + comment.creatorUser.email + '" wf-tooltip-placement-top>' +
							'<div class="user-img" style="background-image: url(' + comment.creatorUser.imageUrl + ')"></div>' +
						'</div>' +
					'</div>' +
					'<div class="influence-comment-text"><span class="comment-text">' + $sanitize(comment.text) + '</span></div>';
				}
				else {
					output = '<div class="influence-comment-text"><span class="comment-text">' + $sanitize(comment.text) + '</span></div>';
				}

				return output;
			};
		}

		function openAttachmentsPopover($event) {
			var htmlTemplate = '<div class="attachments">' + getAllAttachmentAsHtml(vm.attachments); + '</div>'

			initializePopover($event, htmlTemplate);
			showPopover("attachments");

			function getAllAttachmentAsHtml(attachments) {
				var output = "";

				if (attachments && attachments.length) {
					_.each(attachments, function(attachment) {
						output += '<a href="' + $sanitize(attachment.childContent.url) + '" target="_blank">' +
							'<div class="file">' +
								'<div class="file-icon"><i class="fas fa-file"></i></div>' +
								'<span class="file-name">' + attachment.childContent.title + "." + attachment.childContent.fileType + '</span>' +
							'</div>' +
							'<div class="external-icon"><i class="fas fa-external-link-alt"></i></div>' +
						'</a>';
					})
				}

				return output;
			};
		}

		function showPopover(popoverType) {
			if (!vm.popoverVisible) {
				buttonWrapperElement.popover("show");
				vm.popoverVisible = true;

				setTimeout(function() {
					$(document).on('click.followUpPopoverEvent-' + popoverType, function(event) {
						if (vm.popoverVisible && !$(event.target).closest('div.button-wrapper.' + popoverType).length)
							hidePopover(popoverType);
					});
				}, 100);
			}


		}

		function hidePopover(popoverType) {
			if (vm.popoverVisible) {
				vm.popoverVisible = false;
				buttonWrapperElement.popover("hide");
				$(document).off("click.followUpPopoverEvent-" + popoverType);
			}
			$timeout();
		}

		function initializePopover($event, htmlTemplate) {
			var compiledElement;

			buttonWrapperElement = $($event.target);
			if (!buttonWrapperElement.hasClass("button-wrapper"))
				buttonWrapperElement = buttonWrapperElement.closest("div.button-wrapper");

			buttonWrapperElement.attr("data-toggle", "popover");

			buttonWrapperElement.popover({
				delay: 0,
				animation: true,
				placement: "bottom",
				container: vm.appendPopoverToBody ? 'body' : false,
				trigger: "manual",
				template: '<div class="popover follow-up-info-popover"><div class="arrow"></div><div class="popover-content"></div></div>',
				html: true,
				selector: false,
				content: function () {
					compiledElement = $compile(htmlTemplate)($scope);
					$timeout();

					return compiledElement;
				}
			});
		}
	}
})();
