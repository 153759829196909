// @flow
import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Colors from '@worldfavor/constants/colors'
import classNames from 'classnames'

type Props = {
  className?: string,
  style?: Object,
  size?: string,
  title: string | React.Node,
  description?: string | React.Node,
  iconClass?: string,
  children?: React.Node,
  IconComponent?: React.ComponentType<any>,
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  small: {
    '& $circle': {
      height: 62,
      width: 62,
      marginBottom: 12,
    },
    '& $icon': {
      fontSize: 18,
    },
    '& $title': {
      fontSize: 14,
    },
    '& $description': {
      marginTop: 5,
      fontSize: 12,
    },
  },
  large: {
    '& $circle': {
      height: 158,
      width: 158,
      marginBottom: 16,
    },
    '& $icon': {
      fontSize: 48,
    },
    '& $title': {
      fontSize: 20,
    },
    '& $description': {
      marginTop: 16,
    },
  },
  circle: {
    height: 92,
    width: 92,
    marginBottom: 14,
    borderRadius: '100%',
    backgroundColor: Colors.grayLighter,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    fontSize: 28,
    color: Colors.gray,
  },
  title: {
    ...theme.typography.h6,
    color: theme.palette.primary.main,
    fontSize: 16,
    marginTop: theme.spacing(1),
    textAlign: 'center',
    lineHeight: '1.4',
    width: '100%',
  },
  description: {
    marginTop: 10,
    fontSize: 14,
    textAlign: 'center',
    color: Colors.grayText,
    width: '100%',
  },
}))

const EmptyState = (props: Props) => {
  const { className, style, size, title, description, IconComponent, iconClass, children } = props
  const classes = useStyles(props)
  return (
    <div className={classNames(classes.root, className, { [classes[size]]: size !== 'medium' })} style={style}>
      {
        (IconComponent || iconClass) && (
          <div className={classes.circle}>
            {
              IconComponent || <i className={classNames(classes.icon, iconClass)} />
            }
          </div>
        )
      }
      <div className={classes.title}>{title}</div>
      { description && (<div className={classes.description}>{description}</div>) }
      { children }
    </div>
  )
}

EmptyState.defaultProps = {
  size: 'medium',
  iconClass: 'fas fa-question',
}

export default EmptyState
