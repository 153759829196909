// @flow
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TransparencyLevelPicker from '@worldfavor/components/Picker/TransparencyLevelPicker'

const MappingStatusMap = {
  notMapped: 0,
  partiallyMapped: 50,
  fullyMapped: 100,
}

const getMappingStatus = (level) => {
  if (!level || level < 50) {
    return 'notMapped'
  }
  if (level < 100) {
    return 'partiallyMapped'
  }
  return 'fullyMapped'
}

const useStyles = makeStyles(theme => ({
  root: {},
  dialogTitle: {
    ...theme.typography.h5,
  },
  dialogContent: {
    paddingTop: 2,
  },
  pickerContainer: {
    '& > div:last-child': {
      marginBottom: 18,
    },
  },
}))

type Props = {
  open: boolean,
  level: number,
  onCancel: () => void,
  onSave: (level: number) => void,
}

const TransparencyLevelDialog = (props: Props) => {
  const { open, level, onCancel, onSave, ...rest } = props
  const classes = useStyles(props)
  const [selectedLevel, setSelectedLevel] = useState(getMappingStatus(level))

  // Reset selectedLevel to the initial level value on dialog open
  useEffect(() => {
    if (open) {
      setSelectedLevel(getMappingStatus(level))
    }
  }, [open])

  function _onSave() {
    onSave(MappingStatusMap[selectedLevel])
  }

  function onSelect(event, selectedLevel) {
    setSelectedLevel(selectedLevel)
  }

  return (
    <Dialog
      open={open}
      maxWidth={false}
      disableBackdropClick
      disableEscapeKeyDown
      {...rest}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <FormattedMessage id={'supplyChain.mapper.transparencyDialog.title'} />
      </DialogTitle>

      <DialogContent classes={{ root: classes.dialogContent }}>
        <div className={classes.pickerContainer}>
          <TransparencyLevelPicker
            selectedLevel={selectedLevel}
            onSelect={onSelect}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          <FormattedMessage id={'general.cancel'} />
        </Button>
        <Button
          disabled={!selectedLevel}
          onClick={_onSave}
          color="primary"
          autoFocus
        >
          <FormattedMessage id={'general.save'} />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

TransparencyLevelDialog.defaultProps = {
  onSave: () => {},
}

export default TransparencyLevelDialog
