(function () {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfClick', wfClick)
		.directive('wfClickThen', wfClickThen)
		.directive('wfClickLoader', wfClickLoader)
	;

	wfClick.$inject = ['$parse'];

	function wfClick($parse) {
		var directive = {
			restrict: 'A',
			priority: 1,
			controller: ['$scope','$element','$attrs','$timeout', wfClickController]
			// compile: function ($element, attr) {
			// 	var fn = $parse(attr["wfClick"]);
			// 	return function (scope, element, attr) {
			// 		element.on("click", function (event) {
			// 			var promise = fn(scope, { $event: event, $element: element });
			// 			if (promise && promise.then)
			// 			{
			// 				element.addClass("loading");
			// 				promise.then(function () {
			// 					element.removeClass("loading");
			// 				})
			// 			}
			// 		});
			// 	};
			// }
		};
		return directive;

		function wfClickController($scope, $element, $attrs, $timeout) {
			var
				vm = this,
				fn,
				busy,
				dropdownButtonElement,
				checkedForDropdown
			;
			
			if (!$element || !$element[0] || $element.data("wfClickInitialized"))
				return;

			// Prevents wfClick from adding multiple eventHandlers in case of double compiled html or other buggy implementations
			$element.data("wfClickInitialized", true);

			$element[0].addEventListener("click", function (event) {
				if (busy) return;
				busy = true;

				if (!fn)
					fn = $parse($attrs["wfClick"]);

				// console.log(event, fn, $element)
				vm.fnOutput = fn($scope, { $event: event, $element: $element });
				if (vm.fnOutput && vm.fnOutput.then)
				{
					if (!checkedForDropdown && $element.is("a")
						&& $element.parent("li")
						&& $element.parent().parent("ul.dropdown-menu")
						&& $element.parent().parent().parent("div.btn-group")
					) {
						checkedForDropdown = true;
						dropdownButtonElement = $element.parent().parent().parent().children(".btn");
					}
					else
						checkedForDropdown = true;

					if (dropdownButtonElement)
						dropdownButtonElement.addClass("loading");

					$element.addClass("loading");
					
					if (typeof vm.fnOutput.cancelled === "function") {
						vm.fnOutput.cancelled(onResolved)
					}
					vm.fnOutput.then(onResolved);
					
					if (vm.fnOutput.catch) {
						vm.fnOutput.catch(function () {
							busy = false;
							$element.removeClass("loading");
						});
					}
					
					if (vm.fnOutput.modal && $attrs.wfClickModalClosed)
					{
						vm.fnOutput.modal.closed.then(function () {
							$scope.$eval($attrs.wfClickModalClosed);
						});
					}
					
				}
				else
				{
					setTimeout(function () {
						busy = false;
					}, 500);
				}

				function onResolved() {
					if (dropdownButtonElement)
						dropdownButtonElement.removeClass("loading");
					
					$element.removeClass("loading");
					
					// console.log($attrs.wfClickThen);
					$timeout(function () {
						busy = false;
						if ($attrs.wfClickThen)
						{
							$scope.$eval($attrs.wfClickThen);
						}
					});
				}
			}, false);
		}
	}
	
	
	wfClickThen.$inject = [];

	function wfClickThen() {
		var directive = {
			restrict: 'A',
			require: '^wfClick',
			link: function (scope, element, attrs, wfClickCtrl) {
				// console.log(wfClickCtrl);
				// if (wfClickCtrl.fnOutput && wfClickCtrl.fnOutput.then)
				// {
				// 	wfClickCtrl.fnOutput.then(function () {
				// 		console.log("fire $eval");
				// 		scope.$eval(attrs["wfClickThen"]);
				// 	});
				// }
			}
		};
		return directive;
	}
	

	wfClickLoader.$inject = ['$parse','$timeout','$rootScope'];

	function wfClickLoader($parse, $timeout, $rootScope) {
		var directive = {
			restrict: 'A',
			compile: function($element, attr) {
				// We expose the powerful $event object on the scope that provides access to the Window,
				// etc. that isn't protected by the fast paths in $parse.  We explicitly request better
				// checks at the cost of speed since event handler expressions are not executed as
				// frequently as regular change detection.
				var fn = $parse(attr.wfClickLoader, /* interceptorFn */ null, /* expensiveChecks */ true);
				return function ngEventHandler(scope, element) {
					element.on("click", function(event) {
						var callback = function() {
							fn(scope, {$event:event});
						};
						$rootScope.screenLoader.showAndHide();
						setTimeout(function () {
							if ($rootScope.$$phase) {
								scope.$evalAsync(callback);
							} else {
								scope.$apply(callback);
							}
						}, 50);
					});
				};
			}
		};
		return directive;
	}

})();