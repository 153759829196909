(function () {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfItemRelationsPane', wfItemRelationsPane)
	;
	wfItemRelationsPane.$inject = [];

	function wfItemRelationsPane() {
		var directive = {
			restrict: 'E',
			templateUrl: 'scripts/wf/itemRelations/wfItemRelations.pane.directive.html',
			link: link
		};
		
		return directive;

		function link(scope, element, attrs) {
			element.addClass('wfItemRelationsPane');
		}
	}
})();