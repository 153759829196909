import { createSelectorWithDependencies as createSelector } from 'reselect-tools'
import createCachedSelector from 're-reselect'
import { getSubNodesFromNodeId } from './dataSelector'
import { StaticIds } from '@worldfavor/constants'
import { ObjectType } from '@worldfavor/constants/enums'
import { getAuthUserOrganizationWfid } from './appSelector'
import uniqBy from 'lodash/uniqBy'

export const getColleagues = createSelector(
  [state => getSubNodesFromNodeId(state, StaticIds.Colleagues)],
  (subNodes) => {
    return subNodes.map(({ node }) => node).filter(Boolean)
  },
)

export const getContributors = createCachedSelector(
  [
    (state, networkWfid) => getSubNodesFromNodeId(state, networkWfid),
    getAuthUserOrganizationWfid,
  ],
  (subNodes, organizationWfid) => {
    const contributors = subNodes.filter((x) => {
      return x.edge.childType === ObjectType.individual && x.edge.wfxpid === organizationWfid
    })

    // TODO run uniq on the nodes
    return uniqBy(contributors, 'node.wfid').filter(Boolean)
  },
)((state, networkWfid) => networkWfid)
