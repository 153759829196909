import * as enums from '@worldfavor/constants/enums'

(function () {
	'use strict';

	angular
		.module('wf.data')
		.service('dataQuery', dataQuery);

	dataQuery.$inject = [ 'wfObject', 'dataOperationsService', '$translate', 'wfPropertyExtractor', 'wfMeasureService', 'wfAuth' ];

	function dataQuery(wfObject, dataOps, $translate, wfPropertyExtractor, wfMeasureService, wfAuth) {
		var service = {
			consoleLog: function () {
				console.log.apply(this, arguments);
			},
			getArrayFromFilter: function (property, filter) {
				var output = _.map(wfObject.filter(filter), property);
				return output;
			},
			mapProperty: function (property, filter) {
				var output = _.map(wfObject.filter(filter), property);
				return output;
			},
			withLastParentId: withLastParentId,
			getHierarchyAsList: getHierarchyAsList,
			makeItemComposites: makeItemComposites,
			getPickerSettings: getPickerSettings,
			getAll: {
				answersOnQuestion: getAll_answersOnQuestion,
				answersOnMeasure: getAll_answersOnMeasure,
				childrenOf: getAll_childrenOf,
				parentsOf: getAll_parentsOf,
				relatedContentByCreatorOf: getAll_relatedContentByCreatorOf,
				relatedContentByUserOf: getAll_relatedContentByUserOf,
				intersectedChildrenOf: getAll_intersectedChildrenOf,
				intersectedVerifiedBy: getAll_intersectedVerifiedBy,
				answersOnQuestions: getAll_answersOnQuestions,
				latestAnswersOnQuestions: getAll_latestAnswersOnQuestions,
			},
			get: {
				latestAnswerOnQuestion: get_latestAnswerOnQuestion,
				latestAnswerOnMeasure: get_latestAnswerOnMeasure,
				answersOnMeasureAtPeriod: get_answersOnMeasureAtPeriod,
				last: get_last,
				lastIntersectedChildOf: get_lastIntersectedChildOf
			},
			getIntersectedSubItems: getIntersectedSubItems,
			getAnswerStatistics: getAnswerStatistics,
			getSubItems: getSubItems,
			getWfObjectPrototype: function () {
				return _.clone(wfObjectPrototype);
			},
			getRelations: getRelations,
			filterItemsWithAnySettingForAttachingData: filterItemsWithAnySettingForAttachingData,
			hasItemAnySettingForAttachingData: hasItemAnySettingForAttachingData
		};

		var
			userDataObjectTypes = _.keyBy([
				enums.objectType.orgActivity,
				enums.objectType.questionAnswer,
				enums.objectType.measureAnswer,
				enums.objectType.parameterValue,
				enums.objectType.orgDocument,
				enums.objectType.statement,
				enums.objectType.link,
				enums.objectType.location,
				enums.objectType.invoice,
				enums.objectType.embed,
				enums.objectType.certificate
			]),
			answereableType = _.keyBy([
				enums.objectType.question,
				enums.objectType.measure,
				enums.objectType.relativeMeasure,
				enums.objectType.parameter
			]),
			answerType = _.keyBy([
				enums.objectType.questionAnswer,
				enums.objectType.measureAnswer,
				enums.objectType.parameterValue
			]),
			linkableObjectTypes = _.keyBy([
				enums.objectType.orgDocument,
				enums.objectType.link,
				enums.objectType.embed,
				enums.objectType.certificate
			]),
			typeHasHeader = _.keyBy([
				enums.objectType.structure,
				enums.objectType.orgActivity,
				enums.objectType.embed
			]),
			relationalTypes = _.keyBy([
				enums.objectType.dataRelation,
				enums.objectType.historicDataRelation,
				enums.objectType.virtualDataRelation,
				enums.objectType.visibilityTag
			])
		;

		var wfObjectPrototype = {
			getSubContentOfKind: function (kind) {
				switch (kind)
				{
					case enums.subItemsKind.children:
					case enums.subItemsKind.childrenByUser:
					case enums.subItemsKind.usersOnOrg:
					case enums.subItemsKind.contextChildren:
						return this.childContent;
					case enums.subItemsKind.parents:
					case enums.subItemsKind.parentsByUser:
					case enums.subItemsKind.relatedParents:
					case enums.subItemsKind.relatedParentsByUser:
					case enums.subItemsKind.verifies:
					case enums.subItemsKind.visible:
					case enums.subItemsKind.contextParents:
						return this.parentContent;
					case enums.subItemsKind.verifications:
						return this.childContent;
					case enums.subItemsKind.relatedContent:
					case enums.subItemsKind.relatedContentByUser:
						return this.childContent;
					default:
						return {};
				}
			},
			getSubContentWfidOfKind: function (kind) {
				switch (kind)
				{
					case enums.subItemsKind.children:
					case enums.subItemsKind.childrenByUser:
					case enums.subItemsKind.usersOnOrg:
					case enums.subItemsKind.contextChildren:
						return this["wfcid"];
					case enums.subItemsKind.parents:
					case enums.subItemsKind.parentsByUser:
					case enums.subItemsKind.relatedParents:
					case enums.subItemsKind.relatedParentsByUser:
					case enums.subItemsKind.verifies:
					case enums.subItemsKind.contextParents:
						return this["wffid"];
					case enums.subItemsKind.verifications:
						return this["wfcid"];
					case enums.subItemsKind.relatedContent:
					case enums.subItemsKind.relatedContentByUser:
						return this["wfcid"];
					default:
						return null;
				}
			},
			getSubContentTypeOfKind: function (kind) {
				switch (kind)
				{
					case enums.subItemsKind.children:
					case enums.subItemsKind.childrenByUser:
					case enums.subItemsKind.usersOnOrg:
					case enums.subItemsKind.contextChildren:
						return this["childType"];
					case enums.subItemsKind.parents:
					case enums.subItemsKind.parentsByUser:
					case enums.subItemsKind.relatedParents:
					case enums.subItemsKind.relatedParentsByUser:
					case enums.subItemsKind.verifies:
					case enums.subItemsKind.visible:
					case enums.subItemsKind.contextParents:
						return this["parentType"];
					case enums.subItemsKind.verifications:
						return this["childType"];
					case enums.subItemsKind.relatedContent:
					case enums.subItemsKind.relatedContentByUser:
						return this["childType"];
					default:
						return null;
				}
			},
			getSubListOfKind: function (kind, organizationId) {
				// ⚠ This function only works on JSData injected objects

				if (!organizationId)
					organizationId = wfAuth.getOrganizationId() || null;

				organizationId = organizationId || 0;

				switch (kind)
				{
					case enums.subItemsKind.children: return this.filterChildren({ organizationId: null });
					case enums.subItemsKind.childrenByUser: return this.filterChildren({ organizationId: organizationId });
					case enums.subItemsKind.parents: return this.filterParents({ organizationId: null });
					case enums.subItemsKind.parentsByUser: return this.filterParents({ organizationId: organizationId });
					case enums.subItemsKind.verifications: return this.verifications;
					case enums.subItemsKind.verifies: return this.verifies;
					case enums.subItemsKind.relatedContent: return this.filterRelatedContent({ organizationId: null });
					case enums.subItemsKind.relatedContentByUser: return this.filterRelatedContent({ organizationId: organizationId });
					case enums.subItemsKind.relatedParents: return this.filterRelatedParents({ organizationId: null });
					case enums.subItemsKind.relatedParentsByUser: return this.filterRelatedParents({ organizationId: organizationId });
					case enums.subItemsKind.usersOnOrg: return this.users;
					case enums.subItemsKind.visible: return this.visibility;
					case enums.subItemsKind.contextChildren: return this.contextChildren;
					case enums.subItemsKind.contextParents: return this.contextParents;
					default:
						return [];
				}
			},
			getAllTextual: function () {
				var output;
				switch (this.type) {
					case enums.objectType.statement:
					case enums.objectType.question:
					case enums.objectType.measure:
						return wfPropertyExtractor.getBodyText(this);
					default:
						output = wfPropertyExtractor.getHeaderText(this);

						if (!output || !output.length)
							output = wfPropertyExtractor.getBodyText(this);
						else
							output += wfPropertyExtractor.getBodyText(this);

						return output;
				}
			},
			getMainTextual: function () {
				var output;
				switch (this.type) {
					case enums.objectType.statement:
					case enums.objectType.question:
					case enums.objectType.measure:
						return wfPropertyExtractor.getBodyText(this);
					default:
						output = wfPropertyExtractor.getHeaderText(this);

						if (!output || !output.length)
							output = wfPropertyExtractor.getBodyText(this);

						return output;
				}
			},
			getHeaderText: function () {
				return wfPropertyExtractor.getHeaderText(this);
			},
			getBodyText: function () {
				return wfPropertyExtractor.getBodyText(this);
			},
			getImageUrl: function () {
				return wfPropertyExtractor.getImageUrl(this);
			},
			getRequirement: function (forOrganizationId) {
				var
					requirements,
					defaultReq,
					orgReq,
					wfid = this.wfid
				;

				if (this.type == enums.objectType.virtualDataRelation && typeof this.originalRelationWfid === "string") {
					wfid = this.originalRelationWfid;
				}

				requirements = wfObject.filter({ where: { type: 60, wffid_requirement: wfid, organizationId: forOrganizationId ? { "in": [ null, forOrganizationId  ] } : null } });

				if (requirements.length == 1) {
					return requirements[0];
				}
				else if (requirements.length > 1) {
					orgReq = _.find(requirements, { organizationId: forOrganizationId })
					return orgReq;
				}
				else
					return null;
			},
			getRequirementSpecification: function (forOrganizationId) {
				var
					requirements,
					defaultReq,
					organizationReq,
					actualReq,
					wfid = this.wfid
				;

				if (this.type == enums.objectType.virtualDataRelation && typeof this.originalRelationWfid === "string") {
					wfid = this.originalRelationWfid;
				}

				requirements = wfObject.filter({ where: { type: 60, wffid_requirement: wfid, organizationId: forOrganizationId ? { "in": [ null, forOrganizationId  ] } : null } });

				if (requirements.length == 1) {
					actualReq = requirements[0];
					if (actualReq.organizationId)
						organizationReq = actualReq;
					else
						defaultReq = actualReq;
				}
				else if (requirements.length > 1) {
					organizationReq = _.find(requirements, { organizationId: forOrganizationId })
					defaultReq = _.find(requirements, { organizationId: null })
					actualReq = organizationReq || defaultReq;
				}

				return {
					actual: actualReq,
					standard: defaultReq,
					specific: organizationReq
				};
			},
			isOfAnswerableType: function () {
				return !!answereableType[this.type];
			},
			isAnswerType: function () {
				return !!answerType[this.type];
			},
			isUserDataType: function () {
				return !!userDataObjectTypes[this.type];
			},
			isLinkableType: function () {
				return !!linkableObjectTypes[this.type];
			},
			isRelationalType: function () {
				return !!relationalTypes[this.type];
			},
			typeHasHeader: function () {
				return !!typeHasHeader[this.type];
			},
			getEncodedWfid: function (propertyName) {
				return btoa(btoa(this[propertyName || "wfid"]));
			},
			getKindForChildContent: function () {
				if (!this.isRelationalType())
					return;
				if (!this.organizationId && this.parentData1 === null)
					return enums.subItemsKind.parents;
				if (this.organizationId && this.parentData1 === 2)
					return enums.subItemsKind.verifies;
				if (this.organizationId && this.parentData1 === null)
					return enums.subItemsKind.parentsByUser;
				if (!this.organizationId && this.parentData1 === 1)
					return enums.subItemsKind.relatedParents;
				if (this.organizationId && this.parentData1 === 1)
					return enums.subItemsKind.relatedParentsByUser;
				if (this.organizationId && this.parentData1 === 3)
					return enums.subItemsKind.contextParents;
			},
			getKindForParentContent: function () {
				if (!this.isRelationalType())
					return;
				if (!this.organizationId && this.parentData1 === null)
					return enums.subItemsKind.children;
				if (this.organizationId && this.parentData1 === null)
					return enums.subItemsKind.childrenByUser;
				if (this.organizationId && this.parentData1 === 2)
					return enums.subItemsKind.verifications;
				if (!this.organizationId && this.parentData1 === 1)
					return enums.subItemsKind.relatedContent;
				if (this.organizationId && this.parentData1 === 1)
					return enums.subItemsKind.relatedContentByUser;
				if (this.organizationId && this.parentData1 === 3)
					return enums.subItemsKind.contextChildren;
			}
		}

		_.assign(wfObjectPrototype, {
			getTargetContentOfKind: wfObjectPrototype.getSubContentOfKind
		});

		var itemCompositePrototype = {};

		Object.defineProperty(itemCompositePrototype, 'formattedCreatedAtDate', {
			get: function() {
				if (!this._formattedCreateAt)
					this._formattedCreateAt = moment("2016-02-03T16:02:10").format('DD MM YYYY');
					// this._formattedCreateAt = this.content.getFormattedCreatedAtDate();

				return this._formattedCreateAt;
			}
		});

		var ItemComposite = function (dataRelationOrOptions) {
			var
				dataRelation,
				options,
				itemPrototype,
				content,
				targetKind,
				useChildContent,
				useParentContent,
				targetContentTypeKey,
				targetContentIdKey,
				targetContentWfidKey
			;

			if ("wfid" in dataRelationOrOptions) {
				dataRelation = dataRelationOrOptions;
				targetKind = enums.subItemsKind.children;
			}
			else {
				options = dataRelationOrOptions;
				dataRelation = options.dataRelation;
				itemPrototype = options.itemPrototype;
				targetKind = options.targetKind || enums.subItemsKind.children;
			}

			useChildContent = wfObject.isKindChild(targetKind);
			useParentContent = !useChildContent;

			if (useChildContent) {
				content = dataRelation.childContent;
				targetContentTypeKey = "childType";
				targetContentIdKey = "childId";
				targetContentWfidKey = "wfcid";
			}
			else if (useParentContent) {
				content = dataRelation.parentContent;
				targetContentTypeKey = "parentType";
				targetContentIdKey = "parentId";
				targetContentWfidKey = "wffid";
			}

			if (content && !(content instanceof wfObject.WfObject))
				_.assign(content, wfObjectPrototype);

			if (dataRelation && !(dataRelation instanceof wfObject.WfObject))
				_.assign(dataRelation, wfObjectPrototype);

			_.assign(this, _.defaults(itemPrototype, {
				composite: true,
				isComposite: true,
				targetKind: targetKind,
				type: content ? content.type : dataRelation[targetContentTypeKey],
				wfid: content ? content.wfid : dataRelation[targetContentWfidKey],
				id: dataRelation[targetContentIdKey],
				depth: dataRelation.depth,
				content: content,
				dataRelation: dataRelation,
				relationWfid: dataRelation.wfid || "noRelation",
				parentWfid: dataRelation.wffid || "noParent",
				childWfid: dataRelation.wfcid || "noChild",
				isUserData: content && content.isUserDataType ? content.isUserDataType() : undefined,
				order: dataRelation.order || 0,
				searchSource: content && content.getAllTextual ? content.getAllTextual() : undefined,
				getRelationsOfKind: getRelationsOfKind,
				negotiator: options ? options.negotiator : undefined,
				creatorOrganization: content && content.creatorOrganizationWfid ? content.creatorOrganization : dataRelation.creatorOrganization,
				creatorUser: content && content.creatorUserWfid ? content.creatorUser : dataRelation.creatorUser,
				createdAt: dataRelation.createdAt
			}));
		}

		return service;

		function getRelationsOfKind(kind) {
			var
				self = this,
				output
			;

			if (self.negotiator) {
				// output =  self.negotiator.getRelationsAsItemComposites(sourceContentItem, kind)
			}
		}

		function withLastParentId_OLD(items, lastParentId, lookupParentIds) {
			var x;
			_.every(items, function (item) {
				console.log(item.childContent.title);
				console.log("Lookup parents: ", lookupParentIds);
				console.log("Parents result: ", _.map(wfObject.filter({ where: { type: 73, childType: item.childType, childId: item.childId, parentType: item.parentType, parentId: { in: lookupParentIds } }, orderBy: [ [ 'createdAt', 'ASC' ] ] }), 'parentId'))
				// console.log(wfObject.filter({ where: { type: 73, childType: item.childType, childId: item.childId, parentType: item.parentType, parentId: { in: lookupParentIds } }, orderBy: 'id' }).length);
				x = wfObject.filter({ where: { type: 73, childType: item.childType, childId: item.childId, parentType: item.parentType, parentId: { in: lookupParentIds } }, orderBy: [ [ 'createdAt', 'ASC' ] ] })[0];
				// console.log(x.parentContent.title), '|', item.childContent.title;
				console.log("Required last parent id: ", lastParentId, "Actual last parent id: ", x.parentId);
				console.log(x && x.parentId == lastParentId)
				console.log("----------------------------------")

				return x && x.parentId == lastParentId;
			});
		}

		function withLastParentId(items, predicateParentId) {
			return _
				.chain(items)
				.groupBy('childId')
				.map(function(group) {
					return _.orderBy(group, [ 'createdAt' ], [ 'desc' ])[0];
				})
				.filter({ 'parentId': predicateParentId })
				.value()
			;
		}


		function getAll_answersOnQuestion(question, organizationId)
		{
			var query = { type: 73, childType: 31, parentType: 11, parentId: question.id, parentData1: null };
			var result;
			if (organizationId)
				query.organizationId = organizationId;

			result = wfObject.filter({ where: query, orderBy: [ [ 'createdAt', 'DESC' ] ] });
			return result;
		}

		function get_latestAnswerOnQuestion(question, optionsOrOrganizationId)
		{
			var
				result,
				options,
				query,
				organizationId
			;

			if (typeof optionsOrOrganizationId === "object") { // New mode of getting answer
				var
					options = optionsOrOrganizationId
				;

				result = getIntersectedSubItems(question, {
					kind: enums.subItemsKind.childrenByUser,
					parentType: enums.objectType.question,
					childType: enums.objectType.questionAnswer,
					organizationId: options.organizationId,
					networkId: options.networkId,
					contextParents: options.contextParents
				});

				result = _.orderBy(result, [ 'createdAt' ], [ 'desc' ]);
				result = result[0];
				return result ? result.childContent : undefined;
			}
			else { // Old mode that are still used in some places
				organizationId = optionsOrOrganizationId;
				query = { type: 73, childType: 31, parentType: 11, parentId: question.id, parentData1: null };

				if (organizationId)
					query.organizationId = organizationId;

				result = wfObject.filter({ where: query, orderBy: [ [ 'createdAt', 'DESC' ] ] })[0];
				return result ? result.childContent : undefined;
			}
		}



		function getAll_answersOnQuestions(questions, intersectionSettings) {
			var allAnswersDataRelations = getIntersectedSubItems(questions, {
				kind: enums.subItemsKind.childrenByUser,
				parentType: enums.objectType.question,
				childType: enums.objectType.questionAnswer,
				organizationId: intersectionSettings.organizationId,
				organizationIds: intersectionSettings.organizationIds,
				networkId: intersectionSettings.networkId,
				contextParents: intersectionSettings.contextParents,
				contextParentType: intersectionSettings.contextParentType
			});

			return allAnswersDataRelations;
		}

		function getAll_latestAnswersOnQuestions(questions, intersectionSettings) {
			var allAnswersDataRelations = getAll_answersOnQuestions(questions, intersectionSettings)

			var latestAnswersDataRelations = _.chain(allAnswersDataRelations).sortBy([ "wffid", "createdAt" ]).groupBy("wffid").mapValues(function (dataRelations) {
				return dataRelations[dataRelations.length - 1];
			}).map().value();

			return latestAnswersDataRelations;
		}


		function getAll_answersOnMeasure(measure)
		{
			var result = wfObject.filter({ where: { type: 73, childType: 25, parentType: 21, parentId: measure.id, parentData1: null }, orderBy: [ [ 'childContent.period', 'DESC' ], [ 'createdAt', 'DESC' ] ] });
			return result;
		}

		function get_answersOnMeasureAtPeriod(measure, requirementValue, options)
		{
			var dataRelations, measureAnswers, range, period, periodEnd;

			dataRelations = getIntersectedSubItems(measure, {
				kind: enums.subItemsKind.childrenByUser,
				parentType: enums.objectType.measure,
				childType: enums.objectType.measureAnswer,
				organizationId: options.organizationId,
				networkId: options.networkId,
				contextParents: options.contextParents
			});

			if (requirementValue.indexOf("|") !== -1) { // An interval like 2018-01-01|2018-03-31
				range = requirementValue.split("|");
				period = range[0];
				periodEnd = range[1];

				measureAnswers = wfObject.filter({ where: {
					type: enums.objectType.measureAnswer,
					wfid: { "in": _.map(dataRelations, "wfcid") },
					period: period,
					periodEnd: periodEnd
				}});
			}
			else { // Assumes requirementValue is a year
				measureAnswers = wfObject.filter({ where: {
					type: enums.objectType.measureAnswer,
					wfid: { "in": _.map(dataRelations, "wfcid") },
					year: requirementValue
				}});
			}

			return measureAnswers;
		}

		function get_latestAnswerOnMeasure(measure, options) {
			let result;

			result = getIntersectedSubItems(measure, {
				kind: enums.subItemsKind.childrenByUser,
				parentType: measure.type,
				childType: enums.objectType.measureAnswer,
				organizationId: options.organizationId,
				networkId: options.networkId,
				contextParents: options.contextParents,
				includeVirtualRelations: measure.type === enums.objectType.relativeMeasure ? true : false
			});

			result = wfMeasureService.groupAndFormatAnswersByPeriod(result, { useShortDates: true, take: options.take, useRealContent: true });

			if (options.take) {
				return result;
			}
			else {
				result = result[0];
				if (!result) {
					return;
				}

				if (result.type === enums.objectType.measureAnswer) {
					return result
				}
				else if (result.childType === enums.objectType.measureAnswer) {
					return result.childContent
				}
			}

		}

		// Only supports children type relations
		function getIntersectedSubItems(itemOrItems, options) { // If itemOrItems is an array, all the items must have the same type
			var
				argIsArray = itemOrItems instanceof Array,
				typeOf = typeof itemOrItems,
				parentWfids = argIsArray ? { "in": _.map(itemOrItems, "wfid") } : (typeOf === "string" ? itemOrItems : itemOrItems.wfid),
				parentType = options.parentType ? options.parentType : (argIsArray ? _.get(itemOrItems, "[0].type") : itemOrItems.type),
				query = {
					type: options.includeVirtualRelations ? { "in": [ enums.objectType.dataRelation, enums.objectType.virtualDataRelation ] } : enums.objectType.dataRelation,
					parentType: parentType,
					wffid: parentWfids,
					parentData1: wfObject.getRelationParentDataOfKind(options.kind)
				},
				result,
				intersectionSourceArrays = [],
				visibilityResult
			;

			if (!parentType)
				console.error("Could not determine parentType in getIntersectedSubItems", itemOrItems, options);


			if (options.sourceDataRelations)
				result = options.sourceDataRelations;
			else {
				if (options.organizationId)
					query.organizationId = options.organizationId;
				else if (options.organizationIds)
					query.organizationId = { "in": options.organizationIds }

				if (options.childType)
					query.childType = options.childType;

				if (!options.contextParents && !options.contextParentType && !options.contextParentWfid)
					query.wfxpid = undefined;

				if (options.childWfids) {
					query.wfcid = { "in": options.childWfids };
				}

				result = wfObject.filter({ where: query });
			}


			if (options) {
				options.useContextAwareRelations = true;
				if (options.useContextAwareRelations) {
					/* New way of handling contextParents. The contextParent is stored on the dataRelations themselfs:
					There is only one single dataRelation. Example:
					{
						parentData1: null,
						parentType: 11, parentId: 1, wffid: "11-1",
						childType: 31, childId: 1, wfcid: "31-1",
						contextParentType: 107, contextParentId: 1, wfxpid: "107-1" <---- This is the new concept, contextParent is stored directly on the dataRelation
					}
					*/
					if (options.contextParents || options.contextParentWfid) {
						result = _.filter(result, { wfxpid: options.contextParentWfid || options.contextParents[0] })
					}
					else if (options.contextParentType) {
						result = _.filter(result, { contextParentType: options.contextParentType })
					}
				}

				if (options.organizationId || options.organizationIds) {

					if (options.networkId && _.some(result, function (relation) { return !!userDataObjectTypes[relation.childType] })) {
						visibilityResult = getVisibilityTags(options.childType, options.organizationId || options.organizationIds, options.networkId);

						// Instead of using _.intersectionBy we use .difference/_.differenceBy
						// because _.intersectionBy doesn't include duplicates from the first array argument.
						// Duplicates can be present when data has been attached by picking items from a custom list (like a list of countries).
						// When this attached data is viewed aggregated from multiple organizations the same wfcid value will appear multiple times in the array and each duplicate need to be kept.
						result = _.difference(result, _.differenceBy(result, visibilityResult, "wfcid"));

					}

					if (!options.useContextAwareRelations && options.contextParents) {
						/* Old way of handling contextParents - through intersection.
							The contextParent is stored as a separate dataRelation with its own relation kind (enums.subItemskind.contextChildren, 14).
							There are always two dataRelations:
							One for the normal relation (for example between the question and the answer)
							{
								parentData1: null,
								parentType: 11, parentId: 1, wffid: "11-1",
								childType: 31, childId: 1, wfcid: "31-1",
							}
							And another one for the relation between contextParent and the answer
							{
								parentData1: 3, <---- Represents enums.subItemskind.contextChildren/contextParents
								parentType: 107, parentId: 1, wffid: "107-1",
								childType: 11, childId: 1, wfcid: "11-1",
							}

							Just like with visibilityTags, the final resulting array must be determined with intersection
						*/
						Array.prototype.push.apply(intersectionSourceArrays, getContextParentsArrays(options.childType, options.organizationId || options.organizationIds, options.contextParents));
						intersectionSourceArrays.unshift(result);

						result = intersectDataRelationArrays(intersectionSourceArrays, "wfcid");
					}
				}

				if (options.makeItemComposites)
					result = makeItemComposites(result);

			}

			return result;
		}

		function getSubItems(itemOrWfid, kindOrOptions) {
			var
				query = {
					type: { "in": [ enums.objectType.dataRelation, enums.objectType.virtualDataRelation ] }
				},
				kind,
				result,
				intersectionSourceArrays = []
			;

			if (typeof kindOrOptions === "number") {
				kind = kindOrOptions;
				query.parentData1 = wfObject.getRelationParentDataOfKind(kind);
			}

			if (typeof itemOrWfid === "string") {
				query[wfObject.getForeignKeyOfKind(kind)] = itemOrWfid;
			}

			result = wfObject.filter({ where: query });

			return result;
		}

		function getVisibilityTags(objectType, organizationIdOrIds, networkId) {
			var query = {
				type: enums.objectType.visibilityTag,
				organizationId: organizationIdOrIds instanceof Array ? { "in": organizationIdOrIds } : organizationIdOrIds,
				networkId: networkId
			};

			if (objectType)
				query.objectType = objectType;

			return wfObject.filter({ where: query });
		}

		function getContextParentsArrays(objectType, organizationIdOrIds, contextParentWfids) {
			var resultArrays = [];

			for (var i = 0, len = contextParentWfids.length; i < len; i++) {
				resultArrays.push(getContextParentRelations(objectType, organizationIdOrIds, contextParentWfids[0]));
			}

			return resultArrays;
		}

		function getContextParentRelations(objectType, organizationIdOrIds, contextParentWfid) {
			var query = {
				type: enums.objectType.dataRelation,
				parentData1: 3,
				wffid: contextParentWfid,
				organizationId: organizationIdOrIds instanceof Array ? { "in": organizationIdOrIds } : organizationIdOrIds
			};

			if (objectType)
				query.childType = objectType;

			return wfObject.filter({ where: query });
		}

		// arrays: An array of arrays to intersect
		// byProperty: The property to intersect by
		// orderBy: (optional) An array of orderBy arguments that will be passed to _.orderBy.apply
		function intersectDataRelationArrays(arrays, byProperty, orderBy) {
			var
				wrappedFirstArray = _.chain(arrays[0]),
				intersectionArgumentsArray,
				intersectionResult
			;

			intersectionArgumentsArray = arrays.splice(1);
			intersectionArgumentsArray.push(byProperty);

			intersectionResult = wrappedFirstArray.intersectionBy.apply(wrappedFirstArray, intersectionArgumentsArray);

			if (orderBy) {
				intersectionResult = intersectionResult.orderBy.apply(intersectionResult, orderBy);
			}

			return intersectionResult.value();
		}

		function getAll_intersectedVerifiedBy(items, verification)
		{
			var wfcids = _.map(verification.verifies, 'wffid');
			return _.filter(items, function (item) {
				return wfcids.indexOf(item.wfcid) !== -1;
			})
		}

		function getAll_intersectedChildrenOf() // Pass in several objects. Returns the children of all objects interstected by wfcid
		{
			var
				parentItems = arguments[1] ? _.toArray(arguments) : [ arguments[0] ],
				wfcids,
				children,
				parent,
				query
			;

			// Loops through all items and gets the children on each,
			// with a filter that uses the children result from the previuos iteration.
			// The final value of children is the interstected childrens of all parent items
			for (var i = 0, len = parentItems.length; i < len; i++) {
				parent = parentItems[i];
				query = undefined;

				if (!parent)continue;

				if (parent.length && parent.length === 2)
				{
					query = parent[1];
					parent = parent[0];
					if (wfcids)
						query.wfcid = { in: wfcids };
				}
				else if (wfcids)
					query = { wfcid: { in: wfcids } };

				if (query)
					children = parent.filterChildren ? parent.filterChildren(query) : undefined;
				else if (parent.type == 67)
					children = parent.verifies;
				else
					children = parent.childs;

				if (len !== 1)
					wfcids = _.map(children, 'wfcid');
			}
			return children || [];
		}

		function get_lastIntersectedChildOf()
		{
			var items = getAll_intersectedChildrenOf.apply(this, arguments);
			return items[items.length - 1];
		}

		function get_last(items)
		{
			return items[items.length - 1];
		}

		function getAll_childrenOf(obj)
		{
			var result = wfObject.filter({ where: { type: 73, parentType: obj.type, parentId: obj.id, parentData1: null } });
		}

		function getAll_parentsOf(obj)
		{
			var result = wfObject.filter({ where: { type: 73, childType: obj.type, childId: obj.id, parentData1: null } });
		}

		function getAll_relatedContentByCreatorOf(obj)
		{
			var result = wfObject.filter({ where: { type: 73, parentType: obj.type, parentId: obj.id, parentData1: 1, userId: null } });
		}

		function getAll_relatedContentByUserOf(obj)
		{
			dataOps.getSubItems(obj, 5);
			console.log(auth);
			return { where: { type: 73, parentType: obj.type, parentId: obj.id, parentData1: 1, userId: wfAuth.getWorldFavorUserId() } };
		}

		function filterItemsWithAnySettingForAttachingData(items) {
			var outputRelations;

			outputRelations = _.filter(items, function (item) {
				return hasItemAnySettingForAttachingData(item)
			});

			return outputRelations;
		}

		function hasItemAnySettingForAttachingData(item) {
			var
				output,
				relation,
				content,
				itemConditions,
				isComposite
			;

			if (item.composite || item.isComposite) {
				relation = item.dataRelation;
				content = item.content;
				isComposite = true;
			}
			else if (item.type === enums.objectType.dataRelation || item.type === enums.objectType.virtualDataRelation) {
				relation = item;
			}

			output = (relation.settings && relation.settings.attachObjectTypes) || (relation.originalRelation && relation.originalRelation.settings && relation.originalRelation.settings.attachObjectTypes)

			if (!output) {
				if (!content)
					content = relation.childContent;

				if (content) {
					if (content.type !== enums.objectType.structure)
						return false;

					itemConditions = content.conditions;
					output = itemConditions && (itemConditions.pickerSettings || (("dataRelationByUser" in itemConditions) && itemConditions.objectTypes));
				}

				if (!output && isComposite && item.parent)
					output = _.get(item.parent, "content.conditions.subItemsSettings.pickerSettings")
			}

			return output;
		}

		function makeItemComposites(dataRelations, options) {
			var childKinds_withOrg_byParentData1Value = {
				1: enums.subItemsKind.relatedContentByUser,
				2: enums.subItemsKind.verifications,
				3: enums.subItemsKind.contextChildren,
			};

			var childKinds_withoutOrg_byParentData1Value = {
				1: enums.subItemsKind.relatedContent,
				4: enums.subItemsKind.linkageTo,
				5: enums.subItemsKind.linkageChildren
			};

			childKinds_withOrg_byParentData1Value[null] = enums.subItemsKind.childrenByUser;
			childKinds_withoutOrg_byParentData1Value[null] = enums.subItemsKind.children;

			if (!options)
				options = {};

			var itemComposites = _.map(dataRelations, function (dataRelation) {
				var
					// content = dataRelation.childContent,
					output,
					targetKind
					//  = {
					// 	composite: true,
					// 	isComposite: true,
					// 	type: content ? content.type : dataRelation.childType,
					// 	wfid: content ? content.wfid : dataRelation.wfcid,
					// 	id: dataRelation.childId,
					// 	depth: dataRelation.depth,
					// 	content: content,
					// 	dataRelation: dataRelation,
					// 	relationWfid: dataRelation.wfid || "noRelation",
					// 	parentWfid: dataRelation.wffid || "noParent",
					// 	isUserData: content && content.isUserDataType ? content.isUserDataType() : undefined,
					// 	order: dataRelation.order || 0,
					// 	searchSource: content && content.getAllTextual ? content.getAllTextual() : undefined
					// }
				;

				if (!options.targetKind) {
					options.targetKind = getSubItemsKind(dataRelation);
				}

				output = new ItemComposite({
					dataRelation: dataRelation,
					itemPrototype: options ? _.clone(options.itemPrototype) : undefined,
					targetKind: options ? options.targetKind : undefined
				})

				if (dataRelation.itemCompositeInstructions && dataRelation.itemCompositeInstructions.childContent) {
					output.content = dataRelation.itemCompositeInstructions.childContent
					output.isContentLoaded = true;
				}

				return output
			});


			if (_.get(options, "sortHierarchically") && !_.get(options, "rootContentWfid")) {
				console.error("dataQuery.makeItemComposites function need rootContentWfid to sort hierarchically.")
			}

			if (_.get(options, "sortHierarchically") && _.get(options, "rootContentWfid")) {
				(function () {
					var
						itemCompositesByParentWfid = _.groupBy(itemComposites, "parentWfid"),
						orderedOutput = [],
						absoluteOrder = 0,
						maxDepth = 10
					;

					buildOrderedFlatList(options.rootContentWfid, 0);

					itemComposites = orderedOutput;

					function buildOrderedFlatList(wfid, depth) {
						if (depth >= maxDepth || wfid === "noChild")
							return;

						_.forEach(_.sortBy(itemCompositesByParentWfid[wfid], "dataRelation.order"), function (itemComposite) {
							itemComposite.absoluteOrder = absoluteOrder++;
							orderedOutput.push(itemComposite);

							buildOrderedFlatList(itemComposite.childWfid, depth + 1);
						});
					}
				})();
			}

			return itemComposites;

			function getSubItemsKind(dataRelation) {
				if (dataRelation.organizationId === null) {
					return childKinds_withoutOrg_byParentData1Value[dataRelation.parentData1];
				}
				else {
					return childKinds_withOrg_byParentData1Value[dataRelation.parentData1];
				}
			}
		}

		function getPickerSettings(item, ticket, pickerSettingsPrototype, fromSubItemsSettingsCondition) {
			var
				settings = undefined,
				pickerSettings = undefined,
				itemComposite, itemContent, itemDataRelation,
				objectTypesToAdd,
				subItemsKind,
				pickerSettingsFromItemConditions,
				pickerSettingsFromRelationSettings,
				pickerSettingsFromSubItemsSettings
			;

			if (item && item.isComposite) {
				itemComposite = item;
				itemContent = item.content;
				itemDataRelation = item.dataRelation;

				settings = itemDataRelation.settings;

				if (itemDataRelation.type == enums.objectType.virtualDataRelation && typeof itemDataRelation.originalRelationWfid === "string")
					settings = _.get(itemDataRelation.originalRelation, "settings");
				if (itemComposite && itemComposite.originalRelation && itemComposite.originalRelation.settings)
					settings = itemComposite.originalRelation.settings;

				if (fromSubItemsSettingsCondition) {
					pickerSettingsFromSubItemsSettings = _.get(itemContent, "conditions.subItemsSettings.pickerSettings");
					subItemsKind = enums.subItemsKind.relatedContentByUser;
				}
				else {
					if (settings && settings.objectTypes)
						objectTypesToAdd = settings.objectTypes;
					else if (itemContent && itemContent.conditions && itemContent.conditions.objectTypes)
						objectTypesToAdd = itemContent.conditions.objectTypes;

					subItemsKind = settings && settings.pickerRelationTargetKind ? settings.pickerRelationTargetKind : enums.subItemsKind.relatedContentByUser;

					pickerSettingsFromItemConditions = itemContent.conditions ? itemContent.conditions.pickerSettings : undefined;
					pickerSettingsFromRelationSettings = settings ? settings.pickerSettings : undefined;
				}

				if (!pickerSettingsPrototype && !objectTypesToAdd && !pickerSettingsFromItemConditions && !pickerSettingsFromRelationSettings && !pickerSettingsFromSubItemsSettings)
					return null;

				pickerSettings = {
					ticket: ticket,
					objectTypes: objectTypesToAdd,
					relationTarget: { item: itemContent, kind: subItemsKind }
				};

				if (pickerSettingsPrototype)
					pickerSettings = _.defaultsDeep(_.cloneDeep(pickerSettingsPrototype), pickerSettings);

				if (fromSubItemsSettingsCondition) {
					pickerSettings = _.defaultsDeep(_.cloneDeep(pickerSettingsFromSubItemsSettings), pickerSettings);
				}
				else {
					pickerSettings = _.defaultsDeep(_.cloneDeep(pickerSettingsFromRelationSettings), _.cloneDeep(pickerSettingsFromItemConditions), pickerSettings);
				}

				if (pickerSettings.relationTarget.item === "@currentContextParent") {
					// In this scenario show the original itemContent at the top in the picker instead of @currentContextParent item
					pickerSettings.displayTopItem = itemContent;
				}
			}
			else
				console.error("itemComposite not passed to getPickerSettings function");

			return pickerSettings;
		}

		function getHierarchyAsList(obj, childTypes, options) {
			var
				settings = _.assign({
					asItemComposites: true,
					maxDepth: 10,
					filter: undefined,


					// If true each "childs" and "childContent" property on every item in the hierarchy will be accessed to build up the flat list
					// instead of querying JSData cache level-by-level. Has to be true when passing an item that is not in the JSData cache.
					accessPropertiesDirectly: false
				}, options),
				childrenRelationType = [ enums.objectType.dataRelation, enums.objectType.virtualDataRelation ], // The types used to query children on each level.
				intersectionTargetWfid = null,
				dataRelations = [],
				childContents = [],
				maxDepth = settings.maxDepth,
				wfids,
				asItemComposites = settings.asItemComposites,
				output = [],
				contentsByWfid,
				relationsByParentWfid,
				absoluteOrder = 0,
				buildOrderedFlatList = function (wfid, depth) {
					var contents, relations;

					if (depth >= maxDepth)
						return;

					_.forEach(_.sortBy(relationsByParentWfid[wfid], "order"), function (dataRelation) {
						var content = contentsByWfid[dataRelation.wfcid];

						if (!content)
							return;

						if (asItemComposites) {
							output.push(new ItemComposite({
								dataRelation: dataRelation,
								itemPrototype: { order: absoluteOrder++ }
							}))
							// output.push({
							// 	composite: true,
							// 	isComposite: true,
							// 	type: content.type,
							// 	id: content.id,
							// 	wfid: content.wfid,
							// 	depth: dataRelation.depth,
							// 	content: content,
							// 	dataRelation: dataRelation,
							// 	relationWfid: dataRelation.wfid || "noRelation",
							// 	parentWfid: dataRelation.wffid || "noParent",
							// 	isUserData: content.isUserDataType ? content.isUserDataType() : undefined,
							// 	order: absoluteOrder++,
							// 	searchSource: content.getAllTextual ? content.getAllTextual() : undefined
							// })
						}
						else
							output.push(content);

						buildOrderedFlatList(content.wfid, depth + 1);
					});
				},
				mockedItemPrototype = {
					getAllTextual: function () {
						var output;
						switch (this.type) {
							case enums.objectType.statement:
							case enums.objectType.question:
							case enums.objectType.measure:
								return wfPropertyExtractor.getBodyText(this);
							default:
								output = wfPropertyExtractor.getHeaderText(this);

								if (!output || !output.length)
									output = wfPropertyExtractor.getBodyText(this);
								else
									output += wfPropertyExtractor.getBodyText(this);

								return output;
						}
					},
					getMainTextual: function () {
						var output;
						switch (this.type) {
							case enums.objectType.statement:
							case enums.objectType.question:
							case enums.objectType.measure:
								return wfPropertyExtractor.getBodyText(this);
							default:
								output = wfPropertyExtractor.getHeaderText(this);

								if (!output || !output.length)
									output = wfPropertyExtractor.getBodyText(this);

								return output;
						}
					},
					getHeaderText: function () {
						return wfPropertyExtractor.getHeaderText(this);
					},
					getBodyText: function () {
						return wfPropertyExtractor.getBodyText(this);
					},
					getImageUrl: function () {
						return wfPropertyExtractor.getImageUrl(this);
					},
					isOfAnswerableType: function () {
						return !!answereableType[this.type];
					},
					isAnswerType: function () {
						return !!answerType[this.type];
					},
					isUserDataType: function () {
						return !!userDataObjectTypes[this.type];
					},
					isLinkableType: function () {
						return !!linkableObjectTypes[this.type];
					},
					isRelationalType: function () {
						return !!relationalTypes[this.type];
					},
					typeHasHeader: function () {
						return !!typeHasHeader[this.type];
					},
					getEncodedWfid: function (propertyName) {
						return btoa(btoa(this[propertyName || "wfid"]));
					}
				}
			;

			if (settings.accessPropertiesDirectly) {
				handleChildrenDirectly(obj, maxDepth - 1)
			}
			else {
				if (_.get(obj.conditions, "intersectionSourceWfid")) {
					childrenRelationType = [ enums.objectType.virtualDataRelation ];
					intersectionTargetWfid = obj.wfid;
				}

				getChildRelationsOfItems([ obj.wfid ], 0);
				wfids = _.chain(dataRelations).map("wfcid").uniq().value();

				childContents = wfObject.filter({ where: { wfid: { "in": wfids } } });
			}

			contentsByWfid = _.keyBy(childContents, "wfid");
			relationsByParentWfid = _.groupBy(dataRelations, "wffid");

			buildOrderedFlatList(obj.wfid, 0);

			if (settings.filter) {
				output = _.filter(output, settings.filter);
			}

			if (settings.asItemComposites) {
				var itemCompositesByWfid = _.keyBy(output, "wfid");
				_.each(output, function (item) {
					item.parent = itemCompositesByWfid[item.parentWfid];
				})
			}

			return output;

			function handleChildrenDirectly(item, localMaxDepth) {
				var
					children = item ? item.childs : undefined,
					childContent,
					dataRelation
				;

				if (!children)
					return;

				for (var i = 0, len = children.length; i < len; i++) {
					dataRelation = children[i];
					dataRelations.push(dataRelation);
					childContent = children[i].childContent;

					_.assign(dataRelation, mockedItemPrototype);

					if (childContent) {
						_.assign(childContent, mockedItemPrototype);

						childContents.push(childContent)
						if (localMaxDepth)
							handleChildrenDirectly(childContent, localMaxDepth - 1)
					}
				}
			}

			function getChildRelationsOfItems(parentWfids, depth) {
				// Get only the relations
				var query = { where: {
					type: { "in": childrenRelationType },
					parentData1: null,
					wffid: { "in": parentWfids }
				} };

				if (intersectionTargetWfid)
					query.where.intersectionTargetWfid = intersectionTargetWfid;

				var childrenRelations = wfObject.filter(query);

				if (childTypes) {
					childrenRelations = _.filter(childrenRelations, function (relation) {
						return _.includes(childTypes, relation.childType);
					});
				}

				Array.prototype.push.apply(dataRelations, childrenRelations);

				if (depth < maxDepth) {
					getChildRelationsOfItems(_.chain(childrenRelations).filter({ parentType: enums.objectType.structure }).map("wfcid").value(), depth + 1);
				}
			}
		}

		function getAllRequirements(items, organizationId) {

		}

		function getAnswerStatistics(questionItemComposites, organizationId) {
			var
				statsArray,
				questionsCount = 0
			;

			var questions = _.chain(questionItemComposites).filter({ type: enums.objectType.question }).value();
			if (questions.length > 0) {
				var questionsByWfid = _.keyBy(questions, "wfid");
				var questionWfids = _.map(questions, "wfid");
				var latestQuestionAnswerDataRelations = _.chain(wfObject.filter({ where: {
					type: 73,
					parentData1: null,
					wffid: { "in": questionWfids },
					parentType: enums.objectType.question,
					childType: enums.objectType.questionAnswer,
					organizationId: organizationId
				}})).sortBy(["wffid", "createdAt"]).groupBy("wffid").mapValues(function (dataRelations) {
					return _.last(dataRelations);
				}).map().value();
				var latestAnswers = wfObject.filter({ where: {
					type: enums.objectType.questionAnswer,
					wfid: { "in": _.map(latestQuestionAnswerDataRelations, "wfcid") } }
				});
				var uniqueQuestionAnswerTypes = _.chain(latestAnswers).uniqBy("wfcid").map("childContent").uniqBy("wfid").value();
				var questionItemsByLatestAnswerTypeWfid = _.chain(uniqueQuestionAnswerTypes).keyBy("wfid").mapValues(function (questionAnswerType) {
					var output = [];

					var answerWfids = _.chain(latestAnswers).filter({ wfcid: questionAnswerType.wfid }).map("wfid").value();
					var relations = _.filter(latestQuestionAnswerDataRelations, function (dataRelation) {
						return _.includes(answerWfids, dataRelation.wfcid);
					});
					var questionsWithSpecificAnswer = _.map(relations, function (dataRelation) {
						return questionsByWfid[dataRelation.wffid];
					})
					output = questionsWithSpecificAnswer

					return output;
				}).value();
				var questionWfids_withAnswers = _.map(latestQuestionAnswerDataRelations, "wffid");
				var unansweredQuestionItems = _.filter(questions, function (item) {
					return !_.includes(questionWfids_withAnswers, item.wfid);
				})
				var unansweredQuestionItemsCount = unansweredQuestionItems.length;
				var answeredQuestionItemsCount = latestQuestionAnswerDataRelations.length;

				questionsCount = questions.length;


				statsArray = _.chain(uniqueQuestionAnswerTypes).map(function (questionAnswerType) {
					var items = questionItemsByLatestAnswerTypeWfid[questionAnswerType.wfid];
					// vm.aggregatedParents.itemsByParentWfid[questionAnswerType.wfid] = items;

					return {
						id: questionAnswerType.wfid,
						wfid: questionAnswerType.wfid,
						type: questionAnswerType.type,
						title: questionAnswerType.getMainTextual(),
						content: questionAnswerType,
						count: items.length,
						percentage: getPercentage(items.length, questionsCount),
						showButton: true,
						showProgressBar: true
						// imageUrl: parent.getImageUrl()
					};
				}).sortBy("id").value();


				if (unansweredQuestionItemsCount > 0) {
					statsArray.unshift({
						id: "unanswered",
						type: enums.objectType.questionAnswerType,
						title: $translate.instant("Unanswered"),
						count: unansweredQuestionItemsCount,
						showButton: true
					});
					// vm.aggregatedParents.itemsByParentWfid["0-unansweredQuestions"] = unansweredQuestionItems;
				}

				statsArray.unshift({
					id: "answered",
					type: enums.objectType.questionAnswerType,
					title: $translate.instant("TotalAnswered"),
					count: answeredQuestionItemsCount,
					percentage: getPercentage(answeredQuestionItemsCount, questionsCount),
					showProgressBar: true
				});
			}
			else {
				statsArray = [];
			}

			return {
				questionsCount: questionsCount,
				statsArray: statsArray,
				byAnswer: _.keyBy(statsArray, "id")
			};
		}

		function getPercentage(part, total) {
			var output;

			if (total === 0)
				return 0;

			output = part / total * 100.0;

			output = Math.round(output * 10) / 10;
			// output = output.toString();

			return output;
		}

		function getRelations(options) {
			var
				relations,
				queryWhere = {
				}
			;

			if (options.kind)
				options.parentData1 = wfObject.getRelationParentDataOfKind(options.kind);
			else if (options.kinds instanceof Array)
				options.parentData1 = { "in": _.map(options.kinds, function (kind) { return wfObject.getRelationParentDataOfKind(kind) }) };

			if (typeof options.parent === "object") {
				queryWhere.parentType = options.parent.type;
				queryWhere.parentId = options.parent.id;
			}
			else if (typeof options.parentType === "number")
				queryWhere.parentType = options.parentType;

			if (options.parentWfids instanceof Array) {
				queryWhere.wffid = { "in": options.parentWfids };
			}
			else if (typeof options.parentWfid === "string") {
				queryWhere.wffid = options.parentWfid;
			}

			if (typeof options.child === "object") {
				queryWhere.childType = options.child.type;
				queryWhere.childId = options.child.id;
			}
			else if (typeof options.childType === "number")
				queryWhere.childType = options.childType;

			if (options.childWfids instanceof Array) {
				queryWhere.wfcid = { "in": options.childWfids };
			}
			else if (typeof options.childWfid === "string") {
				queryWhere.wfcid = options.childWfid;
			}

			if (typeof options.organizationId === "number")
				queryWhere.organizationId = options.organizationId;
			else if (options.organizationIds instanceof Array) {
				queryWhere.organizationId = { "in": options.organizationIds };
			}
			else
				queryWhere.organizationId = null;


			if (typeof options.userId === "number")
				queryWhere.userId = options.userId;


			if (options.useDeleted === true)
				queryWhere.type = enums.objectType.historicDataRelation;
			else if (options.useVirtual === true)
				queryWhere.type = enums.objectType.virtualDataRelation;
			else if (options.includeVirtualRelations === true)
				queryWhere.type = { "in": [ enums.objectType.dataRelation, enums.objectType.virtualDataRelation ] };
			else
				queryWhere.type = enums.objectType.dataRelation;


			if (typeof options.contextParent === "object" && options.contextParent.wfid)
				queryWhere.contextParentWfid = options.contextParent.wfid;
			else if (typeof options.contextParentWfid === "string")
				queryWhere.contextParentWfid = options.contextParentWfid;
			else
				queryWhere.contextParentWfid = null;


			relations = wfObject.filter({ where: queryWhere });

			return relations
		}
	}
})();
