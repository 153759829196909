import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { DataManagerTableWithPage } from './DataManagerTable'
import { injectIntl } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import Loading from '@worldfavor/portal/scenes/Loading'
import { MAX_PAGE_CONTENT_WIDTH } from '@worldfavor/constants'
import { useDispatch } from 'react-redux'
import { loadSubItemsPageForDataCollector } from '@worldfavor/portal/actions/dataThunk'
import { FilterTypes } from '@worldfavor/constants'
import useFilters from '@worldfavor/hooks/filteringHooks'
import StyledPageHeader from '@worldfavor/components/Header/StyledPageHeader'
import { useDialogState } from '@worldfavor/hooks'
import Dialog from '@material-ui/core/Dialog'
import { withRouter } from 'react-router'
import useDataFetcher, { useFiltersFetcher } from '@worldfavor/hooks/dataFetcher'
import ErrorDialog from '../Dialog/ErrorDialog'
import _ from 'lodash'

const useStyles = makeStyles(() => ({
  [`@media (min-width: ${MAX_PAGE_CONTENT_WIDTH}px)`]: {
    maxWidthGrid: props => ({
      maxWidth: props.disableMaxWidthGrid ? null : MAX_PAGE_CONTENT_WIDTH,
    }),
  },
  dialog: {
    overflowY: 'scroll',
  },
}))

type Props = {
  // --- mandatory props ---
  title: string,
  contentType: string,
  networkId: number,
}

const DataManager = (props: Props) => {
  const { title,
    networkId,
    contentType,
    fetch,
    filtersEndpoint,
    dialogContent,
    getTableColumns,
    renderRowCell,
    renderColumnCell,
    customRowAction,
  } = props
  const classes = useStyles(props)
  const [sceneLoading, setSceneLoading] = useState(true)
  const dispatch = useDispatch()
  const useInvertedFiltering = true
  const [searchText] = useState('')
  const [open, _openDialog, closeDialog] = useDialogState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [isErrorDialogOpen, openErrorDialog, closeErrorDialog] = useDialogState(false)

  const rowActions = {
    openDialog,
    customRowAction,
  }

  const { filtersLoading, fetchedFilters } = useFiltersFetcher(filtersEndpoint(networkId))

  const {
    filters,
    onFilterChange,
    activeFilters,
  } = useFilters(
    fetchedFilters,
    { useInvertedFiltering },
  )

  function getFilterConditionsForFilters() {
    if (activeFilters) {
      const activeFiltersArray = _.values(activeFilters)
      return activeFiltersArray.map((filter) => {
        return {
          filterType: filter.filterType,
          [filter.filterValueType]: filter.selectedOptions.map(option => option.value),
        }
      })
    }
    return []
  }

  const filterConditions = [
    {
      filterType: FilterTypes.TextSearch,
      stringValues: [searchText],
    },
    {
      filterType: FilterTypes.NetworkIds,
      numValues: [networkId],
    },
    ...getFilterConditionsForFilters(),
  ]

  const fetchData = (source, pageNumber, pageSize) => dispatch(
    loadSubItemsPageForDataCollector(
      fetch,
      pageNumber,
      pageSize,
      `${networkId}:${contentType}`,
      source,
      {},
      filterConditions,
    ),
  )

  const {
    resultCount,
    pageNumber,
    pageSize,
    pageId,
    requestId,
    error,
    setPageNumber,
    setPageSize,
  } = useDataFetcher(fetchData, [activeFilters], {
    onSuccess: () => setSceneLoading(false),
    onError: () => !isErrorDialogOpen && openErrorDialog(),
  })

  function onChangeRowsPerPage(event) {
    setPageSize(event.target.value)
  }

  function onPageChange(event, pageNumber) {
    setPageNumber(pageNumber)
  }

  function openDialog(event, item) {
    setSelectedItem(item)
    _openDialog()
  }

  const renderContent = () =>  {
    if (error) {
      return <ErrorDialog open={isErrorDialogOpen} closeDialog={closeErrorDialog} showReloadButton showContactSupport />
    }
    if (sceneLoading || filtersLoading || !requestId || !pageId) {
      return <Loading />
    }
    return (
      <DataManagerTableWithPage
        requestId={requestId}
        pageId={pageId}
        rowsPerPage={pageSize}
        page={pageNumber}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onPageChange={onPageChange}
        title={title}
        contentType={contentType}
        resultCount={resultCount}
        filters={filters}
        onFilterChange={onFilterChange}
        onRowClick={dialogContent && openDialog}
        getTableColumns={getTableColumns}
        renderRowCell={renderRowCell}
        rowActions={rowActions}
        renderColumnCell={renderColumnCell}
      />
    )
  }

  return (
    <>
      <Grid container justify={'center'}>
        <Grid classes={{ item: classes.maxWidthGrid }} item xs={12}>
          <div data-cy="data-manager-title">
            <StyledPageHeader title={title} />
          </div>
          {renderContent()}
        </Grid>
      </Grid>
      {open && selectedItem && dialogContent && (
        <Dialog
          onClose={closeDialog}
          open={open}
          fullWidth
          maxWidth="xl"
          className={classes.dialog}
        >
          {dialogContent(selectedItem, closeDialog)}
        </Dialog>
      )}
    </>
  )
}

export default withRouter(injectIntl(DataManager))
