import * as enums from '@worldfavor/constants/enums'

(function() {
'use strict';

	angular
		.module('wf.common')
		.component("wfPublicInfluencesWidget", {
			templateUrl: "scripts/wf/publicInfluences/wfPublicInfluencesWidget.component.html",
			controller: PublicInfluencesWidgetController,
			controllerAs: "vm",
			bindings: {
				networkId: "<",
				objectId: "<",
				widgetId: "<",
			},
		});

	PublicInfluencesWidgetController.$inject = ['$scope', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', 'formSchemaService', '$q', 'wfTranslate', '$timeout','$stateParams','$rootScope','apiProxy','wfAuth','NgMap', '$sanitize','$state'];
	function PublicInfluencesWidgetController($scope, dataOps, modal, wfObject, dataQuery, $translate, formSchemaService, $q, wfTranslate, $timeout, $stateParams, $rootScope, apiProxy, wfAuth, NgMap, $sanitize, $state) {
		var
			vm = this,
			organizationsByParentId = {},
			allInfluences,
			organizationIds,
			answerableItemsFilterCache = {},
			infoWindow
		;

		wfAuth.setAuthenticationHeaderValue("widget|" + vm.widgetId);

		$scope.modal = modal;
		$scope.$sanitize = $sanitize;

		vm.influences = [];
		vm.allInfluences = [];
		vm.onSearch = function (items) {
			// console.log("onSearch", items.length);
		};
		vm.parents = [];
		vm.selectedParentId;
		vm.applyFilter = applyFilter;
		vm.selectAnswerableFilter = selectAnswerableFilter;
		vm.removeAnswerableFilter = removeAnswerableFilter;
		vm.showItemCharts = true;
		vm.childContentByRelationId = {};
		vm.relatedContentByOrganizationId = {};
		vm.toggleRelatedContent = toggleRelatedContent;
		vm.text = {
			readMore: $translate.instant("ReadMore"),
			readLess: $translate.instant("Hide")
		}
		vm.onMarkerClick = angular.noop
		
		
		activate();

		////////////////

		function activate() {
			apiProxy("public.influenceRoomWidget", {
				networkId: vm.networkId,
				objectId: vm.objectId,
				widgetId: vm.widgetId,
				culture: wfAuth.getCulture(),
			}).then(function (res) {
				/*
				{
					structure,
					organizations,
					influences,
					categories
				}
				*/
				vm.structure = res.structure;
				wfObject.inject(vm.structure);
				// vm.structureChildren = _.map(res.structure.childs, "childContent");

				vm.structureLoaded = true;
				// vm.structureCreatorOrganization = structureCreatorOrganization;
				vm.organizations = []

				vm.influenceObjects = res.influences;
				vm.allInfluences = allInfluences = sortItems(vm.influenceObjects);
				_.each(allInfluences, function (inf) {
					let orgId
					if (inf.contextParentWfids && inf.contextParentWfids.indexOf("101-") === 0) {
						orgId = parseInt(inf.contextParentWfids.split("-")[1])
					} else {
						orgId = inf.organizationId
					}
					inf.actualTargetOrganizationId = orgId
					let org = res.organizations.filter(x => x.id === orgId)[0];
					if (org) {
						vm.organizations.push(org)
					}
				})

				vm.organizationIds = _.map(vm.organizations, "id");
				_.forEach(vm.organizations, function (org) {
					new Image().src = org.imageUrl;
				})
				vm.locations = vm.organizations.map(x => x.location).filter(x => Boolean(x))
				vm.filteredLocations = _.clone(vm.locations);
				vm.allLoaded = true;
				Array.prototype.push.apply(vm.influences, vm.allInfluences);

				_.each(allInfluences, function (inf) {
					let orgId = inf.actualTargetOrganizationId;
					let orgLocations = _.chain(vm.locations).filter({ organizationId: orgId });
					let org = vm.organizations.filter(x => x.id === orgId)[0] || {};
					inf.organization = org;
					inf.searchSource = [
						org.name,
						org.registrationNumber,
						orgLocations.map("name").join(" ").value(),
						orgLocations.map("formattedAddress").join(" ").value()
					].join(" ");
				});

				const orgIdsByCategoryId = _.chain(res.categories).keyBy(x => x.category.id).mapValues(x => x.organizationIds).value()

				const categoryGroupWfid = "71-33208"
				const categoryGroup = res.categoryGroups.find(x => x.wfid === categoryGroupWfid)
				if (categoryGroup) {
					const categoryWfids = categoryGroup.categoryWfids
					const categories = res.categories.filter(x => categoryWfids.includes(x.category.wfid))
					vm.categoriesByOrgId =  categories.reduce((acc, obj) => {
						// console.log(obj)
						const orgSplit = obj.organizationIds.reduce((orgAcc, orgId) => ({
							[orgId]: [ ...new Set([obj.category, ...(acc[orgId] || [])]) ],
							...orgAcc
						}), {})

						return {
							...acc,
							...orgSplit,
						}
					}, {})
				}
				// const categoriesByOrgId = _.chain(res.categories).keyBy(x => x.category.id).mapValues(x => x.organizationIds).value()
				/*
				*  { 4520: [ { title: "Gul" } ] }
				* */
				vm.parents = _.orderBy(res.categories.map(x => x.category), "title");
				
				_.forEach(vm.parents, function (parent) {
					var orgIds = orgIdsByCategoryId[parent.id]
					organizationsByParentId[parent.id] = _.filter(vm.influences, function (influence) {
						return orgIds.includes(influence.actualTargetOrganizationId)
					});
				})
				vm.parents.unshift({
					title: "Alla",
					id: 0
				});

				organizationsByParentId[0] = allInfluences;

				vm.filterGroups = res.categoryGroups.map(x => {
					return {
						...x,
						selected: null,
						options: vm.parents.filter(y => x.categoryWfids.includes(y.wfid))
					}
				})

				_.orderBy(vm.filterGroups, "order");

				applyFilter(0);
				$timeout();

				NgMap.getMap().then(function (map) {
					var clearListeners = google.maps.event.clearListeners;
					var startBounds = new google.maps.LatLngBounds();
					var currentOpenMarker;
					var currentOpenLocation;
					var infoWindowOpen;
					var markers = [];
					infoWindow =  new google.maps.InfoWindow({ });

					vm.onMarkerClick = function (event, location) {
						// console.log("attachMarkersInfoWindow", map.markers.length);
						// clearListeners(marker, 'click');
						// console.log("addListener", marker.infocontent)
						// marker.addListener('click', function() {
							// console.log("click", infoWindowOpen, currentOpenMarker === marker)
							if (infoWindow) {
								infoWindow.close();
								infoWindowOpen = false;
							}

							if (infoWindowOpen && currentOpenLocation === location) {
							}
							else {
								infoWindow = new google.maps.InfoWindow({
									content: '<div class=\'map-infoWindow\'><b>' + $sanitize(location.name) + '</b>' + '<br />' + $sanitize(location.formattedAddress) + '</div>', 
									position: new google.maps.LatLng(location.latitude, location.longitude),
									pixelOffset: new google.maps.Size(-1, -40),
								})
								// infoWindow.setContent();
								// infoWindow.setPosition(new google.maps.LatLng(location.latitude, location.longitude));
								infoWindow.open(map);
								infoWindowOpen = true;
							}
							currentOpenLocation = location;
						// });
					}

					google.maps.event.addListener(infoWindow, 'closeclick', function() {
						infoWindowOpen = false;
						currentOpenLocation = undefined;
					});

					_.forEach(map.markers, function (marker) {
						startBounds.extend(marker.position)
						markers.push(marker);
						// attachMarkerInfoWindow(marker);
						map.fitBounds(startBounds);
					});

					$scope.$watchCollection("vm.searchResultItems", function (influences) {
						var
							influenceIds = _.map(influences, "id"),
							influences = _.filter(vm.influenceObjects, function (influence) {
								return _.includes(influenceIds, influence.id);
							}),
							organizationIds = _.map(influences, "actualTargetOrganizationId"),
							bounds = new google.maps.LatLngBounds()
						;
						// console.log(markers);
						infoWindow.close();

						vm.filteredLocations.length = 0;
						_.assign(vm.filteredLocations, _.filter(vm.locations, function (location) {
							var includes = _.includes(organizationIds, location.organizationId);

							if (includes)
								bounds.extend(new google.maps.LatLng(location.latitude, location.longitude));

							return includes; 
						}));


						map.fitBounds(vm.filteredLocations.length ? bounds : startBounds);
						
						if (map.zoom > 16) {
							map.setZoom(16)
						}

						// setTimeout(function () {
						// 	_.forEach(map.markers, function (marker) {
						// 		attachMarkerInfoWindow(marker);
						// 	});
						// }, 1000);
						// if (vm.filteredLocations.length === 1) {
						// }
						// console.log(map)
					});
				});
			});

			return;
			apiProxy("utility.getInfluenceRoomByRouteName", $stateParams.roomRouteName).then(function (res) {
				if (!res) { // An influnce room with that urlRouteName was not found
					$state.go("root");
				}
				else {
					wfAuth.setAuthenticationHeaderValue("influence_room|" + res.id);

				

					dataOps.getObject({
						objectType: enums.objectType.publicInfluenceRoom,
						objectId: res.id
					}).then(function (room) {
						dataOps.getObject({
							objectType: enums.objectType.structure,
							objectId: room.objectId
						}).then(function (structure) {
							// $rootScope.setPageTitle(structure.title);
							// vm.structure = structure;
							// vm.structureChildren = _.map(structure.childs, "childContent");

							if (structure.creatorOrganizationId) {
								dataOps.getObject({
									objectType: enums.objectType.organization,
									objectId: structure.creatorOrganizationId
								}).then(function (structureCreatorOrganization) {
									// vm.structureLoaded = true;
									// vm.structureCreatorOrganization = structureCreatorOrganization;
									$timeout();
								})
							}
							else {
								// vm.structureLoaded = true;
								$timeout();
							}



							dataOps.getObjects({
								objectType: enums.objectType.influence,
								wrapInRelations: false,
								getterConditions: {
									objectType: enums.objectType.structure,
									objectId: room.objectId
								}
							}).then(function (influences) {
								var organizations;

								// vm.influenceObjects = influences

								// organizations = _.map(vm.influenceObjects, 'organization');
								// organizationIds = _.map(organizations, "id");

								// var inf = _.filter(influences, { childId: 31440 })[0];
								// modal.openItem({
								// 	meta: {
								// 		title: inf.childContent.organization.name,
								// 		settings: {
								// 			templateId: 23
								// 		}
								// 	},
								// 	influence: inf.childContent,
								// 	influenceSourceObject: inf.childContent.childContent,
								// 	organization: inf.childContent.organization
								// })
								// return;
								// console.log(organizationIds);

								// _.forEach(organizations, function (org) {
								// 	new Image().src = org.imageUrl;
								// })

								dataOps.getObjects({
									objectType: enums.objectType.location,
									organizationIds: organizationIds,
									wrapInRelations: false
								}).then(function (locations) {
									// vm.locations = locations;
									// vm.filteredLocations = _.clone(vm.locations);

									dataOps.getSubItemsOfAll(organizations, enums.subItemsKind.parentsByUser).then(function () {
									// setTimeout(function () {
										// wfObject.inject(mockData());

										// setTimeout(function () {
										apiProxy("statistics.getAnswerPercentages", {
											orgIds: organizationIds,
											structureIds: _.map(structure.childs, "childId")
										}).then(function (stats) {
											var stats = stats;
											vm.statsByOrg = stats;

											apiProxy("statistics.getAnswerableChildren", dataOps.prepareWfObject(structure)).then(function (answerableItems) {
												vm.answerableItems = answerableItems;
												
												_.forEach(influences, function (influence) {
													var stats = vm.statsByOrg[influence.organizationId];
													if (stats) {
														influence.order = _.chain(stats).map().sum().value()
													}
												})


												// vm.allInfluences = allInfluences = sortItems(influences);
												// vm.allLoaded = true;
												// Array.prototype.push.apply(vm.influences, allInfluences);

												// _.each(allInfluences, function (inf) {
												// 	var orgLocations = _.chain(locations).filter({ organizationId: inf.organizationId });
												// 	var org = inf.organization || {};
												// 	inf.searchSource = [
												// 		org.name,
												// 		org.registrationNumber,
												// 		orgLocations.map("name").join(" ").value(),
												// 		orgLocations.map("formattedAddress").join(" ").value()
												// 	].join(" ");
												// });

												// vm.parents = wfObject.filter({
												// 	where:
												// 	{
												// 		type: enums.objectType.structure,
												// 		wfid: { "in": _.uniq(_.map(wfObject.filter({ where: { parentType: enums.objectType.structure, wfcid: { "in": _.map(organizations, "wfid") } } }), "wffid")) }
												// 	},
												// 	orderBy: "title"
												// });
												// vm.parents.unshift({
												// 	title: "Alla",
												// 	id: 0
												// });

												// _.forEach(vm.parents, function (parent) {
												// 	organizationsByParentId[parent.id] = _.filter(vm.influences, function (influence) {
												// 		return _.chain(influence.organization.parents).map("parentId").includes(parseInt(parent.id)).value();
												// 	});
												// })
												// organizationsByParentId[0] = allInfluences;

												// applyFilter(0);

												
												// setTimeout(function () {
												// 	selectAnswerableFilter({ wfid: "11-824", type: 11, id: 824 });
												// 	$timeout();
												// }, 500)
											});
										});
									});
								});
							});
						});
					});
				}
			})
		}

		const selectedParentIds = {}

		function applyFilter(parentId, group) {
			var items;
			vm.selectedParentId = parentId;
			vm.influences.length = 0;
			
			if (group) {
				if (group.selected === parentId) {
					group.selected = null
				}
				else {
					group.selected = parentId
				}
			}

			const parentIds = vm.filterGroups.map(x => x.selected).filter(x => Boolean(x))

			if (parentIds.length === 0)
				items = sortItems(allInfluences);
			else {
				items = []
				const intersectionArrays = []
				parentIds.forEach(x => intersectionArrays.push(organizationsByParentId[x]))
				items = sortItems(_.uniq(_.intersectionBy.apply(null, intersectionArrays)))
			}
			
			Array.prototype.push.apply(vm.influences, items);
		}

		function sortItems(items) {
			return _.chain(items).orderBy([ "order", "organization.name" ], [ "desc", "asc" ]).value();
		}

		function selectAnswerableFilter(item, model) {
			var wfid;

			if (!item) {
				removeAnswerableFilter();
				return;
			}

			wfid = item.wfid;
			vm.isFilteredOnAnswerable = true;
			vm.selectedAnswererbleWfid = item.wfid;
			vm.showItemCharts = false;
			vm.answerStatsIsLoading = false;

			_.each(vm.relatedContentByOrganizationId, function (value, key) {
				value.toggled = false;
			});
			vm.relatedContentByOrganizationId = {};

			for (var key in answerableItemsFilterCache) {
				if (answerableItemsFilterCache.hasOwnProperty(key)) {
					answerableItemsFilterCache[key].selected = false;
				}
			}

			if (!answerableItemsFilterCache[wfid]) {
				answerableItemsFilterCache[wfid] = {
					loaded: false,
					selected: true
				};

				vm.answerStatsIsLoading = true;

				apiProxy("statistics.getAnswersByOrganizations", {
					orgIds: organizationIds,
					questionId: item.type === enums.objectType.question ? item.id : undefined,
					measureId: item.type === enums.objectType.measure ? item.id : undefined
				}).then(function (res) {
					_.assign(answerableItemsFilterCache[wfid], {
						loaded: true,
						data: res
						// Example of data
						// 4536: { <-- orgId
						//    "11-5": { text: "Yes", relatedContentCount: 0 }, <-- wfid, the answer and relatedContentCount
						//    "11-6": { text: "No", relatedContentCount: 0 },
						//    "21-2": { text: "300 kWh", relatedContentCount: 0 },
						//    "21-3": { text: "200 tonnes", relatedContentCount: 0 }
						// },
						// ...
					});
					if (vm.selectedAnswererbleWfid === wfid)
					{
						vm.answerStatsIsLoading = false;
						vm.selectedAnswererbleDataCollection = res;
						$timeout();
					}

				});
			}
			else
			{
				// console.log(wfid);
				answerableItemsFilterCache[wfid].selected = true;
				if (answerableItemsFilterCache[wfid].loaded)
					vm.selectedAnswererbleDataCollection = answerableItemsFilterCache[wfid].data;
				else
					vm.answerStatsIsLoading = true;
			}
		}

		function removeAnswerableFilter(item, model) {
			vm.isFilteredOnAnswerable = false;
			vm.selectedAnswererbleWfid = null;
			vm.showItemCharts = true;
			vm.selectedAnswererbleDataCollection = null;
			vm.answerStatsIsLoading = false;

			_.each(vm.relatedContentByOrganizationId, function (value, key) {
				value.toggled = false;
			});
			vm.relatedContentByOrganizationId = {};

			for (var key in answerableItemsFilterCache) {
				if (answerableItemsFilterCache.hasOwnProperty(key)) {
					answerableItemsFilterCache[key].selected = false;
				}
			}
		}

		function toggleRelatedContent(influence) {
			var
				obj,
				answerItem = vm.selectedAnswererbleDataCollection[influence.organizationId][vm.selectedAnswererbleWfid].answerItem
			;

			if (!vm.relatedContentByOrganizationId[influence.organizationId])
				vm.relatedContentByOrganizationId[influence.organizationId] = {};

			obj = vm.relatedContentByOrganizationId[influence.organizationId];

			obj.toggled = !obj.toggled;

			obj.loaded = false;
			dataOps.getSubItems(answerItem, enums.subItemsKind.relatedContentByUser, {
				ticket: {
					organizationId: influence.organizationId,
					networkId: influence.channelId
				}
			}).then(function (res) {
				obj.items = res;
				obj.loaded = true;

				$timeout();
			});
		}
	}
})();
