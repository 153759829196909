// @flow
// global google
import * as React from 'react'
import GoogleMaps from '../GMaps/GoogleMaps'
import MarkerWithInfo from '../GMaps/MarkerWithInfo'
import { GoogleMap } from 'react-google-maps'
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer'
import type { Item } from '@worldfavor/types'
import { ObjectType } from '@worldfavor/constants/enums'

const Stockholm = { lat: 59.334591, lng: 18.063240 }

type Props = {
  items: Array<Item>,
  clusterize?: boolean,
  closeInfoOnClick?: boolean,
}

class Map extends React.PureComponent<Props> {

  static defaultProps = {
    items: [],
  }

  map: GoogleMap = null
  markerRefs: { [string]: MarkerWithInfo } = {}

  _getSubHeader = (item: Item): string | null => {
    switch (item.type) {
      case ObjectType.organization:
        return [item.registrationNumber, item.vatNumber]
          .filter(Boolean).join(' • ')
      case ObjectType.productionSite:
        return item.gln ? `GLN: ${item.gln}` : null
      default:
        return null
    }
  }

  _onMapLoaded = (mapRef: GoogleMap) => {
    this.map = mapRef
    this._fitToMarkerBounds()
  }

  _fitToMarkerBounds = () => {
    const { items } = this.props
    if (this.map) {
      const bounds = items.reduce((bounds, { latitude: lat, longitude: lng }) => {
        return bounds.extend({ lat, lng })
      }, new google.maps.LatLngBounds())
      this.map.fitBounds(bounds)
    }
  }

  /**
   * Close all open info window excepted
   * the one the user has just opened
   * @param id The ref id for the opened info window
   * @private
   */
  _onInfoOpened = (id: string) => {
    Object.keys(this.markerRefs)
      .filter(key => key !== id)
      .filter(key => this.markerRefs[key].isInfoWindowOpen())
      .map(key => this.markerRefs[key].closeInfo())
  }

  closeAllInfo = () => {
    Object.values(this.markerRefs)
      .filter(ref => ref.isInfoWindowOpen())
      .map(ref => ref.closeInfo())
  }

  _onMapClick = () => {
    const { closeInfoOnClick } = this.props
    if (closeInfoOnClick) {
      this.closeAllInfo()
    }
  }

  renderCluster() {
    return (
      <MarkerClusterer
        averageCenter
        enableRetinaIcons
        gridSize={60}
        maxZoom={11}
        clusterClass={'cluster'}
        styles={[
          {
            url: require('@worldfavor/assets/img/map-cluster-512.png'),
            height: 52,
            lineHeight: 52,
            width: 52,
            textColor: '#D7DDDF',
            textSize: 15,
            fontFamily: 'Roboto,"Segoe UI","Droid Sans",Tahoma,Arial,sans-serif',
          },
        ]}
      >
        {
          this.renderContent()
        }
      </MarkerClusterer>
    )
  }

  renderContent() {
    const { items } = this.props
    return items.map((item, index) => (
      <MarkerWithInfo
        ref={ref => this.markerRefs[`${index}`] = ref}
        id={`${index}`}
        key={`marker-${index}`}
        position={{ lat: item.latitude, lng: item.longitude }}
        title={item.name}
        description={item.formattedAddress}
        subHeader={this._getSubHeader(item)}
        onInfoOpened={this._onInfoOpened}
        showPositionLink
      />
    ))
  }

  render() {
    const { clusterize } = this.props
    return (
      <GoogleMaps
        onMapLoaded={this._onMapLoaded}
        defaultCenter={Stockholm}
        defaultZoom={11}
        options={{ streetViewControl: false, scrollwheel: true }}
        onClick={this._onMapClick}
      >
        { clusterize ? this.renderCluster() : this.renderContent() }
      </GoogleMaps>
    )
  }
}

export default Map
