(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('dropdownOverflow', dropdownOverflowDirective);

	dropdownOverflowDirective.$inject = ['dropdownUtility', '$parse'];

	function dropdownOverflowDirective(dropdownUtility, $parse) {
		var win = $(window);
		var directive = {
			link: link,
			restrict: 'A'
		};

		return directive;

		function link(scope, element, $attrs) {
			var el = $(element);
			var button = el.find(".dropdown-toggle, div[type='button'], .btn, .dropdown-custom-trigger");
			var dropdownMenu = el.children().closest('ul.dropdown-menu');
			var subMenu = false;
			var
				settings = {
					align: "left",
					width: 160,
					responsive: true
				},
				options
			;

			el.click(function () {
				dropdownUtility.setAsOpen(el, win);

				if (typeof options === "undefined") {
					if ($attrs.dropdownOverflow.length) {
						options = $parse($attrs.dropdownOverflow)(scope);
						_.assign(settings, options);
					}
					else
						options = null;
				}

				var discreetElement = el.hasClass("discreet");
				if (!(dropdownMenu.css("position") === "fixed")) {
					dropdownMenu.css({
						position: "fixed",
						width: settings.width,
						minWidth: settings.width
					});
				}

				if (discreetElement) { //Admin Tools
					dropdownUtility.fixDropDownPosition(el, dropdownMenu, subMenu, settings);
					var subMenuElement = dropdownMenu.children('li.dropdown-submenu');
					if (subMenuElement) {
						subMenu = true;
						var subMenuDropdown = subMenuElement.children("ul.dropdown-menu");
						subMenuDropdown.css({
							position: "fixed",
							width: settings.width,
							minWidth: settings.width
						});
						subMenuElement.mouseover(function () {
							dropdownUtility.fixDropDownPosition(subMenuElement, subMenuDropdown, subMenu, settings);
						});
					}
				}
				else {
					dropdownUtility.fixDropDownPosition(button, dropdownMenu, subMenu, settings);
				}
			});

			win.scroll(function () {
				dropdownUtility.close();
			});
		}
	}

} ());