import * as enums from '@worldfavor/constants/enums'

(function () {
	'use strict';

	angular
	.module('schemaForm')
	.config([ 'schemaFormProvider', 'schemaFormDecoratorsProvider', 'sfPathProvider',
		function (schemaFormProvider, schemaFormDecoratorsProvider, sfPathProvider) {
			var location_search = function (name, schema, options) {
				if (schema.format === 'location_search') {
					var f  = schemaFormProvider.stdFormObj(name, schema, options);

					f.key  = options.path;
					f.type = 'location_search';
					f.validationMessage = {
						customRule: ""
					};
					f.$validators = {
						customRule: function (value) {
							return !!value;
						}
					}
					options.lookup[sfPathProvider.stringify(options.path)] = f;
					return f;
				}
			};

			schemaFormProvider.defaults.string.unshift(location_search);

			schemaFormDecoratorsProvider.addMapping(
				'bootstrapDecorator',
				'location_search',
				'scripts/wf/form/schema-form-location-search.html'
			);
		}
	]);

	angular
	.module('wf.common')
	.directive('wfSchemaLocationSearch', [ '$timeout', '$q', '$translate', 'modalService', 'wfObject', 'apiProxy', 'dataOperationsService', 'NgMap', '$compile', '$parse',
		function ($timeout, $q, $translate, modal, wfObject, apiProxy, dataOps, NgMap, $compile, $parse) {
		return {
			restrict: 'A',
			scope:    true,
			require:  'ngModel',
			controller: [ "$scope", function ($scope) {
				var vm = $scope.vm ? $scope.vm : $scope.vm = {};

				vm.isGoogleJsLoaded = "google" in window;
			}],
			link: function (scope, element, attrs, ngModel) {
				var
					vm = scope.vm ? scope.vm : scope.vm = {},
					model,
					defaultCenterLatitude = 62.5,
					defaultCenterLongitude = 18.6435,
					defaultCenter = defaultCenterLatitude + "," + defaultCenterLongitude,
					defaultZoom = 4,
					uniquePopoverId = _.uniqueId(),
					buttonWrapperElement = $(element).find("div.input-group.location-search");
				;

				_.assign(vm, {
					location: null,
					mapInPopover: attrs.mapInPopover ? $parse(attrs.mapInPopover)(scope) : false,
					setModelValues: setModelValues,
					initialCenter: defaultCenter,
					initialZoom: defaultZoom,
					inputPlaceholder: $translate.instant("modules.locations.searchInputPlaceholder"),
					manualInputPlaceholder: $translate.instant("modules.locations.manualInputPlaceholder"),
					mapId: "locationSearch" + scope.$id,
					onLocationInputChange: onLocationInputChange,
					openMapPopover: openMapPopover
				});

				scope.validateField = function () {};
				scope.$on('schemaFormReset', reset);
				scope.$on('schemaFormValidate', scope.validateField);
				scope.$on('schemaFormBeforeSubmit', schemaFormBeforeSubmit);
				// scope.$on('schemaFormFileUploadSubmit', scope.submit);

				activate();

				function activate() {
					var
						geocoder = vm.isGoogleJsLoaded ? new google.maps.Geocoder : null
					;

					model = scope.$parent.$parent.$parent.model; // Must be a better way!
					
					if (typeof model.mapInPopover === "boolean") {
						vm.mapInPopover = model.mapInPopover;
					}

					vm.location = {
						formattedAddress: model.formattedAddress,
						latitude: model.latitude,
						longitude: model.longitude
					};

					if (model.latitude && model.longitude) {
						vm.initialCenter = model.latitude + "," + model.longitude;
						vm.initialZoom = 17; // 17 works well for street level
						ngModel.$setViewValue(model.formattedAddress);
					}
					

					vm.addMarker = function(event) {
						var ll = event.latLng;
						vm.location.latitude = ll.lat();
						vm.location.longitude = ll.lng();
						vm.coordinatesToAddress(ll.lat(), ll.lng());
						setModelValues();
					}
					vm.deleteMarker = function() {
						vm.location.latitude = null;
						vm.location.longitude = null;
						vm.location.formattedAddress = "";
						// setTimeout(function () {
							element.find("input.locationSearchInput").focus();
						// });
						ngModel.$setViewValue(undefined);
					};

					vm.types = "['establishment']";
					vm.placeChanged = function() {
						vm.place = this.getPlace();
						if (vm.place.geometry) {
							if (vm.place.geometry.viewport) {
								vm.map.setCenter(vm.place.geometry.location);
								vm.map.setZoom(17);  // 17 works well for street level
								vm.map.fitBounds(vm.place.geometry.viewport);
							}else {
								vm.map.setCenter(vm.place.geometry.location);
								vm.map.setZoom(17);  // 17 works well for street level
							}
							vm.location.latitude = vm.place.geometry.location.lat();
							vm.location.longitude = vm.place.geometry.location.lng();
							vm.location.formattedAddress = vm.place.formatted_address;

							setModelValues();
						}
						else {
							console.log("The address does not exist");
						}
					}

					//Get Address from coordinates
					vm.coordinatesToAddress = function(lat, lng) {
						var latlng = new google.maps.LatLng(lat, lng);

						geocoder.geocode({'latLng': latlng}, function(results, status) {
							if (status == google.maps.GeocoderStatus.OK) {
								// test(results, status)
								if (results[0]) {
									vm.location.formattedAddress = results[0].formatted_address;
									setModelValues();
									$timeout();
								}else {
									console.log("No Location Found (coordinatesToAddress - geocode)");
								}
							}
						});
					}

					if (!vm.mapInPopover) {
						if (vm.isGoogleJsLoaded) {
							setTimeout(function () {
								var mapPromise = NgMap.getMap({ id: vm.mapId });
								mapPromise.then(function(map) {
									var latlng;
									vm.map = map;
								})
							})
						}
					}
				}

				function setModelValues() {
					model.formattedAddress = vm.location.formattedAddress;
					model.latitude = vm.location.latitude;
					model.longitude = vm.location.longitude;
					ngModel.$setViewValue(model.formattedAddress)

					if (vm.map) {
						vm.initialZoom = vm.map.zoom;
						vm.initialCenter = vm.location.latitude + "," + vm.location.longitude;
					}
				}
				
				function reset() {
					ngModel.$setViewValue(undefined);
				}

				function schemaFormBeforeSubmit(event, model) {
				}

				function onLocationInputChange() {
					model.formattedAddress = vm.location.formattedAddress;
					model.latitude = 0;
					model.longitude = 0;
					ngModel.$setViewValue(model.formattedAddress);
				}

				function test(results, status) {
					var city, country, state, available = {}, bestType, output = [];
					if (status == google.maps.GeocoderStatus.OK) {
						if (results[0]) {
							//find country name
							for (var i = 0; i < results[0].address_components.length; i++) {
								for (var b = 0; b < results[0].address_components[i].types.length; b++) {
									var type = results[0].address_components[i].types[b];
									//there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
									if (type == "postal_town" || type == "locality" || type == "administrative_area_level_3" || type == "administrative_area_level_2" || type == "sublocality" ||  type == "administrative_area_level_1") {
										available[type] = results[0].address_components[i];
										//this is the object you are looking for
										// city = results[0].address_components[i];
										break;
									}
								}
							}
							
							if (available["postal_town"]) {
								bestType = "postal_town";
								// city = available["postal_town"];
							}
							else if (available["locality"]) {
								bestType = "locality";
								// city = available["locality"];
							}
							else if (available["sublocality"]) {
								bestType = "sublocality";
								// city = available["sublocality"];
							}
							else if (available["administrative_area_level_3"]) {
								bestType = "administrative_area_level_3";
								// city = available["administrative_area_level_3"];
							}
							else if (available["administrative_area_level_2"]) {
								bestType = "administrative_area_level_2";
								// city = available["administrative_area_level_1"];
							}
							else if (available["administrative_area_level_1"]) {
								bestType = "administrative_area_level_1";
								// city = available["administrative_area_level_1"];
							}
							
							_.each(results, function (resultItem) {
								if (!city && _.includes(resultItem.types, bestType)) {
									city = resultItem.address_components[0];
								}
							});

							_.each(results[0].address_components, function (addrComp) {
								if (!country && _.includes(addrComp.types, "country")) {
									country = addrComp;
								}
							});

							if (!city) {
								city = available[bestType];
							}

							// for (var i = 0; i < results[0].address_components.length; i++) {
							// for (var b = 0; b < results[0].address_components[i].types.length; b++) {
							// 	//there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
							// 	if (results[0].address_components[i].types[b] == "administrative_area_level_1") {
							// 	//this is the object you are looking for
							// 	state = results[0].address_components[i];
							// 	break;
							// 	}
							// }
						}
						if (!city)
							city = {};

						if (city && city.long_name) {
							output.push(city.long_name)
						}

						if (country && country.long_name) {
							output.push(country.long_name)
						}

						// if (!state)
						// 	state = {}

						console.log(output.join(", "));// +", "+ state.long_name);
					} else {
						console.log("City name not available");
					}
				}

				function openMapPopover() {
					if (vm.mapInPopover) {
						if (!vm.popoverInited) {
							initializePopover();
							vm.popoverInited = true;
						}

						if (!vm.popoverVisible) {
							showPopover();
						}
					}
					$timeout();
				}
				
				function showPopover() {
					if (!vm.popoverVisible) {
						buttonWrapperElement.popover("show");
						vm.popoverVisible = true;
						
						setTimeout(function() {
							$(document).on('click.locationMapPopover-' + uniquePopoverId, function(event) {
								if (vm.popoverVisible && !$(event.target).closest('div.wf-form-location').length)
									hidePopover();
							});
						}, 100);
					}
				}
		
				function hidePopover() {
					if (vm.popoverVisible) {
						vm.popoverVisible = false;
						buttonWrapperElement.popover("hide");
						$(document).off("click.locationMapPopover-" + uniquePopoverId);
					}
					$timeout();
				}
		
				function initializePopover() {
					var compiledElement;
					var htmlTemplate = `
						<ng-map wf-if-first="vm.isGoogleJsLoaded" id="{{vm.mapId}}" center="{{vm.initialCenter}}" zoom="{{vm.initialZoom}}" on-click="vm.addMarker()" street-view-control="false" map-type-id="roadmap">
							<marker ng-if="vm.location.latitude" position="{{vm.location.latitude}}, {{vm.location.longitude}}"></marker>
						</ng-map>
					`;

					console.log(vm.initialCenter, vm.initialZoom)

					buttonWrapperElement.attr("data-toggle", "popover");
					buttonWrapperElement.popover({
						delay: 0,
						animation: true,
						placement: "bottom",
						container: vm.appendPopoverToBody ? 'body' : false,
						trigger: "manual",
						template: '<div class="popover location-map-popover"><div class="arrow"></div><div class="popover-content"></div></div>',
						html: true,
						selector: false,
						content: function () {
							compiledElement = $compile(htmlTemplate)(scope);
							$timeout();

							if (vm.isGoogleJsLoaded) {
								setTimeout(function () {
									var mapPromise = NgMap.getMap({ id: vm.mapId });
									mapPromise.then(function(map) {
										var latlng;
										vm.map = map;
									})
								})
							}
		
							return compiledElement;
						}
					});

					buttonWrapperElement.on('hidden.bs.popover', function () {
					});
				}
			}
		};
	} ]);
})();
