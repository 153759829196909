// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Dialog as MuiDialog } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import classNames from 'classnames'
import Colors from '@worldfavor/constants/colors'
import CircularProgressWrapper from '@worldfavor/components/CircularProgressWrapper'

const useStyles = makeStyles(theme => ({
  dialogBox: {
    minWidth: 650,
    minHeight: 250,
  },
  titleWrapper: {},
  infoTitleWrapper: {
    color: theme.palette.common.white,
    backgroundColor: Colors.blackDark,
  },
  errorTitleWrapper: {
    color: theme.palette.common.white,
    backgroundColor: Colors.redDark,
  },
  confirmTitleWrapper: {
    color: theme.palette.common.white,
    backgroundColor: Colors.turquoiseDark,
  },
  alertTitleWrapper: {
    color: theme.palette.common.blackDarker,
    backgroundColor: Colors.yellow,
  },
  titleText: {
    fontSize: theme.typography.fontSizes.larger,
  },
  contentWrapper: {},
  contentText: {
    fontSize: theme.typography.fontSizes.medium,
    marginTop: 20,
  },
  actionsWrapper: {
    margin: 10,
  },
  button: {
    fontSize: theme.typography.fontSizes.medium,
  },
  buttonLabel: {
    visibility: 'hidden',
  },
}))

type Props = {
  title: string,
  description: string,
  open: boolean,
  info?: boolean,
  error?: boolean,
  confirm?: boolean,
  warning?: boolean,
  actions: Array<{
    label: string,
    onClick: () => {},
    variant: string,
    show: boolean,
    saving: boolean,
  }>,
  children?: React.Node,
}

const Dialog = (props: Props) => {
  const classes = useStyles(props)
  const {
    title,
    description,
    actions,
    children,
    open,
    info,
    confirm,
    warning,
    error,
  } = props

  return (
    <MuiDialog
      aria-labelledby="generic-alert-dialog"
      aria-describedby="customizable"
      classes={{ paper: classes.dialogBox }}
      open={open}
    >
      <DialogTitle
        id="alert-dialog-title"
        disableTypography
        classes={{ root: classNames(classes.titleWrapper,
          {
            [classes.infoTitleWrapper]: info,
            [classes.confirmTitleWrapper]: confirm,
            [classes.alertTitleWrapper]: warning,
            [classes.errorTitleWrapper]: error,
          }),
        }}
      >
        <span className={classes.titleText}>{title}</span>
      </DialogTitle>

      <DialogContent
        className={classes.contentWrapper}
      >
        {
          children || <DialogContentText className={classes.contentText} id="alert-dialog-description">{description}</DialogContentText>
        }
      </DialogContent>

      <DialogActions
        className={classes.actionsWrapper}
      >
        {
          actions && actions.filter(action => !action.hide).map((action, index) => (
            <CircularProgressWrapper key={`${actions.label}-${index}`} loading={action.saving}>
              <Button
                variant={action.variant}
                onClick={action.onClick}
                className={classes.button}
              >
                <span className={action.saving ? classes.buttonLabel : undefined}>{action.label}</span>
              </Button>
            </CircularProgressWrapper>
          ))
        }
      </DialogActions>
    </MuiDialog>
  )
}

Dialog.defaultProps = {
  actions: [
    {
      label: 'Confirm',
      onClick: () => {},
      variant: 'contained',
    },
    {
      label: 'Close',
      onClick: () => {},
      variant: 'text',
    },
  ],
}

export default Dialog
