import * as enums from '@worldfavor/constants/enums'

(function () {
	'use strict';

	angular
	.module('schemaForm')
	.config([ 'schemaFormProvider', 'schemaFormDecoratorsProvider', 'sfPathProvider',
		function (schemaFormProvider, schemaFormDecoratorsProvider, sfPathProvider) {
			var picker_multiple = function (name, schema, options) {
				if (schema.format === 'picker_multiple') {
					var f  = schemaFormProvider.stdFormObj(name, schema, options);

					// f.key  = options.path;
					// f.type = 'picker_multiple';
					// f.validationMessage = {
					// 	customRule: ""
					// };
					// f.$validators = {
					// 	customRule: function (value) {
					// 		return !!value;
					// 	}
					// }
					options.lookup[sfPathProvider.stringify(options.path)] = f;
					return f;
				}
			};

			schemaFormProvider.defaults.integer.unshift(picker_multiple);

			schemaFormDecoratorsProvider.addMapping(
				'bootstrapDecorator',
				'picker_multiple',
				'scripts/wf/form/schema-form-picker-multiple.html'
			);
		}
	]);

	angular
	.module('wf.common')
	.directive('wfSchemaPickerMultiple', [ '$timeout', '$q', '$translate', 'modalService', 'wfObject', 'apiProxy', 'dataOperationsService', 'dataQuery', 'wfAuth', 'wfPropertyExtractor',
		function ($timeout, $q, $translate, modal, wfObject, apiProxy, dataOps, dataQuery, wfAuth, wfPropertyExtractor) {
		return {
			restrict: 'A',
			scope:    true,
			require:  'ngModel',
			link: function (scope, element, attrs, ngModel) {
				var
					vm = scope.vm = {},
					originalRelations,
					tempSelected = [],
					tempRemoved = [],
					tempPreSelected = [],
					preSelectedSaved = false,
					typeOptions = scope.form.typeOptions,
					organizationId
				;

				_.assign(vm, {
					loaded: false,
					orgObjectType: enums.objectType.organization,
					removeItem: removeItem,
					removingIndustry: false,
					customFormSpec: undefined,
					selectionBucket: {},
					formControl: {},
					industryPickerError: false,
					openPicker: openPicker,
					isPickerValid: true,
					formSuccess: undefined,
					getTextFromItem: getTextFromItem,
					required: typeOptions.required !== false
				});

				vm.pickerSourceWfid = typeOptions.picker.sourceItem;
				vm.targetWfid = typeOptions.targetWfid;
				vm.skipInitialLoading = typeOptions.skipInitialLoading; 
				vm.singleSelectedItem = typeOptions.selectedItem;
				vm.preselectedItems = typeOptions.selectedItems;
				vm.addButtonCaption = typeOptions.addButtonCaption;
				vm.singlePick = typeOptions.singlePick;
				vm.ticket = typeOptions.ticket;

				scope.validateField = function ($event, formName, validator) {
					if (validator.isValid) {
						vm.isPickerValid = vm.required ? vm.selectionBucket.allSelected.length > 0 : true;
						validator.isValid = vm.isPickerValid;
					}
				};

				scope.$on('schemaFormReset', reset);
				scope.$on('schemaFormValidate', scope.validateField);
				scope.$on('schemaFormBeforeSubmit', schemaFormBeforeSubmit);
				scope.$on('schemaFormSubmitDeferred', submitDeferred);

				activate();

				function activate() {
					if (vm.ticket && vm.ticket.organizationId) {
						organizationId = vm.ticket.organizationId
					}
					else {
						organizationId = wfAuth.getOrganizationId()
					}

					if (vm.singleSelectedItem || vm.preselectedItems) {
						reset();
					}
					else if (vm.skipInitialLoading) {
						reset();
						vm.loaded = true;
						$timeout();
					  }
					else if (vm.targetWfid) {
						dataOps.getSubItems(vm.targetWfid, enums.subItemsKind.childrenByUser, { ticket: vm.ticket }).then(function() {
							reset();
							vm.loaded = true;
							$timeout();
						});
					}
					else {
						reset();
						vm.loaded = true;
						$timeout();
					}
				}

				function openPicker() {
					var pickerOptions = _.defaultsDeep(typeOptions.picker, {
						title: undefined,
						description: undefined,
						create: false,
						singlePick: typeOptions.singlePick,
						relationBucket: { singlePick: typeOptions.singlePick, preSelected: vm.selectionBucket.allSelected, allSelected: vm.selectionBucket.allSelected },
						sourceItem: vm.pickerSourceWfid,
						ticket: vm.ticket,
						closeCaption: $translate.instant("Done"),
						backdrop: 'static',
						hideItem: vm.targetWfid
					});
					modal.openCreatorAndPicker(pickerOptions).closed(function(result) {
						if (result.newlySelected.length == 0 && result.newlyRemoved.length == 0)
							return;
					
						//preSelected
						if (result.preSelected.length != 0 && !preSelectedSaved) {
							tempPreSelected = result.preSelected;
							preSelectedSaved = true;
						}
						if (preSelectedSaved)
							result.preSelected = tempPreSelected;

						//newlySelected
						if (result.newlySelected.length != 0) {
							tempSelected = _.concat(tempSelected, result.newlySelected);
							result.newlySelected = tempSelected;
						}
						else result.newlySelected = tempSelected;

						//newlyRemoved
						if (result.newlyRemoved.length != 0) {
							tempRemoved = _.concat(tempRemoved, result.newlyRemoved);
							_.pullAll(result.newlySelected, tempRemoved);
						}
						
						// result.allSelected = _.uniq(_.concat(result.newlySelected, result.preSelected));
						
						vm.selectionBucket = result;
						if (vm.selectionBucket.allSelected.length != 0) {
							vm.isPickerValid = true;
							vm.formSuccess = true;
							vm.industryPickerError = false;
							vm.industryPickerSuccess = true;
						}
						else {
							vm.isPickerValid = false;
							vm.formSuccess = false;
							vm.industryPickerError = true;
							vm.industryPickerSuccess = false;
						}

						if (!vm.required)
							vm.isPickerValid = true;

						ngModel.$valid = vm.isPickerValid;
						ngModel.$invalid = !vm.isPickerValid;

						if (typeof typeOptions.onPickerClosed === "function")
							typeOptions.onPickerClosed(result);

						$timeout();
					});
				}

				function submitDeferred(event, promisesArray, savedItem) {
					var promises = [];
					vm.selectionBucket.newlyRemoved = tempRemoved;

					if (typeof typeOptions.submitAction === "function") {
						return;
					}
					
					if (vm.selectionBucket.newlySelected) {
						if (vm.selectionBucket.newlySelected.length != 0) {
							_.forEach(vm.selectionBucket.newlySelected, function (item) {
								var promise = dataOps.createSubItemRelation(vm.targetWfid || savedItem.wfid, item, { kind: enums.subItemsKind.childrenByUser, ticket: vm.ticket });

								promisesArray.push(promise);
								promises.push(promise);
							});
						}
					}
					if (vm.selectionBucket.newlyRemoved) {
						if (vm.selectionBucket.newlyRemoved.length != 0) {
							_.forEach(vm.selectionBucket.newlyRemoved, function (item) {
								var
									dataRelation = _.find(originalRelations, { wfcid: item.wfid }),
									promise
								;

								if (dataRelation) {
									promise = dataOps.destroy(dataRelation, { ticket: vm.ticket });

									promisesArray.push(promise);
									promises.push(promise);
								}
							});
						}
					}

					$q.all(promises).then(function () {
						reset();
					});
				}

				function removeItem(item) {
					tempRemoved.push(item);
					if (_.find(vm.selectionBucket.preSelected, item) != undefined) {
						_.remove(tempPreSelected, item);
						_.remove(vm.selectionBucket.preSelected, item);
					}
					else {
						_.remove(tempSelected, item);
						_.remove(vm.selectionBucket.newlySelected, item);
					} 
					_.remove(vm.selectionBucket.allSelected, item);

					if (vm.selectionBucket.allSelected.length === 0) {
						if (vm.required)
							vm.isPickerValid = false;

						vm.industryPickerError = true;
					}
					if (typeof typeOptions.onPickerClosed === "function")
						typeOptions.onPickerClosed(vm.selectionBucket);
					$timeout();
				}
				
				function reset() {
					vm.selectionBucket = {};

					
					if (vm.preselectedItems instanceof Array) {
						vm.selectionBucket.preSelected = vm.selectionBucket.allSelected = _.clone(vm.preselectedItems);
						vm.loaded = true;
					}
					else if (vm.singleSelectedItem) {
						if (vm.singleSelectedItem instanceof wfObject.WfObject) {
							vm.selectionBucket.preSelected = vm.selectionBucket.allSelected = [ vm.singleSelectedItem ];
							vm.loaded = true;
						}
						else if ((vm.singleSelectedItem.type && vm.singleSelectedItem.id)
							|| (vm.singleSelectedItem.objectType && vm.singleSelectedItem.objectId)
							|| vm.singleSelectedItem.wfid) {
							dataOps.getObject(vm.singleSelectedItem).then(function (res) {
								vm.selectionBucket.preSelected = vm.selectionBucket.allSelected = [ res ];
								vm.loaded = true;
								$timeout();
							});
						}
						else {
							vm.selectionBucket.preSelected = vm.selectionBucket.allSelected = [ ];
							vm.loaded = true;
						}
					}
					else if (vm.targetWfid) {
						originalRelations = _.filter(dataQuery.getSubItems(vm.targetWfid, enums.subItemsKind.childrenByUser), { organizationId: organizationId });
						vm.selectionBucket.preSelected = vm.selectionBucket.allSelected = _.map(originalRelations, 'childContent');
					}
					else {
						originalRelations = [];
						vm.selectionBucket.preSelected = vm.selectionBucket.allSelected = [];
					}

					tempSelected = [];
					tempRemoved = [];
					tempPreSelected = [];
					preSelectedSaved = false;
				}

				function schemaFormBeforeSubmit(event, model) {
					if (typeof typeOptions.submitAction === "function") {
						if (typeOptions.singlePick && !typeOptions.targetWfid)
							vm.singleSelectedItem = vm.selectionBucket.allSelected[0]

						typeOptions.submitAction(event, model, vm.selectionBucket);
					}
				}

				function getTextFromItem(item) {
					var itemContent = item, text;
					
					if (item.isRelationalType())
						 itemContent = item.childContent;

					text = wfPropertyExtractor.getMainTextual(itemContent);

					return text;
				}
			}
		};
	} ]);
})();
