import reactItemList from './ItemList'
import reactItemSummary from './ItemSummary'
import reactItemSummaryList from './ItemSummaryList'
import reactItemSummaryContainer from './ItemSummaryContainer'
import reactSinglePointChart from './SinglePointChart'
import reactQuestionBox from './QuestionBox'
import reactIntercomCssBaseline from './IntercomCssBaseline'
import reactDataExplorer from './DataExplorer'
import reactThirdPartyPermissions from './ThirdPartyPermissions'
import reactProductOrganizationExplorer from './ProductOrganizationExplorer'
import reactDataCollectorImporter from './DataCollectorImporter'
import reactRequirementPackageExporter from './RequirementPackageExporter'
import reactNetworkOrganizationsExporter from './NetworkOrganizationsExporter'
import reactCalculationScheduleDialog from './CalculationScheduleDialog'
import reactNextEmailSendoutInfoDialog from './NextEmailSendoutInfoDialog'
import reactDataCollectorDashboard from './DataCollectorDashboard'

export const registerReactDirectives = () => { //eslint-disable-line import/prefer-default-export
  angular.module('wf')
    .component('reactItemList', reactItemList)
    .component('reactItemSummary', reactItemSummary)
    .component('reactItemSummaryList', reactItemSummaryList)
    .component('reactItemSummaryContainer', reactItemSummaryContainer)
    .component('reactSinglePointChart', reactSinglePointChart)
    .component('reactQuestionBox', reactQuestionBox)
    .component('reactIntercomCssBaseline', reactIntercomCssBaseline)
    .component('reactDataExplorer', reactDataExplorer)
    .component('reactThirdPartyPermissions', reactThirdPartyPermissions)
    .component('reactProductOrganizationExplorer', reactProductOrganizationExplorer)
    .component('reactDataCollectorImporter', reactDataCollectorImporter)
    .component('reactRequirementPackageExporter', reactRequirementPackageExporter)
    .component('reactNetworkOrganizationsExporter', reactNetworkOrganizationsExporter)
    .component('reactCalculationScheduleDialog', reactCalculationScheduleDialog)
    .component('reactNextEmailSendoutInfoDialog', reactNextEmailSendoutInfoDialog)
    .component('reactDataCollectorDashboard', reactDataCollectorDashboard)
}
