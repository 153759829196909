import { boolean } from '@storybook/addon-knobs/react';
import * as enums from '@worldfavor/constants/enums'
import html2canvas from 'html2canvas'
// import Color from "color";

(function () {
	'use strict';

	angular
		.module('wf.common')
		.component('wfChartNew', {
			templateUrl: "scripts/wf/visualization/wfChart.component.html",
			require: {
				wfListInterfaceStatistical: "?^^wfListInterfaceStatistical",
				wfDataNegotiator: "?^^wfDataNegotiator",
				wfExpand: "?^wfExpand"
			},
			controller: wfChartNewController,
			controllerAs: "vm",
			bindings: {
				fromItem: '=',
				ticket: '=',
				negotiatorFromAttr: '=negotiator',
				configFromAttribute: '=config',
				usePersistentNegotiator: "<",
				preloaded: "<",
				customDataset: '='
			}
		})
		;

	wfChartNewController.$inject = ["$scope", "$timeout", "$attrs", "$element", "$translate", "DataNegotiator", "WfChartistService", "dataOperationsService", '$q'];
	function wfChartNewController($scope, $timeout, $attrs, $element, $translate, DataNegotiator, wfChartistService, dataOps, $q) {
		var
			vm = this,
			chartPrototype,
			stackedBarChartPrototype,
			donutChartPrototype,
			optionsPrototype,
			datasetPrototype,
			datasets,
			datasetLabels,
			scopeDestroyed,
			ticket,
			itemForSavedSettings = _.get(vm.configFromAttribute, "itemForSavedSettings") || _.get(vm.fromItem, "dataRelation");

		var selectedChartSettings = _.assign({
			// Default values are combined with values on the dataRelation settings from db
			chartType: "line",
			aggregateMode: "combine",
			splitOption: undefined,
			convertMeasureAnswerUnits: false,
			useSingleValue: false,
			period: undefined,
			showAverage: true,
			excludeYears: undefined,
		}, _.get(itemForSavedSettings, "settings.chartSettings"));

		let yearChanges = false;

		vm.$onInit = function () {
			chartPrototype = {
				showButton: true,
				type: selectedChartSettings.chartType,
				aggregateMode: selectedChartSettings.aggregateMode,
				splitOption: selectedChartSettings.splitOption,
				convertMeasureAnswerUnits: selectedChartSettings.convertMeasureAnswerUnits,
				splitUpOrganizationStatistics: selectedChartSettings.aggregateMode === "separate" && (!selectedChartSettings.splitOption || selectedChartSettings.splitOption === "organizations"),
				splitUpRelativeMeasureSourcesStatistics: selectedChartSettings.aggregateMode === "separate" && selectedChartSettings.splitOption === "relativeMeasureSourceObjects",
				aggregateYearly: selectedChartSettings.aggregateMode !== "separate" && selectedChartSettings.splitOption === "yearly",
				aggregatePeriodFrequencies: selectedChartSettings.aggregateMode !== "separate" && selectedChartSettings.aggregatePeriodFrequencies,
				showAverage: selectedChartSettings.showAverage,
				created: false,
				loaded: false,
				element: undefined,
				chartInstance: undefined,
				chartHeight: 200,
				addLinearGradientElement: false,
				chartPadding: { top: 15, right: 15, bottom: 5, left: 10 }, //default options
				chartWidth: undefined,
				showSeriesSelection: true,
				//Layout specific settings
				cardLayout: false,
				showTypeToggler: true,
				saveTypeOnToggle: false,

				//Chart Data
				fillTimeGapsOnXAxes: false,
				accumulateLineChart: false,
				startFromZero: false,
				showLegend: true,
				showTooltips: true,
				mockRandomData: false, //used for testing purposes

				//Chart Options
				showArea: false,
				lineSmooth: false,
				showPoint: true,
				showXGrid: true,
				showYGrid: true,
				showXLabels: true,
				showYLabels: true,
				useFullWidth: false,
				dashedLine: false,
				stackBars: false,
				enableAnimations: false,
				disablePointerEvents: false,
				seriesBarDistance: 5, //series bar distance represents the distance from the x1 of the first bar to the x1 of the second bar
				colors: ["#3b7eb8", "#1abc9c", "#f39c12", "#34495e", "#bdc3c7", "#9b59b6"], //each color represents series,
				chartBackgroundColor: "white",

				//Pie/Donut chart specific options
				showLabelPieChart: true,
				donut: false,
				donutWidth: undefined,

				//Info messages
				showInfoMessage: false,
				allowToDismissMessage: true,
				messageToDisplay: undefined,
				messages: {
					emptyStateMessage: $translate.instant("modules.visualization.chartist.emptyState"),
					notEnoughData: $translate.instant("modules.visualization.chartist.notEnoughData"),
					barOverlapingMessage: $translate.instant("modules.visualization.chartist.barOverlapingMessage"),
					allValuesAreZeros: $translate.instant("modules.visualization.chartist.allValuesAreZeros")
				},

				//Functions
				recreateChart: recreateChart,
				generateRandomData: generateRandomData,
				clearData: clearData,
				onSeriesClick: showSinglePointView,
			};

			stackedBarChartPrototype = _.assign(_.clone(chartPrototype), { stackBars: true });
			donutChartPrototype = _.assign(_.clone(chartPrototype), {
				donut: true,
				donutWidth: 20,
				labelOffset: 20
			});
			ticket = vm.ticket;

			_.assign(vm, {
				//Variables
				currentType: chartPrototype.type,
				currentAggregateMode: chartPrototype.aggregateMode,
				currentSplitOption: chartPrototype.splitOption,
				convertMeasureAnswerUnits: chartPrototype.convertMeasureAnswerUnits,
				currentUnitConversionMode: chartPrototype.convertMeasureAnswerUnits ? "converted" : "asReported",
				aggregateYearly: chartPrototype.aggregateYearly,
				aggregatePeriodFrequencies: chartPrototype.aggregatePeriodFrequencies,
				periodSpan: selectedChartSettings.period,
				enableSingleValueOption: true,
				currentChart: undefined,
				showAverage: selectedChartSettings.showAverage,
				charts: {
					line: _.defaults({ showSeriesSelection: !vm.customDataset }, { type: "line", name: $translate.instant("modules.visualization.chartTypes.line"), actualType: "line" }, chartPrototype),
					bar: _.defaults({ showSeriesSelection: !vm.customDataset }, { type: "bar", name: $translate.instant("modules.visualization.chartTypes.bar"), actualType: "bar" }, chartPrototype),
					stackedBar: _.defaults({ showSeriesSelection: !vm.customDataset }, { type: "bar", name: $translate.instant("modules.visualization.chartTypes.stackedBar"), actualType: "stackedBar" }, stackedBarChartPrototype),
					pie: _.defaults({ type: "pie", name: $translate.instant("modules.visualization.chartTypes.pie"), actualType: "pie" }, chartPrototype),
					donut: _.defaults({ type: "pie", name: $translate.instant("modules.visualization.chartTypes.donut"), actualType: "donut" }, donutChartPrototype)
				},
				excludeYears: selectedChartSettings.excludeYears,
				filteringDropdownYears: undefined,
				yearOptionText: selectedChartSettings.excludeYears && selectedChartSettings.excludeYears.length > 0 ?
					$translate.instant("modules.visualization.yearFilter.some") : $translate.instant("modules.visualization.yearFilter.all"),
				isRelativeMeasure: false,
				appendTooltipToBody: _.get(vm.configFromAttribute, "appendTooltipToBody") || false,
				showSaveButton: (_.get(vm.configFromAttribute, "showSaveButton") && itemForSavedSettings) || false,
				saveButtonTooltipText: $translate.instant("modules.visualization.chartist.saveButtonTooltipMessages.selectedChartType"),
				showDownloadButton: (_.get(vm.configFromAttribute, "showDownloadButton") && itemForSavedSettings) || false,
				averageToggleTooltipText: $translate.instant("modules.visualization.averageToggle.tooltip"),
				itemWfid: _.get(vm.configFromAttribute, "itemWfid") || (vm.fromItem ? (vm.fromItem.type + "-" + vm.fromItem.id) : undefined),
				downloadButtonTooltipText: $translate.instant("modules.visualization.chartist.downloadButtonTooltipMessage"),
				wfChartistService: wfChartistService,
				periodRequests: {}, // {"20150101|20151231": { loaded: true }}
				allPeriodsLoaded: false,
				loadDataAndInitialize: loadDataAndInitialize,
				setAggregateMode: setAggregateMode,
				saveCurrentChartSelections: saveCurrentChartSelections,
				selectChartType: selectChartType,
				setUnitConversionMode: setUnitConversionMode,
				showSinglePointView: showSinglePointView,
				captureChart: captureChart,
				handleAverageColor: handleAverageColor,
				hideAverageValue: hideAverageValue,
				hideAverageValueLabelCall: hideAverageValueLabelCall,
				showAverageToggle: showAverageToggle,
				onSelectYear: onSelectYear,
				getDataOptionSelectedItemText: getDataOptionSelectedItemText,
				showcombineFrequencyOption: showcombineFrequencyOption,
			});

			vm.charts.stackedBar.stackBars = true;


			if (vm.wfExpand) {
				if (vm.wfExpand.expanded) {
					activate();
				}
				else {
					vm.wfExpand.element.on("shown.bs.collapse", function () {
						if (!vm.currentChart)
							activate();
					});
				}
			}
			else {
				activate();
			}

			function activate() {
				if (vm.currentType === undefined)
					vm.currentType = "line";

				if (vm.currentAggregateMode === undefined)
					vm.currentAggregateMode = "combine";

				vm.splitUpOrganizationStatistics = vm.currentAggregateMode === "separate" && (!vm.currentSplitOption || vm.currentSplitOption === "organizations");
				vm.splitUpRelativeMeasureSourcesStatistics = vm.currentAggregateMode === "separate" && vm.currentSplitOption === "relativeMeasureSourceObjects";
				vm.aggregateYearly = vm.currentAggregateMode !== "separate" && vm.currentSplitOption === "yearly";
				vm.aggregatePeriodFrequencies = vm.currentAggregateMode !== "separate" && vm.currentSplitOption === "frequency";

				if (!selectedChartSettings.period) {
					vm.allPeriodsLoaded = true;
				}

				loadDataAndInitialize();
			}

			function loadDataAndInitialize(options) {
				var
					existingNegotiatorInstance, fromItem,
					offscreen = document.createElement('canvas'),
					ctx = offscreen.getContext('2d'),
					bgGradient = ctx.createLinearGradient(0, 0, 0, 110),
					bgGradient_lineFill = ctx.createLinearGradient(0, 0, 0, 130),
					deferred = $q.defer()
					;
				vm.item = vm.preloaded ? wfObject.get(vm.fromItem.type + "-" + vm.fromItem.id) : undefined;

				if (vm.customDataset) {
					vm.negotiator = undefined;
					vm.negotiatorFromAttr = undefined;
					vm.wfDataNegotiator = undefined;
				}

				bgGradient.addColorStop(0, 'rgba(36, 138, 206, 1)');
				bgGradient.addColorStop(1, 'rgba(52, 152, 219, 1)');

				bgGradient_lineFill.addColorStop(0, 'rgba(36, 138, 206, .1)');
				bgGradient_lineFill.addColorStop(1, 'rgba(52, 152, 219, 0)');

				if (vm.currentChart) {
					vm.currentChart.loaded = false;
					vm.currentChart = undefined;
				}

				// splitUpOrganizationStatistics = vm.splitUpOrganizationStatistics = vm.currentAggregateMode === "separate";
				if (!_.isEmpty(vm.configFromAttribute)) {
					vm.useEmptyState = vm.configFromAttribute.useEmptyState;

					_.each(vm.charts, function (chart) {

						_.assign(chart, vm.configFromAttribute);
						if (vm.configFromAttribute.colors) {
							// Needs to be cloned, othwerwise a Non-Assignable Expression error will throw because Chartist is reassigning the array
							// if the number of colors doesn't match the number of series
							chart.colors = _.clone(vm.configFromAttribute.colors);
						}
					});

					if (typeof vm.configFromAttribute.onLoaded === "function")
						vm.configFromAttribute.onLoaded(vm)
				}

				if (options && options.ticket) {
					ticket = options.ticket;
				}

				if (!vm.preloaded && (vm.negotiatorFromAttr || vm.wfDataNegotiator || vm.fromItem)) {
					$element.show();
					if (vm.negotiator)
						vm.negotiator.abortOngoingXhrRequests();


					// TODO: The checks done here is getting really messy and should be refactored.
					// It is confusing which negotiator is used

					if (!_.isEmpty(vm.negotiatorFromAttr) || !_.isEmpty(vm.wfDataNegotiator) || (vm.fromItem.type && vm.fromItem.id)) {
						existingNegotiatorInstance = vm.negotiatorFromAttr || vm.wfDataNegotiator;
						fromItem = vm.fromItem;

						if (vm.negotiator) {
							if ((vm.splitUpOrganizationStatistics !== vm.negotiator.splitUpOrganizationStatistics)
								|| (vm.splitUpRelativeMeasureSourcesStatistics !== vm.negotiator.splitUpRelativeMeasureSourcesStatistics)
								|| (vm.aggregateYearly !== vm.negotiator.aggregateYearly)
								|| (vm.aggregatePeriodFrequencies !== vm.negotiator.aggregatePeriodFrequencies)
								|| vm.convertMeasureAnswerUnits
							) {
								ticket = _.get(options, "ticket") || vm.negotiator.ticket;
								fromItem = vm.negotiator.fromItem;
								existingNegotiatorInstance = undefined;
								vm.usePersistentNegotiator = true;
							}
						}

						if (existingNegotiatorInstance && (
							typeof existingNegotiatorInstance.splitUpOrganizationStatistics === "boolean"
							|| typeof existingNegotiatorInstance.splitUpRelativeMeasureSourcesStatistics === "boolean"
							|| typeof existingNegotiatorInstance.aggregateYearly === "boolean"
							|| typeof existingNegotiatorInstance.aggregatePeriodFrequencies === "boolean"
						)) {
							if (_.get(options, "useNewAggregateMode") && existingNegotiatorInstance.splitUpOrganizationStatistics !== vm.splitUpOrganizationStatistics) {
								options.forceNewNegotiator = true;
							}
							else if (_.get(options, "useNewAggregateMode") && existingNegotiatorInstance.splitUpRelativeMeasureSourcesStatistics !== vm.splitUpRelativeMeasureSourcesStatistics) {
								options.forceNewNegotiator = true;
							}
							else if (_.get(options, "useNewAggregateMode") && existingNegotiatorInstance.aggregateYearly !== vm.aggregateYearly) {
								options.forceNewNegotiator = true;
							}
							else if (_.get(options, "useNewAggregateMode") && existingNegotiatorInstance.aggregatePeriodFrequencies !== vm.aggregatePeriodFrequencies) {
								options.forceNewNegotiator = true;
							}
							else {
								vm.splitUpOrganizationStatistics = existingNegotiatorInstance.splitUpOrganizationStatistics;
								vm.splitUpRelativeMeasureSourcesStatistics = existingNegotiatorInstance.splitUpRelativeMeasureSourcesStatistics;
								vm.aggregateYearly = existingNegotiatorInstance.aggregateYearly;
								vm.aggregatePeriodFrequencies = existingNegotiatorInstance.aggregatePeriodFrequencies;
								vm.currentAggregateMode = (vm.splitUpOrganizationStatistics || vm.splitUpRelativeMeasureSourcesStatistics) ? "separate" : "combine";

								if (vm.aggregateYearly) {
									vm.currentSplitOption = "yearly"
								}

								if (vm.aggregatePeriodFrequencies) {
									vm.currentSplitOption = "frequency"
								}

								if (vm.splitUpOrganizationStatistics) {
									vm.currentSplitOption = "organizations"
								}
								else if (vm.splitUpRelativeMeasureSourcesStatistics) {
									vm.currentSplitOption = "relativeMeasureSourceObjects"
								}
							}
						}
						if (existingNegotiatorInstance && typeof existingNegotiatorInstance.convertMeasureAnswerUnits === "boolean") {
							vm.convertMeasureAnswerUnits = existingNegotiatorInstance.convertMeasureAnswerUnits;
						}

						if (existingNegotiatorInstance && typeof existingNegotiatorInstance.periodSpan === "string") {
							vm.periodSpan = existingNegotiatorInstance.periodSpan;
							vm.selectedPeriod = existingNegotiatorInstance.periodSpan;
						}

						if (existingNegotiatorInstance && existingNegotiatorInstance.excludeYears instanceof Array) {
							vm.excludeYears = existingNegotiatorInstance.excludeYears;
							vm.filteringDropdownYears = vm.excludeYears && vm.filteringDropdownYears ? vm.filteringDropdownYears.map(yearObj => (
								{ ...yearObj, selected: !vm.excludeYears.includes(parseInt(yearObj.name)) }
							)) : undefined
						}

						if (existingNegotiatorInstance && typeof existingNegotiatorInstance.aggregatePeriodFrequencies === 'boolean') {
							vm.aggregatePeriodFrequencies = existingNegotiatorInstance.aggregatePeriodFrequencies;
						}

						let newNegotiatorInstanceCreated = (Boolean)(existingNegotiatorInstance || !vm.usePersistentNegotiator)

						// Getting messy!
						if ((existingNegotiatorInstance || !vm.usePersistentNegotiator) && (
							!existingNegotiatorInstance
							|| existingNegotiatorInstance.splitUpOrganizationStatistics === vm.splitUpOrganizationStatistics
							|| existingNegotiatorInstance.splitUpRelativeMeasureSourcesStatistics === vm.splitUpRelativeMeasureSourcesStatistics
							|| existingNegotiatorInstance.aggregateYearly === vm.aggregateYearly
							|| existingNegotiatorInstance.convertMeasureAnswerUnits === vm.convertMeasureAnswerUnits
							|| existingNegotiatorInstance.periodSpan === vm.periodSpan
							|| existingNegotiatorInstance.excludeYears === vm.excludeYears
							|| existingNegotiatorInstance.includeAvailablePeriods === vm.includeAvailablePeriods
							|| existingNegotiatorInstance.aggregatePeriodFrequencies === vm.aggregatePeriodFrequencies
						)
						) {
							vm.loading = true;
							vm.negotiator = DataNegotiator.instantiate(existingNegotiatorInstance, {
								fromItem: fromItem,
								ticket: ticket,
								loadParents: false,
								splitUpOrganizationStatistics: vm.splitUpOrganizationStatistics,
								splitUpRelativeMeasureSourcesStatistics: vm.splitUpRelativeMeasureSourcesStatistics,
								aggregateYearly: vm.aggregateYearly,
								onlyStatistics: true,
								convertMeasureAnswerUnits: vm.convertMeasureAnswerUnits,
								periodSpan: vm.periodSpan,
								excludeYears: vm.excludeYears,
								includeAvailablePeriods: true,
								aggregatePeriodFrequencies: vm.aggregatePeriodFrequencies,
							});
						}
						else {
							newNegotiatorInstanceCreated = true
							vm.loading = true;
							vm.negotiator = dataOps.persistentDataNegotiator(DataNegotiator, {
								fromItem: fromItem || _.get(existingNegotiatorInstance, "fromItem"),
								ticket: ticket || _.get(existingNegotiatorInstance, "ticket"),
								loadParents: false,
								splitUpOrganizationStatistics: vm.splitUpOrganizationStatistics,
								splitUpRelativeMeasureSourcesStatistics: vm.splitUpRelativeMeasureSourcesStatistics,
								aggregateYearly: vm.aggregateYearly,
								convertMeasureAnswerUnits: vm.convertMeasureAnswerUnits,
								onlyStatistics: true,
								periodSpan: vm.periodSpan,
								excludeYears: vm.excludeYears,
								includeAvailablePeriods: true,
								aggregatePeriodFrequencies: vm.aggregatePeriodFrequencies,
							}, options && options.forceNewNegotiator);
						}

						if (vm.negotiator.item) { // Indicates that the data has been loaded once before
							handleNegotiatorData().then(function () {
								deferred.resolve();
							});
						}
						else {
							if (!vm.negotiator.xhrRequest) {
								vm.loading = true;
								vm.negotiator.loadItemsFromServer();
							}

							if (newNegotiatorInstanceCreated) {
								vm.negotiator.onRequest.then(function (res) {
									handleNegotiatorData().then(function () {
										deferred.resolve();
									});
								});
								vm.negotiator.onRequest.fail(function (res) {
									vm.showErrorMessage = true
									vm.currentChart = undefined
									deferred.resolve();
								});
							}
							// else
							// {
							// 	handleNegotiatorData().then(function() {
							// 		deferred.resolve();
							// 	});
							// }
						}
					}
				}
				else if (vm.item || (vm.customDataset && !_.isEmpty(vm.customDataset))) {
					handleNegotiatorData();
					deferred.resolve();
				}
				else {
					$element.hide();
					deferred.resolve();
				}

				function handleNegotiatorData() {
					var
						deferred2 = $q.defer(),
						item,
						fromItem
						;

					//vm.negotiator.includeAvailablePeriods = false;

					if (scopeDestroyed) {
						deferred2.reject();
						return deferred2.promise;
					}

					vm.loading = false;

					if (vm.customDataset) {
						datasets = vm.customDataset;
						fromItem = {};
						item = {};
					}
					else {
						datasets = _.get(vm.negotiator, "itemMetadata.statistics.datasets");
						datasetLabels = vm.currentAggregateMode === "separate" || vm.aggregatePeriodFrequencies ? _.get(vm.negotiator, "itemMetadata.statistics.datasetLabels") : undefined;

						if (vm.negotiator && !vm.preloaded) {
							item = vm.negotiator.item;
							fromItem = vm.negotiator.fromItem;

							datasets = _.get(vm.negotiator, "itemMetadata.statistics.datasets");
							datasetLabels = vm.currentAggregateMode === "separate" || vm.aggregatePeriodFrequencies ? _.get(vm.negotiator, "itemMetadata.statistics.datasetLabels") : undefined;
						}
						else if (vm.preloaded) {
							item = wfObject.get(vm.fromItem.type + "-" + vm.fromItem.id);
							fromItem = vm.fromItem;

							if (item.metadata && item.metadata.statistics && item.metadata.statistics.datasets && item.metadata.statistics.datasetLabels) {
								datasets = item.metadata.statistics.datasets;
								datasetLabels = item.metadata.statistics.datasetLabels;
								vm.hasData = true;
							}
							else
								console.error("Preloaded item does not have datasets or datasetLabels in metadata.statistics object");
						}

						if (typeof vm.configFromAttribute.onDataLoaded === "function") {
							vm.configFromAttribute.onDataLoaded(item)
						}

						if (item.type === enums.objectType.relativeMeasure)
							vm.isRelativeMeasure = true;



						const ratioUnits = [
							"%",
							"percentage",
							"MWh/employee",
							"tCO2e/employee",
							"tCO2e/EUR",
							"MWh/EUR",
							"tCO2e/MEUR",
							"MWh/MEUR",
							"tCO2e/m2",
							"MWh/m2",
							"accidents/worktime",
							"tCO2e/SEK",
							"kgCO2e/produced liter",
							"energy consumption/produced liter",
							"tCO2e/MSEK",
							"tCO2e/FTE",
							"tCO2e (SFDR)",
							"water/produced liter"
						]

						vm.showAggregateYearlyOption = vm.isRelativeMeasure || !(ratioUnits.includes(_.get(item, "unit.name")) || ratioUnits.includes(_.get(item, "unit.symbol")))

						//Units are now taken from the measure answer

						// if (item.type === enums.objectType.measure || item.type === enums.objectType.relativeMeasure)
						// 	unit = _.get(item, "unit.symbol");

						// if (vm.charts.length !== 0 && unit) {
						// 	_.each(vm.charts, function(chart) {
						// 		chart.unit = unit;
						// 	});
						// }
					}

					// Extract the years to be shown in the filter years dropdown
					if (!vm.filteringDropdownYears) {
						const datasetPeriodSpans = _.get(vm.negotiator, "itemMetadata.statistics.datasetPeriodSpans");
						if (datasetPeriodSpans) {
							let datasetPeriodSpansToSelect = datasetPeriodSpans.map(d => d.slice(0, 4));

							datasetPeriodSpansToSelect = datasetPeriodSpansToSelect.filter((item, index) => datasetPeriodSpansToSelect.indexOf(item) === index)
							vm.filteringDropdownYears = datasetPeriodSpansToSelect.map(year => ({ name: year, selected: true }))

							if (vm.excludeYears && vm.excludeYears.length > 0) {
								vm.filteringDropdownYears = vm.filteringDropdownYears.map(yearObj => ({ ...yearObj, selected: !vm.excludeYears.includes(parseInt(yearObj.name)) }))
							}
						}
					}

					if (vm.configFromAttribute.mockRandomData)
						datasets = wfChartistService.mockRandomData({ numberOfDatasets: vm.configFromAttribute.mockRandomData.numberOfDatasets, numberOfYears: vm.configFromAttribute.mockRandomData.numberOfYears });

					if (!vm.preloaded && datasets && datasets.length && _.some(datasets, function (dataset) {
						return dataset.data.length > 0;
					})) {
						if (fromItem.type === enums.objectType.measure || fromItem.type === enums.objectType.relativeMeasure) {
							if (vm.currentAggregateMode === "separate") {
								const idPrefix = vm.currentSplitOption === "relativeMeasureSourceObjects" ? "period_source_" : "period_org_"
								datasets = _.filter(datasets, function (dataset) {
									return dataset.id.indexOf(idPrefix) === 0;
								});
							}
							else if (vm.aggregatePeriodFrequencies) {
								const idPrefix = "period_year_"
								datasets = _.filter(datasets, function (dataset) {
									return dataset.id.indexOf(idPrefix) === 0;
								});
							}
							else {
								datasets = _.filter(datasets, { id: "period" });

								// If there is only one point to the dataset, show the single point view
								vm.useSinglePointView = datasets.every(dataset => dataset.data.length === 1);
								vm.useSinglePointData = datasets[0].data[0];
								vm.isInconsistentData = datasets[0].inconsistent;
							}
						}

						if (item.type === enums.objectType.question && datasets.length > 1)
							datasets = _.filter(datasets, function (dataset) { return dataset.id !== "count" });

						if ("brightBg" in $attrs) {
							optionsPrototype = {
								responsive: true,
								scales: {
									xAxes: [{
										display: true,
										gridLines: {
											zeroLineColor: "rgba(0,0,0,0.5)",
											color: "rgba(0,0,0,0.1)",
											// tickMarkLength: 10
										},
										ticks: {
											beginAtZero: true,
											padding: 5,
											fontColor: "rgba(0,0,0,0.5)",
										}
									}],
									yAxes: [{
										display: true,
										gridLines: {
											zeroLineColor: "rgba(0,0,0,0.5)",
											color: "rgba(0,0,0,0.1)",
											// tickMarkLength: 0
										},
										ticks: {
											beginAtZero: true,
											padding: 5,
											fontColor: "rgba(0,0,0,0.5)"
										}
									}]
								},
								layout: {
									padding: {
										left: -5,
										right: 0,
										bottom: 0,
										top: 0
									}
								}
							}

							// datasetPrototype = {
							// 	fill: true,
							// 	borderColor: "rgba(0,0,0,0.5)",
							// 	borderWidth: 2,
							// 	pointBorderColor: "rgba(0,0,0,0.5)",
							// 	pointBackgroundColor: "#eee",
							// 	pointBorderWidth: 1.5,
							// 	pointRadius: 4,
							// 	lineTension: 0,
							// }

							datasetPrototype = {
								fill: true,
								backgroundColor: bgGradient_lineFill,
								borderColor: "rgba(36, 138, 206, 1)",
								borderWidth: 3,
								pointBorderColor: "rgba(36, 138, 206, 1)",
								pointBackgroundColor: "rgba(36, 138, 206, 1)",
								pointBorderWidth: 2,
								pointRadius: 4,
								lineTension: 0,
							}

							// datasetPrototype_bar = {
							// 	fill: true,
							// 	backgroundColor: bgGradient,
							// 	hoverBackgroundColor: bgGradient,
							// 	borderColor: "transparent",
							// 	borderWidth: 0,
							// }
						}
						else {
							optionsPrototype = {
								responsive: true,
								scales: {
									xAxes: [{
										display: true,
										gridLines: {
											zeroLineColor: "rgb(255,255,255)",
											color: "rgba(255,255,255,0.1)"
										},
										ticks: {
											beginAtZero: true,
											padding: 5,
											fontColor: "rgba(255,255,255,0.8)",
										}
									}],
									yAxes: [{
										display: true,
										gridLines: {
											zeroLineColor: "rgba(255,255,255,0.4)",
											color: "rgba(255,255,255,0.1)"
										},
										ticks: {
											//autoSkip: false,
											//maxRotation: 0,
											//fontSize: 12,
											//fontWeight: 400,
											beginAtZero: true,
											padding: 20,
											fontColor: "rgba(255,255,255,0.8)"
										}
									}]
								},
								layout: {
									padding: {
										left: -5,
										right: 0
									}
								}
							};

							datasetPrototype = {
								fill: true,
								borderColor: "#fff",
								borderWidth: 2,
								pointBorderColor: "#fff",
								pointBackgroundColor: "#5696DA",
								pointBorderWidth: 1,
								pointRadius: 4,
								lineTension: 0,
							};
						}

						if (_.get(vm.negotiator, "dataNeededAfterChartTypeChange")) {
							vm.currentType = vm.currentChart ? (vm.currentChart.actualType || vm.currentChart.type) : vm.negotiator.currentChartType
						}

						vm.charts[vm.currentType].aggregatePeriodFrequencies = vm.aggregatePeriodFrequencies;

						recreateChart.apply(vm.charts[vm.currentType]).then(function () {
							const hasSingleReportedPeriod = _.get(vm.negotiator, "itemMetadata.statistics.datasetPeriodSpans.length") === 1;
							//unfortunatelly the singlepointview has to be enabled here because the data changes before recreateChart.apply()
							if (!vm.customDataset && (hasSingleReportedPeriod || (!_.get(vm.negotiator, "dataNeededAfterChartTypeChange") && selectedChartSettings.useSingleValue)) && vm.currentAggregateMode === "combine") {
								showSinglePointView(vm.selectedPeriod || selectedChartSettings.period, true)
							}
							else {
								vm.useSinglePointView = false;
								vm.currentChart.chartElement.show();
								$timeout();
							}

							if (vm.negotiator) {
								vm.negotiator.dataNeededAfterChartTypeChange = false;
							}

							deferred2.resolve();
						});
						$timeout();
					}
					else {
						if (vm.preloaded) {
							recreateChart.apply(vm.charts[vm.currentType]).then(function () {
								deferred2.resolve();
							});
						}
						else if (!vm.useEmptyState) {
							$element.hide();
							deferred2.resolve();
						}
					}

					return deferred2.promise;
				}

				return deferred.promise;
			}

			function createChartist(chart) {
				//Check if selected chart type supports aggregateMode
				// if (vm.currentAggregateMode === "combine" && chart.aggregateMode === "separate") {
				// 	if (vm.currentType !== chart.actualType)
				// 		chart = vm.charts.line;
				// }

				var
					chartistConfig,
					tempBarChart,
					fromItem = vm.negotiator ? vm.negotiator.fromItem : vm.fromItem,
					deferred = $q.defer();
				vm.chartOptions = {
					chartType: chart.type,
					optionsPrototype: optionsPrototype,
					datasetPrototype: datasetPrototype,
					datasets: datasets,
					valueProperties: undefined,
					disablePointerEvents: false,
					datasetLabels: datasetLabels,
					ticket: vm.negotiator ? vm.negotiator.ticket : undefined,
					aggregateMode: vm.currentAggregateMode,
					showAverage: vm.showAverage
				};

				if (vm.customDataset)
					fromItem = {};

				vm.hasData = !_.isEmpty(datasets);

				chart.aggregateMode = vm.currentAggregateMode;
				vm.currentChart = chart;
				vm.chartOptions.measure = fromItem;
				_.assign(vm.chartOptions, vm.currentChart);

				if (fromItem.type === enums.objectType.measure || fromItem.type === enums.objectType.relativeMeasure) {
					if (vm.chartOptions.ticket && (vm.chartOptions.ticket.organizationIds || vm.chartOptions.ticket.receivingOrganizationsAndLimitedDataAccessFromInfluenceId)) { //Aggregated mode
						if (vm.currentAggregateMode === "separate") {
							vm.chartOptions.valueProperties = ["value"];
						}
						else if (vm.aggregatePeriodFrequencies) {
							vm.chartOptions.valueProperties = ["sum"];
						}
						else {
							vm.chartOptions.valueProperties = ["average", "sum"];
							if (vm.chartOptions.colors && vm.chartOptions.colors.length === 1 && vm.showAverage) {
								vm.chartOptions.colors = ["#ccc", vm.chartOptions.colors[0]]
							}
						}
					}
					else
						vm.chartOptions.valueProperties = ["value"];
				}
				else if (vm.negotiator && vm.negotiator.fromItem.type === enums.objectType.question) {
					_.each(datasets, function (dataset) {
						if (dataset.id.indexOf("questionAnswerType-") === 0 && dataset.id !== "questionAnswerType-unanswered") {
							dataset.accumulate = true;
						}
					});

				}

				if (!vm.hasData) {
					vm.chartOptions.asEmptyState = true;
					vm.chartOptions.disablePointerEvents = true;
					vm.showSaveButton = false;
				}
				else {
					if (_.get(vm.configFromAttribute, "showSaveButton"))
						vm.showSaveButton = true;
				}


				if (datasets.length === 1 && datasets[0].data.length === 1) {
					// vm.chartOptions.messageToDisplay = vm.chartOptions.messages.notEnoughData;
					// vm.chartOptions.showInfoMessage = true;
				}

				chartistConfig = wfChartistService.getChartistConfigObject(vm.chartOptions);
				vm.showConversionOptions = vm.showConversionOptions || chartistConfig.showConversionOptions;

				//If there is only one series of data, hide pie chart stacked bar and donut chart
				if (vm.chartOptions.originalData && vm.chartOptions.originalData.series && vm.chartOptions.originalData.series.length === 1) {
					vm.charts.pie.showButton = false;
					vm.charts.stackedBar.showButton = false;
					vm.charts.donut.showButton = false;
				}
				else {
					_.each(vm.charts, function (chart) { chart.showButton = true; });
				}

				$timeout(function () {
					vm.currentChart.chartElement = $element.find("div.chartist." + vm.currentChart.type);
					$element.height($element.height());
					vm.currentChart.chartElement.empty(); //Remove all elements from div.chartist to create a new one (plugin elements like tooltip and legend are not removed when the chart gets recreated)
					vm.currentChart.chartElement.attr({ style: "background-color: " + vm.chartOptions.chartBackgroundColor + ";" });

					if (vm.currentChart.chartWidth)
						chartistConfig.options.width = vm.currentChart.chartWidth;

					if (vm.chartOptions.disablePointerEvents)
						vm.currentChart.chartElement.addClass("disable-pointer-events");
					else if (vm.currentChart.chartElement.hasClass("disable-pointer-events"))
						vm.currentChart.chartElement.removeClass("disable-pointer-events");

					// Hide the chart element if the single point view is visible
					if (vm.useSinglePointView || (vm.currentAggregateMode !== "separate" && vm.useSinglePointView)) {
						vm.currentChart.chartElement.hide();
					}

					if (vm.currentChart.type === "line") {
						vm.currentChart.chartInstance = new Chartist.Line(vm.currentChart.chartElement[0], chartistConfig.data, chartistConfig.options, chartistConfig.responsiveOptions);
						vm.currentChart.dashedLine ? vm.currentChart.chartInstance.options.classNames.line += " dashed-line" : "";

					}
					else if (vm.currentChart.type === "bar") {
						if (vm.chartOptions.actualType === "stackedBar") {
							handleAverageColor()
							chartistConfig = wfChartistService.getChartistConfigObject(vm.chartOptions);
						}
						tempBarChart = new Chartist.Bar(vm.currentChart.chartElement[0], chartistConfig.data, chartistConfig.options, chartistConfig.responsiveOptions);
						vm.currentChart.chartInstance = wfChartistService.getChartWithDynamicBars(tempBarChart, vm.chartOptions);
						tempBarChart = undefined;
					}
					else if (vm.currentChart.type === "pie") {
						vm.currentChart.chartInstance = new Chartist.Pie(vm.currentChart.chartElement[0], chartistConfig.data, chartistConfig.options, chartistConfig.responsiveOptions);
						vm.aggregatePeriodFrequencies = false;
					}


					vm.currentChart.loaded = true;

					deferred.resolve();
					setTimeout(function () {

						// Don't animate the graph if the single point view is visible
						if (vm.useSinglePointView || (vm.currentAggregateMode !== "separate" && vm.useSinglePointView)) {
							return;
						}

						if (vm.currentChart) {
							vm.currentType = vm.currentChart.actualType || vm.currentChart.type;
							vm.currentChart.chartElement.animate({ opacity: 1 }, 100);
						}
						$element.height("auto");
					}, 100);
				}, 100);

				vm.currentChart.created = true;
				checkChartSelections(vm.currentChart);

				return deferred.promise;
			}

			function showAverageToggle() {
				return vm.currentChart && vm.currentChart.loaded
					&& vm.currentAggregateMode !== 'separate'
					&& !vm.aggregatePeriodFrequencies
					&& !vm.useSinglePointView
					&& Array.isArray(vm.chartOptions.valueProperties)
					&& (vm.chartOptions.valueProperties.includes("average") || vm.chartOptions.valueProperties.includes("sum"));
			}

			function hideAverageValueLabelCall() {
				vm.showAverage = !vm.showAverage;
				hideAverageValue()
			}

			function hideAverageValue() {
				handleAverageColor();
				vm.chartOptions.showAverage = vm.showAverage;
				vm.currentChart.recreateChart()
			}

			function handleAverageColor() {
				if (!vm.showAverage && vm.chartOptions.colors.includes("#ccc")) {
					const index = vm.chartOptions.colors.indexOf("#ccc");
					vm.chartOptions.colors.splice(index, 1);
				} else if (vm.chartOptions.valueProperties && vm.chartOptions.valueProperties.includes("average") && vm.showAverage && !vm.chartOptions.colors.includes("#ccc")) {
					vm.chartOptions.colors.unshift("#ccc");
				}

				if (vm.chartOptions.colors.includes("#3182bd")) {
					const index = vm.chartOptions.colors.indexOf("#3182bd");
					vm.chartOptions.colors.splice(index, 1);
				}
			}

			$element.on('hide.bs.dropdown', "div.filter-years", function () {
				if (yearChanges) {
					vm.excludeYears = vm.filteringDropdownYears.filter(y => y.selected === false).map(y => parseInt(y.name));
					loadDataAndInitialize().then(function () {
						checkChartSelections(vm.currentChart);
					});
					yearChanges = false
					$timeout();
				}
			})

			function onSelectYear(filteringDropdownYear) {
				filteringDropdownYear.selected = !filteringDropdownYear.selected

				// All years can't be unselected
				const allYearsAreUnselected = vm.filteringDropdownYears.every(year => year.selected === false)
				if (allYearsAreUnselected) {
					filteringDropdownYear.selected = !filteringDropdownYear.selected
				}
				else {
					yearChanges = true
				}

				const someYearsAreUnselected = vm.filteringDropdownYears.some(year => year.selected === false)
				vm.yearOptionText = someYearsAreUnselected ? $translate.instant("modules.visualization.yearFilter.some")
					: $translate.instant("modules.visualization.yearFilter.all")

				$timeout();
			}

			function resetYearFilter() {
				vm.excludeYears = undefined;
				vm.filteringDropdownYears = vm.filteringDropdownYears ? vm.filteringDropdownYears.map(year => ({ ...year, selected: true })) : undefined
				vm.yearOptionText = $translate.instant("modules.visualization.yearFilter.all")
			}

			function selectChartType(chart) {
				vm.useSinglePointView = false;

				chart.aggregatePeriodFrequencies = vm.aggregatePeriodFrequencies;
				if (!vm.allPeriodsLoaded || (vm.excludeYears && vm.excludeYears.length > 0 && chart.type === 'pie')) {
					resetYearFilter()
					vm.allPeriodsLoaded = true;
					vm.currentChart = chart;
					vm.negotiator.currentChartType = chart.actualType || chart.type
					vm.negotiator.dataNeededAfterChartTypeChange = true
					vm.negotiator.periodSpan = undefined;
					vm.negotiator.excludeYears = undefined;
					vm.negotiator.loadItemsFromServer();
				}
				else {
					return recreateChart.apply(chart);
				}
			}

			function recreateChart() {
				var deferred = $q.defer();
				var chartToRecreate = this;
				chartToRecreate.loaded = false;
				chartToRecreate.showAverage = vm.showAverage;
				vm.currentChart = undefined;

				createChartist(chartToRecreate).then(function () {
					handleAverageColor();
					deferred.resolve();
				});

				return deferred.promise;
			}

			function clearData() {
				var chart = this;
				datasets = [];
				chart.recreateChart();
			}

			function generateRandomData() {
				var chart = this;
				datasets = wfChartistService.mockRandomData(chart);
				chart.recreateChart();
			}

			function setAggregateMode(mode, splitOption) {
				if (vm.currentAggregateMode === mode && vm.currentSplitOption === splitOption) return;

				if (vm.periodSpan && (splitOption === "yearly" || vm.currentSplitOption === "yearly") && vm.currentSplitOption !== splitOption) {
					vm.periodSpan = undefined;
				}

				vm.splitUpOrganizationStatistics = mode === "separate" && splitOption === "organizations";
				vm.splitUpRelativeMeasureSourcesStatistics = mode === "separate" && splitOption === "relativeMeasureSourceObjects";
				vm.aggregateYearly = mode !== "separate" && splitOption === "yearly";
				vm.currentAggregateMode = mode;
				vm.currentSplitOption = splitOption;
				vm.aggregatePeriodFrequencies = mode === "combine" && splitOption === "frequency";
				mode === 'separate' ? vm.enableSingleValueOption = false : vm.enableSingleValueOption = true;

				if (mode === "separate") {
					vm.periodSpan = undefined;
				}

				if ((vm.aggregateYearly || vm.aggregatePeriodFrequencies) && !vm.convertMeasureAnswerUnits) {
					vm.convertMeasureAnswerUnits = true;
					if (vm.wfListInterfaceStatistical) {
						vm.wfListInterfaceStatistical.setMeasureUnitConversionMode(vm.convertMeasureAnswerUnits);
					}
				}

				if (vm.aggregatePeriodFrequencies) {
					vm.periodSpan = undefined;
					if (vm.currentChart.type === "pie" || vm.currentChart.type === "donut") {
						selectChartType(vm.charts["line"]).then(() => loadData())
						return
					}
				}

				loadData()

				function loadData() {
					loadDataAndInitialize({ useNewAggregateMode: true }).then(function () {
						checkChartSelections(vm.currentChart);
					});
				}
			}

			function setUnitConversionMode(mode) {
				vm.convertMeasureAnswerUnits = mode === "converted";
				if (vm.wfListInterfaceStatistical) {
					vm.wfListInterfaceStatistical.setMeasureUnitConversionMode(vm.convertMeasureAnswerUnits);
				}

				loadDataAndInitialize().then(function () {
					checkChartSelections(vm.currentChart);
				});
			}

			function checkChartSelections(chart) {
				let setToSaved = false;
				//If currently saved chart is selected, set the 'save icon' to show the appropriate color (set as already saved)
				if (chart) {
					if (vm.useSinglePointView) {
						if (selectedChartSettings.useSingleValue
							&& (!vm.selectedPeriod
								|| (selectedChartSettings.period === (vm.selectedPeriod && vm.selectedPeriod.span)))) {
							setToSaved = true;
						}
					}
					else {
						if (chart.actualType) {
							if (selectedChartSettings.chartType === chart.actualType
								&& selectedChartSettings.aggregateMode === chart.aggregateMode
								&& selectedChartSettings.showAverage === chart.showAverage
								&& !!selectedChartSettings.convertMeasureAnswerUnits === vm.convertMeasureAnswerUnits
							) {
								setToSaved = true;
							}
						}
						else {
							if (selectedChartSettings.chartType === chart.type
								&& selectedChartSettings.aggregateMode === chart.aggregateMode
								&& selectedChartSettings.showAverage === chart.showAverage
								&& !!selectedChartSettings.convertMeasureAnswerUnits === vm.convertMeasureAnswerUnits
							) {
								setToSaved = true;
							}
						}
					}
				}

				if (setToSaved) {
					setChartSelectionAsSaved();
				}
				else {
					setChartSelectionsAsUnsaved();
				}
			}

			function saveCurrentChartSelections() {
				if ((selectedChartSettings.useSingleValue === vm.useSinglePointView && selectedChartSettings.period === (vm.selectedPeriod && vm.selectedPeriod.span))
					&& (selectedChartSettings.chartType === vm.currentChart.actualType || selectedChartSettings.chartType === vm.currentType)
					&& selectedChartSettings.aggregateMode === vm.currentAggregateMode
					&& selectedChartSettings.splitOption === vm.currentSplitOption
					&& selectedChartSettings.showAverage === vm.showAverage
					&& selectedChartSettings.excludeYears === vm.excludeYears
					&& selectedChartSettings.aggregatePeriodFrequencies === vm.aggregatePeriodFrequencies
					&& !!selectedChartSettings.convertMeasureAnswerUnits === vm.convertMeasureAnswerUnits
				)
					return;

				if (vm.useSinglePointView && vm.selectedPeriod) {
					selectedChartSettings = { useSingleValue: true, period: vm.selectedPeriod.span };
					if (vm.aggregateYearly || vm.aggregatePeriodFrequencies) {
						selectedChartSettings.splitOption = vm.currentSplitOption
					}
				}
				else {
					selectedChartSettings = {
						chartType: vm.currentChart.actualType || vm.currentType,
						aggregateMode: vm.currentAggregateMode,
						splitOption: vm.currentSplitOption,
						showAverage: vm.showAverage
					};

				}

				if (vm.convertMeasureAnswerUnits)
					selectedChartSettings.convertMeasureAnswerUnits = true;
				else
					delete selectedChartSettings.convertMeasureAnswerUnits;

				selectedChartSettings.showAverage = vm.showAverage;
				selectedChartSettings.excludeYears = vm.excludeYears;
				selectedChartSettings.aggregatePeriodFrequencies = vm.aggregatePeriodFrequencies;

				vm.savingChartSettings = true;

				dataOps.saveSettings({
					item: itemForSavedSettings,
					settings: {
						chartSettings: JSON.stringify(selectedChartSettings),
					}
				}).then(function () {
					setChartSelectionAsSaved(true);
				});
			}

			function setChartSelectionAsSaved(saveButtonClicked) {
				if (!vm.showSaveButton)
					return;

				vm.unsavedChartSettings = false;

				if (saveButtonClicked) {
					vm.savingChartSettings = false;
					vm.savingChartSettingsJustCompleted = true;

					$timeout(function () {
						vm.savingChartSettingsJustCompleted = false;
					}, 1000)
				}
				else
					vm.saveButtonTooltipText = $translate.instant("modules.visualization.chartist.saveButtonTooltipMessages.selectedChartType");

				$timeout();
			}

			function setChartSelectionsAsUnsaved() {
				if (!vm.showSaveButton)
					return;

				vm.saveButtonTooltipText = $translate.instant("modules.visualization.chartist.saveButtonTooltipMessages.clickToSaveChartType");
				vm.unsavedChartSettings = true;
			}

			function watchChartistWidth() {
				var
					frequency = 100,
					chartistWidthWatcher,
					debounceEvent
					;

				debounceEvent = _.debounce(function () {
					vm.currentChart.chartElement.stop(true, true);
					vm.currentChart.chartElement.animate({ opacity: 0 }, 100, function () {
						vm.currentChart.recreateChart();
					});
				}, frequency * 2, { leading: false, trailing: true });

				chartistWidthWatcher = $scope.$watch(function () {
					return $element.width();
				}, function () {
					if (vm.currentChart && vm.currentChart.chartInstance) {
						// vm.currentChart.loaded = false;
						debounceEvent();
					}
				});

				$scope.$on("$destroy", function () {
					chartistWidthWatcher();
				});
			}

			function showSinglePointView(period, wasJustLoaded) {
				if (vm.excludeYears && vm.excludeYears.length > 0) {
					vm.aggregatePeriodFrequencies = false;
					resetYearFilter();
					loadDataAndInitialize().then(function () {
						createSinglePointView(period, wasJustLoaded);
					});
				}
				else {
					createSinglePointView(period, wasJustLoaded);
				}
			}

			function createSinglePointView(period, wasJustLoaded) { // period = { label: "2019", span: "20190101|20191231" } or "20190101|20191231"
				if (vm.currentChart && vm.currentChart.loaded) {
					vm.currentChart.loaded = false;
				}

				var datasets = _.get(vm.negotiator, "itemMetadata.statistics.datasets");
				const datasetPeriodSpans = _.get(vm.negotiator, "itemMetadata.statistics.datasetPeriodSpans");
				vm.periodsToSelect = _.get(vm.negotiator, "itemMetadata.statistics.datasetLabels").map((label, index) => ({ label, span: datasetPeriodSpans && datasetPeriodSpans[index] }));
				var datasetsHasSingleValue = datasets.every(dataset => dataset.data.length === 1) && datasetPeriodSpans.length === 1
				datasets = _.filter(datasets, { id: "period" });


				if (datasetsHasSingleValue) {
					if (datasetsHasSingleValue) {
						//vm.selectedPeriod = vm.periodsToSelect = undefined
					}
					else {
						vm.useSinglePointData = datasets[0].data[0];
						vm.isInconsistentData = datasets[0].inconsistent;
						vm.selectedPeriod = vm.periodsToSelect[0];
					}
				}

				if (period) {
					if (typeof period === "string") {
						if (period.indexOf("|") === -1) {
							period = vm.periodsToSelect.find(x => x.label === period);
						}
						else {
							period = vm.periodsToSelect.find(x => x.span === period);
						}
					}
					vm.selectedPeriod = period;
				}
				else {
					//check if a period is already selected by the user and display it on type switch
					if (selectedChartSettings.period) {
						vm.selectedPeriod = vm.periodsToSelect.find(x => x.span === selectedChartSettings.period);
					}
					else {
						//get the latest datasetLabel and display it
						vm.selectedPeriod = vm.periodsToSelect[vm.periodsToSelect.length - 1];
					}
				}

				//vm.datasetPeriodSpan = datasetPeriodSpans[vm.labelsToSelect.indexOf(vm.selectedPeriod)]

				if (vm.selectedPeriod) {
					vm.useSinglePointData = _.find(datasets[0].data, function (dataset) {
						return dataset.label === vm.selectedPeriod.label;
					})
					vm.isInconsistentData = datasets[0].inconsistent;
				}

				if (!wasJustLoaded && !vm.allPeriodsLoaded) {
					if (vm.wfListInterfaceStatistical) {
						vm.wfListInterfaceStatistical.updateNegotiatorConfigAndCompile({
							periodSpan: vm.selectedPeriod.span
						});
					}
					else {
						vm.negotiator.periodSpan = vm.selectedPeriod.span;
						vm.negotiator.loadItemsFromServer();
					}
				}

				$timeout(function () {
					if (vm.currentChart && !vm.currentChart.loaded) {
						vm.currentChart.loaded = true;
						vm.useSinglePointView = true;
						if (!wasJustLoaded) {
							checkChartSelections(vm.currentChart)
						}
					}
				});
			}

			function capture($event) {
				const deferred = $q.defer();

				vm.dashboardPanelBodyElement = $($event.target).closest("div.panel-body")//.find(".currentChart");
				vm.isDashboardPanel = !!vm.dashboardPanelBodyElement.length

				if (vm.isDashboardPanel) {
					vm.dashboardPanelElement = vm.dashboardPanelBodyElement.parent()
					vm.dashboardPanelElement.addClass("capturing-visualization")
					vm.dashboardPanelElement.attr("data-capturing-title", $translate.instant("modules.visualization.download.downloadingMessage"))
					vm.wfChartComponentElement = vm.dashboardPanelBodyElement.find("div.wfChartComponent")

					setTimeout(() => {
						if (!vm.useSinglePointView) {
							vm.currentChart.showLegend = true
							vm.currentChart.recreateChart().then(() => {
								setTimeout(() => {
									vm.dashboardPanelElement.height(vm.dashboardPanelElement.height())

									vm.dashboardPanelBodyElement.height(vm.wfChartComponentElement.height() + 27)

									doCapture()
								}, 400)
							})
						}
						else {
							doCapture()
						}
					}, 100)
				}
				else {
					doCapture()
				}

				function doCapture() {
					vm.dashboardPanelBodyElement.find("span").removeAttr("xmlns")
					vm.elementsToHideInCapture = vm.dashboardPanelBodyElement.find("div.top-bar, div.container-open, div.chartist-series-selection");

					vm.elementsToHideInCapture.css("visibility", "hidden")

					const captureElement = vm.dashboardPanelBodyElement.get(0);
					html2canvas(captureElement, { foreignObjectRendering: false, scale: 2.5 })
						.then(canvas => {
							canvas.style.display = 'none'
							document.body.appendChild(canvas)
							deferred.resolve(canvas)
						})
				}


				return deferred.promise
			}

			function getDataOptionSelectedItemText() {
				if (vm.splitUpOrganizationStatistics) {
					return 'modules.visualization.aggregateOptions.separateOrganizations';
				}

				if (vm.splitUpRelativeMeasureSourcesStatistics) {
					return 'modules.visualization.aggregateOptions.separateRelativeMeasureSources';
				}
				if (vm.aggregateYearly) {
					return 'modules.visualization.aggregateOptions.aggregateYearly';
				}

				if (vm.aggregatePeriodFrequencies) {
					return 'modules.visualization.aggregateOptions.combineFrequency';
				}

				return 'modules.visualization.aggregateOptions.combine';
			}

			function showcombineFrequencyOption() {
				return true;
				const datasetPeriodSpans = _.get(vm.negotiator, "itemMetadata.statistics.datasetPeriodSpans");
				let datasetPeriodSpansToSelect = datasetPeriodSpans.map(d => d.slice(0, 4));
				let isYearArray = datasetPeriodSpansToSelect.filter((item, index) => datasetPeriodSpansToSelect.indexOf(item) !== index);
				return isYearArray.some((element) => element);
			}

			function captureChart($event) {
				capture($event).then(canvas => {
					const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
					const a = document.createElement('a')
					a.setAttribute('download', `WF_Chart_${vm.itemWfid}.png`)
					a.setAttribute('href', image)
					a.click()
					a.remove()
					canvas.remove()

					vm.elementsToHideInCapture.css("visibility", "")

					if (vm.isDashboardPanel) {
						if (!vm.useSinglePointView) {
							vm.currentChart.showLegend = false
						}
						vm.currentChart.recreateChart().then(() => {
							setTimeout(() => {
								vm.dashboardPanelElement.css("height", "")
								vm.dashboardPanelBodyElement.css("height", "")
								vm.dashboardPanelElement.removeClass("capturing-visualization")
								vm.dashboardPanelElement.removeAttr("data-capturing-title")
							}, 250)
						})
					}
				})
			}
		}

		vm.$onDestroy = function () {
			scopeDestroyed = true;
			$element.off('hide.bs.dropdown', "div.filter-years")
		};
	}
})();
