// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { getNodeFromNodeId } from '../../selectors/dataSelector'
import { FormattedMessage } from 'react-intl'

import HighlightedText from '@worldfavor/components/Text/HighlightedText'
import DropdownMenu from '@worldfavor/components/DropdownMenu'
import MappingStatus from '../SupplyChain/MappingStatus'
import MenuItem from '@material-ui/core/MenuItem'
import ActivationStatusIndicator from '../SupplyChain/ActivationStatusIndicator'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  cellLink: {
    display: 'block',
    '&, &:hover': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
  },
  title: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    flex: 1,
  },
  actions: {
    float: 'right',
  },
  linkMenuItem: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    outline: 'none',
    '&:focus': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      outline: 'none',
    },
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
}))

type Props = {
  row: Array<Object>,
  column: string | { id: string, enableFilter?: boolean },
  rowIndex?: number,
  columnIndex?: number,
  onToggleActivation?: (node: Object, organization: Object) => void,
  to?: string,
  search?: string,
  cellComponents?: { [string]: React.ComponentType<any> },
}

const ProductTableRowCell = (props: Props) => {
  const {
    row,
    column,
    onToggleActivation,
    to,
    search,
    cellComponents,
  } = props
  const columnId = typeof column === 'string' ? column : column.id
  const classes = useStyles(props)
  const { coMapper, creatorOrganizationWfid } = row.node
  const organization = useSelector(state => creatorOrganizationWfid && getNodeFromNodeId(state, creatorOrganizationWfid))

  function onToggleActivationClick() {
    onToggleActivation(row.node, organization)
  }

  const cells = {
    product: <span className={classes.title}><HighlightedText text={row.node.name} match={search} /></span>,
    organization: organization && <HighlightedText text={organization.name} />,
    coMapper: coMapper && <HighlightedText text={coMapper.name} />,
    gtin: <HighlightedText text={row.node.gtin} match={search} />,
    transparencyLevel: <MappingStatus level={row.node.transparencyLevel} />,
    activation: <ActivationStatusIndicator status={Boolean(row.node.isActive)} />,
    actions: (
      <div className={classes.actions}>
        <DropdownMenu
          PaperProps={{
            style: {
              width: 200,
            },
          }}
        >
          <MenuItem
            classes={{ root: classes.linkMenuItem }}
            component={Link}
            to={to}
          >
            <FormattedMessage id={'general.open'} />
          </MenuItem>
          <MenuItem onClick={onToggleActivationClick}>{<FormattedMessage id={row.node.isActive ? 'general.deactivate' : 'general.activate'} />}</MenuItem>
        </DropdownMenu>
      </div>
    ),
    ...cellComponents,
  }

  if (columnId === 'actions') {
    return cells[columnId]
  }

  return (
    <Link
      to={to}
      className={classes.cellLink}
    >
      {cells[columnId] || ''}
    </Link>
  )
}

ProductTableRowCell.defaultProps = {
  onToggleActivation: () => {},
}

export default ProductTableRowCell
