import Colors from './colors'
import teal from '@material-ui/core/colors/teal'
import { FontSizes, FontFamily, FontWeights } from './typography'
import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
  palette: {
    // Set primary color to WF black
    primary: {
      main: Colors.black,
      // light, dark and contrastText properties will be generated based on main
    },
    secondary: {
      main: teal['700'],
    },
  },

  typography: {
    fontSizes: {
      xxSmall: FontSizes.xxSmall, // 2
      xSmall: FontSizes.xSmall, // 4

      smallest: FontSizes.smallest, // 6
      smaller: FontSizes.smaller, // 8
      small: FontSizes.small, // 10

      default: FontSizes.default, // 12
      medium: FontSizes.medium, // 14

      large: FontSizes.large, // 16
      larger: FontSizes.larger, // 18
      largest: FontSizes.largest, // 24

      xLarge: FontSizes.xLarge, // 28
      xxLarge: FontSizes.xxLarge, // 34
      xxxLarge: FontSizes.xxxLarge, // 42
    },

    fontWeights: {
      thin: FontWeights.thin,
      extraLight: FontWeights.extraLight,
      light: FontWeights.light,
      normal: FontWeights.normal,
      medium: FontWeights.medium,
      semiBold: FontWeights.semiBold,
      bold: FontWeights.bold,
      extraBold: FontWeights.extraBold,
      black: FontWeights.black,
      extraBlack: FontWeights.extraBlack,
    },
    fontFamily: FontFamily,
  },
})
