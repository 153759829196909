// @flow
import * as React from 'react'
import AnswerValue from './AnswerValue'

import '@worldfavor/less/components/question-container.less'

type Props = {
  values: Array<{
    type: string,
    value?: number,
    title?: string,
    subTitle?: string,
  }>,
  forceShowPercentage?: boolean,
}

const ItemStatistics = (props: Props) => {
  const { values, forceShowPercentage } = props
  return (
    <div styleName="summary-statistics">
      {
        values.map(({ value, title, subTitle, type }, index) => (
          <AnswerValue
            key={`item-stats-${index}`}
            {...{ value, title, subTitle, type }}
            forceShowPercentage={forceShowPercentage}
          />
        ))
      }
    </div>
  )
}

ItemStatistics.defaultProps = {
  values: [],
}

export default ItemStatistics
