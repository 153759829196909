(function() {
	'use strict';

	angular
		.module('wf.common')
		.controller('DevTestingController', DevTestingController);

	DevTestingController.$inject = [ '$scope', 'dataOperationsService', 'modalService' ];
	function DevTestingController($scope, dataOps, modal) {
		var vm = this;
		
		activate();

		function activate() {
			
		}
	}
})();
