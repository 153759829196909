const enterpriseLogins = [
  {
    urlPart: "c3dlZGJhbms=", // swedbank
    connection: "U3dlZGJhbmstU0FNTA==", // Swedbank-SAML
    domains: [ "c3dlZGJhbmsuc2U=" ] // swedbank.se
  },
  {
    urlPart: "bWFydGluc2VydmVyYQ==", // martinservera
    connection: "bWFydGluc2VydmVyYS13YWFk", // martinservera-waad
    domains: [
      "bWFydGluc2VydmVyYS5zZQ==", // martinservera.se
      "bWFydGluc2VydmVyYS5jb20=" // martinservera.com
    ]
  },
  {
    urlPart: "bmliZQ==", // nibe
    connection: "bmliZS13YWFk", // nibe-waad
    domains: [ "bmliZS5zZQ==" ] // nibe.se
  },
  {
    urlPart: "c3dlZGlzaG1hdGNo", // swedishmatch
    connection: "c3dlZGlzaG1hdGNoLXdhYWQ=", // swedishmatch-waad
    domains: [
      "c3dlZGlzaG1hdGNoLmNvbQ==", // swedishmatch.com
      "c3dlZGlzaG1hdGNoLmNvbS5icg==", // swedishmatch.com.br
      "c21kbG9naXN0aWNzLnNl", // smdlogistics.se
      "djJ0b2JhY2NvLmNvbQ==", // v2tobacco.com
    ],
    authButtonTheming: {
      displayName: `Log in at ${atob("c3dlZGlzaG1hdGNo")}.com`
    },
  },
  {
    urlPart: "cHdj", // pwc
    connection: "UHdDLVNBTUw=", // PwC-SAML
    domains: [ "cHdjLmNvbQ==" ], // pwc.com
    authButtonTheming: {
      displayName: `Log in at ${atob("cHdjLmNvbQ==")}`
    },
  },
  {
    urlPart: "aG93ZGVuZ3Jw", // howdengrp
    connection: "SG93ZGVuLUFBRA==", // Howden-AAD
    domains: [
      "ZHVhbGFzaWFwYWNpZmljLmNvbQ==", // dualasiapacific.com
      "ZHVhbGRldXRzY2hsYW5kLmNvbQ==", // dualdeutschland.com
      "ZHVhbGdyb3VwLmNvbQ==", // dualgroup.com
      "ZHVhbGliZXJpY2EuY29t", // dualiberica.com
      "ZHVhbGl0YWxpYS5jb20=", // dualitalia.com
      "ZHVhbGNvbW1lcmNpYWwuY29t", // dualcommercial.com
      "ZHVhbGdyb3VwLmll", // dualgroup.ie
      "aG93ZGVuZ3JvdXAuZGU=", // howdengroup.de
      "aGVuZHJpY2tzLW1ha2xlci5kZQ==", // hendricks-makler.de
      "aG93ZGVuLWJyb2tlcnMuY29t", // howden-brokers.com
      "aG93ZGVuZ3JvdXAuY29t", // howdengroup.com
      "aG93ZGVuZ3JvdXAubm8=", // howdengroup.no
      "aG93ZGVuLnNl", // howden.se
      "ZXVyb2Fzc2VrdXJhbnouZGU=", // euroassekuranz.de
      "aG93ZGVuLWNhbmluZW5iZXJnLmRl", // howden-caninenberg.de
      "aHlwZXJpb25jbG91ZC5vbm1pY3Jvc29mdC5jb20=", // hyperioncloud.onmicrosoft.com
      "aG93ZGVuZG9ub3JpYS5wbA==", // howdendonoria.pl
      "SG93ZGVuaWJlcmlhLmNvbQ==", // Howdeniberia.com
      "cGguaG93ZGVuZ3JvdXAuY29t", // ph.howdengroup.com
      "c2cuaG93ZGVuZ3JvdXAuY29t", // sg.howdengroup.com
      "aG93ZGVuaW5kaWEuY29t", // howdenindia.com
      "aWQuaG93ZGVuZ3JvdXAuY29t", // id.howdengroup.com
      "aXBnaG93ZGVuLmNvbQ==", // ipghowden.com
      "bXkuaG93ZGVuZ3JvdXAuY29t", // my.howdengroup.com
      "aG93ZGVubWF4aS5jb20=", // howdenmaxi.com
      "aG93ZGVub21hbi5jb20=", // howdenoman.com
      "aG93ZGVuLmNvLmls", // howden.co.il
      "aG93ZGVucHVyaS5jby50eg==", // howdenpuri.co.tz
      "aG93ZGVuLmNvbS50cg==", // howden.com.tr
      "aG93ZGVudWFlLmNvbQ==", // howdenuae.com
      "Z2liLXVhZS5hZQ==", // gib-uae.ae
      "aG93ZGVuaGFybW9uaWEuY29tLmJy", // howdenharmonia.com.br
      "aG93ZGVucGF0YWdvbmlhLmNs", // howdenpatagonia.cl
      "b3JkYXNob3dkZW4ubXg=", // ordashowden.mx
      "YXBsYW4uY28udWs=" // aplan.co.uk
    ],
    authButtonTheming: {
      displayName: `Log in at ${atob("SG93ZGVuIEdyb3Vw")}` // Howden Group
    },
  },
  {
    urlPart: "d2Y=", // wf
    connection: "d29ybGRmYXZvci13YWFk", // worldfavor-waad
    //domains: [ "d29ybGRmYXZvci5jb20=" ], // worldfavor.com
    authButtonTheming: {
      displayName: `Log in at ${atob("d29ybGRmYXZvci5jb20=")}`
    },
  },
  {
    urlPart: "aG9pc3RmaW5hbmNl", // hoistfinance
    connection: "aG9pc3Qtd2FhZA==", // hoist-waad
    domains: [ "aG9pc3RmaW5hbmNlLmNvbQ==" ], // hoistfinance.com
    authButtonTheming: {
      displayName: `Log in at ${atob("aG9pc3RmaW5hbmNlLmNvbQ==")}`
    },
  },
  {
    urlPart: "c2NoaWJzdGVk", // schibsted
    connection: "U2NoaWJzdGVkLVNBTUw=", // Schibsted-SAML
    domains: [
      "YWZ0ZW5wb3N0ZW4ubm8=", // aftenposten.no
      "YnQubm8=", // bt.no
      "ZGkubm8=", // di.no
      "Zmlubi5ubw==", // finn.no
      "c2NoaWJzdGVkLmNvbQ==", // schibsted.com
      "c2NoaWJzdGVkLm5v", // schibsted.no
      "c2NoaWJzdGVkLnBs", // schibsted.pl
      "dmcubm8=", // vg.no
    ],
    authButtonTheming: {
      displayName: `Log in at ${atob("U2NoaWJzdGVk")}` // Schibsted
    },
  },
  {
    urlPart: "aXJlc3M=", // iress
    connection: "SXJlc3MtU0FNTA==", // Iress-SAML
    domains: [
      "aXJlc3MuY29t", // iress.com
    ],
    authButtonTheming: {
      displayName: `Log in at ${atob("aXJlc3MuY29t")}` // iress.com
    },
  },
  {
    urlPart: "dGlwZXVyb3Bl", // tipeurope
    connection: "VElQRXVyb3BlLUFBRA==", // TIPEurope-AAD
    domains: [
      "dGlwZXVyb3BlLmNvbQ==", // tipeurope.com
      "dGlwLWdyb3VwLmNvbQ==", // tip-group.com
    ],
    authButtonTheming: {
      displayName: `Log in at ${atob("VElQIEV1cm9wZQ==")}` // TIP Europe
    },
  },
  {
    urlPart: "Z3VubmVibw==", // gunnebo
    connection: "R3VubmViby1BQUQ=", // Gunnebo-AAD
    domains: [
      "Z3VubmViby5jb20=", // gunnebo.com
    ],
    authButtonTheming: {
      displayName: `Log in at ${atob("R3VubmVibw==")}` // Gunnebo
    },
  },
  {
    urlPart: "dGhlc3R1ZGVudGhvdGVs", // thestudenthotel
    connection: "VGhlU3R1ZGVudEhvdGVsLUFBRA==", // TheStudentHotel-AAD
    domains: [
      "dGhlc3R1ZGVudGhvdGVsLmNvbQ==", // thestudenthotel.com
    ],
    authButtonTheming: {
      displayName: `Log in at ${atob("VGhlIFN0dWRlbnQgSG90ZWw=")}` // The Student Hotel
    },
  },
  {
    urlPart: "c3lzdGVtYWly", // systemair
    connection: "U3lzdGVtYWlyLUFBRA==", // Systemair-AAD
    domains: [
      "c3lzdGVtYWlyLmRl", // systemair.de
      "c3lzdGVtYWlyLm5ldA==", // systemair.net
      "c3lzdGVtYWlyLnNl", // systemair.se
      "c3lzdGVtYWlyLmNvbQ==", // systemair.com
      "c3lzdGVtYWlyLml0", // systemair.it
      "c3lzdGVtYWlyLnBs", // systemair.pl
      "c3lzdGVtYWlyLm5v", // systemair.no
      "c3lzdGVtYWlyLnNp", // systemair.si
      "c3lzdGVtYWlyLnJ1", // systemair.ru
      "c3lzdGVtYWlyLnNr", // systemair.sk
      "c3lzdGVtYWlyLmZy", // systemair.fr
      "c3lzdGVtYWlyLm5s", // systemair.nl
      "c3lzdGVtYWlyLmRr", // systemair.dk
      "c3lzdGVtYWlyLnJz", // systemair.rs
      "c3lzdGVtYWlyLm15", // systemair.my
      "bWVuZXJnYS5jaA==", // menerga.ch
      "c3lzdGVtYWlyLmJl", // systemair.be
      "cGFjaWZpY3ZlbnRpbGF0aW9uLmNvbQ==", // pacificventilation.com
      "c3lzdGVtYWlyLmJ5", // systemair.by
      "c3lzdGVtYWlyLmFl", // systemair.ae
      "c3lzdGVtYWlyLmNvLnVr", // systemair.co.uk
      "c3lzdGVtYWlyLmx2", // systemair.lv
      "c3lzdGVtYWlyLmNvLnph", // systemair.co.za
      "bWVuZXJnYS5sdA==", // menerga.lt
      "c3lzdGVtYWlyLnFh", // systemair.qa
      "c3lzdGVtYWlyLmNvbS5zZw==", // systemair.com.sg
      "c3lzdGVtYWlyLnNh", // systemair.sa
      "c3lzdGVtYWlyLmNvbS50cg==", // systemair.com.tr
      "c3lzdGVtYWlyLmlu", // systemair.in
      "c3lzdGVtYWlyLmN6", // systemair.cz
      "c3lzdGVtYWlyLmx0", // systemair.lt
      "ZnJpY28uZnI=", // frico.fr
      "c3lzdGVtYWlyLm1h", // systemair.ma
      "bWVuZXJnYS5jb20=", // menerga.com
      "c3lzdGVtYWlyaGsuY29t", // systemairhk.com
      "c3lzdGVtYWlyLnB0", // systemair.pt
      "bGdiLWdtYmguZGU=", // lgb-gmbh.de
      "c3lzdGVtYWlyLnJv", // systemair.ro
      "bWVuZXJnYS5iZQ==", // menerga.be
      "cG9seS1yZWsuaHI=", // poly-rek.hr
      "c3lzdGVtYWlyLmdy", // systemair.gr
      "c2VydmljZWJvbGFnZXQuc2U=", // servicebolaget.se
      "c3lzdGVtYWlyLmh1", // systemair.hu
      "c3lzdGVtYWlyLmF6", // systemair.az
      "c3lzdGVtYWlyLmll", // systemair.ie
      "dGVrYWRvb3IuZGU=", // tekadoor.de
      "c3lzdGVtYWlyLmVl", // systemair.ee
      "c3lzdGVtYWlyLmVz", // systemair.es
      "c3lzdGVtYWlyLmZp", // systemair.fi
      "c3lzdGVtYWlyLm14", // systemair.mx
    ],
    authButtonTheming: {
      displayName: `Log in at ${atob("U3lzdGVtYWly")}` // Systemair
    },
  },
  {
    urlPart: "dG9iaWk=", // tobii
    connection: "VG9iaWktQUFE", // Tobii-AAD
    domains: [
      "dG9iaWkuY29t", // tobii.com
    ],
    authButtonTheming: {
      displayName: `Log in at ${atob("VG9iaWk=")}` // Tobii
    },
  },
  {
    urlPart: "bGl2aW5nYnJpZGdl", // livingbridge
    connection: "TGl2aW5nYnJpZGdlLUFBRA==", // Livingbridge-AAD
    domains: [
      "bGl2aW5nYnJpZGdlLmNvbQ==", // livingbridge.com
    ],
    authButtonTheming: {
      displayName: `Log in at ${atob("TGl2aW5nYnJpZGdl")}` // Livingbridge
    },
  },
  {
    urlPart: "c2l0YQ==", // sita
    connection: "U0lUQS1BQUQ=", // SITA-AAD
    domains: [
      "c2l0YS5hZXJv", // sita.aero
    ],
    authButtonTheming: {
      displayName: `Log in at ${atob("U0lUQQ==")}` // SITA
    },
  },
  {
    urlPart: "YXp0ZWM=", // aztec
    connection: "QXp0ZWMtQUFE", // Aztec-AAD
    domains: [
      "YXp0ZWNncm91cC5jby51aw==", // aztecgroup.co.uk
      "YXp0ZWNncm91cC5ldQ==", // aztecgroup.eu
      "YXp0ZWNncm91cC5pZQ==", // aztecgroup.ie
      "YXp0ZWNncm91cC51cw==", // aztecgroup.us
      "YXp0ZWMuZ3JvdXA=", // aztec.group
    ],
    authButtonTheming: {
      displayName: `Log in at ${atob("QXp0ZWM=")}` // Aztec
    },
  },
  {
    urlPart: "Z2V0cm9uaWNz", // getronics
    connection: "R2V0cm9uaWNzLUFBRA==", // Getronics-AAD
    domains: [
      "Z2V0cm9uaWNzLmNvbQ==", // getronics.com
      "Y29ubmVjdGlzLWdzLmNs", // connectis-gs.cl
      "Y29ubmVjdGlzLWdzLmNvbS5icg==", // connectis-gs.com.br
      "Y29ubmVjdGlzLWljdC5jb20uYXI=", // connectis-ict.com.ar
    ],
    authButtonTheming: {
      displayName: `Log in at ${atob("R2V0cm9uaWNz")}` // Getronics
    },
  },
  {
    lockOptions: {
      rememberLastLogin: false
    },
    urlPart: "c3Rw" // stp
  }
].map(x => {
  x.urlPart = atob(x.urlPart);
  x.routeStateName = `login-${x.urlPart}`
  x.loginUrl = `/login/${x.urlPart}`

  if (x.connection) {
    x.connection = atob(x.connection)
  }

  if (x.domains) {
    x.domains = x.domains.map(domain => atob(domain))
  }
  return x
});


export function getSsoSpecificationFromEmail(email) {
  return enterpriseLogins.find(x => x.domains && x.domains.some(domain => _.endsWith(email, domain)))
}

export function getEnterpriseLoginRouteNames() {
  return enterpriseLogins.map(x => x.routeStateName)
}

export function getEnterpriseLoginSpecifications() {
  return _.cloneDeep(enterpriseLogins)
}
