(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfHierarchicalEditor', wfHierarchicalEditor);

	var templateUrl = "scripts/wf/hierarchical/hierarchicalEditor.controller.html";
	var templateRequest;
	var templateLoaded = false;

	wfHierarchicalEditor.$inject = [];
	
	function wfHierarchicalEditor() {
		var directive = {
			restrict: "E",
			scope: {
				structureIdFromDirective: "=structureId",
				itemFromDirective: "=item",
				include: "<",
				override: "<",
				isPrinting: "=",
				onLoaded: "&",
				transclusionOuterVm: "<",
				uiMode: "<",
				configFromAttribute: "<config", // Use to override anything on vm in hierarchicalEditor controller
				header: "<header", // Use to override the header 
				adderDropdownActions: "<adder", // Use to override what will be available in the dropdown for adding items to the hierarchy
				ticket: "<ticket"
				// hideFilterBars: "<",
				// hidePageHeading: "<"
			},

			// Terminal is needed to prevent Angular from touching any html that might be inside <wf-list-interface></wf-list-interface>
			// that will be used for transclusion. The html is manually cloned and passed to every instance of wfListItem in ngRepeat.
			terminal: true,

			// Transclusion slots:
			// transcludeItemSide (handled manually)
			
			controller: ["$scope", "$element", "$attrs", "$templateCache", "$http", "$timeout", "$compile", function ($scope, $element, $attrs, $templateCache, $http, $timeout, $compile) {

				$scope.openedViaDirective = true;
				$scope.directiveElement = $element;
				$scope.openedInModal = !!$element.closest("div.modal-dialog").length;

				$scope.hidePageHeading = "hidePageHeading" in $attrs;

				if ($element[0].childNodes.length) {
					$scope.transcludeItemSide = {
						elementClone: $element.children("transclude-item-side")[0],
						outerVm: $scope.transclusionOuterVm
					};
					$element.html("");
				}

				loadTemplate();

				$timeout();
		
				function loadTemplate() {
					if (templateLoaded) {
						applyTemplate();
						return;
					}
					else if ($templateCache.get(templateUrl)) {
						templateLoaded = true;
						applyTemplate();
						return;
					}
		
					if (!templateRequest) {
						templateRequest = $http.get(templateUrl)
					}
		
					templateRequest.then(function (res) {
						templateLoaded = true;
						$templateCache.put(templateUrl, res.data);
						applyTemplate();
					});
				}
		
				function applyTemplate() {
					var templateContent = $templateCache.get(templateUrl);
		
					$element.html(templateContent);
					$compile($element.contents())($scope);
				}

			}]
		};
		return directive;
	}
})();