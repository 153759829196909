import * as enums from '@worldfavor/constants/enums'

(function() {
'use strict';

	// Usage:
	//
	// Creates:
	//

	angular
		.module('wf.common')
		.component('wfValuechainPackageSubitem', {
			templateUrl:'scripts/wf/valueChain/valueChainPackageSubItem.component.html',
			//templateUrl: 'templateUrl',
			require: "^^ValueChainPackageEditorController",
			controller: wfValuechainPackageSubitemController,
			controllerAs: "vm",
			bindings: {
				item: '=',
				numberPath: "=",
				packageVm: "=",
				belongsTo: "=",
				depth: "="
			}
		});

	wfValuechainPackageSubitemController.$inject = ["$scope", "$timeout", "requirements", "wfAuth"];
	function wfValuechainPackageSubitemController($scope, $timeout, requirements, wfAuth) {
		var
			authOrgId = wfAuth.getOrganizationId(),
			vm = this,
			dataRelation = vm.item,
			conditions
		;

		////////////////

		vm.enums = enums;
		vm.itemContent = vm.item.childContent;

		conditions = vm.itemContent.conditions;

		if (vm.item.childType === enums.objectType.structure) {
			vm.childs = _.sortBy(vm.itemContent.childs, "order");
		}

		if (vm.packageVm.loadedViaIntersection) {
			vm.childs = _.filter(vm.childs, { type: enums.objectType.virtualDataRelation, intersectionTargetWfid: vm.packageVm.relationIntersectionTargetWfid });
		}

		vm.$onInit = function() {
			if (vm.item.childContent.creatorOrganizationId === authOrgId) {
				vm.dropdownActions = "update,deleteTag";
			}
			else {
				vm.dropdownActions = "deleteTag";
			}
		};
		vm.$onChanges = function(changesObj) { };
		vm.$onDestory = function() { };

		vm.addQuestion = addQuestion;
		vm.addCategory = addCategory;
		vm.addMeasure = addMeasure;
		vm.addRelativeMeasure = addRelativeMeasure;
		vm.addTaskStructure = addTaskStructure;
		vm.reorder = reorder;

		vm.itemRelatedContent = _.orderBy(vm.itemContent.relatedContent, [ x => x.childContent.title && x.childContent.title.toLowerCase() ]);

		vm.onObjectSettingsChanged = function () {
			handleRequirementsSettings();

			if (vm.itemContent.type === enums.objectType.measure)
				vm.measureRequirementsCompiler.compile();
		};

		handleRequirementsSettings()

		if (conditions && "dataRelationByUser" in conditions) {
			vm.hasDataRelationByUserCondition = true;
		}

		$scope.$on("itemToolsActionExecuted", function ($event, operation, item, dataRelation) {
			if (operation === "delete") {
				if (_.remove(vm.belongsTo, { wfid: item.wfid }).length || (dataRelation && _.remove(vm.belongsTo, { wfid: dataRelation.wfid }).length)) {
					$timeout();

					vm.packageVm.reorder(vm.belongsTo);
				}
			}
		});

		function addQuestion() {
			return vm.packageVm.addQuestion(vm.itemContent).then(function () {
				vm.childs = vm.itemContent.childs;
				$timeout();

				vm.packageVm.reorder(vm.childs);
			})
		}

		function addMeasure() {
			return vm.packageVm.addMeasure(vm.itemContent).then(function () {
				vm.childs = vm.itemContent.childs;
				$timeout();

				vm.packageVm.reorder(vm.childs);
			})
		}

		function addRelativeMeasure() {
			return vm.packageVm.addRelativeMeasure(vm.itemContent).then(function () {
				vm.childs = vm.itemContent.childs;
				$timeout();

				vm.packageVm.reorder(vm.childs);
			})
		}

		function addCategory() {
			vm.packageVm.addCategory(vm.itemContent).then(function () {
				vm.childs = vm.itemContent.childs;
				$timeout();

				vm.packageVm.reorder(vm.childs);
			})
		}

		function addTaskStructure() {
			vm.packageVm.addTaskStructure(vm.itemContent).then(function () {
				vm.childs = vm.itemContent.childs;
				$timeout();

				vm.packageVm.reorder(vm.childs);
			})
		}

		function reorder(direction) {
			return vm.packageVm.reorder(vm.belongsTo, vm.item, direction);
		}

		function handleRequirementsSettings() {
			var
				measurePeriodSettings
			;

			if (vm.itemContent.type === enums.objectType.measure) {
				if (!vm.measureRequirementsCompiler)
					vm.measureRequirementsCompiler = {};

				measurePeriodSettings = _.get(dataRelation.settings, "measurePeriodSettings")

				if (measurePeriodSettings) {
					vm.requirementSettings = requirements.getMeasureSettingsFromPeriodSettings(null, vm.packageVm.uiMode, vm.packageVm.$scope, measurePeriodSettings);
				}
				else {
					vm.requirementSettings = vm.packageVm.measureRequirementSettings;
				}
			}
		}
	}
})();
