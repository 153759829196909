import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions  from '@material-ui/core/DialogActions'

const styles = {
  content: {
    whiteSpace: 'pre-wrap',
  },
}

const GuidanceDialog = ({ classes, children, open, onClose, ...rest }) => {
  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      <DialogTitle><FormattedMessage id={'general.guidance'} /></DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          {children}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          <FormattedMessage id={'general.close'} />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(GuidanceDialog)
