import * as enums from '@worldfavor/constants/enums'

/**
 * @ngdoc component
 * @name wfItemComponent
 * @require ?^^wfItemsCollection
 *
 * @description
 * Used to display properties of WfObject, its content and values.
 * The purpose of this component is to prepare all necessary information for rendering in HTML.
 *
 * @param {WfObject} item Used as the main item
 * @param {WfObject=} item-content If item is not available, itemContent is passed
 * @param {WfObject=} item-relation If item is not available, itemRelation is passed
 *
 * @param {Object=} include Specifies which part of a component to show/hide (see getDefaultIncludes())
 * @param {Object=} display Configuration object to set different sizes (tiny, small, MEDIUM (default), large, extra-large, only-image), styles (no-padding, padding-vertical, borders, readMoreLimit), useFriendlyTitle
 *
 * @param {Array|String=} dropdown-actions Mandatory to show the dropdown list. It passes the values to wf-dropdown directive
 * @param {Object} dropdown-options Additional options (functions as a string) passed as an attribute to wf-dropdown directive
 * @property boolean disableDeleting
 * @property function onActionClick
 *
 * @param {Object} include
 * @property boolean image
 * @property boolean userImage
 * @property boolean itemDropdown
 * @property boolean content
 * @property boolean transclude
 * @property boolean sideActions
 * @property boolean header
 * @property boolean preHeader
 * @property boolean subHeader
 * @property boolean body
 * @property boolean embedPlayer
 * @property boolean metadata
 * @property boolean readMoreDescription
 * @property boolean readMoreFocus
 * @property boolean headerIcon
 * @property boolean creatorBar
 *
 * @param {} hide-image Hides the image from the component
 * @param {} hide-user-image Hides the user image from the component
 * @param {} hide-dropdown Hides dropdown button
 * @param {} hide-content Hides all header, body text and metadata
 * @param {} hide-transclude Hides any markup added inside <wf-item-component></wf-item-component>
 * @param {} hide-side-actions Hides buttons for answering a question or adding measured data
 * @param {} hide-header Hides ONLY the header
 * @param {} hide-pre-header Hides ONLY preHeader
 * @param {} hide-sub-header Hides ONLY subHeader
 * @param {} hide-body Hides description and other content under header
 * @param {} hide-embed-player Hides the player if an item is of type embed
 * @param {} hide-metadata Hides metadata
 * @param {} use-vertical-metadata Uses a different style of metadata
 * @param {} use-expandable-object-viewer Adds Show User Data next to Detailed View in metadata
 * @param {} hide-creator Hides the creator of the item
 * @param {} only-image Makes the image in the center and hides the rest of the content
 * @param {} no-padding Removes padding (top, right, bottom and left)
 * @param {} no-hover Removes hover effect
 * @param {} padding-vertical Has only padding top and bottom
 * @param {} read-more-description Accepts boolean (default: false) to show Read More/Read Less
 *
 * @example SIMPLE EXAPMLES - mandatory attributes
 * <wf-item-component item="item"></wf-item-component>
 * <wf-item-component item-content="itemContent" item-relation="itemRelation"></wf-item-component>
 *
 * @example METADATA EXAMPLES
 * <wf-item-component item="item" use-vertical-metadata hide-creator></wf-item-component>
 * <wf-item-component item="item" use-expandable-object-viewer></wf-item-component>
 *
 * @example HIDING CONTENT EXAMPLES
 * <wf-item-component item="item" hide-dropdown hide-pre-header hide-sub-header></wf-item-component>
 * <wf-item-component item="item" include="{itemDropdown: false, preHeader: false, subHeader: false, metadata: true}"></wf-item-component>
 *
 * @example DISPLAY EXAMPLES
 * @description FOR NOW, style and size properties accept only one String
 *
 * <wf-item-component item="item" display="{view:'list', size:'small', styles:{noPadding: true, cutBody: true}}"></wf-item-component>
 * <wf-item-component item="item" only-image></wf-item-component>
 *
 * @example DROPDOWN ACTIONS AND OPTIONS
 * @description string or an array is directly passed to wf-dropdown - see wf-dropdown doc
 *
 * <wf-item-component item="item" dropdown-actions="'userData'"></wf-item-component>
 * <wf-item-component item="item" dropdown-actions="['deleteContent', {text: 'Maki', icon: 'fa fa-pencil', action:'itemVm.whateverAction()'}]"></wf-item-component>
 *
 * <wf-item-component item="item" dropdown-actions="['objectViewer', '-', 'sendTo', 'addTo']" dropdown-options="{disableDeleting: true, onActionClick: itemVm.onActionClick}"></wf-item-component>
 *
 * @example TRANSCLUDE
 * @description wf-item-component supports MULTIPLE transclusion. Transclusion is also optional
 *
 * All the elements are added on the side (next to the itemTitle and itemBody) when putting elements inside <transclude-side> tag
 * <wf-item-component item="item">
 * 	<transclude-side>
 * 		<button>I'm awesome</button>
 * 	<transclude-side>
 * </wf-item-component>
 *
 * All the elements are added under wf-metadata element when putting elements inside <transclude-bottom> tag
 * <wf-item-component item="item">
 * 	<transclude-bottom>
 * 		<button>I'm awesome</button>
 * 	<transclude-bottom>
 * </wf-item-component>
 *
 *
 * @example READ MORE EXAMPLE - setting the limit is optional
 * @description If the body text is too long, there is an option to include read more/read less feature, so that users can see the entire text on demand
 *
 * <wf-item-component item="item" include="{readMoreDescription: true}" display="{styles:{readMoreLimit: 450}}"></wf-item-component>
 *
 *
 * @example HEADER ICON
 * @description Additional icon on the left side from the header (it will add the icon if the item has a class - item.conditions.iconCssClass).
 *  Also the color can be added as - item.conditions.color1 || color2.
 *  Note that headerBodyWrap must also be included in the wf-item-component in order for headerIcon to work
 *
 *
 * <wf-item-component item="item" include={headerBodyWrap: true, headerIcon: true}></wf-item-component>
 *
 *
*/

(function () {
	'use strict';

	angular
		.module ('wf.common')
		.component ('wfItemComponent', wfItemComponent());

	function wfItemComponent() {
		var component = {
			bindings: {
				item: "=",
				incomingItemContent: "=itemContent",
				incomingItemRelation: "=itemRelation",
				display: '<',
				include: '=',
				uiMode: '<',
				dropdownActions: '=',
				dropdownOptions: '=',
				intersection: '<',
				metadataOptions: '=',
				control: '<',
				ticket: '<',
			},
			require: "?^^wfItemsCollection",
			controller: wfItemComponentController,
			controllerAs: 'itemVm',
			transclude: {
				'transcludeSide': '?transcludeSide',
				'transcludeBottom': '?transcludeBottom',
				'transcludeFooter': '?transcludeFooter',
			},
			templateUrl: "scripts/wf/itemComponent/wfItem.component.html"
		}
		return component;
	}

	wfItemComponentController.$inject = ["$scope", "$attrs", '$translate', '$element', '$transclude', '$parse', '$timeout'];
	function wfItemComponentController($scope, $attrs, $translate, $element, $transclude, $parse, $timeout) {
		var
			vm = this
		;

		vm.onDropdownAction = function (action) {

		}

		_.assign(vm, {
			$element: $element,
			itemContent: undefined,
			dataRelation: undefined,
			hasTranscludedContent: undefined,
			imageDefaults: undefined,
			hideCreatorMetadata: false,
			useVerticalMetadata: false,
			appendPopoverToBody: false,
			noPadding: false,
			removeMinWidth: false,
			onlyImage: false,
			paddingVertical: false,
			useExpandableObjectViewerMetadata: false,
			imageCss: undefined,
			isItemUser: false,
			includes: _.assign(getDefaultIncludes(), vm.include),
			answeringUIMode: vm.uiMode || enums.uiMode.work,
			metadataMethods: {},
			onActionClick: onActionClick,
			onActionClickCallback: undefined,
			cutBody: false,
			hasBodyText: false,
			bodyText: undefined,
			readMoreLimit: undefined,
			readMoreFocus: false,
			useFriendlyTitle: false,
			imageIconColor: undefined,
			text: {
				readMore: $translate.instant("ReadMore"),
				readLess: $translate.instant("Hide")
			},
			metadataItemSource: vm.metadataOptions ? vm.metadataOptions.itemSource || "itemContent" : "itemContent",
			metadataConfig: vm.metadataOptions
		});

		if (vm.incomingItemContent || vm.incomingItemRelation) {
			vm.itemContent = vm.incomingItemContent;
			vm.dataRelation = vm.incomingItemRelation;
		}
		else if (vm.item) {
			if (vm.item.composite) {
				vm.itemContent = vm.item.content;
				vm.dataRelation = vm.item.dataRelation;
			}
			else {
				if (vm.item.isRelationalType && vm.item.isRelationalType() || (vm.item.type === 73 || vm.item.type === 81)) {
					vm.itemContent = vm.item.childContent;
					vm.dataRelation = vm.item;
				}
				else {
					vm.itemContent = vm.item;
				}
			}
		}

		activate();

		function activate() {
			if (vm.control) {
				vm.control.$scope = $scope;
				vm.control.$element = $element;
			}
			vm.hasTranscludeSide = $transclude.isSlotFilled("transcludeSide");
			vm.hasTranscludeBottom = $transclude.isSlotFilled("transcludeBottom");
			vm.hasTranscludeFooter = $transclude.isSlotFilled("transcludeFooter");

			// $transclude(function(clonedElement) {
			// 	console.log(arguments)
			// 	vm.hasTranscludedContent = clonedElement.length != 0;
			// });
			vm.isOfAnswerableType = vm.itemContent.isOfAnswerableType && vm.itemContent.isOfAnswerableType();
			vm.isAnswerType = vm.itemContent.isAnswerType && vm.itemContent.isAnswerType();
			if (vm.itemContent.type == enums.objectType.individual)
				vm.isItemUser = true;

			if (!vm.intersection)
				vm.intersection = vm.dropdownOptions && vm.dropdownOptions.intersection ? vm.dropdownOptions.intersection : undefined;

			setView();
			validateAttributes();
			validateDisplayOptions();

			if (vm.includes.readMoreDescription) {
				if (vm.display && vm.display.styles && vm.display.styles.readMoreLimit) {
					vm.readMoreLimit = vm.display.styles.readMoreLimit;
				}
				vm.bodyText = vm.itemContent.getBodyText();

				if (vm.bodyText && vm.bodyText.length !== 0) {
					vm.hasBodyText = true;
				}

				if (vm.includes.readMoreFocus)
					vm.readMoreFocus = true;
			}

			if (vm.includes.itemDropdown && vm.dropdownActions == undefined)
				vm.includes.itemDropdown = false;
		}

		function validateDisplayOptions() {
			var size;
			if (vm.display != undefined) {
				if (vm.display.size != undefined)
					size = vm.display.size;
				if (vm.display.imageSize) {
					vm.imageCss = {
						width: vm.display.imageSize[0] + "px",
						height: vm.display.imageSize[1] + "px"
					}
					if (vm.display.imageOffset) {
						_.assign(vm.imageCss, {
							marginTop: vm.display.imageOffset,
							marginLeft: vm.display.imageOffset,
							paddingRight: -1 * vm.display.imageOffset,
							paddingBottom: -1 * vm.display.imageOffset,
							marginBottom: vm.display.imageOffset,
							boxSizing: 'content-box'
						});
					}
				}
				if (vm.display.useFriendlyTitle)
					vm.useFriendlyTitle = true;
			}
			if ('onlyImage' in $attrs) {
				size = 'only-image';
			}

			switch (size) {
				case 'tiny':
				case 'small':
				case 'medium':
				case 'large':
				case 'extra-large':
				case 'only-image':
					$element.addClass(size);
					break;
				default:
					$element.addClass('medium');
					if (vm.includes.wfid) {
						switch (vm.itemContent.type) {
							case enums.objectType.question:
							case enums.objectType.measure:
							case enums.objectType.structure:
							case enums.objectType.relativeMeasure:
								vm.showItemWfid = true
						}
					}
			}

			var styles;
			if (vm.noPadding)
				$element.addClass('no-padding');
			if (vm.removeMinWidth)
				$element.addClass('no-min-width');
			if (vm.noHover)
				$element.addClass('no-hover');
			if (vm.paddingVertical)
				$element.addClass('padding-vertical')

			if (vm.display != undefined) {
				if (vm.display.imageIconColor)
					vm.imageIconColor = vm.display.imageIconColor;
				if (vm.display.styles != undefined) {
					styles = vm.display.styles;
					if (styles.noPadding) { // no-padding
						$element.addClass('no-padding');
					}
					if (styles.paddingVertical) { // vertical-padding
						$element.addClass('padding-vertical');
					}
					if (styles.borders) { // add-borders
						$element.addClass('borders');
					}
					if (styles.cutBody) {
						vm.cutBody = vm.itemContent.typeHasHeader();
					}
					if (styles.removeMinWidth) {
						$element.addClass('no-min-width');
					}
					if (styles.imageDefaults) {
						vm.imageDefaults = styles.imageDefaults;
					}
					if (styles.noHover) {
						$element.addClass('no-hover');
					}
					if (styles.appendPopoverToBody) {
						vm.appendPopoverToBody = true;
					}
					if (styles.readMoreLimit) {
						vm.readMoreLimit = styles.readMoreLimit;
					}
				}
			}
		}

		function setView() {
			var view;
			if ('onlyImage' in $attrs) {
				$element.addClass('only-image');
			}
			else {
				if (vm.display != undefined) {
					if (vm.display.view != undefined)
						view = vm.display.view;
				}

				switch (view) {
					case 'grid':
						$element.addClass(view);
						break;
					case 'only-image':
						$element.addClass(view);
						break;
					case 'card':
						$element.addClass(view);
						break;
					case 'list':
						$element.addClass(view);
						break;
					default:
						$element.addClass('list');
				}
			}
		}

		function validateAttributes() {
			var include = vm.includes;

			if ('hideImage' in $attrs) { // hide-image
				if ($attrs.include === undefined || !include.image) {
					vm.includes.image = false;
				}
			}
			if ('hideUserImage' in $attrs) { // hide-user-image
				if ($attrs.include === undefined || !include.userImage) {
					vm.includes.userImage = false;
				}
			}
			if ('readMoreDescription' in $attrs) { //read-more-description
				if ($attrs.include == undefined || !include.readMoreDescription) {
					vm.includes.readMoreDescription = true;
				}
			}
			if ('hideDropdown' in $attrs ) { // hide-dropdown
				if ($attrs.include === undefined || !include.itemDropdown) {
					vm.includes.itemDropdown = false;
				}
			}
			if ('hideContent' in $attrs ) { // hide-content
				if ($attrs.include === undefined || !include.content) {
					vm.includes.content = false;
				}
			}
			if ('hideTransclude' in $attrs ) { // hide-transclude
				if ($attrs.include === undefined || !include.transclude) {
					vm.includes.transclude = false;
				}
			}
			if ('hideSideActions' in $attrs) { // hide-side-actions
				if ($attrs.include === undefined || !include.sideActions) {
					vm.includes.sideActions = false;
				}
			}
			if ('hideHeader' in $attrs) { // hide-header
				if ($attrs.include === undefined || !include.header) {
					vm.includes.header = false;
				}
			}
			if ('hidePreHeader' in $attrs) { // hide-pre-header
				if ($attrs.include === undefined || !include.preHeader) {
					vm.includes.preHeader = false;
				}
			}
			if ('hideSubHeader' in $attrs) { // hide-sub-header
				if ($attrs.include === undefined || !include.subHeader) {
					vm.includes.subHeader = false;
				}
			}
			if ('hideBody' in $attrs) { // hide-body
				if ($attrs.include === undefined || !include.body) {
					vm.includes.body = false;
				}
			}
			if ('hideEmbedplayer' in $attrs) { // hide-embedplayer
				if ($attrs.include === undefined || !include.embedPlayer) {
					vm.includes.embedPlayer = false;
				}
			}
			if ('hideMetadata' in $attrs) { // hide-metadata
				if ($attrs.include === undefined || !include.metadata) {
					vm.includes.metadata = false;
				}
			}
			if ('hideCreator' in $attrs) { // hide-creator
				vm.hideCreatorMetadata = true;
			}
			if ('onlyImage' in $attrs) {
				vm.includes.itemDropdown = false;
				vm.includes.content = false;
				vm.includes.transclude = false;
				vm.includes.sideActions = false;
				vm.includes.headerBodyWrap = false;
				vm.includes.header = false;
				vm.includes.preHeader = false;
				vm.includes.subHeader = false;
				vm.includes.body = false;
				vm.includes.embedPlayer = false;
				vm.includes.metadata = false;
			}
			if ('noPadding' in $attrs) {
				vm.noPadding = true;
			}
			if ('noHover' in $attrs) {
				vm.noHover = true;
			}
			if ('removeMinWidth' in $attrs) {
				vm.removeMinWidth = true;
			}
			if ('paddingVertical' in $attrs) {
				vm.paddingVertical = true;
			}
			if ('useVerticalMetadata' in $attrs) { // use-vertical-metadata
				vm.useVerticalMetadata = true;
			}
			if ('useExpandableObjectViewer' in $attrs) { // use-expandable-object-viewer
				vm.useExpandableObjectViewerMetadata = true;
			}
		}

		function getDefaultIncludes() {
			return {
					image:               true,
					userImage:           true,
					itemDropdown:        true,
					readMoreDescription: false,
					content:             true,
					transclude:          true,
					sideActions:         true,
					creatorBar:          false,
					headerBodyWrap:      true,
					header:              true,
					headerIcon:          false,
					preHeader:           true,
					subHeader:           true,
					body:                true,
					embedPlayer:         true,
					metadata:            true,
					attachedInformationList: false
				}
		}

		function onActionClick() {
			//used for wf-dropdown (on-action-click attribute)
			console.log("I clicked!");
		}
	}

} ());
