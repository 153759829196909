import * as enums from '@worldfavor/constants/enums'

(function() {
'use strict';

	angular
		.module('wf.common')
		.controller('StandardsAndGuidelinesController', StandardsAndGuidelinesController);

	StandardsAndGuidelinesController.$inject = ['$scope', '$element', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', '$timeout', 'formSchemaService', '$q', '$rootScope', 'DS', 'wfAuth', 'apiProxy', 'wfTranslate'];
	function StandardsAndGuidelinesController($scope, $element, dataOps, modal, wfObject, dataQuery, $translate, $timeout, formSchemaService, $q, $rootScope, DS, wfAuth, apiProxy, wfTranslate) {
		var
			vm = this,
			searchInput,
			currentSearchString
		;

		_.assign(vm, {
			loaded: false,
			mainStructure: undefined,
			isSearchMode: false,
			isViewMode: true,
			searchResultItems: [],
			standardsById: {},
			initSearch: initSearch,
			getBelongingText: getBelongingText
		})

		activate();

		function activate() {
			dataOps.getObject({
				objectId: 12728, // Contains standards & guidelines according to Enterprise subscription
				objectType: enums.objectType.structure,
				childrenLoadDepth: 1
			}).then(function (res) {
				vm.mainStructure = res;
				vm.standardsById = _.chain(vm.mainStructure.childs).map("childContent").keyBy("id").value();
				vm.standardIds = _.map(vm.mainStructure.childs, "childId");
				vm.loaded = true;
				// console.log(vm)
			});
		}

		function initSearch($event) {
			var
				searchReqeust
			;

			vm.initSearch = undefined;
			searchInput = $($event.target);

			searchInput.bind("keypress keydown change", _.debounce(function (event) {
				var newSearchString = $.trim(searchInput.val());

				if (newSearchString === currentSearchString) {
					// console.log("same input - do nothing")
					return;
				}

				if (searchReqeust) {
					// console.log("abort ongoing search");
					searchReqeust.abort();
					searchReqeust = undefined;
				}

				currentSearchString = newSearchString;

				if (currentSearchString.length === 0) {
					closeSearcher();
					// console.log("closeSearcher")
					return;
				}
				else if (currentSearchString.length > 2) {
					showSearcher();
					// console.log("showSearcher")
				}

				// console.log("do search request")
				vm.searchResultItems.length = 0;
				vm.isSearching = true;
				vm.searchCompleted = false;
				$timeout();
				searchReqeust = apiProxy.raw("multi.getObjects", {
					objectType: enums.objectType.standards,
					wrapInRelations: false,
					searchString: currentSearchString,
					ancestorIds: vm.standardIds,
					dataSource: 1,
					pageSize: 20
				});

				// console.log(searchReqeust);

				searchReqeust.then(function (res) {
					searchReqeust = undefined;
					// console.log(searchInput.val(), res.length)
					vm.isSearching = false;
					vm.searchCompleted = true;
					vm.searchResultCount = res.length;
					_.merge(vm.searchResultItems, _.take(res, vm.searchResultCount));
					$timeout();
					
				});
			}, 150));
		}

		function closeSearcher() {
			vm.isViewMode = true;
			vm.isSearchMode = false;
			vm.searchResultItems.length = 0;
			vm.searchCompleted = true;
			$timeout();
		}

		function showSearcher() {
			vm.isViewMode = false;
			vm.isSearchMode = true;
			$timeout();
		}

		function showSearchLoading() {
			vm.isSearching = true;
			$timeout();
		}

		function getBelongingText(item) {
			var output;

			if (item.type === enums.objectType.structure) {
				output = $translate.instant("Category");
			}
			else {
				output = wfTranslate.instant("MAP_ObjectType", { type: item.type });
			}

			if (vm.standardsById[item.ancestorId])
				output += " " + $translate.instant("In").toLowerCase() + " " + vm.standardsById[item.ancestorId].title;

			return output;
		}
	}
})();
