import * as enums from '@worldfavor/constants/enums'

(function () {
	'use strict';

	angular
		.module('wf.common')
		.service('requirements', requirementsService)

	requirementsService.$inject = [ "$translate", "$timeout", "dataQuery", "wfObject", "wfPropertyExtractor", "wfMeasureService", "wfAuth", "apiProxy", "$q", "dataOperationsService", "valueChainService" ]
	function requirementsService($translate, $timeout, dataQuery, wfObject, wfPropertyExtractor, wfMeasureService, wfAuth, apiProxy, $q, dataOps, valueChainService) {
		var
			translate = $translate.instant,
			authOrgId = wfAuth.getOrganizationId(),
			cachedRequirementTexts = {},
			fulfillmentCreator_xhrRequestByRequirementWfid = {},
			service = {
				getMainStructureSettings: getMainStructureSettings,
				getMeasureSettings: getMeasureSettings,
				getMeasureSettingsFromPeriodSettings: getMeasureSettingsFromPeriodSettings,
				getQuestionSettings: getQuestionSettings,
				getTaskStructureSettings: getTaskStructureSettings,
				getUtilityStructureSettings: getUtilityStructureSettings,
				getParameterSettings: getParameterSettings,
				getFindingSettings: getFindingSettings,
				getActualRequirement: getActualRequirement,
				checkLocalFulfillment: checkLocalFulfillment,
				getActualRequirementOnItems: getActualRequirementOnItems,
				maybeSetAssessmentNeedOnItem: maybeSetAssessmentNeedOnItem,
				getRequirementText: getRequirementText,
				getFulfillmentStateText: getFulfillmentStateText,
				influenceFulfillmentCalculator: influenceFulfillmentCalculator,
				getQuestionRequirementSettings: getQuestionRequirementSettings,
				getTaskStructureRequirementSettings: getTaskStructureRequirementSettings,
			},
			fulfillmentStateTexts = {
				[enums.fulfillmentState.fulfilled]:          $translate.instant("Fulfilled"),
				[enums.fulfillmentState.unfulfilled]:        $translate.instant("NotFulfilled"),
				[enums.fulfillmentState.assessmentNeeded]:   $translate.instant("fulfillmentStates.awaitingAssessment"),
				[enums.fulfillmentState.reportingNeeded]:    $translate.instant("fulfillmentStates.toBeReported"),
				[enums.fulfillmentState.expired]:            $translate.instant("fulfillmentStates.expired"),
				[enums.fulfillmentState.certificateExpired]: $translate.instant("fulfillmentStates.certificateExpired"),
				["partiallyFulfilled"]:                      $translate.instant("fulfillmentStates.partiallyFulfilled"),
				"answered":                                  $translate.instant("fulfillmentStates.answered"),
				"notAnswered":                               $translate.instant("fulfillmentStates.notAnswered"),
			}
		;

		return service;

		function printAllRequirements() {
			const organizationId = 1;
			const uiMode = 3;
			const $scope = undefined;
			const includeExtendedOptions = false;
			const periodSettings = { frequency: enums.calendarFrequency.yearly };
			const periodSettingsSourceObject = undefined;

			const questionRequirements = getQuestionSettings(organizationId, uiMode, $scope, includeExtendedOptions).requirementOptions;
			const measureRequirements = getMeasureSettingsFromPeriodSettings(organizationId, uiMode, $scope, periodSettings, periodSettingsSourceObject).requirementOptions;
			const taskStructureRequirements = getTaskStructureSettings(organizationId, uiMode, $scope, includeExtendedOptions).requirementOptions;

			const output = [];

			[
				{ type: 11, items: questionRequirements },
				{ type: 21, items: measureRequirements },
				{ type: 71, items: taskStructureRequirements }
			].flat().forEach(x => {
				x.items.forEach(req => {
					output.push({ concatinated: `${x.type}_${req.rule}_${req.value || ''}`, objectType: x.type, ...req });
				})
			})

			console.log(output)
			console.log('\n' + output.map(x => `('${x.concatinated}','${x.name.replace("'","")}')`).join(',') + '\n')
		}

		function checkLocalFulfillment(item, dataRelation, requirement, intersectionSettings, options) {
			var fulfills, fulfillmentState = undefined, ruleIsManual, requirementHolder, detailedOutput = {};

			if (requirement) {
				if (requirement.rule === enums.requirementRule.manual && dataRelation) {
					if (item.type === enums.objectType.finding)
						requirementHolder = item;
					else
						requirementHolder = dataRelation

					ruleIsManual = true;
					let fulfillment = _.chain(wfObject.filter({ where: {
						type: enums.objectType.fulfillment,
						objectType: requirementHolder.type,
						objectId: requirementHolder.id,
						organizationId: intersectionSettings.organizationId,
						channelId: intersectionSettings.networkId
					}})).sortBy("createdAt").last().value();

					fulfills = fulfillment && fulfillment.fulfills;

					if (options && options.useDetailedResult) {
						fulfillmentState = fulfillment ? fulfillment.fulfillmentState : null;
					}
					getLatestReportedAnswer(item, dataRelation, intersectionSettings, detailedOutput)

					if (!detailedOutput.isAnswered) {
						if (!fulfills) {
							fulfills = false;
							fulfillmentState = enums.fulfillmentState.unfulfilled;
						}
					}

				}
				else if (item.type === enums.objectType.structure) {
					fulfillmentState = checkLocalStructureFulfillment(item, dataRelation, requirement, intersectionSettings, options, detailedOutput);
					fulfills = fulfillmentState === enums.fulfillmentState.fulfilled;
				}
				else if (item.type === enums.objectType.question) {
					fulfillmentState = checkLocalQuestionFulfillment(item, dataRelation, requirement, intersectionSettings, options, detailedOutput);
					fulfills = fulfillmentState === enums.fulfillmentState.fulfilled;
				}
				else if (item.type === enums.objectType.measure) {
					fulfillmentState = checkLocalMeasureFulfillment(item, dataRelation, requirement, intersectionSettings, options, detailedOutput);
					fulfills = fulfillmentState === enums.fulfillmentState.fulfilled;
				}
				else if (item.type === enums.objectType.parameter) {
					fulfills = checkLocalParameterFulfillment(item, requirement, intersectionSettings, options);
				}
			}
			else
				return null;

			if (options && options.useDetailedResult) {
				if (fulfillmentState === undefined)
					fulfillmentState = fulfills ? enums.fulfillmentState.fulfilled : enums.fulfillmentState.unfulfilled

				return {
					attachmentsNeeded: detailedOutput && detailedOutput.attachmentsNeeded,
					latestAnswerContent: detailedOutput && detailedOutput.latestAnswerContent,
					requiredMeasurePeriod: detailedOutput && detailedOutput.requiredMeasurePeriod,
					isAnswered: detailedOutput && detailedOutput.isAnswered,
					fulfills: fulfills,
					actuallyFulfills: fulfills && !(fulfillmentState != undefined && (fulfillmentState == enums.fulfillmentState.assessmentNeeded || fulfillmentState == enums.fulfillmentState.reportingNeeded || fulfillmentState == enums.fulfillmentState.expired || fulfillmentState == enums.fulfillmentState.certificateExpired)),
					fulfillmentState: fulfillmentState
				}
			}
			else
				return fulfills;
		}

		function getLatestReportedAnswer(item, dataRelation, intersectionSettings, detailedOutput) {
			var latestAnswerContent;
			if (item.type === enums.objectType.structure) {
				let kind;

				if (dataRelation.settings && dataRelation.settings.pickerRelationTargetKind)
					kind = dataRelation.settings.pickerRelationTargetKind;
				else
					kind = enums.subItemsKind.relatedContentByUser;

				let dataRelations = dataQuery.getIntersectedSubItems(item, _.assign({
					kind: kind
				}, intersectionSettings));

				if (dataRelations.length > 0) {
					let latestAddedData = _.chain(dataRelations).orderBy("createdAt").last().value();
					latestAnswerContent = latestAddedData.childContent;
				}
			}
			else if (item.type === enums.objectType.question) {
				latestAnswerContent = dataQuery.get.latestAnswerOnQuestion(item, intersectionSettings);
			}
			else if (item.type === enums.objectType.measure) {
				latestAnswerContent = dataQuery.get.latestAnswerOnMeasure(item, intersectionSettings);
			}
			else if (item.type === enums.objectType.parameter) {
				/// TODO: Implement
			}

			if (detailedOutput) {
				_.assign(detailedOutput, {
					latestAnswerContent: latestAnswerContent,
					isAnswered: !!latestAnswerContent,
					attachmentsNeeded: false
				});
			}

			return latestAnswerContent;
		}

		function checkLocalStructureFulfillment(item, dataRelation, requirement, intersectionSettings, options, detailedOutput) {
			var
				kind,
				dataRelations,
				latestAddedData,
				pickerSettings,
				fulfillmentState
			;

			if (requirement.rule == enums.requirementRule.anyValue) {

				if (_.get(item, "conditions.pickerSettings.relationTarget.item") === "@currentContextParent") {
					if (intersectionSettings && intersectionSettings.contextParents && intersectionSettings.contextParents.length) {
						pickerSettings = _.get(item, "conditions.pickerSettings");
						var
							relationKind = _.get(item, "conditions.pickerSettings.relationTarget.kind") || enums.subItemsKind.relatedContentByUser,
							// pickerSourceItemWfid = _.get(item, "conditions.pickerSettings.sourceItem"),
							// pickerSourceItem = wfObject.get(pickerSourceItemWfid),
							// pickerSourceItemChildren = pickerSourceItem.childs,
							firstContextParent = wfObject.get(intersectionSettings.contextParents[0]),
							firstContextParentSubItems = dataRelations = firstContextParent.getSubListOfKind(relationKind, intersectionSettings.organizationId)
							// resultItems = _.intersectionWith(firstContextParentSubItems, pickerSourceItemChildren, function (dr1, dr2) {
							// 	return dr1[wfObject.getRelationKeyOfKind(relationKind)] === dr2.wfcid
							// });
						;
					}
				}
				else
				{
					if (dataRelation.settings && dataRelation.settings.pickerRelationTargetKind)
						kind = dataRelation.settings.pickerRelationTargetKind;
					else
						kind = enums.subItemsKind.relatedContentByUser;

					dataRelations = dataQuery.getIntersectedSubItems(item, _.assign({
						kind: kind
					}, intersectionSettings));

					if (dataRelations.length > 0 && _.get(item, "conditions.subItemsSettings.pickerSettings")) {
						if (requirement.value == null) {
							let contentsMissingAttachments = [];
							fulfillmentState = _.filter(dataRelations, function (dataRelation) {
								if (_.get(dataRelation.childContent, "relatedContentByUser.length") > 0) {
									return true;
								}
								else {
									contentsMissingAttachments.push(dataRelation.childContent);
									return false;
								}
							}).length === dataRelations.length ? enums.fulfillmentState.fulfilled : enums.fulfillmentState.unfulfilled;

							if (detailedOutput) {
								_.assign(detailedOutput, {
									attachmentsNeeded: fulfillmentState === enums.fulfillmentState.unfulfilled,
									latestAnswerContent: contentsMissingAttachments,
									isAnswered: !!dataRelations.length
								});
							}

							if (requirement.maxAgeInDays) {
								latestAddedData = _.chain(dataRelations).orderBy("createdAt").last().value();
								var daysDiff = moment().diff(moment(latestAddedData.createdAt), "days");

								if (daysDiff > requirement.maxAgeInDays)
									return enums.fulfillmentState.expired;
							}

							return fulfillmentState;
						}
					}
				}

				if (dataRelations && dataRelations.length > 0) {
					latestAddedData = _.chain(dataRelations).orderBy("createdAt").last().value();
				}

				if (detailedOutput) {
					_.assign(detailedOutput, {
						attachmentsNeeded: false,
						latestAnswerContent: latestAddedData && latestAddedData.childContent,
						isAnswered: dataRelations && !!dataRelations.length
					});
				}

				if (hasAnyAttachedCertificateExpired(dataRelation, dataRelation.childContent, intersectionSettings)) {
					return enums.fulfillmentState.certificateExpired;
				}

				if (latestAddedData) {
					if (requirement.maxAgeInDays) {
						var daysDiff = moment().diff(moment(latestAddedData.createdAt), "days");

						if (daysDiff > requirement.maxAgeInDays)
							return enums.fulfillmentState.expired;
					}

					return enums.fulfillmentState.fulfilled;
				}
				else
					return enums.fulfillmentState.unfulfilled;
			}
		}

		function checkLocalQuestionFulfillment(item, dataRelation, requirement, intersectionSettings, options, detailedOutput) {
			var
				latestAnswerContent,
				answerId,
				validValues,
				fulfills = false,
				matchesRequiredValue = false,
				fulfillsException = false,
				partiallyFulfilled,
				_isRelatedContentByUserCountOverZero,
				fulfillmentState,
				isRelatedContentByUserCountOverZero = function () {
					if (typeof _isRelatedContentByUserCountOverZero === "undefined")
						_isRelatedContentByUserCountOverZero
							= latestAnswerContent && ((latestAnswerContent.metadata && latestAnswerContent.metadata.countByRelationKind && latestAnswerContent.metadata.countByRelationKind[enums.subItemsKind.relatedContentByUser] > 0)
								|| latestAnswerContent.relatedContentByUser.length)

					return _isRelatedContentByUserCountOverZero
				},
				attachmentsNeeded = false,
				isAnswered = false
			;

			if (options) {
				latestAnswerContent = options.latestAnswerContent;
				validValues = options.validValues;
			}

			if (!latestAnswerContent)
				latestAnswerContent = dataQuery.get.latestAnswerOnQuestion(item, intersectionSettings);

			answerId = latestAnswerContent ? latestAnswerContent.questionAnswerTypeId : undefined;

			isAnswered = !!answerId;

			if (!(requirement.rule == enums.requirementRule.anyValue
				|| requirement.rule == enums.requirementRule.anyValueWithRelatedContent
			) && answerId) {
				// Handle answer validity only if answerId is defined and the requirement defines a specific or preferred answer.

				if (!validValues) { // Check if validValues wasn't provided
					validValues = requirement && requirement.value ? requirement.value.toString().split(',') : [];
				}

				matchesRequiredValue = validValues.indexOf(answerId.toString()) !== -1
			}

			if (requirement.rule == enums.requirementRule.specificValues) {
				fulfills = matchesRequiredValue;
			}
			else if (requirement.rule == enums.requirementRule.anyValue) {
				if (latestAnswerContent)
					fulfills = true;
			}
			else if (requirement.rule == enums.requirementRule.preferredValue) {
				if (matchesRequiredValue)
					fulfills = true;
				else {
					if (isRelatedContentByUserCountOverZero()) {
						fulfills = true;
						partiallyFulfilled = true;
					}
					else {
						fulfills = false;
						attachmentsNeeded = true;
					}
				}
			}
			else if (requirement.rule == enums.requirementRule.preferredValueWithRelatedContent) {
				if (matchesRequiredValue) {
					if (isRelatedContentByUserCountOverZero()) {
						fulfills = true;
					}
					else {
						attachmentsNeeded = true
					}
				}
				else if (latestAnswerContent) {
					fulfills = true;
				}
			}
			else if (requirement.rule == enums.requirementRule.anyValueWithRelatedContent) {
				if (isRelatedContentByUserCountOverZero()) {
					fulfills = true;
				}
				else {
					attachmentsNeeded = true
				}
			}
			else if (requirement.rule == enums.requirementRule.specificValueWithRelatedContent) {
				if (matchesRequiredValue) {
					if (isRelatedContentByUserCountOverZero()) {
						fulfills = true;
					}
					else {
						attachmentsNeeded = true
					}
				}
			}

			fulfillmentState = fulfills ? enums.fulfillmentState.fulfilled : enums.fulfillmentState.unfulfilled;

			if (detailedOutput) {
				_.assign(detailedOutput, {
					attachmentsNeeded,
					latestAnswerContent,
					isAnswered
				});
			}

			if (hasAnyAttachedCertificateExpired(dataRelation, latestAnswerContent, intersectionSettings)) {
				return enums.fulfillmentState.certificateExpired;
			}

			// If partiallyFulfilled is true then use fulfillment state "partiallyFulfilled".
			// This will be used to display a filter button in hierarchical.
			if (partiallyFulfilled)// && _.get(dataRelation, "organizationId") !== requirement.creatorOrganizationId && authOrgId === requirement.creatorOrganizationId)
				fulfillmentState = "partiallyFulfilled";

			if (latestAnswerContent) {
				if (requirement.maxAgeInDays) {
					var daysDiff = moment().diff(moment(latestAnswerContent.createdAt), "days");

					if (daysDiff > requirement.maxAgeInDays)
						return enums.fulfillmentState.expired;
				}

				return fulfillmentState;
			}
			else
				return fulfillmentState;
		}

		function canHaveCertificateAttachments(dataRelation) {
			var objectTypes = dataRelation.childType === enums.objectType.structure ? _.get(dataRelation, "childContent.conditions.objectTypes") : _.get(dataRelation, "settings.objectTypes");
			return objectTypes && objectTypes.length && objectTypes.includes(enums.objectType.certificate);
		}

		function hasValidCertificate(content) {
			var validCertificates = _.chain(content.relatedContentByUser)
				.filter({ childType: enums.objectType.certificate })
				.filter(function (certificateDataRelation) {
					var certificate = certificateDataRelation.childContent;

					return moment().isBetween(certificate.validFromDate, certificate.validUntilDate);
				}).value();

			return validCertificates.length !== 0;
		}

		function hasAnyAttachedCertificateExpired(dataRelation, content, intersectionSettings) {
			if (!content || !dataRelation || !canHaveCertificateAttachments(dataRelation)) {
				return false
			}
			else {
				const attachedItems = dataQuery.getIntersectedSubItems(content, _.assign({
					kind: enums.subItemsKind.relatedContentByUser
				}, intersectionSettings))

				var expiredCertificates = attachedItems
				.filter(dr => dr.childType === enums.objectType.certificate)
				.filter(dr => {
					var certificate = dr.childContent;

					return !moment().isBetween(certificate.validFromDate, certificate.validUntilDate);
				})

				return expiredCertificates.length > 0;
			}
		}

		function checkLocalMeasureFulfillment(item, dataRelation, requirement, intersectionSettings, options, detailedOutput) {
			var
				value,
				latestAnswerYear,
				latestAnswerContent,
				answers,
				measureAnswer,
				fulfills = false,
				inPeriodRequirementValue,
				periodSettings,
				previousPeriod = {
					start: null,
					end: null
				},
				now,
				year,
				month,
				day,
				previousPeriodValue,
				currentYear,
				yearBeforeRangeIterations,
				maxYear,
				minYear,
				customPeriods,
				concatNowDateInteger,
				attachmentsNeeded = false
			;

			if (options) {
				latestAnswerContent = options.latestAnswerContent
			}

			if (!latestAnswerContent)
				latestAnswerContent = dataQuery.get.latestAnswerOnMeasure(item, intersectionSettings);

			if (latestAnswerContent) {
				if (requirement.rule === enums.requirementRule.anyValue) {
					if (!requirement.value)
						fulfills = !!latestAnswerContent;
					else {
						fulfills = !!dataQuery.get.answersOnMeasureAtPeriod(item, requirement.value, intersectionSettings)[0];
					}
				}
				else if (requirement.rule === enums.requirementRule.anyValueWithRelatedContent || requirement.rule === enums.requirementRule.anyValueWithRelatedContentExceptIfNA) {
					if (!requirement.value)
						fulfills = !!latestAnswerContent;
					else {
						answers = dataQuery.get.answersOnMeasureAtPeriod(item, requirement.value, intersectionSettings);
						if (answers.length === 1)
							latestAnswerContent = answers[0];
						else if (answers.length > 1)
							latestAnswerContent = _.sortBy(answers, "createdAt")[answers.length - 1];
						else
							latestAnswerContent = null;

						fulfills = !!latestAnswerContent;
					}

					if (fulfills) {
						if (requirement.rule !== enums.requirementRule.anyValueWithRelatedContentExceptIfNA
							|| !latestAnswerContent.notAvailable
						) {
							fulfills = dataQuery.getIntersectedSubItems(latestAnswerContent, _.assign({
								kind: enums.subItemsKind.relatedContentByUser
							}, intersectionSettings)).length !== 0;

							if (!fulfills)
								attachmentsNeeded = true;
						}
					}
				}
			}

			// When the requirement.rule are any of these then do the logic even if there is no latestAnswerContent
			// because the generated value of previousPeriodValue is needed.
			if (requirement.rule === enums.requirementRule.inPeriod || requirement.rule === enums.requirementRule.inPeriodWithRelatedContent || requirement.rule === enums.requirementRule.inPeriodWithRelatedContentExceptIfNA || requirement.rule === enums.requirementRule.inPeriodWithRelatedContentIfNA) {
				if (requirement.value === "1" || requirement.value === "0") {
					periodSettings = _.get(dataRelation.settings, "measurePeriodSettings") || _.get(dataRelation.originalRelation, "settings.measurePeriodSettings")
					const inPreviousPeriod = requirement.value === "1";

					if (periodSettings) {
						now = new Date();
						year = now.getFullYear();
						month = now.getMonth() + 1;

						switch (periodSettings.frequency) {
							case enums.calendarFrequency.yearly:
								const delta = inPreviousPeriod ? -1 : 0;
								previousPeriodValue = (year + delta).toString();
								break;
							case enums.calendarFrequency.halfYearly:
								if (month < 7)
									previousPeriodValue = inPreviousPeriod
										? (year - 1) + "-07-01|" + (year - 1) + "-12-31"
										: year + "-01-01|" + year + "-06-30";
								else
									previousPeriodValue = inPreviousPeriod
										? year + "-01-01|" + year + "-06-30"
										: year + "-07-01|" + year + "-12-31";
								break;
							case enums.calendarFrequency.quarterly:
								// First quarter, Q1: 1 January – 31 March (90 days or 91 days in leap years)
								// Second quarter, Q2: 1 April – 30 June (91 days)
								// Third quarter, Q3: 1 July – 30 September (92 days)
								// Fourth quarter, Q4: 1 October – 31 December (92 days)
								if (month < 4) // Currently in Q1 (month 1-3)
								{
									previousPeriodValue = inPreviousPeriod ? (year - 1) + "-10-01|" + (year - 1) + "-12-31" : year + "-01-01|" + year + "-03-31"; // Use Q4
								}
								else if (month < 7) // Currently in Q2 (month 4-6)
								{
									previousPeriodValue = inPreviousPeriod ? year + "-01-01|" + year + "-03-31" : year + "-04-01|" + year + "-06-30"; // Use Q1
								}
								else if (month < 10) // Currently in Q3 (month 7-9)
								{
									previousPeriodValue = inPreviousPeriod ? year + "-04-01|" + year + "-06-30" : year + "-07-01|" + year + "-09-30"; // Use Q2
								}
								else // Currently in Q4 (month 10-12)
								{
									previousPeriodValue = inPreviousPeriod ? year + "-07-01|" + year + "-09-30" : year + "-10-01|" + year + "-12-31"; // Use Q3
								}
								break;
							case enums.calendarFrequency.monthly:
								if (inPreviousPeriod) {
									if (month === 1) // If current month is january set it to december previous year
										previousPeriodValue = (year - 1) + "-12-01|" + (year - 1) + "-12-31";
									else {
										month--; // Decrease month
										day = wfMeasureService.getDaysInMonth(month, year) // Get days in month
										month = ("0" + month).slice(-2) // Pad with zero
										day = ("0" + day).slice(-2) // Pad with zero
										previousPeriodValue = year + "-" + month + "-01|" + year + "-" + month + "-" + day;
									}
								}
								else {
									day = wfMeasureService.getDaysInMonth(month, year) // Get days in month
									month = ("0" + month).slice(-2) // Pad with zero
									day = ("0" + day).slice(-2) // Pad with zero
									previousPeriodValue = year + "-" + month + "-01|" + year + "-" + month + "-" + day;
								}
								break;
							case enums.calendarFrequency.custom:
								day = now.getDate();

								currentYear = year + 1;
								minYear = year - 2;
								customPeriods = [];

								concatNowDateInteger = parseInt([ year, ("0" + month).slice(-2), ("0" + day).slice(-2) ].join(""));


								while (currentYear > minYear && !previousPeriodValue) {
									yearBeforeRangeIterations = currentYear;

									for (var i = periodSettings.ranges.length - 1, startYear, startMonth, startDay, endYear, endMonth, endDay, concatEndInteger, concatStartInteger; i >= 0; i--) {
										endYear = currentYear;
										startMonth = periodSettings.ranges[i].startMonth;
										startDay = periodSettings.ranges[i].startDay;

										endMonth = periodSettings.ranges[i].endMonth;
										endDay = periodSettings.ranges[i].endDay;
										name = periodSettings.ranges[i].name;

										if (endMonth < startMonth || (endMonth === startMonth && endDay >= startDay))
											startYear = endYear - 1;
										else
											startYear = endYear;

										currentYear = startYear;

										concatEndInteger = parseInt([ endYear, ("0" + endMonth).slice(-2), ("0" + endDay).slice(-2) ].join(""));
										concatStartInteger = parseInt([ startYear, ("0" + startMonth).slice(-2), ("0" + startDay).slice(-2) ].join(""));

										if (inPreviousPeriod ? concatEndInteger < concatNowDateInteger : concatStartInteger <= concatNowDateInteger) {
											previousPeriodValue =
												[ startYear, ("0" + startMonth).slice(-2), ("0" + startDay).slice(-2) ].join("-")
												+ "|" +
												[ endYear, ("0" + endMonth).slice(-2), ("0" + endDay).slice(-2) ].join("-")
											;
											break;
										}
									}

									if (yearBeforeRangeIterations === currentYear)
										currentYear--;
								}


								break;
						}
					}
					else {
						year = new Date().getFullYear();
						const delta = inPreviousPeriod ? -1 : 0;
						previousPeriodValue = (year + delta).toString();
					}

					if (previousPeriodValue) {
						answers = dataQuery.get.answersOnMeasureAtPeriod(item, previousPeriodValue, intersectionSettings);
						fulfills = answers.length > 0;

						if (fulfills && (requirement.rule === enums.requirementRule.inPeriodWithRelatedContent || requirement.rule === enums.requirementRule.inPeriodWithRelatedContentExceptIfNA || requirement.rule === enums.requirementRule.inPeriodWithRelatedContentIfNA)) {
							if (answers.length === 1)
								latestAnswerContent = answers[0];
							else if (answers.length > 1)
								latestAnswerContent = _.sortBy(answers, "createdAt")[answers.length - 1];
							else
								latestAnswerContent = null;

							let checkRelatedContent = true

							if (requirement.rule === enums.requirementRule.inPeriodWithRelatedContentExceptIfNA) {
								if (latestAnswerContent != null) {
									if (latestAnswerContent.notAvailable) {
										fulfills = true
										checkRelatedContent = false
									}
								}
							}
							else if (requirement.rule === enums.requirementRule.inPeriodWithRelatedContentIfNA) {
								if (latestAnswerContent != null) {
									if (!latestAnswerContent.notAvailable) {
										fulfills = true
										checkRelatedContent = false
									}
								}
							}

							if (checkRelatedContent) {
								fulfills = dataQuery.getIntersectedSubItems(latestAnswerContent, _.assign({
									kind: enums.subItemsKind.relatedContentByUser
								}, intersectionSettings)).length !== 0;
							}

							if (!fulfills) {
								attachmentsNeeded = true
							}
						}
					}
				}
			}

			var
				requiredMeasurePeriod,
				requiredConcatPeriod = previousPeriodValue || requirement.value
			;

			if (requiredConcatPeriod) {
				if (requiredConcatPeriod.indexOf("|") !== -1) {
					requiredMeasurePeriod = { start: requiredConcatPeriod.split("|")[0], end: requiredConcatPeriod.split("|")[1] };
				}
				else { // Assumes yearly
					requiredMeasurePeriod = { start: requiredConcatPeriod + "-01-01", end: requiredConcatPeriod + "-12-31" }
				}
			}

			if (detailedOutput) {
				_.assign(detailedOutput, {
					attachmentsNeeded,
					latestAnswerContent: latestAnswerContent,
					isAnswered: !!latestAnswerContent,
					requiredMeasurePeriod: requiredMeasurePeriod
				});
			}

			if (hasAnyAttachedCertificateExpired(dataRelation, latestAnswerContent, intersectionSettings)) {
				return enums.fulfillmentState.certificateExpired;
			}

			const fulfillmentState = fulfills ? enums.fulfillmentState.fulfilled : enums.fulfillmentState.unfulfilled
			return fulfillmentState;
		}

		function checkLocalParameterFulfillment(item, requirement, intersectionSettings, options) {
			var
				latestAnswerContent,
				fulfills = false
			;

			if (options) {
				latestAnswerContent = options.latestAnswerContent;
			}

			if (requirement.rule === enums.requirementRule.anyValue) {
				fulfills = !!latestAnswerContent;
			}

			return fulfills;
		}


		// items, forOrganizationId, influence
		// items, ticket
		function getActualRequirementOnItems(items) {
			var
				forOrganizationId,
				contextParents,
				networkId,
				ticket,
				influence,
				itemWfids,
				standardRequirements,
				organizationRequirements,
				actualRequirements,
				firstItem,
				itemsMap = [] // { requirement, item, itemWithRequirement }
			;

			if (typeof arguments[1] === "number" && typeof arguments[2] === "object") { // forOrganizationId and influence
				forOrganizationId = arguments[1];
				influence = arguments[2];

				if (influence) {
					if (influence.channelId > 0)
						networkId = influence.channelId;
					if (influence.contextParentWfids)
						contextParents = influence.contextParentWfids.split(",");
				}
			}
			else if (typeof arguments[1] === "object") { // ticket
				ticket = arguments[1];
				forOrganizationId = ticket.organizationId;
				networkId = ticket.networkId;
				contextParents = ticket.contextParents;
			}

			// firstItem = items[0];

			// if (firstItem.composite && firstItem.originalRelation) {
			// 	if (firstItem.originalRelation) {
					_.each(items, function (item) {
						var output, settingsHolder;

						if (item.composite === true) {
							output = {
								itemComposite: item,
								itemContent: item.content,
								dataRelation: null,
								hasRequirement: false,
								intersectionSettings: { organizationId: forOrganizationId }
							};

							if (networkId > 0)
								output.intersectionSettings.networkId = networkId;
							if (contextParents)
								output.intersectionSettings.contextParents = contextParents;

							output.ticket = output.intersectionSettings;

							// If intersected in front-end
							if (item.originalRelation) {
								// output.itemWithRequirement = item.originalRelation;
								output.itemWithRequirement_wfid = item.originalRelation.wfid;
								settingsHolder = item.originalRelation;
								output.source = "original relation on composite";
							}
							else if (item.dataRelation) {
								// If intersected in back-end
								if (item.dataRelation.type == enums.objectType.virtualDataRelation && typeof item.dataRelation.originalRelationWfid === "string") {
									// output.itemWithRequirement = item.dataRelation.originalRelation;
									output.itemWithRequirement_wfid = item.dataRelation.originalRelationWfid
									settingsHolder = item.dataRelation.originalRelation;
									output.source = "original relation on virtual";

								}
								else {
									output.itemWithRequirement_wfid = item.dataRelation.wfid;
									settingsHolder = item.dataRelation;
									output.source = "relation";
								}
							}

							if (item.content && item.content.type === enums.objectType.finding) {
								if (item.content.locked) // If finding is locked then ignore it
									return;

								output.itemWithRequirement_wfid = item.content.wfid;
								output.source += " + finding";
							}
						}

						if (settingsHolder && settingsHolder.settings && settingsHolder.settings.contextParentWfids) {
							output.intersectionSettings.contextParents = settingsHolder.settings.contextParentWfids;
						}

						output.dataRelation = settingsHolder;

						itemsMap.push(output);
					});
				// }
			// }

			itemWfids = _.map(itemsMap, "itemWithRequirement_wfid");

			standardRequirements = wfObject.filter({ where: {
				type: enums.objectType.requirement,
				organizationId: null,
				wffid_requirement: { "in": itemWfids }
			}});
			organizationRequirements = wfObject.filter({ where: {
				type: enums.objectType.requirement,
				organizationId: forOrganizationId,
				wffid_requirement: { "in": itemWfids }
			}});

			_.each(itemsMap, function (item) {
				var actualReq, standardReq, orgReq;

				standardReq = _.find(standardRequirements, { wffid_requirement: item.itemWithRequirement_wfid });
				orgReq = _.find(organizationRequirements, { wffid_requirement: item.itemWithRequirement_wfid });

				if (orgReq)
					actualReq = orgReq;
				else
					actualReq = standardReq;

				// actualRequirements.push(actualReq)

				if (actualReq) {
					item.requirement = actualReq;
					item.hasRequirement = true;
					// console.log(item.itemWithRequirement_wfid, item.source, item )
				}

				if (item.itemComposite) {
					item.itemComposite.requirement = actualReq;
				}
			});

			_.remove(itemsMap, { hasRequirement: false });

			return itemsMap;
		}

		function getActualRequirement(options) {
			// options = { itemContent, itemRelation, itemComposite, organizationId }

			var requirement, organizationId = options.organizationId;

			// console.log(options)

			if (options.itemComposite && options.itemComposite.originalRelation) {
				requirement = options.itemComposite.originalRelation.getRequirement(organizationId);
				// console.log("actualReq itemComposite.originalRelation", options.itemComposite.originalRelation);
			}
			else if (options.itemRelation) {
				requirement = options.itemRelation.getRequirement(organizationId)
				// console.log("actualReq itemRelation", options.itemRelation);
			}
			else if (options.itemContent) {
				requirement = options.itemContent.getRequirement(organizationId)
				// console.log("actualReq itemContent", options.itemContent);
			}

			return requirement;
		}

		function getActualRequirementSpecification(options) {
			// options = { itemContent, itemRelation, itemComposite, organizationId }

			var requirement, organizationId = options.organizationId;

			if (options.itemComposite && options.itemComposite.originalRelation) {
				requirement = options.itemComposite.originalRelation.getRequirementSpecification(organizationId);
				// console.log("actualSpec itemComposite.originalRelation", options.itemComposite.originalRelation);
			}
			else if (options.itemRelation) {
				requirement = options.itemRelation.getRequirementSpecification(organizationId);
				// console.log("actualSpec itemRelation", options.itemRelation);
			}
			else if (options.itemContent) {
				requirement = options.itemContent.getRequirementSpecification(organizationId);
				// console.log("actualSpec itemContent", options.itemContent);
			}

			return requirement;
		}

		function getMainStructureSettings(organizationId, uiMode, $scope) {
			return {
				uiMode: uiMode,
				forOrganizationId: organizationId,
				requirementOptions: [
					{
						name: translate("modules.valueChain.requirements.None"), // "Inget",
						rule: 0,
						value: null
					},
					{
						name: translate("modules.valueChain.requirements.AllQuestionsFulfilled"), // "Samtliga frågor måste uppfyllas",
						rule: 9,
						value: null
					},
					{
						name: translate("modules.valueChain.requirements.AllQuestionsAnswered"), // "Alla frågor måste besvaras",
						rule: 9,
						value: "0"
					}
				],
				onUpdated: function (updatedRequirement) {
					// $timeout();

					if ($scope) {
						$scope.$broadcast("requirementChanged", updatedRequirement);
					}

					// calculateFulfillment();
				}
			};
		}

		function getTaskStructureSettings(organizationId, uiMode, $scope, includeExtendedOptions) {
			var output = {
				uiMode: uiMode,
				forOrganizationId: organizationId,
				requirementOptions: [
					{
						name: translate("modules.valueChain.requirements.None"), // "Inget",
						rule: 0,
						value: null
					},
					{
						name: translate("modules.valueChain.requirements.ManualAssessment"), // "Manuell bedömning",
						rule: enums.requirementRule.manual,
						value: null
					},
					{
						name: translate("modules.valueChain.requirements.MinimumOneObjectWithAttachments"),
						rule: enums.requirementRule.anyValue,
						value: null
					},
					{
						name: translate("modules.valueChain.requirements.MinimumOneObject"),
						rule: enums.requirementRule.anyValue,
						value: "1"
					}
				],
				onUpdated: function (updatedRequirement) {
					// $timeout();

					if ($scope) {
						$scope.$broadcast("requirementChanged", updatedRequirement);
					}

					// calculateFulfillment();
				}
			};

			if (includeExtendedOptions) {
				output.requirementOptions.push({
					name: "Specific item",
					rule: 7
				});

				// output.requirementOptions.push({
				// 	name: translate("modules.valueChain.requirements.Span"),
				// 	rule: enums.requirementRule.span
				// });
			}

			return output;
		}



		function getFindingSettings(organizationId, uiMode, $scope, includeExtendedOptions) {
			var output = {
				uiMode: uiMode,
				forOrganizationId: organizationId,
				requirementOptions: [
					{
						name: translate("modules.valueChain.requirements.None"), // "Inget",
						rule: 0,
						value: null
					},
					{
						name: translate("modules.valueChain.requirements.ManualAssessment"), // "Manuell bedömning",
						rule: enums.requirementRule.manual,
						value: null
					}
				],
				onUpdated: function (updatedRequirement) {
					// $timeout();

					if ($scope) {
						$scope.$broadcast("requirementChanged", updatedRequirement);
					}

					// calculateFulfillment();
				}
			};

			return output;
		}

		function getUtilityStructureSettings(organizationId, uiMode, $scope, includeExtendedOptions) {
			var output = {
				uiMode: uiMode,
				forOrganizationId: organizationId,
				requirementOptions: [
					{
						name: translate("modules.valueChain.requirements.None"),
						rule: 0,
						value: null
					},
					{
						name: translate("modules.valueChain.requirements.Span"),
						rule: enums.requirementRule.span
					},
					{
						name: translate("modules.valueChain.requirements.TargetOrganizationCountry"),
						rule: enums.requirementRule.specificValues,
						ruleInstruction: 1,
						specificItemOptionsLoader: {
							load: (networkId) => {
								return $q((resolve, reject) => {
									const promise = dataOps.getSubItems("71-13804", enums.subItemsKind.children); // Load all available countries

									promise.then(function (relations) {
										const items = _.map(relations, function (dataRelation) {
											return {
												wfid: dataRelation.wfcid,
												name: dataRelation.childContent.getMainTextual()
											}
										});
										resolve(items)
									})
								})
							}
						}
					},
					{
						name: translate("modules.valueChain.requirements.TargetOrganizationInNetworkCategoryID"),
						rule: enums.requirementRule.specificValues,
						ruleInstruction: 2,
						specificItemOptionsLoader: {
							load: (networkId) => {
								return $q((resolve, reject) => {
									if (!networkId) {
										resolve([])
										return;
									}
									valueChainService.loadCategoriesInNetwork(networkId).then(function (relations) {
										const categories = _.map(relations, "childContent");
										const items = _.map(categories, function (category) {
											return {
												wfid: category.wfid,
												name: category.getMainTextual()
											}
										});
										resolve(items)
									})

								})
							}
						}
					}
				]
			};

			return output;
		}

		function getMeasureSettingsFromPeriodSettings(organizationId, uiMode, $scope, periodSettings, periodSettingsSourceObject) {
			var
				anyAnswer_word = translate("modules.valueChain.requirements.AnyAnswer"),
				anyAnswerWithAttechedInfo_word = translate("modules.valueChain.requirements.AnyAnswerWithMoreInfo"),
				anyAnswerWithAttechedInfoExceptIfNA_word = translate("modules.valueChain.requirements.AnyAnswerSpecificPeriodWithMoreInformationExceptIfNA"),
				output = {
					uiMode: uiMode,
					forOrganizationId: organizationId,
					requirementOptions: [
						{
							name: translate("modules.valueChain.requirements.None"), // "Inget",
							rule: 0,
							value: null
						},
						{
							name: translate("modules.valueChain.requirements.ManualAssessment"), // "Manuell bedömning",
							rule: enums.requirementRule.manual,
							value: null
						},
						{
							name: translate("modules.valueChain.requirements.AnyAnswer"), // "Valfritt svar",
							rule: enums.requirementRule.anyValue,
							value: null
						},
						{
							name: translate("modules.valueChain.requirements.AnyAnswerPreviousPeriod"),
							rule: enums.requirementRule.inPeriod,
							value: "1" // PreviousPeriod
						},
						{
							name: translate("modules.valueChain.requirements.AnyAnswerPreviousPeriodWithMoreInformation"),
							rule: enums.requirementRule.inPeriodWithRelatedContent,
							value: "1" // PreviousPeriod
						},
						{
							name: translate("modules.valueChain.requirements.AnyAnswerPreviousPeriodWithMoreInformationExceptIfNA"),
							rule: enums.requirementRule.inPeriodWithRelatedContentExceptIfNA,
							value: "1" // PreviousPeriod
						},
						{
							name: translate("modules.valueChain.requirements.AnyAnswerPreviousPeriodWithMoreInformationIfNA"),
							rule: enums.requirementRule.inPeriodWithRelatedContentIfNA,
							value: "1" // PreviousPeriod
						},
						{
							name: translate("modules.valueChain.requirements.AnyAnswerCurrentPeriod"),
							rule: enums.requirementRule.inPeriod,
							value: "0" // CurrentPeriod
						},
						{
							name: translate("modules.valueChain.requirements.AnyAnswerCurrentPeriodWithMoreInformation"),
							rule: enums.requirementRule.inPeriodWithRelatedContent,
							value: "0" // CurrentPeriod
						},
						{
							name: translate("modules.valueChain.requirements.AnyAnswerCurrentPeriodWithMoreInformationExceptIfNA"),
							rule: enums.requirementRule.inPeriodWithRelatedContentExceptIfNA,
							value: "0" // CurrentPeriod
						},
						{
							name: translate("modules.valueChain.requirements.AnyAnswerCurrentPeriodWithMoreInformationIfNA"),
							rule: enums.requirementRule.inPeriodWithRelatedContentIfNA,
							value: "0" // CurrentPeriod
						},
					],
					onUpdated: function (updatedRequirement) {
						if ($scope) {
							// console.log('onUpdated: $scope.$broadcast("requirementChanged", updatedRequirement)', updatedRequirement);
							$scope.$broadcast("requirementChanged", updatedRequirement);
						}
					}
				}
			;

			var periodOptions = _.map(wfMeasureService.generatePeriods(periodSettings, { minYear: 2010 }), function (period) {
				var
					option
				;

				option = {
					name: anyAnswer_word + " (" + period.name + ")",
					periodName: period.name,
					rule: enums.requirementRule.anyValue,
					value: period.frequency === enums.calendarFrequency.yearly ? period.year.toString() : period.startDate + "|" + period.endDate,
					periodFrequency: periodSettings.frequency,
					periodStartDate: period.startDate,
					periodEndDate: period.endDate,
					intervalNameSpecification: period.nameSpecification,
					periodSettingsObjectWfid: periodSettingsSourceObject ? periodSettingsSourceObject.wfid : null
				}

				return option;
			});

			var periodOptions_withAttachedInformation = _.chain(periodOptions).cloneDeep().map(function (option) {
				option.name = anyAnswerWithAttechedInfo_word +  " (" + option.periodName + ")";
				option.rule = enums.requirementRule.anyValueWithRelatedContent;

				return option
			}).value();

			var periodOptions_withAttachedInformationExceptIfNA = _.chain(periodOptions).cloneDeep().map(function (option) {
				option.name = anyAnswerWithAttechedInfoExceptIfNA_word +  " (" + option.periodName + ")";
				option.rule = enums.requirementRule.anyValueWithRelatedContentExceptIfNA;

				return option
			}).value();

			Array.prototype.push.apply(output.requirementOptions, periodOptions);
			Array.prototype.push.apply(output.requirementOptions, periodOptions_withAttachedInformation);
			Array.prototype.push.apply(output.requirementOptions, periodOptions_withAttachedInformationExceptIfNA);

			return output;
		}

		function getMeasureSettings(organizationId, uiMode, $scope, includeExtendedOptions) {
			let
				anyAnswer_word = translate("modules.valueChain.requirements.AnyAnswer"),
				anyAnswerWithAttechedInfo_word = translate("modules.valueChain.requirements.AnyAnswerWithMoreInfo"),
				anyAnswerWithAttechedInfoExceptIfNA_word = translate("modules.valueChain.requirements.AnyAnswerSpecificPeriodWithMoreInformationExceptIfNA")
			;

			const periodOptions = _.map(wfMeasureService.generatePeriods({ frequency: 1 }, { minYear: 2000 }), function (period) {
				return {
					name: anyAnswer_word + " (" + period.name + ")",
					periodName: period.name,
					rule: enums.requirementRule.anyValue,
					value: period.year.toString(),
				}
			});

			const periodOptions_withAttachedInformation = _.chain(periodOptions).cloneDeep().map(function (option) {
				option.name = anyAnswerWithAttechedInfo_word +  " (" + option.periodName + ")";
				option.rule = enums.requirementRule.anyValueWithRelatedContent;

				return option
			}).value();

			const periodOptions_withAttachedInformationExceptIfNA = _.chain(periodOptions).cloneDeep().map(function (option) {
				option.name = anyAnswerWithAttechedInfoExceptIfNA_word +  " (" + option.periodName + ")";
				option.rule = enums.requirementRule.anyValueWithRelatedContentExceptIfNA;

				return option
			}).value();

			let requirementOptions = [
				{
					name: translate("modules.valueChain.requirements.None"), // "Inget",
					rule: 0,
					value: null
				},
				{
					name: translate("modules.valueChain.requirements.ManualAssessment"), // "Manuell bedömning",
					rule: enums.requirementRule.manual,
					value: null
				},
				{
					name: translate("modules.valueChain.requirements.AnyAnswer"), // "Valfritt svar",
					rule: enums.requirementRule.anyValue,
					value: null
				},
				{
					name: translate("modules.valueChain.requirements.AnyAnswerPreviousPeriod"),
					rule: enums.requirementRule.inPeriod,
					value: "1" // PreviousPeriod
				},
				{
					name: translate("modules.valueChain.requirements.AnyAnswerPreviousPeriodWithMoreInformation"),
					rule: enums.requirementRule.inPeriodWithRelatedContent,
					value: "1" // PreviousPeriod
				},
				{
					name: translate("modules.valueChain.requirements.AnyAnswerPreviousPeriodWithMoreInformationExceptIfNA"),
					rule: enums.requirementRule.inPeriodWithRelatedContentExceptIfNA,
					value: "1" // PreviousPeriod
				},
				{
					name: translate("modules.valueChain.requirements.AnyAnswerPreviousPeriodWithMoreInformationIfNA"),
					rule: enums.requirementRule.inPeriodWithRelatedContentIfNA,
					value: "1" // PreviousPeriod
				},
				{
					name: translate("modules.valueChain.requirements.AnyAnswerCurrentPeriod"),
					rule: enums.requirementRule.inPeriod,
					value: "0" // CurrentPeriod
				},
				{
					name: translate("modules.valueChain.requirements.AnyAnswerCurrentPeriodWithMoreInformation"),
					rule: enums.requirementRule.inPeriodWithRelatedContent,
					value: "0" // CurrentPeriod
				},
				{
					name: translate("modules.valueChain.requirements.AnyAnswerCurrentPeriodWithMoreInformationExceptIfNA"),
					rule: enums.requirementRule.inPeriodWithRelatedContentExceptIfNA,
					value: "0" // CurrentPeriod
				},
				{
					name: translate("modules.valueChain.requirements.AnyAnswerCurrentPeriodWithMoreInformationIfNA"),
					rule: enums.requirementRule.inPeriodWithRelatedContentIfNA,
					value: "0" // CurrentPeriod
				}
			];

			Array.prototype.push.apply(requirementOptions, periodOptions)
			Array.prototype.push.apply(requirementOptions, periodOptions_withAttachedInformation)
			Array.prototype.push.apply(requirementOptions, periodOptions_withAttachedInformationExceptIfNA)

			return {
				uiMode: uiMode,
				forOrganizationId: organizationId,
				requirementOptions: _.map(requirementOptions, function (item) {
					if (item.value) {
						item.periodFrequency = enums.calendarFrequency.yearly;
						item.periodStartDate = item.value + "-01-01";
						item.periodEndDate = item.value + "-12-31";
						item.intervalNameSpecification = undefined;
						item.periodSettingsObjectWfid = undefined;
						item.periodName = item.value;
					}

					return item;
				}),
				onUpdated: function (updatedRequirement) {
					// $timeout();

					// calculateFulfillment();

					if ($scope) {
						// console.log('onUpdated: $scope.$broadcast("requirementChanged", updatedRequirement)', updatedRequirement);
						$scope.$broadcast("requirementChanged", updatedRequirement);
					}
				}
			};
		}

		function getQuestionSettings(organizationId, uiMode, $scope, includeExtendedOptions) {
			var output = {
				uiMode: uiMode,
				forOrganizationId: organizationId,
				requirementOptions: [
					{
						name: translate("modules.valueChain.requirements.None"), // "Inget",
						rule: 0,
						value: null
					},
					{
						name: translate("modules.valueChain.requirements.ManualAssessment"), // "Manuell bedömning",
						rule: enums.requirementRule.manual,
						value: null
					},
					{
						name: translate("modules.valueChain.requirements.AnyAnswer"), // "Valfritt svar",
						rule: enums.requirementRule.anyValue,
						value: null
					},
					{
						name: translate("modules.valueChain.requirements.Yes"), // "Ja",
						rule: enums.requirementRule.specificValues,
						value: "4",
						selectable: false
					},
					{
						name: translate("modules.valueChain.requirements.No"), // "Nej",
						rule: enums.requirementRule.specificValues,
						value: "3",
						selectable: false
					},
					{
						name: translate("modules.valueChain.requirements.YesWithMoreInfo"), // "Ja med mer information",
						rule: enums.requirementRule.specificValueWithRelatedContent,
						value: "4",
						selectable: false
					},
					{
						name: translate("modules.valueChain.requirements.YesOrNotRelevant"), // "Ja eller Ej relevant",
						rule: enums.requirementRule.specificValues,
						value: "4,2",
						selectable: false
					},
					{
						name: translate("modules.valueChain.requirements.NoOrNotRelevant"), // "Nej eller Ej relevant",
						rule: enums.requirementRule.specificValues,
						value: "3,2",
						selectable: false
					},
					{
						name: translate("modules.valueChain.requirements.Yes_OtherwiseCommentIsRequired"), // "Ja (Annars krävs kommentar)",
						rule: enums.requirementRule.preferredValue,
						value: "4",
						selectable: false
					},
					{
						name: translate("modules.valueChain.requirements.No_OtherwiseCommentIsRequired"), // "Nej (Annars krävs kommentar)",
						rule: enums.requirementRule.preferredValue,
						value: "3",
						selectable: false
					},
					{
						name: translate("modules.valueChain.requirements.YesOrNotRelevant_OtherwiseCommentIsRequired"), // "Ja eller Ej relevant (Annars krävs kommentar)",
						rule: enums.requirementRule.preferredValue,
						value: "4,2",
						selectable: false
					},
					{
						name: translate("modules.valueChain.requirements.NoOrNotRelevant_OtherwiseCommentIsRequired"), // "Nej eller Ej relevant (Annars krävs kommentar)",
						rule: enums.requirementRule.preferredValue,
						value: "3,2",
						selectable: false
					},
					{
						name: translate("modules.valueChain.requirements.AnyAnswer_YesRequiresMoreInfo"), // "Valfritt svar (Vid Ja krävs mer information)",
						rule: enums.requirementRule.preferredValueWithRelatedContent,
						value: "4",
						selectable: false
					},
					{
						name: translate("modules.valueChain.requirements.AnyAnswer_NoRequiresMoreInfo"), // "Valfritt svar (Vid Nej krävs mer information)",
						rule: enums.requirementRule.preferredValueWithRelatedContent,
						value: "3",
						selectable: false
					},
					valueChainService.buildAnswerTypesOptionObject([ 4, 3 ], { preferredValueWithRelatedContent: true }),
					{
						name: translate("modules.valueChain.requirements.AnyAnswerWithMoreInfo"), // "Valfritt svar med mer information",
						rule: enums.requirementRule.anyValueWithRelatedContent,
						value: null,
						selectable: false
					},
					{
						name: translate("modules.valueChain.requirements.AnyAnswer_AccreditedRequiresMoreInfo"),
						rule: enums.requirementRule.preferredValueWithRelatedContent,
						value: "5",
						selectable: false
					},
					{
						name: translate("modules.valueChain.requirements.AnyAnswer_NotCompliantRequiresMoreInfo"),
						rule: enums.requirementRule.preferredValueWithRelatedContent,
						value: "7",
						selectable: false
					},
					valueChainService.buildAnswerTypesOptionObject([ 1 ]),
					valueChainService.buildAnswerTypesOptionObject([ 2 ]),
					{
						name: translate("modules.valueChain.requirements.NotRelevantWithMoreInfo"), // "Ej relevant med mer information",
						rule: enums.requirementRule.specificValueWithRelatedContent,
						value: "2"
					},
					valueChainService.buildAnswerTypesOptionObject([ 5 ]),
					{
						name: translate("modules.valueChain.requirements.AccreditedWithMoreInfo"),
						rule: enums.requirementRule.specificValueWithRelatedContent,
						value: "5"
					},
					valueChainService.buildAnswerTypesOptionObject([ 6 ]),
					valueChainService.buildAnswerTypesOptionObject([ 7 ]),
					valueChainService.buildAnswerTypesOptionObject([ 8 ]),
					valueChainService.buildAnswerTypesOptionObject([ 9 ]),
					valueChainService.buildAnswerTypesOptionObject([ 10 ]),
					valueChainService.buildAnswerTypesOptionObject([ 11 ]),
					valueChainService.buildAnswerTypesOptionObject([ 13 ]),
					valueChainService.buildAnswerTypesOptionObject([ 14 ]),
					valueChainService.buildAnswerTypesOptionObject([ 15 ]),
					valueChainService.buildAnswerTypesOptionObject([ 20 ]),
					valueChainService.buildAnswerTypesOptionObject([ 21 ]),
					valueChainService.buildAnswerTypesOptionObject([ 22 ]),
					{
						name: translate("modules.valueChain.requirements.AnyAnswer_NoOrPartlyRequiresMoreInfo"),
						rule: enums.requirementRule.preferredValueWithRelatedContent,
						value: "3,20"
					},
					{
						name: translate("modules.valueChain.requirements.AnyAnswer_YesOrPartlyRequiresMoreInfo"),
						rule: enums.requirementRule.preferredValueWithRelatedContent,
						value: "4,20"
					},
					valueChainService.buildAnswerTypesOptionObject([ 24 ]),
					valueChainService.buildAnswerTypesOptionObject([ 25 ]),
					valueChainService.buildAnswerTypesOptionObject([ 26 ]),
					valueChainService.buildAnswerTypesOptionObject([ 25, 26 ], { preferredValueWithRelatedContent: true }),
					// Remember to also add new items in valueChainPackageEditor.component.js
				],
				onUpdated: function (updatedRequirement) {
					// $timeout();

					// calculateFulfillment();
					if ($scope) {
						// console.log('onUpdated: $scope.$broadcast("requirementChanged", updatedRequirement)', updatedRequirement);
						$scope.$broadcast("requirementChanged", updatedRequirement);
					}
				}
			};

			if (includeExtendedOptions) {
				output.requirementOptions.push({
					name: translate("modules.valueChain.requirements.Unanswered"),
					rule: enums.requirementRule.noValue,
					value: null
				});
			}

			return output;
		}

		function getParameterSettings(organizationId, uiMode, $scope) {
			return {
				uiMode: uiMode,
				forOrganizationId: organizationId,
				requirementOptions: [
					{
						name: translate("modules.valueChain.requirements.None"), // "Inget",
						rule: 0,
						value: null
					},
					{
						name: translate("modules.valueChain.requirements.ManualAssessment"), // "Manuell bedömning",
						rule: enums.requirementRule.manual,
						value: null
					},
					{
						name: translate("modules.valueChain.requirements.AnyAnswer"), // "Valfritt svar",
						rule: enums.requirementRule.anyValue,
						value: null
					}
				],
				onUpdated: function (updatedRequirement) {
					// $timeout();

					if ($scope) {
						$scope.$broadcast("requirementChanged", updatedRequirement);
					}

					// calculateFulfillment();
				}
			};
		}

		function maybeSetAssessmentNeedOnItem(itemComposite, options) { // options = { influence, forceFulfillmentState }
			const requirement = itemComposite.requirement;

			return $q((resolve, reject) => {
				if (requirement && requirement.rule === enums.requirementRule.manual) {
					const { influence, forceFulfillmentState } = options || {};

					if (!influence) {
						console.error("An influence is required when setting fulfillment state manually")
						reject();
					}

					if (fulfillmentCreator_xhrRequestByRequirementWfid[requirement.wfid]) {
						fulfillmentCreator_xhrRequestByRequirementWfid[requirement.wfid].abort();
					}

					fulfillmentCreator_xhrRequestByRequirementWfid[requirement.wfid] = apiProxy.raw("fulfillment.setFulfillment", {
						influenceId: influence.id,
						requirementId: requirement.id,
						fulfillmentState: forceFulfillmentState || enums.fulfillmentState.assessmentNeeded,

						objectType: itemComposite.type,
						objectId: itemComposite.id
					});

					fulfillmentCreator_xhrRequestByRequirementWfid[requirement.wfid].then(function (fulfillment) {
						// If the fulfillment is undefined it means that the fulfillment was unchanged from what it was before
						if (fulfillment) {
							const fulfillmentTargetItem = wfObject.filter({ where: { type: fulfillment.objectType, id: fulfillment.objectId } })[0];
							wfObject.inject(fulfillment);

							if (fulfillmentTargetItem)
								fulfillmentTargetItem.fulfillmentWfid = fulfillment.wfid;

						}
						resolve();
					});
				}
				else {
					resolve();
				}
			});
		}

		function influenceFulfillmentCalculator(influence) {
			var
				self = {
					calculate,
					xhrRequest: undefined,
					calculating: false
				}
			;

			return self

			function calculate(hasSigned, onCalculated) {
				self.calculating = true;
				$timeout();

				if (self.xhrRequest) {
					self.xhrRequest.abort();
					self.xhrRequest = undefined;
				}

				self.xhrRequest = apiProxy.raw("fulfillment.calculate", {
					item: dataOps.prepareWfObject(influence),
					hasSigned: hasSigned
				});

				self.xhrRequest.then(function (fulfillmentResult) {
					self.xhrRequest = undefined;
					influence.fulfilled = fulfillmentResult.fulfillment.fulfills;
					influence.fulfillmentProgress = fulfillmentResult.fulfillment.fulfillmentProgress;
					influence.fulfillmentProgressTotal = fulfillmentResult.fulfillment.fulfillmentProgressTotal;
					influence.fulfillmentProgressPercentage = fulfillmentResult.fulfillment.fulfillmentProgressPercentage;
					influence.containsSpecialRequirements = fulfillmentResult.fulfillment.containsSpecialRequirements;
					influence.fulfillmentState = fulfillmentResult.fulfillment.fulfillmentState;
					influence.isAssessmentNeeded = fulfillmentResult.fulfillment.isAssessmentNeeded;
					influence.isReportingNeeded = fulfillmentResult.fulfillment.isReportingNeeded;
					influence.fulfillmentStatistics = fulfillmentResult.influence.fulfillmentStatistics;

					if (typeof onCalculated === "function") {
						onCalculated(influence, fulfillmentResult);
					}

					$timeout(function () {
						if (!self.xhrRequest)
							self.calculating = false;
					}, 700);
				});
			}
		}

		function getRequirementText(itemContent, dataRelation, requirement) {
			const requirementRule = requirement.rule;
			const requirementValue = requirement.value;
			const requirementConcatKey = `${itemContent.type}__${requirementRule}__${requirementValue}`;
			const rulesAsString = {
				[enums.requirementRule.specificValues]: "specificValues",
				[enums.requirementRule.specificValueWithRelatedContent]: "specificValueWithRelatedContent",
				[enums.requirementRule.preferredValue]: "preferredValue",
				[enums.requirementRule.preferredValueWithRelatedContent]: "preferredValueWithRelatedContent"
			}
			var output;

			if (!(requirementConcatKey in cachedRequirementTexts)) {
				if (requirementRule === enums.requirementRule.manual) {
					output = $translate.instant("modules.valueChain.requirements.ManualAssessment")
				}
				else {

					if (itemContent.type === enums.objectType.structure) {
						if (requirementRule === enums.requirementRule.anyValue) {
							if (requirementValue === "1") {
								output = $translate.instant("modules.valueChain.requirements.MinimumOneObject")
							}
							else {
								output = $translate.instant("modules.valueChain.requirements.MinimumOneObjectWithAttachments")
							}
						}
					}

					if (itemContent.type === enums.objectType.measure) {
						let needPeriodValue = false;

						if (requirementRule === enums.requirementRule.anyValue) {
							needPeriodValue = !!requirementValue;
							output = $translate.instant("modules.valueChain.requirements.AnyAnswer")
						}
						else if (requirementRule === enums.requirementRule.anyValueWithRelatedContent) {
							needPeriodValue = !!requirementValue;
							output = $translate.instant("modules.valueChain.requirements.AnyAnswerWithMoreInfo")
						}
						else if (requirementRule === enums.requirementRule.anyValueWithRelatedContentExceptIfNA) {
							needPeriodValue = !!requirementValue;
							output = $translate.instant("modules.valueChain.requirements.AnyAnswerSpecificPeriodWithMoreInformationExceptIfNA")
						}
						else if (requirementRule === enums.requirementRule.inPeriod && requirementValue === "1") {
							output = $translate.instant("modules.valueChain.requirements.AnyAnswerPreviousPeriod")
						}
						else if (requirementRule === enums.requirementRule.inPeriodWithRelatedContent && requirementValue === "1") {
							output = $translate.instant("modules.valueChain.requirements.AnyAnswerPreviousPeriodWithMoreInformation")
						}
						else if (requirementRule === enums.requirementRule.inPeriodWithRelatedContentExceptIfNA && requirementValue === "1") {
							output = $translate.instant("modules.valueChain.requirements.AnyAnswerPreviousPeriodWithMoreInformationExceptIfNA")
						}
						else if (requirementRule === enums.requirementRule.inPeriodWithRelatedContentIfNA && requirementValue === "1") {
							output = $translate.instant("modules.valueChain.requirements.AnyAnswerPreviousPeriodWithMoreInformationIfNA")
						}
						else if (requirementRule === enums.requirementRule.inPeriod && requirementValue === "0") {
							output = $translate.instant("modules.valueChain.requirements.AnyAnswerCurrentPeriod")
						}
						else if (requirementRule === enums.requirementRule.inPeriodWithRelatedContent && requirementValue === "0") {
							output = $translate.instant("modules.valueChain.requirements.AnyAnswerCurrentPeriodWithMoreInformation")
						}
						else if (requirementRule === enums.requirementRule.inPeriodWithRelatedContentExceptIfNA && requirementValue === "0") {
							output = $translate.instant("modules.valueChain.requirements.AnyAnswerCurrentPeriodWithMoreInformationExceptIfNA")
						}
						else if (requirementRule === enums.requirementRule.inPeriodWithRelatedContentIfNA && requirementValue === "0") {
							output = $translate.instant("modules.valueChain.requirements.AnyAnswerCurrentPeriodWithMoreInformationIfNA")
						}

						if (needPeriodValue) {
							let
								periodSettingsResult = wfMeasureService.getMeasurePeriodSettings(dataRelation),
								{ measurePeriodSettings, periodSettingsObjectWfid } = periodSettingsResult
							;

							let periodName = wfMeasureService.formatPeriodNameFromRequirement(requirement, measurePeriodSettings);
							output += ` (${periodName})`;
						}
					}

					if (itemContent.type === enums.objectType.question) {

						if (requirementRule === enums.requirementRule.specificValues
							|| requirementRule === enums.requirementRule.specificValueWithRelatedContent
							|| requirementRule === enums.requirementRule.preferredValue
							|| requirementRule === enums.requirementRule.preferredValueWithRelatedContent
						) {
							let joinedAnswerTypesText = buildAnswerTypesText(requirementValue.split(","))
							output = $translate.instant("modules.valueChain.requirements." + rulesAsString[requirementRule], { answertypes: joinedAnswerTypesText })
						}
						else if (requirementRule === enums.requirementRule.anyValue) {
							output = $translate.instant("modules.valueChain.requirements.AnyAnswer")
						}
						else if (requirementRule === enums.requirementRule.anyValueWithRelatedContent) {
							output = $translate.instant("modules.valueChain.requirements.AnyAnswerWithMoreInfo")
						}
					}
				}

				cachedRequirementTexts[requirementConcatKey] = output;
			}

			return cachedRequirementTexts[requirementConcatKey];

			function buildAnswerTypesText(questionAnswerTypeIds) {
				var texts = _.chain(questionAnswerTypeIds).map(function (id) {
					return wfPropertyExtractor.getQuestionAnswerTypeText(id);
				}).value();

				if (texts.length == 1)
					return texts[0];
				else
					return _.take(texts, texts.length - 1).join(", ") + " " + $translate.instant("or") + " " + texts[texts.length - 1];
			}

		}

		function getFulfillmentStateText(fulfillmentState) {
			return fulfillmentStateTexts[fulfillmentState];
		}

		function getTaskStructureRequirementSettings (influenceToOrganizationId){
			const taskStructureRequirementSettings = {
				uiMode: enums.uiMode.admin,
				forOrganizationId: influenceToOrganizationId,
				requirementOptions: [
					{
						name: $translate.instant("modules.valueChain.requirements.None"),
						rule: 0,
						value: null
					},
					{
						name: $translate.instant("modules.valueChain.requirements.ManualAssessment"), // "Manuell bedömning",
						rule: enums.requirementRule.manual,
						value: null
					},
					{
						name: $translate.instant("modules.valueChain.requirements.MinimumOneObjectWithAttachments"),
						rule: enums.requirementRule.anyValue,
						value: null
					},
					{
						name: $translate.instant("modules.valueChain.requirements.MinimumOneObject"),
						rule: enums.requirementRule.anyValue,
						value: "1"
					}
				],
				onUpdated: function (updatedRequirement) {
					$timeout();
				}
			}

			return taskStructureRequirementSettings;
		}

		function getQuestionRequirementSettings (influenceToOrganizationId, forSpecificOrganization) {
			const questionRequirementSettings = {
				uiMode: enums.uiMode.admin,
				forOrganizationId: influenceToOrganizationId,
				requirementOptions: [
					{
						name: $translate.instant("modules.valueChain.requirements.None"), // "Inget",
						rule: 0,
						value: null
					},
					{
						name: $translate.instant("modules.valueChain.requirements.ManualAssessment"), // "Manuell bedömning",
						rule: enums.requirementRule.manual,
						value: null
					},
					{
						name: $translate.instant("modules.valueChain.requirements.AnyAnswer"), // "Valfritt svar",
						rule: enums.requirementRule.anyValue,
						value: null
					},
					{
						name: $translate.instant("modules.valueChain.requirements.Yes"), // "Ja",
						rule: enums.requirementRule.specificValues,
						value: "4",
						selectable: !forSpecificOrganization
					},
					{
						name: $translate.instant("modules.valueChain.requirements.No"), // "Nej",
						rule: enums.requirementRule.specificValues,
						value: "3",
						selectable: !forSpecificOrganization
					},
					{
						name: $translate.instant("modules.valueChain.requirements.YesWithMoreInfo"), // "Ja med mer information",
						rule: enums.requirementRule.specificValueWithRelatedContent,
						value: "4",
						selectable: !forSpecificOrganization
					},
					{
						name: $translate.instant("modules.valueChain.requirements.YesOrNotRelevant"), // "Ja eller Ej relevant",
						rule: enums.requirementRule.specificValues,
						value: "4,2",
						selectable: !forSpecificOrganization
					},
					{
						name: $translate.instant("modules.valueChain.requirements.NoOrNotRelevant"), // "Nej eller Ej relevant",
						rule: enums.requirementRule.specificValues,
						value: "3,2",
						selectable: !forSpecificOrganization
					},
					{
						name: $translate.instant("modules.valueChain.requirements.Yes_OtherwiseCommentIsRequired"), // "Ja (Annars krävs kommentar)",
						rule: enums.requirementRule.preferredValue,
						value: "4",
						selectable: !forSpecificOrganization
					},
					{
						name: $translate.instant("modules.valueChain.requirements.No_OtherwiseCommentIsRequired"), // "Nej (Annars krävs kommentar)",
						rule: enums.requirementRule.preferredValue,
						value: "3",
						selectable: !forSpecificOrganization
					},
					{
						name: $translate.instant("modules.valueChain.requirements.YesOrNotRelevant_OtherwiseCommentIsRequired"), // "Ja eller Ej relevant (Annars krävs kommentar)",
						rule: enums.requirementRule.preferredValue,
						value: "4,2",
						selectable: !forSpecificOrganization
					},
					{
						name: $translate.instant("modules.valueChain.requirements.NoOrNotRelevant_OtherwiseCommentIsRequired"), // "Nej eller Ej relevant (Annars krävs kommentar)",
						rule: enums.requirementRule.preferredValue,
						value: "3,2",
						selectable: !forSpecificOrganization
					},
					{
						name: $translate.instant("modules.valueChain.requirements.AnyAnswer_YesRequiresMoreInfo"), // "Valfritt svar (Vid Ja krävs mer information)",
						rule: enums.requirementRule.preferredValueWithRelatedContent,
						value: "4",
						selectable: !forSpecificOrganization
					},
					{
						name: $translate.instant("modules.valueChain.requirements.AnyAnswer_NoRequiresMoreInfo"), // "Valfritt svar (Vid Nej krävs mer information)",
						rule: enums.requirementRule.preferredValueWithRelatedContent,
						value: "3",
						selectable: !forSpecificOrganization
					},
					valueChainService.buildAnswerTypesOptionObject([ 4, 3 ], { preferredValueWithRelatedContent: true }),
					{
						name: $translate.instant("modules.valueChain.requirements.AnyAnswerWithMoreInfo"), // "Valfritt svar med mer information",
						rule: enums.requirementRule.anyValueWithRelatedContent,
						value: null,
						selectable: !forSpecificOrganization
					},
					{
						name: $translate.instant("modules.valueChain.requirements.AnyAnswer_AccreditedRequiresMoreInfo"),
						rule: enums.requirementRule.preferredValueWithRelatedContent,
						value: "5",
						selectable: !forSpecificOrganization
					},
					{
						name: $translate.instant("modules.valueChain.requirements.AnyAnswer_NotCompliantRequiresMoreInfo"),
						rule: enums.requirementRule.preferredValueWithRelatedContent,
						value: "7",
						selectable: !forSpecificOrganization
					},
					valueChainService.buildAnswerTypesOptionObject([ 1 ]),
					valueChainService.buildAnswerTypesOptionObject([ 2 ]),
					{
						name: $translate.instant("modules.valueChain.requirements.NotRelevantWithMoreInfo"), // "Ej relevant med mer information",
						rule: enums.requirementRule.specificValueWithRelatedContent,
						value: "2"
					},
					valueChainService.buildAnswerTypesOptionObject([ 5 ]),
					{
						name: $translate.instant("modules.valueChain.requirements.AccreditedWithMoreInfo"),
						rule: enums.requirementRule.specificValueWithRelatedContent,
						value: "5"
					},
					valueChainService.buildAnswerTypesOptionObject([ 6 ]),
					valueChainService.buildAnswerTypesOptionObject([ 7 ]),
					valueChainService.buildAnswerTypesOptionObject([ 8 ]),
					valueChainService.buildAnswerTypesOptionObject([ 9 ]),
					valueChainService.buildAnswerTypesOptionObject([ 10 ]),
					valueChainService.buildAnswerTypesOptionObject([ 11 ]),
					valueChainService.buildAnswerTypesOptionObject([ 13 ]),
					valueChainService.buildAnswerTypesOptionObject([ 14 ]),
					valueChainService.buildAnswerTypesOptionObject([ 15 ]),
					valueChainService.buildAnswerTypesOptionObject([ 16 ]),
					valueChainService.buildAnswerTypesOptionObject([ 17 ]),
					valueChainService.buildAnswerTypesOptionObject([ 20 ]),
					valueChainService.buildAnswerTypesOptionObject([ 21 ]),
					valueChainService.buildAnswerTypesOptionObject([ 22 ]),
					{
						name: $translate.instant("modules.valueChain.requirements.AnyAnswer_NoOrPartlyRequiresMoreInfo"),
						rule: enums.requirementRule.preferredValueWithRelatedContent,
						value: "3,20",
						selectable: !forSpecificOrganization
					},
					{
						name: $translate.instant("modules.valueChain.requirements.AnyAnswer_YesOrPartlyRequiresMoreInfo"),
						rule: enums.requirementRule.preferredValueWithRelatedContent,
						value: "4,20",
						selectable: !forSpecificOrganization
					},
					valueChainService.buildAnswerTypesOptionObject([ 24 ]),
					valueChainService.buildAnswerTypesOptionObject([ 25 ]),
					valueChainService.buildAnswerTypesOptionObject([ 26 ]),
					valueChainService.buildAnswerTypesOptionObject([ 25, 26 ], { preferredValueWithRelatedContent: true }),
					// Remember to also add new items in requirement.service.js
				],
				onUpdated: function (updatedRequirement) {
					$timeout();
				}
			}
			return questionRequirementSettings;
		};
	}
} ())
