import React from 'react'
import Image from '@worldfavor/components/Image'
import TableElementsStyles from './TableElementsStyles'
import { ConstVars } from '@worldfavor/constants'
import NewFilterDropdown from '@worldfavor/components/Filters/NewFilterDropdown'
import { FormattedMessage, FormattedDate } from 'react-intl'
import TooltipWithCopyToClipboard from '@worldfavor/components/Text/TooltipWithCopyToClipboard'
import _ from 'lodash'

export function getTableColumns(options) {
  const { filters, onFilterChange, clearFilter, expandCells } = options

  return [
    {
      Header: <FormattedMessage id="dataManager.category" />,
      accessor: ConstVars.CategorizationsAccessors.Category,
      options: {
        filter: filters && filters.find(filter => filter.id === ConstVars.CategorizationsAccessors.CategoryId),
        onFilterChange,
        clearFilter,
      },
      width: !expandCells ? 400 : 500,
    },
    {
      Header: <FormattedMessage id="dataManager.organization" />,
      accessor: ConstVars.CategorizationsAccessors.Organization,
      width: !expandCells ? 400 : 500,
    },
    {
      Header: <FormattedMessage id="dataManager.dateAdded" />,
      accessor: ConstVars.CategorizationsAccessors.Date,
      width: !expandCells ? 400 : 500,
    },
  ]
}

export function renderRowCell(value, row, column, cell) {
  const classes = TableElementsStyles()
  const imageUrl = _.get(row, 'original.category.imageUrl')
  if (column && column.id === ConstVars.CategorizationsAccessors.Category) {
    return (
      <div className={classes.cell}>
        <Image
          className={classes.image}
          size={'cover'}
          src={imageUrl}
        />
        <TooltipWithCopyToClipboard classes={{ root: classes.labelCell }} text={cell.value} />
      </div>
    )
  } else if (column && column.id === ConstVars.CategorizationsAccessors.Date) {
    return (
      <div className={classes.cell}>
        <TooltipWithCopyToClipboard
          text={
            (
              <FormattedDate
                year={'2-digit'}
                day={'2-digit'}
                month={'2-digit'}
                hour={'numeric'}
                minute={'numeric'}
                hour12={false}
                value={cell.value}
              />
            )
          }
        />
      </div>
    )
  }
  return <div className={classes.cell}><TooltipWithCopyToClipboard text={value} /></div>
}

export function renderColumnCell(Header, id, column) {
  if (column.options) {
    const { filter, onFilterChange, clearFilter } = column.options

    if (filter && onFilterChange) {
      return (
        <NewFilterDropdown
          filter={filter}
          style={{ width: column.width }}
          onChange={onFilterChange}
          onClear={clearFilter}
        />
      )
    }
  }

  return column.Header
}
