(function() {
	"use strict";

	// Usage:
	// 
	// Creates:
	// 

	angular
		.module("wf.common")
		.component("wfCheck", {
			templateUrl: "scripts/wf/common/wfCheck.component.html",
			controller: wfCheckController,
			controllerAs: "vm",
			bindings: {
				value: "=",
				label: "<",
				onChange: "&",
				customClass: "<"
			},
		});

		wfCheckController.$inject = [ "$element", "$translate" ];
	function wfCheckController($element, $translate) {
		var vm = this;
		$element.css("visibility", "hidden");

		

		_.assign(vm, {
			id: _.uniqueId()
		})

		
		////////////////
		
		vm.$onInit = function() {
			setTimeout(function () {
				$element.css("visibility", "");
			});

			if (vm.allowUndefinedValue) {
				$element.find("div.icheckbox_wf").click(function () {

				});
			}
		};
		vm.$onChanges = function(changesObj) { };
		vm.$onDestroy = function() { };
	}
})();