(function() {
'use strict';
	angular
		.module('wf.common')
		.component('wfPdfViewer', wfPdfViewer());

		function wfPdfViewer() {
			var component = {
				template:'<ng-pdf template-url="scripts/wf/pdf/wfPdfViewer.template.html" scale="page-fit"></ng-pdf>',
				controller: wfPdfViewerController,
				controllerAs: 'pdfViewerVm',
				bindings: {
					url: '@', //@ bindings can be used when the input is a string, especially when the value of the binding doesn't change
					allowScaling: '<',
					allowNavigation: '<',
					showInfo: '<'
				}
			}

			return component;
		}

	wfPdfViewerController.$inject = [ '$scope', 'wfAuth', '$timeout' ];
	function wfPdfViewerController($scope, wfAuth, $timeout) {
		var vm = this;
		
		$scope.pdfUrl = vm.url;
		$scope.httpHeaders = { Authorization: wfAuth.getAuthenticationHeaderValue() };

		_.assign(vm, {
			loaded: false
		});

		//Available variables from ng-pdf

		//$scope.pageCount
		//$scope.pageNum
		//$scope.pageToDisplay

		//Available functions from ng-pdf

		//$scope.changePage()
		//$scope.fit()
		//$scope.goNext()
		//$scope.goPrevious()
		//$scope.renderPage(num)
		//$scope.rotate()
		//$scope.zoomIn()
		//$scope.zoomOut()
		
		$scope.onLoad = function() {
			vm.loaded = true;

			document.onkeydown = function(e) {
				e = e || window.event;
				if (e.keyCode == '37')
					$scope.goPrevious();
				else if (e.keyCode == '39')
					$scope.goNext();
				
				$timeout();
			}
		}

		$scope.onError = function(error) {
			// handle the error
			// console.log(error);
		}

		$scope.onProgress = function(progress) {
			// handle a progress bar
			// progress% = progress.loaded / progress.total
			// console.log(progress);
		}

		$scope.onPassword = function (updatePasswordFn, passwordResponse) {
			// if passwordResponse === PDFJS.PasswordResponses.NEED_PASSWORD
			// you can provide the password calling updatePasswordFn('THE_PASSWORD')
			// else if passwordResponse === PDFJS.PasswordResponses.INCORRECT_PASSWORD
			// provided password is not correct
		};
	}
})();