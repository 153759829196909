import * as enums from '@worldfavor/constants/enums'

 (function() {
	"use strict";

	angular
		.module("wf.common")
		.component("wfEasyList", {
			templateUrl: "scripts/wf/easy/wfEasyList.component.html",
			controller: wfEasyListController,
			controllerAs: "vm",
			bindings: {
				loader: "<",
				ticket: "<",
				config: "<",
				enableSearch: "<"
			}
		});

	wfEasyListController.$inject = [ "TheNewLoaderFactory", 'wfAuth' ];
	function wfEasyListController(TheNewLoaderFactory, wfAuth) {
		var
			vm = this
		;

		this.$onInit = function () {
			var modalElement = $("div.modal[uib-modal-window]");

			var rootStructure = wfAuth.getRootStructureInfo();
			var reportingOrgRootStructure = 12756;

			_.assign(vm, {
				infiniteScrollContainer: modalElement.length ? modalElement.last() : undefined,
				loader: TheNewLoaderFactory.instantiate(vm.loader || _.assign({}, vm.config, { ticket: vm.ticket })),
				onSearch: onSearch,
				isReportingUser: rootStructure.id === reportingOrgRootStructure,
				
				workModeModel: false, // need to use boolean in the ng-modal for the switcher
				// showUiModeSwitcher: vm.loader && vm.loader.influence ? true : false,
				showUiModeSwitcher: true,
				switchUiMode: switchUiMode,
				onSeriesClick: onChartSeriesClick
			})

			if (modalElement.length === 0) {
				scrollToTop();
			}
			
			vm.loader.onLoaded(function () {
				if (vm.loader.uiMode)
					vm.workModeModel = vm.loader.uiMode === enums.uiMode.work;
			});
		}

		function onChartSeriesClick(seriesLabel) {
			console.log('custom chartSeries clicked', seriesLabel)
		}

		function onSearch(searchString, items, searchActive) {
			vm.loader.filteredAndSearchedItems = searchActive ? items : undefined;

			if (searchActive)
				vm.searchActive = true;

			if (searchString === "")
				vm.searchActive = false;

			vm.loader.viewItems.length = 0;
			vm.loader.pagingFunctionEnabled = true;
			vm.loader.infiniteScrollPagingFunction();
		}

		function switchUiMode() {
			if (vm.workModeModel)
				vm.loader.setUiMode(enums.uiMode.work);
			else
				vm.loader.setUiMode(enums.uiMode.view);
		}

		function scrollToTop() {
			$('html, body').scrollTop(0);
		}
	}
})();
