import React from 'react'
import Divider from '@worldfavor/components/Divider'
import ReceivedInfluenceRow from './ReceivedInfluenceRow'

const ReceivedInfluenceList = (props) => {
  const { influences, ...rest } = props
  return (
    <>
      {
        influences.map((influence, index) => (
          <div key={`influence-${influence.wfid}`} data-cy={'received-influence'}>
            <ReceivedInfluenceRow influence={influence} {...rest} />
            {
              index < influences.length - 1 && (
                <Divider style={{ marginLeft: 121, width: 'initial' }} height={1} margin={'0'} width={'100%'} color={'rgba(221, 221, 221, 0.5)'} />
              )
            }
          </div>
        ))
      }
    </>
  )
}

export default ReceivedInfluenceList
