export default {
  object: '/multi/object',
  objects: '/multi/objects',
  subItems: '/multi/subItems',
  subItemsOfAll: '/multi/subItemsOfAll',
  subItemsPage: '/multi/subItemsPage',
  createSubItemRelation: '/multi/createSubItemRelation',
  updateExtended: '/multi/updateExtended',
  createExtended: '/multi/createExtended',
  delete: '/multi/delete',
  productSupplierTree: (productWfid, networkWfid) => `/graph/productSupplierTree?productWfid=${productWfid}&networkWfid=${networkWfid}`,
  productSupplierList: (productWfid, networkWfid) => `/graph/productSupplierList?productWfid=${productWfid}&networkWfid=${networkWfid}`,
  addFlatMappedProductSupplier: '/graph/addFlatMappedProductSupplier',
  dropFlatMappedProductSupplier: '/graph/dropFlatMappedProductSupplier',
  saveProductSupplierTree: '/graph/saveProductSupplierTree',
  uniqueProductSuppliers: '/graph/uniqueProductSuppliers',
  organizationActorTypes: '/graph/organizationActorTypes',
  getProductOrganizationRelations: (organizationWfid, networkWfid) => `/graph/productOrganizationRelations?organizationWfid=${organizationWfid}&networkWfid=${networkWfid}`,
  getOrganizationByCondition: '/utility/getOrganizationByCondition',
  crowdSourcedOrganization: '/valuechain/crowdSourcedOrganization',
  productActivation: '/valuechain/productActivation',
  uploadImage: '/upload/image',
  createThirdPartyInfluence: '/valuechain/createThirdPartyInfluence',
  deleteThirdPartyInfluence: '/valuechain/deleteThirdPartyInfluence',

  // ---------------- DATA COLLECTION ENDPOINTS -------------------
  readOrganizations: '/data-collection/organizations/read',
  filtersForOrganizations: () => '/data-collection/organizations/filters',

  readContributors: '/data-collection/contributors/read',
  filtersForContributors: networkId => `/data-collection/contributors/filters?networkId=${networkId}`,

  readCategorizations: '/data-collection/categorizations/read',
  filtersForCategorizations: networkId => `/data-collection/categorizations/filters?networkId=${networkId}`,

  readAnalyzeJobs: '/data-collection/analyze-jobs/read',
  filtersForAnalyzeJobs: networkId => `/data-collection/analyze-jobs/filters?networkId=${networkId}`,

  readAnalyzeResults: '/data-collection/analyze-results/read',
  filtersForAnalyzeResults: analyzeJobId => `/data-collection/analyze-results/filters?analyzeJobId=${analyzeJobId}`,

  importDataCollectorItemsStep1: '/valuechain/importDataCollectorItemsStep1',
  importDataCollectorItemsStep2: '/valuechain/importDataCollectorItemsStep2',

  exportRequirementPackages: '/valuechain/exportRequirementPackages',

  exportNetworkOrganizations: '/valuechain/exportNetworkOrganizations',

  getNextEmailSendoutInfo: '/mail/getNextEmailSendoutInfo'
  // --------------------------------------------------------------
}
