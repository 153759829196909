// @flow
import * as React from 'react'
import ItemSummary from './ItemSummary'
import FlatList from '../List/FlatList'
import type { Item } from '@worldfavor/types'

class Separator extends React.PureComponent<{}> {
  render() {
    return <div style={{ borderTop: '1px solid rgba(0, 0, 0, 0.1)' }} />
  }
}

const ITEM_PER_PAGE = 10

type Props = {
  items: Array<Item>,
  getScrollParent?: () => HTMLElement,
}

type State = {
  page: number,
  endReached: boolean | null,
}

class ItemList extends React.PureComponent<Props, State> {

  static defaultProps = {
    items: [],
  }

  state = {
    page: 0,
    endReached: null,
  }

  static getDerivedStateFromProps({ items }: Props, prevState: State) {
    if (prevState.endReached === null && items) {
      return { endReached: items.length <= ITEM_PER_PAGE }
    }
    return null
  }

  componentDidUpdate(prevProps: Props) {
    const { items = [] } = this.props
    const { items: prevItems = [] } = prevProps
    if (items.length !== prevItems.length) {
      this.setState({ endReached: (this.state.page + 1) * ITEM_PER_PAGE >= items.length }) // eslint-disable-line
    }
  }

  _keyExtractor = (item: Item, index: number) => `item-${index}-${!item ? 'placeholder' : item.wfid}`

  _onEndReached = () => {
    const { page, endReached } = this.state
    const { items } = this.props
    if (endReached) {
      return
    }
    this.setState({ page: page + 1, endReached: (page + 2) * ITEM_PER_PAGE >= items.length })
    console.log('end reached')
  }

  renderItem = ({ item }: { item: Item, index: number }) => {
    return (
      <ItemSummary
        style={{ padding: 25 }}
        item={item}
        creator={item && item.creator}
        showPlaceholder={!item}
        extendedView
        noPlayer
      />
    )
  }

  render() {
    const { page, endReached } = this.state
    const { items, getScrollParent } = this.props
    return (
      <div style={{ margin: '0 -25px' }}>
        <FlatList
          data={endReached ? items : items.slice(0, - (items.length - (page + 1) * ITEM_PER_PAGE))}
          renderItem={this.renderItem}
          keyExtractor={this._keyExtractor}
          ItemSeparatorComponent={Separator}
          onEndReached={this._onEndReached}
          getScrollParent={getScrollParent}
        />
      </div>
    )
  }
}

export default ItemList
