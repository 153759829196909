(function() {
	'use strict';
	
	angular
		.module('wf.common')
		.component('wfDashboardCard', {
			template: [ "$attrs", function ($attrs) {
				// Determine if the card should be a link element
				if ("item" in $attrs && "itemRelation" in $attrs)
					return '<a class="card-item" ng-style="::{ backgroundColor: vm.stats.bgColor, height: vm.stats.height }" wf-item-use="vm.itemRelation" wf-item-href>'
						+ '<ng-include src="::\'scripts/wf/dashboard/wfDashboardCard.component.html\'"></ng-include>'
						+ '</a>'
				else
					return '<div class="card-item" ng-style="::{ backgroundColor: vm.stats.bgColor, height: vm.stats.height }">'
						+ '<ng-include src="::\'scripts/wf/dashboard/wfDashboardCard.component.html\'"></ng-include>'
						+ '</div>'
			}],
			controller: wfDashboardCardController,
			controllerAs: "vm",
			bindings: {
				options: "<",
				item: "<",
				itemRelation: "<"
			}
		});

	wfDashboardCardController.$inject = [ "$scope", "$timeout", "$element", "$rootScope", "$state", "requirements", "$transclude", "$compile", "$translate", "$q", "dataOperationsService", "wfObject", "statisticsService" ];
	function wfDashboardCardController($scope, $timeout, $element, $rootScope, $state, requirements, $transclude, $compile, $translate, $q, dataOps, wfObject, statisticsService) {
		var
			vm = this,
			chartContainerElement
		;

		activate();

		function activate() {
			vm.stats = vm.options;

			if (vm.options.cssClass)
				$element.addClass(vm.options.cssClass);

			statisticsService.loadStatistics(vm.options).then(function (stats) {
				vm.stats = stats;
				// setTimeout(function () {
				
				setTimeout(function () {
					chartContainerElement = $element.find("div.card-graph");
					chartContainerElement.fadeTo(300, 1);
				}, 50)
			});
		}

		/*
		EXAMPLES OF OPTIONS:

		optionsByKey =  {
			manage: {
				asEmphasizedTotals: true, // The count value from every structure will be displayed with each structure's title as the label
				structureIds: [
					13546, // ISO Standards
					13547, // Standards & guidelines
					203, // Custom frameworks
					14497 // Internal Influences sent by organization
				],
				accessStructures: {
					14497: "71-13593" // For structureId 14497, structure 13593 is used to check if the organization has access (internal influences / collaboration tool)
					// 14497 is used to return statistics and 13593 is the structure that represents the menu item (Manage > Collaboration tool)
				},
				bgColor: "#4799DB",
				contextVariable1: "MANAGE",
			},
			visualize: {
				combinedData: true, // All statistics from the structures will be combined into a single set of statistics
				structureIds: [
					11971, // Widgets
					11953 // Sustainability Rooms
				],
				bgColor: "#34485E",
				contextVariable1: "Visualize",
				emptyStateHeader: $translate.instant("modules.dashboard.visualize.emptyStateHeader"),
				chartType: "line",
				additionalRequestParams: {
					includeReadStatistics: true
				},
				labels: {
					reads: $translate.instant("modules.dashboard.visualize.totalViews")
				},
				datasetPrototype: {
					backgroundColor: function () {
						var offscreen = document.createElement('canvas'); // detached from DOM
						var ctx = offscreen.getContext('2d');
						var bgGradient = ctx.createLinearGradient(0, 0, 0, 110);
						bgGradient.addColorStop(0, 'rgba(0, 0, 0, 0.15)');
						bgGradient.addColorStop(1, 'rgba(0, 0, 0, 0)');
						return bgGradient;
					}
				},
				onBeforeSetup: function (structureStats) { // Called for each structure specified in structureIds when they have finished loading but before any other logic has executed
					// Modify the statistics object of each structure so that only "reads" data is included
					var modifiedStats = _.cloneDeep(structureStats);
					modifiedStats.datasets = _.filter(modifiedStats.datasets, { id: "reads" });
					modifiedStats.totals = _.filter(modifiedStats.totals, { id: "reads" });

					return modifiedStats;
				}
			},
			valueChain: {
				structureId: 14496,
				bgColor: "#ED9D0D",
				contextVariable1: "ValueChain",
				emptyStateHeader: $translate.instant("modules.dashboard.valueChain.emptyStateHeader"),
				chartType: "line",
				labels: {
					count: $translate.instant("modules.dashboard.valueChain.sentRequest"),
					fulfilledCount: $translate.instant("modules.dashboard.valueChain.fulfilledRequests")
				}
			},
			report: {
				structureId: 10489,
				bgColor: "#48CE76",
				contextVariable1: "Report",
				emptyStateHeader: $translate.instant("modules.dashboard.report.emptyStateHeader"),
				chartType: "donut", // Will show a donut chart with the totals in fractional style (ex: 4/10) in the middle
				label: $translate.instant("modules.dashboard.report.completed")
			},
			eventLog: {
				structureId: 11723,
				contextVariable1: "EventLog",
				chartType: "line",
				datasetPrototype: {
					height: 80,
					borderColor: "#4799DB",
					borderWidth: 4,
					backgroundColor: function () {
						var offscreen = document.createElement('canvas'); // detached from DOM
						var ctx = offscreen.getContext('2d');
						var bgGradient = ctx.createLinearGradient(0, 0, 0, 60);
						bgGradient.addColorStop(0, 'rgba(71, 153, 219, 0.4)');
						bgGradient.addColorStop(1, 'rgba(71, 153, 219, 0)');
						return bgGradient;
					}
				},
				onAfterSetup: function (stats) {
					stats.chartJsOptions.scales.xAxes[0].display = false;
				}
			}
		}
		*/
	}
})();
