import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Colors, { addTransparency } from '@worldfavor/constants/colors'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
  },
  dialogContent: {
    backgroundColor: Colors.grayLighter,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 35,
  },
  dialogButtons: {
    padding: 35,
    flexDirection: 'column',
  },
  circle: {
    height: 92,
    width: 92,
    marginBottom: 14,
    borderRadius: '100%',
    backgroundColor: theme.palette.common.white,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    fontSize: 28,
    color: theme.palette.primary.main,
  },
  title: {
    ...theme.typography.h6,
    color: theme.palette.primary.main,
    fontSize: 20,
    marginTop: theme.spacing(1),
    textAlign: 'center',
    lineHeight: '1.4',
    width: '100%',
  },
  description: {
    marginTop: 10,
    fontSize: 16,
    textAlign: 'center',
    color: theme.palette.primary.text,
    width: '100%',
  },
  closeButtonWrapper: {
    position: 'absolute',
    top: 10,
    right: 15,
  },
  closeButton: {
    color: Colors.gray,
  },
  reloadButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    textAlign: 'center',
    '&:hover': {
      backgroundColor: addTransparency(theme.palette.primary.main, 0.8),
    },
  },
  customerSupportButton: {
    marginTop: 20,
    backgroundColor: 'transparent',
    color: Colors.blue,
    textAlign: 'center',
    width: '100%',
    '&:hover': {
      backgroundColor: addTransparency(Colors.blue, 0.1),
    },
  },
}))

const ErrorDialog = (props) => {
  const { open, closeDialog, showReloadButton, showContactSupport } = props
  const classes = useStyles(props)
  const intercom = window.Intercom

  function reloadPage() {
    if (window && window.location) {
      closeDialog()
      window.location.reload(true) //hard reload
    }
  }

  function openIntercom() {
    if (intercom) {
      closeDialog()
      intercom('show')
    }
  }

  return (
    <Dialog maxWidth={'xs'} open={open} onClose={closeDialog} className={classes.root} fullWidth>
      <DialogContent className={classes.dialogContent} data-cy="dialog-content">
        <div className={classes.circle}>
          <i className={classNames(classes.icon, 'fas fa-sad-cry')} />
        </div>
        <div className={classes.title}>
          <FormattedMessage id={'dialog.errorDialog.title'} />
        </div>
        <div className={classes.description}>
          <FormattedMessage id={'dialog.errorDialog.description'} />
        </div>
        <div className={classes.closeButtonWrapper}>
          <IconButton
            color="primary"
            className={classes.closeButton}
            aria-label="Close"
            onClick={closeDialog}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogButtons}>
        {
          showReloadButton && (
            <Button className={classes.reloadButton} fullWidth onClick={reloadPage} data-cy="reload-button">
              <FormattedMessage id={'general.reloadPage'} />
            </Button>
          )
        }
        {
          showContactSupport && intercom && (
            <Button className={classes.customerSupportButton} onClick={openIntercom} data-cy="support-button">
              <FormattedMessage id={'general.contactCustomerSupport'} />
            </Button>
          )
        }
      </DialogActions>
    </Dialog>
  )
}

export default ErrorDialog
