import * as enums from '@worldfavor/constants/enums'

(function() {
'use strict';

	angular
		.module('wf.common')
		.controller('SupplierUsersController', SupplierUsersController);

	SupplierUsersController.$inject = ['$uibModal', '$ngBootbox', '$scope', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', '$timeout', '$uibModalInstance','modalContext','$q','apiProxy','valueChainService'];
	function SupplierUsersController($uibModal, $ngBootbox, $scope, dataOps, modal, wfObject, dataQuery, $translate, $timeout, $uibModalInstance, modalContext, $q, apiProxy, valueChainService) {
		var vm = this;
		_.assign(this, modalContext);
		
		$scope.modal = modal;
		$scope.wfObject = wfObject;
		$scope.dataQuery = dataQuery;

		_.assign(vm, {
			users: [],
			createUser: function () {
				var df = $q.defer();
				var promise = valueChainService.createUser(vm.network, vm.supplierOrg);

				promise.then(function (res) {
					if (!res) {
						df.resolve();
						return;
					}

					$timeout(function () {
						vm.users = wfObject.filter({
							where: {
								type: 81,
								childType: 100,
								wffid: vm.supplierOrg.wfid,
							}
						});

						if (vm.network) {
							vm.users = _.intersectionBy(
								vm.users,
								wfObject.filter({
									where: {
										type: enums.objectType.dataRelation,
										childType: 100,
										wffid: { "in": [vm.network.wfid, "52-1"] },
										wfxpid: vm.supplierOrg.wfid,
									},
								}),
								"wfcid"
							);
						}

						df.resolve();
						
					}, 1000);
				});

				return df.promise;
			},
			removeUserFromNetwork: function (userDataRelation) {
				var
					df = $q.defer(),
					user,
					dataRelationToNetwork
				;

				// if (!vm.network) // To support old Data Collector
				// 	return removeOrgAccess(userDataRelation)

				user = userDataRelation.childContent;

				dataRelationToNetwork = _.find(user.parents, { wffid: vm.network.wfid, wfxpid: vm.supplierOrg.wfid });

				if (dataRelationToNetwork) {
					$ngBootbox.customDialog({
						title: $translate.instant('Delete'),
						message: $translate.instant('ConfirmDeleteMessage'),
						onEscape: true,
						buttons: {
							cancel: {
								label: $translate.instant('Cancel'),
								className: "btn-default",
								callback: function() {
								}
							},
							primary: {
								label: $translate.instant('OK'),
								className: "btn-primary",
								callback: function() {
									return dataOps.destroy(dataRelationToNetwork).then(function () {
										vm.users = wfObject.filter({ where: { type: enums.objectType.virtualDataRelation, childType: 100, wffid: vm.supplierOrg.wfid } });

										vm.users = _.intersectionBy(
											vm.users,
											wfObject.filter({ where: { type: enums.objectType.dataRelation, childType: 100, wffid: { "in": [ vm.network.wfid, "52-1" ] }, wfxpid: vm.supplierOrg.wfid } }),
											"wfcid"
										);
										df.resolve();
									});
								}
							}
						}
					});
				}
				return df.promise;
			},
			removeOrgAccess_OLD: function (item) {
				var df = $q.defer();
				$ngBootbox.customDialog({
					title: $translate.instant('Delete'),
					message: $translate.instant('ConfirmDeleteMessage'),
					onEscape: true,
					buttons: {
						cancel: {
							label: $translate.instant('Cancel'),
							className: "btn-default",
							callback: function() {
							}
						},
						primary: {
							label: $translate.instant('OK'),
							className: "btn-primary",
							callback: function() {
								return dataOps.destroy(item).then(function () {
									vm.users = wfObject.filter({ where: { type: 81, childType: 100, wffid: vm.supplierOrg.wfid } });
									df.resolve();
								});
							}
						}
					}
				});
				return df.promise;
			}
		});

		activate();

		////////////////

		function activate() {
			vm.users = wfObject.filter({ where: { type: 81, childType: 100, wffid: vm.supplierOrg.wfid } });

			if (vm.network) {
				vm.users = _.intersectionBy(
					vm.users,
					wfObject.filter({ where: { type: enums.objectType.dataRelation, childType: 100, wffid: { "in": [ vm.network.wfid, "52-1" ] }, wfxpid: vm.supplierOrg.wfid } }),
					"wfcid"
				);
			}
		}
	}
})();
