//@flow
import React, { useState, useEffect, forwardRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MuiMenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { FormattedMessage } from 'react-intl'
import Colors from '@worldfavor/constants/colors'
import { FILTER_OPTIONS_TYPE_PROPS } from '@worldfavor/constants'

const useStyles = makeStyles(theme => ({
  menuPaper: {
    marginTop: theme.spacing(1),
    minWidth: 250,
  },
  menuItem: {
    paddingRight: 15,
  },
  menuItemAll: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: Colors.grayLight,
  },
}))

const menuItemStyles = makeStyles(theme => ({
  menuItem: {},
  checkbox: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  listItemText: {
    fontSize: theme.typography.fontSizes.large,
  },
}))

type Props = {
  menuItemProps?: Object,
  menuProps?: Object,
  filterOptions: FILTER_OPTIONS_TYPE_PROPS,
  invertCheckboxes: boolean,
  onAfterFilterChanged?: (hasActiveFilters: boolean) => boolean
}

const MenuItem = forwardRef((props, ref) => {
  const { label, id, checked, onClick } = props
  const classes = menuItemStyles(props)

  function onChange(event) {
    onClick(event, id)
  }

  return (
    <MuiMenuItem classes={{ root: classes.menuItem }} disableGutters onClick={onChange} ref={ref} dense>
      <ListItemIcon>
        <Checkbox
          disableRipple
          checked={checked}
          classes={{ root: classes.checkbox }}
        />
      </ListItemIcon>
      <ListItemText classes={{ primary: classes.listItemText }}>
        {label}
      </ListItemText>
    </MuiMenuItem>
  )
})

const FilterMenu = (props: Props) => {
  const classes = useStyles(props)
  const { filterOptions, menuProps, menuItemProps, onAfterFilterChanged, invertCheckboxes } = props
  const FilterOptionsById = filterOptions.reduce((acc, { id }) => ({ ...acc, [id]: invertCheckboxes }), {})

  const [allSelected, setAllSelected] = useState(invertCheckboxes)
  const [filterOptionsCheck, setFilterOptionsCheck] = useState(FilterOptionsById)

  useEffect(() => {
    const checkedOptions = filterCheckedOptions()
    
    if (!isEveryOptionChecked(filterOptionsCheck)) {
      onAfterFilterChanged && onAfterFilterChanged(checkedOptions)
    }
  }, [allSelected, filterOptionsCheck])
  
  function isEveryOptionChecked(options) {
    return Object.values(options).every((option) => {
      return option === true
    })
  }

  function filterCheckedOptions() {
    return filterOptions.filter((option) => {
      return filterOptionsCheck[option.id] === invertCheckboxes
    })
  }

  function toggleFilter(event, id) {
    const newValue = !filterOptionsCheck[id]

    const newFilterOptionsCheck = {
      ...filterOptionsCheck,
      [id]: newValue,
    }

    setFilterOptionsCheck(newFilterOptionsCheck)
    setAllSelected(isEveryOptionChecked(newFilterOptionsCheck))
  }

  function toggleAll() {
    //Check/Uncheck all filterOptions
    const newFilterOptionsCheck = filterOptionsCheck

    for (const key in filterOptionsCheck) {
      filterOptionsCheck[key] = !allSelected
    }

    setFilterOptionsCheck(newFilterOptionsCheck)
    setAllSelected(!allSelected)
  }
  
  return (
    <Menu
      keepMounted
      classes={{ paper: classes.menuPaper }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      getContentAnchorEl={undefined}
      {...menuProps}
    >
      {
        filterOptions.length > 1 && (
          <MenuItem
            label={<FormattedMessage id={'general.all'} />}
            onClick={toggleAll}
            classes={{ menuItem: classes.menuItemAll }}
            checked={allSelected}
            {...menuItemProps}
          />
        )
      }

      {
        filterOptions.map(({ id, label }) => (
          <MenuItem
            key={`menu-item-${id}`}
            label={label}
            id={id}
            onClick={toggleFilter}
            classes={{ menuItem: classes.menuItem }}
            checked={filterOptionsCheck[id]}
            {...menuItemProps}
          />
        ))
      }
    </Menu>
  )
}

FilterMenu.defaultProps = {
  invertCheckboxes: true,
}

export default FilterMenu
