import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfChartSunburst', wfChartSunburst);

	wfChartSunburst.$inject = ['d3Utility','$parse', 'dataQuery','wfObject'];
	
	function wfChartSunburst (d3Utility, $parse, dataQuery, wfObject) {
		var directive = {
			restrict: 'E',
			//template: '<div><span ng-show="vm.percentage > -1" style="color:{{item.childContent.conditions.color1}}">{{vm.fulfilledPercentage}}%</span><canvas width="150" height="150" canvas-chart="vm.chartData" options="{percentageInnerCutout: 73, animateRotate: false, showTooltips: false}" data-type="\'Doughnut\'"></canvas></div><p>{{vm.header}}</p>',
			// template: '<div><span ng-show="true" style="color:{{vm.color}}">{{vm.percentage}}%</span><canvas width="150" height="150" canvas-chart="vm.chartData" options="{percentageInnerCutout: 74, animateRotate: false, showTooltips: false}" data-type="\'Doughnut\'"></canvas></div><p>{{vm.header}}</p>',
			scope: {
				chartData: "=",
				chartDataNew: "=",
				lookupLevel1: "=",
				lookupLevel2: "=",
				lookupLevel3: "=",
				control: "=",
				organizationId: "="
			},
			link: link
			// controller: ['$scope','$element','$interval','dataModeller','$compile','wfObject', controller],
			// controllerAs: 'vm'
		};
		return directive;

	// function controller($scope, $element, $interval, dataModeller, $compile, wfObject) {
	// }
	


		function link(scope, element, attributes, vm) {
			// return;
			var
				lookupLevel1 = scope.lookupLevel1,
				lookupLevel2 = scope.lookupLevel2,
				lookupLevel3 = scope.lookupLevel3,
				nodesLookup = {},
				control = scope.control || {},
				emptyStateColor = "#ecf0f1",
				emptyStateColorHighlighted = "#d1dcde",
				showLegend = true,
				counter = 0,
				allStructureChildren,
				questionAnswerTypesById,
				allQuestionAnswers,
				allMeasureAnswers
			;
			// console.log(lookupLevel1);
			var chartData;
			// return;
			var prepareData = function (items) {
				var children = items, dataRelation;
				var maxDepth = 10;
				var organizationId = scope.organizationId;
				var prepareChildren = function (items, parent, depth) {
					var newList = [], child, children, children2;
					var color, latestAnswer, extraTooltipValue, label, fillOpacity, isEmptyColor, allChildrensChildren, firstObjectType, isDashed;
					var x, y, z, usingLookup;
					children = items;
					
					if (parent) {
						// console.log(parent, lookupLevel1, depth);
						if (lookupLevel1 && depth === 1) {
							usingLookup = true;
							children = lookupLevel1[parent.wfid];
							// console.log("setting childs", depth, parent.wfid, children)
							if (!children) return;
						}
						else if (lookupLevel2 && depth === 2) {
							usingLookup = true;
							children = lookupLevel2[parent.wfid];
							if (!children) return;
						}
						else if (lookupLevel3 && depth === 3) {
							usingLookup = true;
							children = lookupLevel3[parent.wfid];
							if (!children) return;
						}
						// console.log(depth, children)
					}
					
					if (children.length > 0) {
						firstObjectType = children[0].childType;
						// if ((firstObjectType === 71) && _.every(children, { childType: firstObjectType })) {
						// 	allChildrensChildren = wfObject.filter({ where: { type: 73, parentData1: null, parentType: firstObjectType, wffid: { in: _.map(children, 'wfcid') }}});
						// 	if (allChildrensChildren.length && depth + 1 !== maxDepth)
						// 		allChildrensChildren = prepareChildren(allChildrensChildren, null, depth + 1)
						// 	// console.log(firstObjectType, allChildrensChildren, _.map(children, 'wfcid'));
						// }
						
						for (var i = 0, len = children.length; i < len; i++) {
							// var answers;
							dataRelation = children[i];
							if (!dataRelation) continue;
							
							child = dataRelation.childContent;
							color = null;
							label = null;
							fillOpacity = 1;
							extraTooltipValue = null;
							isEmptyColor = false;
							isDashed = false;
							if (child)
							{
								// answers =
								if (child.type === enums.objectType.question)
								{
									// counter++;
									// console.time(counter + " get latest answer of " + child.wfid);
									latestAnswer = getLatestAnswerOnQuestion(child, organizationId);
									//latestAnswer = dataQuery.get.latestAnswerOnQuestion(child, organizationId);
									// console.timeEnd(counter + " get latest answer of " + child.wfid);
									if (latestAnswer && questionAnswerTypesById[latestAnswer.questionAnswerTypeId])
									{
										extraTooltipValue = "\n<br /><br /><b>" + questionAnswerTypesById[latestAnswer.questionAnswerTypeId].text +  "</b>";
										label = questionAnswerTypesById[latestAnswer.questionAnswerTypeId].text;
										// fillOpacity = getFillOpacityFromAnswer(latestAnswer.childContent.id);
										// if (latestAnswer.childContent.id !== 4)
										//  	color = "#fff";
										// // if (latestAnswer.childContent.id !== 4)
										// // {
											
										
										// }
									}
									else
									{
										isEmptyColor = true;
										color = emptyStateColor;
									}
								}
								if (child.type === enums.objectType.measure)
								{
									latestAnswer = getLatestAnswerOnMeasure(child, organizationId);
									if (latestAnswer)// && latestAnswer.childContent)
									{
										// TODO: Modify tooltip and fill/opacity according to the latest measure answer
									}
									else
									{
										isEmptyColor = true;
										color = emptyStateColor;
									}
								}
								else if (child.type === enums.objectType.structure) {
									fillOpacity = 0.4;
									isDashed = true;
									if (allChildrensChildren)
									{
										children2 = _.filter(allChildrensChildren, { wffid: child.wfid })
										
									}
									else {
										// console.log("query childs");
										// counter++;
										// console.time(counter + " get children of " + child.wfid);
										children2 = getStructureChildren(child);
										// children2 = child.childs;
										// console.timeEnd(counter + " get children of " + child.wfid);
									}
									color = dataRelation.settings && dataRelation.settings.color1 ? dataRelation.settings.color1 : null;
								}
								
								newList.push({
									size: 10,
									id: child.id,
									wffid: dataRelation.wffid,
									wfid: child.wfid,
									type: child.type,
									name: (child.title || child.text),
									color: color,
									label: label,
									isEmptyColor: isEmptyColor,
									extraTooltipValue: extraTooltipValue,
									fillOpacity: fillOpacity,
									isDashed: isDashed,
									children: allChildrensChildren
										? children2
										: (children2 && children2.length && child.type === 71 && depth + 1 !== maxDepth ? prepareChildren(children2, child, depth + 1) : undefined)
								});
							}
						}
					}
					
					return newList.length ? newList : undefined;
				};
				
				// console.time("prepare chart data");
				children = children && children.length ? prepareChildren(children, null, 0) : undefined;
				
				// console.timeEnd("prepare chart data");
				
				return {
					size: 10,
					wfid: 'root',
					name: null, //item.title || item.text,
					children: children,
					root: true
				};
			};

			var prepareData_NEW = function (items) {
				var children = items, dataRelation;
				var maxDepth = 10;
				var organizationId = scope.organizationId;
				
				var prepareChildren = function (items, parent, depth) {
					var newList = [], child, children, children2;
					var color, latestAnswer, extraTooltipValue, label, fillOpacity, isEmptyColor, allChildrensChildren, firstObjectType, isDashed;
					var x, y, z, usingLookup;
					children = items;
					
					if (parent) {
						// console.log(parent, lookupLevel1, depth);
						if (lookupLevel1 && depth === 1) {
							usingLookup = true;
							children = lookupLevel1[parent.wfid];
							// console.log("setting childs", depth, parent.wfid, children)
							if (!children) return;
						}
						else if (lookupLevel2 && depth === 2) {
							usingLookup = true;
							children = lookupLevel2[parent.wfid];
							if (!children) return;
						}
						else if (lookupLevel3 && depth === 3) {
							usingLookup = true;
							children = lookupLevel3[parent.wfid];
							if (!children) return;
						}
						// console.log(depth, children)
					}
					
					if (children.length > 0) {
						firstObjectType = children[0].type;
						// if ((firstObjectType === 71) && _.every(children, { childType: firstObjectType })) {
						// 	allChildrensChildren = wfObject.filter({ where: { type: 73, parentData1: null, parentType: firstObjectType, wffid: { in: _.map(children, 'wfcid') }}});
						// 	if (allChildrensChildren.length && depth + 1 !== maxDepth)
						// 		allChildrensChildren = prepareChildren(allChildrensChildren, null, depth + 1)
						// 	// console.log(firstObjectType, allChildrensChildren, _.map(children, 'wfcid'));
						// }
						
						for (var i = 0, len = children.length, item; i < len; i++) {
							// var answers;
							item = children[i];
							dataRelation = item.dataRelation;
							child = item.content;

							if (!dataRelation) continue;
							
							color = null;
							label = null;
							fillOpacity = 1;
							extraTooltipValue = null;
							isEmptyColor = false;
							isDashed = false;
							if (child)
							{
								// answers =
								if (child.type === enums.objectType.question)
								{
									// counter++;
									// console.time(counter + " get latest answer of " + child.wfid);
									latestAnswer = getLatestAnswerOnQuestion(child, organizationId);
									//latestAnswer = dataQuery.get.latestAnswerOnQuestion(child, organizationId);
									// console.timeEnd(counter + " get latest answer of " + child.wfid);
									if (latestAnswer && questionAnswerTypesById[latestAnswer.questionAnswerTypeId])
									{
										extraTooltipValue = "\n<br /><br /><b>" + questionAnswerTypesById[latestAnswer.questionAnswerTypeId].text +  "</b>";
										label = questionAnswerTypesById[latestAnswer.questionAnswerTypeId].text;
										// fillOpacity = getFillOpacityFromAnswer(latestAnswer.childContent.id);
										// if (latestAnswer.childContent.id !== 4)
										//  	color = "#fff";
										// // if (latestAnswer.childContent.id !== 4)
										// // {
											
										
										// }
									}
									else
									{
										isEmptyColor = true;
										color = emptyStateColor;
									}
								}
								if (child.type === enums.objectType.measure)
								{
									latestAnswer = getLatestAnswerOnMeasure(child, organizationId);
									if (latestAnswer)// && latestAnswer.childContent)
									{
										// TODO: Modify tooltip and fill/opacity according to the latest measure answer
									}
									else
									{
										isEmptyColor = true;
										color = emptyStateColor;
									}
								}
								else if (child.type === enums.objectType.structure) {
									fillOpacity = 0.4;
									isDashed = true;
									if (allChildrensChildren)
									{
										children2 = item.childs
										
									}
									else {
										// console.log("query childs");
										// counter++;
										// console.time(counter + " get children of " + child.wfid);
										children2 = item.childs
										// children2 = child.childs;
										// console.timeEnd(counter + " get children of " + child.wfid);
									}
									color = dataRelation.settings && dataRelation.settings.color1 ? dataRelation.settings.color1 : null;
								}
								
								newList.push({
									size: 10,
									id: child.id,
									wffid: dataRelation.wffid,
									wfid: child.wfid,
									type: child.type,
									name: (child.title || child.text),
									color: color,
									label: label,
									isEmptyColor: isEmptyColor,
									extraTooltipValue: extraTooltipValue,
									fillOpacity: fillOpacity,
									isDashed: isDashed,
									children: allChildrensChildren
										? children2
										: (children2 && children2.length && child.type === 71 && depth + 1 !== maxDepth ? prepareChildren(children2, child, depth + 1) : undefined)
								});
							}
						}
					}
					
					return newList.length ? newList : undefined;
				};
				
				// console.time("prepare chart data");
				children = children && children.length ? prepareChildren(children, null, 0) : undefined;
				
				// console.timeEnd("prepare chart data");
				
				return {
					size: 10,
					wfid: 'root',
					name: null, //item.title || item.text,
					children: children,
					root: true
				};
			};
			
			scope.$on('$destroy', function () {
				d3Utility.tooltip.hide();
			});

			element.addClass("svgChartDirective");
			element.addClass("loading");

			if (attributes.hasOwnProperty("hideLegend"))
				showLegend = false;

			setTimeout(function () {
				var prepareAndRender = function () {
					if (scope.chartData && scope.chartData.length)
					{
						
						element.html("");
						chartData = scope.chartData;
						chartData = prepareData(chartData); 
						// console.time("render chart");

						definePattern();
						renderChart();
						// console.timeEnd("render chart");
					}
					else if (scope.chartDataNew && scope.chartDataNew.length)
					{
						
						element.html("");
						chartData = scope.chartDataNew;
						chartData = prepareData_NEW(chartData); 
						// console.time("render chart");

						definePattern();
						renderChart();
						// console.timeEnd("render chart");
					}
					element.removeClass("loading");
					// console.log(chartData);
					// watch();
					
					
				};
				// console.log("do");
				prepareAndRender();

				if (control.onLoaded) {
					control.onLoaded();
				}
				// return;
				// chartData = scope.chartData;
				// scope.$on("wfObjectModified", function () {
				// 	prepareAndRender();
				// });
				// if (scope.chartData)
				// {
				// 	// console.log($parse(scope.chartData)());
				// 	chartData = prepareData(scope.chartData);
				// 	console.log(chartData);
				// 	renderChart();
				// }
				// else
				// {
				// }
				
				
			}, 0);
			
			
			
			function renderChart() {
				var d3 = d3Utility.d3;
				var width = element.width(),
					height = element.width(),
					radius = Math.min(width, height) / 2.3;

				var x = d3.scale.linear()
					.range([0, 2 * Math.PI]);

				var y = d3.scale.sqrt()
					.range([0, radius]);

				var color = d3.scale.category20c();
				var container = d3.select(element[0]);

				
				var svg = container
					.append("div")
						.classed("svg-container", true) //container class to make it responsive
					.append("svg")
						.attr("width", width)
						.attr("height", height)
						.attr("preserveAspectRatio", "xMinYMin meet")
						.attr("viewBox", "0 0 " + width + " " + height)
						.classed("svg-content-responsive", true)
					.append("g")
						.attr("transform", "translate(" + width / 2 + "," + (height / 2 - 5) + ")");

				var backButton = $("<div class='go-back'><i class='fas fa-compress'></i></div>").prependTo(element.find("div.svg-container"));

				var partition = d3.layout.partition()
					.sort(null)
					// .children(function (x) {
					// 	return x.childContent.children
					// })
					.value(function(d) { return 1; });

				var arc = d3.svg.arc()
					.startAngle(function(d) { return Math.max(0, Math.min(2 * Math.PI, x(d.x))); })
					.endAngle(function(d) { return Math.max(0, Math.min(2 * Math.PI, x(d.x + d.dx))); })
					.innerRadius(function(d) { return Math.max(0, y(d.y)); })
					.outerRadius(function(d) { return Math.max(0, y(d.y + d.dy)); });

				d3.select(self.frameElement).style("height", height + "px");

				// Keep track of the node that is currently being displayed as the root.
				var node;
				var root = node = chartData;
				var onNodeClickCallback;
				
					// node = root;
					// root = node;
					
					 var g = svg.datum(root).selectAll("g")
						.data(partition.nodes)
						.enter().append("g");

					var path = g.append("path")
						.on("mousemove", function (d) {
							if (!d.name) return;
							
							d3Utility.tooltip.show(d.name + (d.extraTooltipValue || ''), d3.event.pageX + 20, d3.event.pageY);
							// tooltipElement.html(d.name)
							// 	.style("margin-left", (d3.event.pageX + 20) + "px")
							// 	.style("margin-top", (d3.event.pageY) + "px");
						})
						.on("mouseout", function (d) {
							d3Utility.tooltip.hide();
							// tooltipElement.style("opacity", 0);
						})
						.on("mousedown", function (d) {
							d3Utility.tooltip.hide();
							// tooltipElement.style("opacity", 0);
						})
						.attr("d", arc)
						.attr("opacity", function (d) {
							if (d.fillOpacity !== null)
								return d.fillOpacity;
						})
						.style("fill", function (d) {
								// return d.color;
								
							nodesLookup[d.wfid] = d;
							if (d.root)
								return "#fff";
							else
							{
								if (d.parent.root)
								{
									d.filledColor = d.color || (d.color = color(d.name));
									return d.filledColor;
								}
								else
								{
									d.filledColor = d.parent.color;
									return d.color || (d.color = d.parent.color);//d3.rgb(d.color = d.parent.color).brighter(0 + (Math.random() * .2)); // .6
								}
							}
							
							// return color((d.children ? d : d.parent).name);
						})
						.attr("title", function (d) {
							return d.name;
						})
						.on("click", click)
						.classed("dashed", function (d) {
							return d.isDashed;
						})
						.each(function (d) {
							stash.apply(this, arguments);
							d.element = d3.select(this);

							// if (d.cssClass)
							// 	$(d.element).addClass(d.cssClass);
						});

					// d3.selectAll("input").on("change", function change() {
					// 	var value = this.value === "count"
					// 		? function() { return 1; }
					// 		: function(d) { return d.size; };

					// 	path
					// 		.data(partition.value(value).nodes)
					// 	.transition()
					// 		.duration(1000)
					// 		.attrTween("d", arcTweenData);
					// });

					// path.append("text")
					// 	.attr("dx", 0)
					// 	.attr("dy", 0)
					// 	.text(function(d) { return d.wfid })
					
					var text = g.append("text")
						.attr("transform", function(d) { return "rotate(" + computeTextRotation(d) + ")"; })
						.attr("x", function(d) { return y(d.y); })
						.attr("dx", "6") // margin
						.attr("dy", ".35em") // vertical-align
						.text(function(d) {
							// return d.label + " " + d.fillOpacity;
						});

					var legend;
					if (showLegend) {
						legend = container.append("ul");
						legend.classed("list-unstyled", true);
						legend.classed("legend", true);

						legend.selectAll("li")
							.data(root.children)
							.enter()
							.append("li").each(function (d) {
								var li = d3.select(this);
								li.append("div").append("svg")
									.append("g")
									.append("rect")
										.attr("width", 10)
										.attr("height", 10)
										.attr("fill", function () {
											return d.color
										});
									;
								li.append("span").text(function () {
									return d.name;
								});
							});
					}
					
					function click(d) {
						// fade out all text elements
    					// text.transition().attr("opacity", 0);
						// if (d.parent && d.parent.parent) return;

						
						if (onNodeClickCallback) {
							if (onNodeClickCallback(d) !== false) {
								setTimeout(function () {
									gotoNode(d);
								}, 0);
							}
						}
						else
							gotoNode(d);
					}

					function gotoNode(d) {
						node = d;
						
						if (d.root)
							backButton.fadeOut(400)
						else {
							setTimeout(function () {
								backButton.fadeIn(400)
							}, 400);
						}

						path.transition()
						.duration(800)
						.attrTween("d", arcTweenZoom(d))
						.each("end", function(e, i) {
							// check if the animated element's data e lies within the visible angle span given in d
							// if (e.x >= d.x && e.x < (d.x + d.dx)) {
							// 	// get a selection of the associated text element
							// 	var arcText = d3.select(this.parentNode).select("text");
							// 	// fade in the text element and recalculate positions
							// 	arcText
							// 		.attr("transform", function() { return "rotate(" + computeTextRotation(e) + ")" })
							// 		.attr("x", function(d) { return y(d.y); })
							// 		.transition().duration(750)
							// 		.attr("opacity", 1);
							// }
						});
					}
					
					control.onNodeClick = function (callback) {
						onNodeClickCallback = callback;
					}

					control.gotoNode = function (wfid) {
						// if (nodesLookup[wfid] === node)
						// 	return;


						clearTimeout(control.navigationTimer);
						control.navigationTimer = setTimeout(function () {
							gotoNode(nodesLookup[wfid]);
						}, 100);
					}

					control.resetHighlightedNodes = function () {
						var transitionChain;
						// d3.transition();

						_.each(nodesLookup, function (d) {
							d.hidden = false;
							transitionChain = d.element.transition().duration(300);

							transitionChain = transitionChain.style("opacity", function () {
								return d.fillOpacity;
							});

							if (d.isEmptyColor) {
								transitionChain.style("fill", function () {
									return emptyStateColor;
								});
							}
						});
					}

					control.highlightNodes = function (wfids) {
						var wfidMap = _.keyBy(wfids);
						var transitionChain;
						// d3.transition();

						_.each(nodesLookup, function (d) {
							transitionChain = d.element.transition().duration(300);

							if (wfidMap[d.wfid]) {
								d.hidden = false;
								transitionChain = transitionChain.style("opacity", function () {
									return d.fillOpacity + .1;
								});

								if (d.isEmptyColor) {
									transitionChain = transitionChain.style("fill", function () {
										return emptyStateColorHighlighted;
									});
								}
							}
							else {
								d.hidden = true;
								transitionChain = transitionChain.style("opacity", function () {
									return d.type == 71 ? 0.07 : 0.06;
								});
							}
						});

						_.each(wfids, function (wfid) {
							var d = nodesLookup[wfid];
							if (d) {
							}
						});
					}
					
					control.setMeasureAnswerState = function (wfid, newMeasureAnswer) {
						var d = nodesLookup[wfid];
						if (d)
						{
							if (newMeasureAnswer)
							{
								d.isEmptyColor = false;
								d.color = d.filledColor;
							}
							else
							{
								d.isEmptyColor = true;
								d.color = emptyStateColor;
							}

							d.element.transition().duration(300)
								.style("fill", function () {
									return d.color;
								});
						}
					}
					
					control.setQuestionAnswerState = function (wfid, newQuestionAnswerType) {
						var d = nodesLookup[wfid];
						if (d)
						{
							if (newQuestionAnswerType)
							{
								d.extraTooltipValue = "\n<br /><br /><b>" + newQuestionAnswerType.text +  "</b>";
								
								if (d.isEmptyColor) {
									d.isEmptyColor = false;
									d.element.transition().duration(300)
										.attr("opacity", function (d) {
											return;//getFillOpacityFromAnswer(newQuestionAnswerType.id);
										})
										.style("fill", function () {
											return d.filledColor;
										});
								}
								else
								{
									d.element.transition().duration(300)
										.attr("opacity", function (d) {
											return;// getFillOpacityFromAnswer(newQuestionAnswerType.id);
										});
								}
							}
							else
							{
								d.extraTooltipValue = "";

								d.isEmptyColor = true;
								d.color = emptyStateColor;

								d.element.transition().duration(300)
									.style("fill", function () {
										return emptyStateColor;
									});
							}
						}
					}


				// Setup for switching data: stash the old values for transition.
				function stash(d) {
					d.x0 = d.x;
					d.dx0 = d.dx;
				}

				function computeTextRotation(d) {
					return (x(d.x + d.dx / 2) - Math.PI / 2) / Math.PI * 180;
				}
				
				// When switching data: interpolate the arcs in data space.
				function arcTweenData(a, i) {
					var oi = d3.interpolate({x: a.x0, dx: a.dx0}, a);
					function tween(t) {
						var b = oi(t);
						a.x0 = b.x;
						a.dx0 = b.dx;
						return arc(b);
					}
					if (i == 0) {
					// If we are on the first arc, adjust the x domain to match the root node
					// at the current zoom level. (We only need to do this once.)
						var xd = d3.interpolate(x.domain(), [node.x, node.x + node.dx]);
						return function(t) {
							x.domain(xd(t));
							return tween(t);
						};
					} else {
						return tween;
					}
				}

				// When zooming: interpolate the scales.
				function arcTweenZoom(d) {
					var xd = d3.interpolate(x.domain(), [d.x, d.x + d.dx]),
						yd = d3.interpolate(y.domain(), [d.y, 1]),
						yr = d3.interpolate(y.range(), [d.y ? 20 : 0, radius]);
					return function(d, i) {
						return i
							? function(t) { return arc(d); }
							: function(t) { x.domain(xd(t)); y.domain(yd(t)).range(yr(t)); return arc(d); };
					};
				}

				
			}

			function definePattern() {
				var
					defs,
					pattern
				;

				if (!$("div#svgDefinitions").length) {
					defs = d3.select("body").append("div").attr("id", "svgDefinitions").append("svg:svg").append('defs');

					pattern = defs.append("pattern")
							.attr("id", "pattern-stripe")
							.attr("width", "4")
							.attr("height", "4")
							.attr("patternUnits", "userSpaceOnUse")
							.attr("patternTransform", "rotate(45)");

					pattern.append("rect")
						.attr("width", "4")
						.attr("height", "4")
						.attr("fill", "#aaa");
					pattern.append("circle")
						.attr("cx", "2")
						.attr("cy", "2")
						.attr("r", "2")
						.attr("fill", "#555");
							// .attr("opacity", "0.7")

					defs.append("mask")
							.attr("id", "mask-stripe")
						.append("rect")
							.attr("x", "-100%")
							.attr("y", "-100%")
							.attr("width", "200%")
							.attr("height", "200%")
							.attr("fill", "url(#pattern-stripe)");
				}
			}
			
			function getFillOpacityFromAnswer(questionAnswerType) {
				// console.log(questionAnswerType)
				switch (questionAnswerType) {
					case 1:
						return .35;
					case 2:
						return .55;
					case 3:
						return .75;
					case 4:
						return 1;
				}
			}

			function getLatestAnswerOnQuestion(question, organizationId) {
				if (!allQuestionAnswers) {
					allQuestionAnswers = wfObject.filter({ where: {
						type: 73,
						parentData1: null,
						parentType: enums.objectType.question,
						childType: enums.objectType.questionAnswer,
						organizationId: organizationId
					}});

					questionAnswerTypesById = _.keyBy(wfObject.filter({ where: {
						type: enums.objectType.questionAnswerType
					}}), "id");
				}

				// TODO: Implement network visibility

				// var
				// 	x = _.filter(allAnswers, { wffid: question.wfid }),
				// 	y = _.sortBy(x, "createdAt")
				// ;

				var answerRelation = _.chain(allQuestionAnswers).filter({ wffid: question.wfid }).sortBy("createdAt").last().value();
				if (answerRelation)
					return answerRelation.childContent;
			}

			function getLatestAnswerOnMeasure(measure, organizationId) {
				if (!allMeasureAnswers) {
					allMeasureAnswers = wfObject.filter({ where: {
						type: 73,
						parentData1: null,
						parentType: enums.objectType.measure,
						childType: enums.objectType.measureAnswer,
						organizationId: organizationId
					}});
				}

				var answerRelation = _.chain(allMeasureAnswers).filter({ wffid: measure.wfid }).value()[0];
				if (answerRelation)
					return answerRelation.childContent;
			}

			function getStructureChildren(structure) {
				if (!allStructureChildren) {
					allStructureChildren = wfObject.filter({ where: {
						type: 73,
						parentData1: null,
						parentType: enums.objectType.structure
					}});
				}

				// var
				// 	x = _.filter(allAnswers, { wffid: question.wfid }),
				// 	y = _.sortBy(x, "createdAt")
				// ;

				var structureChildren = _.chain(allStructureChildren).filter({ wffid: structure.wfid }).value();
				return structureChildren;
			}
		}
	}
})();
