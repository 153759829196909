import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfInfluenceSigning', wfInfluenceSigning);

	wfInfluenceSigning.$inject = [];
	function wfInfluenceSigning() {
		var directive = {
			bindToController: true,
			templateUrl: 'scripts/wf/influence/wfInfluenceSigning.directive.html',
			controllerAs: 'vm',
			controller: wfInfluenceSigningController,
			restrict: 'E',
			scope: {
				questionAnsweringManager: "=",
				measureAnsweringManager: "=",
				requirementFulfillmentManager: "=",
				influence: "=",
				showSigning: "=",
				hierVm: "="
			}
		};
		return directive;
	}

	wfInfluenceSigningController.$inject = ["$scope", "$attrs", "$element", "$parse", "$translate", "$ngBootbox", "$sanitize", "modalService", "$compile", "screenLoaderService", "$timeout", "apiProxy", "dataOperationsService", "pdfGenerator", "wfAuth", "wfObject", "$uibModal", "$state", "valueChainService" ];
	function wfInfluenceSigningController($scope, $attrs, $element, $parse, $translate, $ngBootbox, $sanitize, modal, $compile, screenLoader, $timeout, apiProxy, dataOps, pdfGenerator, wfAuth, wfObject, $uibModal, $state, valueChainService) {
		var
			vm = this,
			verifications,
			calculateFulfillment_delayed = _.throttle(calculateFulfillment, 2000),
			maybeCalculateFulfillment = function () {
				vm.calculatingFulfillment = true;
				calculateFulfillment_delayed();
			},
			contextParentWfid,
			fulfillmentCalcXhrRequest,
			xhrRequestByRequirementWfid = {}
		;
		
		if (vm.influence.isInternal && vm.influence.userId !== wfAuth.getWorldfavorUserId())
			vm.isInfluenceManageMode = true;
		else if (!vm.influence.isInternal && vm.influence.creatorOrganizationId == wfAuth.getOrganizationId())
			vm.isInfluenceManageMode = true;
		else
			vm.isInfluenceManageMode = false;

		vm.haveToSign = false;
		vm.submit = submit;
		vm.organizationId = vm.influence.organizationId;
		vm.mainStructure = vm.influence.childContent;
		vm.isFulfilled = vm.influence.fulfilled;
		vm.verificationDocuments = [];
		vm.calculateFulfillment = calculateFulfillment;
		vm.showMessageAfterSigning = showMessageAfterSigning;
		vm.goToDashboard = goToDashboard;
		vm.showPreviewInHtml = false;

		contextParentWfid = vm.influence.contextParentWfids &&  vm.influence.contextParentWfids.length ? vm.influence.contextParentWfids.split(',')[0] : undefined

		$scope.$on("questionAnswerChanged", function (event, data) {
			vm.haveToSign = true;
			// console.log('$scope.$on("questionAnswerChanged")', data);
			maybeSetAssessmentNeedOnItem(data);

			if (!vm.showSigning)
				maybeCalculateFulfillment();
		});

		$scope.$on("measureAnswerChanged", function (event, data) {
			vm.haveToSign = true;
			maybeSetAssessmentNeedOnItem(data);
			
			if (!vm.showSigning)
				maybeCalculateFulfillment();
		});

		$scope.$on("dataAnswerChanged", function (event, data) {
			vm.haveToSign = true;
			maybeSetAssessmentNeedOnItem(data);
			
			if (!vm.showSigning || data.shouldCalculateInfluenceFulfillment)
				maybeCalculateFulfillment();
		});

		$scope.$on("requirementFulfillmentChanged", function (event, data) {
			vm.haveToSign = true;

			if (!vm.showSigning)
				maybeCalculateFulfillment();
		});

		$scope.$on("requirementChanged", function (event, data) {
			maybeCalculateFulfillment();
		});

		$scope.$on("calculateFulfillment", function (event, data) {
			maybeCalculateFulfillment();
		});

		activate();

		function activate() {
			updateVerificationDocumentsList();

			if (vm.influence.userId) {
				vm.receiverUser = wfObject.get("100-" + vm.influence.userId);

				if (!vm.receiverUser) {
					dataOps.getObject("100-" + vm.influence.userId).then(function (res) {
						vm.receiverUser = res;
						$timeout();
					});
				}
			}

			$element.on("dragstart", "a.draggablePdf", function (e) {
				var dataTransfer = e.originalEvent.dataTransfer;

				if (dataTransfer && dataTransfer.setData)
					dataTransfer.setData("DownloadURL", $(this).data().downloadurl);
			});
		}

		function maybeSetAssessmentNeedOnItem(data) {
			if (data.requirement && data.requirement.rule === enums.requirementRule.manual) {
				if (xhrRequestByRequirementWfid[data.requirement.wfid])
					xhrRequestByRequirementWfid[data.requirement.wfid].abort();

				xhrRequestByRequirementWfid[data.requirement.wfid] = apiProxy.raw("fulfillment.setFulfillment", {
					influenceId: vm.influence.id,
					requirementId: data.requirement.id,
					fulfillmentState: data.forceFulfillmentState || enums.fulfillmentState.assessmentNeeded,
					
					objectType: _.get(data, "itemContent.type"),
					objectId: _.get(data, "itemContent.id")
				});

				xhrRequestByRequirementWfid[data.requirement.wfid].then(function (fulfillment) {
					// If the fulfillment is undefined it means that the fulfillment was unchanged from what it was before
					if (fulfillment) {
						var fulfillmentTargetItem = wfObject.filter({ where: { type: fulfillment.objectType, id: fulfillment.objectId } })[0];
						wfObject.inject(fulfillment);
						
						if (fulfillmentTargetItem)
							fulfillmentTargetItem.fulfillmentWfid = fulfillment.wfid;
						
						$scope.$emit("manualFulfillmentChanged");
					}
				});
			}
		}

		function updateVerificationDocumentsList() {
			return vm.verificationDocuments = valueChainService.getInfluenceVerificationDocuments(vm.influence);
		}

		function calculateFulfillment(hasSigned) {
			var id = _.uniqueId();
			vm.calculatingFulfillment = true;
			$timeout();
			
			if (fulfillmentCalcXhrRequest) {
				fulfillmentCalcXhrRequest.abort();
				fulfillmentCalcXhrRequest = undefined;
			}

			fulfillmentCalcXhrRequest = apiProxy.raw("fulfillment.calculate", {
				item: dataOps.prepareWfObject(vm.influence),
				hasSigned: hasSigned
			});

			fulfillmentCalcXhrRequest.then(function (fulfillmentResult) {
				fulfillmentCalcXhrRequest = undefined;
				vm.influence.fulfilled = vm.isFulfilled = fulfillmentResult.fulfillment.fulfills;
				vm.influence.fulfillmentProgress = fulfillmentResult.fulfillment.fulfillmentProgress;
				vm.influence.fulfillmentProgressTotal = fulfillmentResult.fulfillment.fulfillmentProgressTotal;
				vm.influence.fulfillmentProgressPercentage = fulfillmentResult.fulfillment.fulfillmentProgressPercentage;
				vm.influence.containsSpecialRequirements = fulfillmentResult.fulfillment.containsSpecialRequirements;
				vm.influence.fulfillmentState = fulfillmentResult.fulfillment.fulfillmentState;
				vm.influence.isAssessmentNeeded = fulfillmentResult.fulfillment.isAssessmentNeeded;
				vm.influence.isReportingNeeded = fulfillmentResult.fulfillment.isReportingNeeded;
				vm.influence.fulfillmentStatistics = fulfillmentResult.influence.fulfillmentStatistics;

				$timeout(function () {
					if (!fulfillmentCalcXhrRequest)
						vm.calculatingFulfillment = false;
				}, 700);
			});

			return fulfillmentCalcXhrRequest;
		}

		function areAllAnswersLikeRequired() {
			return vm.questionAnsweringManager.fulfillsAllLocally() && vm.measureAnsweringManager.fulfillsAllLocally() && vm.requirementFulfillmentManager.fulfillsAllLocally()
		}

		function submit($event) {
			var
				jqDf = $.Deferred(),
				message,
				allItemsLeft = [],
				questionsLeft,
				measuresLeft,
				anythingLeft,
				numbers
			;

			if (!areAllAnswersLikeRequired()) {
				message = [
					$translate.instant("modules.valueChain.influence.message_questionsLeft"),
					"",
					$translate.instant("modules.valueChain.influence.message_haveToFulfillQuestions"),
				].join("<br />");

				questionsLeft = vm.questionAnsweringManager.getLocalFulfillmentData().unfulfilledCompositeItems; // Unanswered questions with requirement rule "Manual assessment" are also included
				measuresLeft = vm.measureAnsweringManager.getLocalFulfillmentData().unfulfilledCompositeItems; // Unanswered measures with requirement rule "Manual assessment" are also included
				anythingLeft = vm.requirementFulfillmentManager.getLocalFulfillmentData().unfulfilledCompositeItems;

				Array.prototype.push.apply(allItemsLeft, questionsLeft)
				Array.prototype.push.apply(allItemsLeft, measuresLeft)
				Array.prototype.push.apply(allItemsLeft, anythingLeft)
				
				numbers = _.map(_.orderBy(allItemsLeft, "absoluteOrder"), function (itemComposite) {
					return "<b>" + $sanitize(itemComposite.numbering) + "</b>";
				});

				if (numbers.length > 1)
					message += _.take(numbers, numbers.length - 1).join(", &nbsp;") + " &nbsp;" + $translate.instant("And").toLowerCase() + " &nbsp;" + _.last(numbers);
				else
					message += numbers[0]

				message += ".";

				$ngBootbox.customDialog({
					title: $translate.instant("modules.valueChain.influence.message_header"),
					message: message,
					onEscape: true,
					buttons: {
						primary: {
							label: "OK",
							className: "btn-primary",
							callback: function() {

							}
						}
					}
				});
				return;
			}

			// dataOps.getObject({
			// 	objectId: 3530,
			// 	objectType: 67
			// }).then(function (verification) {


			// screenLoader

			// Fix

			// Creatif
			modal.verifyItem(vm.mainStructure, vm.influence).then(function (verification) {
				var
					verificationScope,
					compiledVerificationHtml,
					element
				;

				if (verification)
				{
					verificationScope = $scope.$new();
					compiledVerificationHtml = $compile('<wf-item template-id="52"></wf-item>'); // Generates a link function
					verificationScope.item = verification;
					vm.context = {
						templateId: 75,
						influence: vm.influence,
						isPrinting: true
					};
					vm.context.verification = verification
					if (!vm.showPreviewInHtml) {
						element = compiledVerificationHtml(verificationScope); // Executes link function using the new scope
						$scope.$on("verifiedContentLoaded", function (event, verifiedContent) {
							delete vm.context.verification;
							
							vm.haveToSign = false;
	
							screenLoader.show($translate.instant("modules.valueChain.influence.message_calculatingFulfillment"))

							calculateFulfillment(true).then(function (fulfillmentResult) {
								screenLoader.show($translate.instant("modules.valueChain.influence.message_generatingPdf"));

								pdfGenerator.fromElement(element, vm.influence.title + " " + moment().format("YYYY-MM-DD"), vm.influence.channelId).then(function (document) {
									// PdfGenerator succeeded
									dataOps.createSubItemRelation(verification, document, {
										kind: enums.subItemsKind.childrenByUser,
										contextParentWfid: contextParentWfid
									}).then(function (dr) {
										updateVerificationDocumentsList();
										
										$timeout();
	
										showMessageAfterSigning();
										screenLoader.hide();
										jqDf.resolve();
									});
								}).fail(function (res) {
									// PdfGenerator failed
									console.info("pdf failed", res);
	
									$timeout();
									screenLoader.hide();
									jqDf.resolve();
									
									$ngBootbox.customDialog({
										message: '<i class="fa fa-exclamation-circle bootbox-icon"></i><div class="bootbox-text">' + $translate.instant("modules.valueChain.influence.message_pdfFailed") + "</div>",
										closeButton: false,
										className: "centerWithIcon",
										buttons: {
											primary: {
												label: $translate.instant('OK'),
												className: "btn-primary"
											}
										}
									});
								});
							});
						});
					}
					else {
						delete vm.context.verification;
						vm.haveToSign = false;
						screenLoader.show($translate.instant("modules.valueChain.influence.message_calculatingFulfillment"))
						calculateFulfillment(true).then(function (fulfillmentResult) {
							if (vm.showPreviewInHtml) {
								showPreviewHtml(verificationScope).opened.then(function() {
									$timeout();
									screenLoader.hide();
									jqDf.resolve();
								});
							}
						});
					}
				}
				else
				{
					screenLoader.hide();
					jqDf.resolve(false);
				}
			})

			return jqDf.promise();
		}

		function goToDashboard() {
			vm.uibModalInstance.close();
			vm.uibModalInstance.closed.then(function() { $state.go('receivedNetworkInfluences', { networkWfid: "52-" + vm.influence.channelId }) });
		}

		function showMessageAfterSigning() {
			var 
				templateHtml,
				buttonHtml = '<button class="btn btn-primary btn-bg" wf-click="$close()">' + $translate.instant("modules.report.messageAfterSigning.closeButtonText") + '</button>',
				unansweredRequests,
				unansweredRequestText,
				unansweredRequestHtml = ""
			;

			dataOps.getObject({
				objectId: 10489, // Structure containing external reporting influences.
				objectType: 71
			}).then(function (reportingStructure) {
				unansweredRequests = _.filter(reportingStructure.childs, function(relation) {
					var influence = relation.childContent;

					if (!influence.fulfilled) {
						// Check for influences where sub-entities are added (Production sites etc using InfluenceGrouper).
						// Those influence does not ever have fulfilled true but can be checked using the below properties.
						// If something has been added in the InfluenceGrouper UI, the fulfillmentProgress will be >= 1. The fulfillmentProgressTotal is not applicable for those kind of influences and will be null.
						if (influence.fulfillmentProgress && !influence.fulfillmentProgressTotal) // At least one item was added with InfluenceGrouper and the influence is considered fulfilled for this scenario.
						{
							return false;
						}
						else if (influence.fulfillmentState === enums.fulfillmentState.assessmentNeeded) {
							return false;
						}
					
						return true;
					};
				});
				

				if (unansweredRequests && unansweredRequests.length > 0) {
					buttonHtml = '<button class="btn btn-primary btn-bg" wf-click="vm.goToDashboard()">' + $translate.instant("modules.report.messageAfterSigning.dashboardButtonText") + '</button>';
					unansweredRequestText = unansweredRequests.length === 1 ? $translate.instant("modules.report.messageAfterSigning.unansweredRequests.unansweredRequestsSingular", { count: unansweredRequests.length }) : $translate.instant("modules.report.messageAfterSigning.unansweredRequests.unansweredRequestsPlural", { count: unansweredRequests.length });
					unansweredRequestHtml = '<div class="unanswered-requests-wrapper"><span>' + $translate.instant("modules.report.messageAfterSigning.unansweredRequests.preSentence") + '</span><span class="unanswered-requests">' + unansweredRequestText + '</span><div>';
				}
	
				templateHtml = 
					'<div class="modal-header">' + 
						'<img src="assets/img/confetti.png" alt="">' +
					'</div>' +
					'<div class="modal-body">' + 
						'<div class="message">' +
							'<h3 class="modal-title">' + $translate.instant("modules.report.messageAfterSigning.headerMessage") + '</h3>' +
							'<p class="message-text">' + $translate.instant("modules.report.messageAfterSigning.completedMessage") + '</p>' +
							unansweredRequestHtml + 
						'</div>' + 
					'</div>' + 
					'<div class="modal-footer">' + buttonHtml + '</div>'
				;
	
				vm.uibModalInstance = $uibModal.open({
					onEscape: false,
					keyboard: false,
					animation: true,
					size: 'width-500',
					windowClass: 'request-completed',
					backdrop: 'static',
					scope: $scope,
					template: templateHtml
				});
			});
		}

		function showPreviewHtml(scope) {
			var modalInstance = $uibModal.open({
				size: 'width-1200',
				windowClass: "wf-export-pdf-modal",
				animation: true,
				backdrop: 'static',
				template: '<div><div class="header" style="display: inline-block;"><button type="button" class="close-modal-button close" wf-click="$close()" aria-hidden="true">Close</button></div><div class="pdf-preview-html">'
							+ '<wf-item template-id="52"></wf-item>'
						+ '</div></div>',
				scope: scope
			});

			modalInstance.closed.then(function(result) {
				scope.$destroy();
				modalInstance = undefined;
			}, function(rejectedResult) {
				scope.$destroy();
				modalInstance = undefined;
				console.error("Rejected result modal - ", rejectedResult);
			});

			return modalInstance;
		}
	}
})();
