import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfAccessTag', wfAccessTag);

	wfAccessTag.$inject = [];
	function wfAccessTag() {
		var directive = {
			controller: wfAccessTagController,
			controllerAs: 'accessTagVm',
			templateUrl: 'scripts/wf/accessTags/wfAccessTag.directive.html',
			restrict: 'E'
		};

		return directive;
	}

	wfAccessTagController.$inject = ['$scope', '$element', 'dataOperationsService', '$timeout', 'wfAuth', 'modalService', '$parse', '$attrs', '$translate']
	function wfAccessTagController ($scope, $element, dataOps, $timeout, wfAuth, modal, $parse, $attrs, $translate) {
		var
			vm = this,
			settings = $parse($attrs.settings)($scope),
			permissionNames = [ "canAccess", "canCreate", "canRead", "canUpdate", "canDelete" ],
			item,
			accessTags,
			defaultAccessTag,
			orgAccessTag,
			userAccessTag,
			forOrganizationId,
			userAdminMode = false,
			forUserId = null,
			specifiedForOrg = {}, // The permissions that are explicitly defined for the organiation
			specifiedForUser = {}, // The permissions that are explicitly defined for the user
			busy,
			permissionsPrototype = {
				canAccess: true,
				canCreate: true,
				canRead: true,
				canUpdate: true,
				canDelete: true
			},

			// Permission will be set from accessTags.
			// If a permission value is a boolean it will override its parent permission, otherwise the parent's permission value will be used.
			//
			// Inheritance tree example:
			// defaultPermissions          { canAccess: true,           canCreate: true  }
			//  |_ organizationPermissions { canAccess: undefined/null, canCreate: false }
			//      |_ actualPermissions   { canAccess: true,           canCreate: false }
			//
			// Only admin (Worldfavor) can set permissions on organizations.
			// Organizations can set permissions on its users but ONLY denying permissions.
			// Meaning that if an organization have canCreate:false, it can not set canCreate:true on their users.

			defaultPermissions = _.clone(permissionsPrototype), // Default permissions. All initial values are true but will be set from the values of the default accessTag if it exists
			organizationPermissions = {}, // Organization permissions from org accessTag if it exists
			userPermissions = {}, // User permissions from user accessTag if it exists
			actualOrgPermissions = {}, // Actual permissions (default + organization)
			actualPermissions = {} // Actual permissions (default + organization + user)
		;

		if (settings) {
			item = $parse($attrs.item)($scope) || settings.item;

			forOrganizationId = settings.forOrganizationId;
			forUserId = settings.forUserId || null;

			userAdminMode = forUserId > 0;
		}
		else {
			$element.remove();
			return;
		}

		_.assign(vm, {
			userAdminMode: userAdminMode,
			hasOrganizationPermissions: false, // Indicates if specific permissions for the organization exists
			hasUserPermissions: false, // Indicates if specific permissions for the user exists

			defaultPermissions: defaultPermissions,
			organizationPermissions: organizationPermissions,
			userPermissions: userPermissions,
			specifiedForOrg: specifiedForOrg,
			specifiedForUser: specifiedForUser,
			actualOrgPermissions: actualOrgPermissions,
			actualPermissions: actualPermissions,

			compiler: {},

			// Functions
			setPermission: setPermission
		})

		activate();

		function activate() {
			accessTags = item.accessTags;

			if (accessTags.length) {
				defaultAccessTag = _.find(accessTags, { organizationId: null });
				orgAccessTag = _.find(accessTags, { organizationId: forOrganizationId, userId: null });


				if (defaultAccessTag) {
					assignPermissionsFromAccessTag(defaultPermissions, defaultAccessTag);
				}

				if (orgAccessTag) {
					vm.hasOrganizationPermissions = true;
					assignPermissionsFromAccessTag(organizationPermissions, orgAccessTag);
				}

				if (forUserId) {
					userAccessTag = _.find(accessTags, { organizationId: forOrganizationId, userId: forUserId });

					if (userAccessTag) {
						vm.hasUserPermissions = true;
						assignPermissionsFromAccessTag(userPermissions, userAccessTag);
					}
				}
			}

			updateActualPermissions();
			
			_.assign(specifiedForOrg, _.mapValues(permissionsPrototype, function (value, key) {
				return typeof organizationPermissions[key] === "boolean";
			}));
			
			_.assign(specifiedForUser, _.mapValues(permissionsPrototype, function (value, key) {
				return typeof userPermissions[key] === "boolean";
			}));
		}

		function updateActualPermissions() {
			_.assign(actualOrgPermissions, _.assignWith(_.clone(defaultPermissions), organizationPermissions, function (objValue, srcValue) {
				return typeof srcValue === "boolean" ? srcValue : objValue;
			}));

			if (vm.userAdminMode) {
				_.assign(actualPermissions, _.assignWith(_.clone(defaultPermissions), organizationPermissions, userPermissions, function (objValue, srcValue) {
					return typeof srcValue === "boolean" ? srcValue : objValue;
				}));
			}
			else {
				_.assign(actualPermissions, actualOrgPermissions);
			}
		}

		function assignPermissionsFromAccessTag(target, source) {
			for (var i = 0, len = permissionNames.length, key, value; i < len; i++) {
				key = permissionNames[i],
				value = source[key];

				if (typeof value === "boolean") { // Value will only be set if it is a boolean
					target[key] = value;
				}
			}
		}

		function setPermission(permissionKey, use, value) {
			if (busy)
				return;

			if (use === "default") {
				specifiedForOrg[permissionKey] = false;
				organizationPermissions[permissionKey] = null;
			}
			else if (use === "organization") {
				specifiedForOrg[permissionKey] = true;
				organizationPermissions[permissionKey] = value;
			}
			else if (use === "organization-actual") {
				specifiedForUser[permissionKey] = false;
				userPermissions[permissionKey] = null;

				if (permissionKey === 'canAccess') [
					_.each(permissionNames, function(name) {
						if (name !== permissionKey) {
							specifiedForUser[name] = false;
							userPermissions[name] = null;
						}
					})
				]
			}
			else if (use === "user") {
				specifiedForUser[permissionKey] = true;
				userPermissions[permissionKey] = value;

				if (permissionKey === 'canAccess' && value === false) [
					_.each(permissionNames, function(name) {
						if (name !== permissionKey) {
							specifiedForUser[name] = true;
							userPermissions[name] = false;
						}
					})
				]
			}

			updateActualPermissions();

			vm.compiler.compile();

			if (userAdminMode) {
				saveUserAccessTag();
			}
			else {
				saveOrganizationAccessTag()
			}
		}

		function saveOrganizationAccessTag() {
			var
				promise,
				anyPermissionsSpecified = _.includes(specifiedForOrg, true)
			;

			_.assign(orgAccessTag, organizationPermissions);


			if (orgAccessTag) {
				if (!anyPermissionsSpecified) {
					promise = dataOps.destroy(orgAccessTag).then(function () {
						orgAccessTag = undefined;
					});
				}
				else
					promise = dataOps.update(orgAccessTag);
			}
			else {
				if (anyPermissionsSpecified) {
					promise = dataOps.create(_.assign({
						type: enums.objectType.accessTag,
						objectId: item.id,
						objectType: item.type,
						organizationId: forOrganizationId,
						userId: null
					}, organizationPermissions)).then(function (res) {
						orgAccessTag = res;
					});
				}
			}

			if (promise) {
				busy = true;
				$element.addClass("saving");
				
				promise.then(function () {
					busy = false;
					$element.removeClass("saving");
				})
			}
		}

		function saveUserAccessTag() {
			var
				promise,
				anyPermissionsSpecified = _.includes(specifiedForUser, true)
			;

			_.assign(userAccessTag, userPermissions);


			if (userAccessTag) {
				if (!anyPermissionsSpecified) {
					promise = dataOps.destroy(userAccessTag).then(function () {
						userAccessTag = undefined;
					});
				}
				else
					promise = dataOps.update(userAccessTag);
			}
			else {
				if (anyPermissionsSpecified) {
					promise = dataOps.create(_.assign({
						type: enums.objectType.accessTag,
						objectId: item.id,
						objectType: item.type,
						organizationId: forOrganizationId,
						userId: forUserId
					}, userPermissions)).then(function (res) {
						userAccessTag = res;
					});
				}
			}

			if (promise) {
				busy = true;
				$element.addClass("saving");
				
				promise.then(function () {
					busy = false;
					$element.removeClass("saving");
				})
			}
		}
	}
})();
