import React from 'react'
import { useBeforeUnload } from '@worldfavor/hooks'
import { injectIntl } from 'react-intl'
import { Prompt } from 'react-router-dom'

const LeavePrompt = (props) => {
  const { children, when, message, intl } = props
  const defaultMessage = intl.formatMessage({ id: 'dialog.defaultLeavePromptMessage' })

  useBeforeUnload(when, message || defaultMessage)

  return (
    <React.Fragment>
      <Prompt when={when} message={message || defaultMessage} />
      { children }
    </React.Fragment>
  )
}

export default injectIntl(LeavePrompt)
