import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfInfluenceStatus', wfInfluenceStatus);

	wfInfluenceStatus.$inject = [];
	function wfInfluenceStatus() {
		var directive = {
			templateUrl: 'scripts/wf/influence/wfInfluenceStatus.directive.html',
			controllerAs: 'influenceStatusVm',
			controller: wfInfluenceStatusController,
			restrict: 'E'
		};
		return directive;
	}

	wfInfluenceStatusController.$inject = [ "$scope", "$attrs", "$element", "$parse", "$translate" ];

	function wfInfluenceStatusController($scope, $attrs, $element, $parse, $translate) {
		var
			vm = this,
			influence
		;

		influence = $parse($attrs.item)($scope);

		_.assign(vm, {
			influence: influence,
			showDueDate: !influence.fulfilled && influence.fulfillmentDueAt && !influence.isOverdue,
			showUnfulfilledProgressIndicator: !influence.fulfilled && influence.fulfillmentProgress < influence.fulfillmentProgressTotal,
			showProgressNumber: influence.fulfillmentProgress && typeof influence.fulfillmentProgressTotal !== "number",
			showOverdue: !influence.fulfilled && influence.isOverdue,
			fulfillmentStatistics: undefined,
			reminderEmailMaxDaysBeforeDue: 28,
		});

		activate();

		function activate() {
			if (influence.fulfillmentStatistics)
				setStatistics();

			if (vm.showDueDate || vm.showOverdue)
				vm.dueAt_formatted = moment(influence.fulfillmentDueAt).format('YYYY-MM-DD');

			if (vm.showUnfulfilledProgressIndicator) {
				vm.progressTooltip = influence.fulfillmentProgress + "/" + influence.fulfillmentProgressTotal + " (" + influence.fulfillmentProgressPercentage + "%)";
			}
			else if (vm.showProgressNumber) {
				// vm.progressTooltip = $translate.instant("");
			}

			if (!vm.influence.mailSentCount
				&& !vm.influence.fulfilled
				&& !vm.showDueDate
				&& !vm.showUnfulfilledProgressIndicator
				&& !vm.showProgressNumber
				&& !vm.showOverdue
				&& !influence.containsSpecialRequirements
			) {
				$element.remove();
			}
			setStateIconClass()

		}

		function setStateIconClass() {
			//get today's date
			const today = new Date ()

			//get date in 28 days after dueDate
 			let reminderEmailMinDate = new Date (vm.influence.fulfillmentDueAt)
			reminderEmailMinDate.setDate(reminderEmailMinDate.getDate() - vm.reminderEmailMaxDaysBeforeDue)

			//convert DueDate to js Date format
			let dueDate = new Date (vm.influence.fulfillmentDueAt)

			if(!influence.fulfilled) {
				if(vm.influence.mailSentCount === 0) {
					vm.stateIconClass = "fa fa-sign-out fa-rotate-270 notFulfilled"
					vm.stateTooltip =  $translate.instant("fulfillmentStates.invitationNotSent")
				}

				if (vm.influence.mailSentCount > 0
					&& ((today <= dueDate && today >= reminderEmailMinDate)
					|| today.getDate() === dueDate.getDate())) {
					vm.stateIconClass =  "fa fa-bell notFulfilled deadlineSoon";
					vm.stateTooltip =  `${$translate.instant("fulfillmentStates.deadlineSoon")}:  ${vm.dueAt_formatted}`
				}
			}
		}
		function setStatistics() {
			defineFulfillmentStatuses();

			var state, stats = vm.influence.fulfillmentStatistics;

			_.each(stats, function(value, key) {
				state = undefined;
				state = _.find(vm.fulfillmentStatistics, { "kind": parseInt(key) });

				if (state)
					state.count = value;
				else
					console.error("Couldn't find the state");
			});
		}

		function defineFulfillmentStatuses() {
			if (!vm.fulfillmentStatistics) {
				vm.fulfillmentStatistics = [
					{
						kind: enums.fulfillmentState.fulfilled,
						icon: "fas fa-check",
						title: $translate.instant("Fulfilled"),
						count: 0,
						cssClass: "fulfilled"
					},
					{
						kind: enums.fulfillmentState.assessmentNeeded,
						icon: "fas fa-user-check",
						title: $translate.instant("fulfillmentStates.assessmentNeeded"),
						count: 0,
						cssClass: "assessment-needed"
					},
					{
						kind: enums.fulfillmentState.unfulfilled,
						icon: "fas fa-times",
						title: $translate.instant("NotFulfilled"),
						count: 0,
						cssClass: "unfulfilled"
					},
					{
						kind: enums.fulfillmentState.reportingNeeded,
						icon: "fas fa-user-edit",
						title: $translate.instant("fulfillmentStates.toBeReported"),
						count: 0,
						cssClass: "reporting-needed"
					},
					{
						kind: enums.fulfillmentState.expired,
						icon: "fa fa-exclamation-triangle",
						title: $translate.instant("fulfillmentStates.expired"),
						count: 0,
						cssClass: "expired"
					},
					{
						kind: enums.fulfillmentState.certificateExpired,
						icon: "fa fa-exclamation-triangle",
						title: $translate.instant("fulfillmentStates.certificateExpired"),
						count: 0,
						cssClass: "certificateExpired"
					}
				];
			}
		}
	}
})();
