// @flow
import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TreeView from '../Tree/TreeView'
import PanZoom from 'react-easy-panzoom'
import { FormattedMessage } from 'react-intl'
import DeleteDialog from '@worldfavor/components/Dialog/DeleteDialog'

type Props = {
  open: boolean,
  organization: {
    name: string,
  },
  nodeId: string,
  nodes: { [string]: Object },
  edges: Array<Object>,
  organizations: Array<Object>,
  onCancel: () => void,
  onRemove: (event: SyntheticEvent<HTMLButtonElement>) => void,
  deleting?: boolean,
}

const useStyles = makeStyles(theme => ({
  organizationName: {
    fontWeight: theme.typography.fontWeightMedium,
    color: 'white',
  },
  treeContainer: {
    marginBottom: 8,
    maxHeight: 400,
    maxWidth: 700,
  },
}))

const DeleteOrganizationNodeDialog = (props: Props) => {
  const { open, organization: { name } = {}, nodeId, nodes, edges, organizations, onCancel, onRemove, deleting } = props
  const classes = useStyles(props)
  const panZoom = useRef()
  const hasSubtree = edges.some(({ fromWfid }) => fromWfid === nodeId)

  function onTreeMounted() {
    if (panZoom.current) {
      panZoom.current.autoCenter()
    }
  }

  return (
    <DeleteDialog
      open={open}
      title={(
        <FormattedMessage
          id={`supplyChain.mapper.removeNode.${hasSubtree ? 'multiple' : 'one'}.title`}
          values={{ companyName: name }}
        />
      )}
      subtitle={(
        <FormattedMessage
          id={`supplyChain.mapper.removeNode.${hasSubtree ? 'multiple' : 'one'}.description`}
          values={{
            companyName: <span className={classes.organizationName}>{name}</span>,
          }}
        />
      )}
      onCancel={onCancel}
      onDelete={onRemove}
      deleting={deleting}
    >
      <div className={classes.treeContainer}>
        <PanZoom
          ref={panZoom}
          maxZoom={1}
          minZoom={0.30}
          disabled={!hasSubtree}
          autoCenter
          style={{ overflow: 'hidden', maxHeight: 400 }}
        >
          <TreeView
            id={nodeId}
            nodes={nodes}
            organizations={organizations}
            edges={edges}
            onTreeMounted={onTreeMounted}
          />
        </PanZoom>
      </div>
    </DeleteDialog>
  )
}

export default DeleteOrganizationNodeDialog
