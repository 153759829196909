import React from 'react'
import { IntlProvider } from 'react-intl'
import { getLocale, getMessages } from '@worldfavor/i18n'
import ErrorBoundary from './ErrorBoundary'
import CssBaseline from './CssBaseline'
import Navbar from './Navbar'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCulture } from '@worldfavor/portal/selectors/appSelector'
import { setToken } from '@worldfavor/portal/actions/appActions'

const App = () => {
  const culture = useSelector(state => getCulture(state))
  const locale = getLocale(culture)

  return (
    <IntlProvider key={locale} locale={locale} messages={getMessages(locale)}>
      <ErrorBoundary>
        <CssBaseline />
        <Navbar />
        <AuthenticationHandler />
      </ErrorBoundary>
    </IntlProvider>
  )
}

const AuthenticationHandler = () => {
  // This is a temporary solution to load the token and user info in the state
  // to be removed when we implement the full authentication process in react
  const app = useSelector(state => state.app)
  const dispatch = useDispatch()
  const token = localStorage.getItem('id_token')
  if (!app.token && token) {
    dispatch(setToken(token))
  }
  return null
}

export default withRouter(App)
