import * as enums from '@worldfavor/constants/enums'
import { getValidFileTypesString } from '@worldfavor/portal/scripts/file-upload/validUploadFileTypes'

(function () {
	'use strict';

	angular
		.module('wf.data')
		.service('formSchemaService', formSchemaService);

	formSchemaService.$inject = [ '$uibModal', '$ngBootbox', '$translate', '$q', '$timeout', 'apiProxy', 'wfAuth', 'dataOperationsService' ];

	function formSchemaService($uibModal, $ngBootbox, $translate, $q, $timeout, apiProxy, wfAuth, dataOps) {
		var deferredLoadingByType = {};
		var specificationCacheByType = {}
		var service = {
			getFromType: getFromType,
			beforeSave: beforeSave,
			getNetworkMemberOrganizationUsersSchema: getNetworkMemberOrganizationUsersSchema,
		};
		var
			regularExpressions = {
				email: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
				swedishOrganizationNumber: /^(SE){0,1}[0-9]{12}$/,

				// Regexp that finds the first valid number, with our without decimals, delimited either by "," or "."
				// Basically: (digits) optionally followed by (. or , and digits)
				// Valid examples: 15000   120,0   -120,9482   120.0   -120.9482
				// Invalid examples: 15 000   15.000.000   -15.000,0   15,000.0   -15 000,396
				decimalNumber: /-?\d+(?:(?:\.|\,)\d{1,})?/
			}

		activate();

		return service;

		// function

		function getFromType(type, options) {
			let specialFormats = {};
			var jqDf = $.Deferred();

			if (specificationCacheByType[type]) {
				jqDf.resolve(getSpecificationFromCache(type, options));
			}
			else
			{
				if (type in deferredLoadingByType) {
					return deferredLoadingByType[type].promise();
				}
				else {
					deferredLoadingByType[type] = jqDf;

					apiProxy('utility.getSchema', type).then(function (res) {
						var
							form = ['*'],
							multilingualSchema,
							multilingualProperties,
							promise
						;

						if (res.allOf && res.allOf.length > 0 && res.allOf[0]["format"] === "imageObject") {
							_.assign(res.properties, res.allOf[0].properties);
						}

						// for (var i = 0, len = res.properties.length; i < len; i++) {
							// console.log(res.properties);
						for (var key in res.properties) {
							if (res.properties.hasOwnProperty(key)) {
								var
									element = res.properties[key],
									oneOfProp,
									xSchemaForm = {},
									propertiesWithTextarea = [
										'text',
										'description',
										'instructions',
										'guidance',
										'license',
										'bodyText',
										'callToActionText',
										'endingText',
										'comment'
									]
								;

								if (_.includes(propertiesWithTextarea, key))
								{
									xSchemaForm.type = 'textarea';
								}
								else if (key == 'year')
								{
									xSchemaForm = {
										type: 'select',
										titleMap: (function () {
											var
												output = [],
												currentYear = new Date().getFullYear() + 2,
												yearsBack = 32,
												year
											;

											for (var i = 0; i <= yearsBack; i++) {
												year = (currentYear - i).toString();
												output.push({ value: year, name: year });
											}

											return output;
										}())
									};
								}
								else if (element.format == 'date-time')
								{
									element.format = 'date';
									element.type = 'string';

									xSchemaForm = {
										// Needed because it is initialy "Field does not validate" if form is rendered in wfInlineAnswering component
										validationMessage: $translate.instant("validationMessages.required")
									}

									// Below doesn't work. Added instead in bootstap-datepicker.js line 56 & 57.
									// element.pickadate = { selectYears: true };
									// element["x-schema-form"] = {
									// 	pickadate: { selectYears: true }
									// };
								}
								else if (key == 'country')
								{
									promise = apiProxy('utility.getCountriesDropdownDefinition', { culture: wfAuth.getCulture() }).then(function (countries) {
										res.properties.country["x-schema-form"] = {
											type: 'select',
											titleMap: countries
										};
									});
								}
								else if (element.format == 'currentOrganization-users')
								{
									(function (element) {
										promise = dataOps.getObject({
											objectId: 322825, // Contains the current organization's users
											objectType: enums.objectType.dataRelation
										}).then(function (colleaguesStructureDataRelation) {
											var
												users = _.sortBy(colleaguesStructureDataRelation.childContent.childs, "childContent.name"),
												titleMap = []
											;

											_.forEach(users, function (item) {
												var user = item.childContent;
												if (user.worldfavorUserId > 0)
													titleMap.push({ value: user.worldfavorUserId, name: user.name + (user.email.length && user.given_name && user.given_name.length ? " (" + user.email + ")" : "") });
											});

											element["x-schema-form"] = {
												type: 'select',
												titleMap: titleMap
											};
										});
									})(element);
								}
								else if (element.format === 'networkMemberOrganization-users')
								{
									xSchemaForm = getNetworkMemberOrganizationUsersSchema(options.model)
									specialFormats[element.format] = key;
								}
								else if (element.format === 'mailSendoutStates') {
									xSchemaForm = getMailSendoutStateSchema(options.model);
									specialFormats[element.format] = key;
								}
								else if (element.format == 'measure-units')
								{
									(function (element) {
										promise = dataOps.getSubItems({ id: 12102, type: enums.objectType.structure }, enums.subItemsKind.children).then(function(res) {
											var
												units = _.chain(res).map("childContent").orderBy([ "quantityType", "name" ]).value(),
												titleMap = []
											;

											_.forEach(units, function (unit) {
												titleMap.push({ value: unit.id, name: unit.name + (unit["symbol"] && unit["symbol"].length ? " (" + unit["symbol"] + ")" : "") });
											});

											element["x-schema-form"] = {
												type: 'select',
												titleMap: titleMap
											};
										});
									})(element);
								}
								else if (key == 'gln' && type === enums.objectType.productionSite)
								{
									element.title = "glnInputLabelDescription";
								}

								if (key == 'culture')
								{
									xSchemaForm = {
										type: 'select',
										titleMap: [ "sv-SE", "en-US", "cs-CZ", "es-ES", "zh-Hans", "pl-PL", "de-DE", "nb-NO", "da-DK", "fi-FI", "fr-FR", "pt-PT", "af-ZA", "it-IT", "hu-HU", "vi-VN" ].map(x => {
											const language = $translate.instant("locales." + x);

											if (x === "sv-SE") {
												// Swedish interface language and preferred for received requests.
												return { value: x, name: $translate.instant("languages.settings.sameInterfaceAndPreferredContentLanguage", { language }) };
											}
											else if (x === "en-US") {
												// English interface language and preferred for received requests.
												return { value: x, name: $translate.instant("languages.settings.sameInterfaceAndPreferredContentLanguage", { language }) };
											}
											else {
												// Some interface language. Another preferred for received requests.
												return { value: x, name: $translate.instant("languages.settings.differentInterfaceAndPreferredContentLanguage", { language_ui: $translate.instant("locales.en-US"), language_content: language }) };
											}
										})
									};
								}

								if (key == 'employeeCountSpan') {
									xSchemaForm = {
										type: 'select',
										titleMap: [
											{ value: undefined, name: "" },
											{ value: 1, name: "0 - 9" },
											{ value: 2, name: "10 - 49" },
											{ value: 3, name: "50 - 249" },
											{ value: 4, name: "250 - 499" },
											{ value: 5, name: "500 - 999" },
											{ value: 6, name: "1000 - 4999" },
											{ value: 7, name: "5000 - 9999" },
											{ value: 8, name: "10000+" }
										]
									};
								}

								if (key == 'deliveryMethod')
								{
									xSchemaForm = {
										type: 'select',
										titleMap: [
											{ value: 0, name: $translate.instant("Email") },
											{ value: 1, name: $translate.instant("modules.eCommerce.checkout.deliveryByPost") },
										]
									};
								}

								if (key == 'countryCode')
								{
									xSchemaForm = {
										type: 'select',
										titleMap: [
											{ value:  "SE", name: "SE"}
										]
									};
								}

								if (key == 'severity') {
									xSchemaForm = {
										type: 'select',
										titleMap: [
											{ value: 0, name: $translate.instant("modules.findings.severities.observation") },
											{ value: 1, name: $translate.instant("modules.findings.severities.minor") },
											{ value: 2, name: $translate.instant("modules.findings.severities.major") },
											{ value: 3, name: $translate.instant("modules.findings.severities.critical") },
											{ value: 4, name: $translate.instant("modules.findings.severities.zeroTolerance") }
										]
									};
								}

								if (element.format === 'singlefile' || element.format === 'multifile') {
									element.type = 'array';

									if (key == "imageUpload") {
										element.pattern = {
											"mimeType":          "image/*"
											// "validationMessage": "Falscher Dateityp: "
										};
										if (type == enums.objectType.sustainabilityRoom) {
											xSchemaForm.title = element.title = "BackgroundImage"
										}
										else if (type == enums.objectType.organization) {
											xSchemaForm.title = element.title = $translate.instant('modules.organization.introModal.form.logo')
										}
										else
											xSchemaForm.title = element.title = "Image"

										xSchemaForm.endpoint = $.proxies.baseUrl +  "api/upload/image";
										xSchemaForm.uploadType = 'image';
									}
									else {
										element.pattern = {
											"mimeType": getValidFileTypesString()
											// "validationMessage": "Falscher Dateityp: "
										};
										xSchemaForm.endpoint = $.proxies.baseUrl +  "api/upload/document";
										xSchemaForm.uploadType = 'file';
									}
								}

								if (element.format === 'hidden' && key !== "unitId") {
									xSchemaForm.condition = 'false'
								}

								if (element.format === 'email') {
									// element.pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/g;
									// xSchemaForm.validationMessage = $translate.instant("validationMessages.email");
									xSchemaForm.validationMessage = {
										email: $translate.instant("validationMessages.email")
									};
									xSchemaForm.$validators = {
										email: function (value) {
											return !value || !value.length || regularExpressions.email.test(value)
										}
									}
								}

								if (type === 1000 && key === "password") {
									const passwordStrengthRexExp = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)
									xSchemaForm.validationMessage = {
										strength: $translate.instant("validationMessages.password")
									};
									xSchemaForm.$validators = {
										strength: function(value) {
											return !value || (value.length >= 8 && value.match(passwordStrengthRexExp))
										}
									}
								}

								if (element.format === 'measureAnswerValue') {
									element.type = 'string';
								}

								if (type === enums.objectType.measureAnswer && key === "value") {
									element.format === 'measureAnswerValue'
									element.type = 'string';
								}

								if (type === enums.objectType.finding)
								{
									if (key === "closed") {
										xSchemaForm.onChangeExtended = function (value, form, model) {
											if (value) {
												if (!model.closedAt)
													model.closedAt = moment().format();
											}
										}
									}

									if (key === "closedAt") {
										xSchemaForm.condition = 'model.closed';
										xSchemaForm.required = true;
										xSchemaForm.maxDate = new Date();
									}
								}

								if (type === enums.objectType.dateItem && key == 'date') {
									element.title = "Date";
								}

								if (element.title)
									xSchemaForm.title = element.title = $translate.instant(element.title);

								// xSchemaForm.title = $translate.instant("Value");

								// console.log(element.title, xSchemaForm.title);
								// console.log($translate.instant("Value"));

								element["x-schema-form"] = xSchemaForm;


								if (element.format == 'multilingual')
								{
									if (!multilingualSchema)
									{
										multilingualSchema = angular.copy(res);
										multilingualSchema.properties = {};
										multilingualProperties = [];
									}
									multilingualSchema.properties[key] = element;
									multilingualProperties.push(key);
								}
								// if (element.oneOf)
								// {
								// 	oneOfProp = _.filter(element.oneOf, { $ref: '#/definitions/TextTranslationsViewModel' })[0];
								// 	if (oneOfProp) {
								// 		res.properties[key] = {
								// 			type: 'object',
								// 			items: { properties: res.definitions.TextTranslationsViewModel.properties }
								// 		}
								// 	}
								// }

								// if (element.format == 'multilingual')
								// {

								// }
								// console.log(key, element);

								// var prop = res.properties[i];
								// form.push({
								// 	key: key,
								// 	type:
								// });
							}
						}

						if (multilingualSchema && multilingualSchema.required)
							multilingualSchema.required = [];

						if (type === enums.objectType.measureAnswer) {
							form = [ "value" ];
						}
						// }
						specificationCacheByType[type] = {
							schema: res,
							form: form,
							isMultilingual: res.format === "multilingualObject",
							multilingualSchema: multilingualSchema,
							multilingualProperties: multilingualProperties,
							specialFormats: specialFormats,
						};

						// console.log(specificationCacheByType[type]);

						if (promise) {
							promise.then(function () {
								// console.log(specificationCacheByType[type]);
								jqDf.resolve(getSpecificationFromCache(type, options));
							});
						}
						else
							jqDf.resolve(getSpecificationFromCache(type, options));
					});
				}

			}


			return jqDf.promise();
		}

		function activate()
		{
			specificationCacheByType[enums.objectType.embed] = {
				schema: {
					'type': 'object',
					'properties': {
						'dummyProperty': {
							'type': 'object',
							'format': 'wfEmbedly'
						}
					}
				},
				form: ["*"]
			}
			// specificationCacheByType[enums.objectType.location] = {
			// 	schema: {
			// 		'type': 'object',
			// 		'properties': {
			// 			'dummyProperty': {
			// 				'type': 'object',
			// 				'format': 'wfMap'
			// 			}
			// 		}
			// 	},
			// 	form: ["*"]
			// }

			specificationCacheByType[enums.objectType.objectSetting] = {
				schema: {
					'type': 'object',
					'properties': {
						'items': {
							'type': 'array',
							'title': '',
							'items': {
								'title': '',
								'type': 'object',
								'properties': {
									'settingKind': { 'type': 'string' },
									'value': { 'type': 'string' }
								}
							}
						}
					}
				},
				form: [{
					key: 'items',
					title: '',
					add: '',
					style: {
						add: 'btn-default'
					},
					htmlClass: 'row clearfix',
					items: [
						{
							key: 'items[].settingKind',
							type: 'select',
							htmlClass: 'col-sm-5',
							title: '',
							placeholder: 'Choose setting...',
							titleMap: [
								{ value: 'templateId', name: 'TemplateID' },
								{ value: 'gridColumns', name: 'Grid columns' },
								{ value: 'gridRows', name: 'Grid rows' },
								{ value: 'dataRelation', name: 'Tagged' },
								{ value: 'dataRelationByUser', name: 'TaggedByUser' },
								//{ value: 'objectType', name: 'Object type' },
								{ value: 'objectTypes', name: 'Object type' },
								{ value: 'childrenOpeningMode', name: 'Children opening mode' },
								{ value: 'cssClass', name: 'CSS class' },
								{ value: 'settingsBundle', name: 'Appearance' },
								{ value: 'childrenSettingsBundle', name: 'Children appearance' },
								{ value: 'childrenLayoutMode', name: 'Children layout mode' },
								{ value: 'childrenTemplateId', name: 'ChildrenTemplateID' },
								{ value: 'includeChildrensRelatedContent', name: 'Include related content on each child' },
								{ value: 'includeChildrensParents', name: 'Include parents on each child' },
								{ value: 'includeOrganizationsInfluences', name: 'Include organizations influences' },
								{ value: 'includeOrganizationsUsers', name: 'Include organizations users' },
								{ value: 'objectId', name: 'Object ID' },
								{ value: 'color1', name: 'Color 1' },
								{ value: 'color2', name: 'Color 2' },
								{ value: 'color3', name: 'Color 3' },
								{ value: 'iconCssClass', name: 'Icon CSS class' },
								{ value: 'imageUrl', name: 'Image URL' },
								{ value: 'customText', name: 'Custom text' },

								{ value: 'contextVariable1', name: 'Context variable 1' },

								{ value: 'filterButtonsSourceType', name: 'FilterButtonsSourceType' },
								{ value: 'filterButtonsSourceValue', name: 'FilterButtonsSourceValue' },
								{ value: 'filterButtonsSourceTake', name: 'FilterButtonsSourceTake' },
								{ value: 'filterButtonsSourceSubProperty', name: 'FilterButtonsSourceSubProperty' },
								{ value: 'filterButtonsObjectType', name: 'FilterButtonsObjectType' },
								{ value: 'filterButtonsDisplayProperty', name: 'FilterButtonsDisplayProperty' },

								{ value: 'comHubKey1', name: 'CommunicationHub Key1' },
								{ value: 'comHubProperty1', name: 'CommunicationHub Property1' },
								{ value: 'accessibleOrganizations', name: 'Accessible organizations' },
								{ value: 'firstSubItemAsRoot', name: 'Take first child item as root' },
								{ value: 'childrenLoadDepth', name: 'ChildrenLoadDepth' },
								{ value: 'limit', name: 'Limit' },
								{ value: 'itemsPerPage', name: 'Paginate: Items per page' },
								{ value: 'itemsPerPage_level2', name: 'Paginate: Items per page (level 2)' },
								{ value: 'usersCondition', name: 'UsersCondition' },

								{ value: 'loadRelatedContent', name: 'LoadRelatedContent' },
								{ value: 'loadRequirements', name: 'Load requirements' },
								{ value: 'networkId', name: 'Network ID' },
								{ value: 'urlPath', name: 'Url path' },
								{ value: 'intersectionSourceWfid', name: 'Intersection Source WFID' },

								{ value: 'measurePeriodSettings', name: 'Measure Settings (Y=1, HY=2, Q=3, M=4)' },
								{ value: 'chartSettings', name: 'Chart Settings' }
							]
						},
						{
							title: '',
							placeholder: 'Value...',
							type: "textarea",
							key: 'items[].value',
							htmlClass: 'col-sm-6'
						}
					]
					//startEmpty: true
				}]
			}

			specificationCacheByType[enums.objectType.requirement] = {
				schema: {
					'type': 'object',
					'properties': {
						'rule': {
							'type': 'string',
							'title': ''
						},
						'value': {
							'type': 'string',
							'title': ''
						}
					}
				},
				form: [{
					key: 'rule',
					type: 'select',
					title: $translate.instant("Rule"),
					placeholder: 'Choose rule...',
					titleMap: [
						{ name: 'NoValue', value: '1' },
						{ name: 'AnyValue', value: '2' },
						{ name: 'SpecificValue', value: '3' },
						{ name: 'MinValue', value: '4' },
						{ name: 'MaxValue', value: '5' },
						{ name: 'Span', value: '6' },
						{ name: 'SpecificValues', value: '7' },
						{ name: 'Manual', value: '8' },
						{ name: 'AllSubItemsFulfilled', value: '9' },
						{ name: 'PreferredValue', value: '10' },
						{ name: 'PreferredValueWithRelatedContent', value: '11' },
						{ name: 'AnyValueWithRelatedContent', value: '12' }
					]
				},
				{
					key: 'value',
					title: $translate.instant("Value")
				}]
			}
		}

		function getSpecificationFromCache(type, options) {
			var output;
			const { simplify, isCreate } = options || {};

			if (simplify) {
				output = _.cloneDeep(specificationCacheByType[type]);
				if (_.isArray(simplify)) {
					_.each(output.schema.properties, function (value, key) {
						if (!_.includes(simplify, key))
							delete output.schema.properties[key];
					});
				}
				else {
					switch (type) {
						case enums.objectType.structure:
							// delete output.schema.properties.guidance;
							delete output.schema.properties.guidanceVideoUrl;
							delete output.schema.properties.helpUrl;
							delete output.schema.properties.license;
							delete output.schema.properties.promotionHtml;
							delete output.schema.properties.friendlyTitle;

							if (output.multilingualSchema) {
								// delete output.multilingualSchema.properties.guidance;
								delete output.multilingualSchema.properties.guidanceVideoUrl;
								delete output.multilingualSchema.properties.helpUrl;
								delete output.multilingualSchema.properties.license;
								delete output.multilingualSchema.properties.promotionHtml;
								delete output.multilingualSchema.properties.friendlyTitle;
							}
							break;


						case enums.objectType.measure:
						case enums.objectType.question:
							delete output.schema.properties.reference;

							if (output.multilingualSchema) {
								delete output.multilingualSchema.properties.reference;
							}
							break;

						case enums.objectType.mailSettings:
							delete output.schema.properties.networkId;
							delete output.schema.properties.templateName;
							delete output.schema.properties.monthOfTheYear;
							if (output.multilingualSchema) {
								//delete output.multilingualSchema.properties.<prop>;
							}
							break;
						case enums.objectType.organization:
							delete output.schema.properties.countryId;
							delete output.schema.properties.country;
							delete output.schema.properties.imageUpload;
							delete output.schema.properties.primaryLocationId;
							delete output.schema.properties.primaryWebsite;
							delete output.schema.properties.employeeCountSpan;
							delete output.schema.properties.industry;
					}
				}

				return output;
			}
			else {
				let formSpec = specificationCacheByType[type]

				if (type === enums.objectType.individual && !isCreate) {
					formSpec = _.defaultsDeep({ schema: { properties: { email: { readonly: true } } } }, _.cloneDeep(formSpec));
				}

				if (formSpec.specialFormats && formSpec.specialFormats.hasOwnProperty("networkMemberOrganization-users")) {
					formSpec.schema.properties[formSpec.specialFormats["networkMemberOrganization-users"]]["x-schema-form"] = getNetworkMemberOrganizationUsersSchema({
						organizationId: options.model.organizationId,
						networkId: options.model.channelId,
					})
				}

				if (formSpec.specialFormats && formSpec.specialFormats.hasOwnProperty("mailSendoutStates")) {
					formSpec.schema.properties[formSpec.specialFormats["mailSendoutStates"]]["x-schema-form"] = getMailSendoutStateSchema({
						organizationId: options.model.organizationId,
						networkId: options.model.channelId,
					})
				}

				return formSpec;
			}
		}

		function beforeSave(model, objectType) {
			switch (objectType) {
				case enums.objectType.orgActivity:
					model.whenDateMode = null;
					model.untilDateMode = null;

					if (model.whenDate) {
						model.isOneTime = true;
						if (!model.whenDateMode)
							model.whenDateMode = 3;
					}
					if (model.untilDate) {
						model.isOneTime = false;
						if (!model.untilDateMode)
							model.untilDateMode = 3;
					}
					// console.log(model, objectType);
					break;
				case enums.objectType.finding:
					// TODO: Use findingService to check if severity level Observation means that all steps are optional.
					if (model.severity === enums.findingSeverity.observation)
						model.closed = true;
					break;
			}
		}

		function getNetworkMemberOrganizationUsersSchema({ organizationId, networkId }) {
			// This logic requires the user to be on the Data Collector overview page so that required data is loaded in JSData
			const
				usersOnOrg = wfObject.filter({ where: { type: 81, childType: 100, wffid: `101-${organizationId}` } }),
				contactPersonsOnOrg = _
					.chain(usersOnOrg)
					.intersectionBy(wfObject.filter({ where: {
								type: enums.objectType.dataRelation,
								childType: 100,
								wffid: { "in": [ `52-${networkId}`, "52-1" ] },
								wfxpid: `101-${organizationId}`
							}}),
						"wfcid"
					)
					.sortBy("childContent.name")
					.value()
				,

				titleMap = contactPersonsOnOrg.map(x => {
					const { worldfavorUserId, name, given_name, email } = x.childContent
					return ({
						value: worldfavorUserId,
						name: name + (email.length && given_name && given_name.length ? ` (${email})` : '')
					})
				})

			titleMap.unshift({
				value: null,
				name: $translate.instant("modules.valueChain.influence.noTargetUser")
			})
			;

			return {
				type: 'select',
				titleMap: titleMap
			};
		}

		function getMailSendoutStateSchema({ mailSentCount }) {
			const mailSendoutStates = [
				{
					name: $translate.instant("modules.mailSettings.ValueChainInvitation"),
					value: 0,
				},
				{
					name: $translate.instant("modules.mailSettings.ValueChainReminder"),
					value: mailSentCount > 1 ? mailSentCount : 1,
				},
			];

			return { type: 'select', titleMap: mailSendoutStates };
		}
	}
})();
