(function() {
	"use strict";

	angular
		.module("wf.common")
		.component("wfListInterfaceConfig", {
			templateUrl: "scripts/wf/list/wfListInterfaceConfig.component.html",
			controller: wfListInterfaceConfigController,
			controllerAs: "vm",
			bindings: {
				item: "<", // itemComposite
				listVm: "<"
			}
		})
	;

	wfListInterfaceConfigController.$inject = [ "listInterfaceService" ];
	function wfListInterfaceConfigController(listInterfaceService) {
		var
			vm = this,
			configSpecPrototype = {
				show: false,
				config: {},
				path: undefined
			},
			configObjectPrototype = {
				showLoadedItem: null,
				showCreationButton: null,
				showFiltering: null,
				showVisualization: true,
				showActions: null,
				showModificationActions: null,
				showTotals: null,
				showLayoutOptions: null
			}
		;

		_.assign(vm, {
			negotiator: undefined,
			onSettingChanged: onSettingChanged,
			configurations: [
				{ label: "Relation - User", path: "dataRelation.settings_user.listInterfaceConfig" },
				{ label: "Relation - Organization", path: "dataRelation.settings_organization.listInterfaceConfig" },
				{ label: "Relation - General", path: "dataRelation.settings.listInterfaceConfig" },
				{ label: "Content - User", path: "content.conditions_user.listInterfaceConfig" },
				{ label: "Content - Organization", path: "content.conditions_organization.listInterfaceConfig" },
				{ label: "Content - General", path: "content.conditions.listInterfaceConfig" }
			]
		});

		////////////////

		vm.$onInit = function() {
			vm.configurations = _.map(vm.configurations, function (configSpec) {
				return _.assign(_.clone(configSpecPrototype), configSpec, {
					config: undefined,
					onSettingChanged: onSettingChanged
				})
			})
			
			if (vm.listVm) {
				vm.negotiator = vm.listVm.negotiator;
				vm.listVm.negotiator.onRequest.then(function () {
					_.each(vm.configurations, function (configSpec) {
						configSpec.config = _.assign(_.clone(configObjectPrototype), _.get(vm.listVm.negotiator.itemComposite, configSpec.path));
						console.log(configSpec.config)
					});

					_.reverse(vm.configurations);

					console.log(vm.configurations)
					console.log(listInterfaceService.getActualConfig(vm.listVm.negotiator.itemComposite))
				})

			}
		};
		vm.$onChanges = function(changesObj) { };
		vm.$onDestroy = function() { };

		function onSettingChanged() {
			console.log(this.config)
		}
	}
})();
