import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';

	angular
		.module('wf.common')
		.component('wfOrganizationEditor', {
			templateUrl: 'scripts/wf/form/wfOrganizationEditor.component.html',
			controller: wfOrganizationEditorController,
			controllerAs: 'vm',
			bindings: {
				itemComposites: '<',
				loader: "<"
			}
		});

	wfOrganizationEditorController.$inject = [ '$scope', 'wfAuth', 'dataQuery', '$q', '$element', 'dataOperationsService', '$translate', '$timeout', 'requirements', '$ngBootbox', '$sanitize', 'valueChainService', 'formSchemaService', 'modalService' ];
	function wfOrganizationEditorController($scope, wfAuth, dataQuery, $q, $element, dataOps, $translate, $timeout, requirements, $ngBootbox, $sanitize, valueChainService, formSchemaService, modalService) {
		const vm = this;
		var instantVatOrRegNumberValidation = false;

		_.assign(vm, {

		});

		vm.$onInit = function() {
			_.assign(vm, {
				loaded: false,
				orgObjectType: enums.objectType.organization,
				organizationId: wfAuth.getOrganizationId(),
				submitButtonClicked: submitButtonClicked,
				customFormSpec: undefined,
				formControl: {},
			});

			getCustomFormSpecification().then(function() {
				dataOps.getObject({ objectType: vm.orgObjectType, objectId: vm.organizationId }).then(function(org) {
					vm.organization = org;
					vm.loaded = true;
					$timeout();
				});
			});
		}

		function getCustomFormSpecification() {
			var isVatOrRegNumberValid = false;
			var regNumberValid = false;
			var vatNumberValid = false;

			return formSchemaService.getFromType(enums.objectType.organization).then(function (res) {
				res.schema.required = [ "name", "country", "employeeCountSpan" ];
				vm.customFormSpec = {
					schema: res.schema,
					form: [
						{
							key: "name",
							title: $translate.instant("modules.organization.introModal.form.orgName")
						},
						{
							type: "section",
							htmlClass: "registration-vat row mr0",
							items: [
								{
									key: "registrationNumber",
									title: $translate.instant("modules.organization.introModal.form.regNumber"),
									htmlClass: "col-sm-6",
									validationMessage: {
										vatOrRegNumber: $translate.instant("validationMessages.vatRegOrGlnNumber")
									},
									$validators: {
										vatOrRegNumber: function (value) {
											if (!instantVatOrRegNumberValidation) return true;

											var result = !!(vatNumberValid || value)
											regNumberValid = !!value;

											if (isVatOrRegNumberValid !== result) {
												isVatOrRegNumberValid = result;
												vm.formControl.$scope.$broadcast('schemaForm.error.vatNumber', 'vatOrRegNumber', result);
											}

											return result;
										}
									}
								},
								{
									key: "vatNumber",
									title: $translate.instant("modules.organization.introModal.form.vatNumber"),
									htmlClass: "col-sm-6",
									validationMessage: {
										vatOrRegNumber: $translate.instant("validationMessages.vatRegOrGlnNumber")
									},
									$validators: {
										vatOrRegNumber: function (value) {
											if (!instantVatOrRegNumberValidation) return true;

											var result = !!(regNumberValid || value)
											vatNumberValid = !!value;

											if (isVatOrRegNumberValid !== result) {
												isVatOrRegNumberValid = result;
												vm.formControl.$scope.$broadcast('schemaForm.error.registrationNumber', 'vatOrRegNumber', result);
											}

											return result;
										}
									}
								}
							]
						},
						// {
						// 	key: "gln",
						// 	title: $translate.instant("GLNNumber")
						// },
						{
							key: "country",
							title: $translate.instant("modules.organization.introModal.form.country")
						},
						// {
						// 	key: "employeeCountSpan",
						// 	title: $translate.instant("modules.organization.introModal.form.employeeRange")
						// },
						// {
						// 	key: "industry",
						// 	title: $translate.instant("modules.organization.introModal.form.industries.label"),
						// 	type: "picker_multiple",
						// 	typeOptions: {
						// 		addButtonCaption: $translate.instant("Select") + " " + $translate.instant("Industry").toLowerCase(),
						// 		targetWfid: "71-14409", // Currently selected industries
						// 		picker: {
						// 			sourceItem: "71-13886", // List of available industries
						// 			title: $translate.instant("modules.organization.introModal.form.industries.pickerTitle"),
						// 			description: $translate.instant("modules.organization.introModal.form.industries.pickerDescription")
						// 		}
						// 	}
						// },
						{
							type: "section",
							htmlClass: "logo-address row mr0",
							items: [
								{
									type: "section",
									htmlClass: "col-sm-6 pr0 organization-logo",
									items: [ { key: "imageUpload" } ]
								},
								{
									type: "section",
									htmlClass: "col-sm-6 pr0 organization-location",
									items: [ { key: "primaryLocationId" } ]
								}
							]
						}
					]
				}
			});
		}

		function submitButtonClicked($event) {
			var deferred = $.Deferred(),
				promises = [];

			vm.button = $($event.target);

			if (!vm.formControl.isValid())
				return;

			vm.button.addClass('loading');

			$q.all(promises).then(function () {
				submit().then(function(valid) {
					// if (valid) {
					// 	$uibModalInstance.close();
					// }
					// else
						vm.button.removeClass('loading');


					deferred.resolve();
				});
			});

			return deferred.promise;
		}

		function submit() {
			var
				promises = [],
				jqDf = $.Deferred()
			;

			// console.log("Submit: ", vm);

			if (vm.formControl.isValid()) {

				vm.formControl.submit(function (model, deferred) {
					model.id = vm.organizationId;
					model.wfid = vm.orgObjectType + "-" + vm.organizationId;
					model.type = vm.orgObjectType;

					if (!model.registrationNumber && !model.vatNumber) {
						instantVatOrRegNumberValidation = true;
						vm.formControl.$scope.$broadcast('schemaForm.error.registrationNumber', 'vatOrRegNumber', false);
						vm.formControl.$scope.$broadcast('schemaForm.error.vatNumber', 'vatOrRegNumber', false);
						deferred.reject();
						return false;
					}

					// Checking if location should be removed
					if (vm.organization.primaryLocationId && !model.primaryLocationId) {
						promises.push(dataOps.destroy(vm.primaryLocation));
					}

					$q.all(promises).then(function () {
						dataOps.update(model).then(function (updatedOrg) {
							wfAuth.assignAuthUserValues({
								organizationName: updatedOrg.name
							});

							if (model.imageUpload) {
								delete model.imageUpload;
							}

							deferred.resolve(model);
							vm.organization = wfObject.get(vm.organization.wfid);
							if (vm.organization.primaryLocationId)
								vm.primaryLocation = wfObject.get(enums.objectType.location + "-" + vm.organization.primaryLocationId);
							else
								vm.primaryLocation = undefined;

						});
					});
				}).then(function (valid) {
					jqDf.resolve(valid);
				});
			}
			return jqDf.promise();
		}
	}
})();
