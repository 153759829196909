import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';
	
	angular
		.module('wf.common')
		.component('wfValueChainOrganizationAdder', {
			templateUrl: "scripts/wf/valueChain/wfValueChainOrganizationAdder.component.html",
			controller: wfValueChainOrganizationAdderController,
			controllerAs: "vm",
			bindings: {
				organizationItem: "<organization",
				ticket: "<",
				reportingStructure: "<"
			}
		});

	wfValueChainOrganizationAdderController.$inject = [ "$scope", "$timeout", "valueChainService", "modalService", "$translate", "$q", "dataOperationsService", "wfObject", "dataQuery", "wfAuth", "apiProxy", "wfPropertyExtractor" ];
	function wfValueChainOrganizationAdderController($scope, $timeout, valueChainService, modal, $translate, $q, dataOps, wfObject, dataQuery, wfAuth, apiProxy, wfPropertyExtractor) {
		var
			vm = this,
			objTypesSettings,
			authOrgId = wfAuth.getOrganizationId(),
			handleContactPersons = false,
			organization
		;
		
		this.$onInit = $onInit;

		function $onInit() {
			if (!vm.ticket || _.isEmpty(vm.ticket) || !vm.organizationItem || _.isEmpty(vm.organizationItem)) {
				console.error("organization or ticket objects are not passed or are empty, in <wf-value-chain-organization-adder>");
				return;
			}

			if (vm.reportingStructure) {
				objTypesSettings = _.get(vm.reportingStructure, "conditions.subItemsSettings.pickerSettings.objectTypes");
				if (objTypesSettings && objTypesSettings.length > 0) {
					_.each(objTypesSettings, function(objType) {
						if (objType === enums.objectType.person)
							handleContactPersons = true;
					}); 
				}
			}

			if (vm.organizationItem && vm.organizationItem.composite)
				organization = vm.organizationItem.content;
			else if (vm.organizationItem.isRelationalType())
				organization = vm.organizationItem.childContent;
			else
				organization = vm.organizationItem;

			if (vm.ticket.influence) {
				vm.ticket = _.clone(vm.ticket);
				delete vm.ticket.influence;
			}

			if (handleContactPersons) {
				apiProxy.raw("multi.getSubItems", {
					kind: enums.subItemsKind.relatedContentByUser,
					useOrganizationMatchModeFromKind: true,
					item: { type: organization.type, id: organization.id },
					objectType: enums.objectType.person,
					culture: wfAuth.getCulture(),
					ticket: vm.ticket,
					loadMetadata: false,
					loadParents: false,
					getterConditions: {
						loadCreators: false
					}
				}).then(function(res) {
					wfObject.inject(res);

					var relations = dataQuery.getIntersectedSubItems(organization, _.assign({
						kind: enums.subItemsKind.relatedContentByUser
					}, vm.ticket));

					if (relations && relations.length > 0)
						vm.peopleAttachedToOrg = _.map(relations, "childContent");
				});
			}

			_.assign(vm, {
				//Variables 
				syncingView: false,
				networkCompiler: {},
				contactPersonsCompiler: {},
				availableNetworks: [],
				networkOrgRelations: [],
				compilersByNetworkWfid: {},
				
				//Functions
				addOrganizationToNetworks: addOrganizationToNetworks,
				addPeopleToNetwork: addPeopleToNetwork,
				showGuidance: showGuidance,
				addReportingOrgAsParent: addReportingOrgAsParent
			});

			syncView(true);
		}

		function syncView(initialLoad) {
			var promises = [];
			vm.loadingNetworks = true;
			
			if (initialLoad) {
				if (valueChainService && !valueChainService.availableNetworks)
					promises.push(valueChainService.loadNetworks({ setPageTitle: false }));

				promises.push(valueChainService.getNetworkOrgRelations(organization).then(function(res) { vm.networkOrgRelations = res; }));
				promises.push(apiProxy.raw("multi.getSubItems", {
					kind: enums.subItemsKind.parentsByUser,
					item: { type: organization.type, id: organization.id },
					objectType: enums.objectType.organization,
					onlyLoadRelations: true,
					loadMetadata: false,
					loadParents: false,
					getterConditions: {
						loadCreators: false,
					}
				}).then(function(parentRelations) {
					vm.reportingOrgIsParent = _.some(parentRelations, { parentId: vm.ticket.organizationId });
				}));

				if (promises && promises.length > 0)
					$q.all(promises).then(function() {
						_.each(vm.networkOrgRelations, function (relation) {
							vm.compilersByNetworkWfid[relation.wffid] = {};
						});
						syncNetworks()
					});
			}
			else {
				vm.networkOrgRelations = dataQuery.getRelations({
					organizationId: authOrgId,
					kind: enums.subItemsKind.childrenByUser,
					parentType: enums.objectType.network,
					child: organization
				});
				syncNetworks();
			}
		}

		function syncNetworks() {
			if (valueChainService.availableNetworks && vm.networkOrgRelations) {
				vm.availableNetworks = _.cloneDeep(valueChainService.availableNetworks.networks);
				vm.loadingNetworks = false;

				vm.memberInAnyNetwork = vm.networkOrgRelations.length > 0;
	
				var queue = _.clone(vm.networkOrgRelations);
				if (queue && queue.length > 0)
					syncNetwork(queue.shift(), queue);
			}
		}

		function syncNetwork(networkRelation, previousQueue) {
			var memberNetwork = networkRelation;
			
			if (memberNetwork && !memberNetwork.isMember)
				memberNetwork = _.find(vm.availableNetworks, { "id": networkRelation.parentId });
			
			if (memberNetwork) {
				memberNetwork.isMember = true;
				vm.availableNetworks = _.orderBy(vm.availableNetworks, "isMember");

				if (handleContactPersons)
					syncContactPersons(memberNetwork).then(function() { continueRecursion(previousQueue) });
				else
					continueRecursion(previousQueue);
			}

			function continueRecursion(queue) {
				if (queue && queue.length > 0)
					syncNetwork(queue.shift(), queue);
				else {
					if (typeof vm.networkCompiler.compile === "function")
						vm.networkCompiler.compile();
				}
			}
		}
		
		function syncContactPersons(memberNetwork) {
			var deferred = $q.defer();
			if (memberNetwork) {
				memberNetwork.loadingContactPersons = true;
				
				apiProxy.raw("multi.getObjects", {
					objectType: 100,
					getterConditions: {
						networkId: memberNetwork.id,
						organizationIds: [ organization.id ]
					}
				}).then(function(networkUserRelations) {
					if (networkUserRelations && networkUserRelations.length > 0) {
						memberNetwork.contactPersons = _.map(networkUserRelations, "childContent");
						_.each(memberNetwork.contactPersons, function (user) {
							user.description = wfPropertyExtractor.getBodyText(user, { html: false });
						});
						deferred.resolve(memberNetwork.contactPersons);
					}
					else {
						deferred.resolve(false);
					}
				
					memberNetwork.loadingContactPersons = false;
					delete memberNetwork.loadingContactPersons;

					if (vm.compilersByNetworkWfid[memberNetwork.wfid] && vm.compilersByNetworkWfid[memberNetwork.wfid].compile)
						vm.compilersByNetworkWfid[memberNetwork.wfid].compile()
				});
			}

			return deferred.promise;
		}

		function addOrganizationToNetworks() {
			var newRelations;
			var orgFromJsData;
			var relationsToDestroy;

			modal.openCreatorAndPicker({
				title: $translate.instant("modules.valueChain.organizationAdder.valueChainPicker.title"),
				description: $translate.instant("modules.valueChain.organizationAdder.valueChainPicker.description"),
				relationTarget: { item: organization, kind: enums.subItemsKind.parentsByUser },
				sourceItem: "71-" + valueChainService.ids.networksStructure,
				disableUntoggle: true,
				disableUntoggleMessage: $translate.instant("modules.valueChain.organizationAdder.valueChainPicker.untoggleMessage")
			}).closed(function () {
				orgFromJsData = wfObject.get(organization.wfid);
				if (orgFromJsData)
					newRelations = _.filter(orgFromJsData.parentsByUser, { "parentType": enums.objectType.network });
				
				if (newRelations) {
					if (newRelations.length > 0 && vm.networkOrgRelations.length === 0) {
						//Create realtion between reporting supplier and sub-supplier as parentsByUser
						dataOps.createSubItemRelation("101-" + vm.ticket.organizationId, organization, {
							kind: enums.subItemsKind.childrenByUser,
							ticket: vm.ticket
						});
					}
					else if (newRelations.length === 0 && vm.networkOrgRelations.length > 0) {
						// If all relations to networks were deleted then also delete the relations between any reporting org and the sub-supplier
						relationsToDestroy = dataQuery.getRelations({
							organizationId: authOrgId,
							kind: enums.subItemsKind.childrenByUser,
							parentType: enums.objectType.organization,
							child: wfObject.get("101-" + vm.ticket.organizationId)
						});

						if (relationsToDestroy.length) {
							_.each(relationsToDestroy, function (relation) {
								dataOps.destroy(relation);
							})
						}
					}
				}

				vm.networkOrgRelations = newRelations;

				syncView();
				$timeout();
			})
		}

		function addPeopleToNetwork(network) {
			var 
				preSelectedPersons = [],
				existingUserEmails
			;
			
			preSelectedPersons = network.contactPersons;

			//Check for preSelectedPersons by comparing emails (because types do not match - peopleAttachedToOrg have type: 100 and preSelectedPersons have type: 112)
			existingUserEmails = _.map(preSelectedPersons, "email");
			preSelectedPersons = _.filter(vm.peopleAttachedToOrg, function(person) {
				return _.includes(existingUserEmails, person.email);
			});

			modal.openCreatorAndPicker({
				title: $translate.instant("modules.valueChain.organizationAdder.peoplePicker.title"),
				description: $translate.instant("modules.valueChain.organizationAdder.peoplePicker.description"),
				sourceList: _.map(vm.peopleAttachedToOrg, function(item) { return { data: item, wfid: item.wfid } }),
				objectTypes: [ enums.objectType.person ],
				relationBucket: { preSelected: preSelectedPersons, allSelected: preSelectedPersons, },
				disableUntoggle: true,
				disableUntoggleMessage: $translate.instant("modules.valueChain.organizationAdder.peoplePicker.untoggleMessage"),
				onToggleCallback: function(toggledItem) {
					// Resolves to false if user clicked the Cancel button which will prevent the checkbox from being toggled
					return valueChainService.createUser(network, organization, _.pick(toggledItem, [ "email", "given_name", "family_name", "phone_number", "position" ]));
				}
			}).closed(function () {
				syncContactPersons(network);
				$timeout();
			});

		}

		function showGuidance() {
			modal.alert($translate.instant("modules.valueChain.organizationAdder.guidance", { orgname: organization.name }));
		}

		function addReportingOrgAsParent() {
			var promise = dataOps.createSubItemRelation("101-" + vm.ticket.organizationId, organization, {
				kind: enums.subItemsKind.childrenByUser,
				ticket: vm.ticket
			});

			promise.then(function () {
				vm.reportingOrgIsParent = true;
				if (typeof vm.networkCompiler.compile === "function")
					vm.networkCompiler.compile();
			});

			return promise;
		}
	}
})();
