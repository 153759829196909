//@flow
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Icon from '@material-ui/core/Icon'
import FilterMenu from '@worldfavor/components/Filters/FilterMenu'
import { useMenuState } from '@worldfavor/hooks/mui-hooks'
import { FILTER_OPTIONS_TYPE_PROPS } from '@worldfavor/constants'

const useStyles = makeStyles({
  filterChip: {
    marginRight: 8,
  },
})

type Props = {
  label: string,
  filterOptions: FILTER_OPTIONS_TYPE_PROPS,
  onAfterFilterChanged?: () => void
}

const FilterDropdown = (props: Props) => {
  const { label, filterOptions, filter, onAfterFilterChanged } = props
  const classes = useStyles(props)

  const [anchorEl, openFilterMenu, closeFilterMenu] = useMenuState(null)
  const [hasActiveFilter, setHasActiveFilter] = useState(null)

  function _onAfterFilterChanged(clickedFilterOptions) {
    const hasClickedFilterOptions = clickedFilterOptions && clickedFilterOptions.length > 0

    setHasActiveFilter(!hasClickedFilterOptions)
    filter.active = hasClickedFilterOptions

    onAfterFilterChanged && onAfterFilterChanged(clickedFilterOptions, filter)
  }
  
  return (
    <>
      <Chip
        label={label}
        classes={{ root: classes.filterChip }}
        color={hasActiveFilter ? 'secondary' : 'primary'}
        variant={hasActiveFilter ? 'default' : 'outlined'}
        onClick={openFilterMenu}
        clickable
        onDelete={openFilterMenu}
        deleteIcon={<Icon classes={{ root: classes.icon }} aria-label="filter"><ArrowDropDownIcon /></Icon>}
      />
      {
        filterOptions && filterOptions.length > 0 && (
          <FilterMenu
            filterOptions={filterOptions}
            onAfterFilterChanged={_onAfterFilterChanged}
            menuProps={{
              anchorEl,
              open: Boolean(anchorEl),
              onClose: closeFilterMenu,
            }}
          />
        )
      }
    </>
  )
}

export default FilterDropdown
