import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';

	angular
		.module('wf.common')
		.component('wfListInterfaceStatistical', {
			templateUrl: "scripts/wf/list/wfListInterfaceStatistical.component.html",
			controllerAs: "vm",
			controller: wfListInterfaceStatisticalComponentController,
			bindings: {
				item: "<",
				preloadedItem: "<",
				ticket: "<",
				config: "<"
			}
		})
	;

	wfListInterfaceStatisticalComponentController.$inject = [ "$scope", "wfAuth" ];
	function wfListInterfaceStatisticalComponentController($scope, wfAuth) {
		var
			vm = this
		;

		_.assign(vm, {
			compiler: {},
			setMeasureUnitConversionMode: setMeasureUnitConversionMode,
			updateNegotiatorConfigAndCompile: updateNegotiatorConfigAndCompile,
			negotiatorConfig: [
				vm.config.negotiator,
				{
					fromItem: vm.item,
					preloadedItem: vm.preloadedItem,
					useRecursiveRelationsLoading: vm.config.useRecursiveRelationsLoading,
					mainHierarchyKind: vm.config.mainHierarchyKind,
					splitUpOrganizationStatistics: vm.config.splitUpOrganizationStatistics,
					splitUpRelativeMeasureSourcesStatistics: vm.config.splitUpRelativeMeasureSourcesStatistics,
					aggregateYearly: vm.config.aggregateYearly,
					aggregatePeriodFrequencies: vm.config.aggregatePeriodFrequencies,
					convertMeasureAnswerUnits: vm.config.convertMeasureAnswerUnits,
					periodSpan: vm.config.periodSpan,
					excludeYears: vm.config.excludeYears,
					includeAvailablePeriods: vm.config.includeAvailablePeriods,
					loadParents: false,
					ticket: {
						networkId: vm.ticket.networkId,
						organizationIds: vm.ticket.organizationIds,
						organizationId: vm.ticket.organizationId,
						contextParentType: vm.ticket.contextParentType,
						contextParentWfids: vm.ticket.contextParentWfids,
						receivingOrganizationsAndLimitedDataAccessFromInfluenceId: vm.ticket.receivingOrganizationsAndLimitedDataAccessFromInfluenceId,
					}
				}
			],
			listInterfaceConfig: _.defaultsDeep(vm.config, {
				width: 900,
				showLoadedItem: false,
				showCreationButton: false,
				showFiltering: false,
				showVisualization: vm.item.type === enums.objectType.measure || vm.item.type === enums.objectType.relativeMeasure || vm.item.type === enums.objectType.question,
				showActions: false,
				showModificationActions: false,
				showTotals: true,
				showLayoutOptions: true,
				showConsolidationStatistics: true,
				includeActions: {
					embed: false,
					export: true,
					view: false,
					create: false,
					sort: false,
					mark: false,
					listView: true,
					gridView: false,
					tableView: true
				},
				itemSettings: {
					showDropdown: false, // _.get(vm.ticket, "organizationId") === wfAuth.getOrganizationId(),
					showMetadata: true,
					metadataConfig: {
						limitRelationKinds: [ 5, 7 ],
						hidePopoverAddAction: true,
						showDetailedViewLink: false,
						showEmbeddedObjectViewer: false,
						popoverItemSettings: {
							showDropdown: false,
							metadataConfig: {
								showCreator: false,
								limitRelationKinds: [],
								showDetailedViewLink: false,
								showEmbeddedObjectViewer: false
							}
						}
					}
				}
			})
		});

		function setMeasureUnitConversionMode(shouldConvert) {
			vm.negotiatorConfig[1].convertMeasureAnswerUnits = shouldConvert;
			vm.compiler.compile();
		}

		function updateNegotiatorConfigAndCompile(newConfig) {
			_.assign(vm.negotiatorConfig[1], newConfig)
			vm.compiler.compile();
		}
	}
})();
