import { registerNumeralLocales } from './assets/plugins/numeral/locales'
import { injectProxies } from './scripts/api-proxy.es8'
import { registerReactDirectives } from './ReactDirectives'
import './views/widget_preview.html'
import { bootstrapPortal } from './scripts/browser-check-after'
import api from './api'

// WF Modules, except templates
import './scripts/multilingual.module.js'
import './scripts/animations.module.js'
import './scripts/common.module.js'
import './scripts/data.module.js'
import './scripts/js-data.config.js'

// WF Services
import './scripts/wf/services/api-proxy.provider.js'
import './scripts/wf/services/communicationHub.service.js'
import './scripts/wf/services/dataModeller.service.js'
import './scripts/wf/services/dataOperations.service.js'
import './scripts/wf/services/dataOperationsCache.service.js'
import './scripts/wf/services/dataQuery.service.js'
import './scripts/wf/services/formSchema.service.js'
import './scripts/wf/services/modal.service.js'
import './scripts/wf/services/d3.service.js'
import './scripts/wf/services/signalr.service.js'
import './scripts/wf/services/processTool.service.js'
import './scripts/wf/services/dropdownUtility.service.js'
import './scripts/wf/services/csv.service.js'
import './scripts/wf/services/responsiveUi.service.js'
import './scripts/wf/influence/informationRequest.service.js'
import './scripts/wf/pdf/pdfGenerator.service.js'
import './scripts/wf/loader/screenLoader.service.js'
import './scripts/wf/translate/wfTranslate.service.js'
import './scripts/wf/authentication/wfAuth.service.js'
import './scripts/wf/valueChain/valueChain.service.js'
import './scripts/wf/requirements/requirements.service.js'
import './scripts/wf/dashboard/statistics.service.js'
import './scripts/wf/importExport/importExport.service.js'
import './scripts/wf/data/dataNegotiator.factory.js'
import './scripts/wf/list/wfListInterface.service.js'
import './scripts/wf/transferManager/relationMapper.factory.js'
import './scripts/wf/transferManager/wfFile.factory.js'
import './scripts/wf/visualization/wfChartist.service.js'
import './scripts/wf/data/tableDataExtractor.service.js'
import './scripts/wf/analyze/analyzeService.service.js'
import './scripts/wf/xmlImporter/wfXmlImporter.factory.js'
import './scripts/wf/findings/findingService.service.js'
import './scripts/wf/workItem/wfAnswering.service.js'
import './scripts/wf/services/wfUtility.service.js'
import './scripts/wf/picker/picker.service.js'

import './scripts/wf/data/theNewLoader.factory.js'
import './scripts/wf/data/theNewLoaderItem.factory.js'
import './scripts/wf/picker/picker.factory.js'

// WF Controllers;
import './scripts/wf/_dev/devTesting.controller.js'
import './scripts/wf/common/wf-main.controller.js'
import './scripts/wf/common/page.controller.js'
import './scripts/wf/common/packageSolutionPage.controller.js'
import './scripts/wf/item/form.controller.js'
import './scripts/wf/item/modal.controller.js'

import './scripts/wf/supplierPlatform2/supplierGroup.controller.js'
import './scripts/wf/supplierPlatform2/supplierCategory.controller.js'
import './scripts/wf/supplierPlatform2/supplierDemandDocument.controller.js'
import './scripts/wf/supplierPlatform2/supplierPlatform.controller.js'
import './scripts/wf/supplierPlatform/supplierGroup.controller.js'
import './scripts/wf/supplierPlatform/supplierCategory.controller.js'
import './scripts/wf/supplierPlatform/supplierUsers.controller.js'
import './scripts/wf/supplierPlatform/supplierDemandDocument.controller.js'
import './scripts/wf/supplierPlatform/supplierEducationStudents.controller.js'
import './scripts/wf/picker/picker.controller.js'
import './scripts/wf/settings/userSettings.controller.js'
import './scripts/wf/settings/orgSettings.controller.js'
import './scripts/wf/settings/orgPanel.controller.js'
import './scripts/wf/settings/dataExplorer.controller.js'
import './scripts/wf/settings/thirdPartyPermissions.controller.js'
import './scripts/wf/report/report.controller.js'
import './scripts/wf/verification/verificationViewer.controller.js'
import './scripts/wf/influence/influence.controller.js'
import './scripts/wf/authentication/setPassword.controller.js'
import './scripts/wf/account/eventLog.controller.js'
import './scripts/wf/dashboard/dashboard.controller.js'
import './scripts/wf/dashboard/rootStarter.controller.js'
import './scripts/wf/mail/mailHistory.controller.js'
import './scripts/wf/mail/mailPreview.controller.js'
import './scripts/wf/publicInfluences/publicInfluences.controller.js'
import './scripts/wf/sustainabilityRoom/sustainabilityRoom.controller.js'
import './scripts/wf/users/users.controller.js'
import './scripts/wf/manage/manage.controller.js'
import './scripts/wf/hierarchical/hierarchical.controller.js'
import './scripts/wf/hierarchical/hierarchicalEditor.controller.js'
import './scripts/wf/widget/widget.controller.js'
import './scripts/wf/objectViewer/objectViewer.controller.js'
import './scripts/wf/eCommerce/invoice.controller.js'
import './scripts/wf/eCommerce/ordering.controller.js'
import './scripts/wf/isoStandards/isoStandards.controller.js'
import './scripts/wf/scorecard/scorecard.controller.js'
import './scripts/wf/organization/organizationIntro.controller.js'
import './scripts/wf/transferManager/transfer.controller.js'

import './scripts/wf/valueChain/valueChain.controller.js'
import './scripts/wf/valueChain/valueChainOrganizations.controller.js'
import './scripts/wf/valueChain/valueChainPackages.controller.js'
import './scripts/wf/valueChain/valueChainCategories.controller.js'
import './scripts/wf/valueChain/valueChainPackageEditor.controller.js'
import './scripts/wf/valueChain/valueChainMailSettings.controller.js'
import './scripts/wf/valueChain/internalValueChainUsers.controller.js'
import './scripts/wf/analyze/package/valueChainAnalyzePackages.controller.js'
import './scripts/wf/analyze/package/valueChainAnalyzePackageEditor.controller.js'
import './scripts/wf/standards/standards.controller.js'

import './scripts/wf/admin/accounts.controller.js'
import './scripts/wf/promotion/promotion.controller.js'
import './scripts/wf/report/internalReport.controller.js'
import './scripts/wf/sustainabilityDiagnosis/sustainabilityDiagnosis.controller.js'
import './scripts/wf/influence/influenceGrouper.controller.js'
import './scripts/wf/widget/widgets.controller.js'
import './scripts/wf/signup/wfClaimOrganization.controller.js'

// WF Components
import './scripts/wf/hierarchical/wfHierarchicalItem.component.js'
import './scripts/wf/hierarchical/wfHierarchicalEditorItem.component.js'
import './scripts/wf/valueChain/valueChainPackageSubItem.component.js'
import './scripts/wf/valueChain/wfValueChainOrganizationAdder.component.js'
import './scripts/wf/objectViewer/objectViewerItem.component.js'
import './scripts/wf/objectViewer/objectViewerBox.component.js'
import './scripts/wf/itemComponent/wfItem.component.js'
import './scripts/wf/pdf/wfPdfViewer.component.js'
import './scripts/wf/dashboard/wfDashboardCard.component.js'
import './scripts/wf/statistics/wfItemStatistics.component.js'
import './scripts/wf/visualization/wfChart.component.js'
import './scripts/wf/common/wfButton.component.js'
import './scripts/wf/common/wfCheck.component.js'
import './scripts/wf/list/wfListItem.component.js'
import './scripts/wf/list/wfListInterfaceSandbox.component.js'
import './scripts/wf/list/wfListInterfaceConfig.component.js'
import './scripts/wf/creatorTime/wfCreatorTime.component.js'
import './scripts/creatorTime/wfCreatorTimeBubble.component.js'
import './scripts/wf/analyze/requirement/wfAnalyzeRequirementsEditor.component.js'
import './scripts/wf/analyze/requirement/wfAnalyzeRequirements.component.js'
import './scripts/wf/analyze/result/wfAnalyzeItemResult.component.js'
import './scripts/wf/analyze/result/wfAnalyzeTotalResult.component.js'
import './scripts/wf/analyze/result/wfAnalyzeResultDetails.component.js'

import './scripts/wf/analyze/job/wfAnalyzeJob.component.js'
import './scripts/wf/xmlImporter/wfXmlImporter.component.js'
import './scripts/wf/objectSettings/wfMeasurePeriodSettingsEditor.component.js'
import './scripts/wf/objectSettings/wfMeasurePeriodLockSetting.component.js'
import './scripts/wf/importExport/wfValueChainImporter.component.js'
import './scripts/wf/visualization/wfMeasureDisplayVariant.component.js'
import './scripts/wf/importExport/wfExportPdf.component.js'
import './scripts/wf/signup/wfLandingPage.component.js'
import './scripts/wf/promotion/wfPromotions.component.js'
import './scripts/wf/materialityAnalysis/wfMaterialityAnalysis.component.js'
import './scripts/wf/findings/wfFinding.component.js'
import './scripts/wf/findings/wfFindingStep.component.js'
import './scripts/wf/findings/wfFindingStepAnswer.component.js'

import './scripts/wf/manage/wfManageScopeDashboard.component.js'
import './scripts/wf/list/wfListInterfaceStatistical.component.js'
import './scripts/wf/easy/wfEasyList.component.js'
import './scripts/wf/relativeMeasures/steps/wfRelativeMeasureSteps.component.js'
import './scripts/wf/relativeMeasures/steps/wfRelativeMeasureStepsEditor.component.js'
import './scripts/wf/relativeMeasures/steps/wfRelativeMeasureCalculationPreview.component.js'
import './scripts/wf/valueChain/wfValueChainCategoryFiltering.component.js'
import './scripts/wf/workItem/wfWorkItem.component.js'
import './scripts/wf/workItem/wfInlineAnswering.component.js'
import './scripts/wf/workItem/wfAnsweringFeed.component.js'
import './scripts/wf/workItem/wfAnsweringFeedItem.component.js'
import './scripts/wf/workItem/wfAnsweringFeedAttachments.component.js'
import './scripts/wf/picker/wfInlinePicker.component.js'
import './scripts/wf/requirements/wfRequirementFulfillment.component.js'
import './scripts/wf/requirements/wfFulfillmentPageBar.component.js'
import './scripts/wf/influence/wfInfluenceHeader.component.js'
import './scripts/wf/publicInfluences/wfPublicInfluencesWidget.component.js'

// WF Directives;
import './scripts/wf/collection/collection.directive.js'
import './scripts/wf/collection/wfItemsCollection.directive.js'
import './scripts/wf/common/page-heading.directive.js'
import './scripts/wf/common/wf-click.directive.js'
import './scripts/wf/item/item.directive.js'
import './scripts/wf/item/item-tools.directive.js'
import './scripts/wf/common/toggle.directive.js'
import './scripts/wf/common/search.directive.js'
import './scripts/wf/common/wf-init.directive.js'
import './scripts/wf/visualization/wfChart.directive.js'
import './scripts/wf/visualization/wfChartSunburst.directive.js'
import './scripts/wf/visualization/wfChartPie.directive.js'
import './scripts/wf/visualization/wfChartLine.directive.js'
import './scripts/wf/common/propertyExtractors.directive.js'
import './scripts/wf/answering/wfQuestionAnswering.directive.js'
import './scripts/wf/answering/wfQuestionAnsweringHistory.directive.js'
import './scripts/wf/answering/wfQuestionAnsweringManager.directive.js'
import './scripts/wf/answering/wfMeasureAnsweringManager.directive.js'
import './scripts/wf/answering/wfMeasureAnswering.directive.js'
import './scripts/wf/answering/wfMeasureAnsweringHistory.directive.js'
import './scripts/wf/answering/wfRequirementFulfillmentManager.directive.js'
import './scripts/wf/answering/wfDataAnswering.directive.js'
import './scripts/wf/hierarchical/wfHierarchy.directive.js'
import './scripts/wf/common/wf-if.directive.js'
import './scripts/wf/itemRelations/wfItemRelations.directive.js'
import './scripts/wf/itemRelations/wfItemRelationsPane.directive.js'
import './scripts/wf/common/wf-compile.directive.js'
import './scripts/wf/common/wfEmptyState.directive.js'
import './scripts/wf/translate/wfTranslate.directive.js'
import './scripts/wf/form/wfForm.directive.js'
import './scripts/wf/form/wfOrganizationEditor.component.js'
import './scripts/wf/notifications/wfNotification.directive.js'
import './scripts/wf/authentication/wfPermissions.directive.js'
import './scripts/wf/embed/wfEmbedPlayer.directive.js'
import './scripts/wf/embed/schema-form-embedly.js'
import './scripts/wf/form/schema-form-picker-single.js'
import './scripts/wf/form/schema-form-location-single.js'
import './scripts/wf/form/schema-form-location-search.js'
import './scripts/wf/form/schema-form-picker-multiple.js'
import './scripts/wf/stickyBreadcrumbs/stickyBreadcrumbs.directive.js'
import './scripts/wf/influence/wfInfluenceViewer.directive.js'
import './scripts/wf/influence/wfInfluenceStatus.directive.js'
import './scripts/wf/influence/wfPublicRequest.directive.js'
import './scripts/wf/location/wfLocation.directive.js'
import './scripts/wf/utility/dropdownOverflow.directive.js'
import './scripts/wf/requirements/wfRequirement.directive.js'
import './scripts/wf/objectSettings/wfObjectSettings.directive.js'
import './scripts/wf/objectMetadata/wfObjectMetadata.directive.js'
import './scripts/wf/dropdown/wfDropdown.directive.js'
import './scripts/wf/dropdown/wfDropdownRightclick.directive.js'
import './scripts/wf/objectViewer/objectViewer.directive.js'
import './scripts/wf/utility/wfScrollable.directive.js'
import './scripts/wf/utility/wfTextareaAutoHeight.directive.js'
import './scripts/wf/hierarchical/wfHierarchical.directive.js'
import './scripts/wf/hierarchical/wfHierarchicalEditor.directive.js'
import './scripts/wf/influence/wfInfluenceSigning.directive.js'
import './scripts/wf/influence/wfInfluenceMoreInformation.directive.js'
import './scripts/wf/answering/wfParameterAnswering.directive.js'
import './scripts/wf/filtering/wfFiltering.directive.js'
import './scripts/wf/filtering/wfFilteringTool.directive.js'
import './scripts/wf/accessTags/wfAccessTag.directive.js'
import './scripts/wf/accessTags/wfAccessTagSelector.directive.js'
import './scripts/wf/permissions/wfPermissions.directive.js'
import './scripts/wf/permissions/wfPermissionDirectives.js'
import './scripts/wf/influence/wfInfluenceGrouper.directive.js'
import './scripts/wf/readMore/wfReadMore.directive.js'
import './scripts/wf/socialShare/wfSocialShare.directive.js'
import './scripts/wf/location/wfMap.directive.js'
import './scripts/wf/importExport/wfExportCsv.directive.js'
import './scripts/wf/dragDrop/wfDraggable.directive.js'
import './scripts/wf/dragDrop/wfDroppable.directive.js'
import './scripts/wf/expand/wfExpand.directive.js'
import './scripts/wf/expand/wfExpandTrigger.directive.js'
import './scripts/wf/expand/wfExpandContent.directive.js'
import './scripts/wf/data/wfDataNegotiator.directive.js'
import './scripts/wf/utility/wfInclude.directive.js'
import './scripts/wf/list/wfListInterface.directive.js'
import './scripts/wf/form/schema-form-measureAnswerValue.js'
import './scripts/wf/utility/wfHoverDisablePageScroll.directive.js'
import './scripts/wf/utility/wfCountUp.directive.js'
import './scripts/wf/utility/ngAnimateDisabled.directive.js'

// Maverick;
import './scripts/core/controllers/navigationController.js'
import './scripts/core/controllers/notificationsController.js'
import './scripts/core/directives/directives.js'
import './scripts/core/directives/form.js'
import './scripts/core/directives/ui.js'
import './scripts/core/services/services.js'
import './scripts/core/modules/panels/panels.js'
import './scripts/core/modules/panels/directives.js'

import './scripts/app.module'

const { registerBaseUrl } = api
registerNumeralLocales()

let proxiesUrl = 'https://dev.api1.worldfavor.com/api/proxies'
if (process.env.NODE_ENV !== 'production') {
  // proxiesUrl = 'http://localhost:5000/api/proxies'
  // proxiesUrl = 'https://dev.api1.worldfavor.com/api/proxies'
  // proxiesUrl = 'https://staging.api1.worldfavor.com/api/proxies'
  proxiesUrl = 'https://api1.worldfavor.com/api/proxies'
}

injectProxies(proxiesUrl)
registerBaseUrl(proxiesUrl.replace(/\/proxies/, ''))

bootstrapPortal()
registerReactDirectives()

// Only run during development
if (process.env.NODE_ENV === 'development') {
  angular.module('wf.templates', [])
}
