// @flow
import * as React from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { questionAnswerTypeTranslateMap } from '@worldfavor/utils/propertyExtractors'
import '@worldfavor/less/components/question-container.less'

type Props = {
  value: number,
  title?: string,
  subTitle?: string,
  small?: boolean,
  type: string,
  forceShowPercentage?: boolean,
  style: { [string]: any },
}

const AnswerValue = (props: Props) => {
  const { value, title, subTitle, small, type, forceShowPercentage, style } = props
  const titleContent = title || (questionAnswerTypeTranslateMap[type]
    && <FormattedMessage id={questionAnswerTypeTranslateMap[type]} />)

  if (value === 0) {
    return null
  }

  return (
    <div styleName={`wf-item-statistics equalWidths ${small ? 'smallSize' : ''}`} style={style}>
      <div styleName="total-item">
        <div styleName="total-value">
          {
            (value && value < 100) || forceShowPercentage ?
              <FormattedNumber value={value / 100} style={'percent'} maximumFractionDigits={2} /> : // eslint-disable-line react/style-prop-object
              titleContent
          }
        </div>
        {
          ((!!value && value < 100) || forceShowPercentage) && <div styleName="total-title">{ titleContent }</div>
        }
        {
          subTitle && <div styleName="total-subTitle">{ subTitle }</div>
        }
      </div>
    </div>
  )
}

AnswerValue.defaultProps = {
  value: 0,
  style: {},
}

export default AnswerValue
