(function () {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfPageHeading', wfPageHeading);

	wfPageHeading.$inject = [ '$window', '$translate', '$ngBootbox', '$sanitize', '$rootScope', '$state', '$parse', 'modalService' ];
	function wfPageHeading($window, $translate, $ngBootbox, $sanitize, $rootScope, $state, $parse, modal) {
		// Usage:
		// <wf-page-heading page-title="Report"></wf-page-heading>

		var directive = {
			restrict: 'E',
			replace: true,
			transclude: true,
			link: link,
			scope: {
				'item': '=',
				'preTitle': '=pagePreTitle',
				'preTitleGoto': '=pagePreTitleGoto',
				'title': '=pageTitle',
				'description': '=pageDescription',
				'guidance':  '=pageGuidance',
				'showPdfExportButton': '=showPdfExportButton',
				'guidanceVideoUrl': '=pageVideourl',
				'helpUrl': '=pageHelpurl',
				'backButtonAction': "<withBackButton"
			},
			templateUrl: "scripts/wf/common/page-heading.directive.html",
			
		};
		return directive;

		function link(scope, element, attrs) {
			var itemWatcher;

			var setFromItem = function (item) {
				scope.itemImageUrl = item.getImageUrl ? item.getImageUrl() : null;
				scope.itemTitle = item.getHeaderText ? item.getHeaderText() : null;
				scope.itemDescription = item.getBodyText ? item.getBodyText() : null;
				scope.itemGuidance = item.guidance;
				scope.itemGuidanceVideoUrl = item.guidanceVideoUrl;
				scope.itemHelpUrl = item.helpUrl
			};

			if (scope.item)
				scope.helpUrlDescription = $translate.instant("modules.pageHeading.helpUrl.description", { solution: scope.item.title });
				
			scope.openGuidance = openGuidance;
			scope.openVideoGuidance = openVideoGuidance;
			scope.window = $window;
			scope.onBackButtonClick = onBackButtonClick;

			if (attrs.hasOwnProperty("item")) {
				if (!scope.item) {
					itemWatcher = scope.$watch("item", function () {
						if (scope.item) {
							itemWatcher();
							setFromItem(scope.item);
						}
					})
				}
				else {
					setFromItem(scope.item);
				}
			}

			scope.withBackButton = attrs.$attr.hasOwnProperty("withBackButton");
			if (scope.withBackButton && attrs.$attr.withBackButton.length) {
				scope.withBackButton = $parse(attrs.withBackButton)(scope.$parent);
				
				if (typeof scope.backButtonAction !== "function" && !(window.history.length > 1))
					scope.withBackButton = false;
			}

			if (attrs.hasOwnProperty("setPageTitle"))
				$rootScope.setPageTitle(scope.title);

			if (scope.preTitleGoto) {
				scope.preTitleLinkUrl = $state.href(scope.preTitleGoto.stateName, scope.preTitleGoto.stateParams, { inherit: false });
			}

			traverseParentsForInflunce();

			function onBackButtonClick() {
				if (typeof scope.backButtonAction === "function") {
					scope.backButtonAction();
				}
				else {
					if (window.history.length > 1)
						window.history.back();
				}
			}

			function openGuidance($event) {
				var messageBody = "";
				var guidance = scope.guidance || scope.itemGuidance;
				// var guidanceVideoUrl = scope.guidanceVideoUrl || scope.itemGuidanceVideoUrl;


				if (guidance) {
					messageBody = "<div class='guidance-text'>" + $sanitize(guidance) + "</div>";
				}

				// if (guidanceVideoUrl) {
				// 	messageBody += [
				// 		'<div class="guidance-video"><div class="embed-responsive embed-responsive-16by9">',
				// 		'<iframe class="embed-responsive-item" ',
				// 		'allowtransparency="true" frameborder="0" scrolling="no" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="100%" ',
				// 		'src="' + $sanitize(guidanceVideoUrl) + '"></iframe></div></div>'].join("");
				// }

				$ngBootbox.customDialog({
					title: scope.title || $translate.instant('Guidance'),
					message: messageBody,
					onEscape: true,
					className: "guidance-modal",
					buttons: {
						primary: {
							label: $translate.instant('Close'),
							className: "btn-primary"
						}
					}
				});
			}

			function openVideoGuidance($event) {
				var messageBody = "";
				var guidanceVideoUrl = scope.guidanceVideoUrl || scope.itemGuidanceVideoUrl;

				if (guidanceVideoUrl) {
					messageBody += [
						'<div class="guidance-video"><div class="embed-responsive embed-responsive-16by9">',
						'<iframe class="embed-responsive-item" ',
						'allowtransparency="true" frameborder="0" scrolling="no" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="100%" ',
						'src="' + $sanitize(guidanceVideoUrl) + '"></iframe></div></div>'].join("");
				}

				modal.openGuidance({
					title: scope.title || $translate.instant('Guidance'),
					message: messageBody
				});
			}

			// Traverses parent scopes up the scope chain, looks for property vm.context.influence.childContent and checks if the title matches.
			// REALLY BAD!!!
			function traverseParentsForInflunce() {
				var
					parent = scope.$parent,
					vm,
					context,
					influence,
					influenceChildContent,
					maxRecursionDepth = 30
				;

				while (parent) {
					maxRecursionDepth--;

					if (!maxRecursionDepth)
						return;

					if ((vm = parent.vm) && (context = vm.context) && (influence = context.influence) && (influenceChildContent = influence.childContent)) {
						if (influence.title === scope.itemTitle || influence.title === scope.title
							|| influenceChildContent.friendlyTitle === scope.itemTitle || influenceChildContent.friendlyTitle === scope.title
							|| influenceChildContent.title === scope.itemTitle || influenceChildContent.title === scope.title
							) {
							scope.inheritedInfluenceChildContent_relatedContent = influenceChildContent.relatedContent;
							break;
						}
					}

					parent = parent.$parent;
				}
			}
		}
	}
})();