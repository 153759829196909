/**
 * @ngdoc directive
 * @name wfDroppable
 * 
 * @description 
 * wfDroppable directive is used to set any element as droppbale area that accepts files dropped from an OS or items dropped from Worldfavor (item requires wf-draggable directive to work)
 * 
 * @param {Object} droppableOptions Used for configuring the droppable area. 
 * @property singleFile (boolean) When set to false, the directive accepts multiple files to be dropped.
 * @property limitFiles (int) Limits the number of files to be dropped
 * @property detectDragOverDocument (boolean) Will add eventListeners to the document when you drag any file on top of it
 * @property disableOsFileDrop (boolean) A file dragged from an operating system (a folder, desktop etc.) will be accepted if set to true, otherwise it is rejected.
 * @property disableWfItemDrop (boolean) Item in Worldfavor that contains wf-draggable directive will be accepted to the droppable area if set to true, otherwise it is rejected.
 * @property onFileDropped (Function) Accepts any custom function run in the controller where directive is used.
 * @property objectCreation (Object) Options used for defining which object type to create, and where to create a relation if defined. It accepts: objectType, dataRelationOptions (optional) and afterDropCallback
 * @property messages (Object) Defines all of the validation messages. Appears appropriate text depending on the validation, like: disableOsFileDrop, disableWfItemDrop, singleFile, length, dragEnter, revealDraggable
 * @property hideMessageInstantly (boolean) Hides the message as soon as the drop event is finished
 * 
 * 
 * @description
 * Either onFileDropped or objectCreation property has to be defined, but not both.
 * 
 * @example SIMPLE example
 * <div wf-droppable="vm.droppableOptions"></div>
 * 
 * @example ObjectViewer - attach information (creating a document and a relation)
 * <div wf-droppable="{
 * 		singleFile: false,
 *		limitFiles: 5, 
 *		disableOsFileDrop: false, 
 *		disableWfItemDrop: true,
 *		objectCreation: {
 *			objectType: enums.objectType.orgDocument, 
 *			dataRelationOptions: { 
 *				kind: enums.subItemsKind.relatedContentByUser, 
 *				item1: mainItem
 *			},
 *			afterDropCallback: function () {
 *				populateBoxItems(box);
 *				$scope.$broadcast("dropdownActionExecuted", "attach", mainItem);
 *				$timeout();
 *			}
 *		}
 * }"></div>
 * 
 * @example Creating a document only
 * <div wf-droppable="{
 * 		singleFile: false,
 *		limitFiles: 5, 
 *		disableOsFileDrop: false, 
 *		disableWfItemDrop: true,
 *		objectCreation: { objectType: enums.objectType.orgDocument }
 *		afterDropCallback: function() { console.log("CalledAfter every file drop" )} //OPTIONAL
 * }"></div>
 * 
 * @example Custom funciton onFileDropped
 * <div wf-droppable="{
 * 		singleFile: false,
 *		limitFiles: 5, 
 *		disableOsFileDrop: false, 
 *		disableWfItemDrop: true,
 *		onFileDropped: function(file) { console.log("Do something here)" }
 * }"></div>
 * 
 * @example Custom messages when validating
 * <div wf-droppable="{
 * 		singleFile: false,
 *		limitFiles: 5, 
 *		disableOsFileDrop: false, 
 *		disableWfItemDrop: true,
 *		onFileDropped: function(file) { console.log("Do something here)" }
 *		messages: {
 *			disableOsFileDrop: { icon: "fa fa-ban", header: "You are not allowed to drop files here", description: "Please choose a draggable item inside Worldfavor" },
 *			disableWfItemDrop: { icon: "fa fa-ban", header: "You are not allowed to drop items here", description: "Please choose a file outside of the web browser" },
 *			singleFile: { icon: "fa fa-ban", header: "You are not allowed to drop more than one file", description: "Please drop only one file" },
 *			length: { icon: " ", header: " ", description: " " },
 *			dragEnter: { icon: "ionicons ion-arrow-down-a", header: "Drop your file here", description: " " },
 *			revealDraggable: { icon: "ionicons ion-arrow-down-a", header: "You can drop here", description: " " },
 *		}
 * }"></div>
 * 
 * @example wf-droppable with notifier
 * <div>
 *		<div wf-droppable="vm.droppableOptions"></div>
 *		<div wf-droppable-notifier></div>
 * </div>
 */


(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfDroppable', wfDroppable);

	wfDroppable.$inject = [ '$timeout', '$parse', '$interpolate', '$sanitize', '$translate' ];
	function wfDroppable($timeout, $parse, $interpolate, $sanitize, $translate) {
		var directive = {
			bindToController: true,
			controller: wfDroppableController,
			controllerAs: 'dropVm',
			link: link,
			restrict: 'A'
		};
		return directive;
		
		function link(scope, element, attrs) {
			var customOptions,
				options = {
					singleFile: false,
					limitFiles: undefined,
					disableOsFileDrop: false,
					disableWfItemDrop: false,
					detectDragOverDocument: true,
					// acceptFileTypes: {}, //for now only 'image/*' is supported
					acceptOnlyFileType: undefined,
					triggerObjectType: undefined, //enum
					onFileDropped: undefined, //function
					hideMessageInstantly: false,
					messages: {
						disableOsFileDrop: { icon: "fa fa-ban", header: "You are not allowed to drop files here", description: "Please choose a draggable item inside Worldfavor" },
						disableWfItemDrop: { icon: "fa fa-ban", header: "You are not allowed to drop items here", description: "Please choose a file outside of the web browser" },
						noFileType: { icon: "fa fa-ban", header: $translate.instant("modules.upload.dropBoxes.rejectUpload.headerText"), description: "" },
						singleFile: { icon: "fa fa-ban", header: "You are not allowed to drop more than one file", description: "Please drop only one file" },
						swesingleFile: { icon: "fa fa-ban", header: "You are not allowed to drop more than one file", description: "Please drop only one file" },						
						length: { icon: "", header: "", description: "" },
						dragEnter: { icon: "fas fa-arrow-down", header: $translate.instant("modules.upload.dropBoxes.acceptUpload.headerText"), description: "" },
						revealDraggable: { icon: "fas fa-arrow-down", header: $translate.instant("modules.upload.dropBoxes.acceptUpload.headerText"), description: "" },
						drop: { icon: "fa fa-check", header: "", description: "" },
						dropDenied: { icon: "fa fa-ban", header: $translate.instant("modules.upload.dropBoxes.rejectUpload.headerText"), description: "" }
					}
				}
			;

			if (attrs.wfDroppable) {
				customOptions = $parse(attrs.wfDroppable)(scope);
				if (typeof customOptions === 'object') {
					_.merge(options, customOptions);
					activate();
				}
			}
			else 
				return;

			function activate() {
				var safariInUse = $.browser.safari,
					droppableElement = element[0],
					classes = undefined,
					fileTypes = [ 'image/*' ],
					allowDropping = false,
					notifierElement = element,
					dragging = 0,
					documentDragging = 0,
					interpolateFunc,
					messageVisible = false,
					dropMessageElement,
					classes = {
						dragEnter: 'drag-enter',
						drop: 'drop',
						dropDenied: 'drop-denied',
						dragLeave: 'drag-leave', //not used
						reveal: 'reveal-droppable',
						allowDropping: 'allow-dropping',
						denyDropping: 'deny-dropping'
					}
				;
	
				droppableElement.classList.add('wf-droppable');
				notifierElement = element.find("[wf-droppable-notifier]");
				if (!notifierElement.length > 0) {
					element.attr('wf-droppable-notifier', '');
					notifierElement = element;
				}
				
				if (options.detectDragOverDocument)
					initializeDocumentEventListeners();
				
				initializeDroppableEventListeners();

				function initializeDocumentEventListeners() {
					document.addEventListener('dragenter', function() {
						documentDragging++;
						hideNotifierContent();
						addClass(classes.reveal);
						
						if (!messageVisible)
							setMessage(options.messages.revealDraggable);
						
						return false;
					}, false);
	
					//CAUTION - dragover event is running in loop once the file is over the draggable element
					//Required for the drop event listener to work
					document.addEventListener('dragover', function(e) {
						e.dataTransfer.dropEffect = 'move';
						if (e.preventDefault) { e.preventDefault(); }
						return false;
					}, false );
	
					document.addEventListener('dragleave', function(e) {
						documentDragging--;

						if (documentDragging == 2)
							documentDragging--;
	
						if (documentDragging === 0) {
							removeClasses();
							showNotifierContent();
							clearAndHideMessage();
							dragging = 0;
						}

						return false;
					}, false );
	
					document.addEventListener('drop', function(e) {
						if (e.preventDefault) { e.preventDefault(); }
						if (e.stopPropagation) { e.stopPropagation(); }

						removeClasses();
						clearAndHideMessage();
						showNotifierContent();
						documentDragging = 0;
						dragging = 0;

						return false;
					}, false);
				}
	
				function initializeDroppableEventListeners() {
					droppableElement.addEventListener('dragenter', function(e) {
						if (e.preventDefault) { e.preventDefault(); }

						if (dragging === 0 && !safariInUse) 
							validate(e.dataTransfer.items, options);
						else
							addClass(classes.allowDropping);
							
						dragging++;
						addClass(classes.dragEnter);
	
						return false;
					}, false );
		
					//CAUTION - dragover event is running in loop once the file is over the draggable element
					//Required for the drop event listener to work
					droppableElement.addEventListener('dragover', function(e) {
						e.stopPropagation(); 
						e.preventDefault();
						
						e.dataTransfer.dropEffect = 'copy';
						return false;
					}, false );
					
					droppableElement.addEventListener('drop', function(e) {
						if (e.preventDefault) { e.preventDefault(); }
						if (e.stopPropagation) { e.stopPropagation(); }
	
						var files = [], items = [];

						if (safariInUse) {
							files = e.dataTransfer.files;
							validate(files, options);
						}
						else
							items = e.dataTransfer.items;
						
						if (allowDropping && ((items.length != 0) || (files.length != 0))) {
							if (files.length == 0)
								_.each(items, function(item) { files.push(item.getAsFile()) });
							
							removeClass(classes.dragEnter);
							addClass(classes.drop);
							setMessage(options.messages.drop);
							
							if (files.length != 0) {
								scope.dropVm.handleDropFiles(files, {
									objectCreation: options.objectCreation ? options.objectCreation : undefined,
									onFileDropped: options.onFileDropped ? options.onFileDropped : undefined,
								});
							}
							else
								console.error("No files found in items array.", items);
							
							if (options.hideMessageInstantly) {
								clearAndHideMessage();
								removeClasses();
								showNotifierContent();
							}
							else {
								setTimeout(function() {
									clearAndHideMessage();
									removeClasses();
									showNotifierContent();
								}, 2000);
							}
						}
						else {
							removeClass(classes.dragEnter);
							addClass(classes.dropDenied);
							
							if (!safariInUse)
								setMessage(options.messages.dropDenied);
	
							setTimeout(function() {
								clearAndHideMessage();
								removeClasses();
								showNotifierContent();
							}, 2000)
						}
						documentDragging = 0;
						dragging = 0;
	
						return false;
					}, false);
		
					droppableElement.addEventListener('dragleave', function(e) {
						dragging--;

						if (dragging == 2)
							dragging--;
						
						if (dragging === 0) {
							removeClasses([ "allowDropping", "dragEnter" ]);
							if (messageVisible)
								clearAndHideMessage();
						}
						
						return false;
					}, false );
				}
				
				function validate(items, options) {
					if (options.disableOsFileDrop && items.length != 0)
						setMessage(options.messages.disableOsFileDrop);
					else if (options.disableWfItemDrop && items.length == 0) //check appropriately when wf-draggable is implemented
						setMessage(options.messages.disableWfItemDrop);
					else if (options.singleFile && items.length > 1)
						setMessage(options.messages.singleFile);
					else if (items.length > options.limitFiles)
						setMessage( { icon: "fa fa-ban", header: "You exeeded the amount of files to drop", description: "Please drop no more than " + options.limitFiles + " files." } );
					else if (options.acceptOnlyFileType && options.acceptOnlyFileType !== "" && !_.some(items, { 'type': options.acceptOnlyFileType } ))
						setMessage(options.messages.noFileType);
					else if (_.some(items, { 'type': "" } ))
						setMessage(options.messages.noFileType);
					else {
						removeClass(classes.denyDropping);
						addClass(classes.allowDropping);
						setMessage(options.messages.dragEnter);
						return allowDropping = true;
					}
	
					if (options.disableWfItemDrop && options.disableOsFileDrop)
						console.error("Options for wf-droppable not used correctly. Please set either disableWfItemDrop or disableOsFileDrop to true in order to enable drag & drop feature");
					
					allowDropping = false;
					addClass(classes.denyDropping);
	
					return allowDropping;
				}
	
				function setMessage(message) {
					if (!interpolateFunc) {
						interpolateFunc = $interpolate('<div><div class="wrap-val-icon"><i class="val-message-icon {{icon}}" aria-hidden="true"></i></div><span class="val-message-header">{{header}}</span><span class="val-message-description">{{description}}</span></div>');
						dropMessageElement = $('<div class="drop-message"></div>');
						notifierElement.prepend(dropMessageElement);
					}
					
					if (!message)
						message = { icon: "", header: "", description: "" };
					
					dropMessageElement.html(interpolateFunc({
						icon: $sanitize(message.icon),
						header: message.header,
						description: message.description
					}));
					
					messageVisible = true;
				}
	
				function hideMessage() {
					messageVisible = false;
					dropMessageElement.remove();
					interpolateFunc = undefined;
				}

				function clearAndHideMessage() {
					setMessage();
					hideMessage();
				}
	
				function hideNotifierContent() {
					addClass('hide-content');
				}
	
				function showNotifierContent() {
					removeClass('hide-content');
				}
	
				function addClass(newClass, delay) {
					var time = delay ? delay : 0;
					setTimeout(function() {
						notifierElement.addClass(newClass);
					}, time);
				}
	
				function removeClass(classToRemove, delay) {
					var time = delay ? delay : 0;
					setTimeout(function() {
						notifierElement.removeClass(classToRemove);
					}, time);
				}
	
				function removeClasses(classesToRemove) {
					if (!classesToRemove)
						classesToRemove = [ "reveal", "allowDropping", "denyDropping", "dragEnter", "drop", "dropDenied" ];
	
					setTimeout(function() {
						_.each(classesToRemove, function(classToRemove) {
							notifierElement.removeClass(classes[classToRemove]);
						});
					}, 0);
				}
			}
		}
	}
	
	wfDroppableController.$inject = [ '$scope', 'modalService', '$q' ];
	function wfDroppableController($scope, modal, $q) {
		var vm = this;

		_.assign(vm, {
			handleDropFiles: handleDropFiles
		});
		
		function handleDropFiles(files, options) {
			var 
				promise,
				promises = []
			;

			if (files.length != 0)
				createRecursively(files.length);

			function createRecursively(index) {
				var 
					file = files[index - 1],
					preparedOptions = {},
					objType
				;
				
				if (index != 0) {
					if (options.onFileDropped != undefined) {
						promise = options.onFileDropped(file); //onFileDropped has to return a promise
					}
					else if (options.objectCreation != undefined) {
						if (options.objectCreation.dataRelationOptions) {
							preparedOptions = _.assign(options.objectCreation, { initialValues: { preSelectedFile: file } });
							promise = modal.createWithRelation(preparedOptions);
						}
						else {
							objType = options.objectCreation.type || options.objectCreation.objectType;
							if (objType)
								promise = modal.createWithPromise({ type: objType, preSelectedFile: file });
							else
								console.error("wfDroppable.directive.js - objectType not defined");
						}
					}
					else {
						console.error("Misuse of wfDroppable.directive.js - onFileDropped nor objectCreation is defined in droppableOptions. Please check the documentation to use the directives properly.");
						return;
					}
						
					promise.then(function(res) {
						if (res && res.wfid) {
							promises.push(promise);
	
							promise.then(function() {
								if (options.objectCreation) {
									if (options.objectCreation.afterDropCallback)
										options.objectCreation.afterDropCallback();
								}
							}, function() {
								modal.alert({
									title: "Upload failed",
									message: "We regred to inform you that the file could not be uploaded. Please try again!" + file.name,
									type: "info",
									buttons: {
										primary:{
											label: "OK",
											className: "btn-hollow action",
											callback: function() {
												$scope.$close();
											}
										}
									}
								});
							});
	
							createRecursively(--index);
						}
						// else if (res === false)
					});
				}
				else {
					$q.all(promises).then(function() {
						console.log("Finished Uploading!");
					}, function() {
						console.log("Something went wrong with the upload!");
					}).finally(function() {
						console.log("Done");
					});
				}
			}
		}
	}
})();