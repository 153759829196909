(function() {
'use strict';

	angular
		.module('wf.common')
		.service('screenLoaderService', screenLoaderService);

	screenLoaderService.$inject = ['$translate'];

	function screenLoaderService($translate) {
		var
			defaultMessage = $translate.instant("Loading") + "...",
			element = $("<div />"),
			messageElement,
			extrasContainer = $("body").children("div.worldfavor-extras-container"),
		// if (window.wf__embeddedWidgetMode) {
			service = {
				show: function (message, fade) {
					messageElement.text(message || ($translate.instant("Loading") + "..."));

					if (fade) {
						element.fadeIn(typeof fade === "number" ? fade : 300);
					}
					else {
						element.show();
					}
				},
				showAndHide: function () {
					messageElement.text(($translate.instant("Loading") + "..."));
					element.show();
					setTimeout(function () {
						element.hide();
					}, 100);
				},
				hide: function (fade) {
					if (fade) {
						element.fadeOut(typeof fade === "number" ? fade : 300);
					}
					else {
						element.hide();
					}
				},
				showWfLoader: function () {
					messageElement.text("");
					element.addClass("loader")
					element.fadeIn(300);
				},
				hideWfLoader: function () {
					element.hide();
					element.removeClass("loader");
				}
			}
		;
		
		element.addClass("worldfavor-screenLoader")
		element.append(messageElement = $("<div />").text(defaultMessage));

		if (!extrasContainer.length) {
			extrasContainer = $("<div class='wf-w worldfavor-extras-container'></div>").appendTo($("body"));
		}

		extrasContainer.append(element);

		return service;
	}
})();