import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';

	angular
		.module('wf.common')
		.component('wfExportPdf', {
			require: {
				wfHierarchical: "?^^wfHierarchical"
			},
			template: '<button class="btn btn-primary" ng-class="{\'loader-small\': vm.downloadingPdf || vm.loadingPreview }" wf-click="vm.showDropdown()" uib-popover-template="vm.popoverTemplate" popover-append-to-body="true" popover-class="export-pdf-popover" popover-trigger="\'outsideClick\'" popover-placement="bottom-right" popover-class="export-popover"><i ng-class="vm.icon" class="icon fa fa-file-pdf-o"></i><span class="caption" translate="modules.export.pdf.exportButton"></span></button>',
			controller: wfExportPdfController,
			controllerAs: 'vm',
			bindings: {
				item: '=',
				outerVm: '='
			},
		});

	wfExportPdfController.$inject = [ "$scope", "$compile", "$uibModal", "$q", "pdfGenerator", "$translate", "wfAuth", "$http", "$sanitize", "dataOperationsService" ];
	function wfExportPdfController($scope, $compile, $uibModal, $q, pdfGenerator, $translate, wfAuth, $http, $sanitize, dataOps) {
		var vm = this;
		var modalInstance;
		var isoStandardWfids;


		this.$onInit = $onInit;


		function $onInit() {

			_.assign(vm, {
				//Variables
				loadingPreview: false,
				downloadingPdf: false,
				tempScope: undefined,
				popoverTemplate: "scripts/wf/importExport/pdfExportPopover.template.html",
				showIsoStandardExportMessage: false,
				exportOptions: {
					coverPage: {
						label: $translate.instant("modules.export.pdf.popover.options.coverPage"),
						include: true,
						svgInfo: "assets/img/pdf-export/cover-page-info.html",
					},
					pageHeading: {
						label: $translate.instant("modules.export.pdf.popover.options.pageHeading"),
						include: true,
						svgInfo: "assets/img/pdf-export/page-heading-info.html"
					},
					userData: {
						label: $translate.instant("modules.export.pdf.popover.options.userData"),
						include: true,
						svgInfo: "assets/img/pdf-export/user-data-info.html"
					},
					itemMeasureChart: {
						label: $translate.instant("modules.export.pdf.popover.options.measureDataChart"),
						include: true,
						svgInfo: "assets/img/pdf-export/measure-data-chart-info.html"
					},
					// filterBars: {
					// 	label: "Filter Bars",
					// 	include: false
					// },
					// levelsFilterBar: {
					// 	label: "Level filter bar",
					// 	include: false,
					// },
					// sunburstChart: {
					// 	label: "Chart",
					// 	include: false
					// }
				},
				selectedStyle: undefined,
				exportStyles: {
					default: {
						class: "default-style",
						label: $translate.instant("modules.export.pdf.popover.styles.default")
					},
					compact: {
						class: "compact-style",
						label: $translate.instant("modules.export.pdf.popover.styles.compact")
					},
					// minimal: {
					// 	class: "minimal-style",
					// 	label: $translate.instant("modules.export.pdf.popover.styles.minimal")
					// },
					// elegant: {
					// 	class: "elegant-style",
					// 	label: $translate.instant("modules.export.pdf.popover.styles.elegant")
					// }
				},

				//Functions
				showDropdown: showDropdown,
				showPreviewHtml: showPreviewHtml,
				showPreviewPdf: showPreviewPdf,
				downloadPdf: downloadPdf
			});
		}

		function showDropdown() {
			modalInstance = undefined;
			vm.tempScope = undefined;
			vm.selectedStyle = vm.exportStyles.default;

			checkForIsoStandardItems();
		}

		function showPreviewHtml() {
			vm.loadingDevPreview = true;
			prepareHtmlForExporting(true).then(function() {
				showModal();
				vm.loadingDevPreview = false;
			}, function(rejectedScope) {
				console.error("Could not get directive element from the scope. ", rejectedScope);
			});
			
			function showModal() {
				modalInstance = $uibModal.open({
					size: 'width-1200',
					windowClass: "wf-export-pdf-modal",
					animation: true,
					backdrop: 'static',
					template: '<div><div class="header" style="display: inline-block;"><button type="button" class="close-modal-button close" wf-click="$close()" aria-hidden="true">Close</button></div><div class="pdf-preview-html"><wf-hierarchical item="item" related-content-as-children="{{vm.exportOptions.userData.include}}" vm-prototype="vmPrototype" influence="influence" ticket="ticket"></wf-hierarchical></div></div>',
					scope: vm.tempScope
				});
	
				modalInstance.closed.then(function(result) {
					vm.tempScope.$destroy();
					modalInstance = undefined;
				}, function(rejectedResult) {
					vm.tempScope.$destroy();
					modalInstance = undefined;
					console.error("Rejected result modal - ", rejectedResult);
				});
			}
		}

		function showPreviewPdf() {
			if (!vm.loadingPreview) {
				var deferred = $q.defer();
				vm.loadingPreview = true;
				prepareHtmlForExporting().then(function() {
					showModal();
					generatePdf(true).then(function(blob) {
						vm.tempScope.url = URL.createObjectURL(blob);
						vm.loadingPreview = false;
					}, function(rejectedItem) {
						console.error("Could not generate PDF - ", rejectedItem);
					});
				}, function(rejectedScope) {
					console.error("Could not get directive element from the scope. ", rejectedScope);
				});
	
				return deferred.promise;
			}

			function showModal() {
				modalInstance = $uibModal.open({
					size: 'width-1200',
					windowClass: "wf-export-pdf-modal",
					animation: true,
					backdrop: 'static',
					templateUrl: "scripts/wf/importExport/wfExportPdfModal.template.html",
					scope: vm.tempScope
				});
	
				modalInstance.closed.then(function(result) {
					URL.revokeObjectURL(vm.tempScope.url); // cleanup
					vm.tempScope.$destroy();
					modalInstance = undefined;
					
					//wfPdfViewer.component.js has document.onkeydown set to a function that changes a page when left/right arrows are pressed
					document.onkeydown = null; 
				}, function(rejectedResult) {
					vm.tempScope.$destroy();
					modalInstance = undefined;
					console.error("Rejected result modal - ", rejectedResult);
				});
			}
		}

		function downloadPdf() {
			if (!vm.downloadingPdf && !vm.loadingPreview) {
				vm.downloadingPdf = true;
				if (!vm.tempScope) {
					prepareHtmlForExporting().then(function() {
						generatePdf().then(function() {
							vm.downloadingPdf = false;
							if (!modalInstance && vm.tempScope)
								vm.tempScope.$destroy();
						}, function(rejectedItem) {
							console.error("Counld not generate PDF ", rejectedItem);
						});
					}, function(rejectedScope) {
						console.error("Could not get directive element from the scope. ", rejectedScope);
					});
				}
				else {
					generatePdf().then(function() {
						vm.downloadingPdf = false;
						if (!modalInstance)
							vm.tempScope.$destroy();
					}, function(rejectedItem) {
						console.error("Could not get directive element form the scope. ", rejectedItem);
					});
				}
			}
		}

		function generatePdf(returnOnlyBlob) {
			var deferred = $q.defer();
			var footerUrl;

			if (vm.exportOptions.coverPage.include)
				footerUrl = "scripts/wf/importExport/wfExportPdfFooterExcludingCover.template.html";
			else
				footerUrl = "scripts/wf/importExport/wfExportPdfFooterIncludingCover.template.html";

			$http.get(footerUrl).then(function(res) {
				if (res.status === 200) {
					var pdfParameters = {
						FileName: vm.tempScope.item.title,
						PageWidth: 210,
						PageHeight: 297,
						MarginBottom: 20,
						FooterFontName: "Arial",
						FooterHtml: res.data,
					};
					vm.tempScope.fileName = pdfParameters.FileName;
					
					if (vm.tempScope && vm.tempScope.wfHierarchicalElement) {
						pdfGenerator.fromElement(vm.tempScope.wfHierarchicalElement, pdfParameters.FileName, null, { onlyDownload: true, returnOnlyBlob: returnOnlyBlob, pdfParameters: pdfParameters }).then(function(resolvedItem) {
							deferred.resolve(resolvedItem);
						}, function(rejectedItem) {
							deferred.reject(rejectedItem);
						});
					}
					else {
						deferred.reject("tempScope or wfHierarchicalElement not defined");
					}
				}
				else {
					deferred.reject("Could not get footerUrl");
					console.error("Could not get footerUrl. Please check if the footerUrl is correct");
				}
			});

			return deferred.promise;
		}

		function checkForIsoStandardItems() {
			var isoStandardsStructure = wfObject.get("71-13546");
			vm.showIsoStandardExportMessage = false;

			if (!isoStandardWfids && isoStandardsStructure) {
				isoStandardWfids = _.map(isoStandardsStructure.childs, 'wfcid');
			}

			if (isoStandardWfids && isoStandardWfids.length) {
				_.each(vm.outerVm.allCascadingItems, function(item) {
					if (isoStandardWfids.indexOf(item.content.ancestorWfid) !== -1) {
						vm.showIsoStandardExportMessage = true;
						return false;
					}
				});
			}
		}

		function prepareHtmlForExporting(isHtmlPreview) {
			var deferred = $q.defer();
			var wfHierarchicalElement, listener;
			var mainHierarchicalVm = vm.outerVm;
			var coverPageHtml = "";
			var limitedItems = [];
			vm.tempScope = $scope.$new();

			//Check for iso standard items and exclude them, also from included items take the ones that have relationWfid
			_.each(mainHierarchicalVm.allCascadingItems, function(item) {
				var excludeItem = false;
				if (isoStandardWfids && isoStandardWfids.length) {
					if (isoStandardWfids.indexOf(item.content.ancestorWfid) !== -1)
						excludeItem = true;
				}
				if (item.relationWfid && !excludeItem)
					limitedItems.push(item.relationWfid);
			});

			_.assign(vm.tempScope, {
				item: vm.item,
				influence: _.get(vm.wfHierarchical, "influenceFromDirective"),
				ticket: _.get(vm.wfHierarchical, "ticket"),
				exportingToPdf: true,
				vmPrototype: {
					limitItemRelationWfids: limitedItems,
					showFilterBars: false,
					showLevelsFilterBar: false,
					showSunburstChart: false,
					showItemMeasureChart: vm.exportOptions.itemMeasureChart.include,
					waitForFindingsToLoad: true,

					showInlineRelatedContent: vm.exportOptions.userData.include,
					includeRelatedContentAsChildren: vm.exportOptions.userData.include,
					loadRelatedContentByUserOnAllItems: vm.exportOptions.userData.include,

					showRequirements: false,
					showSigning: false,
					answeringUiMode: enums.uiMode.view,
					isWorkMode: false,
					showBackButton: false,
					infiniteScrollChunkSize: 10000,
					isPrintMode: true,

					itemSettings: {
						showMetadata: false,
						showDropdown: false,
						// pdfExport: true,
						showMeasureChart: vm.exportOptions.itemMeasureChart.include,
						showRelatedContentAsChildren: vm.exportOptions.userData.include,
					}
				}
			});

			if (vm.exportOptions.coverPage.include) {
				coverPageHtml = '<div class="cover-page"><div class="text"><span class="organization-name">' + $sanitize(wfAuth.getOrganization().name) + '</span><h2 class="title">' + $sanitize(vm.tempScope.item.title) + '</h2><p class="description text-pre-wrap">' + $sanitize(vm.tempScope.item.description) + '</p></div><div class="logo"></div></div>';
			}

			if (!isHtmlPreview) {
				var relatedContentAsChildren = vm.exportOptions.userData.include ? 'related-content-as-children="true"' : '';
				var hierarchicalLinkFunc = $compile('<wf-hierarchical item="item" ' + relatedContentAsChildren + ' vm-prototype="vmPrototype" influence="influence" ticket="ticket"></wf-hierarchical>');
				initializeHierarchicalListener();
				hierarchicalLinkFunc(vm.tempScope);
			}
			else {
				initializeHierarchicalListener();
				deferred.resolve();
			}

			function initializeHierarchicalListener() {
				listener = $scope.$on("hierarchicalLoaded", function(result) {
					if (result && result.targetScope && result.targetScope.directiveElement) {
						wfHierarchicalElement = result.targetScope.directiveElement;

						replaceSubScriptCharactersWithSubTag(wfHierarchicalElement);
						//Set classes
						wfHierarchicalElement.addClass("print-mode");
						wfHierarchicalElement.addClass(vm.selectedStyle.class);
						
						if (coverPageHtml !== "")
							wfHierarchicalElement.prepend(coverPageHtml);
	
						vm.tempScope.wfHierarchicalElement = wfHierarchicalElement;
						vm.tempScope.downloadPdf = downloadPdf;
		
						deferred.resolve(vm.tempScope);
					}
					else {
						console.error("Could not get directive element");
						deferred.reject(vm.tempScope);
					}
	
					listener();
				});

				function replaceSubScriptCharactersWithSubTag(wfHierarchicalElement) {
					var charactersToBeReplaced = {
						"₀": "<sub>0</sub>", 
						"₁": "<sub>1</sub>", 
						"₂": "<sub>2</sub>",
						"₃": "<sub>3</sub>",
						"₄": "<sub>4</sub>",
						"₅": "<sub>5</sub>",
						"₆": "<sub>6</sub>",
						"₇": "<sub>7</sub>",
						"₈": "<sub>8</sub>",
						"₉": "<sub>9</sub>"
					};

					var wfHierarchicalHtml = wfHierarchicalElement.html();
					var regExpresion;

					_.each(charactersToBeReplaced, function(value, key) {
						regExpresion = RegExp(key, "g");
						wfHierarchicalHtml = _.replace(wfHierarchicalHtml, regExpresion, value);
					});

					wfHierarchicalElement.html(wfHierarchicalHtml);
				}
			}

			return deferred.promise;
		}
	}
})();
