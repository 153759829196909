import React from 'react'
import { react2angular } from 'react2angular'
import CalculationScheduleDialog from '@worldfavor/portal/components/Calculation/CalculationScheduleDialog'
import { WrapProvider } from './providers'

const props = [
  'open',
  'onClose',
  'onSave',
  'calculationSchedule',
]

class CalculationScheduleDialogWrapper extends React.Component {
  prepareProps = (props) => {
    return {
      ...props,
    }
  }

  render() {
    const { wfAuth, ...rest } = this.props
    return (
      <WrapProvider wfAuth={wfAuth}>
        <CalculationScheduleDialog {...this.prepareProps(rest)} />
      </WrapProvider>
    )
  }
}

export default react2angular(CalculationScheduleDialogWrapper, props, ['wfAuth'])
