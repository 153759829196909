(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfHoverDisablePageScroll', wfHoverDisablePageScroll);

	var scrollbarWidth;

	function wfHoverDisablePageScroll() {
		var directive = {
			restrict: "A",
			link: function (scope, element) {
				var
					body = $("body"),
					topnav = $("#topnav"),
					originalPaddingRight = parseInt(body.css("paddingRight")) || 0
					// win = $(document),
					// id = "scrollWheelBlock" + scope.$id;
				;

				if (!scrollbarWidth)
					scrollbarWidth = getScrollbarWidth();

				element.on("mouseenter", function () {
					body.css({ overflowY: "hidden", paddingRight: originalPaddingRight + scrollbarWidth });
					topnav.css({ right: scrollbarWidth });
					// win.on("mousewheel." + id + " DOMMouseScroll." + id, function (e) {
					// 	e.preventDefault();
					// });
				});

				element.on("mouseleave", function () {
					body.css({ overflowY: "", paddingRight: "" });
					topnav.css({ right: "" });
					// win.off("mousewheel." + id + " DOMMouseScroll." + id);
				});

				scope.$on("$destroy", function () {
					element.off("mouseenter");
					element.off("mouseleave");
					body.css({ overflowY: "", paddingRight: "" });
					topnav.css({ right: "" });
					// win.off("mousewheel." + id + " DOMMouseScroll." + id);
				})
			}
		};
		return directive;
	}

	function getScrollbarWidth() {
		var outer = document.createElement("div");
		outer.style.visibility = "hidden";
		outer.style.width = "100px";
		outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

		document.body.appendChild(outer);

		var widthNoScroll = outer.offsetWidth;
		// force scrollbars
		outer.style.overflow = "scroll";

		// add innerdiv
		var inner = document.createElement("div");
		inner.style.width = "100%";
		outer.appendChild(inner);        

		var widthWithScroll = inner.offsetWidth;

		// remove divs
		outer.parentNode.removeChild(outer);

		return widthNoScroll - widthWithScroll;
	}
})();