// @flow
import * as React from 'react'
import { FormattedDate } from 'react-intl'

type Props = {
  value: string,
  textStyle: { [string]: any },
  iconStyle: { [string]: any },
}

class CreationDateHeader extends React.PureComponent<Props> {

  static defaultProps = {
    textStyle: {},
    iconStyle: {},
  }

  render() {
    const { value, textStyle, iconStyle } = this.props
    return (
      <React.Fragment>
        <i
          className={'far fa-clock'}
          style={{ paddingRight: 4, ...iconStyle }}
        />
        <span style={{ paddingTop: 2, ...textStyle }}>
          <FormattedDate
            weekday={'short'}
            year={'numeric'}
            day={'numeric'}
            month={'long'}
            hour={'2-digit'}
            minute={'2-digit'}
            value={value}
          />
        </span>
      </React.Fragment>
    )
  }
}

export default CreationDateHeader
