import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';

	angular
		.module('wf.common')
		.controller('OrganizationIntroController', OrganizationIntroController);

	OrganizationIntroController.$inject = [ '$scope', '$q', '$timeout', 'wfObject', '$rootScope', 'dataOperationsService', '$translate', 'wfAuth', 'modalService', 'formSchemaService', '$uibModalInstance', 'dataQuery', '$state' ];
	function OrganizationIntroController($scope, $q, $timeout, wfObject, $rootScope, dataOps, $translate, wfAuth, modal, formSchemaService, $uibModalInstance, dataQuery, $state) {
		var
			vm = this,
			originalRelations,
			tempSelected = [],
			tempRemoved = [],
			tempPreSelected = [],
			preSelectedSaved = false,
			instantVatRegOrGlnNumberValidation = false,
			modalClosed = false
		;

		_.assign(vm, {
			loaded: false,
			orgObjectType: enums.objectType.organization,
			organizationId: wfAuth.getOrganizationId(),
			submitButtonClicked: submitButtonClicked,
			customFormSpec: undefined,
			closeModal: closeModal,
			attemptCloseModal: attemptCloseModal,
			formControl: {},
		});

		activate();

		function activate() {
			getCustomFormSpecification().then(function() {
				dataOps.getObject({ objectType: vm.orgObjectType, objectId: vm.organizationId }).then(function(org) {
					vm.organization = org;
					vm.loaded = true;
					$timeout();
				});
			});

			$rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
				if (!modalClosed) {
					closeModal();
				}
			});

			setupSecretClosingEvents();
		}

		function getCustomFormSpecification() {
			var isVatRegOrGlnNumberValid = false;
			var regNumberValid = false;
			var vatNumberValid = false;
			var glnNumberValid = false;

			return formSchemaService.getFromType(enums.objectType.organization).then(function (res) {
				res.schema.required = [ "name", "country", "employeeCountSpan" ];
				vm.customFormSpec = {
					schema: res.schema,
					form: [
						{
							key: "name",
							title: $translate.instant("modules.organization.introModal.form.orgName"),
						},
						{
							type: "template",
							template: '<span class="enter-number"><strong>Please enter one of the following numbers below *</strong></span>',
						},
						{
							type: "section",
							htmlClass: "registration-vat row mr0",
							items: [
								{
									key: "registrationNumber",
									title: $translate.instant("modules.organization.introModal.form.regNumber"),
									htmlClass: "col-sm-4",
									validationMessage: {
										vatOrRegNumber: $translate.instant("validationMessages.vatRegOrGlnNumber")
									},
									$validators: {
										vatOrRegNumber: function (value) {
											if (!instantVatRegOrGlnNumberValidation) return true;

											var result = !!(glnNumberValid || vatNumberValid || value)
											regNumberValid = !!value;

											if (isVatRegOrGlnNumberValid !== result) {
												isVatRegOrGlnNumberValid = result;
												vm.formControl.$scope.$broadcast('schemaForm.error.vatNumber', 'vatOrRegNumber', result);
												vm.formControl.$scope.$broadcast('schemaForm.error.gln', 'vatOrRegNumber', result);
											}

											return result;
										}
									}
								},
								{
									key: "vatNumber",
									title: $translate.instant("modules.organization.introModal.form.vatNumber"),
									htmlClass: "col-sm-4",
									validationMessage: {
										vatOrRegNumber: $translate.instant("validationMessages.vatRegOrGlnNumber")
									},
									$validators: {
										vatOrRegNumber: function (value) {
											if (!instantVatRegOrGlnNumberValidation) return true;

											var result = !!(glnNumberValid || regNumberValid || value)
											vatNumberValid = !!value;

											if (isVatRegOrGlnNumberValid !== result) {
												isVatRegOrGlnNumberValid = result;
												vm.formControl.$scope.$broadcast('schemaForm.error.registrationNumber', 'vatOrRegNumber', result);
												vm.formControl.$scope.$broadcast('schemaForm.error.gln', 'vatOrRegNumber', result);
											}

											return result;
										}
									}
								},
								{
									key: "gln",
									title: $translate.instant("GLNNumber"),
									htmlClass: "col-sm-4",
									validationMessage: {
										vatOrRegNumber: $translate.instant("validationMessages.vatRegOrGlnNumber")
									},
									$validators: {
										vatOrRegNumber: function (value) {
											if (!instantVatRegOrGlnNumberValidation) return true;

											var result = !!(regNumberValid || vatNumberValid || value)
											glnNumberValid = !!value;

											if (isVatRegOrGlnNumberValid !== result) {
												isVatRegOrGlnNumberValid = result;
												vm.formControl.$scope.$broadcast('schemaForm.error.vatNumber', 'vatOrRegNumber', result);
												vm.formControl.$scope.$broadcast('schemaForm.error.registrationNumber', 'vatOrRegNumber', result);
											}

											return result;
										}
									}
								},
							]
						},
						{
							key: "country",
							title: $translate.instant("modules.organization.introModal.form.country")
						},
						{
							key: "employeeCountSpan",
							title: $translate.instant("modules.organization.introModal.form.employeeRange")
						},
						{
							key: "industry",
							title: $translate.instant("modules.organization.introModal.form.industries.label"),
							type: "picker_multiple",
							typeOptions: {
								addButtonCaption: $translate.instant("Select") + " " + $translate.instant("Industry").toLowerCase(),
								targetWfid: "71-14409", // Currently selected industries
								picker: {
									sourceItem: "71-13886", // List of available industries
									title: $translate.instant("modules.organization.introModal.form.industries.pickerTitle"),
									description: $translate.instant("modules.organization.introModal.form.industries.pickerDescription")
								}
							}
						},
						{
							type: "section",
							htmlClass: "logo-address row mr0",
							items: [
								{
									type: "section",
									htmlClass: "col-sm-6 pr0 organization-logo",
									items: [ { key: "imageUpload" } ]
								},
								{
									type: "section",
									htmlClass: "col-sm-6 pr0 organization-location",
									items: [ { key: "primaryLocationId" } ]
								}
							]
						}
					]
				}
			});
		}

		function broadcastRegVatGlnStatus(result) {
			vm.formControl.$scope.$broadcast('schemaForm.error.registrationNumber', 'vatOrRegNumber', result);
			vm.formControl.$scope.$broadcast('schemaForm.error.vatNumber', 'vatOrRegNumber', result);
			vm.formControl.$scope.$broadcast('schemaForm.error.gln', 'vatOrRegNumber', result);
		}

		function submitButtonClicked($event) {
			var deferred = $.Deferred(),
				promises = [];

			vm.button = $($event.target);

			if (!vm.formControl.isValid())
				return;

			vm.button.addClass('loading');

			$q.all(promises).then(function () {
				submit().then(function(valid) {
					if (valid) {
						$uibModalInstance.close();
						$state.go("root", null, { location: "replace" });
					}
					else
						vm.button.removeClass('loading');


					deferred.resolve();
				});
			});

			return deferred.promise;
		}

		function submit() {
			var
				promises = [],
				jqDf = $.Deferred()
			;

			// console.log("Submit: ", vm);

			if (vm.formControl.isValid()) {

				vm.formControl.submit(function (model, deferred) {
					model.id = vm.organizationId;
					model.wfid = vm.orgObjectType + "-" + vm.organizationId;
					model.type = vm.orgObjectType;
					console.log(model)
					if (!model.registrationNumber && !model.vatNumber && !model.gln) {
						instantVatRegOrGlnNumberValidation = true;
						broadcastRegVatGlnStatus(false);
						deferred.reject();
						return false;
					}

					// Checking if location should be removed
					if (vm.organization.primaryLocationId && !model.primaryLocationId) {
						promises.push(dataOps.destroy(vm.primaryLocation));
					}

					$q.all(promises).then(function () {
						dataOps.update(model).then(function (updatedOrg) {
							wfAuth.assignAuthUserValues({
								organizationName: updatedOrg.name
							});

							if (model.imageUpload) {
								delete model.imageUpload;
							}

							deferred.resolve(model);
							vm.organization = wfObject.get(vm.organization.wfid);
							if (vm.organization.primaryLocationId)
								vm.primaryLocation = wfObject.get(enums.objectType.location + "-" + vm.organization.primaryLocationId);
							else
								vm.primaryLocation = undefined;

						});
					});
				}).then(function (valid) {
					jqDf.resolve(valid);
				});
			}
			return jqDf.promise();
		}

		function closeModal() {
			modalClosed = true;
			$uibModalInstance.close();
			if ($state.current.name !== "networkLandingScene" && $state.current.name !== "root") {
				setTimeout(() => $state.go("root", null, { location: "replace" }), 1000)
			}
		}

		function attemptCloseModal() {
			if (vm.ctrlKeyPressed)
				closeModal();
		}

		function setupSecretClosingEvents() {
			vm.ctrlKeyPressed = false;

			$(document).on("keydown.orgInto", function(e) {
				if (e.ctrlKey)
					vm.ctrlKeyPressed = true;
			});

			$(document).on("keyup.orgInto", function(e) {
				if (e.ctrlKey)
					vm.ctrlKeyPressed = false;

			});

			$scope.$on("$destroy", function () {
				$(document).off("keydown.orgInto");
				$(document).off("keyup.orgInto");
			});
		}
	}
})();
