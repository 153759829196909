import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Colors, { addTransparency } from '@worldfavor/constants/colors'
import classNames from 'classnames'
import HighlightedText from '@worldfavor/components/Text/HighlightedText'
import Button from '@material-ui/core/Button'
import DropdownMenu from '@worldfavor/components/DropdownMenu'
import MenuItem from '@material-ui/core/MenuItem'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    minWidth: 200,
    position: 'relative',
  },
  active: {
    '& $container': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
  },
  sidePan: {
    position: 'absolute',
    borderRadius: '4px 0px 0px 4px',
    backgroundColor: Colors.black,
    width: 4,
    height: '100%',
  },
  container: {
    border: `solid 2px ${Colors.graySemiLight}`,
    cursor: 'pointer',
    color: Colors.black,
    padding: 12,
    paddingLeft: 14,
    borderRadius: 4,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
  },
  imageContainer: {
    width: 55,
    height: 55,
    marginRight: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    borderRadius: 4,
  },
  organizationName: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 3,
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    textAlign: 'left',
  },
  icon: {
    fontSize: 20,
  },
  clearButton: {
    marginLeft: 8,
    padding: 5,
    margin: 3,
  },
  organizationLabel: {
    display: 'flex',
    width: '100%',
  },
  dropdownMenu: {
    marginLeft: 16,
  },
})

const useActorTypeStyles = makeStyles(theme => ({
  root: {

  },
  button: {
    backgroundColor: addTransparency(theme.palette.common.black, 0.08),
  },
  subtitle: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
}))

const ActorTypeContainer = (props) => {
  const { actorType, allowEditing, onSetActorTypeClick } = props
  const classes = useActorTypeStyles(props)

  if (actorType) {
    return <div className={classes.subtitle}>{actorType.title}</div>
  }
  else if (allowEditing) {
    return (
      <Button className={classes.button} size="small" onClick={onSetActorTypeClick}>
        <FormattedMessage id={'supplyChain.mapper.actorTypes.setType'} />
      </Button>
    )
  }

  return null
}

const OrganizationNode = (props) => {
  const {
    node,
    organization,
    onMouseOver,
    onMouseOut,
    onHoverChange,
    onClick,
    active,
    matchText,
    canDelete,
    onDelete,
    showDropdownMenu,
    allowEditingActorTypes,
    onSetActorTypeClick,
  } = props

  const { temporary } = node
  const classes = useStyles(props)
  const actorType = getActorType()

  function getActorType() {
    return get(node, 'properties.actorTypes[0]') || get(organization, 'actorTypes[0]')
  }

  function _onMouseOver(e) {
    onMouseOver && onMouseOver(e)
    onHoverChange && onHoverChange(node, true)
  }

  function _onMouseOut(e) {
    onMouseOut && onMouseOut(e)
    onHoverChange && onHoverChange(node, false)
  }

  function _onClick(e) {
    onClick && onClick(e, node, organization)
  }

  function _onDelete(e) {
    onDelete && onDelete(e, node, organization)
    e.stopPropagation()
  }

  function _onSetActorTypeClick(event) {
    onSetActorTypeClick(event, node, organization)
  }

  return (
    <div
      onMouseOver={_onMouseOver}
      onMouseOut={_onMouseOut}
      onClick={_onClick}
      className={classNames(classes.root, active && classes.active)}
    >
      <div className={classes.sidePan} />
      <div
        className={classes.container}
        style={canDelete ? { paddingRight: 4 } : {}}
      >
        <div className={classes.organizationLabel}>
          <div className={classes.imageContainer}>
            {
              organization.imageUrl ? (
                <div
                  className={classes.image}
                  style={{ backgroundImage: `url('${organization.imageUrl}')` }}
                />
              ) : <i className={classNames('fas fa-box-open', classes.icon)} />
            }
          </div>
          <div className={classes.infoContainer}>
            <div className={classes.organizationName}>
              <HighlightedText text={organization.name} match={matchText} />
              <div>{organization.registrationNumber}</div>
            </div>
            {
              !temporary && (
                <ActorTypeContainer
                  allowEditing={allowEditingActorTypes}
                  actorType={actorType}
                  onSetActorTypeClick={_onSetActorTypeClick}
                />
              )
            }
          </div>
        </div>
        {
          !temporary && showDropdownMenu && (
            <div>
              <DropdownMenu IconButtonProps={{ classes: { root: classes.dropdownMenu } }}>
                <MenuItem onClick={_onSetActorTypeClick}>
                  <FormattedMessage id={'supplyChain.mapper.actorTypes.setType'} />
                </MenuItem>
                {canDelete && <MenuItem onClick={_onDelete}><FormattedMessage id={'supplyChain.mapper.removeOrganizationFromTree.removeButtonLabel'} /></MenuItem>}
              </DropdownMenu>
            </div>
          )
        }
        {
          temporary && (
            <CircularProgress
              size={23}
              style={{ marginRight: 10, marginLeft: 10  }}
            />
          )
        }
      </div>

    </div>
  )
}

export default OrganizationNode
