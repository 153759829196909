import * as enums from '@worldfavor/constants/enums'

(function () {
	'use strict';

	angular
		.module('wf.common')
		.controller('WfClaimOrganizationController', WfClaimOrganizationController);

	WfClaimOrganizationController.$inject = ['$scope', '$element', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', '$timeout', 'formSchemaService', '$q', '$rootScope', 'store', 'DS', 'wfAuth', 'responsiveUi' ];
	function WfClaimOrganizationController($scope, $element, dataOps, modal, wfObject, dataQuery, $translate, $timeout, formSchemaService, $q, $rootScope, store, DS, wfAuth, responsiveUi) {
		var vm = this;
		var urlRegExp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g;

		_.assign(vm, {
			//Variables
			submittingForm: false,
			allFormsLoaded: false,
			pageTitle: $translate.instant("modules.signup.claimOrg.pageHeader.title", { userName: wfAuth.getWorldfavorUser().given_name + "!" }),
			forms: {
				claimOrgForm: {
					loaded: false,
					formControl: {},
					model: {},
					required: [ "name", "country", "employeeCountSpan", "primaryWebsite" ],
					objectType: enums.objectType.signupOrganization,
					schema: undefined,
					form: [
						{
							key: "name",
							title: $translate.instant("modules.organization.introModal.form.orgName")
						},
						{
							key: "country",
							title: $translate.instant("modules.organization.introModal.form.country")
						},
						{
							key: "employeeCountSpan",
							title: $translate.instant("modules.organization.introModal.form.employeeRange")
						},
						{
							key: "primaryWebsite",
							title: $translate.instant("Website"),
							validationMessage: {
								checkUrl: "URL not valid"
							},
							$validators: {
								checkUrl: function (value) {
									var checkedValue, result = false;
									if (value && value.length > 0) {
										checkedValue = value.match(urlRegExp);
										result = false;
										if (checkedValue)
											result = true;
									}
									else if (value === "" || !value)
										result = true;

									return result;
								}
							}
						}
					]
				},
				industriesForm: {
					loaded: false,
					formControl: {},
					model: {},
					required: [ "industry" ],
					schema: {
						additionalProperties: false,
						properties: {
							industry: {
								"format": "picker_multiple",
								"items": {
									type: "integer"
								},
								"title": "Industry",
								"type": [ "Array", "null" ],
								"x-schema-form": { title: "Industry" }
							}
						},
						required: [ "industry" ],
						type: "object"
					},
					form: [
						{
							key: "industry",
							title: $translate.instant("Industry"),
							type: "picker_multiple",
							typeOptions: {
								addButtonCaption: $translate.instant("Select") + " " + $translate.instant("Industry").toLowerCase(),
								skipInitialLoading: true,
								targetWfid: "71-14409", // Currently selected industries
								picker: {
									sourceItem: "71-13886", // List of available industries
									title: $translate.instant("modules.organization.introModal.form.industries.pickerTitle"),
									description: $translate.instant("modules.organization.introModal.form.industries.pickerDescription")
								}
							}
						}
					]
				}
			},

			//Functions
			submit: submit
		});

		responsiveUi.enableResponsiveness();
		activate();

		function activate() {
			$rootScope.setPageTitle("");
			var queue;
			if (vm.forms && !_.isEmpty(vm.forms)) {
				queue = _.clone(_.values(vm.forms));
				getFormSpecifications(queue.shift(), queue).then(function(formsLoaded) {
					vm.allFormsLoaded = formsLoaded;
				});
			}
		}

		function submit() {
			if (!vm.submittingForm) {
				var claimOrgForm = vm.forms.claimOrgForm;
				var industriesForm = vm.forms.industriesForm;

				var claimOrgFormValid = function() {
					claimOrgForm.formControl.isValid();
					claimOrgForm.model = claimOrgForm.formControl.getModel();
	
					return claimOrgForm.formControl.isValid();
				}
				var industriesFormValid = industriesForm.formControl.isValid();
	
				if (claimOrgFormValid() && industriesFormValid) {
					vm.submittingForm = true;
					claimOrgForm.formControl.submit(function (model, deferred) {
						dataOps.create({
							type: enums.objectType.signupOrganization,
							name: model.name,
							registrationNumber: model.registrationNumber,
							vatNumber: model.vatNumber,
							country: model.country,
							employeeCountSpan: model.employeeCountSpan,
							primaryWebsite: model.primaryWebsite
						}).then(function (res) {
							deferred.resolve(res);
						});
					}).then(function (newlyCreatedOrganization) {
						if (newlyCreatedOrganization) {
							wfAuth.signIn({
								organizationId: newlyCreatedOrganization.id,
								skipNavigating: true
							}).then(function() {
								if (wfAuth.isAuthenticated() && wfAuth.isAuthenticatedOrganization()) {
									industriesForm.formControl.submit(function(model, deferred) {
										vm.formHiddenLoaderVisible = true;
										deferred.resolve();
										vm.submittingForm = false;
										$timeout();
										$rootScope.goToStateAfterAuthenticated("root", null, { reload: true });
									});
								}
							}, function() {
								console.error("Could not sign in the organization - ", newlyCreatedOrganization);
							});
						}
					});
				}
			}
			$timeout();
		}

		function getFormSpecifications(singleForm, prevQueue) {
			var deferred = $q.defer();

			getFormSpecification(singleForm, prevQueue);

			function getFormSpecification(form, previousQueue) {
				if (form.objectType && !form.schema) {
					formSchemaService.getFromType(form.objectType).then(function (res) {
						res.schema.required = form.required;
		
						form.customSpec = {
							schema: res.schema,
							form: form.form
						};
	
						form.loaded = true;
		
						//Fix this
						if (previousQueue && previousQueue.length > 0)
							getFormSpecification(previousQueue.shift(), previousQueue);
						else
							deferred.resolve(true);
					}, function() {
						deferred.reject(false);
					});
				}
				else if (typeof form.schema === "object") {
					form.customSpec = {
						schema: form.schema,
						form: form.form
					}
					form.loaded = true;

					//Fix this
					if (previousQueue && previousQueue.length > 0)
						getFormSpecification(previousQueue.shift(), previousQueue);
					else
						deferred.resolve(true);
					
				}
			}

			return deferred.promise;
		}
	}
})();
