import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import AnalyzeResultsTable from '@worldfavor/components/DataManager/AnalyzeResults/AnalyzeResultsTable'
import { get } from 'lodash'
import { MAX_PAGE_CONTENT_WIDTH } from '@worldfavor/constants'
import { objectType } from '@worldfavor/constants/enums'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'
import { injectIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { loadItem } from '@worldfavor/portal/actions/dataThunk'
import { getNodeFromNodeId } from '@worldfavor/portal/selectors/dataSelector'
import Loading from './Loading'
import Colors from '@worldfavor/constants/colors'
import AnalyzeResultsHeader from '@worldfavor/components/DataManager/AnalyzeResults/AnalyzeResultsHeader'

const useStyles = makeStyles({
  [`@media (min-width: ${MAX_PAGE_CONTENT_WIDTH}px)`]: {
    maxWidthGrid: props => ({
      maxWidth: props.disableMaxWidthGrid ? null : MAX_PAGE_CONTENT_WIDTH,
    }),
  },
  headerSection: {
    paddingTop: 70,
    paddingBottom: 70,
  },
  sceneTitle: {
    color: Colors.black,
  },
  analysisJobNameContainer: {
    marginBottom: 10,
  },
  analysisJobOrganization: {
    color: Colors.black,
  },
  analysisTitleDivider: {
    backgroundColor: Colors.blackDarker,
  },
  analysisJobName: {
    color: Colors.black,
  },
  analysisLabel: {
    border: `1px solid ${Colors.black}`,
    color: Colors.black,
  },
  analysisScore: {
    color: Colors.black,
  },
})

type Props = {
  // --- mandatory props ---
  match: Array<{
    params: {
      networkId: number,
      analyzeJobId: number,
    }
  }>,
}

const AnalyzeResultsScene = (props: Props) => {
  const { match, intl } = props
  const classes = useStyles(props)
  const networkId = get(match, 'params.networkId')
  const analyzeJobId = get(match, 'params.analyzeJobId')
  const [structureLoading, setStructureLoading] = useState(true)
  const dispatch = useDispatch()
  const analyzeJobStructure = useSelector(state => getNodeFromNodeId(state, objectType.analyzeJob + '-' + analyzeJobId))
  const [targetOrganization, setTargetOrganization] = useState(null)
  const title = intl.formatMessage({ id: 'dataManager.analyzeResultsTitle' }, { analyzeJobTitle: analyzeJobStructure ? analyzeJobStructure.title : '' })

  useEffect(() => {
    async function fetchStructure() {
      setStructureLoading(true)
      try {
        const analyzeJob = await dispatch(loadItem(analyzeJobId, objectType.analyzeJob))
        const targetOrg = await dispatch(loadItem(analyzeJob.targetOrganizationId, objectType.organization))
        setTargetOrganization(targetOrg)
      } catch (e) {
        console.log('Error fetching structure: ' + e)
      } finally {
        setStructureLoading(false)
      }
    }
    fetchStructure()
  }, [])

  if (networkId && title) {
    return (
      <Grid container justify={'center'}>
        <Grid classes={{ item: classes.maxWidthGrid }} item xs={12}>
          { structureLoading && <Loading /> }
          {
            !structureLoading && (
              <>
                <div className={classes.headerSection}>
                  <AnalyzeResultsHeader
                    selectedAnalyzeJob={analyzeJobStructure}
                    targetOrganization={targetOrganization.name}
                    classes={{
                      analysisJobNameContainer: classes.analysisJobNameContainer,
                      analysisJobName: classes.analysisJobName,
                      title: classes.sceneTitle,
                      analysisLabel: classes.analysisLabel,
                      analysisJobOrganization: classes.analysisJobOrganization,
                      analysisTitleDivider: classes.analysisTitleDivider,
                      analysisScore: classes.analysisScore,
                    }}
                  />
                </div>
                <AnalyzeResultsTable
                  networkId={networkId}
                  title={title}
                  analyzeJobIds={analyzeJobId}
                />
              </>
            )
          }
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(injectIntl(AnalyzeResultsScene))
