// @flow
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import MaxWidthGrid from '@worldfavor/components/MaxWidthGrid'
import Dialog from '@material-ui/core/Dialog'
import { useDialogState } from '@worldfavor/hooks'
import { ObjectType } from '@worldfavor/constants/enums'

import ViewSwitcher from '../components/SupplyChain/Viewer/ViewSwitcher'
import ProductDetail from '../components/SupplyChain/ProductDetail'
import Mapper from '../components/SupplyChain/Mapper'
import OrganizationFormDialog from '../components/SupplyChain/OrganizationForm/OrganizationFormDialog'
import OrganizationPickerDialog from '../components/SupplyChain/OrganizationPickerDialog'

import Api from '../api'
import { getNodeFromNodeId } from '../selectors/dataSelector'
import {
  getProductNode,
  getProductTreeEntities,
  getProductSupplier,
  getProductSubSuppliers,
  getAvailableActorTypes,
} from '../selectors/productSelector'
import { loadItem } from '../actions/dataThunk'
import {
  loadProductSupplierTree,
  loadProductSupplierList,
  saveMappingTransparencyLevel,
  saveProductSupplierTree,
  addCrowdSourcedOrganization,
  deleteFlatProductSupplier,
  setActorType,
} from '../actions/productThunk'
import Loading from './Loading'
import { StaticIds } from '@worldfavor/constants'
import get from 'lodash/get'

const useStyles = makeStyles({
  root: {
    marginTop: 62,
    marginBottom: 200,
  },
})

const useProductSelector = (props) => {
  const { productWfid, influenceWfid, networkId } = props.match.params
  const { nodes, edges } = useSelector(state => getProductTreeEntities(state, productWfid))
  const influence = useSelector(state => getNodeFromNodeId(state, influenceWfid))
  const influenceContent = useSelector(state => getNodeFromNodeId(state, influence && influence.wfcid))
  const product = useSelector(state => getNodeFromNodeId(state, productWfid))
  let networkWfid
  let availableActorTypesWfid

  if (influence) {
    networkWfid = `${ObjectType.network}-${influence.channelId}`
  }
  else if (networkId) {
    networkWfid = `${ObjectType.network}-${networkId}`
  }

  if (influenceContent) {
    availableActorTypesWfid = get(influenceContent, 'conditions.requirementPackageSettings.availableSupplierActorTypesStructure')
    if (availableActorTypesWfid) {
      availableActorTypesWfid = `71-${availableActorTypesWfid}`
    }
  }

  return {
    productWfid,
    influenceWfid,
    influence,
    influenceContent,
    availableActorTypesWfid,
    networkWfid,
    product,
    editable: product && product.canDoMapping,
    productNode: useSelector(state => getProductNode(state, productWfid)),
    productSupplier: useSelector(state => getProductSupplier(state, productWfid)),
    productCoMapper: product ? product.coMapperOrganization : undefined,
    subSuppliers: useSelector(state => getProductSubSuppliers(state, productWfid)),
    actorTypes: useSelector(state => getAvailableActorTypes(state, availableActorTypesWfid)),
    nodes,
    edges,
  }
}

const Product = (props) => {
  const classes = useStyles(props)
  const {
    productWfid,
    influenceWfid,
    networkWfid,
    product,
    availableActorTypesWfid,
    editable,
    productNode,
    productSupplier,
    productCoMapper,
    subSuppliers,
    nodes,
    edges,
  } = useProductSelector(props)
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [uniqueProductSuppliers, setUniqueProductSuppliers] = useState([])

  const [mapperOpen, openMapper, closeMapper] = useDialogState(false)
  const [organizationPickerOpen, openOrganizationPicker, closeOrganizationPicker] = useDialogState(false)
  const [organizationFormOpen, openOrganizationForm, closeOrganizationForm] = useDialogState(false)
  const [savingOrganization, setSavingOrganization] = useState(false)

  async function fetchUniqueProductSuppliers() {
    const result = await Api.get(Api.endpoints.uniqueProductSuppliers)
    const organizations = result.data.content.filter(x => x.type === ObjectType.organization)
    setUniqueProductSuppliers(organizations)
  }

  async function onMappingDone(hasChanged, addedNodes, addedEdges, dropNodeIds, dropEdgeIds, transparencyLevel) {
    if (product.transparencyLevel === transparencyLevel) {
      closeMapper()
      return
    }

    try {
      if (typeof transparencyLevel === 'number') {
        await dispatch(saveMappingTransparencyLevel(product, transparencyLevel))
      }
      closeMapper()
    } catch (e) {
      //todo handle error
    }
  }

  async function onMappingSave(addedNodes, addedEdges, dropNodeIds, dropEdgeIds) {
    try {
      await dispatch(saveProductSupplierTree(productWfid, networkWfid, addedNodes, addedEdges, dropNodeIds, dropEdgeIds))
    } catch (e) {
      //todo handle error
    }
  }

  async function onAddOrganization(organization) {
    try {
      setSavingOrganization(true)
      await dispatch(addCrowdSourcedOrganization(productWfid, networkWfid, influenceWfid, {
        useExisting: true,
        content: organization,
      }))

      // TODO add loader
      closeOrganizationPicker()
      return true
    } catch (e) {
      // TODO handle error
      console.error(`Couldn't add organization to the list of product supplier`, e)
      return false
    }
    finally
    {
      setSavingOrganization(false)
    }
  }

  function onCreateOrganizationClick() {
    closeOrganizationPicker()
    setTimeout(() => {
      openOrganizationForm()
    }, 100)
  }

  async function onCreateOrganization(formData) {
    try {
      await dispatch(addCrowdSourcedOrganization(productWfid, networkWfid, influenceWfid, formData))
      // TODO show loader
      closeOrganizationForm()
      return true
    } catch (e) {
      // TODO handle error
      console.error(`Couldn't create organization`, e)
      return false
    }
  }

  async function onDeleteSupplier(productWfid, organizationWfid) {
    try {
      await dispatch(deleteFlatProductSupplier(productWfid, organizationWfid, networkWfid))
    } catch (e) {
      // TODO handle error
      console.log('error removing supplier')
    } finally {
      // console.log('Supplier REMOVED from redux')
    }
  }

  async function onSetActorType(event, actorType, node) {
    try {
      await dispatch(setActorType(productWfid, node.id, actorType, influenceWfid))
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      try {
        if (influenceWfid) {
          const [influenceType, influenceId] = influenceWfid.split('-')
          await dispatch(loadItem(influenceId, influenceType))
        }
        if (productWfid && networkWfid) {
          if (availableActorTypesWfid) {
            const [actorTypesType, actorTypesId] = availableActorTypesWfid.split('-')
            await dispatch(loadItem(actorTypesId, actorTypesType))
          }

          await Promise.all([
            await dispatch(loadProductSupplierTree(productWfid, networkWfid)),
            await dispatch(loadProductSupplierList(productWfid, networkWfid)),
            await fetchUniqueProductSuppliers(),
          ])
        }
      } catch (e) {
        // TODO improve error handling
        console.error('Error fetching product data')
      } finally {
        if (productWfid && networkWfid) {
          setLoading(false)
        }
      }
    }

    fetchData()
  }, [productWfid, networkWfid, influenceWfid])

  const supplierIds = subSuppliers.map(({ wfid }) => wfid)

  if (loading || !product || !productNode) {
    return <Loading />
  }

  return (
    <Grid container justify={'center'} className={classes.root}>
      <MaxWidthGrid item xs={12} sm={12}>
        <ProductDetail
          product={product}
          supplier={productSupplier}
          coMapperOptions={{
            uniqueProductSuppliers,
            productCoMapper,
            influenceWfid,
            networkWfid,
          }}
          style={{ marginBottom: 48 }}
        />

        <ViewSwitcher
          rootNodeId={productNode.id}
          organizations={subSuppliers}
          nodes={nodes}
          edges={edges}
          onEditButtonClick={openMapper}
          editable={editable}
        />

        <Dialog
          maxWidth={false}
          open={mapperOpen}
          onClose={closeMapper}
          disableBackdropClick
          disableEscapeKeyDown
          PaperProps={{
            style: { height: '100%', width: '100%' },
          }}
        >
          <Mapper
            rootNodeId={productNode.id}
            mappingEntity={product}
            organizations={subSuppliers}
            uniqueOrganizations={uniqueProductSuppliers}
            networkWfid={networkWfid}
            influenceWfid={influenceWfid}
            availableActorTypesWfid={availableActorTypesWfid}
            nodes={nodes}
            edges={edges}
            onDone={onMappingDone}
            onAddOrganization={openOrganizationPicker}
            onDeleteOrganization={onDeleteSupplier}
            onSave={onMappingSave}
            onSetActorType={onSetActorType}
          />
        </Dialog>

        <OrganizationPickerDialog
          open={organizationPickerOpen}
          onCancel={closeOrganizationPicker}
          onAdd={onAddOrganization}
          onCreate={onCreateOrganizationClick}
          organizations={uniqueProductSuppliers.filter(({ wfid }) => !supplierIds.includes(wfid))}
          saving={savingOrganization}
        />

        <OrganizationFormDialog
          open={organizationFormOpen}
          onClose={closeOrganizationForm}
          onCancel={closeOrganizationForm}
          onFinish={onCreateOrganization}
          disableBackdropClick
          disableEscapeKeyDown
        />
      </MaxWidthGrid>
    </Grid>
  )
}

export default Product
