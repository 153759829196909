import * as enums from '@worldfavor/constants/enums'

/**
 * @ngdoc component
 * @name wfObjectViewerItem
 * 
 * @description 
 * This component is used to extract all the information from an item for displaying in a list inside a box.
 * The component has the similar purpose as the wf-item-component (should be changed to that one in future)
 * 
 * @param {WfObject=} item Used as the main item
 * @param {Object=} box Used for passing the box scope (boxVm)
 * @param {Object=} object-viewer-vm Used for passing the objectViewer scope
 * 
 * @param {} answerable This attribute is used when the item needs to have a questionAnswer or measureAnswer
 * 
 * @example
 * 
 * <wf-object-viewer-item item="vm.item" box="vm.boxVm" object-viewer-vm="objectViewerVm"></wf-object-viewer-item>
 */

(function () {
	'use strict';

	angular
		.module('wf.common')
		.component ('wfObjectViewerItem', wfObjectViewerItem());

	function wfObjectViewerItem() {
		var component = {
			bindings: {
				item: '=',
				box: '=',
				objectViewerVm: '='
			},
			controller: wfObjectViewerItemController,
			controllerAs: 'ovItemVm',
			templateUrl: "scripts/wf/objectViewer/objectViewerItem.component.html"
		};
		
		return component;
	}

	wfObjectViewerItemController.$inject = [ "$scope", "$attrs", 'modalService', '$translate', 'dataOperationsService', '$timeout', '$sanitize', 'moment', '$q', '$element', '$state' ];
	function wfObjectViewerItemController($scope, $attrs, modal, $translate, dataOps, $timeout, $sanitize, moment, $q, $element, $state) {
		var
			$ctrl = this,
			box = $ctrl.box,
			itemDataRelation = $ctrl.item,
			kind = box ? box.kind : enums.subItemsKind.children,
			itemContent = itemDataRelation.type === enums.objectType.influence ? itemDataRelation : (itemDataRelation.getSubContentOfKind(kind) || itemDataRelation),
			answerable = itemContent && itemContent.isOfAnswerableType && itemContent.isOfAnswerableType() && ((box && !box.sideBar) || "answerable" in $attrs),
			expandable = box ? !box.sideBar && box.allowExpanding !== false : false,
			itemOptions,
			objectViewerItem = undefined
		;

		_.assign($ctrl, {
			expandable: false,
			isUserInbox: false,
			itemOptions: itemOptions = {},
			itemChildren: undefined,
			itemContent: itemContent,
			isLinkable: itemContent && itemContent.isLinkableType && itemContent.isLinkableType(),
			showToolsDropdown: false,
			blockToolsDropdown: false,
			blockItemDropdown: false,
			item: itemDataRelation,
			itemDataRelation: itemDataRelation,
			answerable: answerable,
			itemCreatorUser: itemDataRelation.creatorUser,
			itemHasClickAction: false,
			toolsDropdownActions: undefined,
			$element: $element,
			itemComponentControl: {},

			//Objects
			fromNowByRelationIds: {},

			//Functions
			openModal_addTo: openModal_addTo,
			openModal_sendTo: openModal_sendTo,
			getSubItemsOfItem: getSubItemsOfItem,
			objTypeSettings: getObjectTypeSettings(),
			getUserInboxTooltipSentence: getUserInboxTooltipSentence,
			openModal_attachInformation: openModal_attachInformation,
			onItemClick: onItemClick,
			openObjectViewer: openObjectViewer

		});

		$scope.enums = enums;

		activate();

		function activate() {
			if (!box) {
				box = {};
			}

			if ($ctrl.objectViewerVm && $ctrl.objectViewerVm.item != undefined) {
				if ($ctrl.objectViewerVm.item.type == enums.objectType.individual)
					$ctrl.isUserInbox = true;
			}

			if ($ctrl.isUserInbox) {
				$ctrl.fromNowByRelationIds[itemDataRelation.wfid] = moment(itemDataRelation.createdAt).fromNow();
			}
			
			var objTypeSettings;
			$ctrl.objTypeSettingsByType = _.keyBy($ctrl.objTypeSettings, "type") || {}; // used for disabling user access on item-tools and wf-dropdown
			objTypeSettings = $ctrl.objTypeSettingsByType[itemContent.type] || {};

			$ctrl.toolsDropdownActions = objTypeSettings.toolsDropdownActions;

			if (box.blockItemDropdown || objTypeSettings.blockItemDropdown || (box.currentItemTypeSettings && box.currentItemTypeSettings.blockItemDropdown === true)) {
				$ctrl.blockToolsDropdown = true;
				$ctrl.blockItemDropdown = true;
			}
			if (!objTypeSettings.blockItemDropdown) {
				$ctrl.blockToolsDropdown = true;
				// $ctrl.blockItemDropdown = true;
			}
			if (objTypeSettings.blockItemTools || (box.currentItemTypeSettings && box.currentItemTypeSettings.blockItemTools === true)) {
				$ctrl.showToolsDropdown = true;
			}
				
			if (expandable) {
				if (objTypeSettings.disableExpand || (box.currentItemTypeSettings && box.currentItemTypeSettings.allowExpanding === false)) {
					$ctrl.expandable = false;
				}
				else if (objTypeSettings.kindToExpand && itemContent.metadata && (itemContent.metadata.countByRelationKind[objTypeSettings.kindToExpand]) != 0) {
					$ctrl.expandable = true;
				}

				if ($ctrl.expandable && itemDataRelation && box.itemsOptions) {
					$ctrl.itemOptions = itemOptions = box.itemsOptions[itemDataRelation.wfid];

					itemOptions.expandItem = function () {
						itemOptions.expanded = true;
						getSubItemsOfItem();
					};
					itemOptions.collapseItem = function () {
						itemOptions.expanded = false;
					};
				}
			}

			$ctrl.itemDisplayOptions = {
				styles: {
					noPadding: true,
					removeMinWidth: true, 
					noHover: true, 
					imageDefaults: 'objectTypeIconPack1',
					appendPopoverToBody: true
				}, 
				size: !$ctrl.box.sideBar && !$ctrl.objectViewerVm.isViewMode && !$ctrl.objectViewerVm.isWorkMode ? 'medium' : 'small'
			};

			$ctrl.itemIncludeOptions = {
				embedPlayer: false,
				sideActions: $ctrl.answerable,
				metadata: (!$ctrl.box.sideBar || box.showMetadata) && box.showMetadata !== false, // && !$ctrl.objectViewerVm.isViewMode,
				itemDropdown: $ctrl.itemContent && ($ctrl.blockToolsDropdown && !$ctrl.blockItemDropdown) && !$ctrl.objectViewerVm.isViewMode
			};

			$ctrl.metadataOptions = {
				itemSource: box.metadataItemSource || "itemContent",
				showDetailedViewLink: !box.hideMetadataDetailedViewLink,
				showEmbeddedObjectViewer: false,
				appendPopoverToBody: true
			}
			if (box.metadataOptions) {
				_.assign($ctrl.metadataOptions, box.metadataOptions);
			}

			$scope.$on("dropdownActionExecuted", function ($event, operation, item, dataRelation) {
				var updateMetadataNumbers = _.get($ctrl, "itemComponentControl.$scope.itemVm.metadataMethods.updateNumbers");

				$ctrl.objectViewerVm.broadcastMeasureChange();
				
				if (typeof updateMetadataNumbers === "function") {
					updateMetadataNumbers();
				}
			});

			$ctrl.itemHasClickAction = typeof box.itemClickAction === "function";

			$ctrl.dropdownActions = getDropdownActions();

		}

		function populateExpandedItems(expandedItem) {
			var listOfItems = _.filter(expandedItem.getSubListOfKind(kind), function(item) {
				if (item.type !== enums.objectType.dataRelation || item.relationType === 1) // item.relationType === 1 is Infrastructural
					return;

				if (kind == 2)
					return item.getSubContentOfKind(enums.subItemsKind.parentsByUser) || item.getSubContentOfKind(kind);
				else
					return item.getSubContentOfKind(kind);

				// if(expandedItem.type == enums.objectType.individual){
				// 	vm.fromNowByRelationIds[item.wfid] = moment(item.createdAt).fromNow();
				// }

			});

			return listOfItems;
		}

		function getSubItemsOfItem() {
			var promises = [];
			if (itemOptions.expanded) {
				if (!itemOptions.isChildrenLoading && !itemOptions.isChildrenLoaded) {
					itemOptions.isChildrenLoading = true;
					itemOptions.isChildrenLoaded = false;

					dataOps.getSubItems(itemContent, enums.subItemsKind.children).then(function(items) {
						var timeout;
						
						items = populateExpandedItems(itemContent);
						
						var answerableItems = _.filter(items, function (item) {
							return item.childType == 11 || item.childType == 21;
						});

						if (answerableItems.length) {
							_.forEach(answerableItems, function(item) {
								promises.push(dataOps.getSubItems(item.childContent, enums.subItemsKind.children));
							});

							$q.all(promises).then(function () {
								$ctrl.itemChildren = itemContent.childs;
								itemOptions.isChildrenLoading = false;
								itemOptions.isChildrenLoaded = true;
								$timeout();
							});
						}
						else {
							$timeout(function() {
								$ctrl.itemChildren = itemContent.childs;
								itemOptions.isChildrenLoading = false;
								itemOptions.isChildrenLoaded = true;
							});
						}
					});
				}

			}
		}

		function openModal_sendTo(item) {
			modal.openCreatorAndPicker({
				showTopItemAboveTitle: true,
				hideListHeader: false,
				hideFilters: false,
				translations: {
					addWord: $translate.instant('Send'),
					toWord: $translate.instant('To'),
					filterButton_all: $translate.instant('AllUsers'),
					filterButton_selected: $translate.instant('Sent')
				},
				compilerControl: null, //vm.context.itemCompilers[item.wfid],
				title: $translate.instant('Send'),
				create: false,
				objectTypes: [ enums.objectType.individual ],
				relationTarget: { item: item, kind: enums.subItemsKind.parentsByUser }
			}).closed(function() {
				if ($scope.mdVm)
					$scope.mdVm.updateNumbers();
			});
		}

		function openModal_addTo(item) {
			modal.openCreatorAndPicker({
				hideListHeader: true,
				compilerControl: null, //vm.context.itemCompilers[item.wfid],
				title: $translate.instant('AddTo'),
				create: false,
				sourceItem: '73-347315',
				relationTarget: { item: item, kind: enums.subItemsKind.parentsByUser }
			}).closed(function() {
				if ($scope.mdVm)
					$scope.mdVm.updateNumbers();
			});
		}

		function openModal_attachInformation(item, type) {
			var target = {
				objectTypes: [ type ],
				relationTarget: {
					item: item,
					kind: enums.subItemsKind.relatedContentByUser
				}
			};
			modal.openCreatorAndPicker(target).closed(function () {
				if ($scope.mdVm)
					$scope.mdVm.updateNumbers();
			});
		}

		function onItemClick($event) {
			if (typeof box.itemClickAction === "function") {
				box.itemClickAction($event, $scope, $ctrl, $ctrl.item);
			}
		}

		function getUserInboxTooltipSentence(creatorUser) {
			if (creatorUser)
				return $sanitize(creatorUser.name) + " " + $translate.instant("InboxWhoSent");
			else
				return "";
		}

		function getDropdownActions() {
			var
				showDivider = true,
				include_sendTo = true,
				include_addTo = true,
				include_attachInformation = true,
				objectTypeSettings = $ctrl.objTypeSettingsByType[itemContent.type] || {}
			;
			var dropdownActions;

			if (itemContent == undefined)
				return;

			if (box.itemDropdownActions)
				return box.itemDropdownActions;

			if ((itemContent.type === enums.objectType.organization) || (kind === enums.subItemsKind.parentsByUser)) {
				include_sendTo = include_addTo = include_attachInformation = false;
				showDivider = false;
			}

			if (itemContent.type === enums.objectType.questionAnswer || itemContent.type === enums.objectType.measureAnswer) {
				if (itemDataRelation.parentType === enums.objectType.relativeMeasure) {
					include_sendTo = include_addTo = include_attachInformation = showDivider = false;
					dropdownActions = [];
					dropdownActions.push('objectViewer');
				}
				else {
					include_sendTo = include_addTo = false;
					
					dropdownActions = [];
					
					if (itemContent.type === enums.objectType.measureAnswer)
						dropdownActions.push('edit');
					
					dropdownActions.push('deleteContent');
					dropdownActions.push('objectViewer');
				}
			}
			
			if (itemContent.isUserDataType && itemContent.isUserDataType() && !objectTypeSettings.blockItemTools) {
				dropdownActions = 'userData';
			}
			else {

				dropdownActions = dropdownActions || [ 'objectViewer' ];

				if (showDivider)
					dropdownActions.push('-');

				if (include_sendTo)
					dropdownActions.push('sendTo');

				if (include_addTo)
					dropdownActions.push('addTo');

				if (include_attachInformation)
					dropdownActions.push('attachInformation');
			}

			// if(objectTypeSettings.extraActions){
			// 	Array.prototype.push.apply(dropdownActions, objectTypeSettings.extraActions);
			// }
			return dropdownActions;
		}

		function getObjectTypeSettings() {
			return [ {
				type: enums.objectType.individual,
				disableExpand: false,
				blockItemTools: true,
				blockItemDropdown: true
			}, {
				type: enums.objectType.question,
				kindToExpand: enums.subItemsKind.childrenByUser,
				disableExpand: false,
				blockItemTools: true
			}, {
				type: enums.objectType.measure,
				kindToExpand: enums.subItemsKind.childrenByUser,
				disableExpand: false,
				blockItemTools: true
			}, {
				type: enums.objectType.structure,
				kindToExpand: enums.subItemsKind.children,
				disableExpand: false,
				blockItemTools: true
			}, {
				type: enums.objectType.questionAnswer,
				disableUserAccess: true,
				disableExpand: true,
				blockItemTools: true,
				// toolsDropdownActions: "delete,belonging"
			}, {
				type: enums.objectType.measureAnswer,
				disableUserAccess: true,
				disableExpand: true,
				blockItemTools: true,
				// toolsDropdownActions: "delete,belonging"
			}, {
				type: enums.objectType.link,
				disableExpand: true,
				blockItemTools: false
			}, {
				type: enums.objectType.orgDocument,
				disableExpand: true,
				blockItemTools: false
			}, {
				type: enums.objectType.statement,
				disableExpand: true,
				blockItemTools: false
			}, {
				type: enums.objectType.orgActivity,
				disableExpand: true,
				blockItemTools: false
			}, {
				type: enums.objectType.location,
				disableExpand: true,
				blockItemTools: false
			}, {
				type: enums.objectType.embed,
				disableExpand: true,
				blockItemTools: false
			}, {
				type: enums.objectType.influence,
				disableExpand: true,
				blockItemTools: true,
				blockItemDropdown: true
			}, {
				type: enums.objectType.network,
				disableExpand: true,
				blockItemTools: true,
				blockItemDropdown: true
			}, {
				type: enums.objectType.organization,
				disableExpand: true,
				blockItemTools: true
			}
			]
		}

		function openObjectViewer(item) {
			var url = $state.href("objectViewer_encoded", { encodedData: item.getEncodedWfid() });
			window.open(url, '_blank');
		}
	}

} ());
