import * as enums from '@worldfavor/constants/enums'

(function() {
	'use strict';

	angular
		.module('wf.common')
		.component('wfRequirementFulfillment', {
			templateUrl: 'scripts/wf/requirements/wfRequirementFulfillment.component.html',
			controller: wfRequirementFulfillmentController,
			controllerAs: 'vm',
			bindings: {
				itemComposite: '='
			}
		});

	wfRequirementFulfillmentController.$inject = [ '$scope', 'wfAuth', 'dataQuery', '$q', '$element', 'dataOperationsService', 'requirements', '$timeout' ];
	function wfRequirementFulfillmentController($scope, wfAuth, dataQuery, $q, $element, dataOps, requirementService, $timeout) {
		var vm = this;

		_.assign(vm, {
			compiler: {}
		});

		vm.$onInit = function() {
			syncFulfillmentState();
			vm.itemComposite.syncFulfillmentResultComponent = syncFulfillmentState;
		};

		function syncFulfillmentState() {
			if ($scope.$$destroyed)
				return;

			vm.hasRequirement = !!vm.itemComposite.requirement;

			if (!vm.hasRequirement) {
				vm.state = undefined;
				vm.fulfillmentState = undefined;
			}
			else {
				let fulfillmentResult = vm.itemComposite.fulfillmentResult;
				vm.requirementText = requirementService.getRequirementText(vm.itemComposite.content, vm.itemComposite.dataRelation, vm.itemComposite.requirement);
				vm.fulfillmentState = _.get(fulfillmentResult, "fulfillmentState");
				vm.isAnswered = _.get(fulfillmentResult, "isAnswered");
				vm.requirement = vm.itemComposite.requirement;
				
				if (vm.fulfillmentState) {
					vm.state = {
						fulfilled: vm.fulfillmentState === enums.fulfillmentState.fulfilled,
						unfulfilled: vm.fulfillmentState === enums.fulfillmentState.unfulfilled,
						assessmentNeeded: vm.fulfillmentState === enums.fulfillmentState.assessmentNeeded && vm.isAnswered,
						reportingNeeded: vm.fulfillmentState === enums.fulfillmentState.reportingNeeded,
						expired: vm.fulfillmentState === enums.fulfillmentState.expired,
						certificateExpired: vm.fulfillmentState === enums.fulfillmentState.certificateExpired,
						partiallyFulfilled: vm.fulfillmentState === "partiallyFulfilled",
					}
				}
				else {
					vm.state = {
						unfulfilled: true
					};
					vm.stateText = undefined;
					vm.stateIcon = undefined;
				}

				if (vm.compiler.compile) {
					vm.compiler.compile();
				}

				// if (vm.requirement.rule === enums.requirementRule.manual && !vm.isAnswered)
				// 	vm.fulfillmentState = enums.fulfillmentState.unfulfilled;
				
				vm.stateText = requirementService.getFulfillmentStateText(vm.fulfillmentState);

				vm.stateIconClass = {
					'requirementIsException': vm.isException,
					'fa fa-check': vm.state.fulfilled,
					'fa fa-times': vm.state.unfulfilled,
					'fa fa-hourglass-half': vm.state.assessmentNeeded,
					'fa fa-repeat': vm.state.reportingNeeded,
					'fa fa-exclamation-triangle': vm.state.expired,
					'fa fa-exclamation-triangle': vm.state.certificateExpired,
					'fa fa-minus-circle': vm.state.partiallyFulfilled,
				}

				$timeout();
			}

		}
	}
})();
