import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

const ScrollRestoration = (props) => {
  const { children, location } = props

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return children || null
}

export default withRouter(ScrollRestoration)
