import React from 'react'
import classNames from 'classnames'

const GradientOverlay = ({ className, startColor, endColor }) => {
  return (
    <div
      className={classNames(className)}
      style={{
        backgroundColor: endColor,
        background: `linear-gradient(${startColor}, ${endColor})`,
        width: '100%',
        height: '100%',
      }}
    />
  )
}

GradientOverlay.defaultProps = {
  startColor: 'rgba(25, 41, 60, 0.85)',
  endColor: 'rgba(66, 95, 122, 0.5)',
}

export default GradientOverlay
