import createCachedSelector from 're-reselect'
import { createSelectorWithDependencies as createSelector } from 'reselect-tools'
import { _wfObjectById, _allWfObjects, getNodeFromNodeId, getEdgeAndOutNodeFromEdge } from './dataSelector'
import { _arg1, _arg2, identity, getFromWfidAttribute, getToWfidAttribute } from '@worldfavor/utils/selectors'
import { getNodes, _graphEntities, getEdges } from './graphSelector'
import { StaticIds } from '@worldfavor/constants'

export const getProduct = createCachedSelector(
  [getNodeFromNodeId],
  identity,
)((state, productWfid) => productWfid)

export const getProductNode = createCachedSelector(
  [getNodes, _arg1],
  (nodes, productWfid) => nodes.find(node => node.label === 'product' && node.properties.productWfid === productWfid),
)((state, productWfid) => productWfid)

export const getFlatProductSupplierNode = createCachedSelector(
  [getNodes, _arg1, _arg2],
  (nodes, productWfid, organizationWfid) => nodes.find(node => node.label === 'flatMappedProductSupplier' && node.properties.productWfid === productWfid && node.properties.organizationWfid === organizationWfid),
)((state, productWfid, organizationWfid) => `${productWfid}:${organizationWfid}`)

export const getFlatProductSupplierEdge = createCachedSelector(
  [getEdges, _arg1, _arg2, getFlatProductSupplierNode],
  (edges, productWfid, organizationWfid, node) => node ? edges.find(edge => edge.label === 'suppliedFlatFrom' && edge.toId === node.id && edge.properties.productWfid === productWfid) : undefined,
)((state, productWfid, organizationWfid) => `${productWfid}:${organizationWfid}`)

// TODO This selector breaks when there is a cycle in the tree graph
// TODO simplify by using [node/edge].properties.productWfid and remove recursiveness
export const getProductTreeEntities = createCachedSelector(
  [getProductNode, _graphEntities, getEdges],
  (productNode, items, edges) => {
    if (!productNode) {
      return []
    }

    const getSubEntities = (fromId) => {
      return edges.filter(edge => getFromWfidAttribute(edge) === fromId && edge.label === 'suppliedFrom')
        .reduce((acc, edge) => {
          const children = getSubEntities(getToWfidAttribute(edge))
          return {
            nodes: [
              ...(acc.nodes || []),
              items[getToWfidAttribute(edge)],
              ...children.nodes,
            ],
            edges: [
              ...(acc.edges || []),
              edge,
              ...children.edges,
            ],
          }
        }, { nodes: [], edges: [] })
    }

    const entities = getSubEntities(productNode.wfid)
    return {
      nodes: [...entities.nodes, productNode].reduce((acc, item) => ({
        ...acc,
        [item.wfid]: item,
      }), {}),
      edges: entities.edges,
    }
  },
)((state, productWfid) => productWfid)

export const getProductSupplier = createCachedSelector(
  [_allWfObjects, getProduct],
  (items, product) => {
    if (!product) {
      return null
    }
    return items[product.creatorOrganizationWfid]
  },
)((state, productWfid) => productWfid)

export const getProductSubSuppliers = createCachedSelector(
  [_arg1, getNodes, _allWfObjects],
  (productWfid, nodes, items) => {
    if (!productWfid) {
      return []
    }

    return nodes.filter(({ properties, label }) => properties.productWfid === productWfid
      && label === 'flatMappedProductSupplier')
      .map(node => items[node.properties.organizationWfid])
  },
)((state, productWfid) => productWfid)

export const getAvailableActorTypes = createCachedSelector(
  [_wfObjectById, _allWfObjects],
  (fromNode, items) => {
    if (!fromNode) {
      return []
    }

    return Object.values(items)
      .filter(item => getFromWfidAttribute(item) === fromNode.wfid)
      .sort((a, b) => a.order - b.order)
      .map(item => getEdgeAndOutNodeFromEdge(items, item))
      .map(({ node }) => node)
  },
)((state, availableActorTypesWfid) => availableActorTypesWfid)
