// @flow
import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import InsertPhoto from '@material-ui/icons/InsertPhoto'
import BrokenImage from '@material-ui/icons/BrokenImage'

const styles = {
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
  placeholder: {
  },
}

const Image = ({
  classes,
  src,
  noPlaceholder,
  placeholder,
  placeholderComponent,
  size,
  position,
  repeat,
  style,
  onLoad,
  onError,
  children,
  overlay,
  alt,
  ...rest
}) => {
  const [loaded, setLoaded] = useState(false)
  const [errorPlaceholder, setErrorPlaceholder] = useState(false)

  function _onLoad() {
    setLoaded(true)
    onLoad && onLoad()
  }

  function _onError() {
    setErrorPlaceholder(true)
    onError && onError()
  }

  if (!src || errorPlaceholder) {
    if (children || placeholder || placeholderComponent || noPlaceholder) {
      return (
        <div
          style={(children || placeholderComponent || noPlaceholder) ? {} : {
            backgroundImage: `url('${placeholder}')`,
            backgroundSize: size,
            backgroundPosition: position,
            backgroundRepeat: repeat,
            ...style,
          }}
          {...rest}
        >
          { children || (noPlaceholder ? null : placeholderComponent) }
        </div>
      )
    }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...style,
        }}
        {...rest}
      >
        {
          errorPlaceholder ?
            <BrokenImage className={classes.placeholder} />
            : <InsertPhoto className={classes.placeholder} />
        }
      </div>
    )
  }

  return (
    <React.Fragment>
      <div
        style={{
          backgroundImage: `url('${src}')`,
          backgroundSize: size,
          backgroundPosition: position,
          backgroundRepeat: repeat,
          position: 'relative',
          zIndex: overlay ? 0 : 'auto',
          ...style,
        }}
        {...rest}
      >
        {overlay && <div className={classes.overlay}>{overlay}</div>}
        {children}
      </div>

      {
        !loaded && (
          <img
            style={{ display: 'none' }}
            src={src}
            onLoad={_onLoad}
            onError={_onError}
            alt={alt}
          />
        )
      }
    </React.Fragment>
  )
}

Image.defaultProps = {
  size: 'contain',
  position: 'center',
  repeat: 'no-repeat',
}

export default withStyles(styles)(Image)
