import * as enums from '@worldfavor/constants/enums'

(function() {
'use strict';

	angular
		.module('wf.common')
		.controller('WidgetController', WidgetController);

	WidgetController.$inject = ['$scope', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', 'formSchemaService', '$q', 'wfTranslate', '$timeout','$stateParams','$rootScope','apiProxy','wfAuth','$state'];
	function WidgetController($scope, dataOps, modal, wfObject, dataQuery, $translate, formSchemaService, $q, wfTranslate, $timeout, $stateParams, $rootScope, apiProxy, wfAuth, $state) {
		var vm = this;

		vm.editWidget = editWidget;
		vm.widgetCompiler = {};
		vm.contentCompiler = {};
		vm.pickWidgetSource = pickWidgetSource;

		activate();

		////////////////

		function activate() {
			dataOps.getObject({
				objectType: enums.objectType.widget,
				objectId: $stateParams.objectId
			}).then(function (widget) {
				$rootScope.setPageTitle(widget.name);
				vm.widget = widget;
				loadWidgetContent();

				$timeout();
			});
		}

		function editWidget() {
			modal.edit(vm.widget).then(function () {
				// if (vm.room.urlRouteName === currentRouteName)
					vm.widgetCompiler.compile();
				// else
				 	// document.location.replace(currentLocation.replace("/room/" + currentRouteName, "/room/" + vm.room.urlRouteName));

			});
		}

		function pickWidgetSource() {
			var preSelected = [];

			if (vm.widgetSourceObject)
				preSelected = [ vm.widgetSourceObject ];

			return dataOps.getObject({
				objectType: enums.objectType.dataRelation,
				objectId: 347433,
				ticket: {
					networkId: 1
				}
			}).then(function (pickerSourceItem) {
				var sourceList = [];

				_.forEach(pickerSourceItem.childContent.childs, function (dataRelation) {
					sourceList.push({
						data: dataRelation,
						wfid: dataRelation.wfid
					});
				});

				modal.openCreatorAndPicker({
					relationBucket: { preSelected: preSelected, allSelected: preSelected, singlePick: true },
					sourceList: sourceList
				}).closed(function (relationBucketResult) {
					var item = relationBucketResult.allSelected[0];
					if (item && item.childContent) {
						vm.widget.objectType = item.type;
						vm.widget.objectId = item.id;
					}
					else {
						vm.widget.objectType = null;
						vm.widget.objectId = null;
					}

					dataOps.update(vm.widget).then(function () {
						loadWidgetContent();
					})
				});
			});
		}

		function loadWidgetContent() {
			vm.widgetSourceLoaded = false;
			vm.widgetSourceObject = null;
			$timeout();

				if (vm.widget.objectId && vm.widget.objectType) {
					dataOps.getObject({
						objectType: vm.widget.objectType,
						objectId: vm.widget.objectId,
						// ticket: {
						// 	networkId: 1
						// }
					}).then(function (widgetSourceObject) {
						$timeout(function () {
							vm.widgetSourceLoaded = true;
							vm.widgetSourceObject = widgetSourceObject;
							// console.log(vm.widgetSourceObject.childContent);
							// console.log(vm.contentCompiler)
							// vm.contentCompiler.compile();
						}, 500);
					});
				}
				else {
					vm.widgetSourceLoaded = true;
					$timeout();
				}
		}
	}
})();
