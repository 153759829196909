import React, { useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import Colors from '@worldfavor/constants/colors'
import { copyTextToClipboard } from '@worldfavor/utils/helpers'

const useStyles = makeStyles(theme => ({
  root: {},
  copyButton: {
    color: Colors.grayOffWhite,
    padding: 5,
    marginLeft: 5,
    '&:hover': {
      backgroundColor: Colors.blackLight,
    },
  },
  copyButtonIcon: {
    fontSize: theme.typography.fontSizes.medium,
  },
  tooltipDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltip: {
    margin: 0,
    fontSize: theme.typography.fontSizes.default,
    backgroundColor: Colors.blackDark,
    maxWidth: 250,
    overflowWrap: 'break-word',
  },
  formattedText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

const TooltipWithCopyToClipboard = (props) => {
  const [copied, setCopied] = useState(false)
  const { text } = props
  const classes = useStyles(props)

  function clearCopy() {
    setCopied(false)
  }

  function copyTooltipText(event) {
    event.stopPropagation()
    copyTextToClipboard(text)
    setCopied(true)
  }

  return (
    <Tooltip
      interactive
      classes={{ tooltip: classes.tooltip }}
      onOpen={clearCopy}
      enterDelay={500}
      leaveDelay={200}
      placement="top"
      title={
        (
          <div className={classes.tooltipDiv}>
            <div>
              {text}
            </div>
            <IconButton size="small" className={classes.copyButton} onClick={copyTooltipText}>
              <i className={classNames(classes.copyButtonIcon, copied ? 'fas fa-check' : 'far fa-copy')} />
            </IconButton>
          </div>
        )
      }
    >
      <div className={classes.formattedText}>
        {text}
      </div>
    </Tooltip>
  )
}

export default TooltipWithCopyToClipboard
