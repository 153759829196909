import enLocale from './locales/en.json'
import enUSLocale from './locales/en-US.json'
import enGBLocale from './locales/en-GB.json'
import svLocale from './locales/sv.json'

import { DEFAULT_LANGUAGE } from '@worldfavor/constants'

const availableLocale = {
  en: [enLocale],
  'en-US': [enLocale, enUSLocale],
  'en-GB': [enLocale, enGBLocale],
  sv: [svLocale],
  'sv-SE': [svLocale],
}

const availableLocaleKeys = Object.keys(availableLocale)

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill')
  require('@formatjs/intl-pluralrules/dist/locale-data/en')
  require('@formatjs/intl-pluralrules/dist/locale-data/sv')
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill')
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en')
  require('@formatjs/intl-relativetimeformat/dist/locale-data/sv')
}

export const availableLanguages = {
  en: {
    key: 'en-US',
    translationKey: 'lang.en',
  },
  sv: {
    key: 'sv-SE',
    translationKey: 'lang.sv',
  },
  zh: {
    key: 'zh-Hans',
    translationKey: 'lang.zh',
  },
  cs: {
    key: 'cs-CZ',
    translationKey: 'lang.cs',
  },
  es: {
    key: 'es-ES',
    translationKey: 'lang.es',
  },
  pl: {
    key: 'pl-PL',
    translationKey: 'lang.pl',
  },
  de: {
    key: 'de-DE',
    translationKey: 'lang.de',
  },
  nb: {
    key: 'nb-NO',
    translationKey: 'lang.nb',
  },
  da: {
    key: 'da-DK',
    translationKey: 'lang.da',
  },
  fi: {
    key: 'fi-FI',
    translationKey: 'lang.fi',
  },
  fr: {
    key: 'fr-FR',
    translationKey: 'lang.fr',
  },
  pt: {
    key: 'pt-PT',
    translationKey: 'lang.pt',
  },
  af: {
    key: 'af-ZA',
    translationKey: 'lang.af',
  },
  it: {
    key: 'it-IT',
    translationKey: 'lang.it',
  },
  hu: {
    key: 'hu-HU',
    translationKey: 'lang.hu',
  },
  vi: {
    key: 'vi-VN',
    translationKey: 'lang.vi',
  },
}

/**
 * Get the compatible locale based on a language.
 * If no locale is compatible, use the default language (en)
 * @param language The language to find compatible locale
 * @returns {*} A compatible locale based on the language or the default locale
 */
export const getLocale = (language) => {
  const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0]
  if (availableLocaleKeys.includes(language)
    || availableLocaleKeys.includes(languageWithoutRegionCode)) {
    return language
  }
  return DEFAULT_LANGUAGE
}

/**
 * Get the user locale based on the browser data
 * @returns {*} Return a compatible locale for translation
 */
export const getUserLocale = () => {
  const language = (typeof navigator === 'undefined' && DEFAULT_LANGUAGE)
    || (navigator.languages && navigator.languages[0])
    || navigator.language
    || navigator.userLanguage
  return getLocale(language)
}

/**
 * Get translation items for a specific language
 * Allow use of nested object by flattening when
 * returning the translations
 * @param language
 * @returns {*} The translation object with flatten values
 */
export const getMessages = (language) => {
  const translations = availableLocale[getLocale(language)]
  return prepareTranslations(translations)
}

/**
 * Flatten and merge a list of translations.
 * This function is used to merge general translations
 * with region based translations
 * @param translationObjects
 * @returns {any} The merged and flatten translation object
 */
const prepareTranslations = (translationObjects: Array<any>) => translationObjects
  .map(translationObject => flattenTranslations(translationObject))
  .reduce((acc, translations) => ({
    ...acc,
    ...translations,
  }))

const flattenTranslations = translations => Object.keys(translations)
  .reduce((acc, key) => {
    if (typeof translations[key] === 'object') {
      if (Object.keys(translations[key]).length === 0) {
        return acc
      }

      const flattenObject = flattenTranslations(translations[key])
      return {
        ...acc,
        ...Object.keys(flattenObject)
          .reduce((acc2, key2) => ({
            ...acc2,
            [`${key}.${key2}`]: flattenObject[key2], // append key to beginning of flatten key
          }), {}),
      }
    }
    return {
      ...acc,
      [key]: translations[key],
    }
  }, {})
