import React from 'react'
import { FormattedMessage } from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import Colors, { hexToRgbA } from '@worldfavor/constants/colors'
import CircularProgressWrapper from '@worldfavor/components/CircularProgressWrapper'

const styles = theme => ({
  root: {
    overflow: 'hidden',
  },
  titleContainer: {
    padding: 22,
    backgroundColor: Colors.red,
  },
  title: {
    color: theme.palette.common.white,
    fontSize: 20,
    fontWeight: theme.typography.fontWeightMedium,
  },
  subtitle: {
    marginTop: 12,
    color: theme.palette.common.white,
    fontSize: 16,
    maxWidth: 500,
  },
  dialogContent: {
    paddingTop: 24,
  },
  removeButton: {
    color: Colors.red,
    '&:hover': {
      backgroundColor: hexToRgbA(Colors.red, 0.08),
    },
  },
  loader: {
    color: Colors.red,
  },
})

const DeleteDialog = ({ classes, open, title, subtitle, onCancel, onDelete, deleting, children }) => {
  return (
    <Dialog open={open} className={classes.root} fullWidth>
      <div className={classes.titleContainer}>
        <div className={classes.title}>{title}</div>
        {
          subtitle && (
            <div className={classes.subtitle}>
              {subtitle}
            </div>
          )
        }
      </div>

      <DialogContent className={classes.dialogContent} data-cy="dialog-content">
        { children }
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onCancel}
          disabled={deleting}
          data-cy="cancel-button"
        >
          <FormattedMessage id={'general.cancel'} />
        </Button>

        <div>
          <CircularProgressWrapper
            loading={deleting}
            classes={{ circle: classes.circle }}
          >
            <Button
              disabled={deleting}
              onClick={onDelete}
              className={classes.removeButton}
              data-cy="delete-button"
            >
              <FormattedMessage id={'general.delete'} />
            </Button>
          </CircularProgressWrapper>
        </div>
      </DialogActions>
    </Dialog>
  )
}

DeleteDialog.defaultProps = {
  onDelete: () => {},
}

export default React.memo(withStyles(styles)(DeleteDialog))
