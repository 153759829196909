//@flow
import React from 'react'
import { useSelector } from 'react-redux'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import { get } from 'lodash'
import classNames from 'classnames'

import { getNodeFromNodeId } from '@worldfavor/portal/selectors/dataSelector'
import Colors, { hexToRgbA } from '@worldfavor/constants/colors'
import FulfillmentStatusIndicator from '../SupplyChain/FulfillmentStatusIndicator'
import Image from '@worldfavor/components/Image'
import InfluenceTarget from './InfluenceTarget'
import MultiLineTextEllipsis from '@worldfavor/components/Text/MultiLineTextEllipsis'
import { exchangePath, influencePath } from '@worldfavor/constants/paths'
import { getCurrentUser } from '@worldfavor/portal/selectors/appSelector'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: 16,
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: Colors.grayHover,
    },
  },
  contentWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  influenceInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '45%',
    justifyContent: 'center',
  },
  detailsWrapper: {
    display: 'flex',
    marginLeft: 40,
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minWidth: 200,
    marginLeft: 26,
    marginRight: 72,
  },
  influenceDetail: {
    color: Colors.grayText,
    marginLeft: 26,
    marginTop: 12,
    paddingRight: 20,

    "& + &": {
      marginTop: 5,
    }
  },
  assignedQuestions: {
    paddingTop: 8,
    color: '#4898DA',
  },
  assignedQuestionsIcon: {
    color: '#4898DA',
    marginRight: 5,
  },
  label: {
    marginRight: 4,
  },
  detailValue: {
    fontWeight: 600,
  },
  takeOver: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 170,
    marginLeft: 26,
    marginRight: 26,
  },
  influenceInfo: {
    display: 'flex',
    width: '100%',
  },
  title: {
    fontSize: theme.typography.fontSizes.larger,
    fontWeight: theme.typography.fontWeights.medium,
    color: theme.palette.text.primary,
  },
  description: {
    fontSize: theme.typography.fontSizes.medium,
    color: hexToRgbA(theme.palette.common.black, 0.6),
  },
  button: {
    borderColor: hexToRgbA(theme.palette.common.black, 0.1),
    color: Colors.black,
  },
}))

const ReceivedInfluenceRow = (props) => {
  const classes = useStyles(props)
  const { influence, hideStatus } = props
  const influenceContent = useSelector(state => influence ? getNodeFromNodeId(state, influence.wfcid) : undefined)
  const influenceTargetLabel = getInfluenceTargetLabel()
  const influenceTargets = getInfluenceTargets()
  const isNotFulfillable = get(influenceContent, 'conditions.requirementPackageSettings.isTool') || get(influenceContent, 'conditions.clientSettings.componentType') || get(influenceContent, 'conditions.templateId') === 80
  const currentUser = useSelector(getCurrentUser)
  const currentUserHaveQuestionsAssigned = influence && influence.taggedUserIds && influence.taggedUserIds.includes(currentUser.id)

  function getInfluenceTargetLabel() {
    if (influence.thirdPartyTargetOrganization) {
      return <FormattedMessage id={'supplyChain.thirdPartyReporting.reportingOnBehalfOf'} />
    }
    if (influence.thirdPartyReporterOrganizations) {
      return <FormattedMessage id={'supplyChain.thirdPartyReporting.reportingOnOurBehalf'} />
    }
    return null
  }

  function getInfluenceTargets() {
    if (influence.thirdPartyTargetOrganization) {
      return [influence.thirdPartyTargetOrganization]
    }
    if (influence.thirdPartyReporterOrganizations) {
      return influence.thirdPartyReporterOrganizations
    }
    return null
  }

  return (
    <Link
      data-cy={'influence'}
      className={classes.root}
      style={{ textDecoration: 'none' }}
      to={influence.useNewRoute ? exchangePath(influence.wfid) : influencePath(influence.id)}
    >
      <div className={classes.contentWrapper}>
        <div className={classes.influenceInfo}>
          <Image
            src={influenceContent && influenceContent.imageUrl}
            style={{ width: 80, height: 80, borderRadius: 3, marginRight: 25, flexShrink: 0 }}
            size={'cover'}
            data-cy={'influence-image'}
          />
          <div className={classes.influenceInfoWrapper}>
            <MultiLineTextEllipsis
              lines={1}
              hideReadMore
              className={classes.title}
            >
              <span data-cy={'influence-title'}>{influence.title}</span>
            </MultiLineTextEllipsis>
            <MultiLineTextEllipsis
              lines={2}
              hideReadMore
              className={classes.description}
            >
              <span data-cy={'influence-description'}>{influenceContent && influenceContent.description}</span>
            </MultiLineTextEllipsis>

          </div>
        </div>
        {
          !hideStatus && (
            <div className={classes.detailsWrapper}>
              {
                influenceTargets && (
                  <div className={classes.takeOver} data-cy={'influence-target'}>
                    <InfluenceTarget
                      label={influenceTargetLabel}
                      targets={influenceTargets}
                    />
                  </div>
                )
              }
              <div className={classes.status} data-cy={'influence-status'}>
                {(influence.hasRequirement || influence.fulfillmentHasProgress) && !isNotFulfillable && (<FulfillmentStatusIndicator influence={influence} />)}
              </div>
              {
                influence.fulfillmentDueAt && !isNotFulfillable && (
                  <div className={classes.influenceDetail} data-cy={'influence-dueAt'}>
                    <span className={classes.label}><FormattedMessage id={'general.deadline'} />:</span>
                    <span className={classes.detailValue}>
                      <FormattedDate
                        year={'numeric'}
                        day={'numeric'}
                        month={'long'}
                        value={influence.fulfillmentDueAt}
                      />
                    </span>
                  </div>
                )
              }
              {
                influence.targetUser && (
                  <div className={classes.influenceDetail} data-cy={'influence-targetUser'}>
                    <span className={classes.label}><FormattedMessage id={'supplyChain.influences.targetUser'} />:</span>
                    <span className={classes.detailValue}>
                      {influence.targetUser.name}
                    </span>
                  </div>
                )
              }
              {
                currentUserHaveQuestionsAssigned && (
                  <div className={classNames(classes.influenceDetail, classes.assignedQuestions)} data-cy={'influence-assignedQuestions'}>
                    <i className={classNames('fas fa-tags', classes.assignedQuestionsIcon)}/>
                    <span className={classes.detailValue}><FormattedMessage id={'supplyChain.influences.assignedQuestions'} /></span>
                  </div>
                )
              }
            </div>
          )
        }
      </div>
      <Button variant="outlined" classes={{ root: classes.button }} data-cy={'actions-button'}>
        <FormattedMessage id={'general.open'} />
      </Button>
    </Link>
  )
}

export default ReceivedInfluenceRow
