/** 
 * @ngdoc directive
 * @name wfSocialShare
 * 
 * @description 
 * Used for sharing links or content on social media - Facebook, Twitter, Google+, Linkedin using (720kb/angular-socialshare plugin) 
 * Directive supports click-to-copy links (using ngClipboard plugin) for easier copying on client'c clipboard
 * 
 * @param {Object=} show Configuration object to set enable different elements of the wfSocialShare directive (socialMediaHeader, socialMedia, shareLinkHeader, shareLink, inputField, copyLinkText)
 * @param {String} link A link that will be shared in social media or copied in clipboard
 * @param {String} socialTitle Sets the title text which is then used in the text field or title field depending on the provider settings
 * @param {String} providers Specifies which providers to include in the html providers="facebook, twitter" will include facebook & twitter 
 * @param {String} hashtags Includes hastags to supported providers (i.e. twitter)
 * 
 * @property funciton copyClipboardSuccess
 * @property function copyClipboardFail
 * @property function resetClipboard
 * 
 * @param {Object} providers 
 * @property boolean facebook
 * @property boolean twitter
 * @property boolean google
 * @property boolean linkedin
 * 
 * @param {Object} show 
 * @property boolean socialMediaHeader
 * @property boolean socialMedia
 * @property boolean shareLinkHeader
 * @property boolean shareLink
 * @property boolean inputField
 * @property boolean copyLinkText
 * 
 * @example SIMPLE EXAPMLES
 * <wf-social-share link="http://portal.worldfavor.com" social-title="My title"></wf-social-share>
 * 
 * @example USING SHOW ATTRIBUTE
 * <wf-social-share link="http://portal.worldfavor.com" show="{socialMediaHeader: false, shareLinkHeader: false, inputField: false }"></wf-social-share>
 * 
 * @example USING PROVIDERS ATTRIBUTE
 * <wf-social-share link="http://portal.worldfavor.com" providers="facebook, twitter"></wf-social-share>
 * 
 **/

(function() {
	'use strict';

	angular
		.module('wf.common')
		.directive('wfSocialShare', wfSocialShare);

	wfSocialShare.$inject = [];
	function wfSocialShare() {
		var directive = {
			bindToController: true,
			controller: wfSocialShareController,
			controllerAs: 'vm',
			templateUrl: 'scripts/wf/socialShare/wfSocialShare.directive.html',
			restrict: 'E',
			scope: {
				link: "@", // @ binding is when the input is a string, especially when the value of the binding doesn't change
				socialTitle: "@", 
				hashtags: "@",
				incomingProviders: "@providers",
				incomingShow: "<show"
			}
		};

		return directive;
	}

	wfSocialShareController.$inject = [ '$scope', 'Socialshare', '$timeout' ];	
	function wfSocialShareController($scope, Socialshare, $timeout) {
		var vm = this;

		_.assign(vm, {
			//Variables
			clipboardSuccess: undefined,
			clipboardText: "Copy profile link",
			hashtags: undefined,
			show: {
				socialMediaHeader: true,
				socialMedia: true,
				shareLinkHeader: true,
				shareLink: true,
				inputField: true,
				copyLinkText: true
			},
			providers: {
				facebook: true,
				twitter: true,
				google: true,
				linkedin: true
			},

			//Functions
			copyClipboardSuccess: copyClipboardSuccess,
			copyClipboardFail: copyClipboardFail,
			resetClipboard: resetClipboard,
			share: share
		});

		activate();

		function activate() {
			if (vm.incomingShow != undefined && !(_.isEmpty(vm.incomingShow))) {
				_.assign(vm.show, vm.incomingShow);
			}

			if (vm.incomingProviders) {
				var providers = vm.incomingProviders.split(/[\s,]+/);
				if (providers.length > 0) {
					vm.providers = {};
					_.forEach(providers, function(provider) {
						vm.providers[provider] = true;
					});
				}
			}
			vm.loaded = true;
		}

		function copyClipboardSuccess(event) {
			vm.clipboardSuccess = true;
			vm.clipboardText = "Copied!";
			$timeout(function() {
				resetClipboard();
			}, 5000);
		}
		
		function copyClipboardFail(event) {
			vm.clipboardSuccess = false;
			vm.clipboardText = "Could not copy!";
		}

		function resetClipboard() {
			vm.clipboardSuccess = undefined;
			vm.clipboardText = "Copy profile link";
		}

		function share(provider) {
			var shareAttributes = getAttributesForProvider(provider);
			if (shareAttributes) {
				Socialshare.share({
					'provider': provider,
					'attrs': shareAttributes
				});
			}
			else {
				console.error("An error occured when trying to set up the social media providers! - shareAttributes might not be defined");
			}
		}

		function getAttributesForProvider(provider) {
			if (provider == "facebook") {
				return {
					'socialshareUrl': vm.link,
					// 'socialshareTitle': vm.socialTitle,
					// 'socialshareText': "Text: " + vm.socialTitle,
					// 'socialshareDescription': "Descripiton: " + vm.socialTitle,
					// 'socialshareHashtags': getHashtags(provider),
					// 'socialshareText': "HEADLINE TEXT", //headline
					// 'socialshareMedia': vm.link //media URL
				}
			}
			else if (provider == "twitter") {
				return {
					'socialshareUrl': vm.link,
					'socialshareText': vm.socialTitle,
					'socialshareHashtags': getHashtags(provider)
				}
			}
			else if (provider == "google") {
				return {
					'socialshareUrl': vm.link
				}
			}
			else if (provider == "linkedin") {
				return {
					'socialshareUrl': vm.link,
					'socialshareText': vm.socialTitle
				}
			}
			else {
				console.error("getAttributesForProvider - could not match any provider");
				return false;
			}
		}

		function getHashtags(provider) {
			if (vm.hashtags) {
				var hashtags = vm.hashtags.split(/[\s,]+/);

				// if (provider == 'facebook') {
				// 	var output = "";
				// 	_.forEach(hashtags, function(hashtag) {
				// 		hashtag = "#" + hashtag;
				// 		output += " " + hashtag;
				// 	});
				// 	return output;
				// }

				if (provider == 'twitter') {
					return hashtags.join(",");
				}
			}
		}
	}
})();