// @flow
import * as React from 'react'
import { useState, useEffect, useLayoutEffect, useRef } from 'react'
import { Map, TileLayer } from 'react-leaflet'
import Colors from '@worldfavor/constants/colors'
import Arc from '@worldfavor/components/Leaflet/Arc'
import CustomMarker from '@worldfavor/components/Leaflet/CustomMarker'
import OrganizationMarker from './OrganizationMarker'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'

const useArcStyles = makeStyles({
  polyline: {
    transition: 'stroke-opacity 0.5s',
  },
})

const StyledArc = (props) => {
  const classes = useArcStyles(props)
  return <Arc className={classes.polyline} {...props} />
}

type Props = {
  innerRef: React.ComponentType<Map>,
  width: number | string,
  height: number | string,
  style?: { [string]: any },
  nodes: {
    [string]: {
      label: string,
      properties: {
        organizationWfid: string,
      },
    },
  },
  edges: Array<{
    [string]: {
      wfid: string,
      toWfid: string,
      fromWfid: string,
      contextWfid?: string,
    }
  }>,
  organizations: Array<{
    wfid: string,
    location: {
      latitude: number,
      longitude: number,
    },
    actorTypes: Array<{ title: string }>,
  }>,
  highlightedNodeId?: string,
  compact?: boolean,
}

export const useMapWidth = (ref) => {
  const [width, setWidth] = useState(null)
  const currentWidth = ref.current ? ref.current.container.offsetWidth : null
  useLayoutEffect(() => {
    setWidth(currentWidth)
  }, [currentWidth])
  return width
}

const OrganizationMap = (props: Props) => {
  const { innerRef, width, height, style, nodes, edges, compact, organizations, highlightedNodeId } = props
  const [polylines, setPolylines] = useState([])
  const [highlightedOrganizationIds, setHighlightedOrganizationIds] = useState(null)
  const [highlightedEdges, setHighlightedEdges] = useState(null)

  const ref = useRef()
  const mapWidth = useMapWidth(innerRef || ref)

  function setHighlightedOrganizations() {
    if (!highlightedNodeId) {
      setHighlightedOrganizationIds(null)
      setHighlightedEdges(null)
      return
    }

    const getHighlightedChildren = (id, parentId, edges) => {
      return edges.filter(edge => (edge.fromWfid === id && (!parentId || (edge.contextWfid === parentId))))
        .map(edge => ({
          edge,
          node: nodes[edge.toWfid],
        }))
        .reduce((acc, child) => {
          const children = getHighlightedChildren(child.node.wfid, child.edge.wfid, edges)
          return {
            ...acc,
            nodes: [
              ...(acc.nodes || []),
              child.node,
              ...children.nodes,
            ],
            edges: [
              ...(acc.edges || []),
              child.edge,
              ...children.edges,
            ],
          }
        }, { nodes: [], edges: [] })
    }
    const highlightedElements = getHighlightedChildren(highlightedNodeId, null, edges)
    const highlightedOrganizations = {
      ...highlightedElements,
      nodes: [
        ...highlightedElements.nodes,
        nodes[highlightedNodeId],
      ],
    }

    const highlightedOrganizationIds = highlightedOrganizations.nodes
      .map(node => _.get(node, 'properties.organizationWfid'))
      .filter(Boolean)

    setHighlightedOrganizationIds(highlightedOrganizationIds)
    setHighlightedEdges(highlightedOrganizations.edges.map(({ wfid }) => wfid))
  }

  function getUniqueEdges() {
    return Object.values(edges.reduce((acc, edge) => ({ ...acc, [`${edge.fromWfid}:${edge.toWfid}`]: edge }), {}))
  }

  // TODO incorrect highlighting, show all connections without
  //  taking into account the context
  function getPolylines() {
    const uniqueEdges = getUniqueEdges()
    return uniqueEdges.map(({ fromWfid, toWfid, wfid }) => {
      const fromNode = nodes[fromWfid]
      const toNode = nodes[toWfid]
      if (!fromNode || !toNode) {
        return null
      }

      // TODO support more than 'product'
      //  for organization mapping
      if (fromNode.label === 'product') {
        return null
      }

      const fromOrganization = organizations.find(({ wfid }) => wfid === fromNode.properties.organizationWfid)
      const toOrganization = organizations.find(({ wfid }) => wfid === toNode.properties.organizationWfid)

      if (!fromOrganization || !toOrganization) {
        return null
      }

      const fromOrganizationLocation = fromOrganization.location
      const toOrganizationLocation = toOrganization.location

      if (!fromOrganizationLocation || !toOrganizationLocation || !fromOrganizationLocation.latitude || !fromOrganizationLocation.longitude
        || !toOrganizationLocation.latitude || !toOrganizationLocation.longitude) {
        return null
      }

      const from = [fromOrganizationLocation.latitude, fromOrganizationLocation.longitude]
      const to = [toOrganizationLocation.latitude, toOrganizationLocation.longitude]
      let color = Colors.black
      let opacity = 1
      if (highlightedEdges) {
        color = highlightedEdges.includes(wfid) ? Colors.black : Colors.blackLight
        opacity = highlightedEdges.includes(wfid) ? 1 : 0.3
      }

      return {
        key: `polyline-from-${fromWfid}-to-${toWfid}`,
        from,
        to,
        color,
        opacity,
      }
    }).filter(Boolean)
  }

  function getOpacity(wfid) {
    if (highlightedOrganizationIds) {
      return highlightedOrganizationIds.includes(wfid) ? 1 : 0.3
    }
    return 1
  }

  useEffect(() => {
    setPolylines(getPolylines())
  }, [edges, highlightedEdges])

  useEffect(() => {
    setHighlightedOrganizations()
  }, [nodes, edges, highlightedNodeId])

  const uniqueOrganizations =  Object.values(organizations.reduce((acc, organization) => ({ ...acc, [organization.wfid]: organization }), {}))
    .filter(organization => organization.location && organization.location.latitude && organization.location.longitude)
  const actorTypesPerOrganization = nodes && Object.values(nodes)
    .filter(node => _.get(node, 'properties.organizationWfid'))
    .reduce((acc, node) => {
      return {
        ...acc,
        [node.properties.organizationWfid]: [
          ...new Set([
            ...(node.properties.actorTypes || []),
            ...(acc[node.properties.organizationWfid] || []),
          ]),
        ],
      }
    }, {})

  function onLoad(event) {
    const latlngs = uniqueOrganizations
      .map(({ location }) => [location.latitude, location.longitude])

    if (latlngs.length === 1) {
      const [location] = latlngs
      latlngs.push([
        location[0] + 0.000000000001,
        location[1] + 0.000000000001,
      ])
    }

    if (latlngs.length) {
      event.target.fitBounds(latlngs, { maxZoom: 10, padding: [10, 10] })
    }
  }

  const attributionText = 'Esri, HERE, Garmin, USGS, Intermap, INCREMENT P, NRCan, Esri Japan, METI, Esri China (Hong Kong), Esri Korea, Esri (Thailand), NGCC, (c) OpenStreetMap contributors, and the GIS User Community'
  const attribution = `<span title="${attributionText}" style="max-width:${mapWidth - 60}px;text-overflow: ellipsis;overflow: hidden;display: inline-block;white-space: nowrap;height: 12px;">${attributionText}</span>`

  return (
    <Map
      ref={innerRef || ref}
      zoomControl={false}
      center={[51.505, -0.09]}
      zoom={3}
      style={{ width, height, ...style }}
      whenReady={onLoad}
      maxZoom={18}
    >
      <TileLayer
        attribution={attribution}
        url={'https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}'}
      />
      {
        polylines.map(({ key, from, to, color, opacity }) => (
          <StyledArc
            key={key}
            from={from}
            to={to}
            options={{ weight: 3, color, opacity }}
          />
        ))
      }
      {
        uniqueOrganizations.map((organization) => {
          const { location, wfid } = organization
          return (
            <CustomMarker
              key={`organization-node-marker-${wfid}`}
              iconAnchor={compact ? [18, 18] : [29, 29]}
              position={[location.latitude, location.longitude]}
              riseOffset={9999}
              riseOnHover
            >
              <OrganizationMarker
                organization={organization}
                actorTypes={actorTypesPerOrganization[wfid]}
                style={{ opacity: getOpacity(wfid), transition: 'opacity 0.5s' }}
                showBadge={!compact}
                compact={compact}
              />
            </CustomMarker>
          )
        })
      }
    </Map>
  )
}

OrganizationMap.defaultProps = {
  width: 250,
  height: 250,

  edges: [],
  nodes: {},
}

export default React.forwardRef((props, ref) => <OrganizationMap {...props} innerRef={ref} />)
