import React from 'react'
import { react2angular } from 'react2angular'
import ItemSummaryContainer from '@worldfavor/components/Items/ItemSummaryContainer'
import { WrapProvider } from './providers'

const props = [
  'subItems',
  'relationProp',
  'style',
]

class ItemSummaryContainerWrapper extends React.Component {

  prepareProps = (props) => {
    const { subItems = [], relationProp, ...rest } = props
    return {
      ...rest,
      subItems: subItems.map(subItem => subItem.isComposite ? subItem.content : subItem.childContent),
      dataRelation: relationProp,
      style: {
        ...props.style,
        maxHeight: 'none',
      },
    }
  }

  render() {
    const { wfAuth, ...rest } = this.props
    return (
      <WrapProvider wfAuth={wfAuth}>
        <ItemSummaryContainer {...this.prepareProps(rest)} />
      </WrapProvider>
    )
  }
}

export default react2angular(ItemSummaryContainerWrapper, props, ['wfAuth'])
