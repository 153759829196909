import 'leaflet/dist/leaflet.css'
import 'leaflet/dist/leaflet.js'

import L from 'leaflet'
import '@worldfavor/components/Leaflet/leaflet.curve'

import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'

const fixLeafletDefaultIcon = () => {
  const DefaultIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: icon,
    shadowUrl: iconShadow,
  })

  L.Marker.prototype.options.icon = DefaultIcon
}

fixLeafletDefaultIcon()
